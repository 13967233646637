import React, { useCallback, useEffect, useState } from "react";
import "./examsStatusInfo.scss";
import { useDispatch, useSelector } from "react-redux";
import { currentFlashcardId, getFlashcardById } from "selectors/studying";
import { getExamHeader } from "selectors/api/apiSelector";
import { getActiveSubject } from "selectors/subject/subjectSelector";
import { examAnswerById } from "selectors/answersSelectors";
import { examsAssessmentType, getExamAnswer } from "v2/helpers";
import { getFormExams, getLastMediaTypeVisited } from "helpers";
import { getUserId } from "selectors/user";
import {
  questionIdUpdateStats,
  setAssessmentAnimation,
  updateExamFilterDataFromTopics,
} from "actions/desk";
import {
  setExamStatus,
  getExamHeader as getExamHeaderAction,
} from "actions/api";
import {
  ConfidentAssessmentIcon,
  NeitherAssessmentIcon,
  UnconfidentAssessmentIcon,
} from "icons/FlashcardIcons";
import classNames from "classnames";
import { isLandscape, isMobile } from "selectors/browser/browserSelector";
import { isEmpty } from "lodash";

type ExamsAssessmentType = {
  name: string;
  icon: any;
  status: number;
};
const ExamsAssessment: ExamsAssessmentType[] = [
  {
    name: "unconfident",
    icon: <UnconfidentAssessmentIcon />,
    status: 0,
  },
  {
    name: "neither",
    icon: <NeitherAssessmentIcon />,
    status: 2,
  },
  {
    name: "confident",
    icon: <ConfidentAssessmentIcon />,
    status: 1,
  },
];

const ExamsStatusInfo = () => {
  const dispatch = useDispatch();
  const activeSubject = useSelector(getActiveSubject);
  const examHeaderData = useSelector(getExamHeader);
  const currentId = useSelector(currentFlashcardId);
  const content = useSelector((state) => getFlashcardById(state, currentId));
  const answer = useSelector((state) => examAnswerById(state, currentId));
  const userId = useSelector(getUserId);
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);
  const initialAnswer = getExamAnswer(
    answer.get("correct"),
    answer.get("answered")
  );
  const [status, setStatus] = useState<number | null>(initialAnswer);
  const [showAnimation, setShowAnimation] = useState<boolean>(true);
  const [clicked, setClicked] = useState<boolean>(false);
  const mediaType = getLastMediaTypeVisited(userId);
  const hasMobileLandscape = hasMobile && hasLandscape;

  const handleAnimation = useCallback(() => {
    setTimeout(() => {
      setShowAnimation(false);
      dispatch(setAssessmentAnimation(false));
    }, 3000);
  }, [dispatch]);

  const setAnswer = useCallback(
    async (newAnswer: number, prevAnswer: number) => {
      const finalStatus = status === newAnswer ? null : newAnswer;
      await setClicked(true);
      await setStatus(finalStatus);
      await setShowAnimation(true);
      (await newAnswer) !== prevAnswer &&
        dispatch(setAssessmentAnimation(true));
      handleAnimation();
      let prevAns, finalAnswer;
      if (answer.get("answered")) {
        prevAns = prevAnswer;
      } else {
        prevAns = "no-answer";
      }
      if (answer.get("answered") && answer.get("correct") == newAnswer) {
        finalAnswer = -1;
      } else {
        finalAnswer = newAnswer;
      }
      if (getFormExams() === "exams") {
        dispatch(questionIdUpdateStats(currentId, finalAnswer));
      } else {
        dispatch(updateExamFilterDataFromTopics(currentId, finalAnswer));
      }
      dispatch(
        setExamStatus({
          flashcardId: currentId,
          answer: finalAnswer,
          prevAnswer: prevAns,
          type: "frq",
        })
      );
    },
    [answer, currentId, dispatch, handleAnimation, status]
  );

  const keyEventListner = useCallback(
    (newAnswer: number) => {
      if (!answer) return null;
      setAnswer(newAnswer, answer.get("correct"));
    },
    [answer, setAnswer]
  );

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const key = parseInt(e.key);
      const target = e.target as Element;
      if (!answer || ["INPUT", "TEXTAREA"].includes(target.nodeName)) return;
      if (key === 1) {
        keyEventListner(0);
      } else if (key === 2) {
        keyEventListner(2);
      } else if (key === 3) {
        keyEventListner(1);
      }
    },
    [answer, keyEventListner]
  );

  useEffect(() => {
    // setStatus(getExamAnswer(answer.get("correct"), answer.get("answered")));
    if (mediaType === "exams") {
      window.addEventListener("keyup", (e: any) => handleKeyUp(e));
      // @ts-ignore
      const eventMethod = window.addEventListener
        ? "addEventListener"
        : "attachEvent";
      // @ts-ignore
      const eventer = window[eventMethod];
      const messageEvent =
        eventMethod === "attachEvent" ? "onmessage" : "message";
      eventer(messageEvent, function (e: any) {
        if (e.data.keyUpEvent && e.data.keyUpEvent.key == 1) {
          keyEventListner(0);
        }
        if (e.data.keyUpEvent && e.data.keyUpEvent.key == 2) {
          keyEventListner(2);
        }
        if (e.data.keyUpEvent && e.data.keyUpEvent.key == 3) {
          keyEventListner(1);
        }
      });
    }

    if (typeof examHeaderData == "undefined" || isEmpty(examHeaderData)) {
      dispatch(
        getExamHeaderAction({
          activeSubjectId: activeSubject?.id,
          examUid:
            content.get("experienceData") &&
            content.get("experienceData").get("exam_uuid"),
        })
      );
    }
  }, [
    activeSubject?.id,
    content,
    dispatch,
    examHeaderData,
    handleKeyUp,
    keyEventListner,
    mediaType,
  ]);

  useEffect(() => setStatus(initialAnswer), [initialAnswer]);

  if (!answer || !currentId) return null;

  return (
    <div className="answer-statistics rounded-[21px] flex-grow mb-2.5 flex-col">
      <div className="answer-assesstment">
        <div className="answer-inner-container">
          {ExamsAssessment.map((item: any, index: number) => {
            let answerClass =
              status === item.status
                ? "confident"
                : answer.get("answered")
                ? "svg-color"
                : "";
            return (
              <div
                key={index}
                className={classNames(
                  `answer-line select-none ${answerClass}`,
                  {
                    active: status === item.status,
                    assessment_uncofident: item.name === "unconfident",
                    assessment_neither: item.name === "neither",
                    assessment_confident: item.name === "confident",
                  }
                )}
                onClick={() => setAnswer(item.status, answer.get("correct"))}
              >
                {item.icon}
              </div>
            );
          })}
        </div>
        {clicked && !hasMobileLandscape && (
          <div
            className={classNames(
              "description font-[600] text-13px leading-18px ml-2",
              {
                "exam-show-animation": showAnimation,
                "hide-animation": !showAnimation,
                "text-[#FA9000]": status === 0,
                "text-[#3EBF8F]": status === 1,
                "text-#A2A3A3": status === 2,
              }
            )}
          >
            <>{examsAssessmentType(status).title}</>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExamsStatusInfo;
