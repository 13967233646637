import './NoteEditor.scss';
import appHistory from 'appHistory';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React, {PureComponent} from 'react';
import * as Actions from 'actions';
import {shouldGoBackToSearch} from 'selectors';
import * as Selectors from 'selectors';
import NoteEditor from './NoteEditor';
import { getLastMediaTypeVisited } from 'helpers';
import {getUserId} from 'selectors/user';

class NoteEditorContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.onTextareaChange = this.onTextareaChange.bind(this);
  }

  componentDidMount() {
    this.props.initializeNote(this.props.currentFlashcardId, (appHistory.location.search.split('mediaType='))[1].split("&id=")[0]);
  }

  componentDidUpdate(prevProps) {
    const  mediaType = getLastMediaTypeVisited(this.props.userId);
    if (this.props.currentFlashcardId !== prevProps.currentFlashcardId) {
      this.props.initializeNote(this.props.currentFlashcardId, mediaType);
      if (prevProps.textareaNote === '') {
        //this.props.deleteNote(prevProps.currentFlashcardId, false);
      }
    }
  }

  componentWillUnmount() {
    if (this.props.textareaNote === '') {
      //this.props.deleteNote(this.props.currentFlashcardId, false);textareaNote
    }
    this.props.textareaNoteChange('');
  }

  onTextareaChange(event) {
    const  mediaType = getLastMediaTypeVisited(this.props.userId) 
    const {value} = event.target;
    this.props.textareaNoteChange(value);
    this.props.updateNote(value, this.props.currentFlashcardId, mediaType);
    if(mediaType != this.props.activeMediaTab){
      this.props.setActiveMediaTab(mediaType);
    }
    
  }

  render() {
    return this.props.initializeStatus === 'inProgress' ?
      <div className="note-editor"><LeftMenuLoader /></div> :
      <NoteEditor onTextareaChange={this.onTextareaChange} {...this.props}/>;
  }
}

NoteEditorContainer.propTypes = {
  getNote: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  flashcardId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  flashcardNoteContent: Selectors.flashcardNoteContent(state),
  flashcardNoteRequest: Selectors.flashcardNoteRequest(state),
  currentFlashcardId: Selectors.Studying.currentFlashcardId(state),
  textareaNote: Selectors.textareaNote(state),
  deleteConfirmationMode: Selectors.deleteConfirmationMode(state),
  initializeStatus: Selectors.initializeStatus(state),
  shouldGoBackToSearch: shouldGoBackToSearch(state),
  userId: getUserId(state),
  activeMediaTab: Selectors.getActiveMediaTab(state),
});

const mapDispatchToProps = {
  getNote: Actions.Desk.getNote,
  updateNote: Actions.Desk.updateNote,
  deleteNote: Actions.Desk.deleteNote,
  addNote: Actions.Desk.addNote,
  initializeNote: Actions.Desk.initializeNote,
  textareaNoteChange: Actions.Desk.textareaNoteChange,
  setActiveMediaTab: Actions.Navigation.setActiveMediaTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteEditorContainer);
