import React, { useCallback, useMemo } from "react";
import classNames from "classnames";


type TlvlButtonProps = {
  onClick?: () => void,
  name: string,
  isActive: boolean,
  color: string,
  slug: string

}

const TlvlButton: React.FC<TlvlButtonProps> = ({ name, slug, isActive, color, onClick }) => {

  const computeBorderRadius = useCallback((tlvl: string, slug: string) => {
    if (slug === "mathematics" && tlvl === "Studies SL") return 'mathematics-sl';
    if (slug === "mathematics" && tlvl === "SL") return 'rounded-none';
    if (slug !== "mathematics" && slug !== "es-amp-s" && tlvl === "SL") return 'mathematics-sl';
    if (slug === "es-amp-s" && tlvl === "SL") return 'rounded-10px';
    if (slug === "pre-ib-mathematics") return 'rounded-10px';
    if (tlvl === "HL") return 'tlvl-hl';
  }, [name, slug])

  const activeClass = useMemo(() => {
    if (isActive) {
      return 'bg-#E6EEF7 z-2'
    } else {
      return 'bg-#FFFFFF z-1'
    }
  }, [isActive])

  const boxShadow = useMemo(() => {
    if (slug !== 'es-amp-s' && slug !== 'pre-ib-mathematics') {
      if (isActive) {
        return 'box-378EF8'
      } else {
        return 'box-E3E6E6'
      }
    }
    return null
  }, [isActive])

  const computePadding = useMemo(() => {
    if (name.indexOf('SL') > -1) return 'py-0.5 px-2';
    if (name.indexOf('HL') > -1) return 'pt-0.5 pr-1.5 pb-0.5 pl-7px';
    return '2px 8px;';
  }, [])

  return <div className={classNames("tlvl-button flex flex-1 items-center justify-center cursor-pointer", computeBorderRadius(name, slug), activeClass, boxShadow)} onClick={onClick}>
    <div className={classNames('tlvl-text ', computePadding)} style={{ backgroundColor: color }}>
      {name}
    </div>
  </div>
}

export default TlvlButton