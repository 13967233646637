import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { activeFlashcardId, activeTeachingLevelTitle, listOfFilterCheckedItems } from 'selectors';
import { List, Map } from 'immutable';
import { getSelectedTreeNodes } from 'selectors/topicsTreeSelector'
import classNames from 'classnames'
import { isLeftMenuOpen } from 'selectors/navigation/navigationSelector';
import './flashcard-list.scss'
import ToggleMenuButtonContainer from 'v2/components/studying/ToggleMenuButtonContainer';
import menuToggleInactiveIcon from 'assets/icons/menu-toggle-inactive.svg';
import { getMediaTypeFilterOverlay, toggleFilterOverlay } from 'actions/navigation';
import { getLastMediaTypeVisited} from 'helpers';
import { getUserId } from 'selectors/user';
import { FilterIcon, FilterClearIcon} from 'icons/HeaderIcons';
import { updateFilterListOfItemsSelected } from 'actions/desk';
import SubtopicFloatingTitle from 'v2/components/flashcards-vertical-list/SubtopicFloatingTitle';
import { useSubtopicNumber } from 'hooks/useTopicNumber';

type RenderHeaderProps = {
    activeTab: string;
    selectTab: (val: string) => void;
    list: any;
    selectedList: any;
    isScrolled: boolean;
    subjectId: string;
    hideNavButton?: boolean;
}  

const RenderHeader: React.FC<RenderHeaderProps> = ({activeTab, selectTab, list, selectedList, isScrolled, subjectId, hideNavButton}) => {
  const dispatch = useDispatch();
  const [filter, setFitler] = useState(false);
  const flascardId = useSelector(activeFlashcardId)
  const selectedTreeNodes = useSelector(getSelectedTreeNodes)
  const hasLeftMenuOpen = useSelector(isLeftMenuOpen)
  const activeTlvl = useSelector(activeTeachingLevelTitle)
  const listOfFilteritems = useSelector(listOfFilterCheckedItems)?.toJS();
  const userId = useSelector(getUserId)
  const listToSearch = activeTab === 'tab-all' ? list : selectedList;
  const flashcard = listToSearch.find((item: any) => item.get('id') === flascardId) || listToSearch.get(0) || List();
  const subTopic = flashcard.get('subTopic', Map())
  const numbering = useSubtopicNumber(subTopic.toJS())
  let hasSelectedValue = false
  const selectedValue = selectedTreeNodes.size

  useEffect(() => {
    checkFilter();
  });

  const checkFilter = () => {
    let mediaType = getLastMediaTypeVisited(userId);
    let data= listOfFilteritems[mediaType];
    if(data && data.length && data.length > 0 && ( (mediaType === "flashcard" && data.length < 4 ) || (mediaType === "revision" && data.length < 2) || (mediaType === "video" && data.length < 3) )){
      setFitler(true)
    }else{
      setFitler(false)
    }
  }
  const openFilter = (e: any) => {
      dispatch(toggleFilterOverlay(true, true))
      dispatch(getMediaTypeFilterOverlay("flashcard"))
      e.stopPropagation();
  }

  const clearFilter = (e: any) => {
    e.stopPropagation();
    dispatch(updateFilterListOfItemsSelected([], getLastMediaTypeVisited(userId)))
  }
  if (selectedValue > 0) {
      hasSelectedValue = true
  }
  return <div className={classNames('left-menu-spacing', {'left-menu-has-spacing':hasLeftMenuOpen})}>
      {!hideNavButton && <div className='absolute top-0 left-0 py-5px px-15px z-1'>
          <ToggleMenuButtonContainer>
          {(data: any) => (
            <div className={classNames('round-button', {'!shadow-none': isScrolled, 'round-btn-shadow' : !isScrolled})} onClick={data.onClick}>
              <img src={menuToggleInactiveIcon} alt="" />
            </div>
          )}
          </ToggleMenuButtonContainer>
      </div>}
      <div className={classNames('tab-header-wrapper', {'tab-header-shadow': isScrolled})}>
          <div className='uppercase box-border overflow-hidden rounded-[18px] text-white font-bold bg-white p-1 h-9'>
              <div className='flex'>
                  <div className={classNames('tab-header exercise-header-all-tab', {'tab-header-active': activeTab === 'tab-all', 'tab-header-inactive': !(activeTab === 'tab-all')})} onClick={() => selectTab('tab-all')}>
                    {activeTlvl} 
                  </div>
                  <div className={classNames('tab-header exercise-header-all-tab flex', {'tab-header-active': activeTab === 'tab-selected', 'tab-header-inactive': !(activeTab === 'tab-selected')}, {'pr-1' : hasSelectedValue})} onClick={() => selectTab('tab-selected')}>
                    Selected{hasSelectedValue && <div className={classNames('tab-select bg-#A2A3A3', {'px-1.5' : (selectedValue && selectedValue > 9)}, {'border border-solid border-white' : activeTab === 'tab-selected'})} >{selectedValue}</div>}
                  </div>
              </div>
          </div>
          <div className={classNames('filter-box execrise-filter-box', {'fliter-box-active' : filter, 'fliter-box-inactive' : !filter})} onClick={ (e) => openFilter(e)}>
            <FilterIcon />
            {filter && <div className={classNames('filter-clear-icon execrise-filter-clear')} onClick={(e) => clearFilter(e)} > <FilterClearIcon />
            </div>
            } 
          </div>
      </div> 
      <div className='flex justify-center'>
          {((activeTab === 'tab-selected' && selectedValue !== 0)) && 
            <div className={classNames("exercise-comment", {'exercise-comment-animation' : isScrolled})} > All cards  currently added to this exercise are displayed, even if they don't match the filter you set.</div>
          }
      </div>
      <div className='flex justify-center mt-2.5'>
          {activeTab === 'tab-all' && list.size > 0 && subTopic && 
            subTopicTitle(subjectId, subTopic, isScrolled, numbering)
          }
          {activeTab === 'tab-selected' && selectedList.size > 0 && subTopic && 
            subTopicTitle(subjectId, subTopic, isScrolled, numbering)
          }
      </div>
      
  </div>
}

const subTopicTitle = (subjectId: any, subTopic: any, isScrolled: boolean, numbering:string | undefined) => {

    return <SubtopicFloatingTitle
              subTopicSeparator
              id={subTopic.get('id')}
              isScrolled={true}
              active
              parentIds={[subjectId, subTopic.get('teachingLevelId'), subTopic.get('topicId')]}
              numbering={numbering ?? subTopic.get('numbering')} title={subTopic.get('name')} />
}

export default RenderHeader;