import React, { ReactElement } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { DueDate } from 'ui-components/exerciseComponents'
import { returnFinalExerciseStatus } from 'v2/helpers';
import { StudyMetaInfo } from 'components/studying/studay-meta-info/StudyMetaInformation';
import FlashcardIcon from 'assets/images/study/StudyMeta/Flashcard.svg';
import LeftActiveTile from '../topics-tree/LeftActiveTile';

type InternalExerciseTileProps = {
  exercise: any;
  isActive: boolean;
  noPadding?: boolean;
  onClick: () => void;
  titleComponent?: () => ReactElement;
}

const InternalExerciseTile: React.FC<InternalExerciseTileProps> = ({ exercise, isActive, noPadding, onClick, titleComponent }) => {
  const {flashcardStatus, flashcardStatusGraph} = returnFinalExerciseStatus(exercise['answerData']?.flashcardBox);
  let dueDate, formattedDueDate = '—', isPastDueDate = false;
  if (exercise.dueDate) {
    dueDate = moment.unix(exercise.dueDate);
    formattedDueDate = dueDate.format('MMM D, Y hh:mm A');
    isPastDueDate = moment().unix() > dueDate.unix();
  }
  const createdDate = moment.unix(exercise.createdAt);
  const formattedCreatedDate = createdDate.format('MMM D, Y');
  return (
    <div className={classNames('relative mb-8 grow', {'ml-3.5': !noPadding, 'mr-3.5': !noPadding })}>
      {isActive && <LeftActiveTile isExercise={false} />}
      <div className={classNames('int-ex-tile shadow-[0_0_0_1px_#E1E6E6] rounded-[10px] pt-3.5 px-3.5 pb-1.5 cursor-pointer select-none active:shadow-[0_0_0_3px_#378EF8]', { 'bg-[#E5ECF2]': isActive })} onClick={onClick}>
        <div className={'flex items-center pb-6'}>
          <div className='flex items-center overflow-hidden'>
            <div className='text-[13px] leading-[16px] text-[#979999] whitespace-nowrap'>{formattedCreatedDate}</div>
            <div className='pl-3 [&_img]:!block'><DueDate passed={isPastDueDate}>{formattedDueDate}</DueDate></div>
          </div>
        </div>
        {titleComponent ? titleComponent() : 
        <div className='font-bold text-[25px] text-#2D3838 leading-[34px]'>{exercise.title}</div>}
        <div className='pt-4'>
        {flashcardStatus.total > 0 && <StudyMetaInfo hasSelection={isActive} icon={FlashcardIcon} name={'Flashcards'} singleCard={'Flashcard'} mediaType={"flashcard"} number={flashcardStatus.total || 0} isRead={flashcardStatus.total === flashcardStatus.correct} statusBarData={flashcardStatusGraph} isActive={isActive} isSubtopic={false} isCarousel={false} isMcq={false} isExercise={true} hasNumbering={null} onClick={()=>{}}/>}
        </div>
      </div>
    </div>
  )
}

export default InternalExerciseTile