import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import HeaderFoldersTooltip from './HeaderFoldersTooltip';
import withScrollDetection, {withScrollDetectionContained} from 'hocs/withScrollDetection';
import FoldersListContainer from 'pages/desk/folders/FoldersListContainer';
/**
 * @deprecated TODO- delete not use any more
 */
class MobileFolders extends Component {
  componentDidMount() {
    this.props.getFolders();
    if (!this.props.currentFlashcardId) return;
    this.props.getFoldersForFlashcard(this.props.currentFlashcardId);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderFoldersTooltip
          isCollapsed={this.props.isScrolled}
          changeRoute={(routeName) => this.props.changeRoute(routeName)}/>
        <div className="folders-tooltip-container" ref={this.props.bindScrollRef}>
          {this.props.folders.get('data') && (
            <div className='folders-list-container'><FoldersListContainer
              changeRoute={(routeName, folderInfo) => this.props.changeRoute(routeName, folderInfo)}/></div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

MobileFolders.propTypes = {
  isScrolled: PropTypes.bool,
  currentFlashcardId: PropTypes.any,
  getFolders: PropTypes.func.isRequired,
  changeRoute: PropTypes.func.isRequired,
  bindScrollRef: PropTypes.func.isRequired,
  folders: PropTypes.instanceOf(Immutable.List),
  getFoldersForFlashcard: PropTypes.func.isRequired,
};

export default withScrollDetectionContained(MobileFolders);
