import classNames from 'classnames';
import React from 'react';
import './button.scss';

type SimpleButtonProps = {
    text: string|React.ReactElement; 
    onClick: () => void;
    inverted?: boolean;
    disabled?: boolean;
}  

const SimpleButton: React.FC<SimpleButtonProps> = ({text, onClick, inverted=false, disabled=false}) => {
  return (
    <button className={classNames('simple-button select-none', {'inverted' : inverted}, {'disabled' : disabled})} onClick={onClick}>{text}</button>
  )
}

export default SimpleButton;