const PREFIX = 'CAROUSEL_';

export const SET_FLASHCARD_IDS = PREFIX + 'SET_FLASHCARD_IDS';
export const SET_REVISION_IDS = PREFIX + 'SET_REVISION_IDS';
export const SET_VIDEO_IDS = PREFIX + 'SET_VIDEO_IDS';
export const SET_FLASHCARD_IDS_DETAILS = PREFIX + 'SET_FLASHCARD_IDS_DETAILS';
export const SET_VIDEO_IDS_DETAILS = PREFIX + 'SET_VIDEO_IDS_DETAILS';
export const SET_REVISION_IDS_DETAILS = PREFIX + 'SET_REVISION_IDS_DETAILS';
export const CALCULATE_FLASHCARD_INDEX = PREFIX + 'CALCULATE_FLASHCARD_INDEX';
export const CALCULATE_RENDERER_IDS = PREFIX + 'CALCULATE_RENDERER_IDS';
export const SELECT_PREV_FLASHCARD = 'SELECT_PREV_FLASHCARD';
export const SELECT_NEXT_FLASHCARD = 'SELECT_NEXT_FLASHCARD';
export const SET_PREVIOUS_FLASHCARD_IDS = PREFIX + 'SET_PREVIOUS_FLASHCARD_IDS';
