import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { StyledExamsFilterLayout, ExamsModeContainer, ExamsModeTitle, ExamsModeSubTitle, FloderExamsSection } from "./examFilter.styles"
import ExamFilterLayout from "./examFilterLayout"
import * as Actions from "actions"
import * as Selectors from 'selectors';
import { getFormExams, getLastMediaTypeVisited} from 'helpers';

const ExamsFilterTile = (props) => {
  const [active, setActive] = useState(false);
  const { isEditMode, type ,isExamsMode,activeMediaTab,currentFlashcardId,userId} = props
  function selectToggle(val) {
    setActive(!val)
    props.toggleExamsMode(!val)
  }
  useEffect(()=>{
    if(currentFlashcardId && getLastMediaTypeVisited(userId) === 'exams'){
      if(getFormExams()==='exams' && currentFlashcardId){
        props.toggleExamsMode(true)
      }else{
        props.toggleExamsMode(false)
      }
    }else{
      // props.toggleExamsMode(false)
    }
    
  }, [])
  

  return (
    <StyledExamsFilterLayout type={type}>
      <ExamsModeContainer >
        <ExamsModeTitle isEditMode={isEditMode ? true : false}>Exams mode</ExamsModeTitle>
        <div className="filter-toggle-icon-box">
          <div className="filter-toggle-icon">
            <label className="switch">
              <input disabled={isEditMode} onChange={() => selectToggle(isExamsMode)} type="checkbox" checked={isExamsMode} />
              <span className={isEditMode ? "opacity-5 slider round" : "slider round"}></span>
            </label>
          </div>
        </div>
      </ExamsModeContainer>
      <ExamsModeSubTitle>Hides any info in the previews you would not receive in the actual exam </ExamsModeSubTitle>
      {(type !== 'filterSearch' || !isExamsMode) &&
        <FloderExamsSection>
          <ExamFilterLayout type={type} examsMode={isExamsMode} isEditMode={isEditMode ? true : false} />
        </FloderExamsSection>
      }
    </StyledExamsFilterLayout>
  )
}

const mapStateToProps = (state) => ({
  isExamsMode: Selectors.isExamsMode(state),
  currentFlashcardId:Selectors.currentFlashcardId(state),
  userId: Selectors.getUserId(state),
})

const mapDispatchToProps = {
  toggleExamsMode: Actions.Navigation.toggleExamsMode,

}

export default connect(mapStateToProps, mapDispatchToProps)(ExamsFilterTile)
