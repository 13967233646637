import React, {Component} from 'react';
import './HorizontalSeparator.scss';

export default class PrimaryButton extends Component{
  render(){
    return(
      <div className={`separator-horizontal ${this.props.className}`}></div>
    );
  }
}
