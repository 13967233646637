import React, {Component} from 'react';
import './BackButton.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class BackButton extends Component {
  render() {
    return (
      <Link className='back-button' to={this.props.location}>
        <img src={require('assets/images/backbutton.svg')} alt="Go back!"/>
      </Link>
    );
  }
}


BackButton.propTypes = {
  location: PropTypes.string.isRequired
};
