import React, { useEffect, useState } from "react"
import { setDocumentTitle } from "actions/navigation";
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import { useDispatch, useSelector } from 'react-redux';
import SaveButton from 'v2/components/navigation-header/SaveButton';
import BackToProfileButtonContainer from 'v2/pages/profile/BackToProfileButtonContainer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RenderInputField } from 'v2/utils/form-helper';
import { changePassword } from 'actions/api'


const ChangePasswordContainer: React.FC = () => {
  const initialFormValue = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }

  const [isFocused, setFocused] = useState({ oldPassword: false, newPassword: false, confirmNewPassword: false });
  const { values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue } = useFormik({
    validationSchema: validation,
    enableReinitialize: true,
    initialValues: initialFormValue,
    onSubmit: (values) => {
      disptach(changePassword(values))
    }
  });

  const disptach = useDispatch();
  useEffect(() => {
    disptach(setDocumentTitle('Change password'))
  }, [])

  const invalid = !(values.oldPassword.length > 0 && values.newPassword.length && values.confirmNewPassword.length)


  return <div className="profile-details-page">
    {/* @ts-ignore */}
    <NavigationHeader extendedWidth title="Password" leftSection={<BackToProfileButtonContainer />} rightSection={(<SaveButton disabled={invalid} onClick={handleSubmit} />)} />
    <div className='profile-details-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-input-wrapper'>
          <RenderInputField
            label={'Current password'}
            type='password'
            name='oldPassword'
            placeholder='Enter your current password'
            className='form-input'
            showIcon={true}
            touched={touched.oldPassword}
            error={errors.oldPassword}
            reset={() => setFieldValue("oldPassword", "")}
            active={isFocused.oldPassword}
            value={values.oldPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ oldPassword: true, newPassword: false, confirmNewPassword: false }
              )
            }}
          />
        </div>
        <div className='form-input-wrapper'>
          <RenderInputField
            label='New password'
            type='password'
            name='newPassword'
            placeholder='Enter a new password'
            className='form-input'
            showIcon={true}
            touched={touched.newPassword}
            error={errors.newPassword}
            reset={() => setFieldValue("newPassword", "")}
            active={isFocused.newPassword}
            value={values.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={false}
            autoComplete={false}
            onFocus={() => {
              setFocused({ oldPassword: false, newPassword: true, confirmNewPassword: false }
              )
            }}
          />
        </div>
        <div className='form-input-wrapper'>
          <RenderInputField
            label={'Confirm new password'}
            type='password'
            name='confirmNewPassword'
            placeholder='Confirm new password'
            className='form-input'
            showIcon={true}
            touched={touched.confirmNewPassword}
            error={errors.confirmNewPassword}
            reset={() => setFieldValue("confirmNewPassword", "")}
            active={isFocused.confirmNewPassword}
            value={values.confirmNewPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ oldPassword: true, newPassword: false, confirmNewPassword: false }
              )
            }}
          />
        </div>
      </form>
    </div>
  </div>
}

let validation = Yup.object().shape({
  oldPassword: Yup.string().required("This field is required!"),
  newPassword: Yup.string().required("This field is required!"),
  confirmNewPassword: Yup.string().required("This field is required!"),
});

export default ChangePasswordContainer;