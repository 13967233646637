import * as Actions from 'actions';
import appHistory from 'appHistory';
import * as Types from 'actions/types';
import * as Selectors from 'selectors';
import {all, takeLatest, select, put} from 'redux-saga/effects';

export function* watchers() {
  yield all([
    yield takeLatest(Types.Navigation.OPEN_STORE, onOpenStore),
    yield takeLatest(Types.Navigation.TOGGLE_BODY_SCROLL, onToggleBodyScroll)
  ]);
}

function* onOpenStore() {
  try {
    const isMobile = yield select(Selectors.isMobile);
    if (isMobile) {
      appHistory.push('/store');
      return;
    }
    yield put(Actions.Navigation.showStore());
  } catch (error) {
    console.error("error for onOpenStore", error)
  }
}

function onToggleBodyScroll(action) {
  try {
    const bodyElement = document.body;
    if (action.value) {
      bodyElement.classList.remove('prevent-scroll');
      window.removeEventListener('touchmove', onMove);
    } else {
      bodyElement.classList.add('prevent-scroll');
      window.addEventListener('touchmove', onMove);
    }
  } catch (error) {
    console.error("error for onToggleBodyScroll", error)
  }
}

const onMove = (e) => {
  e.preventDefault();
  e.stopPropagation();
};
