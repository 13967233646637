import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { contactDetails } from 'selectors'
import { setDocumentTitle } from 'actions/navigation'
import { getContactDetails, saveContactDetails } from 'actions/api'
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import ContactDetailsPageComponent from './ContactDetailsPageComponent';

const ContactDetailsPageContainer = () => {
  const initialValues = useSelector(contactDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContactDetails());
    dispatch(setDocumentTitle('Contact details'));
  }, [])
  return initialValues ? <ContactDetailsPageComponent userProfile = {initialValues} onSubmit={(data:any)=>dispatch(saveContactDetails(data))} /> : <LeftMenuLoader/>;

}

export default ContactDetailsPageContainer