import React, { ReactElement, useMemo } from "react";
import { getMBTldBasedOnRegion, getMBUrlBasedOnRegion } from 'helpers';

type SectionWrapperProps = {
  hasMBIdentity: boolean,
  children: ReactElement | string;
  userData: any,
  isMBUser: boolean,
  onClick?: () => void,
  className?: string
}

const SectionWrapper: React.FC<SectionWrapperProps> = ({ hasMBIdentity, children, userData, isMBUser, onClick, className }) => {

  const getProfileUrl = useMemo(() => {
    if (!hasMBIdentity || !userData.mbRole) {
      return 'https://www.smart-prep.com/pages/app-for-schools';
    }

    if (!isMBUser) {
      return `https://www.managebac.${getMBTldBasedOnRegion(userData.region)}/login/`;
    }

    return `https://${userData.subdomain}.${getMBUrlBasedOnRegion(userData.region)}/${userData.mbRole.toLowerCase()} ? '' : '/profile'}`;
  }, [userData, hasMBIdentity])


  if (hasMBIdentity) {
    return <a href={getProfileUrl} target='_blank' rel="noopener noreferrer" className={className} >{children}</a>;
  } else {
    return <div className="cursor-pointer z-1" onClick={onClick}>{children}</div>;
  }

}

export default SectionWrapper