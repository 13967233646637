import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form/immutable';
import {renderSelectField} from '../../utils/form-helper';

export default class FormSelectComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.resetField = this.resetField.bind(this);
  }

  resetField() {
    this.props.change(this.props.name, '');
    this.props.touch(this.props.name);
  }


  render() {
    return (
      <div className='form-input-wrapper'>
        <Field
          name={this.props.name}
          component={renderSelectField}
          type='select'
          className={`form-input ${this.props.className}`}
          validate={this.props.validate}
          label={this.props.label}
          reset={this.resetField}
          children={this.props.children}
          showIcon={this.props.showIcon}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

FormSelectComponent.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  component: PropTypes.string,
  validate: PropTypes.array,
  change: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

FormSelectComponent.defaultProps = {
  showIcon: true,
};
