import classNames from 'classnames';
import React from 'react';
import './keyboard.scss';
import PageOverlay  from 'v2/ui-components/PageOverlay';

import Text from 'v2/components/common/Text';
import { getLastMediaTypeVisited } from 'helpers';
import { isKeyboardNavigationOverlayVisible } from 'selectors';
import {getUserId} from 'selectors/user';
import { toggleKeyboardNavigationOverlay } from 'actions/navigation';
import KeyboardShortcut from './KeyboardShortcut';
import {title, keyboardKeys, notes} from './keyboardKeys'
import { useDispatch, useSelector } from 'react-redux';

type KeyboardNavigationProps = {
   
}  

const KeyboardNavigation: React.FC<KeyboardNavigationProps> = () => {
    const dispatch = useDispatch();
    const userId = useSelector(getUserId);
    const isOverlay = useSelector(isKeyboardNavigationOverlayVisible);
    const mediaType = getLastMediaTypeVisited(userId) as any;
    return (
        <PageOverlay onClose={() => dispatch(toggleKeyboardNavigationOverlay(false, false))} animate isOverlayVisible={isOverlay} topPosition={false}>
            <div className='p-12 border border-#E1E6E6 border-solid rounded-2xl min-w-[822px] max-w-[822px]'>
                <div className='text-33px font-bold text-#2D3838 tracking-normal leading-45px pl-3'>Keyboard shortcuts</div> 
                <div className='pt-3.5 pl-3 flex justify-between'>
                    <div className='flex flex-col w-2/4 pr-5'>
                        <div className='flex items-center py-2'>
                            {title[mediaType]?.icon}
                            <Text className='text-19px font-bold leading-26px text-#2D3838 ml-2'>{title[mediaType]?.name}</Text>
                        </div>
                        {
                            keyboardKeys[mediaType]?.map(({name, btn, className}:{name: string; btn: string; className: string}) =>{
                                return <KeyboardShortcut btn={btn} name={name} className={className} key={name} />
                            })
                        }
                    </div>
                    <div className='flex flex-col w-2/4'>
                        <div className='pt-2 pb-2'>
                            <Text className='text-19px font-bold leading-26px text-#2D3838'>Tools</Text>
                        </div>
                        {
                            keyboardKeys['tools']?.map(({name, btn, className}:{name: string; btn: string; className: string}) =>{
                                return <KeyboardShortcut btn={btn} name={name !== 'Notes' ? name : notes[mediaType]} className={className} key={name} />
                            })
                        }
                    </div>
                </div>
            </div>
        </PageOverlay>
    )
}

export default KeyboardNavigation;