import * as Actions from 'actions';
import Loader, {BackgroundEnum, SizeEnum} from 'v2/components/loader.v2/Loader.v2';
import {AddToFolderIcon} from 'icons/FolderIcons';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {addFlashcardToFolderResponse, removeFlashcardFromFolderResponse, getFlashcardAnswersResponse} from 'selectors/api/apiSelector';
import { isRequestInProgress} from 'selectors';
import * as Selectors from 'selectors';
import PropTypes from 'prop-types';
import {Map, List} from 'immutable';
import {isMobile as isTouchDevice} from 'react-device-detect';
import styled, { css } from 'styled-components/macro';
import { getLastMediaTypeVisited } from 'helpers';
import {getUserId} from 'selectors/user';

class AddFlashcardToFolderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isHover: false,
    };
    
  }
 

  onClick = (folderId, addButtonVisible) => {
    if (addButtonVisible < 1) return false;
    const  mediaType = getLastMediaTypeVisited(this.props.userId);
    this.props.setActiveMediaTab(mediaType);
    if (this.isInFolder(folderId)) {
      this.props.removeFlashcardFromFolder({id: folderId, mediaType: mediaType});
    } else {
      this.props.addFlashcardToFolder({id: folderId, mediaType: mediaType});
    }
    this.setState({
      isHover: false
    });
  };

  isInFolder = folderId => { 
   
    if (!folderId) return false;
    const flashcardFolders = this.props.flashcardFolders;
    return flashcardFolders && flashcardFolders.findIndex(item => {
      //checks if flashcards are in folder (for FoldersListContainer) or if the currentFlashcardId is inside the list of flashcards of the folder (FolderPage)
      return item.get('id') == folderId || item.get('flashcardId') == this.props.currentFlashcardId;
    }) !== -1;
  };

  onMouseEnterHandler = () => {
    if(!this.props.isMobile || !isTouchDevice ){
      this.setState({
        isHover: true
      });
    }
  }

  onMouseLeaveHandler = () => {
    this.setState({
        isHover: false
    });
  }

  render() {
    const {folderId, currentFlashcardId, addFlashcardToFolderResponse, removeFlashcardFromFolderResponse, addButtonVisible,getFolderAddButtonActive} = this.props;
    if (!folderId) return null;
    return <div className='icon-spacing' style={!getFolderAddButtonActive ? {"pointerEvents":'none'} :{}} onClick={(e) => {
      if (!currentFlashcardId) return;
      e.stopPropagation();
      this.onClick(folderId, addButtonVisible);
    }} onMouseEnter={this.onMouseEnterHandler} onMouseLeave={this.onMouseLeaveHandler}>
      { (addFlashcardToFolderResponse.getIn([folderId, 'loading']) || removeFlashcardFromFolderResponse.getIn([folderId, 'loading'])) ?
        <Loader background={BackgroundEnum.Blue} size={SizeEnum.Small}/> : <React.Fragment>
          <StyledAddFlashcard addButtonVisible={addButtonVisible} activButton ={!getFolderAddButtonActive}>
            {currentFlashcardId && !this.isInFolder(folderId) && (<AddToFolderIcon  activButton ={!getFolderAddButtonActive} />)}
            {currentFlashcardId && this.isInFolder(folderId) && (<AddToFolderIcon  activButton ={!getFolderAddButtonActive} state='added' isHover={this.state.isHover}/>)}
            {!currentFlashcardId && this.isInFolder(folderId) && (<AddToFolderIcon activButton ={!getFolderAddButtonActive} state='active'/>)}
          </StyledAddFlashcard>
        </React.Fragment>}
    </div>;
  }
}

const mapStateToProps = state => ({
  currentFlashcardId: Selectors.Studying.currentFlashcardId(state),
  addFlashcardToFolderResponse: addFlashcardToFolderResponse(state),
  removeFlashcardFromFolderResponse: removeFlashcardFromFolderResponse(state),
  isMobile: Selectors.isMobile(state),
  isLandscape: Selectors.isLandscape(state),
  userId: getUserId(state),
  getFolderAddButtonActive:Selectors.getFolderAddButtonActive(state)

});
const mapDispatchToProps = {
  addFlashcardToFolder: Actions.Api.addFlashcardToFolderRoutine,
  removeFlashcardFromFolder: Actions.Api.removeFlashcardFromFolderRoutine,
  setActiveMediaTab: Actions.Navigation.setActiveMediaTab,
 // getFlashcardAnswersRoutine: Actions.Api.getFlashcardAnswersRoutine,
};

AddFlashcardToFolderContainer.propTypes = {
  folderId: PropTypes.string.isRequired,
  flashcardFolders: PropTypes.instanceOf(List).isRequired,

  // Connect
  currentFlashcardId: PropTypes.number.isRequired,
  addFlashcardToFolderResponse: PropTypes.instanceOf(Map).isRequired,
  removeFlashcardFromFolderResponse: PropTypes.instanceOf(Map).isRequired,
  addFlashcardToFolder: PropTypes.func.isRequired,
  removeFlashcardFromFolder: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(AddFlashcardToFolderContainer);

const StyledAddFlashcard = styled.div`
  display: ${props => props.loading ? 'none;' : 'block;'}
  opacity: ${props => props.addButtonVisible < 1 &&  '0.5'};
  cursor: ${props => props.addButtonVisible < 1 ||  props.activButton  &&  'not-allowed'};
`;