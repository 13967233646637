import React, {PureComponent} from 'react';
import FlashcardLinksContainer from './FlashcardLinksContainer';
import SubtopicLinksContainer from './SubtopicLinksContainer';
import TopicLinksContainer from './TopicLinksContainer';
import Padding from 'ui-components/helpers/Padding';
import {activeTeachingLevelTitle} from 'selectors';
import { connect } from 'react-redux';
import {Map, List} from 'immutable';
class LinksComponent extends PureComponent {
  constructor(props) {
    super(props);
  }


  render() {
    const {linkListItems, activeTeachingLevelTitle} = this.props;
    let flashcard;
    let topics;
    let subtopics;
    if(activeTeachingLevelTitle !== 'All'){
      flashcard = linkListItems.get('cards').filter(item => item.get('teachingLevel') === activeTeachingLevelTitle);
      subtopics = linkListItems.get('subTopics').filter(item => item.get('tlvl') === activeTeachingLevelTitle);
      topics = linkListItems.get('topics').filter(item => item.get('tlvl') === activeTeachingLevelTitle);
    }else{
      flashcard = linkListItems.get('cards');
      subtopics = linkListItems.get('subTopics');
      topics = linkListItems.get('topics');
    }
    return (
      <React.Fragment>
        <FlashcardLinksContainer cardsList={flashcard}/>
        <Padding bottom={16} />
        <SubtopicLinksContainer subTopicsList={subtopics}/>
        <Padding bottom={16} />
        <TopicLinksContainer topicsList={topics}/>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  activeTeachingLevelTitle: activeTeachingLevelTitle(state)
});
export default connect(mapStateToProps, null)(LinksComponent);
