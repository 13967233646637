import { SET_EXERCISE_TREE_NODES } from 'actions/topics-tree/topicsTreeTypes';
import {Map, fromJS} from 'immutable';

export default (state = fromJS({}), action) => {
   let path, current;
  switch (action.type) {
    // case TOGGLE_NODE_SELECTION:
    //   path = interleaveParentsWithChildren(action.parentIds);
    //   //toggle selected state
    //   current = state.getIn(path.concat(action.id, 'selected'));
    //   state = current ? setInitial(state, path.concat(action.id)) : setSelected(state, path.concat(action.id));

    //   state = state.withMutations(state => {
    //     state = setChildrenFieldValueRecursive(state, action.parentIds.concat(action.id), 'selected', !current);
    //     return setChildrenFieldValueRecursive(state, action.parentIds.concat(action.id), 'partial', false);
    //   });

    //   state = setParentsAbove(state, action.parentIds, action.id);

    //   return state;
    case SET_EXERCISE_TREE_NODES:
      return state.setIn(action.parentIds, action.data);
    default:
      return state;
  }
};
const hasAnyChildSelected = (state, path) => {
  return state.getIn(interleaveParentsWithChildren(path)).some(item => item.get('selected'));
};

const hasAnyChildSelectedOrPartial = (state, path) => {
  return state.getIn(interleaveParentsWithChildren(path)).some(item => item.get('selected') || item.get('partial'));
};

const hasAllChildrenSelected = (state, path) => {
  return state.getIn(interleaveParentsWithChildren(path)).every(item => item.get('selected'));
};

const setSelected = (state, path) => {
  return state.setIn(path.concat('selected'), true)
    .setIn(path.concat('partial'), false);
};

const setPartial = (state, path) => {
  return state.setIn(path.concat('selected'), false)
    .setIn(path.concat('partial'), true);
};

const setInitial = (state, path) => {
  return state.setIn(path.concat('selected'), false)
    .setIn(path.concat('partial'), false);
};

const setActive = (state, path) => {
  return state.setIn(path.concat('active'), true);
};

/**
 * Checks if a certain path inside the state is defined
 * @param state
 * @param path
 * @returns {any | boolean}
 */
const pathExists = (state, path) => {
  const pathToCheck = state.getIn(path);
  return pathToCheck && pathToCheck.size > 0;
};

/**
 * Receives an array of parent ids and returns an interleaved array of the following form:
 * [p0, 'children', p1, 'children', p2, 'children'], where p* is the parentId
 * @param parentIds
 * @returns {Array}
 */
const interleaveParentsWithChildren = (parentIds) => {
  if (!parentIds) return [];
  const childrenArray = new Array(parentIds.length);
  childrenArray.fill('children');

  return parentIds.reduce((accumulator, currentValue, currentIndex) => {
    if (childrenArray[currentIndex])
      return accumulator.concat(currentValue, childrenArray[currentIndex]);
    return accumulator.concat(currentValue);
  }, []);
};

/**
 * Sets a value on a desired field in all nodes that have children
 * @param state
 * @param path
 * @param fieldToSet
 * @param fieldValue
 * @returns {*}
 */
const setChildrenFieldValueRecursive = (state, path, fieldToSet, fieldValue) => {
  let interleavedPath = interleaveParentsWithChildren(path);
  if (!pathExists(state, interleavedPath)) {
    return state;
  }

  let children = state.getIn(interleavedPath);
  children.forEach((child, childKey) => {
    state.setIn(interleavedPath.concat(childKey, fieldToSet), fieldValue);
    setChildrenFieldValueRecursive(state, path.concat(childKey), fieldToSet, fieldValue);
  });

  return state;
};

const setParentsAbove = (state, parentIds) => {
  if (parentIds.length === 0) return state;
  const interleavedPath = interleaveParentsWithChildren(parentIds);

  if (hasAllChildrenSelected(state, parentIds)) {
    state = setSelected(state, interleavedPath.slice(0, -1));
  } else if (hasAnyChildSelectedOrPartial(state, parentIds)) {
    state = setPartial(state, interleavedPath.slice(0, -1));
  } else {
    state = setInitial(state, interleavedPath.slice(0, -1));
  }

  return setParentsAbove(state, parentIds.slice(0, -1));
};

const setParentsAboveActive = (state, parentIds) => {
  if (parentIds.length === 0) return state;
  const interleavedPath = interleaveParentsWithChildren(parentIds);
  state = setActive(state, interleavedPath.slice(0, -1));
  return setParentsAboveActive(state, parentIds.slice(0, -1));
};

const setParentsAboveField = (state, parentIds, field, fieldValue) => {
  if (parentIds.length === 0) return state;
  const interleavedPath = interleaveParentsWithChildren(parentIds);
  state = state.setIn(interleavedPath.slice(0, -1).concat(field), fieldValue);
  return setParentsAboveField(state, parentIds.slice(0, -1), field, fieldValue);
};

const setFlashcardsAsSelected = (tempState, parentIds, flashcards, initialState) => {
  tempState.forEach((stateContent, index) => {
    if (!Map.isMap(stateContent)) return;

    if (stateContent.get('children')) {
      setFlashcardsAsSelected(stateContent.get('children'), parentIds.concat(index), flashcards, initialState);
    } else {
      if (flashcards.find(item => item === index)) {
        const path = interleaveParentsWithChildren(parentIds);
        initialState.setIn(path.concat(index, 'selected'), true);
        setParentsAbove(initialState, parentIds);
      }
    }
  });
};
