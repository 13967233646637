import * as Actions from 'actions';
import FormInput from 'components/FormInputComponent';
import BackButton from 'components/navigation-header/BackButton';
import PublicPageHeader from 'components/PublicPageHeader';
import {CenterSection, CenterSectionWrapper} from 'pages/login/loginComponents';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import Col from 'ui-components/helpers/Col';
import {ClickableDiv} from 'ui-components/helpers/common';
import styled from 'styled-components/macro';
import Padding from 'ui-components/helpers/Padding';
import Text from 'ui-components/helpers/Text';
import {Background, PublicPageBackground} from 'ui-components/pageComponents';
import {validator} from 'utils/form-helper';
import {isValid, reduxForm, submit} from 'redux-form/immutable';
import {ReactComponent as SMPPLogo} from 'assets/images/login-logo.svg';

class ForgotPasswordContainer extends Component {
  render() {
    const {change, touch, handleSubmit} = this.props;
    const canSubmit = this.props.isFormValid;

    return <React.Fragment>
      <PublicPageBackground>
        <Background/>
        <CenterSectionWrapper>
          <Col alignItems={'center'} flexGrow={1} justifyContent={'flex-start'}>
            <SMPPLogo/>
            <CenterSection>
              <PublicPageHeader
                leftSection={() => <BackButton linkTo={'/login'}>Back</BackButton>}
                title={() => <Padding left={10} right={10}>
                  <Text size={25} color={'#2D3838'} weight={700} textAlign={'center'} letterSpacing={0}>Forgot
                    password</Text>
                </Padding>}
                rightSection={() => <ClickableDiv onClick={() => this.props.submitForm(FORGOT_PASSWORD_FORM_NAME)}>
                  <StyledText size={13} weight={700} color={canSubmit ? '#378EF8' : '#C8CCCC'}
                    lineHeight={"18px"}>Send</StyledText></ClickableDiv>}
              />

              <form onSubmit={handleSubmit}>
                <FormInput label="Username" name="username" placeholder="Enter your username" type="text"
                  validate={[validator.required]} change={change} touch={touch}/>
                <button type="submit" hidden/>
              </form>

            </CenterSection>
          </Col>
          <div/>
        </CenterSectionWrapper>
      </PublicPageBackground>
    </React.Fragment>;
  }
}

const FORGOT_PASSWORD_FORM_NAME = 'resend-confirmation';

const mapDispatchToProps = {
  // onSubmit: (data) => Actions.Api.sendForgotPasswordEmail(data),
  submitForm: submit,
};

const mapStateToProps = state => ({
  isFormValid: isValid(FORGOT_PASSWORD_FORM_NAME)(state),
});

const StyledText = styled(Text)`
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  color: #378EF8;
  letter-spacing: 2px;
  @media (pointer: fine) {
    &:hover {
      color: #64A8FA;
    }
  } 
  &:active {
    color: #2170CF;
  }
`;
const ForgotPasswordForm = (reduxForm({form: FORGOT_PASSWORD_FORM_NAME})(ForgotPasswordContainer));
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
