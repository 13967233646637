import {CHANGE_MENU_PATH} from 'actions/menuActions';
import {Map} from 'immutable';

export default (state = Map({
  path: ''
}), action) => {
  switch (action.type) {
    case CHANGE_MENU_PATH:
      return state.set('path', action.path);
    default:
      return state;
  }
};
