import classNames from 'classnames';
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';
import { ForwardChevronIcon } from 'icons/HeaderIcons';
import './previewListHeader.scss'
import { useSubtopicNumber } from 'hooks/useTopicNumber';

type PreviewListHeaderProps = {
  topicNumber: number,
  index: number,
  teachingLevel: string,
  teachingTag: string,
  subTopicId: number,
  onClick: () => void,
  fromPage?: string,
  subTopicTitle: string,
  data: any
}

const PreviewListHeader: React.FC<PreviewListHeaderProps> = ({ fromPage, index, teachingLevel, teachingTag, topicNumber, subTopicTitle, onClick, data }) => {
  const subtopicNumber = useSubtopicNumber(data)
  const computeWith = () => {
    if (teachingTag === "SL&HL" && teachingLevel?.includes('Option')) return '100px';
    if (teachingLevel?.includes('Option') || teachingLevel?.includes('options')) return '144px';
    if (teachingLevel?.includes('Pre-IB')) return '197px';
    if (teachingLevel?.includes('Prescribed')) return '81px';
    return '100%';
  };
  const computeColor = () => {
    if (teachingLevel?.indexOf('SL') > -1) return '#458CFC';
    if (teachingLevel?.indexOf('HL') > -1) return '#1FCD6C';
    return '#458CFC;';
  };
  const computePadding = () => {
    if (teachingLevel?.indexOf('SL') > -1) return '2px 8px';
    if (teachingLevel?.indexOf('HL') > -1) return '2px 6px 2px 7px';
    return '2px 8px;';
  };
  const clickAction = () => {
    if (fromPage !== "subtopic") {
      onClick()
    }
  }
  return <div onClick={clickAction} className={classNames('flex items-center h-8 px-5 py-5px ', { 'cursor-pointer styledPreviewListHeader': fromPage !== "subtopic", 'mt-3.5': index > 0 })} >
    {teachingTag !== null ?
      <TeachingLevelSeperator tlvl={teachingLevel} tlvlTag={teachingTag} isExams={false} slTag={teachingTag === 'SL' ? true : false} /> :
      <div style={{ background: computeColor(), padding: computePadding() }} className='font-normal min-w-[30px] text-white leading-18px text-13px rounded-5px'>{teachingLevel}</div>}
    {topicNumber && <div className='topic-number !px-9px !py-0.5 whitespace-nowrap'>{subtopicNumber}</div>}
    <div style={{ maxWidth: computeWith() }} className='sub-topic-tile' dangerouslySetInnerHTML={{__html:subTopicTitle}}/>
    {fromPage !== "subtopic" && <div className='ml-auto pl-[13px]'>
      <ForwardChevronIcon color={'#BEC4C3'} />
    </div>}
  </div>
}
export default PreviewListHeader;