import {TOGGLE_ONBOARDING_OVERLAY} from 'actions/onboarding/onboardingTypes';
import {fromJS} from 'immutable';
import {FREE_TRIAL_NOTIFICATION_CLOSE, FREE_TRIAL_NOTIFICATION_OPEN} from '../actions/onboarding/onboardingTypes';
import {apiReducer} from './api/apiReducer';
import {applyReducers} from './index';
import {getFreeTrialStatusRoutine} from '../actions/onboarding/onboardingActions';

export default (state = fromJS({
  isOnboardingOverlayVisible: false,
  isFreeTrialNotificationOpen: true
}), action) => {
  switch (action.type) {
    case TOGGLE_ONBOARDING_OVERLAY:
      return state.update('isOnboardingOverlayVisible', val => action.value || !val);
    case FREE_TRIAL_NOTIFICATION_CLOSE:
      return state.set('isFreeTrialNotificationOpen', false);
    case FREE_TRIAL_NOTIFICATION_OPEN:
      return state.set('isFreeTrialNotificationOpen', true);
    default:
      return applyReducers(state, action, {
        [getFreeTrialStatusRoutine]: apiReducer(getFreeTrialStatusRoutine)
      });
  }
};
