import React, { ReactNode, useMemo } from 'react';
import { subtopicsSelector } from 'selectors/api/apiSelector';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { currentFlashcardId } from 'selectors'
import LeftActiveTile from './LeftActiveTile';
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';
import Text from 'v2/components/common/Text';
import { ForwardChevronIcon, ArrowTopicIcon } from 'icons/HeaderIcons';
import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2';
import './TopicTile.scss'
import { useTopicNumber } from 'hooks/useTopicNumber';

type TopicTileProps = {
  topicData: any,
  active: boolean,
  activeTopicId: number | undefined | null,
  activeSubtopicId: number | undefined | null,
  expanded: boolean,
  hasSelection: boolean | undefined | null,
  parentIds: any,
  isExercise: boolean | undefined | null,
  isExernalExercise: boolean | undefined | null,
  onExpand: () => void,
  onSelect: () => void,
  checkMircoFilterFlashcard: boolean,
  absolute: boolean | ReactNode,
  answers: boolean | ReactNode,
  children: ReactNode,
  subject: any
}

const TopicTile: React.FC<TopicTileProps> = ({ topicData, hasSelection, active, absolute, isExercise, onSelect, expanded, isExernalExercise, answers, onExpand, children, subject }) => {
  const NEW_SUBJECT = subject?.tagging === 'NEW_SUBJECT'
  const subtopicsResponse = useSelector(subtopicsSelector)?.toJS();
  const subtopicRequest = subtopicsResponse?.[topicData?.id];
  const hasCurrentFlashcardId = useSelector(currentFlashcardId);
  const topicNumber = useTopicNumber(topicData)

  const subTopicsLoader = useMemo(() => {
    let isLoading = false;
    if (subtopicRequest) isLoading = subtopicRequest?.loading;
    return isLoading;
  }, [subtopicRequest?.loading]);

  const clickToggle = () => {
    if (!isExercise) onSelect()
  }
  const renderHeader = () => {
    let className = '';
    if (expanded) {
      className = 'topics-shadow'
      if (!hasSelection) className += ' selection-shadow-hover'
    }
    if (!expanded) {
      className = 'topics-header-shadow'
      if (!hasSelection) className += ' hasSelection-shadow-hover'
    }
    return className;
  }
  const answerPadding = () => {
    let className = '';
    if (hasSelection) {
      className = 'pt-3  px-3.5'
    } else {
      className = 'pt-0 px-[3.5px]';
    }
    return className
  }

  return <div className={classNames('styled-topic ml-auto', { 'cursor-pointer': !hasSelection, 'tutorial-step-topic': active, '!ml-[27px]': absolute })} onClick={clickToggle}>
    <div className={classNames('tile-header', renderHeader(), { 'bg-#E5ECF2': ((active && !expanded) && hasCurrentFlashcardId) })}>
      {!expanded && absolute}
      {(active && !expanded && !hasSelection) && hasCurrentFlashcardId && <LeftActiveTile isExercise={false} />}
      {(active && !expanded && isExercise && hasSelection) && <LeftActiveTile isExercise={isExercise} />}
      <div className='flex flex-col'>
        <div className='pt-14px px-14px pb-0'>
          <div className='flex justify-between items-center'>
            <div className='flex items-center'>
              <TeachingLevelSeperator tlvl={topicData?.tlvl} tlvlTag={topicData?.teachingTag} isTopic={true} {...(NEW_SUBJECT && {slTag : topicData?.teachingTag === 'SL'})} />
              <div className='topic-number'>{topicNumber}</div>
            </div>
          </div>
          <div className='topic-header-title'>
            <Text className='font-bold text-#2D3838 leading-[34px] text-[25px]'>{topicData?.title}</Text>
          </div>
        </div>
      </div>
      {isExercise && <div className='pt-14px px-14px pb-0'>
        <div className='topic-button' onClick={onSelect}>
          <Text className='text-13px font-bold text-white leading-18px tracking-2px mr-4' >{isExercise ? "VIEW FLASHCARDS " : "STUDY TOPIC"}</Text> <ForwardChevronIcon color={'#FFFFFF'} class={"more-icon"} />
        </div>
        {(isExernalExercise) && <div className='pt-2' />}
      </div>}
      {answers && <div className={classNames(answerPadding(), { 'pb-2': expanded })}> <div onClick={(e) => !isExercise && e.stopPropagation()} >{answers}</div> </div>}
      {subTopicsLoader && <div className='pt-4 pb-3'> <div className='flex items-center justify-center h-9'><Loader size={SizeEnum.Small} background={BackgroundEnum.Blue} /> </div></div>}
      {!expanded &&
        <div className='px-3.5 py-4'>
          <div className='sub-topic-button inactive-topic-tile' onClick={(e) => [e.stopPropagation(), onExpand()]} >
            <ArrowTopicIcon classnames={''} /><Text className='text-13px font-bold leading-18px tracking-2px text-blue-normal'> VIEW {isExercise ? topicData?.totalFCSubTopics : topicData?.totalSubTopics} SUBTOPICS</Text>
          </div>
        </div>}
    </div>
    <div>
      {(expanded && !subTopicsLoader) &&
        <React.Fragment>
          <div className='px-3.5 py-3'>
            <div className='sub-topic-close-button' onClick={(e) => [e.stopPropagation(), onExpand()]}>
              <ArrowTopicIcon classnames={''}/>
              <Text className='text-13px font-bold leading-18px tracking-2px text-blue-normal'>Close {isExercise ? topicData?.totalFCSubTopics : topicData?.totalSubTopics} SUBTOPICS</Text>
            </div>
          </div>
          {children}
          <div className='px-3.5 '>
            <div className='sub-topic-close-button' onClick={(e) => [e.stopPropagation(), onExpand()]} >
              <ArrowTopicIcon classnames={''}/><Text className='text-13px font-bold leading-18px tracking-2px text-blue-normal'>Close {isExercise ? topicData?.totalFCSubTopics : topicData?.totalSubTopics} SUBTOPICS</Text>
            </div>
          </div>
        </React.Fragment>
      }
    </div>
  </div>
}

export default TopicTile