import {toggleNodeSelection} from 'actions/topics-tree/topicsTreeActions';
import React from 'react';
import PropTypes from 'prop-types';
import {isNodePartial, isNodeSelected} from 'selectors/topicsTreeSelector';
import styled from 'styled-components/macro';
import checkboxIcon from 'assets/icons/checkbox.svg';
import Row from 'ui-components/helpers/Row';
import {connect} from 'react-redux';

const Checkbox = ({isActive, isPartial, className}) => {
  let checkbox = <DefaultCheckbox/>;
  if (isActive) {
    checkbox = <ActiveCheckbox>
      <Row justifyContent={'center'} alignItems={'center'} style={{height: '100%'}}>
        <img src={checkboxIcon} alt=""/>
      </Row>
    </ActiveCheckbox>;
  }
  if (isPartial) {
    checkbox = <PartialCheckbox/>;
  }

  return <CheckboxFrame className={className} justifyContent={'center'} alignItems={'center'}>
    {checkbox}
  </CheckboxFrame>;
};

Checkbox.propTypes = {
  isActive: PropTypes.bool,
  isPartial: PropTypes.bool,
  className: PropTypes.string,
};

const CheckboxFrame = styled(Row)`
  width: 34px;
  height: 34px;
  cursor: pointer;
`;
const DefaultCheckbox = styled.div`
  border: 1px solid #C8CCCC;
  width: 18px;
  height: 18px;
  border-radius: 50%;
`;
const PartialCheckbox = styled(DefaultCheckbox)`
  border: 3px solid #51D378;
`;
const ActiveCheckbox = styled(DefaultCheckbox)`
  border: 0;
  background-image: linear-gradient(-133deg, #51D378 0%, #51D4B3 100%);
`;


class CheckboxContainer extends React.Component {
  render() {
    return (
      <div className={this.props.className}
        onClick={(e) => {
          if (this.props.beforeDefaultOnClick) {
            this.props.beforeDefaultOnClick();
          }
          this.props.toggleNodeSelection(this.props.id, this.props.parentIds);
          e.stopPropagation();
        }}>
        <Checkbox isActive={this.props.isNodeSelected} isPartial={this.props.isNodePartial}/>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isNodeSelected: isNodeSelected(state, ownProps.id, ownProps.parentIds),
    isNodePartial: isNodePartial(state, ownProps.id, ownProps.parentIds)
  };
};

const mapDispatchToProps = {
  toggleNodeSelection
};

CheckboxContainer.defaultProps = {
  parentIds: []
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(CheckboxContainer);
