import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AvatarComponent from './AvatarComponent';
import * as Selectors from 'selectors';

class AvatarContainer extends Component {
  render() {
    return <AvatarComponent
      avatar={this.props.avatar || this.props.userAvatar}
      initials={this.props.userInitials}
      className={this.props.className}
      newUserInitials={this.props.newUserInitials}/>;
  }
}

const mapStateToProps = (state) => ({
  userAvatar : Selectors.userAvatar(state),
  userInitials: Selectors.getUserInitials(state)
});
const mapDispatchToProps = {};

AvatarContainer.propTypes = {
  avatar: PropTypes.string,
  newUserInitials: PropTypes.string,
  userAvatar: PropTypes.string,
  userInitials: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(AvatarContainer);
