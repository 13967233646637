import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { currentFlashcardId, getActiveMediaTab, getFlashcardById, getLinkSize, getUserId, isDeviceRestricted, isLinksNavigationSectionVisible, isNavigationSectionVisible } from 'selectors'
import InitialFlashcardSelector from 'v2/components/studying/initial-flashcard-selector/InitialFlashcardSelector';
import {currentFlashcardChangedAction, initializeCarousel} from 'actions/studying';
import ExerciseStartedNotification from 'v2/components/notifications/ExerciseStartedNotification';
import {activeExerciseId, isLandscape, isMobile} from 'selectors';
import {showAlert} from 'actions/notification/notificationActions';
import './studying.scss'
import usePrevious from 'hooks/usePrevious';
import { getFormExams, getLastMediaTypeVisited } from 'helpers';
import { carouselFlashcardIds, carouselFlashcardIndex } from 'selectors/carouselSelectors';
import Studying from 'v2/components/studying/Studying';
import classNames from 'classnames';

const StudyingContainer = () => {
    const disptach = useDispatch()
    const [showExerciseNotification, setExecriseNotoification] = useState(false)
    const [showStudyingLoader, setStudyingLoader] = useState(false);
    const flashcardId = useSelector(currentFlashcardId)
    const execriseId = useSelector(activeExerciseId)
    const hasDeviceRestricted = useSelector(isDeviceRestricted)
    const hasMobile = useSelector(isMobile)
    const hasLandscape = useSelector(isLandscape)
    const userId = useSelector(getUserId)
    const carouselIndex = useSelector(carouselFlashcardIndex)
    const carouselIds = useSelector(carouselFlashcardIds)
    const getFromExamsType = getFormExams()
    const activeMedia = useSelector(getActiveMediaTab)
    const hasNavigationSectionVisible = useSelector(isNavigationSectionVisible)
    const hasLinksNavigationSectionVisible = useSelector(isLinksNavigationSectionVisible)
    const link = useSelector(getLinkSize)
    const currentFlashcard = useSelector((state) => getFlashcardById(state, flashcardId))
    const prevExerciesId = usePrevious(execriseId);
    const prevLandscape = usePrevious(hasLandscape);
    const prevFlashcardId = usePrevious(flashcardId);
    const prevCarouselIndex = usePrevious(carouselIndex);
    const prevGetFromExamsType = usePrevious(getFromExamsType);
    const prevActiveMedia = usePrevious(activeMedia)

    const showExerciseStartedAlert = (callback?: any) => {
        if (hasDeviceRestricted) return;
        disptach(showAlert({
            content: <ExerciseStartedNotification />, buttons: false, timer: 2000
          }, () => {
            setTimeout(() => {
              document.body.classList.remove('swal-no-overlay');  
            }, 300);
            callback?.();
          }, () => {
            document.body.classList.add('swal-no-overlay');
          }))
    }
    useEffect(() => {
        disptach(initializeCarousel(flashcardId))
    }, [])

    useEffect(() => {
        if (execriseId && execriseId !== prevExerciesId) {
            if (!(hasMobile && hasLandscape)) {
                showExerciseStartedAlert()
            } else {
                setExecriseNotoification(true);
            }
        }
        const  mediaType = getLastMediaTypeVisited(userId)
        if (hasLandscape && hasLandscape !== prevLandscape) {
            if (showExerciseNotification) {
              showExerciseStartedAlert(() => {
                showExerciseStartedAlert(false)
              });
            }
        }
        if (flashcardId) {
            if(carouselIndex !== prevCarouselIndex){
                disptach(currentFlashcardChangedAction(carouselIds[carouselIndex]))
            }
        }
        if(flashcardId !== prevFlashcardId || (mediaType === "exams" && (getFromExamsType!== prevGetFromExamsType))){
            disptach(initializeCarousel(flashcardId))
        }
        if ((flashcardId !== prevFlashcardId && !prevFlashcardId) || activeMedia !== prevActiveMedia) {
            setStudyingLoader(true)
            setTimeout(() => {
                setStudyingLoader(false)
            }, 2000)
        }

    }, [execriseId, hasMobile, hasLandscape, flashcardId, carouselIndex, activeMedia])

    const showInitialSelector = !flashcardId && !(hasMobile && hasLandscape);
    return <>
        <div className={classNames(`flashcard-section ${(hasNavigationSectionVisible) ? 'flashcard-section-collapsed' : ''} ${!flashcardId ? 'no-flashcard' : ''} ${(!hasNavigationSectionVisible  &&  flashcardId )? 'flashcard-left-section' : ''}`, {'mobile': hasMobile})}>
            {showInitialSelector ? (
                <div className={'no-flashcard-section'}><InitialFlashcardSelector /></div>
                ) : 
                <Studying
                    flashcardId={flashcardId}
                    flashcardContent={currentFlashcard}
                    isDeviceRestricted={hasDeviceRestricted}
                    isLinksNavigationSectionVisible={hasLinksNavigationSectionVisible}
                    isNavigationSectionVisible={hasNavigationSectionVisible}
                    isLoading={showStudyingLoader}
                    links={link}
                />
            }
        </div>
    </>
}

export default StudyingContainer;