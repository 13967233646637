import React, { useEffect } from 'react';
import RequestLoader from '../loader.v2/RequestLoader';
import ExerciseHeader from 'v2/ui-components/classroom/ExerciseHeader';
import { useDispatch, useSelector } from 'react-redux';
import { apiRequestData, exerciseDetailsResponse } from 'selectors/api/apiSelector';
import { getExerciseDetails } from 'actions/api';
import usePrevious from 'hooks/usePrevious';

type ExerciseHeaderContainerProps = {
  exerciseId: number;
  type?: string;
}

const ExerciseHeaderContainer: React.FC<ExerciseHeaderContainerProps> = ({ exerciseId, type = 'internal' }) => {
  const dispatch = useDispatch();
  const exerciseDetails = useSelector(exerciseDetailsResponse)
  const answers = useSelector(state => apiRequestData(state, 'answers'));
  const prevAnswers = usePrevious(answers);

  useEffect(() => {
    if (!exerciseId) return;
    dispatch(getExerciseDetails({key: exerciseId}))
  }, [dispatch, exerciseId])

  useEffect(() => {
    if (answers !== prevAnswers) {
      dispatch({ id: exerciseId });
    }
  }, [answers, prevAnswers])

  return (
    <RequestLoader loader={<div />} allowUpdate request={exerciseDetails[exerciseId.toString()]} exercise>
      {(data: any) => <ExerciseHeader  data={data} type={type} />}
    </RequestLoader>
  )
}

export default ExerciseHeaderContainer