import {
  ADD_VIEWED_EXTERNAL_EXERCISE, GET_EXERCISE_DATA,
  REMOVE_VIEWED_EXTERNAL_EXERCISE, SET_EXERCISE_DATA,
  SET_EXTERNAL_EXERCISES,
  SET_VIEWED_EXTERNAL_EXERCISES, START_NOTIFICATIONS_POLLING
} from 'actions/exercises/exercisesActionTypes';
import makeAction from 'actions/makeAction';

export const setExternalExercises = makeAction(SET_EXTERNAL_EXERCISES, 'data');
export const setViewedExternalExercises = makeAction(SET_VIEWED_EXTERNAL_EXERCISES, 'data');
export const removeViewedExercise = makeAction(REMOVE_VIEWED_EXTERNAL_EXERCISE);
export const addViewedExercise = makeAction(ADD_VIEWED_EXTERNAL_EXERCISE, 'exerciseId');
export const getExerciseData = makeAction(GET_EXERCISE_DATA, 'exerciseId');
export const setExerciseData = makeAction(SET_EXERCISE_DATA, 'exerciseId', 'data');
export const startNotificationsPolling = makeAction(START_NOTIFICATIONS_POLLING);