import * as Actions from 'actions';
import { updateListOfItemsSelected } from 'actions/desk';
import { setSearchIndex, setPreviousSearchResults } from 'actions/search';
import AnswerBulletContainer from 'v2/components/AnswerBullet/AnswerBulletContainer';
import { preparePreviewString , setFormExams } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import * as Selectors from 'selectors';
import { activeSearchTerm, currentFlashcardId, flashcardSearchResults, isDeskEditModeActive, listOfCheckedItems } from 'selectors';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';
import MarkText from 'ui-components/MarkText';
import PreviewListItem, { CircledNumber } from 'ui-components/search-results/PreviewListItem';
import { PreviewListHeader, RevisionPreviewListItem, VideoPreviewListItem, ExamsPreviewListItem } from 'ui-components/search-results/PreviewListItem';
import {
  addBackFlashcardToLocalStorage, setLastMediaTypeVisited, decodeQuery,storeExamsParentId, setExamSubTopicId, setExamTopicId
} from 'helpers';
import { getUserId } from 'selectors/user';
import appHistory from 'appHistory';
import ExamPreviewListItem from 'v2/pages/desk/exam/ExamPreviewListItem';
import { IncorrectIcon } from 'icons/FlashcardIcons';
import AnswerBullet,{MCQAnswerBullet}  from 'ui-components/previews/AnswerBullet';
import ExamPreviewListHeader from 'v2/ui-components/search-results/ExamPreviewListHeader';

class FlashcardResultsList extends Component {

  onListItemClick = (flashcardId , examType,listItem) => {
    const query = decodeQuery(appHistory.location.search.substring(1));
    // this.props.setMediaFlashcardIds();
    if (this.props.onListItemClick) {
      this.props.onListItemClick(flashcardId);
    } else {

      if (this.props.isDeskEditModeActive) {
        this.props.updateListOfItemsSelected(flashcardId);
      } else {
        if (flashcardId !== this.props.currentFlashcardId) {
          addBackFlashcardToLocalStorage(this.props.activeMediaTab + "-" + flashcardId, query.mediaType + "-" + this.props.currentFlashcardId);
        }
        setLastMediaTypeVisited(this.props.activeMediaTab, this.props.userId);
        if (this.props.getExerciseOnCanvas || typeof this.props.getExerciseOnCanvas == "undefined") {
          this.props.setExerciseOnCanvas(false)
          this.props.setActiveExerciseId(null)
        }

        this.props.selectFlashcard(flashcardId, this.props.shouldMark ? 'search' : 'default');
        // return
        if (this.props.flashcardSearchResults.size > 0) {
          const searchIndex = this.props.flashcardSearchResults.findIndex(item => item.get('flashcardId') === flashcardId);
          this.props.setPreviousSearchResults(this.props.flashcardSearchResults)
          if (searchIndex !== -1) {
            this.props.setSearchIndex(searchIndex);
          }
        }
        if (this.props.activeMediaTab === 'exams') {
          let type 
          let parentId
          if (examType) {
            type = 'topics';
            parentId = listItem.get('topicId')
          }else {
            type = 'exams'
            parentId = listItem.getIn(['experienceData','paper_uuid'])
            !this.props.form && this.props.toggleExamsMode(true)
          }
          this.props.setExamsHeaderList({"type":type,"parentId":parentId})
          setFormExams(type)
          setExamSubTopicId(listItem.get('subTopicId'))
          storeExamsParentId(parentId)
          setExamTopicId(listItem.get('topicId'))
        }
      }
      this.props.onListItemSelected && this.props.onListItemSelected();
    }
  };

  renderPreviewRightSection = (flashcardId, occurences, type, questionType = false) => {
    const { shouldMark } = this.props;
    if (shouldMark) {
      return <Row alignItems={'center'}>
        {occurences > 0 && <CircledNumber>{occurences}</CircledNumber>}
        <Padding right={8} />
          <AnswerBulletContainer id={flashcardId} type={type} isMcq={questionType && questionType.includes('mcq')}/>
      </Row>;
    } else {
      return <React.Fragment>
        <AnswerBulletContainer id={flashcardId} type={type} isMcq={questionType && questionType.includes('mcq')}/>
       </React.Fragment>;
    }
  };
 

  onSubTopicClick = (subTopicId) => {
    appHistory.push(`/flashcard/subtopic/${subTopicId}`);
    //this.props.clearSearch();
    this.props.setActiveFlashcardTab(0)
  }
  
  onPaperClick=(item)=>{
    const paperId = item.getIn(['experienceData','paper_uuid']);
    const examsId = item.getIn(['experienceData','exam_uuid']);
    const paperLable = item.getIn(['experienceData','tag_labels'])?.filter(item => item.get('type')=='paper_type')?.first()?.get('label');
    this.props.setActivePaperTab(paperLable)
    this.props.setActiveFlashcardTab(1)
    // this.props.clearSearch();
    appHistory.push(`/flashcard/desk/exams/${examsId}/${paperId}`);
  }

  render() {
    let { newListItems, activeSearchTerm, currentFlashcardId, shouldMark, isDeskEditModeActive, listOfCheckedItems, activeMediaTab, activeTab, type, fromPage, filterFlashcardId, activeSubjectId, form } = this.props;
    if (newListItems.size === 0) return null;

    return <MarkText shouldMark={shouldMark}>
      {form && activeMediaTab === 'exams' ? <React.Fragment>
        {newListItems.map((value, index) => {
          
          const subTilte = value.first().get('exam') + ' • ' + value.first().get('paper')
          return <div key={`exams-${Math.random()}`}>
            <ExamPreviewListHeader topicNumber={value.first().get('topicNumbering')} index={index}
              teachingLevel={value.first().get('examTeachingLevel')} teachingTag={typeof value.first().get('examTeachingLevel') !== "undefined" ? value.first().get('examTeachingLevel') : null} fromPage={fromPage} subTopicId={value.first().get('subTopicId')}
              subTopicTitle={subTilte}
              onClick={() => this.onPaperClick(value.first())}
            />
            {value.sort((a,b) => (a.get('position').toLowerCase() < b.get('position').toLowerCase()) ? -1 : ((b.get('position').toLowerCase() > a.get('position').toLowerCase()) ? 1 : 0)).map(subItems => {
              // const answerBulletData = {
              //   answered: answer.get('answered'),
              //   correct: answer.get('correct')
              // };
              return (
                <ExamPreviewListItem key={`exam-${Math.random()}`}
                  number={''}
                  active={subItems.get('questionId') === currentFlashcardId}
                  teachingLevel={subItems.get('teachingLevel')}
                  teachingTag={subItems.get('teachingTag')}
                  marks={subItems.get('marks')}
                  calculator={subItems.get('calculator')}
                  flashcardId={subItems.get('questionId')}
                  QuestionType={subItems.get('questionType')}
                  sources={subItems.get('sources')}
                  difficulty={subItems.get('difficulty')}
                  listOfCheckedItems={listOfCheckedItems}
                  isEditing={isDeskEditModeActive}
                  onClick={() => {this.onListItemClick(subItems.get('questionId') ,false, subItems) }}
                  calc={subItems.get('experienceData') && subItems.get('experienceData').get('calc_enabled')}
                  answer={subItems.getIn(['answers','user_response'])}
                  />
              )
            })}
          </div>
        })}
      </React.Fragment>:
    <React.Fragment>
        {newListItems.map((item, index) => {
          const title = activeMediaTab === 'exams' ? item.first().get('subTopicTitle') : item.first().get('subTopicName');
          const teachingLevel = activeMediaTab === 'exams' ? item.first().get('teachingTag') : item.first().get('teachingLevel')
          return <div key={`flashcards-${item.first().get('category')}`}>
            <PreviewListHeader topicNumber={item.first().get('topicNumbering')} index={index}
              teachingLevel={teachingLevel} teachingTag={typeof item.first().get('teachingTag') !== "undefined" ? item.first().get('teachingTag') : null} fromPage={fromPage} subTopicId={item.first().get('subTopicId')}
              subTopicTitle={title}
              onClick={() => this.onSubTopicClick(item.first().get('subTopicId'))}
            />
            {item.map(subItem => {
              let preparedPreview
              if (activeMediaTab === 'flashcard') {

                preparedPreview = getFlashcardPreviewAndSide(subItem.get('preview'), activeSearchTerm, activeTab);
              }
              const flashcardId = subItem.get('flashcardId');
              const occurences = subItem.get('occurences');
              if (activeMediaTab === 'revision') {
                return <RevisionPreviewListItem
                  key={`revision-${subItem.get('nodeId')}`}
                  flashcardId={subItem.get('nodeId')}
                  type={type}
                  onClick={() => this.onListItemClick(subItem.get('nodeId'))}
                  isEditing={isDeskEditModeActive}
                  revisionNumber={subItem.get('numbering')}
                  active={subItem.get('nodeId') === currentFlashcardId}
                  title={subItem.get('title')}
                  shortDescription={subItem.get('shortDescription')}
                  deleted={filterFlashcardId === flashcardId}
                  //shouldMark={true}
                  listOfCheckedItems={listOfCheckedItems}
                  rightSection={this.renderPreviewRightSection(subItem.get('nodeId'), occurences, "revision")}
                />;
              } else if (activeMediaTab === 'video') {
                return <VideoPreviewListItem
                  key={`video-${subItem.get('nodeId')}`}
                  flashcardId={subItem.get('nodeId')}
                  id={subItem.get('nodeId')}
                  type={type}
                  onClick={() => this.onListItemClick(subItem.get('nodeId'))}
                  isEditing={isDeskEditModeActive}
                  videoNumber={subItem.get('numbering')}
                  active={subItem.get('nodeId') === currentFlashcardId}
                  title={subItem.get('title')}
                  shortDescription={subItem.get('shortDescription')}
                  totalDuration={subItem.get('totalDuration')}
                  totalWatched={subItem.get('totalWatched')}
                  totalDurationInSeconds={subItem.get('totalDurationInSeconds')}
                  totalWatchedInSeconds={subItem.get('totalWatchedInSeconds')}
                  deleted={filterFlashcardId === flashcardId}
                  //shouldMark={true}
                  thumbImage={subItem.get('thumbImage')}
                  listOfCheckedItems={listOfCheckedItems}
                  rightSection={this.renderPreviewRightSection(subItem.get('nodeId'), occurences, "video")}
                />;
              } else if (activeMediaTab === 'exams') {
                const answer= subItem.getIn(['answers','user_response'])
                return <ExamsPreviewListItem key={`exam-${Math.random()}`}
                  id={subItem.get('questionId')}
                  flashcardId={subItem.get('questionId')}
                  isEditing={isDeskEditModeActive}
                  // revisionNumber={subItem.get('nodeId')}
                  onClick={() => {this.onListItemClick(subItem.get('questionId'), subItem.get('sources') == 'osc_dp_mocks' ? false :  true,subItem) }}
                  listOfCheckedItems={listOfCheckedItems}
                  flashcardNumber={subItem.get('numbering')}
                  title={subItem.get('questionTitle')}
                  paper={subItem.get('paper')}
                  teachingTag={subItem.get('questionTeachingTag')}
                  teachingLevel={subItem.get('questionTeachingTag')}
                  active={subItem.get('questionId') === currentFlashcardId}
                  marks={subItem.get('marks')}
                  rightSection={this.renderPreviewRightSection(subItem.get('questionId'),  occurences, 'exams', subItem.get('questionType'))}
                  sources={subItem.get('sources')}
                  QuestionType={subItem.get('questionType')}
                  difficulty={subItem.get('difficulty')}
                  occurences= {occurences}
                  calc={subItem.get('experienceData') && subItem.get('experienceData').get('calc_enabled')}
                  // calc={true}               
                />

              } else {
                return <PreviewListItem
                  key={`flashcard-${flashcardId}`}
                  listOfCheckedItems={listOfCheckedItems}
                  flashcardId={flashcardId}
                  onClick={() => this.onListItemClick(flashcardId, preparedPreview.side)}
                  isEditing={isDeskEditModeActive}
                  flashcardNumber={subItem.get('numbering')}
                  previewTitle={subItem.get('title')}
                  active={flashcardId === currentFlashcardId}
                  preview={preparedPreview.text}
                  deleted={subItem.get('deleted') || (filterFlashcardId === flashcardId)}
                  rightSection={this.renderPreviewRightSection(flashcardId, occurences, "flashcard")}
                  activeMath={activeSubjectId === 15075 || activeSubjectId === 14982}

                />;
              }
            })}
          </div>;
        })}
      </React.Fragment>}
    </MarkText>;
  }
}

FlashcardResultsList.propTypes = {
  newListItems: PropTypes.any,
  activeSearchTerm: PropTypes.any,
  currentFlashcardId: PropTypes.any,
  form: PropTypes.string
};

FlashcardResultsList.defaultProps = {
  shouldMark: false
};

export default connect(state => ({
  activeSearchTerm: activeSearchTerm(state),
  currentFlashcardId: currentFlashcardId(state),
  flashcardSearchResults: flashcardSearchResults(state),
  isDeskEditModeActive: isDeskEditModeActive(state),
  listOfCheckedItems: listOfCheckedItems(state),
  userId: getUserId(state),
  activeTab: Selectors.getActiveFlashcardTab(state),
  activeMediaTab: Selectors.getActiveMediaTab(state),
  getExerciseOnCanvas: Selectors.getExerciseOnCanvas(state),
  activeSubjectId: Selectors.activeSubjectId(state),
}), {
  selectFlashcard: Actions.Studying.selectFlashcard,
  setSearchIndex: setSearchIndex,
  updateListOfItemsSelected: updateListOfItemsSelected,
  setMediaFlashcardIds: Actions.Navigation.setMediaFlashcardIds,
  setExerciseOnCanvas: Actions.Navigation.setExerciseOnCanvas,
  setActiveFlashcardTab: Actions.Navigation.setActiveFlashcardTab,
  setPreviousSearchResults,
  clearSearch: Actions.Search.clearSearch,
  setActiveExerciseId: Actions.Studying.setActiveExerciseId,
  setExamsHeaderList:Actions.Navigation.setExamsHeaderList,
  setActivePaperTab:Actions.Navigation.setActivePaperTab,
  toggleExamsMode: Actions.Navigation.toggleExamsMode,
})(FlashcardResultsList);


function getFlashcardPreviewAndSide(preview, activeSearchTerm, activeTab) {
  if (!preview) {
    return {
      text: "",
      side: 0
    }
  }
  let regex = new RegExp(activeSearchTerm, 'gi');
  const textContent = preview.get('textContent');
  const svgContent = preview.get('svgContent');
  const sides = Math.max(textContent.size, svgContent.size);

  let returnObj = {
    text: textContent.join(' '),
    side: 0
  };
  for (let i = 0; i < sides; i++) {
    let textForCurrentIndex = textContent.get(i);
    let svgForCurrentIndex = svgContent.get(i);
    if (activeSearchTerm != null) {
      if (activeSearchTerm.indexOf("’") !== -1) {
        activeSearchTerm = activeSearchTerm.replace("’", "'");
        regex = new RegExp(activeSearchTerm, 'gi');
        textForCurrentIndex = textForCurrentIndex.replaceAll("’", "'");
        svgForCurrentIndex = svgForCurrentIndex.replaceAll("’", "'");
      }
      if (activeSearchTerm.indexOf("‘") !== -1) {
        activeSearchTerm = activeSearchTerm.replace("‘", "’");
        regex = new RegExp(activeSearchTerm, 'gi');
        textForCurrentIndex = textForCurrentIndex.replaceAll("‘", "’");
        svgForCurrentIndex = svgForCurrentIndex.replaceAll("‘", "’");
      }
      if (activeSearchTerm.indexOf("'") !== -1) {
        activeSearchTerm = activeSearchTerm.replace("'", "’");
        regex = new RegExp(activeSearchTerm, 'gi');
        textForCurrentIndex = textForCurrentIndex.replaceAll("'", "’");
        svgForCurrentIndex = svgForCurrentIndex.replaceAll("'", "’");
      }
    }
    if (activeSearchTerm != null && activeTab == 4) {
      if (textForCurrentIndex && textForCurrentIndex.search(regex) !== -1) {
        returnObj.text = preparePreviewString(textForCurrentIndex, activeSearchTerm).text;
        returnObj.side = i;
        return returnObj;
      } else if (svgForCurrentIndex && svgForCurrentIndex.search(activeSearchTerm) !== -1) {
        returnObj.text = preparePreviewString(svgForCurrentIndex, activeSearchTerm).text;
        returnObj.side = i;
        return returnObj;
      }
    } else {
      if (textForCurrentIndex) {
        returnObj.text = preparePreviewString(textForCurrentIndex, activeSearchTerm).text;
        returnObj.side = i;
        return returnObj;
      } else if (svgForCurrentIndex) {
        returnObj.text = preparePreviewString(svgForCurrentIndex, activeSearchTerm).text;
        returnObj.side = i;
        return returnObj;
      }
    }

  }

  return returnObj;
}


