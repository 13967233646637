import { useEffect, useRef } from 'react';
import './foldersList.scss';
import { useDispatch, useSelector } from 'react-redux';
import FreeTrialNotification from 'v2/components/free-trial-notification/FreeTrialNotification';
import DeskNavigationHeader from 'v2/components/navigation-header/DeskNavigationHeader';
import FoldersListContainer from './FoldersListContainer';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import { isRequestInProgress } from 'selectors/api/apiSelector';
import { isMobile, isLandscape } from 'selectors/browser/browserSelector';
import { folders } from 'selectors/desk/deskSelector';
import { scrollPosition } from 'selectors/navigation/navigationSelector';
import { getUserId } from 'selectors/user';
import { currentFlashcardId } from 'selectors/studying';
import { activeSubjectId, subjectsTeachingLevel } from 'selectors/subject/subjectSelector';
import FooterEditModeContainer from 'v2/components/footer-edit-mode/FooterEditModeContainer';
import { deleteFolders, selectAllFolders, getFolders, getFoldersForFlashcard } from 'actions/desk/deskActions';
import { setDocumentTitle, saveScrollPosition, setActiveFlashcardTab } from 'actions/navigation/navigationActions';
import { clearApiRequestData } from 'actions/api/apiActions';
import { getLastMediaTypeVisited } from 'helpers';
import useScrollDetection from 'hooks/useScrollDetection';
import usePrevious from 'hooks/usePrevious';
import debounce from 'lodash.debounce';

const FoldersPage = () => {
  const dispatch = useDispatch();
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);
  const hasFoldersInProgress = useSelector(state => isRequestInProgress(state, 'desk', 'getFolders'));
  const foldersData = useSelector(folders).toJS();
  const userId = useSelector(getUserId);
  const getScrollPosition = useSelector(scrollPosition).toJS();
  const getCurrentFlashcardId = useSelector(currentFlashcardId);
  const getActiveSubjectId = useSelector(activeSubjectId);
  const subjectsTeachingLevels = useSelector(subjectsTeachingLevel)
  const teachingLvl = subjectsTeachingLevels[getActiveSubjectId]
  const prevTeachingLvl = usePrevious(teachingLvl)
  const prevSubjectId = usePrevious(getActiveSubjectId);
  const prevFlashcardId = usePrevious(getCurrentFlashcardId);
  const isMobileLandscape = hasMobile && hasLandscape;
  const mediaType = getLastMediaTypeVisited(userId);
  let wrapperRef = useRef<HTMLDivElement>(null);
  const { isScrolled, bindScrollRef } = useScrollDetection({ WrappedRef: wrapperRef, threshold1: 20 });

  useEffect(() => {
    dispatch(setActiveFlashcardTab(3));
    dispatch(setDocumentTitle('Folders'));
    if (foldersData.data?.items?.length === 0 || Object.keys(foldersData)?.length === 0) {
      dispatch(getFolders());
    }
    if(wrapperRef.current) {
      bindScrollRef(wrapperRef.current)
      wrapperRef.current.scrollTop = getScrollPosition.folders;
      wrapperRef.current.addEventListener('scroll', handleScroll)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {        
    if((prevSubjectId && getActiveSubjectId !== prevSubjectId) || (teachingLvl !== prevTeachingLvl)) {
      if(wrapperRef.current){
        wrapperRef.current.scrollTop = 0;
      }
      dispatch(getFolders());
    }
  },[dispatch, getActiveSubjectId, prevSubjectId, prevTeachingLvl, teachingLvl])

  useEffect(() => {
    if(getCurrentFlashcardId) {
      dispatch(getFoldersForFlashcard(getCurrentFlashcardId,mediaType));
    }
    else{
      dispatch(clearApiRequestData('flashcardFolders'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[getCurrentFlashcardId, prevFlashcardId])

  const handleScroll = debounce((event: any) => {
    const scrollTop = event.target.scrollTop;
    dispatch(saveScrollPosition("folders", scrollTop));
  }, 300);

  return (
    <div className='h-full'>
      <DeskNavigationHeader 
        isCollapsed={isScrolled} 
        tabTitle={'Folders'} 
        canEdit={foldersData?.data?.items?.length > 0} 
        extendedHeight 
        isScrolled2={isScrolled} 
        rightButton={foldersData?.data?.items?.length === 0 ? <RightButton /> : null} 
        showButton={!isMobileLandscape} 
        showTechingLevel={false} 
      />
      <div className='pt-14 pb-[150px] h-full overflow-auto' ref={wrapperRef}>
        {!isMobileLandscape && <>
          <FreeTrialNotification />
          <div className='pt-1 pb-2 px-5 font-bold text-33px leading-45px text-#2D3838'>Folders</div>
        </>}

        { hasFoldersInProgress ? <LeftMenuLoader /> : foldersData && <FoldersListContainer /> }
      </div>
      <FooterEditModeContainer 
        onDelete={()=>deleteFolders()} 
        itemsCount={foldersData?.data?.items?.length} 
        onSelectAll={()=>dispatch(selectAllFolders())} 
      />
    </div>
  )
}

export default FoldersPage

const RightButton = () => <div className='font-bold text-13px leading-18px text-right tracking-[2px] uppercase text-#C8CCCC pointer-events-none'>EDIT</div>