import {ConfidentIcon, UnconfidentIcon, NeitherIcon} from 'icons/FlashcardIcons';

export const  titlesMicro = {
  'flashcard': 'Confidence',
  'revision': 'Reading status',
  'video': 'Watch status',
  'exams':'Confidence'
}

export const heading = {
  'flashcard': 'All confidence levels',
  'revision': 'All reading status',
  'video': 'All watch status',
  'exams': 'All confidence levels',
}
export  const filters = {
  flashcard:[
    {text : 'Not yet assessed', icon : ''},,
    {text : 'Unconfident', icon:<UnconfidentIcon />},,
    {text : 'Neither', icon: <NeitherIcon />},
    {text : 'Confident', icon: <ConfidentIcon/>}
   ],
   revision:[
     {text : 'Not yet read', icon : ''},
     {text : 'Read', icon : ''}
   ],
   video:[
     {text : 'Not yet watched', icon : ''},
     {text : 'Partly-watched', icon : ''},
     {text : 'Watched', icon : ''}
   ],
   exams:[
     {text : 'Not yet assessed', icon : ''},
     {text : 'Unconfident', icon:<UnconfidentIcon />},
     {text : 'Neither', icon: <NeitherIcon />},
     {text : 'Confident', icon: <ConfidentIcon/>}
   ]
 
 }