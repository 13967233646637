import * as PropTypes from 'prop-types';
import styled, {css} from 'styled-components/macro';

const Row = styled.div`
  ${props => props.reverse && `
    flex-direction: row-reverse;
  `};
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems || props.verticalAlign ? 'center' : ''};
  ${props => props.shouldWrap && 'flex-wrap: wrap;'}
  ${({alignSelf}) => alignSelf && css`
    align-self: ${alignSelf};
  `}
`;

Row.propTypes = {
  verticalAlign: PropTypes.bool,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  reverse: PropTypes.bool,
  shouldWrap: PropTypes.bool,
};

export default Row;

export const PullRight = styled.div`
  margin-left: auto;
`;
