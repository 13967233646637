import styled from 'styled-components/macro';

const Avatar = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;

  background: ${props => props.alternate ? '#378EF8' : props.initialFlashcard ? '#378EF8' : '#EFF4F9'};
  
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.alternate ? '#EFF4F9' : props.initialFlashcard ? '#EFF4F9' : '#378EF8'};
  font-weight: 700;
  font-size: ${props => Math.floor(props.size * 0.5)}px;
  
  ${props => props.url && `
      background: url('${props.url}');
  `};
  
  ${props => props.accountPortal && `
  background-image: linear-gradient(to bottom, #859bbb, #5b6c86);
  color: #FFFFFE;
  font-size:12px;
  line-height: 1.428571429;
  `}
  background-size: 100%;
  background-repeat: no-repeat;
`;

export default Avatar;
