import React from 'react';
import classNames from 'classnames';

type MediaTabProps = {
    showBar?: boolean;
    isActionTab: boolean;
    onClick: () => void;
    icon: string;
    name: string;
} 
const MediaTab: React.FC<MediaTabProps> = ({showBar, isActionTab, onClick, icon, name}) => {
    return <div className={classNames('media-tab-section', {'active-tab' : (showBar && isActionTab)}, {'pointer-none' : (!showBar && isActionTab)})} onClick={onClick}>
        <img src={icon} alt="" />
        <div className={classNames('flex items-center justify-center mt-1 text-white font-bold text-13px leading-18px', {'opacity-100' : isActionTab}, {'opacity-70' : !isActionTab})}>{name}</div>
    </div>
}

export default MediaTab;