import {createExerciseRoutine} from 'actions/api';
import {
  getRecentSearches,
  getSubtopicsInTopic,
  getPreviewsListByType,
  getExamPreviewsListByType,
  search,
  searchFlashcards,
  searchFolders,
  searchNotes,
  getFlashcardIdsRoutine,
  getExternalExercisesRoutine,
  // getExternalAvatarRoutine,
  getExerciseDetailsRoutine,
  getTopicsDetails,
  getExercisePreviewsRoutine,
  getClassContentRoutine,
  getExerciseAnswersRoutine,
  getAllTopicsInSubject,
  searchExercisesRoutine,
  addMarkAsReadRoutine,
  getMBLastSyncRoutine, addFlashcardToFolderRoutine, removeFlashcardFromFolderRoutine, getFlashcardAnswersRoutine,
  addFlashcardToBookmarkRoutine, removeFlashcardFromBookmarkRoutine,
  getCardsTitleByType,
  getFirstQuestionIdBySubject
} from 'actions/api/apiActions';
import Immutable from 'immutable';
import {requestSelector} from 'redux-saga-request';

// export const getErrorInApiRequest = (state, request) => state.getIn(['api', request, 'error'], null);
export const getErrorInApiRequest = (state, request) => state.getIn(['api', request])?.error || null
export const isApiRequestInProgress = (state, request) => state.getIn(['api', request, 'inProgress'], false);
export const isRequestInProgress = (state, section, request) => state.getIn([section, request, 'inProgress'], false);
export const hasMore = (state, section, request) => state.getIn([section, request, 'hasMore']);
export const apiRequestData = (state, request) => state.getIn(['api', request, 'data'], Immutable.List());
//export const getSubjects = (state) => state.getIn(['api', 'subjects', 'data'], Immutable.List());
export const getSubjects = (state) => {
  const subjects = state.getIn(['api', 'subjects'], []);
  return subjects.data || []
};
export const getTopicsRequest = state => state.getIn(['api', 'topics', 'data'], Immutable.Map());

export const flashcardContent = (state) => state.getIn(['api', 'flashcardContent', 'data'], Immutable.Map());

// export const getStoreSubjects = (state) => state.getIn(['api', 'storeSubjects', 'data'], Immutable.List());
// export const getStoreSubjectPackages = (state) => state.getIn(['api', 'storeSubjectPackages', 'data'], Immutable.List());
// export const getStudentPackages = (state) => state.getIn(['api', 'studentPackages', 'data'], Immutable.List());
export const getPurchase = (state) => state.getIn(['api', 'purchase', 'data'], Immutable.Map());

export const trialInfo = (state) => state.getIn(['api', 'trialInfo', 'data'], Immutable.Map());
export const recentSearches = (state) => requestSelector(state, getRecentSearches);
export const searchRequest = (state) => requestSelector(state, search);

export const flashcardsSearchResponse = (state) => requestSelector(state, searchFlashcards);
export const exercisesSearchResponse = (state) => requestSelector(state, searchExercisesRoutine);
export const notesSearchResponse = (state) => requestSelector(state, searchNotes);
export const foldersSearchResponse = (state) => requestSelector(state, searchFolders);
export const flashcardIdsResponse = (state) => requestSelector(state, getFlashcardIdsRoutine);

export const searchResponse = (state) => {
  const flashcards = flashcardsSearchResponse(state);
  const notes = Immutable.fromJS(notesSearchResponse(state));
  const folders = foldersSearchResponse(state);
  const exercises = exercisesSearchResponse(state);

  let responses = [];

  flashcards.size > 0 && responses.push(flashcards.get('loading'));
  notes.size > 0 && responses.push(notes.get('loading'));
  folders.size > 0 && responses.push(folders.get('loading'));
  exercises.size > 0 && responses.push(exercises.get('loading'));

  const loading = responses.some(el => el);
  const flashcardResultsCount = (flashcards.get('data') && flashcards.get('data').size) || 0;
  // const notesResultsCount = (notes.get('data') && notes.get('data').size) || 0;
  const notesResultsCount = (notes?.data && notes?.data.length) || 0;
  const foldersResultsCount = (folders.get('data') && folders.get('data').size) || 0;
  const exerciseResultsCount = (exercises.get('data') && exercises.get('data').size) || 0;

  const results = flashcardResultsCount + notesResultsCount + foldersResultsCount + exerciseResultsCount;

  return Immutable.fromJS({
    loading: loading || false, results
  });
};
export const subtopicsSelector = state => requestSelector(state, getSubtopicsInTopic);
export const previewsListbyType = state => requestSelector(state, getPreviewsListByType);
// From Topic by Exam
export const examPreviewsListbyType = state => requestSelector(state, getExamPreviewsListByType);
export const getSubtopicByTopicId = (state, topicId) => {
  return state.getIn(['api', getFlashcardIdsRoutine.toString(), 'data', 'items']).find(item => item.get('topicId') === topicId);
};
export const getExerciseByTopicID = (state, topicId) => {
  return state.getIn(['api', getFlashcardIdsRoutine.toString(), 'data', 'items']).filter(item => item.get('topicId') === topicId);
};

export const getSubtopicById = (state, id) => {
  return state.getIn(['api', getFlashcardIdsRoutine.toString(), 'data', 'items']).find(item => item.get('id') === id);
};

export const classContentResponse = (state) => requestSelector(state, getClassContentRoutine);

export const externalExercisesResponse = state => requestSelector(state, getExternalExercisesRoutine);
// export const getExternalAvatarResponse = state => requestSelector(state, getExternalAvatarRoutine);
export const exercisePreviewsResponse = state => requestSelector(state, getExercisePreviewsRoutine);
export const exerciseAnswersResponse = (state, id) => requestSelector(state, getExerciseAnswersRoutine, id);
export const allTopicsInSubjectResponse = state => requestSelector(state, getAllTopicsInSubject);
export const mbLastSyncResponse = state => requestSelector(state, getMBLastSyncRoutine);
export const addFlashcardToFolderResponse = state => requestSelector(state, addFlashcardToFolderRoutine);
export const removeFlashcardFromFolderResponse = state => requestSelector(state, removeFlashcardFromFolderRoutine);
export const getFlashcardAnswersResponse = state => requestSelector(state, getFlashcardAnswersRoutine);
export const createExerciseFormResponse = state => requestSelector(state, createExerciseRoutine);
export const flashcardFolders = (state) => state.getIn(['api', 'flashcardFolders', 'data'], Immutable.List());
//Device List
export const getLoggedinDevicesList = (state) => state.getIn(['api', 'devices']).data || [];

//export const getFlashcardLinks = (state) => state.getIn(['api', 'links', 'data'], Immutable.List());

export const topicsDetailsSelector = state => requestSelector(state, getTopicsDetails);
export const addMarkAsReadResponse = state => requestSelector(state, addMarkAsReadRoutine);
export const flashcardBookmarks = (state) => state.getIn(['api', 'flashcardBookmarks', 'data'], Immutable.List());

export const addFlashcardToBookmarkResponse = state => requestSelector(state, addFlashcardToBookmarkRoutine);
export const removeFlashcardFromBookmarkResponse = state => requestSelector(state, removeFlashcardFromBookmarkRoutine);

export const cardsTitleListbyType = state => requestSelector(state, getCardsTitleByType);

export const firstQuestionIdBySubject = state => requestSelector(state, getFirstQuestionIdBySubject);

// export const getFeature = (state) => state.getIn(['api', 'feature', 'data'], Immutable.List());
// export const examListResponse = (state) =>state.getIn(['api', 'exams', 'succeeded'], null)
// export const questionsCount =(state) => state.getIn(['api','examPreview','data','data','header','totalCount']);
// export const getExamHeader = (state) => state.getIn(['api','examHeader','data']);
export const allTopicsInProgress = (state, subjectId) => state.getIn(['api', 'allTopics', subjectId, 'loading'], Immutable.List());

export const getExamPreviewListByTopic = (state) => state.getIn(['api','examPreviewListByTopicId']);
export const getTopicIdByTeachingLevel = (state) => state.getIn(['api','topicIdsByTeachingLevel']); 
export const getExamFilterDataBySubject = (state) => state.getIn(['api', 'examFilterDataBySubject', 'data'], Immutable.List());
export const getExamFilterLoadingBySubject = (state) => state.getIn(['api', 'examFilterDataBySubject', 'inProgress'],false);



// REFECTORING
export const isApiRequestInProgressV2 = (state, request) => state.getIn(['api', request])?.inProgress || false;


export const examListResponse = (state) => state.getIn(['api', 'exams'], null)?.succeeded;
export const examsResponse = (state) => state.getIn(['api', 'exams'], null)?.data;
export const questionsCount =(state) => state.getIn(['api','examPreview'])?.data?.data?.header?.totalCount || 0;
export const examPreview =(state) => state.getIn(['api','examPreview'])?.data || [];
export const getExamHeader = (state) => state.getIn(['api','examHeader'])?.data || {};
export const getStudentPackages = (state) => state.getIn(['api', 'studentPackages'])?.data || [];

export const getFeature = (state) => state.getIn(['api', 'feature'])?.data;

export const progressPopupOverlayNextData = (state) => state.getIn(['api', 'progressPopupOverlayNext'])?.data || [];
export const progressPopupOverlayPrevious = (state) => state.getIn(['api', 'progressPopupOverlayPrevious'])?.data || [];

export const getExternalAvatarResponse = state => state.getIn(['api', 'getExternalAvatar']);

export const internalExercisesResponse = state => state.getIn(['api', 'internalExercises']);

export const exerciseDetailsResponse = state => state.getIn(['api', 'exerciseDetails'])