import { useEffect } from 'react';
import { setActiveFlashcardTab, setDocumentTitle } from 'actions/navigation';
import { getRecentSearches, search, getAllTopicsInSubject, updateRecentSearches } from 'actions/api'
import { clearSearch } from 'actions/search'
import { useDispatch, useSelector } from 'react-redux'
import { recentSearches, searchResponse, activeSearchTab, activeSearchTerm, isSearchFieldFocused, getActiveSubject, teachingLevelsForActiveSubject, getTopicIdByTeachingLevel, getActiveMediaTab } from 'selectors';
import { searchFieldValue } from 'selectors/searchSelectors';
import SearchPageComponent from 'v2/pages/search/SearchPageComponent';
import './searchPage.scss'
import usePrevious from 'hooks/usePrevious';

const SearchPageContainer = () => {
    const dispatch = useDispatch();
    const activeSubject = useSelector(getActiveSubject);
    const hasSearchTerm = useSelector(activeSearchTerm);
    const hasTopicIdByTeachingLevel = useSelector(getTopicIdByTeachingLevel);
    const hasSearchFieldValue = useSelector(searchFieldValue);
    const recentSearchesRequest = useSelector(recentSearches).toJS();
    const hasSearchResponse = useSelector(searchResponse).toJS();
    const teachingLevelsActiveSubject = useSelector(teachingLevelsForActiveSubject);
    const activeMediaTab = useSelector(getActiveMediaTab);
    const hasSearchFieldFocused = useSelector(isSearchFieldFocused);
    const hasActiveSearchTab = useSelector(activeSearchTab)
    const prevSubject = usePrevious(activeSubject)

    useEffect(() => {
        dispatch(setActiveFlashcardTab(5))
        dispatch(setDocumentTitle('Search'));
        dispatch(getRecentSearches(activeSubject.id));
        hasSearchTerm && dispatch(search({ 'searchField': hasSearchTerm }))
        if (typeof hasTopicIdByTeachingLevel === 'undefined') {
            dispatch(getAllTopicsInSubject({
                key: activeSubject?.id,
                subjectId: activeSubject?.id,
                mapAnswers: true
            }));
        }
    }, [])

    useEffect(() => {
        if(prevSubject.id !== activeSubject.id) {
            dispatch(clearSearch())
            dispatch(getRecentSearches(activeSubject.id))
        }
    },[activeSubject?.id, prevSubject?.id, dispatch])

    const clearRecentSearches = () => {
        dispatch(updateRecentSearches({ subjectId: activeSubject.id, searchedItems: [] }));
    };
    const onSearch = (val: any) => {
        dispatch(search(val))
    }
    return <SearchPageComponent
        searchFieldValue={hasSearchFieldValue}
        clearRecentSearches={clearRecentSearches}
        search={onSearch}
        recentSearches={recentSearchesRequest.data || []}
        activeSearchTab={hasActiveSearchTab}
        isLoading={hasSearchResponse.loading}
        isRecentSearchesLoading={recentSearchesRequest.loading}
        teachingLevelsForActiveSubject={teachingLevelsActiveSubject}
        showResults={hasSearchTerm}
        results={hasSearchResponse.results}
        showOverlay={hasSearchFieldFocused}
        clearSearch={() => dispatch(clearSearch())}
        activeSearchTerm={hasSearchTerm}
        activeSubject={activeSubject}
        updateRecentSearches={(val: any) => dispatch(updateRecentSearches(val))}
        activeMediaTab={activeMediaTab}
    />;
}

export default SearchPageContainer;