import usePrevious from 'hooks/usePrevious';
import { useState, useEffect } from 'react';
import { flashcardsSearchResponse, getActiveMediaTab, listOfFilterCheckedItems, flashcardSearchResults, searchIndex } from 'selectors';
import { useSelector, } from 'react-redux';
import { filterResultsListToArray } from 'v2/helpers';


const useSearchIndex = <T>(callBack?: () => void): { findSearchIndex: any, state: boolean, nextSearch: any, prevSearch: any } => {
  const [state, setState] = useState(false);
  const [nextSearch, setNextSearch] = useState([]);
  const [prevSearch, setPrevSearch] = useState([]);
  const prevState = usePrevious(state)
  const flashcards = useSelector(flashcardsSearchResponse);
  const activeMediaTab = useSelector(getActiveMediaTab);
  const hasFilterCheckedItems = useSelector(listOfFilterCheckedItems);
  const searchResults = useSelector(flashcardSearchResults);
  const hasSearchIndex = useSelector(searchIndex)
  const findSearchIndex = () => {
    let indexValues: Array<any> = [];
    let filterFlashcardIds: Array<any> = [];
    let microFilterData;
    if(hasFilterCheckedItems.toJS()[activeMediaTab].length > 0) {
      microFilterData = flashcards.get('data') && filterResultsListToArray(flashcards.get('data'), activeMediaTab, hasFilterCheckedItems.toJS()[activeMediaTab]);
      microFilterData && microFilterData.map((data: any) => {
        filterFlashcardIds = filterFlashcardIds.concat(data.get('flashcardId'))
      })
    }
    else {
      microFilterData = searchResults && filterResultsListToArray(searchResults, activeMediaTab, hasFilterCheckedItems.toJS()[activeMediaTab]);
      microFilterData && microFilterData.map((data: any) => {
        filterFlashcardIds = filterFlashcardIds.concat(data?.flashcardId)
      })
    }    
    searchResults.map((data: any, index: number) => {
      if (filterFlashcardIds.length > 0 && filterFlashcardIds.includes(data.flashcardId)) {
        indexValues = indexValues.concat(index)
      }
    })
    let finalState: any = {};
    let prevSearchIndex: any = indexValues.filter((val) => {
      if (hasSearchIndex > val) {
        return true
      }
    })
    setPrevSearch(prevSearchIndex)
    finalState.prevSearchIndex = prevSearchIndex;
    let nextSearchIndex: any = indexValues.filter((val) => {
      if (hasSearchIndex < val) {
        return true;
      }
    })
    finalState.nextSearchIndex = nextSearchIndex;
    setNextSearch(nextSearchIndex);
    setState(finalState)
  }
  useEffect(() => {
    if (prevState !== state) {
      callBack?.()
    }
  }, [state, prevState, callBack])
  return { findSearchIndex, state, nextSearch, prevSearch };
}

export default useSearchIndex; 