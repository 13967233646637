import React from 'react';
import AnswerBullet, { MarkRightValue, MCQAnswerBullet } from './AnswerBullet';
import { markById, watchedById, answerById, examAnswerById } from 'selectors/answersSelectors';
import { useSelector } from 'react-redux';

type AnswerBulletContainerProps = {
  id: number | string,
  type: string,
  isMcq?: boolean
}
const AnswerBulletContainer: React.FC<AnswerBulletContainerProps> = ({ id, type, isMcq }) => {
  const answer = useSelector((state) => answerById(state, id))?.toJS();
  const mark = useSelector((state) => markById(state, id))?.toJS();
  const watched = useSelector((state) => watchedById(state, id))?.toJS();
  const examAnswers = useSelector((state) => examAnswerById(state, id))?.toJS();
  if (!answer && !mark && !watched && !examAnswers) return null;
  if (answer && type === "flashcard") {
    const answerBulletData = {
      answered: answer.answered,
      correct: answer.correct
    };
    return <AnswerBullet {...answerBulletData} />;
  }
  if (mark && type === "revision") {
    const MarkReadBulletData = {
      markValue: mark.markValue,
    };
    return <MarkRightValue {...MarkReadBulletData} />;
  }
  if (watched && type === "video") {
    const MarkReadBulletData = {
      markValue: watched.watchedValue,
    };
    return <MarkRightValue {...MarkReadBulletData} />;
  }
  if (examAnswers || type === "exams") {

    const answerBulletData = {
      answered: examAnswers.answered,
      correct: examAnswers.correct
    };
    if (isMcq) {
      return <MCQAnswerBullet {...answerBulletData} />
    } else {
      return <AnswerBullet {...answerBulletData} />;
    }
  }
  return null;
}
export default AnswerBulletContainer;