import * as Util from './Util';

export const registerUser = (data) => {
  return Util.post('/fca/students', data);
};

export const authenticate = (data) => {
  // data = data
  //   .set('client_id', process.env.REACT_APP_CLIENT_ID)
  //   .set('client_secret', process.env.REACT_APP_CLIENT_SECRET)
  //   .set('grant_type', 'password');

  return Util.post('/oauth/v2/token', {...data, client_id: process.env.REACT_APP_CLIENT_ID, client_secret: process.env.REACT_APP_CLIENT_SECRET, grant_type: 'password' });
};

export const refreshToken = (data) => {
  data.client_id = process.env.REACT_APP_CLIENT_ID;
  data.client_secret = process.env.REACT_APP_CLIENT_SECRET;
  data.grant_type = 'refresh_token';

  return Util.post('/oauth/v2/token', data);
};

export const getUser = () => Util.get('/usersbytoken');

export const getMBPartnersAccessToken = () => Util.get('/fca/student/mbpartner');

export const getPartners = (url, queryParams, headers) => Util.getPartners(url+'/api/services',{},headers,queryParams);

export const getProfile = () => Util.get('/fca/student/profile');

export const saveProfile = (data) => Util.put('/fca/student/profile', data);

export const getContactDetails = () => Util.get('/fca/student/contact-details');

export const saveContactDetails = (data) => Util.put('/fca/student/contact-details', data);

export const changePassword = (data) => Util.put('/fca/student/changepassword', data);

export const changeUsername = (data) => Util.put('/fca/student/changeusername', data);

export const confirmEmailAddress = (token) => Util.post('/fca/account-requests/confirm-email-address', token);

export const resendConfirmationEmail = (username) => Util.post('/fca/account-requests/confirm-email-address/resend', username);

export const sendForgotUsernameEmail = (email) => Util.post('/fca/account-requests/recover-username/send-email', email);

export const sendForgotPasswordEmail = (username) => Util.post('/fca/account-requests/forgot-password/send-email', username);

export const resetPassword = (data) => Util.post('/fca/account-requests/forgot-password/reset', data);

export const getStoreSubjects = () => Util.get('/fca/store/subjects');

export const getStoreSubjectPackages = (nodeId) => Util.get(`/fca/store/subject/${nodeId}/packages`);

export const getStudentPackages = () => Util.get('/fca/student/packages');

export const registerPurchase = (data) => Util.post('/fca/student/purchases', data);

export const addVoucher = (data) => Util.post('/fca/student/vouchers', data);

export const getSubjects = () => Util.get('/fca/student/subjects');

export const getSubjectSyllabus = (subjectId) => Util.get(`/fca/student/subject/${subjectId}/subjectfilter`);

//@deprecated - use getTopicsForTeachingLevel instead
export const getTopics = (nodeId) => Util.get(`/fca/student/subject/teaching-level/${nodeId}`);

export const getTopicsForTeachingLevel = (nodeId) => Util.get(`/fca/student/teaching-level/${nodeId}/topics`);
export const getAllTopicsInSubject = (data,jwt,tag, subjectSlug, questionBankCodes) => Util.get(`/fca/student/subject/${data.subjectId}/topics/all` ,{} ,{
  'jwt': jwt,"by_tag_teaching_levels": subjectSlug == 'pre-ib-mathematics'? null : tag, "questionbank_codes":questionBankCodes
});

export const getSubtopicsInTopic = (data,jwt,tag, subjectSlug, questionBankCodes) => Util.get(`/fca/student/topic/${data.id}/sub-topics`,{} ,{
  'jwt': jwt,"by_tag_teaching_levels": subjectSlug == 'pre-ib-mathematics'? null : tag, "questionbank_codes":questionBankCodes
});

//SMPP-LAGGY
//export const getFlashcardContent = (nodeId,activeMediaTab) => Util.get(`/fca/student/media-type/${activeMediaTab}/node/${nodeId}/content`);
export const getFlashcardContent = (nodeId, activeMediaTab, jwt, teachingTag, subjectId) => Util.get(`/fca/student/media-type/${activeMediaTab}/node/${nodeId}/fullcontent`,{},{
  'jwt': jwt,
  'teachingTag': teachingTag,
  'subjectId': subjectId
});

export const setRevisionLink = ( data ) => Util.post('/fca/student/rgurl',data);

export const getElementStyles = () => Util.get('/insidecanvas/elements');

export const answerFlashcardCorrect = (nodeId) => Util.post(`/fca/student/flashcard/${nodeId}/answer/right`);
export const answerFlashcardWrong = (nodeId) => Util.post(`/fca/student/flashcard/${nodeId}/answer/wrong`);

export const createMediaUrl = mediaId => Util.createAuthUrl(`/contentmedia/${mediaId}`);

export const getTrialInfo = () => Util.get('/fca/student/trial');
export const checkPurchase = purchaseId => Util.get(`/fca/student/purchase/${purchaseId}/check`);

export const addDevice = (data) => Util.post('/fca/student/device-access', data);
export const removeDevice = (deviceId) => Util.remove(`/fca/student/device-access/${deviceId}`);
export const updateDevice = (deviceId, data) => Util.put(`/fca/student/device-access/${deviceId}`, data);

//Loggedin Device List
export const getLoggedinDevices = () => Util.get('/fca/student/getall-logindevice');
export const removeLoggedinDevice = (data) => Util.remove(`/fca/student/remove-device-access-by-token/${data.deviceId}`, data);

export const getNote = data => Util.get(`/fca/student/subject/${data.subjectId}/media-type/${data.mediaType}/node/${data.flashcardId}/note`);
export const addNote = (data) => Util.post(`/fca/student/subject/${data.subjectId}/media-type/${data.mediaType}/${data.flashcardId}/note`, data);
export const editNote = (data) => Util.put(`/fca/student/subject/${data.subjectId}/media-type/${data.mediaType}/${data.flashcardId}/note`, data);
export const deleteNote = (data) => Util.remove(`/fca/student/subject/${data.subjectId}/media-type/${data.mediaType}/notes`, data);
export const getFlashcardsPreviewWithNotes = (subjectId,mediaType, teachingTag) => Util.get(`/fca/student/subject/${subjectId}/media-type/${mediaType}/notes`,{},{...(teachingTag && { by_tag_teaching_levels : teachingTag })});
export const createFolder = (subjectNodeId, data) => Util.post(`/fca/student/subject/${subjectNodeId}/folder`, data);
export const updateFolder = (subjectNodeId, folderId, data) => Util.put(`/fca/student/subject/${subjectNodeId}/folder/${folderId}`, data);
export const getFolders = (subjectId, teachingTag) => Util.get(`/fca/student/subject/${subjectId}/folders`,{},{...(teachingTag && { 'folder-teaching-level' : teachingTag})});
//update folder reoragnisation
export const updateFolderSequence = (subjectId, data) => Util.put(`/fca/student/subject/${subjectId}/folderSequence`, data);
// Last study card
export const addLastStudyFlashCard = (data) => Util.put('/fca/student/studylastflashcard', data);
export const getLastStudyFlashCard = () => Util.get('/fca/student/getstudylastflashcard');

export const addFlashcardToFolder = (subjectId, folderId, flashcardId,mediaType) => Util.post(`/fca/student/subject/${subjectId}/folder/${folderId}/media-type/${mediaType}/flashcard/${flashcardId}`);
export const removeFlashcardFromFolder = (subjectId, folderId, flashcardIds,mediaType) => Util.remove(`/fca/student/subject/${subjectId}/folder/${folderId}/media-type/${mediaType}`, {ids: flashcardIds});
export const removeMultipleBookmarks = (subjectId,flashcardIds,mediaType) => Util.remove(`/fca/student/subject/${subjectId}/media-type/${mediaType}/bookmark`, {ids: flashcardIds});
export const getFlashcardsInFolder = (subjectId, folderId, mediaType) => Util.get(`/fca/student/subject/${subjectId}/folder/${folderId}/media-type/${mediaType}`);
export const getFlashcardsInBookmark = (subjectId, folderId, activeMediaTab, page, activeTeachingLevelId, getAPJWTToken, APTeachingTag, questionBankCodes, teachingTag) => Util.get(`/fca/student/subject/${subjectId}/media-type/${activeMediaTab}/bookmark/previews`,{},{...(teachingTag && {'teaching-level':teachingTag})});

export const getExamsInFolder = (subjectId,folderId, activeMediaTab, activePage, activeTeachingLevelId, jwt, tag, questionBankCodes) => Util.get(`/fca/student/examfolder/subject/${subjectId}/folder/${folderId}`,{} ,{
  'jwt': jwt,"by_tag_teaching_levels":tag, "questionbank_codes":questionBankCodes
})
export const getExamsInBookmark = (subjectId,folderId, activeMediaTab,activePage,activeTeachingLevelId,jwt,tag, questionBankCodes) => Util.get(`/fca/student/exambookmark/subject/${subjectId}`,{} ,{
  'jwt': jwt,"by_tag_teaching_levels":tag, "questionbank_codes":questionBankCodes
});
export const addExamsToFolder = (subjectId, folderId, flashcardId,mediaType,topicId ,subtopicId) => Util.post(`/fca/student/examfolder/subject/${subjectId}/topic/${topicId}/subtopic/${subtopicId}/node/${flashcardId}/folder/${folderId}`);
export const removeExamsFromFolder = (subjectId, folderId, flashcardIds,mediaType) => Util.remove(`/fca/student/examfolder/subject/${subjectId}/folder/${folderId}`,{ids: flashcardIds});
export const addExamToBookmark = (subjectId, flashcardId, mediaType, topic, subtopic, teachingTag) => Util.put(`/fca/student/exambookmark/subject/${subjectId}/topic/${topic}/subtopic/${subtopic}/node/${flashcardId}`,{...(teachingTag && {teaching_lvl : teachingTag })});
export const removeExamFromBookmark = (subjectId, flashcardId, mediaType,topic,subtopic) => Util.remove(`/fca/student/exambookmark/subject/${subjectId}/topic/${topic}/subtopic/${subtopic}/node/${flashcardId}`);
export const removeExamsMultipleBookmarks = (subjectId,flashcardIds,mediaType) => Util.remove(`/fca/student/exambookmark/subject/${subjectId}`, {ids: flashcardIds});


export const getFlashcardsInSmartFolder = (subjectId, type, page, teachingLevel) => {
  const ITEMS_PER_PAGE = 30;
  return Util.get(`/fca/student/subject/${subjectId}/folder/smart`, {}, {
    type,
    limit: ITEMS_PER_PAGE,
    offset: ITEMS_PER_PAGE * page,
    'teaching-level': teachingLevel
  });
};

export const getFolder = (subjectId, folderId) => Util.get(`/fca/student/subject/${subjectId}/folder/${folderId}`);
export const deleteFolders = (data) => Util.remove(`/fca/student/subject/${data.subjectId}/folders`, data);
export const getAnswers = (flashcardId) => Util.get(`/fca/student/flashcard/${flashcardId}/answers`);
export const getFoldersForFlashcard = (subjectId, activeType, flashcardId) => Util.get(`/fca/student/subject/${subjectId}/media-type/${activeType}/node/${flashcardId}/folders`);
export const getRecentSearches = (subjectId) => Util.get(`/fca/student/subject/${subjectId}/recent-searches`);
export const updateRecentSearches = (data) => Util.post(`/fca/student/subject/${data.subjectId}/recent-searches`, {searchedItems: data.searchedItems});

export const searchInFlashcards = (data, activeMediaTab, jwt, tag, subjectSlug, questionBankCodes) => {
  if(data.searchField.indexOf("‘") !== -1 ){
    data.searchField = data.searchField.replace("‘", "’");
  }
  const queryParams = {
    search: data.searchField,
    'jwt': jwt,
    "by_tag_teaching_levels": subjectSlug == 'pre-ib-mathematics'? null : tag,
    "questionbank_codes":questionBankCodes
  };

  if (data.limit) queryParams.limit = data.limit;
  if (data.offset) queryParams.offset = data.limit * data.page;
  if (data.teachingLevel) queryParams['teaching-level'] = data.teachingLevel;

  return Util.get(`/fca/student/subject/${data.subjectId}/media-type/${activeMediaTab}/previews`, {}, queryParams);
};
export const searchInNotes = (data) => Util.get(`/fca/student/subject/${data.subjectId}/media-type/${data.activeTab}/notes`, {}, {
  search: data.searchField,
  limit: data.limit,
  offset: data.limit * data.page,
  'jwt': data.jwt,
  "by_tag_teaching_levels": data.subjectSlug == 'pre-ib-mathematics' ? null : data.tag,
  "questionbank_codes":data.questionBankCodes
});
export const searchInFolders = (data) => Util.get(`/fca/student/subject/${data.subjectId}/folders`, {}, {
  search: data.searchField,
  limit: data.limit,
  offset: data.limit * data.page,
  jwt:data.jwt,
  by_tag_teaching_levels:data.tag,
  ...(data?.['folder-teaching-level'] && {'folder-teaching-level' : data?.['folder-teaching-level']})
});

export const searchInExercises = (data) => Util.get(`/fca/student/subject/${data.subjectId}/exercises`, {}, {
  search: data.searchField
});

export const getFlashcardIds = (data, activeMediaTab) => Util.get(`/fca/student/subject/${data.subjectId}/media-type/${activeMediaTab}/ids/structured`, {} ,{
  'teaching-tag': data.teachingTag
});

export const getPreviewsInSubtopic = data => Util.get(`/fca/student/sub-topic/${data.id}/previews`);

export const getExternalExercises = data => Util.get(`/fca/student/subject/${data.id}/exercises/external`);
export const getExternalUserAvatar = data => {
  return Util.get(`/fca/mb/identity/${data.id}/avatar`);
};
export const getExerciseDetails = exerciseId => Util.get(`/fca/exercise/${exerciseId}/details`);
export const getExercisePreviews = data => Util.get(`/fca/exercise/${data.id}/flashcards/previews`);
export const getExerciseAnswers = data => Util.get(`/fca/exercise/${data.id}/answers`);

export const getViewedExercises = () => Util.get('/fca/exercise/external/seen');
export const getAllExternalExercises = () => Util.get('/fca/exercise/external/all');
export const setExerciseAsViewed = (data) => Util.post(`/fca/exercise/external/${data.id}/seen`);
export const getAnswersInSubtopic = data => Util.get(`/fca/student/sub-topic/${data.id}/previews/answers`);
export const createExercise = data => Util.post('/fca/exercise/internal', data);
export const getInternalExercises = subjectId => Util.get(`/fca/student/subject/${subjectId}/exercises/internal`);
export const deleteExercises = data => Util.remove('/fca/exercise/internal', {ids: data});
export const updateExercise = (id, data) => Util.put(`/fca/exercise/internal/${id}`, data);

// iFrame specific actions
//todo handle the case when classId is for music: 10284545
export const getClassContent = data => {
  return Util.get(`/fca/class/${data.classId}/teaching-levels`);
};
export const getLastMBSync = () => Util.get('/fca/mb/identity/last-updated');

// export const getFlashcardAnswers = data => Util.get(`/fca/student/flashcards/last-answer`, {}, {
//   ids: data.toString()
// });
export const getFlashcardAnswers = data => Util.post(`/fca/student/flashcards/last-answer`, {ids: data.toString()});

export const getFreeTrialStatus = ({mbId}) => Util.get(`/fca/mb/identity/${mbId}/subscription`);

export const getFlashcardLinks = (data) => Util.get(`/fca/student/flashcard/${data.flashcardId}/flashcard/previews`);

export const getTopicsDetails = (data,jwt,tag, subjectSlug, questionBankCodes) => Util.get(`/fca/student/subject/${data.subjectId}/${data.activeType}/${data.id}`,{} ,{
  'jwt': jwt,"by_tag_teaching_levels": subjectSlug == 'pre-ib-mathematics'? null : tag,"questionbank_codes": questionBankCodes
});

export const getPreviewsListByType = (data,jwt,tag, subjectSlug, questionBankCodes) => Util.get(`/fca/student/node/${data.id}/media-type/${data.mediaType}/type/${data.type}/previews`,{} ,{
  'jwt': jwt,"by_tag_teaching_levels": subjectSlug == 'pre-ib-mathematics'? null : tag,"questionbank_codes": questionBankCodes
});

export const getQuickStatusData = (subjectId,jwt,tag, subjectSlug,questionBankCodes, by_tag_content_areas) => Util.get(`/fca/student/subject/${subjectId}`,{} ,{
  'jwt': jwt,"by_experience_tag_teaching_levels": subjectSlug == 'pre-ib-mathematics'? null : tag , "questionbank_codes":questionBankCodes, by_tag_content_areas
});
export const addMarkAsRead = (data) => Util.put(`/fca/student/media-type/${data.activeTab}/node/${data.id}/data`, data);
export const getBookmarkForFlashcard = (subjectId, activeType, flashcardId) => Util.get(`/fca/student/subject/${subjectId}/media-type/${activeType}/node/${flashcardId}/bookmarks`);
export const addFlashcardToBookmark = (subjectId, flashcardId, mediaType, topicNumber, subTopicNumber, teachingTag) => Util.post(`/fca/student/subject/${subjectId}/media-type/${mediaType}/node/${flashcardId}/bookmark`,{ ...(teachingTag && {teaching_lvl : teachingTag})});
export const removeFlashcardFromBookmark = (subjectId, flashcardId, mediaType) => Util.remove(`/fca/student/subject/${subjectId}/media-type/${mediaType}/node/${flashcardId}/bookmark`);
export const setAnswerFlashcard = (nodeId, data) => Util.post(`/fca/student/flashcard/${nodeId}/assessment`, data);
export const setAnswerFlashcardByExercise = (nodeId, exerciseId, data) => Util.post(`/fca/student/exercise/${exerciseId}/flashcard/${nodeId}/assessment`, data);
export const addVideoPlayed = (data) => Util.put(`/fca/student/media-type/${data.activeTab}/node/${data.id}/data`, data);
export const addOnBoardingStatus = (data) => Util.put('/fca/student/onboarding');
export const getCardsTitleByType = (data) => Util.get(`/fca/student/subject/${data.subjectId}/media-type/${data.mediaType}/structure`, {} ,{ 'teaching-tag': data.teachingTag });
export const getFeature = () => Util.get(`/fca/student/getactiveappupdates`);
export const closeFeature = (id) => Util.post(`/fca/student/feature/${id}`);

export const getExams = data => Util.post('/fca/student/assessprep/api', data);
export const getExamsNotesPreview = (subjectId, jwt, tag, questionBankCodes) => Util.get(`/fca/student/examnote/subject/${subjectId}/notes`,{} ,{
  'jwt': jwt,
  "by_tag_teaching_levels":tag,
  "questionbank_codes":questionBankCodes
});
export const getQuestionCount = (jwt,tag) => Util.get(`/fca/student/assessprep/questioncountbypaper`,{} ,{
  'jwt': jwt,"by_experience_uuids":tag
});

export const getExamNote = (data,jwt) => Util.get(`/fca/student/examnote/node/${data.node}/note`,{} ,{
  'jwt': jwt
});
export const editExamNote = (data) => Util.put(`/fca/student/examnote/subject/${data.subjectId}/topic/${data.topicId}/subtopic/${data.subTopicId}/node/${data.flashcardId}/note`, data);
export const deleteExamNote = (data) => Util.remove(`/fca/student/examnote/subject/${data.subjectId}/notes`, data);

export const getExamHeader = (data,jwt) => Util.get(`/fca/student/exam/subject/${data.activeSubjectId}/node/${data.examUid}`,{} ,{
  'jwt': jwt
});
export const useResponseSubmit = (data) => Util.apPost('/integrations/osc/user_responses',data)

export const getFilterExamApi = (data, jwt) => Util.post(`/fca/student/exam/subject/${data.activeSubjectId}/node/${data.examUid}`,{} ,{
  'jwt': jwt
});
export const getFirstQuestionBy = (jwt,tag, topicId, subjectSlug, questionBankCodes) => Util.get(`/fca/student/assessprep/getfirstquestion`,{} ,{
  'jwt': jwt,
  'by_tag_teaching_levels': subjectSlug == 'pre-ib-mathematics'? null : tag,
  'topicId':topicId,
  "questionbank_codes": questionBankCodes
});

export const getExamsFilterApi = data => Util.post('/fca/student/assessprep/filterapi', data);

export const setLastTeachingTag = data => Util.put('/fca/student/studylastsubjecttl',data) 

export const getLastTeachingTag = data => Util.get('/fca/student/studylastsubjecttl',data) 