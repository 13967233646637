import classNames from "classnames";

type LeftActiveTileProps = {
  isExercise: boolean;
  width?: "3px" | "4px";
};

const LeftActiveTile: React.FC<LeftActiveTileProps> = ({
  isExercise,
  width = "4px",
}) => {
  return (
    <div
      className={classNames(
        "left-active-tile absolute top-0 bottom-0 bg-blue-normal",
        {
          "!left-[-41px]": isExercise,
          "left-[-14px]": !isExercise,
          "w-[3px]": width === "3px",
          "w-1": width !== "3px",
        }
      )}
    />
  );
};

export default LeftActiveTile;
