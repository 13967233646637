import {SET_NODE_WATCHED} from 'actions/answers/answersActionTypes';
import {fromJS, Map} from 'immutable';

export default (state = Map(), action) => {
  switch (action.type) {
    case SET_NODE_WATCHED:
      return state.withMutations(state => {
        action.data.forEach(node => {
          // if (node.total >= 0) {
          //   //all nodes but previews
          //   state.set(node.id, fromJS({watchedValue: node.watchedValue}));
          // } else {
            //previews
            state.set(node.id, fromJS({
                watchedValue: node.watchedValue,
                partialValue: node.partialValue
            }));
          // }
        });
        return state;
      });
    default:
      return state;
  }
};
