import classNames from 'classnames';
import { useRef, useEffect, useMemo } from 'react'
import { getExternalExercisesRoutine } from 'actions/api/apiActions';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader'
import { externalExercisesResponse } from 'selectors/api/apiSelector';
import { useDispatch, useSelector } from 'react-redux';
import { activeSubjectId, apiRequestData, isMBUserSelector, isMBFromSchool } from 'selectors'
import FreeTrialNotification from 'v2/components/free-trial-notification/FreeTrialNotification'
import ClassRoomHeader from './ClassRoomHeader'
import RequestLoader from 'v2/components/loader.v2/RequestLoader'
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader'
import ClassroomEmptyMessage from 'v2/components/empty-screen-message/ClassroomEmptyMessage'
import RouterLink from 'router/Link';
import ExerciseTile from 'v2/components/exercise/ExerciseTile'
import { setGoBackToSearch } from 'actions/search';
import { setDocumentTitle } from 'actions/navigation/index';
import useScrollDetection from 'hooks/useScrollDetection';
import useExternalExercisesData from 'hooks/useExternalExercisesData';


const ClassRoomPage = () => {
    const dispatch = useDispatch()
    let wrapperRef = useRef(null);
    const { isScrolled, bindScrollRef } = useScrollDetection({ WrappedRef: wrapperRef, threshold1: 20 });
    const externalExecriseData: any = useSelector(externalExercisesResponse)?.toJS()
    const subjectId = useSelector(activeSubjectId)
    const hasMBFromSchool = useSelector(isMBFromSchool)
    const isAccountMerged = useSelector(isMBUserSelector)
    const answers = useSelector((state) => apiRequestData(state, 'answers'))
    const externalExercisesData = useExternalExercisesData();
    const externalExerciseData = useMemo(() => externalExercisesData() ?? [],[externalExercisesData])
    const exercise = externalExerciseData?.length > 0

    const bindRef = (ref: any) => {
        wrapperRef = ref;
        bindScrollRef(ref);
    };

    useEffect(() => {
        dispatch(setDocumentTitle('Classroom'))
        //hasMBFromSchool === "YES" && isAccountMerged && dispatch(getExternalExercisesRoutine({id: subjectId}))
    }, [dispatch])

    useEffect(() => {
        hasMBFromSchool === "YES" && isAccountMerged && dispatch(getExternalExercisesRoutine({ id: subjectId }))
    }, [subjectId, answers, hasMBFromSchool, isAccountMerged, dispatch])


    const onExerciseTileClick = () => {
        dispatch(setGoBackToSearch(false));
    };

    const renderErrorMessage = () => {
        return <div className='pt-5 px-5'>
            <div className='text-14px text-red-600'>An error has occurred!</div>
        </div>;
    };

    const renderExercises = () => {
        if (externalExerciseData.length === 0)
            return <ClassroomEmptyMessage isAccountMerged={isAccountMerged && hasMBFromSchool === "YES"} />

        return externalExerciseData.map((exercise: any) =>
            <RouterLink
                key={exercise.exerciseId}
                to={`/flashcard/classroom/exercise/${exercise.exerciseId}`}
                onClick={() => { onExerciseTileClick(); }}>
                <ExerciseTile
                    onClick={onExerciseTileClick}
                    exercise={exercise} />
            </RouterLink>)
    }

    return <div className='h-full'>
        <NavigationHeader isCollapsed={isScrolled} collapsedTitle={'Classroom'} />
        <div className={classNames('pt-14 pb-[150px] h-full overflow-auto', { 'flex flex-col': !exercise })} ref={bindRef}>
            <FreeTrialNotification />
            <ClassRoomHeader />
            {exercise && <div className='pt-4' />}
            {isAccountMerged && hasMBFromSchool === "YES" ?
                <RequestLoader
                    loader={<LeftMenuLoader />}
                    allowUpdate
                    request={externalExecriseData[subjectId]}
                    error={renderErrorMessage}
                >
                    {renderExercises()}
                </RequestLoader> :
                <ClassroomEmptyMessage isAccountMerged={isAccountMerged && hasMBFromSchool === "YES"} />
            }
        </div>
    </div>
}

export default ClassRoomPage;