import React from 'react';
import styled from 'styled-components/macro';
import Col from 'ui-components/helpers/Col';
import Padding from 'ui-components/helpers/Padding';
import Text from 'ui-components/helpers/Text';
import SearchIcon from 'assets/images/search/search-icon.svg';
import NoResultsMathsIcon from 'assets/images/search/no-results-mathematics-icon.svg';

const NoResults = () => {
  return (<Padding top={96}>
    <Col alignItems={'center'}>
      <img src={SearchIcon} alt=""/>
      <Padding top={15}>
        <NoResultsText weight={'bold'} size={25}>No results found!</NoResultsText>
      </Padding>
    </Col>
  </Padding>);
};

export default NoResults;

const NoResultsText = styled(Text)`
  color: #378EF8;
  background-clip: text;
  line-height: 1.3;
`;

export const NoResultsMaths = () => {
  return (<Padding top={96} left={25} right={25}>
    <Col alignItems={'center'}>
      <img src={NoResultsMathsIcon} alt=""/>
      <Padding top={12}>
        <Text textAlign={'center'} weight={'bold'} size={15} color={'#A2A3A3'}>Coming soon!</Text>
      </Padding>
      <Padding top={7}>
        <Text textAlign={'center'} size={15} color={'#B1B6B5'} lineHeight={'20px'}>We're working hard to make available
          the cards search for the mathematics courses.</Text>
      </Padding>
    </Col>
  </Padding>);
};
