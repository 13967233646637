import styled, {css} from 'styled-components/macro';
import media from 'ui-components/helpers/media';

const calculateWidth = ({mobileWidth, width}) => {
  let finalWidth = mobileWidth || width;
  if (finalWidth) finalWidth += '%';
  return css`
    width: ${finalWidth || 'auto'};
  `;
};
const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  width: ${props => props.width ? `${props.width}%` : 'auto'};
  
   ${({flexGrow}) => flexGrow && css`
      flex-grow: ${flexGrow};
  `}
   
  ${({justifySelf}) => justifySelf && css`
      justify-self: ${justifySelf};
  `}
  
  ${media.tablet`
    ${calculateWidth}
  `}
`;

export default Col;
