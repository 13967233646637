import { checkFlashcardAssesstment } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentFlashcardId, getActiveMediaTab, listOfFilterCheckedItems } from 'selectors';
import { answers, examAnswers, marks, watched } from 'selectors/answersSelectors';
import usePrevious from './usePrevious';

const useAnswerStatus = (fn?: () => void) => {
    const currentId = useSelector(currentFlashcardId);
    const [filterFlashcardId, setFilterFlashcardId] = useState<string | number>();
    const getListOfFilterCheckedItems = useSelector(listOfFilterCheckedItems);
    const activeMediaTab = useSelector(getActiveMediaTab);
    const getAnswers = useSelector(answers);
    const getMarks = useSelector(marks);
    const getWatched = useSelector(watched);
    const getExamAnswers = useSelector(examAnswers);
    const prevAnswers = usePrevious(getAnswers);
    const prevMarks = usePrevious(getMarks);
    const prevWatched = usePrevious(getWatched);
    const prevExamAnswers = usePrevious(getExamAnswers);
    const FCAssessmentType = (assessment: string | number) => {
        switch (assessment) {
            case null:
                return 'Not yet assessed';
            case 0:
                return 'Unconfident';
            case 1:
                return 'Confident';
            case 2:
                return 'Neither';
            default:
                return null;
        }
    }
    const RGAssessmentType = (assessment: string | number) => {
        switch (assessment) {
            case 0:
                return 'Not yet read';
            case 1:
                return 'Read';
            default:
                return null;
        }
    }
    const VAssessmentType = () => {
        if (getWatched?.toJS()[currentId]?.partialValue === 0 && getWatched?.toJS()[currentId]?.watchedValue !== 1) {
            return 'Not yet watched';
        }
        if (getWatched?.toJS()[currentId]?.partialValue === 1 && getWatched?.toJS()[currentId]?.watchedValue !== 1) {
            return 'Partly-watched';
        }
        if (getWatched?.toJS()[currentId]?.watchedValue === 1) {
            return 'Watched';
        }
        else {
            return null;
        }
    }
    const ExamAssessmentType = () => {
        const assessment = getExamAnswers?.toJS()[currentId]?.correct;
        switch (assessment) {
            case null:
                return 'Not yet assessed';
            case 0:
                return 'Unconfident';
            case 1:
                return 'Confident';
            case 2:
                return 'Neither';
            default:
                return null;
        }
    }

    const checkAnswerStatus = () => {

        if (currentId) {
            const filterTypes: any = {
                answer: {
                    'flashcard': getAnswers?.get(currentId)?.get("correct"),
                    'revision': getMarks?.toJS()[currentId]?.markValue,
                    'video': getWatched?.toJS()[currentId]?.partialValue,
                    'exams': getExamAnswers?.toJS()[currentId]?.correct,
                },
                prevAnswer: {
                    'flashcard': prevAnswers?.get(currentId)?.get("correct"),
                    'revision': prevMarks?.toJS()[currentId]?.markValue,
                    'video': prevWatched?.toJS()[currentId]?.partialValue,
                    'exams': prevExamAnswers?.toJS()[currentId]?.correct,
                },
                assessment: {
                    'flashcard': FCAssessmentType(getAnswers?.get(currentId)?.get("correct")),
                    'revision': RGAssessmentType(getMarks?.toJS()[currentId]?.markValue),
                    'video': VAssessmentType(),
                    'exams': ExamAssessmentType()
                }
            }
            const answer = filterTypes.answer[activeMediaTab];
            const prevAnswer = filterTypes.prevAnswer[activeMediaTab];
            const assessment = filterTypes.assessment[activeMediaTab];
            if (prevAnswer !== answer) {
                if (checkFlashcardAssesstment(getListOfFilterCheckedItems.toJS()[activeMediaTab], assessment, activeMediaTab)) {
                    setFilterFlashcardId(currentId);
                }
                setTimeout(() => {
                    fn?.()
                    setFilterFlashcardId('');
                }, 500)
            }
        }
    }

    useEffect(() => {
        checkAnswerStatus()
    }, [getAnswers, prevAnswers, getMarks, prevMarks, getWatched, prevWatched, getExamAnswers, prevExamAnswers])

    return { filterFlashcardId }
}

export default useAnswerStatus