import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveExerciseId, getActiveMediaTab, isFilterOverlayVisible, isMainMicroFilterVisible, listOfFilterCheckedItems } from 'selectors';
import { titlesMicro } from '../microFilter/MicroFilterText'
import classNames from 'classnames';
import Text from 'v2/components/common/Text';
import { ArrowdownIcon, FilterClearIcon } from 'icons/HeaderIcons';
import './mircoFilterLayout.scss'
import { getMediaTypeFilterOverlay, mainMicroFilterBack, toggleFilterOverlay, toggleMainMicroOverlay } from 'actions/navigation';
import { ConfidentIcon, UnconfidentIcon, NeitherIcon } from 'icons/FlashcardIcons';
import { updateFilterListOfItemsSelected } from 'actions/desk';
import { useHistory } from 'react-router-dom';
import { updateExerciseNumber } from 'actions/topics-tree/topicsTreeActions';
import { setExamFilterData } from 'actions/api';


type MircoFilterLayoutProps = {
  page?: string | boolean,
  formCC?: boolean,
  className?: string,
  isEditMode?: boolean,
}
const titles: any = titlesMicro;
const MircoFilterLayout: React.FC<MircoFilterLayoutProps> = ({ page, formCC, className, isEditMode }) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const listOfFilter = useSelector(listOfFilterCheckedItems);
  const activeMediaTab = useSelector(getActiveMediaTab);
  const hasFilterOverlayVisible = useSelector(isFilterOverlayVisible);
  const hasMainMicroFilterVisible = useSelector(isMainMicroFilterVisible);
  const activeExerciseId = useSelector(getActiveExerciseId)
  let data = page === 'exams' || formCC ? listOfFilter.toJS()['exams'] : listOfFilter.toJS()[activeMediaTab] ?? []
  let title = page === 'exams' ? titles['exams'] : titles[activeMediaTab]

  function setFormCCPopoup() {
    if (formCC) {
      dispatch(mainMicroFilterBack(true, true));
    } else {
      dispatch(mainMicroFilterBack(false, false));
    }
  }

  const selectFilter = (e: any) => {
    const mediaType = page === 'exams' ? 'exams' : activeMediaTab;
    e.stopPropagation();
    dispatch(toggleFilterOverlay(true, true));
    dispatch(getMediaTypeFilterOverlay(mediaType));
    setFormCCPopoup()
    if (hasMainMicroFilterVisible) return dispatch(toggleMainMicroOverlay(false, false))
  }

  const assesmentIcon = useCallback((value: Array<string>) => {
    let val: string = '';
    if (value.includes("Not yet assessed")) {
      if (data.length > 1)
        val = 'Not ye...'
      else
        val = 'Not yet assessed'
    } else if (value.includes("Unconfident")) {
      val = 'Unconfident'
    } else if (value.includes("Neither")) {
      val = 'Neither'
    } else if (value.includes("Confident")) {
      val = 'Confident'
    }
    switch (val) {
      case 'Not yet assessed':
        return <Text className='text-13px font-bold text-blue-normal leading-18px py-9px pr-1 pl-4 filter-text'>{val}</Text>
      case 'Unconfident':
        return <div className='my-2 mr-2 ml-4 [&_svg]:block'><UnconfidentIcon  /></div>
      case 'Neither':
        return <div className='my-2 mr-2 ml-4 [&_svg]:block'><NeitherIcon /></div>
      case 'Confident':
        return <div className='my-2 mr-2 ml-4 [&_svg]:block'><ConfidentIcon /></div>
      default:
        return <Text className='text-13px font-bold text-blue-normal leading-18px py-9px pr-1 pl-4 filter-text' >{val}</Text>
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfFilter])
  const clearFilter = (e: any) => {
    const mediaType = page === 'exams' ? 'exams' : activeMediaTab;
    e.stopPropagation();
    dispatch(updateFilterListOfItemsSelected([], mediaType));
    const pathname = history.location.pathname;
    if (pathname.indexOf("/flashcard/desk/exercise/edit/") !== -1 || (pathname.indexOf("flashcard/desk/exercise/create") !== -1)) {
      dispatch(updateExerciseNumber(activeExerciseId))
    }
    mediaType === 'exams' &&dispatch(setExamFilterData());
  }

  return <>
    {data.length > 0 && ((activeMediaTab === "flashcard" && data.length < 4) || (activeMediaTab === "revision" && data.length < 2) || (activeMediaTab === "video" && data.length < 3) || (activeMediaTab === "exams" && data.length < 4) || page === 'exams' && data.length < 4 || formCC && data.length < 4) ?
      <div className={classNames('flex', className, { 'ml-5': page !== 'exercise', 'menu-animation': isEditMode })}>
        <div className='relative'>
          <div onClick={(e) => selectFilter(e)} className={classNames('flex rounded-[18px] items-center select-none cursor-pointer h-9 micro-filter-active', { '!opacity-50 !select-none !pointer-events-none': isEditMode })}>
            {activeMediaTab === "flashcard" || activeMediaTab === "exams" || page === 'exams' || formCC ?
              <>
                {assesmentIcon(data)}{data.length > 1 && <div className='font-bold text-13px text-blue-normal leading-18px pr-1 filter-text'>+ {data.length - 1}</div>}
                <div className="closeButton1"> </div>
              </> :
              <>
                <div className='font-bold text-13px text-blue-normal leading-18px pr-1 py-[9px] pl-4 filter-text' >{data[0]}{data.length > 1 && <span> + {data.length - 1}</span>}</div>
                <div className="closeButton1"></div>
              </>}
          </div>
          <div onClick={(e) => clearFilter(e)} className={classNames('filter-clear', { '!opacity-50 !select-none !pointer-events-none': isEditMode })}> <FilterClearIcon /></div>
        </div>
      </div> :
      <div className={classNames('flex', className, { 'ml-5': page !== 'exercise', 'menu-animation': isEditMode })}>
        <div onClick={(e) => selectFilter(e)} className={classNames('flex rounded-[18px] items-center select-none cursor-pointer h-9 micro-filter-inactive', { 'micro-filter-active [&_svg]:rotate-180 [&_svg]:my-2  [&_svg]:mr-3 [&_svg]:ml-1.5': hasFilterOverlayVisible, '!opacity-50 !select-none !pointer-events-none': isEditMode })}>
          <Text className='text-13px font-bold text-#2D3838 leading-18px py-9px pr-1 pl-4 filter-text' > {title}</Text>
          <ArrowdownIcon />
        </div>
      </div>}
  </>
}

export default MircoFilterLayout;