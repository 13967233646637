import {TopicsIcon} from 'icons/FlashcardIcons';
import SearchResultsHeader from 'pages/search/SearchResultsHeader';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import TopicLinksResultsList from 'ui-components/link-results/TopicLinksResultsList';
import {transformResultsListToArray} from 'helpers';
export default class TopicLinksContainer extends Component {
  componentDidMount() {
  }

  render() {
    const newListItems = this.props.topicsList ? transformResultsListToArray(this.props.topicsList) : [];
    return <React.Fragment>
      <SearchResultsHeader show={true} title={'Topics'} icon={<TopicsIcon />} showMore={false}  total={0} />
      <TopicLinksResultsList newListItems={newListItems} />
    </React.Fragment>;
  }
}

