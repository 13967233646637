import Immutable from 'immutable';
import * as Types from 'actions/user-store/types';

export default (state = Immutable.fromJS(
  {
    isBasketDetailsVisible: false
  }
), action) => {
  switch (action.type) {
    case Types.SET_ACTIVE_STORE_SUBJECT: {
      return state.set('activeStoreSubject', Immutable.fromJS(action.data));
    }
    case Types.ADD_ITEM_IN_PURCHASE_BASKET: {
      let newList;
      const itemsInBasket = state.get('itemsInBasket', Immutable.List());
      const foundItem = itemsInBasket.filter(item => item.get('id') === action.data.package.get('id'));

      if (foundItem.isEmpty()) {
        let addedPackage = action.data.package;
        let addedSubscription = action.data.subscription;
        newList = itemsInBasket.push(addedPackage.set('subscriptions', Immutable.List([addedSubscription.set('amount', 1)])));
      } else {
        let foundItemHasSubscription = foundItem.first().get('subscriptions').filter(subscription => subscription.get('id') === action.data.subscription.get('id'));

        if (!foundItemHasSubscription.size) {
          let addedSubscription = action.data.subscription;
          let foundItemIndex = itemsInBasket.indexOf(foundItem.first());
          let foundItemUpdated = foundItem.first().updateIn(['subscriptions'], list => list.push(addedSubscription.set('amount', 1)));
          newList = itemsInBasket.setIn([foundItemIndex], foundItemUpdated);
        }
        else {
          newList = itemsInBasket;
        }
      }
      return state.set('itemsInBasket', newList);
    }
    case Types.REMOVE_ITEM_FROM_PURCHASE_BASKET: {
      return removeItemFromBasket(state, action.data.package.get('id'), action.data.subscription.get('id'));
    }
    case Types.RESET_PURCHASE_BASKET: {
      return state.set('itemsInBasket', Immutable.List());
    }
    case Types.TOGGLE_BASKET:
      return toggleBasket(state, action.data);
    case Types.CHANGE_AMOUNT: {
      const itemsInBasket = state.get('itemsInBasket', Immutable.List());

      const subscription = action.data.itemInCart;

      const subscriptionId = parseInt(subscription.get('id'));
      const packageId = parseInt(subscription.get('packageId'));

      const packageIndex = itemsInBasket.findIndex((item) => item.get('id') === packageId);
      const subscriptionIndex = itemsInBasket.getIn([packageIndex, 'subscriptions']).findIndex((subscription) => subscription.get('id') === subscriptionId);

      const valueToAdd = action.data.type === 'decrement' ? -1 : 1;

      const basketPackage = itemsInBasket.get(packageIndex);
      const basketSubscription = basketPackage.getIn(['subscriptions', subscriptionIndex]);
      let newAmount = basketSubscription.get('amount') + valueToAdd;

      if (newAmount === 0) {
        state = removeItemFromBasket(state, packageId, subscriptionId);
        if (isBasketEmpty(state)) state = toggleBasket(state, false);
        return state;
      }

      return state.set('itemsInBasket', itemsInBasket.updateIn([packageIndex, 'subscriptions', subscriptionIndex, 'amount'], (amount) => amount + valueToAdd));
    }
    case Types.TOGGLE_PAYMENT:
      return state.update('isPaymentView', val => !val);
    case Types.SET_PACKAGES_DESCRIPTION_PROPS: {
      const statePackagesDescriptionProps = state.get('packagesDescriptionProps', Immutable.List());
      const packagesDescriptionData = Immutable.fromJS(action.data);
      let newPackagesDescriptionPropsData = [];

      packagesDescriptionData.forEach((itemData) => {
        let packageId = itemData.get('packageId');

        let stateItemIndex = statePackagesDescriptionProps.findIndex((stateItem) => stateItem.get('packageId') == packageId);

        if (stateItemIndex !== -1)
          return;

        newPackagesDescriptionPropsData.push(itemData);
      });

      if (newPackagesDescriptionPropsData.length) {
        const newPackagesDescriptionProps = statePackagesDescriptionProps.merge(Immutable.List(newPackagesDescriptionPropsData));
        return state.set('packagesDescriptionProps', newPackagesDescriptionProps);
      }
      return state;
    }
    case Types.TOGGLE_PACKAGE_DESCRIPTION: {
      const statePackagesDescriptionProps = state.get('packagesDescriptionProps', Immutable.List());
      const packagesDescriptionData = Immutable.fromJS(action.data);
      const packageId = packagesDescriptionData.get('packageId');

      let stateItemIndex = statePackagesDescriptionProps.findIndex((stateItem) => stateItem.get('packageId') == packageId);
      if (stateItemIndex === -1)
        return state;

      const stateItem = statePackagesDescriptionProps.get(stateItemIndex);
      const newStatePackagesDescriptionProps = statePackagesDescriptionProps.setIn([stateItemIndex, 'isExpanded'], !stateItem.get('isExpanded'));
      return state.set('packagesDescriptionProps', newStatePackagesDescriptionProps);
    }
    default:
      return state;
  }
};

function removeItemFromBasket(state, packageId, subscriptionId) {
  let newList;
  const itemsInBasket = state.get('itemsInBasket', Immutable.List());
  const packageIndex = itemsInBasket.findIndex((item) => item.get('id') === packageId);

  if (packageIndex === -1) {
    return state;
  }

  let subscriptionIndex = itemsInBasket.getIn([packageIndex, 'subscriptions']).findIndex((subscription) => subscription.get('id') === subscriptionId);
  if (subscriptionIndex === -1) {
    return state;
  }

  let packageSubscriptions = itemsInBasket.getIn([packageIndex, 'subscriptions']);

  if (packageSubscriptions.size > 1) {
    newList = itemsInBasket.deleteIn([packageIndex, 'subscriptions', subscriptionIndex]);
  }
  else {
    newList = itemsInBasket.delete(packageIndex);
  }
  return state.set('itemsInBasket', newList);
}

function toggleBasket(state, value) {
  return state.update('isBasketDetailsVisible', val => {
    if (typeof value !== 'undefined') return value;
    return !val;
  });
}

function isBasketEmpty(state) {
  return state.get('itemsInBasket').size === 0;
}
