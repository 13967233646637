import React from 'react'
import './filter.scss'


type FilterToggleHeaderProps = {
    title: string;
    onChange: () => void;
    checked: boolean;
}  

const FilterToggleHeader: React.FC<FilterToggleHeaderProps> = ({title, checked, onChange}) => {
    return <div className='flex items-center justify-between mt-2 py-2.5 px-5'>
        <div className='text-15px leading-19px text-#2D3838'>{title}</div>
        <div>
            <label className="relative inline-block w-[51px] h-5">
                <input className='w-0 h-0 opacity-0' onChange={() => onChange()} type="checkbox" checked={checked} />
                <span className="slider round"></span>
            </label>
        </div>
    </div>
}

export default FilterToggleHeader;