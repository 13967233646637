import React, {memo, useEffect, useState} from 'react'
import FilterHeader from './FilterHeader';
import './filter.scss'
import { useDispatch, useSelector } from 'react-redux';
import {isMainMicroFilterBack} from 'selectors/navigation/navigationSelector'
import FilterToggleHeader from './FilterToggleHeader'
import {examsofFilterItems} from 'selectors/desk/deskSelector'
import {updateExamsPaperFilterItems} from 'actions/desk/deskActions'
import {toggleMainMicroOverlay} from 'actions/navigation/navigationActions'
import {setExamFilterData} from 'actions/api/apiActions'
import usePrevious from 'hooks/usePrevious';
import FilterButton from './FilterButton'
import FilterContainer from './FilterContainer';

type DiffficultyFilterProps = {
    onClick: (e: any) => void;
    onClose: () => void;
}  

const filterData = [
    { title: 'Accessible' },
    { title: 'Moderate' },
    { title: 'Discriminating' }
]

const DiffficultyFilter: React.FC<DiffficultyFilterProps> = ({onClick, onClose}) => {
    const dispatch = useDispatch();
    const [isActive, setActive] = useState(true)
    const [disabled, setDisable] = useState(true)
    const [filter, setFilter] = useState(['Accessible', 'Moderate', 'Discriminating'])
    const hasMainMicroFilterBack = useSelector(isMainMicroFilterBack)
    const examFilterItems = useSelector(examsofFilterItems)?.toJS();
    const previousFilterState = usePrevious(filter)
    
    useEffect(() => {
        let data = examFilterItems['difficulty']
        data.length > 0 && setFilter(data);
    },[])

    const selectToggle = (val: boolean) => {
        setActive(!val)
        if(isActive){    
            return setFilter([]);
        }
        return setFilter(['Accessible', 'Moderate', 'Discriminating'])
    }

    const selectFilter = (val: string) => {
        const filterVal = [...filter];
        let indexOfItem = filterVal.indexOf(val);
        if (indexOfItem >= 0) {
            filterVal.splice(indexOfItem, 1);
        } else {
            filterVal.push(val);
        }
        setFilter(filterVal);
    }

    useEffect(() => {        
        if(previousFilterState !== filter){
            changeFilter();
        }  
    },[filter, previousFilterState])

    const changeFilter = () => {
        let prevExamFilter = examFilterItems['difficulty']
        if(JSON.stringify(filter.sort()) === JSON.stringify(prevExamFilter.sort()) || filter.length < 1){
            setDisable(true)
        }else{
            setDisable(false)
        }
        if(filter.length === 3){
            setActive(true)
        }else{
            setActive(false)
        }
    }

    const applyChanges = () => {
        if(disabled) return ;
        dispatch(updateExamsPaperFilterItems(filter, "difficulty"))
        dispatch(setExamFilterData())
        onClose();
    }

    const onSelectMain= () => {
        onClose();
        dispatch(toggleMainMicroOverlay(true, true))
    }
    
    return <div className='filter-wrapper' onClick={onClick}>
        <FilterHeader
            fromCC={hasMainMicroFilterBack} 
            onBackClick={onSelectMain} 
            text={'Filter'} 
            hideArrow={false} 
            title={'Difficulty'} 
            onCloseClick={onClose} 
        />
        <FilterToggleHeader title={'All difficulty levels'} onChange={() => selectToggle(isActive)} checked={isActive}/>
        <div className='pt-0 px-5 pb-4'>
            {filterData.map((value, index) => <FilterContainer key={index} index={index} isWidth={true} filterType={'difficulty'} filter={filter} onClick={() => selectFilter(value.title)} itemID={value.title} isMinHeight={true}>
                <div className='filter-category-section'>{value.title}</div>
            </FilterContainer>)}
        </div>
        <FilterButton onClose={onClose} onClick={applyChanges} disabled={disabled}/>
    </div>
}

export default DiffficultyFilter;