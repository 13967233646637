import { useRef, useEffect } from 'react';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile, isLandscape, scrollPosition, previewsListbyType } from 'selectors'
import BackButton from 'v2/components/navigation-header/BackButton';
import { useParams } from 'react-router-dom';
import TopicListContainer from 'v2/pages/topics/TopicListContainer';
import TopicPageHeaderContainer from 'v2/pages/topics/TopicPageHeaderContainer';
import { saveScrollPosition, setActiveFlashcardTab } from 'actions/navigation';
import debounce from 'lodash.debounce';
import usePrevious from 'hooks/usePrevious';
import './topicsPage.scss';
import useScrollDetection from 'hooks/useScrollDetection';
import useRedirectHLSL from 'hooks/useRedirectionHLSL';

type ParamsProps = {
  id: string
}

const SubTopicPage = () => {
  let wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch()
  const { id } = useParams<ParamsProps>()
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);
  const hasScrollPosition = useSelector(scrollPosition).toJS();
  const previews = useSelector(previewsListbyType)?.toJS();
  const prevPreview: any = usePrevious(previews)
  const { isScrolled, bindScrollRef } = useScrollDetection({ WrappedRef: wrapperRef, threshold1: 20 });
  useRedirectHLSL()

  useEffect(() => {
    dispatch(setActiveFlashcardTab(0));
    window.scrollTo(0, 0);
    const values = {
      "previousValue": 0,
      "currentValue": 0
    }
    dispatch(saveScrollPosition("subTopics", values));
    if(wrapperRef?.current) {
      bindScrollRef(wrapperRef.current)
      wrapperRef.current.addEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if(prevPreview?.[id]?.loading !== previews?.[id]?.loading && previews?.[id]?.loading === false && wrapperRef.current){
      wrapperRef.current.scrollTop = hasScrollPosition.subTopics?.currentValue
    } 
  }, [previews, prevPreview])

  const handleScroll = debounce((event: any) => {
    const scrollTop = event.target.scrollTop;
    const values = {
      "previousValue": hasScrollPosition.subTopics?.currentValue,
      "currentValue": scrollTop
    }
    if (previews?.[id]?.loading === false) {
      dispatch(saveScrollPosition("subTopics", values))
    }
  }, 300)

  const renderLeftSection = () => {
    return <BackButton linkTo='/flashcard/topics' text='Topics' />;
  }

  return <div className='relative flashcard-nav-route-wrapper'>
    <NavigationHeader isCollapsed={!(hasMobile && hasLandscape) ? isScrolled : true} text={null}
      extendedWidth
      rightSection={null}
      extendedHeight
      shouldExtendHeight={false}
      leftSection={renderLeftSection()}
      bottomSection={!(hasMobile && hasLandscape) ? null : true}
    />
    <div className="topics-container topic-fixed-space" ref={wrapperRef}>
      <TopicPageHeaderContainer typeId={id} activeType={'subtopic'} />
      <TopicListContainer activeType={2} id={id} fromPage={"subtopic"} />
    </div>
  </div>
}


export default SubTopicPage;