import classNames from "classnames";

type PageTitleProps = {
children: React.ReactElement | string; 
}

export const PageTitle: React.FC<PageTitleProps> = ({ children}) => {
  return <h1 className="page-title">{children}</h1>
}

type SeparatorProps = {
  className?: string
}

export const Separator: React.FC<SeparatorProps> = ({className}) => {
  return <div className={classNames(className, 'h-[1px] rounded-[1px] bg-#EFF4F9 m-0')} />
}

