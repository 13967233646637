import {Map} from 'immutable';

export const answers = state => state.get('answers');
export const answerById = (state, id) => state.getIn(['answers', id], Map());

export const marks = state => state.get('marks');
export const markById = (state, id) => {
    return state.getIn(['marks', id], Map());
}

export const watched = state => state.get('watched');
export const watchedById = (state, id) => {
    return state.getIn(['watched', id], Map());
}

export const examAnswers = state => state.get('examAnswers');
export const examAnswerById = (state, id) => {
    return state.getIn(['examAnswers', id], Map());
}