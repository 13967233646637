import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AddToBookmarkIcon} from 'icons/FolderIcons';
import * as Actions from 'actions';
import styled, { css } from 'styled-components/macro';
import * as Selectors from 'selectors';
import {addFlashcardToBookmarkResponse, removeFlashcardFromBookmarkResponse} from 'selectors/api/apiSelector';
import Loader, {BackgroundEnum, SizeEnum} from 'v2/components/loader.v2/Loader.v2';
import { getLastMediaTypeVisited } from 'helpers';
import {getUserId} from 'selectors/user';
import media from 'ui-components/helpers/media';
import appHistory from 'appHistory';

class AddFlashcardToBookmarkContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isHover: false,
    };
  }
  onClick = () => {
    const pathName = appHistory.location.pathname;
    const  mediaType = getLastMediaTypeVisited(this.props.userId);
    console.log(this.props.getactiveMediaTab , mediaType)
    if(this.props.getactiveMediaTab !== mediaType){
    this.props.setActiveMediaTab(mediaType);
    }

    if (this.isInFolder()) {
      this.props.removeFlashcardFromBookmark({mediaType:mediaType, fromPage:pathName.indexOf('/flashcard/desk/folders/bookmark'),});
    } else {
      this.props.addFlashcardToBookmark({mediaType :mediaType, fromPage: pathName.indexOf('/flashcard/desk/folders/bookmark')});
    }
  };

  isInFolder = () => { 
    const {flashcardFolders ,currentFlashcardId} = this.props;
    if(this.props.isFolderPage){
      if(flashcardFolders.size > 0){  
        return flashcardFolders && flashcardFolders.findIndex(item => {
          
          //checks if flashcards are in folder (for FoldersListContainer) or if the currentFlashcardId is inside the list of flashcards of the folder (FolderPage)
          return item.get('flashcardId') === currentFlashcardId;
        }) !== -1;
      } else {
        return false
      }
    }else{
      if(flashcardFolders === 0 ) return false;
      if(flashcardFolders === 1 ) return true;
    }
    
  };

  render() {
    const {currentFlashcardId, addFlashcardToBookmarkResponse, removeFlashcardFromBookmarkResponse,getFolderAddButtonActive} = this.props;
    return <div className='icon-spacing bookmark-icon-hover'  style={!getFolderAddButtonActive ? {"pointerEvents":'none'} :{}} onClick={(e) => {
        // if (!currentFlashcardId) return;
        e.stopPropagation();
        this.onClick("bookmark");
      }}>
        <React.Fragment>
        { (addFlashcardToBookmarkResponse.getIn(['loading']) || removeFlashcardFromBookmarkResponse.getIn(['loading'])) ?
        <Loader background={BackgroundEnum.Blue} size={SizeEnum.VSmall}/> : <React.Fragment>
          <StyledAddFlashcard>
                {currentFlashcardId && !this.isInFolder() && (<AddToBookmarkIcon/>)}
                {currentFlashcardId && this.isInFolder() && (<AddToBookmarkIcon state='added' isHover={this.state.isHover}/>)}
          </StyledAddFlashcard>
        </React.Fragment>}
         
        </React.Fragment>
    </div>
  }
}
const mapStateToProps = state => ({
  currentFlashcardId: Selectors.Studying.currentFlashcardId(state),
  isMobile: Selectors.isMobile(state),
  isLandscape: Selectors.isLandscape(state),
  addFlashcardToBookmarkResponse: addFlashcardToBookmarkResponse(state),
  removeFlashcardFromBookmarkResponse: removeFlashcardFromBookmarkResponse(state),
  getFolderAddButtonActive:Selectors.getFolderAddButtonActive(state),
  userId: getUserId(state),
  getactiveMediaTab: Selectors.getActiveMediaTab(state)

});
const mapDispatchToProps = {
  addFlashcardToBookmark: Actions.Api.addFlashcardToBookmarkRoutine,
  removeFlashcardFromBookmark: Actions.Api.removeFlashcardFromBookmarkRoutine,
  setActiveMediaTab: Actions.Navigation.setActiveMediaTab,

 // getFlashcardAnswersRoutine: Actions.Api.getFlashcardAnswersRoutine,
};
export default connect(
  mapStateToProps, mapDispatchToProps
)(AddFlashcardToBookmarkContainer);
const StyledAddFlashcard = styled.div`
  display: ${props => props.loading ? 'none' : 'block'}
  width: 26px;
  height: 26px;
  @media (pointer: fine) {
    &:hover {
      background: #EFF4F9;
    }
  }
  &:active {
    border: none;
    background: #E5ECF2;
  }
`;

