import React, { useMemo } from 'react';
import { openMenu } from 'actions/navigation';
import { startOnboarding } from 'actions/onboarding/onboardingActions';
import { isMBUserSelector, userDataSelector, getActiveSubject, isMobile, isLandscape } from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import SupportComponent from 'v2/components/supports-manager/SupportComponents';
import {toggleSupportManagerOverlay} from 'actions/navigation';

type SupportContainerProps = {
  isControlOverlay?: boolean
}

const SupportContainer: React.FC<SupportContainerProps> = ({ isControlOverlay = false }) => {
  const distach = useDispatch();
  const activeSubject = useSelector(getActiveSubject);
  const isMBUser = useSelector(isMBUserSelector);
  const userData = useSelector(userDataSelector);
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);
  const isMobilePortrait = !(hasMobile && hasLandscape);

  const startOnboardingClick =() =>{
    distach(startOnboarding(true))
  }
  const openMenuClick =() =>{
    distach(openMenu())
  }
  const toggleSupportManagerOverlayClick =() =>{
    distach(toggleSupportManagerOverlay(false, false))
  }

  return <SupportComponent isMBUser={isMBUser} userData={userData} startOnboarding={startOnboardingClick} openMenu={openMenuClick} activeSubject={activeSubject}  isControlOverlay={isControlOverlay}  isMobilePortrait={isMobilePortrait} toggleSupportManagerOverlay={toggleSupportManagerOverlayClick} />
}


export default SupportContainer