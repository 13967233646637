import classNames from 'classnames';
import React, { memo, FC, useRef, useImperativeHandle } from 'react'
import {
    AutoSizer as _AutoSizer,
    List as _List,
    ListProps,
    AutoSizerProps,
} from "react-virtualized";
import './flashcard-list.scss';

type FlashcardsInfiniteListProps = {
    onScroll: (val: any) => void;
    hideNavButton: boolean;
    list: any;
    children: any | undefined;
}
const List = _List as unknown as FC<ListProps>;
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
interface FlashcardsRef {
    updateScrollToIndex: (arg: number) => void;
    recalculateRowHeights: () => void
}

export const FLASHCARDS_LIST_ID = 'flashcards-infinite-list';
const FlashcardsInfiniteList = React.forwardRef<FlashcardsRef, FlashcardsInfiniteListProps>((props, ref) => {
    const { onScroll, hideNavButton, list, children } = props
    const listRef = useRef<any>();

    const getRowCount = (list: any, width: number) => {
        const rowHeight = computeRowHeight(list, 0, width);
        const nrOfEmptyRows = Math.floor(window.innerHeight / rowHeight);
        return list.size + nrOfEmptyRows;
    }

    const computeRowHeight = (list: any, index: number, listWidth: number) => {
        const row = list.get(index);
        const SUBTOPIC_HEIGHT = 62;
        const FLASHCARD_MAX_HEIGHT = 400;
        const FLASHCARD_SPACING = 14;
        const ROW_HEIGHT = FLASHCARD_MAX_HEIGHT + FLASHCARD_SPACING;
        const computedRowHeight = Math.min(ROW_HEIGHT, listWidth / 1.5 + FLASHCARD_SPACING);
        if (row && row.get('shouldShowSubtopic')) return SUBTOPIC_HEIGHT + computedRowHeight;
        return computedRowHeight;
    }
    const updateScrollToIndex = (indexToScroll: number) => {
        if (indexToScroll === -1 || isNaN(indexToScroll)) indexToScroll = 0;
        const scrollTop = listRef.current.getOffsetForRow({ alignment: 'start', index: indexToScroll });
        //TODO extract to parent
        const hasSubtopic = list.getIn([indexToScroll, 'shouldShowSubtopic']);
        const FLOATING_SUBTOPIC_HEIGHT = 49;
        const SUBTOPIC_SEPARATOR_SPACING = 30;

        let computedScrollTop = scrollTop + (hasSubtopic ? FLOATING_SUBTOPIC_HEIGHT + SUBTOPIC_SEPARATOR_SPACING : 0);
        if (computedScrollTop === 0) {
            computedScrollTop = 1;
        }
        listRef.current.scrollToPosition(computedScrollTop);
    };

    const recalculateRowHeights = () => {
        listRef.current.recomputeRowHeights();
    }

    useImperativeHandle(ref, () => ({ updateScrollToIndex, recalculateRowHeights }), [listRef]);

    return <AutoSizer>
        {({ width, height }) => {
            return <List className={classNames('flashcard-list-wrapper px-2.5 pb-0 pt-20', { '!pt-[136px]': !hideNavButton })}
                id={FLASHCARDS_LIST_ID}
                onScroll={onScroll}
                MBExernalExercise={hideNavButton}
                ref={listRef}
                height={height}
                overscanRowCount={1}
                scrollToAlignment={'center'}
                rowCount={getRowCount(list, width)}
                rowHeight={(data) => {
                    return computeRowHeight(list, data.index, width);
                }}
                rowRenderer={({ key, index, style }) => {
                    const row = list.get(index);
                    return children({
                        key, index, style, row
                    });
                }}
                width={width}
            />
        }}
    </AutoSizer>
})

export default memo(FlashcardsInfiniteList);