import classNames from 'classnames';
import { FC, useRef, useEffect } from 'react'

type OverlayProps = {
  overlay: boolean
  className?:string | boolean
}  

const Overlay: FC<OverlayProps> = ({overlay, className}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() =>{
    ref.current?.addEventListener('ontouchmove', onTouchMove);
    return() =>{
      ref.current?.removeEventListener('ontouchmove', onTouchMove)
    }
  })

  function onTouchMove(e:any){
    e.preventDefault();
    e.stopPropagation();
  }
  return <div className={classNames(className,{'overlay': overlay})} ref={ref}></div>
}


export default Overlay