import React, {Component} from 'react';
import Markings from './Markings';
import {connect} from 'react-redux';
import * as NavigationActions from 'actions/navigation';
/**
 * @deprecated TODO- delete not use any more
 */
class MarkingsContainer extends Component {
  render() {
    return <Markings {...this.props}/>;
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  setActiveFlashcardTab: NavigationActions.setActiveFlashcardTab,
};
export default connect(mapStateToProps, mapDispatchToProps)(MarkingsContainer);
