import {SCROLL_TO_PREVIEW} from 'actions/studying/types';
import {
  OPEN_NODES, SELECT_FLASHCARDS,
  SET_ACTIVE_NODES,
  SET_TREE_NODES,
  TOGGLE_NODE_SELECTION,
  TOGGLE_SUBTOPIC_VISIBILITY,
  TOGGLE_TOPIC_VISIBILITY,
  SELECT_ACTIVE_TOPIC_ID,
  SELECT_ACTIVE_SUB_TOPIC_ID,
  SET_FILTERED_EXERCISE_FLASHCARD_IDS,
} from 'actions/topics-tree/topicsTreeTypes';
import {Map, fromJS} from 'immutable';

export default (state = fromJS({}), action) => {
  let path, current;

  switch (action.type) {
    case OPEN_NODES: {
      return state.withMutations(state => {
        let isDirectParentOpen = state.getIn(interleaveParentsWithChildren(action.parentIds).slice(0, -1).concat('open'));

        setChildrenFieldValueRecursive(state, [action.parentIds[0]], 'open', false);

        if (isDirectParentOpen) {
          return setParentsAboveField(state, action.parentIds, 'open', true);
        }
        return setParentsAboveField(state, action.parentIds.slice(0, -1), 'open', true);
      });
    }
    case TOGGLE_TOPIC_VISIBILITY:
      path = interleaveParentsWithChildren(action.parentIds).concat(action.id, 'open');
      current = state.getIn(path, false);
      state = state.setIn(path, !current);
      state = state.withMutations(state => setChildrenFieldValueRecursive(state, action.parentIds.concat(action.id), 'open', false));

      return state;
    case TOGGLE_SUBTOPIC_VISIBILITY:
      path = interleaveParentsWithChildren(action.parentIds).concat(action.id, 'open');
      current = state.getIn(path, false);

      return state.setIn(path, !current);
    case TOGGLE_NODE_SELECTION:
      path = interleaveParentsWithChildren(action.parentIds);
      //toggle selected state
      current = state.getIn(path.concat(action.id, 'selected'));
      state = current ? setInitial(state, path.concat(action.id)) : setSelected(state, path.concat(action.id));
      state = state.withMutations(state => {
        state = setChildrenFieldValueRecursive(state, action.parentIds.concat(action.id), 'selected', !current);
        return setChildrenFieldValueRecursive(state, action.parentIds.concat(action.id), 'partial', false);
      });
      state = setParentsAbove(state, action.parentIds, action.id);
      return state;
    case SET_ACTIVE_NODES: {
      path = interleaveParentsWithChildren(action.parentIds);
      state = setActive(state, path.concat(action.id));

      state = state.withMutations(state => {
        setChildrenFieldValueRecursive(state, [action.parentIds[0]], 'active', false);
        return setParentsAboveActive(state, action.parentIds.concat(action.id), 'active', true);
      });
      return state;
    }
    case SET_TREE_NODES:
      return state.setIn(action.parentIds, action.data);
    case SCROLL_TO_PREVIEW:
      return state.set('scrollToPreviewId', action.id);
    case SELECT_FLASHCARDS: {
      return state.withMutations(state => {
        return setFlashcardsAsSelected(state, [], action.flashcardIds, state);
      });
    }
    case SELECT_ACTIVE_TOPIC_ID: {
      const topicid = state.get('topicid');
      let newState = state;
      if(topicid !== action.topicId){
        if(state.get('subtopicid')){
          newState = state.set('subtopicid', null);
        }
      }
      return newState.set('topicId', action.topicId);
    }
    case SELECT_ACTIVE_SUB_TOPIC_ID: {
      return state.set('subtopicId', action.subtopicId);
    }
    case SET_FILTERED_EXERCISE_FLASHCARD_IDS:
      return state.set('filterExerciseFlashcardIds', action.data);
    default:
      return state;
  }
};

const hasAnyChildSelected = (state, path) => {
  return state.getIn(interleaveParentsWithChildren(path)).some(item => item.get('selected'));
};

const hasAnyChildSelectedOrPartial = (state, path) => {
  return state.getIn(interleaveParentsWithChildren(path)).some(item => item.get('selected') || item.get('partial'));
};

const hasAllChildrenSelected = (state, path) => {
  return state.getIn(interleaveParentsWithChildren(path)).every(item => item.get('selected'));
};

const setSelected = (state, path) => {
  return state.setIn(path.concat('selected'), true)
    .setIn(path.concat('partial'), false);
};

const setPartial = (state, path) => {
  return state.setIn(path.concat('selected'), false)
    .setIn(path.concat('partial'), true);
};

const setInitial = (state, path) => {
  return state.setIn(path.concat('selected'), false)
    .setIn(path.concat('partial'), false);
};

const setActive = (state, path) => {
  return state.setIn(path.concat('active'), true);
};

/**
 * Checks if a certain path inside the state is defined
 * @param state
 * @param path
 * @returns {any | boolean}
 */
const pathExists = (state, path) => {
  const pathToCheck = state.getIn(path);
  return pathToCheck && pathToCheck.size > 0;
};

/**
 * Receives an array of parent ids and returns an interleaved array of the following form:
 * [p0, 'children', p1, 'children', p2, 'children'], where p* is the parentId
 * @param parentIds
 * @returns {Array}
 */
const interleaveParentsWithChildren = (parentIds) => {
  if (!parentIds) return [];
  const childrenArray = new Array(parentIds.length);
  childrenArray.fill('children');
  return parentIds.reduce((accumulator, currentValue, currentIndex) => {
    if (childrenArray[currentIndex])
      return accumulator.concat(currentValue, childrenArray[currentIndex]);
    return accumulator.concat(currentValue);
  }, []);
};

/**
 * Sets a value on a desired field in all nodes that have children
 * @param state
 * @param path
 * @param fieldToSet
 * @param fieldValue
 * @returns {*}
 */
const setChildrenFieldValueRecursive = (state, path, fieldToSet, fieldValue) => {
  const filteredFlashcardList = state.get('filterExerciseFlashcardIds');
  let interleavedPath = interleaveParentsWithChildren(path);
  let finalState = state;
  if (!pathExists(state, interleavedPath)) {
    return state;
  }
  let children = state.getIn(interleavedPath);
  children.forEach((child, childKey) => {
    if(path.length == 4){
      if(filteredFlashcardList && filteredFlashcardList.includes(parseInt(childKey))){
        finalState = state.setIn(interleavedPath.concat(childKey, fieldToSet), fieldValue);
        setChildrenFieldValueRecursive(state, path.concat(childKey), fieldToSet, fieldValue);
      }
    } else {
      finalState = state.setIn(interleavedPath.concat(childKey, fieldToSet), fieldValue);
      setChildrenFieldValueRecursive(state, path.concat(childKey), fieldToSet, fieldValue);
    }
  });
  return finalState;
};

const setParentsAbove = (state, parentIds) => {
  if (parentIds.length === 0) return state;
  const interleavedPath = interleaveParentsWithChildren(parentIds);

  if (hasAllChildrenSelected(state, parentIds)) {
    state = setSelected(state, interleavedPath.slice(0, -1));
  } else if (hasAnyChildSelectedOrPartial(state, parentIds)) {
    state = setPartial(state, interleavedPath.slice(0, -1));
  } else {
    state = setInitial(state, interleavedPath.slice(0, -1));
  }

  return setParentsAbove(state, parentIds.slice(0, -1));
};

const setParentsAboveActive = (state, parentIds) => {
  if (parentIds.length === 0) return state;
  const interleavedPath = interleaveParentsWithChildren(parentIds);
  state = setActive(state, interleavedPath.slice(0, -1));
  return setParentsAboveActive(state, parentIds.slice(0, -1));
};

const setParentsAboveField = (state, parentIds, field, fieldValue) => {
  if (parentIds.length === 0) return state;
  const interleavedPath = interleaveParentsWithChildren(parentIds);
  state = state.setIn(interleavedPath.slice(0, -1).concat(field), fieldValue);
  return setParentsAboveField(state, parentIds.slice(0, -1), field, fieldValue);
};

const setFlashcardsAsSelected = (tempState, parentIds, flashcards, initialState) => {
  tempState.forEach((stateContent, index) => {
    if (!Map.isMap(stateContent)) return;

    if (stateContent.get('children')) {
      setFlashcardsAsSelected(stateContent.get('children'), parentIds.concat(index), flashcards, initialState);
    } else {
      if (flashcards.find(item => item === index)) {
        const path = interleaveParentsWithChildren(parentIds);
        initialState.setIn(path.concat(index, 'selected'), true);
        setParentsAbove(initialState, parentIds);
      }
    }
  });
};
