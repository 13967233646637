import classNames from 'classnames';
import React from 'react'
import { useSelector } from 'react-redux';
import {getActiveSubject} from 'selectors'
import './teachingLevel.scss'

type TeachingLevelSeperatorProps = {
    tlvl: string;
    tlvlTag?: string;
    isExams?: boolean;
    slTag?: boolean;
    isTopic?: boolean;
    size?: 'small' | 'normal'
}  


const TeachingLevelSeperator: React.FC<TeachingLevelSeperatorProps> = ({tlvl, tlvlTag, isExams=false, slTag, isTopic, size}) => {
    const activeSubject = useSelector(getActiveSubject)
    const subjectSlug = activeSubject?.slug;
    let optionTag = null, historyTag = null, preIb = null;
    if (tlvl && tlvl.includes('Option')) {
        optionTag = tlvl.split(' | ')[0];
      }
      if (tlvl && (tlvl.includes('Prescribed') || tlvl.includes('World history') || tlvl === "HL options")) {
        historyTag = tlvl;
      }
      if (tlvl && tlvl === "Pre-IB") {
        preIb = tlvl;
      }
      let isTlvlTag;
      if(tlvl && tlvl === 'SL' && isExams){
        isTlvlTag = tlvl === 'SL' ? true : false;
      }
      const isTlvl = tlvlTag === 'SL&HL' ? true : false;
    return <>
        {(isTlvl || isTlvlTag) ? 
            <>
                <TeachingLevelHeader text={'SL'} type={'SL'} isExams={isExams} /> 
                <div className='pl-1.5' />
                <TeachingLevelHeader text={'HL'} type={'HL'} isExams={isExams} />
                {optionTag != null &&
                    <>
                        <div className='pl-1.5' />
                        <TeachingLevelHeader text={optionTag} type={tlvl} isExams={isExams} /> 
                    </>
                }
                {historyTag != null &&
                    <>
                        <div className='pl-1.5' />
                        <TeachingLevelHeader text={historyTag} type={tlvl} isTopic={isTopic} isHistory={true} /> 
                    </>
                } 
            </> 
            : 
            <>
                {historyTag !== null ?
                    <>
                        <TeachingLevelHeader text={'HL'} type={tlvl} isExams={isExams} /> 
                        <div className='pl-1.5' />
                        <TeachingLevelHeader text={'Options'} type={"Options"} isExams={isExams} /> 
                    </>
                    : 
                    <>
                        {preIb ? 
                            <TeachingLevelHeader text={tlvl} type={tlvl} isExams={isExams} />  
                        : 
                            <>
                                {(subjectSlug === "es-amp-s" || subjectSlug === "mathematics" || subjectSlug === "maths-a-amp-a" || subjectSlug === "maths-a-amp-i") ? 
                                    <TeachingLevelHeader text={tlvl} type={tlvl} isExams={isExams} />   
                                 :
                                    <>
                                        {slTag ? 
                                            <TeachingLevelHeader text={'SL'} type={"SL"} isExams={isExams} size={size}/>:
                                            <TeachingLevelHeader text={'HL'} type={"HL"} isExams={isExams} size={size}/> 
                                        }
                                    </> 
                                }
                            </>
                        }
                        {optionTag != null &&
                            <>
                                <div className='pl-1.5' />
                                <TeachingLevelHeader text={optionTag} type={tlvl} isExams={isExams} /> 
                            </>
                        }
                    </> 
                }
            </> 
        }
    </>
}

export default TeachingLevelSeperator;

const TeachingLevelHeader = ({type, isExams, isHistory, isTopic, text, size}:{type: string, isExams?: boolean, isHistory?: boolean, isTopic?: boolean, text: string, size?:'small'|'normal'}) => {
    return <div className={classNames('teaching-header-wrapper text-white rounded-[5px] font-normal', {'teaching-header-exam text-[9.4px] !leading-[13.5px] !min-w-[21px] h-4 w-[22px] rounded-[3.64px]': isExams || size === 'small', 'text-[13px] leading-[18px] min-w-[30px]':!isExams || size === 'normal' }, {'teaching-level-active-topic': (!isTopic && isHistory)})} style={{background : computeColor(type), padding: isExams ? examsPadding(type) : 
        size === 'small' ? '1.45px 3.73px 1.55px 5.09px' : 
        computePadding(type)}}>
        {text}
    </div>
}

const computeColor = (type: string) => {
    if (type.indexOf('SL') > -1 && !type.includes('Option')) return '#458CFC';
    if (type.indexOf('HL') > -1 && !type.includes('Option')) return '#1FCD6C';
    if (type.includes('Option') || type.includes('Prescribed') || type.includes('World history')) return '#97A5B3';
    return '#458CFC';
};
const computePadding = (type: string) => {
    if (type.indexOf('SL') > -1) return '2px 8px';
    if (type.indexOf('HL') > -1) return '2px 6px 2px 7px';
    return '2px 8px';
};
const examsPadding = (type: string) => {
    if (type.indexOf('SL') > -1) return '1.45px 4.73px 1.55px 6.09px';
    if (type.indexOf('HL') > -1) return '1.45px 3.73px 1.55px 5.09px';
    return '1.45px 4.73px 1.55px 6.09px';
}

