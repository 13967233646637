import React, { useState, useRef, useMemo } from "react"
import { useDispatch, useSelector } from 'react-redux';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import SaveButton from 'v2/components/navigation-header/SaveButton';
import BackToProfileButtonContainer from 'v2/pages/profile/BackToProfileButtonContainer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RenderInputField, SelectField, RenderDatepicker } from 'v2/utils/form-helper';
import useScrollDetection from "hooks/useScrollDetection";
import { userProfileMap } from 'selectors'
import moment from 'moment'
import {saveProfile} from 'actions/api'

type AboutYouPageComponentProps = {
}

const AboutYouPageComponent: React.FC<AboutYouPageComponentProps> = (props) => {
  const disptach = useDispatch();
  const ref = useRef(null);
  const userProfile = useSelector(userProfileMap);
  const dob = useMemo(() => {
    let dateOfBirth = userProfile.dateOfBirth;
    if (dateOfBirth) {
       {/* @ts-ignore */}
      if (window.Modernizr.inputtypes.date) {
        dateOfBirth = moment(dateOfBirth * 1000).format('YYYY-MM-DD');
      } else {
        dateOfBirth = moment(dateOfBirth * 1000);
      }
    }
    return dateOfBirth
  }, [userProfile])



  const initialFormValue = {
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    school: userProfile.school ?? '',
    selfRole: userProfile.selfRole ?? '',
    grade: userProfile.grade ?? '',
    dateOfBirth: dob ?? ''
  }
  const { isScrolled, isScrolled2 } = useScrollDetection({ WrappedRef: ref, threshold1: 20 })

  const [isFocused, setFocused] = useState({ firstName: false, lastName: false, school: false, selfRole:false, grade:false, dateOfBirth:false });
  const { values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue } = useFormik({
    validationSchema: validation,
    enableReinitialize: true,
    initialValues: initialFormValue,
    onSubmit: (values) => {
      console.log(values, "sdsdsdsd")
      disptach(saveProfile(values))
    }
  });

  const invalid = !(values.firstName.length > 0 && values.lastName.length > 0)
  return <div className="profile-details-page" ref={ref}>
    <NavigationHeader isCollapsed={isScrolled} extendedWidth title="About you" leftSection={<BackToProfileButtonContainer />} rightSection={(<SaveButton disabled={invalid} onClick={handleSubmit} />)} />
    <div className='profile-details-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-input-wrapper'>
          <RenderInputField
            label={'First name'}
            type='text'
            name='firstName'
            placeholder='Enter your first name'
            className='form-input'
            showIcon={true}
            touched={touched.firstName}
            error={errors.firstName}
            reset={() => setFieldValue("firstName", "")}
            active={isFocused.firstName}
            value={values.firstName}
            onChange={handleChange}
            onBlur={(e:any) => [handleBlur(e), setFocused({...isFocused, firstName: false}) ]}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ ...isFocused, firstName: true })
            }}
          />
        </div>
        <div className='form-input-wrapper'>
          <RenderInputField
            label={'Last name'}
            type='text'
            name='lastName'
            placeholder='Enter your last name'
            className='form-input'
            showIcon={true}
            touched={touched.lastName}
            error={errors.lastName}
            reset={() => setFieldValue("lastName", "")}
            active={isFocused.lastName}
            value={values.lastName}
            onChange={handleChange}
            onBlur={(e:any) => [handleBlur(e),setFocused({...isFocused, lastName: false}) ]}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ ...isFocused, lastName: true })
            }}
          />
        </div>


        <div className='form-input-wrapper'>
          <RenderDatepicker
            label={'Date of birth (optional)'}
            name='dateOfBirth'
            className='form-input'
            id={'about-datepicker'}
            touched={touched.dateOfBirth}
            error={errors.dateOfBirth}
            reset={setFieldValue}
            active={isFocused.dateOfBirth}
            value={values.dateOfBirth}
            disabled={false}
            onChange={handleChange}
            placeholder={'Select your date of birth'}
            onBlur={(e:any) => [handleBlur(e),setFocused({...isFocused, dateOfBirth: false}) ]}
            onFocus={() => {
              setFocused({ ...isFocused, dateOfBirth: true })
            }} />
        </div>


        <div className='form-input-wrapper'>
          <SelectField
            label={'User type (optional)'}
            name='selfRole'
            className='form-input'
            showIcon={true}
            touched={touched.selfRole}
            error={errors.selfRole}
            reset={() => setFieldValue("selfRole", "")}
            active={isFocused.selfRole}
            value={values.selfRole}
            onChange={handleChange}
            onBlur={(e:any) => [handleBlur(e),setFocused({...isFocused, selfRole: false}) ]}
            disabled={false}
            onFocus={() => {
              setFocused({ ...isFocused, selfRole: true })
            }} >
            <option value=''>Who are you?</option>
            <option value='ROLE_STUDENT'>Student</option>
            <option value='ROLE_TEACHER'>Teacher</option>
            <option value='ROLE_OTHER'>Other</option>
          </SelectField>
        </div>

        <div className='form-input-wrapper'>
          <RenderInputField
            label={'School (optional)'}
            type='text'
            name='school'
            placeholder='Where do you go to school?'
            className='form-input'
            showIcon={true}
            touched={touched.school}
            error={errors.school}
            reset={() => setFieldValue("school", "")}
            active={isFocused.school}
            value={values.school}
            onChange={handleChange}
            onBlur={(e:any) => [handleBlur(e),setFocused({...isFocused, school: false}) ]}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ ...isFocused, school: true })
            }}
          />
        </div>

        <div className='form-input-wrapper'>
          <SelectField
            label={'Grade (optional)'}
            name='grade'
            className='form-input'
            showIcon={true}
            touched={touched.grade}
            error={errors.grade}
            reset={() => setFieldValue("grade", "")}
            active={isFocused.grade}
            value={values.grade}
            onChange={handleChange}
            onBlur={(e:any) => [handleBlur(e),setFocused({...isFocused, grade: false}) ]}
            disabled={false}
            onFocus={() => {
              setFocused({ ...isFocused, grade: true })
            }} >
            <option value=''>Which grade are you in?</option>
            <option value='11'>11th grade</option>
            <option value='12'>12th grade</option>
          </SelectField>
        </div>
      </form>
    </div>
  </div>
}

let validation = Yup.object().shape({
  firstName: Yup.string().required("This field is required!"),
  lastName: Yup.string().required("This field is required!"),
});
export default AboutYouPageComponent;