import {setActiveFlashcardTab, toggleSubjectsManagerOverlay} from 'actions/navigation';
import ExerciseNotification, {StyledExerciseNotification} from 'components/exercise/ExerciseNotification';
import CounterNotification from 'v2/components/notifications/CounterNotification';
import FlashcardNavigationMenu from 'components/flashcard-navigation/FlashcardNavigationMenu';
import PropTypes from 'prop-types';
import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import * as Selectors from 'selectors';
import {activeSubjectExerciseNotificationsCount, exerciseNotificationsCount} from 'selectors/exerciseSelectors';
import styled, {css} from 'styled-components/macro';
import media from 'ui-components/helpers/media';
import PointerEvents from 'ui-components/helpers/PointerEvents';
import Row from 'ui-components/helpers/Row';
import './flashcard-navigation.scss';

class FlashcardNavigationContainer extends Component {
  constructor(props) {
    super(props);

    this.overlayEl = createRef();
  }

  render() {
    if (!this.props.isFlashcardNavVisible) return null;

    //todo is loader needed here?
    if (this.props.inProgress || !this.props.activeSubject) return null;

    const {isExpanded, exerciseNotificationsCount, activeSubjectExerciseNotificationsCount} = this.props;
    const hasNotifications = exerciseNotificationsCount > 0;

    return (
      <Wrapper ref={this.overlayEl}
        onClick={this.onOverlayClick}
        className={`flashcard-navigation ${isExpanded ? 'overlay' : ''}`}>
        <Content isExpanded={isExpanded} offset={hasNotifications ? 57 : 0}>
          {!isExpanded && (
            <PointerEvents value={'none'} as={Row} justifyContent={'center'}>
              {hasNotifications && <CounterNotification amount={exerciseNotificationsCount}/>}
            </PointerEvents>
          )}

          <PointerEvents value={'all'}>
            <FlashcardNavigationMenu
              hasClassroomNotification={activeSubjectExerciseNotificationsCount > 0}
              isExpanded={isExpanded}
              activeTab={this.props.activeTab}
              onToggle={() => this.props.toggleSubjectsManagerOverlay(true, true)}
              subject={this.props.activeSubject.get('slug')}
            />
          </PointerEvents>
        </Content>
      </Wrapper>
    );
  }

  onOverlayClick = (event) => {
    if (event.target !== this.overlayEl.current || !this.props.isExpanded) return;

    this.props.toggleFlashcardNav();
  };
}

const mapStateToProps = (state) => ({
  activeTab: Selectors.getActiveFlashcardTab(state),
  isExpanded: Selectors.isFlashcardNavExpanded(state),
  activeSubject: Selectors.getActiveSubject(state),
  inProgress: Selectors.isApiRequestInProgress(state, 'subjects'),
  isFlashcardNavVisible: Selectors.isFlashcardNavVisible(state),
  exerciseNotificationsCount: exerciseNotificationsCount(state),
  activeSubjectExerciseNotificationsCount: activeSubjectExerciseNotificationsCount(state)
});
const mapDispatchToProps = {
  setActiveFlashcardTab: setActiveFlashcardTab,
  toggleSubjectsManagerOverlay: toggleSubjectsManagerOverlay,
};

FlashcardNavigationContainer.propTypes = {
  // Connect
  activeTab: PropTypes.number.isRequired,
  activeSubject: PropTypes.object,
  toggleSubjectsManagerOverlay: PropTypes.func.isRequired,
  setActiveFlashcardTab: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  isFlashcardNavVisible: PropTypes.bool,
  exerciseNotificationsCount: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlashcardNavigationContainer);


export const Wrapper = styled.div`
  ${StyledExerciseNotification} {
    margin-bottom: 27px;
  }
`;

const Content = styled.div`
  pointer-events: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: ${props => props.theme.leftMenuWidth};
  transform: translate3d(0, calc(100% - ${props => (props.offset || 0) + 47}px), 0);
  transition: transform 0.35s cubic-bezier(.25, .75, .5, 1);

  ${props => props.isExpanded && css`
    transform: none;
  `}
  
  ${media.phone(css`
    width: 100%;
  `)}
`;
