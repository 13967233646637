import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';

const ExerciseNotification = ({className, amount}) =>
  <StyledExerciseNotification as={Row} justifyContent={'center'} alignItems={'center'} className={className}>
      <span>{amount}</span>
  </StyledExerciseNotification>;

ExerciseNotification.propTypes = {
  amount: PropTypes.number,
  className: PropTypes.string,
};

export default ExerciseNotification;


export const StyledExerciseNotification = styled.div`
  background: linear-gradient(135deg,#FC4B07 0%,#FC2666 100%);
  line-height: 1.1;
  border-radius: 10px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 13px;
  height: 20px;
  min-width: 20px;
  padding: 0 5px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans";
`;
