import {Map} from 'immutable';

export const mediaStatus = state => state.get('status');
export const statusById = (state, id) => {
    return state.getIn(['status', parseInt(id)], Map({
        flashcard: Map({}),
        revision: Map({}),
        video: Map({}),
        exam:Map({freeResponse:Map({}),msqTotal:Map({})})
    }));
}

/**
 * 
 * @param {*} state 
 * @param {*} exerciseId 
 * @returns
 */
export const exerciseStatusById = (state, exerciseId) => {
    const PREFIX = 'EXERCISE-';
    return state.getIn(['status', PREFIX + exerciseId], Map({
        flashcard: Map({})
    }));
}