import React, {memo, useEffect, useRef} from 'react'
import {FLASHCARDS_LIST_ID} from 'v2/components/flashcards-vertical-list/FlashcardsInfiniteList';
import debounce from 'lodash.debounce';

type ViewportPositionDetectorProps = {
    onTrigger: () => void;
    height: string;
}  

const ViewportPositionDetector: React.FC<ViewportPositionDetectorProps> = ({onTrigger, height}) => {
    const ref = useRef<any>();

    const onScroll = debounce(() => {
        if (!ref.current) return;
        const distanceFromTopViewport = ref.current.getBoundingClientRect().top
        const HEADER_HEIGHT = 63;
        const SUBTOPIC_SPACING = 34;
        if (distanceFromTopViewport >= 0 && distanceFromTopViewport <= (height + HEADER_HEIGHT + SUBTOPIC_SPACING / 2)) {
          onTrigger();
        }
    }, 300);

    useEffect(() => {
        document.getElementById(FLASHCARDS_LIST_ID)?.addEventListener('scroll', onScroll);
        return () => {
            document.getElementById(FLASHCARDS_LIST_ID)?.removeEventListener('scroll', onScroll);
            onScroll.cancel();
        }
    }, [])

    return <div ref={ref} />
}

export default memo(ViewportPositionDetector);