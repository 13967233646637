import React, {memo} from 'react'
import './notifications.scss'

type CounterNotificationProps = {
    amount?: number;
    className?: string; 
}  

const CounterNotification: React.FC<CounterNotificationProps> = ({amount, className}) => {
    return <div className={`counter-notification-wrapper ${className}`}>
        {amount}
    </div>
}

export default memo(CounterNotification);