import React from 'react'
import './moreControlOverlay.scss';
import { CloseIcon, KeyboardIcon, SupportIcon, SwopIcon, LandscapeIcon, PortraitIcon, ReportContentErrorIcon } from 'icons/toolboxIcons';
import { useDispatch, useSelector } from 'react-redux';
import {toggleMoreControlOverlay, toggleSupportManagerOverlay, toggleKeyboardNavigationOverlay} from 'actions/navigation';
import { isLandscape, isMobile } from 'selectors/browser/browserSelector';
import {getUserId} from 'selectors/user';
import {swap, currentFlashcard} from 'selectors/studying'
import { getLastMediaTypeVisited } from 'helpers';
import { toggleSwap } from 'actions/studying'
import { carouselFlashcardIds, carouselFlashcardIndex, hasNext5Flashcards, hasPrev5Flashcards } from 'selectors/carouselSelectors';
import { MoreNext5Icon, MorePre5Icon } from 'icons/toolboxIcons';
import {calculateFlashcardIndex} from 'actions/carousel/carouselActions';
import { getActiveSubject, isMoreControlOverlayVisible, subjectsTeachingLevel } from 'selectors';

type MoreControlOverlayProps = {
  currentId: string | number;
}

const MoreControlOverlay:React.FC<MoreControlOverlayProps> = ({currentId}) => {
  const dispatch = useDispatch();
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);
  const userId = useSelector(getUserId);
  const hasPrev5 = useSelector(hasPrev5Flashcards);
  const hasNext5 = useSelector(hasNext5Flashcards);
  const carouselIndex = useSelector(carouselFlashcardIndex);
  const carouselIds = useSelector(carouselFlashcardIds);
  const current = useSelector(currentFlashcard)?.toJS();
  const subject = useSelector(getActiveSubject);
  const tlvl = useSelector(subjectsTeachingLevel);
  const hasMoreControlOverlayVisible = useSelector(isMoreControlOverlayVisible);
  const hasSwap = useSelector(swap);

  const mediaType = getLastMediaTypeVisited(userId);
  const shouldRenderNavigationControls = currentId && hasMobile && hasLandscape;

  const onClose = (e:React.MouseEvent) => {
    dispatch(toggleMoreControlOverlay(false, false));
    e.stopPropagation();
  }

  const onSupportClick = (e:React.MouseEvent) => {
    dispatch(toggleMoreControlOverlay(false, false));
    dispatch(toggleSupportManagerOverlay());
    e.stopPropagation();
  }

  const computeErrorSubject = () => {
    if (current?.length === 0) return null;
    let mediaTitle;
    const currentFlashcardNo = current?.numbering;
    const currentFlashcardType = current?.cType;
    if(currentFlashcardType == "flashcard") {
      mediaTitle = 'card';
    }
    if(currentFlashcardType == "revision"){
      mediaTitle = 'page';
    }
    if(currentFlashcardType == "video"){
      mediaTitle = 'video';
    }
    if(currentFlashcardType == "exams"){
      mediaTitle = 'exam';
    }
    const activeTopic = currentFlashcardType==='exams' ? current?.parentsData?.[0]?.parentsData?.find((item: any) => item?.type === 'TOPIC')?.title :
    current?.parentsData?.find((item: any) => item?.type === 'TOPIC')?.title;
    const subjectTitle = subject?.title;
    const subjectId = subject?.id;
    const teachingLevelTitle = tlvl[`${subjectId}`];
    if(mediaTitle == 'exam'){
      const examName = current?.experienceData?.exam_name;
      const paperData = current?.experienceData?.tag_labels?.filter((item:any)=>{
        return item?.type === 'paper_type'
      });
      const paperName = paperData?.['0']?.label;
      const questionName = current?.questionTitle;
      return `subject=Error report on subject: ${subjectTitle}%2C Teaching Level: ${teachingLevelTitle}%2C Exam: ${examName}%2C Paper: ${paperName}%2C Question name: ${questionName}`;
    }else{
      return `subject=Error report on subject: ${subjectTitle}%2C package: ${teachingLevelTitle}%2C topic: ${activeTopic}%2C ${mediaTitle} #${currentFlashcardNo}`;
    }
  }

  const onKeyboardClick = (e:React.MouseEvent) => {
    e.stopPropagation();
    dispatch(toggleMoreControlOverlay(false, false));
    dispatch(toggleKeyboardNavigationOverlay())
  }

  const prev5Flashcard = (e:React.MouseEvent) => {
    e.stopPropagation();
    let newIndex = carouselIndex - 5;

    if (newIndex < 0) {
      return;
    }
    dispatch(calculateFlashcardIndex(carouselIds?.[newIndex]));
  }

  const next5Flashcard = (e:React.MouseEvent) => {
    e.stopPropagation();
    let newIndex = carouselIndex + 5;

    if (newIndex > carouselIds.length) {
      return;
    }
    dispatch(calculateFlashcardIndex(carouselIds?.[newIndex]));
  };
  if (!hasMoreControlOverlayVisible) return null;

return <div className='more-control-overlay fixed top-0 left-0 bottom-0 right-0 bg-[#133956] opacity-75 z-[5] max-w-[1440px] mx-auto' onClick={()=>dispatch(toggleMoreControlOverlay(false, false))}>
    <div className='fixed flex items-center justify-between w-full h-full'>
      <div className='fixed top-[58px] flex flex-col bottom-controls'>
        <CloseIcon onClick={onClose} />
        <SupportIcon onClick={onSupportClick} />
        <a href={`mailto:osc@oxfordstudycourses.com?${computeErrorSubject()}`} target="_top"><ReportContentErrorIcon onClick={()=>{}} /></a>
        {!hasMobile && <KeyboardIcon onClick={onKeyboardClick} />}
        {mediaType == "flashcard" && <SwopIcon active={hasSwap} onClick={()=>dispatch(toggleSwap())}/>}
      </div>
    </div>
    {shouldRenderNavigationControls && <>
      {hasPrev5 && <div className='prev-5-btn w-9 h-9 z-[4] bottom-3 absolute left-2 rounded-[22px] flex items-center justify-center' onClick={prev5Flashcard}>
        <MorePre5Icon />
      </div>}
      {hasNext5 && <div className='prev-5-btn w-9 h-9 z-[4] bottom-3 absolute right-2 rounded-[22px] flex items-center justify-center' onClick={next5Flashcard}>
        <MoreNext5Icon />
      </div>}
    </>}
  </div>
}

export default MoreControlOverlay