import React from 'react';

export function ContactUsIcon(props) {
  return <svg width="19px" height="16px" viewBox="0 0 19 16" version="1.1">
    <title>Icons/Profile/Contact us</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="A1---Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
      transform="translate(-253.000000, -352.000000)">
      <g id="Content" transform="translate(89.000000, 226.000000)" stroke="#FFFFFF" strokeWidth="1.8">
        <g id="Contact-us" transform="translate(14.000000, 116.000000)">
          <g id="Icons/Profile/Contact-us/White" transform="translate(149.000000, 8.000000)">
            <path
              d="M17.27834,12.9095457 L18.5800874,16.4699487 L14.4683855,15.8071625 C13.1924596,16.5603128 11.6549123,17 10,17 C5.581722,17 2,13.8659932 2,10 C2,6.13400675 5.581722,3 10,3 C14.418278,3 18,6.13400675 18,10 C18,11.0381345 17.7417282,12.0234866 17.27834,12.9095457 Z"
              id="Combined-Shape-Copy"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

export function SupportIcon(props) {
  return <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="34" rx="17" fill="#378EF8"/>
      <circle cx="17" cy="17" r="9.35" stroke="white" strokeWidth="1.3"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.0829 18.5537C15.8067 18.5537 15.5829 18.3299 15.5829 18.0537V17.9264C15.5829 17.4498 15.674 17.0474 15.8563 16.7192C16.0386 16.3909 16.3622 16.0712 16.827 15.7599C17.1962 15.5119 17.4616 15.2882 17.6234 15.0889C17.7852 14.8895 17.8661 14.6609 17.8661 14.4032C17.8661 14.1989 17.7795 14.0372 17.6063 13.9181C17.4331 13.799 17.2076 13.7394 16.9296 13.7394C16.176 13.7394 15.4753 14.0086 15.0253 14.2308C14.7495 14.3669 14.3962 14.2688 14.2634 13.9914L13.7395 12.8975C13.6383 12.6862 13.6988 12.4353 13.9016 12.3179C14.4263 12.0141 15.5467 11.5 17.121 11.5C18.1373 11.5 18.9348 11.7383 19.5136 12.2149C20.0923 12.6914 20.3817 13.3406 20.3817 14.1625C20.3817 14.7509 20.253 15.2603 19.9955 15.6906C19.738 16.121 19.3267 16.5283 18.7616 16.9125C18.2831 17.2431 17.9834 17.4851 17.8627 17.6383C17.7419 17.7914 17.6815 17.9726 17.6815 18.1817C17.6815 18.3872 17.515 18.5537 17.3095 18.5537H16.0829ZM15.2889 21.1068C15.2889 20.6594 15.4109 20.3141 15.6547 20.071C15.8985 19.8278 16.2574 19.7062 16.7313 19.7062C17.1871 19.7062 17.5368 19.829 17.7806 20.0746C18.0245 20.3202 18.1464 20.6642 18.1464 21.1068C18.1464 21.5493 18.0199 21.8921 17.767 22.1353C17.514 22.3784 17.1688 22.5 16.7313 22.5C16.2802 22.5 15.927 22.3796 15.6718 22.1389C15.4165 21.8982 15.2889 21.5542 15.2889 21.1068Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear" x1="17" y1="-17" x2="-17" y2="17" gradientUnits="userSpaceOnUse">
          <stop stopColor="#43DAF0"/>
          <stop offset="1" stopColor="#395BF8"/>
        </linearGradient>
      </defs>
      </svg>
  ;
}

export function LogoutIcon() {
  return (
    <svg width="17px" height="20px" viewBox="0 0 17 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <linearGradient x1="32.6989619%" y1="0%" x2="67.3010381%" y2="146.01571%" id="linearGradient-1">
          <stop stopColor="#FC4B07" offset="0%"></stop>
          <stop stopColor="#FC421D" offset="16.2361765%"></stop>
          <stop stopColor="#FC1691" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="-61.9081861%" y1="-97.3291405%" x2="60.8888885%" y2="206.07888%" id="linearGradient-2">
          <stop stopColor="#FC4B07" offset="0%"></stop>
          <stop stopColor="#FC1691" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="-104.52595%" y1="34.510246%" x2="106.190031%" y2="63.8615838%" id="linearGradient-3">
          <stop stopColor="#FC4B07" offset="0%"></stop>
          <stop stopColor="#FC1691" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="(xx)-N----Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g id="1--Desktop---Home" transform="translate(-464.000000, -767.000000)">
          <g id="UI" transform="translate(443.000000, 203.000000)">
            <g id="Logout">
              <g transform="translate(20.000000, 561.000000)">
                <g id="Icons/Profile/Logout/Red" transform="translate(0.000000, 3.000000)">
                  <g id="Icon" transform="translate(9.000000, 10.000000) rotate(180.000000) translate(-9.000000, -10.000000) translate(1.000000, 1.500000)">
                    <g id="Square" stroke="url(#linearGradient-1)" strokeWidth="1.8">
                      <path d="M10,13.7056621 L9.99971104,14.1462428 C9.99971104,15.6072726 9,17 7.19999981,17 L2.74516113,17 C0.977132923,17 3.59715334e-13,15.6982867 3.55271368e-15,14.1462428 L2.86922775e-13,2.85296738 C2.86922775e-13,1.30196637 0.977132923,7.33010692e-15 2.74516113,5.32907052e-15 L7.19999981,3.55271368e-15 C9,3.55271368e-15 10,1.29376481 10,2.85296738 L10,3.2919497" id="Shape"></path>
                    </g>
                    <g id="Arrow" transform="translate(5.300000, 5.000000)" strokeWidth="1.3">
                      <path d="M6.59368482,0.504255861 L9.39274735,3.50202687 C9.39399725,3.50325282 9.39399725,3.50525891 9.39274735,3.50648485 L6.59368482,6.50425586" id="Shape" stroke="url(#linearGradient-2)"></path>
                      <polyline id="Line" stroke="url(#linearGradient-3)" points="8.53333314 3.5 5.09578139 3.5 0.533333143 3.5"></polyline>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function ManualIcon(props) {
  return <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <title>Icons/Profile/Manual</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="A1---Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
      transform="translate(-235.000000, -411.000000)">
      <g id="Content" transform="translate(89.000000, 226.000000)" stroke="#FFFFFF">
        <g id="Manual" transform="translate(33.000000, 174.000000)">
          <g id="Icons/Profile/Manual/White" transform="translate(111.000000, 9.000000)">
            <g id="Group-2-Copy" transform="translate(2.000000, 2.000000)">
              <path
                d="M0.9,1.80032462 L0.9,14.1996754 L5.25463338,14.7119852 C5.33607128,14.7215661 5.41800045,14.7263689 5.5,14.7263689 C6.65979797,14.7263689 7.6,13.7861669 7.6,12.6263689 L7.6,3.37363108 C7.6,3.29163153 7.5951972,3.20970236 7.58561627,3.12826446 C7.45010403,1.9764104 6.40648744,1.15250257 5.25463338,1.28801481 L0.9,1.80032462 Z"
                id="Rectangle-7-Copy" strokeWidth="1.8"
                transform="translate(4.250000, 7.999856) scale(-1, 1) translate(-4.250000, -7.999856) "></path>
              <path
                d="M8.4,1.80032462 L8.4,14.1996754 L12.7546334,14.7119852 C12.8360713,14.7215661 12.9180004,14.7263689 13,14.7263689 C14.159798,14.7263689 15.1,13.7861669 15.1,12.6263689 L15.1,3.37363108 C15.1,3.29163153 15.0951972,3.20970236 15.0856163,3.12826446 C14.950104,1.9764104 13.9064874,1.15250257 12.7546334,1.28801481 L8.4,1.80032462 Z"
                id="Rectangle-7-Copy" strokeWidth="1.8"></path>
              <path d="M8,3 L8,14" id="Line" strokeLinecap="square"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

export function MoreIcon(props) {
  return <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="34" height="34" rx="17" fill="#378EF8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9 17C9 18.1027 9.89727 19 11 19C12.1028 19 13 18.1027 13 17C13 15.8972 12.1028 15 11 15C9.89727 15 9 15.8972 9 17Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15 17C15 18.1027 15.8973 19 17 19C18.1028 19 19 18.1027 19 17C19 15.8972 18.1028 15 17 15C15.8973 15 15 15.8972 15 17Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21 17C21 18.1027 21.8973 19 23 19C24.1028 19 25 18.1027 25 17C25 15.8972 24.1028 15 23 15C21.8973 15 21 15.8972 21 17Z" fill="white"/>
        <defs>
          <linearGradient id="paint0_linear" x1="17" y1="-17" x2="-17" y2="17" gradientUnits="userSpaceOnUse">
            <stop stopColor="#43DAF0"/>
            <stop offset="1" stopColor="#395BF8"/>
          </linearGradient>
        </defs>
        </svg>
  ;
}

export function ProfileIcon(props) {
  return <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="34" height="34" rx="17" fill="#378EF8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.0675 20.754C23.1235 19.8834 21.5239 19.1922 20.2007 18.5411C18.9844 17.9425 18.93 18.0151 19.9284 16.437C20.5156 15.5088 20.6983 14.2996 20.6909 13.172C20.6822 11.8665 20.329 9.01524 17.0125 9.00058C13.6711 9.01524 13.3177 11.8665 13.3092 13.172C13.3018 14.2996 13.4843 15.5088 14.0716 16.437C15.0701 18.0151 15.0156 17.9425 13.7993 18.5411C12.4761 19.1922 10.8766 19.8834 9.93258 20.754C8.15875 22.3899 9.066 25.0711 11.3849 24.9986H16.7152H17.2848H22.6152C24.9341 25.0711 25.8412 22.3899 24.0675 20.754Z" stroke="white" strokeWidth="1.3"/>
        <defs>
        <linearGradient id="paint0_linear" x1="17" y1="-17" x2="-17" y2="17" gradientUnits="userSpaceOnUse">
          <stop stopColor="#43DAF0"/>
          <stop offset="1" stopColor="#395BF8"/>
        </linearGradient>
        </defs>
      </svg>
  ;
}

export function MySubjectsIcon() {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.9 16V12.85C4.22035 11.7197 4.22035 8.28027 1.9 7.15004V4C1.9 3.39249 2.39249 2.9 3 2.9L17 2.9C17.6075 2.9 18.1 3.39249 18.1 4V7.15004C15.7796 8.28027 15.7796 11.7197 18.1 12.85V16C18.1 16.6075 17.6075 17.1 17 17.1H3C2.39249 17.1 1.9 16.6075 1.9 16Z" stroke="white" strokeWidth="1.8"/>
  <path d="M14 10H5" stroke="white" strokeLinecap="round" strokeDasharray="3 2"/>
  </svg>
  ;
}

export function MyPlanIcon() {
  return <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="34" rx="17" fill="#378EF8"/>
    <path d="M8.65 23V19.6888C10.9704 18.6981 10.9704 15.3019 8.65 14.3112V11C8.65 10.2544 9.25442 9.65 10 9.65L24 9.65C24.7456 9.65 25.35 10.2544 25.35 11V14.3112C23.0296 15.3019 23.0296 18.6981 25.35 19.6888V23C25.35 23.7456 24.7456 24.35 24 24.35H10C9.25442 24.35 8.65 23.7456 8.65 23Z" stroke="white" strokeWidth="1.3"/>
    <path d="M21 17H12" stroke="white" strokeLinecap="round" strokeDasharray="3 2"/>
    <defs>
      <linearGradient id="paint0_linear" x1="17" y1="-17" x2="-17" y2="17" gradientUnits="userSpaceOnUse">
      <stop stopColor="#43DAF0"/>
      <stop offset="1" stopColor="#395BF8"/>
      </linearGradient>
    </defs>
  </svg>;
}


