import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './exam.scss';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import { getUserId } from 'selectors/user/index';
import { examPreview, getExamHeader as examHeader, isApiRequestInProgressV2 } from 'selectors/api/apiSelector';
import { activeSubjectId, subjectsTeachingLevel, getActiveSubject } from 'selectors/subject/subjectSelector';
import { getActivePaperTab, scrollPosition } from 'selectors/navigation/navigationSelector';
import { useDispatch, useSelector } from 'react-redux';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import ExamHeader from "./ExamHeader";
import PaperDetails from "./PaperDetails";
import { setLastMediaTypeVisited, disableMcqExamSubject, setFormExams, storeExamsParentId } from "helpers"
import MCQFilterLayout from 'v2/components/filter-overlay/exams-filter-container/MCQFilterLayout';
import MircoFilterLayout from 'v2/components/studying/microFilterContainer/MicroFilterLayout';
import { PracticeButtonIcon } from 'icons/MediaIcons';
import ExamListContainer from './ExamListContainer';
import { useHistory, useParams } from "react-router-dom";
import { getExamPreview, getExamHeader, getQuestionCount } from 'actions/api';
import { setActivePaperTab, saveScrollPosition, setActiveFlashcardTab } from 'actions/navigation';
import { updateExamsQuestionIndex, setExamQuestionNumber, paperAllQuestionData } from 'actions/desk';
import { changeFlashcard, selectExams } from 'actions/studying';
import usePrevious from 'hooks/usePrevious';
import FreeTrialNotification from 'v2/components/free-trial-notification/FreeTrialNotification';
import BackToSearchButton from 'v2/ui-components/BackToSearchButton';
import BackButton from 'v2/components/navigation-header/BackButton';
import useScrollDetection from 'hooks/useScrollDetection';
import { debounce } from 'lodash';
import useExamModuleStatus from 'hooks/useExamModuleStatus';
import { paperFilterText } from './paperData';

type HistoryParams = {
    id: string;
    examId: string;
};

const ExamPage = () => {
    const history = useHistory();
    const { id, examId } = useParams<HistoryParams>();
    const dispatch = useDispatch();
    const hasExamHeaderInProgress = useSelector(state => isApiRequestInProgressV2(state, 'examHeader'));
    const hasExamPreviewInProgress = useSelector(state => isApiRequestInProgressV2(state, 'examPreview'));
    const hasQuestionCountInProgress = useSelector(state => isApiRequestInProgressV2(state, 'getQuestionCount'));
    const hasActiveSubjectId = useSelector(activeSubjectId);
    const getSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
    const activePaperTab = useSelector(getActivePaperTab);
    const activeSubject = useSelector(getActiveSubject);
    const examPreviewData = useSelector(examPreview);
    const examHeaderData = useSelector(examHeader);
    const userId = useSelector(getUserId);
    const getScrollPosition = useSelector(scrollPosition).toJS();
    const prevSubjectsTeachingLevel = usePrevious(getSubjectsTeachingLevel);
    const prevActivePaperTab = usePrevious(activePaperTab);
    const prevId = usePrevious(id);
    const prevExamId = usePrevious(examId);
    const prevExamHeaderData = usePrevious(examHeaderData);
    const prevExamPreviewInProgress = usePrevious(hasExamPreviewInProgress);
    const [paperQuestionSize, setPaperQuestionSize] = useState(null);
    const [paperQuestion, setPaperQuestion] = useState<any>({});
    const [paperId, setPaperId] = useState<string>('');
    const tlvl = getSubjectsTeachingLevel[`${hasActiveSubjectId}`];
    const paperData = paperFilterText?.[hasActiveSubjectId]?.[tlvl];
    let wrapperRef = useRef<HTMLDivElement>(null);
    const { isScrolled, bindScrollRef } = useScrollDetection({ WrappedRef: wrapperRef });
    const { loading, status } = useExamModuleStatus();
    const preExamHeaderInProgress = usePrevious(hasExamHeaderInProgress)
    const isPaperStatusActive = useCallback((activePaper: String) => {
        let inactivePaper = paperData?.filter((item: any) => item.title === activePaper)?.[0]?.active
        if (examHeaderData) {
            const papers = examHeaderData?.papers?.map((item: any) => {
                return item.paper_type
            })
            if (papers && !papers?.includes(activePaperTab)) {
                inactivePaper = false;
            }
        }
        return inactivePaper
    }, [paperData, examHeaderData, activePaperTab]);

    const onScroll = debounce((event: any) => {
        const scrollTop = event.target.scrollTop;
        const values = {
            "previousValue": getScrollPosition.examPage.currentValue,
            "currentValue": scrollTop
        }
        dispatch(saveScrollPosition("examPage", values));
    }, 300);

    useEffect(() => {
        dispatch(setActiveFlashcardTab(1))
        const examsList = examPreviewData.length === 0;
        dispatch(getExamPreview(id, examsList));
        dispatch(getQuestionCount(id));
        setPaperId(id);
        dispatch(getExamHeader({
            activeSubjectId: activeSubject.id,
            examUid: examId
        }));
        const values = {
            "previousValue": 0,
            "currentValue": 0
        }
        dispatch(saveScrollPosition("examPage", values));
        // @ts-ignore
        wrapperRef.addEventListener('scroll', onScroll);
        return () => {
            // @ts-ignore
            wrapperRef?.removeEventListener('scroll', onScroll);
        }
    }, [])

    useEffect(() => {
        if (hasExamPreviewInProgress !== prevExamPreviewInProgress) {
            if (wrapperRef) {
                // @ts-ignore
                wrapperRef.scrollTop = getScrollPosition.examPage.previousValue;
            }
        }
    }, [hasExamPreviewInProgress])

    useEffect(() => {
        prevSubjectsTeachingLevel && getSubjectsTeachingLevel !== prevSubjectsTeachingLevel && history.push('/flashcard/desk/exams');
    }, [getSubjectsTeachingLevel, prevSubjectsTeachingLevel])

    useEffect(() => {
        if (prevId && prevExamId && id !== prevId && examId !== prevExamId) {
            setPaperId(id);
        }
    }, [id, examId, prevId, prevExamId])

    useEffect(() => {
        if (JSON.stringify(examHeaderData) != JSON.stringify(prevExamHeaderData)) {
            const paperById = examHeaderData?.papers?.filter((item: any) => item.uid === id)?.[0].paper_type
            if(paperById) {
                dispatch(setActivePaperTab(paperById));
            }
        }
    }, [examHeaderData, prevExamHeaderData, preExamHeaderInProgress])

    useEffect(() => {
        if (activePaperTab !== prevActivePaperTab && isPaperStatusActive(activePaperTab)) {
            const newPaperId = examHeaderData.papers.filter((papers: any) => papers.paper_type === activePaperTab)[0]?.uid
            if (typeof newPaperId !== 'undefined') {
                history.push(`/flashcard/desk/exams/${examId}/${paperId}`);
                dispatch(getQuestionCount(newPaperId));
                dispatch(getExamPreview(newPaperId));
                setPaperId(newPaperId);
            }
        }
    }, [activePaperTab, prevActivePaperTab])

    useEffect(() => {
        !loading && !status && history.push('/flashcard/topics')
    },[history, loading, status])

    const bindRef = useCallback((ref: HTMLDivElement) => {
        // @ts-ignore
        wrapperRef = ref;
        bindScrollRef(ref)
      }, [])

    const firstQuestionData = (val: any, size: any) => {
        setPaperQuestionSize(size);
        setPaperQuestion(val);
    }

    const firstQuestion = () => {
        const data = examPreviewData?.data?.data;
        const flashcardId = paperQuestion?.[0]?.uuid;
        const questionNumbering = paperQuestion[0]?.numbering;
        setFormExams('exams');
        storeExamsParentId(paperId);
        let examsIndex = paperQuestion.map((val: any) => ({
            index: val.numbering,
            uuid: val.uuid,
            user_response: val.user_response,
            teachingTag: val.teachingTag
        }));
        const examsData = data.map((val: any) => ({
            index: val.numbering,
            uuid: val.uuid,
            user_response: val.user_response,
            teachingTag: val.teachingTag,
            paperType: val.paperType
        }));
        dispatch(updateExamsQuestionIndex(examsIndex, 1));
        dispatch(setExamQuestionNumber(questionNumbering));
        dispatch(paperAllQuestionData(examsData));
        dispatch(changeFlashcard(flashcardId, "default"));
        setLastMediaTypeVisited('exams', userId);
        dispatch(selectExams(flashcardId, 'exams', paperQuestionSize, 1));
    }
    const handlePaperClick = (paperTitle: string, uid: string) => {
        dispatch(setActivePaperTab(paperTitle));
        if (prevActivePaperTab && activePaperTab !== paperTitle &&
            (isPaperStatusActive(paperTitle))) {
            if (typeof uid !== 'undefined') {
                history.push(`/flashcard/desk/exams/${examId}/${uid}`)
                dispatch(getQuestionCount(uid));
                dispatch(getExamPreview(uid));
                setPaperId(uid);
            }
        }
    }

    return <div className='relative flashcard-nav-route-wrapper scroll-smooth'>
        <NavigationHeader isCollapsed={isScrolled} extendedWidth leftSection={<BackToSearchButton alternate={() => <BackButton linkTo={'/flashcard/desk/exams'}>Exams</BackButton>} />} />
        <div className='pt-14 h-full scroll-smooth flex flex-col fixedpagespacing' ref={bindRef}>
            <FreeTrialNotification />
            {hasExamHeaderInProgress ? <LeftMenuLoader /> :
                <>
                    <div className='px-5'>
                        <ExamHeader />
                    </div>
                    <PaperDetails
                        examHeader={examHeaderData}
                        id={paperId}
                        loading={hasExamPreviewInProgress || hasQuestionCountInProgress}
                        activeSubjectId={hasActiveSubjectId}
                        hasPaperText={paperFilterText}
                        onClick={(paperTitle, uid) => handlePaperClick(paperTitle, uid)}
                    />
                    {hasExamPreviewInProgress || hasQuestionCountInProgress ? <LeftMenuLoader /> :
                        <>
                            {isPaperStatusActive(activePaperTab) &&
                                <>
                                    {!disableMcqExamSubject(activeSubject.id) && ["Paper 1", "Paper 1A"].includes(activePaperTab) ?
                                        <div className='pt-6 px-5 flex items-center'>
                                            <MCQFilterLayout formCC={false} isEditMode={false}/>
                                        </div> :
                                        <MircoFilterLayout page="exams" className='mt-6' />
                                    }
                                    {paperQuestion &&
                                        <div className='bg-blue-normal gap-2 rounded-3xl py-2 uppercase flex items-center justify-center mt-3.5 mx-5 mb-6 cursor-pointer select-none practice-button' onClick={firstQuestion}>
                                            <PracticeButtonIcon />
                                            <div className='font-bold text-white practice-button'>Practice</div>
                                        </div>
                                    }

                                    <ExamListContainer listItems={examPreviewData?.data?.data} loading={hasExamPreviewInProgress} id={id} paperId={id} firstQuestion={firstQuestionData} />

                                </>
                            }
                        </>
                    }
                </>
            }
        </div>
    </div>
}

export default ExamPage
