import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { ExamsFilter, MainExamsFilter, FilterClear, MircoFilterContainer, FilterText } from "components/studying/examFilterContainer/examFilter.styles"
import Row from 'ui-components/helpers/Row'
import Text from 'ui-components/helpers/Text';
import { ArrowdownIcon, FilterClearIcon } from 'icons/HeaderIcons';
import * as Actions from "actions"
import * as Selectors from 'selectors';
import Padding from 'ui-components/helpers/Padding';
import TeachingLevelSeperator from 'components/teaching-level-selector/TeachingLevelSeperator';
import { OSCIcon, IBIcon } from "icons/HeaderIcons"

export const ExamFilterLayout = (props) => {
    const { subjectsTeachingLevel, activeSubject, getMultipleSourceUpdate, examsTypesMode, formCC } = props
    let data = props.examsofFilterItems.toJS()
    function setFormCCPopoup() {
        if (props.formCC) {
            props.mainMicroFilterBack(true, true);
        } else {
            props.mainMicroFilterBack(false, false);
        }
    }
    function openPaperFilter(e) {
        props.togglePaperOverlay(true, true);
        setFormCCPopoup()
        e.stopPropagation();
        if (props.isMainMicroFilterVisible) return props.toggleMainMicroOverlay(false, false)
    }
    function openDifficultyFilter(e) {
        props.toggleDifficultyOverlay(true, true);
        setFormCCPopoup()
        e.stopPropagation();
        if (props.isMainMicroFilterVisible) return props.toggleMainMicroOverlay(false, false)
    }
    function openTeachingLeve(e) {
        props.toggleTeachingLevelExamsOverlay(true, true);
        setFormCCPopoup()
        e.stopPropagation();
        if (props.isMainMicroFilterVisible) return props.toggleMainMicroOverlay(false, false)
    }
    function openSourceFilter(e) {
        props.toggleSourceOverlay(true, true);
        setFormCCPopoup()
        e.stopPropagation();
        if (props.isMainMicroFilterVisible) return props.toggleMainMicroOverlay(false, false)
    }
    const clearFilter = (e, val) => {
        e.stopPropagation();
        props.updateExamsPaperFilterItems([], val);
        props.setExamFilterData();
    }


    const assesmentIcon = (value) => {
        let val;
        if (value.includes("Accessible")) {
            if(formCC && data['difficulty'].length > 1)
                val = 'Acce...'
            else
                val = 'Accessible'
        } else if (value.includes("Moderate")) {
            if(formCC && data['difficulty'].length > 1)
                val = 'Mode...'
            else
                val = 'Moderate'
        } else if (value.includes("Discriminating")) {
            if(formCC && data['difficulty'].length > 1)
                val = 'Disc...'
            else
                val = 'Discrimin...'
        }
        switch (val) {
            case 'Accessible':
                return <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{val}</Text>
            case 'Unconfident':
                return <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{val}</Text>
            case 'Discriminating':
                return <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{val}</Text>
            default:
                return <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{val}</Text>
        }
    }
    const sourceIcon = (value) => {
        let val;
        if (value.includes("OSC")) {
            val = 'OSC'
        } else if (value.includes("IB")) {
            val = 'IB'
        }
        switch (val) {
            case 'OSC':
                return <Row alignItems={'center'}><OSCIcon /><Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>OSC</Text></Row>
            case 'IB':
                return <Row alignItems={'center'}><IBIcon /><Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>Official IB</Text></Row>
            default:
                return <Row alignItems={'center'}><OSCIcon /><Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>OSC</Text></Row>
        }
    }

    const teachingTag = subjectsTeachingLevel[`${activeSubject.id}`];

    return (
        <MainExamsFilter formCC={formCC}>
            {!examsTypesMode &&
                <Row alignItems={'center'} >
                    {/* <ExamsFilter  onClick={(e) => openSourceFilter(e)}>
                    <Text size={13} weight={'bold'} color={'#2D3838'} lineHeight={'18px'}>Source</Text>
                    <ArrowdownIcon isMarging={true} />
                </ExamsFilter> */}
                    {getMultipleSourceUpdate &&
                        <React.Fragment>
                            {data['source'].length > 0 && data['source'].length < 2 ?
                                <MircoFilterContainer>
                                    <ExamsFilter isEditMode={props.isEditMode} onClick={(e) => openSourceFilter(e)} filter={true} isMarging={true} >
                                        {/* <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{data['source'][0]}</Text>
                            {data['source'].length > 1 && <FilterText data={data['source'][0]}>+ {data['source'].length - 1}</FilterText>} */}
                                        {sourceIcon(data['source'])}
                                        {/* {assesmentIcon(data['difficulty'])} */}
                                        <div className="closeButton1"> </div>
                                    </ExamsFilter>
                                    <FilterClear onClick={(e) => clearFilter(e, 'source')} isEditMode={props.isEditMode}> <FilterClearIcon /></FilterClear>
                                </MircoFilterContainer> :
                                <MircoFilterContainer>
                                    <ExamsFilter isEditMode={props.isEditMode} onClick={(e) => openSourceFilter(e)} filter={props.isSourceOverlayVisible} isMarging={true} >
                                        <Text size={13} weight={'bold'} color={'#2D3838'} lineHeight={'18px'}>Source</Text>
                                        <ArrowdownIcon isMarging={true} />
                                    </ExamsFilter>
                                </MircoFilterContainer>
                            }
                        </React.Fragment>
                    }

                    {data['paper'].length > 0 && data['paper'].length < 3 ?
                        <MircoFilterContainer>
                            <ExamsFilter isEditMode={props.isEditMode} marginLeft={getMultipleSourceUpdate ? 8 : 0} onClick={(e) => openPaperFilter(e)} filter={true} isMarging={true} >
                                <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{data['paper'][0]}</Text>
                                {data['paper'].length > 1 && <FilterText data={data['paper'][0]}>&nbsp;+ {data['paper'].length - 1}</FilterText>}

                                <div className="closeButton1"> </div>
                            </ExamsFilter>
                            <FilterClear onClick={(e) => clearFilter(e, 'paper')} isEditMode={props.isEditMode}> <FilterClearIcon /></FilterClear>
                        </MircoFilterContainer> :
                        <ExamsFilter isEditMode={props.isEditMode} marginLeft={getMultipleSourceUpdate ? 8 : 0} onClick={(e) => openPaperFilter(e)} filter={props.isPaperOverlayVisible} isMarging={true} >
                            <Text size={13} weight={'bold'} color={'#2D3838'} lineHeight={'18px'}>Paper</Text>
                            <ArrowdownIcon isMarging={true} />
                        </ExamsFilter>}


                </Row >
            }
            {!examsTypesMode &&  <Padding top={8} /> }
                <Row alignItems={'center'}>

                    {data['teachingLevel'].length > 0 && data['teachingLevel'].length < 2 ?
                        <MircoFilterContainer>
                            <ExamsFilter isEditMode={props.isEditMode} onClick={(e) => openTeachingLeve(e)} filter={true} isMarging={true} disabled={teachingTag == "SL" ? true : false}>
                                {/* <Text size={13} weight={'bold'} color={'#2D3838'} lineHeight={'18px'}>Teaching level</Text> */}
                                <Row alignItems={'center'}><TeachingLevelSeperator tlvl={data['teachingLevel'][0]} tlvlTag={data['teachingLevel'][0]} filter={true} isExams={false} /></Row>
                                <div className="closeButton1"> </div>
                            </ExamsFilter>
                            <FilterClear onClick={(e) => clearFilter(e, 'teachingLevel')} isEditMode={props.isEditMode}> <FilterClearIcon /></FilterClear>
                        </MircoFilterContainer> :
                        <ExamsFilter isEditMode={props.isEditMode} onClick={(e) => openTeachingLeve(e)} filter={props.isTeachingLevelExamsOverlayVisible} isMarging={true} disabled={teachingTag == "SL" ? true : false}>
                            <Text size={13} weight={'bold'} color={'#2D3838'} lineHeight={'18px'}>Teaching level</Text>
                            <ArrowdownIcon isMarging={true} />
                        </ExamsFilter>
                    }
                    {!examsTypesMode && <React.Fragment>

                        {data['difficulty'].length > 0 && data['difficulty'].length < 3 ?
                            <MircoFilterContainer >
                                <ExamsFilter isEditMode={props.examsMode || props.isEditMode} marginLeft={8} onClick={(e) => openDifficultyFilter(e)} filter={true} isMarging={true} >
                                    {/* <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{data['difficulty'][0]}</Text> */}
                                    {assesmentIcon(data['difficulty'])} {data['difficulty'].length > 1 && <FilterText data={data['difficulty'][0]}>&nbsp;+ {data['difficulty'].length - 1}</FilterText>}
                                    <div className="closeButton1"> </div>

                                </ExamsFilter>
                                <FilterClear onClick={(e) => clearFilter(e, 'difficulty')} isEditMode={props.isEditMode || props.examsMode}> <FilterClearIcon /></FilterClear>
                            </MircoFilterContainer> :
                            <ExamsFilter isEditMode={props.examsMode || props.isEditMode} marginLeft={8} onClick={(e) => openDifficultyFilter(e)} filter={props.isDiffficultyOverlayVisible} isMarging={true} >
                                <Text size={13} weight={'bold'} color={'#2D3838'} lineHeight={'18px'}>Difficulty</Text>
                                <ArrowdownIcon isMarging={true} />
                            </ExamsFilter>}
                    </React.Fragment>}
                </Row>
            


        </MainExamsFilter>
    )
}

const mapStateToProps = (state) => ({
    examsofFilterItems: Selectors.examsofFilterItems(state),
    isPaperOverlayVisible: Selectors.isPaperOverlayVisible(state),
    isDiffficultyOverlayVisible: Selectors.isDiffficultyOverlayVisible(state),
    isSourceOverlayVisible: Selectors.isSourceOverlayVisible(state),
    isTeachingLevelExamsOverlayVisible: Selectors.isTeachingLevelExamsOverlayVisible(state),
    isMainMicroFilterVisible: Selectors.isMainMicroFilterVisible(state),
    subjectsTeachingLevel: Selectors.subjectsTeachingLevel(state),
    activeSubject: Selectors.getActiveSubject(state),
    getMultipleSourceUpdate: Selectors.getMultipleSourceUpdate(state)
})

const mapDispatchToProps = {
    togglePaperOverlay: Actions.Navigation.togglePaperOverlay,
    updateExamsPaperFilterItems: Actions.Desk.updateExamsPaperFilterItems,
    toggleDifficultyOverlay: Actions.Navigation.toggleDifficultyOverlay,
    toggleSourceOverlay: Actions.Navigation.toggleSourceOverlay,
    toggleTeachingLevelExamsOverlay: Actions.Navigation.toggleTeachingLevelExamsOverlay,
    toggleMainMicroOverlay:Actions.Navigation.toggleMainMicroOverlay,
    mainMicroFilterBack:Actions.Navigation.mainMicroFilterBack,
    setExamFilterData: Actions.Api.setExamFilterData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExamFilterLayout)

