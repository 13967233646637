import AddFlashcardToFolderContainer from 'pages/desk/folder/AddFlashcardToFolderContainer';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavigationHeader from 'components/navigation-header/NavigationHeader';
import BackButton from 'components/navigation-header/BackButton';
import {AddToFolderIcon} from 'icons/FolderIcons';
import {ellipsis} from 'helpers';
import * as Selectors from 'selectors';
/**
 * @deprecated TODO- delete not use any more
 */
class HeaderFolderContentTooltip extends Component {
  render() {
    return (
      <React.Fragment>
        <NavigationHeader
          extendedWidth
          contained
          isCollapsed={this.props.isCollapsed}
          title={(
            <div className="folders-title">
              {this.props.isSmart ?
                (
                  <div className="capitalize">
                    {this.props.folderId}
                  </div>
                ) : (
                  this.props.folderTitle
                )
              }
            </div>
          )}
          leftSection={(
            <BackButton onClick={() => this.props.changeRoute('folders')} text="Folders"/>
          )}
          rightSection={(
            this.props.isSmart ? (
              <div className='flex-pull-right vertical-align'>
                {this.props.isCurrentFlashcardAdded && (
                  <AddToFolderIcon state='active'/>
                )}
              </div>
            ) : (
              this.props.currentFlashcardId ?
                <div className='flex-pull-right vertical-align pointer' onClick={this.props.onAddClick}>
                  <AddFlashcardToFolderContainer folderId={this.props.folderId} flashcardFolders={this.props.flashcardFoldersPreview} />
                </div> : null
            )
          )}/>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  flashcardFoldersPreview: Selectors.flashcardsInFolder(state),
});
export default connect(
  mapStateToProps, null
)(HeaderFolderContentTooltip);