import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveMediaTab, previewsListbyType, topicsDetailsSelector, apiWaitingResponse, isApiRequestInProgress, subjectsTeachingLevel, examsofFilterItems, currentFlashcardId, listOfFilterCheckedItems, getActiveSubject} from 'selectors';
import { getPreviewsListByType, setFlashcardAnswerUpdate } from 'actions/api';
import useTopicData from 'hooks/useTopicData';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import EmptyScreenMessage from 'v2/components/empty-screen-message/EmptyScreenMessage';
import { ReactComponent as NoFlascardIcon } from 'assets/icons/no-flashcard-icon.svg';
import { ReactComponent as NoExamsIcon } from "assets/icons/no-exams-icon.svg"
import { ReactComponent as NoRevisionIcon } from 'assets/icons/no-revision-icon.svg';
import { ReactComponent as NoVideoIcon } from 'assets/icons/no-video-icon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import FlashcardResultsList from 'v2/ui-components/search-results/FlashcardResultsList';
import RevisionResultsList from 'v2/ui-components/search-results/RevisionResultsList';
import VideoResultsList from 'v2/ui-components/search-results/VideoResultsList';
import ExamsResultsList from "v2/ui-components/search-results/ExamsResultsList"
import usePrevious from 'hooks/usePrevious';
import { setFilterTopicExamStatusCount } from 'actions/desk';
import {saveScrollPosition} from 'actions/navigation'
import { checkFlashcardAssesstment, teachingFilterResultsListToArray } from 'v2/helpers';
import { marks, answers, watched, examAnswers} from 'selectors/answersSelectors';
import classNames from 'classnames';

type TopicListContainerProps = {
  activeType: number,
  fromPage: string,
  id: string
}

const TopicListContainer: React.FC<TopicListContainerProps> = ({ activeType, id, fromPage }) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const [filterFlashcardId, setFilterFlashcardId] = useState<any>(null);
  const activeMediaTab = useSelector(getActiveMediaTab);
  const previews = useSelector(previewsListbyType).toJS();
  const hasTopicData = useSelector(topicsDetailsSelector).toJS();
  const apiResponse = useSelector(apiWaitingResponse);
  const inProgress = useSelector((state) => isApiRequestInProgress(state, 'examFilterDataBySubject'))
  const subjectstlvl = useSelector(subjectsTeachingLevel);
  const examsofFilter = useSelector(examsofFilterItems).toJS();
  const currentId = useSelector(currentFlashcardId);
  const listOfFilterChecked = useSelector(listOfFilterCheckedItems).toJS();
  const flashcardAnswer = useSelector(answers)?.toJS()
  const hasMarks = useSelector(marks).toJS();
  const hasWatched = useSelector(watched).toJS();
  const hasExamAnswer = useSelector(examAnswers).toJS();
  const activeSubject = useSelector(getActiveSubject);
  const resultData = previews?.[id]?.data;
  const prevSubjectstlvl = usePrevious(subjectstlvl);
  const prevExamsFilter: any = usePrevious(examsofFilter);
  const prevflashcardAnswer:any = usePrevious(flashcardAnswer);
  const prevMarks: any = usePrevious(hasMarks);
  const preWatched: any = usePrevious(hasWatched);
  const prevExamAnswer: any = usePrevious(hasExamAnswer);
  const { finalData, filterStatus } = useTopicData<any>(resultData, activeMediaTab);
  const inProgresstopicsDetailsSelector = hasTopicData?.[id]?.loading;

  const loadDataList = useCallback(() => {
    dispatch(getPreviewsListByType({ id: id, mediaType: activeMediaTab, type: activeType }));
  },[activeMediaTab, activeType, dispatch, id])

  useEffect(() => {
    loadDataList();
  }, [activeMediaTab, id, loadDataList])

  useEffect(() => {
    let assesstment: string = '';
    if (activeMediaTab === "exams" && JSON.stringify(examsofFilter) != JSON.stringify(prevExamsFilter)) {
      dispatch(setFilterTopicExamStatusCount(id, examsofFilter?.teachingLevel, examsofFilter?.difficulty, examsofFilter?.source, examsofFilter?.paper));
    }
    if(activeMediaTab === "flashcard" && currentId){
      if(prevflashcardAnswer[currentId] && prevflashcardAnswer[currentId]?.correct !== flashcardAnswer[currentId]?.correct){
        if(flashcardAnswer[currentId]?.correct === 1){
          assesstment = "Confident";
        }else if(flashcardAnswer[currentId]?.correct === 2){
          assesstment = "Neither";
        }else if(flashcardAnswer[currentId]?.correct === 0){
          assesstment = "Unconfident";
        }else if(flashcardAnswer[currentId]?.correct === null){
          assesstment = "Not yet assessed";
        }else{
          assesstment = '';
        }
        if(checkFlashcardAssesstment(listOfFilterChecked["flashcard"], assesstment)){
          setFilterFlashcardId(currentId)
        }
        const topicList = previews[id].data;
          topicList.map((item:any) => {
            if(item.flashcardId === currentId ){
              item.answers = flashcardAnswer[currentId]?.correct
            }
            return item
          })
        setTimeout(() => {
          dispatch(setFlashcardAnswerUpdate(id, topicList))
          // loadDataList()
          setFilterFlashcardId(null)
        }, 500)
        
      }
    }
    if(activeMediaTab === "revision" && currentId){
      if(prevMarks[currentId]?.markValue !== hasMarks[currentId]?.markValue){
        if(hasMarks[currentId]?.markValue === 1){
          assesstment = "Read";
        }else if(hasMarks[currentId]?.markValue === 0){
          assesstment = "Not yet read";
        }else{
          assesstment = '';
        }
        if(checkFlashcardAssesstment(listOfFilterChecked["revision"], assesstment)){
          setFilterFlashcardId(currentId)
        }
        setTimeout(() => {
          const topicList = previews[id].data;
          topicList.map((item: any) => {
            if(item.flashcardId === currentId ){
              item.value = hasMarks[currentId].markValue
            }
            return item
          })

          setFilterFlashcardId(null)
          dispatch(setFlashcardAnswerUpdate(id, topicList))
          // loadDataList()
        }, 500)
      }
    }

    if(activeMediaTab === "video" && currentId){
      if(preWatched[currentId]?.partialValue !== hasWatched[currentId]?.partialValue){
        if(hasWatched[currentId].partialValue === 0 && hasWatched[currentId].watchedValue !== 1){
          assesstment = "Not yet watched";
        }else if(hasWatched[currentId].partialValue === 1 && hasWatched[currentId].watchedValue !== 1){
          assesstment = "Partly-watched";
        }else if(hasWatched[currentId].watchedValue === 1){
          assesstment = "Watched";
        }else{
          assesstment = '';
        }
        if(checkFlashcardAssesstment(listOfFilterChecked["video"], assesstment)){
          setFilterFlashcardId(currentId)
        }
        setTimeout(() => {
          const topicList = previews[id].data;
          topicList.map((item: any) => {
            if(item.flashcardId === currentId ){
              item.totalWatchedInSeconds = hasWatched[currentId].totalDurationInSeconds
            }
            return item
          })
          dispatch(setFlashcardAnswerUpdate(id, topicList));
          // loadDataList()
          setFilterFlashcardId(null)
        }, 500)
      }
    }
    if(activeMediaTab === "exams" && currentId){
      if(prevExamAnswer[currentId]?.correct !== hasExamAnswer[currentId]?.correct){
        if(hasExamAnswer[currentId]?.correct === 1){
          assesstment = "Confident";
        }else if(hasExamAnswer[currentId]?.correct === 2){
          assesstment = "Neither";
        }else if(hasExamAnswer[currentId]?.correct === 0){
          assesstment = "Unconfident";
        }else if(hasExamAnswer[currentId]?.correct === null){
          assesstment = "Not yet assessed";
        }else{
          assesstment = '';
        }
        if(checkFlashcardAssesstment(listOfFilterChecked["exams"], assesstment)){
          setFilterFlashcardId(currentId)
        }
        setTimeout(() => {
          const topicList = previews[id]?.data;
          topicList.map((item: any) => {
            if(item.flashcardId === currentId ){
              item.answers.user_response.correct = hasExamAnswer[currentId]?.correct
            }
            return item
          })
          dispatch(setFlashcardAnswerUpdate(id, topicList))
          // loadDataList()
          setFilterFlashcardId(null)
        }, 500)
        
      }
    }

    if(prevSubjectstlvl !==  subjectstlvl){
      if(activeMediaTab ==='exams'){
        loadDataList();
      }
      const topicList = previews[id].data
      const teachingTag = subjectstlvl[`${activeSubject.id}`]; 
      const filterTopicsList = topicList && teachingFilterResultsListToArray(topicList, teachingTag, activeSubject);
      if(filterTopicsList && filterTopicsList.length === 0){
        backTopicsList();
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMediaTab, prevExamsFilter, examsofFilter, currentId, prevMarks, hasMarks, prevflashcardAnswer, flashcardAnswer, preWatched,hasWatched, prevExamAnswer, hasExamAnswer, prevSubjectstlvl])

  function backTopicsList(){
    history.push('/flashcard/topics');
    dispatch(saveScrollPosition("topics", 0))
  }
  const renderListContents = () => {
    if (previews?.[id]?.loading || apiResponse || inProgress || inProgresstopicsDetailsSelector) {
      return <><div className={classNames({"pt-[86px]": activeMediaTab === "exams"})}><LeftMenuLoader /></div></>;
    }
    if (finalData.length === 0) {
      return (
        <>
          <div className={classNames({'pt-60px': activeMediaTab !== "exams", "pt-[204px]": activeMediaTab === "exams"})} />
          <EmptyScreenMessage type={activeType === 1 ? filterStatus ? 'topic' : 'filterTopic' : filterStatus ? 'subtopic' : 'filterSubTopic'} mediaType={activeMediaTab} className={"empty-topics-screen"}>{activeMediaTab === 'flashcard' ? filterStatus ? <NoFlascardIcon /> : <FilterIcon /> : activeMediaTab === 'exams' ? filterStatus ? <NoExamsIcon /> : <FilterIcon /> : activeMediaTab === 'revision' ? filterStatus ? <NoRevisionIcon /> : <FilterIcon /> : filterStatus ? <NoVideoIcon /> : <FilterIcon />}</EmptyScreenMessage>
        </>
      )
    }
    if (activeMediaTab === 'revision') {
      return <RevisionResultsList newListItems={finalData} shouldMark={false} onListItemSelected={null} mediaType={activeMediaTab} fromPage={fromPage} filterFlashcardId={filterFlashcardId}  />;
    } else if (activeMediaTab === 'video') {
      return <VideoResultsList newListItems={finalData} shouldMark={false} onListItemSelected={null} mediaType={activeMediaTab} fromPage={fromPage} filterFlashcardId={filterFlashcardId} />;
    } else if (activeMediaTab === 'exams') {
      return <ExamsResultsList newListItems={finalData} shouldMark={false} onListItemSelected={null} mediaType={activeMediaTab} fromPage={fromPage} filterFlashcardId={filterFlashcardId} />;
    } else {
      return <FlashcardResultsList newListItems={finalData} shouldMark={false} mediaType={activeMediaTab} fromPage={fromPage} filterFlashcardId={filterFlashcardId} />;
    }
  }
  return <div>
    {renderListContents()}
  </div>
}
export default React.memo(TopicListContainer);