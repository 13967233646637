import React from 'react';
import { ComputerIcon, MobileIcon, TabletIcon} from 'icons/DeviceIcons';
import './device.scss';
import Loader, {BackgroundEnum, SizeEnum} from 'v2/components/loader.v2/Loader.v2';

// export const deviceTypeIcon: any = {
//     'browser': {
//       title: 'Computer',
//       icon: <ComputerIcon />
//     },
//     'mobile': {
//       title: 'Mobile',
//       icon: <MobileIcon />
//     },
//     'tablet': {
//       title: 'Tablet',
//       icon: <TabletIcon />
//     }
// };
const deviceTypeIcon = (deviceType: string) => {
  switch (deviceType) {
    case "browser":
      return { title: "Computer", icon: <ComputerIcon /> };
    case "mobile":
      return { title: "Mobile", icon: <MobileIcon /> };
    case "tablet":
      return { title: "Tablet", icon: <TabletIcon /> };
    default:
      return { title: "Other", icon: <ComputerIcon /> };
  }
};

type DeviceListItemProps = {
    data: any;
    handleLogout: (val: any) => void;
    isLogoutInProgress: boolean | undefined
}  
const DeviceListItem: React.FC<DeviceListItemProps> = ({data, handleLogout, isLogoutInProgress}) => {
    return <div className='flex pt-2 pb-3'>
        {deviceTypeIcon(data?.deviceType)?.icon}
        <div className='relative ml-2.5 text-#2D3838 text-17px font-bold flex-1 whitespace-nowrap overflow-hidden text-ellipsis leading-3'>
            <div className='font-bold text-17px leading-23px text-#2D3838 pt-[5px] pb-1 capitalize'>{data.deviceType === 'browser' ? "Computer" : data.deviceType}</div>
            <div className='text-15px font-normal leading-19px text-#727373'>{data.osName} • {data.browserName}</div>
            <div className='text-15px font-normal leading-19px text-#727373'>Last log in: {data.readableDate} </div>
        </div>
        <div className='bg-white pt-[5px] cursor-pointer' onClick={() => handleLogout(data)}>
            {isLogoutInProgress ? <Loader size={SizeEnum.VSmall} background={BackgroundEnum.BlueX}/> :
            <div className='device-logout-btn'>LOG OUT</div>}
        </div>
    </div>
}

export default DeviceListItem;