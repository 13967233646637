import classNames from 'classnames'
import React from 'react'
import './previewListHeader.scss'
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';
import {ForwardChevronIcon, SmallExamsIcons} from "icons/HeaderIcons";

type ExamPreviewListHeaderProps = {
    fromPage        : string;
    index           : number;
    onClick         : () => void;
    subTopicTitle   : string;
    teachingLevel   : string;
    teachingTag     : string | null;
}

const ExamPreviewListHeader:React.FC<ExamPreviewListHeaderProps> = ({ fromPage, index, onClick, subTopicTitle, teachingLevel, teachingTag }) => {    
  return <div className={classNames('exam-preview-list-header flex h-8 py-5px px-5 items-center',{'mt-3.5': index > 0, 'not-subtopic cursor-pointer': fromPage !== "subtopic"})} onClick={onClick}>
    {teachingTag != null ? <TeachingLevelSeperator tlvl={teachingLevel} tlvlTag={teachingTag} isExams={false} slTag={teachingTag === 'SL'} /> : 
    <div className={classNames('rounded-5px text-13px leading-18px text-white min-w-[30px] font-normal bg-#458CFC',{
        '!bg-#458CFC py-0.5 px-2'            : teachingLevel?.indexOf('SL') > -1,
        '!bg-#1FCD6C py-0.5 pr-1.5 pl-7px'   : teachingLevel?.indexOf('HL') > -1,
    })}>{teachingLevel}</div>}
    <div className='small-exam-icon bg-white rounded-[11px] mr-0.5 ml-3 flex items-center justify-center w-[41px] h-[22px]'><SmallExamsIcons/></div>
    <div className={classNames('title ml-2 !font-bold !text-15px leading-19px !opacity-100 !text-#2D3838 overflow-hidden max-w-full',{
        '!max-w-[81px]'   :   teachingLevel?.includes('Prescribed'),
        '!max-w-[100px]'  :   teachingTag === "SL&HL" && teachingLevel?.includes('Option'),
        '!max-w-[144px]'  :   teachingLevel?.includes('Option') || teachingLevel?.includes('options'),
        '!max-w-[197px]'  :   teachingLevel?.includes('Pre-IB'),
    })} dangerouslySetInnerHTML={{__html: subTopicTitle}}/>
    {fromPage !== "subtopic" && <div className='ml-auto pl-[13px]'><ForwardChevronIcon color={'#BEC4C3'} /></div>}
  </div>
}

export default ExamPreviewListHeader