import { useMemo, useEffect, useState } from 'react';
import MessageLoader from 'v2/components/loader.v2/MessageLoader';
import PageOverlay from 'v2/ui-components/PageOverlay';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from 'v2/components/navigation-header/BackButton';
import Button, { ThemeEnum } from 'v2/components/Button/Button';
import { toggleSubjectsManagerOverlay, toggleTeachingLevelOverlay, togglePaperOverlay, setActiveExamsTab, setActiveMediaTab, toggleOldSubjectsManagerOverlay } from 'actions/navigation';
import { setActiveMessageLoader, updateIdsOfFilterItems } from 'actions/desk';
import { setFlashcardIdDetails, setRevisionIdDetails, setVideoIdDetails } from 'actions/carousel/carouselActions';
import Subject from 'v2/components/subjects-changer/Subject';
import { useHistory } from 'react-router-dom';
import { setLastMediaTypeVisited, setLastSubjectVisited } from 'helpers';
import { getUserId } from 'selectors/user';
import { isSubjectsManagerOverlayVisible, isOldSubjectsManagerOverlayVisible } from 'selectors/navigation/navigationSelector';
import { getActiveSubject, getActiveTeachingLevel, getSubjectv2 } from 'selectors/subject/subjectSelector';
import { isLandscape, isMobile } from 'selectors/browser/browserSelector';
import { setActiveSubject as actionSetActiveSubject, setActiveTeachingLevel as actionSetActiveTeachingLevel } from 'actions/subject';
import { List } from 'immutable';
import FreeTrialNotification from 'v2/components/free-trial-notification/FreeTrialNotification';
import usePrevious from 'hooks/usePrevious';
import './subjects-manager-container.scss';
import usePopupRest from "hooks/usePopupRest";
import { getExams, getFeature, updateExamPreviewsListByType } from 'actions/api';
import { getLastSubjectActive, getSubjectsSyllabus, setLastSubjectActive } from 'v2/helpers';
import { ForwardArrowRight } from 'icons/HeaderIcons';
import useExamModuleStatus from 'hooks/useExamModuleStatus';

const subjectManagerSubjectsList:Array<number> = [3, 47, 50, 1703, 2269, 8944, 14982, 15075, 17408, 19700, 19711, 19978, 20483, 20970, 14631, 41046];

const subjectManagerOldSubjectsList:Array<number> = [2837];

const SubjectsManagerContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hasOldSubjectsManagerOverlayVisible = useSelector(isOldSubjectsManagerOverlayVisible);
  const subjects = useSelector(getSubjectv2);
  const activeSubject = useSelector(getActiveSubject);
  const userId = useSelector(getUserId);
  const hasSubjectsManagerOverlayVisible = useSelector(isSubjectsManagerOverlayVisible);
  const hasActiveTeachingLevel = useSelector(getActiveTeachingLevel);
  const hasLandscape = useSelector(isLandscape);
  const hasMobile = useSelector(isMobile);
  const examModuleStatus = useExamModuleStatus();
  const prevActiveSubject = usePrevious(activeSubject);
  const [showLoader, setShowLoader] = useState(false)
  const { resetPopup } = usePopupRest();
  const lastSubjectActive = getLastSubjectActive(userId)
  const activeSubjectsList = useMemo(() => subjects.filter(({ id }: any) => subjectManagerSubjectsList.includes(id)),[subjects])
  const oldSubjectsList = useMemo(() => subjects.filter(({ id }: any) => subjectManagerOldSubjectsList.includes(id)),[subjects])

  useEffect(() => {
    if (activeSubject && prevActiveSubject && prevActiveSubject.id !== activeSubject.id) {
      setTimeout(closeSubjectsManager, 3000);
    }
  }, [activeSubject, prevActiveSubject])

  const setActiveTeachingLevel = (teachingLevel: number, subjectChanged: boolean) => {
    if (teachingLevel === hasActiveTeachingLevel) return;
    dispatch(actionSetActiveTeachingLevel(teachingLevel, subjectChanged));
  };

  const handleSubjectChange = (subject: any, subjectClicked: any) => {
    if (activeSubject && subject.id !== activeSubject?.id) {
      dispatch(getFeature())
      setLastSubjectActive(subjectClicked?.id, userId)
      setLastMediaTypeVisited('', userId);
      dispatch(setActiveExamsTab("ib_dp"));
      dispatch(actionSetActiveSubject(subject, true));
      dispatch(updateIdsOfFilterItems(List([]), 'exams'));
      setActiveTeachingLevel(subject.id, true);
      setLastSubjectVisited(subject.id, userId);
      dispatch(updateExamPreviewsListByType())
      dispatch(setFlashcardIdDetails([]));
      dispatch(setRevisionIdDetails([]));
      dispatch(setVideoIdDetails([]));
    } else {
      setTimeout(() => {
        closeSubjectsManager()
      }, 3000);
    }
  }

  const setActiveSubject = (subjectClicked: any) => {
    const subjectsSyllabus = getSubjectsSyllabus(userId)
    const subject = subjects?.find((subject:any) => subjectsSyllabus?.[subjectClicked?.id] === subject?.id) ?? subjectClicked
    const { location } = history;
    if (location.pathname.indexOf("topic") !== -1 || location.pathname.indexOf("subtopic") !== -1) {
      history.push('/flashcard/topics');
    } else if (!examModuleStatus.status && location.pathname.indexOf('exams') !== -1) {
      dispatch(setActiveMediaTab('flashcard'));
      history.push('/flashcard/topics');
    } else if (location.pathname.indexOf('exams') !== -1) {
      history.push('/flashcard/desk/exams');
    }
    dispatch(toggleSubjectsManagerOverlay(false, false));
    dispatch(toggleTeachingLevelOverlay(false, false));
    dispatch(togglePaperOverlay(false, false));
    resetPopup();
    dispatch(setActiveMessageLoader(true))
    setShowLoader(true);
    handleSubjectChange(subject, subjectClicked);
  }

  const closeSubjectsManager = () => {
    dispatch(getExams())
    setShowLoader(false);
    dispatch(setActiveMessageLoader(false));
  };

  const renderSubjects = (subjects: Array<any>) => subjects.map((subject: any) => (
    <Subject
      key={subject.id}
      onClick={setActiveSubject}
      data={subject}
      isActive={subject.id === lastSubjectActive}
      subjectId={subject.id}
    />))

  if (hasLandscape && hasMobile) return null;

  return (
    <>
      {showLoader && <MessageLoader />}
      <PageOverlay onClose={() => dispatch(toggleSubjectsManagerOverlay())} animate={false} topPosition={false} isOverlayVisible={hasSubjectsManagerOverlayVisible} subjectsManager={true} >
        <>{hasOldSubjectsManagerOverlayVisible && <>
          <div className='px-5 flex items-center justify-between mt-3.5 ml-2 py-1.5'>
            <BackButton onClick={() => dispatch(toggleOldSubjectsManagerOverlay(false))} text={<span>subjects</span>} hideArrow={false} />
          </div>
          <div className='mt-5'>
            <div className='flex flex-wrap justify-center items-start'>
              {renderSubjects(oldSubjectsList)}
            </div>
          </div>
        </>}

          {!hasOldSubjectsManagerOverlayVisible && 
          <>
            <div className='subject-manager rounded-2xl px-5 pt-8 pb-0 max-w-full'>
              <div className='freetrialnotification-wrapper'>
                <FreeTrialNotification large={true} hasEnded={true} onClick={() => dispatch(toggleSubjectsManagerOverlay(false))} />
              </div>
              {subjects.length > 1 &&
                <>
                  <div className='mt-5'>
                    <div className='flex justify-center flex-wrap items-start'>
                    {renderSubjects(activeSubjectsList)}
                    </div>
                  </div>
                  <div className='h-9 mt-14 relative mb-2 flex items-center justify-center'>
                    <div className='w-full bg-#E1E6E6 h-px rounded-sm' ></div>
                    <Button onClick={() => dispatch(toggleOldSubjectsManagerOverlay(true))} text='VIEW OLD SUBJECTS' btnTheme={ThemeEnum.White} className={'w-319px absolute box-border'} />
                  </div>
                </>
              }
              <div className='pb-1.5'></div>
            </div>
            </>
          }</>
      </PageOverlay>
    </>
  )
}

export default SubjectsManagerContainer
