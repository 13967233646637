import {CardsIcon, RevisionsIcon, VideosIcon, ExamsIcon} from 'icons/SearchIcons';
type keyBoardKeysTypes = {
    flashcard: {name: string; btn: string; className: string}[];
    revision: {name: string; btn: string; className: string}[];
    video: {name: string; btn: string; className: string}[];
    exams: {name: string; btn: string; className: string}[];
    tools: {name: string; btn: string; className: string}[];
}
export const keyboardKeys: any = {
    flashcard: [
        {
            name: 'Next card',
            btn: '→',
            className: 'w-35px h-24px'
        },
        {
            name: 'Previous card',
            btn: '←',
            className: 'w-35px h-24px'
        },
        {
            name: 'Flip card',
            btn: 'Space',
            className: 'w-56px h-24px'
        },
        {
            name: 'Confidence assessment: unconfident',
            btn: '1',
            className: 'w-30px h-32px'
        },
        {
            name: 'Confidence assessment: neither',
            btn: '2',
            className: 'w-30px h-32px'
        },
        {
            name: 'Confidence assessment: confident',
            btn: '3',
            className: 'w-30px h-32px'
        },
    ] ,
    revision: [
        {
            name: 'Next page',
            btn: '→',
            className: 'w-35px h-24px'
        },
        {
            name: 'Previous page',
            btn: '←',
            className: 'w-35px h-24px'
        },
        {
            name: 'Mark page as not yet read',
            btn: '1',
            className: 'w-30px h-32px'
        },
        {
            name: 'Mark page as read',
            btn: '3',
            className: 'w-30px h-32px'
        },
    ],
    video: [
        {
            name: 'Next video',
            btn: '→',
            className: 'w-35px h-24px'
        },
        {
            name: 'Previous video',
            btn: '←',
            className: 'w-35px h-24px'
        },
        {
            name: 'Pause/play video',
            btn: 'Space',
            className: 'w-56px h-24px'
        },
    ],
    exams: [
        {
            name: 'Next question',
            btn: '→',
            className: 'w-35px h-24px'
        },
        {
            name: 'Previous question',
            btn: '←',
            className: 'w-35px h-24px'
        },
    ],
    tools:[
        {
            name: 'Topics',
            btn: 'T',
            className: 'w-30px h-32px'
        },
        {
            name: 'Exams',
            btn: 'X',
            className: 'w-30px h-32px'
        },
        {
            name: 'Notes',
            btn: 'N',
            className: 'w-30px h-32px'
        },
        {
            name: 'Folders',
            btn: 'F',
            className: 'w-30px h-32px'
        },
        {
            name: 'Exercises',
            btn: 'E',
            className: 'w-30px h-32px'
        },
        {
            name: 'Search',
            btn: 'S',
            className: 'w-30px h-32px'
        },
        {
            name: 'Subject manager',
            btn: 'M',
            className: 'w-30px h-32px'
        }
    ]
}

export const notes: any = {
    flashcard: 'View notes of card on view',
    revision: 'View notes of page on view',
    video: 'View notes of video on view',
    exams: 'View notes of question on view',
}

export const title: any = {
    flashcard: {
        name:  'Card navigation',
        icon: <CardsIcon color={'#2D3838'}/>
    },
    revision: {
        name:  'Page navigation',
        icon: <RevisionsIcon color={'#2D3838'}/>
    },
    video: {
        name:  'Video navigation',
        icon: <VideosIcon color={'#2D3838'}/>
    },
    exams: {
        name:  'Question navigation',
        icon: <ExamsIcon color={'#2D3838'}/>
    }
}


