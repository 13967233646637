import React from 'react';
import PropTypes from 'prop-types';
import {ConfidentIcon, UnconfidentIcon, NeitherIcon, RightIcon,IncorrectIcon,CorrectIcon} from 'icons/FlashcardIcons';

// const computeColor = ({correct, answered}) => {
//   if (correct) return <ConfidentIcon />;
//   if (answered && !correct) return <UnconfidentIcon />;
// };
const AnswerBullet = (props) =>{
  if (props.correct==1 && props.answered) return <ConfidentIcon />;
  if (props.correct==2 && props.answered) return <NeitherIcon />;
  if (props.answered && !props.correct) return <UnconfidentIcon />;
  return null;
}
AnswerBullet.propTypes = {
  // correct: PropTypes.number,
  answered: PropTypes.bool,
};

export default AnswerBullet;

export function MarkRightValue(props) {
  if(props.markValue==1) return  <RightIcon />
  return null
}
export function MCQAnswerBullet(props){
  if (props.correct==0 && props.answered) return <CorrectIcon/>;
  if (props.correct==1 && props.answered) return <IncorrectIcon />;
  return null;
}