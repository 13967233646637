import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {answerById} from 'selectors/answersSelectors';
import {RawStatsBars} from 'ui-components/StatsBars';
import PropTypes from 'prop-types';

class RawStatsBarsContainer extends PureComponent {
  render() {
    const {answer} = this.props;
    const statsBarsData = {
      total: answer.get('total'),
      correct: answer.get('correct'),
      studied: answer.get('studied')
    };

    return <RawStatsBars {...statsBarsData}/>;
  }
}

const mapStateToProps = (state, ownProps) => ({
  answer: answerById(state, ownProps.id)
});

RawStatsBarsContainer.propTypes = {
  id: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
)(RawStatsBarsContainer);
