import * as Actions from 'actions';
import * as Types from 'actions/user-store/types';
import * as Selectors from 'selectors';
import {all, takeLatest, select, put} from 'redux-saga/effects';

export function* watchers() {
  yield all([
    yield takeLatest(Types.TOGGLE_BASKET, updateBodyScroll),
    yield takeLatest(Types.CHANGE_AMOUNT, updateBodyScroll)
  ]);
}

function* updateBodyScroll() {
  const isBasketDetailsVisible = yield select(Selectors.isBasketDetailsVisible);
  if (isBasketDetailsVisible) {
    yield put(Actions.Navigation.toggleBodyScroll(false));
  } else {
    yield put(Actions.Navigation.toggleBodyScroll(true));
  }
}
