import React, {Component} from 'react';
import {EditIcon} from 'icons/HeaderIcons';
import './EditButton.scss';
import * as Actions from 'actions';
import {connect} from 'react-redux';
import * as Selectors from 'selectors';
import styled from 'styled-components/macro';
import Text from 'ui-components/helpers/Text';

class EditButton extends Component {
  render() {
    if (this.props.isDeskEditModeActive) return null;

    return (
      <div
        className="edit-icon cursor-pointer" 
        onClick={() => {this.props.toggleDeskEditMode(true); this.props.deselectAllItems(); this.props.toggleFlashcardNavigationVisibility(false);}}>
        <StyledText color={this.props.color}>EDIT</StyledText>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isDeskEditModeActive: Selectors.isDeskEditModeActive(state)
});

const mapDispatchToProps = {
  deselectAllItems: Actions.Desk.deselectAllItems,
  toggleDeskEditMode: Actions.Navigation.toggleDeskEditMode,
  toggleFlashcardNavigationVisibility: Actions.Navigation.toggleFlashcardNavigationVisibility
};
export default connect(mapStateToProps, mapDispatchToProps)(EditButton);

const StyledText = styled(Text)`
  font-weight: bold;
  font-size: 13px;
  //line-height: 18px;
  text-align: right;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${props => props.color? props.color : "#378EF8"};
  @media (pointer: fine) {
    &:hover {
      color: #64A8FA;
    }
  } 
  &:active {
    color: #2170CF;
  }
`;