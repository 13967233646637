import {setActiveTeachingLevel} from 'actions/subject';
import {getCenteredScrollValue} from 'helpers';
import {List} from 'immutable';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getActiveTeachingLevel, teachingLevelsForActiveSubject} from 'selectors';
import styled from 'styled-components/macro';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';
import PropTypes from 'prop-types';
import {orderTeachingLevel} from 'helpers';

export class TeachingLevelSelectorContainer extends Component {
  constructor(props) {
    super(props);

    this.teachingLevelsWrapper = React.createRef();
    this.activeTeachingLevelWrapper = React.createRef();
  }

  componentDidMount() {
    const {teachingLevelsForActiveSubject, activeTeachingLevelId} = this.props;
    let activeTeachingLevelIndex;

    for (let i = 0; i < teachingLevelsForActiveSubject.length; i++) {
      if (teachingLevelsForActiveSubject.i.id === activeTeachingLevelId) {
        activeTeachingLevelIndex = i;
        break;
      }
    }

    if (this.activeTeachingLevelWrapper && this.activeTeachingLevelWrapper.current) {
      this.teachingLevelsWrapper.current.scrollLeft = getCenteredScrollValue(this.teachingLevelsWrapper.current, this.activeTeachingLevelWrapper.current, activeTeachingLevelIndex);
    }
  }


  onTeachingLevelSelected = (item) => {
    const {setActiveTeachingLevel, onItemSelectedCallback} = this.props;

    setActiveTeachingLevel(item.get('id'));
    onItemSelectedCallback && onItemSelectedCallback(item);
  };

  render() {
    const {teachingLevelsForActiveSubject, activeTeachingLevelId, shadow, className} = this.props;
    //Hide teaching level selector entirely if only ALL and one more TL are visible
    if (teachingLevelsForActiveSubject.length === 2) return null;
    const orderTeachingLevels = orderTeachingLevel(teachingLevelsForActiveSubject)
    return <TeachingLevelSelectorWrapper className={className} shadow={shadow} ref={this.teachingLevelsWrapper}>
    <Padding top={0} bottom={14} left={this.props.leftPadding} right={20}>
        <Row>{orderTeachingLevels.map(item => {
          return <TeachingLevel
            key={item.get('id')}
            ref={activeTeachingLevelId === item.get('id') && this.activeTeachingLevelWrapper}
            active={activeTeachingLevelId === item.get('id')}
            onClick={() => this.onTeachingLevelSelected(item)}
          >
            {item.get('title')}
          </TeachingLevel>;
        })}
        </Row>
      </Padding>
    </TeachingLevelSelectorWrapper>;
  }
}

const mapStateToProps = (state) => {
  return {
    teachingLevelsForActiveSubject: teachingLevelsForActiveSubject(state),
    activeTeachingLevelId: getActiveTeachingLevel(state)
  };
};

const mapDispatchToProps = {
  setActiveTeachingLevel: setActiveTeachingLevel
};

TeachingLevelSelectorContainer.defaultProps = {
  leftPadding: 0
};

TeachingLevelSelectorContainer.propTypes = {
  teachingLevelsForActiveSubject: PropTypes.instanceOf(List).isRequired,
  activeTeachingLevelId: PropTypes.number,
  setActiveTeachingLevel: PropTypes.func.isRequired,
  onItemSelectedCallback: PropTypes.func,
  shadow: PropTypes.bool,
  className: PropTypes.string,
  leftPadding: PropTypes.number,
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(TeachingLevelSelectorContainer);

const TeachingLevelSelectorWrapper = styled.div`
  overflow: auto;
  //margin-left: -2px;
  -webkit-overflow-scrolling: touch;

`;

export const TeachingLevel = styled.div`
  text-transform: uppercase;
  text-align: center;
  min-width: 76px;
  flex: 0 0 auto;
  padding: 8px 22px;
  line-height: 18px;
  color: ${props => props.theme.lightGrey2};
  border-radius: 17px;
  font-size: 13px;
  //margin-left: 2px;
  margin-right: 12px;
  white-space: nowrap;
  font-weight: bold;
  
  cursor: pointer;
  letter-spacing: 2px;
  ${props => !props.active && `
    box-shadow: 0 0 0 2px #F1F3F2 inset;
  `}
  ${props => props.active && `
    color: ${props.theme.blue};
    box-shadow: 0 0 0 2px ${props.theme.blue} inset;
  `} 
  :last-child {
    margin-right: 0;
  }
`;
