import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getAllTopicsInSubject, getSubtopicsInTopic } from 'actions/api';
import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2';
import { flashcardIdsResponse, topicsForCurrentActiveTeachingLevel, activeSubjectId, allTopicsLoading } from 'selectors';
import { scrollToPreviewId, getActiveTopicId, getActivSubTopicId } from 'selectors/topicsTreeSelector';
import TopicTileContainer from 'v2/components/topics-tree/TopicTileContainer';
import RequestLoader from 'v2/components/loader.v2/RequestLoader';
import { subtopicsSelector } from 'selectors/api/apiSelector';
import SubtopicTileContainer from 'v2/components/topics-tree/SubtopicTileContainer';
import usePrevious from 'hooks/usePrevious';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';

type TopicsTreeContainerProps = {
  subjectId: number,
  isExernalExercise?: boolean,
  showAnswers: boolean,
  isExercise?: boolean,
  // onPreviewClick work only exercise (select flashcard)
  onPreviewClick?: (arg: any) => void,
  hasSelection?: boolean,
  scrollTo: (arg: any) => void
}

const TopicsTreeContainer: React.FC<TopicsTreeContainerProps> = ({ subjectId, isExernalExercise, showAnswers, isExercise, scrollTo, onPreviewClick, hasSelection }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const hasCurrentActiveTeachingLevel = useSelector((state) => topicsForCurrentActiveTeachingLevel(state, subjectId, isExernalExercise))?.toJS();
  const hasTopicsLoading = useSelector((state) => allTopicsLoading(state, subjectId));
  const hasFlashcardIdsResponse = useSelector(flashcardIdsResponse)?.toJS();
  const activeTopicId = useSelector(getActiveTopicId);
  const activeSubtopicId = useSelector(getActivSubTopicId);
  const subtopics = useSelector(subtopicsSelector)?.toJS();
  const hasScrollToPreviewId = useSelector(scrollToPreviewId)
  const hasActiveSubjectId = useSelector(activeSubjectId);
  const prevValue: any = usePrevious({ 'scrollToPreviewId': hasScrollToPreviewId, "subjectId": subjectId })

  useEffect(() => {
    if (prevValue) {
      if (prevValue.scrollToPreviewId !== hasScrollToPreviewId) {
        scrollToActive()
      }
    }
  }, [hasScrollToPreviewId])

  useEffect(() => {
    hasCurrentActiveTeachingLevel.length === 0 && dispatch(getAllTopicsInSubject({
      key: hasActiveSubjectId,
      subjectId: hasActiveSubjectId,
      mapAnswers: true
    }))
  },[dispatch, hasActiveSubjectId, hasCurrentActiveTeachingLevel.length])

  const scrollToActive = () => {
    const currentRef = wrapperRef.current;
    if (!currentRef) return;
    let activeElement = currentRef.querySelector('div[data-preview-active="true"]');
    if (!activeElement) {
      //check for active subtopic
      activeElement = currentRef.querySelector('div[data-subtopic-active="true"]');
    }
    if (!activeElement) return;
    scrollTo(activeElement);
  }

  const renderTopic = (topic: any) => {
    const topicId = topic?.id;
    const parentIds = [subjectId, topic?.tlvlId];
    if (!(isExercise && topic?.flashcardBox?.total <= 0)) {
      return <TopicTileContainer
        parentIds={parentIds}
        key={topicId}
        topic={topic}
        activeTopicId={activeTopicId}
        activeSubtopicId={activeSubtopicId}
        onClick={onTopicTileClick}
        onPreviewClick={onPreviewClick}
        hasSelection={hasSelection}
        isExercise={isExercise}
        isExernalExercise={isExernalExercise}
        showAnswers={showAnswers} >
        <RequestLoader request={subtopics?.[topicId]} loader={<div className='flex justify-center '>
          <Loader background={BackgroundEnum.Blue} size={SizeEnum.Small} />
        </div>} allowUpdate>
          {subtopics?.[topicId]?.data?.map((subtopic: any) => renderSubtopic(subtopic, topic))}
        </RequestLoader>
      </TopicTileContainer>
    }
  }

  const renderSubtopic = (subtopic: any, parentTopic: any) => {
    const parentIds = [subjectId, parentTopic?.tlvlId, parentTopic?.id];
    const subtopicId = subtopic?.id;
    if (!(isExercise && subtopic?.flashcardBox?.total <= 0)) {
      return <SubtopicTileContainer
        parentIds={parentIds}
        parentTopic={parentTopic}
        key={subtopicId}
        subtopic={subtopic}
        isExercise={isExercise}
        hasSelection={hasSelection}
        showAnswers={showAnswers}
        isExernalExercise={isExernalExercise}
        onPreviewClick={onPreviewClick}
        onClick={isExercise ? onPreviewClick : ()=>{}} />

    }
  }

  const onTopicTileClick = (topicId: number, parentIds: any) => {
    if (subtopics?.[topicId]) return;
    dispatch(getSubtopicsInTopic({ id: topicId, parentIds: parentIds, mapAnswers: showAnswers }));
  }

  if (hasCurrentActiveTeachingLevel.length === 0 || !hasTopicsLoading || !hasFlashcardIdsResponse?.data) {
    return <LeftMenuLoader />;
  }
  return <div ref={wrapperRef}>
    {hasCurrentActiveTeachingLevel?.map(renderTopic)}
  </div>
}

export default TopicsTreeContainer;