import { useSelector } from 'react-redux';
import { getExamHeader } from 'selectors'
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';

const ExamHeader = () => {
  const examHeader = useSelector(getExamHeader);
  return <>
    <div className='py-[15px]'>
      <div className='flex items-center'>
        {examHeader && examHeader.teachingTag &&
          <TeachingLevelSeperator
            tlvl={examHeader?.teachingTag}
            tlvlTag={examHeader?.teachingTag}
            isTopic={true}
            slTag={examHeader?.teachingTag === "SL" ? true : false} />
        }
      </div>
    </div>
    <div className='text-[25px] font-bold text-#2D3838 pb-3.5 leading-[34px]'>{examHeader?.exam_name}</div>
  </>
}

export default ExamHeader;
