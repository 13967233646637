import * as Actions from 'actions';
import { setCurrentExerciseId } from 'actions/studying';
import { ReactComponent as SwopActive } from 'assets/icons/toolbox/swop-active.svg';
import BottomMenuNavigation from 'v2/components/left-menu-navigation/BottomMenuNavigation';
import DeviceManager from 'v2/components/device-manager/DeviceManager';
import ExerciseNotificationsContainer from 'v2/components/exercise/ExternalExercisesNotificationsContainer';

import LeftMenuContainer from 'v2/components/left-menu-navigation/LeftMenuNavigation';
import KeyboardNavigation from 'v2/components/keyboard-navigation/KeyboardNavigation';
import FullPageLoader from 'v2/components/loader.v2/FullPageLoader';
import NotificationWrapper from 'v2/components/notifications/notification-wrapper/NotificationWrapper';
import Onboarding from 'v2/components/onboarding/Onboarding';
import AccountsPortalOverlay from 'v2/components/studying/accounts-portal/AccountsPortalOverlay';
import CardBackButtonContainer from 'components/studying/CardBackButtonContainer';
import MoreControlOverlay from 'v2/components/studying/control-overlay/MoreControlOverlay';
import TeachingLevelPopupOverlay from 'v2/components/studying/control-overlay/TeachingLevelPopupOverlay';
import ToolTipManager from 'components/studying/control-overlay/tool-tip-manager';
import * as ToolTipComponents from 'components/studying/control-overlay/toolTipsIndex';

import StudyingContainer from 'v2/components/studying/StudyingContainer';
import ToggleMenuButtonContainer from 'components/studying/ToggleMenuButtonContainer';
// import SubjectsManagerContainer from 'components/subjects-manager/SubjectsManagerContainer';
import SubjectsManagerContainer from 'v2/components/subjects-manager/SubjectsManagerContainer';
import SupportComponent from 'components/supports-manager/SupportContainer';
import SupportManagerContainer from 'v2/components/supports-manager/SupportManagerContainer';
import { addBackFlashcardToLocalStorage, decodeQuery, getbackFlashcard, getStoreExamsParentId, toggleEnableCookies } from 'helpers';
import LinksPage from 'pages/link/LinksPage';
import MenuRouter from 'v2/pages/menu/MenuRouter';
import StoreModal from 'pages/store/StoreModal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Selectors from 'selectors';
import { activeSubjectId, getFeature, isDeviceManagerOverlayVisible, isDiffficultyOverlayVisible, isFilterOverlayVisible, isHiddenInfoOverlayVisible, isKeyboardNavigationOverlayVisible, isLandscape, isMainMicroFilterVisible, isMCQOverlayVisible, isMobile, isPaperOverlayVisible, isPortrait, isProgressBarFilterVisible, isQuestionInfoOverlayVisible, isSourceOverlayVisible, isSubjectsManagerOverlayVisible, isSupportManagerOverlayVisible, isTeachingLevelExamsOverlayVisible, isVideoOverlayVisible, setMediaTypeFilter } from 'selectors';
import { canToggleMenu, currentFlashcardId } from 'selectors/studying';
import { getUserId } from 'selectors/user';
import styled, { css } from 'styled-components/macro';
import media from 'ui-components/helpers/media';
import KeyboardListenerContainer from '../components/keyboard-listener/KeyboardListenerContainer';
import './AppLayout.scss';
// import OSCHeader from './OSCHeader';
import OSCHeader from 'v2/layout/OSCHeader';
import FilterOverlay from 'v2/layout/FilterOverlay';
class AppLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previousLocation: null,
      isReady: false,
      isFeature: false
    };
  }

  componentDidMount() {
    addBackFlashcardToLocalStorage(null, null);
    document.body.classList.add('white');
  }

  componentWillUnmount() {
    document.body.classList.remove('white');
  }
  componentDidUpdate(prevProps) {
    if (this.props.isMobile && prevProps.isLandscape !== this.props.isLandscape && this.props.isLandscape === true) {
      if (this.props.isNavigationSectionVisible === false) {
        this.props.toggleNavigationSection(false, false);
        this.props.setActiveFlashcardTab(-1);
      }
    }

    if (prevProps.activeSubjectId && prevProps.activeSubjectId !== this.props.activeSubjectId) {
      this.props.setCurrentExerciseId({ activeExerciseId: null, type: null });
      //SMPP-Laggy
      this.props.setQuickStatus(this.props.activeSubjectId);
    }

    if (prevProps.location.pathname !== this.props.location.pathname || prevProps.location.search !== this.props.location.search || prevProps.subjects.length !== this.props.subjects.length) {
      this.props.getFeature()
    }
    if (this.props.feature !== prevProps.feature) {

      if (this.props.feature && this.props.feature.size > 0) {
        this.setState({ isFeature: true })
      } else {
        this.setState({ isFeature: false })
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState({ previousLocation: this.props.location });
    }
  }

  UNSAFE_componentWillMount() {
    const flashcardId = this.getFlashcardIdFromUrl();
    const activeMediaTab = this.getActiveMediaTab();
    if (activeMediaTab) {
        this.props.setActiveMediaTab(activeMediaTab);
    }
    const exercisePageRegex = RegExp('/flashcard/classroom/exercise/([0-9]+)');

    const exerciseDeskPageRegex = RegExp('/flashcard/desk/exercise/([0-9]+)');

    const examDeskPageRegex = RegExp('/flashcard/desk/exams/([0-9]+)');

    let exerciseId;
    let examsId;
    if (exercisePageRegex.test(this.props.location.pathname)) {
      const matches = exercisePageRegex.exec(this.props.location.pathname);
      exerciseId = matches[1];
    } else if (examDeskPageRegex.test(this.props.location.pathname)) {
      const matches = examDeskPageRegex.exec(this.props.location.pathname);
      examsId = matches[1];

    } else {
      if (exerciseDeskPageRegex.test(this.props.location.pathname)) {
        const matches = exerciseDeskPageRegex.exec(this.props.location.pathname);
        exerciseId = matches[1];
      }
    }

    if (exerciseId || flashcardId || examsId) {
      this.props.toggleSubjectsManagerOverlay(false, false);
    }

    this.props.dataInit(flashcardId, exerciseId, activeMediaTab, examsId);
  }
  render() {
    return this.props.isAuthenticated && this.props.subjects.length > 0 ? this.renderAppLayout() :
    <FullPageLoader text={'Loading...'}/>;
  }

  renderMenu() {
    return this.props.isMenuVisible ? <MenuRouter /> : null;
  }

  getFlashcardIdFromUrl() {
    // const searchString = this.props.location.search;
    // if (!searchString) return;
    // const {flashcardId} = decodeQuery(searchString.substring(1));
    if (!this.props.location.search) return
    const flashcardId = this.props.location.search.split('id=')[1]

    if (!flashcardId) return;
    if(this.getActiveMediaTab() ==='exams') return flashcardId;
    return parseInt(flashcardId);
  }
  getActiveMediaTab() {
    if (!this.props.location.search) return
    const activeMediaTab = (this.props.location.search.split('mediaType='))[1].split("&id=")[0]
    if (!activeMediaTab) return;
    return activeMediaTab;
  }

  renderPage() {
    return React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        location: this.props.location,
        previousLocation: this.state.previousLocation,
        urlQuery: decodeQuery(this.props.location.search.substring(1))
      });
    });
  }

  closeToggleMenu = (isNav, isLink, isSupport) => {
    if (this.props.currentFlashcardId) {
      if (isNav) {
        this.props.toggleNavigationSection(false, false)
        this.props.setActiveFlashcardTab(-1)
      }
      if (isLink) {
        this.props.toggleLinksNavigationSection(false, false)
      }
      if (isSupport) {
        this.props.toggleSupportManagerOverlay(false, false)
      }
    }
  }

  closePopUp = (id) => {
    this.setState({ isFeature: false })
    this.props.closeFeature(id)
  }


  onClickCloseFilter = (e) => {
    e.stopPropagation();
  }
  handleAccountClose = () => {
    this.props.toggleAccountsPortalOverlay(!this.props.isAccountsPortalOverlayVisible)
  }

  renderAppLayout() {

    const { content: Content, isLandscape, isMobile, isIPadPro, currentFlashcardId, location, isAccountsPortalOverlayVisible, toggleAccountsPortalOverlay } = this.props;
    const isMobileLandscape = (isMobile && isLandscape)
    const pathname = location.pathname;
    return (
      <div className="app-layout">
        
        {!this.props.isMobile && <WhiteSpace position={"left"}/>}
          <OSCHeader />
        {!this.props.isMobile && <WhiteSpace position={"right"}/>}
        {this.props.canToggleMenu && !this.props.slidingMenu && (
          <MenuToggle className={'tutorial-step-desktop-menu'}
            isOpen={!this.props.isNavigationSectionVisible}>
            <ToggleMenuButtonContainer />
          </MenuToggle>
        )}
        {this.props.swap && (
          <StyledSwop><SwopActive /></StyledSwop>
        )}
        {(!this.props.isNavigationSectionVisible || !this.props.isLinksNavigationSectionVisible || (this.props.isSupportManagerOverlayVisible && (this.props.isMobile && this.props.isLandscape))) && !this.props.slidingMenu && (
          <React.Fragment>
            <MenuOverlay onClick={() => this.closeToggleMenu(!this.props.isNavigationSectionVisible, !this.props.isLinksNavigationSectionVisible, this.props.isSupportManagerOverlayVisible)} hideBg={!this.props.isLinksNavigationSectionVisible || this.props.isSupportManagerOverlayVisible} isCard={!this.props.currentFlashcardId} className={`${!this.props.currentFlashcardId ? 'no-card-overlay' : ''}`} />
            <CardBackButtonContainer isLeftOverlay={!this.props.isNavigationSectionVisible} isRightOverlay={!this.props.isLinksNavigationSectionVisible} flashcardId={this.props.currentFlashcardId} returnCardId={getbackFlashcard()} />
          </React.Fragment>
        )}

        {(this.props.isSubjectsManagerOverlayVisible || this.props.isSupportManagerOverlayVisible || this.props.isKeyboardNavigationOverlayVisible) && !(isMobile && isLandscape) && (
          <React.Fragment>
            {this.props.isSubjectsManagerOverlayVisible && (<ToggleManagerOverlay onClick={() => this.props.toggleSubjectsManagerOverlay(false, false)} />)}
            {this.props.isSupportManagerOverlayVisible && (<ToggleManagerOverlay onClick={() => this.props.toggleSupportManagerOverlay(false, false)} />)}
            {this.props.isKeyboardNavigationOverlayVisible && (<ToggleManagerOverlay onClick={() => this.props.toggleKeyboardNavigationOverlay(false, false)} />)}
          </React.Fragment>
        )}
  
         {!(pathname.indexOf("/flashcard/desk/exercise/edit") === -1 && pathname.indexOf("/flashcard/desk/exercise/create") === -1) || !(isMobile || isIPadPro) && <LeftMenuContainer currentFlashcardId={currentFlashcardId} /> }
          {!isMobileLandscape && <div className={`navigation-section-wrapper ${this.props.isNavigationSectionVisible ? `navigation-section-visible` : ``} ${(isMobile || isIPadPro) && `device-mobile`} ${!(pathname.indexOf("/flashcard/desk/exercise/edit") === -1 && pathname.indexOf("/flashcard/desk/exercise/create") === -1) ? 'edit-exercise-section' : ''}` }>
            <div
              className={`navigation-section ${!this.props.isNavigationSectionVisible ? 'open' : ''} ${this.props.slidingMenu ? 'sliding' : ''} ${!(pathname.indexOf("/flashcard/desk/exercise/edit") === -1 && pathname.indexOf("/flashcard/desk/exercise/create") === -1) ? 'edit-exercise-section' : ''}`}>
              {!isMobileLandscape && this.renderMenu()}
              {this.props.activeSubjectId && this.renderPage()}
            </div>
          </div>}

        <div
          className={`link-navigation-section ${!this.props.isLinksNavigationSectionVisible || (this.props.isSupportManagerOverlayVisible && isMobileLandscape) ? 'open' : ''} ${this.props.slidingMenu ? 'sliding' : ''}`}>
          {(!this.props.isLinksNavigationSectionVisible && this.props.activeSubjectId) && <div className="relative flashcard-nav-route-wrapper"><LinksPage /></div>}
          {(this.props.isSupportManagerOverlayVisible && isMobileLandscape) && <SupportComponent />}
        </div>
        <FilterOverlay />
        {this.props.activeSubjectId && <Content />}
        <NotificationWrapper hasNavigationSectionVisible={!this.props.isNavigationSectionVisible} />
        {this.props.activeSubjectId && <ExerciseNotificationsContainer />}
        <MoreControlOverlay currentId={this.props.currentFlashcardId} />
         <TeachingLevelPopupOverlay subjectId={this.props.activeSubjectId} flashcardId={this.props.currentFlashcardId} /> 
         {isAccountsPortalOverlayVisible && <PopupOverlayWithoutBg className="filter-overlay-box" onClick={this.handleAccountClose}>
            <AccountsPortalOverlay onClose={(e) => toggleAccountsPortalOverlay(!isAccountsPortalOverlayVisible)}/>
         </PopupOverlayWithoutBg>}
        <SubjectsManagerContainer pathname={pathname} />
        <SupportManagerContainer />
        <DeviceManager />
        {!this.props.isMobile && <KeyboardNavigation />}
        <KeyboardListenerContainer />
        <Onboarding />
        {((pathname.indexOf("/flashcard/desk/exercise/edit") === -1 && pathname.indexOf("/flashcard/desk/exercise/create") === -1) && (isMobile || isIPadPro)) && (<BottomMenuNavigation currentId={currentFlashcardId} />)}
      </div>
    );
  }
  renderDeviceLimitAppLayout() {
    return (
      <div className="app-layout">
        <DeviceManager />
      </div>
    );
  }
}


AppLayout.defaultProps = {
  content: StudyingContainer
};

AppLayout.propTypes = {
  children: PropTypes.object.isRequired,
  rightSection: PropTypes.func,
  slidingMenu: PropTypes.bool,

  // Connect
  subjects: PropTypes.array,
  location: PropTypes.object,
  canToggleMenu: PropTypes.bool,
  activeSubjectId: PropTypes.number,
  dataInit: PropTypes.func.isRequired,
  toggleNavigationSection: PropTypes.func,
  isMenuVisible: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isNavigationSectionVisible: PropTypes.bool,
};

const mapStateToProps = state => ({
  isAuthenticated: Selectors.isAuthenticated(state),
  isMenuVisible: Selectors.isMenuVisible(state),
  subjects: Selectors.getSubjects(state),
  isNavigationSectionVisible: Selectors.isNavigationSectionVisible(state), //TODO remove and replace with isMenuVisible
  isLinksNavigationSectionVisible: Selectors.isLinksNavigationSectionVisible(state),
  activeSubjectId: activeSubjectId(state),
  canToggleMenu: canToggleMenu(state),
  currentFlashcardId: currentFlashcardId(state),
  isMobile: isMobile(state),
  isLandscape: isLandscape(state),
  isPortrait: isPortrait(state),
  swap: Selectors.Studying.swap(state),
  isDeviceManagerOverlayVisible: isDeviceManagerOverlayVisible(state),
  isSubjectsManagerOverlayVisible: isSubjectsManagerOverlayVisible(state),
  isSupportManagerOverlayVisible: isSupportManagerOverlayVisible(state),
  isKeyboardNavigationOverlayVisible: isKeyboardNavigationOverlayVisible(state),
  setMediaTypeFilter: setMediaTypeFilter(state),
  userId: getUserId(state),
  feature: getFeature(state),
  activeMediaTab: Selectors.getActiveMediaTab(state),
  isFilterOverlayVisible: isFilterOverlayVisible(state),
  isPaperOverlayVisible: isPaperOverlayVisible(state),
  isDiffficultyOverlayVisible: isDiffficultyOverlayVisible(state),
  isVideoOverlayVisible: isVideoOverlayVisible(state),
  isSourceOverlayVisible: isSourceOverlayVisible(state),
  isQuestionInfoOverlayVisible: isQuestionInfoOverlayVisible(state),
  isHiddenInfoOverlayVisible: isHiddenInfoOverlayVisible(state),
  isTeachingLevelExamsOverlayVisible: isTeachingLevelExamsOverlayVisible(state),
  isMCQOverlayVisible: isMCQOverlayVisible(state),
  isMainMicroFilterVisible: isMainMicroFilterVisible(state),
  isProgressBarFilterVisible: isProgressBarFilterVisible(state),
  isTeachingLevelOverlayVisible: Selectors.isTeachingLevelOverlayVisible(state),
  isEnableCookiesOverlay: Selectors.isEnableCookiesOverlay(state),
  isAccountsPortalOverlayVisible: Selectors.isAccountsPortalOverlayVisible(state),
  isIPad: Selectors.isIPad(state),
  isIPadPro: Selectors.isIPadPro(state),
});

const mapDispatchToProps = {
  dataInit: Actions.dataInit,
  setActiveFlashcardTab: Actions.Navigation.setActiveFlashcardTab,
  setActiveMediaTab: Actions.Navigation.setActiveMediaTab,
  toggleNavigationSection: Actions.Navigation.toggleNavigationSection,
  toggleLinksNavigationSection: Actions.Navigation.toggleLinksNavigationSection,
  toggleSubjectsManagerOverlay: Actions.Navigation.toggleSubjectsManagerOverlay,
  toggleKeyboardNavigationOverlay: Actions.Navigation.toggleKeyboardNavigationOverlay,
  toggleSupportManagerOverlay: Actions.Navigation.toggleSupportManagerOverlay,
  toggleMoreControlOverlay: Actions.Navigation.toggleMoreControlOverlay,
  setCurrentExerciseId: setCurrentExerciseId,
  setQuickStatus: Actions.Api.setQuickStatus,
  getFeature: Actions.Api.getFeature,
  closeFeature: Actions.Api.closeFeature,
  toggleFilterOverlay: Actions.Navigation.toggleFilterOverlay,
  togglePaperOverlay: Actions.Navigation.togglePaperOverlay,
  toggleDifficultyOverlay: Actions.Navigation.toggleDifficultyOverlay,
  toggleVideoOverlay: Actions.Navigation.toggleVideOverlay,
  getMediaTypeFilterOverlay: Actions.Navigation.getMediaTypeFilterOverlay,
  toggleSourceOverlay: Actions.Navigation.toggleSourceOverlay,
  toggleQuestionInfoOverlay: Actions.Navigation.toggleQuestionInfoOverlay,
  toggleHiddenInfoOverlay: Actions.Navigation.toggleHiddenInfoOverlay,
  toggleTeachingLevelExamsOverlay: Actions.Navigation.toggleTeachingLevelExamsOverlay,
  toggleMCQOverlay: Actions.Navigation.toggleMCQOverlay,
  toggleMainMicroOverlay:Actions.Navigation.toggleMainMicroOverlay,
  updateProgressBarFilter: Actions.Navigation.progressBarFilter,
  toggleEnableCookiesOverlay: Actions.Navigation.toggleEnableCookiesOverlay,
  toggleAccountsPortalOverlay: Actions.Navigation.toggleAccountsPortalOverlay
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);

const WhiteSpace = styled.div`
  content: '';
  height: 100%;
  width: calc(calc(100% - 1440px) / 2);
  background-color: #eff4f9;
  box-shadow: ${({position})=>position==='left' ? 'inset -15px 0 32px -15px rgb(62 87 85 / 26%)' : 'none' };
  ${({position})=> position === "left" ? 'left: 0' : 'right:0'};
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
`;

const MenuOverlay = styled.div`
  top: 24px;
  //top: 0;
  position: fixed;
  left: 0;
  bottom: ${props => props.hideBg ? "0" : '0'};
  right: 0;
  ${props => !props.isCard && css`
    opacity: 0.60;
    background: #1A6DAC;
  `}

  z-index: ${props => props.hideBg ? "3" : '2'};
  display: none;
  margin-left:${props => props.hideBg ? "0px !important" : 'unset'};
  ${media.collapsedStudy(css`
     display: block;
  `)}
  
  ${media.phone(css`
    //display: none;
    @media(orientation: landscape){
      display: block;
      margin-left: 56px;
      bottom: 0;
    }
  `)}
`;
const StyledSwop = styled.div`
  z-index: 2;
  position: absolute;
  top: 7px;
  left: 5px;
`;

const ToggleManagerOverlay = styled.div`
  max-width: 1440px;
  margin: auto;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #1A6DAC;
  opacity: 0.60;
  right: 0;
  top: 0;
  display: block;
  z-index: 4;
  cursor: pointer;
  
`
const MenuToggle = styled.div`
  z-index: 100;
  padding: 0 7px;
  position: fixed;
  top: 7px;
  left: 0;
  transition: all 0.35s cubic-bezier(.25, .75, .5, 1);
  transform: translateX(${({ isOpen }) => isOpen ? 375 : 0}px);
  display:none;
  
  ${media.phone(css`
    display: none;
  `)}
`;

const PopupOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(26,109,172,0.60);
  z-index: 6;
  max-width: 1440px;
  margin: auto;
`;

const PopupOverlayWithoutBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  // right: 0;
  right: calc(max(calc(100% - 1440px), 0px) / 2);
  // background-color: rgba(26,109,172,0.60);
  z-index: 999;
`;
