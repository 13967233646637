import React, {memo} from 'react'
import swal from 'sweetalert';
import './notifications.scss'

 

const MbMergeNotification = () => {
    return <div className='-mb-5'>
       <div className='p-5'>
            <div className='text-15px leading-normal text-#2D3838'>
                You’re about to benefit from a variety of functionalites by merging your <div className='font-light'>SMART</div><b>PREP</b> account
                with <b>ManageBac</b>. This
                action will replace your profile and cannot be undone. Your study progress remains untouched.
            </div>
            <div className='pb-[25px]' />
            <div className='text-15px leading-normal text-#2D3838'>
                Do you want to proceed?
            </div>
       </div>
       <div className='bg-[#E1E6E6] h-px rounded-[1px] m-0' />
       <div className='flex justify-between'>
            <ButtonText onClick={() => {
                swal.setActionValue({cancel: {shouldMerge: true}});
                swal.close();
            }} text={'Yes'}/>
            <div className='bg-#E1E6E6 w-px min-h-full' />
            <ButtonText onClick={() => {
                 swal.setActionValue({cancel: {shouldMerge: false}});
                 swal.close();
            }} text={'No'}/>
       </div>
    </div>
}

const ButtonText = ({onClick, text}:{onClick: () => void, text: string}) => {
    return <div className='cursor-pointer w-3/6 text-center leading-48px text-17px text-#2EBCB4'>
        {text}
    </div>
}

export default memo(MbMergeNotification);