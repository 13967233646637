import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { getActiveMediaTab, isMBFromSchool, isOSCUser } from 'selectors';
import Flickity from "react-flickity-component";
import StudyMetaInfo from 'v2/components/studying/studay-meta-info/StudyMetaInfo';
import { useSelector } from 'react-redux';
import useStatusBars from 'hooks/useStatusBars';
import FlashcardIcon from 'assets/images/study/StudyMeta/Flashcard.svg';
import RevisionIcon from 'assets/images/study/StudyMeta/RevisionGuide.svg';
import VideoIcon from 'assets/images/study/StudyMeta/Video.svg';
import classNames from 'classnames';
import './studyMetaInfo.scss';
import MediaTypeStatusBar from 'v2/components/media-tab/MediaTypeStatusBar';
import ExamIcon from 'assets/images/study/StudyMeta/Exams.svg';
import ExamMediaTypeStatusBar from 'v2/components/media-tab/ExamMediaTypeStatusBar';
import { MultipleChoice, FreeResponse } from "icons/HeaderIcons"

type StudyMetaInformationProps = {
  activeMediaType: string,
  topicData: any,
  id: number,
  active?: boolean | undefined,
  isSubtopic?: boolean | undefined,
  hasSelection: boolean | null | undefined,
  isMcq: boolean,
  onClick: (arg: string) => void,
  className?: string,
  onCardClick: (arg: string) => void,
}
type CardsCount = {
  [key: string]: boolean
}
type MediaCard = {
  [key: string]: any
}
type MediaStatus = {
  status: any,
  icon: string,
  name: string,
  mediaType: string,
  hasNumbering: string,
  statusBarData: any,
  title: string,
}

const StudyMetaInformation: React.FC<StudyMetaInformationProps> = ({ topicData, hasSelection, id, active, isSubtopic, onClick, onCardClick, activeMediaType }) => {
  const [flkty, setFlkty] = useState<any>();
  const { flashcardStatus, revisionStatus, videoStatus, flashcardStatusGraph, revisionStatusGraph, videoStatusGraph, examsStatus, examsStatusGraph, mcqStatusGraph, mcqStatus } = useStatusBars<number>(id);
  const activeMediaTab = useSelector(getActiveMediaTab);
  const hasMBFromSchool = useSelector(isMBFromSchool);
  const hasOSCUser = useSelector(isOSCUser);
  const flashcardNumbering = topicData?.flashcardBox?.absoluteNumbering || topicData?.flashcardBox?.numbering;
  const revisionNumbering = topicData?.revisionGuideBox?.numbering;
  const videoNumbering = topicData?.videoBox?.numbering;

  const carouselCount = useCallback((val: string) : any => {
    let isCarousel: number = 0;
    let arr: any[] = [];
    const cardsCount: CardsCount = {
      "flashcard": flashcardStatus.total > 0 ? true : false,
      "revision": revisionStatus.total > 0 ? true : false,
      "video": videoStatus.total > 0 ? true : false,
      "exams": examsStatus.total > 0 || mcqStatus.total > 0 ? true : false
    }
    if (val === 'count') {
      Object.keys(cardsCount).map(function(key, index) {
        if (cardsCount[key]) {
          isCarousel++;
        }
        else {
          isCarousel--;
        }
      });
      return isCarousel;
    } else {
      Object.entries(cardsCount).map(([key, values], i) => {
        if (values) {
          arr.push(key)
        }

      })
      return arr
    }

  }, [flashcardStatus, revisionStatus, videoStatus, examsStatus, mcqStatus])

  const SliderContainer = useMemo(() => {
    if (examsStatus.total > 0 && mcqStatus.total > 0 && (hasOSCUser || hasMBFromSchool)) {
      return 'min-h-[170px]'
    } else {
      return 'min-h-[121px]'
    }
  }, [examsStatus, mcqStatus, flashcardStatus])

  let flickityOptions = {
    pageDots: true,
    groupCells: 1,
    prevNextButtons: true,
    contain: true,
    initialIndex: currentCard(),
    on: {
      select: (e: number) => OnChangeIndex(e),
      staticClick: (event: any, pointer: any, cellElement: any, cellIndex: number) => cardClick(cellIndex)
    }
  }

  function currentCard() {
    let activeKey = 0;
    carouselCount('array').map((val: any, i: number) => {
      if (val === activeMediaTab) {
        activeKey = i
      }
    })
    return activeKey
  }

  useEffect(() => {
    flkty?.select(currentCard())
  }, [activeMediaTab])
  function OnChangeIndex(params: number) {
    let activeKey: string = '';
    carouselCount('array').map((value: any, index: number) => {
      if (params === index) {
        activeKey = value;
      }
    })
    onClick(activeKey)
  }

  const cardClick = useCallback((val: number) => {
    let activeKey: string = '';
    carouselCount('array').map((value:any, index:any) => {
      if (val === index) {
        activeKey = value;
      }
    })
    onCardClick(activeKey)
  }, [activeMediaType, flashcardStatus, revisionStatus, videoStatus, examsStatus, mcqStatus, activeMediaTab])

  const mediaStatus: MediaStatus[] = useMemo(() => ([
    {
      status: flashcardStatus,
      icon: FlashcardIcon,
      name: 'Flashcards',
      mediaType: 'flashcard',
      hasNumbering: flashcardNumbering,
      statusBarData: flashcardStatusGraph,
      title: flashcardStatus.total <= 1 ? 'Flashcard' : 'Flashcards',
    },
    {
      status: revisionStatus,
      icon: RevisionIcon,
      name: 'Guides',
      mediaType: 'revision',
      hasNumbering: revisionNumbering,
      statusBarData: revisionStatusGraph,
      title: revisionStatus.total <= 1 ? 'Page' : 'Pages',
    },
    {
      status: videoStatus,
      icon: VideoIcon,
      name: 'video',
      mediaType: 'video',
      hasNumbering: videoNumbering,
      statusBarData: videoStatusGraph,
      title: videoStatus.total <= 1 ? 'Video' : 'Videos',
    }
  ]),[flashcardStatus, revisionStatus, videoStatus, flashcardStatusGraph, revisionStatusGraph, videoStatusGraph])

  const mediaCard = useMemo(() => {
    const card: MediaCard = [{
      number: flashcardStatus,
      hasNumbering: flashcardNumbering,
      hasSelection,
      icon: FlashcardIcon,
      title: flashcardStatus.total <= 1 ? 'Flashcard' : 'Flashcards',
      mediaType: "flashcard",
      statusBarData: flashcardStatusGraph,
      isActive: active
    }, {
      number: revisionStatus,
      hasNumbering: revisionNumbering,
      hasSelection,
      icon: RevisionIcon,
      title: revisionStatus.total <= 1 ? 'Page' : 'Pages',
      mediaType: "revision",
      statusBarData: revisionStatusGraph,
      isActive: active
    },
    {
      number: videoStatus,
      hasNumbering: videoNumbering,
      hasSelection,
      icon: VideoIcon,
      title: videoStatus.total <= 1 ? 'Video' : 'Videos',
      mediaType: "video",
      statusBarData: videoStatusGraph,
      isActive: active
    }]
    return card
  }, [flashcardStatus, revisionStatus, videoStatus, active, hasSelection, revisionStatusGraph])

  const examStatusData = useMemo(() => {
    const examData = [];
    if (mcqStatus.total > 0) {
      examData.push({ number: mcqStatus.total, title: 'Multiple choice', mediaStatusData: mcqStatusGraph, isRead: mcqStatus.total === mcqStatus.correct, mediaType: 'exams', icons: <MultipleChoice />, isMCQ: true, showLine: examsStatus.total > 0 })
    }
    if (examsStatus.total > 0) {
      examData.push({ number: examsStatus.total, title: 'Free response', mediaStatusData: examsStatusGraph, isRead: examsStatus.total === examsStatus.correct, mediaType: 'exams', icons: <FreeResponse />, isMCQ: true, showLine: false })
    }
    return examData;
  }, [examsStatus])

  return <>
    {hasSelection ? <>
      {flashcardStatus.total > 0 && <StudyMetaInfo hasSelection={hasSelection} icon={FlashcardIcon} name={'Flashcards'} mediaType={"flashcard"} number={flashcardStatus.total || 0} isRead={flashcardStatus.total === flashcardStatus.correct} statusBarData={flashcardStatusGraph} isActive={active} isSubtopic={isSubtopic} />}
    </> : carouselCount('count') > -1 ?
      <div className={classNames('pt-6 px-3.5', SliderContainer, { 'pb-1 mb-[21px]': (examsStatus.total && mcqStatus.total && (hasOSCUser || hasMBFromSchool)) })}>
        {/* @ts-ignore */}
        <Flickity options={flickityOptions} flickityRef={e => setFlkty(e)} className='inactive-topic-tile'>
          {mediaCard.map((item: any, index: number) => {
            return item?.number?.total > 0 ? <div className='px-[7px] w-full' key={id+item.mediaType}>
              <MediaTypeStatusBar resultRange={item.hasNumbering} icon={item.icon} title={item.title} mediaType={item.mediaType} number={item.number.total || 0} isRead={item.number.total === item.number.correct} mediaStatusData={item.statusBarData} isActive={active} isSubtopic={isSubtopic} page='topic' isSlider={true} />
            </div> : <></>
          })}
          {!hasSelection && (examsStatus.total > 0 || mcqStatus.total > 0) && (hasOSCUser || hasMBFromSchool) && <div className='px-[7px] w-full'>
            <ExamMediaTypeStatusBar examStatusData={examStatusData} type={'quickStart'} icon={ExamIcon} isActive={active} isSubtopic={isSubtopic} />
          </div>}
        </Flickity>
      </div> :
      <div className='pt-5'>
        {mediaStatus.map((media, index:number) => {
          if (media.status.total <= 0) return null;
          return <StudyMetaInfo
              key={index}
              hasNumbering={media.hasNumbering}
              hasSelection={hasSelection}
              icon={media.icon}
              name={media.title}
              mediaType={media.mediaType}
              number={media.status.total || 0}
              isRead={media.status.total === media.status.correct}
              statusBarData={media.statusBarData}
              isActive={active}
              isSubtopic={isSubtopic}
              onClick={() => onCardClick(media.mediaType)}
            />
        })}

        {!hasSelection && (examsStatus.total > 0 || mcqStatus.total > 0) && (hasOSCUser || hasMBFromSchool) && <div className='px-[7px] w-full'>
          <ExamMediaTypeStatusBar examStatusData={examStatusData} type={'quickStart'} icon={ExamIcon} isActive={active} isSubtopic={isSubtopic} />
        </div>}
      </div>
    }
  </>
}
export default StudyMetaInformation