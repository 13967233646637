import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form/immutable';
import { ClearIcon, validator } from 'utils/form-helper';
import * as Selectors from 'selectors';
class EditFolderNameForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasClear: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onClearClick = this.onClearClick.bind(this);
    this.nameInput = null;
  }

  componentDidMount() {
    this.onChange(null, this.initialFolderName());
    setTimeout(()=>{
      if (!this.props.isNavigationSectionVisible) {
        if (this.nameInput) {
          document.querySelector(".folderInputBox").focus();
          //ReactDOM.findDOMNode(this.nameInput).focus();
        }
      }
    },1000)
  }

  initialFolderName() {
    return this.props.initialValues && this.props.initialValues.get('title');
  }

  onChange(event, value = '') {
    const state = {
      hasClear: value.length > 0
    }
    let flag = false;
    if(this.props.initialValues){
      if (this.props.initialValues.get('title') !== value) {
        flag = true;
      } else {
        flag = false;
        state.isFolderNameChanged = false;
      }
    }else{
      flag = true;
    }
    this.props.setFolderNameFlag(flag)
    this.setState(state);
  }

  onClearClick() {
    this.onChange(null, '');
    return this.props.change('title', '');
  }

  render() {
    // if (!this.props.isNavigationSectionVisible) {
    //   if (this.nameInput) {
    //     ReactDOM.findDOMNode(this.nameInput).focus();
    //   }
    // }
    return (
      <form onSubmit={this.props.handleSubmit} className='flex vertical-align input-container'>
        <Field
          onChange={this.onChange}
          name='title'
          ref={input => {
            this.nameInput = input;
          }}
          autoFocus='true'
          placeholder='Enter a folder name'
          className='full-width folderInputBox'
          type='text'
          validate={[validator.required]}
          component='input'
        />
        {this.state.hasClear && (
          <div onClick={this.onClearClick} className='flex-pull-right clear-input pointer'>
            <ClearIcon type='transparents' />
          </div>
        )}
      </form>
    );
  }
}

EditFolderNameForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    isNavigationSectionVisible: Selectors.isNavigationSectionVisible(state),
  }

}
export const FORM_NAME = 'editFolderName';
export default connect(mapStateToProps, null)(reduxForm({
  form: FORM_NAME
})(EditFolderNameForm));
