import * as Actions from 'actions';
import {changeMenuPathAction} from 'actions/menuActions';
import {showAlert} from 'actions/notification/notificationActions';
import PurchasedNotification from 'components/notifications/PurchasedNotification';
import React from 'react';
import swal from 'sweetalert';
import {call, take, actionChannel, all, takeLatest, put} from 'redux-saga/effects';
import {SHOW_ALERT, SHOW_PURCHASED_NOTIFICATION} from 'actions/notification/notificationTypes';
import ReactDOM from 'react-dom';

function* watchRequests() {
  // 1- Create a channel for request actions
  const alertsChannel = yield actionChannel(SHOW_ALERT);
  while (true) {
    // 2- take from the channel
    const {content, callback, beforeExecute} = yield take(alertsChannel);
    // 3- Note that we're using a blocking call
    yield call(handleRequest, content, callback, beforeExecute);
  }
}

function* handleRequest(modalContent, callback, beforeExecute) {
  try {
    if (beforeExecute) {
      beforeExecute();
    }

    if (!(modalContent.content instanceof Element)) {
      const contentToRender = document.createElement('div');
      ReactDOM.render(modalContent.content, contentToRender);
      modalContent.content = contentToRender;
    }

    const response = yield swal(modalContent);
    yield 'response';
    if (callback) {
      yield callback(response);
    }
  } catch (error) {
    console.error("error for handleRequest", error)
  }
}

export function* watchers() {
  yield all([
    watchRequests(),
    takeLatest(SHOW_PURCHASED_NOTIFICATION, onShowPurchasedNotification)
  ]);
}

function* onShowPurchasedNotification() {
  try {
    yield put(showAlert({
      content: <PurchasedNotification/>, timer: false, buttons: false
    }, function* (response) {
      if (response && response.shouldOpenMenu) {
        yield put(Actions.Navigation.openMenu());
        yield put(changeMenuPathAction('profile'));
      }
    }));
  } catch (error) {
    console.error("error for onShowPurchasedNotification", error)
  }
}