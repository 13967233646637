import { useMemo } from 'react';
import ExamsTreeContainer from "./ExamsTreeContainer";
import { examsResponse, isApiRequestInProgress } from 'selectors/api/apiSelector';
import { getActiveExamsTab } from 'selectors/navigation/navigationSelector';
import { useSelector } from 'react-redux';
import PaperESM from 'v2/components/empty-screen-message/PaperESM';
import { isMBFromSchool, isOSCUser } from 'selectors';
import { examsForB2BUsers } from 'v2/helpers'

const ExamsListContainer = () => {
    const hasExamsInProgress = useSelector(state => isApiRequestInProgress(state, 'exams'));
    const activeExamsTab = useSelector(getActiveExamsTab);
    const hasOSCUser = useSelector(isOSCUser)
    const examsData = useSelector(examsResponse);
    const hasMBFromSchool = useSelector(isMBFromSchool)
    const exams = useMemo(()=>{
        if(hasOSCUser || hasMBFromSchool === 'YES'){
            return examsData?.data
        }
        return examsData?.data?.filter((item:any) => !examsForB2BUsers.includes(item.examId))
    },[examsData, hasOSCUser, hasMBFromSchool])

    return !hasExamsInProgress && exams?.length === 0 ? <PaperESM type={activeExamsTab} from={'exam'} color={'#FF8000'} /> 
    :   <div className='px-3.5'>
            {!hasExamsInProgress && <ExamsTreeContainer data={exams} />}
        </div>
}

export default ExamsListContainer
