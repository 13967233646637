import './EditNotePage.scss';
import appHistory from 'appHistory';
import NavigationHeader from 'components/navigation-header';
import withScrollDetection from 'hocs/withScrollDetection';
import {DeleteTrashIcon} from 'icons/FlashcardNavIcons';
import React, {PureComponent} from 'react';
import {Redirect} from 'react-router-dom';
import * as Actions from 'actions';
import {connect} from 'react-redux';
import * as Selectors from 'selectors';
import {currentFlashcardId} from 'selectors/studying';
import NoteEditorContainer from 'components/note-editor/NoteEditorContainer';
import BackButton from 'components/navigation-header/BackButton';
import SimpleButton from 'components/simple-button/SimpleButton';
import FooterDeleteMode from 'components/footer-edit-mode/FooterDeleteMode';
import BackToSearchButton from 'ui-components/BackToSearchButton';

class EditNotePage extends PureComponent {
  componentDidMount() {
    this.props.setDocumentTitle('Notes');
  }
  componentWillUnmount() {
    this.props.toggleDeleteConfirmationMode(false);
  }

  componentDidUpdate(prevProps) {
    console.log("hasMobile", prevProps.isMobile)
    console.log("hasMobile", this.props.isMobile)
    if (!prevProps.isMobile && this.props.isMobile) {
      appHistory.push('/flashcard/desk/notes');
    }
  }

  onDelete() {
    const flashcardId = this.props.currentFlashcardId;
    this.props.deleteNote(flashcardId, true);
    this.props.onDelete && this.props.onDelete();
  }

  renderLeftSection = () => {
    const {deleteConfirmationMode} = this.props;

    return <BackToSearchButton alternate={() => {
      return deleteConfirmationMode ?
        <BackButton onClick={() => this.props.toggleDeleteConfirmationMode(false)} text="Cancel"
          hideArrow={true}/> : <BackButton text="NOTES" linkTo="/flashcard/desk/notes"/>;
    }}/>;
  };

  render() {
    const flashcardId = this.props.currentFlashcardId;
    const deleteConfirmationMode = this.props.deleteConfirmationMode;

    if (!flashcardId) {
      return (
        <Redirect to="/flashcard/desk/notes"/>
      );
    }

    return <React.Fragment>
      <NavigationHeader
        extendedWidth={!deleteConfirmationMode}
        leftSection={this.renderLeftSection()}
        isCollapsed={this.props.isScrolled}
        bottomSection={true}
        extendedHeight
        rightSection={(
          deleteConfirmationMode ?
            <div></div> :
            <div className="edit-note-buttons-container">
              {this.props.isTextareaFocused ?
                <div className="done-button focused"><SimpleButton text="Done"
                onClick={() => this.props.toggleFocusTextarea(false)}/></div> : null}
              {this.props.textareaNote !== '' ?
                <div className="delete-icon-note" onClick={() => this.props.toggleDeleteConfirmationMode(true)}>
                  <DeleteTrashIcon color='#378EF8'/></div> : null}
            </div>
        )}/>

      <div className={`note-editor-container full-height`}>
        <NoteEditorContainer innerRef={this.props.bindScrollRef} toggleFocusTextarea={this.props.toggleFocusTextarea} flashcardId={parseInt(flashcardId)}/>
        {deleteConfirmationMode ?
          <FooterDeleteMode contained={this.props.isMobile} flashcardId={parseInt(flashcardId)} {...this.props}
            onClick={() => this.onDelete()}/> : null}
      </div>
    </React.Fragment>;
  }
}

const mapStateToProps = (state) => ({
  isDeskEditModeActive: Selectors.isDeskEditModeActive(state),
  listOfCheckedItems: Selectors.listOfCheckedItems(state),
  flashcardsWithNotes: Selectors.flashcardsWithNotes(state),
  deleteConfirmationMode: Selectors.deleteConfirmationMode(state),
  currentFlashcardId: currentFlashcardId(state),
  isMobile: Selectors.isMobile(state),
  isTextareaFocused: Selectors.isTextareaFocused(state),
  textareaNote: Selectors.textareaNote(state)
});
const mapDispatchToProps = {
  selectAllFlashcardsWithNotes: Actions.Desk.selectAllFlashcardsWithNotes,
  deselectAllItems: Actions.Desk.deselectAllItems,
  deleteNote: Actions.Desk.deleteNote,
  toggleDeleteConfirmationMode: Actions.Desk.toggleDeleteConfirmationMode,
  toggleFocusTextarea: Actions.Desk.toggleFocusTextarea,
  getFlashcardsPreviewWithNotes: Actions.Desk.getFlashcardsPreviewWithNotes,
  setDocumentTitle: Actions.Navigation.setDocumentTitle,
};
export default connect(mapStateToProps, mapDispatchToProps)(withScrollDetection(EditNotePage));
