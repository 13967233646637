import classNames from "classnames";
import CheckRadioButton from "v2/components/check-radio-button/CheckRadioButton";
import Text from "v2/components/common/Text";
import { NoteListIcon } from "icons/FlashcardIcons";
import MarkText from "v2/ui-components/MarkText";
import TeachingLevelSeperator from "v2/components/teaching-level-selector/TeachingLevelSeperator";

type TextProps = {
  flashcardId: number;
  isEditing?: boolean;
  revisionNumber: number;
  active: boolean;
  onClick: () => void;
  title: string;
  shortDescription: string;
  deleted: boolean;
  shouldMark: boolean;
  listOfCheckedItems: any;
  rightSection: React.ReactNode;
  type?: string;
  nodeTeachingTag: "HL" | "" | null;
  teachingLevel: "SL" | "HL";
  teachingTag: string;
};

const RevisionPreviewListItem: React.FC<TextProps> = ({
  shortDescription,
  deleted,
  type,
  active,
  isEditing,
  flashcardId,
  listOfCheckedItems,
  revisionNumber,
  title,
  rightSection,
  onClick,
  nodeTeachingTag,
  teachingLevel,
  teachingTag,
}) => {
  let preview: any = shortDescription?.trim();
  const parser = new DOMParser();
  preview = parser.parseFromString(
    `<!doctype html><body>${preview}`,
    "text/html"
  ).body.textContent;

  return (
      <div
        onClick={onClick}
        className={classNames("px-5 flex items-start cursor-pointer list-hover relative py-9px revision-preview-list ",
        {
          "bg-#E5ECF2 active-list-hover": active,
          "delete-animation": deleted,
          "h-[85px]": type === "notes",
          "h-[104px]": (!!nodeTeachingTag && type === "notes"),
          "items-center": type !== "notes",
          "h-11":!nodeTeachingTag,
        })}
      >
        {isEditing && (
          <CheckRadioButton
            itemId={flashcardId}
            listOfCheckedItems={listOfCheckedItems}
          />
        )}
        <div className="text-center">
          <Text className="text-15px leading-19px text-#2D3838 font-bold ">
            {revisionNumber}
          </Text>
        </div>
        <div className="break-words relative text-15px leading-19px text-#727373 pl-3 w-[278px] ">
          <Text className="text-15px leading-19px text-#2D3838 list-title">
            {title}
          </Text>
          {type === "notes" ? (
            <div className="flex mt-2 items-center">
              <div className="flex mr-2 items-center">
                <NoteListIcon />
              </div>
              <MarkText className="note-short-desc" shouldMark={false}>
                {preview}
              </MarkText>
            </div>
          ) : null}
          {nodeTeachingTag && (
            <div className="mt-2 pb-[9px]">
              <TeachingLevelSeperator
                tlvl={teachingLevel}
                tlvlTag={nodeTeachingTag}
                size="small"
                slTag={teachingTag === "SL"}
              />
            </div>
          )}
        </div>
        <div
          className={classNames("ml-auto pl-[13px] flex", {
            "items-center pl-0": type === "notes",
          })}
        >
          {rightSection}
        </div>
      </div>
  );
};

export default RevisionPreviewListItem;
