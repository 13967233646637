import {
  SET_FLASHCARD_IDS,
  SET_REVISION_IDS,
  SET_VIDEO_IDS,
  CALCULATE_FLASHCARD_INDEX,
  CALCULATE_RENDERER_IDS,
  SELECT_NEXT_FLASHCARD,
  SELECT_PREV_FLASHCARD,
  SET_PREVIOUS_FLASHCARD_IDS,
  SET_FLASHCARD_IDS_DETAILS,
  SET_VIDEO_IDS_DETAILS,
  SET_REVISION_IDS_DETAILS
} from 'actions/carousel/carouselActionTypes';
import makeAction from 'actions/makeAction';

export const setFlashcardIds = makeAction(SET_FLASHCARD_IDS, 'data');
export const setRevisionIds = makeAction(SET_REVISION_IDS, 'data');
export const setVideoIds = makeAction(SET_VIDEO_IDS, 'data');
export const setFlashcardIdDetails = makeAction(SET_FLASHCARD_IDS_DETAILS, 'data');
export const setVideoIdDetails = makeAction(SET_VIDEO_IDS_DETAILS, 'data');
export const setRevisionIdDetails = makeAction(SET_REVISION_IDS_DETAILS, 'data');
export const calculateFlashcardIndex = makeAction(CALCULATE_FLASHCARD_INDEX, 'flashcardId');
export const calculateRendererIds = makeAction(CALCULATE_RENDERER_IDS);
export const selectNextFlashcard = makeAction(SELECT_NEXT_FLASHCARD);
export const selectPrevFlashcard = makeAction(SELECT_PREV_FLASHCARD);
export const setPreviousFlashcardIds = makeAction(SET_PREVIOUS_FLASHCARD_IDS, 'data');
