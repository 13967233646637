import classNames from 'classnames';
import React from 'react'
import './filter.scss'


type FilterButtonProps = {
    onClose: () => void;
    onClick: () => void;
    disabled?: boolean;
}  

const FilterButton: React.FC<FilterButtonProps> = ({disabled, onClose, onClick}) => {
    return <div className='flex justify-between items-center pt-0 px-5 pb-5'>
        <div className='filter-cancel-btn'  onClick={() => onClose()}>
            <div className='cancel-btn-text'>
                CANCEL
            </div>
        </div>
        <div className={classNames('filter-apply-btn', {'disabled-btn': disabled}, {'enable-btn': !disabled})} onClick={() => onClick()}>
            <div className='apply-btn-text'>
                APPLY
            </div>
        </div>
    </div>
}

export default FilterButton;