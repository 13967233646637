import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveMediaTab, getActiveSubject, getUserId, listOfFilterCheckedItems } from 'selectors';
import { getLastMediaTypeVisited, getFormExams, filterResultsListToArrayByTOJS } from 'helpers'
import { getExerciseByTopicID } from 'selectors';
import { getTreeSectionValue, isTreeSectionExpanded } from 'selectors/topicsTreeSelector';
import TopicTile from 'v2/components/topics-tree/TopicTile';
import {toggleTopicVisibility, setActiveTopicId, setActiveSubTopicId } from 'actions/topics-tree/topicsTreeActions';
import { setActiveMediaTab } from 'actions/navigation'
import Checkbox from 'v2/ui-components/Checkbox';
import StudyMetaInformation from 'v2/components/studying/studay-meta-info/StudyMetaInformation';
import { useHistory } from "react-router-dom";

type TopicTileContainerProps = {
  topic: any,
  parentIds: Array<[0, 0]>,
  activeTopicId: number | undefined | null,
  activeSubtopicId: number | undefined | null,
  hasSelection: boolean | undefined,
  isExercise?: boolean,
  isExernalExercise?: boolean,
  children: React.ReactNode,
  onClick: (topicId: number, parentIds: any) => void,
  onPreviewClick?: (arg: any) => void | undefined,
  showAnswers?: boolean | undefined
}

const TopicTileContainer: React.FC<TopicTileContainerProps> = ({ topic, parentIds, activeTopicId, activeSubtopicId, hasSelection, isExercise, isExernalExercise, children, showAnswers, onPreviewClick, onClick }) => {
  const activeMediaTab: string = useSelector(getActiveMediaTab);
  const topicId = topic?.id;
  const dispatch = useDispatch();
  let history = useHistory();
  const [isActiveMediaType, setActiveMediaType] = useState<string>(activeMediaTab);
  const userId = useSelector(getUserId);
  const isActive = useSelector((state) => getTreeSectionValue(state, topicId, parentIds, 'active'));
  const isExpanded = useSelector((state) => isTreeSectionExpanded(state, topicId, parentIds));
  const hasListOfFilterCheckedItems = useSelector(listOfFilterCheckedItems);
  const subtopicToOpen = useSelector((state) => getExerciseByTopicID(state, topicId))
  const subject = useSelector(getActiveSubject)
  const mediaType = getLastMediaTypeVisited(userId);
  const examsMode = mediaType === 'exams' && getFormExams() === 'exams';

  const onClickAction = (item: any, clickAction: string, activeMedia: string) => {
    setTimeout(() => {
      if (clickAction == "SELECT" && isExercise) {
        if (subtopicToOpen) {
          let filteFlashcardData: Array<[]> = [];
          let flashcardIds = subtopicToOpen.forEach((value: any) => {
            return value?.flashcardsAnswers.forEach((val: any) => {
              filteFlashcardData.push(val.toJS())
            })
          })
          let filteFlashcardIds = filterResultsListToArrayByTOJS(filteFlashcardData, "flashcard", hasListOfFilterCheckedItems.toJS()["flashcard"]).map((item: any) => {
            return item.flashcardId
          });
          onPreviewClick && onPreviewClick(filteFlashcardIds[0]);
        }
      } else {
        const topicId = item?.id;
        if (clickAction === 'SELECT') {
          dispatch(setActiveTopicId(topicId));
          dispatch(setActiveSubTopicId(null));
          dispatch(setActiveMediaTab(activeMedia));
          history.push(`/flashcard/topic/${topicId}`);
        } else {
          dispatch(toggleTopicVisibility(topicId, parentIds))
        }
        onClick(topicId, parentIds)
      }

    }, 100);
  }

  const setActiveMediaSlider = (val: string) => {
    if (val) {
      setActiveMediaType(val)
    }
  }

  const mircoFilter = useMemo(() => {
    if (isExercise) {
      const filterList = hasListOfFilterCheckedItems.toJS()["flashcard"]
      let filteFlashcardData: Array<[]> = [];
      let flashcardIds = subtopicToOpen.forEach((item: any) => {
        return item?.flashcardsAnswers?.forEach((val: any) => {
          filteFlashcardData.push(val.toJS())
        })
      })

      let filteFlashcardIds = filterResultsListToArrayByTOJS(filteFlashcardData, "flashcard", filterList).map((item: any) => {
        return item.flashcardId
      })
      return filteFlashcardIds.length == 0 ? true : false
    } else {
      return false
    }
  }, [isExercise, hasListOfFilterCheckedItems])
 
  return <div className='flex'>
    <TopicTile 
      topicData={topic}
      active={!examsMode && isActive}
      activeSubtopicId={activeSubtopicId}
      activeTopicId={activeTopicId}
      expanded={isExpanded}
      hasSelection={hasSelection}
      parentIds={parentIds}
      isExercise={isExercise}
      isExernalExercise={isExernalExercise}
      onExpand={() => onClickAction(topic, 'EXPAND', isActiveMediaType)}
      onSelect={() => onClickAction(topic, 'SELECT', isActiveMediaType)}
      checkMircoFilterFlashcard={mircoFilter}
      absolute={hasSelection && (<div key={topicId} className='absolute top-0 bottom-0 z-[1px] flex items-center left-[-34px]'> <Checkbox id={topicId} parentIds={parentIds} /> </div>)}
      subject={subject}
      answers={showAnswers && <StudyMetaInformation topicData={topic} activeMediaType={isActiveMediaType} id={topicId} hasSelection={hasSelection} onClick={setActiveMediaSlider} onCardClick={(val:string) => onClickAction(topic, 'SELECT', val)} isMcq={true} key={topicId} />} >
      {children}
    </TopicTile>
  </div>
}

export default TopicTileContainer
