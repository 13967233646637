import React, { useMemo } from 'react';
import classNames from 'classnames';
import './InitialFlashcard.scss'
import UserAvatarContainer from 'v2/components/avatar/UserAvatarContainer';
import { useSelector } from "react-redux";
import { userDataSelector } from 'selectors/user';
import * as FlashcardIcons from 'icons/FlashcardIcons';
import { isMobile, isLandscape } from 'selectors'


type InitialFlashcardSelectorProps = {
  translate?: boolean
}
const InitialFlashcardSelector: React.FC<InitialFlashcardSelectorProps> = ({ translate }) => {
  
  const userData = useSelector(userDataSelector).toJS();
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);

  const firstName = useMemo(() => {
    return userData.firstName;
  }, [userData])

  return (<div className={classNames('bg-white flex items-center justify-center flex-col cursor-default overflow-hidden pt-10px  initial-flashcard-selector', { 'translate-empty-cc': translate })}>
    <div className='shadow-flashcard'>
      <UserAvatarContainer alternate size={96} initialFlashcard={true} />
    </div>
    <div className='text-blue-normal text-33px leading-45px font-bold shadow-flashcard-title'>
      Hi {firstName}!
    </div>
    <FlashcardIcons.InitalEmptyScreenIcon />
    <div className='text-#919191 mt-4 text-center text-13px leading-16px'>This is where you’ll see the media you select in the left menu.</div>
    {(hasLandscape && hasMobile) && <div className='text-#919191 text-center text-13px leading-16px mt-18px font-bold '>Rotate your device to view the menu.</div>}
  </div>)

}
export default InitialFlashcardSelector