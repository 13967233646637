import Text from "v2/components/common/Text";
import CheckRadioButton from "v2/components/check-radio-button/CheckRadioButton";
import classNames from "classnames";
import { NoteListIcon } from "icons/FlashcardIcons";
import MarkText from "v2/ui-components/MarkText";
import TeachingLevelSeperator from "v2/components/teaching-level-selector/TeachingLevelSeperator";

type PreviewListItemProps = {
  listOfCheckedItems?: any;
  onClick: () => void;
  isEditing?: boolean;
  flashcardId: number;
  flashcardNumber: number;
  previewTitle: string;
  active: boolean;
  deleted: boolean;
  shouldMark: boolean;
  rightSection: React.ReactNode;
  type?: string;
  preview: string;
  activeMath: boolean;
  data?: any;
};

const PreviewListItem: React.FC<PreviewListItemProps> = ({
  preview,
  type,
  previewTitle,
  onClick,
  deleted,
  active,
  activeMath,
  isEditing,
  flashcardId,
  listOfCheckedItems,
  flashcardNumber,
  shouldMark,
  rightSection,
  data,
}) => {
  let title: string | null = preview;
  if (preview === " " || preview === "") {
    if (
      previewTitle !== "New flashcard" &&
      type !== "notes" &&
      previewTitle !== ""
    ) {
      title = previewTitle;
    } else {
      title = "The preview functionality is coming soon";
    }
  } else {
    if (previewTitle !== "New flashcard" && type !== "notes") {
      title = previewTitle;
    } else {
      title = preview;
    }
  }
  const parser = new DOMParser();
  title = parser.parseFromString(`<!doctype html><body>${title}`, "text/html")
    .body.textContent;

  return (
    <div className={classNames(
      "flex flex-col list-hover cursor-pointer revision-preview-list",
      {
        "bg-#E5ECF2 active-list-hover": active,
      })}>
      <div
        onClick={onClick}
        className={classNames(
          "relative px-5 py-9px flex items-center",
          {
            "delete-animation": deleted,
            "h-11 py-0": activeMath,
            "h-[59px]": (!activeMath && !data?.nodeTeachingTag),
            "h-[84px]": (!activeMath && data?.nodeTeachingTag),
          }
        )}
      >
        {isEditing && (
          <CheckRadioButton
            itemId={flashcardId}
            listOfCheckedItems={listOfCheckedItems}
          />
        )}
        <div
          className={classNames("flex flex-col items-start justify-start mr-3.5")}
        >
          <Text className="text-15px font-bold leading-19px text-#2D3838">
            {flashcardNumber}
          </Text>
        </div>
        {type === "notes" && (
          <div className="flex items-center mr-2">
            <NoteListIcon />
          </div>
        )}
        <div
          className={classNames(
            "text-15px leading-19px text-#727373 break-words ",
            { "max-w-225px": type === "notes" && !isEditing },
            { "max-w-[191px]": type === "notes" && isEditing },
            { "max-w-[262px]": type !== "notes" && !isEditing },
            { "max-w-[228px]": type !== "notes" && isEditing },
          )}
        >
          <MarkText
            shouldMark={shouldMark}
            className={
              activeMath ? "math-shot-desc note-short-desc" : "note-short-desc"
            }
          >
            {title}
          </MarkText>
          {data?.nodeTeachingTag && (
            <div className="pt-1.5">
              <TeachingLevelSeperator
                tlvl={data?.teachingLevel}
                tlvlTag={data?.nodeTeachingTag}
                size="small"
                slTag={data?.teachingTag === "SL" ? true : false}
              />
            </div>
          )}
        </div>
        <div
          className={classNames("ml-auto pl-[13px] flex", {
            "items-center pl-0": type === "notes",
          })}
        >
          {rightSection}
        </div>
      </div>
      
    </div>
  );
};

export default PreviewListItem;