import React from 'react'
import LoaderSvg from './LoaderSvg';
import './Loader.scss'

export enum BackgroundEnum{
    Blue = 'blue',
    White = 'white',
    BlueX = 'blue-x',
    Grey = 'grey',
    Gradient = 'gradient'
}
export enum SizeEnum{
    Small = 'small',
    VSmall = 'vsmall',
    MeduiumSmall = 'mediumsmall',
    Large = 'large',
}
type LoaderProps = {
    background?: BackgroundEnum; 
    size?: SizeEnum;
}  

const Loader: React.FC<LoaderProps> = ({background, size}) => {
    return (
        <div className={`loader-wrapper ${size}` }>
            <LoaderSvg color={background} />
        </div>
    )
}
  
export default Loader;