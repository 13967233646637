import * as Actions from 'actions';
import ElementPositionDetector from 'components/ElementPositionDetector';
import NavigationHeader from 'components/navigation-header/NavigationHeader';
import {ReactComponent as CloseIcon} from 'assets/icons/page-overlay/close-icon-turquoise.svg';
import TeachingLevelSelectorContainer from 'components/teaching-level-selector/TeachingLevelSelectorContainer';
import withScrollDetection from 'hocs/withScrollDetection';
import {InfoIcon} from 'icons/CardIcons';
import LinksContainer from 'pages/link/LinksContainer';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import styled, {css} from 'styled-components/macro';
import {FixedPageSpacing, PageWrapper, PagePadding} from 'ui-components/pageComponents';
import Row from 'ui-components/helpers/Row';
import media from 'ui-components/helpers/media';

import * as Selectors from 'selectors';
class LinksPage extends PureComponent {
  constructor(props) {
    super(props);

    this.wrapperRef = null;

    this.state = {
      isFixed: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
 
 
  onClose = () => {
    this.props.toggleLinksNavigationSection(false, false)
  }
  render() {
    const {isScrolled, isScrolled2, isMobile, bindScrollRef, isLandscape} = this.props;

    return (
      <PageWrapper>
       <StyledNavigationHeader isCollapsed={!(isMobile && isLandscape) ? isScrolled : true} text={'Links'}
          rightSection={
            <StyledCloseButton onClick={this.onClose}>
              <CloseIcon/>
            </StyledCloseButton>
           }
          extendedHeight
          hideborder={true}
          leftSection={true}
          isLinkPage={true}
          shouldExtendHeight={isScrolled2}
          bottomSection={!(isMobile && isLandscape) ? <StyledTopicsTeachingLevelContainer/> : true}
        /> 
        <StyledFixedPageSpacing ref={bindScrollRef}>
          {!(isMobile && isLandscape) && <React.Fragment> 
            <PagePadding>
              <Row justifyContent={'space-between'}>
                <PageTitle>Links</PageTitle>
              </Row>
            </PagePadding>
          </React.Fragment> }
          <LinksContainer parentScrollRef={() => this.wrapperRef}/>
        </StyledFixedPageSpacing>
      </PageWrapper>
    );
  }

  bindRef = (ref) => {
    this.wrapperRef = ref;
    this.props.bindScrollRef(ref);
  };
}

const mapStateToProps = (state) => ({
  isMobile: Selectors.isMobile(state),
  isLandscape: Selectors.isLandscape(state),
  isPortrait: Selectors.isPortrait(state),
});
const mapDispatchToProps = {
  toggleLinksNavigationSection: Actions.Navigation.toggleLinksNavigationSection,
};
export default connect(mapStateToProps, mapDispatchToProps)(withScrollDetection(LinksPage));


const StyledTopicsTeachingLevelContainer = styled(TeachingLevelSelectorContainer)`
  margin: 0 -20px -15px -20px;
  position: relative;
  background: white;
  padding: 16px 0 0 20px;
`;
const PageTitle = styled.h1`
  font-weight: bold;
  font-size: 33px;
  color: #2D3838;
  letter-spacing: 0;
  line-height: 45px;
`;

const StyledNavigationHeader = styled(NavigationHeader)`
  left: unset;
  @media (min-width: 600px) and (max-width: 1111px) and (orientation: portrait) {
    left: unset !important;
  }
  ${media.phone(css`
    left: 0;
  `)}
`;
const StyledCloseButton = styled.div`
  right: 20px;
  height: 20px;
  width: 20px;
  background: #2EBCB4;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
 
  @media (pointer: fine) {
    &:hover {
      background: #44CAC3;
    }
  } 
  &:active {
    background: #3DA898;
  }
`;

const StyledFixedPageSpacing = styled(FixedPageSpacing)`
  display: flex;
  flex-direction: column;
`;