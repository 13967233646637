export const windowWidth = (state) => state.getIn(['browser', 'windowWidth']);
export const windowHeight = (state) => state.getIn(['browser', 'windowHeight']);
export const isMobile = (state) => state.getIn(['browser', 'isMobile']);
export const isIPad = (state) => state.getIn(['browser', 'iPad']);
export const isIPadPro = (state) => state.getIn(['browser', 'iPadPro']);
export const isPortrait = (state) => state.getIn(['browser', 'orientation']) === 'portrait';
export const isLandscape = (state) => state.getIn(['browser', 'orientation']) === 'landscape';
export const mediaSize = (state, size) => windowWidth(state) <= size;
export const isMobilePortrait = (state) => state.getIn(['browser', 'isMobilePortrait']);

export const mediaSizes = (state) => state.getIn(['browser', 'mediaSizes']);
