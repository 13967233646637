import * as Actions from 'actions';
import {setNodeAnswers, setNodeMark,  setNodeWatched} from 'actions/answers/answersActions';
import {setVideoProgress} from 'actions/videoProgress/videoProgressActions';
import {
  addFlashcardToFolderRoutine,
  getFlashcardAnswersRoutine,
  removeFlashcardFromFolderRoutine,
  addFlashcardToBookmarkRoutine,
  removeFlashcardFromBookmarkRoutine
} from 'actions/api/apiActions';
import {
  CREATE_FOLDER,
  DELETE_FOLDERS,
  GET_FLASHCARDS_IN_FOLDER,
  GET_FOLDER,
  GET_FOLDERS,
  RESET_FOLDERS,
  GET_FOLDERS_FOR_FLASHCARD,
  GET_BOOKMARKS_FOR_FLASHCARD,
  MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER,
  UPDATE_FOLDER,
  UPDATE_FOLDERS_SEQUENCE,
  GET_FLASHCARDS_IN_FOLDER_SUCCEEDED,

} from 'actions/desk/types';
import * as Api from 'api';
import appHistory from 'appHistory';
import {delay} from 'redux-saga/effects';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { setOccurencesToPreview } from 'sagas/api/apiSaga';
import * as Selectors from 'selectors';
import topicReducer from 'reducers/topic/topicReducer';
import { getFormExams,getExamSubTopicId,getJWTToken ,chnageTeachingTag} from 'helpers';
import { changeSequenceOfFolders, getExamTopicId } from 'v2/helpers';

const NEW_SUBJECT = 'NEW_SUBJECT'

function* takeFirst(pattern, saga) {
  while (true) {
    const action = yield take(pattern);
    yield call(saga, action);
  }
}

export function* watchers() {
  yield all([
    takeLatest(UPDATE_FOLDER, updateFolderName),
    takeLatest(CREATE_FOLDER, createFolder),
    takeLatest(GET_FOLDERS, getFolders),
    takeLatest(RESET_FOLDERS, resetFolders),
    takeLatest(addFlashcardToFolderRoutine.trigger, addFlashcardToFolder),
    takeLatest(removeFlashcardFromFolderRoutine.trigger, removeFlashcardFromFolder),
    // takeFirst(GET_FLASHCARDS_IN_FOLDER, getFlashcardsInFolder),
    takeLatest(GET_FLASHCARDS_IN_FOLDER, getFlashcardsInFolder),
    takeLatest(GET_FOLDER, getFolder),
    takeLatest(DELETE_FOLDERS, deleteFolders),
    takeLatest(GET_FOLDERS_FOR_FLASHCARD, getFoldersForFlashcard),
    takeLatest(GET_BOOKMARKS_FOR_FLASHCARD, getBookmarksForFlashcard),
    takeLatest(MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER, multipleDeleteFlashcardsFromFolder),
    takeLatest(UPDATE_FOLDERS_SEQUENCE, updateFolderSequence),
    takeLatest(addFlashcardToBookmarkRoutine.trigger, addFlashcardToBookmark),
    takeLatest(removeFlashcardFromBookmarkRoutine.trigger, removeFlashcardFromBookmark),
  ]);
}

function* updateFolderName(action) {
  try {
    const subjectId = yield select(Selectors.activeSubjectId);
 
    yield put(Actions.Desk.updateFolderStarted());
    yield call(Api.updateFolder, subjectId, action.folderId, action.data);
    yield put(Actions.Desk.updateFolderSucceeded(action.data.title));
    appHistory.push('/flashcard/desk/folders/' + action.folderId);
  } catch (e) {
    yield put(Actions.Desk.updateFolderFailed(e));
  }
}

function* createFolder(action) {
  try {
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel)
    const subject = yield select(Selectors.getActiveSubject)
    const subjectId = yield select(Selectors.activeSubjectId);
    const teachingTag = subjectTeachingLevel[`${subjectId}`]
    const data = {
      ...action?.data,
      ...(subject?.tagging === NEW_SUBJECT && {teaching_lvl:teachingTag})
    }

    yield call(Api.createFolder, subjectId, data);
    yield put(Actions.Desk.createFolderSucceeded());
    yield put(Actions.Desk.getFolders());
    appHistory.push('/flashcard/desk/folders');
  } catch (e) {
    yield put(Actions.Desk.createFolderFailed(e));
  }
}

function* getFolders() {
  try {
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
    let subjectId = yield select(Selectors.activeSubjectId);
    const teachingTag = subjectTeachingLevel[`${subjectId}`]
    const subject = yield select(Selectors.getActiveSubject)
    let response = yield call(Api.getFolders, subjectId, subject?.tagging === NEW_SUBJECT && teachingTag);
    yield put(Actions.Desk.getFoldersSucceeded({ data: response }));
  } catch (e) {
    yield put(Actions.Desk.getFoldersFailed(e));
  }
}

// Reset folder/notes listing on subject change
function* resetFolders() {
    try {
      yield put(Actions.Desk.getFoldersSucceeded({ data: [] }));
      yield put(Actions.Desk.getFlashcardsPreviewWithNotesSucceeded({data: []}));
    } catch (e) {
      yield put(Actions.Desk.getFolderFailed(e));
    }
}
// update Folder sequence
function* updateFolderSequence(action) {
  try {
    const subjectId = yield select(Selectors.activeSubjectId);
    const folders = yield select(Selectors.folders)
    const foldersToJS = folders.getIn(['data','items']).toJS()
    const ids = action.data.sequence_folder.split(',')
    const newSequence = changeSequenceOfFolders(foldersToJS, ids)
    yield call(Api.updateFolderSequence, subjectId, action.data);
    yield put(Actions.Desk.updateFolderSequenceSucceeded());
    yield put(Actions.Desk.getFoldersSucceeded({data:{count:newSequence.length, items:newSequence}}))
  } catch (e) {
    yield put(Actions.Desk.updateFolderSequenceFailed(e));
  }
}

function* addFlashcardToFolder(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  try {
    let subjectId = yield select(Selectors.activeSubjectId);
    let flashcardId = yield select(Selectors.Studying.currentFlashcardId);
    const parentsData = yield select(Selectors.Studying.getFlashcardById, flashcardId);
    let topicNumber;
    let subTopicNumber;
    let mediaType= action.payload.mediaType ? action.payload.mediaType :  yield select(Selectors.getActiveMediaTab);
    if (mediaType === 'exams') {
      if(getFormExams() !== 'exams'){
        const subTopicData = parentsData.get('parentsData').filter((item) =>  {
          return item.get('subTopicId').toString() === getExamSubTopicId() 
        })
        topicNumber = subTopicData.getIn([0, 'parentsData'])?.filter(item => item.get('type') === 'TOPIC').first().get('id')
        subTopicNumber =subTopicData.getIn([0, 'parentsData'])?.filter(item => item.get('type') === 'SUB_TOPIC').first().get('id');
      }else{
        topicNumber =  parentsData.get('parentsData').first().get('parentsData').filter(item => item.get('type') === 'TOPIC').first().get('id')
        subTopicNumber =  parentsData.get('parentsData').first().get('parentsData').filter(item => item.get('type') === 'SUB_TOPIC').first().get('id');
      }
    }
    const endpoint =  mediaType ==='exams' ? Api.addExamsToFolder : Api.addFlashcardToFolder;

    let response = yield call(endpoint, subjectId, key, flashcardId, mediaType,topicNumber,subTopicNumber);

    yield put(Actions.Desk.getFlashcardsInFolder(key, false, 0));
    yield put(Actions.Desk.getFoldersForFlashcard(flashcardId, mediaType));
   // yield take([Actions.Desk.getFoldersForFlashcardSucceeded, Actions.Desk.getFlashcardsInFolderSucceeded]);
    yield take([GET_FLASHCARDS_IN_FOLDER_SUCCEEDED]);
    yield put(addFlashcardToFolderRoutine.success({ key, response }));
  } catch (error) {
    yield put(addFlashcardToFolderRoutine.failure({ key, error }));
    alert('Failed to add flashcard');
  } finally {
    yield put(addFlashcardToFolderRoutine.fulfill({ key }));
  }
}

function* removeFlashcardFromFolder(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  try {
    let subjectId = yield select(Selectors.activeSubjectId);
    let flashcardId = yield select(Selectors.Studying.currentFlashcardId);
    let activeMediaTab= action.mediaType ? action.mediaType : yield select(Selectors.getActiveMediaTab);
    let endpoint =  activeMediaTab === 'exams' ?Api.removeExamsFromFolder : Api.removeFlashcardFromFolder
    let response = yield call (endpoint, subjectId, key, [flashcardId],activeMediaTab)

    yield put(Actions.Desk.getFlashcardsInFolder(key, false, 0));
    yield put(Actions.Desk.getFoldersForFlashcard(flashcardId));

   // yield take([Actions.Desk.getFoldersForFlashcardSucceeded, Actions.Desk.getFlashcardsInFolderSucceeded]);
    yield take([GET_FLASHCARDS_IN_FOLDER_SUCCEEDED]);
    yield put(removeFlashcardFromFolderRoutine.success({ key, response }));
  } catch (error) {
    yield put(removeFlashcardFromFolderRoutine.failure({ key, error }));
    alert('Failed to remove flashcard');
  } finally {
    yield put(removeFlashcardFromFolderRoutine.fulfill({ key }));
  }
}

function* multipleDeleteFlashcardsFromFolder(action) {
  try {
    const folderId = action.folderId;
    let subjectId = yield select(Selectors.activeSubjectId);
    let ids = yield select(Selectors.listOfCheckedItems);
    let flashcardId = yield select(Selectors.Studying.currentFlashcardId);
    let activeMediaTab = yield select(Selectors.getActiveMediaTab);
    if(folderId === 'bookmark'){
      yield put(Actions.Desk.multipleDeleteFlashcardsFromFolderSucceeded());
      const endpoint = activeMediaTab === 'exams' ? Api.removeExamsMultipleBookmarks : Api.removeMultipleBookmarks
      yield call(endpoint, subjectId, ids, activeMediaTab);
      yield put(Actions.Desk.getFlashcardsInFolder(folderId, topicReducer, 0));
      if(flashcardId){
        yield put(Actions.Desk.getBookmarksForFlashcard(flashcardId,action.mediaType));
      }
    }else{
      yield put(Actions.Desk.multipleDeleteFlashcardsFromFolderSucceeded());
      yield call(Api.removeFlashcardFromFolder, subjectId, folderId, ids, activeMediaTab);
      yield put(Actions.Desk.getFlashcardsInFolder(folderId, false, 0));
      if(flashcardId){
        yield put(Actions.Desk.getFoldersForFlashcard(flashcardId,action.mediaType));
      }
    }    
  } catch (e) {
    yield put(Actions.Desk.multipleDeleteFlashcardsFromFolderFailed(e));
  }
}

function* getFlashcardsInFolder(action) {
  try {
    let subjectId = yield select(Selectors.activeSubjectId);
    const subject = yield select(Selectors.getActiveSubject)
    let activeMediaTab= yield select(Selectors.getActiveMediaTab);
    const activeTeachingLevelId = yield select(Selectors.getActiveTeachingLevel);
    const activeSubjectId = yield select(Selectors.activeSubjectId);
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
    const teachingTag = subjectTeachingLevel[`${activeSubjectId}`]
    const APTeachingTag = chnageTeachingTag(teachingTag)
    const getAPJWTToken  = getJWTToken()

    const activeExamModule = yield select(Selectors.getActiveExamModule)
    const subjectExamStatus = [14982 , 15075].includes(activeSubjectId)
    const questionBankCodes =  activeExamModule ? ["ib_dp", "osc_dp", "osc_dp_mocks"] : subjectExamStatus && ["osc_dp", "osc_dp_mocks"]

    const examEndPoint = action.isSmart ? Api.getExamsInBookmark : Api.getExamsInFolder;
    const endpoint =  activeMediaTab ==='exams' ? examEndPoint : action.isSmart ? Api.getFlashcardsInBookmark : Api.getFlashcardsInFolder;
    
    let response = yield call(endpoint, subjectId, action.folderId, activeMediaTab, action.page, activeTeachingLevelId !== subjectId ? activeTeachingLevelId : null, getAPJWTToken, APTeachingTag, questionBankCodes, subject?.tagging === NEW_SUBJECT && teachingTag);
    if(activeMediaTab==="flashcard"){ 
      yield call(setOccurencesToPreview, response);
      const data = response?.map((item) => ({
        id: item.flashcardId,
        correct: item.answers,
        answered: item.answers == null ? false : true
      }));
      yield put(setNodeAnswers(data));
    }else if(activeMediaTab ==='exams'){
      response.map((item,index) => {
        item.category = `${item.topicNumbering} ${item.teachingLevel}`;
        item.examCategory = `${item.experienceData.exam_name} ${item.paper}`;
        item.flashcardId = `${item.questionId}`;
        item.paperType = `${item.paper}`;
        item.qb_code = `${item.experienceData.qb_code}`;
        item.sources = `${item.experienceData.qb_code}`;
        item.question_type = `${item.questionType.includes('mcq') ? 'mcq':'group'}`;
        item.difficultyLevel = item.difficulty;
        item.numbering = index + 1
      });
    } else{
      response.map(item => {
        item.category = `${item.topicNumbering} ${item.teachingLevel}`;
      });
      response.sort((a, b) => {
        if (a.numbering < b.numbering) return -1;
        if (a.numbering > b.numbering) return 1;
        return 0;
      });
      if(activeMediaTab==="revision"){
        yield put(setNodeMark(response.map(item => (
          {
            id: item.nodeId, 
            markValue: item.studentStudyStatus
          }
        ))));
      }
      if(activeMediaTab==="video"){
        yield put(setVideoProgress(response.map(item => (
          {
            id: item.nodeId,
            totalTime: item.totalDurationInSeconds,
            currentTime: item.totalWatchedInSeconds ? item.totalWatchedInSeconds : "0"
          }
        ))));
        yield put(setNodeWatched(response.map(item => (
          {
            id: item.nodeId, 
            watchedValue: item.studentStudyStatus,
            partialValue: (item.totalWatchedInSeconds > 0 && (item.totalWatchedInSeconds !== item.totalDurationInSeconds) && item.value === 0 ) ? 1 : 0
          }
        ))));
      }
    }
    

    yield put(Actions.Desk.getFlashcardsInFolderSucceeded({ data: response }));
  } catch (e) {
    yield put(Actions.Desk.getFlashcardsInFolderFailed(e));
    //alert('Could not get flashcards');
  }
}

function* getFolder(action) {
  try {
    let subjectId = yield select(Selectors.activeSubjectId);
    const response = yield call(Api.getFolder, subjectId, action.folderId);
    yield put(Actions.Desk.getFolderSucceeded({ data: response }));
  } catch (e) {
    yield put(Actions.Desk.getFolderFailed(e));
    alert('Could not get flashcards');
  }
}

function* deleteFolders() {
  try {
    let subjectId = yield select(Selectors.activeSubjectId);
    let ids = yield select(Selectors.listOfCheckedItems);
    let data = { subjectId, ids };
    yield call(Api.deleteFolders, data);
    yield put(Actions.Desk.deleteFoldersSucceeded());
    //yield call(delay, 600);
    yield delay(600);
    yield put(Actions.Desk.getFolders());
  } catch (e) {
    yield put(Actions.Desk.deleteFoldersFailed(e));
  }
}

function* getFoldersForFlashcard(action) {
  try {
    let subjectId = yield select(Selectors.activeSubjectId);
    let activeType = action.mediaType ? action.mediaType : yield select(Selectors.getActiveMediaTab);
    const response = yield call(Api.getFoldersForFlashcard, subjectId, activeType, action.flashcardId);
    yield put(Actions.Desk.getFoldersForFlashcardSucceeded({ data: response }));
  } catch (e) {
    yield put(Actions.Desk.getFoldersForFlashcardFailed(e));
  }
}

function* getBookmarksForFlashcard(action) {
  try {
    let subjectId = yield select(Selectors.activeSubjectId);
    let activeType = action.mediaType ? action.mediaType : yield select(Selectors.getActiveMediaTab);
    // let activeType = yield select(Selectors.getActiveMediaTab);
    const response = yield call(Api.getBookmarkForFlashcard, subjectId, activeType, action.flashcardId);
    yield put(Actions.Desk.getBookmarksForFlashcardSucceeded({ data: response }));
  } catch (e) {
    yield put(Actions.Desk.getBookmarksForFlashcardFailed(e));
  }
}

function* addFlashcardToBookmark(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  try {
    let subjectId = yield select(Selectors.activeSubjectId);
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel)
    const teachingTag = subjectTeachingLevel[`${subjectId}`]
    const subject = yield select(Selectors.getActiveSubject)
    let flashcardId = yield select(Selectors.Studying.currentFlashcardId);
    let parentsData = yield select(Selectors.flashcardContent);
    let topicNumber;
    let subTopicNumber;
    let mediaType= action.payload.mediaType ? action.payload.mediaType :  yield select(Selectors.getActiveMediaTab);

    if (mediaType === 'exams') {
      parentsData.get('parentsData').filter(person => {
        if (person.get('subTopicId') === getExamSubTopicId()) {
          topicNumber = person.get('parentsData').filter(item => item.get('type') === 'TOPIC').first().get('id')
          subTopicNumber = person.get('parentsData').filter(item => item.get('type') === 'SUB_TOPIC').first().get('id');
        } else {
          // topicNumber =  parentsData.get('parentsData').first().get('parentsData').filter(item => item.get('type') === 'TOPIC').first().get('id')
          // subTopicNumber =  parentsData.get('parentsData').first().get('parentsData').filter(item => item.get('type') === 'SUB_TOPIC').first().get('id');
          topicNumber = getExamTopicId()
          subTopicNumber = getExamSubTopicId();
        }
      })
    }

   

    yield put(Actions.Studying.setChangeFlashcardContentBookmarkStatus(
    {
      flashcardId: flashcardId,
      bookmarkStatus: 1,
    })); 

    let endpoint = mediaType === 'exams' ? Api.addExamToBookmark : Api.addFlashcardToBookmark
    let response = yield call(endpoint , subjectId, flashcardId, mediaType,topicNumber,subTopicNumber, subject?.tagging === NEW_SUBJECT && teachingTag);
    //yield put(Actions.Desk.getBookmarksForFlashcard(flashcardId, mediaType));
    if(action.payload.fromPage === 0){
      yield put(Actions.Desk.getFlashcardsInFolder(key, true, 0));
      yield take([GET_FLASHCARDS_IN_FOLDER_SUCCEEDED]);
    } 
    yield put(addFlashcardToBookmarkRoutine.success({ key, response }));
  } catch (error) {
    yield put(addFlashcardToBookmarkRoutine.failure({ key, error }));
  } finally {
    yield put(addFlashcardToBookmarkRoutine.fulfill({ key }));
  }
}

function* removeFlashcardFromBookmark(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  try {
    let subjectId = yield select(Selectors.activeSubjectId);
    let flashcardId = yield select(Selectors.Studying.currentFlashcardId);
    let parentsData = yield select(Selectors.flashcardContent);
    let topicNumber;
    let subTopicNumber;

    // parentsData.get('parentsData').forEach(item => {
    //   if (item.get('type') === 'TOPIC') {
    //     topicNumber = item.get('id');
    //   }
    //   if (item.get('type') === 'SUB_TOPIC') {
    //     subTopicNumber = item.get('id');
    //   }
    // });
    topicNumber = parentsData.get('parentsData').first().get("topicId");
    subTopicNumber = parentsData.get('parentsData').first().get("subTopicId");
    yield put(Actions.Studying.setChangeFlashcardContentBookmarkStatus(
    {
      flashcardId: flashcardId,
      bookmarkStatus: 0,
    })); 
    let mediaType= action.payload.mediaType ? action.payload.mediaType :  yield select(Selectors.getActiveMediaTab);
    let endpoint = mediaType === 'exams' ? Api.removeExamFromBookmark : Api.removeFlashcardFromBookmark
    let response = yield call(endpoint, subjectId, flashcardId, mediaType,topicNumber,subTopicNumber);
    //yield put(Actions.Desk.getBookmarksForFlashcard(flashcardId, mediaType));
    if(action.payload.fromPage === 0){
      yield put(Actions.Desk.getFlashcardsInFolder(key, true, 0));
      yield take([GET_FLASHCARDS_IN_FOLDER_SUCCEEDED]);
    }
    yield put(removeFlashcardFromBookmarkRoutine.success({ key, response }));
  } catch (error) {
    yield put(removeFlashcardFromBookmarkRoutine.failure({ key, error }));
  } finally {
    yield put(removeFlashcardFromBookmarkRoutine.fulfill({ key }));
  }
}

