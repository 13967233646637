import Immutable from 'immutable';

export const activeSearchTab = state => state.getIn(['search', 'currentTab'], 'Cards');
export const activeSearchTerm = state => state.getIn(['search', 'currentTerm'], null);
export const searchFieldValue = state => state.getIn(['form', 'search', 'values', 'searchField'], '');
export const isSearchFieldFocused = state => state.getIn(['search', 'searchActive'], false);
export const flashcardSearchResults = (state) => {
    // get filters
    // 
    //

    const finalData = state.getIn(['search', 'results'], Immutable.List());
    // return finalData.filter((item) => {
    //     return item.flashcardId == 
    // }
    return finalData;
}
export const shouldGoBackToSearch = state => state.getIn(['search', 'goBackToSearch']);
export const activeSearchResult = state => state.getIn(['search', 'results'])?.[searchIndex(state)];
export const searchIndex = state => state.getIn(['search', 'searchIndex']);
export const hasNextResult = state => state.getIn(['search', 'searchIndex']) < previousFlashcardSearchResults(state).size - 1;
export const hasPrevResult = state => state.getIn(['search', 'searchIndex']) > 0;
export const previousFlashcardSearchResults = state => state.getIn(['search', 'previousSearchResults'], Immutable.List());