import {getAllTopicsInSubject, getSubtopicsInTopic, getAllTopicsIds} from 'actions/api';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import RequestLoader from 'v2/components/loader.v2/RequestLoader';
import SubtopicTileContainer from 'components/topics-tree/SubtopicTileContainer';
import TopicTileContainer from 'components/topics-tree/TopicTileContainer';
import TreePreviewContainer from 'components/topics-tree/TreePreviewContainer';
import {List, Map} from 'immutable';
import PropTypes from 'prop-types';
import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import {
  flashcardIdsResponse,
  // previewsInSubtopic,
  topicsForCurrentActiveTeachingLevel,
  allTopicsInProgress,
  activeSubjectId,
  activeSubjectTeachingLevel,
  allTopicsLoading
} from 'selectors';
import {subtopicsSelector, allTopicsInSubjectResponse} from 'selectors/api/apiSelector';
import {scrollToPreviewId, getActiveTopicId, getActivSubTopicId} from 'selectors/topicsTreeSelector';
import { isApiRequestInProgress, apiRequestData } from 'selectors/api/apiSelector';
import styled from 'styled-components/macro';


class TopicsTreeContainer extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = createRef();
  }

  componentDidMount() {
    if (this.props.topicsForCurrentActiveTeachingLevel.size === 0){
    this.loadTopics();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.scrollToPreviewId !== prevProps.scrollToPreviewId) {
      this.scrollToActive();
    }

    if (this.props.subjectId !== prevProps.subjectId) {
      this.loadTopics();
    }
    // if(activeSubjectTeachingLevel != prevProps.activeSubjectTeachingLevel && topicsForCurrentActiveTeachingLevel !== prevProps.topicsForCurrentActiveTeachingLevel ){
    //   const topicsIds =  topicsForCurrentActiveTeachingLevel.map(item => {
    //     return  {  apTopicsId: item.get('apTopicId'), topicId: item.get('topicId') };
    //   });
    //   getAllTopicsIds(topicsIds);
    // } 
  }

  render() {
    const {topicsForCurrentActiveTeachingLevel, flashcardIdsResponse, allTopicsLoading} = this.props;
    if (topicsForCurrentActiveTeachingLevel.size == 0 || !allTopicsLoading || !flashcardIdsResponse.get('data') ){
      return <LeftMenuLoader  />;
    }
    return (
      <div ref={this.wrapperRef}>
        {topicsForCurrentActiveTeachingLevel?.map(this.renderTopic)}
      </div>
    );
  }

  loadTopics() {
    this.props.getAllTopicsInSubject({
      key: this.props.subjectId,
      subjectId: this.props.subjectId,
      mapAnswers: this.props.showAnswers
    });

  }

  renderTopic = (topic) => {
    const {subjectId, hasSelection, showAnswers, subtopics, activeTopicId, activeSubtopicId, isExercise, isExernalExercise} = this.props;
    const topicId = topic.get('id');

    const parentIds = [subjectId, topic.get('tlvlId')]; 
    if(!(isExercise &&  topic.getIn(["flashcardBox","total"]) <= 0)){
      return <TopicTileContainer
        parentIds={parentIds}
        key={topicId}
        topic={topic}
        activeTopicId={activeTopicId}
        activeSubtopicId={activeSubtopicId}
        onClick={this.onTopicTileClick}
        onPreviewClick={this.props.onPreviewClick}
        hasSelection={hasSelection}
        isExercise={isExercise}
        isExernalExercise={isExernalExercise}
        showAnswers={showAnswers}>
        <RequestLoader request={subtopics.get(topicId)}>
          {subtopics.get(topicId)?.get('data')?.map((subtopic) => this.renderSubtopic(subtopic, topic))}
        </RequestLoader>
      </TopicTileContainer>;
    }
  
  };

  renderSubtopic = (subtopic, parentTopic) => {
    const {subjectId, showAnswers, hasSelection, previews, activeTopicId, activeSubtopicId, isExercise, isExernalExercise} = this.props;
    const parentIds = [subjectId, parentTopic.get('tlvlId'), parentTopic.get('id')];
    const subtopicId = subtopic.get('id');
    if(!(isExercise && subtopic.getIn(["flashcardBox","total"]) <= 0)){
      return <SubtopicTileContainer
        parentIds={parentIds}
        parentTopic={parentTopic}
        activeTopicId={activeTopicId}
        activeSubtopicId={activeSubtopicId}
        key={subtopicId}
        subtopic={subtopic}
        isExercise={isExercise}
        hasSelection={hasSelection}
        showAnswers={showAnswers}
        isExernalExercise={isExernalExercise}
        onPreviewClick={this.props.onPreviewClick}
        onClick={isExercise ? this.props.onPreviewClick  : this.onSubtopicClick}
      >
        {/* <RequestLoader request={previews.getIn([subtopicId])}>
          {(data) => data.map(item => this.renderPreview(item, parentIds.concat(subtopicId)))}
        </RequestLoader> */}
      </SubtopicTileContainer>;
    }
  };

  renderPreview = (preview, parentIds) => {
    const {showAnswers, onPreviewClick, hasSelection} = this.props;
    const flashcardId = preview.get('flashcardId');

    return <TreePreviewContainer
      hasSelection={hasSelection}
      showAnswers={showAnswers}
      key={flashcardId}
      parentIds={parentIds}
      preview={preview}
      onClick={onPreviewClick}
    />;
  };

  onTopicTileClick = (topicId, parentIds) => {
    if (this.props.subtopics.get(topicId)) return;
    // this.props.onPreviewClick()
    this.props.getSubtopicsInTopic({id: topicId, parentIds: parentIds, mapAnswers: this.props.showAnswers});
  };

  onSubtopicClick = (id, parentIds) => {
    //do not dispatch getPreviewsInSubtopic request if data is already loaded
    if (this.props.previews.get(id)) return;
   // this.props.getPreviewsInSubtopic({id: id, parentIds: parentIds, mapAnswers: this.props.showAnswers});
  };

  scrollToActive() {
    const wrapperRef = this.wrapperRef.current;

    if (!wrapperRef) return;

    //check for active preview first
    let activeElement = wrapperRef.querySelector('div[data-preview-active="true"]');

    if (!activeElement) {
      //check for active subtopic
      activeElement = wrapperRef.querySelector('div[data-subtopic-active="true"]');
    }

    if (!activeElement) return;

    this.props.scrollTo(activeElement);
  }
}

TopicsTreeContainer.defaultProps = {
  scrollTo: () => null
};

TopicsTreeContainer.propTypes = {
  hasSelection: PropTypes.bool,
  subjectId: PropTypes.number.isRequired,
  onPreviewClick: PropTypes.func.isRequired,
  scrollTo: PropTypes.func,
  showAnswers: PropTypes.bool.isRequired,

  // Connect
  scrollToPreviewId: PropTypes.number,
  getSubtopicsInTopic: PropTypes.func.isRequired,
  getAllTopicsInSubject: PropTypes.func.isRequired,
  getPreviewsInSubtopic: PropTypes.func.isRequired,
  subtopics: PropTypes.instanceOf(Map),
  previews: PropTypes.instanceOf(Map).isRequired,
  flashcardIdsResponse: PropTypes.instanceOf(Map).isRequired,
  topicsForCurrentActiveTeachingLevel: PropTypes.instanceOf(List),
  allTopicsInSubjectResponse: PropTypes.instanceOf(Map).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  subtopics: subtopicsSelector(state),
  // previews: previewsInSubtopic(state),
  scrollToPreviewId: scrollToPreviewId(state),
  flashcardIdsResponse: flashcardIdsResponse(state),
  topicsForCurrentActiveTeachingLevel: topicsForCurrentActiveTeachingLevel(state, ownProps.subjectId, ownProps.isExernalExercise),
  allTopicsInSubjectResponse: allTopicsInSubjectResponse(state),
  activeTopicId: getActiveTopicId(state),
  activeSubtopicId: getActivSubTopicId(state),
  allTopicsInProgress: allTopicsInProgress(state, activeSubjectId(state)),
  activeSubjectTeachingLevel:activeSubjectTeachingLevel(state),
  allTopicsLoading:allTopicsLoading(state, ownProps.subjectId)
});

const mapDispatchToProps = {
  getSubtopicsInTopic,
  getAllTopicsInSubject,
  getAllTopicsIds,
};

TopicsTreeContainer.defaultProps = {
  showAnswers: false
};
export default connect(mapStateToProps, mapDispatchToProps)(TopicsTreeContainer);


export const CheckboxWrapper = styled.div`
  position: absolute;
  top:0;
  bottom:0;
  left: -34px;
  z-index: 1;
  display: flex;
  align-items: center;
`;
