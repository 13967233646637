import Immutable from 'immutable';
import * as Types from 'actions/subject/subjectActionTypes';

export default (state = Immutable.Map(), action) => {
  switch (action.type) {
    case Types.SET_ACTIVE_SUBJECT:
      return state.set('activeSubject', action.subject);
    case Types.SET_ACTIVE_TEACHING_LEVEL:
      return state.set('activeTeachingLevel', action.id);
    case Types.SET_TEACHING_LEVEL_SUBJECT:
      return state.set('subjectsTeachingLevel', action.data);
    case Types.SET_SUBJECT_SYLLABUS_LIST:
      return state.set('subjectSyllabusList', action.data);
    default:
      return state;
  }
};
