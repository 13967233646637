import {useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {deleteConfirmationMode, isTextareaFocused, textareaNote} from 'selectors/desk/deskSelector'
import {currentFlashcardId} from 'selectors/studying';
import { isMobile } from 'selectors/browser/browserSelector';
import {toggleFocusTextarea, toggleDeleteConfirmationMode, deleteNote} from 'actions/desk/deskActions'
import {setActiveFlashcardTab, setDocumentTitle} from 'actions/navigation/navigationActions'
import BackButton from 'v2/components/navigation-header/BackButton';
import SimpleButton from 'v2/components/Button/SimpleButton';
import FooterDeleteMode from 'v2/components/footer-edit-mode/FooterDeleteMode';
import BackToSearchButton from 'v2/ui-components/BackToSearchButton';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import {DeleteTrashIcon} from 'icons/FlashcardNavIcons';
import NoteEditorContainer from 'v2/components/edit-note/NoteEditorContainer';
import useScrollDetection from "hooks/useScrollDetection";
import './editNotePage.scss';
import { useHistory } from 'react-router-dom';
import usePrevious from 'hooks/usePrevious';

const EditNotePage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const bindScrollRef = useRef();
    const { isScrolled } = useScrollDetection({ WrappedRef: bindScrollRef, threshold1: 20 })
    const deleteConfirmMode = useSelector(deleteConfirmationMode)
    const currentId = useSelector(currentFlashcardId)
    const hasMobile = useSelector(isMobile);
    const hasTextAreaFocus = useSelector(isTextareaFocused);
    const noteText = useSelector(textareaNote);
    const prevHasMobile = usePrevious(hasMobile)
    useEffect(() => {
      dispatch(setActiveFlashcardTab(2));
      dispatch(setDocumentTitle('Notes'))
      return () => {
        dispatch(toggleDeleteConfirmationMode(false))
      };
    }, [])

    useEffect(() => {
      if(prevHasMobile && !prevHasMobile && hasMobile){
        history.push('/flashcard/desk/notes')
      }
    },[hasMobile])

    const onDelete = () => {  
      dispatch(deleteNote(currentId, true));
    }
    const renderLeftSection = () => {
      return <BackToSearchButton alternate={() => {
        return deleteConfirmMode ?
          <BackButton onClick={() => dispatch(toggleDeleteConfirmationMode(false))} text="Cancel"
            hideArrow={true}/> : <BackButton text="NOTES" linkTo="/flashcard/desk/notes" />;
      }}/>;
    }

    return <>
    <NavigationHeader
        extendedWidth={!deleteConfirmMode}
        leftSection={renderLeftSection()}
        isCollapsed={isScrolled}
        bottomSection={true}
        extendedHeight
        rightSection={(
          deleteConfirmMode ?
            <></> :
            <div className="edit-note-buttons-container">
              {hasTextAreaFocus &&
                <div className="done-button focused"><SimpleButton text="Done"
                onClick={() => dispatch(toggleFocusTextarea(false))}/></div> }
              {noteText !== '' &&
                <div className="delete-icon-note" onClick={() => dispatch(toggleDeleteConfirmationMode(true))}>
                  <DeleteTrashIcon color='#378EF8'/></div>}
            </div>
        )}/>
        <div className='h-full pt-[52px]'>
          <NoteEditorContainer innerRef={bindScrollRef} noteText={noteText} currentId={currentId} toggleFocusTextarea={(val: boolean) => dispatch(toggleFocusTextarea(val))} disable={deleteConfirmMode}/>
          {deleteConfirmMode && <FooterDeleteMode contained={hasMobile} currentId={currentId} 
            onClick={() => onDelete()}/>}
        </div>
    </>
}

export default EditNotePage;