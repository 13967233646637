import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isApiRequestInProgress } from 'selectors/api/apiSelector';
import { subjectsTeachingLevel, activeSubjectId, getActiveSubject } from 'selectors/subject/subjectSelector';
import { examsofFilterItems, listOfFilterCheckedItems } from 'selectors/desk/deskSelector';
import { getActivePaperTab, isDeskEditModeActive, getExerciseOnCanvas } from 'selectors/navigation/navigationSelector';
import { currentFlashcardId } from 'selectors/studying';
import { getUserId } from 'selectors/user';
import { examAnswers } from 'selectors/answersSelectors';
import { changeFlashcard, setActiveExerciseId, selectExams } from 'actions/studying';
import { setExerciseOnCanvas } from 'actions/navigation';
import { setExamQuestionNumber, paperAllQuestionData, updateExamsQuestionIndex, setFilterExamStatusCount } from 'actions/desk';
import { getExamPreview } from 'actions/api';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import ExamPreviewListItem from "./ExamPreviewListItem"
import { addBackFlashcardToLocalStorage, setLastMediaTypeVisited, decodeQuery, examTeachingFilter, setFormExams, storeExamsParentId, returnExamsStatusFilter, mathematicsIds } from 'helpers';
import { fromJS } from 'immutable';
import PaperESM from 'v2/components/empty-screen-message/PaperESM';
import { useHistory } from 'react-router-dom';
import usePrevious from 'hooks/usePrevious';
import { ExamsOptionsgroupedList, ExamsSectiongroupedList } from 'v2/helpers';
import classNames from 'classnames';

type ExamListContainerProps = {
    listItems: any;
    firstQuestion: (arg1: any, arg2: number) => void;
    paperId: string;
    id: string;
    loading: boolean;
}

const ExamListContainer: React.FC<ExamListContainerProps> = ({ listItems, firstQuestion, paperId, id, loading }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isExamHeaderInProgress = useSelector(state => isApiRequestInProgress(state, 'examHeader'));
    const hasSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
    const getActiveSubjectId = useSelector(activeSubjectId);
    const hasExamsofFilterItems = useSelector(examsofFilterItems).toJS();
    const hasActiveSubject = useSelector(getActiveSubject);
    const activePaperTab = useSelector(getActivePaperTab);
    const hasListOfFilterCheckedItems = useSelector(listOfFilterCheckedItems).toJS();
    const hasDeskEditModeActive = useSelector(isDeskEditModeActive);
    const hasCurrentFlashcardId = useSelector(currentFlashcardId);
    const userId = useSelector(getUserId);
    const hasExerciseOnCanvas = useSelector(getExerciseOnCanvas);
    const hasExamAnswers = useSelector(examAnswers).toJS();
    const prevExamsofFilterItems = usePrevious(hasExamsofFilterItems);
    const prevListOfFilterCheckedItems = usePrevious(hasListOfFilterCheckedItems);
    const prevExamAnswers = usePrevious(hasExamAnswers);
    const tlvl = hasSubjectsTeachingLevel[`${getActiveSubjectId}`];
    const newListItem = listItems?.length > 0 && examTeachingFilter(tlvl, listItems, hasExamsofFilterItems.teachingLevel, 'exams');
    const renderPaperType = () => {
        if (mathematicsIds.includes(hasActiveSubject.id) && activePaperTab === 'Paper 1') {
            return 'Paper 2';
        }
        return activePaperTab;
    }
    const filter = renderPaperType() === 'Paper 1' ? hasExamsofFilterItems.mcq : hasListOfFilterCheckedItems.exams;

    const userResponseFilterData = listItems?.length > 0 && returnExamsStatusFilter(newListItem, filter, renderPaperType());
    const groupedList: any = listItems?.length > 0 && ExamsSectiongroupedList(userResponseFilterData)
    const checkFilterSize = listItems?.length > 0 ? 'filterPaper' : activePaperTab;
    const getFirstQuestion = useCallback(() => {
        listItems?.length > 0 && firstQuestion(userResponseFilterData, userResponseFilterData.length);
    },[firstQuestion, listItems?.length, userResponseFilterData])
    useEffect(() => {
        getFirstQuestion();
    }, [])

    useEffect(() => {
        if (JSON.stringify(hasExamsofFilterItems.teachingLevel) !== JSON.stringify(prevExamsofFilterItems.teachingLevel)) {
            dispatch(setFilterExamStatusCount(id, hasExamsofFilterItems.teachingLevel));
            getFirstQuestion();
        }
        if (JSON.stringify(hasExamsofFilterItems.mcq) !== JSON.stringify(prevExamsofFilterItems.mcq) || JSON.stringify(hasListOfFilterCheckedItems.exams) !== JSON.stringify(prevListOfFilterCheckedItems.exams)) {
            getFirstQuestion();
        }
    }, [hasExamsofFilterItems, prevExamsofFilterItems, hasListOfFilterCheckedItems, prevListOfFilterCheckedItems])

    useEffect(() => {
        if (hasCurrentFlashcardId) {
            if (prevExamAnswers[hasCurrentFlashcardId] && prevExamAnswers[hasCurrentFlashcardId].correct !== hasExamAnswers[hasCurrentFlashcardId].correct) {
                setTimeout(() => dispatch(getExamPreview(id, null)), 500)
            }
        }
    }, [hasExamAnswers, prevExamAnswers])

    const onListItemClick = (flashcardId: string, item: any, index: number, numbering: number) => {
        if (hasDeskEditModeActive) return;
        const currentIndex = item.findIndex((val: any) => val?.uuid === flashcardId);
        const query = decodeQuery(history.location.search.substring(1));
        if (flashcardId !== hasCurrentFlashcardId) {
            addBackFlashcardToLocalStorage("exams-" + flashcardId, query.mediaType + "-" + hasCurrentFlashcardId);
            dispatch(changeFlashcard(flashcardId, "default"));
        }
        setLastMediaTypeVisited('exams', userId);
        if (hasExerciseOnCanvas || typeof hasExerciseOnCanvas === "undefined") {
            dispatch(setExerciseOnCanvas(false));
            dispatch(setActiveExerciseId(null));
        }
        dispatch(selectExams(flashcardId, 'exams', item.length, currentIndex + 1));
        setFormExams('exams')
        storeExamsParentId(paperId)

        const examsIndex = item.map((val: any) => ({
            index: val.numbering,
            uuid: val.uuid,
            user_response: val.user_response,
            teachingTag: val.teachingTag
        }));

        const examData = listItems.map((val: any) => ({
            index: val.numbering,
            uuid: val.uuid,
            user_response: val.user_response,
            teachingTag: val.teachingTag,
            paperType: val.paperType
        }));

        dispatch(setExamQuestionNumber(numbering));
        dispatch(paperAllQuestionData(examData));
        dispatch(updateExamsQuestionIndex(examsIndex, index + 1));

    }

    if (groupedList.length === 0 || groupedList === false) {
        return <PaperESM type={checkFilterSize} from={'paper'} />
    }

    return <> {isExamHeaderInProgress || loading ? <LeftMenuLoader /> :
        <>
            {activePaperTab === 'Paper 3' ?
                <>
                    {groupedList.length > 0 && groupedList.map((item: any) => {
                        const questionOptions = ExamsOptionsgroupedList(item);
                        return <div key={`exams-${item[0].category}`}>
                            {groupedList.length > 1 &&
                                <div className=''>
                                    <div className='exam-list-header h-8 px-5 relative flex items-center bg-white text-15px font-bold text-#2D3838 overflow-hidden max-w-full opacity-90' dangerouslySetInnerHTML={{ __html: item[0].sectionTitle }} />
                                </div>}
                            {questionOptions.map((subSection: any, index: number) => {
                                const questionOptionsTitle = subSection?.[0]?.question_options
                                const question_options_full_text = subSection?.[0]?.question_options_full_text
                                return <>
                                    {(questionOptionsTitle.includes('Option') && questionOptionsTitle !== '' && questionOptionsTitle !== 'Core') &&
                                        <div className={classNames('options-header', { 'mt-3.5': index !== 0 })}>
                                            <div className='options-headlight' dangerouslySetInnerHTML={{ __html: question_options_full_text }} />
                                            <div className='options-text'>{questionOptionsTitle.substring(questionOptionsTitle.indexOf(":") + 1)}</div>
                                        </div>
                                    }
                                    {
                                        subSection.map((subSection: any, index: number) => {
                                            return <ExamPreviewListItem
                                                key={`exams-${Math.random()}`}
                                                number={subSection.numbering}
                                                active={subSection.uuid === hasCurrentFlashcardId}
                                                teachingLevel={subSection.teachingLevel}
                                                teachingTag={subSection.teachingTag}
                                                marks={subSection.marks}
                                                sources={subSection.sources}
                                                onClick={() => onListItemClick(subSection.uuid, userResponseFilterData, index, subSection.numbering)}
                                                flashcardId={subSection.uuid}
                                                experienceData={subSection.experienceData}
                                                QuestionType={subSection.questionType} />
                                        })
                                    }</>
                            })}
                            <div className='pt-3.5'></div>
                        </div>
                    }
                    )}
                </> :
                <>
                    {Object.values(groupedList).length > 0 && Object.values(groupedList).map((item: any) => {
                        return <div key={`exams-${item[0].category}`}>
                            {Object.keys(groupedList).length > 1 &&
                                <div className=''>
                                    <div className='exam-list-header h-8 px-5 relative flex items-center bg-white text-15px font-bold text-#2D3838 overflow-hidden max-w-full opacity-90' dangerouslySetInnerHTML={{ __html: item[0].sectionTitle }} />
                                </div>}
                            {item.map((subItem: any, index: number) => <ExamPreviewListItem
                                key={`exams-${Math.random()}`}
                                number={subItem.numbering}
                                active={subItem.uuid === hasCurrentFlashcardId}
                                teachingLevel={subItem.teachingLevel}
                                teachingTag={subItem.teachingTag}
                                marks={subItem.marks}
                                sources={subItem.sources}
                                onClick={() => onListItemClick(subItem.uuid, userResponseFilterData, index, subItem.numbering)}
                                flashcardId={subItem.uuid}
                                experienceData={subItem.experienceData}
                                QuestionType={subItem.questionType} />
                            )}
                            <div className='pt-3.5'></div>
                        </div>
                    }
                    )}
                </>
            }
        </>}
    </>
}

export default ExamListContainer
