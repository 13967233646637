import * as Actions from 'actions';
import gearIcon from 'assets/icons/home/gear-icon.svg';
import gearWhiteIcon from 'assets/icons/home/gear-white-icon.svg';
import { ReactComponent as GearIcon } from 'assets/icons/home/gear-icon.svg';
import { ReactComponent as GearWhiteIcon } from 'assets/icons/home/gear-white-icon.svg';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import ClickableIcon from 'ui-components/helpers/ClickableIcon';
import Row from 'ui-components/helpers/Row';
import './NavigationHeader.scss';
import { CollapseIconMenu } from 'icons/FlashcardNavIcons';
import * as Selectors from 'selectors';
import { toggleNavigationSection, setActiveFlashcardTab } from 'actions/navigation';

class NavigationHeader extends PureComponent {

  closeToggleMenu = () => {
    if (this.props.currentFlashcardId) {
      if (this.props.isLeftMenuOpen) {
        this.props.toggleNavigationSection(false)
        this.props.setActiveFlashcardTab(-1)
      }
    }
  }
  render() {
    const { isCollapsed, title, collapsedTitle, leftSection, text, inverted, bottomSection, isMenuVisible } = this.props;
    let cssClassModifiers = '';
    if (this.props.isCollapsed && !bottomSection) cssClassModifiers += ' shadow';
    if (this.props.inverted) cssClassModifiers += ' inverted';
    if (this.props.isSearchPage) cssClassModifiers += ' search-inverted';
    if (this.props.isSearch) cssClassModifiers += ' is-search';
    if (this.props.extendedWidth) cssClassModifiers += ' extended-width';
    if (this.props.className) cssClassModifiers += ' ' + this.props.className;
    if (this.props.contained) cssClassModifiers += ' contained';
    if (this.props.hideborder) cssClassModifiers += ' hide-border';
    if (this.props.shouldExtendHeight) cssClassModifiers += ' extended-height';

    if (this.props.teachingLevelsForActiveSubject) cssClassModifiers += ' has-two-teaching-levels';
    
    if (this.props.extendedHeight) cssClassModifiers += ' large';
    if (this.props.isSupportPage) cssClassModifiers += ' is-support-page';
    if (!this.props.isLinkPage) cssClassModifiers += ' is-not-link-page';

    return (
      <div className={`navigation-header${cssClassModifiers}`}>
        <StyledRow alignItems='center' justifyContent='space-between' style={{width: '100%'}}>
          <div className="tutorial-step-header-icons inline-block">
            {leftSection ? this.props.leftSection : /* renderDefaultIcons */ null}
          </div>

          <div className={`small-title ${isCollapsed || title ? 'visible' : ''} ${inverted ? 'inverted' : ''} ${!isMenuVisible ? 'invisible-menu' : ''}`}>
            {isCollapsed ? (collapsedTitle || text || title) : title}
          </div>

          <div className={`right-section`}>{this.props.rightSection} </div>
        </StyledRow>
        <div
          className={`bottom-section ${this.props.shouldExtendHeight ? '' : 'hidden'}`}>{this.props.bottomSection}</div>
      </div>
    );
  }

  renderDefaultIcons() {
    const {openMenu} = this.props;

    if (this.props.inverted) {
      return (
        <GearWhiteIcon onClick={openMenu}/>
      );
    }

    return (
      <GearIcon onClick={openMenu}/>
    );
  }
}

NavigationHeader.propTypes = {
  title: PropTypes.any,
  text: PropTypes.string,
  className: PropTypes.string,
  // collapsedTitle: PropTypes.string,
  inverted: PropTypes.bool,
  leftSection: PropTypes.element,
  rightSection: PropTypes.element,
  bottomSection: PropTypes.element,
  openMenu: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
  extendedWidth: PropTypes.bool,
  contained: PropTypes.bool,
  extendedHeight: PropTypes.bool,
  shouldExtendHeight: PropTypes.bool
};

const mapDispatchToProps = {
  openMenu: Actions.Navigation.openMenu,
  toggleNavigationSection: toggleNavigationSection,
  setActiveFlashcardTab: setActiveFlashcardTab,
};
const mapStateToProps = (state) => ({
  currentFlashcardId: Selectors.Studying.currentFlashcardId(state),
  isLeftMenuOpen: Selectors.isLeftMenuOpen(state),
  activeTab: Selectors.getActiveFlashcardTab(state),
  isMenuVisible: Selectors.isMenuVisible(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(NavigationHeader);

export const NavigationHeaderExtension = styled.div`
    background: ${props => props.theme.leftRightGradient};
    padding: 57px 20px 9px;
`;
const StyledRow = styled(Row)`
  align-self: center;
  min-height: 24px;
`;
