import { preparePreviewString } from 'helpers';
import React, { ReactElement } from 'react'
import PreviewListItem from 'v2/ui-components/search-results/PreviewListItem';

type PreviewsListProps = {
  activeMath: boolean;
  activeSearchTerm: string;
  filterFlashcardId: string | number;
  isActive: (arg: number) => boolean;
  rightSection: (arg: any) => ReactElement;
  list: any;
  onPreviewClick: (arg: number) => void;
  shouldMark: boolean;
}

const PreviewsList: React.FC<PreviewsListProps> = ({ activeMath, activeSearchTerm, filterFlashcardId, isActive, rightSection, list, onPreviewClick, shouldMark }) => {
  return list.map((preview: any) => {
    let previewText;    
    if (shouldMark && activeSearchTerm) {
      previewText = preparePreviewString(preview?.preview?.fullText, activeSearchTerm).text;
    } else {
      previewText = preview?.preview?.fullText;
    }
    const flashcardId = preview?.flashcardId;
    return <PreviewListItem
      key={`flashcard-${flashcardId}`}
      active={isActive(flashcardId)}
      isEditing={false}
      flashcardId={flashcardId}
      onClick={() => onPreviewClick(flashcardId)}
      flashcardNumber={preview?.absoluteNumbering || preview?.numbering}
      preview={previewText}
      deleted={filterFlashcardId === flashcardId}
      previewTitle={preview.title}
      rightSection={rightSection({ preview })}
      shouldMark={shouldMark}
      activeMath={activeMath}
      data={preview}
    />;
  })
}

export default PreviewsList