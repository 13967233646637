import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import StoreItemDetails from './StoreItemDetails.js';
import * as Selectors from 'selectors';
import * as Actions from 'actions';

//FIXME Need to get the store item based by ID!!!
class StoreItemDetailsContainer extends Component {
  constructor(props) {
    super(props);

    this.isTabActive = this.isTabActive.bind(this);
  }

  isTabActive(index) {
    return this.props.getActiveItemStoreDetailsTab === index;
  }

  render() {
    return <StoreItemDetails {...this.props} isTabActive={this.isTabActive}/>;
  }
}

StoreItemDetailsContainer.propTypes = {};

function mapStateToProps(state, ownProps) {
  return {
    getActiveItemStoreDetailsTab: Selectors.getActiveItemStoreDetailsTab(state),
    storeItemById: Selectors.storeItemById(state, ownProps.match.params.id),
  };
}

export default connect(mapStateToProps, {
  setActiveItemStoreDetailsTab: Actions.Navigation.setActiveItemStoreDetailsTab,
})(StoreItemDetailsContainer);
