import React from 'react'
import { GoogleLogoSvg } from 'icons/HeaderIcons';
import { useGoogleLogin } from '@react-oauth/google';
import { useHistory } from 'react-router-dom';

const redirectUri = process.env.REACT_APP_API_URL

const GoogleLogin = () => {
    const history = useHistory();
    async function loginSuccess({ access_token }: any) {
        try {
            const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${access_token}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            const result = await response.json()
            console.log("Success:", result)
            window.location.replace(`${redirectUri}/googlelogin?token=${btoa(result?.email)}`)
        } catch (error) {
            console.error("Error:", error)
            history.push(`/auth/google?error=User%20not%20found`)
        }
    }
    const login = useGoogleLogin({
        onSuccess: loginSuccess,
        onError: (e) => console.error('Error in login with google: ', e),
    })

    return <div
        className='login-with-google-btn flex items-center justify-center bg-white text-13px text-blue-normal text-center rounded-[22px] h-11 w-full cursor-pointer font-bold tracking-2px my-4 [&>span]:tracking-normal [&>svg]:w-6 [&>svg]:mr-3'
        onClick={() => login()}>
        <GoogleLogoSvg /> LOG IN WITH <span>&nbsp;Google</span>
    </div>
}

export default GoogleLogin
