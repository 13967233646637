import React from 'react';
import classNames from 'classnames';
import { isTeachingLevelExamsOverlayVisible } from 'selectors/navigation/navigationSelector';
import { examsofFilterItems } from 'selectors/desk/deskSelector';
import { examStatusById } from 'selectors/quickSelectors';
import { questionsCount, isApiRequestInProgress } from 'selectors/api/apiSelector';
import { toggleTeachingLevelExamsOverlay } from 'actions/navigation';
import { updateExamsPaperFilterItems } from 'actions/desk';
import { returnFinalExams, mathematicsIds } from 'helpers';
import { ArrowdownIcon, FilterClearIcon, MultipleChoice, FreeResponse, } from "icons/HeaderIcons";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux';
import TeachingLevelSeperator from "v2/components/teaching-level-selector/TeachingLevelSeperator";
import StatusBar from "v2/components/status-bar/StatusBar";
import 'react-loading-skeleton/dist/skeleton.css';
import { paperInfo } from './paperData';

type PaperDescriptionProps = {
    paperData: any;
    activeSubjectId: number;
    subjectsTeachingLevel: any;
    activePaperTab: string;
    loading: boolean;
    tlvl: string;
    id: string;
}

enum SKELETON_THEME {
    BASE_COLOR = '#fffefe',
    HIGHLIGHT_COLOR = '#b9b7b7'
};

const PaperDescription: React.FC<PaperDescriptionProps> = ({ tlvl, activeSubjectId, activePaperTab, paperData, id, loading }) => {
    const dispatch = useDispatch();
    const getQuestionsCount = useSelector(questionsCount);
    const isQuestionCountInProgress = useSelector(state => isApiRequestInProgress(state, 'getQuestionCount'));
    const getExamsofFilterItems = useSelector(examsofFilterItems);
    const hasTeachingLevelExamsOverlayVisible = useSelector(isTeachingLevelExamsOverlayVisible);
    const getExamStatusById = useSelector((state) => examStatusById(state, id)).toJS();
    const statusBars = {
        exams: getExamStatusById.freeResponse,
        mcq: getExamStatusById.mcqResponse,
    };
    const { examsStatus, examsStatusGraph, mcqStatus, mcqStatusGraph } = returnFinalExams(statusBars);
    const data = getExamsofFilterItems.toJS();
    const { marks=0, time=0, calc="Calc" } = paperInfo?.[activeSubjectId][tlvl]?.[activePaperTab] ?? {};
    const paperInfoText = () => paperData.filter((value: any) => value.title === activePaperTab);
    const isNotMathsAndPaper1 = () => !mathematicsIds.includes(activeSubjectId) && ["Paper 1", "Paper 1A"].includes(activePaperTab);

    return <>
        {loading ?
            <div className='pt-5 max-h-[128px]'>
                <SkeletonTheme baseColor={SKELETON_THEME.BASE_COLOR} highlightColor={SKELETON_THEME.HIGHLIGHT_COLOR}>
                    <Skeleton className="inner-skeleton-exam" count={1} duration={2} height={23} />
                </SkeletonTheme>
                <SkeletonTheme baseColor={SKELETON_THEME.BASE_COLOR} highlightColor={SKELETON_THEME.HIGHLIGHT_COLOR}>
                    <Skeleton className="inner-skeleton-d-exam" count={3} duration={2} height={15} />
                </SkeletonTheme>
            </div> :
            <>
                <div className='text-15px leading-19px font-bold text-white mt-4 mx-0 mb-3'>
                    {getQuestionsCount} questions • {marks} marks • {time} • {calc}
                </div>
                {paperInfoText().length > 0 && <div className='text-15px leading-19px text-white mb-6'>{paperInfoText()[0].subTitle}</div>}
                {!isQuestionCountInProgress &&
                    <div className='absolute paper-desc-wrapper'>
                        <div className='style-metainfo-tile pt-3.5 pr-5 pb-2 pl-3.5'>
                            {data["teachingLevel"].length > 0 && data["teachingLevel"].length < 2 ?
                                <div className='relative w-fit microfilter-container'>
                                    <div
                                        className={classNames('flex items-center select-none w-fit h-9 exams-filter', {
                                            'pointer-events-none opacity-5': tlvl === 'SL',
                                            'cursor-pointer': tlvl !== 'SL'
                                        })}
                                        onClick={() => dispatch(toggleTeachingLevelExamsOverlay(true, true))}>
                                        <div className='flex items-center teaching-lvl-active'>
                                            <TeachingLevelSeperator tlvl={data["teachingLevel"][0]} tlvlTag={data["teachingLevel"][0]} slTag={data["teachingLevel"][0] === "SL"} isExams={false} />
                                        </div>
                                        <div className="closeButton1"></div>
                                    </div>
                                    <div
                                        className={'h-9 w-9 flex rounded-full items-center justify-center top-0 right-0 cursor-pointer ml-1 z-10 absolute filter-clear'}
                                        onClick={() => dispatch(updateExamsPaperFilterItems([], "teachingLevel"))}
                                    >
                                        <FilterClearIcon />
                                    </div>
                                </div> :
                                <>
                                    <div
                                        className={classNames('flex items-center select-none w-fit h-9 exams-filter ', {
                                            'pointer-events-none opacity-5': tlvl === 'SL',
                                            'cursor-pointer': tlvl !== 'SL',
                                            'tl-exams-overlay-visible': hasTeachingLevelExamsOverlayVisible,
                                            'tl-exams-overlay-not-visible': !hasTeachingLevelExamsOverlayVisible
                                        })}
                                        onClick={() => dispatch(toggleTeachingLevelExamsOverlay(true, true))}>
                                        <div className='font-bold text-13px leading-18px pl-4 teaching-lvl-wrapper'>Teaching level</div>
                                        <ArrowdownIcon />
                                    </div>
                                </>
                            }
                            <div className='mt-4'>
                                <StatusBar
                                    number={isNotMathsAndPaper1() ? mcqStatus.total : examsStatus.total}
                                    title={isNotMathsAndPaper1() ? "Multiple choice" : "Free response"}
                                    statusData={isNotMathsAndPaper1() ? mcqStatusGraph : examsStatusGraph}
                                    isRead={isNotMathsAndPaper1() ? mcqStatus.total === mcqStatus.correct : examsStatus.correct === examsStatus.total}
                                    mediaType={"exams"}
                                    icon={isNotMathsAndPaper1() ? <MultipleChoice /> : <FreeResponse />}
                                />
                            </div>
                        </div>
                    </div>
                }
            </>
        }
    </>
}

export default PaperDescription;