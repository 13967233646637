import {ReactComponent as PlusIcon} from 'assets/icons/topic-tiles/plusIcon.svg';
import {PreviewWrapper} from 'pages/exercises/MBCreateExercisePage';
import * as PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import styled, {css} from 'styled-components/macro';
import Col from 'ui-components/helpers/Col';
import {Relative} from 'ui-components/helpers/common';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';
import Text from 'ui-components/helpers/Text';
import {StyledPreviewListItem, TopicNumber} from 'ui-components/search-results/PreviewListItem';
import media from 'ui-components/helpers/media';
class LinkTopicTile extends PureComponent {
  render() {
    let {topicData, expanded, onSelect, absolute, active, answers} = this.props;
    return <StyledTopic expanded={expanded} active={active} onClick={onSelect} absolute={absolute}
      className={active ? 'tutorial-step-topic' : ''}>
      <TileHeader active={active} expanded={expanded}>
        {absolute}
        <Padding top={12} left={12} right={12} bottom={12}>
        <Col>
          
            <Row justifyContent={'space-between'} alignItems={'center'}>
              {/*TODO replace Row with PreviewListHeader (padding is different)*/}
              <Row alignItems={'center'}>
                <TopicNumber alternate={active || expanded}>{topicData.get('label')}</TopicNumber>
                <Text color={'lightGrey2'} size={13} letterSpacing={0.5}><StyledTechingLevelHeader>{topicData.get('tlvl')}</StyledTechingLevelHeader></Text>
              </Row>
              <Row>  
              <Text size={13} letterSpacing={0.3} color={'lightGrey2'}>{topicData.get('numbering')}</Text>
              </Row>
            </Row>
            <Padding bottom={18}></Padding>
              <Text size={19} weight={'bold'} color={'#363838'}
                lineHeight={'26px'}>{topicData.get('title')}</Text>            
        </Col>
        {answers && <Padding top={12}>{answers}</Padding>}
        </Padding>
      </TileHeader>
      <ExpandedArea>
        {expanded && this.props.children}
      </ExpandedArea>
    </StyledTopic>;
  }
}

const ExpandedArea = styled.div``;

LinkTopicTile.propTypes = {
  topicData: PropTypes.any,
  expanded: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  subtopics: PropTypes.any,
  absolute: PropTypes.node,
  active: PropTypes.bool,
  children: PropTypes.node,
  answers: PropTypes.node,
};

const StyledTopic = styled(Col)`
  border-radius: 10px;
  box-shadow: 0px 0px 0px 1px #E1E6E6;
  margin-bottom: 14px;
  cursor: pointer;
  
  background: ${props => {
    if (props.expanded) return '#F2F2F2';
    if (props.active) return '#e9ebeb';
    return '#FFFFFF';
  }};
  
  position: relative;
  z-index: 1;
  min-height: 90px;
  width: 100%;
  margin-left: ${props => props.absolute ? '27px' : 'auto'};
  
  > ${Col} {
    &:first-child{
      border-bottom: ${props => props.expanded ? '1px solid #E5E5E5' : 'none'};
    }
  }
  
  ${media.tablet(css`
    width: 100%;
  `)};
  
  ${media.phone(css`
    width: 100%;
  `)};
  
  ${TopicNumber}{
    margin-right: 7px;
  }
`;

const StyledTechingLevelHeader=styled.div`
font-weight: bold;
text-transform: uppercase;
letter-spacing: 2px;`;


export default LinkTopicTile;

export const Subtopic = ({label, numbering, title, loading, onExpand, onSelect, expanded, children, absolute, active, answers}) => {
  return <React.Fragment>
    <StyledSubtopic onClick={onSelect} expanded={expanded} active={active}
      data-subtopic-active={active}>
      {absolute}
      <Padding left={12} right={12}>
        <Row justifyContent={'space-between'}>
          <Padding top={12} bottom={12}>
            <TopicNumber alternate={expanded || active}>{label}</TopicNumber>
          </Padding>

          <Content as={Col} alignitems={'space-between'}>
            <Padding top={12} bottom={12}>
              <Row justifyContent={'space-between'}>
                <Text size={15} color={'charcoal'} lineHeight={'19px'} weight={'bold'}
                  dangerouslySetInnerHTML={{__html: title}}/>

                <Row>
                  <Padding left={14} right={26}>
                    <Text letterSpacing={0.3} size={13} color={'lightGrey2'} noWrap
                      lineHeight={1.6}>{numbering}</Text>
                  </Padding>

                  <Relative>
                    <PlusIconWrapper onClick={(e) => {
                      onExpand();
                      e.stopPropagation();
                    }} expanded={expanded}><PlusIcon/></PlusIconWrapper>
                  </Relative>
                </Row>
              </Row>
            </Padding>

            {answers && <Padding bottom={12}>{answers}</Padding>}
          </Content>
        </Row>
      </Padding>
    </StyledSubtopic>
    {expanded && <SubtopicChildren as={Col}>{children}</SubtopicChildren>}
  </React.Fragment>;
};

const Content = styled.div`
  flex-grow: 1;
`;

const StyledSubtopic = styled(Col)`
  background: ${props => {
  if (props.expanded) return '#F2F2F2';
  if (props.active) return '#e9ebeb';
  return '#FFFFFF';
}};
  min-height: 45px;
  position: relative;
  
  ${TopicNumber}{
    margin-right: 14px;
  }
  
  .bar-line-bg{
    ${props => props.active && 'background: white;'}
    ${props => props.expanded && 'background: white;'}
  }
  @media (pointer: fine) {
    &:hover {
      background: ${props => props.active ? '#e9ebeb' : '#F2F2F2'};
      
      ${TopicNumber}{
        background-color: white;
      }
      
      .bar-line-bg{
        background: white;
      }
    }
  }
  
  ${ExpandedArea} & {   
    :not(:last-child):after{
      content: '';
      height: 1px;
      background: #F0F5F5;
      position: absolute;
      bottom: -1px;
      left: 12px;
      right: 12px;
      display: ${props => props.expanded || props.active ? 'none' : 'initial'};
      z-index: 1;
    }
    
    :hover:after{
      display: none;
    }
  }
 
  &:last-child{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const SubtopicChildren = styled.div`
    &:last-of-type{
       > ${PreviewWrapper}:last-child{
        > ${StyledPreviewListItem}:last-child{
           border-bottom-right-radius: 10px;
           border-bottom-left-radius: 10px;
        }
      }
    }
    
      &:last-of-type{
       > ${PreviewWrapper}:last-child:after{
        display: none;
      }
    }
`;

const PlusIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
  
  svg {
    transform: ${props => props.expanded ? 'rotate(45deg)' : 'rotate(0)'};
    transition: transform .1s ease-in-out;
    transform-origin: center center;
  }
  
  ${StyledTopic} & {
    top: -14px;
    right: -8px;
    padding-left: 18px;
  }
  
  ${StyledSubtopic} & {
    top: -12px;
    height: 44px;
  }
`;

const TileHeader = styled.div`
  min-height: 90px;
  position: relative;
  border-bottom: ${props => props.expanded ? '1px solid #E5E5E5;' : 'none;'};
  border-radius: 10px;
  
  ${props => (props.expanded) &&
  'border-top-right-radius: 10px; border-top-left-radius: 10px;border-bottom-left-radius: 0;border-bottom-right-radius: 0;'}
    
  .bar-line-bg{
    ${props => props.active && 'background: white;'}
    ${props => props.expanded && 'background: white;'}
  }
  @media (pointer: fine) {
    &:hover {
      background: ${props => props.active ? '#e9ebeb' : '#F2F2F2'};
      
      ${TopicNumber}{
        background-color: white;
      }
      
      .bar-line-bg{
        background: white;
      }
    }
  }
`;
