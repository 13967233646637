import {changeMenuPathAction} from 'actions/menuActions';
import BackButton from 'components/navigation-header/BackButton';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ClickableDiv} from 'ui-components/helpers/common';

class BackToProfileButtonContainer extends Component {
  render() {
    return (
      <ClickableDiv onClick={this.props.changeMenuPath}>
        <BackButton text="Settings" onClick={() => {
        }}/>
      </ClickableDiv>
    );
  }
}

BackToProfileButtonContainer.propTypes = {
  changeMenuPath: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  changeMenuPath: changeMenuPathAction,
};
export default connect(null, mapDispatchToProps)(BackToProfileButtonContainer);
