import { useRef, useEffect, useCallback } from 'react';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile, isLandscape, scrollPosition, previewsListbyType } from 'selectors'
import BackButton from 'v2/components/navigation-header/BackButton';
import { useParams } from 'react-router-dom';
import TopicListContainer from 'v2/pages/topics/TopicListContainer';
import TopicPageHeaderContainer from 'v2/pages/topics/TopicPageHeaderContainer';
import { saveScrollPosition, setActiveFlashcardTab } from 'actions/navigation';
import debounce from 'lodash.debounce';
import usePrevious from 'hooks/usePrevious';
import './topicsPage.scss';
import useScrollDetection from 'hooks/useScrollDetection';
import useRedirectHLSL from 'hooks/useRedirectionHLSL';

type TopicPageProps = {
  isScrolled: boolean,
  bindScrollRef: (arg: any) => void
}
type ParamsProps = {
  id: string
}

const TopicPage: React.FC<TopicPageProps> = () => {
  let wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch()
  const { id } = useParams<ParamsProps>()
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);
  const hasScrollPosition = useSelector(scrollPosition).toJS();
  const previews = useSelector(previewsListbyType).toJS();
  const prevPreview: any = usePrevious(previews)
  const { isScrolled, bindScrollRef } = useScrollDetection({ WrappedRef: wrapperRef });
  useRedirectHLSL()
  
  useEffect(() => {    
    dispatch(setActiveFlashcardTab(0));
    window.scrollTo(0, 0);
    const values = {
      "previousValue": 0,
      "currentValue": 0
    }
    dispatch(saveScrollPosition("topicsPage", values));
    // @ts-ignore
    wrapperRef?.addEventListener('scroll', onScroll);
    return () => {
       // @ts-ignore
      wrapperRef?.removeEventListener('scroll', onScroll);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if(prevPreview?.[id]?.loading !== previews?.[id]?.loading && previews?.[id]?.loading === false){
       // @ts-ignore
      wrapperRef.scrollTop = hasScrollPosition.topicsPage?.currentValue
    } 
  }, [previews, prevPreview])

  const onScroll = debounce((event: any) => {
    const scrollTop = event.target.scrollTop;
    const values = {
      "previousValue": hasScrollPosition.topicsPage?.currentValue,
      "currentValue": scrollTop
    }
    if (previews?.[id]?.loading === false) {
      dispatch(saveScrollPosition("topicsPage", values))
    }
  }, 300)

  const renderLeftSection = () => {
    return <BackButton linkTo='/flashcard/topics' text='Topics' />;
  };

  const bindRef = useCallback((ref: any) => {
    wrapperRef = ref;
    bindScrollRef(ref);
  },[]);

  return <div className='relative flashcard-nav-route-wrapper scroll-smooth'>
    <NavigationHeader extendedWidth
      isCollapsed={!(hasMobile && hasLandscape) ? isScrolled : true} text={null}
      rightSection={null}
      extendedHeight
      shouldExtendHeight={false}
      leftSection={renderLeftSection()}
      bottomSection={!(hasMobile && hasLandscape) ? null : true}
    />
    <div className="topics-container topic-fixed-space" ref={bindRef}>
      <TopicPageHeaderContainer typeId={id} activeType={'topic'} />
      <TopicListContainer activeType={1} id={id} fromPage={"topic"} />
    </div>
  </div>
}

export default TopicPage