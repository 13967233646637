import PropTypes from 'prop-types';
import styled, {css} from 'styled-components/macro';

const RoundButton = styled.button`
  border-radius: 50%;
  width: ${({small}) => small ? 34 : 44}px;
  min-width: ${({small}) => small ? 34 : 44}px;
  height: ${({small}) => small ? 34 : 44}px;
  background: ${({colored}) => colored ? '#2EBCB4' : 'white'};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({small}) => small ? 4 : 5}px;
  box-shadow: 0 1px 3px 0 rgba(62,87,85,0.22);
  //transition: all .2s;
  outline: none;
  pointer-events: all;
  
  ${props => props.simple && css`
    background-color: transparent;
    box-shadow: unset;
  `}
  
  &:focus {
    outline: none;
  }
  @media (pointer: fine) {
    &:hover {
      box-shadow: 0 2px 6px 0 rgba(62,87,85,0.22);
      
      ${props => props.simple && css`
        box-shadow: unset;
      `}
    }
  }
  &:active {
    background-color: #C8CCCC;
    box-shadow: 0 0 0 0 rgba(62,87,85,0.22);
    outline: none;
    
    ${({colored}) => colored && css`
      background: linear-gradient(-134deg, #3DAD8D 0%, #3D9BAD 100%);
    `}
    
    ${props => props.simple && css`
      background-color: transparent;
    `}
  }
`;

RoundButton.propTypes = {
  small: PropTypes.bool,
  simple: PropTypes.bool,
};

export default RoundButton;
