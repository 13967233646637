import {
  addCredentialsToLocalStorage, getRefreshToken, removeCredentialsFromLocalStorage,
  shouldRefreshToken
} from '../helpers';
import * as ActionTypes from 'actions/types';
import {refreshToken} from '../api';

const oauthMiddleware = store => next => action => {
  const allowedActions = [ActionTypes.INITIALIZE, ActionTypes.Api.REFRESH_TOKEN, ActionTypes.Api.REFRESH_TOKEN_ENDED, ActionTypes.Api.AUTHENTICATE_SUCCEEDED, ActionTypes.Api.AUTHENTICATE_FAILED];
  if (shouldRefreshToken() && allowedActions.indexOf(action.type) === -1) {
    if (window.refreshTokenPromise) {
      return window.refreshTokenPromise.then(() => next(action));
    } else {
      return refreshTokenPromise(store.dispatch).then(() => next(action));
    }
  }

  return next(action);
};

export {oauthMiddleware};

function refreshTokenPromise(dispatch) {
  const storedRefreshToken = getRefreshToken();
  let refreshTokenPromise = refreshToken({refresh_token: storedRefreshToken})
    .then(data => {
      data.createdAt = Math.floor(Date.now() / 1000);
      addCredentialsToLocalStorage(data);
      dispatch({type: ActionTypes.Api.AUTHENTICATE_SUCCEEDED, payload: data});
      dispatch({type: ActionTypes.Api.REFRESH_TOKEN_ENDED});
      window.refreshTokenPromise = null;
    })
    .catch(error => {
      removeCredentialsFromLocalStorage();
      dispatch({type: ActionTypes.Api.AUTHENTICATE_FAILED, payload: error});
      dispatch({type: ActionTypes.Api.REFRESH_TOKEN_ENDED});
      window.refreshTokenPromise = null;
    });

  window.refreshTokenPromise = refreshTokenPromise;
  dispatch({type: 'REFRESH_TOKEN'});

  return refreshTokenPromise;
}
