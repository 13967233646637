import { FC, useRef, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { scrollToFlashcardAction, setActiveFlashcardAction } from 'actions/studying';
import { useDispatch, useSelector } from 'react-redux';
import {getSelectedTreeNodes} from 'selectors/topicsTreeSelector';
import { isMobile, getActiveTeachingLevel, activeSubjectId } from 'selectors';
import { toggleNavigationSection } from 'actions/navigation';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import BackButton from 'v2/components/navigation-header/BackButton';
import classNames from 'classnames';
import { isExerciseNameFieldFocused } from 'selectors/formSelectors';
import Overlay from 'v2/pages/Overlay';
import MircoFilterLayout from 'v2/components/studying/microFilterContainer/MicroFilterLayout'
import Checkbox from 'v2/ui-components/Checkbox';
import Text from 'v2/components/common/Text';
import ToggleMenuButtonContainer from 'v2/components/studying/ToggleMenuButtonContainer';
import cardIcon from 'assets/icons/card-icon.svg';
import ExerciseForm from 'v2/components/exercise/ExerciseFormContainer';
import { exerciseData } from 'selectors/exerciseSelectors';
import TopicsTreeContainer from 'v2/components/topics-tree/TopicsTreeContainer';
import SelectedCardsButtonWrapper from 'v2/components/topics-tree/SelectedCardsButtonWrapper';
import {getExerciseData} from 'actions/exercises/exercisesActions';
import './exercise.scss'
import useScrollDetection from 'hooks/useScrollDetection';
import { getAllTopicsInSubject } from 'actions/api';

const CreateExercisePage = () => {
  let wrapperRef = useRef<HTMLDivElement>(null);
  let formRef = useRef<HTMLFormElement>()
  const dispatch = useDispatch()
  const params: any = useParams(); 
  const hasMobile = useSelector(isMobile);
  const exerciseId = params?.id
  const hasExerciseData = useSelector((state) => exerciseData(state, exerciseId))?.toJS()
  const [exerciseTitle, setExerciseTitle] = useState<any>(hasExerciseData?.title ?? '')
  const showOverlay = useSelector(isExerciseNameFieldFocused);
  const activeTeachingLevelId = useSelector(getActiveTeachingLevel);
  const hasActiveSubjectId = useSelector(activeSubjectId);
  const selectedFlashcards =  useSelector(getSelectedTreeNodes).toJS()
  const teachingLevelSelectorParentIds = hasActiveSubjectId === activeTeachingLevelId ? [] : [hasActiveSubjectId];
  const hasSelectedFlashcards = selectedFlashcards.length > 0;
  const canSubmit = hasSelectedFlashcards && exerciseTitle;
  const { isScrolled, bindScrollRef } = useScrollDetection({ WrappedRef: wrapperRef, threshold1: 20 });

  useEffect(() =>{
    if(exerciseId){
      dispatch(getExerciseData(exerciseId));
    }
  }, [])

  useEffect(() => {
    dispatch(getAllTopicsInSubject({
      key: hasActiveSubjectId,
      subjectId: hasActiveSubjectId,
      mapAnswers: true
    }));
  },[dispatch, hasActiveSubjectId])


  const onTreeScrollTo = (element: any) => {
    const scrollRef = wrapperRef;
    const elementOffsetTop = scrollRef.current?.scrollTop + element.getBoundingClientRect().top;
    if (scrollRef.current) {
      scrollRef.current.scrollTop = elementOffsetTop - scrollRef.current?.offsetHeight / 2;
    }
  }
  const onPreviewClick = (flashcardId: number) => {
    dispatch(setActiveFlashcardAction(flashcardId));
    dispatch(scrollToFlashcardAction(flashcardId));
    if (hasMobile) {
      dispatch(toggleNavigationSection(false));
    }
  }
  
  const formSubmit =() =>{
    formRef.current?.handleSubmit();
  }
  return <>
    <NavigationHeader 
      inverted={true} 
      isCollapsed={isScrolled} 
      text={null}
      leftSection={<BackButton inverted hideArrow linkTo={exerciseId ? `/flashcard/desk/exercise/${exerciseId}` : '/flashcard/desk/exercises'}>Cancel</BackButton>}
      rightSection={<div className='cursor-pointer z-[1px]' onClick={formSubmit}>
        <div className={classNames('exercise-save-btn opacity-100', {'opacity-40 ':!canSubmit})} >{exerciseId ? 'Save' : 'Add'}</div>
      </div>}
      extendedHeight  
      bottomSection={<></>}
    />
    <div className={classNames('fxied-spacing ', { '!overflow-hidden': showOverlay })} ref={bindScrollRef}>
      <Overlay overlay={showOverlay} />
      {/* @ts-ignore */}
      <ExerciseForm ref={formRef}  initialValues={hasExerciseData} updateTitle={setExerciseTitle}
        exerciseId={exerciseId} />

      <div className='pt-4 pb-0.5' >
        <div className='bg-#E1E6E6 rounded-[1px] h-[1px]' />
      </div>
      <MircoFilterLayout className='mt-3.5' />
      <div className='relative bg-white items-center mt-6 w-full'>
        <div className='flex items-center pl-2'>
          <Checkbox id={activeTeachingLevelId} parentIds={teachingLevelSelectorParentIds} />
          <Text className='text-13px font-normal tracking-2px leading-18px uppercase text-blue-normal'>Select All</Text>
          <ToggleMenuButtonContainer>
            {(data: any) => (
              <div className='round-button exercise-round-btn' onClick={data.onClick}>
                <img src={cardIcon} alt="" />
              </div>
            )}
          </ToggleMenuButtonContainer>
        </div>
      </div>
      <div className='px-3.5 pt-[27px]'>
        <TopicsTreeContainer
          subjectId={hasActiveSubjectId}
          hasSelection={true}
          showAnswers={true}
          onPreviewClick={onPreviewClick}
          scrollTo={onTreeScrollTo}
          isExercise={true}
        />
      </div>

    </div>
    <SelectedCardsButtonWrapper isIframe={false} />
  </>
}


export default CreateExercisePage;