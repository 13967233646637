import { useDispatch, useSelector } from 'react-redux';
import { deleteConfirmationMode, isDeskEditModeActive, listOfCheckedItems } from 'selectors';
import FooterDeleteMode from 'v2/components/footer-edit-mode/FooterDeleteMode';
import { deselectAllItems, toggleDeleteConfirmationMode } from 'actions/desk';
import { toggleDeskEditMode, toggleFlashcardNavigationVisibility } from 'actions/navigation';
import FooterEditMode from 'v2/components/footer-edit-mode/FooterEditMode';


type FooterEditModeContainerProps = {
    itemsCount: number,
    onDelete: (arg: any) => void,
    onSelectAll: () => void,
    folderEditMode?: any
}

const FooterEditModeContainer: React.FC<FooterEditModeContainerProps> = ({ itemsCount, onDelete, onSelectAll, folderEditMode }) => {
    const dispatch = useDispatch()
    const hasDeleteConfirmationMode = useSelector(deleteConfirmationMode);
    const hasListOfCheckedItems = useSelector(listOfCheckedItems).toJS()
    const hasDeskEditModeActive = useSelector(isDeskEditModeActive)
    if (!hasDeskEditModeActive || !(itemsCount >= 1)) return null;

    if (hasDeleteConfirmationMode) {
        return <FooterDeleteMode onClick={() => {
            dispatch(toggleDeleteConfirmationMode(false));
            dispatch(toggleDeskEditMode(false));
            dispatch(toggleFlashcardNavigationVisibility(true));
            dispatch(onDelete(hasListOfCheckedItems));
        }} />;
    }
    return <FooterEditMode
        hasDeselect={hasListOfCheckedItems.length === itemsCount}
        canDelete={hasListOfCheckedItems.length > 0}
        deselectAll={() =>  dispatch(deselectAllItems())}
        selectAll={onSelectAll}
        editMode={folderEditMode}
        // folderEditMode={folderEditMode}
        onDelete={() => dispatch(toggleDeleteConfirmationMode(true))} />;
};
export default FooterEditModeContainer