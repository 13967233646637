import Immutable from 'immutable';

export const getStoreActiveSubject = state => state.getIn(['userStore', 'activeStoreSubject'], null);
export const getPackagesDescriptionProps = state => state.getIn(['userStore', 'packagesDescriptionProps'], Immutable.List());

export const itemsInBasket = (state) => state.getIn(['userStore', 'itemsInBasket'], Immutable.List());
export const isBasketDetailsVisible = (state) => state.getIn(['userStore', 'isBasketDetailsVisible']);
export const isPaymentView = (state) => state.getIn(['userStore', 'isPaymentView'], false);

export const storeItemById = (state, id) => {
  const storeItems = state.getIn(['api', 'storeItems'], []);
  return storeItems.filter(item => item.id === parseInt(id))[0];
};
