import React from "react"
import './exercise.scss'
type ExerciseBulletProps ={

}

const ExerciseBullet: React.FC<ExerciseBulletProps> = () => {
  return <div className="exercise-bullet" />
}

export default ExerciseBullet