import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { activeSubjectId } from 'selectors/subject/subjectSelector';
import { isMobile } from 'selectors/browser/browserSelector';
import { exercisePreviewsResponse } from 'selectors/api/apiSelector';
import { setActiveFlashcardTab, setActiveMediaTab } from 'actions/navigation';
import { useHistory, useParams } from 'react-router-dom';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import RouterLink from 'router/Link';
import BackToSearchButton from 'v2/ui-components/BackToSearchButton';
import BackButton from 'v2/components/navigation-header/BackButton';
import ExerciseHeaderContainer from 'v2/components/exercise/ExerciseHeaderContainer';
import MircoFilterLayout from 'v2/components/studying/microFilterContainer/MicroFilterLayout';
import ExercisePreviewsContainer from 'v2/components/exercise/ExercisePreviewsContainer';
import usePrevious from 'hooks/usePrevious';
import useScrollDetection from 'hooks/useScrollDetection';

type ParamsType = {
  id:string;
}

const ExercisePage = () => {
  const history = useHistory();
  let wrapperRef = useRef<HTMLDivElement>(null);
  const { id } = useParams<ParamsType>();
  const dispatch = useDispatch();
  const hasMobile = useSelector(isMobile);
  const subjectId = useSelector(activeSubjectId);
  const exercisePreviews = useSelector(exercisePreviewsResponse).toJS();
  const prevSubjectId = usePrevious(subjectId);
  const exerciseLoading = exercisePreviews[parseInt(id)]?.succeeded || false;
  const { isScrolled, bindScrollRef } = useScrollDetection({ WrappedRef: wrapperRef, threshold1: 20 });

  useEffect(() => {
    dispatch(setActiveFlashcardTab(4))
    if (hasMobile) window.scrollTo(0, 0);
    dispatch(setActiveMediaTab('flashcard'));
  },[])

  useEffect(() => {
    if (prevSubjectId && prevSubjectId !== subjectId) {
      history.push('/flashcard/desk/exercises');
      dispatch(setActiveMediaTab('flashcard'));
    }
  },[subjectId, prevSubjectId])

  return <>
    <NavigationHeader
      isCollapsed={isScrolled}
      extendedWidth
      leftSection={
        <BackToSearchButton alternate={() =>
          <BackButton linkTo={'/flashcard/desk/exercises'}>
            Exercises
          </BackButton>
        } />
      }
      rightSection={
        <RouterLink to={`/flashcard/desk/exercise/edit/${id}`}>
          <div className='edit-btn-exercise-page font-bold text-13px leading-18px text-right tracking-2px uppercase text-blue-normal'>EDIT</div>
        </RouterLink>
      }
    />
    <div className='pt-14 pb-[150px] h-full overflow-auto' ref={bindScrollRef}>
      <div className='px-5'>
        <ExerciseHeaderContainer exerciseId={parseInt(id)}/>
        {exerciseLoading && <MircoFilterLayout page="exercise" className='mt-3.5'/>}
        <ExercisePreviewsContainer exerciseId={parseInt(id)} type={'internal'} />
      </div>
    </div>
  </>
}

export default ExercisePage