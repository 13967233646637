import {setActiveNodesAction} from 'actions/topics-tree/topicsTreeActions';
import AnswerBulletContainer from 'v2/components/AnswerBullet/AnswerBulletContainer';
import {CheckboxWrapper} from 'components/topics-tree/TopicsTreeContainer';
import {Map} from 'immutable';
import {PreviewWrapper} from 'pages/exercises/MBCreateExercisePage';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getTreeSectionValue} from 'selectors/topicsTreeSelector';
import Checkbox from 'ui-components/Checkbox';
import PreviewListItem from 'ui-components/search-results/PreviewListItem';
import * as Selectors from 'selectors';
import {getLastMediaTypeVisited, getFormExams} from "helpers"
class TreePreviewContainer extends PureComponent {
  render() {
    const {isActive, preview, setActiveNodes, onClick, parentIds, showAnswers, hasSelection,activeSubjectId,userId} = this.props;
    const flashcardId = preview.get('flashcardId');
    const mediaType = getLastMediaTypeVisited(userId)
    const examsMode = mediaType === 'exams' && getFormExams() === 'exams';
    return (
      <PreviewWrapper data-preview-active={isActive} key={flashcardId} active={isActive}>
        {hasSelection && <CheckboxWrapper><Checkbox id={flashcardId} parentIds={parentIds}/></CheckboxWrapper>}
        <PreviewListItem
          active={!examsMode && isActive}
          key={flashcardId}
          flashcardNumber={preview.get('numbering')}
          preview={preview.getIn(['preview', 'fullText'])}
          rightSection={showAnswers && <AnswerBulletContainer id={flashcardId}/>}
          onClick={e => {
            e.stopPropagation();
            setActiveNodes(flashcardId, parentIds);
            onClick(flashcardId);
          }}
          activeMath={activeSubjectId === 15075 || activeSubjectId  === 14982}
        />
      </PreviewWrapper>
    );
  }
}

TreePreviewContainer.propTypes = {
  parentIds: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  preview: PropTypes.instanceOf(Map),
  showAnswers: PropTypes.bool,
  hasSelection: PropTypes.bool,

  // Connect
  isActive: PropTypes.bool.isRequired,
  setActiveNodes: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  isActive: getTreeSectionValue(state, props.preview.get('flashcardId'), props.parentIds, 'active'),
  activeSubjectId: Selectors.activeSubjectId(state),
  userId : Selectors.getUserId(state)
});
const mapDispatchToProps = {
  setActiveNodes: setActiveNodesAction
};
export default connect(mapStateToProps, mapDispatchToProps)(TreePreviewContainer);
