import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {examsofFilterItems} from 'selectors/desk/deskSelector'
import {isMCQOverlayVisible, isMainMicroFilterVisible} from 'selectors/navigation/navigationSelector'
import {toggleMCQOverlay, toggleMainMicroOverlay, mainMicroFilterBack} from 'actions/navigation/navigationActions'
import {setExamFilterData} from 'actions/api/apiActions'
import {updateExamsPaperFilterItems} from 'actions/desk/deskActions'
import Text from 'v2/components/common/Text'
import { ArrowdownIcon, FilterClearIcon } from 'icons/HeaderIcons';
import '../filter.scss'
import classNames from 'classnames'

type MCQFilterLayoutProps = {
    formCC: boolean;
    isEditMode?: boolean;
}  

const MCQFilterLayout: React.FC<MCQFilterLayoutProps> = ({formCC, isEditMode}) => {
    const dispatch = useDispatch();
    const examFilterItems = useSelector(examsofFilterItems)?.toJS();
    const hasMCQ = useSelector(isMCQOverlayVisible)
    const hasMainMicroFilter = useSelector(isMainMicroFilterVisible)

    const assesmentIcon = (value: Array<string>) => {
        let val = '';
        if (value.includes("Not yet answered")) {
            val = 'Not ye...'
        } else if (value.includes("Incorrect")) {
            val = 'Incorrect'
        } else if (value.includes("Correct")) {
            val = 'Correct'
        }
        switch (val) {
            case 'Incorrect':
                return <Text className='mcq-text-hover text-13px font-bold text-blue-normal leading-18px py-[9px] pl-4 pr-1'>{val}</Text>
            case 'Correct':
                return <Text className='mcq-text-hover text-13px font-bold text-blue-normal leading-18px py-[9px] pl-4 pr-1'>{val}</Text>
            default:
                return <Text className='mcq-text-hover text-13px font-bold text-blue-normal leading-18px py-[9px] pl-4 pr-1'>{val}</Text>
        }
    }
    const selectMCQFilter = (e: any) => {
        e.stopPropagation();
        dispatch(toggleMCQOverlay(true, true));
        setFormCCPopoup()
        if(hasMainMicroFilter) return dispatch(toggleMainMicroOverlay(false,false))
    }
    const setFormCCPopoup = () => {
        if(formCC){ 
            dispatch(mainMicroFilterBack(true, true));
        }else{
            dispatch(mainMicroFilterBack(false, false));
        }
    }
    const clearFilter = (e: any, val: string) => {
        e.stopPropagation();
        dispatch(updateExamsPaperFilterItems([], val));
        dispatch(setExamFilterData());
    }

    return <>
      {examFilterItems['mcq'].length > 0 && examFilterItems['mcq'].length < 3 ? 
        <div className='relative'>
            <div className={classNames('mcq-filter-layout active bg-#E6EEF7 mcq-text-hover text-13px font-bold leading-[18px] pr-1 ', {'opacity-50 pointer-events-none select-none' : isEditMode})} onClick={(e) => selectMCQFilter(e)}>
                {assesmentIcon(examFilterItems['mcq'])}
                {examFilterItems['mcq'].length > 1 && 
                    <div className='text-blue-normal'>+ {examFilterItems['mcq'].length - 1}</div>
                }
                <div className="closeButton1 h-9 w-9 flex rounded-full items-center justify-center shadow-[none] text-blue-normal"> </div>
            </div>
            <div className={classNames('mcq-filter-clear-btn', {'opacity-50 pointer-events-none select-none' : isEditMode})} onClick={(e) => clearFilter(e, 'mcq')}><FilterClearIcon /></div>
        </div> 
      : 
        <div className='relative w-fit'>
            <div className={classNames('mcq-filter-layout', {'opacity-50 pointer-events-none select-none' : isEditMode}, {'mcq-filter-not' : !hasMCQ})} onClick={(e) => selectMCQFilter(e)}>
                <Text className='mcq-text-hover text-13px font-bold text-#2D3838 leading-18px py-[9px] pl-4'>MCQ answer</Text>
                <ArrowdownIcon />
            </div>
        </div> 
      }
      <div className='pl-2' />
    </>
}

export default MCQFilterLayout;