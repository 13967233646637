import {
  getAllTopicsInSubject,
  getTopicsDetails,
  getPreviewsListByType,
  getRecentSearches,
  getSubtopicsInTopic,
  search,
  searchFlashcards,
  searchFolders,
  searchNotes,
  getFlashcardIdsRoutine,
  getClassContentRoutine,
  getExternalExercisesRoutine,
  // getExternalAvatarRoutine,
  getExercisePreviewsRoutine,
  getViewedExercisesRoutine,
  getAllExternalExercisesRoutine,
  getExerciseAnswersRoutine,
  getFlashcardAnswersRoutine,
  searchExercisesRoutine,
  addMarkAsReadRoutine,
  getMBLastSyncRoutine, addFlashcardToFolderRoutine, removeFlashcardFromFolderRoutine, createExerciseRoutine,
  addFlashcardToBookmarkRoutine, removeFlashcardFromBookmarkRoutine,
  getCardsTitleByType,
  getFirstQuestionIdBySubject,
  getExamPreviewsListByType
} from 'actions/api/apiActions';
import Immutable from 'immutable';
import * as Types from 'actions/api/apiTypes';
import {applyReducers} from 'reducers';
import produce from "immer";
import * as DeskActionTypes from 'actions/desk/types';
import appHistory from 'appHistory';
import { getUniqueExamPreviewData, getUniqueTopicPreview } from 'helpers';
const getPath = (key, property) => key ? [key, property] : [property];
const getPathV2 = (key, property) => key ? [key][property] : [property];

// export const apiReducer = (routine, initialState = Immutable.Map()) => (state = initialState, action) => {
//   const key = action.payload && (action.payload.key || action.payload.id);
//   switch (action.type) {
//     case routine.TRIGGER:
//       //TODO set data to null on trigger
//       return state
//         .setIn(getPath(key, 'loading'), true)
//         .setIn(getPath(key, 'succeeded'), false)
//         .setIn(getPath(key, 'error'), null);
//     case routine.SUCCESS:
//       return state
//         .setIn(getPath(key, 'data'), Immutable.fromJS(action.payload.response))
//         .setIn(getPath(key, 'succeeded'), true);
//     case routine.FAILURE:
//       return state
//         .setIn(getPath(key, 'succeeded'), false)
//         .setIn(getPath(key, 'error'), action.payload.error);
//     case routine.FULFILL:
//       return state
//         .setIn(getPath(key, 'loading'), false);
//     default:
//       return state;
//   }
// };

export const apiReducer = (routine, initialState = Immutable.Map(), options = {}) => (state = initialState, action) => {
  let useImmerjs = options.useImmerjs || false;
  
  const key = action.payload && (action.payload.key || action.payload.id);
  if(useImmerjs){
    switch (action.type) {
      case routine.TRIGGER:
        return produce(state, draftState => {
          draftState[key].loading = true;
          draftState[key].succeeded = false;
          draftState[key].error = null;
        })
      case routine.SUCCESS:
        return produce(state, draftState => {
          draftState[key].data = action.payload.response.data;
          draftState[key].succeeded = true;
          
        })
      case routine.FAILURE:
        return produce(state, draftState => {
          draftState[key].succeeded = true;
          draftState[key].error = action.error; 
        })
      case routine.FULFILL:
        return produce(state, draftState => {
          draftState[key].loading = false;
        })
        
      default:
        return state;
    }
  } else {
    switch (action.type) {
      case routine.TRIGGER:
        return state
          .setIn(getPath(key, 'loading'), true)
          .setIn(getPath(key, 'succeeded'), false)
          .setIn(getPath(key, 'error'), null);
      case routine.SUCCESS:
        return state
          .setIn(getPath(key, 'data'), Immutable.fromJS(action.payload.response))
          .setIn(getPath(key, 'succeeded'), true);
      case routine.FAILURE:
        return state
          .setIn(getPath(key, 'succeeded'), false)
          .setIn(getPath(key, 'error'), action.error);
      case routine.FULFILL:
        return state
          .setIn(getPath(key, 'loading'), false);
      default:
        return state;
    }
  }
};

// export const paginationApiReducer = (routine, continuous = false, initialState = Immutable.Map()) => (state = initialState, action) => {
//   const key = action.payload && (action.payload.key || action.payload.id);
//   switch (action.type) {
//     case routine.TRIGGER: {
//       //TODO set data to null on trigger
//       if (!action.payload) {
//         action.payload = {};
//       }

//       if (!(action.payload.page >= 0)) {
//         throw new Error('Page parameter is missing!');
//       }

//       const isFirstPage = action.payload.page === 0;
//       if (isFirstPage)
//         state = state.setIn(getPath(key, 'data'), Immutable.List());

//       return state
//         .setIn(getPath(key, 'loading'), true)
//         .setIn(getPath(key, 'succeeded'), false)
//         .setIn(getPath(key, 'error'), null)
//         .setIn(getPath(key, 'page'), action.payload.page);
//       }
//     case routine.SUCCESS: {
//       const {items, count} = action.payload.response;
//       const immutableItems = Immutable.fromJS(items);

//       return state
//         .updateIn(getPath(key, 'data'), oldData => continuous ? oldData.concat(immutableItems) : immutableItems)
//         .setIn(getPath(key, 'succeeded'), true)
//         .setIn(getPath(key, 'count'), count);
//     }
//     case routine.FAILURE:
//       return state
//         .setIn(getPath(key, 'succeeded'), false)
//         .setIn(getPath(key, 'error'), action.error);

//     case routine.FULFILL:
//       return state
//         .setIn(getPath(key, 'loading'), false);

//     default:
//       return state;
//   }
// };
export const paginationApiReducer = (routine, continuous = false, initialState = Immutable.Map(), options = {}) => (state = initialState, action) => {
  let useImmerjs = options.useImmerjs || false;
  const key = action.payload && (action.payload.key || action.payload.id);
  if(useImmerjs){
    switch (action.type) {
      case routine.TRIGGER:
        if (!action.payload) {
          action.payload = {};
        }
  
        if (!(action.payload.page >= 0)) {
          throw new Error('Page parameter is missing!');
        }
  
        const isFirstPage = action.payload.page === 0;
        if (isFirstPage)
          state = produce(state, draftState => {
            draftState[getPathV2(key, 'data')] = [];
          })
       
        return produce(state, draftState => {
            draftState[getPathV2(key, 'page')] = action.payload.page;
            draftState[getPathV2(key, 'loading')] = true;
            draftState[getPathV2(key, 'succeeded')] = false;
            draftState[getPathV2(key, 'error')] = null;
          })
      case routine.SUCCESS:
        const {items, count} = action.payload.response;
        const immutableItems = items;
        return produce(state, draftState => {
          // draftState[getPath(key, 'data')] = oldData => continuous ? oldData.concat(immutableItems) : immutableItems;
          draftState[getPathV2(key, 'data')] = continuous ? draftState[getPathV2(key, 'data')].concat(immutableItems) : immutableItems;
          draftState[getPathV2(key, 'total')] = count;
          draftState[getPathV2(key, 'succeeded')] = true;
        })
      case routine.FAILURE:
        return produce(state, draftState => {
          draftState[getPathV2(key, 'succeeded')] = false;
          draftState[getPathV2(key, 'error')] = action.error;
        })
      case routine.FULFILL:
        return produce(state, draftState => {
          draftState[getPathV2(key, 'loading')] = false;
        })
      default:
        return state;
    }
  } else {
    switch (action.type) {
      case routine.TRIGGER:
        if (!action.payload) {
          action.payload = {};
        }
  
        if (!(action.payload.page >= 0)) {
          throw new Error('Page parameter is missing!');
        }
  
        const isFirstPage = action.payload.page === 0;
        if (isFirstPage)
          state = state.setIn(getPath(key, 'data'), Immutable.List());
  
        return state
          .setIn(getPath(key, 'loading'), true)
          .setIn(getPath(key, 'succeeded'), false)
          .setIn(getPath(key, 'error'), null)
          .setIn(getPath(key, 'page'), action.payload.page);
  
      case routine.SUCCESS:
        const {items, total} = action.payload.response;
        const immutableItems = Immutable.fromJS(items);
  
        return state
          .updateIn(getPath(key, 'data'), oldData => continuous ? oldData.concat(immutableItems) : immutableItems)
          .setIn(getPath(key, 'succeeded'), true)
          .setIn(getPath(key, 'total'), total);
  
      case routine.FAILURE:
        return state
          .setIn(getPath(key, 'succeeded'), false)
          .setIn(getPath(key, 'error'), action.error);
  
      case routine.FULFILL:
        return state
          .setIn(getPath(key, 'loading'), false);
  
      default:
        return state;
    }
  }
  
};

const authenticate = apiRequest(
  Types.AUTHENTICATE,
  Types.AUTHENTICATE_SUCCEEDED,
  Types.AUTHENTICATE_FAILED,
  {useImmerjs: true}
);

const user = apiRequest(
  Types.GET_USER,
  Types.GET_USER_SUCCEEDED,
  Types.GET_USER_FAILED,
);

const partners = apiRequest(
  Types.GET_PARTNERS,
  Types.GET_PARTNERS_SUCCEEDED,
  Types.GET_PARTNERS_FAILED
);

const getProfile = apiRequest(
  Types.GET_PROFILE,
  Types.GET_PROFILE_SUCCEEDED,
  Types.GET_PROFILE_FAILED,
  {useImmerjs: true}
);

const saveProfile = apiRequest(
  Types.SAVE_PROFILE,
  Types.SAVE_PROFILE_SUCCEEDED,
  Types.SAVE_PROFILE_FAILED
);

const contactDetails = apiRequest(
  Types.GET_CONTACT_DETAILS,
  Types.GET_CONTACT_DETAILS_SUCCEEDED,
  Types.GET_CONTACT_DETAILS_FAILED,
  {useImmerjs: true}
);

const saveContactDetails = apiRequest(
  Types.SAVE_CONTACT_DETAILS,
  Types.SAVE_CONTACT_DETAILS_SUCCEEDED,
  Types.SAVE_CONTACT_DETAILS_FAILED
);

const changePassword = apiRequest(
  Types.CHANGE_PASSWORD,
  Types.CHANGE_PASSWORD_SUCCEEDED,
  Types.CHANGE_PASSWORD_FAILED
);

const changeUsername = apiRequest(
  Types.CHANGE_USERNAME,
  Types.CHANGE_USERNAME_SUCCEEDED,
  Types.CHANGE_USERNAME_FAILED
);

const confirmEmailAddress = apiRequest(
  Types.CONFIRM_EMAIL_ADDRESS,
  Types.CONFIRM_EMAIL_ADDRESS_SUCCEEDED,
  Types.CONFIRM_EMAIL_ADDRESS_FAILED
);

const studentPackages = apiRequest(
  Types.GET_STUDENT_PACKAGES,
  Types.GET_STUDENT_PACKAGES_SUCCEEDED,
  Types.GET_STUDENT_PACKAGES_FAILED,
  {useImmerjs: true}
);

const addVoucher = apiRequest(
  Types.ADD_VOUCHER,
  Types.ADD_VOUCHER_SUCCEEDED,
  Types.ADD_VOUCHER_FAILED
);

const subjects = apiRequest(
  Types.GET_SUBJECTS,
  Types.GET_SUBJECTS_SUCCEEDED,
  Types.GET_SUBJECTS_FAILED,
  {useImmerjs: true}
);

const exams= apiRequest(
  Types.GET_EXAMS,
  Types.GET_EXAMS_SUCCEEDED,
  Types.GET_EXAMS_FAILED,
  {useImmerjs: true}
);

const examPreview = apiRequest(
  Types.GET_EXAM_PREVIEW,
  Types.GET_EXAM_PREVIEW_SUCCEEDED,
  Types.GET_EXAM_PREVIEW_FAILED,
  {useImmerjs: true}
)

const flashcardContent = apiRequest(
  Types.GET_FLASHCARD_CONTENT,
  Types.GET_FLASHCARD_CONTENT_SUCCEEDED,
  Types.GET_FLASHCARD_CONTENT_FAILED
);

const revisionLink = apiRequest(
  Types.SET_REVISION_LINK,
  Types.SET_REVISION_LINK_SUCCEEDED,
  Types.SET_REVISION_LINK_FAILED
);

const trialInfo = apiRequest(
  Types.GET_TRIAL_INFO,
  Types.GET_TRIAL_INFO_SUCCEEDED,
  Types.GET_TRIAL_INFO_FAILED
);

const answers = apiRequest(
  Types.GET_ANSWERS,
  Types.GET_ANSWERS_SUCCEEDED,
  Types.GET_ANSWERS_FAILED,
);

const marks = apiRequest(
  Types.SET_MARKS,
  Types.SET_MARKS_SUCCEEDED,
  Types.SET_MARKS_FAILED,
);

const videoPlayed = apiRequest(
  Types.SET_VIDEO_PLAYED,
  Types.SET_VIDEO_PLAYED_SUCCEEDED,
  Types.SET_VIDEO_PLAYED_FAILED,
);


const flashcardFolders = apiRequest(
  DeskActionTypes.GET_FOLDERS_FOR_FLASHCARD,
  DeskActionTypes.GET_FOLDERS_FOR_FLASHCARD_SUCCEEDED,
  DeskActionTypes.GET_FOLDERS_FOR_FLASHCARD_FAILED
);

const flashcardBookmarks = apiRequest(
  DeskActionTypes.GET_BOOKMARKS_FOR_FLASHCARD,
  DeskActionTypes.GET_BOOKMARKS_FOR_FLASHCARD_SUCCEEDED,
  DeskActionTypes.GET_BOOKMARKS_FOR_FLASHCARD_FAILED
);

const devices = apiRequest(
  Types.GET_LOGEEDINDEVICE,
  Types.GET_LOGEEDINDEVICE_SUCCEEDED,
  Types.GET_LOGEEDINDEVICE_FAILED,
  {useImmerjs: true}
);

const logoutdevices = apiRequest(
  Types.LOGOUTDEVICE,
  Types.LOGOUTDEVICE_SUCCEEDED,
  Types.LOGOUTDEVICE_FAILED
);

const examFilterDataBySubject = apiRequest(
  Types.SET_EXAM_FILTER_DATA,
  Types.SET_EXAM_FILTER_DATA_SUCCEEDED,
  Types.SET_EXAM_FILTER_DATA_FAILED
);
// const links = apiRequest(
//   Types.GET_FLASHCARD_LINKS,
//   Types.GET_FLASHCARD_LINKS_SUCCEEDED,
//   Types.GET_FLASHCARD_LINKS_FAILED
// );

const examHeader = apiRequest(
  Types.GET_EXAM_HEADER,
  Types.GET_EXAM_HEADER_SUCCEEDED,
  Types.GET_EXAM_HEADER_FAILED,
  {useImmerjs: true}
)

const feature = apiRequest(
  Types.GET_FEATURE,
  Types.GET_FEATURE_SUCCEEDED,
  Types.GET_FEATURE_FAILED,
  {useImmerjs: true}
);

const progressPopupOverlayNext = apiRequest(
  Types.PROGRESS_POPUP_OVERLAY_NEXT,
  Types.SET_PROGRESS_POPUP_OVERLAY_NEXT_SUCCEEDED,
  Types.SET_PROGRESS_POPUP_OVERLAY_NEXT_FAILED,
  {useImmerjs: true}
)

const progressPopupOverlayPrevious = apiRequest(
  Types.PROGRESS_POPUP_OVERLAY_PREVIOUS,
  Types.SET_PROGRESS_POPUP_OVERLAY_PREVIOUS_SUCCEEDED,
  Types.SET_PROGRESS_POPUP_OVERLAY_PREVIOUS_FAILED,
  {useImmerjs: true}
)
const subjectStatusSaved = apiRequest(
  Types.SET_QUICK_STATUS,
  Types.SET_QUICK_STATUS_SUCCEEDED,
  Types.SET_QUICK_STATUS_FAILED
)
const getQuestionCount = apiRequest(
  Types.GET_QUESTION_COUNT,
  Types.GET_QUESTION_COUNT_SUCCEEDED,
  Types.GET_QUESTION_COUNT_FAILED
)

const getExternalAvatar = apiRequestByKey(
  Types.GET_EXTERNAL_AVATAR,
  Types.GET_EXTERNAL_AVATAR_SUCCEEDED,
  Types.GET_EXTERNAL_AVATAR_FAILED,
)

const internalExercises = apiRequestByKey(
  Types.GET_INTERNAL_EXERCISES,
  Types.GET_INTERNAL_EXERCISES_SUCCEEDED,
  Types.GET_INTERNAL_EXERCISES_FAILED,
);

const exerciseDetails = apiRequestByKey(
  Types.GET_EXERCISE_DETAILS,
  Types.GET_EXERCISE_DETAILS_SUCCEEDED,
  Types.GET_EXERCISE_DETAILS_FAILED,
);

let urlId;
let path;
const apiReducerFunction = (state = Immutable.Map({authenticate:{}}), action) => {
  switch (action.type) {
    case Types.REFRESH_TOKEN:
      return state.set('authenticate', { ...state.get('authenticate'), isRefreshingToken: true})
    case Types.REFRESH_TOKEN_ENDED:
      return state.set('authenticate', { ...state.get('authenticate'), isRefreshingToken: false})
    case Types.CLEAR_API_REQUEST_DATA:
      return state.setIn([action.requestName, 'data'], null);
    case Types.UPDATE_MARK_VALUE_OF_TITLES_BY_FLASHCARD_ID: {
      var listOfTitles = state.getIn(['API/GET_CARDS_TITLE_BY_TYPE', 'data', 'items']);
      let indexOfItem = listOfTitles.toJS().findIndex(x => x.nodeId == action.cardId);
      return state.setIn(['API/GET_CARDS_TITLE_BY_TYPE', 'data', 'items', indexOfItem, 'value'], action.value);
    }
    case Types.UPDATE_VIDEO_PROGRESS_VALUE_OF_TITLES_BY_FLASHCARD_ID: {
      let res = action.data[0]
      var listOfTitles = state.getIn(['API/GET_CARDS_TITLE_BY_TYPE', 'data', 'items']);
      let indexOfItem = listOfTitles.toJS().findIndex(val => val.nodeId == res.cardId);
      let itemPath = ['API/GET_CARDS_TITLE_BY_TYPE', 'data', 'items', indexOfItem]
      let tempState = state.setIn([...itemPath, "value"], res.markValue).setIn([...itemPath, "totalWatchedInSeconds"],res.currentTime);
      return tempState
    }
    case Types.SET_EXAM_PREVIEW_LIST_BY_TOPIC_ID: {
        let examData =  state.get('examPreviewListByTopicId') || [];
        let finalExamData;
        if(examData){
          //finalExamData = [...examData, action.data.response];
          finalExamData = examData.concat(action.data.response);
        }else{
          finalExamData = getUniqueTopicPreview(action.data.response);
        }
        
        finalExamData = getUniqueExamPreviewData(finalExamData)
        
        return state.set('examPreviewListByTopicId', finalExamData);
    }
    case Types.UPDATE_EXAM_PREVIEW_LIST_BY_TOPIC_ID:{
      return state.set('examPreviewListByTopicId', []);
    }
    case Types.GET_ALL_TOPICS_ID: {
      return state.set('topicIdsByTeachingLevel', action.data);
    }
    // case Types.UPDATE_ANSWER_BY_FLASHCARD_ID_PREVIEW:
    // path = action.data.pathname;
    // if(path.indexOf("subtopic") !== -1){
    //   urlId = path.split('/')[3];
    //   return state.setIn(['GET_PREVIEWS_LIST_BY_TYPE', urlId, 'data', action.data.flashcardIndex , 'answers'], action.data.answer);
    // }else if(path.indexOf("topic") !== -1 && path.indexOf("topics") === -1){
    //   urlId = path.split('/')[3];
    //   return state.setIn(['GET_PREVIEWS_LIST_BY_TYPE', urlId, 'data', action.data.flashcardIndex , 'answers'], action.data.answer);
    // }
    default:
      return applyReducers(state, action, {
        user: user,
        partners: partners,
        authenticate: authenticate,
        getProfile: getProfile,
        saveProfile: saveProfile,
        contactDetails: contactDetails,
        changePassword: changePassword,
        changeUsername: changeUsername,
        saveContactDetails: saveContactDetails,
        confirmEmailAddress: confirmEmailAddress,
        studentPackages: studentPackages,
        voucher: addVoucher,
        subjects: subjects,
        flashcardContent: flashcardContent,
        revisionLink:revisionLink,
        trialInfo: trialInfo,
        answers: answers,
        marks: marks,
        videoPlayed: videoPlayed,
        devices: devices,
        logoutdevices: logoutdevices,
        flashcardFolders,
        flashcardBookmarks,
        exams:exams,
        examPreview:examPreview,
        examHeader:examHeader,
        [getRecentSearches]: apiReducer(getRecentSearches),
        [search]: apiReducer(search),
        [searchFlashcards]: paginationApiReducer(searchFlashcards, true),
        [searchNotes]: paginationApiReducer(searchNotes, true, {}, {useImmerjs:true}),
        [searchFolders]: paginationApiReducer(searchFolders, true),
        [getFlashcardIdsRoutine]: apiReducer(getFlashcardIdsRoutine),
        allTopics: mergedTopicsApiReducer(),
        [getSubtopicsInTopic]: apiReducer(getSubtopicsInTopic),
        [getPreviewsListByType]: apiReducer(getPreviewsListByType),
        [getClassContentRoutine]: apiReducer(getClassContentRoutine),
        [getExternalExercisesRoutine]: apiReducer(getExternalExercisesRoutine),
        // [getExternalAvatarRoutine]: apiReducer(getExternalAvatarRoutine),
        [getTopicsDetails]: apiReducer(getTopicsDetails),
        [getExercisePreviewsRoutine]: apiReducer(getExercisePreviewsRoutine),
        [getViewedExercisesRoutine]: apiReducer(getViewedExercisesRoutine),
        [getAllExternalExercisesRoutine]: apiReducer(getAllExternalExercisesRoutine),
        [getExerciseAnswersRoutine]: apiReducer(getExerciseAnswersRoutine),
        [searchExercisesRoutine]: paginationApiReducer(searchExercisesRoutine),
        [getFlashcardAnswersRoutine]: apiReducer(getFlashcardAnswersRoutine),
        [getMBLastSyncRoutine]: apiReducer(getMBLastSyncRoutine),
        [addFlashcardToFolderRoutine]: apiReducer(addFlashcardToFolderRoutine),
        [addFlashcardToBookmarkRoutine]: apiReducer(addFlashcardToBookmarkRoutine),
        [addMarkAsReadRoutine]: apiReducer(addMarkAsReadRoutine),
        [removeFlashcardFromFolderRoutine]: apiReducer(removeFlashcardFromFolderRoutine),
        [removeFlashcardFromBookmarkRoutine]: apiReducer(removeFlashcardFromBookmarkRoutine),
        [createExerciseRoutine]: apiReducer(createExerciseRoutine),
        [getCardsTitleByType]: apiReducer(getCardsTitleByType),
        [getFirstQuestionIdBySubject]: apiReducer(getFirstQuestionIdBySubject),
        feature:feature,
        progressPopupOverlayNext: progressPopupOverlayNext,
        progressPopupOverlayPrevious: progressPopupOverlayPrevious,
        subjectStatusSaved: subjectStatusSaved,
        examFilterDataBySubject:examFilterDataBySubject,
        getQuestionCount:getQuestionCount,
        getExternalAvatar:getExternalAvatar,
        internalExercises,
        exerciseDetails
      });
  }
};
export default apiReducerFunction;

// export function apiRequest(request, succeeded, failed) {
//   return (state = Immutable.Map(), action) => {
//     switch (action.type) {
//       case request:
//         //TODO set data to null when request begins
//         state = state
//           .set('inProgress', true)
//           .set('succeeded', null)
//           .set('error', null)
//           .set('paginated', false);
//         if (action.paginated && action.page >= 0) {
//           state = state
//             .set('page', action.page)
//             .set('paginated', true);
//           if (action.page === 0) {
//             state = state
//               .set('data', Immutable.List())
//               .set('hasMore', true);
//           }
//         }
//         return state;
//       case succeeded: {
//         const responseData = action.data ? Immutable.fromJS(action.data.data) : null;
//         state = state
//           .set('inProgress', false)
//           .set('succeeded', true)
//           .set('error', null);

//         if (state.get('paginated')) {
//           if (responseData.size === 0 && state.get('page') !== 0) {
//             state = state.set('hasMore', false);
//           }
//           state = state.update('data', data => data.concat(responseData));
//         } else {
//           state = state.set('data', responseData);
//         }
//         return state;
//       }
//       case failed:
//         return state
//           .set('inProgress', false)
//           .set('succeeded', false)
//           .set('error', action.error)
//           .delete('data');
//       default:
//         return state;
//     }
//   };
// }
export function apiRequest(request, succeeded, failed, options = {}) {
  if(options.useImmerjs){
    return (state = {}, action) => {
      let newState;
      switch (action.type) {
        case request:
          //TODO set data to null when request begins
          newState = produce(state, draftState => {
            draftState.inProgress = true;
            draftState.succeeded = false;
            draftState.error = null;
            draftState.paginated = false;
          })
          if (action.paginated && action.page >= 0) {
            state = produce(state, draftState => {
              draftState.page = action.page;
              draftState.paginated = true;
            })
            if (action.page === 0) {
              state = produce(state, draftState => {
                draftState.data = [];
                draftState.hasMore = true;
              })
            }
          }
          return newState;
        case succeeded:
          //const responseData = action.data ? Immutable.fromJS(action.data.data) : null;
          const responseData = action.data ? action.data.data : null;
          newState = produce(state, draftState => {
            draftState.inProgress = false;
            draftState.succeeded = true;
            draftState.error = null;
          })
          if (newState.paginated) {
            if (responseData.size === 0 && newState.page !== 0) {
              //state = state.set('hasMore', false);
              newState = produce(newState, draftState => {
                draftState.hasMore = false;
              })
            }
            newState = produce(newState, draftState => {
              draftState.data.concat(responseData);
            })
            // state = state.update('data', data => data.concat(responseData));
          } else {
            //state = state.set('data', responseData);
            newState = produce(newState, draftState => {
              draftState.data = responseData;
            })
          }
          return newState;
        
        case failed:
          return produce(state, draftState => {
            draftState.inProgress = false;
            draftState.succeeded = false;
            draftState.error = action.error;
            delete draftState?.data;
          })
        default:
          return state;
      }
    };
  } else {

    return (state = Immutable.Map(), action) => {
      switch (action.type) {
        case request:
          //TODO set data to null when request begins
          state = state
            .set('inProgress', true)
            .set('succeeded', null)
            .set('error', null)
            .set('paginated', false);
          if (action.paginated && action.page >= 0) {
            state = state
              .set('page', action.page)
              .set('paginated', true);
            if (action.page === 0) {
              state = state
                .set('data', Immutable.List())
                .set('hasMore', true);
            }
          }
          return state;
        case succeeded: {
          const responseData = action.data ? Immutable.fromJS(action.data.data) : null;
          state = state
            .set('inProgress', false)
            .set('succeeded', true)
            .set('error', null);
  
          if (state.get('paginated')) {
            if (responseData.size === 0 && state.get('page') !== 0) {
              state = state.set('hasMore', false);
            }
            state = state.update('data', data => data.concat(responseData));
          } else {
            state = state.set('data', responseData);
          }
          return state;
        }
        case failed:
          return state
            .set('inProgress', false)
            .set('succeeded', false)
            .set('error', action.error)
            .delete('data');
        default:
          return state;
      }
    };
  }
}
const mergedTopicsApiReducer = (initialState = Immutable.Map()) => (state = initialState, action) => {
  const key = action.payload && (action.payload.key || action.payload.id);
  switch (action.type) {
    case getAllTopicsInSubject.TRIGGER:
      return state
        .setIn(getPath(key, 'loading'), true)
        .setIn(getPath(key, 'succeeded'), false)
        .setIn(getPath(key, 'error'), null);
    case getAllTopicsInSubject.SUCCESS:
      return state
        .setIn(getPath(key, 'data'), Immutable.fromJS(action.payload.response))
        .setIn(getPath(key, 'succeeded'), true);
    case getAllTopicsInSubject.FAILURE:
      return state
        .setIn(getPath(key, 'succeeded'), false)
        .setIn(getPath(key, 'error'), action.error);
    case getAllTopicsInSubject.FULFILL:
      return state
        .setIn(getPath(key, 'loading'), false);
    default:
      return state;
  }
};

/**
 * 
 * @param {*} request 
 * @param {*} succeeded 
 * @param {*} failed 
 * @returns 
 */
export function apiRequestByKey(request, succeeded, failed) {
  return (state = {}, action) => {
    const key = action.payload?.key || action.payload?.id
    let newState = {}
     switch (action.type) {
      case request:
        newState = produce(state, draftState => {
          draftState[key] = {
            ...draftState[key],
            inProgress: true,
            succeeded: false,
            error: null,
            paginated: false
          }
        })
        return newState;
      case succeeded:
        newState = produce(state, draftState => {
          draftState[key] = {
            ...draftState[key],
            inProgress: false,
            succeeded: true,
            error: null,
            ...(action?.payload?.response && {data: action?.payload?.response})
          }
        })
        return newState;
      case failed:
        newState = produce(state, draftState => {
          draftState[key] = {
            ...draftState[key],
            inProgress: false,
            succeeded: false,
            error: action.payload.error,
          }
        })
        return newState;
      default:
        return state;
    }
  }
} 
