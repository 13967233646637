import React, {Component} from 'react';
import {ListTypeHeader} from 'ui-components/search-results/PreviewListItem';
import PropTypes from 'prop-types';

class SearchResultsHeader extends Component {
  render() {
    const {show, title, icon, showMore, total, onClick} = this.props;

    if(!show) return null;
   
    return <ListTypeHeader
      title={title}
      icon={icon}
      showMore={showMore}
      total={total}
      onClick={onClick}
    />;
  }
}

SearchResultsHeader.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  showMore: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SearchResultsHeader;