import classNames from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MarkText from 'ui-components/MarkText';
import {activeSearchTerm, activeSearchResult} from 'selectors/searchSelectors'
import {currentFlashcardId} from 'selectors/studying'
import {windowWidth} from 'selectors/browser/browserSelector'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactPlayer from 'react-player'
import { isTablet, isTouchDevice, isiPhone} from 'helpers';
import { ResizeObserver } from 'resize-observer';
import Player from "@vimeo/player"
import {videoProgressById} from 'selectors/videoProgressSelectors';
import {watchedById} from 'selectors/answersSelectors';
import screenfull from "screenfull";
import {getActiveSubject} from 'selectors';
import {setVideoPlayed, setVideoPlayedStatus} from 'actions/api/apiActions'
import {setWatchedStatus} from 'actions/studying'
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {SpeedIcon, FullscreenIcon, VolumeIcon, PlayIcon, ExitFullScreenIcon, MuteIcon, PauseIcon, RestartIcon} from 'icons/FlashcardIcons';
import Loader, {BackgroundEnum, SizeEnum} from 'v2/components/loader.v2/Loader.v2';
import './videoPlayer.scss'
import 'react-loading-skeleton/dist/skeleton.css';

type VimeoPlayerProps = {
  id: string| number;
  videoId: string | number;
  videoContent: any;
  mediaType: string;
  hasNavSectionVisible: boolean;
  url: string;
  isMobile: boolean;
  isLandscape: boolean;
  isPortrait: boolean;
  hasSubjectManagerVisible: boolean;
}  
const formatedTime = (seconds: number) => {
    if (isNaN(seconds)) {
        return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
        return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
};
const VimeoPlayer: React.FC<VimeoPlayerProps> = ({id, videoId, videoContent, mediaType, hasNavSectionVisible, url, isMobile, isLandscape, isPortrait, hasSubjectManagerVisible}) => {
    const dispatch = useDispatch()
    const [skeletonShow, setSkeletonShow] = useState(true);
    const [fullscreen, setfullscreen] = useState(false);
    const [vWidth, setWidth] = useState(0);
    const [videoReady, setVideoReady] = useState(false);
    const [reStart, setReStart]= useState(false)
    const [resume, setResume] = useState(false);
    const [played, setPlayed] = useState<any>(null);
    const [loaded, setLoaded] = useState(0);
    const [isLoading , setIsLoading ]= useState(true);
    const [isSeekDrag , setSeekDrag ]= useState(true);
    const [bufferLoading, setBufferLoading] = useState(false);
    const [controlOverlay, setControlOverlay] = useState(true);
    const [hidePlayer, setHidePlayer] = useState(false);
    const videoPlayerRef = useRef<any>(null);
    const playerRef = useRef<any>(null);
    const mainVimeoPlayerRef = useRef<any>(null);
    const controlOverlayTimerRef = useRef<any>(null);
    const searchTerm = useSelector(activeSearchTerm)
    const currentId = useSelector(currentFlashcardId)
    const windowWidthVal = useSelector(windowWidth)
    const activeSubject = useSelector(getActiveSubject)
    const watchedStatus =  useSelector((state) => watchedById(state, id))
    const videoProgress =  useSelector((state) => videoProgressById(state, id))
    const searchResult = useSelector(activeSearchResult)
    let videoCurrentTime = videoProgress?.get('currentTime')
    let totalDurationInSeconds  = videoContent?.get('totalDurationInSeconds')
    const [currentTimeInState , setCurrentTimeInState ]= useState(
        playerRef && playerRef.current && playerRef.current.getCurrentTime()>0 ?
        parseInt(playerRef.current.getCurrentTime()) : Number(videoCurrentTime)
    );
    const [height, setHeight] = useState(0);
    const [elapsedTimeInState , setElapsedTimeInState ]= useState(formatedTime(Number(videoProgress?.get('currentTime'))));
 
    const [state, setState] = useState({
        playing: false,
        muted: false,
        playbackRate: 1,
        seeking: false,
        isHover: false,
    });
    const {
        playing,
        muted,
        playbackRate,
        isHover,
    } = state;
    const totalDuration = videoContent.get('totalDuration');
    const currentSearchResult = searchResult;
    let markSelectedIndex: any;
    let watchTime;
    useEffect(() => {
        if(videoReady){
            if(isTablet() || isiPhone()){
                let iframe = videoPlayerRef.current?.querySelector('iframe');
                if(iframe){
                    mainVimeoPlayerRef.current = new Player(iframe);
                    mainVimeoPlayerRef.current.on('fullscreenchange', function(data: any) {
                        if(!data.fullscreen){
                            setfullscreen(false)
                            if(isiPhone()){ 
                                setState({...state, playing: false});
                            }
                        } else {
                            setfullscreen(true);
                            if(isiPhone()){
                                setState({ ...state, playing: true });
                            }
                        }                        
                    });
                }
            }
        }
    },[videoReady]);  

    useEffect(()=>{
        document.addEventListener('fullscreenchange', () => {
            if (!document.fullscreenElement && !document.fullscreenElement) {
                if(!isTouchDevice()){
                setfullscreen(false)
                }
            }
        });
    })
    useEffect(() => {
        if (!videoPlayerRef) return ;
        const baseTabContent = videoPlayerRef && videoPlayerRef.current;
        let resizeObserver = new ResizeObserver(() => {
            handleHeight();
        });
        resizeObserver.observe(baseTabContent);
        return () => {
            resizeObserver.unobserve(baseTabContent);
        };
    }, [isLandscape, isPortrait, windowWidthVal, fullscreen]);

    useEffect(() => { 
        if(controlOverlayTimerRef.current){
            clearTimeout(controlOverlayTimerRef.current)
        }
        if(playing){
            controlOverlayTimerRef.current = setTimeout(() => {
                setControlOverlay(false)
            }, 2000);
            return () => {
                clearTimeout(controlOverlayTimerRef.current);
            }
        }else{
            setControlOverlay(true)
        }
    },[playing, controlOverlay, controlOverlayTimerRef.current])

    useEffect(()=>{
        setCurrentTimeInState(playerRef && playerRef.current && playerRef.current.getCurrentTime()>0
        ? parseInt(playerRef.current.getCurrentTime())
        : Number(videoCurrentTime))
        setElapsedTimeInState(formatedTime(currentTimeInState))
    },[playerRef.current && playerRef.current.getCurrentTime(), currentTimeInState])

    const toggleFullScreen = (e: any) => {   	
        e.stopPropagation();
        if(isiPhone()){
            mainVimeoPlayerRef.current.play();
            if(!playing){
                mainVimeoPlayerRef.current.setCurrentTime(Number(videoProgress?.get('currentTime'))).then(function(seconds: number) {
                    mainVimeoPlayerRef.current.pause()
                }).catch(function(error: any) {
                    console.log(error)
                });
            }
            mainVimeoPlayerRef.current.play();
            mainVimeoPlayerRef.current.requestFullscreen().then(function() {
                setfullscreen(true)
                // TODO: recheck on vimeo ticket on fullscreenchange bug
                const interval = setInterval(() => {
                    mainVimeoPlayerRef.current.getFullscreen().then(function(localFullscreen: any) {
                        if(!localFullscreen){                            
                            setfullscreen(false)
                            setState({...state, playing: false})
                            clearInterval(interval);
                        }
                    }).catch(function(error: any) {
                        clearInterval(interval)  
                    });
                }, 1000);
            }).catch(function(error: any) {
                console.log(error)
            });                   
        }else{
            screenfull.toggle(videoPlayerRef.current);
            setfullscreen(!fullscreen);
        }  
    };

    const handleHeight = () => {
        let videoWidth = videoPlayerRef && videoPlayerRef.current && videoPlayerRef.current.clientWidth;
            let videoHeight, videoWidths, vWidth;
            if(isMobile && isLandscape && !fullscreen){
                videoWidth = videoPlayerRef && videoPlayerRef.current && videoPlayerRef.current.clientWidth;
                videoHeight = window.innerHeight - 135;
                videoWidths = videoHeight * (16/9);
                if(videoWidth > videoWidths){
                    setWidth(videoWidth)
                }else{
                    setWidth(videoWidths)
                }
            }else{
                if(isMobile){
                    videoHeight = videoWidth && videoWidth * (9/16);
                }else{
                    videoHeight = videoWidth && videoWidth * (9/16);
                }
                const vWidths = videoHeight * (16/9);
                if(videoWidth > vWidths){
                    setWidth(videoWidth)
                }else{
                    setWidth(vWidths)
                }
            }
            if(activeSubject?.slug == "maths-a-amp-i" || activeSubject?.slug === "maths-a-amp-a"){
                if(videoWidth < 1595){
                    setHeight(videoHeight+(videoHeight*11.5/100));
                }else{
                    setHeight(videoHeight);
                }
            }else{
                setHeight(videoHeight);
            }
            if(isMobile && isLandscape){
                setHeight(window.innerHeight);
            }
            if(videoPlayerRef.current){
                videoPlayerRef.current.style.height = videoPlayerRef ? videoHeight+"px" : "0";
                if(isMobile && isLandscape && !fullscreen){
                videoPlayerRef.current.style.width = videoPlayerRef ? vWidth+"px" : "0";
                }
            }
    }
    const handlePlayPause = (e: any) => {	
        e.stopPropagation();
        if(reStart){    
        setPlayed(1)
        setLoaded(1)
        setSeekDrag(false)
        setElapsedTimeInState(formatedTime(1))
        setState({ ...state, playing: true });  
        playerRef.current.seekTo(1 , 'seconds');    
        const timeout = setTimeout(() => {
            setState({ ...state,playing : true })
            setPlayed(1)
            setLoaded(1)   
            setElapsedTimeInState(formatedTime(1))       
            dispatch(setVideoPlayed({id: videoId, totalTime: totalDurationInSeconds, markValue: 0, lastPlayDuration: 1}));
            dispatch(setWatchedStatus({id: videoId, played: 0}))
            playerRef.current?.seekTo(1 , 'seconds');
            setReStart(false)  
            }, 10)
        return () => {
            clearTimeout(timeout)
        }
        }else{
        if(playing){
            setState({ ...state, playing: false });        
        }else{
            setState({ ...state, playing: true });
        } 
        if(Number(videoCurrentTime)>0){
            setResume(true)
        }else{
            setResume(false) 
        }
        setReStart(false)
        setControlOverlay(true)
        }        
    };

    const onChangeSeekRange = (played:number) => {        
        if(!controlOverlay){
            setControlOverlay(true);
        }
        setReStart(false)
        setSeekDrag(true)
        setState({ ...state, seeking:false });
        const playVal = parseFloat(String(played / 100))
        setPlayed(playVal)
        if(played <2){
            playerRef.current.seekTo(0, "seconds");
        }else{
            playerRef.current.seekTo(played / 100, "fraction");
        }  
        if(played == 100){
            setState({ ...state, playing: false });
            setReStart(true)
            setResume(false)
            setPlayed(100)
            setElapsedTimeInState(formatedTime(totalDurationInSeconds))
            playerRef.current.seekTo(100,"fraction");
            dispatch(setWatchedStatus({id: videoId, played: 1}))
            dispatch(setVideoPlayed({id: videoId, totalTime:totalDurationInSeconds, activeTab: 'video', markValue: 1, lastPlayDuration: totalDurationInSeconds}))
        } else{
            if((totalDurationInSeconds - parseInt(String(totalDurationInSeconds*played/100)) < 3.2) && (watchedStatus.get('watchedValue') === 0 || watchedStatus.get('watchedValue') == null)){
                dispatch(setWatchedStatus({id: videoId, played: 1}))
            }
            if((totalDurationInSeconds - parseInt(String(totalDurationInSeconds*played/100)) > 3.2) && (watchedStatus.get('watchedValue') === 1)){
                dispatch(setWatchedStatus({id: videoId, played: 0}))
            }
            setElapsedTimeInState(formatedTime((totalDurationInSeconds*played/100)))
            if(!playing){
                setState({ ...state, playing: false, seeking: false});
                setResume(true)   
                dispatch(setVideoPlayed({id: videoId, totalTime:totalDurationInSeconds, markValue: 0, lastPlayDuration: parseInt(String(totalDurationInSeconds*played/100))}));
            }
            if(played === 1){
                setLoaded(1)
                setElapsedTimeInState(formatedTime(0.1))
                dispatch(setVideoPlayed({id: videoId, totalTime:totalDurationInSeconds, markValue: 0, lastPlayDuration: 0}));
            }
        }    
    }

    const handleProgress = (changeState: any) => {    
        if(changeState.played > 0){
            setLoaded(changeState.loaded)
            if (!state.seeking) {
                setSeekDrag(false)
                setState({ ...state, ...changeState });
                if(isSeekDrag){
                    if(played>0){
                        setPlayed(parseFloat(played))
                    }
                }else{
                    setPlayed(changeState.played)
                }
            }
            if(!isSeekDrag){
                if((totalDurationInSeconds - parseInt(changeState.playedSeconds) < 3.2) && !(watchedStatus.get('watchedValue'))){
                    dispatch(setWatchedStatus({id: videoId, played: 1}))
                }
                if((totalDurationInSeconds - parseInt(changeState.playedSeconds) > 3.2) && (watchedStatus.get('watchedValue') === 1)){
                    dispatch(setWatchedStatus({id: videoId, played: 0}))
                }
            }
            if(playing){                
                watchTime= totalDurationInSeconds-parseInt(playerRef.current.getCurrentTime())
                let markValue= 0;
                if(0 < watchTime && watchTime <4 ){
                    markValue= 1
                }
                if(playerRef.current.getCurrentTime() && playerRef.current.getCurrentTime()>0){
                    dispatch(setVideoPlayed({id: videoId, totalTime:totalDurationInSeconds, markValue: markValue, lastPlayDuration: parseInt(playerRef.current.getCurrentTime())}));
                }
            }
        } 
    };

    useEffect(()=>{
        setIsLoading(true)
        setHidePlayer(true)
        const timeout = setTimeout(() => {
          setHidePlayer(false)
          setState({ ...state, playing: false, playbackRate: 1 , muted : false});
          setElapsedTimeInState(formatedTime(Number(videoProgress.get('currentTime'))))
          setCurrentTimeInState(Number(videoProgress.get('currentTime')))
          setPlayed(parseFloat(String(Number(videoCurrentTime)/totalDurationInSeconds)))
        }, 1)
        if(Number(videoCurrentTime) && Number(videoCurrentTime)>0){
              if(Number(videoCurrentTime) && (Number(videoProgress.get('totalTime'))-Number(videoCurrentTime)) < 3.1){
                  setReStart(true)
                  setResume(false)
                  setState({...state, playing: false});
              }else{
                setResume(true)
                setReStart(false)
                setState({...state, playing: false});
              }            
        }else{
            setResume(false)
            setReStart(false)
        }
      setSkeletonShow(true)
      setPlayed(parseFloat(String(Number(videoCurrentTime)/totalDurationInSeconds)))

      return () => {
          clearTimeout(timeout)
          setfullscreen(false)
          setVideoReady(false)
      }
    },[videoPlayerRef, videoId, totalDurationInSeconds])

  
    const OnStartTime =()=>{
        playerRef.current.seekTo(Number(videoCurrentTime),'seconds');
    }

    const onEnded=() => {
        setState({ ...state, playing: false });
        setReStart(true)
        setResume(false)
        setPlayed(100)
        setElapsedTimeInState(formatedTime(Number(totalDurationInSeconds)))
        dispatch(setVideoPlayed({id: videoId, totalTime:totalDurationInSeconds, activeTab: 'video', markValue: 1, lastPlayDuration: totalDurationInSeconds}));
    }

    function onReady(){
        setSkeletonShow(false)
        setVideoReady(true);
        setIsLoading(false)
        dispatch(setVideoPlayedStatus({id: videoId, playing:false}))
        setState({ ...state, playing: false });
    }

    function onPlay(){
        if(isiPhone()){
            if(!playing){
                setState({ ...state, playing: false });
            } else {
                setState({ ...state, playing: true });
            }
        } else {
            if(!playing){
                setState({ ...state, playing: true });
                const timeout = setTimeout(() => {
                    setState({ ...state, playing: false});
                }, 10)
            } 
        }
    }

    const handleMute = (e: any) => {
         setState({ ...state, muted: !state.muted });
         setControlOverlay(true)
         e.preventDefault();	
         e.stopPropagation();
    };

    const handlePlaybackRate = (e: any, rate: number) => {
        setState({ ...state, playbackRate: rate });	
        setControlOverlay(true)	
        e.preventDefault();	
        e.stopPropagation();
    };
   
    function handleMouseMove () {
       
        if(playing && !isTouchDevice()){           
            setControlOverlay(true)
        }
    };

    function hanldeMouseLeave () {
      
        if(playing && !isTouchDevice()){          
            setControlOverlay(false)
        }
    };

    function handleClick (e: any) {   
        e.preventDefault();	
        e.stopPropagation(); 
        if(playing){
            if(isMobile){
                if(controlOverlay){
                    setControlOverlay(false)
                }else{
                    setControlOverlay(true)
                }  
            }else{
                if(controlOverlay && !isHover){
                    setControlOverlay(false)
                }else{
                    setControlOverlay(true)
                }  
            }
             
        }else{
            setControlOverlay(true)
        }
    };

    function onMouseLeaveHandler() {
        setState({ ...state, isHover: false });
    }

    function onMouseEnterHandler() {
        setState({ ...state, isHover: true });
    }
    useEffect(()=>{
        dispatch(setVideoPlayedStatus({id: videoId, playing: playing}))
            
        if(isMobile && !hasNavSectionVisible || hasSubjectManagerVisible){
            setState({...state, playing:false})
            if(Number(videoCurrentTime)>0){
                if(Number(videoCurrentTime) == Number(videoProgress.get('totalTime'))){
                    setResume(false)
                    setReStart(true)
                }else{
                    setResume(true) 
                }
            }else{
                setResume(false) 
            }
        }else{
            function handleKeyUp(e: any) {
                if(e.code == "Space"){
                    if (['INPUT', 'TEXTAREA'].indexOf(e.target.nodeName) !== -1) return;
                    // props.setVideoPlayedStatus({id: flashcardId, playing:!state.playing})
                    setState({ ...state, playing: !state.playing});
                    if(Number(videoCurrentTime)>0){
                        setResume(true)
                        setReStart(false)
                    }else{
                        setResume(false) 
                        setReStart(false)
                    }
                    setControlOverlay(true)
                }
              }
              window.addEventListener('keyup', handleKeyUp)
            return () => window.removeEventListener("keyup", handleKeyUp);
        }
    },[hasNavSectionVisible, state.playing, hasSubjectManagerVisible])

   
    
   
    if (currentSearchResult && currentId === currentSearchResult?.flashcardId) {
      markSelectedIndex = currentSearchResult?.searchIndex;
    }
    const onBufferStart = () => {
        setBufferLoading(true);
    }
    const onBufferEnd = () => {
        setBufferLoading(false)
    }

    const getLandscapeWidth = () => {
        if(isMobile && isLandscape && windowWidthVal >= 320 &&  windowWidthVal <= 900){
        return `${vWidth}px`;
        }
        return '100%'
    }

    const renderLoader = () => {
        return <>
            <SkeletonTheme baseColor="#4A677E" highlightColor="#4A677E" width={'100%'}>
                <Skeleton className="inner-skeleton-h-video" duration={2} />
            </SkeletonTheme>
            <SkeletonTheme baseColor="#4A677E" highlightColor="#4A677E" height={'100%'} width={'100%'}>
                <Skeleton className="inner-skeleton-d-video" height={height-30} duration={2} />
            </SkeletonTheme>
        </>
    }

    return <div className={classNames('video-main-wrapper', {'video-skeleton': skeletonShow}, {'video-no-skeleton': !skeletonShow})} style={{width: getLandscapeWidth()}}>
        {!skeletonShow && 
            // @ts-ignore
            <MarkText shouldMark={searchTerm} markType={'span'} type={"line-hieght-mark"} selectedIndex={markSelectedIndex} key={`${videoId}`}>
                <div className={classNames('video-title', {'mt-6' : (isMobile && isPortrait)}, {'mt-7': !(isMobile && isPortrait)})} dangerouslySetInnerHTML={{__html: videoContent?.get('id') == currentId ? videoContent?.get('title') : ""}}  />
            </MarkText>
        }
        <div className={classNames('video-player-section relative', {'pb-[50px]': skeletonShow})}>
            <div className={classNames('video-play', {'flex items-center' : fullscreen})} style={{width : (isMobile && isLandscape) ? `${vWidth}px` : 'unset'}} ref={videoPlayerRef} onMouseMove={handleMouseMove} onMouseLeave={hanldeMouseLeave}>
                {skeletonShow && renderLoader()}
                {(!hidePlayer) && 
                    <ReactPlayer 
                        ref={playerRef}
                        onBuffer={onBufferStart}
                        onBufferEnd={onBufferEnd}
                        playsInline
                        width="100%"
                        height={skeletonShow ? "0.01%" : "100%"}
                        controls={false}
                        muted={muted}
                        volume={1}
                        loop={false}
                        playsinline={true}
                        playing={playing}
                        playbackRate={playbackRate}
                        onProgress={handleProgress}
                        className="video-player"
                        url={url}                            
                        config={{
                            vimeo:{
                            playerOptions:{
                                autoplay:false,
                                speed:true,
                                responsive:false,
                                controls:false,                                                                  
                            },                                  
                            }
                        }}
                        onStart={OnStartTime}
                        onEnded={onEnded}
                        onReady={onReady}
                        onPlay={onPlay}
                        />
                }
                { !isLoading  && !skeletonShow && 
                    <div className={classNames('items-center justify-center touch-manipulation', {'flex absolute ' : fullscreen})} style={{width : (vWidth) ? `${vWidth}px` : '100%'}} onClick={handleClick}>
                        <div className={classNames('absolute bg-[#133956] mix-blend-normal opacity-0 z-10 h-full top-0', {'h-full w-full': fullscreen})} style={{width : (vWidth) ? `${vWidth}px` : '0'}}/>
                        <div className={classNames('absolute z-10 h-full top-0 bg-[#133956] mix-blend-normal opacity-75', {'h-full w-full position-unset': fullscreen}, {'visible': (controlOverlay || bufferLoading)}, {'invisible': !(controlOverlay || bufferLoading)})} style={{width : (vWidth) ? `${vWidth}px` : '0'}}>
                            <div className='flex absolute w-full top-0 items-center justify-between z-50 px-5 pt-3.5 pb-0'>
                                <div className='flex items-center justify-between z-999 w-[128px]' onMouseEnter={() => onMouseEnterHandler()} onMouseLeave={() => onMouseLeaveHandler()}>
                                    <SpeedIcon />
                                    {[1, 1.5, 2].map((rate,index) => (
                                        <div key={index} className={classNames('flex items-center justify-center font-normal text-[13px] leading-18px text-white cursor-pointer select-none w-[23px] h-[22px] rounded-md', {'bg-blue-normal' : (rate === playbackRate)}, {'w-[34px]' : (rate === 1.5)})} onClick={(e) => handlePlaybackRate(e, rate) }>{rate}x</div>
                                    ))}
                                </div>
                                <div className='flex items-center cursor-pointer'>
                                    <div className='z-10 cursor-pointer mr-[22px]'  onClick={(e) =>  handleMute(e)}>{muted ? <MuteIcon /> : <VolumeIcon />}</div>  
                                    <div className='z-10 cursor-pointer' onClick={(e) => toggleFullScreen(e)}>{fullscreen ? (isTablet() || isiPhone()) ? <div /> : <ExitFullScreenIcon /> : <FullscreenIcon />}</div>
                                </div>
                            </div>
                            <div className='flex items-center justify-center w-full absolute z-10 -translate-y-2/4 top-2/4'>
                                {bufferLoading ? null:
                                    <div className={classNames('flex items-center justify-center flex-col z-20', { 'w-[44px]' : !reStart, 'w-full' : reStart}, {'h-[66px]' : reStart})} >
                                        <div className='video-play-icon'  onClick={handlePlayPause}>{reStart ? <RestartIcon/> : playing ? <PauseIcon /> : <PlayIcon />}</div>
                                        {resume ?
                                            <div className='video-play-btn'>{playing ? "Pause" : "Resume"}</div>
                                            :
                                            reStart ?
                                                <>
                                                    <div className='video-play-btn'>FROM BEGINNING</div>
                                                    <div className='items-center font-normal text-13px leading-18px mix-blend-normal opacity-60'>This will remove the 'Watched‘ flag</div>
                                                </>
                                                    :
                                                <div className='video-play-btn'>{playing ? "Pause" : "Play"}</div>
                                        }
                                    </div>
                                }
                            </div>
                            <div className='absolute w-full h-[46px] bottom-3 py-0 px-5'>
                                <div className='flex items-center font-normal text-13px leading-18px text-white h-[18px] mb-1'>
                                    <div className='mr-0.5'>{elapsedTimeInState} / </div>
                                    <div className='opacity-60'>{totalDuration}</div>
                                </div>
                                <div className='flex items-center h-[24px] w-full bottom-0' >
                                    <Slider
                                        className={'input-range'}
                                        min={1}
                                        max={100}
                                        value={played*100}
                                        onChange={onChangeSeekRange}
                                        railStyle={{
                                            appearance: 'none',
                                            height: 4,
                                            backgroundColor: "#FFFFFF",
                                            borderRadius: 2,
                                        }}
                                        handleStyle={{
                                            appearance: 'none',
                                            height: 24,
                                            width: 24,
                                            backgroundColor: "#50D3A6",
                                            borderRadius: 12,
                                            border: 0,
                                        }}
                                        trackStyle={{
                                            background: "#50D3A6"
                                        }}
                                    />
                                </div>
                                <div className='relative -ml-px -mt-3.5 h-1 bg-[#B1B4B6] rounded-sm ' style={{width : (loaded * 100) ? `${loaded * 100}%` : '0'}}></div>
                            </div>
                        </div>
                    </div> 
                }
                
            </div>
        </div>
        {(videoContent.get('id') == currentId && videoContent.get('content') && mediaType == 'video' && !skeletonShow ) && <><div className='video-description'>{videoContent.get('content')}</div></>}
    </div>
}

export default VimeoPlayer;