import React, { useState } from 'react';
import classNames from 'classnames';
import { AddToFolderIcon } from 'icons/FolderIcons';
import { setActiveMediaTab } from 'actions/navigation';
import { getLastMediaTypeVisited } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from 'selectors/user';
import { currentFlashcardId } from 'selectors/studying';
import { addFlashcardToFolderRoutine, removeFlashcardFromFolderRoutine } from 'actions/api';
import { getFolderAddButtonActive } from 'selectors/navigation/navigationSelector';
import { addFlashcardToFolderResponse, removeFlashcardFromFolderResponse } from 'selectors/api/apiSelector';
import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2'
import { isMobile } from 'selectors/browser/browserSelector';
import { isMobile as isTouchDevice } from 'react-device-detect';

type AddFlashcardToFolderContainerProps = {
  folderId: string;
  flashcardFolders: any;
  addButtonVisible?: number | undefined;
}

const AddFlashcardToFolderContainer: React.FC<AddFlashcardToFolderContainerProps> = ({ addButtonVisible, flashcardFolders, folderId }) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId)
  const currentId = useSelector(currentFlashcardId);
  const folderAddButtonActive = useSelector(getFolderAddButtonActive);
  const addFlashcardToFolder = useSelector(addFlashcardToFolderResponse).toJS();
  const removeFlashcardFromFolder = useSelector(removeFlashcardFromFolderResponse).toJS();
  const hasMobile = useSelector(isMobile);
  const [isHover, setHover] = useState<boolean>(false);
  const mediaType = getLastMediaTypeVisited(userId);

  const isInFolder = (folderId: string) => {
    if (!folderId) return false;
    return flashcardFolders && flashcardFolders.filter((item: any) => item.id == folderId || item.flashcardId == currentId).length > 0;
  }
  const handleMouseEnter = () => {
    if (!hasMobile || !isTouchDevice) {
      setHover(true)
    }
  }
  const onClick = (e: React.MouseEvent<HTMLElement>, folderId: string, addButtonVisible: number | undefined) => {
    if (!currentId) return;
    e.stopPropagation();
    if (addButtonVisible && addButtonVisible < 1) return false;
    dispatch(setActiveMediaTab(mediaType));
    if (isInFolder(folderId)) {
      dispatch(removeFlashcardFromFolderRoutine({ id: folderId, mediaType: mediaType }));
    } else {
      dispatch(addFlashcardToFolderRoutine({ id: folderId, mediaType: mediaType }));
    }
  }
  if (!folderId) return null;
  return <div className={classNames({ 'pointer-events-none': !folderAddButtonActive })} onClick={(e: React.MouseEvent<HTMLElement>) => onClick(e, folderId, addButtonVisible)} onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => setHover(false)}>
      {(addFlashcardToFolder[folderId]?.loading|| removeFlashcardFromFolder[folderId]?.loading) ?
        <Loader size={SizeEnum.Small} background={BackgroundEnum.Blue} /> :
        <>
          <div className={classNames('block', { 'opacity-50': (addButtonVisible && addButtonVisible < 1), 'cursor-not-allowed': (addButtonVisible && addButtonVisible < 1 || !folderAddButtonActive) })}>
            {currentId && !isInFolder(folderId) && (<AddToFolderIcon activButton={!folderAddButtonActive} />)}
            {currentId && isInFolder(folderId) && (<AddToFolderIcon activButton={!folderAddButtonActive} state='added' isHover={isHover} />)}
            {!currentId && isInFolder(folderId) && (<AddToFolderIcon activButton={!folderAddButtonActive} state='active' />)}
          </div>
        </>
      }
    </div>
}

export default AddFlashcardToFolderContainer