import classNames from 'classnames';
import React from 'react';
import {DeleteTrashIcon} from 'icons/FlashcardNavIcons';
import './footer.scss';


type FooterDeleteModeProps = {
    contained?: boolean;
    currentId?: number;
    onClick: () => void;
}

const FooterDeleteMode: React.FC<FooterDeleteModeProps> = ({contained, currentId, onClick}) => {
  return (
    <div className={classNames('footer-delete-mode', {'contained' : contained})} onClick={onClick}>
        <div className='delete-btn'><DeleteTrashIcon /></div>
    </div>
  )
}

export default FooterDeleteMode;