import Immutable from 'immutable';
import answersReducer from 'reducers/answersReducer';
import marksReducer from 'reducers/marksReducer';
import watchedReducer from 'reducers/watchedReducer';
import examAnswersReducer from 'reducers/examAnswersReducer';
import statusReducer from 'reducers/statusReducer';
import videoProgressReducer from 'reducers/videoProgressReducer';
import quickReducer from 'reducers/quickReducer';
import revisionReducer from 'reducers/revisionReducer';
import carouselReducer from 'reducers/carouselReducer';
import exercisesReducer from 'reducers/exercisesReducer';
import menuReducer from 'reducers/menuReducer';
import onboardingReducer from 'reducers/onboardingReducer';
import searchReducer from 'reducers/searchReducer';
import topicsTreeReducer from 'reducers/topicsTreeReducer';
import exercisesTopicTreeReducer from 'reducers/exercisesTopicTreeReducer';
import profileReducer from './profile';
import * as Types from 'actions/types';
import apiReducer from './api/apiReducer';
import userReducer from './user/userReducer';
import topicReducer from './topic/topicReducer';
import studyingReducer from './studyingReducer';
import subjectReducer from './subject/subjectReducer';
import browserReducer from './browser/browserReducer';
import markingsReducer from 'reducers/markingsReducer';
import {reducer as formReducer} from 'redux-form/immutable';
import userStoreReducer from './user-store/userStoreReducer';
import navigationReducer from './navigation/navigationReducer';
import notificationReducer from './notification/notificationReducer';
import deskReducer from './desk/deskReducer';
import examStatusReducer from './examStatusReducer';


const appReducer = combineReducers({
  api: apiReducer,
  form: formReducer,
  user: userReducer,
  desk: deskReducer,
  topics: topicReducer,
  browser: browserReducer,
  profile: profileReducer,
  subject: subjectReducer,
  studying: studyingReducer,
  markings: markingsReducer,
  userStore: userStoreReducer,
  navigation: navigationReducer,
  notification: notificationReducer,
  search: searchReducer,
  topicsTree: topicsTreeReducer,
  topicsExericseTree: exercisesTopicTreeReducer,
  carousel: carouselReducer,
  exercises: exercisesReducer,
  answers: answersReducer,
  marks: marksReducer,
  watched: watchedReducer,
  status: statusReducer,
  videoProgress: videoProgressReducer,
  quickStatus: quickReducer,
  menu: menuReducer,
  onboarding: onboardingReducer,
  examStatus: examStatusReducer,
  examAnswers: examAnswersReducer,
});

const rootReducer = (state, action) => {
  if (action.type === Types.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export function combineReducers(reducers) {
  return (state = Immutable.Map(), action) => {
    return applyReducers(state, action, reducers);
  };
}

export function applyReducers(state, action, reducers) {
  const reducerKeys = Object.keys(reducers);
  return state.withMutations(temporaryState => {
    reducerKeys.forEach(domainName => {
      const reducer = reducers[domainName];
      const currentDomainState = temporaryState.get(domainName);
      const newDomainState = reducer(currentDomainState, action);

      validateState(newDomainState, domainName, action);
      temporaryState.set(domainName, newDomainState);
    });
  });
}

const validateState = (nextState, reducerName, action) => {
  if (nextState === undefined) {
    throw new Error('Reducer "' + reducerName + '" returned undefined when handling "' + action.type + '" action. To ignore an action, you must explicitly return the previous state.');
  }
};

export default rootReducer;
