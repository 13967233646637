import React from 'react';
import ExternalExerciseTile from 'v2/components/exercise/ExternalExerciseTile';
import InternalExerciseTile from 'v2/components/exercise/InternalExerciseTile';
import { useDispatch, useSelector } from 'react-redux';
import { activeExerciseId } from 'selectors/studying';
import { hasNotification } from 'selectors/exerciseSelectors';
import { setExerciseAsViewedRoutine } from 'actions/api';

type ExerciseTileProps = {
  exercise: any;
  noPadding?: boolean;
  onClick: (arg: any) => void;
  titleComponent?: any;
  className?: any;
}

const ExerciseTile: React.FC<ExerciseTileProps> = ({ className, exercise, noPadding, onClick, titleComponent }) => {
  const dispatch = useDispatch();
  const getActiveExerciseId = useSelector(activeExerciseId);
  const exerciseId = exercise.exerciseId;
  // @ts-ignore
  const isNotification = useSelector((state) => hasNotification(state, exerciseId));
  const isExternal = exercise.mbTeacherId;

  const onExternalExerciseTileClick = (exercise: any) => {
    const exerciseId = exercise.exerciseId;
    if (isNotification) {
      dispatch(setExerciseAsViewedRoutine({ id: exerciseId }));
    }
    onClick(exercise);
  };

  return isExternal ?
    <ExternalExerciseTile
      isActive={exerciseId === parseInt(getActiveExerciseId)}
      titleComponent={titleComponent}
      onClick={() => onExternalExerciseTileClick(exercise)}
      exercise={exercise}
      hasNotification={isNotification} /> :
    <InternalExerciseTile
      isActive={exerciseId === parseInt(getActiveExerciseId)}
      titleComponent={titleComponent}
      onClick={() => onClick(null)}
      exercise={exercise}
      noPadding={noPadding}
    />;
}

export default ExerciseTile