import React, {memo} from 'react'
import SpeakingAvatarComponent from './SpeakingAvatarComponent'
import swal from 'sweetalert';
import './notifications.scss'


const DeviceLimitNotification = () => {
    return <div className='swal-notification clear-margin'>
       <SpeakingAvatarComponent showAvatar='police'>
            <div className="modal-tutorial-text swal-container less-padding">\
                <div className="title">Hold your horses!</div>
                <div>
                    It looks like you already have two sessions in progress. Concurrent access is limited to two devices.
                </div>
            </div>
       </SpeakingAvatarComponent>
       <div className="notification-text bold text-center">
          Log out on one device or close your browser now to reset. If you’re away from your devices, it’s no problem.
          The system will end sessions when it detects a period of inactivity. You can try again in a few minutes.
        </div>
        <div className="notification-button" onClick={() => {swal.close();}}>
            OK
        </div>
    </div>
}

export default memo(DeviceLimitNotification);