import React from 'react';
import classNames from 'classnames';
import StatusBar from 'v2/components/status-bar/StatusBar'
import ExamFilterLayout from "v2/components/studying/examFilterContainer/examFilterLayout"

type MediaTypeStatusBarProps = {
    examStatusData: any;
    isMargin?: number;
    type?: string;
    icon?: string,
    isActive?: boolean,
    isSubtopic?: boolean
} 
const ExamMediaTypeStatusBar: React.FC<MediaTypeStatusBarProps> = ({examStatusData = [], isMargin, type, icon, isSubtopic = false, isActive = false}) => {
    // if(examStatusData.length === 0) return null;
    return <div className={classNames('media-status-bar-wrapper', {'mt-4' : !isMargin}, {'mt-0' : isMargin, 'flex py-2 !px-[11px] !mt-0 study-meta-info' : icon, 'px-5 py-2' : type==='quickStart', 'pt-3.5 px-3.5 pb-2' : type !== 'quickStart'})}> 
        {type !=='quickStart' && <ExamFilterLayout />}
        {icon && <img draggable="false" className="mediaTab-image !w-[41px]" src={icon} alt='' /> }
        <div className={classNames({'ml-[3px] w-full [&_svg]:mt-px': icon})}>
        {examStatusData.map(({number, title, mediaStatusData, isRead, mediaType, icons, showLine}: any) => {
            return <React.Fragment key={mediaType+title+number}>
                <StatusBar number={number} title={title} statusData={mediaStatusData} isRead={isRead} mediaType={mediaType} icon={icons}  isSubtopic={isSubtopic} isActive={isActive}/>
                {showLine && <div className='bg-#E1E6E6 h-px rounded-[1px] my-7px mx-0' />}
            </React.Fragment>
        })}
        </div>
    </div>
}
export default ExamMediaTypeStatusBar;