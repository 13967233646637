import React from 'react';

export function AddedToCardIcon(props) {
  return <svg width="28px" height="29px" viewBox="0 0 28 29">
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="linearGradient-1">
        <stop stopColor="white" offset="0%"></stop>
        <stop stopColor="white" offset="100%"></stop>
      </linearGradient>
      <circle id="path-2" cx="10" cy="10" r="9.5"></circle>
      <filter x="-40.8%" y="-43.4%" width="181.6%" height="181.6%" filterUnits="objectBoundingBox" id="filter-3">
        <feOffset dx="0" dy="-0.5" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.580392157   0 0 0 0 0.580392157   0 0 0 0 0.580392157  0 0 0 0.35 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
      </filter>
    </defs>
    <g id="Icons/Profile/Activated/Green" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(4.000000, 5.000000)">
      <g id="Float">
        <g id="Circle">
          <use fill="white" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-2"></use>
          <use fill="white" fillRule="evenodd" xlinkHref="#path-2"></use>
        </g>
        <g id="Check" transform="translate(5.000000, 6.000000)">
          <polyline stroke="#2EBCB4" strokeWidth="1.3" points="0.63999939 4.3788183 3.26499939 7.0038183 9.26499939 1.0038183"></polyline>
          <circle id="Oval" fill="#2EBCB4" cx="9.29999959" cy="0.969999671" r="1"></circle>
          <circle id="Oval-Copy" fill="#2EBCB4" cx="0.690000892" cy="4.43000066" r="1"></circle>
        </g>
      </g>
    </g>
  </svg>;
}
export function ItemPurchasedAndValid(props) {
  return <svg width="28px" height="29px" viewBox="0 0 28 29">
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="linearGradient-1">
        <stop stopColor="#2EBCB4" offset="0%"></stop>
        <stop stopColor="#2EBCB4" offset="100%"></stop>
      </linearGradient>
      <circle id="path-2" cx="10" cy="10" r="9.5"></circle>
      <filter x="-40.8%" y="-43.4%" width="181.6%" height="181.6%" filterUnits="objectBoundingBox" id="filter-3">
        <feOffset dx="0" dy="-0.5" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.580392157   0 0 0 0 0.580392157   0 0 0 0 0.580392157  0 0 0 0.35 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
      </filter>
    </defs>
    <g id="Icons/Profile/Activated/Green" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(4.000000, 5.000000)">
      <g id="Float">
        <g id="Circle">
          <use fill="#2EBCB4" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-2"></use>
          <use fill="#2EBCB4" fillRule="evenodd" xlinkHref="#path-2"></use>
        </g>
        <g id="Check" transform="translate(5.000000, 6.000000)">
          <polyline stroke="white" strokeWidth="1.3" points="0.63999939 4.3788183 3.26499939 7.0038183 9.26499939 1.0038183"></polyline>
          <circle id="Oval" fill="white" cx="9.29999959" cy="0.969999671" r="1"></circle>
          <circle id="Oval-Copy" fill="white" cx="0.690000892" cy="4.43000066" r="1"></circle>
        </g>
      </g>
    </g>
  </svg>;
}
export function ItemPurchasedAndExpired(props) {
  return <svg width="28px" height="29px" viewBox="0 0 28 29">
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="linearGradient-1">
        <stop stopColor="#fc4b07" offset="0%"></stop>
        <stop stopColor="#fc4b07" offset="100%"></stop>
      </linearGradient>
      <circle id="path-2" cx="10" cy="10" r="9.5"></circle>
      <filter x="-40.8%" y="-43.4%" width="181.6%" height="181.6%" filterUnits="objectBoundingBox" id="filter-3">
        <feOffset dx="0" dy="-0.5" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.580392157   0 0 0 0 0.580392157   0 0 0 0 0.580392157  0 0 0 0.35 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
      </filter>
    </defs>
    <g id="Icons/Profile/Activated/Green" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(4.000000, 5.000000)">
      <g id="Float">
        <g id="Circle">
          <use fill="#fc4b07" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-2"></use>
          <use fill="#fc4b07" fillRule="evenodd" xlinkHref="#path-2"></use>
        </g>
        <g id="Check" transform="translate(5.000000, 6.000000)">
          <polyline stroke="white" strokeWidth="1.3" points="0.63999939 4.3788183 3.26499939 7.0038183 9.26499939 1.0038183"></polyline>
          <circle id="Oval" fill="white" cx="9.29999959" cy="0.969999671" r="1"></circle>
          <circle id="Oval-Copy" fill="white" cx="0.690000892" cy="4.43000066" r="1"></circle>
        </g>
      </g>
    </g>
  </svg>;
}

export function CloseStoreModalIcon(props) {
  return (
    <svg {...props} width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Minimal-Store-Version" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Store---Purchased-" transform="translate(-1317.000000, -23.000000)" fill="#FFFFFF">
          <g id="Icons/Header/Delete/White---Solid" transform="translate(1316.000000, 22.000000)">
            <g id="Group-2-Copy" transform="translate(1.600000, 1.600000)">
              <path d="M12.9271488,14.3979309 L8.17539118,19.1496885 C7.76924593,19.5558337 7.76924593,20.2143253 8.17539118,20.6204705 C8.58153643,21.0266158 9.24002798,21.0266158 9.64617323,20.6204705 L14.3979309,15.8687129 L19.1496885,20.6204705 C19.5558337,21.0266158 20.2143253,21.0266158 20.6204705,20.6204705 C21.0266158,20.2143253 21.0266158,19.5558337 20.6204705,19.1496885 L15.8687129,14.3979309 L20.6204705,9.64617323 C21.0266158,9.24002798 21.0266158,8.58153643 20.6204705,8.17539118 C20.2143253,7.76924593 19.5558337,7.76924593 19.1496885,8.17539118 L14.3979309,12.9271488 L9.64617323,8.17539118 C9.24002798,7.76924593 8.58153643,7.76924593 8.17539118,8.17539118 C7.76924593,8.58153643 7.76924593,9.24002798 8.17539118,9.64617323 L12.9271488,14.3979309 Z M28.8,14.4 C28.8,22.3530198 22.3529143,28.8 14.4,28.8 C6.44708571,28.8 0,22.3530198 0,14.4 C0,6.44708571 6.44708571,0 14.4,0 C22.3529143,0 28.8,6.44708571 28.8,14.4 Z" id="Combined-Shape"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
