import * as Actions from 'actions';
import { getExerciseDetails, getFlashcardContent, getSubjects,  } from 'actions/api';
import { GET_EXERCISE_DETAILS_FAILED, GET_EXERCISE_DETAILS_SUCCEEDED, GET_FLASHCARD_CONTENT_SUCCEEDED, GET_SUBJECTS_SUCCEEDED } from 'actions/api/apiTypes';
import { showAlert } from 'actions/notification';
import { registerDevice, setCurrentFlashcard } from 'actions/studying';
import * as Types from 'actions/types';
import { SET_USER_DATA } from 'actions/user/types';
import appHistory from 'appHistory';
import ErrorNotification from 'v2/components/notifications/ErrorNotification';
import ExerciseNotAuthorizedNotification from 'components/notifications/ExerciseNotAuthorizedNotification';
import WarningNotification, {errorReasonsEnum} from 'v2/components/notifications/WarningNotification';
import { getAccessToken, getLastSubjectVisited, removeCredentialsFromLocalStorage, clearDevice,getFormExams } from 'helpers';
import React from 'react';
import { all, put, call, race, select, take, takeLatest } from 'redux-saga/effects';
import {getFlashcardById, getMBIdentity} from 'selectors';
import * as Selectors from 'selectors';
import * as ApiSaga from './api/apiSaga';
import * as ExerciseSaga from './exerciseSaga';
import * as FoldersSaga from './foldersSaga';
import * as NavigationSaga from './navigation/navigationSaga';
import * as NotesSaga from './noteSaga';
import * as NotificationSaga from './notificationSaga';
import * as ProfileSaga from './profile';
import * as SearchSaga from './searchSaga';
import * as StoreSaga from './store/storeSaga';
import * as StudyingSaga from './studying/studyingSaga';
import * as TopicTreeSaga from './topicsTreeSaga';
import * as UserSaga from './user';
import * as OnboardingSaga from './onboardingSaga';
import {getFreeTrialStatusRoutine} from '../actions/onboarding/onboardingActions';
import { getLastSubjectActive, oldNewSubjectMapped, setLastSubjectActive } from 'v2/helpers';


export default function * rootSaga () {
 try {
    yield all([
      takeLatest(Types.INITIALIZE, onInitialize),
      takeLatest(Types.LOGOUT, onLogout),
      takeLatest(Types.Navigation.SET_DOCUMENT_TITLE, onSetTitle),
      takeLatest(Types.DATA_INIT, onInitializeData),
      takeLatest(Types.IFRAME_DATA_INIT, onIFrameInitializeData),
      UserSaga.watchers(),
      ApiSaga.watchers(),
      ProfileSaga.watchers(),
      StudyingSaga.watchers(),
      NavigationSaga.watchers(),
      StoreSaga.watchers(),
      NotificationSaga.watchers(),
      NotesSaga.watchers(),
      FoldersSaga.watchers(),
      SearchSaga.watchers(),
      TopicTreeSaga.watchers(),
      ExerciseSaga.watchers(),
      OnboardingSaga.watchers()
    ]);

  } catch(e) {

    yield(call(handleTokenInvalidGrant, e))
    
  }
}

function * onInitialize () {
  try {
    yield put(Actions.windowResizeListener(window.innerWidth, window.innerHeight));
    const authToken = getAccessToken();
    if (authToken) {
      yield put(Actions.Api.getUser());
      yield put(Actions.Api.getElementStyles());
      if (appHistory.location.pathname === '/') appHistory.push('/flashcard/topics');
    }
  } catch (error) {
    console.error("error for onInitialize", error)
  }
}

function * onLogout () {
  try {
    yield put(Actions.Studying.unRegisterDevice());
    removeCredentialsFromLocalStorage();
    appHistory.push('/login', true);
    yield put(Actions.windowResizeListener(window.innerWidth, window.innerHeight));
  } catch (error) {
    console.error("error for onLogout", error)
  }
}

function onSetTitle (action) {
  document.title = 'OSC Study - ' + action.title;
}

/**
 * Loads subjects and set the active subject and teaching level
 * Active subject can be:
 *   1. The given exercise subject
 *   2. The given flashcards subject
 *   3. The last subject visited
 *   4. The first subject in the list
 * @param {Object} action
 * @param {number} action.flashcardId
 * @param {string} action.exerciseId
 */
function * onInitializeData ({flashcardId, exerciseId, activeMediaTab,examsId}) {
  yield put(registerDevice());
  if (flashcardId) yield put(setCurrentFlashcard(flashcardId));
  
  let userId = yield select(Selectors.getUserId);
  if (!userId) {
    yield take(SET_USER_DATA);
    userId = yield select(Selectors.getUserId);
  }
  yield put(getSubjects());
  yield take(GET_SUBJECTS_SUCCEEDED);
  const subjects = yield select(Selectors.getSubjects);
  // let lastSubjectActive = getLastSubjectActive(userId)
  let subjectId;
  try {

    subjectId = yield exerciseId ? getSubjectIdByExercise(exerciseId) : (flashcardId ? getSubjectIdByFlashcard(flashcardId,activeMediaTab) : getLastSubjectVisited(userId));

  } catch (e) {
    appHistory.push('/flashcard/classroom');
    if (e.error === 'EXERCISE_NOT_FOUND') {
      yield put(showAlert({
        content: <ErrorNotification hasContact={false}
          text={'We\'re sorry but the Exercise you\'re trying to view was deleted by your teacher.'}/>, buttons: false
      }));
    } else if (e.error === 'EXERCISE_FORBIDDEN_TO_USER') {
      yield put(showAlert({
        content: <WarningNotification 
          title={errorReasonsEnum.NotExercise}
          icon={'warningIcon'}
        />, buttons: false
      }));
    }
    else {
      console.error('Error in onInitializeData : ', e)
    }
  }

  const isInTrial = subjects?.length === 1

  let subject = findSubject(subjects, subjectId);

  const [ oldSubjectId ] = isInTrial ? [8832, 8832] : (oldNewSubjectMapped.find((subjects) => subjects.includes(subject?.id)) ?? oldNewSubjectMapped[0])

  setLastSubjectActive(oldSubjectId, userId)

  yield put(Actions.Subject.setActiveSubject(subject));
  yield put(Actions.Api.setQuickStatus(subject.id))
  yield put(Actions.Subject.setActiveTeachingLevel(subject.id, true));

  const mbId = yield select(getMBIdentity);
  if (mbId) yield put(getFreeTrialStatusRoutine({mbId}));
}

function * onIFrameInitializeData (action) {
  try {
    let userId = yield select(Selectors.getUserId);
    if (!userId) {
      yield take(SET_USER_DATA);
      userId = yield select(Selectors.getUserId);
    }
    if (!action.classId) {
      yield put(showAlert({content: <ErrorNotification text={'Class ID was not provided in URL!'}/>, buttons: false}));
      return;
    }

    //TODO make sure registerDevice() is not needed here
    yield put(Actions.Api.getSubjects());

    yield put(Actions.Api.getClassContentRoutine({classId: action.classId}));

    const [classContentOutcome, subjectsOutcome] = yield all([
      take([Actions.Api.getClassContentRoutine.success, Actions.Api.getClassContentRoutine.failure]),
      take([Types.Api.GET_SUBJECTS_SUCCEEDED, Types.Api.GET_SUBJECTS_FAILED]),
    ]);

    if (classContentOutcome.type === Actions.Api.getClassContentRoutine.success.toString() && subjectsOutcome.type === Types.Api.GET_SUBJECTS_SUCCEEDED) {
      const [classContent, subjects] = yield all([
        select(Selectors.classContentResponse),
        select(Selectors.getSubjects)
      ]);

      const classContentSubjectId = classContent.getIn(['data', 'subjectId']);
      const classContentTeachingLevels = classContent.getIn(['data', 'teachingLevelIds']).unshift(classContentSubjectId);

      const [ oldSubjectId ] = (oldNewSubjectMapped.find((subjects) => subjects.includes(classContentSubjectId)) ?? oldNewSubjectMapped[0])

      setLastSubjectActive(oldSubjectId, userId)


      let newActiveSubject = subjects.find(subject => subject.id === classContentSubjectId);
      const activeSubjectChildren = newActiveSubject.children.filter(child => {
        return classContentTeachingLevels.find(teachingLevelId => teachingLevelId === child.id);
      });

      // newActiveSubject = newActiveSubject
      // .set('childrenIds', classContentTeachingLevels)
      // .set('children', activeSubjectChildren);
      newActiveSubject = {...newActiveSubject, 
        childrenIds: classContentTeachingLevels,
        children: activeSubjectChildren,
      }

      yield put(Actions.Subject.setActiveSubject(newActiveSubject));
      yield put(Actions.Subject.setActiveTeachingLevel(classContentSubjectId, true));
    } else {
      let errorText;
      if (classContentOutcome.type === Actions.Api.getClassContentRoutine.failure.toString()) {
        errorText = 'There was a problem retrieving data from ManageBac!';
      }
      yield put(showAlert({content: <ErrorNotification text={errorText}/>, buttons: false}));
    }
  } catch (e) {
    yield put(showAlert({content: <ErrorNotification/>, buttons: false}));
  }
}

/**
 * Find the subject id of a given exercise
 * @param exerciseId
 * @returns {number} ID of the subject
 */
function * getSubjectIdByExercise (exerciseId) {
  // yield put(getExerciseDetailsRoutine({id: exerciseId}));
  yield put(getExerciseDetails({key: exerciseId}));
  const result = yield race({
    success: take(GET_EXERCISE_DETAILS_SUCCEEDED),
    failure: take(GET_EXERCISE_DETAILS_FAILED),
  });

  if (result.success) {
    return result.success.payload.response.subjectId;
  }

  if (result.failure && result.failure.payload && result.failure.payload.error) {
    throw {error: result.failure.payload.error};
  }

  throw {error: 'EXERCISE_NOT_FOUND'};
}
function * getSubjectIdByExams(examsId){
}

/**
 * Find the subject ID of a given flashcard
 * @param flashcardId
 * @returns {number} ID of the subject
 */
function * getSubjectIdByFlashcard (flashcardId, activeMedia) {
  try {
    let activeMediaTab = yield select(Selectors.getActiveMediaTab)
    let activeMediaType
      if(activeMedia){
        activeMediaType=activeMedia      
      }else{
        activeMediaType=activeMediaTab
      }
    let subject ;
    yield put(getFlashcardContent(flashcardId, activeMediaType));
    yield take(GET_FLASHCARD_CONTENT_SUCCEEDED);
    const flashcardContent = yield select(getFlashcardById, flashcardId);
    if(activeMediaType === 'exams'){
      flashcardContent.get('parentsData').map((val)=>{
        const parentsData = val.get('parentsData');
        subject =  parentsData.find(item => item.get('type') === 'SUBJECT');
      })
    }else{
    subject = flashcardContent.get('parentsData').find(item => item.get('type') === 'SUBJECT');
    }
    return subject?.toJS()?.id;
  } catch (error) {
    console.error("error for getSubjectIdByFlashcard", error)
  }
}

/**
 * Find subject by subject id in the given list
 * @param {Immutable.List} subjects - List of subjects
 * @param {number} subjectId
 * @returns {Immutable.Map} The subject found or the first one in the list
 */
function findSubject (subjects, subjectId) {
  try {
    let subject;
    if (subjectId) {
      subject = subjects.find((subject) => {
        return subject.id === subjectId;
      });
    }
    // return subject || subjects[0];
    return subject ||
     subjects?.find((subject) => [47, 8832].includes(subject.id))       // [biology, demo] id
  } catch (error) {
    console.error("error for findSubject", error)
  }
}

function* handleTokenInvalidGrant(e){
  try {
    if(typeof(e) === "object" && e.invalid_grant){
      removeCredentialsFromLocalStorage();
      clearDevice();
      appHistory.push('/login', true);
      yield put(Actions.windowResizeListener(window.innerWidth, window.innerHeight));
      window.location.reload()
    }
  } catch (error) {
    console.error("error for handleTokenInvalidGrant", error)
  }
}