import {createExerciseRoutine, updateExerciseRoutine} from 'actions/api/apiActions';
import FormInputDatepicker from 'components/form-input-datepicker/FormInputDatepicker';
import FormTextareaInput from 'components/form-inputs/FormTextareaInput';
import FormSelect from 'components/form-select/FormSelectComponent';
import {NavigationHeaderExtension} from 'components/navigation-header/NavigationHeader';
import moment from 'moment';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form/immutable';
import {StyledForm, StyledFormInputInverted} from 'ui-components/formComponents';
import {ClickableDiv} from 'ui-components/helpers/common';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';
import Text from 'ui-components/helpers/Text';
import {PagePadding} from 'ui-components/pageComponents';
import {ClearIcon, findFirstFieldWithError, validator, WarnIcon} from 'utils/form-helper';

class ExerciseInput extends Component {
  render() {
    const {input, placeholder, onCancel, hasCancel, meta} = this.props;
    const hasError = meta.error && meta.touched;

    return (<Row alignItems={'center'} justifyContent={'space-between'}>
      <StyledFormInputInverted {...input} placeholder={placeholder}/>

      {hasError && <WarnIcon/>}
      {(input.value !== '' || hasCancel) &&
      <ClickableDiv onClick={onCancel}><ClearIcon type={'transparent'}/></ClickableDiv>}
    </Row>);
  }
}

ExerciseInput.propTypes = {
  input: PropTypes.any,
  placeholder: PropTypes.any
};


class ExerciseFormContainer extends Component {
  onDateChanged = () => {
    const currentValues = this.props.innerRef && this.props.innerRef.current && this.props.innerRef.current.values;

    if (currentValues.get('dueDate') === 'Invalid date') return;
    if (!currentValues.get('hour')) this.props.change('hour', 9);
    if (!currentValues.get('minute')) this.props.change('minute', 0);
  };

  onTimeChanged = () => {
    const currentValues = this.props.innerRef && this.props.innerRef.current && this.props.innerRef.current.values;

    if (currentValues.get('dueDate') === undefined) {
      this.props.change('dueDate', moment().add(1, 'd').format('YYYY-MM-DD'));
    }
  };

  render() {
    const {change, touch} = this.props;
    console.log(this.props.innerRef?.current?.values, "this.props.innerRef.current", this.props.initialValues)
    const datePickerMinDate = moment();

    return (
      <StyledForm onSubmit={this.props.handleSubmit}>
        <NavigationHeaderExtension>
          <Field
            id='title'
            name={'title'}
            placeholder={'Exercise name'}
            type='text'
            component={ExerciseInput}
            onCancel={() => {
              change('title', '');
              touch('title');
            }}
            validate={[validator.required]}
          />
        </NavigationHeaderExtension>
        <PagePadding>
          <FormTextareaInput
            label={'Comment (optional)'}
            name={'comment'}
            placeholder={'Add anything worthwile mentioning'}
            type={'text'}
            change={change}
            touch={touch}
          />

          <Row alignItems={'flex-end'} justifyContent={'space-between'}>
            <FormInputDatepicker
              id={'create-exercise-datepicker'}
              label={'Due date (optional)'}
              name={'dueDate'}
              placeholder={'Select a date'}
              minDate={datePickerMinDate}
              maxDate={null}
              change={change} touch={touch}
              onChange={this.onDateChanged}
              normalize={(value) => {
                if (!value) return;
                const minDate = datePickerMinDate;
                const momentValue = moment(value);

                if (momentValue.isSameOrBefore(minDate)) {
                  return minDate.format('YYYY-MM-DD');
                }
                return momentValue.format('YYYY-MM-DD');
              }}
            />
            <Row alignSelf={'flex-end'} alignItems={'flex-end'}>
              <Padding left={50}/>

              <FormSelect name='hour' placeholder='+' className={'time'} showIcon={false} onChange={this.onTimeChanged}
                change={change} touch={touch}>
                <option value="" selected disabled hidden>09 AM</option>
                <option value='0'>12 AM</option>
                <option value='1'>01 AM</option>
                <option value='2'>02 AM</option>
                <option value='3'>03 AM</option>
                <option value='4'>04 AM</option>
                <option value='5'>05 AM</option>
                <option value='6'>06 AM</option>
                <option value='7'>07 AM</option>
                <option value='8'>08 AM</option>
                <option value='9'>09 AM</option>
                <option value='10'>10 AM</option>
                <option value='11'>11 AM</option>
                <option value='12'>12 PM</option>
                <option value='13'>01 PM</option>
                <option value='14'>02 PM</option>
                <option value='15'>03 PM</option>
                <option value='16'>04 PM</option>
                <option value='17'>05 PM</option>
                <option value='18'>06 PM</option>
                <option value='19'>07 PM</option>
                <option value='20'>08 PM</option>
                <option value='21'>09 PM</option>
                <option value='22'>10 PM</option>
                <option value='23'>11 PM</option>
              </FormSelect>
              <Padding top={22} left={7} right={7}>
                <Text size={15} color={'#2D3838'} letterSpacing={0} lineHeight={'19px'}>:</Text>
              </Padding>
              <FormSelect name='minute' placeholder='+' className={'time small'} showIcon={false}
                onChange={this.onTimeChanged}
                change={change} touch={touch}>
                <option value="" selected disabled hidden>00</option>
                <option value='0'>00</option>
                <option value='5'>05</option>
                <option value='10'>10</option>
                <option value='15'>15</option>
                <option value='20'>20</option>
                <option value='25'>25</option>
                <option value='30'>30</option>
                <option value='35'>35</option>
                <option value='40'>40</option>
                <option value='45'>45</option>
                <option value='50'>50</option>
                <option value='55'>55</option>
              </FormSelect>

            </Row>
          </Row>
        </PagePadding>
        <button type={'submit'} hidden>submit</button>
      </StyledForm>
    );
  }
}

ExerciseFormContainer.propTypes = {
  change: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  innerRef: PropTypes.object.isRequired,
};

export const EXERCISE_FORM_NAME = 'createExercise';

const onSubmit = (data, dispatch, ownProps) => {
  return ownProps.exerciseId ? dispatch(updateExerciseRoutine({
    id: ownProps.exerciseId,
    data: data
  })) : dispatch(createExerciseRoutine(data));
};

export default reduxForm({
  form: EXERCISE_FORM_NAME,
  onSubmit: onSubmit,
  onSubmitFail: (errors) => findFirstFieldWithError(errors)
})(ExerciseFormContainer);
