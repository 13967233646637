import React, {Component} from 'react';
import './ProfilePicture.scss';

export default class ProfilePicture extends Component {
  render() {
    const {isImage} = this.props;

    return isImage ? <img className={this.props.className} src={this.props.source} alt=""/> :
      <div className={this.props.className}>{this.props.source}</div>
      ;
  }
}

ProfilePicture.defaultProps = {
  isImage: true
};
