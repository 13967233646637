import React, { ReactNode } from 'react';
import cardIcon from 'assets/icons/exercise/card-icon.svg';
import dueIcon from 'assets/icons/exercise/due-icon.svg';
import dueIconRed from 'assets/icons/exercise/due-icon-red.svg';

type DueDateProps = {
  children:ReactNode;
  passed?: boolean;
}
export const DueDate:React.FC<DueDateProps> = ({children, passed = false}) => {
  return <div className='whitespace-nowrap flex items-center'>
    <div className='pr-[5px] [&>img]:pb-px'><img src={passed ? dueIconRed : dueIcon} alt=""/></div>
    <div className='text-[13px] leading-4 text-[#979999]'>{children}</div>
  </div>
}

type NrOfCardsProps = {
  children:ReactNode;
}

export const NrOfCards:React.FC<NrOfCardsProps> = ({children}) => {
  return <div className='whitespace-nowrap flex items-center'>
    <div className='pr-[5px] [&>img]:pb-px'><img src={cardIcon} alt=""/></div>
    <div className='text-[13px] leading-4 text-[#979999]'>{children}</div>
  </div>
}