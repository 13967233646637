import React, { useEffect, useRef } from 'react'
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import useScrollDetection from 'hooks/useScrollDetection';
import BackToSearchButton from 'v2/ui-components/BackToSearchButton';
import BackButton from 'v2/components/navigation-header/BackButton';
import CounterNotification from 'v2/components/notifications/CounterNotification';
import { useDispatch, useSelector } from 'react-redux';
import { activeSubjectExerciseNotificationsCount } from 'selectors/exerciseSelectors';
import ExerciseHeaderContainer from 'v2/components/exercise/ExerciseHeaderContainer';
import { useHistory, useParams } from 'react-router-dom';
import ExercisePreviewsContainer from 'v2/components/exercise/ExercisePreviewsContainer';
import { setDocumentTitle } from 'actions/navigation';
import {activeSubjectId} from 'selectors';
import usePrevious from 'hooks/usePrevious';


const MBExercisePage = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const bindScrollRef = useRef<any>();
    const params: any = useParams();
    const subjectId = useSelector(activeSubjectId)
    const prevSubjectId = usePrevious(subjectId)
    const { isScrolled } = useScrollDetection({ WrappedRef: bindScrollRef, threshold1: 20 })
    const exerciseNotificationsCount = useSelector(activeSubjectExerciseNotificationsCount)
    const exerciseId = params?.id;

    useEffect(() => {
        dispatch(setDocumentTitle('Classroom'))
    }, [])
    useEffect(() => {
        if(prevSubjectId !== subjectId){
            history.push('/flashcard/classroom')
        }
    },[subjectId])


    return <>
        <NavigationHeader
            isCollapsed={isScrolled}
            extendedWidth
            leftSection={
                <BackToSearchButton alternate={() =>
                    <BackButton linkTo={'/flashcard/classroom'}>
                        <div className='flex items-center '>
                            <span>Classroom</span>
                            <div className='mr-2' />
                            {exerciseNotificationsCount > 0 && (
                                 <CounterNotification amount={exerciseNotificationsCount}/>
                            )}
                        </div>
                    </BackButton>
                } />
            }
        />  
        <div className='spacing-overlay pt-14 pb-[150px] h-full' ref={bindScrollRef}>
            <div className='py-0 px-5'>
                <ExerciseHeaderContainer exerciseId={exerciseId} type={'external'}/>
                <ExercisePreviewsContainer exerciseId={exerciseId} type={'external'} activeSubjectId={subjectId} />
            </div>
        </div> 
    </>
}

export default MBExercisePage;