import cardIcon from 'assets/icons/exercise/card-icon.svg';
import dueIcon from 'assets/icons/exercise/due-icon.svg';
import dueIconRed from 'assets/icons/exercise/due-icon-red.svg';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';

const ContentText = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #979999;
`;

const NoWrap = styled.div`
  white-space: nowrap;
`;

export const DueDate = ({children, passed = false}) => (
  <NoWrap as={Row} verticalAlign>
    <Padding right={5}>
      <img src={passed ? dueIconRed : dueIcon} alt=""/>
    </Padding>
    <ContentText>
      {children}
    </ContentText>
  </NoWrap>
);
DueDate.propTypes = {
  passed: PropTypes.bool,
  children: PropTypes.node,
};

export const NrOfCards = ({children}) => (
  <NoWrap as={Row} verticalAlign>
    <Padding right={5}>
      <img src={cardIcon} alt=""/>
    </Padding>
    <ContentText>
      {children}
    </ContentText>
  </NoWrap>
);
NrOfCards.propTypes = {
  children: PropTypes.node,
};
