import { useSelector } from "react-redux";
import { getActiveSubject, subjectsTeachingLevel } from "selectors/subject/subjectSelector";
import { examsofFilterItems, flashcardsWithNotes } from "selectors/desk/deskSelector";
import { difficultyFilter, paperFilter, sourceFilter, teachingFilterResultsListToArray, topicTeachingFilter } from "v2/helpers";
import { getActiveMediaTab, isExamsMode } from "selectors/navigation/navigationSelector";

export const useNoteData = () => {
  const getExamsofFilterItems = useSelector(examsofFilterItems);
  const subject = useSelector(getActiveSubject);
  const getSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
  const getFlashcardsWithNotes = useSelector(flashcardsWithNotes)?.toJS();
  const activeMediaTab = useSelector(getActiveMediaTab);
  const hasExamsMode = useSelector(isExamsMode);

  const examsofFilterItemsToJS = getExamsofFilterItems && getExamsofFilterItems.toJS()
  const teachingTag = subject && getSubjectsTeachingLevel[subject.id];
  const flashcards = getFlashcardsWithNotes && teachingTag && subject && teachingFilterResultsListToArray(getFlashcardsWithNotes, teachingTag, subject);
  const data = flashcards && sourceFilter(flashcards, examsofFilterItemsToJS.source, activeMediaTab);
  const paperFilterData = flashcards && paperFilter(data, examsofFilterItemsToJS.paper, activeMediaTab);
  const teachingTagData = flashcards && topicTeachingFilter(teachingTag, paperFilterData, examsofFilterItemsToJS.teachingLevel, "exams", activeMediaTab);
  const difficultyData = flashcards && difficultyFilter(teachingTagData, examsofFilterItemsToJS.difficulty, activeMediaTab)
  const newFilterItems = activeMediaTab === 'exams' ? (hasExamsMode ? teachingTagData : difficultyData) : flashcards;

  return { flashcards, newFilterItems };
}