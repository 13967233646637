import React, { ReactElement } from 'react'
import CancelEditButton from 'v2/components/Button/CancelEditButton';
import {isDeskEditModeActive} from 'selectors/navigation/navigationSelector'
import NavigationHeader from './NavigationHeader'
import EditButton from 'components/edit-button/EditButton';
import { useSelector } from 'react-redux';


type DeskNavigationHeaderProps = {
    tabTitle: string;
    isCollapsed: boolean;
    rightButton?: any
    canEdit?: boolean;
    isScrolled2?: boolean;
    isScrolled?: boolean;
    extendedHeight?: boolean;
    showButton?: boolean;
    showTechingLevel?: boolean;
}  

const DeskNavigationHeader: React.FC<DeskNavigationHeaderProps> = ({tabTitle, isCollapsed, rightButton, canEdit, isScrolled2, isScrolled, extendedHeight, showButton, showTechingLevel}) => {
    const hasDeskEditModeActive = useSelector(isDeskEditModeActive)

    return <NavigationHeader 
        collapsedTitle={tabTitle}
        isCollapsed={showButton ? isCollapsed : true}
        text={''}
        extendedHeight={extendedHeight}
        shouldExtendHeight={isScrolled2}
        rightSection={showButton ?
            <div className='flex items-center' >
              {!hasDeskEditModeActive && rightButton}
              {canEdit && <EditButton/>}
            </div> : null}
        leftSection={showButton ? hasDeskEditModeActive ? <CancelEditButton/> : null : <div/> }
        
        bottomSection={false}
    />
}

export default DeskNavigationHeader;