import React, { useEffect, useMemo, useState } from 'react'
import FilterHeader from './FilterHeader';
import './filter.scss'
import { useDispatch, useSelector } from 'react-redux';
import { isMainMicroFilterBack } from 'selectors/navigation/navigationSelector'
import FilterToggleHeader from './FilterToggleHeader'
import { examsofFilterItems } from 'selectors/desk/deskSelector'
import { activeSubjectId, subjectsTeachingLevel } from 'selectors/subject/subjectSelector'
import { updateExamsPaperFilterItems } from 'actions/desk/deskActions'
import { toggleMainMicroOverlay } from 'actions/navigation/navigationActions'
import { setExamFilterData } from 'actions/api/apiActions'
import usePrevious from 'hooks/usePrevious';
import FilterButton from './FilterButton'
import FilterContainer from './FilterContainer';
import { paperFilterText } from 'v2/pages/desk/exam/paperData'; 


type PaperFilterProps = {
    onClick?: (e: any) => void;
    onClose: () => void;
}

const PaperFilter: React.FC<PaperFilterProps> = ({ onClick, onClose }) => {
    const dispatch = useDispatch();
    const [isActive, setActive] = useState(true)
    const [disabled, setDisable] = useState(true)
    const hasMainMicroFilterBack = useSelector(isMainMicroFilterBack)
    const examFilterItems = useSelector(examsofFilterItems)?.toJS();
    const subjectId = useSelector(activeSubjectId)
    const subjectTLvl = useSelector(subjectsTeachingLevel)
    const tlvl = subjectTLvl[`${subjectId}`];
    const activePapersData = paperFilterText?.[`${subjectId}`][`${tlvl}`].filter((item:any) => item.active)
    let filterData = useMemo(() => paperFilterText?.[`${subjectId}`][`${tlvl}`], [subjectId, tlvl])
    const [filter, setFilter] = useState(() => activePaperTitle())
    const previousFilterState = usePrevious(filter)

    function activePaperTitle() {

        return filterData.filter(({ active }: { active: boolean }) =>
            !!active
        ).map(({ title }: { title: string }) =>
            title
        )
    }
    useEffect(() => {
        let data = examFilterItems['paper']
        data.length > 0 && setFilter(data);
    }, [])

    useEffect(() => {
        if (previousFilterState !== filter) {
            changeFilter();
        }
    }, [filter, previousFilterState])

    const selectToggle = (val: boolean) => {
        setActive(prevActive => !prevActive)
        if (val) {
            setFilter([]);
            return
        }
        setFilter(activePaperTitle())
    }

    const selectFilter = (val: string) => {
        const filterVal = [...filter];
        let indexOfItem = filterVal.indexOf(val);
        if (indexOfItem >= 0) {
            filterVal.splice(indexOfItem, 1);
        } else {
            filterVal.push(val);
        }
        setFilter(filterVal);
    }

    const changeFilter = () => {
        let prevExamFilter = examFilterItems['paper']
        if (JSON.stringify(filter.sort()) !== JSON.stringify(prevExamFilter.sort())) {
            setDisable(false)
        } else {
            setDisable(true)
        }
        if (filter.length < 1) {
            setDisable(true)
        }
        if (filter.length === activePapersData.length) {
            setActive(true)
        } else {
            setActive(false)
        }
    }

    const applyChanges = () => {
        if (disabled) return;
        dispatch(updateExamsPaperFilterItems(filter.length === activePaperTitle().length ? [] : filter, "paper"))
        dispatch(setExamFilterData())
        onClose();
    }

    const onSelectMain = () => {
        onClose();
        dispatch(toggleMainMicroOverlay(true, true))
    }

    return <div className='filter-wrapper' onClick={onClick}>
        <FilterHeader
            fromCC={hasMainMicroFilterBack}
            onBackClick={onSelectMain}
            text={'Filter'}
            hideArrow={false}
            title={'Paper'}
            onCloseClick={onClose}
        />
        <FilterToggleHeader title={'All paper'} onChange={() => selectToggle(isActive)} checked={isActive} />
        <div className='pt-0 px-5 pb-4'>
            {filterData?.map(({ active, subTitle, title }: any, index: number) => {
                return <FilterContainer key={'PaperFilter-'+index} index={index} isWidth={true} filterType={'paper'} filter={filter} onClick={() => active && selectFilter(title)} itemID={title} isRow={false} isMinHeight={true} isExams={true} isDisabled={!active} active={active}>
                    <>
                        <div className='filter-category-section'>{title}</div>
                        {!active && <div className='paper-filter-content'>Coming soon</div>}
                        <div className='filter-sub-category text-13px leading-16px text-#919191 text-ellipsis overflow-hidden mt-[3px]'>{subTitle}</div>
                    </>
                </FilterContainer>;
            })}
        </div>
        <FilterButton onClose={onClose} onClick={applyChanges} disabled={disabled} />
    </div>
}

export default PaperFilter;