import React, { useState, useRef, useMemo, Fragment, useEffect, useCallback, MouseEvent } from "react";
import { isTouchDevice } from 'helpers';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { getLastMediaTypeVisited, addBackFlashcardToLocalStorage, isMobile } from 'helpers';
import { getFlashcardById, isNavigationSectionVisible, activeSearchTerm, activeSearchResult, isLandscape, isMobile as mobile } from "selectors"
import { useSelector, useDispatch } from "react-redux";
import { getUserId } from 'selectors/user';
import classnames from "classnames";
import MarkText from 'ui-components/MarkText';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReviosionImageZoom from './ReviosionImageZoom'
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import MathjaxFormula from 'components/studying/renderer/MathjaxFormula';
import SVGObject from 'components/studying/renderer/SVGObject';
import usePrevious from 'hooks/usePrevious';
import { setRevisionLink, getFlashcardContent } from "actions/api";
import 'react-loading-skeleton/dist/skeleton.css';

type RevisionRendererContainerProps = {
	hasZoom: boolean,
	flashcardId: number,
	currentFlashcardId: number,
	isVisible: boolean

}
const options: HTMLReactParserOptions = {
	replace: (domNode: any) => {
		if (domNode.type === "tag" && domNode.name === "formula") {
			//  @ts-ignore
			const formula = children[0];
			return <MathjaxFormula>{'###' + formula + '###'}</MathjaxFormula>;
		}
	},
}
let lastScrollTop = 0;
let revisionListener

const RevisionRendererContainer: React.FC<RevisionRendererContainerProps> = ({ flashcardId, hasZoom, isVisible, currentFlashcardId }) => {
	// const [isScrolled, setScrolled] = useState<boolean>(false);
	let isScrolled = false;

	const [imageZoom, setImageZoom] = useState<boolean>(false);
	const [zoomLevel, setZoomLevel] = useState<object>({ current: 1, max: 2 });
	const [imageWidth, setImageWidth] = useState<number>(400);
	const [imageHeight, setImageHeight] = useState<number>(400);
	const [ishasZoom, setHasZoom] = useState<boolean>(false);
	const [isZoomed, setZoomed] = useState<boolean>(false);

	const revisionRef = useRef<HTMLDivElement>(null)
	const revRef = useRef<HTMLDivElement>(null)
	const revisionScrollRef = useRef<HTMLDivElement>(null);
	const hasTouchDevice = isTouchDevice();
	const zoomCanvas = useRef(null);
	const dispatch = useDispatch()

	const flashcardContent = useSelector((state => getFlashcardById(state, flashcardId)));
	const hasNavigationSectionVisible = useSelector(isNavigationSectionVisible);
	const userId = useSelector(getUserId);
	const shouldMark = useSelector(activeSearchTerm);
	const hasLandscape = useSelector(isLandscape);
	const hasMobile = useSelector(mobile);
	const hasSearchResult = useSelector(activeSearchResult)
	const mediaType = getLastMediaTypeVisited(userId);
	const skeletonShow = flashcardContent.get('id') == flashcardId && flashcardContent.get('content');
	const prevNavigationSectionVisible = usePrevious(hasNavigationSectionVisible)

	const showTargetElement = () => {
		if (isMobile()) {
			// @ts-ignore
			disableBodyScroll(revisionScrollRef.current);
		}
	};
	const hideTargetElement = () => {
		// @ts-ignore
		enableBodyScroll(revisionScrollRef.current);
	};
	useEffect(() => {
		callFlashcardContent();
		revisionScrollRef.current?.addEventListener('scroll', handleRevisionScroll);
		revisionLink();
		showTargetElement();

	}, []);


	useEffect(() => {
		if (prevNavigationSectionVisible !== hasNavigationSectionVisible) {
			if (!hasNavigationSectionVisible) {
				hideTargetElement();
				clearAllBodyScrollLocks();
			} else {
				showTargetElement();
			}
		}
	}, [hasNavigationSectionVisible, prevNavigationSectionVisible]);

	const callFlashcardContent = () => {
		flashcardId && dispatch(getFlashcardContent(flashcardId, 'revision'));
	}

	const getClosest = (elem: HTMLDivElement, selector: string) => {
		// @ts-ignore
		for (; elem && elem !== document; elem = elem.parentNode) {
			if (elem.matches(selector)) return elem;
		}
		return null;
	}

	const tabFunction = () => {
		if (revRef.current) {
			revRef.current.addEventListener("click", (e: any) => {
				const target = e.target;
				if (target.classList.contains("reset-3c756112--tabsItem-488dd212--UIH300-2063425d") || target.classList.contains("text-4505230f--UIH300-2063425d--textContentFamily-49a318e1") || target.classList.contains("reset-3c756112") || target.classList.contains("reset-3c756112--tabInner-1de1736f")) {
					let tabContent;
					let selectTabs: any = [];
					if (target.classList.contains("reset-3c756112--tabsItem-488dd212--UIH300-2063425d")) {
						tabContent = target.children[1].children[0].children[0].textContent;
					} else if (target.classList.contains("text-4505230f--UIH300-2063425d--textContentFamily-49a318e1")) {
						tabContent = target.textContent;
					}
					const parentTab: any = getClosest(target, '.reset-3c756112--tabsHeaderContent-5e26ff2c');
					const selectTab = parentTab.querySelectorAll(".reset-3c756112--tabsItem-488dd212--UIH300-2063425d");
					const myNodeListAsArray = Array.prototype.slice.call(selectTab);
					myNodeListAsArray.forEach((tabs, index) => {
						selectTabs[index] = tabs.children[1].children[0].children[0].textContent;
					});
					const unSelectTab = parentTab.querySelector(".reset-3c756112--tabsItem-488dd212--tabsItemActive-7bf3ed3d--UIH300-2063425d");
					if (selectTabs.includes(tabContent)) {
						const selectTabIndex = selectTabs.indexOf(tabContent);
						selectTab[selectTabIndex].classList.remove("reset-3c756112--tabsItem-488dd212--UIH300-2063425d")
						selectTab[selectTabIndex].classList.add("reset-3c756112--tabsItem-488dd212--tabsItemActive-7bf3ed3d--UIH300-2063425d")
						unSelectTab.classList.remove("reset-3c756112--tabsItem-488dd212--tabsItemActive-7bf3ed3d--UIH300-2063425d")
						unSelectTab.classList.add("reset-3c756112--tabsItem-488dd212--UIH300-2063425d")
						const parentTabContent: any = getClosest(target, '.reset-3c756112--tabs-4505230f--tabs-ed73a86a');
						const selectTabContent = parentTabContent.querySelector(".reset-3c756112--tabsItemBody-2814c950--tabsItemBodyActive-41e173d5");
						const parentTabDiv = parentTabContent.children[1]
						const unSelectTabContent = parentTabDiv.querySelectorAll(".reset-3c756112--tabsItemBody-2814c950");
						selectTabContent.classList.remove("reset-3c756112--tabsItemBody-2814c950--tabsItemBodyActive-41e173d5")
						selectTabContent.classList.add("reset-3c756112--tabsItemBody-2814c950")
						unSelectTabContent[selectTabIndex].classList.remove("reset-3c756112--tabsItemBody-2814c950")
						unSelectTabContent[selectTabIndex].classList.add("reset-3c756112--tabsItemBody-2814c950--tabsItemBodyActive-41e173d5")
					}
				}
			});
		}
	}

	const revisionLink = () => {
		revRef.current?.querySelectorAll('a') && revRef.current.querySelectorAll('a').forEach(item => {
			if (item.getAttribute('href') && (item.getAttribute('href'))?.indexOf('guide') == 1) {
				revisionListener = item.addEventListener('click', event => {
					event.preventDefault();
					event.stopPropagation()
					dispatch(setRevisionLink(item.getAttribute('href')));
					return
				})
			}
		});
	}

	useEffect(() => {
		tabFunction();
		revisionLink()
		callFlashcardContent()
	}, [currentFlashcardId, flashcardId, flashcardContent])

	const handleRevisionScroll = (event: MouseEventInit) => {
	    let nowScrollTop: number = revisionScrollRef.current?.scrollTop || 0;
	    if (!(hasMobile && hasLandscape)) {
	        let cardInfoSection = document.querySelector('.revision-wrapper')?.parentNode?.children[0];
	        let revisionBottomSection = document.querySelector('.revision-wrapper')?.children[1];
	        if (nowScrollTop > lastScrollTop && nowScrollTop > 0) {
	            // @ts-ignore
	            if (revisionScrollRef.current?.scrollHeight - revisionScrollRef.current?.scrollTop >= revisionScrollRef.current?.clientHeight) {
	                if (!isScrolled) {
	                    // setScrolled(true)
						isScrolled = true;
	                    revisionBottomSection?.classList.add('is-bottom-menu-scroll-down')
	                    cardInfoSection?.classList.remove('is-cardInfo-scroll-up')
	                    revisionBottomSection?.classList.remove('is-bottom-menu-scroll-up')
	                    cardInfoSection?.classList.add('is-cardInfo-scroll-down')
	                    showTargetElement();
	                }
	            }
	        } else {
	            if (isScrolled) {
	                // setScrolled(false);
					isScrolled = false
	                cardInfoSection?.classList.remove('is-cardInfo-scroll-down')
	                revisionBottomSection?.classList.remove('is-bottom-menu-scroll-down')
	                cardInfoSection?.classList.add('is-cardInfo-scroll-up')
	                revisionBottomSection?.classList.add('is-bottom-menu-scroll-up')
	            }
	        }
	        lastScrollTop = nowScrollTop;
	        // @ts-ignore
	        if (revisionScrollRef.current.scrollHeight - revisionScrollRef.current.scrollTop === revisionScrollRef.current.clientHeight) {
	            if (isScrolled) {
	                // setScrolled(false);
					isScrolled = false
	                cardInfoSection?.classList.remove('is-cardInfo-scroll-down')
	                revisionBottomSection?.classList.remove('is-bottom-menu-scroll-down')
	                cardInfoSection?.classList.add('is-cardInfo-scroll-up')
	                revisionBottomSection?.classList.add('is-bottom-menu-scroll-up')
	                showTargetElement();
	            }
	        }
	    }
	}
	

	const renderLoader = useCallback(() => {
		return (
			<React.Fragment>
				<SkeletonTheme baseColor="#EFF4F9" >
					<Skeleton className="inner-skeleton-h rg-react-loading-skeleton" count={1} duration={2} />
				</SkeletonTheme>

				<SkeletonTheme baseColor="#EFF4F9" >
					<Skeleton className="inner-skeleton-d rg-react-loading-skeleton" count={7} duration={2} />
				</SkeletonTheme>
			</React.Fragment>
		)
	}, [flashcardContent, flashcardId])

	const markSelected = useMemo(() => {
		let markSelectedIndex;
		const currentSearchResult = hasSearchResult;
		if (currentSearchResult && currentFlashcardId === currentSearchResult?.flashcardId) {
			markSelectedIndex = currentSearchResult?.searchIndex;
		}
	}, [currentFlashcardId, hasSearchResult])


	const generateDom = useCallback((htmlString: string) => {
		return parse(htmlString, options);
	}, [])


	return <div className={`revision-contain ${isScrolled ? "is-page-scrolleded" : "is-page-notscrolled"} `} ref={revisionScrollRef}>
		{mediaType === 'revision' && (
			<div className={classnames('py-6 px-0 revision-main', { 'revision-main-skeleton': !skeletonShow, 'max-w-[926px]':skeletonShow, 'revision-main-navigation': hasNavigationSectionVisible })} ref={revisionRef}>
				{flashcardContent.get('id') == flashcardId && flashcardContent.get('content') ? (
					<Fragment>
						{/* @ts-ignore  */}
						<MarkText shouldMark={shouldMark} markType={'span'} type={"line-hieght-mark"} selectedIndex={markSelected} key={`${flashcardId}`}><div className="revision-title">{generateDom('<div>' + flashcardContent.get('title') + '</div>')} </div>
						</MarkText>
						<div className="revision-content" ref={revRef}>{generateDom(flashcardContent.get('content'))}</div>
					</Fragment>
				) : <Fragment>
					<div className="w-full">
						{renderLoader()}
					</div>
				</Fragment>}

			</div>
		)}
		<ReviosionImageZoom imageZoom={imageZoom} zoomLevel={zoomLevel} ishasZoom={ishasZoom} hasTouchDevice={hasTouchDevice} imageWidth={imageWidth} zoomCanvas={zoomCanvas} currentFlashcardId={currentFlashcardId} canEditExercise={undefined} listFlashcardId={undefined} selectedSide={undefined} revRef={revRef} setImageZoom={setImageZoom} setImageWidth={setImageWidth} setImageHeight={setImageHeight} setZoomLevel={setZoomLevel} flashcardContent={flashcardContent.get('id')} setHasZoom={setHasZoom} />
	</div>
}

export default RevisionRendererContainer;