import * as Actions from 'actions';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import ResetPassword from './ResetPassword';
import {reduxForm} from 'redux-form/immutable';

class ResetPasswordContainer extends Component {
  render() {
    return <ResetPassword {...this.props}/>;
  }
}
const mapDispatchToProps = {
  // resetPassword: Actions.Api.resetPassword
};
const onSubmit = (data, dispatch, props) => {
  let token = props.match.params.token;
  // props.resetPassword(data, token);
};
const ResetPasswordForm = (reduxForm({form: 'reset-password', onSubmit})(ResetPasswordContainer));
export default connect(null, mapDispatchToProps)(ResetPasswordForm);
