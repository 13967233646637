import Immutable from 'immutable';
import * as Types from 'actions/notification/notificationTypes';

export default (state = Immutable.Map(), action) => {
  switch (action.type) {
    case Types.SHOW_NOTIFICATION:
      return state.merge(action.data);
    case Types.HIDE_NOTIFICATION:
      return state.clear();
    default:
      return state;
  }
};
