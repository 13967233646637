import React from "react";
import classNames from "classnames";
import Checkbox from "v2/ui-components/Checkbox";

type SubtopicFloatingTitleProps = {
  active?: boolean;
  id: number;
  isScrolled: boolean;
  numbering: string;
  parentIds: any;
  subTopicSeparator: boolean;
  title?: string;
};

const SubtopicFloatingTitle: React.FC<SubtopicFloatingTitleProps> = ({
  active,
  id,
  isScrolled,
  numbering,
  parentIds,
  subTopicSeparator,
  title,
}) => {
  return (
    <div
      className={classNames(
        "max-w-[320px] font-bold text-15px text-#2D3838 tracking-0px leading-[19px] rounded-[18px] border border-solid border-[#D7D9D9]",
        {
          "bg-[rgba(255,255,255,0.90)] shadow-[0_2px_5px_1px_rgba(62,87,85,0.22)] !border-0":
            active,
          // "list-animation": !(isScrolled && !subTopicSeparator),
        }
      )}
    >
      <div className="flex items-center">
        <div className="bg-white shadow-[0_2px_5px_rgba(62,87,85,0.22)] rounded-[11px] py-0.5 px-[9px] font-bold text-13px leading-18px text-center tracking-2px uppercase text-blue-normal ml-3 mr-4 whitespace-nowrap">
          {numbering}
        </div>
        {title && (
          <div
            className="text-ellipsis whitespace-nowrap overflow-x-hidden"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <Checkbox id={id} parentIds={parentIds} className="[&_svg]:block" />
      </div>
    </div>
  );
};

export default SubtopicFloatingTitle;
