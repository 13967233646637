import * as Api from './api/apiTypes';
import * as User from './user/types';
import * as Subject from './subject/subjectActionTypes';
import * as Navigation from './navigation/types';
import * as Studying from './studying/types';
import * as Topic from './topic/topicTypes';
import * as Desk from './desk/types';

export {Api, User, Subject, Navigation, Studying, Topic, Desk};

export const INITIALIZE = 'INITIALIZE';
export const LOGOUT = 'LOGOUT';
export const WINDOW_RESIZE_LISTENER = 'WINDOW_RESIZE_LISTENER';
export const DATA_INIT = 'DATA_INIT';
export const IFRAME_DATA_INIT = 'IFRAME_DATA_INIT';
