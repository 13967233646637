import React, { PureComponent} from 'react';
import media from 'ui-components/helpers/media';
import styled, {css} from 'styled-components/macro';
import {connect} from 'react-redux';
import TeachingLevelSelectorContainer from 'components/teaching-level-selector/TeachingLevelSelectorContainer';
import LinksComponent from 'pages/link/LinksComponent';
// import {flashcardLinksSelector} from 'selectors/api/apiSelector';
import * as Selectors from 'selectors';
import * as Actions from 'actions';
import EmptyScreenMessage from 'v2/components/empty-screen-message/EmptyScreenMessage';
import {LinkIcon} from 'icons/FlashcardNavIcons';
import {Map, List} from 'immutable';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
class LinksContainer extends PureComponent {

  componentDidMount() {
   // this.loadLinks();
  }
  componentDidUpdate(prevProps) {
    if (this.props.currentFlashcardId !== prevProps.currentFlashcardId) {
    //  this.loadLinks();
    }
  }
  loadLinks() {
    // this.props.getFlashcardLinks({
    //   flashcardId: this.props.currentFlashcardId
    // });
  }

  render() {
    const {flashcardLinksSelector} = this.props;
    const linkListItems = flashcardLinksSelector.get('data') || Map({cards: List([]), topics: List([]), subTopics: List([])});
    if (flashcardLinksSelector.get('loading'))
      return <LeftMenuLoader/>;
    return (
      <React.Fragment>
        {linkListItems.get('cards').size > 0 ? 
          <div className="link-container">
            <HeaderStyledTopicsTeachingLevelContainer/> 
            <LinksComponent linkListItems={linkListItems}/>
          </div> :
           <EmptyScreenMessage type='links' className={"empty-screen-padding"}><LinkIcon color={'#2EBCB4'} /></EmptyScreenMessage>
          }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentFlashcardId: Selectors.currentFlashcardId(state),
  // flashcardLinksSelector: flashcardLinksSelector(state),
});
const mapDispatchToProps = {
  // getFlashcardLinks: Actions.Api.getFlashcardLinks,
};
export default connect(mapStateToProps, mapDispatchToProps)(LinksContainer);
const HeaderStyledTopicsTeachingLevelContainer = styled(TeachingLevelSelectorContainer)`
  padding: 16px 0 0 20px;
  display: flex;
  ${media.phone(css` 
    @media(orientation: landscape){
      padding: 0px 0 0 20px;
    }
  `)}
`;