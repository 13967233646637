import React from 'react';

export function ComputerIcon() {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="34" height="34" rx="17" fill="url(#paint55_linear)"/>
        <path d="M7 22.1C7 22.0448 7.04477 22 7.1 22H26.9C26.9552 22 27 22.0448 27 22.1V22.5C27 23.6046 26.1046 24.5 25 24.5H9C7.89543 24.5 7 23.6046 7 22.5V22.1Z" stroke="white" strokeWidth="1.3"/>
        <path d="M9.1 22C9.04477 22 9 21.9552 9 21.9V11C9 10.4477 9.44772 10 10 10L24 10C24.5523 10 25 10.4477 25 11V22H9.1Z" stroke="white" strokeWidth="1.6"/>
        <defs>
            <linearGradient id="paint55_linear" x1="-17" y1="17" x2="17" y2="51" gradientUnits="userSpaceOnUse">
                <stop stopColor="#378EF8"/>
                <stop offset="1" stopColor="#378EF8"/>
            </linearGradient>
        </defs>
    </svg>
  );
}

export function MobileIcon() {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="34" height="34" rx="17" fill="url(#paint56_linear)"/>
        <rect x="11" y="7" width="12" height="20" rx="2" stroke="white" strokeWidth="1.6"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17 24.5C17.8284 24.5 18.5 23.8284 18.5 23C18.5 22.1716 17.8284 21.5 17 21.5C16.1716 21.5 15.5 22.1716 15.5 23C15.5 23.8284 16.1716 24.5 17 24.5Z" fill="white"/>
        <path d="M16 10H18" stroke="white" strokeLinecap="round"/>
        <defs>
            <linearGradient id="paint56_linear" x1="-17" y1="17" x2="17" y2="51" gradientUnits="userSpaceOnUse">
                <stop stopColor="#378EF8"/>
                <stop offset="1" stopColor="#378EF8"/>
            </linearGradient>
        </defs>
    </svg>
  );
}

export function TabletIcon() {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="34" height="34" rx="17" fill="url(#paint57_linear)"/>
        <rect x="27" y="9" width="16" height="20" rx="2" transform="rotate(90 27 9)" stroke="white" strokeWidth="1.6"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.5 18.5C11.3284 18.5 12 17.8284 12 17C12 16.1716 11.3284 15.5 10.5 15.5C9.67157 15.5 9 16.1716 9 17C9 17.8284 9.67157 18.5 10.5 18.5Z" fill="white"/>
        <defs>
            <linearGradient id="paint57_linear" x1="-17" y1="17" x2="17" y2="51" gradientUnits="userSpaceOnUse">
                <stop stopColor="#378EF8"/>
                <stop offset="1" stopColor="#378EF8"/>
            </linearGradient>
        </defs>
    </svg> 
  );
}
