import React from 'react'
import Loader, {BackgroundEnum, SizeEnum} from 'v2/components/loader.v2/Loader.v2';

const ExamMediaLoading = () => {
    return <div className='flex items-center justify-center flex-1'>
            <Loader background={BackgroundEnum.Blue} size={SizeEnum.Small} />
        </div>
}

export default ExamMediaLoading
