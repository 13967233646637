export const TOGGLE_OVERLAY = 'STUDYING_TOGGLE_OVERLAY';

export const TOOL_TIP_PROPS = 'STUDYING_TOOL_TIP_PROPS';

export const SELECT_SIDE = 'STUDYING_SELECT_SIDE';

export const CHANGE_FLASHCARD = 'STUDYING_CHANGE_FLASHCARD';

export const SET_LINKS = 'SET_LINKS';

export const SET_ZOOM_FACTOR = 'SET_ZOOM_FACTOR';
export const SET_FIXED_ZOOM_FACTOR = 'SET_FIXED_ZOOM_FACTOR';
export const TOGGLE_SWAP = 'TOGGLE_SWAP';

export const SET_CURRENT_FLASHCARD_ID = 'SET_CURRENT_FLASHCARD_ID';

export const CALCULATE_FLASHCARD_IDS = 'CALCULATE_FLASHCARD_IDS';

export const SET_FLASHCARD_INDEX = 'STUDYING_SET_FLASHCARD_INDEX';

export const CALCULATE_RENDERER_IDS = 'STUDYING_CALCULATE_RENDERER_IDS';

export const CAROUSEL_PREV_FLASHCARD = 'STUDYING_CAROUSEL_PREV_FLASHCARD';

export const CAROUSEL_NEXT_FLASHCARD = 'STUDYING_CAROUSEL_NEXT_FLASHCARD';

export const NEXT_SIDE = 'STUDYING_NEXT_SIDE';
export const FLIP_FLASHCARD = 'STUDYING_FLIP_FLASHCARD';

export const SET_FLASHCARD_META = 'STUDYING_SET_FLASHCARD_META';

const PREFIX = 'STUDYING_';
export const REGISTER_DEVICE = PREFIX + 'REGISTER_DEVICE';
export const UNREGISTER_DEVICE = PREFIX + 'UNREGISTER_DEVICE';
export const START_UPDATE_DEVICE = PREFIX + 'START_UPDATE_DEVICE';
export const ENABLE_RESTRICTION = PREFIX + 'ENABLE_RESTRICTION';
export const SELECT_FLASHCARD = PREFIX + 'SELECT_FLASHCARD';
export const SET_NAVIGATION_MODE = PREFIX + 'SET_NAVIGATION_MODE';
export const SET_TOOLBOX_OPEN_SECTION = PREFIX + 'SET_TOOLBOX_OPEN_SECTION';
export const SET_ACTIVE_FLASHCARD = PREFIX + 'SET_ACTIVE_FLASHCARD';
export const SCROLL_TO_FLASHCARD = PREFIX + 'SCROLL_TO_FLASHCARD';
export const SCROLL_TO_PREVIEW = PREFIX + 'SCROLL_TO_PREVIEW';
export const SET_ACTIVE_EXERCISE_ID = PREFIX + 'SET_ACTIVE_EXERCISE_ID';
export const SELECT_EXERCISE_FLASHCARD = PREFIX + 'SELECT_EXERCISE_FLASHCARD';
export const SET_EXERCISE_ID = PREFIX + 'SET_EXERCISE_ID';
export const END_EXERCISE = PREFIX + 'END_EXERCISE';
export const INITIALIZE_CAROUSEL = PREFIX + 'INITIALIZE_CAROUSEL';
export const CURRENT_FLASHCARD_CHANGED = PREFIX + 'CURRENT_FLASHCARD_CHANGED';
export const SET_CAROUSEL_TEACHING_LEVEL = PREFIX + 'SET_CAROUSEL_TEACHING_LEVEL';

export const ADD_PENDING_SVG = PREFIX + 'ADD_PENDING_SVG';
export const REMOVE_PENDING_SVG = PREFIX + 'REMOVE_PENDING_SVG';

export const GET_LAST_STUDY_FLASHCARD = PREFIX + 'GET_LAST_STUDY_FLASHCARD';
export const GET_LAST_STUDY_FLASHCARD_SUCEEDED = PREFIX + 'GET_LAST_STUDY_FLASHCARD_SUCEEDED';
export const GET_LAST_STUDY_FLASHCARD_FAILED = PREFIX + 'GET_LAST_STUDY_FLASHCARD_FAILED';

export const SET_CANVAS_ZOOM_FACTOR = PREFIX + 'SET_CANVAS_ZOOM_FACTOR';
export const SET_LINKS_SIZE = 'SET_LINKS_SIZE';
export const SET_CURRENT_EXERCISE_ID = PREFIX + 'SET_CURRENT_EXERCISE_ID';
export const SET_WATCHED_STATUS = PREFIX + 'SET_WATCHED_STATUS';
export const SET_CHANGE_FLASCHCARD_CONTENT_BOOKMARK_STATUS = PREFIX + 'SET_CHANGE_FLASCHCARD_CONTENT_BOOKMARK_STATUS'; 

export const SELECT_EXAMS = PREFIX + 'SELECT_EXAMS';
export const SET_INPROGRESS_LAST_STUDY_FLASHCARD = PREFIX + 'SET_INPROGRESS_LAST_STUDY_FLASHCARD';