import Immutable from 'immutable';

// export const userProfileMap = (state) => {
//   return state.getIn(['api', 'getProfile', 'data'], null);
// };

export const userProfile = (state) => {
  return state.getIn(['api', 'getProfile', 'data'], Immutable.Map()).toJS();
};

// export const contactDetails = (state) => {
//   return state.getIn(['api', 'contactDetails', 'data'], null);
// };

// REFECTORING
export const userProfileMap = (state) =>  state.getIn(['api', 'getProfile'])?.data || null;
export const contactDetails = (state) => state.getIn(['api', 'contactDetails'])?.data || null;
