import styled, {css} from 'styled-components/macro';
// import media from 'ui-components/helpers/media';
import Text from 'ui-components/helpers/Text';


export const MircoFilterContainer = styled.div`
  position: relative;
`;
export const MircoFilterBox = styled.div`
display: flex;
margin-left:${props => props.page === 'exercise'  ? '0' : '20px'};
margin-top: ${props => props.marginTop}px;

`;

export const FilterText = styled.div`
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #378EF8;
    // padding-left:${props => props.data === 'Not yet assessed' ? '8px;' :'0;'}
    padding-right: 4px;
`;

export const MircoFilter = styled.div`
  display: flex;    
  box-shadow: ${props => props.filter ? '0 0 0 2px #378EF8' : '0 0 0 1px #E3E6E6'};
  background: ${props => props.filter ? '#E6EEF7' : '#FFFFFF'};
  border-radius: 18px;
  align-items: center;
  cursor: ${props => props.filter ? 'unset' : 'pointer'};
  user-select: none;
  cursor: pointer;
  height: 36px;
  
  ${props => props.isFolderEdit && css`
    opacity: 0.5 !important;
    pointer-events: none !important;
    user-select: none !important;
  `};
  @media (pointer: fine) {
    &:hover{
      box-shadow: ${props => props.filter ? '0 0 0 2px #64A8FA' : '0 0 0 1px #C8CCCC'};
    }
  }
  >${Text}{
    padding: 9px 4px 9px 16px; 
  }
  svg{
    margin:  ${props => props.filter ? props.isMarging ? '8px 12px 8px 6px' : ' 8px 8px 8px 16px' : '8px 12px 8px 6px'};
   
  }
  .micro-arrow{
    margin: 8px 12px 8px 6px !important;
    transform:  ${props => (props.filter && props.isMarging) ? "rotate(180deg)" : "rotate(360deg)"};
    transition-duration: 0.3s;
  }
      
  &:active{
    background: #378EF8;
    box-shadow: none;
    >${Text}{
      color: #FFFFFF !important;
    }
    >${FilterText}{
      color: #FFFFFF;
    }
    
    color: #FFFFFF;
    svg{
      circle{
          fill: #FFFFFF;
      }
      
      path{
          &:first-child{
              fill: #FFFFFF !important;
          }
          fill: ${props => props.filter ? '#378EF8 !important' : '#FFFFFF'};
      }
    }
    .closeButton1{
      height: 36px; 
      width: 36px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      z-index: 999;
      box-shadow: none;
      background: #378EF8;
      svg{
        margin:  0;
      }
      circle{
        fill: #FFFFFF;
      }
      path { 
        fill: #378EF8 !important;
      } 
    }
  }
  .closeButton1{
    height: 36px; 
    width: 36px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 7;
    
    &:active {
      z-index: 999;
      box-shadow: none;
      background: #378EF8;
      circle{
        fill: #FFFFFF;
      }
      path { 
        fill: #378EF8 !important;
      } 
    }
  }
  .closeButton{
    height: 36px; 
    width: 36px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 7;
    @media (pointer: fine) {
      z-index: 999;
      &:hover {
          background: #B2D4FC;
      }
    }
 
  }
`;

export const FilterClear = styled.div`  
  height: 36px; 
  width: 36px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  z-index: 9;
  right: 0;
  top: 0;
  cursor: pointer;
  position: absolute;
  ${props => props.isEditMode && css`
    opacity: 0.5 !important;
    pointer-events: none !important;
    user-select: none !important;
  `};
  svg{
      margin: 8px !important;
      cursor: unset;
        circle{
          stroke: unset !important;
        }
      path{
        fill:#FFFFFF !important;
      }
    }
  @media (pointer: fine) {
    &:hover {
      background: #B2D4FC;
   }
  }
  &:active {
    box-shadow: none;
    background: #378EF8;
    circle{
      fill: #FFFFFF;
    }
    path { 
      fill: #378EF8 !important;
    } 
  }
`;



// export const ArrowIcon = styled.div`
//     // padding: 8px 12px 8px 0;
// `;
