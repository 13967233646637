import {getExercisePreviewsRoutine, getSubtopicsInTopic} from 'actions/api';
import {showAlert} from 'actions/notification';
import {scrollToPreviewAction} from 'actions/studying';
import {selectFlashcards} from 'actions/topics-tree/topicsTreeActions';
import {
  GET_SELECTED_FLASHCARDS,
  OPEN_NODES,
  SET_TREE_NODES,
  TOGGLE_NODE_SELECTION,
  UPDATE_EXERCISE_NUMBER,

} from 'actions/topics-tree/topicsTreeTypes';
import ErrorNotification from 'v2/components/notifications/ErrorNotification';
import {List} from 'immutable';
import React from 'react';
import {delay} from 'redux-saga/effects';
import {all, call, put, select, take, takeLatest} from 'redux-saga/effects';
import {exercisePreviewsResponse, subtopicsSelector} from 'selectors/api/apiSelector';
import {getSelectedTreeNodes} from 'selectors/topicsTreeSelector';

export function* watchers() {
  yield all([
    takeLatest(OPEN_NODES, openNodes),
    takeLatest(GET_SELECTED_FLASHCARDS, getSelectedFlashcards),
    takeLatest(TOGGLE_NODE_SELECTION, onNodeSelected),
    takeLatest(UPDATE_EXERCISE_NUMBER,updateExerciseNumber)
  ]);
}

function* onNodeSelected() {
  try {
    if (!window.parent) return;
    const selectedTreeNodesImmutable = yield select(getSelectedTreeNodes);
    const selectedTreeNodes = selectedTreeNodesImmutable.toJS();
    window.parent.postMessage(selectedTreeNodes, '*');
  } catch (error) {
    console.error("error for onNodeSelected", error)
  }
}

/**
 * Opens tree nodes and scrolls to the subtopic / preview
 * @param {array} action.parentIds - Path to node to open
 * @param {boolean} action.mapAnswers - If answers should be included in subtopic
 * @param {number} action.previewId - Id of the node to scroll to
 */
function* openNodes(action) {
  try {
    const topicId = action.parentIds[2];
    const subTopics = yield select(subtopicsSelector);
    if (!subTopics.get(topicId)) {
      yield put(getSubtopicsInTopic({id: topicId, mapAnswers: action.mapAnswers}));
      yield take(getSubtopicsInTopic.success);
    }
    //yield call(delay, 100);
    yield delay(100);
    yield put(scrollToPreviewAction(action.previewId));
  } catch (error) {
    console.error("error for onNodeSelected", error)
  }
}

function* getSelectedFlashcards(action) {
  try {
  //wait for the tree to be populated down to flashcard level
    yield take(SET_TREE_NODES);

    yield put(getExercisePreviewsRoutine.trigger({id: action.exerciseId}));

    //wait for the exercise previews to be fetched
    const previewsResponse = yield take([getExercisePreviewsRoutine.success, getExercisePreviewsRoutine.failure]);

    if (previewsResponse.type === getExercisePreviewsRoutine.failure.toString()) {
      //todo what should happen when the error message is closed?
      yield put(showAlert({content: <ErrorNotification title={'Exercise ID is invalid!'}/>, buttons: false}));
      return;
    }

    const exercisePreviewsData = yield select(exercisePreviewsResponse);
    const previews = exercisePreviewsData.getIn([action.exerciseId, 'data'], List());
    yield put(selectFlashcards(previews.map(item => item.get('flashcardId'))));
  } catch (error) {
    console.error("error for getSelectedFlashcards", error)
  }
}
function* updateExerciseNumber(action){
  try {
    yield take(SET_TREE_NODES);
    const exercisePreviewsData = yield select(exercisePreviewsResponse);
    const previews = exercisePreviewsData.getIn([action.exerciseId, 'data'], List());
    yield put(selectFlashcards(previews.map(item => item.get('flashcardId'))));
  } catch (error) {
    console.error("error for updateExerciseNumber", error)
  }
}
