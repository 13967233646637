import React, {Component} from 'react';
import * as Actions from 'actions';
import StoreSubjectsChangerComponent from './StoreSubjectsChangerComponent';
import * as Selectors from 'selectors';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
/**
 * @deprecated TODO- delete not use any more
 */
class StoreSubjectsChangerContainer extends Component {
  constructor(props) {
    super(props);

    this.setStoreActiveSubject = this.setStoreActiveSubject.bind(this);
  }

  setStoreActiveSubject(subject) {
    if (subject.get('id') !== this.props.activeStoreSubject.get('id')) {
      this.props.setStoreActiveSubject(subject);
    }
  }

  render() {
    return <StoreSubjectsChangerComponent
        {...this.props}
      />;
  }
}

const mapStateToProps = (state) => ({
  activeStoreSubject: Selectors.getStoreActiveSubject(state),
});
const mapDispatchToProps = {
  setStoreActiveSubject: Actions.UserStore.setActiveStoreSubject,
};

StoreSubjectsChangerContainer.propTypes = {
  activeStoreSubject: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreSubjectsChangerContainer);
