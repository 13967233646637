import { useDispatch, useSelector } from 'react-redux';
import { mediaFilters, examFilters } from 'v2/helpers';
import { updateFilterListOfItemsSelected, updateExamsPaperFilterItems, setActiveTeachingTagStatus } from 'actions/desk';
import { isHiddenInfoOverlayVisible, isMainMicroFilterVisible, isTeachingLevelExamsOverlayVisible, isFilterOverlayVisible, isQuestionInfoOverlayVisible, isMCQOverlayVisible, isProgressBarFilterVisible } from 'selectors';
import { toggleHiddenInfoOverlay, toggleMainMicroOverlay, toggleTeachingLevelExamsOverlay, toggleFilterOverlay, toggleQuestionInfoOverlay, toggleMCQOverlay, progressBarFilter } from 'actions/navigation';
import { updateExamPreviewsListByType } from 'actions/api'

const usePopupRest = () => {
  const dispatch = useDispatch();
  const hasHiddenInfoOverlayVisible = useSelector(isHiddenInfoOverlayVisible);
  const hasMainMicroFilterVisible = useSelector(isMainMicroFilterVisible);
  const hasTeachingLevelExamsOverlayVisible = useSelector(isTeachingLevelExamsOverlayVisible);
  const hasFilterOverlayVisible = useSelector(isFilterOverlayVisible);
  const hasQuestionInfoOverlayVisible = useSelector(isQuestionInfoOverlayVisible);
  const hasMCQOverlayVisible = useSelector(isMCQOverlayVisible);
  const hasProgressBarFilterVisible = useSelector(isProgressBarFilterVisible);


  const reset = () => {
    mediaFilters.map((item: string) => dispatch(updateFilterListOfItemsSelected([], item)));
    examFilters.map((item: string) => dispatch(updateExamsPaperFilterItems([], item)));
    dispatch(setActiveTeachingTagStatus());
    dispatch(updateExamPreviewsListByType())

    hasHiddenInfoOverlayVisible && dispatch(toggleHiddenInfoOverlay(false, false))
    hasMainMicroFilterVisible && dispatch(toggleMainMicroOverlay(false, false))
    hasTeachingLevelExamsOverlayVisible && dispatch(toggleTeachingLevelExamsOverlay(false, false))
    hasFilterOverlayVisible && dispatch(toggleFilterOverlay(false, false))
    hasQuestionInfoOverlayVisible && dispatch(toggleQuestionInfoOverlay(false, false))
    hasMCQOverlayVisible && dispatch(toggleMCQOverlay(false, false))
    hasProgressBarFilterVisible && dispatch(progressBarFilter(false))
  }

  return {resetPopup: reset };
};

export default usePopupRest;
