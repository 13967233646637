import React from 'react';

export const PenIcon = (props = {}) => {
  const color = props.color;
  return (
    <svg
      width="18px" height="59px" viewBox="0 0 18 59" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs/>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons/Marking/Pen---Red" transform="translate(-1.000000, -1.000000)" stroke="#2D3838">
          <g id="Pen" transform="translate(2.000000, 2.000000)">
            <path
              d="M8.48337521,0.376194236 L10.5,8 L5.5,8 L7.51662479,0.376194236 C7.58724039,0.109233476 7.86090011,-0.0499357172 8.12786087,0.0206798914 C8.30166171,0.0666531351 8.43740197,0.202393396 8.48337521,0.376194236 Z"
              id="Triangle" fill={color}/>
            <path
              d="M8.13951717,8 L10.2104848,8 C10.6733872,8 11.0757847,8.31769892 11.1831929,8.7679678 L15.0980061,25.1793561 C15.6993354,28.1761088 16,30.3724457 16,31.7683669 C16,33.164288 16,41.3914657 16,56.4498999 L8.13951717,56.4498999 L0,56.4498999 C0,41.3914657 0,33.164288 0,31.7683669 C1.87905496e-17,30.3724457 0.300664643,28.1761088 0.901993929,25.1793561 L4.81680706,8.7679678 C4.92421531,8.31769892 5.3266128,8 5.78951517,8 L8.13951717,8 Z"
              id="Combined-Shape" strokeWidth="1.3"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const HighlighterIcon = (props) => {
  const color = props.color;
  return (
    <svg
      width="18px" height="58px" viewBox="0 0 18 58" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs/>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons/Marking/Marker---Red" transform="translate(-1.000000, -1.000000)" stroke="#2D3838">
          <g id="Marker" transform="translate(0.000000, 2.000000)">
            <path
              d="M4.5387305,9.65 L1.65568913,48.8102249 C1.60598936,49.4852946 1.76195794,50.1595844 2.10304716,50.7442615 L5.27203053,56.1763655 C5.33475572,56.2838857 5.44986787,56.35 5.57434695,56.35 L14.4255873,56.35 C14.5500787,56.35 14.6652003,56.2838727 14.7279211,56.1763356 L17.8962075,50.7441915 C18.2371819,50.1595783 18.39309,49.4853982 18.3433985,48.8104412 L15.4603412,9.65 L4.5387305,9.65 Z"
              id="Combined-Shape" strokeWidth="1.3"
              transform="translate(9.999544, 33.000000) scale(1, -1) translate(-9.999544, -33.000000) "/>
            <path
              d="M14,0.023947782 L14,1.87102001 C14,2.77131528 13.5956764,3.62401625 12.8986173,4.1937883 L6.48986173,9.43226771 C6.36157878,9.53712545 6.17258091,9.51813556 6.06772317,9.38985261 C6.02392581,9.33627091 6,9.26919498 6,9.19999088 L6,0.023947782 L14,0.023947782 Z"
              id="Path-11-Copy" fill={color}
              transform="translate(10.000000, 4.761974) scale(-1, -1) translate(-10.000000, -4.761974) "/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const EraserIcon = (props) => {
  const active = props.active;
  return (
    <svg
      width="32px" height="55px" viewBox="0 0 32 55" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <rect id="path-1" x="0" y="0" width="32" height="55" rx="4"/>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons/Marking/Eraser---ACTIVE" transform="translate(-4.000000, -4.000000)">
          <g id="Eraser" transform="translate(4.000000, 4.000000)">
            <g id="Rectangle-3">
              <use fill={active ? '#F78F8C' : '#FFFFFF'} fillRule="evenodd" xlinkHref="#path-1"/>
              <rect stroke="#2D3838" strokeWidth="1.3" x="0.65" y="0.65" width="30.7" height="53.7" rx="4"/>
            </g>
            <path d="M1,11.5 L31,11.5" id="Line" stroke="#2D3838" strokeWidth="0.5" strokeLinecap="square"/>
            <path
              d="M15.5387657,6 L14.0955247,4.556759 C13.9681584,4.42939268 13.9681584,4.22289107 14.0955247,4.09552474 C14.2228911,3.96815842 14.4293927,3.96815842 14.556759,4.09552474 L16,5.53876574 L17.443241,4.09552474 C17.5706073,3.96815842 17.7771089,3.96815842 17.9044753,4.09552474 C18.0318416,4.22289107 18.0318416,4.42939268 17.9044753,4.556759 L16.4612343,6 L17.9044753,7.443241 C18.0318416,7.57060732 18.0318416,7.77710893 17.9044753,7.90447526 C17.7771089,8.03184158 17.5706073,8.03184158 17.443241,7.90447526 L16,6.46123426 L14.556759,7.90447526 C14.4293927,8.03184158 14.2228911,8.03184158 14.0955247,7.90447526 C13.9681584,7.77710893 13.9681584,7.57060732 14.0955247,7.443241 L15.5387657,6 Z"
              id="Combined-Shape" fill="#363838"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const MarkingIcon = () => {
  return (
    <svg
      width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs/>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons/Content/Markings/Passive" stroke="#2D3838" strokeWidth="1.3">
          <g
            id="Group-6"
            transform="translate(9.606602, 11.606602) rotate(45.000000) translate(-9.606602, -11.606602) translate(5.106602, 1.106602)">
            <path
              d="M0,10 L0.819900744,1.80099256 C0.922141103,0.778588967 1.78247223,-3.48610185e-15 2.80997512,8.8817842e-16 L6.19002488,1.55431223e-14 C7.21752777,1.99174026e-14 8.0778589,0.778588967 8.18009926,1.80099256 L9,10"
              id="Path-10" strokeLinecap="round"/>
            <path
              d="M0.216113754,12.4969517 L8.78388625,12.4969517 C8.8943432,12.4969517 8.98388625,12.5864947 8.98388625,12.6969517 C8.98388625,12.7423661 8.96843001,12.7864283 8.94005978,12.821891 L6.21913235,16.2230373 C6.07728119,16.4003506 6,16.6206616 6,16.8477338 L6,17.5827381 C6,17.8479546 5.89464316,18.1023085 5.70710678,18.2898449 L3.51213203,20.4848196 C3.39497475,20.6019769 3.20502525,20.6019769 3.08786797,20.4848196 C3.03160705,20.4285587 3,20.3522526 3,20.2726876 L3,16.8477338 C3,16.6206616 2.92271881,16.4003506 2.78086765,16.2230373 L0.0599402247,12.821891 C-0.00906184541,12.7356387 0.00492219536,12.6097802 0.091174454,12.5407781 C0.12663711,12.5124079 0.170699327,12.4969517 0.216113754,12.4969517 Z"
              id="Path-11"/>
          </g>
        </g>
      </g>
    </svg>
  );
};
