import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import Text from 'ui-components/helpers/Text';
import Padding from 'ui-components/helpers/Padding';
import * as Selectors from 'selectors';
import media from 'ui-components/helpers/media';
export class TeachingLevelSeperator extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { tlvl, tlvlTag, isTopic, activeSubject, isExams ,slTag,filter} = this.props;
    let subjectSlug = activeSubject?.slug;
    let optionTag = null;
    let historyTag = null;
    let preIb = null;
    if (tlvl && tlvl.includes('Option')) {
      optionTag = tlvl.split(' | ')[0];
    }
    if (tlvl && (tlvl.includes('Prescribed') || tlvl.includes('World history'))) {
      historyTag = tlvl;
    }
    if (tlvl && tlvl === "HL options") {
      historyTag = tlvl;
    }
    if (tlvl && tlvl === "Pre-IB") {
      preIb = tlvl;
    }
    let isTlvlTag;
    if(tlvl && tlvl === 'SL' && isExams){
      isTlvlTag = tlvl === 'SL' ? true : false;
    }
    const isTlvl = tlvlTag === 'SL&HL' ? true : false;
    return <React.Fragment>
      {(isTlvl || isTlvlTag) ?
        <React.Fragment>
          <StyledTechingLevelHeader type={'SL'} isExams={isExams} filter={filter}>
            SL
          </StyledTechingLevelHeader>
          <Padding left={6} />
          <StyledTechingLevelHeader type={'HL'} isExams={isExams} filter={filter}>
            HL
          </StyledTechingLevelHeader>

          {optionTag != null &&
            <React.Fragment>
              <Padding left={6} />
              <StyledTechingLevelHeader type={tlvl} isOption={true} isExams={isExams} filter={filter}>
                {optionTag}
              </StyledTechingLevelHeader>
            </React.Fragment>
          }
          {historyTag != null &&
            <React.Fragment>
              <Padding left={6} />
              <StyledTechingLevelHeader type={tlvl} isOption={true} isTopic={isTopic} isHistory={true} filter={filter}>
                {historyTag}
              </StyledTechingLevelHeader>
            </React.Fragment>
          }
        </React.Fragment>
        :
        <React.Fragment>
          {historyTag !== null ?
            <React.Fragment>
              <StyledTechingLevelHeader type={tlvl} isExams={isExams} filter={filter}>
                HL
              </StyledTechingLevelHeader>

              <React.Fragment>
                <Padding left={6} />
                <StyledTechingLevelHeader type={"Options"} isOption={true} isExams={isExams} filter={filter}>
                  Options
                </StyledTechingLevelHeader>
              </React.Fragment>
            </React.Fragment> :
            <React.Fragment>
              {preIb ? 
              <StyledTechingLevelHeader type={tlvl} isExams={isExams} filter={filter}>
                {tlvl}
              </StyledTechingLevelHeader> :
                <React.Fragment>
                  {(subjectSlug === "es-amp-s" || subjectSlug === "mathematics" || subjectSlug === "maths-a-amp-a" || subjectSlug === "maths-a-amp-i") ? 
                  <StyledTechingLevelHeader type={tlvl} isExams={isExams}>
                    {tlvl}
                  </StyledTechingLevelHeader> :
                    <React.Fragment>
                      {slTag ?
                        <StyledTechingLevelHeader type={"SL"} isExams={isExams} filter={filter}>
                          SL
                        </StyledTechingLevelHeader> :
                        <StyledTechingLevelHeader type={"HL"} isExams={isExams} filter={filter}>
                          HL
                        </StyledTechingLevelHeader>
                      }
                    </React.Fragment>

                  }
                </React.Fragment>
              }
              {optionTag != null &&
                <React.Fragment>
                  <Padding left={6} />
                  <StyledTechingLevelHeader type={tlvl} isOption={true} isExams={isExams} filter={filter}>
                    {optionTag}
                  </StyledTechingLevelHeader>
                </React.Fragment>
              }
            </React.Fragment>
          }
        </React.Fragment>
      }
    </React.Fragment>;
  }
}
const mapStateToProps = state => ({
  activeSubject: Selectors.getActiveSubject(state),
});
TeachingLevelSeperator.defaultProps ={
  isExams :false
}

export default connect(
  mapStateToProps, null
)(TeachingLevelSeperator);

const computeColor = type => {
  if (type.indexOf('SL') > -1 && !type.includes('Option')) return '#458CFC';
  if (type.indexOf('HL') > -1 && !type.includes('Option')) return '#1FCD6C';
  if (type.includes('Option') || type.includes('Prescribed') || type.includes('World history')) return '#97A5B3';
  return '#458CFC;';
};
const computePadding = type => {
  if (type.indexOf('SL') > -1) return '2px 8px';
  if (type.indexOf('HL') > -1) return '2px 6px 2px 7px';
  return '2px 8px;';
};
const examsPadding = type => {
  if (type.indexOf('SL') > -1) return '1.45px 4.73px 1.55px 6.09px';
  if (type.indexOf('HL') > -1) return '1.45px 4.73px 1.55px 5.09px';
  return '1.45px 4.73px 1.55px 6.09px';
}


export const StyledTechingLevelHeader = styled.div`
  background: ${props => computeColor(props.type)};
  border-radius: 5px;
  padding: ${props => props.isExams ? examsPadding(props.type) : computePadding(props.type)};
  user-select: none;
  font-size: ${props => props.isExams ? "9.4px" : "13px"};
  line-height:${props => props.isExams ? "13.5px" : "18px"};
  color: #FFFFFF;
  text-transform: ${props => props.isOption ? "initial" : "initial"};
  min-width:${props => props.isExams ? "21px" : "30px"};
  font-weight: normal;
  
  ${media.smallPhone(css`
    font-size:10px;
    width :23px;
  `)}
  ${props => props.isExams && css`
    width :22px;
    height:16px;
    border-radius:3.64px;
  `}; 
  ${props => (!props.isTopic && props.isHistory) && css`
    max-width: 70px;
    white-space: nowrap;
    overflow: hidden;
    //text-overflow: ellipsis;
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 20px;
      background: linear-gradient(to right,rgba(255,255,255,0.0001) 0%,#EFF4F9 162.71%);
      height:22px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  `}
         
`;

