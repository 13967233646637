import { useEffect, useState } from 'react'
import './answerStatistics.scss';
import { UnconfidentAssessmentIcon, ConfidentAssessmentIcon, NeitherAssessmentIcon } from 'icons/FlashcardIcons';
import { useDispatch, useSelector } from 'react-redux';
import { currentFlashcardId } from 'selectors/studying';
import { getUserId } from 'selectors/user';
import { answerById } from 'selectors/answersSelectors';
import { apiRequestData } from 'selectors/api/apiSelector';
import { getLastMediaTypeVisited } from 'helpers';
import { useParams } from 'react-router-dom';
import { setAssessmentAnimation, updateAnswerByFlashcardId } from 'actions/desk';
import { setAnswerFlashcard } from 'actions/api';
import { carouselFlashcardIndex } from 'selectors/carouselSelectors';
import { isLandscape, isMobile } from 'selectors/browser/browserSelector';
import classNames from 'classnames';
import { FCAssessmentStatus, FCAssessmentStatusTitle } from 'v2/helpers';
import { getExerciseOnCanvas } from 'selectors';
const PREFIX = 'EXERCISE-';
const AnswerStatistics = () => {
  const dispatch = useDispatch();
  const isExerciseOnCanvas = useSelector(getExerciseOnCanvas)
  const currentId = useSelector(currentFlashcardId);
  const userId = useSelector(getUserId);
  const answer = useSelector(state => answerById(state, isExerciseOnCanvas ? PREFIX + currentId : currentId));
  const answers = useSelector(state => apiRequestData(state, 'answers'));
  const getCarouselFlashcardIndex = useSelector(carouselFlashcardIndex);
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);
  const [status, setStatus] = useState<number | null>(null);
  const [showAnimation, setShowAnimation] = useState<boolean>(true);
  const [clicked, setClicked] = useState<boolean>(false);
  const mediaType = getLastMediaTypeVisited(userId);
  const hasMobileLandscape = (hasMobile && hasLandscape)

  const handleAnimation = () => {
    setTimeout(() => {
      setShowAnimation(false)
      dispatch(setAssessmentAnimation(false));
    }, 3000);
  }

  const setAnswer = (newAnswer: number, prevAns: any) => {
    if (!answers) return null;
    const finalStatus = status === newAnswer ? null : newAnswer;
    newAnswer !== prevAns && dispatch(setAssessmentAnimation(true));
    setShowAnimation(true);
    setStatus(finalStatus);
    setClicked(true);
    handleAnimation();
    if (!answer) return null;
    let prevAnswer, finalAnswer;
    if (answer.get("answered")) {
      prevAnswer = prevAns;
    } else {
      prevAnswer = "no-answer";
    }
    if (answer.get("answered") && answer.get("correct") == newAnswer) {
      finalAnswer = -1;
    } else {
      finalAnswer = newAnswer;
    }

    dispatch(updateAnswerByFlashcardId({ id: currentId, answer: finalAnswer, index: getCarouselFlashcardIndex }))
    dispatch(setAnswerFlashcard({ answer: finalAnswer, prevAnswer: prevAnswer }));
  }

  const handleKeyUpEvent = (e: any) => {
    if (['INPUT', 'TEXTAREA'].indexOf(e.target.nodeName) !== -1) return;
    if (mediaType === 'flashcard') {
      if (e.key === '1') { setAnswer(0, answers.get("correct")) }
      else if (e.key === '2') { setAnswer(2, answers.get("correct")) }
      else if (e.key === '3') { setAnswer(1, answers.get("correct")) }
    }
  }
  useEffect(() => {
    setStatus(FCAssessmentStatus(answer.get("correct"), answer.get("answered")))
    if (mediaType === 'flashcard' && answer) {
      window.addEventListener('keyup', handleKeyUpEvent)
    }
    return () => window.removeEventListener('keyup', handleKeyUpEvent)
  }, [answer, mediaType])
  if (!answers || !currentId) return null;

  return <div className='answer-statistics flex-column'>
    <div className='answer-assesstment'>
      <div className='answer-inner-container'>
        <div className={classNames('unconfident fc-assessment answer-line select-none', {
          'svg-color': (answer.get("answered") && status != 0),
          'active': (answer.get("answered") && status == 0),
          'not-answered': !answer.get("answered")
        })} onClick={() => setAnswer(0, answer.get("correct"))}><UnconfidentAssessmentIcon /></div>

        <div className={classNames('neither fc-assessment answer-line select-none', {
          'svg-color': (answer.get("answered") && status != 2),
          'active': (answer.get("answered") && status == 2),
          'not-answered': !answer.get("answered")
        })} onClick={() => setAnswer(2, answer.get("correct"))}><NeitherAssessmentIcon /></div>

        <div className={classNames('confident fc-assessment answer-line select-none', {
          'svg-color': (answer.get("answered") && status != 1),
          'active': (answer.get("answered") && status == 1),
          'not-answered': !answer.get("answered")
        })} onClick={() => setAnswer(1, answer.get("correct"))}><ConfidentAssessmentIcon /></div>
      </div>
      {(clicked && !hasMobileLandscape) && <div className={classNames('description font-[600] text-13px leading-18px ml-2',{ 'show-animation': showAnimation, 'hide-animation': !showAnimation, 'text-#FA9000': status == 0, 'text-[#3EBF8F]': status == 1, 'text-[#A2A3A3]': status == 2 })} >{FCAssessmentStatusTitle(status)}</div>}
    </div>
  </div>
}

export default AnswerStatistics