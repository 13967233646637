import React, {Component} from 'react';
import {validator} from 'utils/form-helper';
import BackButton from 'components/BackButton/BackButton';
import FormInput from 'components/FormInputComponent/FormInput';
import {RightArrowIcon} from "icons/PassiveIcons";

const passwordsMatch = (field, fields) => {
  return fields.get('newPassword') === fields.get('confirmNewPassword') ? undefined : 'The two passwords do not match!';
};

export default class ForgotPassword extends Component {
  render() {
    let {change, touch, handleSubmit} = this.props;
    return <div className="login-page">
      <div className="back-button-wrapper">
        <BackButton location="/login"/>
      </div>
      <div className="form-title">Reset Password </div>
      <form onSubmit={handleSubmit}>
        <FormInput label="New Password" name="newPassword" placeholder="Enter a secure password" type="password"
                   validate={[validator.required, validator.passwordLength]} change={change} touch={touch}/>
        <FormInput label="Confirm new password" name="confirmNewPassword" placeholder="Confirm secure password" type="password"
                   validate={[validator.required, passwordsMatch]} change={change} touch={touch}/>
        <button type="submit" className="primary-button">
          <div>SAVE</div>
          <div className="arrow"><RightArrowIcon/></div></button>
      </form>
    </div>;
  }
}
