import createHistory from 'history/createBrowserHistory';
import createMemoryHistory from 'history/createMemoryHistory';

let history;

if (process.env.NODE_ENV !== 'test') {
  history = createHistory();
} else {
  // used for test environment
  history = createMemoryHistory();
}

const originalPush = history.push;

history.push = (url, clearSearch = false, state) => {
  if (typeof url === 'string')
  url = {pathname: url};
  url.search = clearSearch ? '' : url.search || (history.location && history.location.search) || '';
  originalPush(url, state);
};

history.listen(function (location) {
  if (window.gtag) {
    window.gtag('event', 'screen_view', {
      'app_name': 'OSC Study',
      'screen_name': location.pathname + location.search
    });
  }
});

export default history;
