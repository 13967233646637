import './NoteEditor.scss';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import styled from 'styled-components/macro';
import MarkText from 'ui-components/MarkText';

export default class NoteEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isTextareaVisible: false
    };

    this.textareaRef = null;
  }

  toggleTextareaVisibility = () => {
    this.setState(prevState => {
      this.props.toggleFocusTextarea(!prevState.isTextareaVisible);
      return {isTextareaVisible: !prevState.isTextareaVisible};
    }, () => {
      this.state.isTextareaVisible && this.textareaRef.focus();
    });
  };

  setTextareaVisible = (e) => {
    if (this.state.isTextareaVisible) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      this.props.toggleFocusTextarea(true);
      this.setState({isTextareaVisible: true}, () => {
        this.textareaRef.focus();
      });
    }
  };

  handleEsc = (key, event) => {
    if (this.state.isTextareaVisible) {
      this.textareaRef.blur();
    } else {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  render() {
    return (<div className="note-editor" ref={this.props.innerRef} onClick={this.setTextareaVisible}>
      <KeyboardEventHandler handleKeys={['esc']} handleFocusableElements={true} onKeyEvent={this.handleEsc}/>
      {this.state.isTextareaVisible && <div className={'editor-focused-marker'}/>}
      {this.state.isTextareaVisible ? <TextareaAutosize innerRef={ref => this.textareaRef = ref}
          placeholder="Add a new note..."
          id="flashcard-note"
          name="flashcardNote"
          value={this.props.textareaNote}
          onChange={this.props.onTextareaChange}
          onBlur={this.toggleTextareaVisibility}
          disabled={this.props.deleteConfirmationMode}
        /> :
        <MarkText markType={'span'} shouldMark={this.props.shouldGoBackToSearch}>
          <TextAreaReplacement placeholder={!this.state.isTextareaVisible && !this.props.textareaNote}>
            {this.props.textareaNote || 'Add a new note...'}
          </TextAreaReplacement>
        </MarkText>
      }
    </div>);
  }
}

NoteEditor.propTypes = {
  getNote: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  flashcardId: PropTypes.number.isRequired,
  shouldGoBackToSearch: PropTypes.bool.isRequired,
};

const TextAreaReplacement = styled.div`
    height: 100%;
    background: unset;
    border: none;
    width: 100%;
    resize: none;
    font-family: "Open Sans";
    font-size: 15px;
    color: #4b4d4d;
    text-shadow: 0 -0.2px 0.2px rgba(75, 77, 77, 0.39), 0 -0.1px 0 #ffffff;
    outline: none;
    white-space: pre-wrap;
    
    ${props => props.placeholder && `  
    font-size: 15px;
    line-height: 1.27;
    text-align: left;
    color: #b1b6b5;
    text-shadow: 0 -0.2px 0.2px rgba(75, 77, 77, 0.39), 0 -0.1px 0 #ffffff;
  `}
`;

