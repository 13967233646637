export const theme = {
  turquoiseGreen: '#48CFA8',
  turquoiseBlue: '#378EF8',
  turquoiseLight: '#EDF8FA',
  turquoise: '#2EBCB4',
  lightGrey: '#e4e6e6',
  lightGrey2: '#a2a3a3',
  lightGrey3: '#919191',
  lightGrey4: '#dfe2e2',
  overlayColor: '#203d3c',
  errorColor: '#FA2326',
  green: '#82e52b',
  orange: '#f2a539',
  red: '#f23e38',
  yellow: '#f2e43c',
  blue: '#378EF8',
  purple: '#9013fe',
  charcoal: '#2d3838',
  sizes: {
    tablet: 992,
    phone: 991, //893
    iPadPro: 1024,
    iPad: 769,
    smallPhone: 375,
    collapsedStudy: 1023,
    iFrameMobile: 550,
    iFrameTablet: 770,
    smalliPhone5: 320,
    iFrameMobileMedium: 599,
    sPhone: 480
  },
  topBottomGradient: 'linear-gradient(148deg, #48CFA8 , #4ABAD1)',
  leftRightGradient: 'linear-gradient(225deg,#43DAF0 0%,#395BF8 90%)',
  leftMenuWidth: '375px',
};

export default theme;
