import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as Selectors from 'selectors';
import Avatar from 'ui-components/Avatar';

class InternalAvatarContainer extends Component {
  render() {
    return <Avatar className={this.props.className} size={this.props.size} alternate={this.props.alternate} initialFlashcard={this.props.initialFlashcard}>
      {this.props.userInitials}
    </Avatar>;
  }
}

const mapStateToProps = (state) => ({
  userInitials: Selectors.getUserInitials(state)
});
const mapDispatchToProps = {};

InternalAvatarContainer.propTypes = {
  className: PropTypes.string,
  userInitials: PropTypes.string.isRequired,
  size: PropTypes.number,
  alternate: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(InternalAvatarContainer);
