import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveFlashcardTab, currentFlashcardId, getActiveSubject, isNavigationSectionVisible, isLeftMenuOpen, isLinksNavigationSectionVisible, getActiveExamModule, lastMenuPath, isShortcutMenu, isMobile, isLandscape } from 'selectors'
import { useHistory } from 'react-router-dom';
import { setActiveFlashcardTab, toggleNavigationSection, closeMenu, toggleDeskEditMode, saveLastMenuPath, toggleLinksNavigationSection, hideShortcutMenu } from 'actions/navigation';
import { setGoBackToSearch } from "actions/search";
import usePrevious from 'hooks/usePrevious';
import { navigationsTab, navigationsName, leftMenuItems, defaultMenuPathObj } from "v2/helpers"
import BottomMenuItems from './BottomMenuItems';
import './bottomMenu.scss'
import useExamModuleStatus from 'hooks/useExamModuleStatus';


const BottomMenuNavigation = () => {
  const history = useHistory();
  const { location } = history;
  const dispatch = useDispatch();
  const [localActiveTab, setLocalActiveTab] = useState(-1)
  const examModuleStatus = useExamModuleStatus();
  const hasCurrentFlashcardId = useSelector(currentFlashcardId);
  const hasActiveSubject = useSelector(getActiveSubject);
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape)
  const hasNavigationSectionVisible = useSelector(isNavigationSectionVisible);
  const hasLeftMenuOpen = useSelector(isLeftMenuOpen);
  const hasLinksNavigationSectionVisible = useSelector(isLinksNavigationSectionVisible);
  const hasLastMenuPath = useSelector(lastMenuPath);
  const activeFlashcardTab = useSelector(getActiveFlashcardTab);
  const hasShortcutMenu = useSelector(isShortcutMenu);
  const prevActiveSubject = usePrevious(hasActiveSubject)
  const prevLeftMenuOpen = usePrevious(hasLeftMenuOpen)
  const prevlocation = usePrevious(location) || { pathname: '' };
  const prevPathname = prevlocation?.pathname;
  const currentPathname = location.pathname;

  function setActiveTab(tabId = -1) {
    setLocalActiveTab(tabId)
    dispatch(setActiveFlashcardTab(tabId))
  }

  useEffect(() => {
    if (prevlocation?.pathname && (prevPathname !== currentPathname)) {
      const pathname = currentPathname;
      let tabName;
      navigationsName.map((item) => {
        if (pathname.indexOf(item.name) !== -1) {
          tabName = item.tabName;
        }
      });
      if (tabName) {
        dispatch(saveLastMenuPath({
          ...hasLastMenuPath,
          [tabName]: pathname
        }))
      } else {
        console.error("tabName", tabName, pathname);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPathname, prevPathname])

  useEffect(() => {
    if (prevLeftMenuOpen !== hasLeftMenuOpen && localActiveTab !== -1) {
      if (!hasLeftMenuOpen) {
        setActiveTab(-1)
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLeftMenuOpen, prevLeftMenuOpen])

  useEffect(() => {
    if (prevActiveSubject !== hasActiveSubject) {
      const finalLastMenuPathObj = {
        ...defaultMenuPathObj,
      }
      if (hasCurrentFlashcardId) {
        finalLastMenuPathObj.notes = "/flashcard/desk/notes/edit"
      }
      navigationsTab.map((item) => {
        if (currentPathname.indexOf(item.name) !== -1 && !hasCurrentFlashcardId) {
          setActiveTab(item.tab)
          if (!hasLeftMenuOpen) {
            dispatch(toggleNavigationSection(true, true));
          }
        }
      });

      if (!hasNavigationSectionVisible && hasCurrentFlashcardId) {
        dispatch(toggleNavigationSection(true, true));
      }
      dispatch(saveLastMenuPath(finalLastMenuPathObj))
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasActiveSubject, prevActiveSubject])

  const openTab = (tabName: string, url: string, index: number) => {
    if (index !== activeFlashcardTab) {
      dispatch(setActiveFlashcardTab(index));
      if (!hasLeftMenuOpen) {
        dispatch(toggleNavigationSection(!hasNavigationSectionVisible));
        dispatch(hideShortcutMenu());
      } else if (hasShortcutMenu && activeFlashcardTab === index) {
        dispatch(toggleNavigationSection(!hasNavigationSectionVisible));
        dispatch(hideShortcutMenu());
      }
    }
    const pathname = location.pathname;
    if (url !== pathname || pathname.indexOf("/flashcard/classroom") === -1) {
      dispatch(toggleDeskEditMode(false));
    }
    dispatch(closeMenu())
    if (url.indexOf("/flashcard/search") === -1) {
      dispatch(setGoBackToSearch(false));
    }
    if (!hasLinksNavigationSectionVisible) {
      dispatch(toggleLinksNavigationSection(false, false));
    }
    const exercisePageRegex = RegExp('/flashcard/classroom/exercise/([0-9]+)');

    let finalTabName = tabName.toLowerCase();
    if (finalTabName === "notes" && hasCurrentFlashcardId) {
      finalTabName = "editNotes";
    }

    let finalUrl = hasLastMenuPath[finalTabName];

    if (!(exercisePageRegex.test(pathname) && finalTabName === 'exercises')) {
      dispatch(saveLastMenuPath({
        ...hasLastMenuPath,
        [finalTabName]: finalUrl
      }))
      history.push(finalUrl)
    }
    
    if (hasLeftMenuOpen && !hasShortcutMenu && hasCurrentFlashcardId) {
      setLocalActiveTab(-1)
      dispatch(setActiveFlashcardTab(-1))
    }
  }
  const handleTouchStart = (tabName: string, url: string, index: number) => {
    dispatch(setActiveFlashcardTab(index));
    openTab(tabName, url, index);
  }

  const hasMobileLandscape = hasMobile && hasLandscape
  return (
    <>
      {!(hasMobileLandscape || !hasLeftMenuOpen) &&
        <div className='bottom-menu flex items-center justify-center flex-col fixed w-full bg-white z-10 bottom-0'>
          <div className='bottom-menu-inner flex items-center border-t border-[#E3E6E6] w-full border-solid'>
            {!hasMobileLandscape &&
              <div className='bottom-menu-content inline-flex w-full justify-center items-center h-[64px]'>
                {leftMenuItems.map((item) => {
                  switch (item.name) {
                    case 'Exams':
                      return examModuleStatus.status && <BottomMenuItems
                        isActive={activeFlashcardTab === item.index}
                        onClick={() => openTab(item.name, defaultMenuPathObj[item.url], item.index)}
                        onTouchStart={() => handleTouchStart(item.name, defaultMenuPathObj[item.url], item.index)}
                        item={item}
                        />
                    case 'Notes':
                      return <BottomMenuItems
                        isActive={activeFlashcardTab === item.index}
                        onClick={() => openTab(item.name, hasCurrentFlashcardId ? defaultMenuPathObj['editNotes'] : defaultMenuPathObj[item.url], item.index)}
                        onTouchStart={() => handleTouchStart(item.name, hasCurrentFlashcardId ? defaultMenuPathObj['editNotes'] : defaultMenuPathObj[item.url], item.index)}
                        item={item}
                        />
                    default:
                      return <BottomMenuItems
                        isActive={activeFlashcardTab === item.index}
                        onClick={() => openTab(item.name, defaultMenuPathObj[item.url], item.index)}
                        onTouchStart={() => handleTouchStart(item.name, defaultMenuPathObj[item.url], item.index)}
                        item={item}
                        />
                  }
                })}
              </div>
            }
          </div>
        </div>
      }
    </>
  )
}

export default BottomMenuNavigation
