import styled, {css} from 'styled-components/macro';
import PropTypes from 'prop-types';

const calculateColor = ({color, theme}) => {
  let cssColor = color || '#000000';

  if (theme[color]) {
    cssColor = theme[color];
  }

  return css`
    color: ${cssColor}; 
  `;
};
const Text = styled.div`
  font-size: ${({size}) => size || 16}px;
  ${calculateColor}
  ${({weight}) => weight && css`
    font-weight: ${weight};
  `}

  ${({noWrap}) => noWrap && css`
    white-space: nowrap;
  `}
  ${({overflow}) => overflow && css`
    overflow: ${overflow};
  `}
  ${({cursor}) => cursor && css`
    cursor: ${cursor};
  `}
  
  ${({fontFamily}) => fontFamily && css`
    font-family: ${fontFamily};
  `}
  
  ${({letterSpacing}) => letterSpacing >= 0 && css`
    letter-spacing: ${letterSpacing}px;
  `}
  
  ${({textAlign}) => textAlign && css`
    text-align: ${textAlign};
  `}
  
  ${({lineHeight}) => lineHeight && css`
    line-height: ${lineHeight};
  `}
  
  ${({opacity}) => opacity && css`
    opacity: ${opacity};
  `}
  ${({background}) => background && css`
    background: ${background};
  `}
  ${({textTransform}) => textTransform && css`
    text-transform: ${textTransform};
  `}
  ${({paddingTop}) => paddingTop && css`
    padding-top: ${paddingTop};
  `}
  ${({paddingBottom}) => paddingBottom && css`
    padding-bottom: ${paddingBottom};
  `}
  ${({paddingLeft}) => paddingLeft && css`
    padding-left: ${paddingLeft};
  `}
  ${({paddingRight}) => paddingRight && css`
    padding-right: ${paddingRight};
  `}
`;

Text.propTypes = {
  size: PropTypes.number,
  // weight: PropTypes.string,
  noWrap: PropTypes.bool,
  color: PropTypes.string,
};

export default Text;
