import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/page-overlay/close-icon-turquoise.svg';
import classNames from 'classnames';
import './PageOverlay.scss';

type PageOverlayProps = {
  onClose?: (value: boolean) => void;
  isOverlayVisible?: boolean;
  children: React.ReactElement;
  animate?: boolean;
  topPosition?: boolean;
  supportAnimation?: boolean;
  subjectsManager?: boolean;
  deactiveCloseBtn?: boolean;
}
const PageOverlay: React.FC<PageOverlayProps> = ({ onClose, isOverlayVisible, children, topPosition, supportAnimation, subjectsManager, deactiveCloseBtn, animate = false }) => {

  useEffect(() => {
    //if (!onClose) return;
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Esc') {
      onClose?.(false);
    }
  };

  const handleClose = () => {
    setTimeout(() => {
      onClose?.(false);
    }, 200);
  }

  return (
    <div className={classNames('m-auto max-w-1440px top-14 page-overlay', { 'open': isOverlayVisible }, { 'support-animation': supportAnimation }, { '!top-0': topPosition })}>
      {deactiveCloseBtn ? null :
        <div className="relative">
          {onClose && !subjectsManager && <CloseButton onClose={handleClose} />}
        </div>
      }
      <div className={classNames('overlay-wrapper', { 'flex items-center justify-center': !subjectsManager }, { 'p-0': subjectsManager })}>
        {children}
      </div>
    </div>
  )
}

export default PageOverlay;

type CloseButtonProps = {
  onClose: () => void;
}
const CloseButton: React.FC<CloseButtonProps> = ({ onClose }) => {
  return <div className='close-btn-support' onClick={() => onClose()}>
    <div className="overlay-close-btn" >
      <CloseIcon />
    </div>
  </div>
}