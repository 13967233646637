import * as Actions from 'actions';
import { setNodeAnswers, setNodeMark, setNodeWatched, setExamNodeAnswers } from 'actions/answers/answersActions';
import { setTeachingLevelForSubject } from 'actions/subject';
import { setVideoProgress } from 'actions/videoProgress/videoProgressActions';
import { setNodeStatus } from 'actions/status/statusActions';
import { setExamNodeStatus } from 'actions/examStatus/examStatusAction';
import { setQuickNodeStatus } from 'actions/quickStatus/quickStatusActions';
import { exerciseStatusById, statusById } from 'selectors/statusSelector';
import { quickStatusById, examStatusById } from 'selectors/quickSelectors';
import { registerDevice } from 'actions/studying';
import { setRevisionIds, setVideoIds, setFlashcardIds, setFlashcardIdDetails, setVideoIdDetails, setRevisionIdDetails } from 'actions/carousel/carouselActions';
import { updateIdsOfFilterItems, multipleSourceUpdate } from 'actions/desk/deskActions';
import * as NavigationActions from 'actions/navigation';
import { apiResponseLoading } from 'actions/navigation'
import { setFilteredExerciseFlashcardIds } from 'actions/topics-tree/topicsTreeActions';
import { getMBLastSyncRoutine } from 'actions/api';
import {
  createExerciseRoutine,
  deleteExercisesRoutine,
  getAllExternalExercisesRoutine,
  getAllTopicsInSubject,
  getClassContentRoutine,
  getExerciseAnswersRoutine,
  getExerciseDetailsRoutine,
  getExercisePreviewsRoutine,
  // getExternalAvatarRoutine,
  getExternalExercisesRoutine,
  getFlashcardAnswersRoutine,
  getFlashcardIdsRoutine,
  getPreviewsListByType,
  getExamPreviewsListByType,
  getRecentSearches,
  getSubtopicsInTopic,
  getTopicsDetails,
  getTopicsForTeachingLevel,
  getViewedExercisesRoutine,
  search,
  searchExercisesRoutine,
  searchFlashcards,
  searchFolders,
  searchNotes,
  setExerciseAsViewedRoutine,
  updateExerciseRoutine,
  updateRecentSearches,
  getFirstQuestionIdBySubject,
  getExternalAvatarFailed,
  getExternalAvatarSucceeded,
  getInternalExercises,
  getInternalExercisesSucceeded,
  getExerciseDetailsSucceeded,
} from 'actions/api/apiActions';
import { GET_FLASHCARD_CONTENT_SUCCEEDED, GET_USER_SUCCEEDED } from 'actions/api/apiTypes';
import { addViewedExercise, setExternalExercises, setViewedExternalExercises } from 'actions/exercises/exercisesActions';
import { toggleDeviceManagerOverlay } from 'actions/navigation';
import { changeMenuPathAction } from 'actions/menuActions';
import { setGoBackToSearch } from 'actions/search';
import { endExercise, setNavigationMode } from 'actions/studying';
import { setTreeNodes } from 'actions/topics-tree/topicsTreeActions';
import * as Types from 'actions/types';
import * as Api from 'api';
import appHistory from 'appHistory';
import WarningNotification, { errorReasonsEnum } from 'v2/components/notifications/WarningNotification';
import { fromJS, List, Map } from 'immutable';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { change } from 'redux-form';
import { delay, takeLeading } from 'redux-saga/effects';
import { watchRequest } from 'redux-saga-request';
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Selectors from 'selectors';
import { activeSearchTerm, activeSubjectId, activeTeachingLevelTitle, getActiveTeachingLevel, listOfFilterCheckedItems, examPreview } from 'selectors';
import { getSelectedTreeNodes, topicsTree as topicsTreeSelector } from 'selectors/topicsTreeSelector';
import { userMBRoleSelector } from 'selectors/user';
import swal from 'sweetalert';
import Notification from 'v2/components/notifications/Notification';
import NoLinkNotification from 'v2/components/notifications/NoLinkNotification';
import { subject } from 'v2/actions/subject/subjectActions'
import { questionTypeFindV1 } from 'helpers';
import { teachingFilterResultsMapToArray, mathematicsIds, isValidJSON, sortFoldersByDate, orderSubjects, getUniqueExamPreviewData, questionTypeFind, getLastMediaTypeVisited, addBackFlashcardToLocalStorage, filterResultsListToArrayByTOJS, setJWTToken, getJWTToken, changeTeachingTag, getStoreExamsParentId, getFormExams, examTeachingFilter, difficultyFilter, paperFilter, sourceFilter, returnExamsStatusFilter, returnExamAnswerFilter, setFirstQuestionIdBySubject, getExamSubTopicId, topicTeachingFilter, addCredentialsToLocalStorage, addSubjectSubtitles, setLastMediaTypeVisited, buildSearchResultsArray, isMBiframe, saveFirstMediaNodeId, getLastSubjectVisited, teachingTagFilter, getPartnersServiceBasedOnRegion, countExamsStatus, getPartnerProviderIDSecretBasedOnRegion, getAccessToken, yearNewSyllabus } from 'v2/helpers';
import { SET_CURRENT_FLASHCARD_ID } from 'actions/studying/types';
import { isMBFromSchool } from 'selectors/user';

export function* watchers() {
  yield all([
    yield takeLatest(Types.Api.GET_SUBJECTS, getSubjects),
    yield takeLatest(Types.Api.GET_SUBJECTS_SYLLABUS, getSubjectSyllabus),
    yield takeLatest(Types.Api.GET_LOGEEDINDEVICE, getLoggedinDevices),
    yield takeLatest(Types.Api.LOGOUTDEVICE, removeLoggedinDevice),
    yield takeLatest(Types.Api.AUTHENTICATE, authenticate),
    yield takeLatest(Types.Api.GET_USER, getUser),
    yield takeLatest(Types.Api.GET_PARTNERS, getPartners),
    yield takeEvery(Types.Api.GET_FLASHCARD_CONTENT, getFlashcardContent),
    yield takeLatest(Types.Api.GET_ELEMENT_STYLES, getElementStyles),
    yield takeLatest(Types.Api.GET_STUDENT_PACKAGES, getStudentPackages),
    yield takeLatest(Types.Api.ADD_VOUCHER, addVoucher),
    yield takeLatest(Types.Api.GET_PROFILE, getProfile),
    yield takeLatest(Types.Api.SAVE_PROFILE, saveProfile),
    yield takeLatest(Types.Api.GET_CONTACT_DETAILS, getContactDetails),
    yield takeLatest(Types.Api.SAVE_CONTACT_DETAILS, saveContactDetails),
    yield takeLatest(Types.Api.CHANGE_PASSWORD, changePassword),
    yield takeLatest(Types.Api.CHANGE_USERNAME, changeUsername),
    yield takeLatest(Types.Api.SET_ANSWER_FLASHCARD, setAnswerFlashcard),
    yield takeLatest(Types.Api.GET_TRIAL_INFO, getTrialInfo),
    yield takeLatest(Types.Api.CHECK_PURCHASE, checkPurchase),
    watchRequest(getRecentSearches, Api.getRecentSearches, { response: (data) => data.searchedItems }),
    yield takeLatest(search.trigger, onSearch),
    yield takeLatest(updateRecentSearches.trigger, onUpdateRecentSearches),
    yield takeLatest(searchFlashcards.trigger, onSearchFlashcards),
    yield takeLatest(searchNotes.trigger, onSearchNotes),
    watchRequest(searchFolders, Api.searchInFolders, { response: mapFoldersResponse }),
    watchRequest(getTopicsForTeachingLevel, Api.getTopicsForTeachingLevel),
    yield takeLatest(getSubtopicsInTopic.trigger, onGetSubtopicsInTopic),
    yield takeLatest(getTopicsDetails.trigger, onGetTopicsDetails),
    yield takeLatest(getPreviewsListByType.trigger, onGetPreviewsListByType),
    yield takeLatest(getAllTopicsInSubject.trigger, onGetAllTopicsInSubject),
    yield takeLatest(getFlashcardIdsRoutine.trigger, getFlashcardIds),
    watchRequest(getClassContentRoutine, Api.getClassContent),
    watchRequest(getExternalExercisesRoutine, Api.getExternalExercises),
    // yield takeEvery(getExternalAvatarRoutine.trigger, onGetExternalUserAvatar),
    yield takeEvery(Types.Api.GET_EXTERNAL_AVATAR, onGetExternalUserAvatar),
    yield takeLatest(getExercisePreviewsRoutine.trigger, getPreviewsInExercise),
    watchRequest(getExerciseAnswersRoutine, Api.getExerciseAnswers),
    yield takeLatest(getAllExternalExercisesRoutine.trigger, getAllExternalExercises),
    yield takeLatest(getViewedExercisesRoutine.trigger, getViewedExercises),
    yield takeLatest(setExerciseAsViewedRoutine.trigger, setExerciseAsViewed),
    yield takeLatest(createExerciseRoutine.trigger, createExercise),
    yield takeLatest(updateExerciseRoutine.trigger, updateExercise),
    yield takeLatest(getFirstQuestionIdBySubject.trigger, onGetFirstQuestion),
    yield takeLatest(Types.Api.PROGRESS_POPUP_OVERLAY_NEXT, progressPopupOverlayNext),
    yield takeLatest(Types.Api.PROGRESS_POPUP_OVERLAY_PREVIOUS, progressPopupOverlayPrevious),
    yield takeLatest(Types.Desk.SET_FILTER_EXAM_STATUS_COUNT, onChangeExamStatusCount),
    yield takeLatest(Types.Desk.SET_FILTER_TOPIC_EXAM_STATUS_COUNT, onChangeTopicExamStatusCount),
    // changed to GET_INTERNAL_EXERCISES
    // watchRequest(getInternalExercisesRoutine, Api.getInternalExercises, {
    //   response: data => data.sort((a, b) => b.createdAt - a.createdAt)
    // }),
    watchRequest(deleteExercisesRoutine, Api.deleteExercises),
    watchRequest(getMBLastSyncRoutine, Api.getLastMBSync),
    yield takeLatest(deleteExercisesRoutine.success, onExercisesDeleted),
    //response function is added to follow the same data structure as in flashcards, notes, folders; cannot paginate on backend
    watchRequest(searchExercisesRoutine, Api.searchInExercises, {
      response: data => ({
        count: data.length,
        items: data
      })
    }),
    //yield takeLatest(getFlashcardAnswersRoutine.trigger, getFlashcardAnswers),
    yield takeLatest(Types.Api.SET_REVISION_LINK, setRevisionLink),
    //SMPP-LAGGY
    yield takeLatest(Types.Api.SET_QUICK_STATUS, onSetQuickStatus),
    yield takeLatest(Types.Api.GET_FEATURE, getFeature),
    yield takeLatest(Types.Api.CLOSE_FEATURE, closeFeature),
    yield takeLatest(Types.Api.GET_EXAMS, getExams),
    yield takeLatest(Types.Navigation.SET_EXAM_HEADER_LIST, setExamsHeaderList),
    yield takeLatest(Types.Api.GET_EXAM_PREVIEW, getExamPreview),
    yield takeLatest(Types.Api.GET_EXAM_HEADER, getExamHeader),
    yield takeLatest(Types.Api.GET_NEXT_PREV_TOPICS_DATA, onGetNextPrevTopics),
    yield takeLatest(Types.Api.SET_EXAM_FILTER_DATA, onGetExamFilterData),
    yield takeLatest(Types.Api.GET_QUESTION_COUNT, getQuestionCount),
    yield takeLatest(Types.Api.SET_LAST_TEACHING_TAG, setLastTeachingTagBySubject),
    yield takeLatest(Types.Api.UPDATE_FLASHCARD_ANSWER, updateFlashcardAnswer),
    yield takeEvery(Types.Api.GET_INTERNAL_EXERCISES, onGetInternalExercises),
    yield takeEvery(Types.Api.GET_EXERCISE_DETAILS, onGetExerciseDetails),
  ]);
}

/**
 * @deprecated 
 */
function* checkPurchase(action) {
  try {
    appHistory.push('/my-subjects', true);
    //yield call(delay, 1000);
    yield delay(1000)
    let attempts = 0;
    while (attempts < 10) {
      attempts++;
      const response = yield call(Api.checkPurchase, action.purchaseId);
      if (response.isComplete) {
        yield put(Actions.Api.getStudentPackages());
        yield put(Actions.Api.getSubjects());
        yield put(subject())
        break;
      } else {
        //yield call(delay, 2000);
        yield delay(2000);
      }
    }
  } catch (error) {
    console.error("error for insertTableSizes", error)
  }
}

function* onSearchNotes(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  const { searchField } = action.payload;
  const APJWTToken = getJWTToken();
  const activeSubjectId = yield select(Selectors.activeSubjectId);
  const subjectsTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const activeSubject = yield select(Selectors.getActiveSubject);
  const activeExamModule = yield select(Selectors.getActiveExamModule)
  const activeMediaTab = yield select(Selectors.getActiveMediaTab);
  const activeTeachingLevel = yield select(activeTeachingLevelTitle);
  const isMathematics = mathematicsIds.includes(activeSubjectId);
  const questionBankCodes = activeExamModule ? ["ib_dp", "osc_dp"] : isMathematics && ["osc_dp"];
  const teachingTag = subjectsTeachingLevel[activeSubjectId];
  const APTeachingTag = changeTeachingTag(teachingTag);
  const requestData = {
    jwt: APJWTToken,
    tag: APTeachingTag,
    page: 0,
    activeTab: activeMediaTab,
    subjectId: activeSubjectId,
    searchField: searchField,
    subjectSlug: activeSubject.slug,
    questionBankCodes: questionBankCodes
  };
  try {
    let response = yield call(Api.searchInNotes, requestData);
    if (activeMediaTab === 'exams') {
      response?.items.map((item) => {
        item.qb_code = `${item.experienceData.qb_code}`;
        item.sources = `${item.experienceData.qb_code}`;
        item.paperType = `${item.paper}`;
        item.examCategory = `${item.teachingLevel} ${item.experienceData.exam_name} ${item.paper}`;
      });
    }

    //filter notes based on the active teaching level
    if (activeTeachingLevel !== 'All') {
      response.items = response.items.filter((item) => item.teachingLevel === activeTeachingLevel);
    }

    //simulate pagination; all results are needed for the above filtering
    if (action.payload.limit) {
      response.items = response.items.slice(0, action.payload.limit);
    }

    response.items.map(item => {
      item.category = `${item.topicNumbering} ${item.teachingLevel}`;
    });

    yield put(searchNotes.success({ key, response, requestData }));

  } catch (error) {
    yield put(searchNotes.failure({ key, error }));
    console.error('Error for onSearchNotes: ', error)
  } finally {
    yield put(searchNotes.fulfill({ key }));
  }
}

function* getFlashcardAnswers(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  const requestData = action.payload;
  const activeMediaTab = yield select(Selectors.getActiveMediaTab);

  if (action.payload.length === 0) {
    yield put(getFlashcardAnswersRoutine.fulfill({ key }));
    return;
  }

  try {
    if (activeMediaTab === "flashcard") {
      const response = yield call(Api.getFlashcardAnswers, requestData);
      const data = response?.items.map((answer) => ({
        id: answer.flashcardNode,
        correct: answer.correct,
        answered: answer.answered
      }));
      yield put(setNodeAnswers(data));
      yield put(getFlashcardAnswersRoutine.success({ key, response, requestData }));
    }
  } catch (error) {
    yield put(getFlashcardAnswersRoutine.failure({ key, error }));
    console.error('Error for getFlashcardAnswers: ', error)
  } finally {
    yield put(getFlashcardAnswersRoutine.fulfill({ key }));
  }
}

function* updateExercise(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  const requestData = yield mapExerciseData(action.payload.data);
  const alert = {
    content: <Notification title={'No flashcards were selected!'} text={'Please select at least one flashcard before updating this exercise!'} />,
    buttons: false,
    timer: 5000
  }

  try {
    if (requestData === null) {
      yield put(Actions.Notification.showAlert(alert));
      return;
    }
    const response = yield call(Api.updateExercise, key, requestData);

    yield put(updateExerciseRoutine.success({ key, response, requestData }));

    appHistory.push('/flashcard/desk/exercises');
  } catch (error) {
    yield put(updateExerciseRoutine.failure({ key, error }));
    console.error('Error for updateExercise: ', error)
  } finally {
    yield put(updateExerciseRoutine.fulfill({ key }));
  }
}

function* onExercisesDeleted() {
  const subjectId = yield select(activeSubjectId);
  try {
    yield put(Actions.Desk.deselectAllItems());
    yield put(Actions.Navigation.toggleDeskEditMode(false));
    yield put(Actions.Desk.toggleDeleteConfirmationMode(false));
    // Get Internal Exercises
    yield put(getInternalExercises(subjectId))
    yield put(endExercise());
  } catch (error) {
    console.log("error for onExercisesDeleted: ", error)
  }
}

function* createExercise(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  const alert = {
    content: <Notification title={'No flashcards were selected!'} text={'Please select at least one flashcard before creating the exercise!'} />,
    buttons: false,
    timer: 5000
  }
  try {
    const requestData = yield mapExerciseData(action.payload);
    if (requestData === null) {
      yield put(Actions.Notification.showAlert(alert));
      return;
    }
    let response = yield call(Api.createExercise, requestData);
    yield put(createExerciseRoutine.success({ key, response, requestData }));
    appHistory.push('/flashcard/desk/exercises');
  } catch (error) {
    yield put(createExerciseRoutine.failure({ key, error }));
    console.log("error for createExercise: ", error);
  } finally {
    yield put(createExerciseRoutine.fulfill({ key }));
  }
}

function* setExerciseAsViewed(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  try {
    const requestData = action.payload;
    let response = yield call(Api.setExerciseAsViewed, requestData);
    response = response.exercises;
    yield put(setExerciseAsViewedRoutine.success({ key, response, requestData }));
    yield put(addViewedExercise(key));
  } catch (error) {
    yield put(setExerciseAsViewedRoutine.failure({ key, error }));
    console.log("error for onSetExerciseAsViewed: ", error);
  } finally {
    yield put(setExerciseAsViewedRoutine.fulfill({ key }));
  }
}

function* getAllExternalExercises(action) {
  const token = getAccessToken();
  if(!token) {
    window.location.reload()
    return;
  }
  const key = action.payload && (action.payload.key || action.payload.id);
  const userRole = yield select(userMBRoleSelector);
  if (userRole !== 'STUDENT') {
    yield put(getAllExternalExercisesRoutine.fulfill({ key }));
    return;
  }
  try {
    const requestData = action.payload;
    let response = yield call(Api.getAllExternalExercises, requestData);
    response = response.exercises;

    yield put(getAllExternalExercisesRoutine.success({ key, response, requestData }));

    yield put(setExternalExercises(response));
  } catch (error) {
    yield put(getAllExternalExercisesRoutine.failure({ key, error }));
    console.log("error for getAllExternalExercises: ", error);
    if(!!error?.invalid_grant) {
      window.location.reload()
      return;
    }
  }
}

function* getViewedExercises(action) {
  const token = getAccessToken();
  if(!token) {
    window.location.reload()
    return;
  }
  const key = action.payload && (action.payload.key || action.payload.id);
  const userRole = yield select(userMBRoleSelector);
  if (userRole !== 'STUDENT') {
    yield put(getAllExternalExercisesRoutine.fulfill({ key }));
    return;
  }
  try {
    const requestData = action.payload;
    let response = yield call(Api.getViewedExercises, requestData);
    response = response.exerciseIds;

    yield put(getViewedExercisesRoutine.success({ key, response, requestData }));

    yield put(setViewedExternalExercises(response));
  } catch (error) {
    yield put(getViewedExercisesRoutine.failure({ key, error }));
    console.log("error for getViewedExercises: ", error);
    if(!!error?.invalid_grant) {
      window.location.reload()
      return;
    }
  } finally {
    yield put(getViewedExercisesRoutine.fulfill({ key }));
  }
}

function* getPreviewsInExercise(action) {
  const PREFIX = 'EXERCISE-';
  const key = action.payload && (action.payload.key || action.payload.id);
  try {
    const requestData = action.payload;
    const getExerciseOnCanvas = yield select(Selectors.getExerciseOnCanvas);

    let resp = yield call(Api.getExercisePreviews, requestData);
    const answData = resp?.items.map((answer) => ({
      id: PREFIX + answer.flashcardId,
      correct: answer.answers,
      answered: answer.answers == null ? false : true
    }));
    yield put(setNodeAnswers(answData));
    const { data } = yield call(setOccurencesToPreview, resp.items);

    yield put(getExercisePreviewsRoutine.success({ key, response: data, requestData }));
  } catch (error) {
    yield put(getExercisePreviewsRoutine.failure({ key, error }));
  } finally {
    yield put(getExercisePreviewsRoutine.fulfill({ key }));
  }
}

function* onGetExternalUserAvatar(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  try {
    const requestData = action.payload;
    let response = yield call(Api.getExternalUserAvatar, requestData);

    //API acts as a proxy and returns 200 with an object {error: ''} if the photo is missing
    if (isValidJSON(response)) {
      response = JSON.parse(response);
      if (response && response.error) {
        // yield put(getExternalAvatarRoutine.failure({ key, error: 'AVATAR_NOT_FOUND' }));
        yield put(getExternalAvatarFailed({key, error: 'AVATAR_NOT_FOUND'}))
      }
    } else {
      // yield put(getExternalAvatarRoutine.success({ key, response, requestData }));
      yield put(getExternalAvatarSucceeded({key, response, requestData}))
    }
  } catch (error) {
    console.error('Error in onGetExternalUserAvatar: ', error)
    // yield put(getExternalAvatarRoutine.failure({ key, error }));
  }
}

function* getFlashcardIds(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  const pathname = appHistory.location.pathname;
  const activeMediaTab = yield select(Selectors.getActiveMediaTab);
  const activeSubjectId = yield select(Selectors.activeSubjectId);
  const activeSubject = yield select(Selectors.getActiveSubject);
  const subjectsTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const filterList = yield select(listOfFilterCheckedItems);
  const teachingTag = subjectsTeachingLevel[activeSubjectId];
  let requestData, topicsTree;
  let flashcardIds = [];
  let filteredIds = [];
  let microFilterFlashcardIds = [];
  try {
    yield put(apiResponseLoading(true))

    if (pathname.indexOf("/class/") !== -1 || ['pre-ib-mathematics', 'history'].includes(activeSubject?.slug)) {
      requestData = action.payload;
    } else {
      requestData = { subjectId: activeSubjectId, teachingTag: teachingTag }
    }
    let activeType = 'flashcard'
    if(activeSubject?.slug === 'business-management') {
      activeType = 'revision'
    }
    let response = yield call(Api.getFlashcardIds, requestData, activeMediaTab === "exams" ? activeType : activeMediaTab);

    if (response) {
      yield put(apiResponseLoading(false));
    }
    if (pathname.indexOf("flashcard/desk/exercise/create") !== -1) {
      topicsTree = Map();
    } else {
      topicsTree = yield (select(topicsTreeSelector));
    }

    const classContent = yield select(Selectors.classContentResponse);
    if (classContent.get('data')) {
      response.items = response.items.filter(item => classContent.getIn(['data', 'teachingLevelIds']).find(teachingLevelId => teachingLevelId === item.teachingLevelId));
    }
    topicsTree = topicsTree.withMutations(topicsTree => {
      return response.items.forEach(item => {
        return item.flashcards.forEach(flashcard => {
          topicsTree.setIn([activeSubjectId, 'children', item.teachingLevelId, 'children', item.topicId, 'children', item.id, 'children', flashcard], Map());
        });
      });
    });

    response.items.forEach((data) => {
      flashcardIds = flashcardIds.concat(data.flashcards);
      filteredIds = filteredIds.concat(data.flashcardsAnswers);
      if (data.flashcardsAnswers && (pathname.indexOf("flashcard/desk/exercise/create") !== -1 || pathname.indexOf("flashcard/desk/exercise/edit") !== -1)) {
        microFilterFlashcardIds = microFilterFlashcardIds.concat(filterResultsListToArrayByTOJS(data.flashcardsAnswers, "flashcard", filterList.toJS()["flashcard"]).map((item) => item.flashcardId));
      }
    });
    if (activeMediaTab === "revision") {
      yield put(setRevisionIds(flashcardIds));
      yield put(updateIdsOfFilterItems(List(filteredIds), 'revision'));
      yield put(setRevisionIdDetails(response))
    }
    if (activeMediaTab === "video") {
      yield put(setVideoIds(flashcardIds));
      yield put(updateIdsOfFilterItems(List(filteredIds), 'video'));
      yield put(setVideoIdDetails(response))
    }

    if (["flashcard", "exams"].includes(activeMediaTab)) {
      yield put(setFlashcardIds(flashcardIds));
      yield put(updateIdsOfFilterItems(List(filteredIds), 'flashcard'));
      yield put(setFlashcardIdDetails(response))
    }
    yield put(setTreeNodes([], topicsTree));
    //external Exercise
    if (pathname.indexOf("/class/") !== -1) {
      yield put(setFilteredExerciseFlashcardIds(flashcardIds));
    } else {
      yield put(setFilteredExerciseFlashcardIds(microFilterFlashcardIds));
    }

    yield put(getFlashcardIdsRoutine.success({ key, response, requestData }));

  } catch (error) {
    yield put(getFlashcardIdsRoutine.failure({ key, error }));
    console.error('Error for getFlashcardIds: ', error);
  }
  yield put(getFlashcardIdsRoutine.fulfill({ key }));
}

function* onGetAllTopicsInSubject(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  const getAPJWTToken = getJWTToken();
  const activeSubjectId = yield select(Selectors.activeSubjectId);
  const subjectsTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const activeSubject = yield select(Selectors.getActiveSubject);
  const activeExamModule = yield select(Selectors.getActiveExamModule);
  const teachingTag = subjectsTeachingLevel[activeSubjectId];
  const subjectSlug = activeSubject.slug;
  const APTeachingTag = changeTeachingTag(teachingTag);
  const subjectExamStatus = mathematicsIds.includes(activeSubjectId);
  const questionBankCodes = activeExamModule ? ['ib_dp', 'osc_dp'] : subjectExamStatus && ['osc_dp'];

  try {
    const requestData = action.payload;
    let response = yield call(Api.getAllTopicsInSubject, requestData, getAPJWTToken, APTeachingTag, subjectSlug, questionBankCodes);
    response.topics = response?.topics?.filter(({examsBox, flashcardBox, revisionGuideBox, videoBox}) => {
      return (!!examsBox && Object.keys(examsBox)?.length > 0) || 
      (!!flashcardBox && flashcardBox?.total > 0) || 
      (!!revisionGuideBox && revisionGuideBox?.total > 0) ||
      (!!videoBox && videoBox?.total > 0)
    })
    const classContent = yield select(Selectors.classContentResponse);
    if (classContent.get('data')) {
      response.topics = response.topics.filter(item => classContent.getIn(['data', 'teachingLevelIds']).find(teachingLevelId => teachingLevelId === item.tlvlId));
    }

    yield put(getAllTopicsInSubject.success({ key, response, requestData }));
    const filterData = yield select((state) => Selectors.topicsForCurrentActiveTeachingLevel(state, activeSubjectId, false));
    const topicsIds = filterData.map(item => ({ apTopicsId: item.get('apTopicId'), topicId: item.get('topicId') }));
    const topicsIdsToJS = topicsIds.toJS();
    yield put(Actions.Api.getAllTopicsIds(topicsIdsToJS));
    yield put(Actions.Api.getFirstQuestionIdBySubject(filterData?.first()?.get('id'), APTeachingTag));
    const nodeStatus = response?.topics.map(topic => ({
      id: topic.id,
      flashcard: topic.flashcardBox,
      revision: topic.revisionGuideBox,
      video: topic.videoBox,
      exam: topic.examsBox
    }));
    yield put(setNodeStatus(nodeStatus));

  } catch (error) {
    yield put(getAllTopicsInSubject.failure({ key, error }));
    console.error('Error for onGetAllTopicsInSubject: ', error);
  } finally {
    yield put(getAllTopicsInSubject.fulfill({ key }));
  }
}

function* onGetSubtopicsInTopic(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  const shouldMapAnswers = action.payload && action.payload?.mapAnswers;
  const requestData = action.payload;
  const getAPJWTToken = getJWTToken();
  const subjectId = yield select(Selectors.activeSubjectId);
  const subjectsTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const subject = yield select(Selectors.getActiveSubject);
  const activeExamModule = yield select(Selectors.getActiveExamModule);
  const teachingTag = subjectsTeachingLevel[subjectId];
  const APTeachingTag = changeTeachingTag(teachingTag);
  const subjectSlug = subject.slug;
  const isMathematics = mathematicsIds.includes(subjectId);
  const questionBankCodes = activeExamModule ? ["ib_dp", "osc_dp"] : isMathematics && ["osc_dp"];

  try {
    let { subTopics } = yield call(Api.getSubtopicsInTopic, requestData, getAPJWTToken, APTeachingTag, subjectSlug, questionBankCodes);
    yield put(getSubtopicsInTopic.success({ key, response: subTopics, requestData }));
    if (shouldMapAnswers) {
      const nodeStatus = subTopics.map((subtopic) => ({
        id: subtopic.id,
        flashcard: subtopic.flashcardBox,
        revision: subtopic.revisionGuideBox,
        video: subtopic.videoBox,
        exam: subtopic.examsBox
      }));
      yield put(setNodeStatus(nodeStatus));
    }
  } catch (error) {
    yield put(getSubtopicsInTopic.failure({ key, error }));
    console.error('Error for onGetSubtopicsInTopic: ', error);
  } finally {
    yield put(getSubtopicsInTopic.fulfill({ key }));
  }
}

const mapFoldersResponse = (folders) => {
  let response = sortFoldersByDate(folders);
  return { ...response };
};

function* onSearchFlashcards(action) {
  const activeTeachingLevel = yield select(getActiveTeachingLevel);
  const activeMediaTab = yield select(Selectors.getActiveMediaTab);
  const subjectId = yield select(Selectors.activeSubjectId);
  const subject = yield select(Selectors.getActiveSubject);
  const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const activeExamModule = yield select(Selectors.getActiveExamModule);
  const teachingTag = subjectTeachingLevel[subjectId];
  const subjectSlug = subject.slug;
  const APJWTToken = getJWTToken();
  const APTeachingTag = changeTeachingTag(teachingTag);
  const subjectExamStatus = mathematicsIds.includes(subjectId);
  const questionBankCodes = activeExamModule ? ["ib_dp", "osc_dp",] : subjectExamStatus && ["osc_dp"];
  try {
    yield put(setGoBackToSearch(true));
    if (activeTeachingLevel !== action.payload.subjectId) {
      action.payload.teachingLevel = activeTeachingLevel;
    }
    let response = yield call(Api.searchInFlashcards, action.payload, activeMediaTab, APJWTToken, APTeachingTag, subjectSlug, questionBankCodes);
    const macroFilterList = response && teachingFilterResultsMapToArray(response.items, teachingTag, subject);
    const { data, flashcardSearchResults } = yield call(setOccurencesToPreview, macroFilterList);

    if (activeMediaTab === "flashcard") {
      // const flashcardIds = flashcardSearchResults.map((item) => item.flashcardId);
      // yield put(getFlashcardAnswersRoutine(flashcardIds));
      const data = response?.items.map((item) => ({
        id: item.flashcardId,
        correct: item.answers,
        answered: item.answers !== null
      }));
      yield put(setNodeAnswers(data));

    }
    if (activeMediaTab === 'exams') {
      response.items.map((item, index) => {
        item.category = `${item.sectionTitle} ${item.sectionId}`;
        item.teachingLevel = item.teachingTag;
        item.marks = parseInt(item.points);
        item.sources = item.experienceData.qb_code;
        item.questionId = item.flashcardId
        item.questionTitle = item.title;
        item.category = `${item.topicNumbering} ${item.teachingTag}`;
        item.difficulty = item.difficultyLevel;
        item.paper = item.experienceData.tag_labels.filter(item => item.type === 'paper_type')[0].label;
        item.question_type = `${item.questionType.includes('mcq') ? 'mcq' : 'group'}`;
        item.subTopicTitle = item.subTopicName;
        item.numbering = index + 1;
      });
    }
    if (activeMediaTab === "revision") {
      const nodeMarks = response.items.map(item => ({
        id: item.flashcardId,
        markValue: item.studentStudyStatus
      }));
      yield put(setNodeMark(nodeMarks));
    }
    if (activeMediaTab === "video") {
      const videoProgress = response.items.map(item => ({
        id: item.nodeId,
        totalTime: item.totalDurationInSeconds,
        currentTime: item.totalWatchedInSeconds ? item.totalWatchedInSeconds : "0"
      }));
      const nodeWatched = response.items.map(item => ({
        id: item.flashcardId,
        watchedValue: item.studentStudyStatus,
        partialValue: (item.totalWatchedInSeconds > 0 && (item.totalWatchedInSeconds !== item.totalDurationInSeconds) && item.value === 0) ? 1 : 0
      }));
      yield put(setVideoProgress(videoProgress));
      yield put(setNodeWatched(nodeWatched));
    }
    const userId = yield select(Selectors.getUserId);
    const mediaType = getLastMediaTypeVisited(userId);
    yield put(Actions.Search.setSearchResults(flashcardSearchResults));
    if (mediaType === activeMediaTab || mediaType === "") {
      yield put(Actions.Search.setPreviousSearchResults(flashcardSearchResults));
    }
    const searchIndex = yield select(Selectors.searchIndex);

    if (isNaN(searchIndex)) {
      const currentFlashcardId = yield select(Selectors.currentFlashcardId);
      const searchResults = yield select(Selectors.flashcardSearchResults);

      const currentFlashcardIndex = searchResults.findIndex((item) => {
        return currentFlashcardId && item.flashcardId.toString() === currentFlashcardId.toString();
      });

      if (currentFlashcardIndex >= 0) {
        yield put(Actions.Search.setSearchIndex(currentFlashcardIndex));
        yield put(setNavigationMode('search'));
      }
    }

    yield put(searchFlashcards.success({ response: { count: response.count, items: data } }));
  } catch (error) {
    yield put(searchFlashcards.failure(error));
    console.error('Error for onSearchFlashcards: ', error);
  } finally {
    yield put(searchFlashcards.fulfill());
  }
}

function* onUpdateRecentSearches(action) {
  const response = yield call(Api.updateRecentSearches, action.payload);
  yield put(getRecentSearches(action.payload.subjectId));
  yield put(updateRecentSearches.success(response));
}

function* onSearch(action) {
  try {
    const recentSearchesRequest = yield select(Selectors.recentSearches);
    const subjectId = yield select(Selectors.activeSubjectId);
    const currentSearchTerm = yield select(Selectors.activeSearchTerm);
    let recentSearches = recentSearchesRequest.get('data');
    const searchField = action.payload?.searchField;
    if (currentSearchTerm !== searchField) {
      yield put(Actions.Search.clearSearchResults());
    }

    yield put(Actions.Search.setActiveSearchTerm(searchField));

    yield put(change('search', 'searchField', searchField));

    if (searchField && searchField !== '') {
      recentSearches = recentSearches.unshift(searchField);
      recentSearches = recentSearches.toSet().toList();

      yield put(updateRecentSearches({
        subjectId: subjectId,
        searchedItems: recentSearches.size > 6 ? recentSearches.setSize(6) : recentSearches
      }));

      yield put(search.success({ response: {} }));
    }
  } catch (error) {
    yield put(search.failure(error));
    console.error('Error for onSearch: ', error);
  } finally {
    yield put(search.fulfill());
  }
}

function* getSubjects() {
  try {
    let response = yield call(Api.getSubjects);
    const saveLastTeachingTag = yield call(Api.getLastTeachingTag);
    if (response.length > 0) {
      response.forEach((subject) => {
        subject.childrenIds.unshift(subject.id);
        subject.children.unshift({
          id: subject.id,
          type: 'TEACHING_LEVEL',
          title: 'All'
        });
      });

      orderSubjects(response);
      response = addSubjectSubtitles(response);
      const tvlArray = {};
      response.map((item) =>
        tvlArray[item.id] = saveLastTeachingTag[item.id] || "SL",
      );
      yield put(setTeachingLevelForSubject(tvlArray));
    }
    yield put(Actions.Api.getSubjectsSucceeded({ data: response }));
  } catch (error) {
    yield put(Actions.Api.getSubjectsFailed(error));
    console.error('Error for getSubjects: ', error);
  }
}

function* getSubjectSyllabus(action) {
  try {
    const subjectId = action.subjectId
    const subjects = yield select(Selectors.getSubjects)
    const subject = subjects.find(subject => subject.id === subjectId)
    // const subjectId = yield select(activeSubjectId)

    let response = yield call(Api.getSubjectSyllabus, subjectId)
    response = response?.subjects?.map((item) => {
      return {
        ...item,
        ...(item?.tagging === 'NEW_SUBJECT' ? {year: yearNewSyllabus(subject.slug)} : {year:'2014'}),
      }
    })
    yield put(Actions.Subject.setSubjectSyllabusList(response))
    yield put(Actions.Api.getSubjectSyllabusSucceeded(response))
  } catch (error) {
    console.error('Error for getSubjectSyllabus: ', error);
    yield put(Actions.Api.getSubjectSyllabusFailed(error))
  }
}

export function* authenticate(action) {
  try {
    // Skip login with username / password if token data is already available
    let response;
    if (!action.tokenData) {
      response = yield call(Api.authenticate, action.data);
    } else {
      response = action.tokenData;
    }
    response.createdAt = Math.floor(Date.now() / 1000);
    addCredentialsToLocalStorage(response);
    yield put(Actions.Api.getUser());
    yield take(GET_USER_SUCCEEDED);
    yield put(Actions.Api.authenticateSucceeded(response));
    yield put(Actions.Api.getElementStyles());
    appHistory.push(action.redirectTo || '/flashcard/topics');

    if (!isMBiframe(action.redirectTo)) yield call(getTrialInfo);;

  } catch (error) {
    yield put(Actions.Api.authenticateFailed(error));
    console.error('Error for authenticate: ', error);
  }
}

export function* getTrialInfo() {
  try {
    const response = yield call(Api.getTrialInfo);
    yield put(Actions.Api.getTrialInfoSucceeded({ data: response }));
  } catch (error) {
    yield put(Actions.Api.getTrialInfoFailed(error));
    console.error('Error for getTrialInfo: ', error);
  }
}

export function* getUser() {
  try {
    let response = yield call(Api.getUser);
    let lastMediaType = "";

    if (window.location.search) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      lastMediaType = urlSearchParams.get('mediaType');
    }
    setLastMediaTypeVisited(lastMediaType, response.id);
    yield put(Actions.User.setUserData(response));
    yield put(Actions.Api.getUserSucceeded(response));
    yield put(Actions.Navigation.setActiveExamModule(response.examStatus));
    setJWTToken(response.userJWTToken);
    if (response?.mbAccountUid) {
      const MBPartnersAccessToken = yield call(Api.getMBPartnersAccessToken)
      yield put(Actions.Api.getPartners(response.mbAccountUid, response?.region, MBPartnersAccessToken));
    }
  } catch (error) {
    yield put(Actions.Api.getUserFailed(error));
    yield put(Actions.logout());
    console.error('Error for getUser: ', error);
  }
}

export function* getPartners(action) {
  const url = getPartnersServiceBasedOnRegion(action.region)
  const accessToken = action?.MBPartnersAccessToken?.mbPartnersAccessToken
  const queryParams = {
    account_id: action.data,
  }
  const headers = {
    'Authorization' : `Bearer ${accessToken}`
  }
  try {
    let response = yield call(Api.getPartners, url, queryParams, headers);
    yield put(Actions.Api.getPartnersSucceeded({ "data": response }));

  } catch (error) {
    yield put(Actions.Api.getPartnersFailed(error));
    console.error('Error for getPartners: ', error);
  }
}

export function* getFlashcardContent(action) {
  try {
    const { nodeId, activeMediaTab } = action;
    const PREFIX = 'EXERCISE-';
    const cachedFlashcard = yield select(Selectors.Studying.getFlashcardById, nodeId);
    const getExerciseOnCanvas = yield select(Selectors.getExerciseOnCanvas);
    const activeExerciseId = yield select(Selectors.activeExerciseId);
    const exercisePreviewsResponse = yield select(Selectors.exercisePreviewsResponse);
    if (cachedFlashcard.size === 0) {
      yield put(Actions.Navigation.folderAddButtonActive(false));
      const APJWTToken = getJWTToken();
      const userId = yield select(Selectors.getUserId);
      const subjectId = getLastSubjectVisited(userId);
      const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
      const teachingTag = subjectTeachingLevel[subjectId];
      const response = yield call(Api.getFlashcardContent, nodeId, activeMediaTab, APJWTToken, teachingTag, subjectId);
      if (activeMediaTab === 'exams') {
        response.id = nodeId;
        const examsQuestionIndex = yield select(Selectors.getExamsQuestionIndex);
        if (!examsQuestionIndex.get('data')) {
          yield put(Actions.Navigation.setExamsHeaderList({ type: getFormExams(), parentId: getStoreExamsParentId() }));
        }
        const nodeAnswer = {
          id: response?.answers?.user_response?.uuid,
          correct: response?.answers?.user_response?.correct,
          answered: response?.answers?.user_response?.answered
        }
        yield put(setExamNodeAnswers([nodeAnswer]));
      }

      if (activeMediaTab === "flashcard") {
        response.content = response?.content?.map(side => {
          const sideContentJSON = side.content;
          let sideContent = fromJS(JSON.parse(sideContentJSON));
          return {
            ...side,
            content: sideContent.map(element => insertTableSizes(element)).map(element => insertSrcForImages(element))
          };
        });
        let nodeAnswer;
        if(getExerciseOnCanvas && !!activeExerciseId) {
          const exerciseContentList = exercisePreviewsResponse.toJS()?.[activeExerciseId]?.data ?? [];
          const exerciseContent = exerciseContentList.find((content) => content.flashcardId === nodeId);
          nodeAnswer = [{
            id: PREFIX + nodeId,
            correct: exerciseContent.answers,
            answered: exerciseContent.answers == null ? false : true,
          }]
        } else {
          nodeAnswer = response?.answers?.map(answer => ({
            id: nodeId,
            correct: answer.value,
            answered: answer.value == null ? false : true
          }));
        }
        yield put(setNodeAnswers(nodeAnswer));
      } else {
        if (activeMediaTab === "revision") {
          yield put(setNodeMark([{ id: parseInt(response?.flashcardId), markValue: response?.studentStudyStatus }]));
        } else {
          yield put(setVideoProgress([{ id: parseInt(response?.flashcardId), totalTime: response?.totalDurationInSeconds, currentTime: response?.totalWatchedInSeconds }]));
          yield put(setNodeWatched([{ id: parseInt(response?.flashcardId), watchedValue: response?.studentStudyStatus, partialValue: (response?.totalWatchedInSeconds > 0 && (response?.totalWatchedInSeconds !== response?.totalDurationInSeconds) && response?.value === 0) ? 1 : 0 }]));
        }
      }
      yield put(Actions.Navigation.folderAddButtonActive(true));
      yield put(Actions.Api.getFlashcardContentSucceeded({ data: response }));
    }
  } catch (error) {
    yield put(Actions.Api.getFlashcardContentFailed(error));
    console.error("Error for getFlashcardContent: ", error);
  }
}

export function* setRevisionLink(action) {
  try {
    const subjectId = yield select(activeSubjectId);
    let requestData = {
      url: action.url,
      subjectId: subjectId
    }
    const response = yield call(Api.setRevisionLink, requestData);
    if (response.id) {
      yield put(Actions.Api.setRevisionLinkSucceeded({ data: response }));
      const currentFlashcardId = yield select(Selectors.currentFlashcardId);
      addBackFlashcardToLocalStorage("flashcard" + "-" + response.id, 'revision' + "-" + currentFlashcardId);
      yield put(Actions.Studying.selectFlashcard(response.id));
    } else {
      yield put(Actions.Notification.showAlert({
        content: <NoLinkNotification title={'Page unavailable'} text={'We\'re sorry, the page you\'re trying to access is not yet available. You\'ll have access to it very soon. We\'ll keep you updated!'} />,
        buttons: false,
      }));
    }
  } catch (error) {
    yield put(Actions.Api.setRevisionLinkFailed(error));
    console.error("Error for setRevisionLink: ", error);
  }
}

export function* getElementStyles() {
  try {
    const response = yield call(Api.getElementStyles);

    let head = document.head || document.getElementsByTagName('head')[0];

    for (let item of response) {
      let style = document.createElement('style');
      style.type = 'text/css';
      if (style.styleSheet) {
        style.styleSheet.cssText = item.styleSheet;
      } else {
        style.appendChild(document.createTextNode(item.styleSheet));
      }
      head.appendChild(style);
    }

  } catch (error) {
    yield put(Actions.Api.getFlashcardContentFailed(error));
    console.error("Error for getElementStyles: ", error);
  }
}

function getProgrammeSubjectName(title, slug) {
  switch (slug) {
    case "business-management-2025":
      return "business management"
    
    case "maths-a-amp-a":
    case "maths-a-amp-i":
      return slug;
    
    default:
      return title
  }
}

export function* getExams() {
  try {
    const hasMBFromSchool = yield select(isMBFromSchool);
    const subject = yield select(Selectors.getActiveSubject);
    const getQuestionBankCode = yield select(Selectors.getActiveExamsTab);
    const APJWTToken = getJWTToken();
    const subjectId = yield select(Selectors.activeSubjectId);
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
    const teachingTag = subjectTeachingLevel[subjectId];
    const APTeachingTag = changeTeachingTag(teachingTag);
    const { subject:subjectTitle, year } = JSON.parse(subject?.nodeProps ?? JSON.stringify({}))
    const by_tag_content_areas = subjectTitle ? subjectTitle.toLowerCase() + ' ' + year : {}
    const requestData = {
      by_questionbank_code: getQuestionBankCode,
      by_programme_subject_name: getProgrammeSubjectName(subject.title, subject.slug),
      by_tag_type: '',
      by_tag_values: '',
      sub_url: 'v1/integrations/osc/experiences',
      api_type: 'experiences',
      jwt: APJWTToken,
      by_tag_teaching_levels: APTeachingTag,
      by_tag_content_areas
    }
    const response = yield call(Api.getExams, requestData);
    yield put(Actions.Api.getExamsSucceeded({ data: response }));
    const data = response?.data.slice().sort((a, b) => a.examTitle > b.examTitle ? 1 : -1);
    const paperId = data?.[0]?.papers?.filter((item) => ['Paper 1', 'Paper 1A'].includes(item.paperTitle))?.[0]?.uId;
    if(!hasMBFromSchool){
      yield put(Actions.Api.getExamPreview(paperId, data.length === 0))
    }
  } catch (error) {
    yield put(Actions.Api.getExamsFailed(error));
    console.error("Error for getExams: ", error);
  }
}

export function* getExamPreview(action) {
  const APJWTToken = getJWTToken();
  const subjectId = yield select(Selectors.activeSubjectId);
  const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const teachingTag = subjectTeachingLevel[subjectId];
  const APTeachingTag = changeTeachingTag(teachingTag);
  const requestData = {
    by_experience_uuid: action.data,
    sub_url: 'v1/integrations/osc/segments',
    api_type: 'segments',
    jwt: APJWTToken,
    by_tag_teaching_levels: APTeachingTag
  };
  try {
    const response = yield call(Api.getExams, requestData);
    response?.data?.data?.map((item, index) => {
      item.category = `${item.sectionTitle} ${item.sectionId}`;
      item.teachingLevel = item.teachingTag;
      item.sources = item.experienceData.qb_code;
      item.type = item.questionType;
      item.answered = false;
      item.flashcardId = item.sectionId;
      item.paperType = item.experienceData.tag_labels.filter(item => item.type === 'paper_type')[0].label;
      item.numbering = index + 1;
      item.correct = null;
    });
    const nodeAnswer = response?.data?.data?.map(answer => ({
      id: answer?.uuid,
      correct: answer?.user_response?.correct,
      answered: answer?.user_response?.answered
    }))
    yield put(setExamNodeAnswers(nodeAnswer));
    yield put(Actions.Api.getExamPreviewSucceeded({ data: response }));
    const examFilters = yield select(Selectors.examsofFilterItems);
    yield put(Actions.Desk.setFilterExamStatusCount(action.data, examFilters.toJS().teachingLevel));
  } catch (error) {
    yield put(Actions.Api.getExamPreviewFailed(error));
    console.error("Error for getExamPreview: ", error);
  }
}

export function* getExamHeader(action) {
  const APJWTToken = getJWTToken();
  try {
    const response = yield call(Api.getExamHeader, action.data, APJWTToken);
    yield put(Actions.Api.getExamHeaderSucceeded({ data: response }));
  } catch (error) {
    yield put(Actions.Api.getExamHeaderFailed(error));
    console.error("Error for getExamHeader: ", error);
  }
}

export function* getStudentPackages() {
  try {
    const response = yield call(Api.getStudentPackages);
    yield put(Actions.Api.getStudentPackagesSucceeded({ data: response }));
  } catch (error) {
    yield put(Actions.Api.getStudentPackagesFailed(error));
    console.error("Error for getStudentPackages: ", error);
  }
}

export function* addVoucher(action) {
  try {
    yield call(Api.addVoucher, action.data);

    yield put(Actions.Api.addVoucherSucceeded());

    const swalContent = document.createElement('div');
    ReactDOM.render(<Notification title={'Saved'} icon={'savedIcon'} />, swalContent);
    swal({ content: swalContent, buttons: false, timer: 2000 });

    yield put(changeMenuPathAction('subscriptions'));

  } catch (error) {
    yield put(Actions.Api.addVoucherFailed(error));
    const firstError = Object.keys(error)[0];
    const swalContent = document.createElement('div');
    ReactDOM.render(<WarningNotification title={errorReasonsEnum[firstError]} icon={'warningIcon'} />, swalContent);
    yield put(Actions.Notification.showAlert({ content: swalContent, buttons: false }, function* (response) {
      if (response?.showStore) {
        yield put(Actions.Navigation.openStore());
      }
    }));
    console.error("Error for addVoucher: ", error);
  }
}

export function* getProfile() {
  try {
    const response = yield call(Api.getProfile);
    yield put(Actions.Api.getProfileSucceeded({ data: response }));
  } catch (error) {
    yield put(Actions.Api.getProfileFailed(error));
    console.error("Error for getProfile: ", error);
  }
}

export function* saveProfile(action) {
  try {
    const dateOfBirth = action.data.dateOfBirth;
    action.data.dateOfBirth = moment(dateOfBirth).unix();

    yield call(Api.saveProfile, action.data);
    yield put(Actions.Api.saveProfileSucceeded({ data: action.data }));
    yield put(Actions.User.setUserData(action.data));

    const swalContent = document.createElement('div');
    ReactDOM.render(<Notification title={'Saved'} icon={'savedIcon'} />, swalContent);
    swal({ content: swalContent, buttons: false, timer: 2000 });

    yield put(changeMenuPathAction('profile'));
  } catch (error) {
    yield put(Actions.Api.saveProfileFailed(error));
    console.error("Error for saveProfile: ", error);
  }
}

export function* getContactDetails() {
  try {
    const response = yield call(Api.getContactDetails);
    yield put(Actions.Api.getContactDetailsSucceeded({ data: response }));
  } catch (error) {
    yield put(Actions.Api.getContactDetailsFailed(error));
    console.error("Error for getContactDetails: ", error);
  }
}

export function* saveContactDetails(action) {
  try {
    const response = yield call(Api.saveContactDetails, action.data);
    yield put(Actions.Api.getContactDetailsSucceeded({ data: response }));

    const swalContent = document.createElement('div');
    ReactDOM.render(<Notification title={'Saved'} icon={'savedIcon'} />, swalContent);
    swal({ content: swalContent, buttons: false, timer: 2000 });

    yield put(changeMenuPathAction('profile'));
  } catch (error) {
    yield put(Actions.Api.getContactDetailsFailed(error));
    console.error("Error for saveContactDetails: ", error);
  }
}

export function* changePassword(action) {
  try {
    const response = yield call(Api.changePassword, action.data);
    yield put(Actions.Api.changePasswordSucceeded({ data: response }));

    const swalContent = document.createElement('div');
    ReactDOM.render(<Notification title={'Saved'} icon={'savedIcon'} />, swalContent);
    swal({ content: swalContent, buttons: false, timer: 2000 });

    yield put(changeMenuPathAction('profile'));
  } catch (error) {
    yield put(Actions.Api.changePasswordFailed(error));
    swal(error.password, { buttons: false, timer: 1500 });
    console.error("Error for changePassword: ", error);
  }
}

export function* changeUsername(action) {
  try {
    const response = yield call(Api.changeUsername, action.data);
    yield put(Actions.Api.changeUsernameSucceeded({ data: response }));
    yield put(Actions.Api.getUser());

    const swalContent = document.createElement('div');
    ReactDOM.render(<Notification title={'Saved'} icon={'savedIcon'} />, swalContent);
    swal({ content: swalContent, buttons: false, timer: 2000 });

    yield put(changeMenuPathAction('profile'));
  } catch (error) {
    yield put(Actions.Api.changeUsernameFailed(error));
    swal(error.password, { buttons: false, timer: 1500 });
    console.error("Error for changeUsername: ", error);
  }
}

export function* setAnswerFlashcard(action) {
  try {
    const { answer, prevAnswer } = action.data;
    const flashcardId = yield select(Selectors.Studying.currentFlashcardId);
    const getExerciseOnCanvas = yield select(Selectors.getExerciseOnCanvas);
    const activeExerciseId = yield select(Selectors.activeExerciseId);
    let finalAnswer, finalBoolAnswer;
    if(getExerciseOnCanvas) {
      yield call(Api.setAnswerFlashcardByExercise, flashcardId, activeExerciseId, { assessmentMarkValue: action.data.answer });
    }
    else {
      yield call(Api.setAnswerFlashcard, flashcardId, { assessmentMarkValue: action.data.answer });
    }
    if (answer === -1) {
      finalAnswer = null;
      finalBoolAnswer = false;
    } else {
      finalAnswer = action.data.answer;
      finalBoolAnswer = true;
    }
    yield put(Actions.Api.setAnswerFlashcardSucceeded());
    if(getExerciseOnCanvas) {
      let exerciseStatus = yield select((state) => exerciseStatusById(state, activeExerciseId))

      const previousValue = prevAnswer === 0 ? "incorrect" : (prevAnswer === 1 ? "correct" : "neither");
      const currentValue = answer === 0 ? "incorrect" : (answer === 1 ? "correct" : "neither");

      let calculatedExerciseFlashcardStatus = {
        ...exerciseStatus.get("flashcard").toJS(),
        total: exerciseStatus.get("flashcard").get("total"),
        correct: exerciseStatus.get("flashcard").get("correct"),
        neither: exerciseStatus.get("flashcard").get("neither"),
        incorrect: exerciseStatus.get("flashcard").get("incorrect"),
      }

      if (answer !== -1) {
        calculatedExerciseFlashcardStatus[currentValue] += 1
      }
      if (prevAnswer !== "no-answer" || answer === -1) {
        calculatedExerciseFlashcardStatus[previousValue] -= 1
      }
      const PREFIX = 'EXERCISE-';
      const nodeStatus = {
        id: PREFIX + activeExerciseId,
        flashcard: calculatedExerciseFlashcardStatus
      };
      yield put(setNodeStatus([nodeStatus]));
      yield put(setNodeAnswers([{ id: PREFIX + flashcardId, correct: finalAnswer, answered: finalBoolAnswer }]));
    }
    else {
      yield put(setNodeAnswers([{ id: flashcardId, correct: finalAnswer, answered: finalBoolAnswer }]));
      yield onFlashcardAnswered({ correct: answer, prevAnswer: prevAnswer });
    }
  } catch (error) {
    yield put(Actions.Api.setAnswerFlashcardFailed(error));
    console.error("Error for setAnswerFlashcard: ", error);
  }
}

function* onFlashcardAnswered(answer) {
  try {
    const flashcardId = yield select(Selectors.Studying.currentFlashcardId);
    const currentFlashcard = yield select(Selectors.Studying.getFlashcardById, flashcardId);
    const parentTopicId = currentFlashcard.get('parentsData').find(item => item.get('type') === 'TOPIC').get('id');
    const parentSubTopicId = currentFlashcard.get('parentsData').find(item => item.get('type') === 'SUB_TOPIC').get('id');

    const activeSubjectId = yield select(Selectors.activeSubjectId);
    let quickStatus = yield select((state) => quickStatusById(state, activeSubjectId));
    let topicStatus = yield select((state) => statusById(state, parentTopicId));
    let subtopicStatus = yield select((state) => statusById(state, parentSubTopicId));

    const previousValue = answer.prevAnswer === 0 ? "incorrect" : (answer.prevAnswer === 1 ? "correct" : "neither");
    const currentValue = answer.correct === 0 ? "incorrect" : (answer.correct === 1 ? "correct" : "neither");
    let calculatedQuickStatus = {
      total: quickStatus.get("flashcard").get("total"),
      correct: quickStatus.get("flashcard").get("correct"),
      neither: quickStatus.get("flashcard").get("neither"),
      incorrect: quickStatus.get("flashcard").get("incorrect"),
    }

    let calculatedTopicFlashcardStatus = {
      ...topicStatus.get("flashcard").toJS(),
      total: topicStatus.get("flashcard").get("total"),
      correct: topicStatus.get("flashcard").get("correct"),
      neither: topicStatus.get("flashcard").get("neither"),
      incorrect: topicStatus.get("flashcard").get("incorrect"),
    }

    let calculatedSubtopicFlashcardStatus = {
      ...subtopicStatus.get("flashcard").toJS(),
      total: subtopicStatus.get("flashcard").get("total"),
      correct: subtopicStatus.get("flashcard").get("correct"),
      neither: subtopicStatus.get("flashcard").get("neither"),
      incorrect: subtopicStatus.get("flashcard").get("incorrect"),
    }

    if (answer.correct !== -1) {
      calculatedTopicFlashcardStatus[currentValue] += 1
      calculatedSubtopicFlashcardStatus[currentValue] += 1
      calculatedQuickStatus[currentValue] += 1
    }
    if (answer.prevAnswer !== "no-answer" || answer.correct === -1) {
      calculatedQuickStatus[previousValue] -= 1
      calculatedTopicFlashcardStatus[previousValue] -= 1
      calculatedSubtopicFlashcardStatus[previousValue] -= 1
    }

    yield put(setQuickNodeStatus({
      id: activeSubjectId,
      flashcard: calculatedQuickStatus,
      revision: quickStatus.get("revision"),
      video: quickStatus.get("video"),
      exam: quickStatus.get("exam"),
    }));

    yield put(setNodeStatus([
      {
        id: parentTopicId,
        flashcard: calculatedTopicFlashcardStatus,
        revision: topicStatus.get("revision"),
        video: topicStatus.get("video"),
        exam: quickStatus.get("exam"),
      }]));
    yield put(setNodeStatus([
      {
        id: parentSubTopicId,
        flashcard: calculatedSubtopicFlashcardStatus,
        revision: subtopicStatus.get("revision"),
        video: subtopicStatus.get("video"),
        exam: quickStatus.get("exam"),
      }]));
  } catch (error) {
    console.error("Error for onFlashcardAnswered: ", error)
  }
}

export function insertSrcForImages(node) {
  try {
    let newNode = node;
    if (node.get('tag') === 'img') {
      const imgUrl = Api.createMediaUrl(node.getIn(['attr', 'alt']));
      newNode = node.setIn(['attr', 'src'], imgUrl);
    }
    const children = node.get('child', List());
    return newNode.set('child', children.map((child) => insertSrcForImages(child)));
  } catch (error) {
    console.error("Error for insertSrcForImages: ", error);
  }
}

export function insertTableSizes(el) {
  try {
    if (el.getIn(['attr', 'class'], '').indexOf('table') < 0) return el;

    const height = el.getIn(['dataAttributes', 'cellSizes', 'height']);
    const width = el.getIn(['dataAttributes', 'cellSizes', 'width']);

    return el.update('child', (initialRows) => {
      return initialRows.map((elm, rowsIdx) => {
        return elm
          .setIn(['attr', 'style'], `height:${height.get(rowsIdx)}%`)
          .update('child', (initialCols) => {
            return initialCols.map((colsElm, colsIdx) => {
              return colsElm.setIn(['attr', 'style'], `width:${width.get(colsIdx)}%`);
            });
          });
      });
    });
  } catch (error) {
    console.error("Error for insertTableSizes: ", error)
  }
}

function* mapExerciseData(payload) {
  try {
    let requestData = {};
    const { dueDate, title, comment, hour, minute } = payload;
    const flashcards = yield select(getSelectedTreeNodes);
    if (flashcards.size === 0) return null;
    requestData = {
      title: title,
      comment: comment,
      flashcards: flashcards
    }

    if (dueDate) {
      const dueDateHour = hour;
      const dueDateMinute = minute;
      const processedDueDate = moment(dueDate).add(dueDateHour, 'hours').add(dueDateMinute, 'minutes');
      requestData = { ...requestData, dueDate: processedDueDate.unix() }
    }

    return requestData;
  } catch (error) {
    console.error("Error for mapExerciseData: ", error);
  }
}

export function* setOccurencesToPreview(data) {
  try {
    let flashcardSearchResults = [];
    const searchField = yield select(activeSearchTerm);
    let activeMediaTab = yield select(Selectors.getActiveMediaTab);
    if (activeMediaTab === "flashcard") {
      data.map(item => {
        item.category = `${item.topicNumbering} ${item.teachingLevel}`;
        item.occurences = 0;

        let fullTextContent = item.preview.textContent.join(' ');
        let fullSvgContent = item.preview.svgContent.join(' ');

        if (fullTextContent === '' || fullTextContent === ' ') {
          fullTextContent = '';
        }

        if (fullSvgContent === '' || fullSvgContent === ' ') {
          fullSvgContent = '';
        }

        item.preview.fullText = fullTextContent + fullSvgContent;

        const flashcardId = item.flashcardId;
        const textContent = item.preview.textContent;
        const svgContent = item.preview.svgContent;
        const sides = Math.max(textContent.length, svgContent.length);

        if (searchField) {
          for (let i = 0; i < sides; i++) {
            const text = textContent[i];
            const svgText = svgContent[i];
            const mergedText = text + ' ' + svgText;
            const searchIndexes = buildSearchResultsArray(mergedText, searchField, flashcardId, i);
            item.occurences += searchIndexes.length;
            flashcardSearchResults = flashcardSearchResults.concat(searchIndexes);
          }
        }
      });
      return { data, flashcardSearchResults };
    } else {
      data.map(item => {
        item.category = `${item.topicNumbering} ${item.teachingLevel}`;
        item.occurences = 0;

        let fullTitle = item.title;
        let fullDescription = item.shortDescription;

        if (fullTitle === '' || fullTitle === ' ' || fullTitle === null) {
          fullTitle = '';
        }

        if (fullDescription === '' || fullDescription === ' ' || fullDescription === null) {
          fullDescription = '';
        }

        item.fullText = fullTitle;

        const flashcardId = item.flashcardId;
        const titleContent = item.title;
        if (searchField) {
          const text = titleContent;
          const mergedText = text;
          const searchIndexes = buildSearchResultsArray(mergedText, searchField, flashcardId, 0);
          item.occurences += searchIndexes.length;
          flashcardSearchResults = flashcardSearchResults.concat(searchIndexes);
        }
      });
      return { data, flashcardSearchResults };

    }
  } catch (error) {
    console.error("Error for setOccurencesToPreview: ", error);
  }
}

//Loggedin Device List
function* getLoggedinDevices() {
  try {
    const response = yield call(Api.getLoggedinDevices);
    yield put(Actions.Api.getLoggedinDevicesSucceeded(response));
  } catch (error) {
    yield put(Actions.Api.getLoggedinDevicesFailed(error));
    console.error("Error for getLoggedinDevices: ", error);
  }
}

//Remove device list
function* removeLoggedinDevice(action) {
  try {
    yield call(Api.removeLoggedinDevice, action.data);
    yield put(registerDevice());
    yield put(Actions.Api.removeLoggedinDevicesSucceeded());
    yield put(toggleDeviceManagerOverlay(false));
    appHistory.push('/flashcard/topics');
  } catch (error) {
    console.error('Error for removeLoggedinDevice: ', error);
  }
}

function* onGetTopicsDetails(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  try {
    const APJWTToken = getJWTToken();
    const subjectId = yield select(Selectors.activeSubjectId);
    const requestData = { ...action.payload, subjectId: subjectId };
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
    const teachingTag = subjectTeachingLevel[subjectId];
    const subject = yield select(Selectors.getActiveSubject);
    const subjectSlug = subject.slug;
    const APTeachingTag = changeTeachingTag(teachingTag);
    const activeExamModule = yield select(Selectors.getActiveExamModule);
    const subjectExamStatus = [14982, 15075].includes(subjectId);
    const questionBankCodes = activeExamModule ? ["ib_dp", "osc_dp"] : subjectExamStatus && ["osc_dp"];
    let { data: response } = yield call(Api.getTopicsDetails, requestData, APJWTToken, APTeachingTag, subjectSlug, questionBankCodes);
    const nodeStatus = [{
      id: response.id,
      flashcard: response.flashcardBox,
      revision: response.revisionGuideBox,
      video: response.videoBox,
      exam: response.examsBox
    }];
    yield put(setNodeStatus(nodeStatus));
    yield put(Actions.Desk.setExamTopicStatus([{ id: response.id, exam: response.examsBox }]));
    yield put(getTopicsDetails.success({ key, response, requestData }));

  } catch (error) {
    yield put(getTopicsDetails.failure({ key, error }));
    console.error('Error for onGetTopicsDetails: ', error);
  }
  yield put(getTopicsDetails.fulfill({ key }));
}

function* onGetPreviewsListByType(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  const { mediaType, id } = action.payload;
  try {
    const requestData = action.payload;
    const APJWTToken = getJWTToken();
    const subjectId = yield select(Selectors.activeSubjectId);
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
    const teachingTag = subjectTeachingLevel[subjectId];
    const APTeachingTag = changeTeachingTag(teachingTag);
    const subject = yield select(Selectors.getActiveSubject);
    const subjectSlug = subject.slug;
    const activeExamModule = yield select(Selectors.getActiveExamModule);
    const subjectExamStatus = mathematicsIds.includes(subjectId);
    const questionBankCodes = activeExamModule ? ['ib_dp', 'osc_dp'] : subjectExamStatus && ['osc_dp'];
    let resp = yield call(Api.getPreviewsListByType, requestData, APJWTToken, APTeachingTag, subjectSlug, questionBankCodes);
    let response = resp.previews;
    if (response.length !== 0) {
      if (mediaType === "flashcard") {
        resp.previews.map(item => {
          item.preview.fullText = item.preview.textContent.join(' ');
          item.category = `${item.topicNumbering} ${item.teachingLevel}`;
        });
        const data = response?.map((item) => ({
          id: item.flashcardId,
          correct: item.answers,
          answered: item.answers == null ? false : true
        }));
        yield put(setNodeAnswers(data));
        // yield put(getFlashcardAnswersRoutine(resp.previews.map(item => item.flashcardId)));
      } else if (mediaType === "exams") {
        resp.previews.map((item, index) => {
          item.qb_code = item.experienceData.qb_code;
          item.category = `${item.topicNumbering} ${item.teachingLevel}`;
          item.paperType = item.experienceData.tag_labels.filter(item => item.type === 'paper_type')[0].label;
          item.numbering = index + 1;
          item.flashcarduuId = `${item.subTopicId}-${item.flashcardId}`;
          item.topicUniqueNumber = `${item.topicNumbering}.${index + 1}`;
          item.question_type = `${item.questionType.includes('mcq') ? 'mcq' : 'group'}`;
          item.isTopic = true;
        });
        const nodeAnswer = resp.previews.map(answer => ({
          id: answer.flashcardId,
          correct: answer.answers.user_response.correct,
          answered: answer.answers.user_response.answered
        }))
        yield put(setExamNodeAnswers(nodeAnswer));
      } else {
        resp.previews.map(item => {
          item.category = `${item.topicNumbering} ${item.teachingLevel}`;
        });
        if (mediaType === "revision") {
          const nodeMark = resp.previews.map(item => ({
            id: item.nodeId,
            markValue: item.studentStudyStatus
          }));
          yield put(setNodeMark(nodeMark));
        } else {
          const videoProgress = resp.previews.map(item => ({
            id: item.nodeId,
            totalTime: item.totalDurationInSeconds,
            currentTime: item.totalWatchedInSeconds ? item.totalWatchedInSeconds : "0"
          }));
          yield put(setVideoProgress(videoProgress));
          const nodeWatched = resp.previews.map(item => ({
            id: item.nodeId,
            watchedValue: item.studentStudyStatus,
            partialValue: (item.totalWatchedInSeconds > 0 && ((item.totalWatchedInSeconds !== item.totalDurationInSeconds) && item.value === 0)) ? 1 : 0
          }));
          yield put(setNodeWatched(nodeWatched));
        }
      }
      response.sort((a, b) => {
        if (a.numbering < b.numbering) return -1;
        if (a.numbering > b.numbering) return 1;
        return 0;
      });
      if (mediaType === "flashcard") {
        saveFirstMediaNodeId(response[0].flashcardId);
      } else {
        saveFirstMediaNodeId(response[0].id);
      }
    }
    yield put(getPreviewsListByType.success({ key, response, requestData }));
    if (mediaType === "exams") {
      yield put(Actions.Desk.updateExamQuestionByFilter({ response }));
      yield put(getExamPreviewsListByType({ response }));
      const examFilters = yield select(Selectors.examsofFilterItems);
      yield put(Actions.Desk.setFilterTopicExamStatusCount(id, examFilters.toJS().teachingLevel, examFilters.toJS().difficulty, examFilters.toJS().source, examFilters.toJS().paper));
    }
  } catch (error) {
    yield put(getPreviewsListByType.failure({ key, error }));
    console.error('Error for onGetPreviewsListByType: ', error);
  } finally {
    yield put(getPreviewsListByType.fulfill({ key }));
  }
}

//SMPP-LAGGY
function* onSetQuickStatus(action) {
  const APJWTToken = getJWTToken();
  const subjectId = yield select(Selectors.activeSubjectId);
  const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const teachingTag = subjectTeachingLevel[subjectId];
  const activeSubject = yield select(Selectors.getActiveSubject);
  const subjectSlug = activeSubject.slug;
  const APTeachingTag = changeTeachingTag(teachingTag);
  const activeExamModule = yield select(Selectors.getActiveExamModule);
  const subjectExamStatus = mathematicsIds.includes(subjectId);
  const questionBankCodes = activeExamModule ? ["ib_dp", "osc_dp"] : subjectExamStatus && ["osc_dp"];
  const { subject:subjectTitle, year } = JSON.parse(activeSubject?.nodeProps ?? '{}')
  const by_tag_content_areas = subjectTitle ? subjectTitle.toLowerCase() + ' ' + year : undefined;
  try {
    let quickStatusResponse = yield call(Api.getQuickStatusData, action.data, APJWTToken, APTeachingTag, subjectSlug, questionBankCodes, by_tag_content_areas);

    let quickResponse = quickStatusResponse.data;
    const quickNodeStatus = {
      id: action.data,
      flashcard: quickResponse.flashcardBox,
      revision: quickResponse.revisionGuideBox,
      video: quickResponse.videoBox,
      exam: quickResponse.examsBox
    };
    yield put(setQuickNodeStatus(quickNodeStatus));
    yield put(Actions.Api.setQuickStatusSucceeded({ data: quickStatusResponse }));
    yield put(multipleSourceUpdate(quickResponse.examsBox.sourceTypeEnable));
  } catch (error) {
    yield put(Actions.Api.setQuickStatusFailed(error));
    console.error('Error for onSetQuickStatus: ', error);
  }
}

function* getFeature() {
  try {
    let response = yield call(Api.getFeature);
    yield put(Actions.Api.getFeatureSucceeded({ data: response }));
  } catch (error) {
    yield put(Actions.Api.getFeatureFailed());
    console.error('Error for getFeature: ', error);
  }
}

function* closeFeature(action) {
  try {
    yield call(Api.closeFeature, action.id);
    yield put(Actions.Api.closeFeatureSucceeded());
  } catch (error) {
    yield put(Actions.Api.closeFeatureFailed());
    console.error('Error for closeFeature: ', error);
  }
}

function* setExamsHeaderList(action) {
  const APJWTToken = getJWTToken();
  yield put(Actions.Desk.updateExamsQuestionIndex([], 0));
  yield put(Actions.Navigation.updateExamsList(0, 0));
  yield put(Actions.Desk.setExamQuestionNumber(null));
  yield put(Actions.Desk.paperAllQuestionData([]));
  const userId = yield select(Selectors.getUserId);
  const subjectId = getLastSubjectVisited(userId);
  const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const teachingTag = subjectTeachingLevel[subjectId];
  const APTeachingTag = changeTeachingTag(teachingTag);
  const type = action?.data?.type || getFormExams();
  const id = action?.data?.parentId || getStoreExamsParentId();
  const examFilter = yield select(Selectors.examsofFilterItems);
  const assessment = yield select(listOfFilterCheckedItems);
  // yield take([SET_CURRENT_FLASHCARD_ID, GET_FLASHCARD_CONTENT_SUCCEEDED]);
  const currentId = yield select(Selectors.Studying.currentFlashcardId);
  let requestData;
  if (type === 'exams') {
    requestData = {
      by_experience_uuid: id,
      sub_url: 'v1/integrations/osc/segments',
      api_type: 'segments',
      jwt: APJWTToken,
      by_tag_teaching_levels: APTeachingTag
    }
  } else {
    requestData = { id: id, mediaType: 'exams', type: 1 }
  }
  try {
    let endPoint = type === 'exams' ? Api.getExams : Api.getPreviewsListByType;
    const activeExamModule = yield select(Selectors.getActiveExamModule);
    const isMathamatics = mathematicsIds.includes(subjectId);
    const questionBankCodes = activeExamModule ? ["ib_dp", "osc_dp"] : isMathamatics && ["osc_dp"];
    const activeSubject = yield select(Selectors.getActiveSubject);
    const subjectSlug = activeSubject?.slug;
    let res = yield call(endPoint, requestData, APJWTToken, APTeachingTag, subjectSlug, questionBankCodes);
    const data = type === 'exams' ? res.data.data : res.previews;
    data.map((item, index) => {
      item.numbering = index + 1;
    });
    let examData = [], currentIndex, finalData, activeIndex, examDataWithFilter;
    if (type === 'exams') {
      currentIndex = data.findIndex(i => i.uuid === currentId);
      const newListItem = examTeachingFilter(teachingTag, data, examFilter.toJS().teachingLevel, 'exams');
      const filterType = data[0].questionType.includes('mcq') ? examFilter.toJS().mcq : assessment.toJS()['exams'];
      const userResponseFilterData = returnExamsStatusFilter(newListItem, filterType, data[0].questionType.includes('mcq') ? 'Paper 1' : 'Paper 2');
      activeIndex = userResponseFilterData.findIndex((item) => item.uuid == currentId)
      finalData = userResponseFilterData;
      const examNodeAnswers = data.map(answer => ({
        id: answer.uuid,
        correct: answer.user_response.correct,
        answered: answer.user_response.answered
      }));
      yield put(setExamNodeAnswers(examNodeAnswers));
      data.map((item) => {
        item.paperType = item.experienceData.tag_labels.filter(item => item.type === 'paper_type')[0].label;
      })
    } else {
      data.map((item, index) => {
        item.qb_code = item.experienceData.qb_code;
        item.category = `${item.topicNumbering} ${item.teachingLevel}`;
        item.paperType = item.experienceData.tag_labels.filter(item => item.type === 'paper_type')[0].label;
        item.numbering = index + 1;
        item.flashcarduuId = `${item.subTopicId}-${item.flashcardId}`;
        item.topicUniqueNumber = `${item.topicNumbering}.${index + 1}`;
        item.question_type = `${item.questionType.includes('mcq') ? 'mcq' : 'group'}`
        item.isTopic = true;
      });
      let response = data
      const examNodeAnswers = data.map(answer => ({
        id: answer.flashcardId,
        correct: answer.answers.user_response.correct,
        answered: answer.answers.user_response.answered
      }));
      yield put(setExamNodeAnswers(examNodeAnswers));
      yield put(Actions.Desk.updateExamQuestionByFilter({ response }))
      yield put(getExamPreviewsListByType({ response }))
      const sourceFilterData = sourceFilter(data, examFilter.toJS().source, 'exams');
      const paperFilterData = paperFilter(sourceFilterData, examFilter.toJS().paper, 'exams');
      const difficultyData = difficultyFilter(paperFilterData, examFilter.toJS().difficulty, 'exams')
      const teachingTagData = topicTeachingFilter(teachingTag, difficultyData, examFilter.toJS().teachingLevel, "topics", 'exams')
      const multipleChooseData = questionTypeFindV1(fromJS(teachingTagData)).size > 0 && questionTypeFindV1(fromJS(teachingTagData)).getIn(['mcq', 'mcq'])
      const freeResponseData = questionTypeFindV1(fromJS(teachingTagData)).size > 0 && questionTypeFindV1(fromJS(teachingTagData)).getIn(['group', 'group'])
      const examAnswerFilter = returnExamAnswerFilter(multipleChooseData && multipleChooseData.toJS(), freeResponseData && freeResponseData.toJS(), examFilter.toJS().mcq, assessment.toJS()['exams'], 'exams', teachingTagData)
      const uniqueExamData = getUniqueExamPreviewData(examAnswerFilter);

      activeIndex = uniqueExamData.findIndex((item) => item.flashcarduuId == `${getExamSubTopicId()}-${currentId}`)
      finalData = uniqueExamData
    }

    examDataWithFilter = finalData.map((val) => {
      let numbering = val.numbering
      return { index: numbering, uuid: type === 'exams' ? val.uuid : val.flashcardId, user_response: type === 'exams' ? val.user_response : val.answers.user_response, teachingTag: val.teachingTag }
    })
    examData = data.map((val) => {
      let numbering = val.numbering
      return { index: numbering, uuid: type === 'exams' ? val.uuid : val.flashcardId, user_response: type === 'exams' ? val.user_response : val.answers.user_response, teachingTag: val.teachingTag, paperType: val.paperType }
    })

    if (currentId) {
      yield put(Actions.Desk.paperAllQuestionData(examData))
      yield put(Actions.Desk.updateExamsQuestionIndex(examDataWithFilter, activeIndex + 1))
      yield put(Actions.Studying.selectExams(currentId, type, examDataWithFilter.length, activeIndex + 1))
      yield put(Actions.Desk.setExamQuestionNumber(currentIndex + 1))
    }
  } catch (error) {
    console.error('Error for setExamsHeaderList: ', error);
  }
}

function* progressPopupOverlayNext(action) {
  const APJWTToken = getJWTToken();
  const subjectId = yield select(Selectors.activeSubjectId);
  const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const teachingTag = subjectTeachingLevel[subjectId]
  const APTeachingTag = changeTeachingTag(teachingTag)
  const requestData = {
    by_experience_uuid: action.data,
    sub_url: 'v1/integrations/osc/segments',
    api_type: 'segments',
    jwt: APJWTToken,
    by_tag_teaching_levels: APTeachingTag
  };
  try {
    const response = yield call(Api.getExams, requestData);
    response.data.data.map((item, index) => {
      item.category = `${item.sectionTitle} ${item.sectionId}`;
      item.teachingLevel = item.teachingTag;
      item.sources = item.experienceData.qb_code;
      item.type = item.questionType;
      item.answered = false;
      item.flashcardId = item.sectionId;
      item.paperType = item.experienceData.tag_labels.filter(item => item.type === 'paper_type')[0].label;
      item.numbering = index + 1;
      item.correct = null
    });
    yield put(Actions.Api.progressPopupOverlayNextSucceeded({ data: response }));
  } catch (error) {
    yield put(Actions.Api.progressPopupOverlayNextFailed(error));
    console.error('Error for progressPopupOverlayNext: ', error);
  }
}

function* progressPopupOverlayPrevious(action) {
  const APJWTToken = getJWTToken();
  const subjectId = yield select(Selectors.activeSubjectId);
  const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const teachingTag = subjectTeachingLevel[subjectId];
  const APTeachingTag = changeTeachingTag(teachingTag)
  const requestData = {
    by_experience_uuid: action.data,
    sub_url: 'v1/integrations/osc/segments',
    api_type: 'segments',
    jwt: APJWTToken,
    by_tag_teaching_levels: APTeachingTag
  };
  try {
    const response = yield call(Api.getExams, requestData);
    response?.data?.data?.map((item, index) => {
      item.category = `${item.sectionTitle} ${item.sectionId}`;
      item.teachingLevel = item.teachingTag;
      item.sources = item.experienceData.qb_code;
      item.type = item.questionType;
      item.answered = false;
      item.flashcardId = item.sectionId;
      item.paperType = item?.experienceData?.tag_labels?.filter(item => item?.type === 'paper_type')?.[0]?.label;
      item.numbering = index + 1;
      item.correct = null
    });
    yield put(Actions.Api.progressPopupOverlayPreviousSucceeded({ data: response }));
  } catch (error) {
    yield put(Actions.Api.progressPopupOverlayPreviousFailed(error));
    console.error('Error for progressPopupOverlayPrevious: ', error);
  }
}

function* onGetNextPrevTopics(action) {
  try {
    const topicIds = action.data;
    const APJWTToken = getJWTToken();
    const hasType = action.hasType;
    yield put(NavigationActions.topicsNextPrevButtonEnable(true, hasType));
    const userId = yield select(Selectors.getUserId);
    const subjectId = getLastSubjectVisited(userId);
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
    const subject = yield select(Selectors.getActiveSubject);
    const subjectSlug = subject.slug;
    const teachingTag = subjectTeachingLevel[subjectId];
    const APTeachingTag = changeTeachingTag(teachingTag);
    const activeExamModule = yield select(Selectors.getActiveExamModule);
    const subjectExamStatus = mathematicsIds.includes(subjectId);
    const questionBankCodes = activeExamModule ? ["ib_dp", "osc_dp"] : subjectExamStatus && ["osc_dp"];
    let resp = yield all(topicIds.map((topicId) => {
      const requestData = { id: topicId, mediaType: 'exams', type: 1 };
      return call(Api.getPreviewsListByType, requestData, APJWTToken, APTeachingTag, subjectSlug, questionBankCodes);
    }));
    let response;
    yield all(resp.map(previewItems => {
      previewItems.previews.map((item, index) => {
        item.qb_code = item.experienceData.qb_code;
        item.category = `${item.topicNumbering} ${item.teachingLevel}`;
        item.paperType = item.experienceData.tag_labels.filter(item => item.type === 'paper_type')[0].label;
        item.flashcarduuId = `${item.subTopicId}-${item.flashcardId}`;
        item.topicUniqueNumber = `${item.topicNumbering}.${index + 1}`;
        item.question_type = `${item.questionType.includes('mcq') ? 'mcq' : 'group'}`;
        item.isTopic = true;
      });
      response = previewItems.previews;
      return put(getExamPreviewsListByType({ response }))
    }));
    yield put(NavigationActions.topicsNextPrevButtonEnable(false, 'next'));
    yield put(NavigationActions.topicsNextPrevButtonEnable(false, 'prev'));
    yield put(Actions.Desk.updateExamQuestionByFilter({ response }))
  } catch (error) {
    console.error("Error for onGetNextPrevTopics: ", error);
  }
}

function* onGetExamFilterData() {
  try {
    const APJWTToken = getJWTToken();
    const subjectId = yield select(Selectors.activeSubjectId);
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
    const teachingTag = subjectTeachingLevel[subjectId];
    const APTeachingTag = changeTeachingTag(teachingTag);
    const assessment = yield select(listOfFilterCheckedItems);
    let assessmentFilter = assessment && assessment.get('exams');
    const examFilter = yield select(Selectors.examsofFilterItems);
    let paperFilter = examFilter && examFilter.toJS().paper;
    let difficultyFilter = examFilter && examFilter.toJS().difficulty;
    const sourceFilter = examFilter && examFilter.toJS().source;
    let teachingLevelFilter = examFilter && examFilter.toJS().teachingLevel;
    let mcqFilter = examFilter && examFilter.get('mcq');

    let sourceFilters = sourceFilter.map(item => item
      .replace('IB', 'ib_dp')
      .replace('OSC', 'osc_dp')
    );
    mcqFilter = mcqFilter.map(item => item
      .replace('Not yet answered', 'not_yet_assessed')
    );
    assessmentFilter = assessmentFilter.map(item => item
      .replace('Confident', 'confident')
      .replace('Neither', 'neither')
      .replace('Not yet assessed', 'not_yet_assessed')
    );

    const examTeachingTag = teachingLevelFilter.map(item => teachingTagFilter(item));

    if (sourceFilters.length > 1) {
      sourceFilters = []
    }
    if (difficultyFilter.length > 2) {
      difficultyFilter = []
    }
    if (paperFilter.length > 2) {
      paperFilter = []
    }
    if (assessmentFilter.length > 3) {
      assessmentFilter = []
    }
    if (mcqFilter.length > 2) {
      mcqFilter = []
    }

    const requestData = {
      sub_url: 'v1/integrations/osc/segments',
      jwt: APJWTToken,
      subjectNodeId: subjectId,
      by_experience_tag_teaching_levels: APTeachingTag,
      questionbank_codes: sourceFilters.map(name => name.toLowerCase()),
      by_tag_paper_types: paperFilter.map(name => name.toLowerCase()),
      by_tag_difficulty_levels: difficultyFilter.map(name => name.toLowerCase()),
      by_tag_teaching_levels: examTeachingTag,
      by_confidence_levels: assessmentFilter.map(name => name.toLowerCase()),
      by_user_response_statuses: mcqFilter.map(name => name.toLowerCase()),
    };
    let response = yield call(Api.getExamsFilterApi, requestData);
    response.previews.map((item, index) => {
      item.qb_code = item.experienceData.qb_code;
      item.paperType = item.experienceData.tag_labels.filter(item => item.type === 'paper_type')[0].label;
      item.numbering = index + 1;
      item.flashcarduuId = `${item.subTopicId}-${item.flashcardId}`;
      item.topicUniqueNumber = `${item.topicNumbering}.${index + 1}`;
      item.question_type = `${item.questionType.includes('mcq') ? 'mcq' : 'group'}`
      item.isTopic = false;
    });
    // let finalFilterExamData = getUniqueExamPreviewData(response.previews)
    let finalFilterExamData = response?.previews || []
    yield put(Actions.Api.setExamFilterDataSucceeded({ data: finalFilterExamData }));
    yield put(Actions.Desk.updateExamQuestionByFilter({ response: finalFilterExamData }))
  } catch (error) {
    console.error("Error for onGetExamFilterData: ", error)
  }
}

function* getQuestionCount(action) {
  try {
    const paperId = action.data;
    const APJWTToken = getJWTToken();
    const response = yield call(Api.getQuestionCount, APJWTToken, paperId);
    const total = response.questions_count;
    let mcqResponse = response.response_counts.msqTotal ?? {}
    mcqResponse.total = total;
    let freeResponse = response.response_counts.freeResponse ?? {}
    freeResponse.total = total;
    const examsBox = { mcqResponse: mcqResponse, freeResponse: freeResponse };
    let quickStatus = yield select((state) => examStatusById(state, paperId));
    if (quickStatus.get('freeResponse').size === 0 && quickStatus.get('mcqResponse').size === 0) {
      const examNodeStatus = [{
        id: paperId,
        freeResponse: freeResponse,
        mcqResponse: mcqResponse
      }]
      yield put(setExamNodeStatus(examNodeStatus));
    }
    yield put(Actions.Api.getQuestionCountSucceeded({ data: examsBox }));
  } catch (error) {
    yield put(Actions.Api.getQuestionCountFailed(error));
    console.error("error for getQuestionCount: ", error);
  }
}

function* onChangeExamStatusCount(action) {
  try {
    const paperId = action.id;
    const tlvl = action.tlvl;
    const subjectId = yield select(Selectors.activeSubjectId);
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
    const teachingTag = subjectTeachingLevel[subjectId]
    const dataList = yield select(examPreview);
    const filterData = dataList?.data?.data.length > 0 ? examTeachingFilter(teachingTag, dataList?.data?.data, tlvl, 'exams') : []
    const paperType = filterData[0]?.questionType;
    let mcqResponse, freeResponse;
    if (paperType?.includes('mcq')) {
      const statusCount = countExamsStatus(filterData, 'Paper 1')
      mcqResponse = {
        "total": filterData.length || 0,
        "correct": statusCount.correct ? statusCount.correct.length : 0,
        "incorrect": statusCount.incorrect ? statusCount.incorrect.length : 0
      }
      freeResponse = {
        "neither": 0,
        "studied": 0,
        "incorrect": 0,
        "correct": 0,
        "total": 0
      }

    } else {
      const statusFreeCount = countExamsStatus(filterData, 'Paper 2')
      mcqResponse = {
        "total": 0,
        "correct": 0,
        "incorrect": 0
      }
      freeResponse = {
        "neither": statusFreeCount.neither ? parseInt(statusFreeCount.neither.length) : 0,
        "studied": statusFreeCount.neither ? parseInt(statusFreeCount.neither.length) : 0 + statusFreeCount.incorrect ? parseInt(statusFreeCount.incorrect.length) : 0 + statusFreeCount.correct ? parseInt(statusFreeCount.correct.length) : 0,
        "incorrect": statusFreeCount.incorrect ? statusFreeCount.incorrect.length : 0,
        "correct": statusFreeCount.correct ? statusFreeCount.correct.length : 0,
        "total": filterData.length || 0
      }
    }
    yield put(setExamNodeStatus([
      {
        id: paperId,
        freeResponse: freeResponse,
        mcqResponse: mcqResponse
      }
    ]));
  } catch (error) {
    console.error("error for onChangeExamStatusCount: ", error)
  }
}

function* onChangeTopicExamStatusCount({ id, tlvl, difficulty, paper, source }) {
  try {
    const subjectId = yield select(Selectors.activeSubjectId)
    const topicStatus = yield select((state) => statusById(state, id))
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel)
    const teachingTag = subjectTeachingLevel[subjectId]
    const dataList = yield select((state) => Selectors.previewsListbyType(state))
    const dataListbyIds = dataList.getIn([id, 'data'])
    const dataListbyId = [...new Map(dataListbyIds?.toJS().map(item => [item['flashcardId'], item])).values()]  //  remove duplicate
    const sourceFilterData = dataListbyId && sourceFilter(dataListbyId, source, "exams")
    const paperFilterData = dataListbyId && paperFilter(sourceFilterData, paper, "exams")
    const difficultyData = dataListbyId && difficultyFilter(paperFilterData, difficulty, "exams")
    const finalFilterData = dataListbyId && topicTeachingFilter(teachingTag, difficultyData, tlvl, "topics", "exams")
    const multipleChooseData = dataListbyId && questionTypeFind(finalFilterData).mcq
    const freeResponseData = dataListbyId && questionTypeFind(finalFilterData).group
    const statusCount = multipleChooseData && countExamsStatus(multipleChooseData, 'Paper 1', true)
    const statusFreeResponseCount = freeResponseData && countExamsStatus(freeResponseData, 'Paper 2', true)
    const mcqResponse = {
      total: multipleChooseData?.length || 0,
      correct: statusCount?.correct?.length || 0,
      incorrect: statusCount?.incorrect?.length || 0
    }
    const freeResponse = {
      neither: freeResponseData ? statusFreeResponseCount.neither ? parseInt(statusFreeResponseCount.neither.length) : 0 : 0,
      studied: freeResponseData ? statusFreeResponseCount.neither ? parseInt(statusFreeResponseCount.neither.length) : 0 + statusFreeResponseCount.incorrect ? parseInt(statusFreeResponseCount.incorrect.length) : 0 + statusFreeResponseCount.correct ? parseInt(statusFreeResponseCount.correct.length) : 0 : 0,
      incorrect: freeResponseData ? statusFreeResponseCount.incorrect ? statusFreeResponseCount.incorrect.length : 0 : 0,
      correct: freeResponseData ? statusFreeResponseCount.correct ? statusFreeResponseCount.correct.length : 0 : 0,
      total: freeResponseData?.length || 0
    }

    if (dataListbyIds?.size !== 0) {
      yield put(setNodeStatus([{
        id: parseInt(id),
        flashcard: topicStatus.get("flashcard"),
        revision: topicStatus.get("revision"),
        video: topicStatus.get("video"),
        exam: { freeResponse: freeResponse, msqTotal: mcqResponse },
      }]));
    }
  } catch (error) {
    console.error("Error for onChangeTopicExamStatusCount: ", error)
  }
}

function* onGetFirstQuestion(action) {
  const key = action.payload
  const cachedFlashcard = yield select(Selectors.firstQuestionIdBySubject)
  const topicId = cachedFlashcard.getIn([key, 'data']);
  const activeStatus = yield select(Selectors.getActiveTeachingTagStatus)
  try {
    const mbFromSchool = yield select(Selectors.isMBFromSchool);
    if (!topicId || activeStatus) {
      const APJWTToken = getJWTToken()
      const subjectId = yield select(Selectors.activeSubjectId)
      const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel)
      const teachingTag = subjectTeachingLevel[subjectId]
      const subject = yield select(Selectors.getActiveSubject)
      const subjectSlug = subject.slug
      const APTeachingTag = changeTeachingTag(teachingTag)
      const activeExamModule = yield select(Selectors.getActiveExamModule)
      const isMathematics = mathematicsIds.includes(subjectId)
      const questionBankCodes = activeExamModule ? ["ib_dp", "osc_dp"] : isMathematics && ["osc_dp"]
      if(!!mbFromSchool) {
        yield put(Actions.Api.getPreviewsListByType({ id: key.toString(), mediaType: 'exams', type: 1, subjectSlug }))
      }
      let response = yield call(Api.getFirstQuestionBy, APJWTToken, APTeachingTag, key, subjectSlug, questionBankCodes)

      setFirstQuestionIdBySubject(response.flashcardId)
      yield put(Actions.Desk.setActiveTeachingTagStatus(false))
      yield put(getFirstQuestionIdBySubject.success({ key, response }))
    } else {
      setFirstQuestionIdBySubject(topicId.get('flashcardId'))
    }
  } catch (error) {
    console.error("Error for onGetFirstQuestion: ", error)
    yield put(getFirstQuestionIdBySubject.failure({ key, error }))
  } finally {
    yield put(getFirstQuestionIdBySubject.fulfill({ key }))
  }
}

function* setLastTeachingTagBySubject(action) {
  try {
    yield call(Api.setLastTeachingTag, action.data)
  } catch (error) {
    console.error("Error for setLastTeachingTagBySubject: ", error)
  }
}

function* updateFlashcardAnswer(action) {
  const key = action.id
  const { id } = action;
  const response = action.response;
  try {
    yield put(getPreviewsListByType.success({ key, response, id }));
  } catch (error) {
    console.error("Error for updateFlashcardAnswer: ", error)
  } finally {
    yield put(getPreviewsListByType.fulfill({ key }));
  }
}

function* onGetInternalExercises() {
  try {
    const PREFIX = 'EXERCISE-';
    const subjectId = yield select(activeSubjectId);
    const response = yield call(Api.getInternalExercises, subjectId);
    const sortedExercises = response.sort((a, b) => b.createdAt - a.createdAt)
    yield put(getInternalExercisesSucceeded({key: subjectId, response: sortedExercises}));
    if(sortedExercises.length > 0) {
      const nodeStatus = sortedExercises.map(({exerciseId, answerData}) => {      
        return {
          id: PREFIX + exerciseId,
          flashcard: answerData.flashcardBox
        };
      })
      yield put(setNodeStatus(nodeStatus));
    }
  } catch (error) {
    console.error("Error in onGetInternalExercises: ", error);
  }
}

function* onGetExerciseDetails(action) {
  try {
    const { key } = action.payload;
    const EXERCISE = "EXERCISE-";
    const response = yield call(Api.getExerciseDetails, key);
    const nodeStatus = {
      id: EXERCISE + response.exerciseId,
      flashcard: response.answerData.flashcardBox,
    };
    yield put(setNodeStatus([nodeStatus]));
    yield put(getExerciseDetailsSucceeded({key, response}))
  } catch (error) {
    console.error("Error in onGetExerciseDetails: ", error);
  }
}