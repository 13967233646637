import React from 'react';
import classNames from 'classnames';
import StatusBar from 'v2/components/status-bar/StatusBar'

type MediaTypeStatusBarProps = {
    number: number;
    title: string;
    mediaStatusData: any;
    isRead: boolean;
    mediaType: string;
    isMargin?: boolean;
    page?: string;
    resultRange?: string;
    icon?: string,
    isActive?: boolean,
    isSubtopic?: boolean,
    isSlider?: boolean
}
const MediaTypeStatusBar: React.FC<MediaTypeStatusBarProps> = ({ number, title, mediaStatusData, isRead, mediaType, isMargin = false, page, resultRange, icon, isSlider = false, isSubtopic = false, isActive = false }) => {
    return <div className={classNames('media-status-bar-wrapper px-5 py-2', { 'mt-4': !isMargin }, { 'mt-0': isMargin, 'flex !pt-[9px] !px-[11px] !pb-2 !mt-0 study-meta-info': icon })}>
        {icon && <img draggable="false" className="mediaTab-image" src={icon} alt={title} />}
        <StatusBar number={number} title={title} statusData={mediaStatusData} isRead={isRead} mediaType={mediaType} page={page} resultRange={resultRange} isSlider={isSlider} isSubtopic={isSubtopic} isActive={isActive} />
    </div>

}

export default MediaTypeStatusBar;