import appHistory from 'appHistory';
import {  Map ,update} from 'immutable';
import moment from 'moment';
import media from 'ui-components/helpers/media';
import { NoteIcon, FolderIcon, SearchIcon, TopicsIcon, ExerciseIcon, ExamsIcon } from 'icons/FlashcardNavIcons';

const storageImpl = isLocalStorageNameSupported() ? window.localStorage : new LocalStorageAlternative();

function LocalStorageAlternative() {

  var structureLocalStorage = {};
  this.setItem = function (key, value) {
    structureLocalStorage[key] = value;
  };
  this.getItem = function (key) {
    if (typeof structureLocalStorage[key] != 'undefined') return structureLocalStorage[key];
    return null;
  };
  this.removeItem = function (key) {
    structureLocalStorage[key] = undefined;
  };
}

export function getFromStorage(key) {
  return storageImpl.getItem(key);
}

export function saveToStorage(key, data) {
  storageImpl.setItem(key, JSON.stringify(data));
}

export function addCredentialsToLocalStorage(credentials) {
  storageImpl.setItem('smpp-credentials', JSON.stringify(credentials));
}

export function addShownNotificationToLocalStorage() {
  storageImpl.setItem('smpp-shown-notif', 'true');
}

export function hasShownNotificationInLocalStorage() {
  return storageImpl.getItem('smpp-shown-notif');
}

export function toggleEnableCookies(data) {
  return storageImpl.setItem('Enable-Cookies', data);
}

export function getEnableCookies() {
  return storageImpl.getItem('Enable-Cookies');
}

export function getCredentials() {
  const defaultValue = {
    'access_token': null,
    'expires_in': null,
    'token_type': null,
    'scope': null,
    'refresh_token': null,
    'createdAt': null
  };
  try {
    return JSON.parse(storageImpl.getItem('smpp-credentials')) ||
      defaultValue;
  } catch (e) {
    return defaultValue;
  }
}

export function addLastFlashcardToLocalStorage(flashcardId, subject, userId) {
  let lastFlashcardsSaved;
  let subjectName = subject.slug;

  getLastFlashcard(userId) ? lastFlashcardsSaved = getLastFlashcard(userId) : lastFlashcardsSaved = {};
  if (typeof lastFlashcardsSaved === 'string') {
    lastFlashcardsSaved = {};
  }

  lastFlashcardsSaved[subjectName] = flashcardId;

  storageImpl.setItem('smpp-last-flashcard-' + userId, JSON.stringify(lastFlashcardsSaved));
}

export function getLastFlashcard(userId) {
  return JSON.parse(storageImpl.getItem('smpp-last-flashcard-' + userId));
}

export function addBackFlashcardToLocalStorage(flashcardId, returnFlashcardId) {
  let backFlashcardsSaved;
  getbackFlashcard() ? backFlashcardsSaved = getbackFlashcard() : backFlashcardsSaved = {};
  if (typeof backFlashcardsSaved === 'string') {
    backFlashcardsSaved = {};
  }
  backFlashcardsSaved['current-flashcard-id'] = flashcardId;
  backFlashcardsSaved['return-flashcard-id'] = returnFlashcardId;
  storageImpl.setItem('smpp-return-flashcard', JSON.stringify(backFlashcardsSaved));
}
export function getbackFlashcard() {
  return JSON.parse(storageImpl.getItem('smpp-return-flashcard'));
}

export function setLastSubjectVisited(subject, userId) {
  storageImpl.setItem('smpp-last-subject-visited-' + userId, JSON.stringify(subject));
}
export function getFirstQuestionIdBySubjectId() {
  return storageImpl.getItem('smpp-first-question-id');
}

export function setFirstQuestionIdBySubject(questionId) {
  storageImpl.setItem('smpp-first-question-id' , questionId);
}
export function setLastMediaTypeVisited(type, userId) {
  storageImpl.setItem('smpp-last-media-type-visited-' + userId, JSON.stringify(type));
}

export function getLastMediaTypeVisited(userId) {
  return JSON.parse(storageImpl.getItem('smpp-last-media-type-visited-' + userId) );
}

export function getLastSubjectVisited(userId) {
  return JSON.parse(storageImpl.getItem('smpp-last-subject-visited-' + userId));
}

export function setFormExams(type){
  storageImpl.setItem('smpp-last-exams-type' , JSON.stringify(type));
}
export function setExamSubTopicId(id){
  storageImpl.setItem('smpp-last-exams-subTopic-id' , JSON.stringify(id));
}
export function setExamTopicId(id){
  storageImpl.setItem('smpp-last-exams-Topic-id' , id);
}
export function getExamSubTopicId(id){
  return storageImpl.getItem('smpp-last-exams-subTopic-id');
}
export function getExamTopicId(){
  return storageImpl.getItem('smpp-last-exams-Topic-id');
}

export function getFormExams(){
  return JSON.parse(storageImpl.getItem('smpp-last-exams-type'));
}

export function getAccessToken() {
  return getCredentials().access_token;
}

export function shouldRefreshToken() {
  const credentials = getCredentials();

  if (credentials.createdAt && credentials.expires_in) {
    const halfTime = credentials.createdAt + Math.floor(credentials.expires_in / 2);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime > halfTime;
  }
  return false;
}

export function getRefreshToken() {
  return getCredentials().refresh_token;
}

export function removeCredentialsFromLocalStorage() {
  storageImpl.removeItem('smpp-credentials');
}

export function getCenteredScrollValue(container, target, elementIndex) {
  const fullWidth = container.clientWidth,
    targetWidth = target.clientWidth;

  const leftScreenOffset = (fullWidth - targetWidth) / 2,
    leftSiblingOffset = targetWidth * elementIndex;

  const scrollValue = leftSiblingOffset - leftScreenOffset;

  return Math.max(0, scrollValue);
}

export function percentageOf(x, y) {
  return x * 100 / y;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isiOSSafari() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
}

export function isiPhoneSafari() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
}
export function isiPhone() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPhone/i);
  if(iOS){
    return true;
  }else{
    return false;
  }
}
export function isTablet(){
  const reactDeviceDetect = require("react-device-detect");
  if(reactDeviceDetect.isTablet){
    return true;
  }
}
export function isTouchDevice() {
  const reactDeviceDetect = require("react-device-detect");
  if(reactDeviceDetect.deviceType !== "browser"){
    return true;
  }
  if(reactDeviceDetect.isTablet){
    return true;
  }
  return false;
}

export function isMobile() {
  const reactDeviceDetect = require("react-device-detect");
  if(reactDeviceDetect.deviceType == "mobile"){
    return true;
  }
  return false;
}

function isLocalStorageNameSupported() {
  try {
    window.localStorage.setItem('test', '1');
    window.localStorage.removeItem('test');
    return true;
  } catch (error) {
    return false;
  }
}

export function getDevice() {
  return storageImpl.getItem('smpp-device-id');
}

export function saveDevice(deviceId) {
  storageImpl.setItem('smpp-device-id', deviceId);
}

export function clearDevice() {
  storageImpl.removeItem('smpp-device-id');
}

export const ellipsis = (word, characterLimit) => {
  if (!word) return;
  return word.substring(0, characterLimit) + (word.length > characterLimit ? '...' : '');
};

export function getAllIndexes(arr, val) {
  let indexes = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].indexOf(val) !== -1) {
      indexes.push(i);
    }
  }
  return indexes;
}

export const preparePreviewString = (initialString, searchValue) => {
  const replacement = '___REPLACEMENT___';
  let initialStringAsArray = initialString.replaceAll(searchValue, replacement).split(' ');
  const indexes = getAllIndexes(initialStringAsArray, replacement);

  const firstIndex = indexes[0] - 3 > 0 ? indexes[0] - 3 : 0;

  return {
    text: initialString.split(' ').splice(firstIndex).join(' '),
    occurences: indexes.length
  };
};

export function transformResultsListToArray(list) {
  const orderOfTeachingLevels = getOrderOfTeachingLevels();

  const sortArray =  list.sort((a, b) => {
    const indexA = orderOfTeachingLevels.indexOf(a.teachingLevel);
    const indexB = orderOfTeachingLevels.indexOf(b.teachingLevel);
    if (indexA < indexB) return -1;
    return 1;
  }).sort((a, b) => {return  a.numbering - b.numbering})
  return  sortArray.groupBy(listItem => listItem.category)
  .toList().map(arr => arr.sortBy(item => item.numbering));
}


export const groupByParam = (array, param) => array.reduce(function (r, a) {
  r[a?.[param]] = r[a?.[param]] || [];
  r[a?.[param]].push(a);
  return r;
}, Object.create(null));

export function ExamsSectiongroupedList(list){
  const orderOfTeachingLevels = getOrderofExamSection();
  const sortArray =  list.sort((a, b) => {
    const indexA = orderOfTeachingLevels.indexOf(a.sectionTitle.innerText);
    const indexB = orderOfTeachingLevels.indexOf(b.sectionTitle.innerText);
    if (indexA < indexB) return -1;
    return 1;
  }).sort((a, b) => {return  a.numbering - b.numbering});
  return groupByParam(sortArray, 'category')
}


export function examsPapergroupedList(list){
  const orderOfTeachingLevels = getOrderofExamPaper();
  const sortData = list.sort((a, b) => a.get('examCategory') > b.get('examCategory') ? 1 : -1)
  return sortData.sort((a, b) => {
    const indexA = orderOfTeachingLevels.indexOf(a.get('examCategory'));
    const indexB = orderOfTeachingLevels.indexOf(b.get('examCategory'));
    if (indexA < indexB) return -1;
    return 1;
  }).groupBy(listItem => listItem.get('examCategory'))
    .toList().map(arr => arr.sortBy(item => item.get('exam')));
}


export function questionTypeFind(list){
  const orderofquestionType = ['mcq',"group"]
  return list
  .sort((a, b) => {
    const indexA = orderofquestionType.indexOf(a.question_type) && orderofquestionType.indexOf(a.question_type);
    const indexB = orderofquestionType.indexOf(a.question_type )&& orderofquestionType.indexOf(b.question_type);
    if (indexA < indexB) return -1;
    return 1;
  })
  .groupBy(flashcard => flashcard.question_type && flashcard.question_type)
  .map(question_type =>
    question_type
      .sortBy((item) => parseFloat(item.paper))
      .groupBy((item) => item.question_type && item.question_type))
}
export function questionTypeFindV1(list){
  const orderofquestionType = ['mcq',"group"]
  return list
  .sort((a, b) => {
    const indexA = orderofquestionType.indexOf(a.get('question_type')) && orderofquestionType.indexOf(a.get('question_type'));
    const indexB = orderofquestionType.indexOf(a.get('question_type') )&& orderofquestionType.indexOf(b.get('question_type'));
    if (indexA < indexB) return -1;
    return 1;
  })
  .groupBy(flashcard => flashcard.get('question_type') && flashcard.get('question_type'))
  .map(question_type =>
    question_type
      .sortBy((item) => parseFloat(item.get('paper')))
      .groupBy((item) => item.get('question_type') && item.get('question_type')))
}




// filter data
export function filterResultsListToArray(list, mediaType, filter) {
    if(mediaType === "flashcard"){
      if(filter.length > 3 || filter.length === 0 ){
        return list
      }
      if(filter.length === 1){
        if(filter.includes('Not yet assessed')){
          return list.filter((val) => {
            return val.get('answers') === null;        
          })
        }else if(filter.includes('Unconfident')){
          return list.filter((val) => {
            return val.get('answers') === 0;        
          })
        }else if(filter.includes('Neither')){
          return list.filter((val) => {
            return val.get('answers') === 2;
          })
        }else{
          return list.filter((val) => {
            return val.get('answers') === 1;
          })
        }
      }
      if(filter.length === 2){
        if(filter.includes('Not yet assessed') && filter.includes('Unconfident')){
          return list.filter((val) => {
            return (val.get('answers') === null  || val.get('answers') === 0);
          })
        }else if(filter.includes('Not yet assessed') && filter.includes('Neither')){
          return list.filter((val) => {
            return (val.get('answers') === null  || val.get('answers') === 2);
          })
        }else if(filter.includes('Unconfident') && filter.includes('Neither')){
          return list.filter((val) => {
            return (val.get('answers') === 0 || val.get('answers') === 2);
          })
        }else if(filter.includes('Unconfident') && filter.includes('Confident')){
          return list.filter((val) => {
            return (val.get('answers') === 0 || val.get('answers') === 1);
          })
        }else if(filter.includes('Neither') && filter.includes('Confident')){
          return list.filter((val) => {
            return (val.get('answers') === 2 || val.get('answers') === 1);
          })
        }else{
          return list.filter((val) => {
            return (val.get('answers') === null  || val.get('answers') === 1);
          })
        }
      }
      if(filter.length === 3){
        if(filter.includes('Not yet assessed') && filter.includes('Unconfident') && filter.includes('Neither')){
          return list.filter((val) => {
            return (val.get('answers') === null || val.get('answers') === 0 || val.get('answers') === 2);
          })
        }else if(filter.includes('Not yet assessed') && filter.includes('Neither') && filter.includes('Confident')){
          return list.filter((val) => {
            return (val.get('answers') === null || val.get('answers') === 2 || val.get('answers') === 1);
          })
        }else if(filter.includes('Unconfident') && filter.includes('Neither') && filter.includes('Confident')){
          return list.filter((val) => {
            return (val.get('answers') === 0 || val.get('answers') === 2 || val.get('answers') === 1);
          })
        }else{
          return list.filter((val) => {
            return (val.get('answers') === null || val.get('answers') === 0 || val.get('answers') === 1);
          })
        }
      }
    }else if(mediaType === "revision"){
      if(filter.length > 1 || filter.length === 0 ){
        return list
      }else if(filter.includes('Read')){
        return list.filter((val) => {
          return val.get('value') === 1;
        })
      }else{
        return list.filter((val) => {
          return val.get('value') === 0 || val.get('value') === null;
        })
      }
    }else if(mediaType === "video"){
      if(filter.length > 2 || filter.length === 0 ){
        return list
      }
      if(filter.length === 1){
        if(filter.includes('Not yet watched')){
          return list.filter((val) => {
            return val.get('totalWatchedInSeconds') === 0;
          })
        }else if(filter.includes('Partly-watched')){
          return list.filter((val) => {
            return (val.get('totalWatchedInSeconds') > 0 && val.get('totalWatchedInSeconds') !== val.get('totalDurationInSeconds') && val.get('value') === 0);
          })
        }else{
          return list.filter((val) => {
            return val.get('totalWatchedInSeconds') === val.get('totalDurationInSeconds') ||  val.get('value') === 1;
          })
        }
      }
      if(filter.length === 2){
        if(filter.includes('Not yet watched') && filter.includes('Partly-watched')){
          return list.filter((val) => {
            return (val.get('totalWatchedInSeconds') === 0 || ((val.get('totalWatchedInSeconds') !== val.get('totalDurationInSeconds')) && val.get('value') === 0));
          })
        }else if(filter.includes('Not yet watched') && filter.includes('Watched')){
          return list.filter((val) => {
            return (val.get('totalWatchedInSeconds') === 0 || (val.get('totalWatchedInSeconds') === val.get('totalDurationInSeconds') ||  val.get('value') === 1 ));
          })
        }else{
          return list.filter((val) => {
            return (val.get('totalWatchedInSeconds') > 0 && (val.get('totalWatchedInSeconds') !== val.get('totalDurationInSeconds') || (val.get('totalWatchedInSeconds') === val.get('totalDurationInSeconds'))));
          })
        }
      }
    }else{
      return list
    }
}
export function filterResultsListToArrayByTOJS(list, mediaType, filter) {
  if(mediaType === "flashcard"){
    if(filter.length > 3 || filter.length === 0 ){
      return list
    }
    if(filter.length === 1){
      if(filter.includes('Not yet assessed')){      
        return list.filter((val) => {
          return val.answers === null;        
        })
      }else if(filter.includes('Unconfident')){      
        return list.filter((val) => {
          return val.answers === 0;        
        })
      }else if(filter.includes('Neither')){      
        return list.filter((val) => {
          return val.answers === 2;         
        })
      }else{
        return list.filter((val) => {
          return val.answers === 1;        
        })
      }
    }
    if(filter.length === 2){
      if(filter.includes('Not yet assessed') && filter.includes('Unconfident')){      
        return list.filter((val) => {
          return (val.answers === null  || val.answers === 0);        
        })
      }else if(filter.includes('Not yet assessed') && filter.includes('Neither')){      
        return list.filter((val) => {
          return (val.answers === null  || val.answers === 2);        
        })
      }else if(filter.includes('Unconfident') && filter.includes('Neither')){      
        return list.filter((val) => {
          return (val.answers === 0 || val.answers === 2);        
        })
      }else if(filter.includes('Unconfident') && filter.includes('Confident')){      
        return list.filter((val) => {
          return (val.answers === 0 || val.answers === 1);        
        })
      }else if(filter.includes('Neither') && filter.includes('Confident')){      
        return list.filter((val) => {
          return (val.answers === 2 || val.answers === 1);        
        })
      }else{
        return list.filter((val) => {
          return (val.answers === null  || val.answers === 1);   
        })
      }
    }
    if(filter.length === 3){
      if(filter.includes('Not yet assessed') && filter.includes('Unconfident') && filter.includes('Neither')){      
        return list.filter((val) => {
          return (val.answers === null || val.answers === 0 || val.answers === 2);        
        })
      }else if(filter.includes('Not yet assessed') && filter.includes('Neither') && filter.includes('Confident')){      
        return list.filter((val) => {
          return (val.answers === null || val.answers === 2 || val.answers === 1);        
        })
      }else if(filter.includes('Unconfident') && filter.includes('Neither') && filter.includes('Confident')){      
        return list.filter((val) => {
          return (val.answers === 0 || val.answers === 2 || val.answers === 1);        
        })
      }else{
        return list.filter((val) => {
          return (val.answers === null || val.answers === 0 || val.answers === 1);        
        })
      }
    }
  }
    if(mediaType === "revision"){
      if(filter.length > 1 || filter.length === 0 ){
        return list
      }else if(filter.includes('Read')){      
        return list.filter((val) => {
          return val.value === 1;        
        })
      }else{
        return list.filter((val) => {
          return val.value === 0 || val.value === null; 
        })
      }
    }   
    if(mediaType === "video"){
      if(filter.length > 2 || filter.length === 0 ){
        return list
      }
      if(filter.length === 1){
        if(filter.includes('Not yet watched')){      
          return list.filter((val) => {
            return val.totalWatchedInSeconds === 0;        
          })
        }else if(filter.includes('Partly-watched')){      
          return list.filter((val) => {
            return (val.totalWatchedInSeconds > 0 && val.totalWatchedInSeconds !== val.totalDurationInSeconds && val.value === 0);        
          })
        }else{
          return list.filter((val) => {
            return val.totalWatchedInSeconds === val.totalDurationInSeconds ||  val.value === 1;        
          })
        }
      }
      if(filter.length === 2){
        if(filter.includes('Not yet watched') && filter.includes('Partly-watched')){      
          return list.filter((val) => {
            return (val.totalWatchedInSeconds === 0 || ((val.totalWatchedInSeconds !== val.totalDurationInSeconds) && val.value === 0));        
          })
        }else if(filter.includes('Not yet watched') && filter.includes('Watched')){      
          return list.filter((val) => {
            return (val.totalWatchedInSeconds === 0 || (val.totalWatchedInSeconds === val.totalDurationInSeconds ||  val.value === 1 ));        
          })
        }else{
          return list.filter((val) => {
            return (val.totalWatchedInSeconds > 0 && (val.totalWatchedInSeconds !== val.totalDurationInSeconds || (val.totalWatchedInSeconds === val.totalDurationInSeconds)));        
          })
        }
      }
    }     
}

export function buildSearchResultsArray(initialString, val, flashcardId, side) {
  const replacement = '___REPLACEMENT___';
  let indexes = [];
  if(val.indexOf("’") !== -1){
    val = val.replace("’", "'");
    initialString = initialString.replaceAll("’", "'");
    initialString = initialString.replaceAll(val, replacement);
    let arr = initialString.split(' ');
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf(replacement) !== -1) {
        indexes.push({ flashcardId: flashcardId, searchIndex: indexes.length, side: side });
      }
    }
  }else if(val.indexOf("‘") !== -1){
    val = val.replace("‘", "’");
    initialString = initialString.replaceAll("‘", "’");
    initialString = initialString.replaceAll(val, replacement);
    let arr = initialString.split(' ');
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf(replacement) !== -1) {
        indexes.push({ flashcardId: flashcardId, searchIndex: indexes.length, side: side });
      }
    }
  }else if(val.indexOf("'") !== -1){
    val = val.replace("'", "’");
    initialString = initialString.replaceAll("'", "’");
    initialString = initialString.replaceAll(val, replacement);
    let arr = initialString.split(' ');
 
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf(replacement) !== -1) {
        indexes.push({ flashcardId: flashcardId, searchIndex: indexes.length, side: side });
      }
    }
  }else{
    initialString = initialString.replaceAll(val, replacement);
    let arr = initialString.split(' ');
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf(replacement) !== -1) {
        indexes.push({ flashcardId: flashcardId, searchIndex: indexes.length, side: side });
      }
    }
  }
  return indexes;
}

export function sortFoldersByDate(folders) {
  folders.items = folders.items.sort((a, b) => {
    let aDate = moment(a.createdAt.date);
    let bDate = moment(b.createdAt.date);

    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
  });

  return folders;
}

export function groupBy(input, fieldToGroupBy) {
  return input.reduce(function (accumulator, currentValue) {
    (accumulator[currentValue[fieldToGroupBy]] = accumulator[currentValue[fieldToGroupBy]] || []).push(currentValue);
    return accumulator;
  }, {});
}

export function transformArrayToKeyedObject(arr) {
  let returnObject = {};
  for (let i = 0; i < arr.length; i++) {
    returnObject[arr[i]] = {};
  }
  return returnObject;
}

export function isValidJSON(input) {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
}

export function convertToIndexedMap(list, key) {
  return Map(list.map(item => [item.get(key), item]));
}

export function convertToIndexedArray(list, key){
  return list.map(item => [item[key], item]);
}

export function updateFlashcardUrl(flashcardId, activeMediaTab, mediaType) {
    const currentUrl = window.location;
    appHistory.push({
      pathname: currentUrl.pathname,
      search: `?mediaType=${activeMediaTab}&id=${flashcardId}`
    });
}
export function storeExamsParentId(id){
  storageImpl.setItem('exam-parent-id' , JSON.stringify(id));
}
export function getStoreExamsParentId(){
  return JSON.parse(storageImpl.getItem('exam-parent-id'));
}
export function getOrderOfTeachingLevels() {
  return ['Studies SL', 'SL', 'HL', 'Option A | SL', 'Option B | SL', 'Option C | SL', 'Option D | SL', 'Option A | HL', 'Option B | HL', 'Option C | HL', 'Option D | HL', 'Teaching Level'];
}
//todo this is very similar to transformResultsListToArray, which breaks some other parts. The two should be merged into one

export function getOrderofExamSection(){
  return['Section 1','Section 2','Section 3','Section 4',"Section A","Section B","Section C" ]
}
export function getOrderofExamPaper(){
  return['Paper 1','Paper 2','Paper 3','Paper 4','Paper 5','Paper 6','Paper 7','Paper 8','Paper 9','Paper 10']
}
//todo this is very similar to transformExamsResultsListToArray, which breaks some other parts. The two should be merged into one

export function preparePreviewsList(list) {
  const orderOfTeachingLevels = getOrderOfTeachingLevels();

  return list.sort((a, b) => {
    const indexA = orderOfTeachingLevels.indexOf(a.teachingLevel);
    const indexB = orderOfTeachingLevels.indexOf(b.teachingLevel);
    if (indexA < indexB) return -1;
    return 1;
  }).groupBy(listItem => listItem.category)
    .toList().map(arr => arr.sortBy(item => item.numbering));
}

// Sorting Teaching Level 
export function orderTeachingLevel(list) {
  const orderOfTeachingLevels = getOrderOfTeachingLevels();
  return list.sort((a, b) => {
    const indexA = orderOfTeachingLevels.indexOf(a.title);
    const indexB = orderOfTeachingLevels.indexOf(b.title);
    if (indexA < indexB) return -1;
    return 1;
  });
}

export function isMBiframe(url) {
  const exercisePageRegex = RegExp('\\/class\\/[0-9]+\\/exercise\\/');
  return exercisePageRegex.test(url);
}
export function getUniqueId () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};

export function decodeQuery(queryString) {
  if (!queryString) return {};

  try {
    return JSON.parse('{"' + decodeURI(queryString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
  } catch (e) {
    return {};
  }
}

export function getMBUrlBasedOnRegion(region) {
  switch (region) {
    case 'cn':
    case 'china':
      return process.env.REACT_APP_MB_URL_CN;
    case 'canada':
    default:
      return process.env.REACT_APP_MB_URL;
  }
}

export function getMBTldBasedOnRegion(region) {
  switch (region) {
    case 'china':
      return 'cn';
    case 'canada':
      return 'com';
    default:
      return 'com';
  }
}

export function getPartnersBasedOnRegion(region){
  switch (region) {
    case 'cn':
    case 'china':
      return process.env.REACT_APP_ACCOUNT_PROTAL_CN;
    case 'canada':
    default:
      return process.env.REACT_APP_ACCOUNT_PROTAL_CANADA;
  }
}

export function getPartnersServiceBasedOnRegion(region){
  switch (region) {
    case 'cn':
    case 'china':
      return process.env.REACT_APP_PARTNER_BASE_URL_CN;
    case 'canada':
    default:
      return process.env.REACT_APP_PARTNER_BASE_URL;
  }
}

export function orderSubjects(subjects) {
  const orderOfSubjects = [
    'biology',
    'chemistry',
    'es-amp-s',
    'physics',
    'business-management',
    'geography',
    'history',
    'psychology',   
    'maths-a-amp-a',
    'maths-a-amp-i',
    'english-b',
    'french',
    'spanish-b',
    'sample-package',
    'pre-ib-mathematics',
    'economics',
    'mathematics',
  ];

  return subjects.sort((a, b) => {
    const indexA = orderOfSubjects.indexOf(a['slug']);
    const indexB = orderOfSubjects.indexOf(b['slug']);
    if (indexA < indexB) return -1;
    return 1;
  });
}

export function orderLinks(links) {

  return links.sort((a, b) => {
    const indexA = a.get("linkKey");
    const indexB = b.get("linkKey");
    if (indexA < indexB) return -1;
    return 1;
  });
}

export function getUniqueExamPreviewDataByTopicNumber(list){
  let uniquePreviewData = [...new Map(list.map(item => [item['flashcarduuId'], item])).values()]  ;
  return uniquePreviewData.sort((a, b) => {
    const indexA = a.topicNumbering;
    const indexB = b.topicNumbering;
    if (indexA < indexB) return -1;
    return 1;
  });
}
export function getUniqueExamPreviewData(list){
  let uniquePreviewData = [...new Map(list.map(item => [item['flashcarduuId'], item])).values()];
  return uniquePreviewData.sort((a, b) => {
    const x = a.topicUniqueNumber.split('.').map( n => +n+100000 ).join('.') 
    const y = b.topicUniqueNumber.split('.').map( n => +n+100000 ).join('.')
    const indexA = x;
    const indexB = y;
    if (indexA < indexB) return -1;
    return 1;
  });
}
export function getUniqueExamPreviewDataToJS(list){
  let uniquePreviewData = [...new Map(list.map(item => [item['flashcarduuId'], item])).values()];
  return uniquePreviewData.toJS().sort((a, b) => {
    const x = a.topicUniqueNumber.split('.').map( n => +n+100000 ).join('.') 
    const y = b.topicUniqueNumber.split('.').map( n => +n+100000 ).join('.')
    const indexA = x;
    const indexB = y;
    if (indexA < indexB) return -1;
    return 1;
  });
}

export function getUniqueTopicPreview(list){
  let uniquePreviewData = [...new Map(list.map(item => [item['flashcarduuId'], item])).values()]  ;
  return uniquePreviewData.sort((a, b) => {
    const indexA = a.numbering;
    const indexB = b.numbering;
    if (indexA < indexB) return -1;
    return 1;
  });
}

export function addSubjectSubtitles(subjects) {
  return subjects.map(subject => {
    switch (subject.slug) {
      case 'psychology':
        return { ...subject, isNew: true};
      case 'geography':
        return { ...subject, isNew: true};
      case 'economics':
        return { ...subject, isNew: false };
      case 'mathematics':
        return { ...subject, title: 'Maths' };
      case 'maths-a-amp-a':
        return { ...subject, isNew: false };
      case 'maths-a-amp-i':
        return { ...subject, isNew: false };
      case 'spanish-b':
        return { ...subject, isNew: false };
      default:
        return { ...subject, subtitle: null, subtitleColor: null };
    }
  });
}
export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Folder drag and drop
// export const getAnswerListStyle = isDraggingOver => ({	

//   padding: 4,	

// });	
export const getItemStyle = (isDragging, draggableStyle) => {
  let finalStyle = {
    userSelect: "none",

    // margin: `0 0 ${grid}px 0`,
    ...draggableStyle,
  };
  if (isDragging) {
    //finalStyle.top = draggableStyle.top;	
    finalStyle.boxShadow = "0px 6px 17px rgba(0, 0, 0, 0.2)";
  }
  return finalStyle;
};

export const mediaTypes = {flashcard:'flashcard', revision:'revision', video:'video', exams:'exams'}

export function getFirstMediaNodeId() {
  return storageImpl.getItem('first-media-nodeId');
}

export function saveFirstMediaNodeId(nodeId) {
  storageImpl.setItem('first-media-nodeId', nodeId);
}

export const returnFinalStatus = (data) => {
  const {flashcard, revision, video,exams,mcq} = data;
  const flashcardStatus = {
      total: flashcard.get("total") || 0,
      correct: flashcard.get("correct") || 0,
      neither: flashcard.get("neither") || 0,
      incorrect: flashcard.get("incorrect")|| 0,
  };
  
  const revisionStatus = {
      total: revision.get("total") || 0,
      read: revision.get("nodeWatchRead") || 0
  };
  const videoStatus = {
      total: video.get("total") || 0,
      read: video.get("nodeWatchRead") || 0
  };
  const examsStatus = {
    total: exams.get("total") || 0,
    correct: exams.get("correct") || 0,
    neither: exams.get("neither") || 0,
    incorrect: exams.get("incorrect")|| 0,
  };
  const mcqStatus ={
    total: mcq.get("total") || 0,
    correct: mcq.get("correct") || 0,
    incorrect: mcq.get("incorrect")|| 0,
  }
  const flashcardStatusGraph = [
      {
          title: "Unconfident",
          value: flashcardStatus.incorrect,
          percentage: flashcardStatus.incorrect * 100/flashcardStatus.total,
          color: "text-[#FA9A00]"
      },
      {
          title: "Neither",
          value: flashcardStatus.neither,
          percentage: ((flashcardStatus.incorrect * 100/flashcardStatus.total) <= 3.5 && (flashcardStatus.incorrect * 100/flashcardStatus.total) > 0) ? ((parseInt(flashcardStatus.incorrect)+parseInt(flashcardStatus.neither)) * 100/flashcardStatus.total)+3.5 :(parseInt(flashcardStatus.incorrect)+parseInt(flashcardStatus.neither)) * 100/flashcardStatus.total,
          color: "text-[#C2D0D9]"
      },
      {
          title: "Confident",
          value: flashcardStatus.correct,
          percentage: (((flashcardStatus.incorrect * 100/flashcardStatus.total)<= 3.5 && (flashcardStatus.incorrect * 100/flashcardStatus.total) > 0) || ((flashcardStatus.neither * 100/flashcardStatus.total)<= 3.5 && (flashcardStatus.neither * 100/flashcardStatus.total) > 0) ) ? ((parseInt(flashcardStatus.correct)+parseInt(flashcardStatus.incorrect)+parseInt(flashcardStatus.neither)) * 100/flashcardStatus.total)+3.5 : (parseInt(flashcardStatus.correct)+parseInt(flashcardStatus.incorrect)+parseInt(flashcardStatus.neither)) * 100/flashcardStatus.total, 
          color: "text-[#50D4A8]"
      },
  ];
  const examsStatusGraph = [
    {
        title: "Unconfident",
        value: examsStatus.incorrect,
        percentage: examsStatus.incorrect * 100/examsStatus.total,
        color: "text-[#FA9A00]"
    },
    {
        title: "Neither",
        value: examsStatus.neither,
        percentage: ((examsStatus.incorrect * 100/examsStatus.total) <= 3.5 && (examsStatus.incorrect * 100/examsStatus.total) > 0) ? ((parseInt(examsStatus.incorrect)+parseInt(examsStatus.neither)) * 100/examsStatus.total)+3.5 :(parseInt(examsStatus.incorrect)+parseInt(examsStatus.neither)) * 100/examsStatus.total,
        color: "text-[#C2D0D9]"
    },
    {
        title: "Confident",
        value: examsStatus.correct,
        percentage: (((examsStatus.incorrect * 100/examsStatus.total)<= 3.5 && (examsStatus.incorrect * 100/examsStatus.total) > 0) || ((examsStatus.neither * 100/examsStatus.total)<= 3.5 && (examsStatus.neither * 100/examsStatus.total) > 0) ) ? ((parseInt(examsStatus.correct)+parseInt(examsStatus.incorrect)+parseInt(examsStatus.neither)) * 100/examsStatus.total)+3.5 : (parseInt(examsStatus.correct)+parseInt(examsStatus.incorrect)+parseInt(examsStatus.neither)) * 100/examsStatus.total, 
        color: "text-[#50D4A8]"
    },
];
const mcqStatusGraph=[
  {
    title:'Incorrect',
    value: mcqStatus.incorrect,
    percentage: mcqStatus.incorrect * 100/mcqStatus.total,
    color: mcqStatus.incorrect == 0 ? "text-[#2D3838]" : "text-[#FC2808]",
  },
  {
    title: "Correct",
    value: mcqStatus.correct,
    percentage: ((mcqStatus.incorrect * 100/mcqStatus.total) <= 3.5 && (mcqStatus.incorrect * 100/mcqStatus.total) > 0) ? ((parseInt(mcqStatus.incorrect)+parseInt(mcqStatus.correct)) * 100/mcqStatus.total)+3.5 :(parseInt(mcqStatus.incorrect)+parseInt(mcqStatus.correct)) * 100/mcqStatus.total,
    color:  mcqStatus.correct == 0 ? "text-[#2D3838]" : "text-[#50D4A8]",
},
]
  const revisionStatusGraph = [
      {
          title: "Read",
          value: revisionStatus.read,
          percentage: revisionStatus.read * 100/revisionStatus.total,
          color: "text-[#2D3838]"
      } 
  ];
  const videoStatusGraph = [
      {
          title: "Watched",
          value: videoStatus.read,
          percentage: videoStatus.read * 100/videoStatus.total,
          color: "text-[#2D3838]"
      }   
  ];
  return {
    flashcardStatus,
    revisionStatus,
    videoStatus,
    examsStatus,
    flashcardStatusGraph,
    revisionStatusGraph,
    videoStatusGraph,
    examsStatusGraph,
    mcqStatusGraph,
    mcqStatus
  }
}
export const setResizeObserver = (elementRef, onResize) => {
  if (!elementRef && !elementRef.current) return ;
  const baseTabContent = elementRef && elementRef.current;
  let resizeObserver = new ResizeObserver(() => {
    onResize();
  });
  resizeObserver.observe(baseTabContent);
  return () => {
    resizeObserver.unobserve(baseTabContent);
  };
};

export const splitString = (mediaWithId) =>{
  if(!mediaWithId) return;
  const mediaValue = mediaWithId.split("-");
  return mediaValue;
}

export const returnFinalExerciseStatus = (data) => {
  const flashcardStatus = {
      total: data.get("total") || 0,
      correct: data.get("correct") || 0,
      neither: data.get("neither") || 0,
      incorrect: data.get("incorrect")|| 0,
  };
  const flashcardStatusGraph = [
      {
          title: "Unconfident",
          value: flashcardStatus.incorrect,
          percentage: flashcardStatus.incorrect * 100/flashcardStatus.total,
          color: "text-[#FA9A00]"
      },
      {
          title: "Neither",
          value: flashcardStatus.neither,
          percentage: ((flashcardStatus.incorrect * 100/flashcardStatus.total) <= 3.5 && (flashcardStatus.incorrect * 100/flashcardStatus.total) > 0) ? ((parseInt(flashcardStatus.incorrect)+parseInt(flashcardStatus.neither)) * 100/flashcardStatus.total)+3.5 :(parseInt(flashcardStatus.incorrect)+parseInt(flashcardStatus.neither)) * 100/flashcardStatus.total,
          color: "text-[#C2D0D9]"
      },
      {
          title: "Confident",
          value: flashcardStatus.correct,
          percentage: (((flashcardStatus.incorrect * 100/flashcardStatus.total)<= 3.5 && (flashcardStatus.incorrect * 100/flashcardStatus.total) > 0) || ((flashcardStatus.neither * 100/flashcardStatus.total)<= 3.5 && (flashcardStatus.neither * 100/flashcardStatus.total) > 0) ) ? ((parseInt(flashcardStatus.correct)+parseInt(flashcardStatus.incorrect)+parseInt(flashcardStatus.neither)) * 100/flashcardStatus.total)+3.5 : (parseInt(flashcardStatus.correct)+parseInt(flashcardStatus.incorrect)+parseInt(flashcardStatus.neither)) * 100/flashcardStatus.total, 
          color: "text-[#50D4A8]"
      },
  ];
  return {
    flashcardStatus,
    flashcardStatusGraph
  }
}

export function teachingFilterResultsListToArray(list, tag, activeSubject) {
  if(activeSubject && ((typeof activeSubject.toJS === 'function' && activeSubject?.get('slug') === "mathematics") || activeSubject?.slug === "mathematics")){
    return list.filter((item) => item.get('teachingTag') === tag);   
  }
  if(activeSubject && ((typeof activeSubject.toJS === 'function' && activeSubject?.get('slug') === "pre-ib-mathematics") || activeSubject?.slug === "pre-ib-mathematics")){
    return list;
  }
  if(typeof activeSubject.toJS !== 'function') {
    return list && list.filter((item) => item?.teachingTag.includes(tag));      
  }
  return list && list.filter((item) => item.get('teachingTag').includes(tag));      
}

export function teachingFilterResultsMapToArray(list, tag, activeSubject) {
  if(activeSubject && activeSubject.get('slug') === "mathematics"){
    return list.filter((item) => item.teachingTag === tag);   
  }
  if(activeSubject && activeSubject.get('slug') === "pre-ib-mathematics"){
    return list;
  }
  return list && list.filter((item) => item.teachingTag.includes(tag));      
}


export function checkFlashcardAssesstment(filterItems, assesstment, mediaType){
 
    if(filterItems.length === 0){
      return false;
    }
    if(filterItems.indexOf(assesstment) !== -1){
      return false;
    }else{
      return true;
    }
}



export const returnFinalExams = (data) => {
  const {exams,mcq} = data;
  const examsStatus = {
    total: exams.total || 0,
    correct: exams.correct || 0,
    neither: exams.neither || 0,
    incorrect: exams.incorrect|| 0,
  };
  const mcqStatus ={
    total: mcq.total || 0,
    correct: mcq.correct || 0,
    incorrect: mcq.incorrect|| 0,
  }
 
  const examsStatusGraph = [
      {
          title: "Unconfident",
          value: examsStatus.incorrect,
          percentage: examsStatus.incorrect * 100/examsStatus.total,
          color: "text-[#FA9A00]"
      },
      {
          title: "Neither",
          value: examsStatus.neither,
          percentage: ((examsStatus.incorrect * 100/examsStatus.total) <= 3.5 && (examsStatus.incorrect * 100/examsStatus.total) > 0) ? ((parseInt(examsStatus.incorrect)+parseInt(examsStatus.neither)) * 100/examsStatus.total)+3.5 :(parseInt(examsStatus.incorrect)+parseInt(examsStatus.neither)) * 100/examsStatus.total,
          color: "text-[#C2D0D9]"
      },
      {
          title: "Confident",
          value: examsStatus.correct,
          percentage: (((examsStatus.incorrect * 100/examsStatus.total)<= 3.5 && (examsStatus.incorrect * 100/examsStatus.total) > 0) || ((examsStatus.neither * 100/examsStatus.total)<= 3.5 && (examsStatus.neither * 100/examsStatus.total) > 0) ) ? ((parseInt(examsStatus.correct)+parseInt(examsStatus.incorrect)+parseInt(examsStatus.neither)) * 100/examsStatus.total)+3.5 : (parseInt(examsStatus.correct)+parseInt(examsStatus.incorrect)+parseInt(examsStatus.neither)) * 100/examsStatus.total, 
          color: "text-[#50D4A8]"
      },
  ];
  const mcqStatusGraph=[
    {
      title:'Incorrect',
      value: mcqStatus.incorrect,
      percentage: mcqStatus.incorrect * 100/mcqStatus.total,
      color: mcqStatus.incorrect == 0 ? "text-[#2D3838]" : "text-[#FC2808]",
    },
    {
      title: "Correct",
      value: mcqStatus.correct,
      percentage: ((mcqStatus.incorrect * 100/mcqStatus.total) <= 2.5 && (mcqStatus.incorrect * 100/mcqStatus.total) > 0) ? ((parseInt(mcqStatus.incorrect)+parseInt(mcqStatus.correct)) * 100/mcqStatus.total)+2.5 :(parseInt(mcqStatus.incorrect)+parseInt(mcqStatus.correct)) * 100/mcqStatus.total,
      // percentage: mcqStatus.correct * 100/mcqStatus.total,
      color:  mcqStatus.correct == 0 ? "text-[#2D3838]" : "text-[#50D4A8]",
  },
  ]
  return {
    examsStatus,
    examsStatusGraph,
    mcqStatus,
    mcqStatusGraph
  }
}

const examsSubjectID = [14982 , 15075, 47, 3, 50, 28374, 38010, 24932, 21913]
export const mathematicsIds = [14982 , 15075]
/**
 * @deprecated instead use useExamModuleStatus hook 
 * @param activeModule 
 * @param subjectId 
 * @returns 
 */
export const activeExamsSubject =(activeModule, subjectId)=>{
  let activeExamsSubject;
  if (activeModule) {
    activeExamsSubject = examsSubjectID.filter((id) => {
      return subjectId === id
    })
  }else{
    activeExamsSubject = mathematicsIds.filter((id) => {
      return subjectId === id
    })
  }
  return activeExamsSubject.length > 0 ;
}

export const disableMcqExamSubject = (val)=>{
  const activeMcqExam =mathematicsIds.filter((id)=>{
    return val === id
  })
  return activeMcqExam.length > 0 ;
}
export const renderPaperType = (activeSubjectId, paperType) => {
  if (disableMcqExamSubject(activeSubjectId) && paperType === 'Paper' ) {
    return 'Paper 2'
  } else {
    return paperType
  }
}

export function examAnswerStatistics(val) {
  switch (val) {
    case 0:
      return 'unconfident';
    case 1:
      return 'confident'
    case 2:
      return 'neither'
    case -1:
      return '_destroy'
    default:
      return val;
  }
}

export function examTeachingFilter(teachingTag, list, filter,form,mediaType) {
  if(form === 'topics' && mediaType === 'exams'){
  if (teachingTag == "HL") {
    if (filter.length > 1 || filter.length === 0) {
      return list
    } else if (filter.includes('HL')) {
      return list.filter((val) => {
        return val.teachingTag === 'HL';
      })
    } else {
      return list.filter((val) => {
        return val.teachingTag == 'SL&HL' || val.teachingTag == 'SL' ;
      })
    }
  }else{
    return list
  }
}else if(form === 'exams'){
  if (teachingTag == "HL") {
    if (filter.length > 1 || filter.length === 0) {
      return list
    } else if (filter.includes('HL')) {
      return list.filter((val) => {
        return val.teachingTag == 'HL';
      })
    } else {
      return list.filter((val) => {
        return val.teachingTag == 'SL&HL'  ;
      })
    }
  }else{
    return list
  }
}else{
  return list
}
}

export function topicTeachingFilter(teachingTag, list, filter,form,mediaType) {
  if(form === 'topics' && mediaType === 'exams'){
  if (teachingTag == "HL") {
    if (filter.length > 1 || filter.length === 0) {
      return list
    } else if (filter.includes('HL')) {
      return list.filter((val) => {
        return val.questionTeachingTag === 'HL';
      })
    } else {
      return list.filter((val) => {
        return val.questionTeachingTag == 'SL&HL' || val.questionTeachingTag == 'SL' ;
      })
    }
  }else{
    return list
  }
}else if(form === 'exams'){
  if (teachingTag == "HL") {
    if (filter.length > 1 || filter.length === 0) {
      return list
    } else if (filter.includes('HL')) {
      return list.filter((val) => {
        return val.questionTeachingTag == 'HL';
      })
    } else {
      return list.filter((val) => {
        return val.questionTeachingTag == 'SL&HL'  ;
      })
    }
  }else{
    return list
  }
}else{
  return list
}
}

export function sourceFilter(list, filter,mediaType) {
  if(mediaType === "exams"){
    if (filter.length > 1 || filter.length === 0) {
      return list
    } else if (filter.includes('OSC')) {
      return list.filter((val) => {
        return val.qb_code === 'osc_dp';
      })
    } else if(filter.includes('IB')){
      return list.filter((val) => {
        return val.qb_code === 'ib_dp' ;
      })
    }else{
        return list
    }
  }
  
}



export function paperFilter(list, filter,mediaType) {
  if(mediaType === "exams"){
    if(filter.length > 2 || filter.length === 0 ){
      return list
    }
    if(filter.length === 1){
      if(filter.includes('Paper 1')){      
        return list.filter((val) => {
          return val.paperType == 'Paper 1';       
        })
      }else if(filter.includes('Paper 2')){      
        return list.filter((val) => {
          return val.paperType == "Paper 2";        
        })
      }else if(filter.includes('Paper 3')){      
        return list.filter((val) => {
          return val.paperType == "Paper 3";         
        })
      }else{
        return list
    }
    }
    if(filter.length === 2){
      if(filter.includes('Paper 1') && filter.includes('Paper 2')){      
        return list.filter((val) => {
          return (val.paperType === "Paper 1"  || val.paperType === "Paper 2");        
        })
      }else if(filter.includes('Paper 1') && filter.includes('Paper 3')){      
        return list.filter((val) => {
          return (val.paperType === 'Paper 1'  || val.paperType === 'Paper 3');        
        })
      }else if(filter.includes('Paper 2') && filter.includes('Paper 3')){      
        return list.filter((val) => {
          return (val.paperType === 'Paper 2' || val.paperType === 'Paper 3');        
        })
      }
    }
    
  }else{
    return list 
  }

}

export function difficultyFilter(list, filter,mediaType){
  if(mediaType === "exams"){
    if(filter.length > 2 || filter.length === 0 ){
      return list
    }
    if(filter.length === 1){
      if(filter.includes('Accessible')){      
        return list.filter((val) => {
          return val.difficultyLevel === 'Accessible';        
        })
      }else if(filter.includes('Moderate')){      
        return list.filter((val) => {
          return val.difficultyLevel === "Moderate";        
        })
      }else if(filter.includes('Discriminating')){      
        return list.filter((val) => {
          return val.difficultyLevel === "Discriminating";         
        })
      }else{
        return list
    }
    }
    if(filter.length === 2){
      if(filter.includes('Accessible') && filter.includes('Moderate')){      
        return list.filter((val) => {
          return (val.difficultyLevel === "Accessible"  || val.difficultyLevel === "Moderate");        
        })
      }else if(filter.includes('Accessible') && filter.includes('Discriminating')){      
        return list.filter((val) => {
          return (val.difficultyLevel === 'Accessible'  || val.difficultyLevel === 'Discriminating');        
        })
      }else if(filter.includes('Moderate') && filter.includes('Discriminating')){      
        return list.filter((val) => {
          return (val.difficultyLevel === 'Moderate' || val.difficultyLevel === 'Discriminating');        
        })
      }
    }
    
  }else{
    return list 
  }
}

export function examsFilterNumberingUpdate(list,mediaType){
  if(mediaType ==='exams'){
   list.map((item,index)=>{
    item.numbering = index+1
  })
  return list
}else{
  return list
}
}
export function returnExamsStatusFilter(list , filter , paperType){
  if(paperType !== "Paper 1"){
    if(filter.length > 3 || filter.length === 0 ){
      return list
    }
    if(filter.length === 1){
      if(filter.includes('Not yet assessed')){      
        return list.filter((val) => {
          return val.user_response.correct === null;        
        })
      }else if(filter.includes('Unconfident')){      
        return list.filter((val) => {
          return val.user_response.correct === 0;        
        })
      }else if(filter.includes('Neither')){      
        return list.filter((val) => {
          return val.user_response.correct === 2;         
        })
      }else{
        return list.filter((val) => {
          return val.user_response.correct === 1;        
        })
      }
    }
    if(filter.length === 2){
      if(filter.includes('Not yet assessed') && filter.includes('Unconfident')){      
        return list.filter((val) => {
          return (val.user_response.correct === null  || val.user_response.correct === 0);        
        })
      }else if(filter.includes('Not yet assessed') && filter.includes('Neither')){      
        return list.filter((val) => {
          return (val.user_response.correct === null  || val.user_response.correct === 2);        
        })
      }else if(filter.includes('Unconfident') && filter.includes('Neither')){      
        return list.filter((val) => {
          return (val.user_response.correct === 0 || val.user_response.correct === 2);        
        })
      }else if(filter.includes('Unconfident') && filter.includes('Confident')){      
        return list.filter((val) => {
          return (val.user_response.correct === 0 || val.user_response.correct === 1);        
        })
      }else if(filter.includes('Neither') && filter.includes('Confident')){      
        return list.filter((val) => {
          return (val.user_response.correct === 2 || val.user_response.correct === 1);        
        })
      }else{
        return list.filter((val) => {
          return (val.user_response.correct === null  || val.user_response.correct === 1);   
        })
      }
    }
    if(filter.length === 3){
      if(filter.includes('Not yet assessed') && filter.includes('Unconfident') && filter.includes('Neither')){      
        return list.filter((val) => {
          return (val.user_response.correct === null || val.user_response.correct === 0 || val.user_response.correct === 2);        
        })
      }else if(filter.includes('Not yet assessed') && filter.includes('Neither') && filter.includes('Confident')){      
        return list.filter((val) => {
          return (val.user_response.correct === null || val.user_response.correct === 2 || val.user_response.correct === 1);        
        })
      }else if(filter.includes('Unconfident') && filter.includes('Neither') && filter.includes('Confident')){      
        return list.filter((val) => {
          return (val.user_response.correct === 0 || val.user_response.correct === 2 || val.user_response.correct === 1);        
        })
      }else{
        return list.filter((val) => {
          return (val.user_response.correct === null || val.user_response.correct === 0 || val.user_response.correct === 1);        
        })
      }
    }
  }else if(paperType === "Paper 1"){
    if(filter.length > 2 || filter.length === 0 ){
      return list
    }
    if(filter.length === 1){
      if(filter.includes('Not yet answered')){      
        return list.filter((val) => {
          return val.user_response.correct === null;        
        })
      }else if(filter.includes('Incorrect')){      
        return list.filter((val) => {
          return val.user_response.correct === 1;        
        })
      }else if(filter.includes('Correct')){      
        return list.filter((val) => {
          return val.user_response.correct === 0;         
        })
      }else{
        return list
      }
    }
    if(filter.length === 2){
      
      if(filter.includes('Not yet answered') && filter.includes('Incorrect')){      
        return list.filter((val) => {
          return (val.user_response.correct === null  || val.user_response.correct === 1);        
        })
      }else if(filter.includes('Not yet answered') && filter.includes('Correct')){      
        return list.filter((val) => {
          return (val.user_response.correct === null  || val.user_response.correct === 0);        
        })
      }else if(filter.includes('Incorrect') && filter.includes('Correct')){      
        return list.filter((val) => {
          return (val.user_response.correct === 1 || val.user_response.correct === 0);        
        })
      }
    }
  }else{
    return list
  }
}
function numberStatsConvertName(val){
  switch (val) {
    case 1:
      return 'incorrect';
    case 0:
      return 'correct'
    case null:
      return 'not yet answered'
    default:
      return val
  }
}



function mcqFilterData (list,filter){
    if(filter.length > 2 || filter.length === 0 ){
      return list
    }
    if(filter.length === 1){
      if(filter.includes('Not yet answered')){      
        return list.filter((val) => {
          return val.answers.user_response.correct === null;        
        })
      }else if(filter.includes('Incorrect')){      
        return list.filter((val) => {
          return val.answers.user_response.correct === 1;        
        })
      }else if(filter.includes('Correct')){      
        return list.filter((val) => {
          return val.answers.user_response.correct === 0;         
        })
      }else{
        return list
      }
    }
    if(filter.length === 2){
      if(filter.includes('Not yet answered') && filter.includes('Incorrect')){      
        return list.filter((val) => {
          return (val?.answers?.user_response?.correct === null  || val?.answers?.user_response?.correct === 1);        
        })
      }else if(filter.includes('Not yet answered') && filter.includes('Correct')){      
        return list.filter((val) => {
          return (val?.answers?.user_response?.correct === null  || val?.answers?.user_response?.correct === 0);        
        })
      }else if(filter.includes('Incorrect') && filter.includes('Correct')){      
        return list.filter((val) => {
          return (val?.answers?.user_response?.correct === 1 || val?.answers?.user_response?.correct === 0);        
        })
      }
    }
  
}

function frqFilterData (list,filter){
  if(filter.length > 3 || filter.length === 0 ){
    return list
  }
  if(filter.length === 1){
    if(filter.includes('Not yet assessed')){      
      return list.filter((val) => {
        return val.answers.user_response.correct === null;        
      })
    }else if(filter.includes('Unconfident')){      
      return list.filter((val) => {
        return val.answers.user_response.correct === 0;        
      })
    }else if(filter.includes('Neither')){      
      return list.filter((val) => {
        return val.answers.user_response.correct === 2;         
      })
    }else{
      return list.filter((val) => {
        return val.answers.user_response.correct === 1;        
      })
    }
  }
  if(filter.length === 2){
    if(filter.includes('Not yet assessed') && filter.includes('Unconfident')){      
      return list.filter((val) => {
        return (val.answers.user_response.correct === null  || val.answers.user_response.correct === 0);        
      })
    }else if(filter.includes('Not yet assessed') && filter.includes('Neither')){      
      return list.filter((val) => {
        return (val.answers.user_response.correct === null  || val.answers.user_response.correct === 2);        
      })
    }else if(filter.includes('Unconfident') && filter.includes('Neither')){      
      return list.filter((val) => {
        return (val.answers.user_response.correct === 0 || val.answers.user_response.correct === 2);        
      })
    }else if(filter.includes('Unconfident') && filter.includes('Confident')){      
      return list.filter((val) => {
        return (val.answers.user_response.correct === 0 || val.answers.user_response.correct === 1);        
      })
    }else if(filter.includes('Neither') && filter.includes('Confident')){      
      return list.filter((val) => {
        return (val?.answers?.user_response?.correct === 2 || val?.answers?.user_response?.correct === 1);        
      })
    }else{
      return list.filter((val) => {
        return (val.answers.user_response.correct === null  || val.answers.user_response.correct === 1);   
      })
    }
  }
  if(filter.length === 3){
    if(filter.includes('Not yet assessed') && filter.includes('Unconfident') && filter.includes('Neither')){      
      return list.filter((val) => {
        return (val.answers.user_response.correct === null || val.answers.user_response.correct === 0 || val.answers.user_response.correct === 2);        
      })
    }else if(filter.includes('Not yet assessed') && filter.includes('Neither') && filter.includes('Confident')){      
      return list.filter((val) => {
        return (val.answers.user_response.correct === null || val.answers.user_response.correct === 2 || val.answers.user_response.correct === 1);        
      })
    }else if(filter.includes('Unconfident') && filter.includes('Neither') && filter.includes('Confident')){      
      return list.filter((val) => {
        return (val.answers.user_response.correct === 0 || val.answers.user_response.correct === 2 || val.answers.user_response.correct === 1);        
      })
    }else{
      return list.filter((val) => {
        return (val.answers.user_response.correct === null || val.answers.user_response.correct === 0 || val.answers.user_response.correct === 1);        
      })
    }
  }
}

export function returnExamAnswerFilter(mcqList, frqList, mcqFilter, frqFilter, mediaType ,list){
 
  if(mediaType ==='exams'){
    // console.table(list)
    let multipleChooseData = []
    let freeResponseData = []
    if(mcqList && mcqList.length > 0){
      multipleChooseData = mcqFilterData(mcqList,mcqFilter)
    }
    if(frqList && frqList.length > 0){
      freeResponseData = frqFilterData(frqList,frqFilter)
    }
    const finalData = [...multipleChooseData , ...freeResponseData]
    return finalData
  }else{
  return list
  }
}

export function setJWTToken(val) {
  storageImpl.setItem('AP-JWT-Token',val);
}

export function getJWTToken() {
  return storageImpl.getItem('AP-JWT-Token');
}

export function getSubjectsTeachingLevel(){
  return JSON.parse(storageImpl.getItem('subjectsTeachingLevel'));
}
export function setSubjectsTeachingLevel(data){
  storageImpl.setItem('subjectsTeachingLevel', JSON.stringify(data));
}
export function chnageTeachingTag(val){
  switch (val) {
    case "SL":
      return "sl only"
    case "HL":
      return "hl only"
    default:
      return val
  }
}
export function teachingTagFilter(val){
  switch (val) {
    case "HL":
      return "hl only"
    case "SL&HL":
      return "sl & hl"
      case "hl":
      return "hl only"
    case "sl&hl":
      return "sl & hl"
    default:
      return val
  }
}

export function prevNextTopicsData(previewListByTopics, topicIdsByTeachingLvl, currentId, index){
  const flashcardTopicId = previewListByTopics[index].topicId;
  const flashcardCount = previewListByTopics.filter((item) => item.topicId === flashcardTopicId);

  const flashcardIndexOfTopic = flashcardCount.findIndex((item) => item.flashcardId === currentId);
  let topicIndex;
  if(flashcardIndexOfTopic-3 > 0){
      topicIndex = topicIdsByTeachingLvl.findIndex((item) => item.topicId === flashcardTopicId);
      
      let topicIds=[];
      if(topicIndex === 0){
          topicIds.push(topicIdsByTeachingLvl.toJS()[topicIndex+1].topicId);
      }else{
          topicIds.push(topicIdsByTeachingLvl.toJS()[topicIndex-1].topicId); 
          topicIds.push(topicIdsByTeachingLvl.toJS()[topicIndex+1].topicId); 
      } 
      return topicIds;
  }else{
    return false;
  }
  
}
export function changeQuestionBank(val){
  switch (val) {
    case "IB":
      return "ib_dp"
    case "OSC":
      return "osc_dp"
    default:
      return val
  }
}

export function countExamsStatus(list, type, fromTopic = false){
  if(type == "Paper 1" ){
    if(fromTopic){
      return list.reduce(function (r, a) {
        r[numberStatsConvertName(a?.answers?.user_response?.correct)] = r[numberStatsConvertName(a?.answers?.user_response?.correct)] || [];
        r[numberStatsConvertName(a?.answers?.user_response?.correct)].push(a);
        return r;
    }, Object.create(null));
    }else{
      return list.reduce(function (r, a) {
        r[numberStatsConvertName(a.user_response.correct)] = r[numberStatsConvertName(a.user_response.correct)] || [];
        r[numberStatsConvertName(a.user_response.correct)].push(a);
        return r;
    }, Object.create(null));
    }
  }else{
    if(fromTopic){
      return list.reduce(function (r, a) {
        r[PaperStatsConvertName(a.answers?.user_response?.correct)] = r[PaperStatsConvertName(a.answers.user_response?.correct)] || [];
        r[PaperStatsConvertName(a.answers?.user_response?.correct)].push(a);
        return r;
      }, Object.create(null));
    }else{
      return list.reduce(function (r, a) {
        r[PaperStatsConvertName(a?.user_response?.correct)] = r[PaperStatsConvertName(a?.user_response?.correct)] || [];
        r[PaperStatsConvertName(a?.user_response?.correct)].push(a);
        return r;
      }, Object.create(null));
    }
  }
}
function PaperStatsConvertName(val){
  switch (val) {
    case 0:
      return 'incorrect';
    case 1:
      return 'correct'
    case 2:
      return 'neither'
    case null:
      return 'not yet answered'
    default:
      return val
  }
}

export function sortHiddenInfoOverlayQuestions(list){
  let uniquePreviewData = [...new Map(list.map(item => [item['topicNumber'], item])).values()];
  return uniquePreviewData.sort((a, b) => {
    const x = a.topicNumber.split('.').map( n => +n+100000 ).join('.') 
    const y = b.topicNumber.split('.').map( n => +n+100000 ).join('.')
    const indexA = x;
    const indexB = y;
    if (indexA < indexB) return -1;
    return 1;
  });
}

export const detectIncognito = function() {
  return new Promise(function(resolve, reject) {
    var browserName = "Unknown";

    function __callback(isPrivate) {
      resolve({
        isPrivate: isPrivate,
        browserName: browserName
      });
    }

    function identifyChromium() {
      var ua = navigator.userAgent;
      if (ua.match(/Chrome/)) {
        if (navigator.brave !== undefined) {
          return "Brave"
        } else if (ua.match(/Edg/)) {
          return "Edge";
        } else if (ua.match(/OPR/)) {
          return "Opera";
        }
        return "Chrome";
      } else {
        return "Chromium";
      }
    }

    function assertEvalToString(value) {
      return value === eval.toString().length;
    }

    function isSafari() {
      var v = navigator.vendor;
      return v !== undefined && v.indexOf("Apple") === 0 && assertEvalToString(37);
    }

    function isChrome() {
      var v = navigator.vendor;
      return v !== undefined && v.indexOf("Google") === 0 && assertEvalToString(33);
    }

    function macOS_safari14() {
      try {
        window.safari.pushNotification.requestPermission("https://example.com", "private", {}, (function() {}));
      } catch (e) {
        return __callback(!new RegExp("gesture").test(e));
      }
      return __callback(false);
    }
    function iOS_safari14() {
      var tripped = false;
      var iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);

      iframe.contentWindow.applicationCache.addEventListener("error", function() {
        tripped = true;
        return __callback(true);
      });

      setTimeout(function() {
        if (!tripped) {
          __callback(false);
        }
      }, 100);
    }

    function oldSafariTest() {
      var openDB = window.openDatabase;
      var storage = window.localStorage;
      try {
        openDB(null, null, null, null);
      } catch (e) {
        return __callback(true);
      }
      try {
        storage.setItem("test", "1");
        storage.removeItem("test");
      } catch (e) {
        return __callback(true);
      }
      return __callback(false);
    }

    function safariPrivateTest() {
      var w = window;
      if (navigator.maxTouchPoints !== undefined) {
        if (w.safari !== undefined && w.DeviceMotionEvent === undefined) {
          browserName = "Safari for macOS";
          macOS_safari14();
        } else if (w.DeviceMotionEvent !== undefined) {
          browserName = "Safari for iOS";
          iOS_safari14();
        } else {
          reject(new Error("detectIncognito Could not identify this version of Safari"));
        }
      } else {
        browserName = "Safari";
        oldSafariTest();
      }
    }

    function getQuotaLimit() {
      var w = window;
      if (w.performance !== undefined && w.performance.memory !== undefined && w.performance.memory.jsHeapSizeLimit !== undefined) {
        return performance.memory.jsHeapSizeLimit;
      }
      return 1073741824;
    }

    // >= 76
    function storageQuotaChromePrivateTest() {
      navigator.webkitTemporaryStorage.queryUsageAndQuota(
        function(usage, quota) {
          __callback(quota < getQuotaLimit());
        },
        function(e) {
          reject(new Error("detectIncognito somehow failed to query storage quota: " + e.message));
        }
      );
    }

    // 50 to 75
    function oldChromePrivateTest() {
      var fs = window.webkitRequestFileSystem;
      var success = function() {
        __callback(false);
      };
      var error = function() {
        __callback(true);
      };
      fs(0, 1, success, error);
    }

    function chromePrivateTest() {
      if (Promise !== undefined && Promise.allSettled !== undefined) {
        storageQuotaChromePrivateTest();
      } else {
        oldChromePrivateTest();
      }
    }

    function main() {
      if (isSafari()) {
        safariPrivateTest();
      } else if (isChrome()) {
        browserName = identifyChromium();
        chromePrivateTest();
      } else {
        reject(new Error("detectIncognito cannot determine the browser"));
      }
    }
    main();
  });
};

export const defaultMenuPathObj = { 
  'topics': '/flashcard/topics',
  'notes': '/flashcard/desk/notes',
  'folders': '/flashcard/desk/folders',
  'exercises': '/flashcard/desk/exercises',
  'search': '/flashcard/search',
  'editNotes' : '/flashcard/desk/notes/edit',
  'exams':'/flashcard/desk/exams'
}


export const navigationsTab = [
  {
    name:'topic',
    tab:0
  },
  {
    name:'exams',
    tab:1
  },
  {
    name:'notes',
    tab:2
  },
  {
    name:'folders',
    tab:3
  },
  {
    name:'exercise',
    tab:4
  },
  {
    name:'classroom',
    tab:4
  },
  {
    name:'search',
    tab:5
  }
]

export const navigationsName = [
  {
    name:'folders',
    tabName:'folders'
  },
  {
    name:'exams',
    tabName:'exams'
  },
  {
    name:'notes',
    tabName:'notes'
  },
  {
    name:'search',
    tabName:'search'
  },
  {
    name:'exercises',
    tabName:'exercises'
  },
  {
    name:'exercise',
    tabName:'exercises'
  },
  {
    name:'classroom',
    tabName:'exercises'
  },
  {
    name:'topics',
    tabName:'topics'
  },
  {
    name:'topic',
    tabName:'topics'
  },
  {
    name:'subtopic',
    tabName:'topics'
  }
]

export const leftMenuItems = [
  {
    name: 'Topics',
    icon: TopicsIcon,
    hasNotification: false,
    index: 0,
    url: 'topics'
  },
  {
    name: 'Exams',
    icon: ExamsIcon,
    hasNotification: false,
    index: 1,
    url: 'exams'
  },
  {
    name: 'Notes',
    icon: NoteIcon,
    hasNotification: false,
    index: 2,
    url: 'notes'
  },
  {
    name: 'Folders',
    icon: FolderIcon,
    hasNotification: false,
    index: 3,
    url: 'folders'
  },
  {
    name: 'Exercises',
    icon: ExerciseIcon,
    hasNotification: false,
    index: 4,
    url: 'exercises'
  },
  {
    name: 'Search',
    icon: SearchIcon,
    hasNotification: false,
    index: 5,
    url: 'search'
  }
]


export const mediaFilters = [
  'flashcard',
  'revision',
  'video',
  'exams',
]

export const examFilters = [
  'teachingLevel',
  'difficulty',
  'paper',
  'source',
  'mcq',
]

export function menuPathIndex() {
  return [
    { name: "topics", id: 0 },
    { name: "topic", id: 0 },
    { name: "exams", id: 1 },
    { name: "notes", id: 2 },
    { name: "folders", id: 3 },
    { name: "exercise", id: 4 },
    { name: "classroom", id: 4 },
    { name: "search", id: 5 },
  ];
}
export const returnFinalStatusV2 = (data) => {
  const {flashcard, revision, video,exams,mcq} = data;
  const flashcardStatus = {
      total: flashcard.total || 0,
      correct: flashcard.correct || 0,
      neither: flashcard.neither || 0,
      incorrect: flashcard.incorrect|| 0,
  };
  
  const revisionStatus = {
      total: revision.total || 0,
      read: revision.nodeWatchRead || 0
  };
  const videoStatus = {
      total: video.total || 0,
      read: video.nodeWatchRead || 0
  };
  const examsStatus = {
    total: exams.total || 0,
    correct: exams.correct || 0,
    neither: exams.neither || 0,
    incorrect: exams.incorrect|| 0,
  };
  const mcqStatus ={
    total: mcq.total || 0,
    correct: mcq.correct || 0,
    incorrect: mcq.incorrect|| 0,
  }
  const flashcardStatusGraph = [
      {
          title: "Unconfident",
          value: flashcardStatus.incorrect,
          percentage: flashcardStatus.incorrect * 100/flashcardStatus.total,
          color: "text-[#FA9A00]"
      },
      {
          title: "Neither",
          value: flashcardStatus.neither,
          percentage: ((flashcardStatus.incorrect * 100/flashcardStatus.total) <= 3.5 && (flashcardStatus.incorrect * 100/flashcardStatus.total) > 0) ? ((parseInt(flashcardStatus.incorrect)+parseInt(flashcardStatus.neither)) * 100/flashcardStatus.total)+3.5 :(parseInt(flashcardStatus.incorrect)+parseInt(flashcardStatus.neither)) * 100/flashcardStatus.total,
          color: "text-[#C2D0D9]"
      },
      {
          title: "Confident",
          value: flashcardStatus.correct,
          percentage: (((flashcardStatus.incorrect * 100/flashcardStatus.total)<= 3.5 && (flashcardStatus.incorrect * 100/flashcardStatus.total) > 0) || ((flashcardStatus.neither * 100/flashcardStatus.total)<= 3.5 && (flashcardStatus.neither * 100/flashcardStatus.total) > 0) ) ? ((parseInt(flashcardStatus.correct)+parseInt(flashcardStatus.incorrect)+parseInt(flashcardStatus.neither)) * 100/flashcardStatus.total)+3.5 : (parseInt(flashcardStatus.correct)+parseInt(flashcardStatus.incorrect)+parseInt(flashcardStatus.neither)) * 100/flashcardStatus.total, 
          color: "text-[#50D4A8]"
      },
  ];
  const examsStatusGraph = [
    {
        title: "Unconfident",
        value: examsStatus.incorrect,
        percentage: examsStatus.incorrect * 100/examsStatus.total,
        color: "text-[#FA9A00]"
    },
    {
        title: "Neither",
        value: examsStatus.neither,
        percentage: ((examsStatus.incorrect * 100/examsStatus.total) <= 3.5 && (examsStatus.incorrect * 100/examsStatus.total) > 0) ? ((parseInt(examsStatus.incorrect)+parseInt(examsStatus.neither)) * 100/examsStatus.total)+3.5 :(parseInt(examsStatus.incorrect)+parseInt(examsStatus.neither)) * 100/examsStatus.total,
        color: "text-[#C2D0D9]"
    },
    {
        title: "Confident",
        value: examsStatus.correct,
        percentage: (((examsStatus.incorrect * 100/examsStatus.total)<= 3.5 && (examsStatus.incorrect * 100/examsStatus.total) > 0) || ((examsStatus.neither * 100/examsStatus.total)<= 3.5 && (examsStatus.neither * 100/examsStatus.total) > 0) ) ? ((parseInt(examsStatus.correct)+parseInt(examsStatus.incorrect)+parseInt(examsStatus.neither)) * 100/examsStatus.total)+3.5 : (parseInt(examsStatus.correct)+parseInt(examsStatus.incorrect)+parseInt(examsStatus.neither)) * 100/examsStatus.total, 
        color: "text-[#50D4A8]"
    },
];
const mcqStatusGraph=[
  {
    title:'Incorrect',
    value: mcqStatus.incorrect,
    percentage: mcqStatus.incorrect * 100/mcqStatus.total,
    color: mcqStatus.incorrect == 0 ? "text-[#2D3838]" : "text-[#FC2808]",
  },
  {
    title: "Correct",
    value: mcqStatus.correct,
    percentage: ((mcqStatus.incorrect * 100/mcqStatus.total) <= 3.5 && (mcqStatus.incorrect * 100/mcqStatus.total) > 0) ? ((parseInt(mcqStatus.incorrect)+parseInt(mcqStatus.correct)) * 100/mcqStatus.total)+3.5 :(parseInt(mcqStatus.incorrect)+parseInt(mcqStatus.correct)) * 100/mcqStatus.total,
    color:  mcqStatus.correct == 0 ? "text-[#2D3838]" : "text-[#50D4A8]",
},
]
  const revisionStatusGraph = [
      {
          title: "Read",
          value: revisionStatus.read,
          percentage: revisionStatus.read * 100/revisionStatus.total,
          color: "text-[#2D3838]"
      } 
  ];
  const videoStatusGraph = [
      {
          title: "Watched",
          value: videoStatus.read,
          percentage: videoStatus.read * 100/videoStatus.total,
          color: "text-[#2D3838]"
      }   
  ];
  return {
    flashcardStatus,
    revisionStatus,
    videoStatus,
    examsStatus,
    flashcardStatusGraph,
    revisionStatusGraph,
    videoStatusGraph,
    examsStatusGraph,
    mcqStatusGraph,
    mcqStatus
  }
}


export function getFlashcardPreviewAndSide(preview, activeSearchTerm, activeTab) {
  if (!preview) {
    return {
      text: "",
      side: 0
    }
  }
  let regex = new RegExp(activeSearchTerm, 'gi');
  const textContent = preview.get('textContent');
  const svgContent = preview.get('svgContent');
  const sides = Math.max(textContent.size, svgContent.size);

  let returnObj = {
    text: textContent.join(' '),
    side: 0
  };
  for (let i = 0; i < sides; i++) {
    let textForCurrentIndex = textContent.get(i);
    let svgForCurrentIndex = svgContent.get(i);
    if (activeSearchTerm != null) {
      if (activeSearchTerm.indexOf("’") !== -1) {
        activeSearchTerm = activeSearchTerm.replace("’", "'");
        regex = new RegExp(activeSearchTerm, 'gi');
        textForCurrentIndex = textForCurrentIndex.replaceAll("’", "'");
        svgForCurrentIndex = svgForCurrentIndex.replaceAll("’", "'");
      }
      if (activeSearchTerm.indexOf("‘") !== -1) {
        activeSearchTerm = activeSearchTerm.replace("‘", "’");
        regex = new RegExp(activeSearchTerm, 'gi');
        textForCurrentIndex = textForCurrentIndex.replaceAll("‘", "’");
        svgForCurrentIndex = svgForCurrentIndex.replaceAll("‘", "’");
      }
      if (activeSearchTerm.indexOf("'") !== -1) {
        activeSearchTerm = activeSearchTerm.replace("'", "’");
        regex = new RegExp(activeSearchTerm, 'gi');
        textForCurrentIndex = textForCurrentIndex.replaceAll("'", "’");
        svgForCurrentIndex = svgForCurrentIndex.replaceAll("'", "’");
      }
    }
    if (activeSearchTerm != null && activeTab == 4) {
      if (textForCurrentIndex && textForCurrentIndex.search(regex) !== -1) {
        returnObj.text = preparePreviewString(textForCurrentIndex, activeSearchTerm).text;
        returnObj.side = i;
        return returnObj;
      } else if (svgForCurrentIndex && svgForCurrentIndex.search(activeSearchTerm) !== -1) {
        returnObj.text = preparePreviewString(svgForCurrentIndex, activeSearchTerm).text;
        returnObj.side = i;
        return returnObj;
      }
    } else {
      if (textForCurrentIndex) {
        returnObj.text = preparePreviewString(textForCurrentIndex, activeSearchTerm).text;
        returnObj.side = i;
        return returnObj;
      } else if (svgForCurrentIndex) {
        returnObj.text = preparePreviewString(svgForCurrentIndex, activeSearchTerm).text;
        returnObj.side = i;
        return returnObj;
      }
    }

  }

  return returnObj;
}


