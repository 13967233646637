import SavedIcon from 'assets/images/notifications/saved.svg';
import ChangeOrientationToLandscape from 'assets/images/notifications/change-orientation-to-landscape.svg';
import ChangeOrientationToPortrait from 'assets/images/notifications/change-orientation-to-portrait.svg';
import VerifyIcon from 'assets/images/notifications/verify-account\.svg';
import WarningIcon from 'assets/images/notifications/warning.svg';
type pathType = {
    [key: string]: string
}

const NotificationIcons: pathType = {
    savedIcon: SavedIcon,
    landscape: ChangeOrientationToLandscape,
    portrait: ChangeOrientationToPortrait,
    verifyIcon: VerifyIcon,
    warningIcon: WarningIcon
}
export default NotificationIcons;