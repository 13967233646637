import {SET_NODE_STATUS } from 'actions/status/statusActionTypes';
import {fromJS, Map} from 'immutable';

export default (state = Map({
  flashcard: Map({}),
    revision: Map({}),
    video: Map({}),
    exam:Map({})
}), action) => {
  switch (action.type) {
    case SET_NODE_STATUS:
      return state.withMutations(state => {
        action.data.forEach(node => {
            state.set(node.id, fromJS({flashcard: node.flashcard, revision: node.revision, video: node.video,exam:node.exam}));
        });
        return state;
      });
    default:
      return state;
  }
};
