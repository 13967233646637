import Avatar from 'assets/images/Avatar.png';
import AvatarCelebrate from 'assets/images/AvatarCelebrate.png';
import AvatarFantastic from 'assets/images/AvatarFantastic.png';
import AvatarNerdy from 'assets/images/AvatarNerdy.png';
import AvatarPolice from 'assets/images/AvatarPolice.png';
import AvatarRotate from 'assets/images/AvatarRotate.png';
import AvatarSmile from 'assets/images/AvatarSmile.png';
import AvatarWink from 'assets/images/AvatarWink.png';
import AvatarWow from 'assets/images/AvatarWow.png';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styled from 'styled-components/macro';

export default class SpeakingAvatarComponent extends Component {
  render() {
    return (
      <StyledSpeakingAvatar>
        {this.props.showAvatar === 'wow' ? <img height="86px" src={AvatarWow} alt=""/> : ''}
        {this.props.showAvatar === 'normal' ? <img height="86px" src={Avatar} alt=""/> : ''}
        {this.props.showAvatar === 'smile' ? <img height="86px" src={AvatarSmile} alt=""/> : ''}
        {this.props.showAvatar === 'fantastic' ? <img height="86px" src={AvatarFantastic} alt=""/> : ''}
        {this.props.showAvatar === 'wink' ? <img height="86px" src={AvatarWink} alt=""/> : ''}
        {this.props.showAvatar === 'nerdy' ? <img height="86px" src={AvatarNerdy} alt=""/> : ''}
        {this.props.showAvatar === 'celebrate' ? <img height="86px" src={AvatarCelebrate} alt=""/> : ''}
        {this.props.showAvatar === 'rotate' ? <img height="86px" src={AvatarRotate} alt=""/> : ''}
        {this.props.showAvatar === 'police' ? <img height="86px" src={AvatarPolice} alt=""/> : ''}

        {this.props.children}
      </StyledSpeakingAvatar>
    );
  }
}

SpeakingAvatarComponent.propTypes = {
  showAvatar: PropTypes.string.isRequired,
  children: PropTypes.number,
};


const StyledSpeakingAvatar = styled.div`
  img {
    min-height: 86px;
  }
`;
