import React from 'react'
import FilterHeader from './FilterHeader';
import './filter.scss'

type EnableCookiesProps = {
    onClose: () => void;
}  

const EnableCookies: React.FC<EnableCookiesProps> = ({ onClose }) => {
   
    return <div className='filter-wrapper' onClick={e => e.stopPropagation()}>
        <FilterHeader
            hideArrow={false} 
            text={'Filter'} 
            title={'Enable cookies'} 
            onCloseClick={onClose} 
        />
        <div className='relative p-0 px-5'>
            <div className='text-15px text-#2D3838 leading-19px'>
                Your browser is currently blocking cookies - something OSC Study needs in order to display exams.<br /><br /> To experience exams, either 
                <ul className='unorder-list'>
                    <li> quit Incognito mode, or</li>
                    <li> enable cookies for this website.</li>
                </ul>
            </div>
            <div className='cookie-active-bar absolute left-0 bg-#FA9000 top-0 bottom-0 w-1 rounded-tl-[20px] rounded-bl-[20px]'></div>
        </div>
        <div className='mt-3.5 pt-0 px-5 pb-6'>
            <div className='continue-btn !max-h-[34px]' onClick={onClose}>
                <div className='continue-txt text-13px font-bold text-blue-normal leading-18px tracking-2px'>OK</div>
            </div>
        </div>
    </div>
}

export default EnableCookies;