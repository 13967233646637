import React, { useEffect } from 'react';
import { searchNotes } from 'actions/api';
import { useDispatch, useSelector } from 'react-redux';
import { activeSubjectId, getActiveSubject, getActiveTeachingLevel, subjectsTeachingLevel } from 'selectors/subject/subjectSelector';
import { activeSearchTerm } from 'selectors/searchSelectors';
import usePrevious from 'hooks/usePrevious';
import { getActiveMediaTab, getExerciseOnCanvas, isExamsMode } from 'selectors/navigation/navigationSelector';
import { examsofFilterItems } from 'selectors/desk/deskSelector';
import { notesSearchResponse } from 'selectors/api/apiSelector';
import { difficultyFilter, paperFilter, preparePreviewString, setExamSubTopicId, setExamTopicId, setFormExams, setLastMediaTypeVisited, sourceFilter, storeExamsParentId, topicTeachingFilter } from 'helpers';
import { teachingFilterResultsListToArray } from 'v2/helpers'
import SearchResultsHeader from 'pages/search/SearchResultsHeader';
import { setActiveSearchTab, setGoBackToSearch } from 'actions/search';
import { NotesIcon } from 'icons/FlashcardIcons';
import ExamsFilterTile from 'v2/components/filter-overlay/exams-filter-container/ExamsFilterTile';
import { setActiveFlashcardTab, setActivePaperTab, setExamsHeaderList, setExerciseOnCanvas } from 'actions/navigation';
import { useHistory } from 'react-router-dom';
import { currentFlashcardId } from 'selectors/studying';
import ExamPreviewListItem from 'v2/pages/desk/exam/ExamPreviewListItem';
import { ForwardChevronIcon } from 'icons/HeaderIcons';
import { getUserId, isMBFromSchool, isOSCUser } from 'selectors/user';
import { setActiveExerciseId } from 'actions/studying';
import { selectNote } from 'actions/desk';
import PreviewListHeader from './PreviewListHeader';
import ExamsPreviewListItem from './ExamsPreviewListItem';
import PreviewListItem from './PreviewListItem';
import ExamPreviewListHeader from 'v2/ui-components/search-results/ExamPreviewListHeader';
import { transformResultsListToArray } from 'v2/helpers';
import NoResults from './NoResults';

type NotesResultsListContainerProps = {
  isPartial?: boolean;
  hidden?: boolean;
}

const NotesResultsListContainer: React.FC<NotesResultsListContainerProps> = ({ isPartial = false, hidden = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const subjectId = useSelector(activeSubjectId);
  const subject = useSelector(getActiveSubject);
  const searchTerm = useSelector(activeSearchTerm);
  const activeTlvl = useSelector(getActiveTeachingLevel);
  const activeMediaTab = useSelector(getActiveMediaTab);
  const currentId = useSelector(currentFlashcardId);
  const notes = useSelector(notesSearchResponse);
  const subjectsTlvl = useSelector(subjectsTeachingLevel);
  const hasExamsMode = useSelector(isExamsMode);
  const exerciseOnCanvas = useSelector(getExerciseOnCanvas);
  const hasOSCUser = useSelector(isOSCUser)
  const hasMBFromSchool = useSelector(isMBFromSchool)
  const userId = useSelector(getUserId);
  const prevSearchTerm = usePrevious(searchTerm);
  const prevTlvl = usePrevious(activeTlvl);
  const prevMediaTab = usePrevious(activeMediaTab);
  const examsofFilterItemsToJS = useSelector(examsofFilterItems).toJS();
  const requestSucceeded = notes.succeeded;
  const requestLoading = notes.loading;
  const requestData = notes.data;
  const teachingTag = subjectsTlvl[`${subjectId}`];
  const sourceData = requestData && sourceFilter(requestData, examsofFilterItemsToJS.source, activeMediaTab);
  const paperData = requestData && paperFilter(sourceData, examsofFilterItemsToJS.paper, activeMediaTab);
  const difficultyData = requestData && difficultyFilter(paperData, examsofFilterItemsToJS.difficulty, activeMediaTab)
  const teachingTagData = requestData && topicTeachingFilter(teachingTag, difficultyData, examsofFilterItemsToJS.teachingLevel, "topics", activeMediaTab)

  const resultData = requestData && teachingFilterResultsListToArray(requestData, teachingTag, subject);
  const newListItems = resultData ? transformResultsListToArray(activeMediaTab === 'exams' ? teachingTagData : resultData) : [];
  const hasData = resultData && activeMediaTab === 'exams' ? resultData.length > 0 : newListItems.length > 0;

  const startSearch = (page: number) => {
    dispatch(searchNotes({
      searchField: searchTerm,
      page: page,
      limit: isPartial ? 3 : null
    }));
  };
  useEffect(() => {
    searchTerm && startSearch(0);
  }, [])

  useEffect(() => {
    if (searchTerm !== prevSearchTerm || activeTlvl !== prevTlvl || activeMediaTab !== prevMediaTab) {
      startSearch(0);
    }
  }, [searchTerm, prevSearchTerm, activeTlvl, prevTlvl, activeMediaTab, prevMediaTab])

  const onPaperClick = (item: any) => {
    const paperId = item.experienceData?.paper_uuid;
    const examsId = item.experienceData?.exam_uuid;
    const paperLable = item.experienceData?.tag_labels?.filter((item: any) => item.type == 'paper_type')[0]?.label;
    dispatch(setActivePaperTab(paperLable));
    dispatch(setActiveFlashcardTab(1));
    history.push(`/flashcard/desk/exams/${examsId}/${paperId}`);
  }

  const onListItemClick = (flashcardId: any, from: boolean = false, listItem: any = null) => {
    let url = history.location.pathname;
    let navigationMode = url.indexOf("/desk/notes") ? "noteSearch" : null;
    setLastMediaTypeVisited(activeMediaTab, userId);
    if (exerciseOnCanvas || typeof exerciseOnCanvas == "undefined") {
      dispatch(setExerciseOnCanvas(false))
      dispatch(setActiveExerciseId(null))
    }
    if (activeMediaTab === 'exams') {
      let type
      let parentId
      if (from) {
        type = 'topics';
        parentId = listItem.topicId
      } else {
        type = 'exams'
        parentId = listItem.experienceData.paper_uuid
      }
      dispatch(setExamsHeaderList({ "type": type, "parentId": parentId }))
      setFormExams(type)
      if(hasOSCUser || hasMBFromSchool) {
        setExamTopicId(parentId)
      }
      else {
        setExamTopicId(listItem?.topicId)
      }
      setExamSubTopicId(listItem.subTopicId)
      storeExamsParentId(parentId)
    }
    dispatch(selectNote(flashcardId, navigationMode));
    history.push('/flashcard/desk/notes/edit');
    dispatch(setGoBackToSearch(true));
  };

  const onSubTopicClick = (subTopicId: string) => {
    history.push(`/flashcard/subtopic/${subTopicId}`);
    dispatch(setActiveFlashcardTab(0));
  }

  const listHeaderProps = (item: any, index: number) => {
    const isExamsTabAndExamsMode = (activeMediaTab === 'exams' && hasExamsMode);
    const examsTopicTitle = item[0].exam + ' • ' + item[0].paper;
    return ({
      index: index,
      onClick: () => isExamsTabAndExamsMode ? onPaperClick(item?.[0]) : onSubTopicClick(item[0].subTopicId),
      subTopicId: item[0].subTopicId,
      subTopicTitle: isExamsTabAndExamsMode ? examsTopicTitle : activeMediaTab === 'exams' ? item[0].subTopicTitle : item[0].subTopicName,
      teachingLevel: item[0].teachingLevel,
      teachingTag: item[0].teachingTag ?? null,
      topicNumber: item[0].topicNumbering,
    })
  }

  const listItemProps = (subItem: any) => {
    const flashcardId = subItem.flashcardId;
    const preparedPreview = preparePreviewString(subItem.content, searchTerm);
    return ({
      active: flashcardId === currentId,
      isEditing: false,
      flashcardId: flashcardId,
      flashcardNumber: activeMediaTab === 'exams' ? subItem.flashcardId : subItem.numbering,
      listOfCheckedItems: subItem,
      onClick: () => activeMediaTab === 'exams' ? onListItemClick(flashcardId, true, subItem) : onListItemClick(flashcardId),
      preview: activeMediaTab === 'exams' ? subItem.content : preparedPreview.text,
      rightSection: <RightSection text={preparedPreview.occurences} />,
      type: 'notes',
    });
  }

  if (hidden) return null;
  if (requestSucceeded && !isPartial && !hasData) return <NoResults />;
  return <>
    <SearchResultsHeader show={hasData} title={'Notes'} icon={<NotesIcon />} showMore={isPartial} total={notes.count} onClick={() => dispatch(setActiveSearchTab('Notes'))} />
    {activeMediaTab === 'exams' && hasData &&
      <div className='pt-2 pb-6'>
        <ExamsFilterTile type={"notes"} />
      </div>}
    {!requestLoading && newListItems.length === 0 ? <NoResults /> : <>
      {(activeMediaTab === 'exams' && hasExamsMode) ? <>
        {newListItems.map((value: any, index: number) => <><ExamPreviewListHeader fromPage={'notes'} {...listHeaderProps(value, index)} />
          {value.map((subItems: any) => <ExamPreviewListItem key={`exam-${Math.random()}`}
            type={'notes'}
            number={subItems.numbering}
            active={subItems.flashcardId === currentId}
            teachingLevel={subItems.teachingLevel}
            teachingTag={subItems.teachingTag}
            marks={subItems.marks}
            experienceData={subItems}
            flashcardId={subItems.nodeId}
            previewTitle={subItems.title}
            onClick={() => onListItemClick(subItems.flashcardId, false, subItems)}
            preview={subItems.content}
            rightSection={<RightSection text={preparePreviewString(subItems.content, searchTerm).occurences} />}
            QuestionType={subItems.questionType} />)}
        </>)
        }
      </> : <>
        {newListItems.map((item: any, index: number) => <div key={`notes-${item[0].category}`}>
          <PreviewListHeader {...listHeaderProps(item, index)} data={item?.[0]} />
          {item.map((subItem: any) => {
            const flashcardId = subItem.flashcardId;
            return (activeMediaTab === 'exams') ?
              <ExamsPreviewListItem key={`exam-${Math.random()}`}
                experienceData={subItem.experienceData}
                title={subItem.questionTitle}
                paper={item.paper}
                teachingTag={subItem.questionTeachingTag}
                marks={subItem.marks}
                sources={subItem.experienceData}
                formSearch={true}
                difficulty={subItem.difficultyLevel}
                nodeTeachingTag={subItem?.nodeTeachingTag}
                {...listItemProps(subItem)} />
              : <PreviewListItem key={`note-flashcard-${flashcardId}`}
                previewTitle={'New Flashcard'}
                shouldMark={true}
                activeMath={[15075, 14982].includes(subjectId)}
                deleted={false}
                data={subItem}
                {...listItemProps(subItem)}
              />
          })}
        </div>)}
      </>}
    </>}
  </>
}

type RightSectionProps = {
  text: string | number;
}
const RightSection: React.FC<RightSectionProps> = ({ text }) => <div className='flex justify-center items-center'>
  <div className='flex items-center justify-center px-[5px] leading-[1.1] min-w-[20px] h-5 rounded-[10px] bg-blue-normal text-white text-13px font-[600]'>{text}</div>
  <div className='pl-2'><ForwardChevronIcon /></div>
</div>

export default NotesResultsListContainer