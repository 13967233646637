import Renderer from 'v2/components/studying/renderer/RendererContainer';
import React, {useRef, useState, useEffect, useCallback} from 'react';
import {currentFlashcardId} from 'selectors';
import './carousel.scss';
import FlashcardBottomControls from 'v2/components/studying/FlashcardBottomControlsContainer';
import usePrevious from 'hooks/usePrevious';
import {useSelector} from "react-redux"

type FlashcardCarouselProps = {
  order: number[];
  hasPrev: boolean;
  hasNext: boolean;
  isMobile: boolean;
  isLandscape: boolean;
  animate: boolean;
  flashcardIndex: number;
  carouselRefIndex: number;
  rendererIds: any;
  currentFlashcard: any;
  flipStatus:any;
}  

const FlashcardCarousel: React.FC<FlashcardCarouselProps> = (props) => {
  const {order, isMobile, isLandscape, animate, flashcardIndex, rendererIds, flipStatus} = props;
  const [isHoverEffect, setHoverEffect] = useState<boolean>(false);
  const [isAllowAnimation, setAllowAnimation] = useState<boolean>(false);
  let flashcardBottomControlsRef:any = useRef<HTMLDivElement>(null);
  const prevAnimate  = usePrevious(animate);
  const canvas =useRef<HTMLUListElement>(null);
  const hasCurrentFlashcardId = useSelector(currentFlashcardId)
 

  useEffect(() => {
    setAllowAnimation(true);
  },[])

  useEffect(() => {
    if(prevAnimate !== animate){
      setAllowAnimation(false);
      setTimeout(() => {
        setAllowAnimation(true);
      }, 200);
    } 
  },[animate, prevAnimate]);

  useEffect(() => {
    if(isHoverEffect){
      setTimeout(() => {
        setHoverEffect(false);
      }, 150);
    }
  }, [isHoverEffect])
  
  const setFlashcardBottomControlsRef = (ref: any) => {
    flashcardBottomControlsRef = ref;
  }

  const getCardSize = () => {
    return canvas.current?.clientWidth;
  };

  const calculateTranslate = (index: number) => {
    const CARD_SIZE: number = getCardSize() || 0;
    return (index - 1) * CARD_SIZE;
  };

  const calculateLeft = (index: number) => {
    const CARD_SIZE: number = getCardSize() || 0;
    return CARD_SIZE * (index - 1) || 0;
  };

  const onActionFlashcard = (actionKey:string = "nextHover", value: any, callback: () => void, canFlip: boolean = true) => {
    let selectorClass = actionKey.includes("next") ? "next" : actionKey.includes("flip") ? "flip" : "prev";
    
    if(!canFlip){
      let selectorRenderClass = canvas.current?.getElementsByClassName(
        `flashcard-id-${hasCurrentFlashcardId}`
      )[0];
      if(selectorRenderClass){
        selectorRenderClass.classList.remove('flip-animation');
        selectorRenderClass.classList.add('flip-animation');
        setTimeout(() => {
          selectorRenderClass?.classList.remove('flip-animation');
        }, 150)
      }
    }
    if(isHoverEffect){
      let selectedControl = flashcardBottomControlsRef?.getElementsByClassName(
        `${selectorClass}-btn`
      )[0];
      if(selectedControl){
        selectedControl.classList.remove("hover");
      }
      // return;
    }
    const classToAdd = actionKey.includes("Hover") ? "hover" : "click"
      let selectedControl = flashcardBottomControlsRef?.getElementsByClassName(
        `${selectorClass}-btn`
      )[0];
      if(selectedControl){
        if(value){
          selectedControl.classList.add(classToAdd);
          if(classToAdd === "click") {
            setTimeout(() => {
              selectedControl?.classList.remove(classToAdd);
            }, 150)
            setHoverEffect(true);
          }
        } else {
          selectedControl.classList.remove(classToAdd);
        }
      }
    callback?.()
    return
  }
  
  const renderRenderers = useCallback((leftValue: any) => {
    let renderers = new Array(3);

    for (let i = 0; i < 3; i++) {  
      const flashcardId = rendererIds?.[i];
      renderers[i] = (
        <li className="carousel-seat" style={{left: leftValue, order: order[i]}} key={flashcardId}>
          {/*TODO extract to study container*/}
          <div className={'full-height flex justify-center carousel-fc'} >
            <div className='flashcard-render-wrapper'>
              <Renderer
                flashcardId={flashcardId}
                responsive={false}
                onActionFlashcard={onActionFlashcard}
                canEditExercise={false}
              />
            </div>
          </div>
        </li>
      );
    }

    return renderers;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rendererIds, order]);

  const renderNavigation = useCallback((val:boolean) => {
    return <FlashcardBottomControls setFlashcardBottomControlsRef={setFlashcardBottomControlsRef}  flipStatus= {flipStatus} isLandscape={val} isIPadLandscape={isLandscape}/>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[flipStatus, isLandscape, rendererIds]);

  const translateValue = calculateTranslate(flashcardIndex);
  const hasTransition = isAllowAnimation;
  const style = {
    transitionDuration: hasTransition ? '.2s' : '0s',
    transform: `translate3d(${-translateValue}px, 0, 0)`
  };
  return (
    <>
      <div className='carousel-wrapper'>
         {(isMobile && isLandscape) &&  renderNavigation(true)}
        <ul style={style} className="carousel" ref={canvas}>
          {renderRenderers(calculateLeft(flashcardIndex))}
        </ul>
        {!(isMobile && isLandscape) &&  renderNavigation(false)}
      </div>
    </>
  )
}

export default FlashcardCarousel;

