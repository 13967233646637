import { WarnIcon } from "v2/utils/form-helper";
import { ClearIcon } from "v2/utils/form-helper";

type ExerciseInputProps = {
  id: string,
  name: string,
  placeholder: string,
  type: string,
  reset: () => void,
  error: any,
  active: boolean,
  value: string,
  onChange: (e: any) => void,
  onBlur: (e: any) => void,
  onFocus: () => void,
  touched:any
}

const ExerciseInput: React.FC<ExerciseInputProps> = ({ touched, error, active, value, reset, placeholder, type, id, name, onChange, onBlur, onFocus }) => {
  const hasError = touched && error;
  return <div className="flex justify-between items-center">
    <input className='exercise-input' value={value} placeholder={placeholder} type={type}
      id={id} name={name} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
    {hasError && <WarnIcon />}

    {(value !== '') && <div className="cursor-pointer z-[1px]" onClick={reset}><ClearIcon type={'transparent'} /></div>}
  </div>
}

export default ExerciseInput
