import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getActiveExamModule,
  getActiveSubject,
  isApiRequestInProgress,
} from "selectors";
import { quickStatusById } from "selectors/quickSelectors";

const mathsAAandAIslug = ["maths-a-amp-a", "maths-a-amp-i"];

/**
 *
 * @returns {{loading: boolean, status: boolean}}
 */
const useExamModuleStatus = () => {
  const loading = useSelector((state) =>
    isApiRequestInProgress(state, "subjectStatusSaved")
  );
  const activeExamModule = useSelector(getActiveExamModule);
  const activeSubject = useSelector(getActiveSubject);
  const quickStatus = useSelector((state) =>
    quickStatusById(state, activeSubject?.id)
  );
  const { exam } =
    quickStatus.size > 0 ? quickStatus?.toJS() : { exam: undefined };
  const [status, setStatus] = useState({
    loading: loading,
    status:
      (exam?.freeResponse?.total === undefined &&
        exam?.msqTotal?.total === undefined) ||
      exam?.freeResponse?.total > 0 ||
      exam?.msqTotal?.total > 0,
  });

  useEffect(() => {
    setStatus(() => {
      if (!activeExamModule) {
        return {
          loading,
          status: mathsAAandAIslug.includes(activeSubject?.slug),
        };
      }
      return {
        loading,
        status:
          (exam?.freeResponse?.total === undefined &&
            exam?.msqTotal?.total === undefined) ||
          exam?.freeResponse?.total > 0 ||
          exam?.msqTotal?.total > 0,
      };
    });
  }, [
    activeExamModule,
    activeSubject?.slug,
    exam?.freeResponse?.total,
    exam?.msqTotal?.total,
    loading,
  ]);

  return status;
};

export default useExamModuleStatus;
