import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {isExamsMode} from 'selectors/navigation/navigationSelector'
import {getUserId, isMBFromSchool, isOSCUser} from 'selectors'
import {currentFlashcardId} from 'selectors/studying'
import {toggleExamsMode} from 'actions/navigation/navigationActions'
import ExamFilterLayout from "v2/components/studying/examFilterContainer/examFilterLayout"
import { getFormExams, getLastMediaTypeVisited} from 'helpers';

type ExamsFilterTileProps = {
    isEditMode?: boolean;
    type: string;
}  

const ExamsFilterTile: React.FC<ExamsFilterTileProps> = ({isEditMode, type}) => {
    const dispatch = useDispatch()
    const hasExamMode = useSelector(isExamsMode)
    const currentId = useSelector(currentFlashcardId)
    const userId = useSelector(getUserId)
    const hasMBFromSchool = useSelector(isMBFromSchool);
    const hasOSCUser = useSelector(isOSCUser)

    const selectToggle = (val: boolean) => {
        dispatch(toggleExamsMode(!val))
    }
    
    useEffect(() => {
        if(currentId && getLastMediaTypeVisited(userId) === 'exams'){
            if(getFormExams()==='exams' && currentId){
                dispatch(toggleExamsMode(true))
                return
            }
            dispatch(toggleExamsMode(false))
        }
    }, [])

    return <div className={classNames('px-5 pb-2 pt-4')}> 
        {(hasOSCUser || hasMBFromSchool) && <><div className='flex items-center justify-between'>
            <div className={classNames('text-#2D3838 text-15px leading-19px', {'opacity-50 pointer-events-none select-none' : isEditMode}, {'opacity-100': !isEditMode})}>Exams mode</div>
            <div>
                <label className="switch">
                <input disabled={isEditMode} onChange={() => selectToggle(hasExamMode)} type="checkbox" checked={hasExamMode} />
                <span className={isEditMode ? "opacity-50 cursor-default select-none slider round" : "slider round"}></span>
                </label>
            </div>
        </div>
        <div className='text-13px text-[#979999] leading-4 mt-2.5 mb-4'>Hides any info in the previews you would not receive in the actual exam</div></>}
        {(type !== 'filterSearch' || !hasExamMode) &&
            <div className='bg-white shadow-#3E5755 rounded-10px h-[108px] mt-1.5 p-3.5'>
                <ExamFilterLayout type={"filter"} examsMode={hasExamMode} isEditMode={isEditMode ? true : false} />
            </div>
        }
    </div>
}

export default ExamsFilterTile;