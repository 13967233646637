export const GET_SUBJECTS = 'GET_SUBJECTS';
export const GET_SUBJECTS_SUCCEEDED = 'GET_SUBJECTS_SUCCEEDED';
export const GET_SUBJECTS_FAILED = 'GET_SUBJECTS_FAILED';

export const GET_SUBJECTS_SYLLABUS = 'GET_SUBJECTS_SYLLABUS';
export const GET_SUBJECTS_SYLLABUS_SUCCEEDED = 'GET_SUBJECTS_SYLLABUS_SUCCEEDED';
export const GET_SUBJECTS_SYLLABUS_FAILED = 'GET_SUBJECTS_SYLLABUS_FAILED';

export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_SUCCEEDED = 'AUTHENTICATE_SUCCEEDED';
export const AUTHENTICATE_FAILED = 'AUTHENTICATE_FAILED';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCEEDED = 'GET_USER_SUCCEEDED';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_PARTNERS_SUCCEEDED = 'GET_PARTNERS_SUCCEEDED';
export const GET_PARTNERS_FAILED = 'GET_PARTNERS_FAILED';


export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCEEDED = 'REFRESH_TOKEN_SUCCEEDED';
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCEEDED = 'GET_PROFILE_SUCCEEDED';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const SAVE_PROFILE = 'SAVE_PROFILE';
export const SAVE_PROFILE_SUCCEEDED = 'SAVE_PROFILE_SUCCEEDED';
export const SAVE_PROFILE_FAILED = 'SAVE_PROFILE_FAILED';

export const GET_CONTACT_DETAILS = 'GET_CONTACT_DETAILS';
export const GET_CONTACT_DETAILS_SUCCEEDED = 'GET_CONTACT_DETAILS_SUCCEEDED';
export const GET_CONTACT_DETAILS_FAILED = 'GET_CONTACT_DETAILS_FAILED';

export const SAVE_CONTACT_DETAILS = 'SAVE_CONTACT_DETAILS';
export const SAVE_CONTACT_DETAILS_SUCCEEDED = 'SAVE_CONTACT_DETAILS_SUCCEEDED';
export const SAVE_CONTACT_DETAILS_FAILED = 'SAVE_CONTACT_DETAILS_FAILED';

export const REFRESH_TOKEN_ENDED = 'REFRESH_TOKEN_ENDED';

export const GET_FLASHCARD_CONTENT = 'API_GET_FLASHCARD_CONTENT';
export const GET_FLASHCARD_CONTENT_SUCCEEDED = 'API_GET_FLASHCARD_CONTENT_SUCCEEDED';
export const GET_FLASHCARD_CONTENT_FAILED = 'API_GET_FLASHCARD_CONTENT_FAILED';

export const SET_REVISION_LINK = 'API_SET_REVISION_LINK';
export const SET_REVISION_LINK_SUCCEEDED = 'API_SET_REVISION_LINK_SUCCEEDED';
export const SET_REVISION_LINK_FAILED = 'API_SET_REVISION_LINK_FAILED';

export const GET_ELEMENT_STYLES = 'API_GET_ELEMENT_STYLES';
export const GET_ELEMENT_STYLES_SUCCEEDED = 'API_GET_ELEMENT_STYLES_SUCCEEDED';
export const GET_ELEMENT_STYLES_FAILED = 'API_GET_ELEMENT_STYLES_FAILED';

export const GET_EXAMS = 'API_GET_EXAMS';
export const GET_EXAMS_SUCCEEDED = 'API_GET_EXAMS_SUCCEEDED';
export const GET_EXAMS_FAILED = 'API_GET_EXAMS_FAILED';

export const GET_EXAM_PREVIEW = 'GET_EXAM_PREVIEW';
export const GET_EXAM_PREVIEW_SUCCEEDED = 'API_GET_EXAM_PREVIEW_SUCCEEDED';
export const GET_EXAM_PREVIEW_FAILED = 'API_GET_EXAM_PREVIEW_FAILED';

export const GET_QUESTION_COUNT = 'GET_QUESTION_COUNT';
export const GET_QUESTION_COUNT_SUCCEEDED = 'API_GET_QUESTION_COUNT_SUCCEEDED';
export const GET_QUESTION_COUNT_FAILED = 'API_GET_QUESTION_COUNT_FAILED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCEEDED = 'CHANGE_PASSWORD_SUCCEEDED';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const CHANGE_USERNAME = 'CHANGE_USERNAME';
export const CHANGE_USERNAME_SUCCEEDED = 'CHANGE_USERNAME_SUCCEEDED';
export const CHANGE_USERNAME_FAILED = 'CHANGE_USERNAME_FAILED';

export const CONFIRM_EMAIL_ADDRESS = 'CONFIRM_EMAIL_ADDRESS';
export const CONFIRM_EMAIL_ADDRESS_SUCCEEDED = 'CONFIRM_EMAIL_ADDRESS_SUCCEEDED';
export const CONFIRM_EMAIL_ADDRESS_FAILED = 'CONFIRM_EMAIL_ADDRESS_FAILED';

export const GET_STUDENT_PACKAGES = 'GET_STUDENT_PACKAGES';
export const GET_STUDENT_PACKAGES_SUCCEEDED = 'GET_STUDENT_PACKAGES_SUCCEEDED';
export const GET_STUDENT_PACKAGES_FAILED = 'GET_STUDENT_PACKAGES_FAILED';

export const REGISTER_PURCHASE = 'REGISTER_PURCHASE';
export const REGISTER_PURCHASE_SUCCEEDED = 'REGISTER_PURCHASE_SUCCEEDED';
export const REGISTER_PURCHASE_FAILED = 'REGISTER_PURCHASE_FAILED';

export const REGISTER_PAYMENT = 'REGISTER_PAYMENT';
export const REGISTER_PAYMENT_SUCCEEDED = 'REGISTER_PAYMENT_SUCCEEDED';
export const REGISTER_PAYMENT_FAILED = 'REGISTER_PAYMENT_FAILED';

export const ADD_VOUCHER = 'ADD_VOUCHER';
export const ADD_VOUCHER_SUCCEEDED = 'ADD_VOUCHER_SUCCEEDED';
export const ADD_VOUCHER_FAILED = 'ADD_VOUCHER_FAILED';

export const GET_TRIAL_INFO = 'GET_TRIAL_INFO';
export const GET_TRIAL_INFO_SUCCEEDED = 'GET_TRIAL_INFO_SUCCEEDED';
export const GET_TRIAL_INFO_FAILED = 'GET_TRIAL_INFO_FAILED';
export const CHECK_PURCHASE = 'CHECK_PURCHASE';
export const CHECK_PURCHASE_SUCCEEDED = 'CHECK_PURCHASE_SUCCEEDED';
export const CHECK_PURCHASE_FAILED = 'CHECK_PURCHASE_FAILED';

const PREFIX = 'API_';
export const GET_ANSWERS = PREFIX + 'GET_ANSWERS';
export const GET_ANSWERS_SUCCEEDED = PREFIX + 'GET_ANSWERS_SUCCEEDED';
export const GET_ANSWERS_FAILED = PREFIX + 'GET_ANSWERS_FAILED';

export const SET_MARKS = PREFIX + 'SET_MARKS';
export const SET_MARKS_SUCCEEDED = PREFIX + 'SET_MARKS_SUCCEEDED';
export const SET_MARKS_FAILED = PREFIX + 'SET_MARKS_FAILED';

export const SET_VIDEO_PLAYED = PREFIX + 'SET_VIDEO_PLAYED';
export const SET_VIDEO_PLAYED_SUCCEEDED = PREFIX + 'SET_VIDEO_PLAYED_SUCCEEDED';
export const SET_VIDEO_PLAYED_FAILED = PREFIX + 'SET_VIDEO_PLAYED_FAILED';

export const SET_VIDEO_PLAYED_STATUS = PREFIX + 'SET_VIDEO_PLAYED_STATUS';
export const SET_VIDEO_PLAYED_STATUS_SUCCEEDED = PREFIX + 'SET_VIDEO_PLAYED_STATUS_SUCCEEDED';
export const SET_VIDEO_PLAYED_STATUS_FAILED = PREFIX + 'SET_VIDEO_PLAYED_STATUS_FAILED';

export const SET_EXAM_STATUS = PREFIX + 'SET_EXAM_STATUS';
export const SET_EXAM_STATUS_SUCCEEDED = PREFIX + 'SET_EXAM_STATUS_SUCCEEDED';
export const SET_EXAM_STATUS_FAILED = PREFIX + 'SET_EXAM_STATUS_FAILED';

export const CLEAR_API_REQUEST_DATA = PREFIX + 'CLEAR_API_REQUEST_DATA';

//LoggedIn Device List
export const GET_LOGEEDINDEVICE = 'GET_LOGEEDINDEVICE';
export const GET_LOGEEDINDEVICE_SUCCEEDED = 'GET_LOGEEDINDEVICE_SUCCEEDED';
export const GET_LOGEEDINDEVICE_FAILED = 'GET_LOGEEDINDEVICE_FAILED';

//Logout Device List
export const LOGOUTDEVICE = 'LOGOUTDEVICE';
export const LOGOUTDEVICE_SUCCEEDED = 'LOGOUTDEVICE_SUCCEEDED';
export const LOGOUTDEVICE_FAILED = 'LOGOUTDEVICE_FAILED';

export const ADD_TOPIC_DATA_LENGHT = 'ADD_TOPIC_DATA_LENGHT';
export const SET_CURRENT_REVISION_INDEX = 'SET_CURRENT_REVISION_INDEX';

export const SET_ANSWER_FLASHCARD = 'API_SET_ANSWER_FLASHCARD';
export const SET_ANSWER_FLASHCARD_SUCCEEDED = 'API_SET_ANSWER_FLASHCARD_SUCCEEDED';
export const SET_ANSWER_FLASHCARD_FAILED = 'API_SET_ANSWER_FLASHCARD_FAILED';

//SMPP-LAGGY
export const SET_QUICK_STATUS = 'API_SET_QUICK_STATUS';
export const SET_QUICK_STATUS_SUCCEEDED = 'API_SET_QUICK_STATUS_SUCCEEDED';
export const SET_QUICK_STATUS_FAILED = 'API_SET_QUICK_STATUS_FAILED';

export const GET_FEATURE = 'GET_FEATURE';
export const GET_FEATURE_SUCCEEDED = 'GET_FEATURE_SUCCEEDED';
export const GET_FEATURE_FAILED = 'GET_FEATURE_FAILED';

export const CLOSE_FEATURE = 'CLOSE_FEATURE';
export const CLOSE_FEATURE_SUCCEEDED = 'CLOSE_FEATURE_SUCCEEDED';
export const CLOSE_FEATURE_FAILED = 'GET_FEATURE_FAILED';

export const UPDATE_MARK_VALUE_OF_TITLES_BY_FLASHCARD_ID =PREFIX + 'UPDATE_MARK_VALUE_OF_TITLES_BY_FLASHCARD_ID';
export const UPDATE_VIDEO_PROGRESS_VALUE_OF_TITLES_BY_FLASHCARD_ID =PREFIX + 'UPDATE_VIDEO_PROGRESS_VALUE_OF_TITLES_BY_FLASHCARD_ID';
// export const UPDATE_ANSWER_BY_FLASHCARD_ID_PREVIEW = 'UPDATE_ANSWER_BY_FLASHCARD_ID_PREVIEW';

export const GET_EXAM_HEADER = PREFIX + 'GET_EXAM_HEADER';
export const GET_EXAM_HEADER_SUCCEEDED = PREFIX + 'GET_EXAM_HEADER_SUCCEEDED';
export const GET_EXAM_HEADER_FAILED = PREFIX + 'GET_EXAM_HEADER_FAILED';
//SMPP-LAGGY
export const SET_EXAM_PREVIEW_LIST_BY_TOPIC_ID = PREFIX + 'SET_EXAM_PREVIEW_LIST_BY_TOPIC_ID';
export const SET_EXAM_PREVIEW_LIST_BY_TOPIC_ID_SUCCEEDED = 'SET_EXAM_PREVIEW_LIST_BY_TOPIC_ID_SUCCEEDED';
export const SET_EXAM_PREVIEW_LIST_BY_TOPIC_ID_FAILED = 'SET_EXAM_PREVIEW_LIST_BY_TOPIC_ID_FAILED';
export const UPDATE_EXAM_PREVIEW_LIST_BY_TOPIC_ID = PREFIX + 'UPDATE_EXAM_PREVIEW_LIST_BY_TOPIC_ID';

export const PROGRESS_POPUP_OVERLAY_NEXT = PREFIX + 'PROGRESS_POPUP_OVERLAY_NEXT';
export const SET_PROGRESS_POPUP_OVERLAY_NEXT_SUCCEEDED = PREFIX + 'SET_PROGRESS_POPUP_OVERLAY_NEXT_SUCCEEDED';
export const SET_PROGRESS_POPUP_OVERLAY_NEXT_FAILED = PREFIX + 'SET_PROGRESS_POPUP_OVERLAY_NEXT_FAILED';

export const PROGRESS_POPUP_OVERLAY_PREVIOUS = PREFIX + 'PROGRESS_POPUP_OVERLAY_PREVIOUS';
export const SET_PROGRESS_POPUP_OVERLAY_PREVIOUS_SUCCEEDED = PREFIX + 'SET_PROGRESS_POPUP_OVERLAY_PREVIOUS_SUCCEEDED';
export const SET_PROGRESS_POPUP_OVERLAY_PREVIOUS_FAILED = PREFIX + 'SET_PROGRESS_POPUP_OVERLAY_PREVIOUS_FAILED';
export const GET_ALL_TOPICS_ID = PREFIX +'GET_ALL_TOPICS_ID';
export const GET_NEXT_PREV_TOPICS_DATA = PREFIX +'GET_NEXT_PREV_TOPICS_DATA';

export const SET_EXAM_FILTER_DATA = PREFIX + 'SET_EXAM_FILTER_DATA';
export const SET_EXAM_FILTER_DATA_SUCCEEDED = 'SET_EXAM_FILTER_DATA_SUCCEEDED';
export const SET_EXAM_FILTER_DATA_FAILED = 'SET_EXAM_FILTER_DATA_FAILED';

export const SET_LAST_TEACHING_TAG = PREFIX + 'SET_LAST_TEACHING_TAG';

export const UPDATE_FLASHCARD_ANSWER = 'UPDATE_FLASHCARD_ANSWER';

export const GET_EXTERNAL_AVATAR = PREFIX + 'GET_EXTERNAL_AVATAR'
export const GET_EXTERNAL_AVATAR_SUCCEEDED = PREFIX + 'GET_EXTERNAL_AVATAR_SUCCEEDED'
export const GET_EXTERNAL_AVATAR_FAILED = PREFIX + 'GET_EXTERNAL_AVATAR_FAILED'

export const GET_INTERNAL_EXERCISES = PREFIX + 'GET_INTERNAL_EXERCISES'
export const GET_INTERNAL_EXERCISES_SUCCEEDED = PREFIX + 'GET_INTERNAL_EXERCISES_SUCCEEDED'
export const GET_INTERNAL_EXERCISES_FAILED = PREFIX + 'GET_INTERNAL_EXERCISES_FAILED'

export const GET_EXERCISE_DETAILS = PREFIX + 'GET_EXERCISE_DETAILS'
export const GET_EXERCISE_DETAILS_SUCCEEDED = PREFIX + 'GET_EXERCISE_DETAILS_SUCCEEDED'
export const GET_EXERCISE_DETAILS_FAILED = PREFIX + 'GET_EXERCISE_DETAILS_FAILED'
