
import React, { useMemo } from "react"
import classNames from 'classnames';

type AvatarProps = {
  size?: number,
  widthSize?: number,
  accountPortal?: boolean,
  alternate?: boolean,
  initialFlashcard?: boolean,
  url?: string,
  children?: any,
  className?: string
  
}

const Avatar: React.FC<AvatarProps> = ({ size = 0, widthSize, accountPortal, alternate, initialFlashcard, url , children, className}) => {

  const style = useMemo(() => {
    const createStyle = { width: size, height: size, background:url ? `url(${url})` :  alternate ? '#378EF8' : initialFlashcard ? '#378EF8' : '#EFF4F9', color: alternate ? '#EFF4F9' : initialFlashcard ? '#EFF4F9' : '#378EF8', fontSize: Math.floor(size * 0.5) }
    return createStyle
  }, [url, alternate, initialFlashcard])

  return <div style={style} className={classNames(`profile-avatar` , {"account-portal-avatar":accountPortal} , className)} >{children}</div>
}

export default Avatar