const PREFIX = 'DESK_';

export const GET_FOLDERS_FOR_FLASHCARD = PREFIX + 'GET_FOLDERS_FOR_CURRENT_FLASHCARD';
export const GET_FOLDERS_FOR_FLASHCARD_SUCCEEDED = PREFIX + 'GET_FOLDERS_FOR_CURRENT_FLASHCARD_SUCCEEDED';
export const GET_FOLDERS_FOR_FLASHCARD_FAILED = PREFIX + 'GET_FOLDERS_FOR_CURRENT_FLASHCARD_FAILED';

export const GET_BOOKMARKS_FOR_FLASHCARD = PREFIX + 'GET_BOOKMARKS_FOR_FLASHCARD';
export const GET_BOOKMARKS_FOR_FLASHCARD_SUCCEEDED = PREFIX + 'GET_BOOKMARKS_FOR_FLASHCARD_SUCCEEDED';
export const GET_BOOKMARKS_FOR_FLASHCARD_FAILED = PREFIX + 'GET_BOOKMARKS_FOR_FLASHCARD_FAILED';

export const UPDATE_LIST_OF_ITEMS_SELECTED = PREFIX + 'UPDATE_LIST_OF_ITEMS_SELECTED';
export const SELECT_ALL_FLASHCARDS_WITH_NOTES = PREFIX + 'SELECT_ALL_FLASHCARDS_WITH_NOTES';
export const SELECT_ALL_EXERCISES = PREFIX + 'SELECT_ALL_EXERCISES';
export const DESELECT_ALL_ITEMS = PREFIX + 'DESELECT_ALL_ITEMS';

export const GET_FLASHCARDS_PREVIEW_WITH_NOTES = PREFIX + 'GET_FLASHCARDS_PREVIEW_WITH_NOTES';
export const GET_FLASHCARDS_PREVIEW_WITH_NOTES_SUCCEEDED = PREFIX + 'GET_FLASHCARDS_PREVIEW_WITH_NOTES_SUCCEEDED';
export const GET_FLASHCARDS_PREVIEW_WITH_NOTES_FAILED = PREFIX + 'GET_FLASHCARDS_PREVIEW_WITH_NOTES_FAILED';

export const GET_NOTE = PREFIX + 'GET_NOTE';
export const GET_NOTE_SUCCEEDED = PREFIX + 'GET_NOTE_SUCCEEDED';
export const GET_NOTE_FAILED = PREFIX + 'GET_NOTE_FAILED';

export const UPDATE_NOTE = PREFIX + 'UPDATE_NOTE';

export const PUT_NOTE = PREFIX + 'PUT_NOTE';
export const PUT_NOTE_SUCCEEDED = PREFIX + 'PUT_NOTE_SUCCEEDED';
export const PUT_NOTE_FAILED = PREFIX + 'PUT_NOTE_FAILED';

export const ADD_NOTE = PREFIX + 'ADD_NOTE';
export const ADD_NOTE_SUCCEEDED = PREFIX + 'ADD_NOTE_SUCCEEDED';
export const ADD_NOTE_FAILED = PREFIX + 'ADD_NOTE_FAILED';

export const DELETE_NOTE = PREFIX + 'DELETE_NOTE';
export const DELETE_NOTE_SUCCEEDED = PREFIX + 'DELETE_NOTE_SUCCEEDED';
export const DELETE_NOTE_FAILED = PREFIX + 'DELETE_NOTE_FAILED';

export const MULTIPLE_DELETE_NOTE = PREFIX + 'MULTIPLE_DELETE_NOTE';
export const MULTIPLE_DELETE_NOTE_SUCCEEDED = PREFIX + 'MULTIPLE_DELETE_NOTE_SUCCEEDED';
export const MULTIPLE_DELETE_NOTE_FAILED = PREFIX + 'MULTIPLE_DELETE_NOTE_FAILED';

export const UPDATE_FOLDER = PREFIX + 'UPDATE_FOLDER_NAME';
export const UPDATE_FOLDER_SUCCEEDED = PREFIX + 'UPDATE_FOLDER_NAME_SUCCEEDED';
export const UPDATE_FOLDER_FAILED = PREFIX + 'UPDATE_FOLDER_NAME_FAILED';
export const UPDATE_FOLDER_STARTED = PREFIX + 'UPDATE_FOLDER_STARTED';

export const CREATE_FOLDER = PREFIX + 'CREATE_FOLDER';
export const CREATE_FOLDER_SUCCEEDED = PREFIX + 'CREATE_FOLDER_SUCCEEDED';
export const CREATE_FOLDER_FAILED = PREFIX + 'CREATE_FOLDER_FAILED';

export const TEXTAREA_NOTE_CHANGED = PREFIX + 'TEXTAREA_NOTE_CHANGED';

export const INITIALIZE_NOTE = PREFIX + 'INITIALIZE_NOTE';
export const INITIALIZE_NOTE_SUCCEEDED = PREFIX + 'INITIALIZE_NOTE_SUCCEEDED';

export const TOGGLE_DELETE_CONFIRMATION_MODE = PREFIX + 'TOGGLE_DELETE_CONFIRMATION_MODE';
export const TOGGLE_DELETE_NOTE = PREFIX + 'TOGGLE_DELETE_NOTE';
export const SELECT_NOTE = PREFIX + 'SELECT_NOTE';

export const TOGGLE_FOCUS_TEXTAREA = PREFIX + 'TOGGLE_FOCUS_TEXTAREA';

export const GET_FOLDERS = PREFIX + 'GET_FOLDERS';
export const RESET_FOLDERS = PREFIX + 'RESET_FOLDERS';
export const GET_FOLDERS_SUCCEEDED = PREFIX + 'GET_FOLDERS_SUCCEEDED';
export const GET_FOLDERS_FAILED = PREFIX + 'GET_FOLDERS_FAILED';

export const ADD_FLASHCARD_TO_FOLDER = PREFIX + 'ADD_FLASHCARD_TO_FOLDER';
export const REMOVE_FLASHCARD_FROM_FOLDER = PREFIX + 'REMOVE_FLASHCARD_FROM_FOLDER';

export const MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER = PREFIX + 'MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER';
export const MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER_SUCCEEDED = PREFIX + 'MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER_SUCCEEDED';
export const MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER_FAILED = PREFIX + 'MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER_FAILED';

export const GET_FLASHCARDS_IN_FOLDER = PREFIX + 'GET_FLASHCARDS_IN_FOLDER';
export const GET_FLASHCARDS_IN_FOLDER_SUCCEEDED = PREFIX + 'GET_FLASHCARDS_IN_FOLDER_SUCCEEDED';
export const GET_FLASHCARDS_IN_FOLDER_FAILED = PREFIX + 'GET_FLASHCARDS_IN_FOLDER_FAILED';
export const CLEAR_FLASHCARDS_IN_FOLDER = PREFIX + 'CLEAR_FLASHCARDS_IN_FOLDER';

export const GET_FOLDER = PREFIX + 'GET_FOLDER';
export const GET_FOLDER_SUCCEEDED = PREFIX + 'GET_FOLDER_SUCCEEDED';
export const GET_FOLDER_FAILED = PREFIX + 'GET_FOLDER_FAILED';
export const CLEAR_FOLDER = PREFIX + 'CLEAR_FOLDER';

export const SELECT_ALL_FOLDERS = PREFIX + 'SELECT_ALL_FOLDERS';
export const SELECT_ALL_FLASHCARD_PREVIEWS_FROM_FOLDER = PREFIX + 'SELECT_ALL_FLASHCARD_PREVIEWS_FROM_FOLDER';

export const DELETE_FOLDERS = PREFIX + 'DELETE_FOLDERS';
export const DELETE_FOLDERS_SUCCEEDED = PREFIX + 'DELETE_FOLDERS_SUCCEEDED';
export const DELETE_FOLDERS_FAILED = PREFIX + 'DELETE_FOLDERS_FAILED';

export const SET_SMART_FOLDER_TYPE = PREFIX + 'SET_SMART_FOLDER_TYPE';

// For folder reorganisation
export const UPDATE_FOLDERS_SEQUENCE = PREFIX + 'UPDATE_FOLDERS_SEQUENCE';
export const UPDATE_FOLDERS_SEQUENCE_SUCCEEDED = PREFIX + 'UPDATE_FOLDERS_SEQUENCE_SUCCEEDED';
export const UPDATE_FOLDERS_SEQUENCE_FAILED = PREFIX + 'UPDATE_FOLDERS_SEQUENCE_FAILED';

export const ADD_MARK_AS_READ = PREFIX + 'ADD_MARK_AS_READ';

export const ADD_FLASHCARD_TO_BOOKMARK = PREFIX + 'ADD_FLASHCARD_TO_BOOKMARK';
export const REMOVE_FLASHCARD_FROM_BOOKMARK = PREFIX + 'REMOVE_FLASHCARD_FROM_BOOKMARK';

export const UPDATE_FILTER_LIST_OF_ITEMS_SELECTED = PREFIX + 'UPDATE_FILTER_LIST_OF_ITEMS_SELECTED';
export const FOLDER_FILTER_LIST_SIZE = PREFIX + 'FOLDER_FILTER_LIST_SIZE';
export const UPDATE_IDS_OF_FILTER_ITEM = PREFIX + 'UPDATE_IDS_OF_FILTER_ITEM';
export const UPDATE_LIST_OF_FILTERED_TITLES = PREFIX + 'UPDATE_LIST_OF_FILTERED_TITLES';
export const UPDATE_ANSWER_BY_FLODER_ID = PREFIX + 'UPDATE_ANSWER_BY_FLODER_ID';
export const UPDATE_MARK_BY_FLODER_ID = PREFIX + 'UPDATE_MARK_BY_FLODER_ID';
export const UPDATE_WATCH_BY_FLODER_ID = PREFIX + 'UPDATE_WATCH_BY_FLODER_ID';
export const UPDATE_ANSWER_BY_FLASHCARD_ID = PREFIX + 'UPDATE_ANSWER_BY_FLASHCARD_ID';
export const UPDATE_EXAMS_PAPER_FILTER_ITEMS = PREFIX + 'UPDATE_EXAMS_PAPER_FILTER_ITEMS';
export const UPDATE_EXAMS_QUESTION_INDEX = PREFIX + 'UPDATE_EXAMS_QUESTION_INDEX';
export const PAPER_ALL_QUESTION_DATA = PREFIX + 'PAPER_ALL_QUESTION_DATA';
export const MULTIPLE_SOURCE_UPDATE = PREFIX + 'MULTIPLE_SOURCE_UPDATE';
export const EXAM_QUESTION_NUMBER = PREFIX + 'EXAM_QUESTION_NUMBER';
export const QUESTION_IDS_UPDATE_STATS = PREFIX + 'QUESTION_IDS_UPDATE_STATS';
export const UPDATE_EXAM_FILTER_DATA_FROM_TOPICS = PREFIX + 'UPDATE_EXAM_FILTER_DATA_FROM_TOPICS';
export const UPDATE_EXAM_QUESTION_BY_FILTER = PREFIX + 'UPDATE_EXAM_QUESTION_BY_FILTER';

export const SET_ASSESSMENT_ANIMATION = PREFIX + 'SET_ASSESSMENT_ANIMATION';
export const SET_FILTER_EXAM_STATUS_COUNT = PREFIX + 'SET_FILTER_EXAM_STATUS_COUNT';
export const SET_FILTER_TOPIC_EXAM_STATUS_COUNT = PREFIX + 'SET_FILTER_TOPIC_EXAM_STATUS_COUNT';
export const SET_ACTIVE_TEACHING_TAG_STATUS = PREFIX + 'SET_ACTIVE_TEACHING_TAG_STATUS';
export const SET_ACTIVE_MESSAGE_LOADER = PREFIX + 'SET_ACTIVE_MESSAGE_LOADER';
export const SET_EXAM_TOPIC_STATUS = PREFIX + 'SET_EXAM_TOPIC_STATUS';
export const SET_LAST_MEDIA_TYPE_NOTES = PREFIX + 'SET_LAST_MEDIA_TYPE_NOTES';