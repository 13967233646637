import React, { useEffect } from 'react';
import debounce from 'lodash.debounce';
import {getFlashcardContent} from 'actions/api/apiActions'
import VimeoPlayer from 'v2/components/video-carousel/VimeoPlayer/VimeoPlayer';
import {getFlashcardById, isNavigationSectionVisible, isMobile, isLandscape, isPortrait, getUserId, isSubjectsManagerOverlayVisible} from 'selectors'
import { useDispatch, useSelector } from 'react-redux';
import { getLastMediaTypeVisited } from 'helpers';
import './video.scss'

type VideoRendererProps = {   
    videoId: string | number; 
    isVisible: boolean;
}

const VideoRenderer: React.FC<VideoRendererProps> = ({videoId, isVisible}) => {
    const dispatch = useDispatch();
    const videoContent = useSelector((state) => getFlashcardById(state, videoId))
    const hasNavSectionVisible = useSelector(isNavigationSectionVisible)
    const hasMobile = useSelector(isMobile)
    const hasLandscape = useSelector(isLandscape)
    const hasPortrait = useSelector(isPortrait)
    const userId = useSelector(getUserId)
    const hasSubjectManagerVisible = useSelector(isSubjectsManagerOverlayVisible)
    const mediaType = getLastMediaTypeVisited(userId)

    useEffect(() => {
        if(!videoId) return;
        getVideoContent();
    },[])

    useEffect(() => {
        getVideoContent();
    }, [videoId]);

    const getVideoContent = debounce(() => {
        videoId && dispatch(getFlashcardContent(videoId, 'video'));
    }, 300);

    if(!isVisible) return null
    return <div className='video-wrappers'>
         <div className="video-contain">
            <VimeoPlayer 
              videoContent={videoContent} 
              mediaType= {mediaType} 
              hasNavSectionVisible={hasNavSectionVisible} 
              url={videoContent?.get('url')} 
              isMobile={hasMobile}  
              isLandscape={hasLandscape} 
              videoId={videoId} 
              isPortrait={hasPortrait} 
              hasSubjectManagerVisible={hasSubjectManagerVisible}
              id={videoContent?.get('flashcardId')} />
         </div>
      </div>
}

export default VideoRenderer;