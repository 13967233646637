import React, {memo} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {deleteConfirmationMode} from 'selectors/desk/deskSelector'
import {toggleDeskEditMode, toggleFlashcardNavigationVisibility} from 'actions/navigation/navigationActions'
import {toggleDeleteConfirmationMode} from 'actions/desk/deskActions'
import BackButton from 'v2/components/navigation-header/BackButton';

const CancelEditButton = () => {
    const dispatch = useDispatch();
    const isDeleteConfirmationMode = useSelector(deleteConfirmationMode)

    const onCancel = () => {
        if (isDeleteConfirmationMode) {
            dispatch(toggleDeleteConfirmationMode(false));
        } else {
            dispatch(toggleDeskEditMode(false));
            dispatch(toggleFlashcardNavigationVisibility(true));
        }
    }
    
    return  <BackButton onClick={onCancel} text="Cancel" hideArrow={true}/>
}

export default CancelEditButton;