import React, { useEffect, useRef, Fragment, RefObject } from "react";
import { setDocumentTitle } from "actions/navigation";
import { useDispatch, useSelector } from 'react-redux'
import { getProfile, getMBLastSyncRoutine } from 'actions/api'
import { changeMenuPathAction } from 'actions/menuActions';
import { getAccessToken } from 'helpers';
import moment from 'moment';
import { manageBacUrl, manageBacUrlChina, } from 'pages/login/LoginPage';
import { hasMBIdentitySelector, mbLastSyncResponse, userProfile, userDataSelector, isMBUserSelector } from 'selectors';
import swal from 'sweetalert';
import ProfilePageComponent from './ProfilePageComponent';
import ReactDOM from "react-dom";
import MbMergeNotification from 'v2/components/notifications/MbMergeNotification';

const ProfilePageContainer = () => {
  const disptach = useDispatch();
  const mbFormRef = useRef<HTMLFormElement | null>(null);
  const mbFormRefChina = useRef<HTMLFormElement | null>(null);
  const hasUserProfile = useSelector(userProfile);
  const userData = useSelector(userDataSelector)?.toJS();
  const isMBUser = useSelector(isMBUserSelector);
  const hasMBIdentity = useSelector(hasMBIdentitySelector);
  const hasMbLastSyncResponse = useSelector(mbLastSyncResponse)
  const lastSync = moment.unix(hasMbLastSyncResponse.getIn(['data', 'lastUpdated'])).fromNow()

  useEffect(() => {
    disptach(setDocumentTitle('Profile'));
    disptach(getProfile());
  }, [])
  const changeMenuPath = (val?: string) => {
    disptach(changeMenuPathAction(val))
  }
  const onMergeClick = (region: string) => {
    const swalContent = document.createElement('div');
    ReactDOM.render(<MbMergeNotification />, swalContent);
    // @ts-ignore
    swal({ content: swalContent, buttons: false }).then((value) => {
      if (!value || !value.shouldMerge) return;

      //todo add again when china is re-enabled
      if (region === 'china') {
        // mbFormRefChina.current?.submit();
        return;
      } else {
        // mbFormRef.current?.submit();
        return;
      }

    });

  }
  return <Fragment>
    <ProfilePageComponent changeMenuPath={changeMenuPath} userProfile={hasUserProfile}
      userData={userData} isMBUser={isMBUser} hasMBIdentity={hasMBIdentity}
      lastSync={lastSync} onMergeClick={onMergeClick} />

    <form ref={mbFormRef} method='POST' action={manageBacUrl + '&access_token=' + getAccessToken()} />
    <form ref={mbFormRefChina} method='POST' action={manageBacUrlChina + '&access_token=' + getAccessToken()} />
  </Fragment>
}

export default ProfilePageContainer;