import PropTypes from 'prop-types';
import React from 'react';
import Row from 'ui-components/helpers/Row';
import StatsBar from 'ui-components/StatsBar';

const StatsBars = ({studied, correct}) => (
  <Row justifyContent={'space-between'}>
    <StatsBar title={'Studied'} percentage={studied}/>
    <StatsBar title={'Correct'} percentage={correct}/>
  </Row>
);
StatsBars.propTypes = {
  studied: PropTypes.number,
  correct: PropTypes.number,
};
export default StatsBars;

export const RawStatsBars = ({studied, correct, total}) => {
  const studiedPercentage = studied * 100 / total;
  const correctPercentage = correct * 100 / total;

  return <StatsBars studied={studiedPercentage || 0} correct={correctPercentage || 0}/>;
};
