import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { subjectsTeachingLevel, activeSubjectId, getTopicsExamLoading, listOfFilterCheckedItems, examsofFilterItems, getPaperAllQuestionData, getActivePaperTab, getListOfFilterItemsIds, currentFlashcardId, getExamPreviewListByTopic, getTopicIdByTeachingLevel, getExamQuestionNumber, getActiveMediaTab, isMBFromSchool, isOSCUser } from "selectors";
import { getFormExams, examTeachingFilter, disableMcqExamSubject, returnExamsStatusFilter, setExamSubTopicId, getExamSubTopicId, returnExamAnswerFilter, sourceFilter, paperFilter, difficultyFilter, topicTeachingFilter, getExamTopicId, setExamTopicId, storeExamsParentId } from "helpers"
import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2';
import { ExamNextButton, ExamPrevButton } from "v2/components/Button/NavigationButton"
import { questionTypeFind } from 'v2/helpers'
import { getNextPrevTopicsData } from "actions/api"
import { setActiveMediaTab, progressBarFilter } from "actions/navigation"
import { selectExams, changeFlashcard } from "actions/studying"
import { updateExamsQuestionIndex, setExamQuestionNumber } from 'actions/desk'
import debounce from 'lodash.debounce';


type ExamMediaButtonControllerProps = {
  getExamsQuestionIndex: any
}

const ExamMediaButtonController: React.FC<ExamMediaButtonControllerProps> = ({ getExamsQuestionIndex }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [filterStatus, setFilterStatus] = useState<boolean | null>(null);
  const [prevQuestionIndex, setPrevQuestion] = useState([]);
  const [nextQuestionIndex, setNextQuestion] = useState([]);
  const dispatch = useDispatch()

  const hasSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
  const hasSubjectId = useSelector(activeSubjectId);
  const hasTopicsExamLoading = useSelector(getTopicsExamLoading).toJS();
  const hasListOfFilterCheckedItems = useSelector(listOfFilterCheckedItems);
  const hasExamsofFilterItems = useSelector(examsofFilterItems);
  const hasPaperAllQuestionData = useSelector(getPaperAllQuestionData);
  const hasActivePaperTab = useSelector(getActivePaperTab);
  const hasListOfFilterItemsIds = useSelector(getListOfFilterItemsIds);
  const hasCurrentFlashcardId = useSelector(currentFlashcardId);
  const hasExamPreviewListByTopic = useSelector(getExamPreviewListByTopic);
  const hasTopicIdByTeachingLevel = useSelector(getTopicIdByTeachingLevel);
  const hasExamQuestionNumber: number = useSelector(getExamQuestionNumber)
  const activeMediaTab = useSelector(getActiveMediaTab);
  const hasMbFromSchool = useSelector(isMBFromSchool);
  const hasOSCUser = useSelector(isOSCUser)

  const tlvl = hasSubjectsTeachingLevel[`${hasSubjectId}`];
  const activeTopiId = getExamTopicId();
  const hasPrevLoading = hasTopicsExamLoading.prev;
  const hasNextLoading = hasTopicsExamLoading.next;
  const examsofFilterItemsToJS = hasExamsofFilterItems.toJS()

  const renderPaperType = (paperType: any) => {
    if (disableMcqExamSubject(hasSubjectId) && paperType == 'Paper 1') {
      return 'Paper 2'
    } else {
      return paperType
    }
  }

  const renderFinalFilter = () => {
    const paperType = hasPaperAllQuestionData.toJS()[0].paperType
    const newListItem = examTeachingFilter(tlvl, hasPaperAllQuestionData.toJS(), examsofFilterItemsToJS.teachingLevel, 'exams');
    const filterType = renderPaperType(paperType) === 'Paper 1' ? examsofFilterItemsToJS.mcq : hasListOfFilterCheckedItems.toJS()['exams'];
    const userResponseFilterData = returnExamsStatusFilter(newListItem, filterType, renderPaperType(hasActivePaperTab));
    return userResponseFilterData
  }
  const renderTopicFinalFilter = () => {
    let filterData = hasListOfFilterItemsIds.toJS()["exams"];
    // const requestNewData = resultData 
    const teachingTag = hasSubjectsTeachingLevel[`${hasSubjectId}`];
    const data = filterData && sourceFilter(filterData, examsofFilterItemsToJS.source, "exams");
    const paperFilterData = filterData && paperFilter(data, examsofFilterItemsToJS.paper, "exams");
    const difficultyData = filterData && difficultyFilter(paperFilterData, examsofFilterItemsToJS.difficulty, "exams")
    const teachingTagData = filterData && topicTeachingFilter(teachingTag, difficultyData, examsofFilterItemsToJS.teachingLevel, "topics", "exams")
    const multipleChooseData = filterData &&  questionTypeFind(teachingTagData)?.mcq
    const freeResponseData = filterData &&  questionTypeFind(teachingTagData)?.group
    const examAnswerFilter = filterData && returnExamAnswerFilter(multipleChooseData, freeResponseData, examsofFilterItemsToJS.mcq, hasListOfFilterCheckedItems.toJS()["exams"], "exams", teachingTagData)
    const uiqueExamData = examAnswerFilter.sort((a: any, b: any) => {
      const indexA = a.index;
      const indexB = b.index;
      if (indexA < indexB) return -1;
      return 1;
    });
    return uiqueExamData;
  }

  function filterTitleData() {
    if (filterStatus) {
      let indexx = hasListOfFilterItemsIds.toJS()["exams"] && hasListOfFilterItemsIds.toJS()["exams"].length > 0 && hasListOfFilterItemsIds.toJS()["exams"].filter((val: any) => {
        return val.flashcarduuId == getExamSubTopicId() + '-' + hasCurrentFlashcardId
      })
      let filteredPrevIds = renderTopicFinalFilter() && renderTopicFinalFilter().length > 0 && renderTopicFinalFilter().filter((val: any) => {
        if (val.index < indexx[0]?.index) {
          return val
        }
      })
      setPrevQuestion(filteredPrevIds)
      let filteredNextIds = renderTopicFinalFilter() && renderTopicFinalFilter().length > 0 && renderTopicFinalFilter().filter((val: any) => {
        if (val.index > indexx[0]?.index) {
          return val
        }
      })
      setNextQuestion(filteredNextIds)
    }
  }

  useEffect(() => {
    filterTitleData()
  }, [hasListOfFilterCheckedItems, hasExamsofFilterItems, hasListOfFilterItemsIds, hasCurrentFlashcardId, currentIndex])

  useEffect(() => {
    if (getFormExams() !== "exams") {
      nextPrevTopicApiCall()
    }
  }, [activeTopiId, hasTopicIdByTeachingLevel])
  const nextPrevTopicApiCall = () => {
    const currentIndex = hasExamPreviewListByTopic?.findIndex((item: any) => item.flashcarduuId === getExamSubTopicId() + '-' + hasCurrentFlashcardId);
    const flashcardTopicId = hasExamPreviewListByTopic?.[currentIndex] && hasExamPreviewListByTopic[currentIndex].topicId;

    const flashcardCount = hasExamPreviewListByTopic && hasExamPreviewListByTopic.filter((item: any) => item.topicId === flashcardTopicId);
    const flashcardIndexOfTopic = flashcardCount && flashcardCount.findIndex((item: any) => item.flashcarduuId === getExamSubTopicId() + '-' + hasCurrentFlashcardId);
    let nextTopicIndex;
    if (flashcardCount?.length < flashcardIndexOfTopic + 5) {
      nextTopicIndex = hasTopicIdByTeachingLevel && hasTopicIdByTeachingLevel.findIndex((item: any) => item.topicId === flashcardTopicId);
      let nextTopicIds = [];
      if (nextTopicIndex === 0) {
        hasTopicIdByTeachingLevel?.[nextTopicIndex + 1] && nextTopicIds.push(hasTopicIdByTeachingLevel[nextTopicIndex + 1].topicId);
      } else {
        hasTopicIdByTeachingLevel?.[nextTopicIndex - 1] && nextTopicIds.push(hasTopicIdByTeachingLevel[nextTopicIndex - 1].topicId);
        hasTopicIdByTeachingLevel?.[nextTopicIndex + 1] && nextTopicIds.push(hasTopicIdByTeachingLevel[nextTopicIndex + 1].topicId);
      }
      nextTopicIds.length > 0 && dispatch(getNextPrevTopicsData(nextTopicIds, 'next'));

    }

    if (flashcardIndexOfTopic - 5 < 0) {
      let prevTopicIndex = hasTopicIdByTeachingLevel && hasTopicIdByTeachingLevel.findIndex((item: any) => item.topicId === flashcardTopicId);
      let prevTopicIds = [];
      if (prevTopicIndex === 0) {
        hasTopicIdByTeachingLevel?.[prevTopicIndex + 1] && prevTopicIds.push(hasTopicIdByTeachingLevel?.[prevTopicIndex + 1].topicId);

      } else {
        hasTopicIdByTeachingLevel?.[prevTopicIndex - 1] && prevTopicIds.push(hasTopicIdByTeachingLevel?.[prevTopicIndex - 1].topicId);
        hasTopicIdByTeachingLevel?.[prevTopicIndex + 1] && prevTopicIds.push(hasTopicIdByTeachingLevel?.[prevTopicIndex + 1].topicId);
      }
      prevTopicIds.length > 0 && dispatch(getNextPrevTopicsData(prevTopicIds, 'prev'));
    }
  }

  function checkExamsFilter() {
    let data = hasExamsofFilterItems.toJS()
    if (data['source'].length > 0 || data['paper'].length > 0 || data['teachingLevel'].length > 0 || data['difficulty'].length > 0 || data['mcq'].length > 0) {
      return true
    } else {
      return false
    }
  }

  function checkFilter() {
    let data = hasListOfFilterCheckedItems.toJS()['exams'];
    if (data && data.length && data.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const hasPrevLoading = hasTopicsExamLoading.prev;
    const hasNextLoading = hasTopicsExamLoading.next;

    document.body.onkeyup = function (e) {
      if (hasPrevLoading || hasNextLoading) return;
      if (e.key == 'ArrowRight') {
        //@ts-ignore
        if (['INPUT', 'TEXTAREA'].indexOf(e.target?.nodeName) !== -1) return;
        if (getFormExams() === 'exams') {
          !nextFlashcardIds() ? endProgressOpen() : nextQuestion()
        } else {
          nextQuestion()
        }
      }
      if (e.key == 'ArrowLeft') {
        //@ts-ignore
        if (['INPUT', 'TEXTAREA'].indexOf(e.target?.nodeName) !== -1) return;
        prevQuestion()
      }
    }
    //@ts-ignore
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    //@ts-ignore
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
    //@ts-ignore
    eventer(messageEvent, keyBoardEvent);
    return () => {
      //@ts-ignore
      const eventMethod = window.addEventListener
        ? "removeEventListener"
        : "detachEvent";
      //@ts-ignore
      const messageEvent = eventMethod === "attachEvent"
        ? "onmessage"
        : "message";
      //@ts-ignore
      window[eventMethod](messageEvent, keyBoardEvent);
    }
  });
  function keyBoardEvent(event: any) {
    if (event.data.keyUpEvent && event.data.keyUpEvent.key == 'ArrowRight') {
      debounceLoadNext()
    }
    if (event.data.keyUpEvent && event.data.keyUpEvent.key == 'ArrowLeft') {
      debounceLoadPrev()
    }
    event.stopPropagation();
  }

  useEffect(() => {
    if (checkExamsFilter() || checkFilter()) {
      setFilterStatus(true)
    } else {
      setFilterStatus(false)
    }
  }, [hasExamsofFilterItems, hasListOfFilterCheckedItems]);

  useEffect(() => {
    setCurrentIndex(getExamsQuestionIndex.currentIndex)
  }, [getExamsQuestionIndex])

  const debounceNext = () => {
    if (getFormExams() === 'exams') {
      !nextFlashcardIds() ? endProgressOpen() : nextQuestion();
      dispatch(setActiveMediaTab('exams'))
    } else {
      nextQuestion()
    }
  }
  const debounceLoadNext = useCallback(debounce(debounceNext, 100), [hasListOfFilterCheckedItems, hasExamsofFilterItems, hasListOfFilterItemsIds, hasCurrentFlashcardId, currentIndex,]);
  const debounceLoadPrev = useCallback(debounce(prevQuestion, 100), [hasListOfFilterCheckedItems, hasExamsofFilterItems, hasListOfFilterItemsIds, hasCurrentFlashcardId, currentIndex]);

  function prevQuestion() {

    if (getFormExams() !== "exams") {
      let subTopicId, topicId;
      if (filterStatus) {
        //@ts-ignore
        let prevQuestionId = prevFlashcardIds().prevQuestionuuId;
        const currentIndex = renderTopicFinalFilter().findIndex((item: any) => item.flashcarduuId == prevQuestionId);
        subTopicId = renderTopicFinalFilter() && renderTopicFinalFilter()[currentIndex].subTopicId;
        topicId = renderTopicFinalFilter()?.[currentIndex]?.topicId
        setExamSubTopicId(subTopicId);
        setExamTopicId(topicId)
        storeExamsParentId(topicId)
        changeQuestion(currentIndex, false, subTopicId)

      } else {
        const currentIndex = hasExamPreviewListByTopic.findIndex((item: any) => item.flashcarduuId === getExamSubTopicId() + '-' + hasCurrentFlashcardId);
        // get Next and Previous Topics data
        const flashcardTopicId = hasExamPreviewListByTopic[currentIndex] && hasExamPreviewListByTopic[currentIndex].topicId;
        subTopicId = hasExamPreviewListByTopic[currentIndex - 1] && hasExamPreviewListByTopic[currentIndex - 1].subTopicId;
        topicId = hasExamPreviewListByTopic[currentIndex - 1] && hasExamPreviewListByTopic[currentIndex - 1].topicId;
        setExamTopicId(topicId)
        storeExamsParentId(topicId)
        setExamSubTopicId(subTopicId);
        changeQuestion(parseInt(currentIndex) - 1, true, subTopicId);
      }

      //end 

    } else {
      const currentFlashcardIndex = hasPaperAllQuestionData.toJS().findIndex((item: any) => item.uuid === hasCurrentFlashcardId) + 1;
      const nextFilterIndex = renderFinalFilter().findIndex((item: any) => item.index == currentFlashcardIndex);
      let nextIndex;
      if (nextFilterIndex > 0) {
        nextIndex = renderFinalFilter().filter((e: any, index: any) => {
          return index < nextFilterIndex;
        });
      } else {
        nextIndex = renderFinalFilter().filter((e: any, index: any) => {
          return e.index < currentFlashcardIndex;
        });
      }
      const arrayFirstElement = nextIndex[nextIndex.length - 1]?.index;
      const currentIndex = renderFinalFilter().findIndex((item: any) => item.index == arrayFirstElement);
      changeQuestion(arrayFirstElement, currentIndex + 1)
    }
  }

  const getExamsUIds = (data: any) => {
    const examData = data.map((val: any, index: any) => {
      let numbering = index + 1
      return { "index": numbering, "uuid": val.flashcardId, "subTopicId": val.subTopicId }
    })
    return examData
  }


  function changeQuestion(currentIndex: any, activeIndex: any, subTopicId?: any) {
    let nextIndex;
    let flashcardId: any;
    if (getFormExams() !== "exams") {
      let flashcardIndexOfTopic, getExamsQuestionData, flashcardCount;
      if (filterStatus) {
        let examFilterDataBySubject = renderTopicFinalFilter();
        nextIndex = renderTopicFinalFilter().filter((e: any, index: any) => {
          return index == currentIndex;
        });
        flashcardId = nextIndex[0] && nextIndex[0].flashcardId
        const flashcardTopicId = renderTopicFinalFilter()[currentIndex] && renderTopicFinalFilter()[currentIndex].topicId;
        flashcardCount = renderTopicFinalFilter().filter((item: any) => item.topicId === flashcardTopicId);

        getExamsQuestionData = flashcardCount && getExamsUIds(flashcardCount)
        flashcardIndexOfTopic = getExamsQuestionData && getExamsQuestionData.findIndex((item: any) => `${item.subTopicId}-${item.uuid}` == `${subTopicId}-${flashcardId}`);
        flashcardId && dispatch(selectExams(flashcardId, "topics", flashcardCount.length, flashcardIndexOfTopic + 1));
        flashcardId && dispatch(updateExamsQuestionIndex(getExamsQuestionData, flashcardIndexOfTopic + 1))
      } else {
        nextIndex = hasExamPreviewListByTopic.filter((e: any, index: any) => {
          return index == currentIndex;
        });
        flashcardId = nextIndex[0] && nextIndex[0].flashcardId
        const flashcardTopicId = hasExamPreviewListByTopic[currentIndex] && hasExamPreviewListByTopic[currentIndex].topicId;
        flashcardCount = flashcardTopicId && hasExamPreviewListByTopic.filter((item: any) => item.topicId === flashcardTopicId);
        getExamsQuestionData = flashcardCount && getExamsUIds(flashcardCount)
        flashcardIndexOfTopic = getExamsQuestionData && getExamsQuestionData.findIndex((item: any) => `${item.subTopicId}-${item.uuid}` === `${subTopicId}-${flashcardId}`);
        flashcardId && dispatch(selectExams(flashcardId, "topics", flashcardCount.length, flashcardIndexOfTopic + 1));
        flashcardId && dispatch(updateExamsQuestionIndex(getExamsQuestionData, flashcardIndexOfTopic + 1))
      }

    } else {
      nextIndex = renderFinalFilter().filter((e: any) => {
        return e.index == currentIndex;
      });
      flashcardId = nextIndex[0]?.uuid
      flashcardId && dispatch(selectExams(flashcardId, 'exams', renderFinalFilter().length, activeIndex));
      flashcardId && dispatch(setExamQuestionNumber(currentIndex))
      flashcardId && dispatch(updateExamsQuestionIndex(renderFinalFilter(), activeIndex))
    }
    if(activeMediaTab !== 'exams' && (hasOSCUser || hasMbFromSchool)) {
      dispatch(setActiveMediaTab('exams'));
    }
    dispatch(changeFlashcard(flashcardId, "default"));

  }

  function prevFlashcardIds() {
    let dataLenght;
    if (getFormExams() !== "exams") {
      if (filterStatus) {
        //@ts-ignore
        let prevNumber = prevQuestionIndex && prevQuestionIndex.length > 0 && prevQuestionIndex[prevQuestionIndex.length - 1].index;
        //@ts-ignore
        let prevQuestionuuId = prevQuestionIndex && prevQuestionIndex.length > 0 && prevQuestionIndex[prevQuestionIndex.length - 1].flashcarduuId;
        return { "prevNumber": prevNumber, "prevQuestionuuId": prevQuestionuuId };

      } else {
        const indexOfFlashcard = hasExamPreviewListByTopic && hasExamPreviewListByTopic.findIndex((item: any) => item.flashcarduuId === getExamSubTopicId() + '-' + hasCurrentFlashcardId);
        dataLenght = indexOfFlashcard > 0;
        return dataLenght
      }

    } else {
      if (getExamsQuestionIndex.data) {
        const test = renderFinalFilter().filter((e: any) => {
          return e.index < hasExamQuestionNumber
        })
        return test.length > 0
      }
    }
  }
  function nextFlashcardIds() {
    let dataLenght = [];

    if (getFormExams() !== "exams") {
      if (filterStatus) {
        //@ts-ignore
        let nextNumber = nextQuestionIndex && nextQuestionIndex.length > 0 && nextQuestionIndex[0].index;
        //@ts-ignore
        let nextQuestionuuId = nextQuestionIndex && nextQuestionIndex.length > 0 && nextQuestionIndex[0].flashcarduuId;
        return { "nextNumber": nextNumber, "nextQuestionuuId": nextQuestionuuId };
      } else {
        const indexOfFlashcard = hasExamPreviewListByTopic && hasExamPreviewListByTopic.findIndex((item: any) => item.flashcarduuId === getExamSubTopicId() + '-' + hasCurrentFlashcardId);
        dataLenght = hasExamPreviewListByTopic && indexOfFlashcard + 1 < hasExamPreviewListByTopic.length;
        return dataLenght;
      }

    } else {
      const test = renderFinalFilter().filter((e: any) => {
        return e.index > hasExamQuestionNumber
      })
      return test.length > 0
    }
  }
  function endProgressOpen() {
    dispatch(progressBarFilter(true, true))
  }

  function nextQuestion() {
    if (getFormExams() !== "exams") {
      let subTopicId, topicId;
      if (filterStatus) {
        let nextQuestionId = nextFlashcardIds().nextQuestionuuId;
        const currentIndex = renderTopicFinalFilter().findIndex((item: any) => item.flashcarduuId == nextQuestionId);

        subTopicId = renderTopicFinalFilter() && renderTopicFinalFilter()[currentIndex].subTopicId;
        setExamSubTopicId(subTopicId);
        changeQuestion(currentIndex, false, subTopicId);
        topicId = renderTopicFinalFilter()[currentIndex].topicId;
        setExamTopicId(topicId)
        storeExamsParentId(topicId)
      } else {
        const currentIndex = hasExamPreviewListByTopic.findIndex((item: any) => item.flashcarduuId === getExamSubTopicId() + '-' + hasCurrentFlashcardId);
        subTopicId = hasExamPreviewListByTopic[currentIndex + 1] && hasExamPreviewListByTopic[currentIndex + 1].subTopicId;
        topicId = hasExamPreviewListByTopic[currentIndex + 1] && hasExamPreviewListByTopic[currentIndex + 1].topicId;

        setExamSubTopicId(subTopicId);
        setExamTopicId(topicId)
        storeExamsParentId(topicId)
        changeQuestion(currentIndex + 1, false, subTopicId);
      }
    } else {
      const currentFlashcardIndex = hasPaperAllQuestionData.toJS().findIndex((item: any) => item.uuid == hasCurrentFlashcardId) + 1;
      const nextFilterIndex = renderFinalFilter().findIndex((item: any) => item.index == currentFlashcardIndex);
      let nextIndex;
      if (nextFilterIndex > 0) {
        nextIndex = renderFinalFilter().filter((e: any, index: any) => {
          return index > nextFilterIndex;
        });
      } else {
        nextIndex = renderFinalFilter().filter((e: any) => {
          return e.index > currentFlashcardIndex;
        });
      }
      const arrayFirstElement = nextIndex[0]?.index;
      const currentIndex = renderFinalFilter().findIndex((item: any) => item.index == arrayFirstElement);
      changeQuestion(arrayFirstElement, currentIndex + 1)
    }
  }

  return (
    <>
      {hasNextLoading && hasPrevLoading ? <Loader background={BackgroundEnum.Blue} size={SizeEnum.Small} /> :
        <>
          <div className='pr-2'>
            {getFormExams() !== 'exams' && hasPrevLoading ?
              <div className='w-11 min-w-44px h-11 flex justify-center items-center'>
                <Loader background={BackgroundEnum.Blue} size={SizeEnum.Small} />
              </div> :
              //@ts-ignore
              <ExamPrevButton onClick={prevQuestion} visibility={!((filterStatus && getFormExams() !== 'exams') ? prevFlashcardIds().prevNumber !== false : prevFlashcardIds())} exam={true} />
            }
          </div>
          <div className='pl-2'>
            {getFormExams() !== 'exams' && hasNextLoading ?
              <div className='w-11 min-w-44px h-11 flex justify-center items-center'>
                <Loader background={BackgroundEnum.Blue} size={SizeEnum.Small} />
              </div> :
              <>
                {getFormExams() === 'exams' ?
                  <ExamNextButton onClick={!nextFlashcardIds() ? endProgressOpen : nextQuestion} exam={true} endPaper={!nextFlashcardIds()} /> :
                  <>
                    {((filterStatus && getFormExams() !== 'exams') ? nextFlashcardIds() : nextFlashcardIds()) ?
                      <ExamNextButton onClick={nextQuestion} exam={true} endPaper={false} /> :
                      <div />}
                  </>
                }
              </>
            }

          </div>
        </>
      }
    </>
  )
}
export default ExamMediaButtonController;