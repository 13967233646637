import * as Types from './types';
import makeAction from 'actions/makeAction';

export const updateListOfItemsSelected = makeAction(Types.UPDATE_LIST_OF_ITEMS_SELECTED, 'item');
export const selectAllFlashcardsWithNotes = makeAction(Types.SELECT_ALL_FLASHCARDS_WITH_NOTES);
export const selectAllExercisesAction = makeAction(Types.SELECT_ALL_EXERCISES, 'items');
export const deselectAllItems = makeAction(Types.DESELECT_ALL_ITEMS);

export const getFlashcardsPreviewWithNotes = makeAction(Types.GET_FLASHCARDS_PREVIEW_WITH_NOTES, 'hasDelay');
export const getFlashcardsPreviewWithNotesSucceeded = makeAction(Types.GET_FLASHCARDS_PREVIEW_WITH_NOTES_SUCCEEDED, 'data');
export const getFlashcardsPreviewWithNotesFailed = makeAction(Types.GET_FLASHCARDS_PREVIEW_WITH_NOTES_FAILED, 'error');

export const getNote = makeAction(Types.GET_NOTE, 'flashcardId','mediaType');
export const getNoteSucceeded = makeAction(Types.GET_NOTE_SUCCEEDED, 'data');
export const getNoteFailed = makeAction(Types.GET_NOTE_FAILED, 'error');

export const updateNote = makeAction(Types.UPDATE_NOTE, 'value', 'flashcardId','mediaType');

export const putNote = makeAction(Types.PUT_NOTE, 'value', 'flashcardId');
export const putNoteSucceeded = makeAction(Types.PUT_NOTE_SUCCEEDED, 'data');
export const putNoteFailed = makeAction(Types.PUT_NOTE_FAILED, 'error');

export const addNote = makeAction(Types.ADD_NOTE, 'value', 'flashcardId');
export const addNoteSucceeded = makeAction(Types.ADD_NOTE_SUCCEEDED, 'data');
export const addNoteFailed = makeAction(Types.ADD_NOTE_FAILED, 'error');

export const deleteNote = makeAction(Types.DELETE_NOTE, 'flashcardId', 'redirected');
export const deleteNoteSucceeded = makeAction(Types.DELETE_NOTE_SUCCEEDED);
export const deleteNoteFailed = makeAction(Types.DELETE_NOTE_FAILED, 'error');

export const multipleDeleteNote = makeAction(Types.MULTIPLE_DELETE_NOTE);
export const multipleDeleteNoteSucceeded = makeAction(Types.MULTIPLE_DELETE_NOTE_SUCCEEDED);
export const multipleDeleteNoteFailed = makeAction(Types.MULTIPLE_DELETE_NOTE_FAILED, 'error');

export const updateFolder = makeAction(Types.UPDATE_FOLDER, 'folderId', 'data');
export const updateFolderStarted = makeAction(Types.UPDATE_FOLDER_STARTED);
export const updateFolderSucceeded = makeAction(Types.UPDATE_FOLDER_SUCCEEDED, 'data');
export const updateFolderFailed = makeAction(Types.UPDATE_FOLDER_FAILED, 'error');

export const createFolder = makeAction(Types.CREATE_FOLDER, 'data');
export const createFolderSucceeded = makeAction(Types.CREATE_FOLDER_SUCCEEDED, 'data');
export const createFolderFailed = makeAction(Types.CREATE_FOLDER_FAILED, 'error');

export const textareaNoteChange = makeAction(Types.TEXTAREA_NOTE_CHANGED, 'value');

export const initializeNote = makeAction(Types.INITIALIZE_NOTE, 'flashcardId','mediaType');
export const initializeNoteSucceeded = makeAction(Types.INITIALIZE_NOTE_SUCCEEDED);

export const toggleDeleteConfirmationMode = makeAction(Types.TOGGLE_DELETE_CONFIRMATION_MODE, 'value');
export const toggleDeleteNote = makeAction(Types.TOGGLE_DELETE_NOTE, 'value');
export const selectNote = makeAction(Types.SELECT_NOTE, 'flashcardId', "navigationSearchType",'mediaType');

export const toggleFocusTextarea = makeAction(Types.TOGGLE_FOCUS_TEXTAREA, 'value');

export const getFolders = makeAction(Types.GET_FOLDERS);
export const resetFolders = makeAction(Types.RESET_FOLDERS);

export const getFoldersSucceeded = makeAction(Types.GET_FOLDERS_SUCCEEDED, 'data');
export const getFoldersFailed = makeAction(Types.GET_FOLDERS_FAILED, 'error');

export const selectAllFolders = makeAction(Types.SELECT_ALL_FOLDERS);

export const multipleDeleteFlashcardsFromFolder = makeAction(Types.MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER, 'folderId','mediaType');
export const multipleDeleteFlashcardsFromFolderSucceeded = makeAction(Types.MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER_SUCCEEDED);
export const multipleDeleteFlashcardsFromFolderFailed = makeAction(Types.MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER_FAILED, 'error');

export const getFlashcardsInFolder = makeAction(Types.GET_FLASHCARDS_IN_FOLDER, 'folderId', 'isSmart', 'page', 'paginated');
export const getFlashcardsInFolderSucceeded = makeAction(Types.GET_FLASHCARDS_IN_FOLDER_SUCCEEDED, 'data');
export const getFlashcardsInFolderFailed = makeAction(Types.GET_FLASHCARDS_IN_FOLDER_FAILED, 'error');
export const clearFlashcardsInFolder = makeAction(Types.CLEAR_FLASHCARDS_IN_FOLDER);

export const selectAllFlashcardPreviewsFromFolder = makeAction(Types.SELECT_ALL_FLASHCARD_PREVIEWS_FROM_FOLDER);

export const getFolder = makeAction(Types.GET_FOLDER, 'folderId');
export const getFolderSucceeded = makeAction(Types.GET_FOLDER_SUCCEEDED, 'data');
export const getFolderFailed = makeAction(Types.GET_FOLDER_FAILED, 'error');
export const clearFolder = makeAction(Types.CLEAR_FOLDER);

export const deleteFolders = makeAction(Types.DELETE_FOLDERS);
export const deleteFoldersSucceeded = makeAction(Types.DELETE_FOLDERS_SUCCEEDED);
export const deleteFoldersFailed = makeAction(Types.DELETE_FOLDERS_FAILED);

export const getFoldersForFlashcard = makeAction(Types.GET_FOLDERS_FOR_FLASHCARD, 'flashcardId','mediaType');
export const getFoldersForFlashcardSucceeded = makeAction(Types.GET_FOLDERS_FOR_FLASHCARD_SUCCEEDED, 'data');
export const getFoldersForFlashcardFailed = makeAction(Types.GET_FOLDERS_FOR_FLASHCARD_FAILED, 'error');

export const setSmartFolderType = makeAction(Types.SET_SMART_FOLDER_TYPE, 'folderType');

// For folder reorganisation
export const updateFolderSequence = makeAction(Types.UPDATE_FOLDERS_SEQUENCE, 'data');
export const updateFolderSequenceSucceeded = makeAction(Types.UPDATE_FOLDERS_SEQUENCE_SUCCEEDED, 'data');
export const updateFolderSequenceFailed = makeAction(Types.UPDATE_FOLDERS_SEQUENCE_FAILED, 'error');

export const getBookmarksForFlashcard = makeAction(Types.GET_BOOKMARKS_FOR_FLASHCARD, 'flashcardId','mediaType');
export const getBookmarksForFlashcardSucceeded = makeAction(Types.GET_BOOKMARKS_FOR_FLASHCARD_SUCCEEDED, 'data');
export const getBookmarksForFlashcardFailed = makeAction(Types.GET_BOOKMARKS_FOR_FLASHCARD_FAILED, 'error');

export const updateFilterListOfItemsSelected = makeAction(Types.UPDATE_FILTER_LIST_OF_ITEMS_SELECTED, 'item', 'mediaType');
export const updateExamsPaperFilterItems = makeAction(Types.UPDATE_EXAMS_PAPER_FILTER_ITEMS, 'item', 'filterType');
export const folderFilterListSize = makeAction(Types.FOLDER_FILTER_LIST_SIZE, 'item', 'mediaType');
export const updateIdsOfFilterItems = makeAction(Types.UPDATE_IDS_OF_FILTER_ITEM, 'item', 'mediaType');
export const updateListOfFilteredTitles = makeAction(Types.UPDATE_LIST_OF_FILTERED_TITLES, 'item', 'mediaType');
export const updateAnswerByFolderId = makeAction(Types.UPDATE_ANSWER_BY_FLODER_ID, 'data');
export const updateMarkByFolderId = makeAction(Types.UPDATE_MARK_BY_FLODER_ID, 'data');
export const updateWatchByFolderId = makeAction(Types.UPDATE_WATCH_BY_FLODER_ID, 'data');
export const updateAnswerByFlashcardId = makeAction(Types.UPDATE_ANSWER_BY_FLASHCARD_ID, 'data');
export const updateExamsQuestionIndex = makeAction(Types.UPDATE_EXAMS_QUESTION_INDEX,'data','index')
export const paperAllQuestionData = makeAction(Types.PAPER_ALL_QUESTION_DATA,'data')
export const questionIdUpdateStats = makeAction(Types.QUESTION_IDS_UPDATE_STATS,'id','answer')
export const setExamQuestionNumber = makeAction(Types.EXAM_QUESTION_NUMBER,'data')
export const updateExamFilterDataFromTopics = makeAction(Types.UPDATE_EXAM_FILTER_DATA_FROM_TOPICS,'id','answer');
export const updateExamQuestionByFilter = makeAction(Types.UPDATE_EXAM_QUESTION_BY_FILTER, 'data');

export const multipleSourceUpdate = makeAction(Types.MULTIPLE_SOURCE_UPDATE,'data')


export const setAssessmentAnimation = makeAction(Types.SET_ASSESSMENT_ANIMATION, 'value');

export const setFilterExamStatusCount = makeAction(Types.SET_FILTER_EXAM_STATUS_COUNT, 'id', 'tlvl');
export const setFilterTopicExamStatusCount = makeAction(Types.SET_FILTER_TOPIC_EXAM_STATUS_COUNT, 'id', 'tlvl', 'difficulty', 'source', 'paper');

export const setActiveTeachingTagStatus = makeAction(Types.SET_ACTIVE_TEACHING_TAG_STATUS,'data')
export const setActiveMessageLoader = makeAction(Types.SET_ACTIVE_MESSAGE_LOADER,'data')
export const setExamTopicStatus = makeAction(Types.SET_EXAM_TOPIC_STATUS,'data')
export const setLastMediaTypeNotes = makeAction(Types.SET_LAST_MEDIA_TYPE_NOTES, 'data')