import { useEffect, useRef } from 'react';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import FreeTrialNotification from 'v2/components/free-trial-notification/FreeTrialNotification';
import './exams.scss';
import ExamsHeader from './ExamsHeader';
import ExamsListContainer from './ExamsListContainer';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import { InfoIcon } from 'icons/CardIcons';
import { getActiveSubject, subjectsTeachingLevel } from 'selectors/subject/subjectSelector'
import { getActiveExamModule } from 'selectors/navigation/navigationSelector'
import { examListResponse } from 'selectors/api/apiSelector'
import { isMobile, isLandscape } from 'selectors/browser/browserSelector'
import { useDispatch, useSelector } from 'react-redux';
import { setActiveFlashcardTab, setDocumentTitle } from 'actions/navigation';
import { getExams } from 'actions/api';
import usePrevious from 'hooks/usePrevious';
import { useHistory } from "react-router-dom";
import useScrollDetection from 'hooks/useScrollDetection';
import useExamModuleStatus from 'hooks/useExamModuleStatus';

const ExamsPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const hasMobile = useSelector(isMobile);
    const hasLandscape = useSelector(isLandscape);
    const hasExamListResponse = useSelector(examListResponse);
    const hasActiveExamModule = useSelector(getActiveExamModule);
    const activeSubject = useSelector(getActiveSubject);
    const hasSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
    const prevActiveSubject = usePrevious(activeSubject);
    const prevSubjectsTeachingLevel = usePrevious(hasSubjectsTeachingLevel);
    let wrapperRef = useRef<HTMLDivElement>(null);
    const { isScrolled, bindScrollRef } = useScrollDetection({ WrappedRef: wrapperRef, threshold1: 20 });
    const { loading, status } = useExamModuleStatus();

    useEffect(() => {
        !loading && !status && history.push('/flashcard/topics')
    },[history, loading, status])

    useEffect(() => {
        dispatch(setDocumentTitle('Exams'));
        dispatch(getExams());
        dispatch(setActiveFlashcardTab(1));
    }, [])

    useEffect(() => {
        if (prevActiveSubject !== activeSubject || hasSubjectsTeachingLevel !== prevSubjectsTeachingLevel) {
            dispatch(getExams());
        }
    }, [activeSubject, hasSubjectsTeachingLevel])

    return <>
        <NavigationHeader
            isCollapsed={isScrolled}
            text={'Exams'}
            rightSection={<a href="https://oxfordstudycourses.com/publication-data" target="_blank" rel="noopener noreferrer" className="info-icon right-section-info-icons"> <InfoIcon /> </a>}
            extendedHeight
            shouldExtendHeight={false}
            leftSection={(hasMobile && hasLandscape) ? <div /> : null}
            bottomSection={null}
        />
        <div className='pt-14 h-full fixedpagespacing' ref={bindScrollRef}>
            <FreeTrialNotification />
            <div className='pt-1 px-5'>
                <div className='font-bold text-#2D3838 page-title'>Exams</div>
            </div>
            <ExamsHeader hasExamListResponse={hasExamListResponse} getActiveExamModule={hasActiveExamModule} />
            {!hasExamListResponse ? <LeftMenuLoader /> : <ExamsListContainer />}
        </div>
    </>
}

export default ExamsPage