import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import {
  previewsListbyType,
  apiWaitingResponse,
  getActiveSubject,
} from "selectors";
import { statusById } from "selectors/statusSelector";
import TeachingLevelSeperator from "v2/components/teaching-level-selector/TeachingLevelSeperator";
import Text from "v2/components/common/Text";
import { ArrowTopicIcon } from "icons/HeaderIcons";
import MediaTypeTab from "v2/components/media-tab/MediaTypeTab";
import useNewSubject from "hooks/useNewSubject";
import { useSubtopicNumber, useTopicNumber } from "hooks/useTopicNumber";
import { useMemo } from "react";

type TopicHeaderProps = {
  type: string;
  data: any;
  id: number;
};

const TopicHeader: React.FC<TopicHeaderProps> = ({ type, data, id }) => {
  const histroy = useHistory();
  const previews = useSelector(previewsListbyType)?.toJS();
  const apiResponse = useSelector(apiWaitingResponse);
  const activeSubject = useSelector(getActiveSubject);
  const mediaStatus = useSelector((state) => statusById(state, id));
  const newSubject = useNewSubject();
  const topicNumber = useTopicNumber(data);
  const subTopicNumber = useSubtopicNumber(data);
  const isLoading = previews[id].loading || apiResponse;
  const resultData = previews[id].data;
  const resultSize = resultData?.length;
  const topicClick = () => {
    if (type === "subtopic") {
      const topicId = data.topicId;
      histroy.push(`/flashcard/topic/${topicId}`);
    }
  };
  const getSubtopicNumber = useMemo(() => {
    if (
      data.tltagging === "NUMERIC" ||
      (data.tltagging === "CUSTOM" && data?.customTopicNumbering === null) ||
      [null, "", "null"].includes(data.tltagging)
    ) {
      return `${data.label}.${data.subTopicLabel}`;
    }
    return subTopicNumber;
  }, [
    data?.customTopicNumbering,
    data?.label,
    data?.subTopicLabel,
    data?.tltagging,
    subTopicNumber,
  ]);
  if (!mediaStatus) return null;
  return (
    <>
      <div className="px-5">
        <div className="h-52px flex py-[15px] items-center [&_svg]:rotate-[270deg]">
          <TeachingLevelSeperator
            tlvl={data.tlvl}
            tlvlTag={data.teachingTag}
            {...(newSubject && { slTag: data.teachingTag === "SL" })}
          />
          <div
            className={classNames("topic-number", {
              "sub-topic-number": type === "subtopic",
            })}
            onClick={topicClick}
          >
           {topicNumber}
          </div>
          {type === "subtopic" && (
            <>
              {" "}
              <div className="pl-2" />
              <ArrowTopicIcon classnames={""} />
              <div className="sub-topics-wrapper">
                <Text className="text-13px font-bold leading-18px tracking-2px text-blue-normal">
                  {getSubtopicNumber}
                </Text>
              </div>
            </>
          )}
        </div>
        <Text className="font-bold mt-[9px] mb-3.5 leading-[34px] text-#2D3838 text-[25px]" dangerouslySetInnerHTML={data?.title}/>
      </div>
      <MediaTypeTab
        page="topic"
        subject={activeSubject}
        topicId={id}
        techingLevel={data.tlvl}
        isLoading={isLoading}
        resultSize={resultSize}
      />
    </>
  );
};

export default TopicHeader;
