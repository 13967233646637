import React, { useMemo } from 'react';
import './MessageLoader.scss'
import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2';



type MessageCodeProps = {
}

const MessageCode: React.FC<MessageCodeProps> = () => {

  const generateRandomMessage = useMemo(() => {
    const messagesArray = [
      {
        title: <SimpleTitle text={`"Alright IB, time to take you on!"`} />,
        subtitle: <YourFriendsAtSMPP />
      },
      {
        title: <SimpleTitle text={`"The journey of a thousand miles begins with one step."`} />,
        subtitle: <SimpleSubtitle text={`Chinese proverb`} />
      },
      {
        title: <SimpleTitle text={`"Remember two rules: 1. never quit, 2. never forget rule #1."`} />,
        subtitle: <YourFriendsAtSMPP />
      },
      {
        title: <SimpleTitle text={`"Energy and persistence conquer all things."`} />,
        subtitle: <SimpleSubtitle text={`Benjamin Franklin`} />
      },
      {
        title: <SimpleTitle text={`"Success is never final, failure is never fatal."`} />,
        subtitle: <SimpleSubtitle text={'John Wooden'} />
      },
      {
        title: <SimpleTitle text={`"Do or do not; There is no try."`} />,
        subtitle: <SimpleSubtitle text={'Yoda'} />
      },
      {
        title: <SimpleTitle text={`"The journey of a thousand miles begins with one step."`} />,
        subtitle: <SimpleSubtitle text={`Chinese proverb`} />
      },
      {
        title: <SimpleTitle text={`"To move mountains, begin by carrying away small stones."`} />,
        subtitle: <SimpleSubtitle text={'Chinese proverb'} />
      },
      {
        title: <SimpleTitle text={`"Discipline is the bridge between goals and accomplishment."`} />,
        subtitle: <SimpleSubtitle text={'Jim Rohn'} />
      },
      { title: <SimpleTitle text={`"Do yourself proud."`} />, subtitle: <YourFriendsAtSMPP /> },
    ];
    return messagesArray[Math.floor(Math.random() * 10)];
  }, [])
  const { title, subtitle } = generateRandomMessage;


  return <>
    <div className=' justify-center items-center flex flex-col w-auto pr-5px pl-5px'>{title}</div>
    <div className='pt-4 pb-12'>{subtitle}</div>
    <Loader background={BackgroundEnum.BlueX} size={SizeEnum.Large} />
  </>
}

export default MessageCode

type SimpleTitleProps = {
  text: string
}
const SimpleTitle: React.FC<SimpleTitleProps> = ({ text }) => (
  <div className='text-33px text-blue-normal font-bold leading-45px text-center'>{text}</div>
);

type SimpleSubtitleProps = {
  text: string
}

const SimpleSubtitle: React.FC<SimpleSubtitleProps> = ({ text }) => (
  <div className='text-19px text-#A2A3A3 font-bold leading-26px text-center tracking-1px'>- {text}</div>
);


const YourFriendsAtSMPP = () => (<div className='text-19px text-#A2A3A3 font-bold tracking-1px text-center leading-26px '> - Your friends at <span className='text-19px text-#A2A3A3 font-light leading-26px tracking-0px'><strong className='font-bold'>OSC</strong></span></div>);