import {SET_VIDEO_PROGRESS, SET_VIDEO_PLAYING_STATUS} from 'actions/videoProgress/videoProgressActionTypes';
import {fromJS, Map} from 'immutable';

export default (state = Map({
    totalTime: "00:00",
    currentTime: "00:00",
    // playingStatus:[{
    //   playing:false
    // }]
}), action) => {
  switch (action.type) {
    case SET_VIDEO_PROGRESS:
      return state.withMutations(state => {
        action.data.forEach(node => {
            state.set(node.id, fromJS({totalTime: node.totalTime, currentTime: node.currentTime}));
        });
      });
      case SET_VIDEO_PLAYING_STATUS:
        return state.withMutations(state => {
          action.data.forEach(node => {
              state.setIn(["playingStatus",node.id], fromJS({playing: node.playing}));
          });
        });
    default:
      return state;
  }
};
