import { useEffect, useRef, useState } from 'react'
import { isDeskEditModeActive } from 'selectors/navigation/navigationSelector'
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import FolderListItem from './FolderListItem';
import FlashcardEmptyScreen from 'v2/components/empty-screen-message/FlashcardEmptyScreen';
import { CreateFolderIcon } from 'icons/HeaderIcons';
import { folders } from 'selectors/desk/deskSelector';
import { apiRequestData } from 'selectors/api/apiSelector';
import { listOfCheckedItems } from 'selectors/desk/deskSelector';
import { getFlashcardById, currentFlashcardId } from 'selectors/studying';
import { updateFolderSequence, clearFlashcardsInFolder, clearFolder, updateListOfItemsSelected } from 'actions/desk/deskActions';
import { setGoBackToSearch } from 'actions/search/searchActions';
import { Reorder } from 'helpers';
import { useHistory } from 'react-router-dom';
import usePrevious from 'hooks/usePrevious';

const FoldersListContainer = () => {
  const getFolders = useSelector(folders).toJS();
  const prevFolders = usePrevious(getFolders);
  const [foldersData, setFoldersData] = useState<any>(getFolders?.data?.items || []);
  const [foldersHeight, setFoldersHeight] = useState<number>(0);
  const folderContainerHeight = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const hasDeskEditModeActive = useSelector(isDeskEditModeActive);
  const flashcardFolders = useSelector(state => apiRequestData(state, 'flashcardFolders'))?.toJS();
  const getListOfCheckedItems = useSelector(listOfCheckedItems);
  const getCurrentFlashcardId = useSelector(currentFlashcardId);
  const flashcardContent = useSelector(state => getFlashcardById(state, getCurrentFlashcardId))?.toJS();

  useEffect(() => {
    calculateFolderContainerHeight();
  }, [])

  useEffect(() => {
    if(getFolders?.data?.items?.length != prevFolders?.data?.items?.length) {
      setFoldersData(getFolders.data.items)
    }
    //setFoldersData(getFolders.data?.items || [])

  }, [getFolders])

  const onFolderClick = (item: any) => {
    // Remove previous clickable folder list on button click
    dispatch(clearFlashcardsInFolder());
    dispatch(clearFolder());
    dispatch(setGoBackToSearch(false));
    if(hasDeskEditModeActive) {
      dispatch(updateListOfItemsSelected(item.id));
    }else{
      history.push(`/flashcard/desk/folders/${item.id}`);
    }
  }

  const calculateFolderContainerHeight = () => {
    if (folderContainerHeight.current && folderContainerHeight.current.clientHeight) {
      setFoldersHeight(folderContainerHeight.current.clientHeight);
    }
  }

  const onDragEnd = (result:DropResult) => {
    if (!result.destination) {
      return ;
    }
    if (result.type === "Folders") {
      const folders = Reorder(
        foldersData,
        result.source.index,
        result.destination.index
      );
      const sequenceFolderIds = folders.map((items:any) => {
        return items.id;
      });
      const sequenceFolder = { sequence_folder: sequenceFolderIds.toString() };
      dispatch(updateFolderSequence(sequenceFolder));
      setFoldersData(folders);
    }
  }

  return <>
      <div className='mb-4'>
        <ul className={classNames('folders-list', { 'opacity-50 pointer-events-none': hasDeskEditModeActive })}>
          <FolderListItem hasFlashcard={true} flashcardFolders={flashcardContent.bookMark} onClick={() => onFolderClick({ id: 'bookmark', isSmart: true })} name='Bookmarked' isEditing={hasDeskEditModeActive} />
        </ul>
      </div>
      <div className='folders-section'>
        <h2 className='text-13px font-bold text-#A2A3A3 tracking-[2px] py-[7px] px-5 leading-[18px] mb-2'>PERSONAL</h2>
        <div className={classNames({'': hasDeskEditModeActive })}>
            <FlashcardEmptyScreen type='folders' size={foldersData?.length} edit={hasDeskEditModeActive} > <CreateFolderIcon /> </FlashcardEmptyScreen>
        </div>
        <div ref={folderContainerHeight} style={{ 'minHeight': `${foldersHeight}px` }}>
          <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
            <Droppable droppableId="droppable" type="Folders" direction="vertical">
              {(provided, snapshot) => (
              <div className="dragFolder" {...provided.droppableProps} ref={provided.innerRef}>
                {foldersData?.map((folder: any, index: number) => (
                  <FolderListItem key={index} index={index} folder={folder} foldercount={foldersData.length} isfolders={true} flashcardFolders={flashcardFolders} onClick={() => onFolderClick(folder)} isEditing={hasDeskEditModeActive} listOfCheckedItems={getListOfCheckedItems} />
                ))}
              </div>
              )} 
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </>
}

export default FoldersListContainer
