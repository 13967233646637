import styled, {css} from 'styled-components/macro';
import media from 'ui-components/helpers/media';

export const DeskMenuItem = styled.div`
  
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  color: ${props => props.theme.lightGrey2};
  cursor: pointer;
  line-height: 18px;
  //z-index: 2;
  width: 100%;
  text-align: center;
  letter-spacing: 2px;
  padding:5px 20px 5px 20px;

  ${media.smallPhone(css` 
    padding:5px 0;
  `)}
  ${props => props.active && `border-radius: 14px; box-shadow: 0px 1px 3px rgba(93, 116, 114, 0.35); background: linear-gradient(165.4deg, #48CEA8 0%, #4ABAD1 128.13%);  color: #ffffff;`}
  ${props => props.editing && 'opacity: 0.3; pointer-events: none;'}
`;
export const ExerciseDeskMenu = styled.div`
  
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  color: ${props => props.theme.lightGrey2};
  cursor: pointer;
  line-height: 26px;
  //z-index: 2;
  width: 100%;
  text-align: center;
  letter-spacing: 2px;
  padding: ${props => props.hasNotification ? '5px 0px 5px 20px' : '5px 20px 5px 20px'};

  ${media.smallPhone(css` 
    padding:5px 0;
  `)}
 
  ${props => props.active && css`
        border-radius: 18px;
        background: linear-gradient(225deg, #43DAF0 0%, #395BF8 100%);
        box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
        color: #FFFFFF;`
    }
  ${props => props.editing && 'opacity: 0.3; pointer-events: none;'}
`;
