import './App.scss';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

class PublicLayout extends Component {
  componentDidMount() {
    const whiteBgPages = ['/register'];
    const isRegisterPage = this.props.match && whiteBgPages.indexOf(this.props.match.path) !== -1;
    if(isRegisterPage){
      document.body.classList.remove('gradient-bg');
    }else{
      document.body.classList.add('gradient-bg');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('gradient-bg');
  }

  render() {
    const whiteBgPages = ['/register'];
    const isRegisterPage = this.props.match && whiteBgPages.indexOf(this.props.match.path) !== -1;
    if(isRegisterPage){
      document.body.classList.remove('gradient-bg');
    }else{
      document.body.classList.add('gradient-bg');
    }
    return (
      <div className={`public-layout ${isRegisterPage ? 'white-bg' : ''}`}>
        {this.props.children}
      </div>
    );
  }
}

// PublicLayout.propTypes = {
//   children: PropTypes.element.isRequired,
//   match: PropTypes.element
// };


export default PublicLayout;
