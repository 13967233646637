import { authenticate } from 'actions/api';
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import GoogleLoginError from 'v2/components/notifications/GoogleLoginError';
import { decodeQuery } from 'v2/helpers';

type ParamsType = {
    token: string
}

enum Errors {
    INVALID_TOKEN = "INVALID_TOKEN",
    INVALID_GMAIL = "INVALID_GMAIL",
}

const GoogleAuthPage = () => {
    const location = useLocation();
    const disptach = useDispatch();
    const params = useParams<ParamsType>();
    const [isError, setError] = useState<string>();

    const completeLogin = useCallback((accessTokenData: string) => {
        const redirectUrl = localStorage.getItem('smpp-redirect-url');
        if (redirectUrl) localStorage.removeItem('smpp-redirect-url');
        disptach(authenticate({}, accessTokenData, redirectUrl));
    },[disptach])
    
    useEffect(() => {
        const query = decodeQuery(location.search.substring(1));
        const token = params?.token;
        let error = query.error;
        let accessTokenData;
        if (token) {
            try {
                accessTokenData = JSON.parse(window.atob(token));
            } catch (e) {
                error = Errors.INVALID_TOKEN;
            }
        } else {
            error = Errors.INVALID_GMAIL;
        }

        if (error || !token) {
            setError(error);
        }
        completeLogin(accessTokenData);
    }, [completeLogin, location.search, params?.token]);

    const handleErrors = () => {
        switch (isError) {
            case "INVALID_TOKEN":
                return <GoogleLoginError error={'INVALID_TOKEN'} />
            case "INVALID_GMAIL":
                return <GoogleLoginError error={'INVALID_GMAIL'} />
            default:
                break;
        }
    }
    return <div>{isError && handleErrors()}</div>
}

export default GoogleAuthPage