import React from 'react';
import { toggleNodeSelection } from 'actions/topics-tree/topicsTreeActions';
import { useDispatch, useSelector } from 'react-redux';
import { isNodePartial, isNodeSelected } from 'selectors/topicsTreeSelector';
import CheckboxContainer from './CheckboxContainer';
import './checkbox.scss'

type CheckboxProps = {
  id: number,
  parentIds: Array<[0, 0]>,
  className?: string,
  beforeDefaultOnClick?: () => void
}

const Checkbox: React.FC<CheckboxProps> = ({ id, parentIds = [], className, beforeDefaultOnClick }) => {
  const disptatch = useDispatch();
  const hasNodePartial = useSelector((state) => isNodePartial(state, id, parentIds));
  const hasNodeSelected = useSelector((state) => isNodeSelected(state, id, parentIds))

  const buttonToggle = (e: any) => {
    beforeDefaultOnClick?.();
    disptatch(toggleNodeSelection(id, parentIds));
    e.stopPropagation();
  }

  return <div className={className} onClick={(e) => buttonToggle(e)}>

     <CheckboxContainer isActive={hasNodeSelected} isPartial={hasNodePartial}/>
  </div>
}


export default Checkbox