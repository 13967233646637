import React from 'react'
import classNames from 'classnames';
import './studyStatusBars.scss'

type StudyStatsBarsProps = {
    statusBarGraphData: any;
    isActive?: boolean; 
    isSubtopic?: boolean;
}  

const StudyStatsBars: React.FC<StudyStatsBarsProps> = ({statusBarGraphData, isActive, isSubtopic}) => {
    const computeBGColor = () => {
        return isActive ? '#EFF4F9' : isSubtopic ? '#DBE6F1' : '#EFF4F9'
    };
    const computeWidth = (percentage: number) => {
        return `${(percentage > 0 && percentage <= 3.5)  ? 3.5 : percentage}%`
    }
    return <div className='flex flex-col w-auto overflow-hidden'>
        <div className='relative'>
            <div className={classNames('bar-line bar-line-border z-0')} style={{background: computeBGColor()}} />
            {statusBarGraphData.map((statusData: any, index: number )=> (
                <div className={classNames(`bar-line bar-line-absolute z-${computeIndex(statusData.title)} left-0`, {'hidden' : (statusData.title === 'Unassessed')}, {'hidden' : (statusData.title === 'Unassessed')}, {'bar-border-active' : (statusData.percentage === 100)})} key={index} style={{background: computeColor(statusData.title), width: computeWidth(statusData.percentage)}} />
            ))}
        </div>
    </div>
}

const computeColor = (type:string) => {
    if (type === 'Neither') return '#C2D0D9';
    if (['Confident', 'Read', 'Watched', 'Correct'].includes(type)) return 'linear-gradient(135deg, #50D466 0%, #50D4A8 100%)';
    if (type === 'Unconfident') return 'linear-gradient(135deg, #FA7D00 0%, #FA9A00 100%)';
    if (type === 'Incorrect') return 'linear-gradient(135deg, #FC2808 0%, #FC5108 100%)';
    return '#EFF4F9;';
};
const computeIndex = (type:string) => {
    if (['Neither', 'Incorrect'].includes(type)) return 2;
    if (['Confident', 'Read', 'Watched', 'Correct'].includes(type)) return 1;
    if (type === 'Unconfident') return 3;
    return 0;
  };

export default StudyStatsBars;