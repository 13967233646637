import React from 'react';
import { useDispatch } from 'react-redux';
import { isNavigationSectionVisible } from 'selectors';
import { toggleNavigationSection } from 'actions/navigation';
import menuToggleActiveIcon from 'assets/icons/menu-toggle-active.svg';
import menuToggleInactiveIcon from 'assets/icons/menu-toggle-inactive.svg';

type ToggleMenuButtonContainerProps = {
  children: any
}
const ToggleMenuButtonContainer: React.FC<ToggleMenuButtonContainerProps> = ({ children }) => {
  const dispatch = useDispatch()
  const isActive = !isNavigationSectionVisible;

  const onClickAction = () => {
    dispatch(toggleNavigationSection(!isNavigationSectionVisible));
  }
  if (children) return children({ onClick: onClickAction, isActive });
  return (<div className='round-button' onClick={onClickAction}>
    <img src={isActive ? menuToggleActiveIcon : menuToggleInactiveIcon} alt="" />
  </div>)
}


export default ToggleMenuButtonContainer;