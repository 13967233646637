import React from 'react';
import styled, {css} from 'styled-components/macro';
import media from 'ui-components/helpers/media';
import Padding from 'ui-components/helpers/Padding';
import PropTypes from 'prop-types';
import {PageTitle} from 'ui-components/pageComponents';
import {PageOverlayWrapper} from 'ui-components/PageOverlay';
import * as HeaderIcons from 'icons/HeaderIcons';
import * as CountryIcons from 'icons/CountryIcons';
import {getMBUrlBasedOnRegion} from 'helpers';
import NavigationHeader from 'components/navigation-header/NavigationHeader';
import {withScrollDetectionContained} from 'hocs/withScrollDetection';
import './Support.scss';
import {ReactComponent as CloseIcon} from 'assets/icons/page-overlay/close-icon-turquoise.svg';

const SupportComponent = (props) => {
  const {isMBUser, userData, startOnboarding, isControlOverlay, isMobilePortrait, toggleSupportManagerOverlay, isScrolled, bindScrollRef} = props;
  return (<StyledSupportManager isControlOverlay={isControlOverlay} isMBUser={isMBUser} ref={bindScrollRef}>
    {!isMobilePortrait && (<NavigationHeader
              title={(
                <div className="support-title-container">
                  <div className="support-title">Support</div>
                </div>
              )}
              rightSection={<StyledCloseButton onClick={() => toggleSupportManagerOverlay(false,false)}>
                              <CloseIcon/>
                            </StyledCloseButton>}
              isSupportPage={true}
              leftSection={<div/>}
              isCollapsed={isScrolled}
              extendedHeight
              shouldExtendHeight={isScrolled}
              bottomSection={<div/>}
          />) }
    <StyledSupportInner>
        <StyledSupportLeftSection>
        
          <PageTitle>Support</PageTitle>
            <div className="support-page-items-container">
                <a href={`http://help.oxfordstudycourses.com/`} target="_blank" className="support-page-item">
                  <div className="support-page-item-container"> Support centre
                      <div className="forward-arrow-icon"><HeaderIcons.ForwardArrowIcon/></div>
                  </div>
                </a>
                <a
                    onClick={() => startOnboarding(true)}
                    className="support-page-item">
                    <div className="support-page-item-container"> Restart onboarding
                       {/* <div className="forward-arrow-icon"><HeaderIcons.ForwardChevronIcon/></div> */}
                    </div>
                </a>
            </div>
        </StyledSupportLeftSection>
    </StyledSupportInner> 
</StyledSupportManager>)
}
export default withScrollDetectionContained(SupportComponent);
SupportComponent.propTypes = {
  userData: PropTypes.object,
  openMenu: PropTypes.func.isRequired,
  startOnboarding: PropTypes.func.isRequired,
};
export const StyledSupportManager = styled.div`
  background: #FFFFFF;
  border: 1px solid #E1E6E6;
  border-radius: 16px;
  padding: ${props => props.isMBUser ? "48px 48px 48px 36px" : "36px"};
  max-width: 822px;
  ${props => props.isControlOverlay && css`
    background: none;
    padding: 0;
    border: 0;
 `}
  ${PageTitle}{
    padding-left: 20px;
    padding-bottom: 14px; 
    ${media.phone(css`
      //padding-left: 18px;
      @media(orientation: landscape){
        display:none;
      }
    `)}
  }
  > ${Padding}{
    ${media.phone(css`
      padding-left: 4px;
    `)}
  }
  
   ${media.phone(css`
      border: none;
      border-radius: 0;
      padding: 0 0;
      max-width: initial; 
      @media(orientation: landscape){
        overflow: auto;
        height: 100%;
      }
  `)}
   
   ${PageOverlayWrapper} & {
    ${media.phone(css`
      align-self: flex-start;
      margin-top: 56px;
      width: 100%;
  `)}
   }
`;
export const StyledSupportInner = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
export const StyledSupportLeftSection = styled.div`
    width: ${props => props.isMBUser ? "391px" : "391px"};
   // border: 1px solid red;
    //padding-left: 20px;
    ${media.phone(css`
      width: 100%;
      padding-left: 0px;
  `)}
`;
export const StyledSupportRightSection = styled.div`
    //border: 1px solid blue;
    padding-top: 59px;
    padding-left: 15px;
    ${props => props.isControlOverlay && css`
        flex: 1;
        padding: 16px 14px;
    `}
    ${media.phone(css`
      width: 100%;
      @media(orientation: Portrait){
        padding: 16px 14px 16px 14px;
      }
      @media(orientation: landscape){
        padding: 16px 14px;
      }
    `)}
`;

export const StyledContactInformation = styled.div`
    padding: 16px 20px 20px 20px;
`;

export const HoursHeading = styled.div`
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #FFFFFF;
  padding-bottom: 27px;
`;

export const StyledContact = styled.div`
  display: flex;
  padding-bottom: 12px;
  align-items: center;
  &:last-child{
    padding-bottom: 0px;
  }
`;
export const StyledContactNumber = styled.div`
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #FFFFFF;
  padding-left: 10px;
`;
const CloseButton = styled.div`
 
  right: 20px;
  height: 20px;
  width: 20px;
  background: #2EBCB4;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  //transition: all .2s;
  @media (pointer: fine) {
    &:hover {
      background: #44CAC3;
    }
  } 
  &:active {
    background: #3DA898;
  }
`;
const StyledCloseButton = styled.div`
  right: 20px;
  height: 20px;
  width: 20px;
  background: #2EBCB4;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
 
  @media (pointer: fine) {
    &:hover {
      background: #44CAC3;
    }
  } 
  &:active {
    background: #3DA898;
  }
`;