import * as Actions from 'actions';
import {getAccessToken, getFormExams, getLastSubjectVisited, filterResultsListToArrayByTOJS } from 'helpers';

import {setNodeMark, setNodeWatched, setExamNodeAnswers} from 'actions/answers/answersActions';
import {setVideoProgress, setVideoPlayingStatus} from 'actions/videoProgress/videoProgressActions';

import {setQuickNodeStatus} from 'actions/quickStatus/quickStatusActions';
import {statusById} from 'selectors/statusSelector';
import {quickStatusById, examStatusById} from 'selectors/quickSelectors';
import { getFlashcardIdsRoutine, getCardsTitleByType, updateVideoProgressValueOfTitlesByFlashcardId, getLoggedinDevices} from 'actions/api';
import { GET_FLASHCARD_CONTENT_SUCCEEDED } from 'actions/api/apiTypes';
import { calculateFlashcardIndex, setFlashcardIds, setRevisionIds, setVideoIds, setPreviousFlashcardIds , setFlashcardIdDetails ,setVideoIdDetails, setRevisionIdDetails} from 'actions/carousel/carouselActions';
import { updateIdsOfFilterItems, updateListOfFilteredTitles } from 'actions/desk/deskActions';

import {
  CALCULATE_FLASHCARD_INDEX,
  SELECT_NEXT_FLASHCARD,
  SELECT_PREV_FLASHCARD,
  SET_FLASHCARD_IDS
} from 'actions/carousel/carouselActionTypes';
import { toggleSubjectsManager, toggleDeviceManagerOverlay, toggleNavigationSection, setActiveFlashcardTab , setExerciseOnCanvas,updateExamsList, updateProgressBarData} from 'actions/navigation';
import { showAlert } from 'actions/notification';
import { clearSearch } from 'actions/search';
import { SET_SEARCH_INDEX } from 'actions/search/searchTypes';
import {
  changeFlashcard as changeFlashcardAction,
  flipFlashcardAction,
  setActiveExerciseId,
  setCarouselTeachingLevel,
  setToolboxOpenSection, 
  toggleOverlay
} from 'actions/studying';
import {setNodeStatus} from 'actions/status/statusActions';
import {setExamNodeStatus} from 'actions/examStatus/examStatusAction';
import { CURRENT_FLASHCARD_CHANGED } from 'actions/studying/types';
import { setActiveTeachingLevel } from 'actions/subject';
import { openNodesAction, setActiveNodesAction } from 'actions/topics-tree/topicsTreeActions';
import * as Types from 'actions/types';
import * as Api from 'api';
import appHistory from 'appHistory';
import DeviceLimitNotification from 'v2/components/notifications/DeviceLimitNotification';
import {
  addLastFlashcardToLocalStorage,
  clearDevice,
  getDevice,
  saveDevice,
  setLastSubjectVisited,
  updateFlashcardUrl,
} from 'helpers';
import { List } from 'immutable/dist/immutable';
import React from 'react';
import { delay } from 'redux-saga/effects';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import * as Selectors from 'selectors';
import {
  activeExerciseId,
  activeSearchResult,
  activeSubjectId,
  flashcardIdsResponse,
  getActiveTeachingLevel,
  navigationMode,
  shouldGoBackToSearch,
  isNavigationSectionVisible,
  exercisePreviewsResponse,
  getListOfFilterItemsIds,
  listOfFilterCheckedItems
} from 'selectors';
import { isMobile, isLandscape, isMobilePortrait } from 'selectors/browser/browserSelector';
import {
  carouselCurrentFlashcard,
  carouselCurrentFlashcardId,
  carouselFlashcardIds,
  carouselRevisionIds,
  carouselVideoIds,
  carouselFlashcardIndex,
  carouselFlashcardIdDetails,
  carouselVideoIdDetails ,
  carouselRevisiondIdDetails
} from 'selectors/carouselSelectors';
import {
  carouselTeachingLevelSelector,
  getFlashcardById,
  isDeviceRestricted,
  swap as swapSelector
} from 'selectors/studying';
import { getLastMediaTypeVisited ,setFormExams,examAnswerStatistics,getExamTopicId, getExamSubTopicId} from 'helpers';
import { getLastSubjectActive } from 'v2/helpers';

export function* watchers() {
  yield all([
    takeLatest(Types.Studying.REGISTER_DEVICE, registerDevice),
    takeLatest(Types.Studying.UNREGISTER_DEVICE, unRegisterDevice),
    takeLatest(Types.Studying.ENABLE_RESTRICTION, enableRestriction),
    takeLatest(Types.Studying.START_UPDATE_DEVICE, startUpdateDevice),
    takeLatest(Types.Studying.SELECT_FLASHCARD, selectFlashcard),
    takeLatest(Types.Studying.CHANGE_FLASHCARD, changeFlashcard),
    takeLatest(Types.Subject.SET_ACTIVE_SUBJECT, setActiveSubject),
    takeLatest(Types.Studying.SET_FLASHCARD_META, setFlashcardMeta),
    takeLatest([CALCULATE_FLASHCARD_INDEX, SELECT_NEXT_FLASHCARD, SELECT_PREV_FLASHCARD ], onCurrentFlashcardChange),
    //SMPP-LAGGY
    // takeLatest(Types.Studying.SET_CURRENT_FLASHCARD_ID, getAnswers),
    // takeLatest(Types.Api.GET_ANSWERS, getAnswers),
    takeLatest(Types.Api.SET_MARKS, setMarks),
    takeLatest(Types.Api.SET_EXAM_STATUS,setExamsStatus),
    takeLatest(Types.Api.SET_VIDEO_PLAYED, setVideoPlayed),
    takeLatest(Types.Api.SET_VIDEO_PLAYED_STATUS, setVideoPlayedStatus),
    takeLatest(Types.Studying.SELECT_EXERCISE_FLASHCARD, selectExerciseFlashcard),
    takeLatest(Types.Studying.END_EXERCISE, endExercise),
    takeLatest(Types.Studying.INITIALIZE_CAROUSEL, initializeCarousel),
    takeLatest(CURRENT_FLASHCARD_CHANGED, onCurrentFlashcardChanged),
    takeLatest(SET_SEARCH_INDEX, onSetSearchIndex),
    takeLatest(Types.Studying.GET_LAST_STUDY_FLASHCARD, getLastStudyFlashcard),
    takeLatest(Types.Navigation.SET_ACTIVE_MEDIA_TAB, onSetFlashcardIds),
    takeLatest(Types.Studying.SET_WATCHED_STATUS, onSetWatchedSatus),
    takeLatest(getCardsTitleByType.trigger, onGetCardsTitleByType), 
    takeLatest(Types.Studying.SELECT_EXAMS, selectExams),
    takeLatest(Types.Navigation.SET_ACTIVE_TEACHING_EXAM,setActiveTeachingForExam) 
  ]);
}

function* onSetSearchIndex() {
  try {
    const searchResult = yield select(activeSearchResult);
    if (searchResult) yield flipCardForSearch(searchResult.flashcardId);
  } catch (error) {
    console.error("error for onSetSearchIndex", error)
  }
}

/**
 * Flips flashcard according to the search selected
 * @param {Object} action
 * @param {Number} action.flashcardId
 */
function* onCurrentFlashcardChanged(action) {
  try {
    yield put(Actions.Notification.hideNotification())
    const userId = yield select(Selectors.getUserId);
    const mediaType = getLastMediaTypeVisited(userId)
    if(mediaType == 'flashcard') {
      yield flipCards();
      yield flipCardForSearch(action.flashcardId);
    }
  } catch (error) {
    console.error("error for onCurrentFlashcardChanged", error)
  }
}

function* flipCardForSearch(flashcardId) {
  try {
    const currentNavigationMode = yield select(navigationMode);
    const currentShouldGoBackToSearch = yield select(shouldGoBackToSearch);
    const currentActiveSearchResult = yield select(activeSearchResult);

    if (currentActiveSearchResult
      && currentNavigationMode === 'search'
      && currentShouldGoBackToSearch
      && flashcardId === currentActiveSearchResult.flashcardId) {

      const flashcard = yield select(getFlashcardById, flashcardId);
      if (flashcard.size === 0) {
        yield take((capturedAction) => {
          return capturedAction.type === GET_FLASHCARD_CONTENT_SUCCEEDED
            && capturedAction.data.data.id === flashcardId;
        });
      }

      yield put(flipFlashcardAction(flashcardId, currentActiveSearchResult.side));
    }
  } catch (error) {
    console.error("error for flipCardForSearch", error)
  }
}

function* flipCards() {
  try {
    const carouselIndex = yield select(carouselFlashcardIndex);
    const carouselFlashcards = yield select(carouselFlashcardIds);
    const prevIndex = carouselIndex - 1;
    const nextIndex = carouselIndex + 1;
    const swap = yield select(swapSelector);
    const newSideIndex = swap ? 1 : 0;
    yield put(flipFlashcardAction(carouselFlashcards[prevIndex], newSideIndex));
    yield put(flipFlashcardAction(carouselFlashcards[carouselIndex], newSideIndex));
    yield put(flipFlashcardAction(carouselFlashcards[nextIndex], newSideIndex));
  } catch (error) {
    console.error("error for flipCards", error)
  }
}

/**
 * Get flashcards and set them for use in the carousel
 * @param {Object} action
 * @param {number} action.flashcardId
 */
function* initializeCarousel({ flashcardId }) {
  try {
    const userId = yield select(Selectors.getUserId);
    const  mediaType = getLastMediaTypeVisited(userId)
    let activeMediaTab = yield select(Selectors.getActiveMediaTab);
    const activeSubject = yield select(Selectors.getActiveSubject);
    let flashcardIdsResponse = []
    let flashcardIds = [];
    let filteredIds = [];
    let exerciseflashcardIds = [];
    if(activeMediaTab != mediaType){      // prevent structure API call recursively, for same media type on CC and LM
      yield put(getFlashcardIdsRoutine.trigger({ subjectId: activeSubject?.id }));
      flashcardIdsResponse = yield take(getFlashcardIdsRoutine.success);
      let responseData = flashcardIdsResponse.payload.response.items;
        responseData.forEach((data) => {
          flashcardIds = flashcardIds.concat(data.flashcards);
          filteredIds = filteredIds.concat(data.flashcardsAnswers);
        });
      const getExerciseOnCanvas =  yield select(Selectors.getExerciseOnCanvas)
      if(!getExerciseOnCanvas){
        // yield put(setFlashcardIds(List(flashcardIds)));
        yield put(setFlashcardIds(flashcardIds));
      }else{
        // set exerciseflashcardIds
        const exercisePreviews = yield select(exercisePreviewsResponse);
        const exerciseId = yield select(activeExerciseId);
        if(exerciseId){
          exercisePreviews.getIn([exerciseId, 'data']).map((item) => {
            exerciseflashcardIds = exerciseflashcardIds.concat(item.get('flashcardId'));
          });
          yield put(setExerciseOnCanvas(true));
          yield put(setPreviousFlashcardIds(List(exerciseflashcardIds)));
          // yield put(setFlashcardIds(List(exerciseflashcardIds)));
          yield put(setFlashcardIds(exerciseflashcardIds));
        }else{
          // yield put(setFlashcardIds(List(flashcardIds)));
          yield put(setFlashcardIds(flashcardIds));
        }
      }
    }
    const activeTeachingLevel = yield select(getActiveTeachingLevel);
    yield put(setCarouselTeachingLevel(activeTeachingLevel));
    if (flashcardId) yield put(calculateFlashcardIndex(flashcardId));
  } catch (error) {
    console.error("error for initializeCarousel", error)
  }
}


function* onGetCardsTitleByType(action) {
  const key = action.payload && (action.payload.key || action.payload.id);
  try {
    let requestData = action.payload;
    const activeSubjectId = yield select(Selectors.activeSubjectId);
    const activeSubject = yield select(Selectors.getActiveSubject);
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
    const teachingTag = subjectTeachingLevel[`${activeSubjectId}`]
    if((activeSubject && activeSubject.slug === "pre-ib-mathematics") ){
      requestData = action.payload;
    }else{
      requestData = {subjectId: action.payload.subjectId,  mediaType: action.payload.mediaType , teachingTag: teachingTag}
    }
    let response = yield call(Api.getCardsTitleByType, requestData);
    yield put(updateListOfFilteredTitles(response, action.payload.mediaType))
    yield put(getCardsTitleByType.success({key, response, requestData}));
  } catch (error) {
    yield put(getCardsTitleByType.failure({key, error}));
  }
  yield put(getCardsTitleByType.fulfill({key}));
}

function* onSetFlashcardIds(action){
  try {
    const pathname = appHistory.location.pathname;
    const activeSubject = yield select(Selectors.getActiveSubject);
    const userId = yield select(Selectors.getUserId);
    const getExerciseOnCanvas =  yield select(Selectors.getExerciseOnCanvas)
    const  mediaType = getLastMediaTypeVisited(userId)
    let activeMediaTab = yield select(Selectors.getActiveMediaTab);

    if(activeSubject){
      if(!getExerciseOnCanvas){
        if(pathname==='/flashcard/topics')
        yield delay(2000);
        yield put(getFlashcardIdsRoutine.trigger({ subjectId: activeSubject.id }));
        let flashcardIdsResponse = yield take(getFlashcardIdsRoutine.success);
        let flashcardIds = [];
        let filteredIds= [];
        let responseData = flashcardIdsResponse.payload.response.items;
        responseData.forEach((data) => {
          flashcardIds = flashcardIds.concat(data.flashcards);
          filteredIds = filteredIds.concat(data.flashcardsAnswers);
        });
        if(activeMediaTab == "revision"){
          yield put(setRevisionIds(flashcardIds));
          yield put(updateIdsOfFilterItems(List(filteredIds), 'revision'));
          yield put(setRevisionIdDetails((flashcardIdsResponse.payload.response)));
        }
        if(activeMediaTab == "video"){
          yield put(setVideoIds(flashcardIds));
          yield put(updateIdsOfFilterItems(List(filteredIds), 'video'));
          yield put(setVideoIdDetails((flashcardIdsResponse.payload.response)));
        }

        if(activeMediaTab === "flashcard"){
          // yield put(setFlashcardIds(List(flashcardIds)));
          yield put(setFlashcardIds(flashcardIds));
          yield put(updateIdsOfFilterItems(List(filteredIds), 'flashcard'));
          yield put(setFlashcardIdDetails(flashcardIdsResponse.payload.response))
          yield put(setPreviousFlashcardIds(List(flashcardIds)));
        }
        if(mediaType == "" && activeMediaTab === "flashcard"){
          yield put(setFlashcardIds(flashcardIds));
          yield put(updateIdsOfFilterItems(List(filteredIds), 'flashcard'));
          yield put(setPreviousFlashcardIds(List(flashcardIds)));
        }
      }   
      if(action && action.callback){

        yield call(action.callback)
      }
      // callback
    }
  } catch (error) {
    console.error("error for onSetFlashcardIds", error)
  }
}

function* endExercise() {
  try {
    const exerciseId = yield select(activeExerciseId);
    if (!exerciseId) return;
    const ExerciseOnCanvas =  yield select(Selectors.getExerciseOnCanvas)
    const allSubtopicsResponse = yield select(flashcardIdsResponse);
    let flashcardIds = List();
  
    allSubtopicsResponse.getIn(['data', 'items']).forEach((subtopic) => {
      flashcardIds = flashcardIds.concat(subtopic.get('flashcards'));
    });
    yield put(setFlashcardIds(flashcardIds.toJS()));
    yield put(setPreviousFlashcardIds(flashcardIds));
  } catch (error) {
    console.error("error for endExercise", error)
  }
}

function* selectExerciseFlashcard(action) {
  try {
    const flashcardId = action.flashcardId;
    const isNavigationVisible = yield select(isNavigationSectionVisible);
    if (yield select(isDeviceRestricted)) {
      yield put(showAlert({ content: <DeviceLimitNotification />, buttons: false }));
      return;
    }
    // if (mobile && portrait) {
    //   yield put(showAlert({ content: <ChangeOrientationNotification />, buttons: false, timer: 1000 }));
    // }
    if (yield select(isMobilePortrait)) {
      //yield put(showAlert({ content: <ChangeOrientationNotification />, buttons: false, timer: 2000 }));
      if(isNavigationVisible === false ){
        yield put(toggleNavigationSection(false));
        yield put(setActiveFlashcardTab(-1));
      }
    }

    yield updateFlashcardUrl(flashcardId);
    yield put(calculateFlashcardIndex(flashcardId));
    yield put(Actions.Studying.setCurrentFlashcard(flashcardId));
  } catch (error) {
    console.error("error for endExercise", error)
  }
}

/**
 * Changes flashcard without showing any notification
 * @param action
 * @returns {IterableIterator<*>}
 */
function* changeFlashcard(action) {
  try {
    const subject = yield select(Selectors.getActiveSubject);
    const userId = yield select(Selectors.getUserId);
    const mediaType = getLastMediaTypeVisited(userId)
    const currentFlashcardId = yield select(carouselCurrentFlashcardId);
    let activeMediaTab = yield select(Selectors.getActiveMediaTab);
    const flashcardId = action.data;
    const mediaTypes = currentFlashcardId && mediaType || activeMediaTab

    if (!flashcardId) return;
    const activeExercise = yield select(activeExerciseId);
    // const mediaType = getLastMediaTypeVisited(userId)
    let newNavigationMode = action.navigationMode || 'default';
    let lastStudyFlashcard = { subject_name: subject.slug, flashcard_id: flashcardId, media_type: activeMediaTab };
    addLastFlashcardToLocalStorage(flashcardId, subject, userId);
    setLastSubjectVisited(subject.id, userId);
    yield updateFlashcardUrl(flashcardId, activeMediaTab, mediaType);
    // End exercise
    const ExerciseOnCanvas = yield select(Selectors.getExerciseOnCanvas)
    if (currentFlashcardId !== flashcardId && activeExercise) {
      yield put(Actions.Studying.endExercise());
      yield take(SET_FLASHCARD_IDS);
    }
    // Update carousel flashcards with the teaching level of the new flashcard
    const teachingLevelId = yield select(getActiveTeachingLevel);
    const carouselTeachingLevel = yield select(carouselTeachingLevelSelector);
    if (carouselTeachingLevel !== teachingLevelId) {
      const flashcardIds = yield calculateFlashcardsByTeachingLevel(subject.id, teachingLevelId);
      yield put(setFlashcardIds(flashcardIds));
      yield put(setCarouselTeachingLevel(teachingLevelId));
      yield put(calculateFlashcardIndex(flashcardId));
    }
    yield put(Actions.Studying.setNavigationMode(newNavigationMode));
    yield put(Actions.Studying.setCurrentFlashcard(flashcardId));
    // yield call(Api.addLastStudyFlashCard, lastStudyFlashcard);
    if (mediaTypes !== "exams" && typeof flashcardId === 'number') {
      let FilterCheckedItems = yield select(listOfFilterCheckedItems);
      let carouselIds = yield select(getListOfFilterItemsIds);
      const flashcardIds = carouselIds.get(mediaTypes);
      const filterMediaItems = FilterCheckedItems.toJS()[mediaTypes]

      let filteredIds = filterResultsListToArrayByTOJS(flashcardIds, mediaTypes, filterMediaItems)

      let subTopicsId = filteredIds.find(item => item.flashcardId == flashcardId)?.subTopicId
      let subTopicData = filteredIds.filter(item => item.subTopicId === subTopicsId);

      let size = subTopicData.size;
      let indexOfFC = subTopicData.findIndex(item => item.flashcardId === flashcardId)
      yield put(updateProgressBarData(size, indexOfFC + 1))

    }
  } catch (error) {
    console.log("error :-  changeFlashcard", error)
  }
}

function* calculateFlashcardsByTeachingLevel(subjectId, teachingLevelId) {
  try {
    let flashcardIdsResp = yield select(flashcardIdsResponse);

    if (flashcardIdsResp.size === 0) return;
    if (flashcardIdsResp.get('loading')) {
      yield take(getFlashcardIdsRoutine.FULFILL);
      flashcardIdsResp = yield select(flashcardIdsResponse);
    }

    let flashcardIds = List();
    flashcardIdsResp.getIn(['data', 'items']).forEach((subtopic) => {
      if (teachingLevelId === subjectId || teachingLevelId === subtopic.get('teachingLevelId')) {
        flashcardIds = flashcardIds.concat(subtopic.get('flashcards'));
      }
    });
    return flashcardIds;
  } catch (error) {
    console.log("error for calculateFlashcardsByTeachingLevel", error)
  }
}

function* onCurrentFlashcardChange(action) {
  try {
    const pathname = appHistory.location.pathname;
    const flashcardIndex = yield select(carouselFlashcardIndex);
    const flashcardIds = yield select(carouselFlashcardIds);
    const revisionIds = yield select(carouselRevisionIds);
    const videoIds = yield select(carouselVideoIds);
    // let currentFlashcardId = flashcardIds.get(flashcardIndex) ? flashcardIds.get(flashcardIndex) : action.flashcardId ;
    let currentFlashcardId = flashcardIds?.[flashcardIndex] ? flashcardIds?.[flashcardIndex] : action.flashcardId ;
    const subject = yield select(Selectors.getActiveSubject);
    let activeMediaTab = yield select(Selectors.getActiveMediaTab);
    const userId = yield select(Selectors.getUserId);
    const  mediaType = getLastMediaTypeVisited(userId) 
    if(mediaType == "revision"){
      currentFlashcardId = revisionIds[flashcardIndex] ? revisionIds[flashcardIndex] : action.flashcardId ;
    }
    if(mediaType == "video"){
      currentFlashcardId = videoIds[flashcardIndex] ? videoIds[flashcardIndex] : action.flashcardId ;
    }

    if(mediaType == 'exams'){
      currentFlashcardId  = action.flashcardId
    }
    addLastFlashcardToLocalStorage(currentFlashcardId, subject, userId);

    const mediaTypes  = mediaType || activeMediaTab
    const exerciseId = yield select(activeExerciseId);
    

    if(mediaTypes!=="exams" && exerciseId) {
      let FilterCheckedItems =  yield select(listOfFilterCheckedItems);
      let carouselIds = yield select(getListOfFilterItemsIds);
      const flashcardIds = carouselIds.get(mediaTypes);
      const filterMediaItems = FilterCheckedItems.toJS()[mediaTypes]
      
      let filteredIds=  filterResultsListToArrayByTOJS(flashcardIds, mediaTypes, filterMediaItems)
      
      let subTopicsId = filteredIds.find(item => item.flashcardId == currentFlashcardId)?.subTopicId
      let subTopicData = filteredIds.filter(item => item.subTopicId === subTopicsId);
    
      let size = subTopicData.size;
      let indexOfFC = subTopicData.findIndex(item => item.flashcardId === currentFlashcardId)
      yield put(updateProgressBarData(size,indexOfFC + 1))

    }


    const activeSubjectId = getLastSubjectVisited(userId);
    const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
    const teachingTag = subjectTeachingLevel[`${activeSubjectId}`]

    let lastStudyFlashcard = {subject_name: subject.slug, flashcard_id: {id:currentFlashcardId, sub_topic:JSON.parse(getExamSubTopicId()),topic:JSON.parse(getExamTopicId()), teachingTag:teachingTag }, media_type:activeMediaTab};
    if(mediaType == 'exams' ? (activeMediaTab === mediaType && !(yield select(Selectors.currentFlashcardId))) : activeMediaTab === mediaType){
      yield put(Actions.Studying.setCurrentFlashcard(currentFlashcardId));
      yield updateFlashcardUrl(currentFlashcardId, activeMediaTab, mediaType);
      let FilterCheckedItems =  yield select(listOfFilterCheckedItems);
      let carouselIds = yield select(getListOfFilterItemsIds);
      const flashcardIds = carouselIds.get(mediaTypes);
      const filterMediaItems = FilterCheckedItems?.toJS()[mediaTypes]
      // console.log(filterMediaItems ,"filterMediaItems", FilterCheckedItems.toJS())
      
      let filteredIds=  filterResultsListToArrayByTOJS(flashcardIds, mediaTypes, filterMediaItems)
      
      let subTopicsId = filteredIds?.find(item => item?.flashcardId == currentFlashcardId)?.subTopicId
      let subTopicData = filteredIds?.filter(item => item?.subTopicId === subTopicsId);
    
      let size = subTopicData?.size || 0;
      let indexOfFC = subTopicData?.findIndex(item => item?.flashcardId === currentFlashcardId) || 0
      yield put(updateProgressBarData(size,indexOfFC + 1))
    }
    if(!(mediaType == 'exams' && getFormExams() == 'exams')){
      const parentIds = yield getParentIds(currentFlashcardId);
      yield put(setActiveNodesAction(currentFlashcardId, parentIds));
      // this action remove for subtopic tile expands stop
      // if(pathname.indexOf("flashcard/topic/") === -1){
      //   yield put(openNodesAction(currentFlashcardId, parentIds, true));
      // }
    }
    // Check if the PC is the same as the study carousel, if not update the PC
    const teachingLevelId = yield select(getActiveTeachingLevel);
    const carouselTeachingLevel = yield select(carouselTeachingLevelSelector);
    if (carouselTeachingLevel !== teachingLevelId) {
      yield put(setActiveTeachingLevel(carouselTeachingLevel));
    }
    
    if(mediaType == 'exams' && getFormExams() !== 'exams' || mediaType !== 'exams')
    yield call(Api.addLastStudyFlashCard, lastStudyFlashcard);
  } catch (error) {
    console.error("error for onCurrentFlashcardChange", error)
  }
}


function* getParentIds(flashcardId) {
  try {
    let structuredFlashcards1 = (yield select(flashcardIdsResponse)).getIn(['data', 'items']);
    let activeMediaTab = yield select(Selectors.getActiveMediaTab);
    let structuredFlashcards = (yield select(carouselFlashcardIdDetails))?.items
    if(activeMediaTab == "revision"){
      // structuredFlashcards = yield select(carouselRevisiondIdDetails)
      if((yield select(carouselRevisiondIdDetails))?.items){
        structuredFlashcards = (yield select(carouselRevisiondIdDetails)).items
      }
    }
    if(activeMediaTab == "video"){
      // structuredFlashcards = yield select(carouselVideoIdDetails)
      if((yield select(carouselVideoIdDetails))?.items){
        structuredFlashcards = (yield select(carouselVideoIdDetails)).items
      }
    }
    
    
    // Getting flashcardIds on refreshing URL with flashcardId 
      if( (!structuredFlashcards || structuredFlashcards.size < 1) ){
        const activeSubject = yield select(Selectors.getActiveSubject);
        let flashcardIds = [];
        let flashcardIdsResponses = []
        yield put(getFlashcardIdsRoutine.trigger({ subjectId: activeSubject.id}));
        flashcardIdsResponses = yield take(getFlashcardIdsRoutine.success);
        let responseData = flashcardIdsResponses.payload.response.items;
          responseData.forEach((subtopic) => {
            flashcardIds = flashcardIds.concat(subtopic.flashcards);
          });
          // yield put(setFlashcardIds(List(flashcardIds)));
          yield put(setFlashcardIds(flashcardIds));
          yield put(setPreviousFlashcardIds(List(flashcardIds)));
          const activeTeachingLevel = yield select(getActiveTeachingLevel);
          yield put(setCarouselTeachingLevel(activeTeachingLevel));
          yield put(calculateFlashcardIndex(flashcardId));
          yield getParentIds(flashcardId)

      }
      const subjectId = yield select(activeSubjectId);
    if(activeMediaTab === 'exams'){
        const getListOfFilterItemsIdstoJS = yield select(getListOfFilterItemsIds);
        let examIdRespose = getListOfFilterItemsIdstoJS.toJS()["exams"];
        const flashcardData = examIdRespose.find(item => {
          return item.flashcarduuId == getExamSubTopicId()+'-'+flashcardId
        })
      
        const subTopic = structuredFlashcards.find(subTopic => { 
            return subTopic.id == parseInt(getExamSubTopicId());
        });
        return [subjectId , subTopic.teachingLevelId , parseInt(getExamTopicId()) , parseInt(getExamSubTopicId())]
      }else{
      const subTopic = structuredFlashcards.find(subTopic => {
        return subTopic?.flashcards?.find(flashcard => {
          return flashcard === flashcardId;
        });
      });
      return [subjectId, subTopic?.teachingLevelId, subTopic?.topicId, subTopic?.id];
    }
  } catch (error) {
    console.error("error for getParentIds", error)
  }
}

function* setFlashcardMeta() {
  const currentFlashcard = yield select(carouselCurrentFlashcard);
  const flashcardId = currentFlashcard.get('id');
  // const requestData = {flashcardId: flashcardId};
  try {
    if(flashcardId){
      // let resps = yield call(Api.getFlashcardLinks, requestData);
      // const isLinks = resps['cards'].length;
      // yield put(Actions.Studying.setLinksSize(isLinks));
      // yield put(Actions.Studying.setLinks(currentFlashcard.get('links')));
    }
  }catch(error){

  }
 
}

function* setActiveSubject(action) {
  try {
    let userId = yield select(Selectors.getUserId);
    if (action.isChanged) {
      yield put(Actions.Studying.setCurrentFlashcard(null));
      
      yield put(Actions.Studying.initializeCarousel(action.flashcardId));

      appHistory.push('', true);   
    }
    const activeSubject = yield select(Selectors.getActiveSubject)
    if(activeSubject?.isNew || activeSubject?.tagging === 'NEW_SUBJECT') {
      const lastSubjectActive = getLastSubjectActive(userId)
      yield put(Actions.Api.getSubjectSyllabus(lastSubjectActive))
    }
  } catch (error) {
    console.error("error for onToggleBodyScroll", error)
  }
}

function* setActiveTeachingForExam(){
  try {
    yield put(Actions.Studying.setCurrentFlashcard(null));
    appHistory.push('', true);
    yield put(Actions.Studying.initializeCarousel(null));
    yield put(changeFlashcardAction(null,"default" ));
  } catch (error) {
    console.error("error for onToggleBodyScroll", error)
  }
}

// Device
function* startUpdateDevice() {
  //todo removed because it caused too much traffic
  return;
  // if (process.env.NODE_ENV === 'development') return;
  // let shouldContinue = true;
  // while (shouldContinue) {
  //   const currentDeviceId = getDevice();
  //   if (!currentDeviceId) {
  //     shouldContinue = false;
  //     continue;
  //   }
  //   try {
  //     yield call(Api.updateDevice, currentDeviceId);
  //     yield call(delay, 1500);
  //   } catch (e) {
  //     shouldContinue = false;
  //     yield addDevice();
  //   }
  // }
}

// function* addDevice() {
//   //todo removed all device related logic
//   return;
//   try {
//     const { deviceId } = yield call(Api.addDevice);
//     saveDevice(deviceId);
//     yield put(Actions.Studying.startUpdateDevice());
//   } catch (e) {
//     if (e === 'DEVICE_LIMIT_REACHED') {
//       yield put(Actions.Studying.enableRestriction());
//     }
//   }
// }

function* addDevice() {
  try {
    const reactDeviceDetect = require("react-device-detect");
    let data = {
      deviceType: reactDeviceDetect.deviceType,
      mobileModel: reactDeviceDetect.mobileModel,
      mobileVendor: reactDeviceDetect.mobileVendor,
      osName: reactDeviceDetect.osName,
      browserName: reactDeviceDetect.browserName,
      device_token: getAccessToken(),
    };
    const { deviceId } = yield call(Api.addDevice, data);
    saveDevice(deviceId);
  } catch (e) {
    if (e === 'DEVICE_LIMIT_REACHED') {
      yield put(toggleDeviceManagerOverlay(true));
      yield put(getLoggedinDevices())
    }
  }
}

function* updateDevice() {
  try {  
    const currentDeviceId = getDevice();
    if(currentDeviceId != null){
      let data = {
        device_token: getAccessToken(),
      };
      yield call(Api.updateDevice, currentDeviceId, data);
    }
  } catch (e) {
    console.error('Could not unregister device', e);
  }
}


export function* registerDevice() {
  //todo removed all device related logic
  //return;
  try {
    const currentDeviceId = getDevice();
    if (currentDeviceId != null) {
      yield updateDevice();
      return;
    }else{
      yield addDevice();
      return;
    }
  } catch (error) {
    console.error("error for onToggleBodyScroll", error)
  }
}

function* unRegisterDevice() {
  //todo removed all device related logic
  //return;
  const currentDeviceId = getDevice();
  try {
    let data = {
      deviceId: currentDeviceId,
      device_token: getAccessToken()
    };
    clearDevice();
    yield call(Api.removeLoggedinDevice, data);
  } catch (e) {
    console.error('Could not unregister device', e);
  }
}

function* enableRestriction() {
  yield put(toggleDeviceManagerOverlay(true));
}

/**
 * Is used mostly for selecting a flashcard by clicking on a preview.
 * Calls changeFlashcard
 * @param action
 * @returns {IterableIterator<*>}
 */
function* selectFlashcard(action) {
  try {
    const flashcardId = action.flashcardId;
    const isNavigationVisible = yield select(isNavigationSectionVisible);
    if (yield select(isDeviceRestricted)) {
      yield put(showAlert({ content: <DeviceLimitNotification />, buttons: false }));
      return;
    }
    if (!(yield select(isMobile))) {
      yield put(changeFlashcardAction(flashcardId, action.navigationMode));
      return;
    }
    if (yield select(isMobilePortrait)) {
      const pathname = appHistory.location.pathname;
      //yield put(showAlert({ content: <ChangeOrientationNotification />, buttons: false, timer: 2000 }));
      if(isNavigationVisible == false && !(pathname.indexOf("/flashcard/desk/notes") !== -1) && action.navigationMode !== "noteSearch"){
        yield put(toggleNavigationSection(false));
        yield put(setActiveFlashcardTab(-1));
      } 
    } else {
      yield put(toggleSubjectsManager(false));
    }
    // Load UI after one second
    if(action.isEmptyScreen){  
      yield take(["WINDOW_RESIZE_LISTENER"])
      const isLandscapeVal = yield select(isLandscape);
      if (isLandscapeVal){
        yield delay(1000)
        yield put(setToolboxOpenSection(action.deskType));
        yield put(toggleOverlay(false));
      }
    }
    yield put(changeFlashcardAction(flashcardId, action.navigationMode));
  } catch (error) {
    console.error("error for selectFlashcard", error)
  }
}
function* selectExams(action){
  try {
    const flashcardId = action.flashcardId;
    const from = action.from;
    const size = action.size;
    const index = action.index
    if (yield select(isDeviceRestricted)) {
      yield put(showAlert({ content: <DeviceLimitNotification />, buttons: false }));
      return;
    }
    setFormExams(from)
    yield updateFlashcardUrl(flashcardId, 'exams');
    yield put(updateExamsList(size,index))
    // yield put(changeFlashcardAction(flashcardId, 'exams'));
    const isNavigationVisible = yield select(isNavigationSectionVisible);
    if (yield select(isMobilePortrait)) {
      const pathname = appHistory.location.pathname;
      //yield put(showAlert({ content: <ChangeOrientationNotification />, buttons: false, timer: 2000 }));
      if(isNavigationVisible == false && !(pathname.indexOf("/flashcard/desk/notes") !== -1) && action.navigationMode !== "noteSearch"){
        yield put(toggleNavigationSection(false));
        yield put(setActiveFlashcardTab(-1));
      } 
    } else {
      yield put(toggleSubjectsManager(false));
    }
  } catch (error) {
    console.error("error for selectExams", error)
  }
}

// function* getAnswers(action) {
//   let activeMediaTab = yield select(Selectors.getActiveMediaTab);
//   if (!action.data) {
//     // yield put(Actions.Desk.setSmartFolderType());
//     return;
//   }
//   try {
//     if(activeMediaTab=='flashcard' && activeMediaTab){
//       const response = yield call(Api.getAnswers, action.data);
//       yield put(setNodeAnswers(response.map(answer => ({
//         id: action.data,
//         correct: answer.value,
//         answered: true
//       }))));
//       yield put(Actions.Api.getAnswersSucceeded({ data: response }));
//     }
//   } catch (e) {
//     yield put(Actions.Api.getAnswersFailed(e));
//   }
// }

// Get Last Study Flashcard
function* getLastStudyFlashcard() {
  try {
    yield put(Actions.Studying.setInProgressLastStudyFlashcard(true));
    let response = yield call(Api.getLastStudyFlashCard);
    yield put(Actions.Studying.getLastStudyFlashcardSucceeded(response));
    yield put(Actions.Studying.setInProgressLastStudyFlashcard(false));

  } catch (e) {
    yield put(Actions.Studying.getLastStudyFlashcardFailed(e));
  }
}

function* setMarks(action) {
  // const key = action.payload && (action.payload.key || action.payload.id);
  try {
    let requestData = action.data;
    const currentFlashcard = yield select(Selectors.Studying.getFlashcardById, requestData.id);
    const parentTopicId = currentFlashcard.get('parentsData').find(item => item.get('type') === 'TOPIC').get('id');
    const parentSubTopicId = currentFlashcard.get('parentsData').find(item => item.get('type') === 'SUB_TOPIC').get('id');
    const activeSubjectId = yield select(Selectors.activeSubjectId);
    let response = yield call(Api.addMarkAsRead, requestData);
    yield put(Actions.Api.setMarksSucceeded({ data: response }));
    yield put(setNodeMark([{id: parseInt(response.flashcardId), markValue: response.markValue}]));
    let quickStatus = yield select((state) => quickStatusById(state, activeSubjectId));
    let topicStatus = yield select((state) => statusById(state, parentTopicId));
    let subtopicStatus = yield select((state) => statusById(state, parentSubTopicId));
    yield put(setQuickNodeStatus({
      id: activeSubjectId,
      flashcard: quickStatus.get("flashcard"),
      revision: {
        ...quickStatus.get("revision"),
        nodeWatchRead: response.markValue ? quickStatus.get("revision").get("nodeWatchRead") + 1 : quickStatus.get("revision").get("nodeWatchRead") - 1,
        total: quickStatus.get("revision").get("total")
      },
      video: quickStatus.get("video"),
      exam: quickStatus.get("exam")
    }));
    yield put(setNodeStatus([
      {
        id: parentTopicId,
        flashcard: topicStatus.get("flashcard"),
        revision: {
          ...topicStatus.get("revision").toJS(),
          nodeWatchRead: response.markValue ? topicStatus.get("revision").get("nodeWatchRead") + 1 : topicStatus.get("revision").get("nodeWatchRead") - 1,
          total: topicStatus.get("revision").get("total")
        },
        video: topicStatus.get("video"),
        exam:topicStatus.get("exam")
      }])); 
      yield put(setNodeStatus([
      {
        id: parentSubTopicId,
        flashcard: subtopicStatus.get("flashcard"),
        revision: {
          ...subtopicStatus.get("revision").toJS(),
          nodeWatchRead: response.markValue ? subtopicStatus.get("revision").get("nodeWatchRead") + 1 : subtopicStatus.get("revision").get("nodeWatchRead") - 1,
          total: subtopicStatus.get("revision").get("total")
        },
        exam:subtopicStatus.get("exam"),
        video: subtopicStatus.get("video")
      }])); 
      // const pathname = appHistory.location.pathname;
      // const flashcardIndex = yield select(carouselFlashcardIndex);
      // yield put(Actions.Api.updateMarkByFlashcardId({flashcardId: response.flashcardId, markValue: response.markValue, pathname: pathname, flashcardIndex: flashcardIndex}))
  } catch (error) {
    yield put(Actions.Api.setMarksFailed(error));
  } 
}
function* setExamsStatus(action){
  try {
    const currentFlashcard = yield select(Selectors.Studying.getFlashcardById, action.data.flashcardId);
    const questionId = currentFlashcard.get('questionId');
    const segmentId = currentFlashcard.get('segmentId');
    const experienceId = currentFlashcard.get('experienceId')
    const activeSubjectId = yield select(Selectors.activeSubjectId);
    const answer = examAnswerStatistics(action.data.answer)
    const paperId = currentFlashcard.getIn(['experienceData','paper_uuid'])
    let quickStatus = yield select((state) => quickStatusById(state, activeSubjectId));
    let examMediaStatus = yield select((state) => examStatusById(state, paperId));
    let parentTopicId = currentFlashcard.get('parentsData').map(items => items.get('parentsData').find(item => item.get('type') === 'TOPIC').get('id'));
    let parentSubTopicId = currentFlashcard.get('parentsData').map(items => items.get('parentsData').find(item => item.get('type') === 'SUB_TOPIC').get('id'));
    const frqPreviousValue = action.data.prevAnswer === 0 ? "incorrect" : (action.data.prevAnswer  === 1 ? "correct" : action.data.prevAnswer === 2 && "neither");
    const frqcurrentValue = action.data.answer === 0 ? "incorrect" : (action.data.answer === 1 ? "correct" : "neither");

    const mcqPreviousValue = action.data.prevAnswer === 1 ? "incorrect" : action.data.prevAnswer !==  null && "correct";
    const mcqcurrentValue = action.data.answer === 1 ? "incorrect" : "correct";
    let finalAnswer;
    let finalBoolAnswer;

    const data  ={
      "user_response":{
        "segment_id": segmentId,
        "question_id":questionId,
        "experience_id": experienceId,
        "attr_associations":[
          {
              "name":"confidence_level",
              "type_c":"string",
              "value":answer
          }
      ]
      }
    }

    let response = yield call(Api.useResponseSubmit,data)
    if(action.data.answer === -1 ){
      finalAnswer = null;
      finalBoolAnswer =  false;
    }else{
      finalAnswer = action.data.answer;
      finalBoolAnswer =  true;
    }

    let calculatedFreeResponseQuickStatus = {
      total: quickStatus.getIn(["exam","freeResponse"]).get("total"),
      correct: quickStatus.getIn(["exam","freeResponse"]).get("correct"),
      neither: quickStatus.getIn(["exam","freeResponse"]).get("neither"),
      incorrect: quickStatus.getIn(["exam","freeResponse"]).get("incorrect"),
    }


    let calculatedMCQQuickStatus = {
      total: quickStatus.getIn(["exam","msqTotal"]).get("total"),
      correct: quickStatus.getIn(["exam","msqTotal"]).get("correct"),
      incorrect: quickStatus.getIn(["exam","msqTotal"]).get("incorrect"),
    }

    let topicsStatus = [];
    let subTopicsStatus = [];
    for (let item of parentTopicId) {
      const topicStatus = yield select((state) => statusById(state, item));
      topicsStatus.push({id:item ,exam:topicStatus})
    }

    for (let item of parentSubTopicId) {
      const subTopicStatus = yield select((state) => statusById(state, item));
      subTopicsStatus.push({id:item ,exam:subTopicStatus})
    }
    subTopicsStatus = [...new Map(subTopicsStatus.map(item => [item['id'], item])).values()] 
    topicsStatus = [...new Map(topicsStatus.map(item => [item['id'], item])).values()] 
    let calculatedTopicFreeResponseStatus, calculatedSubTopicFreeResponseStatus;

    for (let item of topicsStatus) {
      calculatedTopicFreeResponseStatus = {
        total: item.exam.get('exam').get("freeResponse").get("total"),
        correct:  item.exam.get('exam').get("freeResponse").get("correct"),
        neither:  item.exam.get('exam').get("freeResponse").get("neither"),
        incorrect:  item.exam.get('exam').get("freeResponse").get("incorrect"),
      }
      let calculatedTopicMCQStatus = {
        total:  item.exam.get('exam').get("msqTotal").get("total"),
        correct:  item.exam.get('exam').get("msqTotal").get("correct"),
        incorrect:  item.exam.get('exam').get("msqTotal").get("incorrect"),
      }
      if(action.data.type === 'frq'){
        if(action.data.answer != -1){
          calculatedTopicFreeResponseStatus[frqcurrentValue] += 1
        }
        if(action.data.prevAnswer !== "no-answer" || action.data.answer == -1){
          calculatedTopicFreeResponseStatus[frqPreviousValue] -= 1
        }
      }else{
        calculatedTopicMCQStatus[mcqcurrentValue] += 1;
        calculatedTopicMCQStatus[mcqPreviousValue] -= 1;
      }
      const exam = {
        freeResponse:calculatedTopicFreeResponseStatus,
        msqTotal:calculatedTopicMCQStatus
      }
      yield put(setNodeStatus([
        {
          id: item.id,
          flashcard: item.exam.get("flashcard"),
          revision:  item.exam.get("revision"),
          video:  item.exam.get("video"),
          exam:  exam,
        }])); 
      

    }

    for (let item of subTopicsStatus) {
      calculatedSubTopicFreeResponseStatus = {
        total: item.exam.get('exam').get("freeResponse").get("total"),
        correct:  item.exam.get('exam').get("freeResponse").get("correct"),
        neither:  item.exam.get('exam').get("freeResponse").get("neither"),
        incorrect:  item.exam.get('exam').get("freeResponse").get("incorrect"),
      }
      let calculatedSubTopicMCQStatus = {
        total:  item.exam.get('exam').get("msqTotal").get("total"),
        correct:  item.exam.get('exam').get("msqTotal").get("correct"),
        incorrect:  item.exam.get('exam').get("msqTotal").get("incorrect"),
      }
      if(action.data.type === 'frq'){
        if(action.data.answer != -1){
          calculatedSubTopicFreeResponseStatus[frqcurrentValue] += 1
        }
        if(action.data.prevAnswer !== "no-answer" || action.data.answer == -1){
          calculatedSubTopicFreeResponseStatus[frqPreviousValue] -= 1
        }
      }else{
        calculatedSubTopicMCQStatus[mcqcurrentValue] += 1;
        calculatedSubTopicMCQStatus[mcqPreviousValue] -= 1;
      }
      const exam = {
        freeResponse:calculatedSubTopicFreeResponseStatus,
        msqTotal:calculatedSubTopicMCQStatus
      }
      yield put(setNodeStatus([
        {
          id: item.id,
          flashcard: item.exam.get("flashcard"),
          revision:  item.exam.get("revision"),
          video:  item.exam.get("video"),
          exam:  exam,
        }])); 
    }
    if(action.data.type === 'frq'){
    if(action.data.answer != -1){
      calculatedFreeResponseQuickStatus[frqcurrentValue] += 1
    }
    if(action.data.prevAnswer !== "no-answer"){
      calculatedFreeResponseQuickStatus[frqPreviousValue] -= 1
    }
  }else{
    calculatedMCQQuickStatus[mcqcurrentValue] += 1;
    calculatedMCQQuickStatus[mcqPreviousValue] -= 1;
  }
    if(action.data.type === 'frq' && getFormExams() === "exams"){
      yield put(Actions.Desk.questionIdUpdateStats(action.data.flashcardId, finalAnswer))
    }
    
      const quickStatu = {
        freeResponse:calculatedFreeResponseQuickStatus,
        msqTotal:calculatedMCQQuickStatus
      }

      yield put(setQuickNodeStatus({
        id: activeSubjectId,
        flashcard: quickStatus.get("flashcard"),
        revision: quickStatus.get("revision"),
        video: quickStatus.get("video"),
        exam: quickStatu,
      }));
      let calculatedFreeResponseExamStatus = {
        total: examMediaStatus.get("freeResponse").get("total"),
        correct: examMediaStatus.get("freeResponse").get("correct"),
        neither: examMediaStatus.get("freeResponse").get("neither"),
        incorrect: examMediaStatus.get("freeResponse").get("incorrect"),
      }
    
      let calculatedMCQExamStatus = {
        total: examMediaStatus.get('mcqResponse').get("total"),
        correct: examMediaStatus.get('mcqResponse').get("correct"),
        incorrect: examMediaStatus.get('mcqResponse').get("incorrect"),
      }

      if(action.data.type === 'frq'){
        if(action.data.answer != -1){
          calculatedFreeResponseExamStatus[frqcurrentValue] += 1
        }
        if(action.data.prevAnswer !== "no-answer" || action.data.answer == -1){
          calculatedFreeResponseExamStatus[frqPreviousValue] -= 1
        }
      }else{
        calculatedMCQExamStatus[mcqcurrentValue] += 1
        calculatedMCQExamStatus[mcqPreviousValue] -= 1
      }
      

      yield put(setExamNodeStatus([
        {
          id: paperId,
          freeResponse: calculatedFreeResponseExamStatus,
          mcqResponse: calculatedMCQExamStatus
        }
      ]));
    
    yield put(Actions.Api.setExamStatusSucceeded({ data: response }));
    yield put(setExamNodeAnswers([{id: action.data.flashcardId, correct: finalAnswer, answered: finalBoolAnswer}]));

  } catch (error) {
    yield put(Actions.Api.setExamStatusFailed(error));
    console.error('Error in setExamsStatus:', error)
  } 

}

function* setVideoPlayed(action) {
  // const key = action.payload && (action.payload.key || action.payload.id);
  try {
    // let requestData = action.data;
    let lastPlayDuration= action.data.lastPlayDuration
    let totalWatched
    let  d = Number(lastPlayDuration);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var hDisplay = h > 0 ? h : 0;
      var mDisplay = m > 0 ? m > 9 ? m : '0' + m : '00';
      var sDisplay = s > 0 ? s > 9 ? s : '0' + s : '00';
      if(hDisplay>0){
        totalWatched = hDisplay + ':'+mDisplay +':' +sDisplay; 
      }else{
        totalWatched = mDisplay +':' +sDisplay; 
      }
      
      let partialValue;    
      // if(d < action.data.totalTime){
      //   partialValue = 1
      // }else{
      //   partialValue = 0
      // }
      if(d > 0){
        if(action.data.markValue === 1){
          partialValue = 0
        }else{
          partialValue = 1
        }
      }else if(d === 0){
        partialValue = 0
      }
      yield put(setNodeWatched([{id: parseInt(action.data.id), watchedValue: action.data.markValue, partialValue: partialValue}]));
      yield put(setVideoProgress([{id: parseInt(action.data.id), totalTime: action.data.totalTime, currentTime: d}]));
      yield put(updateVideoProgressValueOfTitlesByFlashcardId([{cardId: parseInt(action.data.id), markValue: action.data.markValue, currentTime: d}]))
      let response = yield call(Api.addVideoPlayed, {id: action.data.id, markValue: action.data.markValue, activeTab:'video', lastPlayDuration: totalWatched});

    yield put(Actions.Api.setVideoPlayedSucceeded({ data: response }));
     
  } catch (error) {
    yield put(Actions.Api.setVideoPlayedFailed(error));
    console.error("error for setVideoPlayed", error)
  } 
}

function* setVideoPlayedStatus(action) {
  try {
    yield put(setVideoPlayingStatus([{id: parseInt(action.data.id),  playing: action.data.playing}]));
  } catch (error) {
    console.error("error for setVideoPlayedStatus", error)
  }
}

function* onSetWatchedSatus(action) {
  try {
    const activeSubjectId = yield select(Selectors.activeSubjectId);
    const currentFlashcard = yield select(Selectors.Studying.getFlashcardById, action.data.id);
    const parentTopicId = currentFlashcard.get('parentsData').find(item => item.get('type') === 'TOPIC').get('id');
    const parentSubTopicId = currentFlashcard.get('parentsData').find(item => item.get('type') === 'SUB_TOPIC').get('id');
    let quickStatus = yield select((state) => quickStatusById(state, activeSubjectId));
    let topicStatus = yield select((state) => statusById(state, parentTopicId));
    let subtopicStatus = yield select((state) => statusById(state, parentSubTopicId));

    let calculatedQuickVideoStatus = {
      total: quickStatus.get("video").get("total"),
      nodeWatchRead: quickStatus.get("video").get("nodeWatchRead")
  }
    let calculatedTopicVideoStatus = {
        ...topicStatus?.get('video')?.toJS(),
        total: topicStatus.get("video").get("total"),
        nodeWatchRead: topicStatus.get("video").get("nodeWatchRead")
    }
    let calculatedSubtopicVideoStatus = {
      ...subtopicStatus?.get('video')?.toJS(),
      total: subtopicStatus.get("video").get("total"),
      nodeWatchRead: subtopicStatus.get("video").get("nodeWatchRead")
    }
    if(action.data.played == 1){
      calculatedQuickVideoStatus['nodeWatchRead'] += 1
      calculatedTopicVideoStatus['nodeWatchRead'] += 1
      calculatedSubtopicVideoStatus['nodeWatchRead'] += 1
      // yield put(setNodeWatched([{id: parseInt(action.data.id), watchedValue: 1, partialValue: 0}]));
    }
    if(action.data.played == 0){
      calculatedQuickVideoStatus['nodeWatchRead'] -= 1
      calculatedTopicVideoStatus['nodeWatchRead'] -= 1
      calculatedSubtopicVideoStatus['nodeWatchRead'] -= 1
      // yield put(setNodeWatched([{id: parseInt(action.data.id), watchedValue: 0, partialValue: 1 }]));
    }
    yield put(setQuickNodeStatus({
      id: activeSubjectId,
      flashcard: quickStatus.get("flashcard"),
      revision: quickStatus.get("revision"),
      video: calculatedQuickVideoStatus,
      exam: quickStatus.get("exam")
    }));
    yield put(setNodeStatus([
    {
      id: parentTopicId,
      flashcard: topicStatus.get("flashcard"),
      revision: topicStatus.get("revision"),
      video: calculatedTopicVideoStatus,
      exam:topicStatus.get('exam')
    }])); 
    yield put(setNodeStatus([
    {
      id: parentSubTopicId,
      flashcard: subtopicStatus.get("flashcard"),
      revision: subtopicStatus.get("revision"),
      video: calculatedSubtopicVideoStatus,
      exam:topicStatus.get('exam')
    }])); 
  } catch (error) {
    console.error("error for onSetWatchedSatus", error)
  }
}
