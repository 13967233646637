import {
  ADD_VIEWED_EXTERNAL_EXERCISE, SET_EXERCISE_DATA,
  SET_EXTERNAL_EXERCISES,
  SET_VIEWED_EXTERNAL_EXERCISES
} from 'actions/exercises/exercisesActionTypes';
import {fromJS, List, Map} from 'immutable';

export default (state = Map(), action) => {
  switch (action.type) {
    case SET_EXTERNAL_EXERCISES:
      return state.set('external', fromJS(action.data));
    case SET_VIEWED_EXTERNAL_EXERCISES:
      return state.set('viewed', fromJS(action.data));
    case ADD_VIEWED_EXTERNAL_EXERCISE: {
      let viewed = state.get('viewed', List());
      return state.set('viewed', viewed.push(action.exerciseId));
    }
    case SET_EXERCISE_DATA:
      return state.set(action.exerciseId, action.data);
    default:
      return state;
  }
};
