import React from 'react';
import classNames from 'classnames';
import { disableMcqExamSubject, getLastMediaTypeVisited, returnFinalExams } from 'helpers';
import { ReadIcon } from 'icons/CardIcons';
import { FreeResponse, MultipleChoice } from 'icons/HeaderIcons';
import { useSelector } from 'react-redux';
import { getActiveSubject } from 'selectors/subject/subjectSelector';
import { getUserId } from 'selectors/user'
import StudyStatsBarsContainer from 'v2/components/topics-tree/StudyStatsBarsContainer';
import LeftActiveTile from 'v2/components/topics-tree/LeftActiveTile';

type PaperTileProps = {
    activeExamsId: string;
    currentFlashcard: any;
    data: any;
    examId: string;
    onClick: (argA: React.MouseEvent<HTMLElement>, argB: any, argC: string) => void;
}

const PaperTile: React.FC<PaperTileProps> = ({ activeExamsId, currentFlashcard, data, examId, onClick }) => {
    const activeSubject = useSelector(getActiveSubject);
    const userId = useSelector(getUserId);
    const paperTitle = data.paperTitle;
    const mediaType = getLastMediaTypeVisited(userId);
    const isActiveTile = () => examId === activeExamsId && mediaType === 'exams' && data.uId === currentFlashcard?.experienceData?.paper_uuid;

    const statusBar = {
        exams: data?.examBox?.freeResponse,
        mcq: data?.examBox?.msqTotal
    }
    const renderPaperType = (paperType: string) => {
        if (disableMcqExamSubject(activeSubject.id) && paperType == 'Paper 1') {
            return 'Paper 2';
        } else {
            return paperType;
        }
    }

    const { examsStatus, examsStatusGraph, mcqStatusGraph, mcqStatus } = returnFinalExams(statusBar);
    const isRead = renderPaperType(paperTitle) === 'Paper 1' ? mcqStatus.total === mcqStatus.correct : examsStatus.total === examsStatus.correct;
    
    return <div 
        className={classNames('relative pt-2.5 px-3.5 pb-2 mt-3.5 select-none paper-tile', { 'bg-#E5ECF2 isActiveTile': isActiveTile(), 'bg-white': !isActiveTile() })}
        onClick={(e: React.MouseEvent<HTMLElement>) => onClick(e, data, "EXTEND")}>
        {isActiveTile() && <LeftActiveTile isExercise={false} width='3px' />}
        <div className='font-bold text-#2D3838 paper-tile-text'>{paperTitle}</div>
        <div className='flex pt-2.5 paper-tile-meta-info'>
            <div className='heading'>
                <div className='font-bold number'>{data.questionsCount} {(isRead && data.questionsCount !== 0) && <ReadIcon />}</div>
                <div className='flex items-center mt-0.5'>
                    <div className='icon mr-1'>
                        {renderPaperType(paperTitle) === 'Paper 1' ? <MultipleChoice /> : <FreeResponse />}
                    </div>
                    <div className='title text-#919191 font-bold'>
                        {renderPaperType(paperTitle) === 'Paper 1' ? "Multiple choice" : "Free response"}
                    </div>
                </div>
            </div>
            <StudyStatsBarsContainer statusBarData={renderPaperType(paperTitle) === 'Paper 1' ? mcqStatusGraph : examsStatusGraph} isActive={false} isSubtopic={false} mediaType={'exams'} />
        </div>
    </div>
}

export default PaperTile