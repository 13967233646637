import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import htmlToReact from 'html-to-react';
import DOMConverter from 'html2json';
import { activeSearchResult, activeSearchTerm, currentFlashcardId, getFlashcardById, isMobile, isPortrait, pendingSVGsSelector, selectedSide, windowHeight, windowWidth, zoomFactor } from 'selectors';
import MarkText from 'v2/ui-components/MarkText';
import Renderer from './Renderer';
import SVGObject from 'v2/components/studying/renderer/SVGObject';
import MathjaxFormula from 'v2/components/studying/renderer/MathjaxFormula';
import { getFlashcardContent } from 'actions/api';
import { debounce } from 'lodash';
import usePrevious from 'hooks/usePrevious';
import { showNotification } from 'actions/notification';
import './Flipper.scss';

type RendererContainerProps = {
    flashcardId: string | number;
    className?: string;
    canEditExercise: boolean;
    responsive: boolean;
    isActiveZoom?: boolean;
    parentClick?: (val: any) => void;
    onActionFlashcard?: (actionKey: string, value: any, callback:() => void, canFlip: boolean) => void;
    contentDelay?: number;
}  
const CM_TO_PX_CONST = 37.79;
const htmlToReactParser = new htmlToReact.Parser();
const processNodeDefinitions = new htmlToReact.ProcessNodeDefinitions(React);
const isValidNode = function () {
    return true;
};
const processingInstructions = [
    {
      shouldProcessNode: function (node: any) {
        return node.name === 'object';
      },
      processNode: function (node: any) {
        return (
          <SVGObject mediaId={parseInt(node.attribs['alt'])}/>
        );
      }
    }, {
      shouldProcessNode: function (node: any) {
        return node.name === 'formula';
      },
      processNode: function (node: any, children: any) {
        const formula = children[0];
        return <MathjaxFormula>{'###' + formula + '###'}</MathjaxFormula>;
      }
    }, {
      // Anything else
      shouldProcessNode: function () {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode
}];
const RendererContainer: React.FC<RendererContainerProps> = ({flashcardId, className, canEditExercise, responsive, isActiveZoom, parentClick, onActionFlashcard, contentDelay = 300}) => {
    const dispatch = useDispatch()
    const [hasZoom, setZoom] = useState(false)
    const [showNotificatiion, setNotification] = useState(false)
    const flashcardContent = useSelector((state) => getFlashcardById(state, flashcardId))
    const zFactor = useSelector(zoomFactor)
    const hasMobile = useSelector(isMobile)
    const hasPortrait = useSelector(isPortrait)
    const currentId = useSelector(currentFlashcardId)
    const activeSearch = useSelector(activeSearchTerm)
    const activeSearchResults = useSelector(activeSearchResult)
    const pendingSVGs = useSelector(pendingSVGsSelector).toJS()
    const wWidth = useSelector(windowWidth)
    const wHeight = useSelector(windowHeight)
    const selectSide= useSelector((state) => selectedSide(state, flashcardId))
    const isCurrentFlashcard = currentId === flashcardId
    const prevFlashcardId = usePrevious(flashcardId)
    const prevCurrentId = usePrevious(currentId)
    const prevSelectedSide = usePrevious(selectSide)

    useEffect(() => {
        if (!flashcardId) return;
        getFlashcardContents();
        return () => {
            getFlashcardContents.cancel()
        }
    }, [])

    useEffect(() => {
        if (flashcardId !== prevFlashcardId && flashcardId) {
            getFlashcardContents();
          }
      
          if ((currentId !== prevCurrentId && showNotificatiion) || (selectSide && selectSide !== prevSelectedSide) || (flashcardId !== prevFlashcardId && flashcardId)) {
            setNotification(false)
          }
          if (selectSide || (flashcardId !== prevFlashcardId && flashcardId)) {
            performXXLCardCheck()
          }
    }, [currentId, selectSide, flashcardId])

    const getFlashcardContents = debounce(() => {
        flashcardId && dispatch(getFlashcardContent(flashcardId, 'flashcard'))
    }, contentDelay);

    const performXXLCardCheck = () => {
        if (showNotificatiion) return;
        if (isCurrentFlashcard) {
          const cardType = flashcardContent.getIn(['outsideTemplate', 'size']);
          if (cardType === 'XXL') {
            if(selectSide % 2 === 0){
              dispatch(showNotification({type: 'xxlCard'}))
              setNotification(true)
            }  
          }
        }
    }

    const computeSideDimensions = () => {
        let widthDimension;
        let heightDimension;
        if ((hasMobile && !hasPortrait) || responsive) {
            return {};
        }
        if(wWidth > 319 && wWidth < 501){
            heightDimension = (wWidth - 12)/1.5 + 'px';
        }else if(wWidth > 500 && wWidth < 649){
            heightDimension = (wWidth - 12)/1.5 + 'px';
        }else if(wWidth   > 650 && wWidth < 1200 &&  (wWidth > 775)){
            if(wWidth < 1070 && wWidth > 1023){
                widthDimension = 625;
                heightDimension = (widthDimension + 12)/1.5+ 'px';
                widthDimension = 580 + 'px';
            }else{
                widthDimension = 625;
                heightDimension = (widthDimension + 12)/1.5+ 'px';
                widthDimension = 625 + 'px';
            }
        }else if(wHeight >= 775 && wHeight <= 792){ 
            widthDimension = 580;
            heightDimension = (widthDimension + 12)/1.5+ 'px';
            widthDimension = 580 + 'px';
        }else if(wHeight >= 600 && wHeight <= 775){ 
            widthDimension = 515;
            heightDimension = (widthDimension + 12)/1.5+ 'px';
            widthDimension = 515 + 'px';
        }
        else if(wHeight < 600){
            widthDimension = 515;
            heightDimension = (widthDimension + 12)/1.5+ 'px';
            widthDimension = 515 + 'px';
        }
        else{
            widthDimension = (600 * zFactor) + 'px';
            heightDimension = (400 * zFactor) + 'px';
        }
        return {
            width: widthDimension,
            height: heightDimension,
        }
    }
    const showZoom = (val: boolean) => {	
        setZoom(val)
    }
    
    const getTemplateSides = (flashcardContent: any) => {
        const sides = flashcardContent.getIn(['outsideTemplate', 'sides'], null);
        if (sides === null) return null;
        return sides.map((item: any) => {
            return item.get('id');
        });
    }

    const generateHTML = (el: any) => {
        let element = el.toJS();
        let elPos = element.dataAttributes.position;
        let elSize = element.dataAttributes.size;
        element.attr.style = `
            position: absolute; 
            top: ${elPos.top}px; 
            left: ${elPos.left}px; 
            width: ${elSize.width}px; 
            height: ${elSize.height}px;
        `;

        let verticalAlign = element.dataAttributes.verticalAlign || 'none';
        if (element.attr.class) element.attr.class += ' ';
        element.attr.class += ` ql-editor align-${verticalAlign}`;
        if (element.insideElementAttr && element.insideElementAttr.name) {
            let insideElementClassName = element.insideElementAttr.name.toLowerCase()
                .replace(/\W+/g, '-')
                .replace(/^-+|-+$/g, '');
            element.attr.class += ' ' + insideElementClassName;
        }

        let json2htmlObj: any = {
            node: 'root',
            child: [element]
        };

        return DOMConverter.json2html(json2htmlObj);
    }
    const generateDom = (selectedSide: any) => {
        if (!selectedSide || !flashcardContent.get('content')) return;
        let htmlString = '';
        let contentElements;
        const sideData = flashcardContent.get('content').filter((side: any) => {
            return side.get('sideId') === selectedSide;
        }).first();
        if (!sideData) return;

        try {
            contentElements = sideData.get('content');
        }
        catch (e) {
            console.error('content is not a valid JSON!');
        }

        if (!contentElements || contentElements.length === 0) return React.createElement('div');

        contentElements.forEach((element: any) => {
            htmlString += generateHTML(element);
        });

        return htmlToReactParser.parseWithInstructions(htmlString, isValidNode, processingInstructions);
    } 
    const getSizes = (outsideTemplate:any , selectedSide: any) => {
        if (!outsideTemplate || selectedSide === null) return {width: 1, height: 1};
    
        const sideTemplateData = outsideTemplate.get('sides').filter((side:any) => side.get('id') === selectedSide).first();
    
        if (!sideTemplateData) return {width: 2, height: 2};
        const height = sideTemplateData.get('insideCanvasHeight') * CM_TO_PX_CONST;
        const width = sideTemplateData.get('insideCanvasWidth') * CM_TO_PX_CONST;
    
        return {
          height, width
        };
    }

    const renderSide = (side: any, index: number, isCube = false) => {
        const sideId = side.get('sideId');
        const sideType = index === 0 ? 'front' : 'back';
        let markSelectedIndex;
        
        if (activeSearchResults && index === activeSearchResults.side && flashcardId === activeSearchResults.flashcardId) {
            markSelectedIndex = activeSearchResults.searchIndex;
        }
        const shouldMark = isCurrentFlashcard && activeSearch && sideId === selectSide && pendingSVGs.length === 0;
        return <MarkText className={isCube ? 'cube-side-renderer' : sideType}
        shouldMark={shouldMark}
        markType={'span'}
        selectedIndex={markSelectedIndex} key={`${flashcardId}-${side}`}>
        <Renderer
          parentClick={parentClick}
          key={`${flashcardId}-${side}`}
          currentFlashcardId={currentId}
          hasZoom={hasZoom}
          isMobile={hasMobile}
          width={getSizes(flashcardContent.get('outsideTemplate'), sideId).width}
          height={getSizes(flashcardContent.get('outsideTemplate'), sideId).height}
          elements={generateDom(sideId)}
          selectedSide={selectSide}
          onActionFlashcard={onActionFlashcard}
          listFlashcardId ={flashcardId}
          canEditExercise={canEditExercise}
          isActiveZoom={isActiveZoom}
        />
      </MarkText>
    }
    const render2Side = (flashcardContent: any, sideIndex: number) => {
        const isFlipped = flashcardContent && sideIndex !== 0;
        return <div className={'flip-container ' + (isFlipped && ' flip')}>
            <div className='flipper'>
                {flashcardContent && flashcardContent.map((side: any, index: number) =>
                    renderSide(side, index)
                )}
            </div> 
        </div>
    }
    const render3Side = (flashcardContent: any, sideIndex: number) => {
        const classNameSide = [
            'bottom-side',
            'top-side',
            'front-side',
        ];
        return <div className='cube-wrapper'>
            <div className={
            (sideIndex === 0 ? 'show-cube-bottom' : sideIndex === 1 ? 'show-cube-front' : sideIndex === 2 ? 'show-cube-top' : '') + ' cube-container'
            }>
            {flashcardContent?.map((side: any, index: number) => <div key={index + '-cube-side'} className={'cube-side-face ' + classNameSide[index]}>
                    {renderSide(side, index, true)}
                </div>)}
            </div>
        </div>
    }

    const sideIds = flashcardContent.get('content') && getTemplateSides(flashcardContent);
    const sideIndex = sideIds && sideIds.indexOf(selectSide);

    let content;
    if (flashcardContent.get('content') && (flashcardContent.get('content').size === 1 || flashcardContent.get('content').size === 2)) {
        content = render2Side(flashcardContent?.get('content'), sideIndex);
    } else {
        content = render3Side(flashcardContent?.get('content'), sideIndex);
    }
    
    if (flashcardContent.size == 0) {
        if (!flashcardId) return null;
        return (
          <div style={computeSideDimensions()}
            className={`flashcard-container placeholder-card ${className}`}>
            <div className='content'/>
          </div>
        );
    }
    if (!selectSide) return null;

    return <div style={computeSideDimensions()}
        className={`flashcard-container ${className} ${!canEditExercise && 'no-edit-execrise'} flashcard-id-${flashcardId}`}
        onMouseOver={() => showZoom(true)}
        onMouseLeave={() => showZoom(false)}
    >
        <div className={'content'}>
            {content}
        </div>
    </div>
}

export default RendererContainer;