import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exerciseAnswersResponse, exercisePreviewsResponse } from 'selectors/api/apiSelector';
import { execriseFilterItemSize, getActiveMediaTab, isNavigationSectionVisible } from 'selectors/navigation/navigationSelector';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import { StudyButtonIcon } from 'icons/MediaIcons';
import { activeSubjectId, getActiveSubject, subjectsTeachingLevel } from 'selectors/subject/subjectSelector';
import { addBackFlashcardToLocalStorage, filterResultsListToArray, setLastMediaTypeVisited, fcAssessments } from 'v2/helpers';
import { openShortcutMenu, setActiveFlashcardTab, setActiveMediaTab, setExerciseOnCanvas } from 'actions/navigation';
import RequestLoader from 'v2/components/loader.v2/RequestLoader';
import PreviewGroups from 'v2/ui-components/previews/PreviewGroups';
import { listOfFilterCheckedItems } from 'selectors/desk/deskSelector';
import { setCurrentFlashcard, selectExerciseFlashcard, setCurrentExerciseId, setActiveExerciseId, setExerciseId } from 'actions/studying';
import { clearSearch } from 'actions/search';
import { setFlashcardIds, setPreviousFlashcardIds } from 'actions/carousel/carouselActions';
import { activeExerciseId, activeSearchTerm, getUserId, isDeviceRestricted, shouldGoBackToSearch } from 'selectors';
import { carouselCurrentFlashcardId } from 'selectors/carouselSelectors';
import { checkFlashcardAssesstment } from 'v2/helpers';
import PreviewsList from 'v2/ui-components/previews/PreviewsList';
import { getExerciseAnswersRoutine, getExercisePreviewsRoutine } from 'actions/api';
import { answers } from 'selectors/answersSelectors';
import usePrevious from 'hooks/usePrevious';
import AnswerBullet from '../AnswerBullet/AnswerBullet';

type ExercisePreviewsContainerProps = {
  exerciseId: number;
  type: string;
  activeSubjectId?: string | number
}

const ExercisePreviewsContainer: React.FC<ExercisePreviewsContainerProps> = ({ exerciseId, type }) => {
  const dispatch = useDispatch();
  const filterItemSize = useSelector(execriseFilterItemSize);
  const exercisePreviews = useSelector(exercisePreviewsResponse)?.toJS();
  const answer = useSelector(answers);
  const activeMediaTab = useSelector(getActiveMediaTab);
  const filterCheckedItems = useSelector(listOfFilterCheckedItems)?.toJS();
  const activeSubject = useSelector(getActiveSubject);
  const teachingLvl = useSelector(subjectsTeachingLevel);
  const getActiveExerciseId = useSelector(activeExerciseId);
  const carouselCurrentId = useSelector(carouselCurrentFlashcardId);
  const hasShouldGoBackToSearch = useSelector(shouldGoBackToSearch);
  const getActiveSearchTerm = useSelector(activeSearchTerm);
  const subjectId = useSelector(activeSubjectId);
  const exerciseAnswers = useSelector(answers)
  const userId = useSelector(getUserId);
  const getAnswers = useSelector(answers)?.toJS();
  const hasDeviceRestricted = useSelector(isDeviceRestricted);
  const hasNavigationSectionVisible = useSelector(isNavigationSectionVisible);
  const [filterFlashcardId, setFilterFlashcardId] = useState<string>();
  const prevAnswers = usePrevious(getAnswers);

  useEffect(() => {
    if (exerciseId) {
      if (hasNavigationSectionVisible) {
        dispatch(openShortcutMenu());
      }
      dispatch(setExerciseId(exerciseId));
      dispatch(setActiveMediaTab("flashcard"));
      dispatch(getExercisePreviewsRoutine({ id: exerciseId }));
      //dispatch(getExerciseAnswersRoutine({ id: exerciseId }))
    }
  }, [])

  useEffect(() => {
    if (activeMediaTab === "flashcard" && carouselCurrentId) {
      if (prevAnswers[carouselCurrentId] && prevAnswers[carouselCurrentId].correct !== getAnswers[carouselCurrentId].correct) {
        const assesstment = fcAssessments(getAnswers[carouselCurrentId].correct)
        if (checkFlashcardAssesstment(filterCheckedItems.flashcard, assesstment)) {
          setFilterFlashcardId(carouselCurrentId);
        }
        setTimeout(() => {
          //dispatch(getExerciseAnswersRoutine({ id: exerciseId }));
          dispatch(getExercisePreviewsRoutine({ id: exerciseId }));
          setFilterFlashcardId(filterFlashcardId);
        }, 500)
      }
    }
  }, [getAnswers, prevAnswers])

  const selectedCardClick = (flashcardId: any) => {
    if (!getActiveExerciseId || (getActiveExerciseId !== exerciseId)) {
      !hasDeviceRestricted && dispatch(setActiveExerciseId(exerciseId));
    }
    setLastMediaTypeVisited(activeMediaTab, userId);
    dispatch(setCurrentExerciseId({ activeExerciseId: exerciseId, type: type }));
    const flashcardIds = exercisePreviews[exerciseId].data.map((item: any) => item.flashcardId);
    addBackFlashcardToLocalStorage(null, null);
    dispatch(setPreviousFlashcardIds(flashcardIds));
    dispatch(setFlashcardIds(flashcardIds));
    dispatch(selectExerciseFlashcard(flashcardId));
  }

  const onPreviewClick = (flashcardId: number) => {
    dispatch(setExerciseOnCanvas(true));
    selectedCardClick(flashcardId);
  }

  const renderPreviewsList = (previewsList: any, filterFlashcardId: any) => {
    return <PreviewsList
      rightSection={({ preview }: any) => {
        const { answered, correct } = answer.getIn(['EXERCISE-' + preview.flashcardId]).toJS();
        return <div className='flex items-center'>
          {hasShouldGoBackToSearch && preview.occurences > 0 &&
            <div className='flex min-w-[20px] h-5 rounded-[10px] text-white text-[13px] font-[600] items-center justify-center px-[5px] leading-[1.1] pr-2'>{preview.occurences}</div>}
          <AnswerBullet answered={answered !== false} correct={correct} />
        </div>;
      }}
      filterFlashcardId={filterFlashcardId}
      isActive={(flashcardId: number) => flashcardId === parseInt(carouselCurrentId)}
      onPreviewClick={(flashcardId: number) => onPreviewClick(flashcardId)}
      list={previewsList}
      shouldMark={hasShouldGoBackToSearch}
      activeSearchTerm={getActiveSearchTerm}
      activeMath={[15075, 14982].includes(subjectId)}
    />;
  };

  const selectCard = () => {
    let FlashcardIds = exercisePreviews[exerciseId].data
    let FilterCheckedItems = filterCheckedItems[activeMediaTab]
    let filteredIds = filterResultsListToArray(FlashcardIds, activeMediaTab, FilterCheckedItems)
    let filterFlashcardId = filteredIds[0].flashcardId

    if (filterFlashcardId) {
      dispatch(setExerciseOnCanvas(true));
      selectedCardClick(filterFlashcardId);
    }
  }

  return (
    <>
      {exercisePreviews[exerciseId]?.loading ?
        <LeftMenuLoader /> :
        <>
          {filterItemSize !== 0 && <div className='bg-[#378EF8] rounded-[22px] py-2 uppercase flex items-center justify-center mt-3.5 mb-[23px] cursor-pointer w-full text-[13px] font-bold text-white leading-[18px] tracking-[2px] active:bg-[#2170CF] gap-2' onClick={() => selectCard()}>
            <StudyButtonIcon />
            Study
          </div>}

          <RequestLoader loader={<LeftMenuLoader />} allowUpdate request={exercisePreviews[exerciseId]} exercise={true}>
            {(data: any) => {
              return <div className='mt-2 -mx-5'>
              {/* @ts-ignore */}
              <PreviewGroups list={data} activeMediaTab={activeMediaTab} listOfFilterCheckedItems={filterCheckedItems} setActiveFlashcardTab={() => dispatch(setActiveFlashcardTab)} clearSearch={() => dispatch(clearSearch)} activeSubject={activeSubject} setCurrentFlashcard={() => dispatch(setCurrentFlashcard)} subjectsTeachingLevel={teachingLvl}>
                {(previewsList) => renderPreviewsList(previewsList, filterFlashcardId)}
              </PreviewGroups>
            </div>}
            }
          </RequestLoader>
        </>
      }
    </>
  )
}

export default ExercisePreviewsContainer