import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {getActivePaperTab, getExamHeader,  getActiveSubject, isLeftMenuOpen, isApiRequestInProgressV2} from 'selectors'
import { MultipleChoice, RetryButton, SelectButton, FreeResponse } from 'icons/HeaderIcons'
import {examStatusById} from 'selectors/quickSelectors'
import {currentFlashcardId, getFlashcardById} from 'selectors/studying'
import {progressBarFilter, toggleNavigationSection} from 'actions/navigation/navigationActions'
import FilterHeader from './FilterHeader'
import StatusBar from 'v2/components/status-bar/StatusBar'
import Loader, {BackgroundEnum, SizeEnum} from 'v2/components/loader.v2/Loader.v2';
import { disableMcqExamSubject, returnFinalExams} from "helpers"
import useExamNavigationData from './useExamNavigationData'
import useSetFirstQuestion from './useSetFirstQuestion'
import './filter.scss'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'

type ProgressPopupFilterProps = {
    id?: string | number;
    onClose: (e: any) => void;
}  
const ProgressPopupFilter: React.FC<ProgressPopupFilterProps> = ({id, onClose}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {prevNavData, nextNavData} = useExamNavigationData();
    const {navFirstQuestion} = useSetFirstQuestion();
    const activePaper = useSelector(getActivePaperTab)
    const examHeader = useSelector(getExamHeader);
    const isNextPaperProgress = useSelector((state) => isApiRequestInProgressV2(state, 'progressPopupOverlayNext'));
    const isPrevPaperProgress = useSelector((state) => isApiRequestInProgressV2(state, 'progressPopupOverlayPrevious'));
    const activeSubject = useSelector(getActiveSubject)
    const currentId = useSelector(currentFlashcardId)
    const currentContent = useSelector((state) => getFlashcardById(state, currentId))?.toJS()
    const examMediaStatus = useSelector((state) => examStatusById(state, id))?.toJS()
    const hasLeftMenuOpen = useSelector(isLeftMenuOpen)
    const activePaperCCTab = currentContent?.experienceData?.tag_labels?.filter((item: any) => item.type === 'paper_type')[0].label;
    let statusBars =  {
        exams : examMediaStatus.freeResponse,
        mcq : examMediaStatus.mcqResponse
    }
    const papers = examHeader?.papers?.map((item: any) => item.paper_type);    
    const {examsStatus,examsStatusGraph,mcqStatusGraph,mcqStatus} = returnFinalExams(statusBars)
    const checkPaper2 = (activePaperCCTab === 'Paper 2' && isPrevPaperProgress && isNextPaperProgress)
    const handlePrevClick = () => {
        if(isPrevPaperProgress){
            return;
        }
        prevNavData()
    }

    const handleNextClick = () => {
        if(isNextPaperProgress){
            return;
        }
        nextNavData()
    }
    
    const handleSelectNewPaperClick = () => {
      
        history.push('/flashcard/desk/exams');
        dispatch(progressBarFilter(false,false))
        if(!hasLeftMenuOpen){
            dispatch(toggleNavigationSection(true, true));
        }
    }
    const firstQuestion = (e: any) => {
        onClose(e);
        navFirstQuestion()
    }
    return <div className='filter-wrapper' onClick={(e) => e.stopPropagation()}>
        <FilterHeader
            text={'Filter'} 
            hideArrow={false} 
            title={`Your progress for ${activePaperCCTab}`} 
            onCloseClick={() => dispatch(progressBarFilter(false))} 
        />
        <div className='px-5 pb-6 font-bold text-19px leading-6 text-[#9C9C9C]'>
            {examHeader.exam_name}
        </div>
        <div className='pt-0 px-5 pb-4'>
            <div className='shadow-#3E5755 rounded-[10px] px-5 pt-2.5 pb-2'>
                {!disableMcqExamSubject(activeSubject.id) && activePaper === 'Paper 1'  ? 
                    <StatusBar number={mcqStatus.total} title={'Multiple choice'} statusData={mcqStatusGraph} isRead={mcqStatus.total === mcqStatus.correct} mediaType={"exams"} icon={<MultipleChoice />} /> 
                    : 
                    <StatusBar number={examsStatus.total} title={'Free response'} statusData  = {examsStatusGraph} isRead={examsStatus.correct === examsStatus.total} mediaType={"exams"} icon={<FreeResponse />} />
                }
            </div>
        </div>
        <div className='mt-6 mb-3'>
            <div className={classNames('flex items-center justify-between pt-0 px-5 pb-5',{'!justify-center' : checkPaper2})}>
                {checkPaper2 ? 
                    <div className='py-1'> 
                        <Loader background={BackgroundEnum.Blue} size={SizeEnum.VSmall}/>
                    </div>
                    : 
                    <>
                        <ApplyButton isHidden={papers?.indexOf(activePaperCCTab) === 0} isLoading={isPrevPaperProgress} text={'PREV. PAPER'} onClick={() => handlePrevClick()}/>
                        <ApplyButton isHidden={papers?.indexOf(activePaperCCTab) === papers?.length-1} isLoading={isNextPaperProgress} text={'NEXT PAPER'} onClick={() => handleNextClick()}/>  
                    </>
                }
            </div>
        </div>
        <LargeButton text={'RETRY PAPER'} icon={<RetryButton />} onClick={(e: any) => firstQuestion(e)} />
        <LargeButton text={'SELECT NEW PAPER'} icon={<SelectButton />} onClick={() => handleSelectNewPaperClick()} isLastButton={true}/>
    </div>
}

const ApplyButton = ({isHidden, isLoading, text, onClick}:{isHidden: boolean, isLoading: boolean, text: string, onClick: () => void}) => {
    return <div className={classNames('filter-apply-btn', {'invisible': isHidden}, {'pointer-events-none': isLoading})} onClick={onClick} >
    {isLoading ?
        <Loader background={BackgroundEnum.Blue} size={SizeEnum.VSmall}/>
        :
        <div className='apply-btn-text'>
            {text}
        </div>
     }
    </div>
}

const LargeButton = ({text, onClick,icon, isLastButton}:{text: string, onClick: (e: any) => void, icon: ReactElement, isLastButton?: boolean}) => {
    return <div className={classNames('px-4',{'pb-5':isLastButton, 'pb-4':!isLastButton})}>
        <div className='paper-large-btn' onClick={onClick}>
            {icon}
            <div className='paper-large-btn-txt'>
                {text}
            </div>
        </div>
    </div>
}
export default ProgressPopupFilter;