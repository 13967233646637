import React, { useState, useRef, useEffect } from "react"
import usePrevious from "hooks/usePrevious";
import lodash from 'lodash';


type ElementPositionDetectorProps = {
  debounce: number,
  onTrigger: (arg: boolean) => void,
  maxScrollTop: number,
  parentScrollRef?: any

}

const ElementPositionDetector: React.FC<ElementPositionDetectorProps> = ({ debounce = 300, parentScrollRef, maxScrollTop, onTrigger }) => {
  const [passedThreshold, setPassedThreshold] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const prevScrollRef = usePrevious(parentScrollRef)

  useEffect(() => {
    if (prevScrollRef !== parentScrollRef) {
      window.addEventListener('scroll', onScroll);
      parentScrollRef?.addEventListener('scroll', onScroll);
    }
    return () => {
      window.removeEventListener('scroll', onScroll);
      parentScrollRef?.removeEventListener('scroll', onScroll);
      onScroll.cancel();
    }
  }, [parentScrollRef, prevScrollRef])

  const onScroll = lodash.debounce(() => {
    if (!ref.current) return;

    const distanceFromTopViewport = ref.current.getBoundingClientRect().top;

    if (distanceFromTopViewport <= maxScrollTop) {
      if (!passedThreshold) {
        setPassedThreshold(true);
        onTrigger(true);
      }
    } else {
      if (passedThreshold) {
        setPassedThreshold(false);
        onTrigger(false);
      }
    }

  }, debounce)

  return <div ref={ref} />
}


export default ElementPositionDetector