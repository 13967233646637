import {createRoutine} from 'redux-saga-request';
import * as Types from './apiTypes';
import makeAction from 'actions/makeAction';

export const authenticate = makeAction(Types.AUTHENTICATE, 'data', 'tokenData', 'redirectTo');
export const authenticateSucceeded = makeAction(Types.AUTHENTICATE_SUCCEEDED, 'data');
export const authenticateFailed = makeAction(Types.AUTHENTICATE_FAILED, 'error');

export const getUser = makeAction(Types.GET_USER);
export const getUserSucceeded = makeAction(Types.GET_USER_SUCCEEDED, 'data');
export const getUserFailed = makeAction(Types.GET_USER_FAILED, 'error');

export const getPartners = makeAction(Types.GET_PARTNERS, 'data', 'region', 'MBPartnersAccessToken');
export const getPartnersSucceeded = makeAction(Types.GET_PARTNERS_SUCCEEDED, 'data');
export const getPartnersFailed = makeAction(Types.GET_PARTNERS_FAILED, 'error');


export const getSubjects = makeAction(Types.GET_SUBJECTS);
export const getSubjectsSucceeded = makeAction(Types.GET_SUBJECTS_SUCCEEDED, 'data');
export const getSubjectsFailed = makeAction(Types.GET_SUBJECTS_FAILED, 'error');

export const getSubjectSyllabus = makeAction(Types.GET_SUBJECTS_SYLLABUS, 'subjectId');
export const getSubjectSyllabusSucceeded = makeAction(Types.GET_SUBJECTS_SYLLABUS_SUCCEEDED, 'data');
export const getSubjectSyllabusFailed = makeAction(Types.GET_SUBJECTS_SYLLABUS_FAILED, 'error');

export const getFlashcardContent = makeAction(Types.GET_FLASHCARD_CONTENT, 'nodeId','activeMediaTab');
export const getFlashcardContentSucceeded = makeAction(Types.GET_FLASHCARD_CONTENT_SUCCEEDED, 'data');
export const getFlashcardContentFailed = makeAction(Types.GET_FLASHCARD_CONTENT_FAILED, 'error');

export const setRevisionLink = makeAction(Types.SET_REVISION_LINK, 'url');
export const setRevisionLinkSucceeded = makeAction(Types.SET_REVISION_LINK_SUCCEEDED, 'data');
export const setRevisionLinkFailed = makeAction(Types.SET_REVISION_LINK_FAILED, 'error');

export const getElementStyles = makeAction(Types.GET_ELEMENT_STYLES);
export const getElementStylesSucceeded = makeAction(Types.GET_ELEMENT_STYLES_SUCCEEDED, 'data');
export const getElementStylesFailed = makeAction(Types.GET_ELEMENT_STYLES_FAILED);

export const getExams = makeAction(Types.GET_EXAMS);
export const getExamsSucceeded = makeAction(Types.GET_EXAMS_SUCCEEDED, 'data');
export const getExamsFailed = makeAction(Types.GET_EXAMS_FAILED, 'error');

export const getExamPreview = makeAction(Types.GET_EXAM_PREVIEW,'data','status')
export const getExamPreviewSucceeded = makeAction(Types.GET_EXAM_PREVIEW_SUCCEEDED, 'data');
export const getExamPreviewFailed = makeAction(Types.GET_EXAM_PREVIEW_FAILED, 'error');

export const getQuestionCount = makeAction(Types.GET_QUESTION_COUNT,'data');
export const getQuestionCountSucceeded = makeAction(Types.GET_QUESTION_COUNT_SUCCEEDED, 'data');
export const getQuestionCountFailed = makeAction(Types.GET_QUESTION_COUNT_FAILED, 'error');

export const getProfile = makeAction(Types.GET_PROFILE, 'data');
export const getProfileSucceeded = makeAction(Types.GET_PROFILE_SUCCEEDED, 'data');
export const getProfileFailed = makeAction(Types.GET_PROFILE_FAILED, 'error');

export const saveProfile = makeAction(Types.SAVE_PROFILE, 'data');
export const saveProfileSucceeded = makeAction(Types.SAVE_PROFILE_SUCCEEDED, 'data');
export const saveProfileFailed = makeAction(Types.SAVE_PROFILE_FAILED, 'error');

export const getContactDetails = makeAction(Types.GET_CONTACT_DETAILS, 'data');
export const getContactDetailsSucceeded = makeAction(Types.GET_CONTACT_DETAILS_SUCCEEDED, 'data');
export const getContactDetailsFailed = makeAction(Types.GET_CONTACT_DETAILS_FAILED, 'error');

export const saveContactDetails = makeAction(Types.SAVE_CONTACT_DETAILS, 'data');
export const saveContactDetailsSucceeded = makeAction(Types.SAVE_CONTACT_DETAILS_SUCCEEDED, 'data');
export const saveContactDetailsFailed = makeAction(Types.SAVE_CONTACT_DETAILS_FAILED, 'error');

export const changePassword = makeAction(Types.CHANGE_PASSWORD, 'data');
export const changePasswordSucceeded = makeAction(Types.CHANGE_PASSWORD_SUCCEEDED, 'data');
export const changePasswordFailed = makeAction(Types.CHANGE_PASSWORD_FAILED, 'error');

export const changeUsername = makeAction(Types.CHANGE_USERNAME, 'data');
export const changeUsernameSucceeded = makeAction(Types.CHANGE_USERNAME_SUCCEEDED, 'data');
export const changeUsernameFailed = makeAction(Types.CHANGE_USERNAME_FAILED, 'error');

export const confirmEmailAddress = makeAction(Types.CONFIRM_EMAIL_ADDRESS, 'data');
export const confirmEmailAddressSucceeded = makeAction(Types.CONFIRM_EMAIL_ADDRESS_SUCCEEDED);
export const confirmEmailAddressFailed = makeAction(Types.CONFIRM_EMAIL_ADDRESS_FAILED, 'error');

export const getStudentPackages = makeAction(Types.GET_STUDENT_PACKAGES);
export const getStudentPackagesSucceeded = makeAction(Types.GET_STUDENT_PACKAGES_SUCCEEDED, 'data');
export const getStudentPackagesFailed = makeAction(Types.GET_STUDENT_PACKAGES_FAILED, 'error');

export const registerPayment = makeAction(Types.REGISTER_PAYMENT, 'data');
export const registerPaymentSucceeded = makeAction(Types.REGISTER_PAYMENT_SUCCEEDED, 'data');
export const registerPaymentFailed = makeAction(Types.REGISTER_PAYMENT_FAILED, 'error');

export const addVoucher = makeAction(Types.ADD_VOUCHER, 'data');
export const addVoucherSucceeded = makeAction(Types.ADD_VOUCHER_SUCCEEDED);
export const addVoucherFailed = makeAction(Types.ADD_VOUCHER_FAILED, 'error');

export const getTrialInfo = makeAction(Types.GET_TRIAL_INFO, 'data');
export const getTrialInfoSucceeded = makeAction(Types.GET_TRIAL_INFO_SUCCEEDED, 'data');
export const getTrialInfoFailed = makeAction(Types.GET_TRIAL_INFO_FAILED, 'error');
export const checkPurchase = makeAction(Types.CHECK_PURCHASE, 'purchaseId');
export const checkPurchaseSucceeded = makeAction(Types.CHECK_PURCHASE_SUCCEEDED);
export const checkPurchaseFailed = makeAction(Types.CHECK_PURCHASE_FAILED, 'error');

export const getAnswers = makeAction(Types.GET_ANSWERS, 'data');
export const getAnswersSucceeded = makeAction(Types.GET_ANSWERS_SUCCEEDED, 'data');
export const getAnswersFailed = makeAction(Types.GET_ANSWERS_FAILED, 'error');

export const setMarks = makeAction(Types.SET_MARKS, 'data');
export const setMarksSucceeded = makeAction(Types.SET_MARKS_SUCCEEDED, 'data');
export const setMarksFailed = makeAction(Types.SET_MARKS_FAILED, 'error');

export const setVideoPlayed = makeAction(Types.SET_VIDEO_PLAYED, 'data');
export const setVideoPlayedSucceeded = makeAction(Types.SET_VIDEO_PLAYED_SUCCEEDED, 'data');
export const setVideoPlayedFailed = makeAction(Types.SET_VIDEO_PLAYED_FAILED, 'error');

export const setVideoPlayedStatus= makeAction(Types.SET_VIDEO_PLAYED_STATUS, 'data');
export const setVideoPlayedStatusSucceeded = makeAction(Types.SET_VIDEO_PLAYED_STATUS_SUCCEEDED, 'data');
export const setVideoPlayedStatusFailed = makeAction(Types.SET_VIDEO_PLAYED_STATUS_FAILED, 'error');

export const setExamStatus = makeAction(Types.SET_EXAM_STATUS, 'data');
export const setExamStatusSucceeded = makeAction(Types.SET_EXAM_STATUS_SUCCEEDED, 'data');
export const setExamStatusFailed = makeAction(Types.SET_EXAM_STATUS_FAILED, 'error');

export const getRecentSearches = createRoutine('API/GET_RECENT_SEARCHES');
export const updateRecentSearches = createRoutine('API/UPDATE_RECENT_SEARCHES');
export const search = createRoutine('API/SEARCH');
export const searchFlashcards = createRoutine('API/SEARCH_FLASHCARDS');
export const searchNotes = createRoutine('API/SEARCH_NOTES');
export const searchFolders = createRoutine('API/SEARCH_FOLDERS');
export const getFlashcardIdsRoutine = createRoutine('API/GET_FLASHCARD_IDS');
export const clearApiRequestData = makeAction(Types.CLEAR_API_REQUEST_DATA, 'requestName');
export const getTopicsForTeachingLevel = createRoutine('GET_TOPICS_FOR_TEACHING_LEVEL');
export const getAllTopicsInSubject = createRoutine('GET_ALL_TOPICS_IN_SUBJECT');
export const getSubtopicsInTopic = createRoutine('GET_SUBTOPICS_IN_TOPIC');
export const getPreviewsListByType = createRoutine('GET_PREVIEWS_LIST_BY_TYPE');
export const getClassContentRoutine = createRoutine('GET_CLASS_CONTENT');
export const getExternalExercisesRoutine = createRoutine('GET_EXTERNAL_EXERCISES');
// export const getExternalAvatarRoutine = createRoutine('GET_EXTERNAL_AVATAR');
// export const getExerciseDetailsRoutine = createRoutine('GET_EXERCISE_DETAILS');
export const getExercisePreviewsRoutine = createRoutine('GET_EXERCISE_PREVIEWS');
export const getViewedExercisesRoutine = createRoutine('API/GET_VIEWED_EXERCISES');
export const getAllExternalExercisesRoutine = createRoutine('API/GET_ALL_EXTERNAL_EXERCISES');
export const getExerciseAnswersRoutine = createRoutine('API/GET_EXERCISE_ANSWERS');
export const setExerciseAsViewedRoutine = createRoutine('API/SET_EXERCISE_AS_VIEWED');
export const createExerciseRoutine = createRoutine('API/CREATE_EXERCISE');
export const deleteExercisesRoutine = createRoutine('API/DELETE_EXERCISES');
export const updateExerciseRoutine = createRoutine('API/UPDATE_EXERCISE');
export const searchExercisesRoutine = createRoutine('API/SEARCH_EXERCISES');
export const getFlashcardAnswersRoutine = createRoutine('API/GET_FLASHCARD_ANSWERS');
export const getMBLastSyncRoutine = createRoutine('API/GET_MB_LAST_SYNC');
export const addFlashcardToFolderRoutine = createRoutine('API/ADD_FLASHCARD_TO_FOLDER');
export const removeFlashcardFromFolderRoutine = createRoutine('API/REMOVE_FLASHCARD_FROM_FOLDER');
export const getCardsTitleByType = createRoutine('API/GET_CARDS_TITLE_BY_TYPE');
export const getFirstQuestionIdBySubject = createRoutine('API/GET_FIRST_QUESTION_ID_BY_SUBJECT');
//Logged in Device List
export const getLoggedinDevices = makeAction(Types.GET_LOGEEDINDEVICE);
export const getLoggedinDevicesSucceeded = makeAction(Types.GET_LOGEEDINDEVICE_SUCCEEDED, 'data');
export const getLoggedinDevicesFailed = makeAction(Types.GET_LOGEEDINDEVICE_FAILED, 'error');

//Logout Device
export const removeLoggedinDevice = makeAction(Types.LOGOUTDEVICE, 'data');
export const removeLoggedinDevicesSucceeded = makeAction(Types.LOGOUTDEVICE_SUCCEEDED);
export const removeLoggedinDeviceFailed = makeAction(Types.LOGOUTDEVICE_FAILED, 'error');

//Flashcard Links List
// export const getFlashcardLinks = makeAction(Types.GET_FLASHCARD_LINKS, 'nodeId');
// export const getFlashcardLinksSucceeded = makeAction(Types.GET_FLASHCARD_LINKS_SUCCEEDED, 'data');
// export const getFlashcardLinksFailed = makeAction(Types.GET_FLASHCARD_LINKS_FAILED, 'error');

export const getTopicsDetails = createRoutine('GET_TOPICS_DETAILS');
export const addMarkAsReadRoutine = createRoutine('API/ADD_MARK_AS_READ');
export const addTopicsDataLength = makeAction(Types.ADD_TOPIC_DATA_LENGHT, 'data');

export const addFlashcardToBookmarkRoutine = createRoutine('API/ADD_FLASHCARD_TO_BOOKMARK');
export const removeFlashcardFromBookmarkRoutine = createRoutine('API/REMOVE_FLASHCARD_FROM_BOOKMARK');

export const setAnswerFlashcard = makeAction(Types.SET_ANSWER_FLASHCARD, 'data');
export const setAnswerFlashcardSucceeded = makeAction(Types.SET_ANSWER_FLASHCARD_SUCCEEDED);
export const setAnswerFlashcardFailed = makeAction(Types.SET_ANSWER_FLASHCARD_FAILED, 'error');


//SMPP-LAGGY
export const setQuickStatus = makeAction(Types.SET_QUICK_STATUS, 'data');
export const setQuickStatusSucceeded = makeAction(Types.SET_QUICK_STATUS_SUCCEEDED,'data');
export const setQuickStatusFailed = makeAction(Types.SET_QUICK_STATUS_FAILED, 'error');

export const getFeature = makeAction(Types.GET_FEATURE);
export const getFeatureSucceeded = makeAction(Types.GET_FEATURE_SUCCEEDED, 'data');
export const getFeatureFailed = makeAction(Types.GET_FEATURE_FAILED, 'error');

export const closeFeature = makeAction(Types.CLOSE_FEATURE, 'id');
export const closeFeatureSucceeded = makeAction(Types.CLOSE_FEATURE_SUCCEEDED, 'data');
export const closeFeatureFailed = makeAction(Types.CLOSE_FEATURE_FAILED, 'error');
export const updateMarkValueOfTitlesByFlashcardId = makeAction(Types.UPDATE_MARK_VALUE_OF_TITLES_BY_FLASHCARD_ID, 'cardId', 'value');
export const updateVideoProgressValueOfTitlesByFlashcardId = makeAction(Types.UPDATE_VIDEO_PROGRESS_VALUE_OF_TITLES_BY_FLASHCARD_ID, 'data');
// export const updateAnswerByFlashcardIdPreview = makeAction(Types.UPDATE_ANSWER_BY_FLASHCARD_ID_PREVIEW, 'data');

export const getExamHeader = makeAction(Types.GET_EXAM_HEADER,'data');
export const getExamHeaderSucceeded = makeAction(Types.GET_EXAM_HEADER_SUCCEEDED,'data');
export const getExamHeaderFailed = makeAction(Types.GET_EXAM_HEADER_FAILED,'data');

export const getExamPreviewsListByType  = makeAction(Types.SET_EXAM_PREVIEW_LIST_BY_TOPIC_ID, 'data');
export const getExamPreviewsListByTypeSucceeded  = makeAction(Types.SET_EXAM_PREVIEW_LIST_BY_TOPIC_ID_SUCCEEDED);
export const getExamPreviewsListByTypeFailed  = makeAction(Types.SET_EXAM_PREVIEW_LIST_BY_TOPIC_ID_FAILED, 'error');

export const updateExamPreviewsListByType = makeAction(Types.UPDATE_EXAM_PREVIEW_LIST_BY_TOPIC_ID, 'data')

export const progressPopupOverlayNext  = makeAction(Types.PROGRESS_POPUP_OVERLAY_NEXT, 'data');
export const progressPopupOverlayNextSucceeded  = makeAction(Types.SET_PROGRESS_POPUP_OVERLAY_NEXT_SUCCEEDED, 'data');
export const progressPopupOverlayNextFailed  = makeAction(Types.SET_PROGRESS_POPUP_OVERLAY_NEXT_FAILED, 'error');

export const progressPopupOverlayPrevious  = makeAction(Types.PROGRESS_POPUP_OVERLAY_PREVIOUS, 'data');
export const progressPopupOverlayPreviousSucceeded  = makeAction(Types.SET_PROGRESS_POPUP_OVERLAY_PREVIOUS_SUCCEEDED, 'data');
export const progressPopupOverlayPreviousFailed  = makeAction(Types.SET_PROGRESS_POPUP_OVERLAY_PREVIOUS_FAILED, 'error');
export const getAllTopicsIds = makeAction(Types.GET_ALL_TOPICS_ID, 'data');
export const getNextPrevTopicsData  = makeAction(Types.GET_NEXT_PREV_TOPICS_DATA, 'data','hasType');

export const setExamFilterData = makeAction(Types.SET_EXAM_FILTER_DATA);
export const setExamFilterDataSucceeded = makeAction(Types.SET_EXAM_FILTER_DATA_SUCCEEDED, 'data');
export const setExamFilterDataFailed = makeAction(Types.SET_EXAM_FILTER_DATA_FAILED, 'error');

export const setLastTeachingTag = makeAction(Types.SET_LAST_TEACHING_TAG, 'data');

export const setFlashcardAnswerUpdate = makeAction(Types.UPDATE_FLASHCARD_ANSWER, 'id', 'response')

export const getExternalAvatar = makeAction(Types.GET_EXTERNAL_AVATAR, 'payload')
export const getExternalAvatarSucceeded = makeAction(Types.GET_EXTERNAL_AVATAR_SUCCEEDED, 'payload')
export const getExternalAvatarFailed = makeAction(Types.GET_EXTERNAL_AVATAR_FAILED, 'payload')

// Internal Exercises List
export const getInternalExercises = makeAction(Types.GET_INTERNAL_EXERCISES, 'payload');
export const getInternalExercisesSucceeded = makeAction(Types.GET_INTERNAL_EXERCISES_SUCCEEDED, 'payload');
export const getInternalExercisesFailed = makeAction(Types.GET_INTERNAL_EXERCISES_FAILED, 'error');

export const getExerciseDetails = makeAction(Types.GET_EXERCISE_DETAILS, 'payload');
export const getExerciseDetailsSucceeded = makeAction(Types.GET_EXERCISE_DETAILS_SUCCEEDED, 'payload');
export const getExerciseDetailsFailed = makeAction(Types.GET_EXERCISE_DETAILS_FAILED, 'error');
