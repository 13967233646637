import media from 'ui-components/helpers/media';
import CheckRadioButton from 'v2/components/check-radio-button/CheckRadioButton';
import {AddToFolderIcon, DefaultFolderIcon, DragDropFolderIcon} from 'icons/FolderIcons';
import {ForwardChevronIcon} from 'icons/HeaderIcons';
import AddFlashcardToFolderContainer from 'pages/desk/folder/AddFlashcardToFolderContainer';
import AddFlashcardToBookmarkContainer from 'pages/desk/folder/AddFlashcardToBookmarkContainer';
import PropTypes from 'prop-types';
import React from 'react';
import styled, {css} from 'styled-components/macro';
import Margin from 'ui-components/helpers/Margin';
import MarkText from 'ui-components/MarkText';
import {SubSeparator} from 'ui-components/pageComponents';
import { Draggable } from 'react-beautiful-dnd'
import {Reorder, getItemStyle} from 'helpers';
// import './FolderListItem.scss';


const FolderListItem = (props) => {

  return (
    <React.Fragment>	
        {(!props.isfolders || props.foldercount <= 1) && (	
        <StyledFolderListItem onClick={props.onClick}	
          className={'folder-list-item flex' + (props.active && ' active' || '') + (props.folder && props.folder.get('deleted') ? ' deleted' : '')}>	
                  {props.isEditing && (	
                    <CheckRadioButton itemId={props.folder.get('id')} listOfCheckedItems={props.listOfCheckedItems}/>	
                  )}	
                  {props.icon ? props.icon :<DefaultFolderIcon/>}	
                    <div className='name' >	
                      <MarkText shouldMark={props.shouldMark}>	
                        {props.name || props.folder.get('title')}	
                      </MarkText>	
                    </div>	
                  {!props.isEditing && (	
                    <div className='flex-pull-right vertical-align'>	
                      
                      {/* {props.hasFlashcard && 
                        <Margin right={4}><InActiveBookmarkedIcon /></Margin>
                      }	 */}
                      {props.hasFlashcard &&  <AddFlashcardToBookmarkContainer	flashcardFolders={props.flashcardFolders}	/>}	
                      <AddFlashcardToFolderContainer	
                        folderId={props.folder && props.folder.get('id')}	
                        flashcardFolders={props.flashcardFolders}	
                      />
                      <ForwardChevronIcon/>	
                    </div>	
                  )}	
                  {/* <StyledSubSeparator as={SubSeparator}/>	 */}
        </StyledFolderListItem>)}	
        {(props.isfolders && props.foldercount > 1) && (	 	
         <Draggable	
                  key={props.folder.get('id').toString()}	
                  draggableId={props.folder.get('id').toString()}	
                  index={props.index}	>	
           {(provided, snapshot) => (		
          <StyledFolderListItem 	
            ref={provided.innerRef}	
            {...provided.draggableProps}	
            style={getItemStyle(	
              snapshot.isDragging,	
              provided.draggableProps.style	
            )}	
            onClick={props.onClick}	
            className={'folder-list-item flex' + (props.active && ' active' || '') + (props.folder && props.folder.get('deleted') ? ' deleted' : '')}>	
                  {props.isEditing && (	
                    <CheckRadioButton itemId={props.folder.get('id')} listOfCheckedItems={props.listOfCheckedItems}/>	
                  )}
                   {props.foldercount > 1 && (	
                    <StyleDragIconDiv>
                      { props.icon ? null : <div {...provided.dragHandleProps}><DragDropFolderIcon /></div>}	
                      
                    </StyleDragIconDiv>
                  )}
                    
                  {props.icon ? props.icon :<DefaultFolderIcon/>}	
                  <div className='name' >	
                    <MarkText shouldMark={props.shouldMark}>	
                      {props.name || props.folder.get('title')}	
                    </MarkText>	
                  </div>	
                  
                    {/* {props.isFolder ? <div className="name-transparent"></div> : null} */}	
                  	
                  {!props.isEditing && (	
                    <div className='flex-pull-right vertical-align'>	
                    
                      {/* {props.hasFlashcard && <Margin right={20}><AddToFolderIcon state='active'/></Margin>}	 */}
                      {props.flashcardFolders && <AddFlashcardToFolderContainer	
                        folderId={props.folder && props.folder.get('id')}	
                        flashcardFolders={props.flashcardFolders}	
                      />	}
                      <ForwardChevronIcon/>	
                    </div>	
                  )}	
                  {/* <StyledSubSeparator as={SubSeparator}/>	    	 */}
           </StyledFolderListItem>		
         )} 	
        </Draggable>)}	
    </React.Fragment>
  );
};

FolderListItem.propTypes = {
  active: PropTypes.bool,
  hasFlashcard: PropTypes.bool,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  onAddClick: PropTypes.func,
  name: PropTypes.string,
  isEditing: PropTypes.bool,
  folder: PropTypes.any,
  listOfCheckedItems: PropTypes.array,
  shouldMark: PropTypes.bool.isRequired,
};

FolderListItem.defaultProps = {
  isEditing: false,
  listOfCheckedItems: [],
  shouldMark: false
};

export default FolderListItem;


const StyledSubSeparator = styled.div`
  position: absolute;
  right: 20px;
  left: 68px;
  bottom: 0;
`;

const StyleDragIconDiv = styled.div`
  margin-right:12px;
  display:block;
  
  ${media.phone(css`
      @media (orientation: landscape){
        display:none;
      }
  `)}
 
  
`;
const StyledFolderListItem = styled.div`	
:last-of-type{	
  ${StyledSubSeparator} {	
    display: none;	
  }	
}	
`;
