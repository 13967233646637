import TeachingLevelSelectorContainer from 'components/teaching-level-selector/TeachingLevelSelectorContainer';
import React, {Component} from 'react';
import * as InfoIcons from 'icons/InfoIcons';
import HeaderNotesTooltip from './HeaderNotesTooltip';
import {withScrollDetectionContained} from 'hocs/withScrollDetection';
import EmptyScreenMessage from 'v2/components/empty-screen-message/EmptyScreenMessage';
import ListFlashcardPreview from 'components/list-flashcard-preview';
import styled from 'styled-components/macro';
import {NoNotesIcon} from 'icons/FlashcardIcons';
import FlashcardEmptyScreen from 'v2/components/empty-screen-message/FlashcardEmptyScreen';
import Padding from 'ui-components/helpers/Padding';
import NotesTooltipContainer from 'components/studying/control-overlay/notes-menu/NotesTooltipContainer';
/**
 * @deprecated TODO- delete not use any more
 */
class MobileNoteList extends Component {
  componentDidMount() {
    this.props.getFlashcardsWithNotes(true);
    
  }


  renderBody = () => {
    const {flashcardsWithNotes, activeTeachingLevelTitle} = this.props;
    let flashcards;
    if (flashcardsWithNotes.size > 0 || this.props.currentFlashcardId) {
      flashcards = activeTeachingLevelTitle !== 'All' ? flashcardsWithNotes.filter(item => item.get('teachingLevel') === activeTeachingLevelTitle) : flashcardsWithNotes;

      return (  
        <React.Fragment>
          <React.Fragment>
            <Padding top={this.props.flashcardsWithNotes.size > 0 ? 2 : 16}/>
             <FlashcardEmptyScreen type='notes' FlashcardtypeSize={flashcardsWithNotes.size} onEditNoteMobile={this.props.onEdit}
            editNoteMobile={true}><NoNotesIcon/></FlashcardEmptyScreen>
           </React.Fragment>
          <ListFlashcardPreview
          type='notes' 
          list={flashcards}
          onEditNoteMobile={this.props.onEdit}
          editNoteMobile={true}/>
        </React.Fragment>
       );
      
    }
    
    return <EmptyScreenMessage type='notes'> <NoNotesIcon/></EmptyScreenMessage>;
  };

  render() {
    return (
      <React.Fragment>
        <HeaderNotesTooltip
          isCollapsed={this.props.isScrolled}
          showAdd={this.props.currentFlashcardId}
          onEdit={this.props.onEdit}
          deleteConfirmationMode={this.props.deleteConfirmationMode}
          toggleDeleteConfirmationMode={this.props.toggleDeleteConfirmationMode}/>
          <div className="notes-tooltip-container" ref={this.props.bindScrollRef}>
          <div className="list-flashcards-preview-container">
            
            {this.props.flashcardsWithNotes.size > 0 &&
              <StyledTeachingLevelContainer/>
            }
            {this.renderBody()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withScrollDetectionContained(MobileNoteList);
const StyledTeachingLevelContainer = styled(TeachingLevelSelectorContainer)`
  padding: 0 0 0 20px;
  display: flex;
`;
const StyledFlashcardEmptyScreen = styled(FlashcardEmptyScreen)`
   ${props => props.editing && 'opacity: 0.7; pointer-events: none;'}
`;
