import React, { FC, useState, useImperativeHandle, useRef } from 'react';
import {createExerciseRoutine, updateExerciseRoutine} from 'actions/api/apiActions';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RenderInputField, WarnIcon, RenderDatepicker, RenderSelectField } from 'v2/utils/form-helper';
import ExerciseInput from './ExerciseInput';
import moment from 'moment';

type ExerciseFormProps = {
  exerciseId: number,
  updateTitle: (arg : any) => void, 
  initialValues: any,
  ref: any
} 
 interface FormRef {
  handleSubmit(): void;
}


const ExerciseForm =  React.forwardRef<FormRef, ExerciseFormProps> ((props, ref) => {
  const {exerciseId, updateTitle, initialValues} = props
  const disptach = useDispatch()
  const formRef = useRef<HTMLFormElement>(null)
  const initialFormValue = {
    title:  initialValues?.title || '',
    comment: initialValues?.comment || '',
    dueDate: initialValues?.dueDate || '',
    hour: initialValues?.hour || '',
    minute: initialValues?.minute || ''
  }
  
  const [isFocused, setFocused] = useState({ title: false, comment: false, dueDate: false, hour: false, minute: false });
  const { values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue } = useFormik({
    validationSchema: validation,
    enableReinitialize: true,
    initialValues: initialFormValue,
    onSubmit: (data) => {
      if(exerciseId){
        disptach(updateExerciseRoutine({ id: exerciseId,data: data}))
      }else{
        disptach(createExerciseRoutine(data))
      }
    }
  });


  useImperativeHandle(ref, () => ({ handleSubmit }), [values]);
  return <form onSubmit={handleSubmit} className='exercise-form' ref={formRef}>
    <div className='header-extension'>
      <ExerciseInput id='title'
        name={'title'}
        placeholder={'Exercise name'}
        type='text'
        touched={touched.title}
        error={errors.title}
        reset={() => [setFieldValue("title", ""), updateTitle('')]}
        active={isFocused.title}
        value={values.title}
        onChange={(e) => [handleChange(e), updateTitle(e.target?.value)]}
        onBlur={(e: any) => [handleBlur(e), setFocused({ ...isFocused, title: false })]}
        onFocus={() => {
          setFocused({ ...isFocused, title: true })
        }} />
    </div>
    <div className='px-5'>
      <div className='form-input-wrapper'>
        <RenderInputField
          label={'Comment (optional)'}
          type='text'
          name='comment'
          placeholder='Add anything worthwile mentioning'
          className='form-input'
          showIcon={true}
          touched={touched.comment}
          error={errors.comment}
          reset={() => setFieldValue("comment", "")}
          active={isFocused.comment}
          value={values.comment}
          onChange={handleChange}
          onBlur={(e: any) => [handleBlur(e), setFocused({ ...isFocused, comment: false })]}
          disabled={false}
          autoComplete={"off"}
          onFocus={() => {
            setFocused({ ...isFocused, comment: true })
          }}
        />
      </div>
      <div className='flex items-center justify-between'>
        <div className='form-input-wrapper'>
          <RenderDatepicker
            label={'Due date (optional)'}
            name='dueDate'
            className='form-input'
            id={'create-exercise-datepicker'}
            touched={touched.dueDate}
            error={errors.dueDate}
            minDate={moment()}
            maxDate={false}
            reset={setFieldValue}
            active={isFocused.dueDate}
            value={values.dueDate}
            disabled={false}
            onChange={handleChange}
            placeholder={'Select a date'}
            onBlur={(e: any) => [handleBlur(e), setFocused({ ...isFocused, dueDate: false })]}
            onFocus={() => {
              setFocused({ ...isFocused, dueDate: true })
            }} />
        </div>
        <div className='flex items-end self-end pl-[50px]'>
          <div className='form-input-wrapper'>
            <RenderSelectField
              name='hour'
              className='form-input time'
              type={'select'}
              id={'create-exercise-time'}
              touched={touched.hour}
              error={errors.hour}
              showIcon={false}
              //  minDate={moment()}
              reset={setFieldValue}
              active={isFocused.hour}
              value={values.hour}
              disabled={false}
              onChange={handleChange}
              placeholder={'+'}
              onBlur={(e: any) => [handleBlur(e), setFocused({ ...isFocused, hour: false })]}
              onFocus={() => {
                setFocused({ ...isFocused, hour: true })
              }}
            >
              <option value="" selected disabled hidden>09 AM</option>
              <option value='0'>12 AM</option>
              <option value='1'>01 AM</option>
              <option value='2'>02 AM</option>
              <option value='3'>03 AM</option>
              <option value='4'>04 AM</option>
              <option value='5'>05 AM</option>
              <option value='6'>06 AM</option>
              <option value='7'>07 AM</option>
              <option value='8'>08 AM</option>
              <option value='9'>09 AM</option>
              <option value='10'>10 AM</option>
              <option value='11'>11 AM</option>
              <option value='12'>12 PM</option>
              <option value='13'>01 PM</option>
              <option value='14'>02 PM</option>
              <option value='15'>03 PM</option>
              <option value='16'>04 PM</option>
              <option value='17'>05 PM</option>
              <option value='18'>06 PM</option>
              <option value='19'>07 PM</option>
              <option value='20'>08 PM</option>
              <option value='21'>09 PM</option>
              <option value='22'>10 PM</option>
              <option value='23'>11 PM</option>
            </RenderSelectField>
          </div>

          <div className='px-7px pt-[22px] text-15px text-#2D3838 leading-19px text-center pb-[5px]'>:</div>

          <div className='form-input-wrapper'>
            <RenderSelectField
              name='minute'
              className='form-input time-small'
              type={'select'}
              id={'create-exercise-time-small'}
              touched={touched.minute}
              error={errors.minute}
              showIcon={false}
              //  minDate={moment()}
              reset={setFieldValue}
              active={isFocused.minute}
              value={values.minute}
              disabled={false}
              onChange={handleChange}
              placeholder={'+'}
              onBlur={(e: any) => [handleBlur(e), setFocused({ ...isFocused, minute: false })]}
              onFocus={() => {
                setFocused({ ...isFocused, minute: true })
              }}
            >
              <option value="" selected disabled hidden>00</option>
              <option value='0'>00</option>
              <option value='5'>05</option>
              <option value='10'>10</option>
              <option value='15'>15</option>
              <option value='20'>20</option>
              <option value='25'>25</option>
              <option value='30'>30</option>
              <option value='35'>35</option>
              <option value='40'>40</option>
              <option value='45'>45</option>
              <option value='50'>50</option>
              <option value='55'>55</option>
            </RenderSelectField>
          </div>
        </div>
      </div>
    </div>

  </form>
})


let validation = Yup.object().shape({
  title: Yup.string().required("This field is required!")
});


export default ExerciseForm