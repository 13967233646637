import React from "react"

type HorizontalSeparatorProps = {
  className?: string
}

const HorizontalSeparator: React.FC<HorizontalSeparatorProps> = ({ className }) => {
  return <div className={`separator-horizontal ${className}`}></div>

}

export default HorizontalSeparator