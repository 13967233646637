import React from 'react';
import PhysicsIcon from 'assets/images/subjects/physics.svg';
import ChemistryIcon from 'assets/images/subjects/chemistry.svg';
import BiologyIcon from 'assets/images/subjects/biology.svg';
import NewEconomicsIcon from 'assets/images/subjects/Economics.png';
import EssIcon from 'assets/images/subjects/ess.svg';
import MathIcon from 'assets/images/subjects/math.svg';
import AllIcon from 'assets/images/subjects/all.svg';
import DemoIcon from 'assets/images/subjects/demo.svg';
import GeographyIcon from 'assets/images/subjects/geography.svg';
import PsychologyIcon from 'assets/images/subjects/psychology.svg';
import EnglishBIcon from 'assets/images/subjects/english-b.svg';
import FrenchBIcon from 'assets/images/subjects/french-b.svg';
import SpanishBIcon from 'assets/images/subjects/spanish-b.svg';
import MathAandA from 'assets/images/subjects/math-a-and-a.svg';
import MathAandI from 'assets/images/subjects/math-a-and-i.svg';
import {ReactComponent as SmallPhysicsIcon} from 'assets/images/subjects/physics-small.svg';
import {ReactComponent as SmallChemistryIcon} from 'assets/images/subjects/chemistry-small.svg';
import {ReactComponent as SmallBiologyIcon} from 'assets/images/subjects/biology-small.svg';
import {ReactComponent as SmallEconomicsIcon} from 'assets/images/subjects/economics-small.svg';
import {ReactComponent as SmallEssIcon} from 'assets/images/subjects/ess-small.svg';
import {ReactComponent as SmallMathIcon} from 'assets/images/subjects/math-small.svg';
import {ReactComponent as SmallDemoIcon} from 'assets/images/subjects/demo-small.svg';
import {ReactComponent as SmallGeographyIcon} from 'assets/images/subjects/geography-small.svg';
import {ReactComponent as SmallPsychologyIcon} from 'assets/images/subjects/psychology-small.svg';
import {ReactComponent as SmallEnglishBIcon} from 'assets/images/subjects/english-b-small.svg';
import {ReactComponent as SmallFrenchBIcon} from 'assets/images/subjects/french-b-small.svg';
import {ReactComponent as SmallSpanishBIcon} from 'assets/images/subjects/spanish-b-small.svg';
import {ReactComponent as SmallMathAandA} from 'assets/images/subjects/math-a-and-a-small.svg';
import {ReactComponent as SmallMathAandI} from 'assets/images/subjects/math-a-and-i-small.svg';
import HistoryIcon from 'assets/images/subjects/history.svg';
import PreIbIcon from 'assets/images/subjects/pre-ib.svg';
import {ReactComponent as SmallPreIbIcon} from 'assets/images/subjects/pre-ib-small.svg';
import {ReactComponent as SmallHistoryIcon} from 'assets/images/subjects/history-small.svg';
import BusinessIcon from 'assets/images/subjects/business-management.svg';
import {ReactComponent as SmallBusinessIcon} from 'assets/images/subjects/business-management-small.svg';
import Padding from 'ui-components/helpers/Padding';

export const SubjectIcon = (props) => {
  const newProps = {...props};
  delete newProps.changeDefaultIcon;
  switch (props.subject) {
    case 'physics':
      return <img src={PhysicsIcon} {...newProps} alt=""/>;
    case 'chemistry':
      return <img src={ChemistryIcon} {...newProps} alt=""/>;
    case 'biology':
      return <img src={BiologyIcon} {...newProps} alt=""/>;
    case 'economics':
      return <img src={NewEconomicsIcon} {...newProps} alt="" width={'80px'} height={'80px'} />;
    case 'environmental-systems-and-societies':
    case 'es-amp-s':
      return <img src={EssIcon} {...newProps} alt=""/>;
    case 'mathematics':
      return <img src={MathIcon} {...newProps} alt=""/>;
    case 'geography':
      return <img src={GeographyIcon} {...newProps} alt=""/>;
    case 'psychology':
      return <img src={PsychologyIcon} {...newProps} alt=""/>;
    case 'english-b':
      return <img src={EnglishBIcon} {...newProps} alt=""/>;
    case 'french-b':
    case 'french':
      return <img src={FrenchBIcon} {...newProps} alt=""/>;
    case 'spanish-b':
      return <img src={SpanishBIcon} {...newProps} alt=""/>;
    case 'maths-a-amp-a':
      return <img src={MathAandA} {...newProps} alt=""/>;
    case 'maths-a-amp-i':
      return <img src={MathAandI} {...newProps} alt=""/>;
    case 'history':
      return <img src={HistoryIcon} {...newProps} alt=""/>;
    case 'business-management':
      return <img src={BusinessIcon} {...newProps} alt=""/>;
    case 'pre-ib-mathematics':
      return <img src={PreIbIcon} {...newProps} alt=""/>;
    case 'all':
    case '3-month':
    case '6-month':
    case 'trial':
      return <img className="full" src={AllIcon} {...newProps} alt=""/>;
    case 'sample-package':
    case 'demo':
      return <Padding left={6}><img src={DemoIcon} {...newProps} alt=""/></Padding>;
    default:
      return <div>No icon</div>;
  }
};
export const SmallSubjectIcon = (props) => {
  const newProps = {...props};
  delete newProps.changeDefaultIcon;
  switch (props.subject) {
    case 'physics':
      return <SmallPhysicsIcon {...newProps} alt=""/>;
    case 'chemistry':
      return <SmallChemistryIcon {...newProps} alt=""/>;
    case 'biology':
      return <SmallBiologyIcon {...newProps} alt=""/>;
    case 'economics':
      return <SmallEconomicsIcon {...newProps} alt=""/>;
    case 'environmental-systems-and-societies':
    case 'es-amp-s':
      return <SmallEssIcon {...newProps} alt=""/>;
    case 'mathematics':
      return <SmallMathIcon {...newProps} alt=""/>;
    case 'geography':
      return <SmallGeographyIcon {...newProps} alt=""/>;
    case 'psychology':
      return <SmallPsychologyIcon {...newProps} alt=""/>;
    case 'english-b':
      return <SmallEnglishBIcon {...newProps} alt=""/>;
    case 'french-b':
    case 'french':
      return <SmallFrenchBIcon {...newProps} alt=""/>;
    case 'spanish-b':
      return <SmallSpanishBIcon {...newProps} alt=""/>;
    case 'maths-a-amp-a':
      return <SmallMathAandA {...newProps} alt=""/>;
    case 'maths-a-amp-i':
      return <SmallMathAandI {...newProps} alt=""/>;
    case 'history':
      return <SmallHistoryIcon {...newProps} alt=""/>;
    case 'business-management':
      return <SmallBusinessIcon {...newProps} alt=""/>;
    case 'pre-ib-mathematics':
      return <SmallPreIbIcon {...newProps} alt=""/>;
    case 'all':
      return <img className="full" src={AllIcon} {...newProps} alt=""/>;
    case 'sample-package':
    case 'demo':
      return <SmallDemoIcon {...newProps} alt=""/>;
    default:
      return <div>No icon</div>;
  }
}