import desktopBackgroundRetina from 'assets/images/login-desktop-bg.png';
import mobileBackgroundRetina from 'assets/images/login-mobile-bg.png';
import UserAvatarContainer from 'components/avatar/UserAvatarContainer';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import Row from 'ui-components/helpers/Row';

export const PagePadding = styled.div`
  padding: 0 20px 0px 20px;
`;

export const FixedPageSpacing = styled.div`
  padding-top: 56px;
  padding-bottom: 150px;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: ${props => props.scrollSmooth && 'smooth'};
  overflow: ${props => props.preventScroll ? 'hidden' : 'overlay'};
`;

FixedPageSpacing.propTypes = {
  large: PropTypes.bool,
  preventScroll: PropTypes.bool,
};

export const Separator = styled.div`
  background: ${props => props.color ? props.color : '#EFF4F9'};
  height: 1px;
  border-radius: 1px;
  margin: ${props => props.spacing || 0}px 0;
`;
Separator.propTypes = {
  spacing: PropTypes.number,
  color: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
};
export const BorderSeprator = styled.div`
  border: 1px solid ${props => props.color ? props.color : '#EFF4F9'};
  margin: ${props => props.spacing || 0}px 0;
  width: 100%;
`;

export const MainSeparator = (props) =>
  <Separator color='#E1E6E6' {...props}/>;
export const SubSeparator = (props) =>
  <Separator color='#F0F5F5' {...props}/>;

export const PageTitle = styled.h1`
  font-weight: bold;
  font-size: 33px;
  color: #2D3838;
  letter-spacing: 0;
  line-height: 45px;
`;
export const SmallPageTitle = styled(PageTitle)`
  font-size: 25px;
  line-height: 1.27;
`;

export const PageWrapper = styled.div`
  height: 100%;
`;

export const PublicPageBackground = styled.div`
  //width: 100%;
  min-height: 100vh;
  //position: relative;
`;

export const PageHeader = ({children, avatar}) =>
  <Row justifyContent={'space-between'}>
    <PageTitle>{children}</PageTitle>
    {avatar}
  </Row>;
PageHeader.defaultProps = {
  avatar: <UserAvatarContainer/>
};
PageHeader.propTypes = {
  avatar: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export const Background = styled.div`
   z-index: 0;
   
   background-repeat: no-repeat;
   width: 100%;
   height: 150px;
   background-size: cover;
   background-position: bottom center;
   //background-image: url('${desktopBackgroundRetina}');
  
  @media (min-width: 1200px){
    height: 285px;
  }
  
  @media (min-width: 900px) and (max-width: 1200px){
    height: 200px;
  }
  
  @media (min-width: 700px) and (max-width: 900px){
    height: 165px;
  }
  
  @media (max-width: 700px){
    height: 150px;
    //margin-bottom: 50px;
    //background-image: url('${mobileBackgroundRetina}');  
  } 

 `;
