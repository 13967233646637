import React, {Component} from 'react';
import './FormCheckbox.scss';
import {Field} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {renderCheckbox, validator} from 'utils/form-helper';

export default class FormCheckbox extends Component {
  render() {
    return (
      <div className='form-checkbox-wrapper'>
        <div className='form-checkbox-container'>
          <Field
            name={this.props.name}
            component={renderCheckbox}
            type='checkbox'
            className='form-checkbox'
            id={this.props.name}
            validate={this.props.required ? validator.checkboxValidation : null}
          />

          <div className='input-checkbox'>
          </div>
        </div>

        <label className='form-label' htmlFor={this.props.name}>{this.props.label}</label>
      </div>
    );
  }
}

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element,
  ]).isRequired,
  required: PropTypes.any
};
