const PREFIX = 'ANSWERS/';

export const SET_NODE_ANSWERS = PREFIX + 'SET_NODE_ANSWERS';
export const CHANGE_NODE_ANSWERS = PREFIX + 'CHANGE_NODE_ANSWERS';

const MARK_PREFIX = 'MARKS/';
export const SET_NODE_MARKS = MARK_PREFIX + 'SET_NODE_MARKS';
export const CHANGE_NODE_MARKS = MARK_PREFIX + 'CHANGE_NODE_MARKS';

const WATCHED_PREFIX = 'WATCHED/';
export const SET_NODE_WATCHED = WATCHED_PREFIX + 'SET_NODE_WATCHED';

const EXAM_PREFIX = 'EAXM/';
export const SET_EXAM_NODE_ANSWERS = EXAM_PREFIX + 'SET_EXAM_NODE_ANSWERS';