import React, { useEffect, useImperativeHandle, useState, useRef } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputField, ClearIcon } from 'v2/utils/form-helper';
import { createFolder, updateFolder } from 'actions/desk';
import './editFolder.scss'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { forwardRef } from 'react';

type EditFolderNameFormProps = {
    setFolderNameFlag: (val: boolean) => void;
    title: string;
    setHandleSubmit: any;
    folderId: string | number;
    toggleEditMode: () => void;
    ref: any;
}

const EditFolderNameForm: React.FC<EditFolderNameFormProps> = forwardRef(({ title, folderId, setFolderNameFlag, setHandleSubmit, toggleEditMode}, ref) => {
    const dispatch = useDispatch()
    const formRef = useRef<HTMLFormElement>(null)
    const [hasClear, setClear] = useState(false);
    const { values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue } = useFormik({
        validationSchema: validation,
        enableReinitialize: true,
        initialValues: { title: title },
        onSubmit: (values) => {
            if(folderId) {
                dispatch(updateFolder(folderId, values));
                toggleEditMode();
                return
            }
            dispatch(createFolder(values))
            // changeRoute && changeRoute('folders')

        }
    });
    useImperativeHandle(ref, () => ({ handleSubmit }), [values]);
    const onChange = (value = '') => {
        let flag = false;
        if (title) {
            if (title !== value) {
                flag = true;
            } else {
                flag = false;
            }
        } else {
            flag = true;
        }
        setFolderNameFlag(flag)
        setClear(value.length > 0)
    }

    const onClearClick = () => {
        setFieldValue("title", "")
        setClear(false)
    }
    return <form onSubmit={handleSubmit} className='flex vertical-align input-container'>
        <InputField
            label={null}
            type='text'
            name='title'
            placeholder='Enter a folder name'
            className='form-input full-width'
            showIcon={false}
            touched={touched.title}
            error={errors.title}
            reset={() => setFieldValue("title", "")}
            active={true}
            value={values.title}
            onChange={(e: any) => [onChange(e.target.value), handleChange(e)]}
            onBlur={handleBlur}
            disabled={false}
            autoComplete={"off"}
            onFocus={(e:any) => {
                e?.target?.value && setClear(true)
            }}
        />
        {hasClear && (
            <div onClick={onClearClick} className='flex-pull-right clear-input pointer'>
                <ClearIcon type='transparents' />
            </div>
        )}
    </form>

})

export default EditFolderNameForm;

let validation = Yup.object().shape({
    title: Yup.string().required("This field is required!")
});