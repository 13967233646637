import DeskHeader from 'components/desk-header/DeskHeaderContainer';
import DeskNavigationHeader from 'v2/components/navigation-header/DeskNavigationHeader';
import React, {Component} from 'react';
import ComingSoonComponent from 'components/coming-soon';
import {MarkingsIcon} from 'icons/PassiveIcons';
import '../DeskPage.scss';
/**
 * @deprecated TODO- delete not use any more
 */
export default class Markings extends Component {

  renderMarkings() {
    return <ComingSoonComponent
      title="Markings"
      text="Color-code key information and access all your highlights in one screen.">
      <div className="coming-soon-icon"><MarkingsIcon/></div>
    </ComingSoonComponent>;
  }
  render() {
    return (
      <div id="desk-page" className="page flashcard">
        <DeskNavigationHeader
          isCollapsed={this.props.isScrolled}
          tabTitle={'Desk'}
        />

        <DeskHeader title="Desk"/>

        <div className="page-body">
          <div>
            {this.renderMarkings()}
          </div>
        </div>

      </div>
    );
  }
}
