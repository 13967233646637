import './BackButton.scss';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {BackChevronIcon, BackChevronTurquoiseIcon} from 'icons/HeaderIcons';
import RouterLink from 'router/Link';

export default class BackButton extends PureComponent {
  renderArrow() {
    return this.props.inverted ? (
      <BackChevronIcon />
    ) : (
      <BackChevronTurquoiseIcon/>
    );
  }

  render() {
    const content = (
      <div className={'back-button back-icon-container'  + (this.props.inverted ? ' inverted' : '')} onClick={this.props.onClick} >
        {!this.props.hideArrow && this.renderArrow()}
        <span
          className={'back-title' + (this.props.inverted ? ' inverted' : '') + (this.props.hideArrow ? ' no-padding' : '')}>
          {this.props.text || this.props.children}</span>
      </div>
    );

    if (!this.props.linkTo) {
      return content;
    }

    return (
      <RouterLink to={this.props.linkTo}>
        {content}
      </RouterLink>
    );
  }
}

BackButton.defaultProps = {
  onClick: () => {
  }
};

BackButton.propTypes = {
  text: PropTypes.string,
  linkTo: PropTypes.string,
  inverted: PropTypes.bool,
  hideArrow: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};
