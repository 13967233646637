import {Map} from 'immutable';
export const quickMediaStatus = state => state.get('quickStatus');
export const quickStatusById = (state, id) => state.getIn(['quickStatus', parseInt(id)], Map({
    flashcard: Map({}),
    revision: Map({}),
    video: Map({}),
    exam:Map({freeResponse:Map({}),msqTotal:Map({})})
}));
export const firstExamsId =  state =>{return  state.get('quickStatus')}

export const examStatusById = (state, id) => state.getIn(['examStatus', id], Map({
    freeResponse: Map({}),
    mcqResponse: Map({}),
}));