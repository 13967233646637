import React, { useEffect } from "react"
import { setDocumentTitle } from 'actions/navigation'
import { useDispatch, useSelector } from 'react-redux'
import AddVoucher from "./AddVoucher"

type AddVoucherContainerProps = {
}

const AddVoucherContainer: React.FC<AddVoucherContainerProps> = ({ }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setDocumentTitle('Add Voucher'))

  }, [])

  return <AddVoucher />
}

export default AddVoucherContainer