import React from 'react';
import { ReadIcon } from 'icons/CardIcons';
import StudyStatsBarsContainer from 'v2/components/topics-tree/StudyStatsBarsContainer';
import classNames from 'classnames';
import Text from 'v2/components/common/Text'

import './statusBar.scss'

type StatusBarProps = {
    number: number;
    title: string;
    isRead: boolean;
    mediaType: string;
    icon?: React.ReactElement;
    statusData: any;
    page?: string;
    resultRange?: string;
    isSlider?: boolean,
    isSubtopic?: boolean,
    isActive?: boolean
}
const StatusBar: React.FC<StatusBarProps> = ({ number, title, statusData, isRead, mediaType, icon, page, resultRange, isSlider = false, isSubtopic, isActive }) => {
    return <>
        <div className='status-bar-content'>
            <div className={classNames('mr-5 w-54px', { 'ml-1.5': mediaType === 'exams' }, { 'ml-3': mediaType !== 'exams' })} >
                <div className={classNames('text-#2D3838 leading-23px text-17px font-bold')}>{number} {(isRead && number !== 0) && <ReadIcon />}</div>
                <div className='flex'>
                    {mediaType === "exams" && <div className='w-17px h-17px mt-px mr-1 mb-1'>
                        {icon}
                    </div>}
                    <div className={classNames('font-bold text-10px leading-[11px] text-#919191', { 'leading-[12px]': (!isSlider && mediaType !== 'exams') })}>{title}</div>
                </div>
                {(page === 'topic' && resultRange) ? <Text className={'text-#2D3838 leading-14px mt-1 !text-[10px] font-bold'}>{resultRange}</Text> : <></>}
            </div>
            {!isSlider && <StudyStatsBarsContainer statusBarData={statusData} mediaType={mediaType} isSubtopic={isSubtopic} isActive={isActive} />}
        </div>
        {isSlider && <StudyStatsBarsContainer statusBarData={statusData} mediaType={mediaType} isSubtopic={isSubtopic} isActive={isActive} />}
    </>
};
export default StatusBar;


