import React from 'react'
import { getUniqueId } from 'helpers';
import Text from 'v2/components/common/Text';
import StudyStatsBars from 'v2/ui-components/StudyStatsBars';

type StudyStatsBarsContainerProps = {
    statusBarData: any;
    isActive?: boolean;
    isSubtopic?: boolean;
    mediaType: string;
}  
const statusList = ['Neither', 'Unconfident', 'Confident', 'Incorrect', 'Correct'];
const StudyStatsBarsContainer: React.FC<StudyStatsBarsContainerProps> = ({statusBarData, isActive=false, isSubtopic=false, mediaType}) => {
    let allStatusBlank = true;
    if(mediaType === "flashcard" || mediaType === "exams"){
        statusBarData.forEach((item: any) => {
            if(statusList.indexOf(item.title) !== -1){
                if(item.value !== 0){
                    allStatusBlank = false;
                }
            }
        })
    }
    return <div className='flex-1'>
        <div className='flex justify-between flex-[3]'>
            {statusBarData.sort((a:any, b:any) => {
                if (a.value === 0 && b.value !== 0) return 1;
                if (b.value === 0 && a.value !== 0) return -1;
                return 0;
            }).map((statusData: any, index: number) => (
                <div className={`flex-${statusData?.value > 0 ? 1 : 0}`} key={index} >
                    <StatusBarInfo statusBarData={statusBarData} statusData={statusData} allStatusBlank={allStatusBlank} mediaType={mediaType}/>
                </div>
            ))}
        </div>
        <div className='pt-1'>
            <StudyStatsBars statusBarGraphData={statusBarData} isActive={isActive} isSubtopic={isSubtopic} />
        </div>
    </div>
}

export default StudyStatsBarsContainer;

const StatusBarInfo = ({statusBarData, statusData, allStatusBlank, mediaType}: {statusBarData: any, statusData: any, allStatusBlank: boolean, mediaType: string}) => {
    let isTopProgressBar = false;
    if(['Neither', 'Unconfident', 'Confident'].indexOf(statusData.title) !== -1 || ['Incorrect', 'Correct'].indexOf(statusData.title) !== -1){
        isTopProgressBar = true;
    }
    if(isTopProgressBar && statusData.value===0 && !allStatusBlank){
        return null;
    }
    let finalTitle = statusData.title;
    let finalValue = statusData.value;
    if(isTopProgressBar && allStatusBlank){
        if(statusData.title === 'Unconfident' || statusData.title === 'Incorrect'){
            finalTitle = "Studied";
            finalValue = 0;
        } else {
            return null;
        }
    }
    function getShortTitle(title: string){
        const neitherValue = statusBarData?.filter((item: any) => item?.title === 'Neither')[0]?.value;
        const confidentValue = statusBarData?.filter((item: any) => item?.title === 'Confident')[0]?.value;
        const uconfidentValue = statusBarData?.filter((item: any) => item?.title === 'Unconfident')[0]?.value;
        switch (title) {
            case 'Unconfident':
                if(neitherValue <= 0 || confidentValue <= 0) return "Unconfident";
                return "Unconf."
            case 'Confident':
                if(neitherValue <= 0 || uconfidentValue <= 0) return "Confident"
               return "Conf."
            default:
                return title
        }
    }
   
    return <>
        <div className='flex-1 max-h-[37px]'>
            <Text className={`text-17px font-bold leading-23px ${!allStatusBlank ? statusData.color : 'text-#2D3838'}`}>{finalValue}</Text>
            <Text className={`text-10px font-bold leading-14px !text-#919191`}>{getShortTitle(finalTitle)}</Text>
        </div>
    </>
}