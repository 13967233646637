import React, {useEffect, useState} from 'react'
import FilterHeader from './FilterHeader';
import './filter.scss'
import { useDispatch, useSelector } from 'react-redux';
import {getFlashcardById} from 'selectors/studying'
import {isNavigationSectionVisible} from 'selectors/navigation/navigationSelector'
import { isMobile, isLandscape, isMBFromSchool, isOSCUser, getActiveSubject, subjectsTeachingLevel, activeSubjectId } from 'selectors';
import { sortHiddenInfoOverlayQuestions } from 'helpers'
import { toggleNavigationSection,  setActiveFlashcardTab, setActiveMediaTab} from 'actions/navigation'
import { useHistory } from 'react-router-dom';
import { OSCIcon, ForwardChevronIcon ,IBIcon} from "icons/HeaderIcons"
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';

type HiddenInfoProps = {
    onClick?: (e: any) => void;
    onClose: () => void;
    currentId: string | number;
}  

const HiddenInfo: React.FC<HiddenInfoProps> = ({currentId, onClick, onClose}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [data, setData] = useState<any>([])
    const activeSubject = useSelector(getActiveSubject);
    const hasSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
    const hasActiveSubjectId = useSelector(activeSubjectId);
    const content = useSelector((state) => getFlashcardById(state, currentId)).toJS()
    const hasNavigation = useSelector(isNavigationSectionVisible)
    const hasMobile = useSelector(isMobile)
    const hasLandscape = useSelector(isLandscape)
    const hasMBFromSchool = useSelector(isMBFromSchool);
    const hasOSCUser = useSelector(isOSCUser)
    const checkMobilePortrait = (hasMobile && hasLandscape)
    const teachingTag = content?.teachingTag?.replace(/ /g, '');
    const questionTitle = content?.questionTitle
    const difficultyLevel = content?.difficultyLevel
    const tlvl = hasSubjectsTeachingLevel[`${hasActiveSubjectId}`];

    useEffect(() => {
      const value: any = [];
      content?.parentsData?.map((val: any) => {
        const parentsData = val.parentsData;
        let obj: any = {};
        parentsData.forEach((item: any) => {
            if(activeSubject?.tagging === "NEW_SUBJECT" && item?.type === "TEACHING_LEVEL" && tlvl !== item?.title) {
                return;
            }
          const topicNumber = topicsNumbering(parentsData);
          if (item.type === "SUB_TOPIC") {
            if (item.tltagging === "ALPHA") {
              obj["topicNumber"] =
                String.fromCharCode(parseInt(item?.topicIndex) + 64) +
                "." +
                item?.subTopicIndex;
            } else if (
              item.tltagging === "CUSTOM" &&
              ![null, "", "null"].includes(item?.customTopicNumbering)
            ) {
              obj["topicNumber"] =
                item?.customTopicNumbering?.at(0) + "." + item?.subTopicIndex ||
                "";
            } else {
              obj["topicNumber"] = `${topicNumber}.${item.numbering}`;
            }
            obj["topicsName"] = item.title;
            obj["id"] = item.id;
          }
          if (item.type === "TEACHING_LEVEL") {
            obj["tlvlTag"] = item.teachingTag;
          }
        });
        obj?.tlvlTag && value.push(obj);
      });
      const sortData = value?.sort(({ a, b }: { a: any; b: any }) =>
        a?.topicNumber > b?.topicNumber ? 1 : -1
      );
      let uniquePreviewData = [
        ...new Map(
          sortData.map((item: any) => [item["topicNumber"], item])
        ).values(),
      ];

      setData(sortHiddenInfoOverlayQuestions(uniquePreviewData));
    }, [content.id]);

    const topicsNumbering = (val: any) => {
        let topicNumber;
        val.forEach((item: any) => {
            if (item.type === 'TOPIC') {
                topicNumber = item.numbering;
            }
        })
        return topicNumber;
    }

    const onSubTopicClick = (subTopicId: number) => {
        if(!checkMobilePortrait){
            if (hasNavigation) {
                dispatch(toggleNavigationSection(true, true));
            }
            dispatch(setActiveFlashcardTab(0));
            history.push(`/flashcard/subtopic/${subTopicId}`);
            onClose()
            if(hasOSCUser || hasMBFromSchool) {
                dispatch(setActiveMediaTab('exams'));  
            }
            else {
                dispatch(setActiveMediaTab('flashcard'));
            }
        }
        onClose()
    }

    const formSource = () => {
        let val ;
        if(Object.keys(content?.experienceData).length > 0){
            val =  content?.experienceData.qb_code
        }
        switch (val) {
            case "ib_dp":
                return (<IBIcon />)
            case "osc_dp":
                return (<OSCIcon />)
            default:
                return (<OSCIcon />)
        }
    }

    return <div className='flex relative items-center justify-center h-full w-full overflow-hidden pt-14 pb-5 px-0' onClick={onClose}>
        <div className='filter-wrapper !relative' onClick={onClick}>
            <FilterHeader
                text={'Filter'} 
                title={'Hidden question info'} 
                hideArrow={false}
                onCloseClick={onClose} 
            />
            <div className='px-5 py-0'>
                <div className='relative'>
                    <div className='text-15px leading-19px text-#2D3838'>
                        You’ll not receive this info when sitting the actual exam, so we’re hiding it to give you a realistic exam impression. 
                    </div>
                    <div className='filter-active' />
                </div>
                <div className='bg-#EFF4F9 rounded-[10px] p-3.5 mx-0 my-4'>
                    <div className='text-#2D3838 opacity-90 text-15px leading-19px'>{questionTitle}</div>
                    <div className='pt-2'>
                        <div className='flex items-center'>
                            {formSource()}
                            <div className='pr-2' />
                                <TeachingLevelSeperator tlvl={teachingTag} tlvlTag={teachingTag} isExams={true} />
                            <div className='pr-2' />
                            <div className='text-13px text-[#979999] leading-16px'>{difficultyLevel}</div>
                        </div>
                    </div>
                </div>
                <div className='text-15px leading-5 text-#2D3838 py-2.5 px-0'>This question is part of:</div>
                <div className='pb-5 [&>:nth-child(1)]:mt-0'>
                    {data.map((value:any, index: number) => <div className='topic-wrapper' key={index} onClick={() => onSubTopicClick(value?.id)}>
                            <TeachingLevelSeperator tlvl={value?.tlvlTag} tlvlTag={value?.tlvlTag} slTag={value?.tlvlTag === "SL"} />
                            <div className='topic-number'>{value?.topicNumber}</div>
                            <div className={`topic-title`}>{value?.topicsName}</div>
                            <div className='flex items-center ml-auto'>
                                <ForwardChevronIcon color={'#BEC4C3'} />
                            </div>
                        </div>)}
                </div>
            </div>
        </div>
    </div>
}

export default HiddenInfo;