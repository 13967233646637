import classNames from 'classnames';
import React from 'react';
import './keyboard.scss';
import Text from 'v2/components/common/Text';
import PageOverlay  from 'v2/ui-components/PageOverlay';


type KeyboardShortcutProps = {
    btn: string;
    name: string;
    className: string;
}  

const KeyboardShortcut: React.FC<KeyboardShortcutProps> = ({name, btn, className}) => {
  return (
    <div className='flex items-center justify-between h-[49px]'>
        <Text className='text-15px text-#2D3838 leading-19px'>{name}</Text>
        <div className={classNames(className, 'key-name')}>{btn}</div>
    </div>
  )
}

export default KeyboardShortcut;