import React, { useEffect, useState } from "react"
import { setDocumentTitle } from "actions/navigation";
import { useDispatch, useSelector } from 'react-redux';
import '../ProfileDetailsPage.scss'
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import SaveButton from 'v2/components/navigation-header/SaveButton';
import BackToProfileButtonContainer from 'v2/pages/profile/BackToProfileButtonContainer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RenderInputField, WarnIcon } from 'v2/utils/form-helper';
import {changeUsername} from 'actions/api'
import { userDataSelector } from "selectors";

const ChangeUsernameContainer: React.FC = () => {
  const userData = useSelector(userDataSelector)?.toJS();

  const initialFormValue = {
    username: userData.username ||  '',
    password: ''
  }
  const [isFocused, setFocused] = useState({ username: false, password: false });
  const { values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue } = useFormik({
    validationSchema: validation,
    enableReinitialize: true,
    initialValues: initialFormValue,
    onSubmit: (values) => {
      disptach(changeUsername(values))
    }
  });

  const disptach = useDispatch();
  useEffect(() => {
    disptach(setDocumentTitle('Change username'))
  }, [])

  const invalid = !(values.username.length > 0 && values.password.length)

  return <div className="profile-details-page">
    <NavigationHeader extendedWidth title="Username" leftSection={<BackToProfileButtonContainer />} rightSection={(<SaveButton disabled={invalid} onClick={handleSubmit} />)} />
    <div className='profile-details-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-input-wrapper'>
          <RenderInputField
            label={'Username'}
            type='text'
            name='username'
            placeholder='Enter your new username'
            className='form-input'
            showIcon={true}
            touched={touched.username}
            error={errors.username}
            reset={() => setFieldValue("username", "")}
            active={isFocused.username}
            value={values.username}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ username: true, password: false }
              )
            }}
          />
        </div>
        <div className='form-input-wrapper'>
          <RenderInputField
            label='Password'
            type='password'
            name='password'
            placeholder='Enter your current password'
            className='form-input'
            showIcon={true}
            touched={touched.password}
            error={errors.password}
            reset={() => setFieldValue("password", "")}
            active={isFocused.password}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={false}
            autoComplete={false}
            onFocus={() => {
              setFocused({ username: false, password: true }
              )
            }}
          />
        </div>
      </form>
    </div>
  </div>


}

let validation = Yup.object().shape({
  username: Yup.string().required("This field is required!"),
  password: Yup.string().required("This field is required!"),
});
export default ChangeUsernameContainer