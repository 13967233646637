import { videoProgressById, videoPlayingStatusById } from 'selectors/videoProgressSelectors';
import { useSelector } from 'react-redux';
import { activeSubjectId } from 'selectors';
import classNames from 'classnames';
import CheckRadioButton from 'v2/components/check-radio-button/CheckRadioButton';
import Text from "v2/components/common/Text";
import { NoteListIcon, PlayOnThumbnailIcon } from 'icons/FlashcardIcons';
import MarkText from 'v2/ui-components/MarkText'
import TeachingLevelSeperator from "v2/components/teaching-level-selector/TeachingLevelSeperator";


type VideoPreviewListItemProps = {
  id: number,
  isEditing?: boolean,
  videoNumber: number,
  active: boolean,
  onClick: () => void,
  title: string,
  totalDuration: number,
  totalWatched: number,
  totalDurationInSeconds: number,
  totalWatchedInSeconds: number,
  deleted: boolean,
  shouldMark: boolean,
  listOfCheckedItems: any,
  rightSection: React.ReactNode,
  type?: string,
  thumbImage: string,
  shortDescription?: string | null
  nodeTeachingTag?:string
  teachingLevel:string
  teachingTag?:string

}

const VideoPreviewListItem: React.FC<VideoPreviewListItemProps> = ({ shortDescription = '', id, onClick, deleted, type, active, isEditing, listOfCheckedItems, thumbImage, rightSection, videoNumber, title, totalDuration, shouldMark, nodeTeachingTag, teachingLevel, teachingTag }) => {
  const videoProgress = useSelector((state) => videoProgressById(state, id)).toJS();
  const videoPlayingStatus = useSelector((state) => videoPlayingStatusById(state, id)).toJS();
  const subjectId = useSelector(activeSubjectId);
  let preview: any = shortDescription?.trim();
  const parser = new DOMParser();
  preview = parser.parseFromString(`<!doctype html><body>${preview}`, 'text/html').body.textContent;
  const size = videoNumber?.toString().length

  function secondToTime(timeInseconds: string) {
    let totalWatched
    let d = Number(timeInseconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h : 0;
    var mDisplay = m > 0 ? m > 9 ? m : '0' + m : '00';
    var sDisplay = s > 0 ? s > 9 ? s : '0' + s : '00';
    if (hDisplay > 0) {
      totalWatched = hDisplay + ':' + mDisplay + ':' + sDisplay;
    } else {
      totalWatched = mDisplay + ':' + sDisplay;
    }
    return totalWatched
  }
  let progressPecentage: number = 0
  if (Number(videoProgress.totalTime) && Number(videoProgress.currentTime)) {
    let totalDurationSeconds = Number(videoProgress.totalTime)
    let totalWatchedSeconds = Number(videoProgress.currentTime)
    progressPecentage = (totalWatchedSeconds / totalDurationSeconds) * 100;
  }
  return <div onClick={onClick} className={classNames('relative cursor-pointer px-5 py-2 flex items-center revision-preview-list list-hover', { 'delete-animation': deleted, 'h-[150px]': type === "notes",'h-[92px]':type !== 'notes', 'bg-#E5ECF2 active-list-hover': active })}>
    {isEditing && <CheckRadioButton itemId={id} listOfCheckedItems={listOfCheckedItems} />}
    <div className={classNames('break-words text-15px leading-19px text-#727373', { 'h-[134px]': type === 'notes' })}>
      <div className='flex items-center'>
        <div className='h-[76px] rounded-10px w-[133px] min-w-[133px] relative'>
          {[14982, 15075].includes(subjectId) &&
            <>
              <div className='left-overlay-section' />
              <div className='right-overlay-section' />
            </>
          }
          <img src={thumbImage} className='rounded-10px bg-#378ef826 h-full w-full' alt=''/>
          <div className='video-progress-bar'
            style={{ borderBottomRightRadius: progressPecentage >= 96 || progressPecentage! <= 6 ? (progressPecentage > 98 ? '10px' : "8px") : '0px', width: `${progressPecentage}%` }} />
        </div>
        <div className='h-[76px] rounded-10px w-[133px] min-w-[133px] absolute bg-#378ef826 flex items-center justify-center'>
          {!videoPlayingStatus.playing && <PlayOnThumbnailIcon />}
        </div>

        <div className='flex'>
          {type === 'notes' && <div className={classNames('ml-auto flex pl-[13px] absolute right-0 pr-5', { 'items-center pl-0 mt-5px': type === 'notes' })} >{rightSection}</div>}
          <div className={classNames('flex justify-center px-2  font-bold text-15px leading-19px text-#2D3838 w-7', { '!w-35px': size == 2, '!w-42px': size == 3, 'leading-[17px]':!!nodeTeachingTag, 'leading-19px':!nodeTeachingTag })} > {videoNumber}</div>
          <div className={classNames('flex flex-col', { 'w-[170px]': !isEditing })}>
            <div className='flex'>
              <div className={classNames('video-text',{'!leading-[17px]':!!nodeTeachingTag})}>{title} </div>
              {type !== 'notes' && <div className={classNames('ml-auto flex pl-[13px] absolute right-0 pr-5', { 'items-center pl-0 mt-5px': type === 'notes' })} >{rightSection}</div>}
            </div>
            <div className='flex mt-1'>
              <Text className='text-13px leading-19px text-#2D3838' >{videoProgress.currentTime ? secondToTime(videoProgress.currentTime) : '0:00'}</Text>
              <div className='px-0.5'>/</div>
              <Text className='text-13px leading-19px text-#919191' >{totalDuration}</Text>
            </div>
            {nodeTeachingTag && (
              <div className="">
                <TeachingLevelSeperator
                  tlvl={teachingLevel}
                  tlvlTag={nodeTeachingTag}
                  size="small"
                  slTag={teachingTag === "SL"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {type === 'notes' && <div className='flex mt-4 items-center'>
        <div className='flex items-center mr-2'><NoteListIcon /></div>
        <div className='video-mark-text'><MarkText shouldMark={shouldMark} className="note-short-desc" >{preview}</MarkText></div>
      </div>}
    </div>
  </div>
}


export default VideoPreviewListItem;