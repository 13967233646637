import HorizontalSeparator from 'components/horizontal-separator/HorizontalSeparator';
import {getMBUrlBasedOnRegion} from 'helpers';
import * as FlashcardIcons from 'icons/FlashcardIcons';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {isMBUserSelector, userDataSelector} from 'selectors';
import * as Selectors from 'selectors';

class SupportContainer extends Component {
  constructor(props) {
    super(props);

    this.computeErrorSubject = this.computeErrorSubject.bind(this);
  }

  computeErrorSubject() {
    const {activeSubject, activeTeachingLevel, currentFlashcard} = this.props;

    if (currentFlashcard.size === 0) return null;

    const currentFlashcardNo = currentFlashcard.get('numbering');
    const activeTopic = currentFlashcard.get('parentsData').find(item => item.get('type') === 'TOPIC').get('title');
    const subjectTitle = activeSubject.get('title');
    const teachingLevelTitle = activeSubject.get('children').find(item => item.get('id') === activeTeachingLevel).get('title');

    return `Subject=Error report on subject: ${subjectTitle}, package: ${teachingLevelTitle}, topic: ${activeTopic}, card #${currentFlashcardNo}`;
  }

  render() {
    const helpURL = this.props.isMBUser
      ? `https://${this.props.userData.get('subdomain')}.${getMBUrlBasedOnRegion(this.props.userData.get('region'))}/help`
      : 'https://www.smart-prep.com/pages/sp-ib-app-faqs';

    return (
      <div className="pop-up-container support-container">
        <div className="pop-up-title-container">
          <span className="pop-up-icon"><FlashcardIcons.SupportIcon/></span>
          <span className="pop-up-title"> Support</span>
        </div>
        <div className="subject-title"></div>


        <div className="pop-up-list-item"><span className="pop-up-icon"><FlashcardIcons.ReportErrorIcon/></span> <a
          href={`mailto:osc@oxfordstudycourses.com?${this.computeErrorSubject()}`} target="_top">Report an error</a></div>
        <HorizontalSeparator className="items-separator"/>
        <div className="pop-up-list-item">
          <span className="pop-up-icon"><FlashcardIcons.QuestionsIcon/></span>
          <a href={helpURL} target="_blank">Help & FAQs</a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeSubject: Selectors.getActiveSubject(state),
  activeTeachingLevel: Selectors.getActiveTeachingLevel(state),
  currentFlashcard: Selectors.Studying.currentFlashcard(state),
  isMBUser: isMBUserSelector(state),
  userData: userDataSelector(state),
});

export default connect(mapStateToProps, {})(SupportContainer);
