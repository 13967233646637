import { difficultyFilter, paperFilter, sourceFilter, topicTeachingFilter } from 'helpers';
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getActiveSubject, getActiveTeachingLevel, subjectsTeachingLevel } from 'selectors/subject/subjectSelector';
import { flashcardsSearchResponse } from 'selectors/api/apiSelector';
import { examsofFilterItems } from 'selectors/desk/deskSelector';
import { getActiveMediaTab, isExamsMode } from 'selectors/navigation/navigationSelector';
import { listOfFilterCheckedItems } from 'selectors/desk/deskSelector';
import { activeSearchTerm } from 'selectors/searchSelectors';
import { CardsIcon, RevisionsIcon, VideosIcon, ExamsIcon } from 'icons/SearchIcons';
import { setActiveSearchTab } from 'actions/search';
import { searchFlashcards } from 'actions/api';
import ExamsFilterTile from "v2/components/filter-overlay/exams-filter-container/ExamsFilterTile";
import MCQFilterLayout from 'v2/components/filter-overlay/exams-filter-container/MCQFilterLayout';
import MircoFilterLayout from 'v2/components/studying/microFilterContainer/MicroFilterLayout';
import ExamsModeNoResults from "v2/ui-components/search-results/ExamsModeNoResults";
import FlashcardResultsList from 'v2/ui-components/search-results/FlashcardResultsList';
import NoResults from 'ui-components/search-results/NoResults';
import EmptyScreenMessage from 'v2/components/empty-screen-message/EmptyScreenMessage';
import { FilterIcon } from 'icons/HeaderIcons';
import { MainSeparator } from 'ui-components/pageComponents';
import usePrevious from 'hooks/usePrevious';
import useAnswerStatus from 'hooks/useAnswerStatus';
import SearchResultsHeader from 'v2/pages/search/SearchResultsHeader';
import RevisionResultsList from 'v2/ui-components/search-results/RevisionResultsList';
import { filterResultsListToArray, questionTypeFind, returnExamAnswerFilter, teachingFilterResultsListToArray, transformResultsListToArray } from 'v2/helpers';
import VideoResultsList from './VideoResultsList';
import ExamsResultsList from './ExamsResultsList';

type FlashcardResultsContainerProps = {
  hidden: boolean;
  isPartial: boolean;
}

const FlashcardResultsContainer: React.FC<FlashcardResultsContainerProps> = ({ hidden, isPartial = false }) => {
  const dispatch = useDispatch();
  const getExamsofFilterItems = useSelector(examsofFilterItems).toJS();
  const flashcards = useSelector(flashcardsSearchResponse).toJS();
  const subjectsTLevel = useSelector(subjectsTeachingLevel);
  const activeSubject = useSelector(getActiveSubject);
  const activeMediaTab = useSelector(getActiveMediaTab);
  const getListOfFilterCheckedItems = useSelector(listOfFilterCheckedItems).toJS();
  const hasExamsMode = useSelector(isExamsMode);
  const searchTerm = useSelector(activeSearchTerm);
  const activeTeachingLvl = useSelector(getActiveTeachingLevel);
  const prevSearchTerm = usePrevious(searchTerm);
  const prevTeachingLvl = usePrevious(activeTeachingLvl);
  const prevMediaTab = usePrevious(activeMediaTab);
  const prevSubjectsTLevel = usePrevious(subjectsTLevel);
  const requestSucceeded = flashcards.succeeded;
  const requestLoading = flashcards.loading;
  const requestData = flashcards.data;
  const teachingTag = subjectsTLevel[`${activeSubject.id}`];
  const resultData = requestData && teachingFilterResultsListToArray(requestData, teachingTag, activeSubject);
  const sourceData = resultData && sourceFilter(resultData, getExamsofFilterItems?.source, activeMediaTab);
  const paperData = resultData && paperFilter(sourceData, getExamsofFilterItems?.paper, activeMediaTab);
  const difficultyData = resultData && difficultyFilter(paperData, getExamsofFilterItems?.difficulty, activeMediaTab);
  const teachingTagData = resultData && topicTeachingFilter(teachingTag, difficultyData, getExamsofFilterItems?.teachingLevel, "topics", activeMediaTab)
  const multipleChooseData = activeMediaTab === 'exams' && resultData && questionTypeFind(teachingTagData) && questionTypeFind(teachingTagData)?.mcq;
  const freeResponseData = activeMediaTab === 'exams' && resultData && questionTypeFind(teachingTagData) && questionTypeFind(teachingTagData)?.group;
  const examAnswerFilter = activeMediaTab === 'exams' && resultData && returnExamAnswerFilter(multipleChooseData && multipleChooseData, freeResponseData && freeResponseData, getExamsofFilterItems?.mcq, getListOfFilterCheckedItems[activeMediaTab], activeMediaTab, teachingTagData);
  const sortData = activeMediaTab === 'exams' && resultData && examAnswerFilter.length > 0 ? examAnswerFilter.sort((a: any, b: any) => {
    const indexA = a.numbering;
    const indexB = b.numbering;
    if (indexA < indexB) return -1;
    return 1;
  }) : [];
  const newFilterItems = resultData && filterResultsListToArray(activeMediaTab === 'exams' ? sortData : resultData, activeMediaTab, getListOfFilterCheckedItems[activeMediaTab]);
  const newListItems = resultData ? transformResultsListToArray(newFilterItems) : [];
  const hasData = resultData && resultData.length > 0;

  const searchActiveIcon = () => {
    switch (activeMediaTab) {
      case "flashcard":
        return ({ "title": "Flashcards", "active": "Cards", "icon": <CardsIcon color={'#2D3838'} /> })
      case "revision":
        return ({ "title": "Revision guide", "active": "Revisions", "icon": <RevisionsIcon color={'#2D3838'} /> })
      case "video":
        return ({ "title": "Videos", "active": "Videos", "icon": <VideosIcon color={'#2D3838'} id="video-default" /> })
      case "exams":
        return ({ "title": "Exams", "active": "Exams", "icon": <ExamsIcon color={'#2D3838'} /> })
      default:
        return ({ "title": "", "active": "", "icon": null })
    }
  }
  
  useEffect(() => {
    searchTerm && startSearch(0);
  }, [])

  useEffect(() => {
    if (prevSearchTerm !== searchTerm || prevTeachingLvl !== activeTeachingLvl || prevMediaTab !== activeMediaTab || prevSubjectsTLevel !== subjectsTLevel) {
      startSearch(0);
    }
  }, [searchTerm, prevSearchTerm, activeTeachingLvl, prevTeachingLvl, activeMediaTab, prevMediaTab, subjectsTLevel, prevSubjectsTLevel])
  const startSearch = (page: number) => {      
    const queryParams: { [key: string]: any } = { searchField: searchTerm, subjectId: activeSubject.id, page: page };
    if (isPartial) {
      queryParams.limit = 3;
    }
    dispatch(searchFlashcards(queryParams));
  };
  const renderList = useCallback((newListItems: any, filterFlashcardId: any) =>{
    switch (activeMediaTab) {
      case 'flashcard':
        return <FlashcardResultsList newListItems={newListItems} shouldMark={true} filterFlashcardId={filterFlashcardId} /> 
      case "revision":
        return <RevisionResultsList newListItems={newListItems} shouldMark={true} filterFlashcardId={filterFlashcardId} mediaType={activeMediaTab}/>
      case "video":
        return <VideoResultsList newListItems={newListItems} shouldMark={true} filterFlashcardId={filterFlashcardId} mediaType={activeMediaTab}/>
      case "exams":
        return <ExamsResultsList newListItems={newListItems} shouldMark={true} onListItemSelected={null} mediaType={activeMediaTab}  />;
      default:
        return null
    }
  }, [activeMediaTab])

  const { filterFlashcardId } = useAnswerStatus(() => startSearch(0));
  if (hidden) return null;
  return <>
      <SearchResultsHeader show={hasData} title={searchActiveIcon().title} icon={searchActiveIcon().icon} showMore={isPartial} total={flashcards.count} onClick={() => dispatch(setActiveSearchTab('Cards'))} />
      {hasData && <>
        {activeMediaTab === 'exams' ? <div className='pt-2'>
          <ExamsFilterTile type={"filterSearch"} />
          {!hasExamsMode && <>
            <div className='pt-6 px-5 flex items-center pb-6'><MCQFilterLayout formCC={false} isEditMode={false}/> <MircoFilterLayout page={"exercise"} /></div>
          </>}
        </div> :
          <div className='pb-6'>
            <MircoFilterLayout className='mt-2' />
          </div>}
      </>}
      {(!requestLoading && hasExamsMode && activeMediaTab === 'exams') ? <ExamsModeNoResults /> : <>
        {(requestSucceeded && !isPartial && !hasData) ? <NoResults /> : (!requestLoading && newListItems.length === 0) ? <>
          <div className='pt-[38px]'>
            <EmptyScreenMessage type={"filterSearch"} mediaType={activeMediaTab} className={"empty-topics-screen"}><FilterIcon /></EmptyScreenMessage>
          </div>
        </> : <>{renderList(newListItems, filterFlashcardId)}</>}
      </>}
      {!requestLoading && isPartial && hasData && <MainSeparator />}
    </>
}

export default FlashcardResultsContainer