import {authenticate as authenticateAction} from 'actions/api';
import {changeMenuPathAction} from 'actions/menuActions';
import {openMenu} from 'actions/navigation';
import {showAlert} from 'actions/notification';
import appHistory from 'appHistory';
import ErrorNotification from 'v2/components/notifications/ErrorNotification';
import {decodeQuery} from 'helpers';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Light} from 'ui-components/helpers/common';
import Padding from 'ui-components/helpers/Padding';

export class ExternalAuthPage extends PureComponent {
  componentDidMount() {
    const query = decodeQuery(this.props.location.search.substring(1));
    const token = this.props.match.params.token;
    let error = query.error;
    let accessTokenData;
   
    if (token) {
      try {
        accessTokenData = this.decodeTokenData(token);
      } catch (e) {
        error = 'INVALID_TOKEN';
      }
    }

    if (error || !token) {
      this.handleErrors(error);
      return;
    }

    this.completeLogin(accessTokenData);
  }

  completeLogin(accessTokenData) {
    const redirectUrl = localStorage.getItem('smpp-redirect-url');
    // const {location} = this.props;
    // let redirectUrl;
    // if(location.state && location.state.from && location.state.from.pathname){
    //   redirectUrl = location.state.from.pathname;
    // }
    if (redirectUrl) localStorage.removeItem('smpp-redirect-url');
    this.props.authenticate({}, accessTokenData, redirectUrl);
  }

  handleErrors(error) {
    switch (error) {
      case 'AUTHENTICATION_FAILED_IDENTITY_ID_ALREADY_USED':
        appHistory.replace('/flashcard/topics');
        this.props.openMenu();
        this.props.changeMenuPath('profile');
        this.props.showAlert({
          content: (
            <ErrorNotification hasContact={true} contactSection={(
              <Padding top={16}>
                <div>Please contact us at:</div>
                <div><a href='mailto:osc@oxfordstudycourses.com'><b>osc@oxfordstudycourses.com</b></a></div>
              </Padding>
            )} text={<span>The ManageBac account used was already merged with a <Light>SMART</Light><b>PREP</b> account.</span>}/>
          ),
          buttons: false
        });
        break;
      case 'INVALID_TOKEN':
        this.props.showAlert({
          content: (
            <ErrorNotification hasContact={false} text={'The token provided is invalid.'}/>
          ),
          buttons: false
        });
        appHistory.replace('/login');
        break;
      default:
        appHistory.replace('/login');
        break;
    }
  }

  decodeTokenData(token) {
    return JSON.parse(window.atob(token));
  }

  render() {
    //TODO should add the gradient loading screen
    return (<div/>);
  }
}

ExternalAuthPage.propTypes = {
  match: PropTypes.object,

  // WithRouter
  location: PropTypes.object.isRequired,

  // Connect
  authenticate: PropTypes.func.isRequired,
  openMenu: PropTypes.func.isRequired,
  changeMenuPath: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  authenticate: authenticateAction,
  openMenu: openMenu,
  changeMenuPath: changeMenuPathAction,
  showAlert: showAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExternalAuthPage));
