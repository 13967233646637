import React, { useEffect, useMemo, useRef } from 'react';
import './searchPage.scss';
import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2';
import ExercisesResultsContainer from 'v2/ui-components/search-results/ExercisesResultsContainer';
import FlashcardResultsContainer from 'v2/ui-components/search-results/FlashcardResultsContainer';
import FoldersResultsListContainer from 'v2/ui-components/search-results/FoldersResultsListContainer';
import { NoResultsMaths } from 'ui-components/search-results/NoResults';
import NotesResultsListContainer from 'v2/ui-components/search-results/NotesResultsListContainer';
import EmptyScreenMessage from 'v2/components/empty-screen-message/EmptyScreenMessage';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import useScrollDetection from "hooks/useScrollDetection";
import SearchInputContainer from 'v2/pages/search/SearchInputContainer';
import SearchTabs from 'v2/pages/search/SearchTabs';
import classNames from 'classnames';
import MediaTypeTabSelector from 'v2/components/media-tab/MediaTypeTabSelector';
import Overlay from 'v2/pages/Overlay';
import List from 'v2/ui-components/List';
import { ReactComponent as SearchIcon } from 'assets/images/search/no-search-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { isMBFromSchool, isOSCUser } from 'selectors/user';
import { toggleExamsMode } from 'actions/navigation';
import { apiWaitingResponse } from 'selectors'

type SearchPageComponentProps = {
  searchFieldValue: any;
  clearRecentSearches: () => void;
  search: (arg: any) => void;
  recentSearches: any;
  activeSearchTab: string;
  isLoading: boolean
  isRecentSearchesLoading: boolean
  teachingLevelsForActiveSubject: any;
  showResults: any;
  results: any;
  showOverlay: boolean
  clearSearch: () => void;
  activeSearchTerm: string
  activeSubject: any
  updateRecentSearches: (val: any) => void
  activeMediaTab: any

}

const SearchPageComponent: React.FC<SearchPageComponentProps> = ({ search, activeSubject, recentSearches, updateRecentSearches, isLoading, activeMediaTab, activeSearchTab, showResults, searchFieldValue, teachingLevelsForActiveSubject, activeSearchTerm, clearSearch, showOverlay, isRecentSearchesLoading, clearRecentSearches }) => {
  let wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { isScrolled, isScrolled2, bindScrollRef } = useScrollDetection({ WrappedRef: wrapperRef, threshold1: 20 });
  const hasMBFromSchool = useSelector(isMBFromSchool);
  const hasApiResponseLoading = useSelector(apiWaitingResponse)
  const hasOSCUser = useSelector(isOSCUser)
  const onSearchItemSelect = (item: any) => {
    search({ subjectId: activeSubject.id, searchField: item });
  };
  const onSearchItemDeteled = (item: any) => {
    const selectedItemIndex = recentSearches.indexOf(item);
    const recentSearchesCopy = [...recentSearches];
    recentSearchesCopy.splice(selectedItemIndex, 1);
    updateRecentSearches({ subjectId: activeSubject.id, searchedItems: recentSearchesCopy });
  };
  const isMathematics = useMemo(() => {
    return activeSubject.slug === 'mathematics';
  }, [activeSubject]);

  useEffect(() => {
    if (!(hasOSCUser || hasMBFromSchool)) {
      dispatch(toggleExamsMode(true))
    }
  },[dispatch, hasMBFromSchool, hasOSCUser])

  const renderSearchPageContents = useMemo(() => {
    switch (activeSearchTab) {
      case 'All':
        return <>
          {!isMathematics && <FlashcardResultsContainer isPartial hidden={isLoading} />}
          <NotesResultsListContainer isPartial hidden={isLoading} />
          <FoldersResultsListContainer isPartial hidden={isLoading} />
          {activeMediaTab === "flashcard" && <ExercisesResultsContainer isPartial hidden={isLoading} />}
        </>;

      case 'Cards':
        if (isMathematics) return <NoResultsMaths />;
        return <FlashcardResultsContainer isPartial={false} hidden={false} />;
      case 'Notes':
        return <NotesResultsListContainer />;
      case 'Folders':
        return <FoldersResultsListContainer />;
      case 'Exercises':
        return <>{activeMediaTab === "flashcard" && <ExercisesResultsContainer />}</>;
      default:
        return null;
    }
  }, [activeSearchTab, isLoading, activeMediaTab]);

  const renderTeachingLevelSelector = useMemo(() => {
    if (activeSearchTab === 'Folders' || activeSearchTab === 'Exercises')
      return null;
    // return showResults && <HeaderStyledSearchTeachingLevelContainer/>;
    return showResults && <div />;

  }, [activeSearchTab]);
  const bindRef = (ref: any) => {
    wrapperRef = ref;
    bindScrollRef(ref);
  };
  return <>
    <NavigationHeader
      inverted={true}
      isSearchPage={isScrolled2 ? false : true}
      isSearch={searchFieldValue ? false : true}
      isCollapsed={isScrolled}
      text={searchFieldValue || 'Search'}
      shouldExtendHeight={isScrolled2}
      teachingLevelsForActiveSubject={teachingLevelsForActiveSubject.length === 2 ? true : false}
      bottomSection={<>
        <div className='pt-3' />
        {isScrolled && <SearchTabs />}
      </>}
      extendedHeight={teachingLevelsForActiveSubject.length === 2 ? false : true}
      rightSection={isScrolled && !!activeSearchTerm && <div className='search-close cancel-btn text-[13px] leading-[18px] text-right tracking-2px uppercase text-white cursor-pointer' onClick={clearSearch}>Cancel</div>} />
    <div className={classNames('pt-14 pb-[150px] h-full search-overlay', { 'flex flex-col': !(recentSearches.length > 0), '!overflow-hidden':showOverlay })} id='search-page' ref={bindRef}>
      <div className="header">
        <div className='items-baseline justify-between'>
          <SearchInputContainer showResults={showResults} />
        </div>
        <MediaTypeTabSelector page={'search-page'} subjectId={activeSubject.id} />
        <div className='pt-3' />
        <SearchTabs />
      </div>
      <div className='relative'>
        <Overlay overlay={showOverlay}  className={showOverlay && '!top-[295px] animate-overlayFadeIn'}/>
        <>
          {!hasApiResponseLoading && showResults && renderSearchPageContents}
          {(isRecentSearchesLoading || isLoading || (isRecentSearchesLoading && hasApiResponseLoading)) &&
            <>
              <div className='pt-[100px]' />
              <div className='justify-center flex' >
                <Loader background={BackgroundEnum.Blue} size={SizeEnum.Large} />
              </div>
            </>}
          {!isRecentSearchesLoading && !showResults && recentSearches.length > 0 &&
            <List title={'Recent terms'} buttonText={'Clear'} buttonCallback={clearRecentSearches}
              itemCallback={onSearchItemSelect}
              isItemDeletable={true}
              deleteCallback={onSearchItemDeteled}
              items={recentSearches} />}
        </>
      </div>
      {!isRecentSearchesLoading && recentSearches.length === 0 && (
        <>
          <EmptyScreenMessage mediaType={activeMediaTab} type='search' isSmart={true} className={'empty-search-screen'}>
            <SearchIcon />
          </EmptyScreenMessage>
        </>)}
    </div>
  </>
}


export default SearchPageComponent;