import React, { memo, useEffect, useRef, useState } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import TextareaAutosize from 'react-autosize-textarea';
import MarkText from 'v2/ui-components/MarkText';
import classNames from 'classnames';
import './noteEditor.scss';


type NoteEditorProps = {
    onTextareaChange: (e: any) => void;
    innerRef: any;
    noteText: string | number;
    currentId: number | string;
    toggleFocusTextarea: (val: boolean) => void;
    searchGoBack: string;
    disable: boolean | undefined;
} 

const NoteEditor: React.FC<NoteEditorProps> = ({innerRef, noteText, searchGoBack, toggleFocusTextarea, onTextareaChange, disable}) => {
    const [isTextAreaShow, setTextAreaShow] = useState(false);
    let textareaRef = useRef<HTMLTextAreaElement>(null);
    const setTextAreaVisible = (e: any) => {
        if (isTextAreaShow) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            toggleFocusTextarea(true);
            setTextAreaShow(true)
        }
    }
    useEffect(() => {
        if (isTextAreaShow) {
            textareaRef.current?.focus();
          return
        }
    }, [isTextAreaShow])

    const handleEsc = (event: any) => {
        if (isTextAreaShow) {
            textareaRef.current?.blur();
        } else {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    const toggleTextareaVisibility = () => {
        setTextAreaShow(prevState => {
            toggleFocusTextarea(!prevState)
            return !prevState ;
        })
    };

    return <div className='note-editor' ref={innerRef} onClick={(e: any) => setTextAreaVisible(e)}>
       <KeyboardEventHandler handleKeys={['esc']} handleFocusableElements={true} onKeyEvent={handleEsc} />
       {isTextAreaShow && <div className={'editor-focused-marker'}/>}
       {/* @ts-ignore */}
       {isTextAreaShow ? <TextareaAutosize 
            ref={textareaRef}
            placeholder="Add a new note..."
            id="flashcard-note"
            name="flashcardNote"
            value={noteText}
            onChange={onTextareaChange}
            onBlur={toggleTextareaVisibility}
            disabled={disable}
        /> : <MarkText markType={'span'} shouldMark={searchGoBack}>
                <div className={classNames('edit-text-note', {'edit-text-focus !text-[#b1b6b5]' : (!isTextAreaShow && !noteText)})}>
                    {noteText || 'Add a new note...'}
                </div>
            </MarkText>}
    </div>;
}

export default memo(NoteEditor);