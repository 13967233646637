import classNames from 'classnames';
import {DeleteTrashIcon} from 'icons/FlashcardNavIcons';
type FooterEditModeProps = {
    hasDeselect: boolean;
    deselectAll: () => void,
    selectAll: () => void,
    onDelete: () => void,
    canDelete: boolean,
    editMode: boolean
}

const FooterEditMode: React.FC<FooterEditModeProps> = ({ hasDeselect, canDelete, onDelete, deselectAll, selectAll, editMode }) => {
    return <div className={classNames('footer-edit-mode', { 'opacity-50 pointer-events-auto': editMode })}>
        {hasDeselect ?
            <div className="footer-label" onClick={deselectAll}>Deselect all</div>
            :
            <div className="footer-label" onClick={selectAll}>Select all</div>
        }
        <div onClick={onDelete} className={`delete-btn ${canDelete ? '' : 'inactive'}`}>
            <DeleteTrashIcon opacity={canDelete ? '1' : '0.4'} />
        </div>
    </div>
}

export default FooterEditMode