import React from 'react';
import styled, {css} from 'styled-components/macro';
import Col from 'ui-components/helpers/Col';
import {Relative} from 'ui-components/helpers/common';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';
import Text from 'ui-components/helpers/Text';

const StatsBar = ({title, percentage}) => {
  return <StyledStatsBar as={Col}>
    <Relative>
      <BarLine className={'bar-line-bg'} percentage={percentage}/>
      <BarLine absolute percentage={percentage}/>
    </Relative>
    <Padding top={4} bottom={0}>
      <Row justifyContent={'space-between'} alignItems={'center'}>
        <Text size={13} lineHeight={'16px'} letterSpacing={0} color={'#979999'}>{title}</Text>
        {percentage && <StatsBarPercentageText percentage={percentage}>{percentage.toFixed(0)}%</StatsBarPercentageText>}
      </Row>
    </Padding>
  </StyledStatsBar>;
};

const StyledStatsBar = styled.div`
  ${Row} & {
   width: 48%; 
  }
`;

const StatsBarPercentageText = styled.div`
  background: ${props => computeColor(props.percentage)};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-family: 'Open Sans';
  font-size: 13px;
  letter-spacing: 0;
`;

const computeColor = percentage => {
  if (percentage === 0) return '#A2A3A3';
  if (percentage < 40) return 'linear-gradient(-145deg, #FC4B07 0%, #FC1691 100%);';
  if (percentage >= 80) return 'linear-gradient(-135deg, #51D378 0%, #51D4B3 100%);';
  return 'linear-gradient(-135deg, #FE9200 0%, #FAA201 100%);';
};

const BarLine = styled.div`
  height: 3px;
  border-radius: 3px;
  
  background: #EFF0F0;
  
  transition: 1s ease-in width;
  
  ${props => props.absolute && css`
    position: absolute;
    top:  0;
    background: ${props => computeColor(props.percentage)};
    ${({percentage}) => percentage >= 0 && css`
      width: ${percentage > 0 ? percentage : 1}%;
    `}
  `}
`;

export default StatsBar;
