import ExternalAvatar from 'components/avatar/ExternalAvatar';
import InternalAvatarContainer from 'components/avatar/InternalAvatarContainer';
import {Map} from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {isMBUserSelector, userDataSelector} from 'selectors/user';

class UserAvatarContainer extends Component {
  render() {
    const {userData, isMBUser, alternate, size, initialFlashcard, accountPortal} = this.props;
    if (accountPortal ? isMBUser : isMBUser && userData.get('fromSchool') === "YES") {
      const fullName = userData.get('firstName') + ' ' + userData.get('lastName');
      return <ExternalAvatar
        userName={fullName}
        userId={userData.get('mbId')}
        size={size}
        initialFlashcard={initialFlashcard}
        accountPortal={accountPortal}
        />;
    }

    return <InternalAvatarContainer alternate={alternate} size={size}  initialFlashcard={initialFlashcard}/>;
  }
}

UserAvatarContainer.defaultProps = {
  size: 40
};
UserAvatarContainer.propTypes = {
  size: PropTypes.number,
  alternate: PropTypes.bool,

  // Connect
  isMBUser: PropTypes.bool.isRequired,
  userData: PropTypes.instanceOf(Map),
};

const mapStateToProps = (state) => ({
  isMBUser: isMBUserSelector(state),
  userData: userDataSelector(state),
});
export default connect(mapStateToProps, null)(UserAvatarContainer);
