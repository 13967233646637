import React from 'react';
import ChangeOrientationToLandscape from 'assets/images/notifications/change-orientation-to-landscape.svg';
import ChangeOrientationToPortrait from 'assets/images/notifications/change-orientation-to-portrait.svg';
import './notifications.scss';
import PropTypes from 'prop-types';

const ChangeOrientationNotification = ({to, title, subtitle}) => {
  return <div className="swal-notification">
    <div className="swal-notification-image">
      <img src={to === 'landscape' ? ChangeOrientationToLandscape : ChangeOrientationToPortrait} alt=''/>
    </div>
    <div className="swal-notification-title">
      {title}
    </div>
    <div className="swal-notification-text">
      {subtitle}
    </div>
  </div>;
};

ChangeOrientationNotification.propTypes = {
  to: PropTypes.oneOf(['portrait', 'landscape']).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

ChangeOrientationNotification.defaultProps = {
  to: 'landscape',
  title: 'Rotated your device to view flash card',
  subtitle: 'Make sure auto rotate is activated'
};

export default ChangeOrientationNotification;
