import {toggleNavigationSection} from 'actions/navigation';
import menuToggleActiveIcon from 'assets/icons/menu-toggle-active.svg';
import menuToggleInactiveIcon from 'assets/icons/menu-toggle-inactive.svg';
import RoundButton from 'components/studying/RoundButton';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {currentFlashcardId, isNavigationSectionVisible} from 'selectors';

const ToggleMenuButtonContainer = ({isNavigationSectionVisible, toggleNavigationSection, children}) => {
  const isActive = !isNavigationSectionVisible;
  const onClick = () => toggleNavigationSection(!isNavigationSectionVisible);

  if (children) return children({onClick: onClick, isActive});

  return (
    <RoundButton onClick={onClick}>
      <img src={isActive ? menuToggleActiveIcon : menuToggleInactiveIcon} alt=""/>
    </RoundButton>
  );
};

ToggleMenuButtonContainer.propTypes = {
  children: PropTypes.func,

  // Connect
  isNavigationSectionVisible: PropTypes.bool,
  toggleNavigationSection: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isNavigationSectionVisible: isNavigationSectionVisible(state),
  hasFlashcard: !!currentFlashcardId(state)
});

const mapDispatchToProps = {
  toggleNavigationSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleMenuButtonContainer);
