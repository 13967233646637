import React, {Component} from 'react';
import {connect} from 'react-redux';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import {selectNextFlashcard, selectPrevFlashcard} from 'actions/carousel/carouselActions';
// import {answerFlashcardCorrect, answerFlashcardWrong} from 'actions/api';
import {showNotification} from 'actions/notification';
import {isLeftMenuOpen, isLinksNavigationSectionVisible, isNavigationSectionVisible, isShortcutMenu} from 'selectors';
import {currentFlashcardId} from 'selectors/studying';
import {toggleNavigationSection, toggleSubjectsManagerOverlay, toggleLinksNavigationSection, setActiveFlashcardTab, closeMenu, openShortcutMenu, hideShortcutMenu} from 'actions/navigation';
import appHistory from 'appHistory';
import {nextSide} from 'actions/studying';
import * as Selectors from 'selectors';
import * as Actions from 'actions';
import {answerById} from 'selectors/answersSelectors';
import { getLastMediaTypeVisited, activeExamsSubject } from 'helpers';
import {getUserId} from 'selectors/user';

class KeyboardListenerContainer extends Component {
  handleSpace = (key, event) => {
    const  mediaType = getLastMediaTypeVisited(this.props.userId)
    if (['INPUT', 'TEXTAREA'].indexOf(event.target.nodeName) !== -1) return;
    if (document.activeElement !== document.body) {
      event.preventDefault();
    }
    if(mediaType === 'flashcard'){
      event.preventDefault();
      this.props.nextSide();
    }
  };
  
  toggleLeftMenu = (currentPath) => {
    const {isLeftMenuOpen, toggleNavigationSection, closeMenu, openShortcutMenu, isNavigationSectionVisible, flashcardId} = this.props;
    const prevPath = appHistory.location.pathname;
    if(currentPath === prevPath && flashcardId){
      if(!isNavigationSectionVisible){
        toggleNavigationSection(false, false);
        this.props.setActiveFlashcardTab(-1)
      }
    }
    if (isLeftMenuOpen) return;
    
    if(!this.props.isLinksNavigationSectionVisible){
      this.props.toggleLinksNavigationSection(false);
      this.props.setActiveFlashcardTab(-1)
    }
    if(this.props.isNavigationSectionVisible){
      closeMenu()
      openShortcutMenu()
      toggleNavigationSection(true, true);
    }
  };

  toggleLinkMenu = () => {
    if(this.props.links > 0){
      if(!this.props.isNavigationSectionVisible){
        this.props.toggleNavigationSection(false);
        this.props.setActiveFlashcardTab(-1);
      }
      this.props.toggleLinksNavigationSection(!this.props.isLinksNavigationSectionVisible);
    }
  }

  handleKey = (key, event) => {
    if (['INPUT', 'TEXTAREA'].indexOf(event.target.nodeName) !== -1) return;
    const answers = this.props.answer;
    const  mediaType = getLastMediaTypeVisited(this.props.userId)
    switch (key) {
      case 'n':
        // this.toggleLeftMenu(this.props.lastMenuPath['notes']);
        if(!this.props.isNavigationSectionVisible){
          this.props.setActiveFlashcardTab(2)
        }
        if(this.props.flashcardId){
          this.toggleLeftMenu('/flashcard/desk/notes/edit')
          appHistory.push('/flashcard/desk/notes/edit');
          if(!this.props.isNavigationSectionVisible){
            this.props.setActiveFlashcardTab(2)
          }
        }else{
          this.toggleLeftMenu(this.props.lastMenuPath['notes'])
          appHistory.push(this.props.lastMenuPath['notes']);
        }
        break;
      case 'f':
        this.toggleLeftMenu(this.props.lastMenuPath['folders']);
        if(!this.props.isNavigationSectionVisible){
          this.props.setActiveFlashcardTab(3)
        }
        appHistory.push(this.props.lastMenuPath['folders']);
        break;
      case 'e':
        this.toggleLeftMenu(this.props.lastMenuPath['exercises']);
        if(!this.props.isNavigationSectionVisible){
          this.props.setActiveFlashcardTab(4)
        }
        appHistory.push(this.props.lastMenuPath['exercises']);
        break;
      case 't':
        this.toggleLeftMenu(this.props.lastMenuPath['topics']);
        if(!this.props.isNavigationSectionVisible){
          this.props.setActiveFlashcardTab(0)
        }
        appHistory.push(this.props.lastMenuPath['topics']);
        break;
      case 's':
        this.toggleLeftMenu(this.props.lastMenuPath['search']);
        if(!this.props.isNavigationSectionVisible){
          this.props.setActiveFlashcardTab(5)
        }
        appHistory.push(this.props.lastMenuPath['search']);
        break;
      case 'x':
        this.toggleLeftMenu(this.props.lastMenuPath['exams']);
        if(!this.props.isNavigationSectionVisible){
          this.props.setActiveFlashcardTab(1)
        }
        appHistory.push(this.props.lastMenuPath['exams']);
        break;
      case 'm':
        this.props.toggleSubjectsManagerOverlay(undefined, true);
        break;
      case 'space':
        if(this.props.flashcardId){
          this.handleSpace(key, event);
        }
        break;
      default:
        break;
    }
  };

  render() {
    const {getActiveExamModule, activeSubject} = this.props;
    const withFlashcardKeys = ['right', 'left', 'c', 'n'];
    let withoutFlashcardKeys;
    const examsStatus = activeExamsSubject(getActiveExamModule, activeSubject?.id);
    if(examsStatus){
      withoutFlashcardKeys = ['f', 'e', 's', 'm', 't', 'l', 'x', 'right', 'left', 'c', 'n', 'space'];
    }else{
      withoutFlashcardKeys = ['f', 'e', 's', 'm', 't', 'l', 'right', 'left', 'c', 'n', 'space'];
    }
    return (
      <React.Fragment>
        {/* {this.props.flashcardId && <React.Fragment>
          <KeyboardEventHandler handleKeys={withFlashcardKeys} onKeyEvent={this.handleKey}
            handleFocusableElements={true}/>
          <KeyboardEventHandler handleKeys={['space']} onKeyEvent={this.handleSpace} handleFocusableElements={true}/>
        </React.Fragment>} */}
        <KeyboardEventHandler handleKeys={withoutFlashcardKeys} onKeyEvent={this.handleKey}
          handleFocusableElements={true}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  const currentFlashcardIdValue = currentFlashcardId(state);
  return ({
    flashcardId: currentFlashcardIdValue,
    isLeftMenuOpen: isLeftMenuOpen(state),
    answer: answerById(state, currentFlashcardIdValue),
    isLinksNavigationSectionVisible: isLinksNavigationSectionVisible(state),
    isNavigationSectionVisible: isNavigationSectionVisible(state),
    links: Selectors.Studying.getLinkSize(state),
    isShortcutMenu: isShortcutMenu(state),
    lastMenuPath: Selectors.lastMenuPath(state),
    userId: getUserId(state),
    activeSubject: Selectors.getActiveSubject(state),
    getActiveExamModule: Selectors.getActiveExamModule(state)

  });
};

const mapDispatchToProps = {
  selectNextFlashcard: selectNextFlashcard,
  selectPrevFlashcard: selectPrevFlashcard,
  // answerFlashcardCorrect: answerFlashcardCorrect,
  // answerFlashcardWrong: answerFlashcardWrong,
  showNotification: showNotification,
  toggleSubjectsManagerOverlay: toggleSubjectsManagerOverlay,
  nextSide: nextSide,
  toggleNavigationSection: toggleNavigationSection,
  toggleLinksNavigationSection: toggleLinksNavigationSection,
  setActiveFlashcardTab: setActiveFlashcardTab,
  closeMenu: closeMenu,
  openShortcutMenu: openShortcutMenu,
  hideShortcutMenu: hideShortcutMenu,
  setAnswerFlashcard: Actions.Api.setAnswerFlashcard,
  setActiveMediaTab: Actions.Navigation.setActiveMediaTab
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(KeyboardListenerContainer);
