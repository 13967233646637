import PropTypes from 'prop-types';
import React, {createRef, PureComponent} from 'react';
import debounce from 'lodash.debounce';

class ElementPositionDetector extends PureComponent {
  constructor(props) {
    super(props);

    this.ref = createRef();

    this.state = {
      passedThreshold: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.parentScrollRef !== prevProps.parentScrollRef) {
      window.addEventListener('scroll', this.onScroll);
      this.props.parentScrollRef && this.props.parentScrollRef.addEventListener('scroll', this.onScroll);
    }
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    this.props.parentScrollRef && this.props.parentScrollRef.removeEventListener('scroll', this.onScroll);
    this.onScroll.cancel();
  }

  onScroll = debounce(() => {
    const {maxScrollTop} = this.props;
    if (!this.ref.current) return;

    const distanceFromTopViewport = this.ref.current.getBoundingClientRect().top;

    if (distanceFromTopViewport <= maxScrollTop) {
      if (!this.state.passedThreshold) {
        this.setState({passedThreshold: true});
        this.props.onTrigger(true);
      }
    } else {
      if (this.state.passedThreshold) {
        this.setState({passedThreshold: false});
        this.props.onTrigger(false);
      }
    }
  }, this.props.debounce);

  render() {
    return (
      <div ref={this.ref}/>
    );
  }
}

ElementPositionDetector.propTypes = {
  onTrigger: PropTypes.func.isRequired,
  debounce: PropTypes.number.isRequired,
  maxScrollTop: PropTypes.number.isRequired,
};

ElementPositionDetector.defaultProps = {
  debounce: 300
};

export default ElementPositionDetector;
