import Immutable from 'immutable';
import * as Types from 'actions/user/types';

export default (state = Immutable.fromJS(
  {
    avatars: [
      {index: 0, name: 'crown', active: false},
      {index: 1, name: 'initials', active: true},
      {index: 4, name: 'medal', active: false},
      {index: 2, name: 'trophy', active: false},
      {index: 3, name: 'mountain', active: false},
    ],
    isAvatarComponentExpanded: false
  }
), action) => {
  switch (action.type) {
    case Types.SET_USER_DATA:
      return state.merge(action.data);
    case Types.TOGGLE_CHOOSE_AVATAR:
      return state.update('isAvatarComponentExpanded', val => !val);
    case Types.SET_AVATAR: {
      let avatars = state.get('avatars');
      avatars = avatars.map(avatar =>
        avatar.update((item) => {
          if (item.get('index') === action.avatar.index) {
            return avatar.set('active', true);
          }
          return avatar.set('active', false);
        })
      );
      return state
        .set('avatars', avatars)
        .set('avatar', action.avatar.name);
    }
    default:
      return state;
  }
};

