import { ConfidentIcon, UnconfidentIcon, NeitherIcon, RightIcon, IncorrectIcon, CorrectIcon } from 'icons/FlashcardIcons';

type AnswerBulletProps = {
  correct?: number;
  answered?: boolean,
  markValue?: number
}
const AnswerBullet: React.FC<AnswerBulletProps> = ({ correct, answered }) => {
  if (correct === 1 && answered) return <ConfidentIcon />;
  if (correct === 2 && answered) return <NeitherIcon />;
  if (answered && !correct) return <UnconfidentIcon />;
  return null;
}
export default AnswerBullet;

export const MarkRightValue: React.FC<AnswerBulletProps> = ({ markValue }) => {
  if (markValue === 1) return <RightIcon />
  return null
}

export const MCQAnswerBullet: React.FC<AnswerBulletProps> = ({ correct, answered }) => {
  if (correct === 0 && answered) return <CorrectIcon />;
  if (correct === 1 && answered) return <IncorrectIcon />;
  return null;
}