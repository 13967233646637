import styled, {css} from 'styled-components/macro';
import media from 'ui-components/helpers/media';
import Text from 'ui-components/helpers/Text';

export const MediaTypeMain = styled.div`
    min-height: ${props =>props.exams ?  (props.mediaTypeHeight ?   '94px' : (props.isMcq ? '348px': "278px")) :(props.mediaTypeHeight ? '94px' : '178px')};
    margin-bottom:${props => props.margin && `10px`};
    margin-top:${props => props.margin && `8px`}
`;
export const QuickMediaTypeMain = styled.div`
    margin-bottom:10px;
    margin-top:8px;
    min-height:178px;
`

export const MediaTypeCotainer = styled.div`
    height:  ${props => props.mediaTypeHeight ? '94px' : '142px'};
    width: 100%;
    background: linear-gradient(225deg, #43DAF0 0%, #395BF8 100%);
    padding: 16px 20px;
`;
export const MediaType = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    cursor: pointer;
    // align-items: center;
    pointer-events:  ${props => props.isActionTab && !props.showBar && 'none'};
    user-select:  ${props => props.isActionTab && !props.showBar && 'none'};
    ${props => props.isExercise && css`
        pointer-events: none;
        opacity: 0.5;
    `}
    // ${props => props.disabled && css`
    //     pointer-events: none;
    //     opacity: 0.5;
    // `}
    >${Text}{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 4px;
        opacity: ${props => props.isActionTab ? 1 : 0.7};
    }
    ${props => props.isActionTab && props.showBar && css`
        &:after{
            content: '';
            background: #FFFFFF;
            box-shadow: 0px 1px 3px rgba(30, 76, 172, 0.514423);
            border-radius: 14px;
            height: 6px;
            min-width: 83px;
            margin-top: 6px;
            align-items: center;
            display: flex;
        }
    `}
    img{
        height: 40px;
    }
`;

export const MediaTypeSection = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const MediaStatusSection = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.2);
    border-radius: 10px;
    // height: ${props => props.exams ? "172px" : props.activeMediaTab==='exams' ? "144px" : "72px"};
    ${props => props.isMcq && css `
    height: fit-content;
    `}
    margin-top: ${props => props.marginTop ===0 ? "0" : "16px"};
    padding:${props => props.exams ? "14px 14px 8px 14px": "8px 20px"};
`;


export const MediaButton = styled.div`
    background: #378EF8;
    border-radius: 22px;
    padding: 8px 0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    //margin:  ${props => props.exercise ? '23px 0' : '46px 0'};;
    margin: ${props => props.isMarging ? "14px 0 24px 0" : props.exercise ? '14px 0 23px 0' : '14px 0 46px 0' };
    cursor: pointer;
    width: 100%;
    >svg{
        margin-right: 8px;
    }
    @media (pointer: fine) {
        &:hover {
            background: #64A8FA;
        }
    } 
    &:active {
        background: #2170CF;
    }
`;

export const StyleMetaInfo = styled.div`
display: flex;
max-height: 70px;
img {
    width: 41px;
    height: 40px;
}
`;
export const Heading = styled.div`
    margin-left: ${props => props.mediaType ==='exams' ? '6px': "12px"};
    margin-right: 20px;
    width: 54px;
`;
export const Number = styled.div`
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    color: #2D3838;
`;
export const Title = styled.div`
    font-weight: 600;
    font-size: 10px;
    line-height:${props => props.isMCQ ? "11px": "14px"};
    font-weight: bold;
    line-height:${props => props.examsMode ? "11px" : "12px"};
    color: #919191;
`;

export const MediaTypeTabCotainer = styled.div`
    height: 94px;
    width: 100%;
    background: ${props => props.page === 'search-page' ? "none" : "linear-gradient(225deg, #43DAF0 0%, #395BF8 100%)"};
    padding: ${props => props.page === 'search-page' ? "16px 0 0 0" : "16px 20px"};
`;

export const StyledMediaButton = styled.div`
    display: flex;
    flex-direction: column;
    
`;

export const Haese = styled.div`
    background: #F3F3F3;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #919191;
    padding: 8px 12px;
    flex-flow: wrap;
    margin-bottom: 24px;
    a{
        color: #378EF8;
        @media (pointer: fine) {
        &:hover {
            color: #64A8FA;
        } 
        &:active {
            color: #2170CF;
        }
    }
`;
export const MCQStatus =styled.div`
    display:flex;
    align-items: center;
    margin-top:2px;
`;
export const StyleIcon = styled.div`
    width:17px;
    height:17px;
    margin-right:4px;
`;
export const LineBreak = styled.div`
    background: #E1E6E6;
    height: 1px;
    margin: 7.5px 0;
    border-radius: 1px;
    // margin-left: 6px;
`;
export const ExamsMCQFilterWrapper =styled.div`
    padding:24px 20px 0 20px;
    display: flex;
    align-items: center;
`;