
import React, { useEffect, useMemo } from "react";
import { getExternalAvatar } from 'actions/api/apiActions';
import RequestLoader from 'v2/components/loader.v2/RequestLoader';
import { getExternalAvatarResponse } from 'selectors/api/apiSelector';
import Avatar from 'v2/ui-components/Avatar';
import { useSelector, useDispatch } from "react-redux";


type ExternalAvatarProps = {
  size: number,
  initialFlashcard?: boolean,
  widthSize?: number,
  accountPortal?: boolean,
  userId: string,
  userName: string
}

const ExternalAvatar: React.FC<ExternalAvatarProps> = ({ size, initialFlashcard, widthSize, accountPortal, userId, userName }) => {
  const externalAvatarResponse = useSelector(getExternalAvatarResponse)
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId && !externalAvatarResponse[userId]) {
      dispatch(getExternalAvatar({id:userId}))

    }
  }, [dispatch, externalAvatarResponse, userId])

  const getUserInitials = useMemo(() => {
    const splitUserName = userName.split(' ');
    return splitUserName[0][0] + splitUserName[splitUserName.length - 1][0];
  }, [userName])

  return <RequestLoader loader={<Avatar size={size}>{getUserInitials}</Avatar>} request={externalAvatarResponse[userId]}
    error={() => <Avatar size={size} widthSize={widthSize} initialFlashcard={initialFlashcard} accountPortal={accountPortal}>
      {getUserInitials}
    </Avatar>}
  >
    <Avatar size={size} widthSize={widthSize} url={externalAvatarResponse[userId]?.data} initialFlashcard={initialFlashcard} />
  </RequestLoader>
}

export default ExternalAvatar;