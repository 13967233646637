import {connect} from 'react-redux';
import React, {Component} from 'react';
import {PreviewListHeader, PreviewSubTopicListItem} from 'ui-components/search-results/PreviewListItem';
import {activeSubjectId, isLinksNavigationSectionVisible, currentFlashcardId} from 'selectors';
import * as Actions from 'actions';
import {topicsTree} from 'selectors/topicsTreeSelector';
import {setActiveNodesAction} from 'actions/topics-tree/topicsTreeActions';
import { isMobilePortrait } from 'selectors/browser/browserSelector';
import {
  addBackFlashcardToLocalStorage, decodeQuery
} from 'helpers';
import appHistory from 'appHistory';
class SubtopicLinkResultList extends Component {
  onListItemClick = (subTopicId, teachingLvl, topicId) => {
    const query = decodeQuery(appHistory.location.search.substring(1));
    const subjectId = this.props.activeSubjectId;
    const subTopicContents = this.props.topicsTree.getIn([subjectId, 'children', teachingLvl, 'children', topicId, 'children', subTopicId, 'children']);
    if (subTopicContents) {
      const firstFlashcard = subTopicContents.keySeq().first();
      if(!this.props.isLinksNavigationSectionVisible && this.props.isMobilePortrait){
        this.props.toggleLinksNavigationSection(false) 
      }
      if(firstFlashcard !== this.props.currentFlashcardId){
        addBackFlashcardToLocalStorage("flashcard"+"-"+firstFlashcard, query.mediaType+"-"+this.props.currentFlashcardId);
      }
      this.props.selectFlashcard(firstFlashcard);
    }
  };
  render() {
    const {newListItems} = this.props;
    return newListItems.map((item, index) => {
        const firstItem = item.get(0);
        return (
          <div key={firstItem.get('tlvl') + index}>
            <PreviewListHeader topicNumber={null} index={index}
              teachingLevel={firstItem.get('tlvl')}/>
            {this.renderSubtopicPreview(item)}
          </div>
        );
      });
  }

  renderSubtopicPreview(subtopics) {
    return subtopics.sortBy(item => item.get('label')).map(item => {
        const subTopicId = item.get('id');
        const title = item.get('title');
        const numbering = item.get('label');
        const teachingLvl = item.get('tlvlId');
        const topicId = item.get('topicId');
        return <PreviewSubTopicListItem
            subTopicId={subTopicId}
            key={numbering}
            onClick={() => this.onListItemClick(subTopicId, teachingLvl, topicId)}
            subTopicNumber={numbering}
            preview={title}
        />;
    });
  }
}

const mapStateToProps = (state) => ({
  activeSubjectId: activeSubjectId(state),
  topicsTree: topicsTree(state),
  isLinksNavigationSectionVisible: isLinksNavigationSectionVisible(state),
  isMobilePortrait: isMobilePortrait(state),
  currentFlashcardId: currentFlashcardId(state),
});
const mapDispatchToProps = {
  selectFlashcard: Actions.Studying.selectFlashcard,
  setActiveNodes: setActiveNodesAction,
  toggleLinksNavigationSection: Actions.Navigation.toggleLinksNavigationSection
};
export default connect(mapStateToProps, mapDispatchToProps)(SubtopicLinkResultList);