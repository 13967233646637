import React, { useMemo } from 'react';
import PaperESM from 'v2/components/empty-screen-message/PaperESM';
import classNames from 'classnames';
import { getActivePaperTab } from 'selectors/navigation/navigationSelector';
import { subjectsTeachingLevel } from 'selectors/subject/subjectSelector';
import { useSelector } from 'react-redux';
import PaperDescription from './PaperDescription';

type PaperDetailsProps = {
    examHeader: any;
    id: string;
    loading: boolean;
    activeSubjectId: number;
    hasPaperText: any;
    onClick: (argA: string, argB: string) => void;
}

const PaperDetails: React.FC<PaperDetailsProps> = ({ examHeader, id, loading, activeSubjectId, hasPaperText, onClick }) => {
    const activePaperTab = useSelector(getActivePaperTab);
    const getSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
    const tlvl = getSubjectsTeachingLevel[`${activeSubjectId}`];
    const paperData = hasPaperText?.[activeSubjectId]?.[tlvl];
    const isPaperActive = () => {
        let inactivePaper = paperData?.filter((item: any) => item.title === activePaperTab)[0]?.active
        if (examHeader) {
            let papers = examHeader?.papers?.map((item: any) => {
                return item?.paper_type;
            });
            if (papers && !papers.includes(activePaperTab)) {
                inactivePaper = false;
            }
        }
        return inactivePaper
    }
    const handleClick = (paperTitle: string) => {
        const uid = examHeader.papers.filter((item: any) => item.paper_type === paperTitle)[0]?.uid;
        onClick(paperTitle, uid);
    }

    return (
        <>
            <div className='relative paper-card-wrapper'>
                <div className={classNames('pt-5 px-5 pb-9 paper-card', { 'h-fit': isPaperActive(), 'h-76px': !isPaperActive() })}>
                    <div className='h-9 relative flex paperbar-option'>
                        {examHeader?.papers?.map(({paper_type}: any, index: number) => (
                            <div
                                className={classNames('w-full h-full text-center text-white text-13px font-bold cursor-pointer flex justify-center items-center paperbar-button', { 'bg-white !text-blue-normal active': activePaperTab === paper_type })}
                                key={"exam-"+paper_type+index}
                                onClick={() => handleClick(paper_type)}>
                                {paper_type}
                            </div>
                        ))
                        }
                    </div>
                    {isPaperActive() &&
                        <PaperDescription
                            paperData={paperData}
                            activeSubjectId={activeSubjectId}
                            subjectsTeachingLevel={getSubjectsTeachingLevel}
                            activePaperTab={activePaperTab}
                            loading={loading}
                            tlvl={tlvl}
                            id={id}
                        />
                    }
                </div>
            </div>
            {!isPaperActive() && <PaperESM type={activePaperTab} from={"paper"} color={"#378EF8"} inactivePaper={isPaperActive()} />
            }
        </>
    )
}

export default PaperDetails
