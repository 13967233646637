import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageOverlay from 'v2/ui-components/PageOverlay';
import Flickity from "react-flickity-component";
import {startOnboarding, toggleOnboardingOverlay} from 'actions/onboarding/onboardingActions';
import Loader, {BackgroundEnum, SizeEnum} from 'v2/components/loader.v2/Loader.v2';
import classNames from 'classnames';
import { OnBoardingLogoIcon, OnBoardingLeft, OnBoardingRight } from 'icons/HeaderIcons';
import { isOnboardingOverlayVisible } from 'selectors/onboardingSelectors';
import { userDataSelector } from 'selectors/user';
import { mediaSize } from 'selectors';
import {onboardingSteps} from 'v2/components/onboarding/onBoardingSteps';
import {toggleSubjectsManagerOverlay} from 'actions/navigation';
import theme from 'theme';
import './onBoarding.scss';


const Onboarding = () => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [step, setStep] = useState<number>(0);
    const [maxStep, setMaxStep] = useState<number>(3);
    const [flkty, setFlkty] = useState<any>({});
    const steps = [...Array(maxStep).keys()];
    const isOnboardingVisible = useSelector(isOnboardingOverlayVisible);
    const userData = useSelector(userDataSelector);
    const smalliPhone5 = useSelector((state) => mediaSize(state, theme.sizes.smalliPhone5))
    let flickityOptions = {
        pageDots: false,
        groupCells: 1,
        prevNextButtons: false,
        initialIndex: 0,
        on: {
            select: (e: any) => {
                setStep(e);
            }
        }
    }
    useEffect(() => {
        if (userData.get("onBoarding") === "" || userData.get("onBoarding") === null) {
            dispatch(startOnboarding());
        }
    },[])

    useEffect(() => {
        setStep(0);
    },[isOnboardingVisible])

    const isFirstStep = () => step === 0;
    const isLastStep = () => step === maxStep - 1;

    const prevStep = () => {
        if (isFirstStep()) return;
        flkty.previous();
    }

    const nextStep = () => {
        if (isLastStep()) return;
        flkty.next()
    };

    const onStart = () => {
        dispatch(toggleOnboardingOverlay(false));
        dispatch(toggleSubjectsManagerOverlay(true, true));
    };

    const onSkip = () => {
        dispatch(toggleOnboardingOverlay(false));
        dispatch(toggleSubjectsManagerOverlay(true, true));  
    }
    
    const onBoardingSlide = (index: number) => {
        return <>
            <div className="flex flex-col items-center justify-between full-height">
                <div className={classNames('items-center justify-center self-center w-full h-full', {'hidden' : !isLoading}, {'flex' : isLoading})}>
                    <Loader background={BackgroundEnum.Blue} size={SizeEnum.Large}/>
                </div>
                <div className={classNames('onboaring-img-wrapper', {'block': !isLoading}, {'hidden': isLoading})}>
                    <img src={onboardingSteps[index]['image']} className={classNames({'visible': !isLoading}, {'invisible': isLoading})} onLoad={() => setLoading(false)
                    } alt="onboardingImg" />
                </div>
                {!isLoading && 
                    <div className="onbaording-content-wrapper full-height">
                        <div className="flex flex-col justify-start items-center w-auto">
                            <div className='onboarding-title'>
                                {onboardingSteps[index]['title']}
                            </div>
                            {onboardingSteps[index]['logo'] && <OnBoardingLogoIcon />}
                            <div className='onboarding-subtitle'>
                                {onboardingSteps[index]['subtitle']}
                            </div>
                            <div className='onboarding-subtitle'>
                                {onboardingSteps[index]['subtitle2']}
                            </div>
                            {onboardingSteps[index]['button']}
                        </div>
                    </div>
                }
            </div>
        </>
    }
    if (!isOnboardingVisible) return null;

    return (
        <PageOverlay  isOverlayVisible={true}>
            <div className="onboaring-wrapper">
                {/* @ts-ignore */}
                <Flickity options={flickityOptions} flickityRef={e => setFlkty(e)} className={'onboarding-slider'}>
                    { steps.map((item) => onBoardingSlide(item)) }
                </Flickity>
                <div className="onboarding-footer">
                    <div className="flex items-center justify-between">
                        <div className="flex">
                            <button className='onboarding-skin-btn' onClick={onSkip}>Skip</button>
                        </div>
                        <div className="flex items-center justify-center">
                            <div className="flex justify-end w-73px">
                                <div className={classNames('onboarding-nav-btn', {'invisible' : isFirstStep()}, {'visible' : !isFirstStep()})} onClick={prevStep}><OnBoardingLeft /></div>
                            </div>
                            <div className={classNames({'mr-8' : !smalliPhone5}, {'mr-2.5' : smalliPhone5})} />
                            {steps.map((item, index) => 
                                <div className={classNames('onboarding-bullet-icon', {'active' : index === step})} key={item} onClick={() => flkty.select(index)}/>
                            )}
                            {isLastStep() ? 
                                <div className={classNames('onboarding-start-btn', {'ml-2.5' : smalliPhone5}, {'ml-8' : !smalliPhone5})} onClick={onStart} >
                                    Start
                                </div>: 
                                <>
                                    <div className={classNames({'ml-8' : !smalliPhone5}, {'ml-2.5' : smalliPhone5})} />
                                    <div className="w-73px">
                                        <div className={classNames('onboarding-nav-btn')} onClick={nextStep}><OnBoardingRight /></div>
                                    </div>
                                   
                                </>
                            }    
                        </div>
                        <div className="flex">
                            <button className='onboarding-skin-btn' ></button>
                        </div> 
                    </div>
                </div>
            </div>
        </PageOverlay>
    )
}


export default Onboarding;