import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'modernizr';

export const validator = {
  required: (value) => value ? undefined : 'This field is required!',
  email: (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
      ? 'The email is invalid!'
      : undefined,
  password: (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(password) ? undefined : 'passwordStrength';
  },
  checkboxValidation: val => val ? undefined : true,
  passwordLength: (value) => {
    return value && value.length > 5 ? undefined : 'Password must have at least 6 characters!';
  }
};

export const renderField = ({input, className, type, meta: {touched, error, warning, submitFailed, active}, placeholder, disabled, label, reset, autoComplete, showIcon}) => {
  const hasError = touched && error;
  const activeClass = active ? 'active' : '';

  return <div className='input'>
    <label className={`form-label ${activeClass} ${hasError ? 'error' : ''}`}>{hasError ? error : label}</label>
    <input {...input} placeholder={placeholder}
      className={`${className} ${activeClass} ${hasError ? 'error' : ''}`} type={type}
      disabled={disabled} autoComplete={autoComplete}/>
    {showIcon && <FormIcon active={active} hasError={hasError} reset={reset} value={input.value}/>}
  </div>;
};

export const RenderInputField = ({className, type, value, touched, error, active, placeholder, disabled, label, name, reset, autoComplete, showIcon, onChange, onBlur, onFocus}) => {
  const hasError = touched && error;
  const activeClass = active ? 'active' : '';
  return <div className='input'>
    <label className={`form-label ${activeClass} ${hasError ? 'error' : ''}`}>{hasError ? error : label}</label>
    <input placeholder={placeholder}
      className={`${className} ${activeClass} ${hasError ? 'error' : ''}`} type={type}
      disabled={disabled} autoComplete={autoComplete} name={name} onChange={onChange} onBlur={onBlur} onFocus={onFocus}/>
    {showIcon && <FormIcon active={active} hasError={hasError} reset={reset} value={value}/>}
  </div>;
}

export const renderTextAreaField = ({input, className, type, meta: {touched, error, warning, submitFailed, active}, placeholder, disabled, label, reset, autoComplete, showIcon, onResize}) => {
  const hasError = touched && error;
  const activeClass = active ? 'active' : '';

  return <div className='inpRenderSelectFieldut'>
    <label className={`form-label ${activeClass} ${hasError ? 'error' : ''}`}>{hasError ? error : label}</label>
    <TextareaAutosize {...input} placeholder={placeholder} rows={1} onResize={onResize}
      className={`${className} ${activeClass} ${hasError ? 'error' : ''}`}
      disabled={disabled} autoComplete={autoComplete}/>
    {showIcon && <FormIcon active={active} hasError={hasError} reset={reset} value={input.value}/>}
  </div>;
};

export const RenderSelectField = ({input, className, type, meta: {touched, error, warning, submitFailed, active}, placeholder, disabled, label, reset, children, showIcon}) => {
  const hasError = touched && error;
  const activeClass = active ? 'active' : '';

  return <div className="input">
    <label className={`form-label ${activeClass} ${hasError ? 'error' : ''}`}>{hasError ? error : label}</label>
    <select {...input} className={`${className} ${activeClass} ${hasError ? 'error' : ''}`}
      data-selected-placeholder={input.value === ''}>
      {children}
    </select>
    {showIcon && <FormIcon active={active} hasError={hasError} reset={reset} value={input.value}/>}
  </div>;
};

export const renderSelectField = ({input, className, type, meta: {touched, error, warning, submitFailed, active}, placeholder, disabled, label, reset, children, showIcon}) => {
  const hasError = touched && error;
  const activeClass = active ? 'active' : '';

  return <div className="input">
    <label className={`form-label ${activeClass} ${hasError ? 'error' : ''}`}>{hasError ? error : label}</label>
    <select {...input} className={`${className} ${activeClass} ${hasError ? 'error' : ''}`}
      data-selected-placeholder={input.value === ''}>
      {children}
    </select>
    {showIcon && <FormIcon active={active} hasError={hasError} reset={reset} value={input.value}/>}
  </div>;
};

const FormIcon = (props) => {
  const {active, hasError, reset, value} = props;

  function resetField(e) {
    e.preventDefault();
    e.stopPropagation();
    reset();
  }

  if (hasError) {
    return <div className='form-icon'><WarnIcon/></div>;
  } else {
    return active && (value.length > 0 || value > 0) ?
      <div onMouseDown={e => resetField(e)} onClick={e => resetField(e)} className='form-icon'><ClearIcon/>
      </div> : null;
  }
};


//TODO move icons to separate file
export const ClearIcon = (props) => {
  const {type = 'active'} = props;
  return <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
      fillOpacity={type === 'transparent' ? '0.7' : '1'}>
      <g id="Icons/Header/Delete/Active" transform="translate(-1.000000, -1.000000)"
        fill={type === 'transparent' ? '#FFFFFF' : '#FFFFFF'}>
        <g id="Group-2-Copy" transform="translate(1.000000, 1.000000)">
          <path
            d="M8.079468,8.99870678 L5.10961949,11.9685553 C4.85577871,12.2223961 4.85577871,12.6339533 5.10961949,12.8877941 C5.36346027,13.1416349 5.77501749,13.1416349 6.02885827,12.8877941 L8.99870678,9.91794556 L11.9685553,12.8877941 C12.2223961,13.1416349 12.6339533,13.1416349 12.8877941,12.8877941 C13.1416349,12.6339533 13.1416349,12.2223961 12.8877941,11.9685553 L9.91794556,8.99870678 L12.8877941,6.02885827 C13.1416349,5.77501749 13.1416349,5.36346027 12.8877941,5.10961949 C12.6339533,4.85577871 12.2223961,4.85577871 11.9685553,5.10961949 L8.99870678,8.079468 L6.02885827,5.10961949 C5.77501749,4.85577871 5.36346027,4.85577871 5.10961949,5.10961949 C4.85577871,5.36346027 4.85577871,5.77501749 5.10961949,6.02885827 L8.079468,8.99870678 Z M18,9 C18,13.9706374 13.9705714,18 9,18 C4.02942857,18 0,13.9706374 0,9 C0,4.02942857 4.02942857,0 9,0 C13.9705714,0 18,4.02942857 18,9 Z"
            id="Combined-Shape"/>
        </g>
      </g>
    </g>
  </svg>;
};

export const WarnIcon = () => {
  return <svg width="25px" height="22px" viewBox="0 0 25 22" version="1.1">
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100%" y2="133.485797%" id="linearGradient-1">
        <stop stopColor="#FC4B07" offset="0%"></stop>
        <stop stopColor="#FC1691" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons/Notification/Warning/Red" transform="translate(0.000000, -2.000000)">
        <g id="Warning" transform="translate(-1.000000, 0.000000)">
          <path
            d="M15.1609277,3.24661649 L24.803037,21.0474337 L24.803037,21.0474337 C25.3291248,22.0186728 24.9682585,23.2324961 23.9970194,23.7585839 C23.7045157,23.9170234 23.3771112,24 23.0444531,24 L3.76023443,24 L3.76023443,24 C2.65566493,24 1.76023443,23.1045695 1.76023443,22 C1.76023443,21.6673418 1.84321102,21.3399373 2.00165049,21.0474337 L11.6437598,3.24661649 L11.6437598,3.24661649 C12.1698477,2.2753774 13.383671,1.91451102 14.35491,2.44059886 C14.6960792,2.62539881 14.9761277,2.90544734 15.1609277,3.24661649 Z"
            id="Triangle-2" fill="url(#linearGradient-1)"></path>
          <path
            d="M14.0170202,5.17096289 L22.4001316,20.8397785 L22.4001316,20.8397785 C22.5825079,21.1806567 22.4540167,21.6048382 22.1131385,21.7872144 C22.0115545,21.8415638 21.8981263,21.8699999 21.7829171,21.8699999 L5.01669415,21.8699999 L5.01669415,21.8699999 C4.63009482,21.8699999 4.31669415,21.5565992 4.31669415,21.1699999 C4.31669415,21.0547907 4.34513023,20.9413625 4.39947958,20.8397785 L12.782591,5.17096289 L12.782591,5.17096289 C12.9649673,4.8300847 13.3891488,4.70159349 13.730027,4.88396975 C13.8519345,4.94919255 13.9517974,5.0490554 14.0170202,5.17096289 Z"
            id="Triangle-2"></path>
          <path
            d="M13.7973964,15.8904432 L13.2009766,15.8904432 L13.2009766,15.8904432 C12.9344964,15.8904432 12.714843,15.6814514 12.7015949,15.4153008 L12.427839,9.91561993 L12.427839,9.91561993 C12.4141106,9.63981902 12.6265623,9.4051091 12.9023632,9.39138065 C12.9106426,9.39096852 12.918931,9.39076236 12.9272207,9.39076236 L14.0711522,9.39076236 L14.0711522,9.39076236 C14.3472946,9.39076236 14.5711522,9.61461999 14.5711522,9.89076236 C14.5711522,9.89905206 14.5709461,9.90734048 14.5705339,9.91561993 L14.2967781,15.4153008 L14.2967781,15.4153008 C14.28353,15.6814514 14.0638765,15.8904432 13.7973964,15.8904432 Z M12.3763382,18.8989647 C12.3763382,18.5264121 12.4714939,18.2447842 12.6618081,18.0540728 C12.8521223,17.8633613 13.1291311,17.768007 13.4928427,17.768007 C13.8438667,17.768007 14.115589,17.8655788 14.3080178,18.0607255 C14.5004466,18.2558721 14.5966596,18.5352824 14.5966596,18.8989647 C14.5966596,19.2493417 14.4993894,19.5254256 14.3048459,19.727225 C14.1103025,19.9290244 13.8396375,20.0299225 13.4928427,20.0299225 C13.1375895,20.0299225 12.8626953,19.9312419 12.6681519,19.7338777 C12.4736085,19.5365135 12.3763382,19.258212 12.3763382,18.8989647 Z"
            id="!" fill="#FFFFFF"></path>
        </g>
      </g>
    </g>
  </svg>;
};

export const renderCheckbox = ({input, id, className, type, meta: {touched, error, warning}, value}) => {
  return <input {...input} id={id} className={className} type={type} value={value}/>;
};

export const renderDatepicker = ({input, placeholder, className, label, meta: {touched, active, error, warning}, reset, value, minDate, maxDate, id}) => {
  const activeClass = active ? 'active' : '';
  const hasError = touched && error;
  let inputToRender, placeholderContainer;

  if (window.Modernizr.inputtypes.date) {
    inputToRender = <input id={id} {...input} type='date' placeholder={placeholder} value={input.value}
      className={`${className} ${activeClass} ${hasError ? 'error' : ''}`}
      min={minDate && minDate.format('YYYY-MM-DD')}
      max={maxDate && maxDate.format('YYYY-MM-DD')}
    />;
    placeholderContainer = !active ?
      <div onClick={() => {
        document.getElementById(id).focus();
      }} className="date-placeholder">{placeholder}</div> : null;
  } else {
    const inputValue = input.value;
    inputToRender = [
      <DatePicker key="datepicker"
        onChange={(value) => {
          input.onChange(value);
        }}
        onBlur={() => {
          input.onBlur(inputValue ? moment(inputValue) : undefined);
        }}
        onFocus={input.onFocus}
        value={formatValue(inputValue)}
        selected={inputValue ? moment(inputValue) : null}
        className={`${className} ${activeClass} ${hasError ? 'error' : ''}`}
        placeholderText={placeholder}
        showYearDropdown
        scrollableYearDropdown
        showMonthDropdown
        yearDropdownItemNumber={40}
        minDate={minDate}
        maxDate={maxDate}
      />,
      <FormIcon key="form-icon" active={active} hasError={hasError} reset={reset} value={input.value}/>];
  }
  return (
    <div className="input">
      <label className={`form-label ${activeClass} ${hasError ? 'error' : ''}`}>{hasError ? error : label}</label>
      {inputToRender}
      {input.value ? null : placeholderContainer}
    </div>
  );
};

const formatValue = (value) => {
  if (!value) return;
  return value.format ? value.format('DD/MM/YYYY') : moment(value).format('DD/MM/YYYY');
};

export const findFirstFieldWithError = (errors) => {
  let firstErrorField;
  for (let error in errors) {
    firstErrorField = error;
    break;
  }
  document.querySelector([`[name="${firstErrorField}"]`]).focus();
};
