import {List, Map} from 'immutable';
import {createSelector} from 'reselect';
import {getActiveSubject} from 'selectors';

export const topicsTree = state => state.get('topicsTree', Map());
export const isNodeSelected = (state, id, parentsId) => {
  const path = interleaveParentsWithChildren(parentsId);
  if (path.length === 0) return state.getIn(['topicsTree', id, 'selected']);

  return state.getIn(['topicsTree'].concat(path, id, 'selected'));
};

export const isNodePartial = (state, id, parentsId) => {
  const path = interleaveParentsWithChildren(parentsId);
  if (path.length === 0) return state.getIn(['topicsTree', id, 'partial']);

  return state.getIn(['topicsTree'].concat(path, id, 'partial'));
};

const interleaveParentsWithChildren = (parentIds) => {
  if (!parentIds) return [];
  const childrenArray = new Array(parentIds.length);
  childrenArray.fill('children');

  return parentIds.reduce((accumulator, currentValue, currentIndex) => {
    if (childrenArray[currentIndex])
      return accumulator.concat(currentValue, childrenArray[currentIndex]);
    return accumulator.concat(currentValue);
  }, []);
};

export const isTreeSectionExpanded = (state, id, parentIds) => {
  const path = interleaveParentsWithChildren(parentIds);
  if (path.length === 0) return false;
  return state.getIn(['topicsTree'].concat(path, id, 'open'), false);
};

export const getTreeSectionValue = (state, id, parentIds, field) => {
  const path = interleaveParentsWithChildren(parentIds);
  if (path.length === 0) return false;
  return state.getIn(['topicsTree'].concat(path, id, field), false);
};

export const getSelectedTreeNodes = createSelector(
  [topicsTree, getActiveSubject],
  (tree, activeSubject) => {
    const treeRoot = tree.get(activeSubject.id);

    return parseTree(treeRoot, activeSubject.id, List());
  }
);
export const exericseTopicsTree = state => state.get('exerciseTree', Map());

export const getSelectedExerciseTreeNodes = createSelector(
  [exericseTopicsTree, getActiveSubject],
  (tree, activeSubject) => {
    const treeRoot = tree.get(activeSubject.id);

    return parseTree(treeRoot, activeSubject.id, List());
  }
);
const parseTree = (rootElement, rootKey, accumulator) => {
  if (!rootElement) return accumulator;
  const children = rootElement.get('children');
  if (children) {
    children.forEach((child, childKey) => {
      accumulator = parseTree(children.get(childKey), childKey, accumulator);
    });
  } else {
    if (rootElement.get('selected'))
      return accumulator.concat(rootKey);
  }

  return accumulator;
};

export const scrollToPreviewId = state => state.getIn(['topicsTree', 'scrollToPreviewId']);
export const getActiveTopicId = state => state.getIn(['topicsTree', 'topicId']);
export const getActivSubTopicId = state => state.getIn(['topicsTree', 'subtopicId']);