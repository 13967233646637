import React from "react"
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import BackToMenuButtonContainer from 'pages/menu/BackToMenuButtonContainer';
import SupportComponent from 'v2/components/supports-manager/SupportContainer';


const ManualPage = () => {
  return <div className="standalone pt-0" id="more-page setting-page">
     <NavigationHeader 
      extendedWidth 
      title="" 
      leftSection={(<BackToMenuButtonContainer/>)}
     />
     <SupportComponent  />
  </div>
}


export default ManualPage