import { toggleTeachingLevelOverlay } from "actions/navigation";
import classNames from "classnames";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeSubjectId, getActiveSubject, isTeachingLevelOverlayVisible, subjectsTeachingLevel } from "selectors";



const TeachingLevel = () => {
    const dispatch = useDispatch()
    const activeSubject = useSelector(getActiveSubject);
    const hasSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
    const hasTeachingLevelOverlayVisible = useSelector(isTeachingLevelOverlayVisible);
    const hasActiveSubjectId = useSelector(activeSubjectId);
    const isNewSubject = activeSubject?.isNew || activeSubject?.tagging === 'NEW_SUBJECT'
    const tlvl = hasSubjectsTeachingLevel[`${hasActiveSubjectId}`];
    const teachingTag = activeSubject?.slug !== "pre-ib-mathematics" ? tlvl : "Pre-IB"
    const studiesTag = tlvl && (teachingTag.indexOf('Studies SL') !== -1 || teachingTag.indexOf('Pre-IB') !== -1);
    const toggleTeachingLevel = () => {
        dispatch(toggleTeachingLevelOverlay(!hasTeachingLevelOverlayVisible))
    }

    const teachingLevelWrapper = useCallback(
    (onClick?: () => void) => {
      return (
        <div
          className={classNames("rounded-15px", {
            "ml-2 py-1.5 px-2.5 cursor-pointer select-none gray-background-hover":
              !isNewSubject,
          })}
          onClick={onClick}
        >
          {tlvl && (
            <div
              className={classNames(
                "flex items-center justify-center rounded-5px font-normal text-13px leading-18px text-white min-w-27px h-20px bg-#1FCD6C",
                {
                  "bg-#458CFC":
                    teachingTag.indexOf("SL") !== -1 ||
                    teachingTag.indexOf("Pre-IB") !== -1,
                  "w-full": studiesTag,
                  "w-27px": !studiesTag,
                  "py-0 px-2": studiesTag,
                }
              )}
            >
              {activeSubject?.slug === "pre-ib-mathematics" ? "Pre-IB" : tlvl}
            </div>
          )}
        </div>
      );
    },
    [activeSubject?.slug, isNewSubject, studiesTag, teachingTag, tlvl]
  );
  return (
    <>
      {isNewSubject ? (
        <div
          className="ml-2 gray-background-hover flex items-center justify-center gap-[5px] rounded-2xl cursor-pointer py-[5px] px-2.5 select-none"
          onClick={() => toggleTeachingLevel()}
        >
          <div className="flex items-center justify-center rounded-[4px] year-wrapper w-[42px] h-[22px]">
            <div className="font-normal text-13px text-[#A2A3A3]">
              {activeSubject?.tagging === "NEW_SUBJECT" ? "2023" : "2014"}
            </div>
          </div>
          {teachingLevelWrapper()}
        </div>
      ) : (
        <>{teachingLevelWrapper(toggleTeachingLevel)}</>
      )}
    </>
  );
};
export default TeachingLevel;