import styled, {css} from 'styled-components/macro';
import media from 'ui-components/helpers/media';

const FooterMenuWrapper = styled.div`
  // width: ${props => props.theme.leftMenuWidth};
  // ${media.phone(css`
  //   width: 100%;
  // `)}
`;
export default FooterMenuWrapper;
