import {createLogger} from 'redux-logger';
import Immutable from 'immutable';

const toJS = immutable => {
  if (Immutable.Iterable.isIterable(immutable)) {
    if (immutable.count() > 500) {
      return `Collection of ${immutable.count()} items, skipping.`;
    } else {
      return immutable.toSeq().map(item => toJS(item)).toJSON();
    }
  }
  return immutable;
};

const loggerMiddleware = createLogger({
  stateTransformer: state => state,
  titleFormatter: (action, time, took) => `${action.type} @ ${time} (in ${took.toFixed(2)} ms)`,
  duration: true,
  collapsed: (state, action) => !action.error,
  level: action => {
    return action.error ? 'error' : 'info';
  }
  /*, predicate: (getState, action) => !action.type.match(/@@redux-form\/(.*)/)*/
});

export {loggerMiddleware};
