import classNames from "classnames";
import React from "react";
import SubjectNotification from "v2/components/notifications/SubjectNotification";

type YearButtonProps = {
  onClick?: () => void;
  year: string;
  isActive?: boolean;
  newTag?: boolean;
  exerciseNotificationCount: number;
};

const YearButton: React.FC<YearButtonProps> = ({
  onClick,
  year,
  isActive,
  newTag,
  exerciseNotificationCount
}) => {
  return (
    <>
    <div
      className={classNames(
        "flex flex-1 items-center justify-center cursor-pointer year-button",
        {
          "active bg-[#E6EEF7]": isActive,
        }
      )}
      onClick={() => onClick?.()}
    >
      {newTag && (
        <div className="new-tag rounded-md py-1 flex items-center justify-center px-7px text-[#FF8209] font-semibold text-[10px]">
          New
        </div>
      )}
      <div className="flex items-center justify-center rounded-[4px] year-wrapper w-[42px] h-[22px] bg-white">
        <div className="font-normal text-13px text-[#A2A3A3]">{year}</div>
      </div>
    </div>
    {exerciseNotificationCount > 0 && (
        <div className="relative">
          <SubjectNotification
            amount={exerciseNotificationCount}
            className="!right-0.5 !-top-2.5" 
          />
        </div>
      )}
      </>
  );
};

export default YearButton;
