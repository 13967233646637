import { statusById } from 'selectors/statusSelector';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import { returnFinalStatus } from 'v2/helpers';

function useStatusBars<T>(id: T) {
  const mediaStatus = useSelector((state) => statusById(state, id))
  
  const statusBars = {
    flashcard: mediaStatus.get("flashcard") || Map({}),
    revision: mediaStatus.get("revision") || Map({}),
    video: mediaStatus.get("video") || Map({}),
    exams: mediaStatus.get("exam").get('freeResponse') || Map({}),
    mcq: mediaStatus.get("exam").get('msqTotal') || Map({})

  }
  const { flashcardStatus, revisionStatus, videoStatus, flashcardStatusGraph, revisionStatusGraph, videoStatusGraph, examsStatus, examsStatusGraph, mcqStatusGraph, mcqStatus } = returnFinalStatus(statusBars);
  return { flashcardStatus, revisionStatus, videoStatus, flashcardStatusGraph, revisionStatusGraph, videoStatusGraph, examsStatus, examsStatusGraph, mcqStatusGraph, mcqStatus }
}

export default useStatusBars;