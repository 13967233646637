import React, {Component} from 'react';
import './ComingSoon.scss';

export default class ComingSoonComponent extends Component {
  render() {
    return(
      <div id="coming-soon" className={this.props.className}>
        {this.props.children}
        <div className="coming-soon-title">{this.props.title}</div>
        <div className="coming-soon-subtitle">Coming soon</div>
        <div className="coming-soon-content">{this.props.text}</div>
      </div>
    );
  }
}
