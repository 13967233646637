import {Component} from 'react';
import PropTypes from 'prop-types';
import * as Actions from 'actions';
import {connect} from 'react-redux';

class ConfirmEmailContainer extends Component {
  UNSAFE_componentWillMount() {
    let token = this.props.match.params.token;
    // this.props.confirmEmailAddress({'token': token});

  }
  render() {
    return null;
  }
}
ConfirmEmailContainer.propTypes = {
  match: PropTypes.object.isRequired,
  confirmEmailAddress: PropTypes.func
};
const mapDispatchToProps = {
  confirmEmailAddress: Actions.Api.confirmEmailAddress
};
export default connect(null, mapDispatchToProps)(ConfirmEmailContainer);
