import './RegisterPage.scss';
import PropTypes from 'prop-types';
import * as Actions from 'actions';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import * as Selectors from 'selectors';
import {reduxForm} from 'redux-form/immutable';
import {findFirstFieldWithError} from 'utils/form-helper';
import RegisterPageComponent from './RegisterPageComponent';

class RegisterPageContainer extends Component {
  UNSAFE_componentWillMount() {
    this.props.setDocumentTitle('Register');
  }

  submitRegisterForm(values) {
    return new Promise((resolve, reject) => {
      // this.props.registerUser(values, {resolve, reject});
    });
  }

  render() {
    return (
      <RegisterPageComponent onSubmit={this.submitRegisterForm.bind(this)} {...this.props}/>
    );
  }
}

const mapDispatchToProps = {
  // registerUser: Actions.Api.registerUser,
  toggleChooseAvatar: Actions.User.toggleChooseAvatar,
  setDocumentTitle: Actions.Navigation.setDocumentTitle
};

const mapStateToProps = (state) => ({
  isAvatarComponentExpanded: Selectors.isAvatarComponentExpanded(state),
  requestError: Selectors.getErrorInApiRequest(state, 'register')
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'registerForm',
  onSubmitFail: (errors) => findFirstFieldWithError(errors)
})(RegisterPageContainer));

RegisterPageContainer.propTypes = {
  // registerUser: PropTypes.func,
  setDocumentTitle: PropTypes.func.isRequired
};
