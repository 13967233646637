import Immutable from 'immutable';
import {SELECT_TOOL, REMOVE_TOOL} from 'actions/markings/markingsActionTypes';

export default (state = Immutable.Map(), action) => {
  switch (action.type) {
    case SELECT_TOOL:
      return state.set('currentTool', Immutable.Map({type: action.toolType, color: action.color}));
    case REMOVE_TOOL:
      return state.delete('currentTool');
    default:
      return state;
  }
};
