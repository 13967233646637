import { useSelector } from 'react-redux';
import { getSelectedTreeNodes } from 'selectors/topicsTreeSelector';
import classNames from 'classnames';

type SelectedCardsButtonWrapperProps = {
  isIframe: boolean
}

const SelectedCardsButtonWrapper: React.FC<SelectedCardsButtonWrapperProps> = ({ isIframe }) => {
  const selectedTreeNodes = useSelector(getSelectedTreeNodes);
  const cardText = selectedTreeNodes.size === 1 ? 'card' : 'cards';

  if (selectedTreeNodes.size === 0) return null;
  return <div className={classNames('selected-items-wrapper flex items-center justify-center bottom-3 right-0 z-3 fixed', { 'inner-selected-items-wrapper !left-0': isIframe })} >
    <div className='selected-card-btn'>{selectedTreeNodes.size} {cardText} selected</div>
  </div>;
}

export default SelectedCardsButtonWrapper