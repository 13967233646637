import React from 'react'
import CorrectAnswerIcon from 'assets/icons/correct-answer-icon.svg';
import WrongAnswerIcon from 'assets/icons/wrong-answer-icon.svg';
import {ClosePopUp} from 'icons/FlashcardNavIcons';
import {XXLNotificationIcon} from "icons/FlashcardIcons"

export const AnswerNotification = ({type}: {type: string}) => {
    const feedback = type === 'correct' ? 'Correct!' : 'Wrong!';
    const extendedFeedback = type === 'correct' ? 'You gave a complete and accurate answer. Impressive!' : 'Your answer was incomplete and/or inaccurate. Try again!';
    return <div className='flex items-center justify-between w-full'>
        <div className='flex items-center'>
            <img src={type === 'correct' ? CorrectAnswerIcon : WrongAnswerIcon} alt=""/>
            <div className='answer-text'>{feedback}</div>
        </div>
        <div className='answer-correct-text'>{extendedFeedback}</div>
        <ClosePopUp color={'#FFFFFF'}/>
    </div>
  };

  export const XXLCardNotification = ({mobile}: {mobile: boolean}) => {
    const text = mobile ? 'This is an XXL card. Scroll to navigate the card.' : 'This is an XXL card. Scroll to navigate the card.';
    return <div className='flex items-center justify-between w-full'>
        <XXLNotificationIcon />
        <div className='xxl-text'>{text}</div>
        <ClosePopUp color={'#FFFFFF'}/>
    </div>
  };

 
  