import React from 'react'
import { ESMExamsIcons } from "icons/HeaderIcons"

const ExamsModeNoResults = ({}) => {
  return <div className='pt-[104px] pb-2 px-8'>
    <div className='flex items-center justify-center'><ESMExamsIcons /></div>
    <div className='text-[#F4803D] font-bold text-17px leading-23px text-center pt-2.5 pb-2'>Content search unavailable in exam mode</div>
    <div className='text-#A2A3A3 text-center text-13px leading-16px px-[5px]'>Currently, you can only search question titles. As this is a piece of information that could help solve a question, it is unavailable in exam mode.</div>
    <div className='text-#A2A3A3 mt-4 text-center font-bold text-13px leading-16px px-[5px]'>Deactivate exam mode to browse available  search results or set different search filter.</div>
  </div>
}

export default ExamsModeNoResults