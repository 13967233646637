import React from 'react'
import { useSelector } from 'react-redux';
import { getUserData } from 'selectors/user';
import { getSubjects } from 'selectors/api/apiSelector';
import { getDaysLeft, getIsInTrial, getIsLessThanOneDay, isFreeTrialNotificationOpen } from 'selectors/onboardingSelectors';
import { getMBUrlBasedOnRegion } from 'v2/helpers';
import WarningIcon from 'assets/icons/free-trial/warning-icon.svg';
import { FreeTrialIcon } from 'icons/HeaderIcons';

type FreeTrialNotificationProps = {
  className?: string;
  hasEnded?: boolean;
  large?: boolean;
  onClick?: () => void;
}

const WarningIconComponent = (): React.ReactElement | JSX.Element => <div className='text-center'><img src={WarningIcon} alt="" /></div>;

const LargeText = (): React.ReactElement | JSX.Element => <div className='text-center text-13px text-white leading-normal'>
  <div className='pt-2.5 leading-[normal]'>
    Your account remains active and free, but you now can no longer enjoy access to the 11+ IB Diploma subjects.
    <br /><br />
    We saved all your study progress, notes, folders, and exercises so that you can pick up where you left off once you decide to upgrade your account.
  </div>
</div>

const BottomText = ({ onClick }: any): React.ReactElement | JSX.Element => <>
  <div className='pt-8 text-center text-white text-13px leading-16px'>Want to continue experiencing OSC Study with some sample content?</div>
  <div onClick={onClick} className='cont-btn mt-3.5 bg-white rounded-[22px] text-[#F5973D] font-bold text-13px leading-[18px] tracking-[2px] uppercase h-9 flex items-center justify-center cursor-pointer select-none'>
    CONTINUE IN DEMO MODE
  </div>
</>

const FreeTrialNotification: React.FC<FreeTrialNotificationProps> = ({ className, hasEnded, large, onClick }) => {
  const daysLeft = useSelector(getDaysLeft);
  const isInTrial = useSelector(getIsInTrial);
  const isLessThanOneDay = useSelector(getIsLessThanOneDay);
  const subjects = useSelector(getSubjects);
  const userData = useSelector(getUserData);
  const hasFreeTrialNotificationOpen = useSelector(isFreeTrialNotificationOpen);

  let url, text, warningIconImg, bottomText = null, secondLineText = null, thirdLineText = null;

  const hasNoVoucher = subjects && subjects.length === 1;

  const handleClick = (): void => {
    if (hasEnded) {
      onClick?.()
    }
  }

  // if has voucher and not in trial return
  if (!isInTrial && !hasNoVoucher) return null;
  // hide notification if closed in the side menu
  if (!hasFreeTrialNotificationOpen && !large) return null;
  const hasEndedLessThanOneDay = daysLeft <= 0 && isLessThanOneDay === false;
  // const hasEnded = true;
  // if voucher has not ended
  if (!hasEndedLessThanOneDay && daysLeft > 7) return null;
  if (large && (typeof (daysLeft) == 'undefined' ? subjects && subjects.length !== 1 : !hasEndedLessThanOneDay)) return null;
  if (!large && userData.fromSchool === "YES" && !hasEndedLessThanOneDay) return null;

  if (userData && userData.fromSchool === "YES") {
    url = `https://${userData.subdomain}.${getMBUrlBasedOnRegion(userData.region)}/${userData.mbRole.toLowerCase()}${userData.mbRole.toLowerCase() === 'teacher' ? '/oxford_study_courses' : '/oxford_study_courses'}`;
  } else if (userData && userData.fromSchool === "NO") {
    url = `https://${userData.subdomain}.${getMBUrlBasedOnRegion(userData.region)}/${userData.mbRole.toLowerCase()}${userData.mbRole.toLowerCase() === 'teacher' ? '/oxford_study_courses' : '/oxford_study_courses'}`;
  } else {
    url = `https://oxfordstudycourses.com/student/study`
  }

  const buttonText = (hasEndedLessThanOneDay || hasNoVoucher) ? 'UPGRADE NOW ' : userData && userData.fromSchool !== "YES" ? 'UPGRADE NOW ' : 'GO TO OSC HOME ';

  // has no voucher
  if (hasNoVoucher && !isInTrial) {
    text = (<span><b>Full access to all subjects has ended</b></span>);
    // voucher ended
  } else if (hasEndedLessThanOneDay) {
    text = <b>Full access to all subjects has ended</b>;
    warningIconImg = <WarningIconComponent />;
    // trial period
  } else if (!hasEndedLessThanOneDay) {
    text = (<span><b>Full access to all subjects is ending in</b></span>);
    warningIconImg = <WarningIconComponent />;
  }

  // trial period
  if ((!hasNoVoucher && !hasEndedLessThanOneDay) || (isInTrial && !hasEndedLessThanOneDay)) {
    secondLineText = <b>{(isLessThanOneDay || daysLeft < 2) ? "1 day" : `${daysLeft} days`}</b>;
    thirdLineText = 'Want to seamlessly continue with OSC Study?';
  } else if (large) {
    secondLineText = <LargeText />;
  }
  if (large) {
    bottomText = <BottomText onClick={handleClick} />
  } else {
    bottomText = null
  }

  return <div className={className + ' free-trial-notification mt-1 mx-3.5 mb-3 pt-3.5 px-3.5 pb-4 rounded-[10px] relative'}>
    <div className='text-white text-center text-13px leading-18px'>
      <div className='text-center'>{text} {secondLineText}</div>
    </div>
    <div className='text-white text-center text-13px leading-18px'>
      <div className='text-center'>{thirdLineText}</div>
    </div>
    <div className='flex justify-center'>
      <a className='button mt-3.5 font-bold uppercase text-13px text-white tracking-[2px] text-center py-9px w-full box-border rounded-[22px] outline-none leading-[normal] flex items-center justify-center' target='_blank' href={url}>
        {buttonText} <FreeTrialIcon />
      </a>
    </div>
    {bottomText}
  </div>
}

export default FreeTrialNotification
