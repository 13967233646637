import {SET_QUICK_NODE_STATUS} from 'actions/quickStatus/quickStatusActionTypes';
import {fromJS, Map} from 'immutable';

export default (state = Map({
  flashcard: Map({}),
    revision: Map({}),
    video: Map({}),
    exam: Map({})
}), action) => {
  switch (action.type) {
   
     case SET_QUICK_NODE_STATUS:
      return state.withMutations(state => {
        let node = action.data;
        state.set(node.id, fromJS({flashcard: node.flashcard, revision: node.revision, video: node.video, exam: node.exam}));
        return state;
      });
    default:
      return state;
  }
};
