import React from 'react'
import { connect } from 'react-redux'
import { MircoFilterContainer, ExamsFilter, FilterClear,FilterText, FilterTextRow } from "./examFilter.styles"
import Text from 'ui-components/helpers/Text';
import { ArrowdownIcon, FilterClearIcon } from 'icons/HeaderIcons';
import * as Actions from 'actions';
import * as Selectors from 'selectors';
import { CorrectIcon, IncorrectIcon } from "icons/FlashcardIcons"
import Padding from 'ui-components/helpers/Padding';

export const MCQFilterLayout = (props) => {
    let data = props.examsofFilterItems.toJS()
    function setFormCCPopoup(){
        if(props.formCC){ 
            props.mainMicroFilterBack(true,true);
        }else{
            props.mainMicroFilterBack(false,false);
        }
    }
    const selectMCQFilter = (e) => {
        e.stopPropagation();
        props.toggleMCQOverlay(true, true);
        setFormCCPopoup()
        if(props.isMainMicroFilterVisible) return props.toggleMainMicroOverlay(false,false)
        // props.toggleMainMicroOverlay(false,false);
    };
    const clearFilter = (e, val) => {
        e.stopPropagation();
        props.setExamFilterData();
        props.updateExamsPaperFilterItems([], val);
        props.setExamFilterData();
    }
    const assesmentIcon = (value) => {
        let val;
        if (value.includes("Not yet answered")) {
            val = 'Not ye...'
        } else if (value.includes("Incorrect")) {
            val = 'Incorrect'
        } else if (value.includes("Correct")) {
            val = 'Correct'
        } 
        switch (val) {
            case 'Not yet answered':
                return <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{val}</Text>
            case 'Incorrect':
                return <React.Fragment alignItems={'center'}> <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{val}</Text> <Padding left={2} /></React.Fragment>
            case 'Correct':
                return <React.Fragment alignItems={'center'}> <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{val}</Text> <Padding left={2} /> </React.Fragment>
            default:
                return <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{val}</Text>
        }
    }
    return (
        <React.Fragment>
            {data['mcq'].length > 0 && data['mcq'].length < 3 ?
                <MircoFilterContainer>
                    <ExamsFilter isEditMode={props.isEditMode} onClick={(e) => selectMCQFilter(e)} filter={true} isMarging={true} >
                        <FilterTextRow>{assesmentIcon(data['mcq'])}</FilterTextRow> {data['mcq'].length > 1 && <FilterText data={data['mcq'][0]}>+ {data['mcq'].length - 1}</FilterText>}
                        {/* {assesmentIcon(data['difficulty'])} */}
                        <div className="closeButton1"> </div>
                    </ExamsFilter>
                    <FilterClear onClick={(e) => clearFilter(e, 'mcq')} isEditMode={props.isEditMode}> <FilterClearIcon /></FilterClear>
                </MircoFilterContainer> :
                <MircoFilterContainer>
                    <ExamsFilter isEditMode={props.isEditMode} isMarging={true} onClick={(e) => selectMCQFilter(e)} filter={props.isMCQOverlayVisible}>
                        <Text size={13} weight={'bold'} color={'#2D3838'} lineHeight={'18px'}>MCQ answer</Text>
                        <ArrowdownIcon isMarging={true} />
                    </ExamsFilter>
                </MircoFilterContainer>
                
            }
            <Padding left={8} />
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    examsofFilterItems: Selectors.examsofFilterItems(state),
    isMCQOverlayVisible: Selectors.isMCQOverlayVisible(state),
    isMainMicroFilterVisible:Selectors.isMainMicroFilterVisible(state)
})

const mapDispatchToProps = {
    toggleMCQOverlay: Actions.Navigation.toggleMCQOverlay,
    updateExamsPaperFilterItems: Actions.Desk.updateExamsPaperFilterItems,
    toggleMainMicroOverlay:Actions.Navigation.toggleMainMicroOverlay,
    mainMicroFilterBack:Actions.Navigation.mainMicroFilterBack,
    setExamFilterData: Actions.Api.setExamFilterData,
}

export default connect(mapStateToProps, mapDispatchToProps)(MCQFilterLayout)
