import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isMenuVisible } from 'selectors';

import './navigationHeader.scss';


type NavigationHeaderProps = {
  title?: any,
  text?: string | null,
  className?: string,
  inverted?: boolean,
  leftSection?: React.ReactNode,
  rightSection?: React.ReactNode,
  bottomSection?: React.ReactNode,
  isCollapsed?: boolean,
  extendedWidth?: boolean,
  contained?: boolean,
  extendedHeight?: boolean,
  shouldExtendHeight?: boolean,
  isSearchPage?: boolean,
  isSearch?: boolean,
  hideborder?: boolean,
  teachingLevelsForActiveSubject?: boolean,
  isSupportPage?: boolean,
  isLinkPage?: boolean,
  collapsedTitle?: any
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({ isCollapsed, bottomSection, className, inverted, isSearchPage, isSearch, extendedWidth, contained, extendedHeight, rightSection, shouldExtendHeight, leftSection, text, title, hideborder, isSupportPage, teachingLevelsForActiveSubject, isLinkPage, collapsedTitle }) => {
  const hasMenuVisible = useSelector(isMenuVisible)
  const cssClassModifiers = useMemo(() => {
    let cssClass = '';
    if (isCollapsed && !bottomSection) cssClass += ' shadow';
    if (inverted) cssClass += ' inverted';
    if (isSearchPage) cssClass += ' search-inverted';
    if (isSearch) cssClass += ' is-search';
    if (extendedWidth) cssClass += ' extended-width';
    if (className) cssClass += ' ' + className;
    if (contained) cssClass += ' contained';
    if (hideborder) cssClass += ' hide-border';
    if (shouldExtendHeight) cssClass += ' extended-height';

    if (teachingLevelsForActiveSubject) cssClass += ' has-two-teaching-levels';

    if (extendedHeight) cssClass += ' large';
    if (isSupportPage) cssClass += ' is-support-page';
    if (!isLinkPage) cssClass += ' is-not-link-page';
    return cssClass
  }, [isCollapsed, bottomSection, inverted, isSearchPage, isSearch, extendedWidth, className, contained, hideborder, shouldExtendHeight, teachingLevelsForActiveSubject, extendedHeight, isSupportPage, isLinkPage])

  return <div className={`navigation-header${cssClassModifiers}`}>
    <div className='self-center min-h-[24px] flex justify-between items-center w-full'>
      <div className="tutorial-step-header-icons inline-block">
        {leftSection ? leftSection : /* renderDefaultIcons */ null}
      </div>
      <div className={`small-title ${isCollapsed || title ? 'visible' : ''} ${inverted ? 'inverted' : ''} ${!hasMenuVisible ? 'invisible-menu' : ''}`}>
        {isCollapsed ? (collapsedTitle || text || title) : title}
      </div>
      <div className={`right-section`}>{rightSection} </div>
    </div>
    <div className={`bottom-section ${shouldExtendHeight ? '' : 'hidden'}`}>{bottomSection}</div>
  </div>
}
export default NavigationHeader