import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { activeSubjectId } from 'selectors/subject/subjectSelector';
import { activeSearchTerm } from 'selectors/searchSelectors';
import { searchExercisesRoutine } from 'actions/api';
import usePrevious from 'hooks/usePrevious';
import { setActiveSearchTab, setGoBackToSearch } from 'actions/search';
import { useHistory } from 'react-router-dom';
import { exercisesSearchResponse } from 'selectors/api/apiSelector';
import NoResults from 'ui-components/search-results/NoResults';
import { ListTypeHeader } from 'ui-components/search-results/PreviewListItem';
import MarkText from 'ui-components/MarkText';
import { ExercisesIcon } from 'icons/SearchIcons';
import ExerciseTile from 'v2/components/exercise/ExerciseTile';


type ExercisesResultsContainerProps = {
  hidden?: boolean;
  isPartial?: boolean;
}

const ExercisesResultsContainer: React.FC<ExercisesResultsContainerProps> = ({ hidden = false, isPartial = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const searchTerm = useSelector(activeSearchTerm);
  const subjectId = useSelector(activeSubjectId);
  const getExercisesSearchResponse = useSelector(exercisesSearchResponse).toJS();
  const prevSearchTerm = usePrevious(searchTerm);

  useEffect(() => {
    searchTerm && dispatch(searchExercisesRoutine({ page: 0, searchField: searchTerm, subjectId: subjectId }));
  }, [])
  useEffect(() => {
    if (prevSearchTerm !== searchTerm) {
      dispatch(searchExercisesRoutine({ page: 0, searchField: searchTerm, subjectId: subjectId }));
    }
  }, [searchTerm, prevSearchTerm])

  const onExerciseClick = (exercise: any) => {
    const exerciseId = exercise.exerciseId;
    dispatch(setGoBackToSearch(true));
    if (exercise.mbTeacherId) {
      history.push(`/flashcard/classroom/exercise/${exerciseId}`);
    } else {
      history.push(`/flashcard/desk/exercise/${exerciseId}`);
    }
  }
  const requestSucceeded = getExercisesSearchResponse.succeeded ?? false;
  const requestData = getExercisesSearchResponse.data  ?? [];  
  const hasData = requestData.length > 0;
  if (hidden || !requestData) return null;
  if (requestSucceeded && !isPartial && !hasData) return <NoResults />;
  //simulate pagination
  const exercisesToRender = isPartial && requestData.length > 3 ? requestData.slice(0, 3) : requestData;
  return <>
    {hasData && <div className='pb-1.5'>
      <ListTypeHeader title={'Exercises'} icon={<ExercisesIcon color={'#2D3838'} />} showMore={isPartial}
        total={getExercisesSearchResponse.count}
        onClick={() => dispatch(setActiveSearchTab('Exercises'))} />
    </div>}
    {exercisesToRender.map((exercise: any) => {
      const exerciseId = exercise.exerciseId;
      return <ExerciseTile key={exerciseId} exercise={exercise} onClick={() => onExerciseClick(exercise)}
      titleComponent={() => <MarkText><div className='font-bold text-[25px] text-#2D3838 leading-34px'>{exercise.title}</div></MarkText>}
    />
    })}
  </>
}

export default ExercisesResultsContainer