import React, { useState, useEffect, useMemo } from 'react'
import {
    carouselFlashcardIndex,
    carouselRefIndex,
    carouselRendererIds, hasNextRevision, hasPrevFlashcard
} from 'selectors/carouselSelectors';
import { activeSubjectId, isLandscape, isMobile, isNavigationSectionVisible, mediaSizes } from "selectors"
import { getCardsTitleByType } from 'actions/api/apiActions';
import SearchNavigationContainer from 'v2/components/studying/SearchNavigation/SearchNavigationContainer';
import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2';
import { getUserId } from 'selectors/user';
import { getLastMediaTypeVisited } from 'helpers';
import './revision.scss'
import { useSelector, useDispatch } from "react-redux"
import RevisionCarousel from './RevisionCarousel';
import MediaBottomButton from 'v2/components/studying/MediaBottomButton'
import classNames from 'classnames';

const RevisionCarouselContainer = () => {

    const [isLoading, setLoading] = useState<Boolean>(true);
    const dispatch = useDispatch();
    const hasCarouselRefIndex = useSelector(carouselRefIndex);
    const hasActiveSubjectId = useSelector(activeSubjectId);
    const hasLandscape = useSelector(isLandscape);
    const hasMobile = useSelector(isMobile);
    const flashcardIndex = useSelector(carouselFlashcardIndex);
    const rendererIds = useSelector(carouselRendererIds);
    const hasMediaSizes = useSelector(mediaSizes);
    const hasNext = useSelector(hasNextRevision);
    const hasPrev = useSelector(hasPrevFlashcard);
    const isSPhone = hasMediaSizes?.sPhone;
    const navigationSectionVisible = useSelector(isNavigationSectionVisible)
    const userId = useSelector(getUserId);
    const mediaType = getLastMediaTypeVisited(userId)


    useEffect(() => {
        dispatch(getCardsTitleByType({ subjectId: hasActiveSubjectId, mediaType: "revision" }))
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, [])

    const computeOrder = useMemo(():any => {
        let orderArr = new Array(3);
        orderArr[hasCarouselRefIndex] = 1;
        orderArr[(hasCarouselRefIndex + 1) % 3] = 2;
        orderArr[(hasCarouselRefIndex + 2) % 3] = 3;
        return orderArr;
    }, [hasCarouselRefIndex]);



    if (isLoading) {
        return (
            <div className={'studying-wrapper-overlay-loader'}>
                <Loader background={BackgroundEnum.BlueX} size={SizeEnum.Large} />
            </div>
        );
    }
    return (
        <div className='revision-wrapper'>
            <RevisionCarousel
                order={computeOrder} rendererIds={rendererIds} flashcardId={rendererIds?.[1]} flashcardIndex={flashcardIndex} carouselRefIndex={hasCarouselRefIndex}
                isMobile={hasMobile} isLandscape={hasLandscape}
            />
            <div className={classNames("revision-bottom-controls absolute mx-auto flex items-center justify-center px-5 rounded-10px", { "left-menu-invisible": navigationSectionVisible, "left-menu-visible": !navigationSectionVisible})}>
                <MediaBottomButton id={rendererIds?.[1]} mediaType={mediaType} hasPrev={hasPrev} hasNext={hasNext} />
                <div className="revision-search" >
                    <SearchNavigationContainer isMobile={isSPhone} isLandscape={hasLandscape} />
                </div>
            </div>

        </div>
    )
}

export default RevisionCarouselContainer;