import React, {Component} from 'react';
import {connect} from 'react-redux';
import { isLandscape, isMobile,} from 'selectors';
import {ReactComponent as CloseIcon} from 'assets/icons/page-overlay/close-icon-turquoise.svg';
import "./microFilterOverlay.scss"
import {ConfidentIcon, UnconfidentIcon, NeitherIcon} from 'icons/FlashcardIcons';
import CheckRadioButton from 'v2/components/check-radio-button/CheckRadioButton';
import styled, {css} from 'styled-components/macro';
import Text from 'ui-components/helpers/Text';
import * as Actions from 'actions';
import * as Selectors from 'selectors';
import appHistory from 'appHistory';
import {getSelectedFlashcards} from 'actions/topics-tree/topicsTreeActions';
import {getActiveExerciseId} from 'selectors/studying';
import media from 'ui-components/helpers/media';
import {updateExerciseNumber} from 'actions/topics-tree/topicsTreeActions';
import BackButton from 'components/navigation-header/BackButton'; 
import FilterHeader from '../examFilter/FilterHeader';

export const titles = {
  'flashcard': 'Confidence',
  'revision': 'Reading status',
  'video': 'Watch status',
  'exams':'Confidence'
}
const heading = {
  'flashcard': 'All confidence levels',
  'revision': 'All reading status',
  'video': 'All watch status',
  'exams': 'All confidence levels',
}

const filters = {
 flashcard:[
   [{text : 'Not yet assessed'},{icon : ''}],
   [{text : 'Unconfident'},{icon:<UnconfidentIcon />}],
   [{text : 'Neither'},{icon: <NeitherIcon />}],
   [{text : 'Confident'},{icon: <ConfidentIcon/>}]
  ],
  revision:[
    [{text : 'Not yet read'}, {icon : ''}],
    [{text : 'Read'}, {icon: ''}]
  ],
  video:[
    [{text : 'Not yet watched'}, {icon : ''}],
    [{text : 'Partly-watched'}, {icon: ''}],
    [{text : 'Watched'}, {icon : ''}]
  ],
  exams:[
    [{text : 'Not yet assessed'},{icon : ''}],
    [{text : 'Unconfident'},{icon:<UnconfidentIcon />}],
    [{text : 'Neither'},{icon: <NeitherIcon />}],
    [{text : 'Confident'},{icon: <ConfidentIcon/>}]
  ]

}
class MicroFilterOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: false,	
      active:true,
      disabled:true,
      filter:{
          flashcard: ['Not yet assessed', 'Unconfident', 'Neither', 'Confident' ],
          revision: ['Not yet read', 'Read' ],
          video: ['Not yet watched', 'Partly-watched', 'Watched'  ],
          exams: ['Not yet assessed', 'Unconfident', 'Neither', 'Confident' ],
        }
      // filter:['Not yet assessed', 'Unconfident', 'Neither', 'Confident', 'Not yet read', 'Read', 'Not yet watched', 'Partly-watched', 'Watched'  ]
    };

    this.initialState = this.state

  }

  componentDidMount(){
    let data = this.props.listOfFilterCheckedItems.toJS()[this.props.mediaType]
    if(this.props.mediaType === "flashcard"){
      data.length > 0 && this.setState({ filter :{ ...this.state.filter,flashcard: data}});
    }
    if(this.props.mediaType === "revision"){
      data.length > 0 && this.setState({ filter :{ ...this.state.filter,revision: data}});
    }
    if(this.props.mediaType === "video"){
      data.length > 0 && this.setState({ filter :{ ...this.state.filter,video: data}});
    }
    if(this.props.mediaType === "exams"){
      data.length > 0 && this.setState({ filter :{ ...this.state.filter,exams: data}});
    }
  }

  selectFilter = (val) =>{

    const filter = [...this.state.filter[this.props.mediaType]];
    let indexOfItem = filter.indexOf(val);
    
    if (indexOfItem >= 0) {
      filter.splice(indexOfItem, 1);
    } else {
      filter.push(val);
    }
    if(this.props.mediaType === "flashcard"){
      this.setState({ filter :{ ...this.state.filter,flashcard: filter} });
    }
    if(this.props.mediaType === "revision"){
      this.setState({ filter :{ ...this.state.filter,revision: filter} });
    }
    if(this.props.mediaType === "video"){
      this.setState({ filter :{ ...this.state.filter,video: filter} });
    }
    if(this.props.mediaType === "exams"){
      this.setState({ filter :{ ...this.state.filter,exams: filter} });
    }
  }

  checkChangedFilter = () =>{
    let stateFilter = this.state.filter[this.props.mediaType]
    let reduxFilter = this.props.listOfFilterCheckedItems.toJS()[this.props.mediaType]
    // const sortedFilter = [...stateFilter].sort();
    // const sortedRedux = [...reduxFilter].sort();  
    //   if(JSON.stringify(sortedFilter) !== JSON.stringify(sortedRedux)){
      if(JSON.stringify(stateFilter.sort()) !== JSON.stringify(reduxFilter.sort())){
        this.setState({disabled: false})
      }else{
        this.setState({disabled: true})
      }
      if(this.state.filter[this.props.mediaType].length < 1){
        this.setState({disabled: true})
      }
      // if(this.state.filter[this.props.mediaType].length === 0){
      //   this.setState({active: false})
      // }
      if(this.state.filter[this.props.mediaType].length === this.initialState.filter[this.props.mediaType].length){
        this.setState({active: true})
      }else{
        this.setState({active: false})
      }
  }

  selectToggle = (val) => {

    this.setState({active: !val})
    if(this.state.active){      
      if(this.props.mediaType === "flashcard"){
        this.setState({ filter :{ ...this.state.filter,flashcard: []}});
      }
      if(this.props.mediaType === "revision"){
        this.setState({ filter :{ ...this.state.filter,revision: []}});
      }
      if(this.props.mediaType === "video"){
        this.setState({ filter :{ ...this.state.filter,video: []}});
      }
      if(this.props.mediaType === "exams"){
        this.setState({ filter :{ ...this.state.filter,exams: []}});
      }
    }else{
      if(this.props.mediaType === "flashcard"){
        this.setState({ filter :{ ...this.state.filter,flashcard: this.initialState.filter.flashcard}});
      }
      if(this.props.mediaType === "revision"){
        this.setState({ filter :{ ...this.state.filter,revision: this.initialState.filter.revision}});
      }
      if(this.props.mediaType === "video"){
        this.setState({ filter :{ ...this.state.filter,video: this.initialState.filter.video}});
      }
      if(this.props.mediaType === "exams"){
        this.setState({ filter :{ ...this.state.filter,exams: this.initialState.filter.exams}});
      }       
    }
  }

  componentDidUpdate(prevProps, prevState) {
   if(prevState.filter !== this.state.filter){
     this.checkChangedFilter()
   }
  }

  ApplyChanges = () =>{
    let stateFilter = this.state.filter[this.props.mediaType]
    this.props.updateFilterListOfItemsSelected(stateFilter, this.props.mediaType)
    this.props.toggleFilterOverlay(false, false)
    this.props.getMediaTypeFilterOverlay(null);
    const pathname = appHistory.location.pathname;
    if (pathname.indexOf("/flashcard/desk/exercise/edit/") !== -1  || (pathname.indexOf("flashcard/desk/exercise/create")  !== -1 )) {
      this.props.updateExerciseNumber(this.props.getActiveExerciseId)
    }
    if(this.props.mediaType === "exams"){
      this.props.setExamFilterData();
    }   
  }
  onSelectMain=()=>{
    this.props.onClose()
    this.props.toggleMainMicroOverlay(true, true);
}

  render() {
    const { isLandscape, isMobile, mediaType , listOfFilterCheckedItems} = this.props;
    // if (isLandscape && isMobile) return null;
    const formCC = this.props.isMainMicroFilterBack
    return (
      <React.Fragment>
          <FilterWrapper className="filter-pop-up" onClick={this.props.onClick} >
            <div className="filter-main-container">
              <FilterHeader 
                fromCC={formCC} 
                onBackClick={this.onSelectMain} 
                text={'Filter'} 
                hideArrow={false} 
                title={titles[mediaType]} 
                onCloseClick={this.props.onClose} 
              />
              {/* <PopupLeftSection> */}
                {/* {formCC && <React.Fragment>
                  <BackChevronTurquoiseIcon onClick={this.onSelectMain} />
                  <Text onClick={this.onSelectMain} size={13} textTransform={'uppercase'} color={'#378EF8'} lineHeight={'18px'} letterSpacing={'2'}>Filter</Text>
                </React.Fragment>
                } */}
                {/* {formCC &&
                  <BackButton onClick={this.onSelectMain} text="Filter" hideArrow={false} />
                }
                <div className="filter-heading">{titles[mediaType]}</div>
              </PopupLeftSection>
                <div className="filter-close-icon-box" onClick={()=> this.props.onClose()}>               
                  <div className="filter-close-icon">
                    <CloseIcon/>
                  </div>
                </div>
              </FilterHeader> */}
              <div className="filter-toggle-header">
                <div className="filter-toggle-heading">{heading[mediaType]}</div>
                <div className="filter-toggle-icon-box">               
                  <div className="filter-toggle-icon">
                  <label className="switch">
                    <input onChange={() => this.selectToggle(this.state.active)} type="checkbox" checked={this.state.active}/>
                    <span className="slider round"></span>
                  </label>
                  </div>
                </div>
              </div>
              <FilterOptionContainer> 
              {filters[mediaType].map((value ,index) =>{
                return(
                  <FilterInnerContainer key={index}  onClick={() =>this.selectFilter(value[0].text)}>
                    <div className="filter-checkbox">
                      <div className="filter-checkbox-icon"> 
                        <CheckRadioButton itemId={value[0].text} listOfCheckedItems={this.state.filter[mediaType]} isWidth={true}/>
                        {/* <CheckRadioButton itemId={value[0].text} listOfCheckedItems={this.props.listOfFilterCheckedItems.toJS()[mediaType]}/> */}
                      </div>
                    </div>
                    <div className="filter-options-container">
                        <div className="filter-title">{value[0].text}</div>                    
                        <div className="filter-icon">{value[1].icon}</div>
                    </div>
                  </FilterInnerContainer>               
                )
              })}
              </FilterOptionContainer>
              <ButtonsWrapper>
                <CancelButton  onClick={()=> this.props.onClose()}>
                  <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'} letterSpacing={'2'}>cancel</Text>
                </CancelButton>
                <ApplyButton disabled={this.state.disabled} onClick={()=> !this.state.disabled && this.ApplyChanges()}>
                  <Text size={13} weight={'bold'} color={'#FFFFFF'} lineHeight={'18px'} letterSpacing={'2'}>Apply</Text>
                </ApplyButton>
              </ButtonsWrapper>
            </div>
           </FilterWrapper>
      </React.Fragment>
    );
  }
}

const CancelButton = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    text-transform: uppercase;
    cursor: pointer;
    background: #FFFFFF;
    width: 146px;
    height: 36px;
    box-shadow: 0 0 0 1px #E3E6E6;
    box-sizing: border-box;
    border-radius: 22px;
    user-select:none;
    svg{
      margin-right: 12px;
    }
    &:active {
      box-shadow: none !important;
      background: #378EF8;
      >${Text}{
        color: #FFFFFF;
        margin:0
      }
    }

    @media (pointer: fine) {
        &:hover {
            box-shadow: 0 0 0 1px #C8CCCC;
        }
    } 
    
`;
const PopupLeftSection =styled.div`
display:flex;
align-items: center;
svg{
  cursor: pointer;
  width:20px;
}
.back-title{
  cursor: pointer;
  user-select: none;
  margin-right:23px;
}
`;

const ApplyButton = styled.div`
    height: 36px;
    width: 146px;
    background:  ${props => props.disabled ? '#EFF4F9' : '#378EF8'};
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${props => props.disabled ? 'initial' : 'pointer'};
    text-transform: uppercase;
    user-select:none;
    @media (pointer: fine) {
        &:hover {
            ${props => !props.disabled && css`
                background: #64A8FA;
            `};  
        }
    } 
    &:active {
        ${props => !props.disabled && css`
            background: #2170CF;
        `}; 
        
    }

`;

const ButtonsWrapper =styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px;
`;

const FilterOptionContainer = styled.div`
  padding: 0 20px 16px 20px;
`;

const FilterInnerContainer = styled.div`
  display: flex;
  align-items: center;
  ${'' /* padding-top: 5px;
  padding-bottom: 5px; */}
  cursor: pointer;
  height: 40px;
`;

const FilterWrapper = styled.div`
  // width: 100%;
  width: 347px;
  z-index: 2;
  overflow: hidden;
  // max-height: 332px; 
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
  border-radius: 10px;
  // border-top-right-radius: 0;
  // border-top-left-radius: 0;

  ${media.phone(css`
    // max-width: calc(100% - 28px);
  `)}
`;

// const FilterHeader = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   // padding: 18px 20px 8px 20px;
//   padding:${props => props.formCC ? "18px 20px 8px 12px" : "15px 14px 5px 20px"};
  
// `;
const mapStateToProps = state => ({
  isLandscape: isLandscape(state),
  isMobile: isMobile(state),
  getActiveExerciseId:getActiveExerciseId(state),
  listOfFilterCheckedItems: Selectors.listOfFilterCheckedItems(state),
  isMainMicroFilterBack:Selectors.isMainMicroFilterBack(state)
  

});

const mapDispatchToProps = {
  updateFilterListOfItemsSelected: Actions.Desk.updateFilterListOfItemsSelected,
  getSelectedFlashcards: getSelectedFlashcards,
  toggleFilterOverlay: Actions.Navigation.toggleFilterOverlay,
  getMediaTypeFilterOverlay: Actions.Navigation.getMediaTypeFilterOverlay,
  updateExerciseNumber: updateExerciseNumber,
  toggleMainMicroOverlay: Actions.Navigation.toggleMainMicroOverlay,
  setExamFilterData: Actions.Api.setExamFilterData,
}

export default connect( mapStateToProps, mapDispatchToProps )(MicroFilterOverlay);
