import React from 'react';
import {NotificationExerciseIcon} from 'icons/FlashcardNavIcons';
import './notifications.scss';
import classNames from 'classnames';

type Props = {
    amount:any;
    className?:string;
}

const SubjectNotification: React.FC<Props> = ({amount, className}) => {
    return (
    <div className={classNames('subject-notification min-w-42px absolute -top-3.5 -right-3 text-white flex items-center justify-center', className)}>
        <div className='px-2'>
            <div className='flex flex-row items-center justify-center'>
                <NotificationExerciseIcon />
                <div className='font-semibold text-13px text-right ml-0.5'>{amount}</div>
            </div>
        </div>
    </div>
    );
}

export default SubjectNotification;
