import React, {memo} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { currentFlashcardId } from 'selectors/studying';
import { getActiveSubject, getActiveMediaTab ,activeSubjectId, isMobile, getListOfFilterItemsIds, listOfFilterCheckedItems, topicsForCurrentActiveTeachingLevel, firstQuestionIdBySubject, apiRequestData} from 'selectors';
import { getUserId, isMBFromSchool, isOSCUser } from 'selectors/user';
import './emptyScreen.scss'
import classNames from 'classnames';
import Loader, {BackgroundEnum, SizeEnum} from 'v2/components/loader.v2/Loader.v2';
import {setExamsHeaderList} from 'actions/navigation/navigationActions'
import { setActiveTeachingLevel } from 'actions/subject';
import { setLastMediaTypeVisited, filterResultsListToArrayByTOJS ,setFormExams,storeExamsParentId,setExamSubTopicId, setExamTopicId} from 'v2/helpers';
import { changeFlashcard, selectFlashcard } from 'actions/studying';
import { useHistory } from 'react-router-dom';

type EmptyScreenButtonProps = {
    deskType: string;
}  

const EmptyScreenButton: React.FC<EmptyScreenButtonProps> = ({deskType}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const currentId = useSelector(currentFlashcardId)
    const userId = useSelector(getUserId)
    const activeMedia = useSelector(getActiveMediaTab)
    const activeSubjectsId = useSelector(activeSubjectId)
    const subjects = useSelector(getActiveSubject)
    const hasMobile = useSelector(isMobile)
    const listOfFilterIds = useSelector(getListOfFilterItemsIds)
    const listOfCheckedItems = useSelector(listOfFilterCheckedItems)
    const currrentTLvlForTopic = useSelector((state) => topicsForCurrentActiveTeachingLevel(state, activeSubjectsId, false))?.toJS();
    const firstQuesIdBySubject = useSelector(firstQuestionIdBySubject)?.toJS();
    const hasMBFromSchool = useSelector(isMBFromSchool);
    const examsData = useSelector(state => apiRequestData(state, 'exams'))?.toJS();
    const examPreviewData = useSelector(state => apiRequestData(state, 'examPreview'))?.toJS();    
    const hasOSCUser = useSelector(isOSCUser)

    const selectFirstCard = () => {
        const cardIds = listOfFilterIds?.toJS()[activeMedia]
        const FilterCheckedItems = listOfCheckedItems.toJS()[activeMedia]
        const filteredIds = filterResultsListToArrayByTOJS(cardIds, activeMedia, FilterCheckedItems )
        setActiveTeachingLevel(subjects.id);
        let examsId, topicsId, subTopicId;
        if(activeMedia === 'exams'){
            if (hasOSCUser || hasMBFromSchool) {
                setFormExams('topics')
                topicsId = currrentTLvlForTopic[0].topicId
                subTopicId = firstQuesIdBySubject[topicsId].data.subTopicId
                setExamTopicId(topicsId)
                setExamSubTopicId(subTopicId)
                examsId = firstQuesIdBySubject[topicsId].data.flashcardId
                storeExamsParentId(topicsId)
            }
            else {
                setFormExams('exams')
                topicsId = examsData?.data?.sort((a: any, b: any) => a?.examTitle > b?.examTitle ? 1 : -1)?.[0]?.papers.filter((item:any) => item?.paperTitle === 'Paper 1')?.[0]?.uId
                examsId = examPreviewData?.data?.data?.[0]?.uuid
            }
        }
        let firstCard = activeMedia === 'exams' ? examsId  : filteredIds[0]?.flashcardId;
        dispatch(changeFlashcard(firstCard))
        setLastMediaTypeVisited(activeMedia, userId);
        if(!hasMobile && deskType === 'notes'){
            history.push('/flashcard/desk/notes/edit');
        }
        dispatch(selectFlashcard(firstCard, '', deskType, true));
        if(activeMedia === 'exams') {
            dispatch(setExamsHeaderList(
                {"type":(hasOSCUser || hasMBFromSchool) ? 'topics' : 'exams',
                "parentId":topicsId
            }))
        }

    }


    // if (currentId) return null;
    const isClickable = activeMedia === 'exams' && firstQuesIdBySubject[currrentTLvlForTopic?.[0]?.topicId];
    return <>
        {activeMedia === 'exams' ? 
            <div className={classNames('empty-screen-btn', {'pointer-events-none' : !isClickable})} onClick={isClickable && selectFirstCard}> 
                { !isClickable ? <Loader background={BackgroundEnum.BlueX} size={SizeEnum.VSmall}/> :  <div className='emtpy-btn-text text-13px font-bold leading-18px mr-[11px] tracking-[2px] text-blue-normal uppercase'>YES, LET’S DO IT</div> }
            </div>
        : 
        <div className={classNames('empty-screen-btn')} onClick={selectFirstCard}> 
            <div className='emtpy-btn-text text-13px font-bold leading-18px mr-[11px] tracking-[2px] text-blue-normal uppercase'>YES, LET’S DO IT</div>
        </div> }
    </>
}

export default memo(EmptyScreenButton)