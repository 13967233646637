import {setActiveNodesAction, toggleTopicVisibility, setActiveTopicId, setActiveSubTopicId} from 'actions/topics-tree/topicsTreeActions';
import StudyMetaInformation from 'components/studying/studay-meta-info/StudyMetaInformation';
import {CheckboxWrapper} from 'components/topics-tree/TopicsTreeContainer';
import TopicTile from 'components/topics-tree/TopicTile';
import {Map,List} from 'immutable';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getExerciseByTopicID} from 'selectors';
import {getTreeSectionValue, isTreeSectionExpanded} from 'selectors/topicsTreeSelector';
import Checkbox from 'ui-components/Checkbox';
import Row from 'ui-components/helpers/Row';
import appHistory from 'appHistory';
import { filterResultsListToArrayByTOJS , getLastMediaTypeVisited , getFormExams} from 'helpers';
import * as Selectors from 'selectors';
import * as Actions from 'actions';

class TopicTileContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeMediaType:this.props.activeMediaTab
    }
  }
  
  render() {
    const {isExpanded, topic, parentIds, hasSelection, showAnswers, children, isActive, activeTopicId, activeSubtopicId, isExercise, isExernalExercise, userId} = this.props;
    const topicId = topic.get('id');
    const mediaType = getLastMediaTypeVisited(userId)
    const examsMode = mediaType === 'exams' && getFormExams() === 'exams';
    return (
      <Row>
        <TopicTile
          topicData={topic}
          active={!examsMode && isActive}
          activeTopicId={activeTopicId}
          activeSubtopicId={activeSubtopicId}
          expanded={isExpanded}
          hasSelection={hasSelection}
          onExpand={() => this.onClick(topic, 'EXPAND')}
          onSelect={() => this.onClick(topic, 'SELECT' , isExercise)}
          parentIds={parentIds}
          isExercise={isExercise}
          isExernalExercise={isExernalExercise}
          toggleTopicVisibility={this.props.toggleTopicVisibility}
          checkMircoFilterFlashcard = {this.mircoFilter(isExercise )}
          absolute={hasSelection && (
            <CheckboxWrapper key={topicId}>
              <Checkbox id={topicId} parentIds={parentIds}/>
            </CheckboxWrapper>
          )}
          answers={showAnswers && <StudyMetaInformation topicData={topic} id={topicId} hasSelection={hasSelection} onClick={this.setActiveMediaSlider} onCardClick={()=>this.onClick(topic, 'SELECT' , isExercise)}/>}
        >
          {children}
        </TopicTile>
      </Row>
    );
  }
  setActiveMediaSlider=(val)=>{
    if(val){
      this.setState({activeMediaType:val})
      }
  }
  mircoFilter(isExercise){
    if(isExercise){
      const filterList = this.props.listOfFilterCheckedItems.toJS()["flashcard"]
      let filteFlashcardData = [] ; 
      let flashcardIds = this.props.subtopicToOpen.forEach((item) =>{
       return item.get('flashcardsAnswers').forEach((val) =>{
        filteFlashcardData.push(val.toJS())
       })
      })

      let filteFlashcardIds = filterResultsListToArrayByTOJS(filteFlashcardData, "flashcard", filterList).map((item) => {
        return item.flashcardId
      })
      return filteFlashcardIds.length == 0 ? true :false
    }else{
      return false
    }
  }

  onClick = (item, clickAction, isExercise) => {
    setTimeout(() => {
    const subtopicToOpen = this.props.subtopicToOpen;
    const filterList = this.props.listOfFilterCheckedItems.toJS()["flashcard"]

    if(clickAction == "SELECT" && isExercise){
      if(subtopicToOpen){
        let filteFlashcardData = [] ; 
        let flashcardIds = this.props.subtopicToOpen.forEach((item) =>{
          return item.get('flashcardsAnswers').forEach((val) =>{
            filteFlashcardData.push(val.toJS())
          })
        })
        let filteFlashcardIds = filterResultsListToArrayByTOJS(filteFlashcardData, "flashcard", filterList).map((item) => {
          return item.flashcardId
        })
        this.props.onPreviewClick(filteFlashcardIds[0]);
      }
    }else{
    const {parentIds,setActiveMediaTab} = this.props;
      const topicId = item.get('id');

        if (clickAction === 'SELECT') {
          
          if(subtopicToOpen){
          // const firstFlashcard = subtopicToOpen.get('flashcards').first();    
          //this.props.setActiveNodes(firstFlashcard, parentIds.concat(subtopicToOpen.get('topicId'), subtopicToOpen.get('id')));
        }
        // if (!this.props.isExpanded) {
          // this.props.toggleTopicVisibility(topicId, parentIds);
        // }

        //this.props.onPreviewClick(firstFlashcard);
        this.props.setActiveTopicId(topicId)
        this.props.setActiveSubTopicId(null);
        appHistory.push(`/flashcard/topic/${topicId}`);
        setActiveMediaTab(this.state.activeMediaType)
      } else {
        this.props.toggleTopicVisibility(topicId, parentIds);
      }
      this.props.onClick(topicId, parentIds);
      };
    }, 100);
  }
}

TopicTileContainer.propTypes = {
  hasSelection: PropTypes.bool,
  showAnswers: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  onPreviewClick: PropTypes.func.isRequired,
  topic: PropTypes.instanceOf(Map).isRequired,
  parentIds: PropTypes.array.isRequired,
  isActive: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setActiveNodes: PropTypes.func.isRequired,
  toggleTopicVisibility: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const topicId = props.topic.get('id');

  return ({
    isActive: getTreeSectionValue(state, topicId, props.parentIds, 'active'),
    isExpanded: isTreeSectionExpanded(state, topicId, props.parentIds),
    subtopicToOpen: getExerciseByTopicID(state, topicId),
    listOfFilterCheckedItems: Selectors.listOfFilterCheckedItems(state),
    activeMediaTab: Selectors.getActiveMediaTab(state),
    userId : Selectors.getUserId(state)
  });
};
const mapDispatchToProps = {
  toggleTopicVisibility: toggleTopicVisibility,
  setActiveNodes: setActiveNodesAction,
  setActiveTopicId: setActiveTopicId,
  setActiveSubTopicId: setActiveSubTopicId,
  setActiveMediaTab: Actions.Navigation.setActiveMediaTab,
};
export default connect(mapStateToProps, mapDispatchToProps)(TopicTileContainer);
