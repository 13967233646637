import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { deleteExercisesRoutine, getInternalExercises } from 'actions/api/apiActions';
import { selectAllExercisesAction, updateListOfItemsSelected } from 'actions/desk/deskActions';
import { activeSubjectId, scrollPosition, getActiveMediaTab, isDeskEditModeActive, listOfCheckedItems, subjectsTeachingLevel } from 'selectors';
import { setActiveFlashcardTab } from 'actions/navigation'
import DeskNavigationHeader from 'v2/components/navigation-header/DeskNavigationHeader';
import useScrollDetection from "hooks/useScrollDetection";
import { internalExercisesResponse } from 'selectors/api/apiSelector';
import Text from 'v2/components/common/Text';
import FreeTrialNotification from 'v2/components/free-trial-notification/FreeTrialNotification';
import ExerciseDeskHeaderContainer from 'v2/components/exercise/ExerciseDeskHeaderContainer';
import RequestLoader from 'v2/components/loader.v2/RequestLoader';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import { CSSTransition, TransitionGroup, } from 'react-transition-group';
import FlashcardEmptyScreen from 'v2/components/empty-screen-message/FlashcardEmptyScreen';
import CheckRadioButton from 'v2/components/check-radio-button/CheckRadioButton';
import ExerciseTile from 'v2/components/exercise/ExerciseTile';
import ClassroomEmptyMessage from 'v2/components/empty-screen-message/ClassroomEmptyMessage';
import { ReactComponent as AddExerciseIcon } from 'assets/icons/exercise/add-exercise.svg';
import FooterEditModeContainer from 'v2/components/footer-edit-mode/FooterEditModeContainer';
import { setGoBackToSearch } from 'actions/search';
import './exercise.scss'

const ExercisesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const subjectId = useSelector(activeSubjectId);
  let wrapperRef = useRef(null);
  const getScrollPosition = useSelector(scrollPosition)?.toJS();
  const activeMediaTab = useSelector(getActiveMediaTab);
  const internalExercises = useSelector(internalExercisesResponse);
  const hasDeskEditModeActive = useSelector(isDeskEditModeActive);
  const hasListOfCheckedItems = useSelector(listOfCheckedItems);
  // const deleteExercises = useSelector(deleteExercisesRoutine);
  const { isScrolled, bindScrollRef } = useScrollDetection({ WrappedRef: wrapperRef, threshold1: 20 });
  const exercises = internalExercises?.[subjectId]?.data;
  const hasSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
  const tlvl = hasSubjectsTeachingLevel[subjectId];

  const canEdit = activeMediaTab === "flashcard" && exercises?.length > 0;
  useEffect(() => {
    dispatch(setActiveFlashcardTab(4))
    dispatch(getInternalExercises({key: subjectId}));
    // dispatch(setActiveMediaTab('flashcard'));
    if (wrapperRef) {
      // @ts-ignore
      wrapperRef.scrollTop = getScrollPosition.exercises
    }

  }, [dispatch, getScrollPosition.exercises, subjectId, tlvl])
  const bindRef = (ref: any) => {
    wrapperRef = ref;
    bindScrollRef(ref);
  };
  const onExerciseClick = (exerciseId: number) => {
    dispatch(setGoBackToSearch(false));
    if (hasDeskEditModeActive) {
      dispatch(updateListOfItemsSelected(exerciseId));
    } else {
      history.push(`/flashcard/desk/exercise/${exerciseId}`);
    }
  };
  return <div className='h-full'>
    <DeskNavigationHeader
      isCollapsed={isScrolled}
      tabTitle={'Exercises'}
      canEdit={canEdit}
      showButton={true}
      rightButton={activeMediaTab === "flashcard" && exercises?.length === 0 ?

        <Text className={classNames('text-13px font-bold leading-18px text-center tracking-2px uppercase  text-#C8CCCC pointer-events-auto', { 'pointer-events-none': exercises.length === 0 })} >EDIT</Text>
        : null}
    />
    <div className={classNames('fixed-page-spacing', { 'flex flex-col': activeMediaTab !== "flashcard" })} ref={bindRef}>
      <FreeTrialNotification />
      <ExerciseDeskHeaderContainer />
      <div className='pb-3.5' />
      {activeMediaTab === "flashcard" ?
        <RequestLoader allowUpdate loader={<LeftMenuLoader />}
          request={internalExercises[subjectId]}>
          <React.Fragment>
            {(exercises?.length === 0 || exercises?.length > 0) && (
              <CSSTransition
                key={'empty-list'}
                classNames='fade'
                timeout={500}
              >
                <FlashcardEmptyScreen type='exercises' FlashcardtypeSize={exercises?.length} size={exercises?.length} edit={hasDeskEditModeActive} className={'empty-execerises'}>
                  <AddExerciseIcon />
                </FlashcardEmptyScreen>
              </CSSTransition>
            )}
            <div className='list-wrapper'>
              <TransitionGroup>
                {exercises?.map((exercise: any) => {
                  const exerciseId = exercise.exerciseId;
                  return (
                    <CSSTransition
                      key={exerciseId}
                      timeout={500}
                      classNames="slide"
                    >
                      <div className='flex items-center'  >
                        {hasDeskEditModeActive && (
                          <span onClick={() => {
                            onExerciseClick(exerciseId)
                          }}>
                            <CheckRadioButton itemId={exerciseId} listOfCheckedItems={hasListOfCheckedItems} />
                          </span>
                        )}

                        <ExerciseTile onClick={() => onExerciseClick(exerciseId)}
                          noPadding={true} exercise={exercise} className='grow w-1/2' />
                      </div>
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            </div>
          </React.Fragment>

        </RequestLoader> :
        <ClassroomEmptyMessage type='exercises' />}
    </div>

    <FooterEditModeContainer onDelete={deleteExercisesRoutine}
      itemsCount={exercises?.length}
      onSelectAll={() => dispatch(selectAllExercisesAction(exercises))}
    />
  </div>
}


export default ExercisesPage