import './RegisterPage.scss';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Field} from 'redux-form/immutable';
import {validator} from 'utils/form-helper';
import BackButton from 'components/BackButton/BackButton';
import PrimaryButton from 'components/primary-button';
import FormSelect from 'components/form-select';
import FormInput from 'components/FormInputComponent';
import FormCheckbox from 'components/FormCheckboxComponent';
import FormInputDatepicker from 'components/form-input-datepicker';

const passwordsMatch = (field, fields) => {
  return fields.get('password') === fields.get('confirmPassword') ? undefined : 'The two passwords do not match!';
};

class RegisterPageComponent extends Component {
  renderSubmissionError(requestError) {
    if (!requestError || requestError.email || requestError.usename) return null;
    return <div className="form-error-message">Sorry! An error has occurred! Please report the error <a
      href="mailto:osc@oxfordstudycourses.com?Subject=Error" target="_top"><strong>here</strong>!</a></div>;
  }

  render() {
    const {requestError, valid, handleSubmit, onSubmit, change, touch, toggleChooseAvatar, isAvatarComponentExpanded} = this.props;
    return (
      <div className="register-page">
        <div className='back-button-wrapper'>
          <BackButton location='/login'/>
        </div>

        <div className='register-title'>
          <span>Create an account</span>
        </div>

        <div className='register-form'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput change={change} touch={touch} label='First name' name='firstName'
              placeholder='Enter your first name' type='text' validate={[validator.required]}/>

            <FormInput change={change} touch={touch} label='Last name' name='lastName'
              placeholder='Enter your last name' type='text' validate={[validator.required]}/>

            <FormInput change={change} touch={touch} label='Email address' name='email'
              placeholder='Enter your email address' type='email'
              validate={[validator.required, validator.email]}/>

            <FormInput change={change} touch={touch} label='Username' name='username' placeholder='Enter a username'
              type='text' validate={[validator.required]}/>

            <FormInput change={change} touch={touch} label='Password' name='password'
              placeholder='Enter a secure password' type='password'
              validate={[validator.required, validator.passwordLength]}/>

            <FormInput change={change} touch={touch}
              label='Confirm Password' name='confirmPassword' placeholder='Confirm secure password'
              type='password' validate={[validator.required, passwordsMatch]}/>

            <FormInputDatepicker id={'register-datepicker'} change={change} touch={touch}
              label='Date of birth (optional)' name='dateOfBirth'
              placeholder='Select your date of birth'/>

            <FormSelect label='User type (optional)' name='selfRole' placeholder='Who are you?' type='text'
              change={change}
              touch={touch} value="Who are you?">
              <option value='' selected="selected">Who are you?</option>
              <option value='ROLE_STUDENT'>Student</option>
              <option value='ROLE_TEACHER'>Teacher</option>
              <option value='ROLE_OTHER'>Other</option>
            </FormSelect>

            <FormInput change={change} touch={touch} label='School (optional)' name='school'
              placeholder='Where do you go to school?' type='text'/>
            <FormCheckbox required
              label={(
                <span>I have read and accept the <a
                  href='https://www.smart-prep.com/pages/terms-of-service'
                  target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a
                  href="https://www.smart-prep.com/pages/privacy-policy"
                  target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</span>
              )}
              name='iAgree'/>

            <FormCheckbox
              label={(
                <span>
                  I would like to sign up to receive email updates from <span className="font-weight-light">SMART</span><span
                  className="font-weight-bold">PREP</span> (Hsquared Education GmbH). See
                  <a target="_blank" rel="noopener noreferrer" href="https://www.smart-prep.com/pages/privacy-policy"> Privacy Policy</a>.
                </span>
              )}
              name='allowMarketing'/>

            {this.renderSubmissionError(requestError)}

            <PrimaryButton
              content={'SIGN UP FOR FREE'} disabled={!valid} className={(valid ? '' : 'disabled-btn ') + 'sign-up-btn'}
              hasArrow={true}
              onSubmit={handleSubmit(onSubmit)}/>
          </form>
        </div>
      </div>
    );
  }
}

RegisterPageComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  touch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  isAvatarComponentExpanded: PropTypes.bool,
  toggleChooseAvatar: PropTypes.func,
  requestError: PropTypes.object
};


export default RegisterPageComponent;
