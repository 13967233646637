import * as Types from './types';
import makeAction from 'actions/makeAction';

export const addItemInPurchaseBasket = makeAction(Types.ADD_ITEM_IN_PURCHASE_BASKET, 'data');
export const removeItemFromPurchaseBasket = makeAction(Types.REMOVE_ITEM_FROM_PURCHASE_BASKET, 'data');
export const resetPurchaseBasket = makeAction(Types.RESET_PURCHASE_BASKET);
export const toggleBasket = makeAction(Types.TOGGLE_BASKET, 'data');
export const changeAmount = makeAction(Types.CHANGE_AMOUNT, 'data');
export const togglePayment = makeAction(Types.TOGGLE_PAYMENT);
export const setActiveStoreSubject = makeAction(Types.SET_ACTIVE_STORE_SUBJECT, 'data');
export const setPackagesDescriptionProps = makeAction(Types.SET_PACKAGES_DESCRIPTION_PROPS, 'data');
export const togglePackageDescription = makeAction(Types.TOGGLE_PACKAGE_DESCRIPTION, 'data');
