import React, { useMemo } from "react"
import TopicsTreeContainer from 'v2/components/topics-tree/TopicsTreeContainer';
import { activeSubjectId, getActiveSubject, getSubjectv2 } from 'selectors';
import { useSelector } from 'react-redux'
import { getDaysLeft, getIsInTrial, getIsLessThanOneDay } from "selectors/onboardingSelectors";
import classNames from "classnames";
import { ForwardArrowRight } from "icons/HeaderIcons";

type TopicsContainerProps = {
  parentScrollRef: any
}
const subjectsToShowRibbon = []

const TopicsContainer: React.FC<TopicsContainerProps> = ({ parentScrollRef }) => {
  const hasActiveSubjectId = useSelector(activeSubjectId);
  const subjects = useSelector(getSubjectv2);
  const subject = useSelector(getActiveSubject)
  const hasNoVoucher = subjects && subjects.length === 1;
  const daysLeft = useSelector(getDaysLeft);
  const isLessThanOneDay = useSelector(getIsLessThanOneDay);
  const isInTrial = useSelector(getIsInTrial);
  const hasEndedLessThanOneDay = daysLeft <= 0 && isLessThanOneDay === false;
  const isDemoOverlayVisible = useMemo(() => !((!hasNoVoucher && !hasEndedLessThanOneDay) || (isInTrial && !hasEndedLessThanOneDay)),[hasEndedLessThanOneDay, hasNoVoucher, isInTrial])
 
  const onTreeScrollTo = (element: any) => {
    const scrollRef = parentScrollRef();
    const elementOffsetTop = scrollRef?.scrollTop + element.getBoundingClientRect().top;
    scrollRef.scrollTop = elementOffsetTop - scrollRef.offsetHeight / 2;

  }
  return <>
    <div className={classNames("mt-4  mx-0 h-[1px] bg-#E1E6E6 rounded-[1px] mb-[30px]")} />
    {/* {!isDemoOverlayVisible && subjectsToShowRibbon.includes(subject?.id) && <div className="flex items-center justify-center bg-[#EFF4F8] mb-[30px]">
      <div className="px-[18px] pt-3 pb-[15px] text-[#2D3838] text-[15px] font-normal leading-[19px]">
        <p>
          Coming this January: revised and new content aligned to the 2023 {subject?.title} subject guide. Meanwhile, navigate by topic names and get plenty of practice.
        </p>
        <a href="https://www.managebac.com/ib-diploma/system?title=11-dp-subjects&modal-tab=commingsoon" target="_blank" rel="noreferrer">
          <p className="text-blue-normal font-bold cursor-pointer inline">
            Explore Our Content Roadmap <ForwardArrowRight />
          </p>
        </a>
      </div>
    </div>} */}
    <div className="px-3.5">
      <TopicsTreeContainer
        key={hasActiveSubjectId}
        showAnswers={true}
        subjectId={hasActiveSubjectId}
        scrollTo={onTreeScrollTo}
      />
    </div>
  </>
}


export default TopicsContainer