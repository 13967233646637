import React, { useEffect, useState, useRef, useMemo, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getActiveSubject, subjectsTeachingLevel, isTeachingLevelOverlayVisible, activeSubjectId, getActiveMediaTab, currentFlashcardId, getFlashcardById, flashcardIdsResponse, lastMenuPath, subjectSyllabusList, getActiveExamModule, getSubjectv2, isTeachingLevelOverlayVisibleFromFeature } from 'selectors'
import { toggleTeachingLevelOverlay, structureLoading, setActiveTeachingForExam, saveLastMenuPath, setActiveMediaTab, togglePaperOverlay, setActiveExamsTab } from 'actions/navigation';
import { getAllTopicsInSubject, setLastTeachingTag, getAllTopicsIds, setQuickStatus, getFirstQuestionIdBySubject } from 'actions/api'
import { getFlashcardIdsRoutine, getCardsTitleByType, updateExamPreviewsListByType, closeFeature } from 'actions/api/apiActions';
import { setActiveSubject, setActiveTeachingLevel, setTeachingLevelForSubject, setSyllabusSubject } from 'actions/subject';
import { setCurrentFlashcard } from "actions/studying"
import { updateIdsOfFilterItems, setActiveMessageLoader } from 'actions/desk'
import { ReactComponent as CloseIcon } from 'assets/icons/page-overlay/close-icon-turquoise.svg';
import TlvlButton from "./TlvlButton";
import classNames from "classnames";
import { setSubjectsTeachingLevel, getLastMediaTypeVisited, setLastMediaTypeVisited } from "helpers"
import { getUserId } from 'selectors/user';
import usePrevious from "hooks/usePrevious";
import { useHistory } from "react-router-dom";
import { List } from 'immutable';
import { topicsForCurrentActiveTeachingLevel } from 'selectors';
import usePopupRest from "hooks/usePopupRest";
import './TeachingLevelPopup.scss'
import { getExams } from 'actions/api';
import YearButton from "./YearButton";
import { activeSyllabusBySubject, getLastSubjectActive, optimizedSubjectTitle, setLastSubjectVisited, setSubjectsSyllabus } from "v2/helpers";
import { setFlashcardIdDetails, setRevisionIdDetails, setVideoIdDetails } from "actions/carousel/carouselActions";
import useExerciseNotificationCountBySubject from "hooks/useExerciseNotificationCountBySubject";
import useExamModuleStatus from "hooks/useExamModuleStatus";

type TeachingLevelPopupOverlayProps = {
  subjectId: number,
  flashcardId: number | string
}

const NEW_SUBJECT = "NEW_SUBJECT"

const SubjctSLContent = (subjectSlug: string, teachingTag: string) => {
  switch (subjectSlug) {
    case "es-amp-s":
      return teachingTag === "SL" ? "This subject was designed for SL students. The IB does not currently offer an HL option." : "";
    case "pre-ib-mathematics":
      return <div>This subject was designed for pre-IB Diploma Programme students. The IB does not offer different teaching levels.</div>;
    case "mathematics":
      return <div>Choosing this setting displays all the topics the {teachingTag} student needs to study.</div>;
    case "maths-a-amp-a":
      return <div>Choosing this setting displays all the topics the {teachingTag} student needs to study. </div>;
    case "maths-a-amp-i":
      return <div>Choosing this setting displays all the topics the {teachingTag} student needs to study. </div>;
    default:
      return <div>Choosing this setting displays all the topics the {teachingTag} student needs to study. The common core topics (covered in both syllabi) will be shown with an SL and HL tag.</div>;
  }
}

const newSubjctSLContent = (isActive:string):string => {
  return `Your teaching level setting displays all the topics the ${isActive === 'SL' ? 'SL' : 'HL'} student needs to study. The common core topics (covered in both syllabi) will be shown with an SL and HL tag.`
}

const TeachingLevelPopupOverlay: React.FC<TeachingLevelPopupOverlayProps> = ({ subjectId, flashcardId }) => {
  const [isActive, setActive] = useState('');
  const [isAnimation, setAnimation] = useState('');
  const [syllabus, setSyllabus] = useState<any>(null);
  const subjects = useSelector(getSubjectv2)
  let history = useHistory();
  const dispatch = useDispatch();
  const tvlRef = useRef<HTMLDivElement>(null)
  const activeSubject = useSelector(getActiveSubject);
  const hasSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
  const hasTeachingLevelOverlayVisible = useSelector(isTeachingLevelOverlayVisible);
  const hasActiveSubjectId = useSelector(activeSubjectId)
  const activeMediaTab = useSelector(getActiveMediaTab);
  const hasCurrentFlashcardId = useSelector(currentFlashcardId);
  const userId = useSelector(getUserId)
  const flashcardContent = useSelector((state) => getFlashcardById(state, hasCurrentFlashcardId))
  const topicsCurrentActiveTeachingLevel: any = useSelector((state) => hasTeachingLevelOverlayVisible &&  topicsForCurrentActiveTeachingLevel(state, subjectId));
  const hasLastMenuPath = useSelector(lastMenuPath)
  const examModuleStatus = useExamModuleStatus();
  const {resetPopup} = usePopupRest()
  const activeSubjectSlug = activeSubject?.slug;
  const activeTvl = hasSubjectsTeachingLevel[`${hasActiveSubjectId}`];
  const hasFlashcardIdsResponse = useSelector((state) => flashcardIdsResponse(state))
  const getSubjectSyllabusList = useSelector(subjectSyllabusList)
  const prevProps: any = usePrevious({ 'activeSubject': activeSubject, 'subjectsTeachingLevel': hasSubjectsTeachingLevel, 'flashcardIdsResponse': hasFlashcardIdsResponse })
  const isNewSubject = !["french", "english-b"].includes(activeSubject?.slug) && (activeSubject?.isNew || activeSubject?.tagging === 'NEW_SUBJECT')
  const isSaveBtnEnable = useMemo(() => (isNewSubject ? isActive !== activeTvl || activeSubject?.id !== syllabus?.id : isActive !== activeTvl),[activeTvl, isActive, activeSubject?.id, syllabus?.id, isNewSubject])
  const hasActiveExamModule = useSelector(getActiveExamModule);
  const hasTeachingLevelOverlayVisibleFromFeature = useSelector(isTeachingLevelOverlayVisibleFromFeature)
  const { oldSubjectExerciseNotificationsCount, newSubjectExerciseNotificationsCount } = useExerciseNotificationCountBySubject(activeSubject?.id);
  
  const subjectTitle = useMemo(() => optimizedSubjectTitle(activeSubject?.title),[activeSubject?.title])

  useEffect(() => {
    const activeSubjectIds = activeSubject?.id;
    setActive(hasSubjectsTeachingLevel[`${activeSubjectIds}`]);
    setAnimation(hasSubjectsTeachingLevel[`${activeSubjectIds}`])
  }, [])

  useEffect(() => {
    const activeSubjectIds = activeSubject?.id;
    if (activeSubject !== prevProps?.activeSubject) {
      setActive(hasSubjectsTeachingLevel[`${activeSubjectIds}`]);
      setAnimation(hasSubjectsTeachingLevel[`${activeSubjectIds}`])
    }

    if (prevProps?.subjectsTeachingLevel) {
      if (hasSubjectsTeachingLevel !== prevProps?.subjectsTeachingLevel) {
        dispatch(getAllTopicsInSubject({
          key: activeSubject?.id,
          subjectId: activeSubject?.id,
          mapAnswers: true
        }));
        dispatch(getFlashcardIdsRoutine({ subjectId: activeSubject?.id }));
        if (activeMediaTab !== 'flashcard') {
          dispatch(getCardsTitleByType({ subjectId: activeSubject?.id, mediaType: activeMediaTab }))
        }
        let flashcardTeachingTag = hasCurrentFlashcardId && flashcardContent.get("teachingTag");

        if (hasCurrentFlashcardId && flashcardTeachingTag?.includes(hasSubjectsTeachingLevel[`${hasActiveSubjectId}`]) && activeSubject?.tagging !== 'NEW_SUBJECT') {
          dispatch(structureLoading(true));
        } else {
          history.push('', true);
          dispatch(setCurrentFlashcard())
        }
      }
    }
    if (prevProps?.flashcardIdsResponse.get("loading") !== hasFlashcardIdsResponse.get("loading") && hasFlashcardIdsResponse.get("loading") === false) {
      dispatch(structureLoading(false));
    }
  }, [activeSubject, hasFlashcardIdsResponse, hasSubjectsTeachingLevel, activeSubject?.tagging])

  const closeFeatureOverlay = useCallback(() => {
    if(!!hasTeachingLevelOverlayVisibleFromFeature && activeSubject?.isNew) {
      dispatch(closeFeature(hasTeachingLevelOverlayVisibleFromFeature))
    }
  },[activeSubject?.isNew, dispatch, hasTeachingLevelOverlayVisibleFromFeature])

  const onClose = (e: any) => {
    dispatch(toggleTeachingLevelOverlay(false, false))
    closeFeatureOverlay()
    e.stopPropagation();
  };
  const stopInnerClick = (e: any) => {
    e.stopPropagation();
  }

  const changeTeachingLevel = (val: string) => {
    let tvlHeading = tvlRef.current;
    setActive(val)
    setTimeout(() => {
      setAnimation(val)
    }, 300)
    if (isActive !== val) {
      tvlHeading?.classList.add('tvl-heading-hidden')
      setTimeout(() => {
        tvlHeading?.classList.remove('tvl-heading-hidden');
      }, 300)
    }
  }

  useEffect(() => {
    // const oldSubject = getSubjectSyllabusList?.find((item:any) => [null,''].includes(item?.tagging))
    const oldSubject = getSubjectSyllabusList?.find((subjectSyllabus:any) => subjectSyllabus?.id === activeSubject?.id)
    setSyllabus(oldSubject)
  },[activeSubject?.id, getSubjectSyllabusList])

  const saveNewTlvl = useCallback(() => {
    // const activeSubjectIds = activeSubject?.id;
    dispatch(setTeachingLevelForSubject({
      ...hasSubjectsTeachingLevel,
      [`${hasActiveSubjectId}`]: isActive
    }))
    setSubjectsTeachingLevel({
      ...hasSubjectsTeachingLevel,
      [`${hasActiveSubjectId}`]: isActive
    })
    dispatch(setLastTeachingTag({ "subject_name": hasActiveSubjectId, "tl": isActive }))
    const mediaType = getLastMediaTypeVisited(userId);
    if (mediaType === 'exams') {
      dispatch(setActiveTeachingForExam())
      setLastMediaTypeVisited("", userId)
      dispatch(updateIdsOfFilterItems(List([]), 'exams'));
    }
    const topicsIds = topicsCurrentActiveTeachingLevel.map((item: any) => {
      return { apTopicsId: item.get('apTopicId'), topicId: item.get('topicId') };
    });
    if (hasLastMenuPath.topics !== '/flashcard/topics') {
      dispatch(saveLastMenuPath({
        ...hasLastMenuPath,
        ['topics']: '/flashcard/topics'
      }))
    }
    if (hasLastMenuPath.exams !== '/flashcard/desk/exams') {
      dispatch(saveLastMenuPath({
        ...hasLastMenuPath,
        ['exams']: '/flashcard/desk/exams'
      }))
    }
    dispatch(getAllTopicsIds(topicsIds));
    dispatch(toggleTeachingLevelOverlay(false, false))
    dispatch(setQuickStatus(hasActiveSubjectId));
    resetPopup() // custom hooks for reset all popup reset
    topicsIds.size && dispatch(getFirstQuestionIdBySubject(topicsIds.first().topicId))
    dispatch(getExams());
  },[dispatch, hasActiveSubjectId, hasLastMenuPath, hasSubjectsTeachingLevel, isActive, resetPopup, topicsCurrentActiveTeachingLevel, userId])

  const saveNewSyllabus = useCallback(() => {
    dispatch(setSyllabusSubject(syllabus?.year))

    dispatch(setTeachingLevelForSubject({
      ...hasSubjectsTeachingLevel,
      [`${syllabus?.id}`]: isActive
    }))
    setSubjectsTeachingLevel({
      ...hasSubjectsTeachingLevel,
      [`${syllabus?.id}`]: isActive
    })
    const newActiveSyllabusBySubject = {
      ...activeSyllabusBySubject,
      ...(!!getLastSubjectActive(userId) && {[getLastSubjectActive(userId)] : syllabus?.id})
    }
    setSubjectsSyllabus(userId,newActiveSyllabusBySubject)
    dispatch(setLastTeachingTag({ subject_name: syllabus?.id, tl: isActive }))

    const { location } = history;
    if (location.pathname.indexOf("topic") !== -1 || location.pathname.indexOf("subtopic") !== -1) {
      history.push('/flashcard/topics');
    } else if (!examModuleStatus.status && location.pathname.indexOf('exams') !== -1) {
      dispatch(setActiveMediaTab('flashcard'));
      history.push('/flashcard/topics');
    } else if (location.pathname.indexOf('exams') !== -1) {
      history.push('/flashcard/desk/exams');
    }
    // dispatch(toggleSubjectsManagerOverlay(false, false));
    dispatch(toggleTeachingLevelOverlay(false, false));
    dispatch(togglePaperOverlay(false, false));
    resetPopup();
    dispatch(setActiveMessageLoader(true))


    setLastMediaTypeVisited('', userId);
    dispatch(setActiveExamsTab("ib_dp"));
    const newSubject = subjects.find((subject:any) => subject?.id === syllabus?.id)
    dispatch(setActiveSubject(newSubject, true));
    dispatch(updateIdsOfFilterItems(List([]), 'exams'));
    dispatch(setActiveTeachingLevel(newSubject.id, true));
    setLastSubjectVisited(newSubject.id, userId);
    dispatch(updateExamPreviewsListByType())
    dispatch(setFlashcardIdDetails([]));
    dispatch(setRevisionIdDetails([]));
    dispatch(setVideoIdDetails([]));
  },[dispatch, hasActiveExamModule, hasSubjectsTeachingLevel, history, isActive, resetPopup, subjects, syllabus?.id, userId, syllabus?.year])

  const saveTlvl = useCallback((val: any) => {
    if(activeSubject?.id !== syllabus?.id && !!syllabus) {
      saveNewSyllabus()
    }
    if(isActive !== activeTvl && (activeSubject?.id === syllabus?.id || !syllabus)) {
      saveNewTlvl()
    }
    closeFeatureOverlay()
  },[activeSubject?.id, syllabus, isActive, activeTvl, closeFeatureOverlay, saveNewSyllabus, saveNewTlvl])

  const saveActive = () => {
    if (isSaveBtnEnable) {
      return 'bg-blue-normal cursor-pointer pointer-events-auto'
    } else {
      return 'bg-#EFF4F9 cursor-default pointer-events-none'
    }
  }

  const syllabusCaption = useMemo(() => {
    if(['2020', '2023'].includes(syllabus?.year)) return <>You selected the <strong>all-new course</strong> with first examinations in  2025. (Your exams are in May 2025 or later)</>
    return <>You selected the <strong>soon to expire course</strong> with first examinations in 2016. (Your exams are between now and Nov 2024)</>
  },[syllabus?.year])

  if (!hasTeachingLevelOverlayVisible) return null;
  return <div className="teaching-level-bg" onClick={(e) => onClose(e)}>
    <div className="controls-wrapper">
      <div className={classNames("teaching-popup-section relative",{
        'h-[474px]':isNewSubject,
        'h-[337px]':!isNewSubject
      })} onClick={(e) => stopInnerClick(e)}>

        <div className="font-bold leading-26px text-#2D3838 pt-18px pb-2  px-0 text-19px">
          {isNewSubject ? `Configure ${subjectTitle}` : 'Select your syllabus'}
        </div>
        <div className='close-btn-wrapper' onClick={(e) => onClose(e)}><div className="close-button" ><CloseIcon /></div></div>
        
        {isNewSubject && 
        <>
          <div className='cookie-active-bar absolute top-[148px] h-[57.775px] left-0 bg-#FA9000 bottom-0 w-1 rounded-tl-[20px] rounded-bl-[20px]'></div>
          <div className="pt-[7px] pb-1.5 text-[#2D3838] text-[15px] font-bold leading-[19px]">Select your syllabus</div>

          <div className="flex w-full h-12 mt-1">
            {getSubjectSyllabusList?.map((subjectYear:any) => <YearButton
              key={subjectYear?.id}
              onClick={() => setSyllabus((prev:any) => (subjectYear))}
              year={subjectYear?.year}
              isActive={syllabus?.id === subjectYear?.id}
              newTag={activeSubject?.slug !=='french' &&  subjectYear?.tagging === 'NEW_SUBJECT'}
              exerciseNotificationCount={subjectYear.tagging === NEW_SUBJECT ? newSubjectExerciseNotificationsCount : oldSubjectExerciseNotificationsCount}
            />)}
          </div>

          <div className="pt-[7px] pb-1.5 text-[#2D3838] text-[15px] leading-[19px] mb-4">{syllabusCaption}</div>

          <div className='cookie-active-bar absolute top-[318px] h-[76px] left-0 bg-#FA9000 bottom-0 w-1 rounded-tl-[20px] rounded-bl-[20px]'></div>
          <div className="pt-[7px] pb-1.5 text-[#2D3838] text-[15px] font-bold leading-[19px]">Select your teaching level</div>
        </>}

        <div className={classNames("teaching-seperator-btn flex w-full h-12", 
        {
          'mt-1': isNewSubject,
          'mt-4':!isNewSubject
        })}>
          {activeSubjectSlug === 'mathematics' && <TlvlButton onClick={() => changeTeachingLevel('Studies SL')} name={'Studies SL'} color={'#458CFC'} isActive={isActive === 'Studies SL'} slug={activeSubjectSlug} />}
          {activeSubjectSlug === "pre-ib-mathematics" ? <TlvlButton name={'Pre-IB'} color={'#458CFC'} isActive={isActive === 'SL'} slug={activeSubjectSlug} /> :
            <TlvlButton onClick={() => changeTeachingLevel('SL')} name={'SL'} color={'#458CFC'} isActive={isActive === 'SL'} slug={activeSubjectSlug} />}
          {(activeSubjectSlug !== 'es-amp-s' && activeSubjectSlug !== "pre-ib-mathematics") && <TlvlButton onClick={() => activeSubjectSlug !== 'es-amp-s' && changeTeachingLevel('HL')} name={'HL'} color={'#1FCD6C'} isActive={isActive === 'HL'} slug={activeSubjectSlug} />}
        </div>

        <div className={classNames('tvl-heading-section teaching-tag-heading',
        {
          'mt-3':isNewSubject,
          'mt-4':!isNewSubject
        })} ref={tvlRef}>
          <div className={`tvl-heading ${isAnimation === 'SL' || isAnimation === 'Studies SL' ? 'tvl-active' : 'hidden'}`}>
            {isNewSubject ? newSubjctSLContent(isActive) : SubjctSLContent(activeSubjectSlug, isActive)}
          </div>
          <div className={`tvl-heading ${isAnimation === 'HL' ? 'tvl-active' : 'hidden'}`}>{isNewSubject ? newSubjctSLContent(isActive) : SubjctSLContent(activeSubjectSlug, isActive)}</div>
        </div>

        <div className="flex w-full items-center justify-center">
          {(activeSubjectSlug !== 'es-amp-s' && activeSubjectSlug !== 'pre-ib-mathematics') ? <div className="flex absolute bottom-5 justify-between w-full px-5">
            <div className="tvl-cancel-button" onClick={(e: any) => onClose(e)}>Cancel</div>

            <div className={classNames("tvl-save-button", saveActive())} onClick={(e: any) => isSaveBtnEnable && saveTlvl(e)} >Save</div>
          </div>
            :
            <div className="flex absolute bottom-5 justify-between w-full px-5">
              <div className="tvl-ok-button" onClick={(e: any) => onClose(e)}>Ok</div>
            </div>}
        </div>


      </div>

    </div>
  </div>
}

export default TeachingLevelPopupOverlay
