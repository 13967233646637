import * as Navigation from 'actions/navigation/types';
import Immutable from 'immutable';
import { fromJS } from 'immutable';
import { act } from 'react-test-renderer';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = fromJS({
  active: -1,
  isFlashcardNavExpanded: false,
  isMenuVisible: false,
  isSubjectsManagerVisible: false,
  isNavigationSectionVisible: false,
  isLinksNavigationSectionVisible: false,
  isLastCardMessageHidden: false,
  isStoreModalOpen: false,
  isDeskEditModeActive: false,
  isFlashcardNavVisible: true,
  isSubjectsManagerOverlayVisible: true,
  isOldSubjectsManagerOverlayVisible:false,
  shouldAnimateSubjectsManagerOverlay: false,
  isKeyboardNavigationOverlayVisible: false,
  isSupportManagerOverlayVisible: false,
  isMoreControlOverlayVisible: false,
  isQuestionInfoOverlayVisible:false,
  isHiddenInfoOverlayVisible:false,
  isTeachingLevelOverlayVisible: false,
  isDeviceManagerOverlayVisible: false,
  structureLoading: false,
  isShortcutMenu: false,
  activeMediaTab: 'flashcard',
  lastMenuPath: Immutable.Map(),
  macroFilter: 0,
  apiWaitingResponse: false,
  scrollPosition:Immutable.Map({
    topics: 0,
    notes: 0,
    folders: 0,
    exercises: 0,
    Search: 0,
    subTopics:{
      previousValue:0,
      currentValue:0
    },
    topicsPage:{
      previousValue:0,
      currentValue:0
    },
    examPage:{
      previousValue:0,
      currentValue:0
    }
  }),
  examslistSize:{size:0,index:0},
  progressBarData:{size:0,index:0},
  isFilterOverlayVisible: false,
  isPaperOverlayVisible:false,
  isDiffficultyOverlayVisible:false,
  isExamsMode:false,
  isTeachingLevelExamsOverlayVisible:false,
  isAccountsPortalOverlayVisible:false,
  isVideoOverlayVisible:false,
  isSourceOverlayVisible:false,
  isMCQOverlayVisible:false,
  isMainMicroFilterVisible:false,
  isMainMicroFilterBack:false,
  isProgressBarFilterVisible:false,
  isEnableCookiesOverlayVisible: false,
  setMediaTypeFilter: null,
  execriseFilterItemSize: null,
  activeExamsTab:'ib_dp',
  setJWTToken:null,
  folderAddButtonActive:true,
  activeExamsId:'',
  activeExamsPaperId:'',
  activePaperTab:'Paper 1',
  topicsExamLoading:{next:false,prev :false},
  activeExamModule:false,
  isTeachingLevelOverlayVisibleFromFeature:false,
  activeExamsDetails:{'osc_dp':'Experience realistic, full exams created by Pamoja authors. Pamoja exams consist of questions that you can also practice through the topics menu.',
                      'osc_dp_mocks':'For your final studying steps, experience realistic, full exams created by Pamoja authors. Pamoja mocks consist of questions that you can only practice in this menu.',
                      'ib_dp': 'Benefit from official IB exams that students actually sat. Every exam comes with step-by-step written and video solutions, alongside the official IB markscheme.'
                      },
}), action) => {
  switch (action.type) {
    case Navigation.TOGGLE_FLASHCARD_NAVIGATION_VISIBILITY:
      return state.set('isFlashcardNavVisible', action.value);
    case Navigation.SET_ACTIVE_FLASHCARD_TAB:
      return state.set('active', action.index);
    case Navigation.SET_ACTIVE_EXAMS_TAB:
      return state.set('activeExamsTab',action.value)
    case Navigation.SET_ACTIVE_EXAMS_IDS:
      return state.set('activeExamsId',action.id)
    case Navigation.SET_ACTIVE_PAPER_TAB:
      return state.set('activePaperTab',action.value)
    case Navigation.SET_ACTIVE_EXAMS_PAPER_IDS:
      return state.set('activeExamsPaperId',action.id)
    case Navigation.SET_ACTIVE_MEDIA_TAB:
      return state.set('activeMediaTab', action.data || 'flashcard');
    case Navigation.SET_EXERCISE_ON_CANVAS:
      return state.set('exerciseOnCanvas', action.data || false);
    case Navigation.TOGGLE_FLASHCARD_NAVIGATION:
      // alert('This should be removed');
      console.warn('Toggle flashcard navigation should have been removed');
      return state;
      return state.update('isFlashcardNavExpanded', val => action.value || !val);
    case Navigation.SET_ACTIVE_DESK_TAB:
      return state.set('activeDeskTab', action.index);
    case Navigation.SET_ACTIVE_TROPHIES_TAB:
      return state.set('activeTrophiesTab', action.index);
    case Navigation.SET_ACTIVE_ITEM_STORE_DETAILS_TAB:
      return state.set('activeItemStoreDetailsTab', action.index);
    case Navigation.OPEN_MENU:
      return state.set('isMenuVisible', true);
    case Navigation.CLOSE_MENU:
      return state.set('isMenuVisible', false);
    case Navigation.TOGGLE_SUBJECTS_MANAGER:
      return state.update('isSubjectsManagerVisible', val => action.value !== undefined ? action.value : !val);
    case Navigation.TOGGLE_DESK_EDIT_MODE:
      return state.set('isDeskEditModeActive', action.value);
    case Navigation.TOGGLE_NAVIGATION_SECTION:
      return state.update('isNavigationSectionVisible', val => !val);
    case Navigation.TOGGLE_LINkS_NAVIGATION_SECTION:
        return state.update('isLinksNavigationSectionVisible', val => !val);
    case Navigation.HIDE_LAST_FLASHCARD_STUDIED_MESSAGE:
      return state.set('isLastCardMessageHidden', action.value);
    case Navigation.SHOW_STORE_MODAL:
      return state.set('isStoreModalOpen', true);
    case Navigation.HIDE_STORE_MODAL:
      return state.set('isStoreModalOpen', false);
    case Navigation.TOGGLE_SUBJECTS_MANAGER_OVERLAY:
      return state.update('isSubjectsManagerOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val)
      .set('isSubjectsManagerOverlayAnimationVisible', action.shouldAnimate || false);
    case Navigation.TOGGLE_OLD_SUBJECTS_MANAGER_OVERLAY:
      return state.update('isOldSubjectsManagerOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val)
      .set('isOldSubjectsManagerOverlayAnimationVisible', action.shouldAnimate || false);
    case Navigation.TOGGLE_SUPPORT_MANAGER_OVERLAY:
        return state.update('isSupportManagerOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_DEVICE_MANAGER_OVERLAY:
          return state.update('isDeviceManagerOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_KEYBOARD_NAVIGATION_OVERLAY:
      return state.update('isKeyboardNavigationOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_MORE_CONTROL_OVERLAY:
        return state.update('isMoreControlOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_QUESTION_INFO_OVERLAY:
      return state.update('isQuestionInfoOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_HIDDEN_INFO_OVERLAY:
      return state.update('isHiddenInfoOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_TEACHING_LEVEL_OVERLAY:
        return state.update('isTeachingLevelOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.OPEN_SHORTCUT_MENU:
      return state.set('isShortcutMenu', true);
    case Navigation.HIDE_SHORTCUT_MENU:
        return state.set('isShortcutMenu', false);
    case Navigation.SAVE_LAST_MENU_PATH:
      return state.set('lastMenuPath', action.data);
    case Navigation.SAVE_SCROLL_POSITION:
        return state.setIn(["scrollPosition", action.key], action.value);
    case Navigation.TOGGLE_FILTER_OVERLAY:
      return state.update('isFilterOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_PAPER_OVERLAY:
      return state.update('isPaperOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_DIFFICULTY_OVERLAY:
      return state.update('isDiffficultyOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_EXAMS_MODE:
      return state.update('isExamsMode', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_TEACHING_LEVEL_EXAMS_OVERLAY:
      return state.update('isTeachingLevelExamsOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_VIDEO_OVERLAY:
      return state.update('isVideoOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val)
    case Navigation.TOGGLE_SOURCE_OVERLAY:
      return state.update('isSourceOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_MCQ_OVERLAY:
      return state.update('isMCQOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.FOLDER_ADD_BUTTON_ACTIVE:
      return state.update('folderAddButtonActive', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.TOGGLE_MAIN_MICRO_OVERLAY:
      return state.update('isMainMicroFilterVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.MAIN_MICRO_FILTER_BACK:
      return state.update('isMainMicroFilterBack', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.GET_MEDIA_TYPE_FILTER_OVERLAY:
      return state.set('setMediaTypeFilter', action.mediaType);
    case Navigation.SET_FILTER_ITEM_SIZE:
      return state.set('filterItemSize', action.data);
    case Navigation.MACRO_FILTER_SIZE:
        return state.set('macroFilter', action.value);
    case Navigation.SET_FIRST_NODE:
      return state.set('firstNode', action.value);
    case Navigation.SET_FIRST_TOPIC_ID:
      return state.set('firstTopicId', action.value);
    case Navigation.SET_FIRST_SUBTOPIC_ID:
      return state.set('firstSubtopicId', action.value);
    case Navigation.STRUCTURE_LOADING:
        return state.update('structureLoading', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.API_RESPONSE_LOADING:
        return state.set('apiWaitingResponse', action.value);
    case Navigation.SET_EXERCISE_FILTER_ITEM_SIZE:
        return state.set('execriseFilterItemSize', action.value);
    case Navigation.TOPICS_NEXT_PREV_BUTTON_ENABLE:
      // return state.set('topicsExamLoading', fromJS({loading:action.loading, hasType:action.hasType}));
      return state.setIn(['topicsExamLoading', action.hasType], (action.loading));
    case Navigation.UPDATE_EXAMS_LIST:
      return state.set('examslistSize', fromJS({size:action.size, index:action.index}));
    case Navigation.SET_JWT_TOKEN:
      return state.set('setJWTToken',action.value)
    case Navigation.SET_UPDATE_EXAMS_PREVIEW_LIST:
      return state.set('updateExamsPreviewList',action.data)
    case Navigation.PROGRESS_BAR_FILTER_VISIBLE:
        return state.update('isProgressBarFilterVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.ENABLE_COOKIES_OVERLAY_VISIBLE:
      return state.update('isEnableCookiesOverlayVisible', val => (action.value === false || action.value === true) ? action.value : !val);
    case Navigation.SET_ACTIVE_EXAM_MODULE:
      return state.set('activeExamModule',action.data)
    case Navigation.TOGGLE_ACCOUNTS_PORTAL_OVERLAY:
      return state.set('isAccountsPortalOverlayVisible', action.data)
    case Navigation.UPDATE_PROGRESS_BAR:
      return state.set('progressBarData', fromJS({size:action.size, index:action.index}));
    case Navigation.TOGGLE_TEACHING_LEVEL_OVERLAY_FROM_FEATURE:
      return state.set('isTeachingLevelOverlayVisibleFromFeature', action.value);
    default:
      return state;
  }
};
