import {authenticate} from 'actions/api';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {reduxForm} from 'redux-form/immutable';
import RouterLink from 'router/Link';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';
import Text from 'ui-components/helpers/Text';
import FormInput from '../../components/FormInputComponent/FormInput';
import {validator, WarnIcon} from '../../utils/form-helper';
import './login.scss';
import styled from 'styled-components/macro';

class LoginForm extends PureComponent {
  renderSubmissionError = () => {
    const errorMessage = this.props.requestError.access_denied ? 'Your account is not yet confirmed! Check your email address!' : 'Please check your credentials and try again!';

    return <Row alignItems={'center'} justifyContent={'space-between'}>
      <WarnIcon/>
      <Padding right={6}/>
      <Text size={13} lineHeight={'19px'} color={'errorColor'} weight={600} letterSpacing={0}
        textAlign={'center'}>{errorMessage}</Text>
    </Row>;

  };

  render() {
    let {change, touch, handleSubmit, requestError} = this.props;

    return <React.Fragment>
      <Padding top={16}/>
      {requestError && this.renderSubmissionError()}
      <form onSubmit={handleSubmit}>
        <FormInput label={'Email or username'} name={'username'} placeholder={'Enter your email address or username'}
          type={'text'}
          change={change} touch={touch} validate={[validator.required]}/>
        <FormInput label={'Password'} name={'password'} placeholder={'Enter your password'} type={'password'}
          change={change} touch={touch} validate={[validator.required]}/>
        <Padding top={18}/>
        <Text size={13} color={'#A2A3A3'} lineHeight={'18px'} textAlign={'center'}>Forgot your <RouterLink
          to="/forgot-username"><StyledText as={'span'} size={13} color={'#378EF8'} weight={600}
          lineHeight={'18px'}>username</StyledText></RouterLink> or <RouterLink
          to="/forgot-password"><StyledText as={'span'} size={13} color={'#378EF8'} weight={600}
          lineHeight={'18px'}>password</StyledText></RouterLink>?</Text>
        <Padding bottom={8}/>
        {/*Hidden to allow submit on enter*/}
        <button type={'submit'} hidden>submit</button>
      </form>
    </React.Fragment>;
  }
}

LoginForm.propTypes = {
  requestError: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  externalButtons: PropTypes.node,

  // redux-form
  touch: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export const LOGIN_FORM_NAME = 'authenticate';

const onSubmit = (data, dispatch) => {
  return dispatch(authenticate(data));
};

const StyledText = styled(Text)`
  color: #378EF8;
  @media (pointer: fine) {
    &:hover {
      color: #64A8FA;
    }
  } 
  &:active {
    color: #2170CF;
  }
`;
export default reduxForm({form: LOGIN_FORM_NAME, onSubmit})(LoginForm);
