import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import './BackButton.scss';
import {BackChevronIcon, BackChevronTurquoiseIcon} from 'icons/HeaderIcons';
import RouterLink from 'router/Link';


type BackButtonProps = {
    onClick?: () => void;
    inverted?: boolean;
    hideArrow?: boolean;
    linkTo?: string;
    text?: React.ReactElement | string;
    children?: React.ReactElement | string;
}  

const BackButton: React.FC<PropsWithChildren<BackButtonProps>> = ({onClick, inverted, hideArrow, linkTo, text, children}) => {
    const renderArrow = () => {
        return inverted ? <BackChevronIcon /> : <BackChevronTurquoiseIcon/>;
    }
    const content = (
        <div className={'back-button back-icon-container'  + (inverted ? ' inverted' : '')} onClick={onClick} >
          {!hideArrow && renderArrow()}
          <span
            className={'back-title' + (inverted ? ' inverted' : '') + (hideArrow ? ' no-padding' : '')}>
            {text || children}</span>
        </div>
      );
  
      if (!linkTo) {
        return content;
      }
  return (
    <RouterLink to={linkTo}>
        {content}
    </RouterLink>
  )
}

export default BackButton;