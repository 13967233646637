import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import React, { PureComponent, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import Col from 'ui-components/helpers/Col';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';
import Text from 'ui-components/helpers/Text';
import { TopicNumber } from 'ui-components/search-results/PreviewListItem';
import TeachingLevelSeperator from 'components/teaching-level-selector/TeachingLevelSeperator';
import media from 'ui-components/helpers/media';
import { ForwardChevronIcon, ArrowTopicIcon } from 'icons/HeaderIcons';
import Loader, {BackgroundEnum, SizeEnum} from 'v2/components/loader.v2/Loader.v2';
import { subtopicsSelector } from 'selectors/api/apiSelector';
import * as Selectors from 'selectors';
class TopicTile extends PureComponent {
  state={
    isHover:false
  }
  componentDidMount() {
    const { topicData, parentIds } = this.props;
    const topicId = topicData.get("id");
    if (this.props.activeTopicId === topicData.get("id")) {      
        // this.props.toggleTopicVisibility(topicId, parentIds)
    }
  }

  setHover = (e) => {
    if(e.type!=='mouseleave'){
      this.setState({isHover: true})
    }
    else{
      this.setState({isHover: false},()=>{this.setState({isHover: false})})
    }
  }

  render() {
    let { topicData, expanded, onExpand, onSelect, absolute, active, answers, activeTopicId, isExercise, currentFlashcardId, hasSelection, isExernalExercise, checkMircoFilterFlashcard } = this.props;
    const subtopicRequest = this.props.subtopicsResponse.get(topicData.get('id'));
    let isLoading = false;
    if (subtopicRequest) {
      isLoading = subtopicRequest.get("loading");
    }
    return <StyledTopic onClick={!isExercise && onSelect} expanded={(active && !expanded)} active={active} absolute={absolute} className={active ? 'tutorial-step-topic' : ''} hasSelection={hasSelection}>
      <TileHeader active={active} expanded={(active && !expanded)} isShadow={(expanded)} currentFlashcardId={currentFlashcardId} hasSelection={hasSelection} isHover={this.state.isHover}>
        {absolute}
        {(active && !expanded && !hasSelection) && currentFlashcardId && <LeftActiveTile />}
        {(active && !expanded && isExercise && hasSelection) && <LeftActiveTile isExercise={isExercise} />}
        <React.Fragment>
          <Col onMouseLeave={this.setHover} onMouseMove={this.setHover} onTouchMove={this.setHover} onTouchStart={this.setHover} onTouchEnd={this.setHover}>
            <Padding top={14} right={14} bottom={0} left={14}>
              <Row justifyContent={'space-between'} alignItems={'center'}>
                {/*TODO replace Row with PreviewListHeader (padding is different)*/}
                <Row alignItems={'center'}>
                  {/* <StyledTechingLevelHeader type={topicData.get('tlvl')}><Text color={'#FFFFFF'} size={13} lineHeight={'18px'}>{topicData.get('tlvl')}</Text></StyledTechingLevelHeader> */}
                  <TeachingLevelSeperator tlvl={topicData.get('tlvl')} tlvlTag={topicData.get('teachingTag')} isTopic={true} />
                  <TopicNumber alternate={active || expanded}>TOPIC {topicData.get('label')}</TopicNumber>
                </Row>
              </Row>
              <StyleTopicTitle><Text size={25} weight={'bold'} color={'#2D3838'} lineHeight={'34px'}>{topicData.get('title')}</Text></StyleTopicTitle>
            </Padding>
          </Col>
          {isExercise && <Padding top={14} right={14} bottom={0} left={14}>
            <TopicButton onClick={onSelect} disabled={checkMircoFilterFlashcard} ><Text size={13} weight={'bold'} color={'#FFFFFF'}
              lineHeight={'18px'} letterSpacing={'2'}>{isExercise ? "VIEW FLASHCARDS " : "STUDY TOPIC"}</Text> <ForwardChevronIcon color={'#FFFFFF'} class={"more-icon"} /></TopicButton>
            {(isExernalExercise) && <Padding top={8} />}
          </Padding>}
          {answers && <Padding left={hasSelection ? 14 : 3.5} right={hasSelection ? 14 : 3.5} top={hasSelection ? 12 : 0} bottom={expanded ? 8 : 0}> <TopicSliderChild onClick={(e) => !isExercise && e.stopPropagation()} onTouchStart={()=>this.setState({isHover: false})}>{answers}</TopicSliderChild> </Padding>}
          {isLoading && <Padding top={16} bottom={12}> <StyledLoader><Loader size={SizeEnum.Small} background={BackgroundEnum.Blue} /> </StyledLoader></Padding>}
          {!expanded &&
            <Padding top={16} right={14} bottom={16} left={14}>
              <StyledSubTopicButton onClick={(e) => { e.stopPropagation(); onExpand();}} expanded={(active && !expanded)} onTouchStart={()=>this.setState({isHover: false})}>
                <ArrowTopicIcon /><Text size={13} weight={'bold'} color={'#378EF8'}
                  lineHeight={'18px'} letterSpacing={'2'}>VIEW {isExercise ? topicData.get('totalFCSubTopics') : topicData.get('totalSubTopics')} SUBTOPICS</Text>
              </StyledSubTopicButton>
            </Padding>
          }
          {/* {answers && <Padding top={12}>{answers}</Padding>} */}
        </React.Fragment>
      </TileHeader>
      <ExpandedArea>
        {(expanded && !isLoading) &&
          <React.Fragment>
            <Padding left={14} top={12} bottom={12} right={14}>
              <StyledSubTopicCloseButton onClick={(e) => { e.stopPropagation(); onExpand(); }} expanded={expanded}>
                <ArrowTopicIcon /><Text size={13} weight={'bold'} color={'#378EF8'}
                  lineHeight={'18px'} letterSpacing={'2'}>Close {isExercise ? topicData.get('totalFCSubTopics') : topicData.get('totalSubTopics')} SUBTOPICS</Text>
              </StyledSubTopicCloseButton>
            </Padding>
            {this.props.children}
            <Padding left={14} right={14}>
              <StyledSubTopicCloseButton onClick={(e) => { e.stopPropagation(); onExpand(); }} expanded={expanded}>
                <ArrowTopicIcon /><Text size={13} weight={'bold'} color={'#378EF8'}
                  lineHeight={'18px'} letterSpacing={'2'}>Close {isExercise ? topicData.get('totalFCSubTopics') : topicData.get('totalSubTopics')} SUBTOPICS</Text>
              </StyledSubTopicCloseButton>
            </Padding>
          </React.Fragment>
        }
      </ExpandedArea>
    </StyledTopic>;
  }
}

const ExpandedArea = styled.div`
`;

const StyleTopicTitle = styled.div`
  padding-top: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  >${Text}{
    overflow: hidden;
  }
`;
const computeColor = type => {
  if (type.indexOf('SL') > -1) return '#458CFC';
  if (type.indexOf('HL') > -1) return '#1FCD6C';
  return '#458CFC;';
};
const TopicSliderChild = styled.div``;
const TopicSlder = styled.div`
  padding:24px ${props => props.hasSelection ? 14 : 17.5 }px ${props => props.hasSelection ? 0 :4}px; 
  ${props => props.isExercise && css`
      padding-top:12px !important;
    ${props => props.expanded && css`
      padding-bottom:4px !important;
    `}
    ${props => (!props.expanded && !props.hasSelection) && css`
      padding-bottom:14px !important; 
    `}
  `}
  margin-bottom: ${props => !props.hasSelection && (props.isMcq && props.hasResponse) ? (!props.expanded ? css`25px` : css`36px`) : (props.expanded ? css`4px` : css`0px`) };
`;

TopicTile.propTypes = {
  topicData: PropTypes.any,
  expanded: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  subtopics: PropTypes.any,
  absolute: PropTypes.node,
  active: PropTypes.bool,
  children: PropTypes.node,
  answers: PropTypes.node,
};
const StyledLoader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
`;

const StyledSubTopicButton = styled.div`
    box-shadow: 0 0 0 1px #E3E6E6;
    background: #FFFFFF;
    border-radius: 22px;
    padding: 9px 0;
    justify-content: center;
    align-items: center;
    display: flex;
    text-transform: uppercase;
    cursor: pointer;
    user-select:none;
    svg{
      margin-right: 12px;
    }
    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 0 0 1px #C8CCCC;
      }
    } 
    &:active {
      box-shadow: none;
      background: #378EF8;
      >${Text}{
        color: #FFFFFF;
      }
      svg{
        circle{
          fill: #FFFFFF;
        }
        path{
          fill: #378EF8;
        }
      }
    }
`;
const TopicButton = styled.div`
    height: 36px;
    background: #378EF8;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    opacity: ${props => props.disabled && '0.5 !important'};
    pointer-events: ${props => props.disabled && 'none !important'};
    user-select:${props => props.disabled && 'none !important'};
    >${Text}{
      margin-right: 16px;
    }
    @media (pointer: fine) {
      &:hover {
        background: #64A8FA;
      }
    } 
    &:active {
      background: #2170CF;
    }
`;

const StyledSubTopicCloseButton = styled.div`
  height: 36px;
  box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.2);
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor:pointer;
  background: #FFFFFF;
  position: relative;
  svg{
    margin-right: 12px;
    transform: rotate(180deg);
  }
  @media (pointer: fine) {
      &:hover {
        //box-shadow: 0 0 0 1px #E3E6E6;
        border: 1px solid #E3E6E6
      }
    } 
    &:active {
      border: none;
      background: #378EF8;
      >${Text}{
        color: #FFFFFF;
      }
      svg{
        circle{
          fill: #FFFFFF;
        }
        path{
          fill: #378EF8;
        }
      }
    }
`;

const HorizontalSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: transparent;
  margin: 10px 0;
  border-radius: 5px;
`;

const StyledTopic = styled(Col)`
  
  margin-bottom: 32px;
  position: relative;
  z-index: 1;
  width: 100%;
  margin-left: ${props => props.absolute ? '27px' : 'auto'}; 
  ${props => !props.hasSelection && css`cursor: pointer`};
  > ${Col} {
    
    &:first-child{
      border-bottom: ${props => props.expanded ? '1px solid #E5E5E5' : 'none'};
      
    }
  }
  ${media.tablet(css`
    width: 100%;
  `)};
  
  ${media.phone(css`
    width: 100%;
  `)};
  
  ${TopicNumber}{
    margin-left: 12px;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.34);
    border-radius: 97px;
    user-select: none;
  }
`;

const StyledTechingLevelHeader = styled.div`
  text-transform: initial;
  background: ${props => computeColor(props.type)};
  border-radius: 5px;
  padding: 2px 8px 2px 10px;
  user-select: none;
`;


const mapStateToProps = state => ({
  subtopicsResponse: subtopicsSelector(state),
  currentFlashcardId: Selectors.Studying.currentFlashcardId(state),
});
export default connect(
  mapStateToProps, null
)(TopicTile);

export const Subtopic = ({ label, title, onSelect, expanded, isExercise, children, absolute, active, answers, parentLabel, parentTlvl, parentTlvlTag, currentFlashcardId, hasSelection, isExernalExercise, checkMircoFilterFlashcard }) => {
  const [isHover, setIsHover] = useState(false)

  const setHover = (e) => {
    if(e.type!=='mouseleave'){
      setIsHover(true)
    }
    else{
      setIsHover(false)
    }
  }
 
  return <React.Fragment>
    <StyledSubtopic expanded={expanded} active={active} data-subtopic-active={active} currentFlashcardId={currentFlashcardId} isExercise={isExercise} isHover={isHover} onClick={onSelect}>
      {absolute}
      {(active && currentFlashcardId && !hasSelection) && <LeftActiveTile />}
      {(active && isExercise && hasSelection) && <LeftActiveTile isExercise={isExercise} />}
      {/* <Padding left={14} right={14} bottom={hasSelection ? 0 : expanded ? 16 : 14} top={14} style={{ overflow: 'hidden' }}> */}
        <Col onMouseLeave={setHover} onMouseMove={setHover} onTouchMove={setHover} onTouchStart={setHover}>
          <Padding left={14} right={14}  top={14}>
            <Row alignItems={'center'}>
              {/* <StyledSubtopicTechingLevelHeader type={parentTlvl}><Text color={'#FFFFFF'} size={13} lineHeight={'18px'}>{parentTlvlTag}</Text></StyledSubtopicTechingLevelHeader> */}
              <TeachingLevelSeperator tlvl={parentTlvl} tlvlTag={parentTlvlTag} />
              <TopicNumber alternate={active || expanded}>TOPIC {parentLabel}</TopicNumber>
              <ArrowTopicIcon />
              <SubTopicNumber><Text color={'#378EF8'} size={13} weight={'bold'} letterSpacing={'2'} lineHeight={'18px'}>{label}</Text></SubTopicNumber>
            </Row>
            <StyleTopicTitle><Text size={25} weight={'bold'} color={'#2D3838'} lineHeight={'34px'} dangerouslySetInnerHTML={{ __html: title }} /></StyleTopicTitle>
            </Padding>
        </Col>
        {isExercise && <Padding top={14} left={14} right={14}>
          <SubTopicButton onClick={onSelect} disabled={checkMircoFilterFlashcard} ><Text size={13} weight={'bold'} color={'#FFFFFF'}
            lineHeight={'18px'} letterSpacing={'2'}>{isExercise ? "VIEW FLASHCARDS " : "STUDY SUBTOPIC"} </Text> <ForwardChevronIcon color={'#FFFFFF'} class={"more-icon"} /></SubTopicButton>
          {(isExernalExercise && !expanded) && <Padding top={8} />}
        </Padding>}
        {answers && <Padding left={hasSelection ? 14 : 3.5} right={hasSelection ? 14 : 3.5} top={hasSelection ? 12 : 0} bottom={hasSelection ? 0 : 16} style={{'overflow':'hidden'}}><SilderWarpe onClick={(e) => e.stopPropagation()} hasSelection={hasSelection} onTouchStart={()=>setIsHover(false)}>{answers}</SilderWarpe></Padding>}
      {/* </Padding> */}
    </StyledSubtopic>
    {/* {expanded && <SubtopicChildren as={Col}>{children}</SubtopicChildren>} */}
  </React.Fragment>;
};

const LeftActiveTile = styled.div`
  position: absolute;
  left: ${props => props.isExercise ? "-41px" : "-14px"};
  // background: linear-gradient(225deg, #43DAF0 0%, #395BF8 100%);
  background: #378EF8;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  top: 0;
  bottom: 0;
  width: 4px;
`;
const Content = styled.div`
  flex-grow: 1;
`;
const StyledSubtopicTechingLevelHeader = styled.div`
  text-transform: uppercase;
  background: ${props => computeColor(props.type)};;
  border-radius: 5px;
  padding: 2px 8px;
  user-select: none;

`;
const SubTopicButton = styled.div`
    height: 36px;
    background: #378EF8;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    cursor:pointer;
    opacity: ${props => props.disabled && '0.5 !important'};
    pointer-events: ${props => props.disabled && 'none !important'};
    user-select:${props => props.disabled && 'none !important'};
    >${Text}{
      margin-right: 16px;
    }
    svg{
      transform: rotate(0deg) !important;
    }
    @media (pointer: fine) {
      &:hover {
        background:#64A8FA;
      }
    } 
    &:active {
      background: #2170CF;
    }
`;
const SubTopicNumber = styled.div`
  text-transform: uppercase;
  background: #FFFFFF;
  box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.34);
  border-radius: 97px;
  padding: 2px 8px 2px 10px;
  margin-left: 8px;
  user-select: none;
`;

const StyledSubtopic = styled(Col)`
  box-shadow: 0px 10px 32px rgba(62, 87, 85, 0.257293);
  border-radius: 10px;
  margin-bottom: 12px;
  background: ${props => {
    if (props.active && props.currentFlashcardId) return '#E5ECF2';
    return '#EFF4F9';
  }};
  position: relative;
  
  ${TopicNumber}{
    margin-left: 12px;
    background: #FFFFFF;
    box-shadow: none;
    border-radius: 97px;
    margin-right: 8px;
  }
  ${Row} {
    svg{
      transform: rotate(270deg);
    }
  }
  
  .bar-line-bg{
    ${props => props.active && 'background: white;'}
    ${props => props.expanded && 'background: white;'}
  }
  @media (pointer: fine){
    &:hover{
      // ${props => !props.isExercise && 'box-shadow: 0px 0px 0px 1px #BEC8C6;'}
      ${props => !props.isExercise && 'box-shadow: 0px 0px 0px 1px #BEC8C6, 0px 10px 32px rgba(62, 87, 85, 0.257293);'}

    }
  }
  &:active{
    // ${props => (!props.isExercise && !props.isHover) && 'box-shadow: 0px 0px 0px 3px #378EF8;'}
    ${props => (!props.isExercise && props.isHover) && 'box-shadow: 0px 0px 0px 3px #378EF8, 0px 10px 32px rgba(62, 87, 85, 0.257293);'}
  }
  ${'' /* @media (pointer: fine) {
    &:hover {
      background: ${props => props.active ? '#E5ECF2' : '#E5ECF2'};
      
      ${TopicNumber}{
        background-color: white;
      }
      
      .bar-line-bg{
        background: white;
      }
    }
  } */}
`;

// const SubtopicChildren = styled.div`
//     &:last-of-type{
//        > ${PreviewWrapper}:last-child{
//         > ${StyledPreviewListItem}:last-child{
//            border-bottom-right-radius: 10px;
//            border-bottom-left-radius: 10px;
//         }
//       }
//     }

//       &:last-of-type{
//        > ${PreviewWrapper}:last-child:after{
//         display: none;
//       }
//     }
// `;

const PlusIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
  
  svg {
    transform: ${props => props.expanded ? 'rotate(45deg)' : 'rotate(0)'};
    transition: transform .1s ease-in-out;
    transform-origin: center center;
  }
  
  ${StyledTopic} & {
    top: -14px;
    right: -8px;
    padding-left: 18px;
  }
  
  ${StyledSubtopic} & {
    top: -12px;
    height: 44px;
  }
`;

const TileHeader = styled.div`
  overflow: hidden;
  border-radius: 10px;
  box-shadow: ${props => props.isShadow ? '0px 6px 20px rgba(62, 87, 85, 0.2)' : '0px 0px 0px 1px #E1E6E6'};
  
  background: ${props => {
    if (props.expanded && props.currentFlashcardId) return '#E5ECF2';
    return '#FFFFFF';
  }};
  .bar-line-bg{
    ${props => props.active && 'background: white;'}
    ${props => props.expanded && 'background: white;'}
  }
  @media (pointer: fine) {	
  &:hover{
    // ${props => !props.hasSelection && 'box-shadow: 0px 0px 0px 1px #BEC8C6;'}
    ${props => !props.hasSelection && 'box-shadow: 0px 0px 0px 1px #BEC8C6;'}
    ${props => (!props.hasSelection && props.isShadow) && 'box-shadow: 0px 0px 0px 1px #BEC8C6, 0px 6px 20px rgba(62, 87, 85, 0.2);'}
  }
}
  &:active{
    // ${props => (!props.hasSelection && props.isHover) && 'box-shadow: 0px 0px 0px 2px #378EF8;'}
    ${props => (!props.hasSelection && props.isHover) && 'box-shadow: 0px 0px 0px 3px #378EF8;'}
    ${props => (!props.hasSelection && props.isHover && props.isShadow) && 'box-shadow: 0px 0px 0px 3px #378EF8, 0px 6px 20px rgba(62, 87, 85, 0.2);'}
  }
  ${'' /* @media (pointer: fine) {
    &:hover {
      background: ${props => props.active ? '#e9ebeb' : '#F2F2F2'};
      
      ${TopicNumber}{
        background-color: white;
      }
      
      .bar-line-bg{
        background: white;
      }
    }
  } */}
`;

const SilderWarpe = styled.div`
// ${props => (props.isMcq && !props.hasSelection) && css`margin-bottom:27px;`}
//   padding-top:23px;
`;