import './SimpleButton.scss';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';

export default class SimpleButton extends PureComponent {
  render() {
    return (
      <button
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        className={`simple-button${this.props.inverted ? ' inverted' : ''}`}>{this.props.text}</button>
    );
  }
}

SimpleButton.defaultProps = {
  onClick: () => {
  }
};

SimpleButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
};
