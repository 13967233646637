import React from 'react';
import { toggleSupportManagerOverlay } from 'actions/navigation';
import SupportComponent from 'v2/components/supports-manager/SupportContainer';
import { isSupportManagerOverlayVisible, isMobile, isLandscape } from 'selectors';
import { useDispatch, useSelector } from "react-redux";
import { PageOverlay } from 'ui-components/PageOverlay';



type SupportManagerContainerProps = {
  isControlOverlay?: boolean
}

const SupportManagerContainer: React.FC<SupportManagerContainerProps> = ({ }) => {
  const dispatch = useDispatch();
  const hasMobile = useSelector(isMobile);
  const hasisLandscape = useSelector(isLandscape);
  const hasSupportManagerOverlayVisible = useSelector(isSupportManagerOverlayVisible)
  const isMobilePortrait = !(hasMobile && hasisLandscape);

  return <PageOverlay onClose={() => dispatch(toggleSupportManagerOverlay())} isOverlayVisible={hasSupportManagerOverlayVisible} topPosition={isMobilePortrait} isWidth={!isMobilePortrait} supportAnimation={!isMobilePortrait}>
    {hasSupportManagerOverlayVisible && (<SupportComponent />)}
  </PageOverlay>
}


export default SupportManagerContainer