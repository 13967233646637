import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLandscape } from 'selectors';
import {
  carouselFlashcardIndex,
  carouselRefIndex,
  carouselRendererIds, hasNextVideo, hasPrevFlashcard
} from 'selectors/carouselSelectors';
import {getUserId} from 'selectors/user';
import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2';
import {mediaSizes} from 'selectors/browser/browserSelector'
import VideoCarousel from 'v2/components/video-carousel/VideoCarousel';
import MediaButtonController from 'v2/components/studying/MediaBottomButton';
import SearchNavigationContainer from 'v2/components/studying/SearchNavigation/SearchNavigationContainer';
import { getLastMediaTypeVisited} from 'helpers';
import { getCardsTitleByType } from 'actions/api/apiActions';
import {activeSubjectId} from 'selectors/subject/subjectSelector';
import './video.scss'

type VideoCarouselContainerProps = {
    currentId: number
}

const VideoCarouselContainer: React.FC<VideoCarouselContainerProps> = ({currentId}) => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState<boolean>(true)
    const hasLandscape = useSelector(isLandscape)
    const rendererIds = useSelector(carouselRendererIds)
    const hasCarouselRefIndex = useSelector(carouselRefIndex)
    const hasNext = useSelector(hasNextVideo)
    const hasPrev = useSelector(hasPrevFlashcard)
    const flashcardIndex = useSelector(carouselFlashcardIndex)
    const subjectId = useSelector(activeSubjectId)
    const userId = useSelector(getUserId)
    const mediaSize = useSelector(mediaSizes)
    const mediaType = getLastMediaTypeVisited(userId)
    const isSPhone = mediaSize.sPhone;

    useEffect(() => {
        dispatch(getCardsTitleByType({ subjectId: subjectId, mediaType: "video" }))
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [])


    const computeOrder = useMemo(() => {
        let orderArr = new Array(3);
        orderArr[hasCarouselRefIndex] = 1;
        orderArr[(hasCarouselRefIndex + 1) % 3] = 2;
        orderArr[(hasCarouselRefIndex + 2) % 3] = 3;
        return orderArr;
    },[hasCarouselRefIndex]);

    if (!rendererIds || !(flashcardIndex >=0)) return null;
    
    if (isLoading) {
        return <div className='flex items-center justify-center z-3 bottom-0 top-0 right-0 left-0 fixed bg-#EFF4F9'>
          <Loader background={BackgroundEnum.BlueX} size={SizeEnum.Large} />
        </div>
    }

    return <div className='video-wrapper'>
        <VideoCarousel
            order={computeOrder} 
            rendererIds={rendererIds} 
        />
        <div className="video-bottom-controls">
          <MediaButtonController id={rendererIds?.get(1)} mediaType={mediaType} hasPrev={hasPrev} hasNext={hasNext} />
            <div className="video-search" >
              <SearchNavigationContainer isMobile={isSPhone} isLandscape={hasLandscape}/>
            </div>
        </div>
    </div>

}

export default VideoCarouselContainer;
