import React, { useEffect } from 'react'
import Loader, {BackgroundEnum, SizeEnum} from './Loader.v2';

type FullPageLoaderProps = {
    text?: string; 
    background?: BackgroundEnum;
} 
const FullPageLoader: React.FC<FullPageLoaderProps> = ({text, background}) => {
  useEffect(() => {
    document.body.classList.add('gradient');
    //TODO- Background Remove
    // return () => {
    //   document.body.classList.remove('gradient');
    // }
  },[]);
  return (
    <div className='flex min-h-screen items-center justify-center'>
        <div className='flex items-center justify-center w-auto flex-col'>
            <div className='text-white text-33px tracking-normal leading-45px font-bold pb-24px'>
              {text}  
            </div>
            <Loader size={SizeEnum.Large} background={background}/>
        </div>
    </div>
  )
}
export default FullPageLoader