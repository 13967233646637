import React, {Component} from 'react';
import {connect} from 'react-redux';
import './NotesTooltip.scss';
import * as Actions from 'actions';
import * as Selectors from 'selectors';
import PropTypes from 'prop-types';
import MobileNoteList from './MobileNoteList';
import MobileEditNote from './MobileEditNote';
/**
 * @deprecated TODO- delete not use any more
 */

class NotesTooltipContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: true
    };
    this.onBack = this.onBack.bind(this);
    this.onEdit = this.onEdit.bind(this);
    
  }

  componentDidMount() {
    this.props.getFlashcardsWithNotes();
    if (!this.props.currentFlashcardId) {
      this.setState({
        isEditing: false
      });
    }else{
      this.setState({
        isEditing: true
      });
    }
  }

  onBack() {
    this.setState({isEditing: false});
  }

  onEdit() {
    this.setState({isEditing: true});
  }

  render() {
    return (
      this.state.isEditing ?
        <MobileEditNote
          onBack={this.onBack}
          currentFlashcard={this.props.currentFlashcard}
          deleteConfirmationMode={this.props.deleteConfirmationMode}
          toggleDeleteConfirmationMode={this.props.toggleDeleteConfirmationMode}
          toggleFocusTextarea={this.props.toggleFocusTextarea}
          isTextareaFocused={this.props.isTextareaFocused}
          textareaNote={this.props.textareaNote}/> :
        <MobileNoteList
          currentFlashcardId={this.props.currentFlashcardId}
          onEdit={this.onEdit}
          getFlashcardsWithNotes={this.props.getFlashcardsWithNotes}
          deleteConfirmationMode={this.props.deleteConfirmationMode}
          toggleDeleteConfirmationMode={this.props.toggleDeleteConfirmationMode}
          flashcardsWithNotes={this.props.flashcardsWithNotes}
          activeTeachingLevelTitle={this.props.activeTeachingLevelTitle}
        />
    );
  }
}

const mapStateToProps = state => ({
  flashcardsWithNotes: Selectors.flashcardsWithNotes(state),
  currentFlashcard: Selectors.Studying.currentFlashcard(state),
  currentFlashcardId: Selectors.Studying.currentFlashcardId(state),
  deleteConfirmationMode: Selectors.deleteConfirmationMode(state),
  isTextareaFocused: Selectors.isTextareaFocused(state),
  textareaNote: Selectors.textareaNote(state),
  activeTeachingLevelTitle: Selectors.activeTeachingLevelTitle(state),
});

const mapDispatchToProps = {
  getFlashcardsWithNotes: Actions.Desk.getFlashcardsPreviewWithNotes,
  toggleDeleteConfirmationMode: Actions.Desk.toggleDeleteConfirmationMode,
  toggleFocusTextarea: Actions.Desk.toggleFocusTextarea,
};

NotesTooltipContainer.propTypes = {
  flashcardsWithNotes: PropTypes.any,
  getFlashcardsWithNotes: PropTypes.func.isRequired,
  toggleFocusTextarea: PropTypes.func.isRequired,
  isTextareaFocused: PropTypes.bool,
  currentFlashcard: PropTypes.any,
  currentFlashcardId: PropTypes.any,
  deleteConfirmationMode: PropTypes.bool,
  toggleDeleteConfirmationMode: PropTypes.func,
  textareaNote: PropTypes.string
};
export default connect(mapStateToProps, mapDispatchToProps)(NotesTooltipContainer);
