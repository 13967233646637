import Immutable,{Map} from 'immutable';

export const listOfCheckedItems = (state) => state.getIn(['desk', 'listOfCheckedItems'], Immutable.List());
export const isDeskRequestInProgress = (state, request) => state.getIn(['desk', request, 'inProgress'], false);
export const flashcardsWithNotes = (state) => state.getIn(['desk', 'flashcardsWithNotes', 'data', 'items'], Immutable.List());
export const flashcardsInFolder = (state) => state.getIn(['desk', 'flashcardsInFolder', 'data'], Immutable.List());
export const flashcardNoteContent = (state) => state.getIn(['desk', 'flashcardNote', 'data']);
export const flashcardNoteRequest = (state) => state.getIn(['desk', 'flashcardNote']);
export const textareaNote = (state) => state.getIn(['desk', 'textareaNote']);
export const deleteConfirmationMode = (state) => state.getIn(['desk', 'deleteConfirmationMode']);
export const isTextareaFocused = (state) => state.getIn(['desk', 'isTextareaFocused']);
export const deleteFlashcardNoteRequest = (state) => state.getIn(['desk', 'deleteFlashcardNote']);
export const folders = (state) => state.getIn(['desk', 'getFolders']);
export const initializeStatus = (state) => state.getIn(['desk', 'initializeStatus']);
export const folderTitle = (state) => state.getIn(['desk', 'getFolder', 'data', 'title']);
export const smartFolderType = (state) => state.getIn(['desk', 'smartFolderType']);
export const listOfFilterCheckedItems = (state) => state.getIn(['desk', 'listOfFilterCheckedItems'], Immutable.List());
export const examsofFilterItems = (state) => state.getIn(['desk','examsofFilterItems'],Immutable.List())
export const getFolderFilterListSize = (state) => state.getIn(['desk', 'folderFilterListSize'], Immutable.List());
export const getListOfFilterItemsIds = (state) => state.getIn(['desk', 'listOfFilterItemsIds'], Immutable.List());
export const getListOfFilterTitles = (state) => state.getIn(['desk', 'listOfFilterTitles'], Immutable.List());
export const getExamsQuestionIndex =(state)=> state.getIn(['desk','examsQuestionIndex']);
export const getPaperAllQuestionData =(state)=> state.getIn(['desk','paperAllQuestionData']);
export const getExamQuestionNumber = (state) => state.getIn(['desk','setExamQuestionNumber'])
export const getQuestionPoint = (state) => state.getIn(['desk','questionPoint']);
export const getMultipleSourceUpdate = (state) => state.getIn(['desk','multipleSourceUpdate']);
export const assessmentAnimation = (state) => state.getIn(['desk', 'isAssessmentAnimation']);
export const getActiveTeachingTagStatus = (state) => state.getIn(['desk',"isActiveTeachingTagStatus"])
export const getLastMediaTypeNotes = (state) => state.getIn(['desk', 'lastMediaTypeNotes'])

export const getActiveMessageLoader = (state) => state.getIn(['desk','isActiveMessageLoader'])

export const getExamTopicsStatus = (state, request) => {
    return state.getIn(['desk', 'examTopicStatus', parseInt(request), 'exam'], Map({
        exam: Map({ freeResponse: Map({}), msqTotal: Map({}) })
    }));
}


