import React, {memo} from 'react'
import {ReactComponent as ExerciseStartedIcon} from 'assets/images/notifications/exercise-started.svg';
import './notifications.scss'

const ExerciseStartedNotification = () => {
    return <div className='exercise-start-notification-wrapper'>
        <ExerciseStartedIcon/>
        <div className='font-bold text-15px text-[#FBFBFB]'>Exercise started</div>
    </div>
}
export default memo(ExerciseStartedNotification);