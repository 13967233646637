import React, {useState, useEffect} from "react";
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useSelector } from 'react-redux';
import {isMobile} from "selectors"
import theme from 'theme';

const useScrollDetection = ({WrappedRef, threshold1 = 20, threshold2 = 60, containedOnly = true}) => {

  const [scrolledBeyondThreshold1 , setScrolledBeyondThreshold1] = useState(false);
  const [scrolledBeyondThreshold2 , setScrolledBeyondThreshold2] = useState(false);
  const hasMobile = useSelector(isMobile)

  useEffect(() => {
    if (!hasMobile || containedOnly) {
      WrappedRef?.current?.addEventListener('scroll', handleScroll);
    } else {
      window.addEventListener('scroll', handleScroll);
    }
    return () =>  window.removeEventListener('scroll', handleScroll);
  }, [WrappedRef, threshold1])

  function handleScroll(event) {
    clearAllBodyScrollLocks();
    if (!hasMobile && event?.target !== WrappedRef) return;
    if (window.innerWidth < theme.sizes.phone && window.innerWidth > window.innerHeight
      && hasMobile && event?.target !== WrappedRef) return;

    let target = event?.target;

    if (target.scrollingElement) {
      target = target.scrollingElement;
    }
    if (target.scrollTop > threshold1) {
      setScrolledBeyondThreshold1(true)
    } else {
      setScrolledBeyondThreshold1(false)
    }

    if (target.scrollTop > threshold2) {
      setScrolledBeyondThreshold2(true)
    } else {
      setScrolledBeyondThreshold2(false)
    }
  }
  const bindScrollRef = (ref) =>{
    if (!ref) ref = document;
    WrappedRef = ref;

    if (!hasMobile || containedOnly) {
      WrappedRef && WrappedRef.addEventListener('scroll', handleScroll);
    } else {
      window.addEventListener('scroll', handleScroll);
    }
  }

  return {isScrolled: scrolledBeyondThreshold1, isScrolled2: scrolledBeyondThreshold2, bindScrollRef:bindScrollRef };
};

export default useScrollDetection;