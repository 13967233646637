import React, { ReactSVGElement } from 'react';
import classNames from 'classnames';
import CounterNotification from 'v2/components/notifications/CounterNotification';
import './LeftMenuItem.scss';

type ItemType = {
    icon:(arg: boolean) => ReactSVGElement;
    name: string;
    hasNotification:boolean;
}

type LeftMenuItemProps = {
    onClick: () => void;
    isActive: boolean;
    item: ItemType;
    notificationCount?: number;
}

const LeftMenuItem: React.FC<LeftMenuItemProps> = ({ onClick, item, isActive, notificationCount }) => (
    <div className={classNames('h-52px flex items-center justify-center menu-button', { 'mt-2.5': item.name !== 'Topics' })}>
        <div className='w-46 flex items-center cursor-pointer z-4 select-none menu-item-container' onClick={onClick}>
            <div className={classNames('flex items-center justify-center cursor-pointer w-10 h-10 select-none rounded-3xl menu-link', { 'bg-blue-normal isActive': isActive })}>
                <>
                    {item.hasNotification && <CounterNotification amount={notificationCount} />}
                    {item.icon(isActive)}
                </>
            </div>
            <div className={classNames('font-bold hidden menu-name', { 'text-blue-normal': isActive })}>{item.name}</div>
            <div className='text-white text-center py-0.5 px-1.5 leading-none capitalize -mt-0.5 items-center justify-center hidden lm-key-name'>{item.name == 'Exams' ? item.name.charAt(1) : item.name.charAt(0)}</div>
        </div>
    </div>
)

export default LeftMenuItem;
