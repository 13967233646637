import React, {memo, useEffect, useRef, useState} from 'react'
import * as Api from 'api';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addPendingSVGAction, removePendingSVGAction } from 'actions/studying';

type MathjaxFormulaProps = {
    children?: any;
}  

const MathjaxFormula: React.FC<MathjaxFormulaProps> = ({children}) => {
    const ref = useRef<any>();
    useEffect(() =>{
        // @ts-ignore
        window.MathJax.Hub.Queue(['Typeset',  window.MathJax.Hub]);
    },[])
    return <span className='mathjax-formula' ref={ref}>{children}</span>;
}

export default memo(MathjaxFormula);