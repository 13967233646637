import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {cardsTitleListbyType} from 'selectors';
import {
    carouselFlashcardIndex
  } from 'selectors/carouselSelectors';
import {getActiveMediaTab} from 'selectors/navigation/navigationSelector'
import { addBackFlashcardToLocalStorage, filterResultsListToArray} from 'helpers';
import {listOfFilterCheckedItems} from 'selectors/desk/deskSelector'
import {currentFlashcardId} from 'selectors/studying'
import { getFlashcardById } from 'selectors'
import {TriangleLeft, TriangleRight} from 'icons/FlashcardIcons';
import {setActiveMediaTab} from 'actions/navigation/navigationActions'
import {selectFlashcard} from 'actions/studying'
import './mediaBottomButton.scss'
import usePrevious from 'hooks/usePrevious';

type MediaBottomButtonProps = {
    id: string;
    mediaType: string;
    hasNext: boolean;
    hasPrev: boolean;
}

const MediaBottomButton: React.FC<MediaBottomButtonProps> =  ({id, mediaType, hasNext, hasPrev}) => {
    const dispatch = useDispatch()
    const [prevTitles, setPrevTitles] = useState<any>([]); 
    const [nextTitles, setNextTitles] = useState<any>([]); 
    const carouselIndex = useSelector(carouselFlashcardIndex)
    const titleListByType = useSelector(cardsTitleListbyType)
    const activeMedia = useSelector(getActiveMediaTab);
    const filterCheckedItems = useSelector(listOfFilterCheckedItems)?.toJS();
    const currentId = useSelector(currentFlashcardId)
    const content = useSelector((state) => getFlashcardById(state, currentId))
    const prevCarouseIndex = usePrevious(carouselIndex);
    const prevFilterCheckedItems = usePrevious(filterCheckedItems);
    const prevTitleListByType = usePrevious(titleListByType);

    let filterItemsData =  filterCheckedItems[mediaType]
    let titles = typeof titleListByType?.get('data') !== 'string' && titleListByType?.get('data')?.get('items');
    let filterTitlesData= titles && filterResultsListToArray(titles, mediaType, filterItemsData )
    let currentNumber = content.get('numbering')

    useEffect(() => { 
        filterTitleData()
    },[]);

    useEffect(() => {
        if(carouselIndex != prevCarouseIndex || JSON.stringify(filterCheckedItems) != JSON.stringify(prevFilterCheckedItems) || titleListByType != prevTitleListByType) {
            filterTitleData()
        }
    },[carouselIndex, filterCheckedItems, titleListByType])

    const filterTitleData = () => {
        let indexx = titles?.size > 0 && titles?.filter((val: any)=>{
            return val.get("nodeId") === currentId
        })
        currentNumber = currentNumber ? currentNumber : titles?.size > 0 && indexx.getIn([0,"numbering"])

        let filteredPrevIds = filterTitlesData && filterTitlesData.size > 0 && filterTitlesData?.filter((val: any) => {
          if(currentNumber && val.get('numbering') < currentNumber){
            return val
          }
        })
        setPrevTitles(filteredPrevIds)

        let filteredNextIds = filterTitlesData && filterTitlesData.size > 0 && filterTitlesData?.filter((val: any) => {
            if(currentNumber && val.get('numbering') > currentNumber){
              return val
            }
        })
        setNextTitles(filteredNextIds)
    }

    const prevRevision = (type: string, prevId: number) => {
        addBackFlashcardToLocalStorage(null, null);
        if(activeMedia !== type){
            dispatch(setActiveMediaTab(type));
        }
        dispatch(selectFlashcard(prevId, 'default'));
    }

    const nextRevision = (type: string, nextId: number) => {
        addBackFlashcardToLocalStorage(null, null);   
        if(activeMedia !== type){
            dispatch(setActiveMediaTab(type));
        }
        dispatch(selectFlashcard(nextId, 'default'));
    }

    let prevNumber = prevTitles?.size > 0 && prevTitles?.getIn([prevTitles?.size-1])?.get('numbering') ;
    let prevCardId = prevTitles?.size > 0 && prevTitles?.getIn([prevTitles.size-1])?.get('nodeId') ;
    
      
    let nextNumber = nextTitles?.size > 0 &&nextTitles?.getIn([0])?.get('numbering')
    let nextCardId = nextTitles?.size > 0 &&nextTitles?.getIn([0])?.get('nodeId')
    

    useEffect(() => {
        if(mediaType === "video" || mediaType === "revision"){
            document.body.onkeyup = function(e: any){                
                if(e.key === 'ArrowRight'){
                    if (['INPUT', 'TEXTAREA'].indexOf(e.target.nodeName) !== -1) return;
                    nextRevision(mediaType, nextCardId) 
                }
                if(e.key === 'ArrowLeft'){
                    if (['INPUT', 'TEXTAREA'].indexOf(e.target.nodeName) !== -1) return;
                    prevRevision(mediaType, prevCardId)    
                }
            }
        }
    });

    return <>
        <div className='pr-2'>
            <div className={classNames('prev-btn', {'visible' : (hasPrev && prevNumber)}, {'invisible' : !(hasPrev && prevNumber)})} onClick={() => prevRevision(mediaType, prevCardId)}>
                <TriangleLeft />
            </div>
        </div>
        <div className='pl-2'>
            {(hasNext && nextNumber) &&
                <div className={classNames('next-btn')} onClick={() => nextRevision(mediaType, nextCardId)}>
                    <TriangleRight />
                </div>
            }
        </div>
    </>;
};

export default MediaBottomButton;
