import React from 'react';
import Step1 from 'assets/images/onboarding/1-Welcome.png';
import Step2 from 'assets/images/onboarding/2-TheContent.png';
import Step3 from 'assets/images/onboarding/3-OurExams.png';
import Step4 from 'assets/images/onboarding/4-SubjectsandOSCHome.png';
import FCAssessment from 'assets/images/onboarding/FC-ConfidenceAssessment.svg';
import Subject from 'assets/images/onboarding/Subject.svg';
import PageStatus from 'assets/images/onboarding/RG-PageStatus.svg';
import VideoStatus from 'assets/images/onboarding/V-VideoStatus.svg';

type TextProps = {
    title: string
}   
const Text: React.FC<TextProps> = ({title}) => {
    return <span className="onboarding-subtitle-text" dangerouslySetInnerHTML={{__html: title}} ></span>;
}

export const onboardingSteps = [
    {
        image: Step1,
        title: 'Welcome!',
        logo: false,
        new: false,
        subtitle: <Text title="We're so glad you joined us 👋!" />,
        subtitle2: <Text title="OSC Study was designed by IB alumni and teachers, and we want you to study in away that best suits you 👆🏽." />,
        button: null
      },
      {
        image: Step2,
        title: 'The Content',
        logo: false,
        new: false,
        subtitle: <Text title="Whether you prefer flashcards, revision guides, videos or exams, all four media types are mapped along the official IB syllabus 📘." />,
        subtitle2: <Text title="So once you browse any topic of a subject, the choice is yours 😎!" />,
        button: null
      },
      {
        image: Step3,
        title: 'Subjects and OSC Home',
        logo: false,
        new: false,
        subtitle: <> 
        <Text title="The top header lets you change subjects and set your teaching level. And with OSC Home, there is so much more to discover 🔎." />
        <div className='flex flex-col mt-4'>
          <div className='flex items-center justify-center'>
            <div className='text-right'><img src={Subject} alt='img' /> </div>
          </div>
        </div>
        </>,
        button: null
      },
]

