import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile, isIPad, getActiveSubject, getActiveMediaTab, getListOfFilterItemsIds, listOfFilterCheckedItems, getExerciseOnCanvas, exercisePreviewsResponse, subjectsTeachingLevel, currentFlashcardId, getFlashcardById } from 'selectors';
import { getLastMediaTypeVisited, isTablet, addBackFlashcardToLocalStorage, filterResultsListToArrayByTOJS } from 'helpers';
import { filterResultsListToArray } from 'v2/helpers'
import {
    carouselFlashcardIndex
} from 'selectors/carouselSelectors';
import SearchNavigationContainer from 'v2/components/studying/SearchNavigation/SearchNavigationContainer';
import { setFixedZoomFactor, selectFlashcard, nextSide } from 'actions/studying'
import { setActiveMediaTab } from 'actions/navigation'
import {
    Next5Button,
    NextButton,
    Prev5Button,
    PrevButton
} from 'v2/components/Button/NavigationButton';
import {MobilePrevButton, MobileNextButton, MobileFlipButton} from 'icons/FlashcardIcons';
import { getUserId } from 'selectors/user';
import { windowHeight } from 'selectors/browser/browserSelector'
import { useLocation } from 'react-router-dom';
import { teachingFilterResultsListToArray } from 'v2/helpers';


type FlashcardBottomControlsContainerProps = {
    flipStatus: any;
    isIPadLandscape: boolean;
    isLandscape: boolean;
    setFlashcardBottomControlsRef: any;
}

const FlashcardBottomControlsContainer = React.forwardRef<HTMLDivElement, FlashcardBottomControlsContainerProps>(({ flipStatus, isIPadLandscape, isLandscape, setFlashcardBottomControlsRef }) => {
    const dispatch = useDispatch()
    const hasMobile = useSelector(isMobile);
    const hasIpad = useSelector(isIPad);
    const userId = useSelector(getUserId);
    const isTablets = isTablet();
    const mediaType = getLastMediaTypeVisited(userId)
    const appHistory = useLocation();
    const [prevFlashcardIds, setPrevFlashcardIds] = useState<any>([]);
    const [nextFlashcardIds, setNextFlashcardIds] = useState<any>([]);
    const [prevExerciseFlashcardIds, setPrevExerciseFlashcardIds] = useState<any>([]);
    const [nextExerciseFlashcardIds, setNextExerciseFlashcardIds] = useState<any>([]);
    const listOfFilterCheckedItemData = useSelector(listOfFilterCheckedItems);
    const listOfFilterItemData = useSelector(getListOfFilterItemsIds);
    const flashcardId = useSelector(currentFlashcardId);
    const subjectsTL = useSelector(subjectsTeachingLevel)
    const flashcardIndex = useSelector(carouselFlashcardIndex);
    const flashcardContent = useSelector((state) => getFlashcardById(state, flashcardId));
    const exerciseOnCanvas = useSelector(getExerciseOnCanvas);
    const activeSubject = useSelector(getActiveSubject);
    const exercisePreviewsData = useSelector(exercisePreviewsResponse);
    const activeMediaTab = useSelector(getActiveMediaTab);
    const wHeight = useSelector(windowHeight);

    let prevFlashcardId: number | string;
    let nextFlashcardId: number | string;
    let prevFifthFlashcardId: number | string;
    let nextFifthFlashcardId: number | string;
    let ExerciseIds: any;
    let filteredExerciseIds: any;
    let FilterCheckedItems = listOfFilterCheckedItemData.toJS()['flashcard']
    let flashcardIds = listOfFilterItemData.get('flashcard')
    let filteredIds = filterResultsListToArrayByTOJS(flashcardIds, 'flashcard', FilterCheckedItems)
    let currentNumber = flashcardContent.get('numbering')
    const prevExerciseFlashcardIdsCount = useMemo(() =>
        prevExerciseFlashcardIds?.length ?? 0, [prevExerciseFlashcardIds]
    );
    const nextExerciseFlashcardIdsCount = useMemo(() =>
        nextExerciseFlashcardIds?.length ?? 0, [nextExerciseFlashcardIds]
    );

    const prevFlashcardIdsCount = useMemo(() =>
        prevFlashcardIds?.size ?? 0, [prevFlashcardIds]
    );
    const nextFlashcardIdsCount = useMemo(() =>
        nextFlashcardIds?.size ?? 0, [nextFlashcardIds]
    );

    if (exerciseOnCanvas) {
        const pathname = appHistory.pathname;
        const teachingTag = subjectsTL[`${activeSubject?.id}`];
        let exerciseId = pathname.split('/')[4]
        ExerciseIds = exercisePreviewsData?.getIn([Number(exerciseId), 'data'])
        ExerciseIds = ExerciseIds && teachingFilterResultsListToArray(ExerciseIds.toJS(), teachingTag, activeSubject)
        filteredExerciseIds = ExerciseIds && filterResultsListToArray(ExerciseIds, 'flashcard', FilterCheckedItems)
    }
    const exerciseFilterData = () => {
        let indexx = ExerciseIds?.length > 0 && ExerciseIds?.filter((val: any) => {
            return val.flashcardId === flashcardId
        })
        currentNumber = currentNumber ? currentNumber : ExerciseIds && ExerciseIds.length > 0 && indexx?.[0]?.numbering
        let filteredPrevIds = filteredExerciseIds?.length > 0 && filteredExerciseIds.filter((val: any) => {
            if (currentNumber && val?.numbering < Number(currentNumber)) {
                return val
            }
        })
        setPrevExerciseFlashcardIds(filteredPrevIds)
        let filteredNextIds = filteredExerciseIds?.length > 0 && filteredExerciseIds.filter((val: any) => {
            if (currentNumber && val?.numbering > Number(currentNumber)) {
                return val
            }
        })
        setNextExerciseFlashcardIds(filteredNextIds)
    }
    const filterData = () => {
        currentNumber = currentNumber ? currentNumber : flashcardIds?.size > 0 && flashcardIds?.getIn([flashcardIndex]).numbering
        let filteredPrevIds = filteredIds?.size > 0 && filteredIds?.filter((val: any) => {
            if (currentNumber && val.numbering < currentNumber) {
                return val
            }
        })
        setPrevFlashcardIds(filteredPrevIds)
        let filteredNextIds = filteredIds?.size > 0 && filteredIds?.filter((val: any) => {
            if (currentNumber && val.numbering > currentNumber) {
                return val
            }
        })
        setNextFlashcardIds(filteredNextIds)
    }
    useEffect(() => {
        if (exerciseOnCanvas) {
            exerciseFilterData()
        } else {
            filterData()
        }
    }, [exerciseOnCanvas])

    useEffect(() => {
        const mediaType = getLastMediaTypeVisited(userId)
        if (!hasMobile) {
            dispatch(setFixedZoomFactor(1.2));
        }
        document.body.onkeyup = function (e: any) {
            if (e.key == 'ArrowRight') {
                if (['INPUT', 'TEXTAREA'].indexOf(e?.target?.nodeName) !== -1) return;
                nextFlashcard(mediaType, nextFlashcardId)
            }
            if (e.key == 'ArrowLeft') {
                if (['INPUT', 'TEXTAREA'].indexOf(e?.target?.nodeName) !== -1) return;
                prevFlashcard(mediaType, prevFlashcardId)
            }
        }
    });

    useEffect(() => {
        if (exerciseOnCanvas) {
            exerciseFilterData()
        } else {
            filterData()
        }
    }, [flashcardIndex, listOfFilterCheckedItemData, exerciseOnCanvas, listOfFilterItemData, exercisePreviewsData])

    const canFlip = () => { 
        if(flashcardContent.getIn(['outsideTemplate', 'sides'])){
          return flashcardContent?.size && flashcardContent.getIn(['outsideTemplate', 'sides']).size > 1;
        }
    }

    const nextFlashcard = (type: string, id: string | number) => {
        dispatch(selectFlashcard(id, 'default'));
        addBackFlashcardToLocalStorage(null, null);
        if (activeMediaTab !== type) {
            dispatch(setActiveMediaTab(type));
        }
    }

    const prevFlashcard = (type: string, id: string | number) => {
        dispatch(selectFlashcard(id, 'default'));
        addBackFlashcardToLocalStorage(null, null);
        if (activeMediaTab !== type) {
            dispatch(setActiveMediaTab(type));
        }
    }
    const prev5Flashcard = (type: string, id: string | number) => {
        dispatch(selectFlashcard(id, 'default'));
        addBackFlashcardToLocalStorage(null, null);
        if (activeMediaTab !== type) {
            dispatch(setActiveMediaTab(type));
        }
    };

    const next5Flashcard = (type: string, id: string | number) => {
        dispatch(selectFlashcard(id, 'default'));
        addBackFlashcardToLocalStorage(null, null);
        if (activeMediaTab !== type) {
            dispatch(setActiveMediaTab(type));
        }
    };
    

    const isButton5Visible = (nav: string) => {
        if(nav==='prev'){
            return prevFlashcardIds?.size > 4 && prevFlashcardIds?.getIn([prevFlashcardIds.size-5])?.flashcardId && wHeight < 820 && !hasMobile;
        }
        else if(nav==='next'){
            return nextFlashcardIds?.size > 4 && nextFlashcardIds?.getIn([4])?.flashcardId && wHeight < 820 && !hasMobile;
        }
    }
    if (exerciseOnCanvas) {
        prevFlashcardId = prevExerciseFlashcardIdsCount > 0 && prevExerciseFlashcardIds?.[prevExerciseFlashcardIdsCount - 1]?.flashcardId
        nextFlashcardId = nextExerciseFlashcardIdsCount > 0 && nextExerciseFlashcardIds?.[0]?.flashcardId
        prevFifthFlashcardId = prevExerciseFlashcardIdsCount > 4 && prevExerciseFlashcardIds?.[prevExerciseFlashcardIds.size - 5]?.flashcardId
        nextFifthFlashcardId = nextExerciseFlashcardIdsCount > 4 && nextExerciseFlashcardIds?.[4]?.flashcardId
    } else {
        prevFlashcardId = prevFlashcardIdsCount > 0 && prevFlashcardIds?.getIn([prevFlashcardIdsCount - 1]).flashcardId
        nextFlashcardId = nextFlashcardIdsCount > 0 && nextFlashcardIds?.getIn([0]).flashcardId
        prevFifthFlashcardId = prevFlashcardIdsCount > 4 && prevFlashcardIds.getIn([prevFlashcardIdsCount - 5]).flashcardId
        nextFifthFlashcardId = nextFlashcardIdsCount > 4 && nextFlashcardIds?.getIn([4]).flashcardId
    }
    const shouldShowPrevNextBtn = useMemo(() => isIPadLandscape && isTablets, [isIPadLandscape, isTablets])
    return (
        <>
            {!isLandscape &&
                (<div className={'flashcar-bottom-control'} ref={setFlashcardBottomControlsRef}>
                    <div className='flex items-center justify-between w-full mt-6'>
                        <div className={classNames('pre-next-button', {'invisible' : !prevFlashcardId }, {'visible' : prevFlashcardId })}>
                            
                            {isButton5Visible('prev') && <div className={classNames({'invisible' : !prevFifthFlashcardId, 'visible' : prevFifthFlashcardId}, {'pre-next-button' : !shouldShowPrevNextBtn, 'landscape-pre-next-button' : shouldShowPrevNextBtn})}>
                                <Prev5Button onClick={() => prev5Flashcard(mediaType, prevFifthFlashcardId)} />
                            </div>}

                            <PrevButton onClick={() => prevFlashcard(mediaType, prevFlashcardId)} className={`prev-btn`} />
                        </div> 
                        <div className={classNames('show-answer-button', {'px-3.5' : !hasMobile }, {'px-0' : hasMobile })}>
                            <div className={classNames({'invisible' : !canFlip(), 'visible' : canFlip()})}  >
                                <div onClick={() => { dispatch(nextSide()) }} className={`flashcard-answer-btn flip-btn`}>{(!flipStatus) ? `SHOW ANSWER ${(!hasMobile || hasIpad) ? 'SIDE' : ''}` : `SHOW QUESTION ${!hasMobile ? 'SIDE' : ''}`}</div>
                            </div>
                        </div>
                        <div className={classNames('pre-next-button', {'invisible' : !nextFlashcardId },    {'visible' : nextFlashcardId })} > 
                            <NextButton onClick={() => nextFlashcard(mediaType, nextFlashcardId)} className={`next-btn`} />
                            {isButton5Visible('next') && <div className={classNames({'invisible' : !nextFifthFlashcardId, 'visible' : nextFifthFlashcardId}, {'pre-next-button' : !shouldShowPrevNextBtn, 'landscape-pre-next-button' : shouldShowPrevNextBtn})}>
                                <Next5Button onClick={() => next5Flashcard(mediaType, nextFifthFlashcardId)} />
                            </div> }
                        </div>
                    </div>
                    <div className={classNames('flex items-center justify-between w-full search-navigation', { '-mt-47px': isIPadLandscape && isTablets }, { 'mt-5px': !(isIPadLandscape && isTablets) })}>
                        {!isButton5Visible('prev') && <div className={classNames('hidden-btn',{'invisible' : !prevFifthFlashcardId, 'visible' : prevFifthFlashcardId}, {'pre-next-button' : !shouldShowPrevNextBtn, 'landscape-pre-next-button' : shouldShowPrevNextBtn})}>
                            <Prev5Button onClick={() => prev5Flashcard(mediaType, prevFifthFlashcardId)} />
                        </div>}
                        <div className='flex items-center justify-center'>
                            <div className='inset-x-0 bottom-full search-section'>
                                <div className="flex justify-center">
                                   <SearchNavigationContainer isMobile={hasMobile} isLandscape={isLandscape}/>
                                </div>
                            </div>
                        </div>

                        {!isButton5Visible('next') && <div className={classNames({'invisible' : !nextFifthFlashcardId, 'visible' : nextFifthFlashcardId}, {'pre-next-button' : !shouldShowPrevNextBtn, 'landscape-pre-next-button' : shouldShowPrevNextBtn})}>
                            <Next5Button onClick={() => next5Flashcard(mediaType, nextFifthFlashcardId)} />
                        </div> }
                    </div>

                    <div className='inset-x-0 bottom-full search-section-small' >
                        <div className="flex justify-center">
                            <SearchNavigationContainer isSmallDevice={true} isMobile={hasMobile} isLandscape={isLandscape}/>
                        </div>
                    </div>
                </div>)}
                {isLandscape &&(
                    <>
                        <div className='landscape-buttons-container' ref={setFlashcardBottomControlsRef}>
                            <div className='landscape-flashcard-button-control left'>
                                {prevFlashcardId ? (
                                <div className='prev-flashcard-button landscape-flashcard-nav-button tutorial-step-flashcard prev-btn'
                                onClick={()=>prevFlashcard(mediaType, prevFlashcardId)}>
                                    <MobilePrevButton/>
                                </div>
                                ) : (null)}
                            </div>
                            
                            <div className='landscape-flashcard-button-control right'>
                                {nextFlashcardId ? (
                                <div className='next-flashcard-button landscape-flashcard-nav-button next-btn' onClick={()=>nextFlashcard(mediaType, nextFlashcardId)}>
                                    <MobileNextButton/>
                                </div>
                                ) : null}
                            
                            </div>
                            {(canFlip())
                                ? <div className='switch-side-flashcard-button landscape-flashcard-nav-button flip-btn' onClick={() => dispatch(nextSide())}>
                                    <MobileFlipButton/>
                                </div>
                                : null
                                }
                            <div className='absolute bottom-1 z-10 inset-x-0 search-section-small'>
                                <SearchNavigationContainer isLandscapeMode={true} isMobile={hasMobile} isLandscape={isLandscape}/>
                            </div>
                        </div>
                        
                        </>
                    )   
                }

        </>
    )
})

export default FlashcardBottomControlsContainer;