import { useMemo } from "react";

const TOPIC = "TOPIC ";

enum tltagging {
  ALPHA = "ALPHA",
  CUSTOM = "CUSTOM",
  CUSTOM_TOPIC_SUBTOPIC = "CUSTOM_TOPIC_SUBTOPIC",
  CUSTOM_TOPIC_CUSTOM_SUBTOPIC = "CUSTOM_TOPIC_CUSTOM_SUBTOPIC",
}

const customNumberFormat = (customNumber: string): string => {
  const trimmedCustomNumber = customNumber.trim();
  let formattedCustomNumber = trimmedCustomNumber.at(0)!;
  const splittedCustomNumbering = trimmedCustomNumber.split(" ");
  if (splittedCustomNumbering.length > 1) {
    formattedCustomNumber += splittedCustomNumbering[1].at(0);
  }
  return formattedCustomNumber;
};

const useTopicNumber = (data: any): string => {
  const topicNumber = useMemo(() => {
    if (data?.tagging === tltagging.ALPHA) {
      return TOPIC + String.fromCharCode(parseInt(data?.label) + 64);
    }
    if (
      [
        tltagging.CUSTOM,
        tltagging.CUSTOM_TOPIC_SUBTOPIC,
        tltagging.CUSTOM_TOPIC_CUSTOM_SUBTOPIC,
      ].includes(data?.tagging) &&
      ![null, "", "null"].includes(data?.customTopicNumbering)
    ) {
      return data?.customTopicNumbering;
    }
    return TOPIC + data?.label;
  }, [data?.customTopicNumbering, data?.label, data?.tagging]);
  return topicNumber;
};

const useSubtopicNumber = (data: any): string => {
  const topicNumber = useMemo(() => {
    if (data?.tltagging === tltagging.ALPHA) {
      return (
        String.fromCharCode(parseInt(data?.topicIndex) + 64) +
        "." +
        data?.subTopicIndex
      );
    }
    if (
      data?.tltagging === tltagging.CUSTOM &&
      ![null, "", "null"].includes(data?.customTopicNumbering)
    ) {
      const trimmedCustomTopicNumbering = data?.customTopicNumbering?.trim();
      let subtopicTitleFirstChar = trimmedCustomTopicNumbering?.at(0);
      const splittedCustomTopicNumbering =
        trimmedCustomTopicNumbering.split(" ");
      if (splittedCustomTopicNumbering?.length > 1) {
        subtopicTitleFirstChar += splittedCustomTopicNumbering[1]?.at(0);
      }
      return subtopicTitleFirstChar + "." + data?.subTopicIndex;
    }
    if (data?.tltagging === tltagging.CUSTOM_TOPIC_SUBTOPIC) {
      const topicTitleFirstChar = !!data?.customTopicNumbering
        ? customNumberFormat(data.customTopicNumbering)
        : data?.topicIndex;
      const subTopicTitleFirstChar = !!data?.customSubTopicNumbering
        ? customNumberFormat(data.customSubTopicNumbering)
        : data?.subTopicIndex;
      return topicTitleFirstChar + "." + subTopicTitleFirstChar;
    }
    if (data?.tltagging === tltagging.CUSTOM_TOPIC_CUSTOM_SUBTOPIC) {
      return data?.customSubTopicNumbering || data?.label;
    }
    return data?.label;
  }, [
    data?.customTopicNumbering,
    data?.label,
    data?.subTopicIndex,
    data?.tltagging,
    data?.topicIndex,
    data?.customSubTopicNumbering,
  ]);
  return topicNumber;
};

export { useTopicNumber, useSubtopicNumber };
