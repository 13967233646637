import classNames from 'classnames';
import React from 'react';
import {fontSize} from './font-size'
import {fontWeight} from './font-weights'

type TextProps = {
    children?: string | number | React.ReactElement | JSX.Element|JSX.Element[]; 
    size?: fontSize;
    weight?: fontWeight;
    className?: string,
    onClick?: () => void
    dangerouslySetInnerHTML?: string
}  

const Text: React.FC<TextProps> = ({children, className, onClick, dangerouslySetInnerHTML}) => {
    
    return (
        <div className={classNames(className)} onClick={onClick} {...(dangerouslySetInnerHTML && {dangerouslySetInnerHTML:{__html:dangerouslySetInnerHTML}})}>
            {children}
        </div>
    )
}

export default Text;