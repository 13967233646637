import React from 'react';
import SearchIcon from 'assets/images/search/search-icon.svg';
import NoResultsMathsIcon from 'assets/images/search/no-results-mathematics-icon.svg';

const NoResults = () => <div className='pt-24 flex flex-col items-center'>
  <img src={SearchIcon} alt="" />
  <div className='pt-15px font-bold text-[25px] text-blue-normal leading-[1.3]'>
    No results found!
  </div>
</div>

export default NoResults

export const NoResultsMaths = () => <div className='pt-24 px-25px flex flex-col items-center'>
  <img src={NoResultsMathsIcon} alt=""/>
  <div className='pt-3 text-center font-bold text-15px text-#A2A3A3'>Coming soon!</div>
  <div className='pt-7px text-center text-15px text-#B1B6B5 leading-5'>We're working hard to make available the cards search for the mathematics courses.</div>
</div>