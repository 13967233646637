import React, { useMemo } from 'react';
import checkboxIcon from 'assets/icons/checkbox.svg';
import classNames from 'classnames';

type CheckboxContainerProps = {
  isActive: any,
  isPartial: any,
  className?: string
}

const CheckboxContainer: React.FC<CheckboxContainerProps> = ({ isActive, isPartial, className }) => {

  const renderBox = useMemo(() => {
    let checkbox = <DefaultCheckbox />;

    if (isActive) {
      checkbox = <ActiveCheckbox />
    }
    if (isPartial) {
      checkbox = <PartialCheckbox />;
    }
    return checkbox
  }, [isPartial, isActive])
  return <div className={classNames('justify-center items-center flex cursor-pointer h-[34px] w-[34px]', className)}>
    {renderBox}
  </div>
}


export default CheckboxContainer

const DefaultCheckbox = () => {
  return <div className='default-checkbox'></div>
}

const ActiveCheckbox = () => {
  return <div className='flex justify-center items-center '>
    <div className='active-checkbox default-checkbox'>
      <div className='h-full flex items-center justify-center'>
      <img src={checkboxIcon} alt="" />
      </div>
    </div>
  </div>
}

const PartialCheckbox = () => {
  return <div className='default-checkbox partial-checkbox'></div>
}