import {SET_EXAM_NODE_ANSWERS } from 'actions/answers/answersActionTypes';
import {fromJS, Map} from 'immutable';

export default (state = Map(), action) => {
  switch (action.type) {
    case SET_EXAM_NODE_ANSWERS:
      return state.withMutations(state => {
        action.data.forEach(node => {
          if (node.total >= 0) {
            //all nodes but previews
            state.set(node.id, fromJS({
                correct: node.correct,
                answered: node.answered
              }));
          } else {
            //previews
            state.set(node.id, fromJS({
              correct: node.correct,
              answered: node.answered
            }));
          }
        });
        return state;
      });
    default:
      return state;
  }
};
