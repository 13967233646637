import React from 'react'
import Avatar from 'assets/images/Avatar.png';
import AvatarCelebrate from 'assets/images/AvatarCelebrate.png';
import AvatarFantastic from 'assets/images/AvatarFantastic.png';
import AvatarNerdy from 'assets/images/AvatarNerdy.png';
import AvatarPolice from 'assets/images/AvatarPolice.png';
import AvatarRotate from 'assets/images/AvatarRotate.png';
import AvatarSmile from 'assets/images/AvatarSmile.png';
import AvatarWink from 'assets/images/AvatarWink.png';
import AvatarWow from 'assets/images/AvatarWow.png';

type SpeakingAvatarComponentProps = {
    showAvatar: string;
    children?: React.ReactElement
}  

const SpeakingAvatarComponent: React.FC<SpeakingAvatarComponentProps> = ({showAvatar, children}) => {
    return <div>
        {showAvatar === 'wow' ? <img className='h-[86px] min-h-[86px]' src={AvatarWow} alt="wow"/> : ''}
        {showAvatar === 'normal' ? <img className='h-[86px] min-h-[86px]' src={Avatar} alt="normal"/> : ''}
        {showAvatar === 'smile' ? <img className='h-[86px] min-h-[86px]' src={AvatarSmile} alt="smile"/> : ''}
        {showAvatar === 'fantastic' ? <img className='h-[86px] min-h-[86px]' src={AvatarFantastic} alt="fantastic"/> : ''}
        {showAvatar === 'wink' ? <img className='h-[86px] min-h-[86px]' src={AvatarWink} alt="wink"/> : ''}
        {showAvatar === 'nerdy' ? <img className='h-[86px] min-h-[86px]' src={AvatarNerdy} alt="nerdy"/> : ''}
        {showAvatar === 'celebrate' ? <img className='h-[86px] min-h-[86px]' src={AvatarCelebrate} alt="celebrate"/> : ''}
        {showAvatar === 'rotate' ? <img className='h-[86px] min-h-[86px]' src={AvatarRotate} alt="rotate"/> : ''}
        {showAvatar === 'police' ? <img className='h-[86px] min-h-[86px]' src={AvatarPolice} alt="police"/> : ''}
        {children}
    </div>
}

export default SpeakingAvatarComponent;