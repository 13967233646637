import Immutable from 'immutable';
import {createSelector} from 'reselect';
import {exerciseDetailsResponse} from 'selectors/api/apiSelector';

export const studyingShowOverlay = (state) => state.getIn(['studying', 'showOverlay'], false);

export const toolTipProps = (state) => state.getIn(['studying', 'toolTipProps'], Immutable.Map());

export const selectedSide = (state, flashcardId = currentFlashcardId(state)) => state.getIn(['studying', 'flashcards', parseInt(flashcardId), 'selectedSide'], null);
export const selectedSide2 = (state) => {
  const flashcardId = currentFlashcardId(state);
  return state.getIn(['studying', 'flashcards', parseInt(flashcardId), 'selectedSide'], null);
}
export const flashcardFlipStatus = (state) => {
  const currentSelectedSide = selectedSide2(state);
  return currentSelectedSide%2 === 0 ? true : false;
}

export const zoomFactor = (state) => state.getIn(['studying', 'zoomFactor'], 1);

export const getLinks = (state) => state.getIn(['studying', 'links'], Immutable.Map());

export const swap = (state) => state.getIn(['studying', 'activeTools', 'swap']);

export const activeTools = (state) => state.getIn(['studying', 'activeTools']);

export const currentFlashcardId = (state) => state.getIn(['studying', 'currentFlashcardId']);

/** @deprecated
 * @see carouselFlashcardIds from carouselSelectors.js
 */
export const flashcardIds = (state) => state.getIn(['studying', 'flashcardIds']);

/** @deprecated
 * @see carouselRendererIds from carouselSelectors.js
 */
export const rendererIds = (state) => state.getIn(['studying', 'rendererIds'], null);

export const getFlashcardById = (state, flashcardId) => state.getIn(['studying', 'flashcards', flashcardId], Immutable.Map());

/** @deprecated
 * @see carouselFlashcardIndex from carouselSelectors.js
 */
export const flashcardIndex = state => state.getIn(['studying', 'flashcardIndex']);

/** @deprecated
 * @see carouselRefIndex from carouselSelectors.js
 */
export const carouselRefIndex = state => state.getIn(['studying', 'carouselRefIndex']);

export const isDeviceRestricted = state => state.getIn(['studying', 'deviceRestricted']);

/** @deprecated
 * @see carouselCurrentFlashcard from carouselSelectors.js
 */
export const currentFlashcard = (state) => getFlashcardById(state, currentFlashcardId(state));

export const hasPrevFlashcard = (state) => flashcardIndex(state) > 0;
export const hasNextFlashcard = (state) => {
  const ids = flashcardIds(state);
  return ids && flashcardIndex(state) < ids.size - 1;
};

export const hasFlashcard = state => !!currentFlashcardId(state);
export const navigationMode = state => state.getIn(['studying', 'navigationMode'], 'default');
export const toolboxOpenSection = state => state.getIn(['studying', 'toolboxOpenSection'], null);

export const canToggleMenu = state => hasFlashcard(state) || navigationMode(state) === 'select';

export const activeFlashcardId = state => state.getIn(['studying', 'activeFlashcardId']);
export const scrollToFlashcardId = state => state.getIn(['studying', 'scrollToFlashcardId']);
export const activeExerciseId = state => state.getIn(['studying', 'activeExerciseId'], null);

export const activeExercise = createSelector(
  [exerciseDetailsResponse, activeExerciseId],
  (response, activeId) => {
    return response?.[activeId]?.data ?? null;
  }
);
export const carouselTeachingLevelSelector = state => state.getIn(['studying', 'carouselTeachingLevel']);
export const pendingSVGsSelector = state => state.getIn(['studying', 'pendingSVGs']);
export const getLastStudyFlashcardSelector = state => state.getIn(['studying', 'getStudyLastFlashcard']);
export const inProgressLastStudyFlashcardSelector = state => state.getIn(['studying', 'inProgressLastStudyFlashcard'])

export const canvasZoomFactor = state => state.getIn(['studying', 'canvasZoomFactor']);

export const getLinkSize = (state) => state.getIn(['studying', 'linksSize']);
export const currentExerciseId = state => state.getIn(['studying', 'currentExerciseId'], {});

export const getActiveExerciseId = state => state.getIn(['studying', 'setExerciseId']);

export const getFlashcards = (state) => state.getIn(['studying', 'flashcards'], Immutable.Map());
