import classNames from 'classnames';
import './listFlashcardContainer.scss'
import React from 'react'
import { useSelector } from 'react-redux';
import { answerById } from 'selectors/answersSelectors';
import { getFlashcardById } from 'selectors/studying';
import RendererContainer from 'v2/components/studying/renderer/RendererContainer';
import Checkbox from 'v2/ui-components/Checkbox';
import AnswerBulletContainer from 'components/topics-tree/AnswerBulletContainer';

type ListFlashcardContainerProps = {
  id: number;
  isActive: boolean;
  parentClick: (argA: number, argB: boolean, argC: any) => void;
  canEditExercise: boolean;
  isIframe: boolean;
}

const ListFlashcardContainer: React.FC<ListFlashcardContainerProps> = ({ id, isActive, canEditExercise, isIframe, parentClick }) => {
  const flashcard = useSelector(state => getFlashcardById(state, id));
  const answer = useSelector(state => answerById(state, id));
  const parentsData = flashcard.get('parentsData');
  let answerSize = answer.size === 0 ? "false" : answer.get('answered');
  let parentIds = parentsData && parentsData.map((item: any) => item.get('id')).toJS().reverse();

  return <div className={classNames('card-wrapper max-w-[600px] transition-shadow duration-150 ease-linear relative my-0 mx-auto', { 'active': isActive })}>
    <RendererContainer 
      responsive 
      contentDelay={400}
      flashcardId={id}
      canEditExercise={canEditExercise}
      isActiveZoom={isActive}
      parentClick={(canFlip: any) => parentClick(id, isActive, canFlip)} 
    />
    <div className='absolute top-0 right-0' ><Checkbox id={id} parentIds={parentIds} /></div>
    <div className={classNames('absolute left-0.5 top-0.5 flex items-center justify-center bg-[rgba(227,230,230,0.50)] rounded-[16px]', { 'pr-2.5': id, 'p-0': !id })}>
      <div className={classNames('flex items-center justify-center rounded-[16px] px-[13px] h-[33px] font-bold text-19px text-#2D3838 leading-normal', { 'pr-2 pl-[13px]': answerSize, 'px-[13px]': !answerSize })}>{flashcard.get('absoluteNumbering') || flashcard.get('numbering')}</div>
      {!isIframe && <AnswerBulletContainer id={id} type={"flashcard"} />}
    </div>
  </div>
}

export default ListFlashcardContainer