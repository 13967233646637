import React, {Component} from 'react';
import FolderPage from 'pages/desk/folder/FolderPage';
import {withScrollDetectionContained} from 'hocs/withScrollDetection';
import HeaderFolderContentTooltip from './HeaderFolderContentTooltip';
/**
 * @deprecated TODO- delete not use any more
 */
class MobileFolderContent extends Component {
  render() {
    return (
      <React.Fragment>
        <HeaderFolderContentTooltip
          changeRoute={(routeName) => this.props.changeRoute(routeName)}
          isCurrentFlashcardAdded={this.props.isCurrentFlashcardAdded}
          flashcardFolders={this.props.flashcardFolders}
          onAddClick={this.props.onAddClick}
          isSmart={this.props.isSmart}
          folderTitle={this.props.folderTitle}
          folderId={this.props.folderId}
          isCollapsed={this.props.isScrolled}
          currentFlashcardId={this.props.currentFlashcardId}/>
        <div className="folders-tooltip-container">
          <div className='folders-list-container'>
            <FolderPage innerRef={this.props.bindScrollRef} key={'tooltip'} inTooltip folderId={this.props.folderId}
              onListItemSelected={this.props.onListItemSelected}/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withScrollDetectionContained(MobileFolderContent);
