import {toggleNavigationSection} from 'actions/navigation';
import {scrollToFlashcardAction, setActiveFlashcardAction} from 'actions/studying';
import {getSelectedFlashcards} from 'actions/topics-tree/topicsTreeActions';
import TeachingLevelSelector from 'components/teaching-level-selector/TeachingLevelSelectorContainer';
import SelectedCardsButtonWrapper from 'components/topics-tree/SelectedCardsButtonWrapper';
import TopicsTreeContainer from 'components/topics-tree/TopicsTreeContainer';
import {withScrollDetectionContained} from 'hocs/withScrollDetection';
import {List} from 'immutable';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {mediaSize} from 'selectors/browser/browserSelector';
import {activeSubjectId, getActiveTeachingLevel} from 'selectors/subject/subjectSelector';
import {getSelectedTreeNodes} from 'selectors/topicsTreeSelector';
import styled, {css} from 'styled-components/macro';
import theme from 'theme';
import Checkbox from 'ui-components/Checkbox';
import media from 'ui-components/helpers/media';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';
import {FixedPageSpacing} from 'ui-components/pageComponents';

class MBCreateExercisePage extends PureComponent {
  constructor(props) {
    super(props);

    this.wrapperRef = null;
  }

  componentDidMount() {
    const {match, getSelectedFlashcards} = this.props;
    if (match && match.params && match.params.exerciseId) {
      getSelectedFlashcards(match.params.exerciseId);
    }
  }

  bindRef = (ref) => {
    this.wrapperRef = ref;
    this.props.bindScrollRef(ref);
  };

  //TODO topics tree should be extracted into it's own container
  render() {
    const {selectedTreeNodes} = this.props;
    const teachingLevelSelectorParentIds = this.props.activeSubjectId === this.props.activeTeachingLevelId ? [] : [this.props.activeSubjectId];

    return <React.Fragment>
      <FixedTop>
        <Shadow shadow={this.props.isScrolled}>
          <Padding top={10}>
            <Row alignItems={'center'}>
              <Padding left={6} right={8} bottom={8}>
                <Checkbox id={this.props.activeTeachingLevelId} parentIds={teachingLevelSelectorParentIds}/>
              </Padding>
              <MBHeaderExerciseTeachingLevelContainer onItemSelectedCallback={() => null}/>
            </Row>
          </Padding>
        </Shadow>
      </FixedTop>
      <FixedPageSpacing ref={this.bindRef}>
        <Padding left={14} right={14} top={14} bottom={selectedTreeNodes.size > 0 ? 46 : 14}>
          <TopicsTreeContainer
            onPreviewClick={this.onPreviewClick}
            hasSelection={true}
            subjectId={this.props.activeSubjectId}
            scrollTo={this.onTreeScrollTo}
            isExercise={true}
            isExernalExercise={true}
          />
        </Padding>
      </FixedPageSpacing>
      <SelectedCardsButtonWrapper isIframe={true}/>
    </React.Fragment>;
  }

  onTreeScrollTo = (element) => {
    const elementOffsetTop = this.wrapperRef.scrollTop + element.getBoundingClientRect().top;
    // Half of wrapper height to position element in center
    this.wrapperRef.scrollTop = elementOffsetTop - this.wrapperRef.offsetHeight / 2;
  };

  onPreviewClick = (flashcardId) => {
    this.props.setActiveFlashcard(flashcardId);
    this.props.scrollToFlashcardAction(flashcardId);

    if (this.props.isIframeMobile) {
      this.props.toggleNavigationSection(false);
    }
  };
}

const mapStateToProps = (state) => ({
  activeSubjectId: activeSubjectId(state),
  activeTeachingLevelId: getActiveTeachingLevel(state),
  selectedTreeNodes: getSelectedTreeNodes(state),
  isIframeMobile: mediaSize(state, theme.sizes.iFrameMobile),
});

const mapDispatchToProps = {
  setActiveFlashcard: setActiveFlashcardAction,
  scrollToFlashcardAction: scrollToFlashcardAction,
  toggleNavigationSection,
  getSelectedFlashcards: getSelectedFlashcards,
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(withScrollDetectionContained(MBCreateExercisePage));

MBCreateExercisePage.propTypes = {
  // WithScrollDetection
  bindScrollRef: PropTypes.func.isRequired,
  isScrolled: PropTypes.bool.isRequired,

  // Connect
  isIframeMobile: PropTypes.bool.isRequired,
  activeSubjectId: PropTypes.number.isRequired,
  activeTeachingLevelId: PropTypes.number,
  toggleNavigationSection: PropTypes.func.isRequired,
  selectedTreeNodes: PropTypes.instanceOf(List).isRequired,
  setActiveFlashcard: PropTypes.func.isRequired,
  scrollToFlashcardAction: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  getSelectedFlashcards: PropTypes.func.isRequired,
};

const FixedTop = styled.div`
  position: fixed;
  top:0; 
  left:0; 
  right: 0;
  max-width: 375px;
  z-index: 2;
  overflow: hidden;
  padding-bottom: 10px;
  
  ${media.iFrameMobile(css`
    max-width: 100%;
  `)}
`;

export const PreviewWrapper = styled.div`
  position: relative;
  
  &:after{
    content: '';
    position: absolute;
    bottom: -2px;
    left:0; 
    right:0;
    height: 0;
    margin: 0 20px;
    background: #F0F5F5;
    display: ${props => props.active ? 'none' : 'initial'};
    z-index: 1;
  }
  
  &:hover:after{
    display: none;
  }
`;

const Shadow = styled.div`
  background-color: white;
  transition: box-shadow .15s linear;
  // box-shadow: ${props => props.shadow ? '0 0.5px 5px 0 rgba(62, 87, 85, 0.22)' : 'none'};
  border-bottom: 1px solid rgb(225, 230, 230);
`;
const MBHeaderExerciseTeachingLevelContainer = styled(TeachingLevelSelector)`
  display: flex;
`;