import DiffficultyFilterOverlay from "v2/components/filter-overlay/DiffficultyFilter";
import EnableCookies from 'v2/components/filter-overlay/EnableCookies';
import HiddenInfo from "v2/components/filter-overlay/HiddenInfo";
// import MainMicroFilterOverlay from 'components/studying/examFilter/MainMicroFilterOverlay';
import MCQFilterOverlay from 'v2/components/filter-overlay/MCQFilter';
import PaperFilter from "v2/components/filter-overlay/PaperFilter";
import ProgressPopupFilter from 'v2/components/filter-overlay/ProgressPopupFilter';
import QuestionInfo from "v2/components/filter-overlay/QuestionInfo";
import SourceFilter from 'v2/components/filter-overlay/SourceFilter';
import TeachingLevelExams from 'v2/components/filter-overlay/TeachingLevelExamsFilter';
import MicroFilterOverlay from 'v2/components/studying/microFilter/MicroFilter';
import './filterOverlay.scss'
import {getActiveSubject, getFeature, isDiffficultyOverlayVisible, isEnableCookiesOverlay, isFilterOverlayVisible, isHiddenInfoOverlayVisible, isMainMicroFilterVisible, isMCQOverlayVisible, isPaperOverlayVisible, isProgressBarFilterVisible, isQuestionInfoOverlayVisible, isSourceOverlayVisible, isTeachingLevelExamsOverlayVisible, setMediaTypeFilter } from 'selectors';
import {currentFlashcardId } from 'selectors/studying';
import { useDispatch, useSelector } from "react-redux";
import { getMediaTypeFilterOverlay, progressBarFilter, toggleDifficultyOverlay, toggleEnableCookiesOverlay, toggleFilterOverlay, toggleHiddenInfoOverlay, toggleMainMicroOverlay, toggleMCQOverlay, togglePaperOverlay, toggleQuestionInfoOverlay, toggleSourceOverlay, toggleTeachingLevelExamsOverlay, toggleTeachingLevelOverlay, toggleTeachingLevelOverlayFromFeature } from "actions/navigation";
import { toggleEnableCookies, getStoreExamsParentId } from "helpers";
import { useEffect, useMemo, useState } from "react";
import { closeFeature } from "actions/api";
import usePrevious from "hooks/usePrevious";
import Features from "v2/components/studying/features/Features";
import MainMicroFilter from 'v2/components/filter-overlay/MainMicroFilter';

type FilterOverlayProps = {
    classId: string
}

const popupTitlesForTeachingLevelOverlay = ['New Syllabus Active']

const FilterOverlay: React.FC<FilterOverlayProps> = () => {
    const dispatch = useDispatch();
    const [isFeature, setFeature] = useState(false)
    const hasFilterOverlayVisible = useSelector(isFilterOverlayVisible)
    const hasPaperOverlayVisible = useSelector(isPaperOverlayVisible)
    const hasDiffficultyOverlayVisible = useSelector(isDiffficultyOverlayVisible)
    const hasSourceOverlayVisible = useSelector(isSourceOverlayVisible)
    const hasMCQOverlayVisible = useSelector(isMCQOverlayVisible)
    const hasMainMicroFilterVisible = useSelector(isMainMicroFilterVisible)
    const hasTeachingLevelExamsOverlayVisible = useSelector(isTeachingLevelExamsOverlayVisible)
    const hasQuestionInfoOverlayVisible = useSelector(isQuestionInfoOverlayVisible)
    const hasHiddenInfoOverlayVisible = useSelector(isHiddenInfoOverlayVisible)
    const hasEnableCookiesOverlay = useSelector(isEnableCookiesOverlay)
    const hasProgressBarFilterVisible = useSelector(isProgressBarFilterVisible)
    const currentId = useSelector(currentFlashcardId)
    const mediaType = useSelector(setMediaTypeFilter)
    const feature = useSelector(getFeature);
    const subject = useSelector(getActiveSubject)
    const prevFeature = usePrevious(feature)

    const canShowOverlay = useMemo(() => {
      return !popupTitlesForTeachingLevelOverlay.includes(feature?.[0]?.title)
    },[feature])

    useEffect(() =>{
        if (feature !== prevFeature) {
          if(popupTitlesForTeachingLevelOverlay.includes(feature?.[0]?.title) && subject?.isNew) {
            dispatch(toggleTeachingLevelOverlay(true))
            dispatch(toggleTeachingLevelOverlayFromFeature(feature?.[0]?.id))
          }
          if (feature && feature.length > 0 && canShowOverlay) {
              setFeature(true)
          } else {
              setFeature(false)
          }
        }
    }, [canShowOverlay, dispatch, feature, prevFeature, subject?.isNew])

    useEffect(() => {

    },[])
    
    const onBackgroundClick = (e: any) => {
        dispatch(toggleFilterOverlay(false, false))
        dispatch(getMediaTypeFilterOverlay(null))
        e.stopPropagation();
    };

    const closeFilter = () => {
        dispatch(toggleFilterOverlay(false, false))
        dispatch(getMediaTypeFilterOverlay(null))
    }
    const closePaperOverlay = () => {
        dispatch(togglePaperOverlay(false, false))
        dispatch(getMediaTypeFilterOverlay(null))
    }
    const closeDiffficultyOverlay = () => {
        dispatch(toggleDifficultyOverlay(false, false))
        dispatch(getMediaTypeFilterOverlay(null))
    }
   

    const closeSourceOverlay = () => {
        dispatch(toggleSourceOverlay(false, false))
        dispatch(getMediaTypeFilterOverlay(null))
    }
    const closeMCQOverlay = () => {
        dispatch(toggleMCQOverlay(false, false));
        dispatch(getMediaTypeFilterOverlay(null))
    }
    const closeMainOverlay =()=>{
        dispatch(toggleMainMicroOverlay(false,false));
        dispatch(getMediaTypeFilterOverlay(null))
    }
    const closeTeachinLevelOverlay = () => {
        dispatch(toggleTeachingLevelExamsOverlay(false, false));
        dispatch(getMediaTypeFilterOverlay(null))
    }

    const closeQuestionOverlay = () => {
        dispatch(toggleQuestionInfoOverlay(false, false))
        dispatch(getMediaTypeFilterOverlay(null))
    }
    const closeHiddenOverlay = () => {
        dispatch(toggleHiddenInfoOverlay(false, false))
        dispatch(getMediaTypeFilterOverlay(null))
    }
    const closeEnableCookiesOverlay = () => {
        dispatch(toggleEnableCookiesOverlay(false))
        toggleEnableCookies(true)
    }
    const updateProgressBarFilter = () =>{
        dispatch(progressBarFilter(false))
    }

    const onClickCloseFilter = (e: any) => {
        e.stopPropagation();
    }
    const closePopUp = (id: any) => {
        // this.setState({ isFeature: false })
        setFeature(false)
        dispatch(closeFeature(id))
      }
    return <> 
        {hasFilterOverlayVisible &&
          <div className="popup-overlay" onClick={(e) => onBackgroundClick(e)}>
            <MicroFilterOverlay onClick={(e: any) => onClickCloseFilter(e)} mediaType={mediaType} onClose={() => closeFilter()} />
          </div>}

        {hasPaperOverlayVisible &&
          <div className="popup-overlay" onClick={closePaperOverlay}>
            <PaperFilter onClick={(e: any) => onClickCloseFilter(e)} onClose={closePaperOverlay} />
          </div>}

        {hasDiffficultyOverlayVisible &&
          <div className="popup-overlay" onClick={closeDiffficultyOverlay}>
            <DiffficultyFilterOverlay onClick={(e: any) => onClickCloseFilter(e)} onClose={closeDiffficultyOverlay} />
          </div>}
       
        {hasSourceOverlayVisible &&
          <div className="popup-overlay" onClick={closeSourceOverlay}>
            <SourceFilter onClick={(e: any) => onClickCloseFilter(e)}  onClose={() => closeSourceOverlay()} />
          </div>
        }
        {hasMCQOverlayVisible &&
          <div className="popup-overlay" onClick={closeMCQOverlay}>
            <MCQFilterOverlay onClick={(e: any) => onClickCloseFilter(e)} onClose={() => closeMCQOverlay()} />
          </div>
        }
        {hasMainMicroFilterVisible &&
          <div className="popup-overlay" onClick={closeMainOverlay}>
            <MainMicroFilter onClick={onClickCloseFilter} onClose={closeMainOverlay} currentId={currentId}/>
          </div>
        }
        {hasTeachingLevelExamsOverlayVisible &&
          <div className="popup-overlay" onClick={closeTeachinLevelOverlay}>
            <TeachingLevelExams onClick={(e: any) => onClickCloseFilter(e)} onClose={closeTeachinLevelOverlay} />
          </div>}

        {hasQuestionInfoOverlayVisible &&
          <div className="popup-overlay" onClick={closeQuestionOverlay}>
            <QuestionInfo onClick={(e: any) => onClickCloseFilter(e)} onClose={closeQuestionOverlay} currentId={currentId}/>
          </div>
        }
        {hasHiddenInfoOverlayVisible &&
          <div className="popup-overlay" onClick={closeHiddenOverlay}>
            <HiddenInfo onClick={(e: any) => onClickCloseFilter(e)}  onClose={closeHiddenOverlay} currentId={currentId} />
          </div>
        }

        {hasProgressBarFilterVisible && 
          <div className="popup-overlay" onClick={updateProgressBarFilter}>
            <ProgressPopupFilter onClose={updateProgressBarFilter} id={getStoreExamsParentId()}/>
          </div>
        }

        {hasEnableCookiesOverlay && 
          <div className="popup-overlay" onClick={() => closeEnableCookiesOverlay()}>
            <EnableCookies onClose={() => closeEnableCookiesOverlay()}/>
          </div>
        }
        
        {isFeature &&
          <div className="feature-popup-box">
            <Features data={feature} onClick={(id: any) => closePopUp(id)} />
          </div>
        }
    </>
}

export default FilterOverlay;