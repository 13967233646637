import { setExerciseFilterItemSize } from 'actions/navigation';
import usePrevious from 'hooks/usePrevious';
import React, { ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmptyScreenMessage from 'v2/components/empty-screen-message/EmptyScreenMessage';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import PreviewListHeader from 'v2/ui-components/search-results/PreviewListHeader'
import { preparePreviewsList, teachingFilterResultsListToArray, filterResultsListToArray } from 'v2/helpers';

type PreviewGroupsProps = {
  activeMediaTab: string;
  activeSubject: any;
  children: (arg:any) => ReactElement;
  list: any;
  listOfFilterCheckedItems: any;
  setActiveFlashcardTab: (arg:number) => void;
  subjectsTeachingLevel: any;
}

// @ts-ignore
const PreviewGroups:React.FC<PreviewGroupsProps> = ({ activeMediaTab, activeSubject, children, list, listOfFilterCheckedItems, setActiveFlashcardTab, subjectsTeachingLevel }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const prevSubjectsTeachingLevel= usePrevious(subjectsTeachingLevel);
  const prevListOfFilterCheckedItems = usePrevious(listOfFilterCheckedItems);
  const teachingTag = subjectsTeachingLevel[`${activeSubject.id}`]; 
  const flashcards = list && teachingFilterResultsListToArray(list, teachingTag, activeSubject)
  const newFilterItems = flashcards && filterResultsListToArray(flashcards, activeMediaTab, listOfFilterCheckedItems[activeMediaTab])
  const filterStatus = list?.length === 0;


  useEffect(() => {
    dispatch(setExerciseFilterItemSize(newFilterItems.length));
  },[])

  useEffect(() => {
    if(subjectsTeachingLevel != prevSubjectsTeachingLevel) {
      if(flashcards && flashcards.length === 0){
        history.push('/flashcard/desk/exercises');
      }
    }
  },[subjectsTeachingLevel, prevSubjectsTeachingLevel])

  useEffect(() => {
    if(listOfFilterCheckedItems != prevListOfFilterCheckedItems) {
      dispatch(setExerciseFilterItemSize(newFilterItems.length));
    }
  },[listOfFilterCheckedItems, prevListOfFilterCheckedItems])

  const onSubTopicClick = (subTopicId:string) => {
    history.push(`/flashcard/subtopic/${subTopicId}`);
    dispatch(setActiveFlashcardTab(0));
  }
  
  if (newFilterItems && newFilterItems.length === 0 && !filterStatus) {
    return <div className='pt-10'>
    <EmptyScreenMessage type={"filterExercise"} mediaType={"flashcard"} className={"empty-topics-screen"} ><FilterIcon /></EmptyScreenMessage>
    </div>
  }
  return preparePreviewsList(newFilterItems).map((item:any, index:number) => {
    return <div key={`flashcards-${item[0]?.category}`}>
      <PreviewListHeader
      topicNumber={item[0]?.topicNumbering}
      index={index}
      teachingLevel={item[0]?.teachingLevel}
      teachingTag={typeof item[0]?.teachingTag !== "undefined" ? item[0]?.teachingTag : null}
      subTopicId={item[0]?.subTopicId}
      subTopicTitle={item[0]?.subTopicName}
      onClick={() => onSubTopicClick(item[0]?.subTopicId)}
      data={item?.[0]}
      />
      {children(item)}
    </div>
  });
}

export default PreviewGroups