import * as DeskActionTypes from 'actions/desk/types';
import * as DeskActions from 'actions/desk';
import {takeLatest, all, put, select, call, take, race, takeEvery} from 'redux-saga/effects';
import {delay} from 'redux-saga/effects';
import * as Api from 'api';
import * as Selectors from 'selectors';
import * as Actions from 'actions';
import appHistory from 'appHistory';
import { getExamSubTopicId ,getJWTToken,chnageTeachingTag, getFormExams} from 'helpers'
export function* watchers() {
  yield all([
    takeLatest(DeskActionTypes.GET_NOTE, getNote),
    takeLatest(DeskActionTypes.UPDATE_NOTE, updateNote),
    takeEvery(DeskActionTypes.DELETE_NOTE, deleteNote),
    takeLatest(DeskActionTypes.ADD_NOTE, addNote),
    takeLatest(DeskActionTypes.INITIALIZE_NOTE, initializeNote),
    takeLatest(DeskActionTypes.GET_FLASHCARDS_PREVIEW_WITH_NOTES, getFlashcardsWithNotes),
    takeLatest(DeskActionTypes.SELECT_NOTE, selectNote),
    takeLatest(DeskActionTypes.MULTIPLE_DELETE_NOTE, multipleDeleteNote)
  ]);
}

function* getNote(action) {

  let subjectId = yield select(Selectors.activeSubjectId);
  let activeMediaTab=action.mediaType ? action.mediaType  : yield select(Selectors.getActiveMediaTab);
  const getAPJWTToken  = getJWTToken()
  try {
    const response = activeMediaTab === 'exams' ? yield call(Api.getExamNote,{subjectId: subjectId, node:action.flashcardId,getAPJWTToken}) : yield call(Api.getNote,{flashcardId: action.flashcardId, subjectId: subjectId,mediaType:activeMediaTab});
    if(response){
        let data = {
          data: response,
          flashcardId: action.flashcardId
        };
        yield put(DeskActions.getNoteSucceeded({data: data}));
      }
  } catch (e) {
    yield put(DeskActions.getNoteFailed(e));
  }
}

function* addNote(action) {
  let subjectId = yield select(Selectors.activeSubjectId);
  const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const teachingTag = subjectTeachingLevel[`${subjectId}`]
  let activeMediaTab = yield select(Selectors.getActiveMediaTab);
  let data = {
    content: action.value,
    flashcardId: action.flashcardId,
    subjectId,
    mediaType:activeMediaTab,
    teachingTag: teachingTag
  };
  
  if(action.value !=""){
    try {
      const response = yield call(Api.addNote, data);
      yield put(DeskActions.addNoteSucceeded(response));
      yield put(Actions.Desk.getFlashcardsPreviewWithNotes(true));
    } catch (e) {
      yield put(DeskActions.addNoteFailed(e));
    }
  }
}

function* updateNote(action) {
  //yield call(delay, 300);
  yield delay(300);
  let subjectId = yield select(Selectors.activeSubjectId);
  // let parentsData = yield select(Selectors.flashcardContent);
  let parentsData = yield select(Selectors.Studying.getFlashcardById, action.flashcardId);
  let activeMediaTab=action.mediaType ? action.mediaType : yield select(Selectors.getActiveMediaTab);
  const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const teachingTag = subjectTeachingLevel[`${subjectId}`]
  let data = {
    content: action.value,
    flashcardId: action.flashcardId,
    subjectId,
    mediaType:activeMediaTab,
    teachingTag: teachingTag
  };

  let topicNumber;
  let subTopicNumber;


  if (activeMediaTab === 'exams') {
    if (getFormExams() !== 'exams') {
      const subTopicData = parentsData.get('parentsData').filter((item) => {
        return item.get('subTopicId').toString() === getExamSubTopicId()
      })
      topicNumber = subTopicData.getIn([0, 'parentsData'])?.filter(item => item.get('type') === 'TOPIC').first().get('id')
      subTopicNumber = subTopicData.getIn([0, 'parentsData'])?.filter(item => item.get('type') === 'SUB_TOPIC').first().get('id');
    } else {
      topicNumber = parentsData.get('parentsData').first().get('parentsData').filter(item => item.get('type') === 'TOPIC').first().get('id')
      subTopicNumber = parentsData.get('parentsData').first().get('parentsData').filter(item => item.get('type') === 'SUB_TOPIC').first().get('id');
    }
    data = {
      ...data,
      topicId: topicNumber,
      subTopicId: subTopicNumber,
      teachingTag: teachingTag
    };
  }

    try {
      if(action.value !=""){
        const response = activeMediaTab==='exams' ? yield call(Api.editExamNote, data) : yield call(Api.editNote, data);
        yield put(DeskActions.putNoteSucceeded(response));
        yield put(Actions.Desk.getFlashcardsPreviewWithNotes(true));
      }else{
        yield call(Api.deleteNote, {ids: [action.flashcardId], subjectId,mediaType:activeMediaTab});
        yield put(DeskActions.deleteNoteSucceeded());
        yield put(Actions.Desk.getFlashcardsPreviewWithNotes(true));
      }
    } catch (e) {
      yield put(DeskActions.putNoteFailed(e));
    }
}

function* deleteNote(action) {
  let subjectId = yield select(Selectors.activeSubjectId);
  let activeMediaTab = yield select(Selectors.getActiveMediaTab);
  try {
    activeMediaTab==='exams' ? yield call(Api.deleteExamNote, {ids: [action.flashcardId], subjectId: subjectId}) : yield call(Api.deleteNote, {ids: [action.flashcardId], subjectId,mediaType:activeMediaTab});
    yield put(DeskActions.deleteNoteSucceeded());
    if(action.redirected) appHistory.push('/flashcard/desk/notes');
    // yield call(delay, 600);
    yield put(Actions.Desk.getFlashcardsPreviewWithNotes());
  } catch (e) {
    yield put(DeskActions.deleteNoteFailed(e));
  }
}

function* multipleDeleteNote() {
  let subjectId = yield select(Selectors.activeSubjectId);
  let ids = yield select(Selectors.listOfCheckedItems);
  let activeMediaTab = yield select(Selectors.getActiveMediaTab);
  try {
    activeMediaTab==='exams' ? yield call(Api.deleteExamNote, {ids: ids, subjectId}) : yield call(Api.deleteNote, {ids: ids, subjectId, mediaType:activeMediaTab});
    yield put(DeskActions.multipleDeleteNoteSucceeded());
    yield put(Actions.Desk.deselectAllItems());
    yield put(Actions.Navigation.toggleDeskEditMode(false));
    yield put(Actions.Desk.toggleDeleteConfirmationMode(false));
    yield put(Actions.Desk.textareaNoteChange(''));
    yield put(Actions.Desk.getFlashcardsPreviewWithNotes(true));
  } catch (e) {
    yield put(DeskActions.deleteNoteFailed(e));
  }
}

function* initializeNote(action) {
  try {
    yield put(Actions.Desk.getNote(action.flashcardId,action.mediaType));
    yield race([
      take(DeskActionTypes.GET_NOTE_SUCCEEDED),
      take(DeskActionTypes.GET_NOTE_FAILED),
    ]);
    const flashcardNoteContent = yield select(Selectors.flashcardNoteContent);
    if (
      flashcardNoteContent &&
      flashcardNoteContent.get("flashcardId") === action.flashcardId
    ) {
      yield put(
        Actions.Desk.textareaNoteChange(
          flashcardNoteContent.getIn(["data", "content"])
        )
      );
    } else {
      if(flashcardNoteContent && flashcardNoteContent.getIn(["data", "content"])){
        // yield put(Actions.Desk.textareaNoteChange(""));
        yield put(Actions.Desk.textareaNoteChange(flashcardNoteContent.getIn(["data", "content"])));
      } else {
        yield put(Actions.Desk.textareaNoteChange(""));
      }
      action.value = "";
      yield put(DeskActions.addNote(action.value, action.flashcardId));
    }
    yield put(Actions.Desk.initializeNoteSucceeded());
  } catch (error) {
    console.error("error for initializeNote", error)
  }
}

function* getFlashcardsWithNotes(action) {
  if (action.hasDelay)  yield delay(500);
  let subjectId = yield select(Selectors.activeSubjectId);
  const subject = yield select(Selectors.getActiveSubject)
  let activeMediaTab = yield select(Selectors.getActiveMediaTab);
  const subjectTeachingLevel = yield select(Selectors.subjectsTeachingLevel);
  const teachingTag = subjectTeachingLevel[`${subjectId}`]
  const APTeachingTag = chnageTeachingTag(teachingTag)
  const getAPJWTToken  = getJWTToken()
  const activeExamModule = yield select(Selectors.getActiveExamModule)
  const subjectExamStatus = [14982 , 15075].includes(subjectId)
  const questionBankCodes =  activeExamModule ? ["ib_dp", "osc_dp", "osc_dp_mocks"] : subjectExamStatus && ["osc_dp", "osc_dp_mocks"]
  try {

    const response = activeMediaTab === 'exams' ?
      yield call(Api.getExamsNotesPreview, subjectId, getAPJWTToken, APTeachingTag, questionBankCodes, subject?.tagging && teachingTag) :
      yield call(Api.getFlashcardsPreviewWithNotes, subjectId, activeMediaTab, subject?.tagging && teachingTag);
    if(activeMediaTab === 'exams'){
      response.items.map(item => {
      //   item.category = `${item.topicNumbering} ${item.teachingLevel}`;
        item.examCategory = `${item.teachingLevel} ${item.experienceData.exam_name} ${item.paper}`;
        item.paperType = `${item.paper}`;
        item.qb_code = `${item.experienceData.qb_code}`;
        item.sources = `${item.experienceData.qb_code}`;
      });
    }

    yield put(DeskActions.getFlashcardsPreviewWithNotesSucceeded({data: response}));
  } catch (error) {
    yield put(DeskActions.getFlashcardsPreviewWithNotesFailed(error));
  }
}

function* selectNote(action) {
  yield put(Actions.Studying.selectFlashcard(action.flashcardId, action.navigationSearchType));

  if (!(yield select(Selectors.isMobile))) return;
  yield put(Actions.Studying.toggleOverlay(true));
  //yield put(Actions.Studying.toolTipProps('note-tt'));
}
