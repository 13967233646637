import React, {Component} from 'react';
import NavigationHeader from 'components/navigation-header/NavigationHeader';
import * as FlashcardIcons from 'icons/FlashcardIcons';
import {CreateFolderIcon} from 'icons/HeaderIcons';
/**
 * @deprecated TODO- delete not use any more
 */
export default class HeaderFoldersTooltip extends Component {
  render() {
    return (
      <React.Fragment>
        <NavigationHeader
          extendedWidth
          isCollapsed={this.props.isCollapsed}
          contained
          title={(
            <div className="folders-title-container">
              <FlashcardIcons.FoldersIcon color="#000000" opacity='1'/> 
              <div className="folders-title">Folders</div>
            </div>
          )}
          leftSection={(<div></div>)}
          rightSection={null}/>
      </React.Fragment>
    );
  }
}
