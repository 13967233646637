import './Modal.scss';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {CloseStoreModalIcon} from 'icons/StoreIcons';

export default class Modal extends PureComponent {
  render() {
    let className = 'modal';
    if (this.props.className) className += ` ${this.props.className}`;

    return (
      <div className={className}>
        <div className='modal-content'>
          {this.props.children}
        </div>
        
        <div className="modal-overlay" onClick={this.props.onClose}/>

        <div className="modal-close" onClick={this.props.onClose}>
          <CloseStoreModalIcon/>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isBasketDetailsVisible: PropTypes.bool,
};
