import { ReactElement } from 'react';
import { IBIcon, PmjIcon } from 'icons/HeaderIcons';

type ObjectTypes = {
    [key: string]: string;
}
type ExamsIcon = {
    [key: string]: ReactElement
}
type MathsTitle = {
    [key: number]: any
}
export const title: ObjectTypes = {
    'ib_dp':'We’re currently in the middle of adding official IB exams to the HL course. By switching to the SL course (via the header), you can experience the SL version now.',
    'osc_dp':'We’re sorry, but currently no Pamoja exams are available for this subject.',
    'osc_dp_mocks':'We’re sorry, but currently no Pamoja mocks are available for this subject.',
    'Paper 3':'Paper 3 covers the options.',
    'Paper 1':'Paper 1 covers the options.',
    'filterPaper':'No match found for your filter'
}
export const comingSoonText: ObjectTypes = {
    'ib_dp':'Coming soon',
    'osc_dp':'Coming soon',
    'osc_dp_mocks':'Coming soon',
    'Paper 3':'Coming soon',
    'Paper 2':'Not tested by IB',
    'Paper 1':'Not tested by IB',
    'filterPaper':''
}

export const pandemicComingSoonText: ObjectTypes ={
    'Paper 3':'Unavailable',
}

export const pandemicSubtitles: ObjectTypes ={
    'Paper 3':"The IB did not test the options in this exam session due to the pandemic.",
}

export const subtitles: ObjectTypes = {
    'ib_dp':'Pamoja exams and mocks are available. Do you want to practice your exam skills with these?',
    'osc_dp':'This subject offers offical IB exams. Do you want to practice your exam skills with these?',
    'osc_dp_mocks':'This subject offers official IB exams. Do you want to practice your exam skills with these?',
    'Paper 3':"We're currently in the middle of making Paper 3 content available for this exam.",
    'Paper 1':'The IB will not test the options in this exam session, which is why we’re not including Paper 1 content currently.',
    'Paper 2':'The IB will not test the options in this exam session, which is why we’re not including Paper 1 content currently.',
    'filterPaper':'<strong>To view the exam questions of this paper, change the filter above.</strong>'
}

export const examsIconTitle: ObjectTypes = {
    'ib_dp':'Official IB exams',
    'osc_dp':'Pamoja practice exams',
    'osc_dp_mocks':'Pamoja mock exams',
}
export const examsIcon: ExamsIcon = {
    'ib_dp': <IBIcon />,
    'osc_dp': <PmjIcon />,
    'osc_dp_mocks': <PmjIcon />
}
export const buttonText: ObjectTypes = {
    'ib_dp': 'YES, LET’S DO IT',
    'osc_dp': 'YES, LET’S DO IT',
    'osc_dp_mocks': 'YES, LET’S DO IT',
    'Paper 1': '',
    'Paper 2': '',
    'Paper 3': '',
    'filterPaper': ''
}
export const mathsTitle: MathsTitle = {
    14982 : {
        'SL': {
            'ib_dp':'We’re currently in the middle of adding official IB exams to the SL course.',
            'osc_dp':'We’re sorry, but currently no Pamoja exams are available for this subject.',
            'osc_dp_mocks':'We’re sorry, but currently no Pamoja mocks are available for this subject.',
            'Paper 3':'Paper 3 covers the options.',
            'Paper 1':'Paper 1 covers the options.',
            'filterPaper':'No match found for your filter'
        },
        'HL': {
            'ib_dp':'We’re currently in the middle of adding official IB exams to the HL course.',
            'osc_dp':'We’re sorry, but currently no Pamoja exams are available for this subject.',
            'osc_dp_mocks':'We’re sorry, but currently no Pamoja mocks are available for this subject.',
            'Paper 3':'Paper 3 covers the options.',
            'Paper 1':'Paper 1 covers the options.',
            'filterPaper':'No match found for your filter'
        }
    },
    15075 : {
        'SL': {
            'ib_dp':'We’re currently in the middle of adding official IB exams to the SL course.',
            'osc_dp':'We’re sorry, but currently no Pamoja exams are available for this subject.',
            'osc_dp_mocks':'We’re sorry, but currently no Pamoja mocks are available for this subject.',
            'Paper 3':'Paper 3 covers the options.',
            'Paper 1':'Paper 1 covers the options.',
            'filterPaper':'No match found for your filter'
        },
        'HL': {
            'ib_dp':'We’re currently in the middle of adding official IB exams to the HL course.',
            'osc_dp':'We’re sorry, but currently no Pamoja exams are available for this subject.',
            'osc_dp_mocks':'We’re sorry, but currently no Pamoja mocks are available for this subject.',
            'Paper 3':'Paper 3 covers the options.',
            'Paper 1':'Paper 1 covers the options.',
            'filterPaper':'No match found for your filter'
        }
    }
}