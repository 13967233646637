export const paperFilterText: any = {
  47: {
    SL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features 30 multiple choice questions, covering the SL Core topics.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features Section A where you answer all questions, and Section B where you answer one question, covering the SL Core topics.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features Section A where you answer all questions, and Section B where you answer all questions from one option.",
        active: true,
      },
    ],
    HL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features 40 multiple choice questions, covering the SL Core plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features Section A where you answer all questions, and Section B where you answer two questions, covering the SL Core topics plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features Section A where you answer all questions, and Section B where you answer all questions from one option.",
        active: true,
      },
    ],
  },
  3: {
    SL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features 30 multiple choice questions, covering the SL Core topics. No data booklet is allowed (but they do give you the periodic table on page 2.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features longer questions that cover the SL Core topics. Chemistry data booklet is allowed.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Paper 3 covers the options. We're currently in the middle of making Paper 3 content available.",
        active: true,
      },
    ],
    HL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features 40 multiple choice questions, covering the SL Core topics, plus the Additional Higher Level topics. No data booklet is allowed (but they do give you the periodic table on page 2).",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features longer questions that cover the SL Core topics plus the Additional Higher Level topics. Chemistry data booklet is allowed.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Paper 3 covers the options. We're currently in the middle of making Paper 3 content available.",
        active: true,
      },
    ],
  },
  50: {
    SL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, paper 1 features 30 multiple choice questions, covering the SL Core topics. Physics data booklet is allowed.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features longer questions that cover the SL Core topics. Physics data booklet is allowed.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features Section A where you answer all questions, and Section B where you answer all questions from one option.",
        active: true,
      },
    ],
    HL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, paper 1 features 40 multiple choice questions, covering the SL Core topics, plus the Additional Higher Level topics. Physics data booklet is allowed.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features longer questions that cover the SL Core topics, plus the Additional Higher Level topics. Physics data booklet is allowed.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features Section A where you answer all questions, and Section B where you answer all questions from one option.",
        active: true,
      },
    ],
  },
  14982: {
    SL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features shorter questions in Section A, and longer questions in Section B. Formula booklet is allowed.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features shorter questions in Section A, and longer questions in Section B. Formula booklet is allowed.",
        active: true,
      },
    ],
    HL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features shorter questions in Section A, and longer questions in Section B. Formula booklet is allowed.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features shorter questions in Section A, and longer questions in Section B. Formula booklet is allowed.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features two extended response questions, covering any topic from the syllabus. Formula booklet is allowed.",
        active: true,
      },
    ],
  },
  15075: {
    SL: [
      {
        title: "Paper 1",
        subTitle:
          "Calculator required, Paper 1 features shorter questions that cover the entire syllabus. Formula booklet is allowed.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features longer questions that cover the entire syllabus. Formula booklet is allowed.",
        active: true,
      },
    ],
    HL: [
      {
        title: "Paper 1",
        subTitle:
          "Calculator required, Paper 1 features shorter questions that cover the entire syllabus. Formula booklet is allowed.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features longer questions that cover the entire syllabus. Formula booklet is allowed.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features two extended response questions, covering any topic from the syllabus. Formula booklet is allowed.",
        active: true,
      },
    ],
  },
  28374: {
    SL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features 30 multiple choice questions, covering the SL Core topics.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features Section A where you answer all questions, and Section B where you answer one question, covering the SL Core topics.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features Section A where you answer all questions, and Section B where you answer all questions from one option.",
        active: true,
      },
    ],
    HL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features 40 multiple choice questions, covering the SL Core plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features Section A where you answer all questions, and Section B where you answer two questions, covering the SL Core topics plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features Section A where you answer all questions, and Section B where you answer all questions from one option.",
        active: true,
      },
    ],
  },
  21913: {
    SL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features 30 multiple choice questions, covering the SL Core topics.",
        active: true,
      },
      {
        title: "Paper 1A",
        subTitle:
          "Calculator required, Paper 1A features 25 multiple choice questions, covering the SL core topics A, B, C, D and E.",
        active: true,
      },
      {
        title: "Paper 1B",
        subTitle:
          "Calculator required, Paper 1B features data based questions, which cover topics like experimental analysis, calculating uncertainties, analysis and interpretation of data.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features short answer and extended response questions covering the SL core topics A, B, C, D and E.",
        active: true,
      },
      {
        title: "Paper 2 ",
        subTitle:
          "Calculator required, Paper 2 features short answer and extended response questions covering the SL core topics A, B, C, D and E.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features Section A where you answer all questions, and Section B where you answer all questions from one option.",
        active: true,
      },
    ],
    HL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features 40 multiple choice questions, covering the SL Core plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 1A",
        subTitle:
          "Calculator required, Paper 1A features 40 multiple choice questions, covering the SL core plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 1B",
        subTitle:
          "Calculator required, Paper 1B features data based questions, which cover topics like experimental analysis, calculating uncertainties, analysis and interpretation of data.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features short answer and extended response questions covering the SL core plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 2 ",
        subTitle:
          "Calculator required, Paper 2 features Section A where you answer all questions, and Section B where you answer two questions, covering the SL Core topics plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features Section A where you answer all questions, and Section B where you answer all questions from one option.",
        active: true,
      },
    ],
  },
  38010: {
    SL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features 30 multiple choice questions, covering the SL Core topics.",
        active: true,
      },
      {
        title: "Paper 1A",
        subTitle:
          "Calculator required, Paper 1A features 25 multiple choice questions, covering the SL core topics A, B, C, D and E.",
        active: true,
      },
      {
        title: "Paper 1B",
        subTitle:
          "Calculator required, Paper 1B features data based questions, which cover topics like experimental analysis, calculating uncertainties, analysis and interpretation of data.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features short answer and extended response questions covering the SL core topics A, B, C, D and E.",
        active: true,
      },
      {
        title: "Paper 2 ",
        subTitle:
          "Calculator required, Paper 2 features short answer and extended response questions covering the SL core topics A, B, C, D and E.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features Section A where you answer all questions, and Section B where you answer all questions from one option.",
        active: true,
      },
    ],
    HL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features 40 multiple choice questions, covering the SL Core plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 1A",
        subTitle:
          "Calculator required, Paper 1A features 40 multiple choice questions, covering the SL core plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 1B",
        subTitle:
          "Calculator required, Paper 1B features data based questions, which cover topics like experimental analysis, calculating uncertainties, analysis and interpretation of data.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features short answer and extended response questions covering the SL core plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 2 ",
        subTitle:
          "Calculator required, Paper 2 features short answer and extended response questions covering the SL core plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features Section A where you answer all questions, and Section B where you answer all questions from one option.",
        active: true,
      },
    ],
  },
  24932: {
    SL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features 30 multiple choice questions, covering the SL Core topics.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features Section A where you answer all questions, and Section B where you answer one question, covering the SL Core topics.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features Section A where you answer all questions, and Section B where you answer all questions from one option.",
        active: true,
      },
      {
        title: "Paper 1A",
        subTitle:
          "No calculator allowed, Paper 1A features 30 multiple choice questions, covering the SL Core topics.",
        active: true,
      },
      {
        title: "Paper 1B",
        subTitle:
          "Calculator required, Paper 1B features Section A where you answer all questions, and Section B where you answer one question, covering the SL Core topics.",
        active: true,
      },
    ],
    HL: [
      {
        title: "Paper 1",
        subTitle:
          "No calculator allowed, Paper 1 features 40 multiple choice questions, covering the SL Core plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 2",
        subTitle:
          "Calculator required, Paper 2 features Section A where you answer all questions, and Section B where you answer two questions, covering the SL Core topics plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 3",
        subTitle:
          "Calculator required, Paper 3 features Section A where you answer all questions, and Section B where you answer all questions from one option.",
        active: true,
      },
      {
        title: "Paper 1A",
        subTitle:
          "No calculator allowed, Paper 1A features 40 multiple choice questions, covering the SL Core plus the Additional Higher Level topics.",
        active: true,
      },
      {
        title: "Paper 1B",
        subTitle:
          "Calculator required, Paper 1A features Section A where you answer all questions, and Section B where you answer two questions, covering the SL Core topics plus the Additional Higher Level topics.",
        active: true,
      },
    ],
  },
};

export const paperInfo: any = {
  14982: {
    SL: {
      "Paper 1": {
        question: 5,
        marks: 80,
        time: "1h 30m",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 5,
        marks: 80,
        time: "1h 30m",
        calc: "Calc",
      },
    },
    HL: {
      "Paper 1": {
        question: 12,
        marks: 110,
        time: "2h",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 12,
        marks: 110,
        time: "2h",
        calc: "Calc",
      },
      "Paper 3": {
        question: 2,
        marks: 55,
        time: "1h",
        calc: "Calc",
      },
    },
  },
  15075: {
    SL: {
      "Paper 1": {
        question: 13,
        marks: 80,
        time: "1h 30m",
        calc: "Calc",
      },
      "Paper 2": {
        question: 5,
        marks: 80,
        time: "1h 30m",
        calc: "Calc",
      },
    },
    HL: {
      "Paper 1": {
        question: 17,
        marks: 110,
        time: "2h",
        calc: "Calc",
      },
      "Paper 2": {
        question: 7,
        marks: 110,
        time: "2h",
        calc: "Calc",
      },
      "Paper 3": {
        question: 2,
        marks: 55,
        time: "1h",
        calc: "Calc",
      },
    },
  },
  47: {
    SL: {
      "Paper 1": {
        question: 30,
        marks: 30,
        time: "45m",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 5,
        marks: 50,
        time: "1h 15m",
        calc: "Calc",
      },
      "Paper 3": {
        question: 7,
        marks: 35,
        time: "1h",
        calc: "Calc",
      },
    },
    HL: {
      "Paper 1": {
        question: 40,
        marks: 40,
        time: "1h",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 7,
        marks: 72,
        time: "2h 15m",
        calc: "Calc",
      },
      "Paper 3": {
        question: 7,
        marks: 45,
        time: "1h 15m",
        calc: "Calc",
      },
    },
  },
  3: {
    SL: {
      "Paper 1": {
        question: 30,
        marks: 30,
        time: "45m",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 6,
        marks: 50,
        time: "1h 15m",
        calc: "Calc",
      },
      "Paper 3": {
        question: 6,
        marks: 95,
        time: "1h 15m",
        calc: "Non-calc",
      },
    },
    HL: {
      "Paper 1": {
        question: 40,
        marks: 40,
        time: "1h",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 9,
        marks: 95,
        time: "2h 15m",
        calc: "Calc",
      },
      "Paper 3": {
        question: 0,
        marks: 95,
        time: "1h 15m",
        calc: "Non-calc",
      },
    },
  },
  50: {
    SL: {
      "Paper 1": {
        question: 30,
        marks: 30,
        time: "45m",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 6,
        marks: 50,
        time: "1h 15m",
        calc: "Calc",
      },
      "Paper 3": {
        question: 6,
        marks: 35,
        time: "1h",
        calc: "Calc",
      },
    },
    HL: {
      "Paper 1": {
        question: 40,
        marks: 40,
        time: "1h",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 9,
        marks: 95,
        time: "2h 15m",
        calc: "Calc",
      },
      "Paper 3": {
        question: 6,
        marks: 45,
        time: "1h 15m",
        calc: "Calc",
      },
    },
  },
  28374: {
    SL: {
      "Paper 1": {
        question: 30,
        marks: 30,
        time: "45m",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 6,
        marks: 50,
        time: "1h 15m",
        calc: "Calc",
      },
      "Paper 3": {
        question: 6,
        marks: 35,
        time: "1h",
        calc: "Calc",
      },
    },
    HL: {
      "Paper 1": {
        question: 40,
        marks: 40,
        time: "1h",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 9,
        marks: 95,
        time: "2h 15m",
        calc: "Calc",
      },
      "Paper 3": {
        question: 6,
        marks: 45,
        time: "1h 15m",
        calc: "Calc",
      },
    },
  },
  38010: {
    SL: {
      "Paper 1": {
        question: 26,
        marks: 26,
        time: "45m",
        calc: "Non-calc",
      },
      "Paper 1A": {
        question: 25,
        marks: 25,
        time: "45m",
        calc: "calc",
      },
      "Paper 1B": {
        question: 2,
        marks: 20,
        time: "2h 15m",
        calc: "Calc",
      },
      "Paper 2 ": {
        question: 7,
        marks: 50,
        time: "1h",
        calc: "Calc",
      },
      "Paper 2": {
        question: 7,
        marks: 50,
        time: "1h",
        calc: "Calc",
      },
      "Paper 3": {
        question: 10,
        marks: 90,
        time: "1h 15m",
        calc: "Calc",
      },
    },
    HL: {
      "Paper 1": {
        question: 40,
        marks: 40,
        time: "1h",
        calc: "Calc",
      },
      "Paper 1A": {
        question: 40,
        marks: 40,
        time: "1h",
        calc: "Calc",
      },
      "Paper 1B": {
        question: 2,
        marks: 20,
        time: "2h 15m",
        calc: "Calc",
      },
      "Paper 2": {
        question: 10,
        marks: 90,
        time: "1h 15m",
        calc: "Calc",
      },
      "Paper 2 ": {
        question: 10,
        marks: 90,
        time: "1h 15m",
        calc: "Calc",
      },
      "Paper 3": {
        question: 10,
        marks: 90,
        time: "1h 15m",
        calc: "Calc",
      },
    },
  },
  24932: {
    SL: {
      "Paper 1": {
        question: 30,
        marks: 30,
        time: "45m",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 6,
        marks: 50,
        time: "1h 15m",
        calc: "Calc",
      },
      "Paper 3": {
        question: 6,
        marks: 35,
        time: "1h",
        calc: "Calc",
      },
      "Paper 1A": {
        question: 30,
        marks: 30,
        time: "45m",
        calc: "Non-calc",
      },
      "Paper 1B": {
        question: 6,
        marks: 50,
        time: "1h 15m",
        calc: "Calc",
      },
    },
    HL: {
      "Paper 1": {
        question: 40,
        marks: 40,
        time: "1h",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 9,
        marks: 95,
        time: "2h 15m",
        calc: "Calc",
      },
      "Paper 3": {
        question: 6,
        marks: 45,
        time: "1h 15m",
        calc: "Calc",
      },
      "Paper 1A": {
        question: 40,
        marks: 40,
        time: "1h",
        calc: "Non-calc",
      },
      "Paper 1B": {
        question: 40,
        marks: 40,
        time: "1h",
        calc: "Non-calc",
      },
    },
  },
  21913: {
    SL: {
      "Paper 1": {
        question: 30,
        marks: 30,
        time: "45m",
        calc: "Non-calc",
      },
      "Paper 1A": {
        question: 25,
        marks: 25,
        time: "45m",
        calc: "calc",
      },
      "Paper 1B": {
        question: 2,
        marks: 20,
        time: "2h 15m",
        calc: "Calc",
      },
      "Paper 2 ": {
        question: 7,
        marks: 50,
        time: "1h",
        calc: "Calc",
      },
      "Paper 2": {
        question: 6,
        marks: 50,
        time: "1h 15m",
        calc: "Calc",
      },
      "Paper 3": {
        question: 6,
        marks: 35,
        time: "1h",
        calc: "Calc",
      },
    },
    HL: {
      "Paper 1": {
        question: 40,
        marks: 40,
        time: "1h",
        calc: "Non-calc",
      },
      "Paper 1A": {
        question: 40,
        marks: 40,
        time: "1h",
        calc: "Non-calc",
      },
      "Paper 1B": {
        question: 3,
        marks: 35,
        time: "2h 15m",
        calc: "Non-calc",
      },
      "Paper 2 ": {
        question: 10,
        marks: 90,
        time: "1h 15m",
        calc: "Non-calc",
      },
      "Paper 2": {
        question: 9,
        marks: 90,
        time: "2h 15m",
        calc: "Non-calc",
      },
      "Paper 3": {
        question: 6,
        marks: 45,
        time: "1h 15m",
        calc: "Calc",
      },
    },
  },
};
