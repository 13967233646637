import React, { useMemo } from "react";
import { PurchaseDateIcon, RemainingDaysIcon } from "icons/MySubjectsIcon";
import "./Subscriptions.scss";

type SubscriptionProps = {
  icon: React.ReactElement;
  name: string;
  validity?: string;
  daysPurchased: number | string;
  daysRemaining: number;
  purchaseDateFormatted: string;
  userData: any;
};

const Subscription: React.FC<SubscriptionProps> = ({
  icon,
  name,
  validity,
  daysPurchased,
  daysRemaining,
  purchaseDateFormatted,
  userData,
}) => {

  const daysRenderer = useMemo(() => {
    if (daysPurchased === "unlimited") {
      return <span>Active Subscription</span>;
    }
    return (
      <span>
        All subjects for {daysPurchased} day
        {typeof daysPurchased === "number" && daysPurchased <= 1 ? "" : "s"}
      </span>
    );
  }, [daysPurchased]);

  return (
    <>
      <div className="flex subject-item">
        {icon}

        <div className="subject-info">
          <div className="subject-title">
            <span>{name}</span>
            {userData && userData.fromSchool === "YES" ? (
              <span className="from-school">From School</span>
            ) : null}
          </div>

          <div className="subscription">
            {validity ? validity : daysRenderer}
          </div>

          {(daysPurchased !== "unlimited") && <div className="bought-date">
            <div className="bought-date-icon">
              <PurchaseDateIcon />
            </div>

            <span className="bought-date-title">{purchaseDateFormatted}</span>
          </div>}

          {daysPurchased !== "unlimited" && (
            <div className="remaining-days">
              <div className="remaining-days-icon">
                <RemainingDaysIcon />
              </div>

              <span className="remaining-days-title">
                {daysRemaining}/{daysPurchased} day
                {daysRemaining <= 1 ? "" : "s"} remaining
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="my-3 h-1px rounded-1px bg-#F0F5F5 plan-bottom-line" />
    </>
  );
};

export default Subscription;
