import { getFlashcardIdsRoutine } from "actions/api";
import { setActiveMediaTab } from "actions/navigation";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveMediaTab, getActiveSubject, isMBFromSchool, isOSCUser } from "selectors";
import { quickStatusById } from "selectors/quickSelectors";

function useMediaStatus() {
  const dispatch = useDispatch();
  const activeMediaTab = useSelector(getActiveMediaTab);
  const activeSubject = useSelector(getActiveSubject);
  const mediaQuickStatus = useSelector((state) => quickStatusById(state, activeSubject.id));
  const getFlash = mediaQuickStatus.getIn(['flashcard', 'total']);
  const getRevison = mediaQuickStatus.getIn(['revision', 'total']);
  const getVideo = mediaQuickStatus.getIn(['video', 'total']);
  const getExams = mediaQuickStatus.getIn(['exam', 'freeResponse', 'total']);
  const getFreeResponseExamTotal = mediaQuickStatus.get("exam").getIn(['freeResponse', 'total'])
  const getMCQExamTotal = mediaQuickStatus.get("exam").getIn(['msqTotal', 'total'])
  const hasMBFromSchool = useSelector(isMBFromSchool);
  const hasOSCUser = useSelector(isOSCUser)

  const getMediaType = () => {
    if (getFlash > 0) {
      return 'flashcard'
    }
    if (getRevison > 0) {
      return 'revision'
    }
    if (getVideo > 0) {
      return 'video'
    }
    if (getExams > 0) {
      return 'exams'
    }
  }

  const setMediaTab = () => {
    dispatch(getFlashcardIdsRoutine({ subjectId: activeSubject.id }));
    if (activeMediaTab === 'flashcard' && getFlash <= 0) {
      dispatch(setActiveMediaTab(getMediaType()));
    }
    if (activeMediaTab === 'revision' && getRevison <= 0) {
      dispatch(setActiveMediaTab(getMediaType()));
    }
    if (activeMediaTab === 'video' && getVideo <= 0) {
      dispatch(setActiveMediaTab(getMediaType()));
    }
    if (activeMediaTab === 'exams' && getExams <= 0) {
      dispatch(setActiveMediaTab(getMediaType()));
    }
  }

  const setActiveMediaType = useCallback(() => {
    if (getFlash > 0 || getRevison > 0 || getVideo > 0 || (getFreeResponseExamTotal > 0 || getMCQExamTotal > 0)) {
      if (getFlash > 0) {
        if (getRevison > 0 || getVideo > 0) {
          if (getRevison > 0 && activeMediaTab === "revision") {
            dispatch(setActiveMediaTab('revision'))
          } else if (getVideo > 0 && activeMediaTab === "video") {
            dispatch(setActiveMediaTab('video'))
          } else if ((getFreeResponseExamTotal > 0 || getMCQExamTotal > 0) && activeMediaTab === "exams" && (hasOSCUser || hasMBFromSchool)) {
            dispatch(setActiveMediaTab('exams'))
          }
          else {
            dispatch(setActiveMediaTab('flashcard'))
          }
        } else {
          dispatch(setActiveMediaTab("flashcard"))
        }
      } else if (getRevison > 0 && getVideo > 0 && (getFreeResponseExamTotal > 0 || getMCQExamTotal > 0)) {
        if (getRevison > 0 && activeMediaTab === "revision") {
          dispatch(setActiveMediaTab('revision'))
        }
        if (getVideo > 0 && activeMediaTab === "video") {
          dispatch(setActiveMediaTab('video'))
        } if ((getFreeResponseExamTotal > 0 || getMCQExamTotal > 0) && activeMediaTab === "exams") {
          dispatch(setActiveMediaTab('exams'))
        } else {
          dispatch(setActiveMediaTab('revision'))
        }
      } else if (getRevison > 0 || getVideo > 0 || (getFreeResponseExamTotal > 0 || getMCQExamTotal > 0)) {
        if (getRevison > 0 && activeMediaTab === "revision") {
          dispatch(setActiveMediaTab('revision'))
        } else if (getVideo > 0 && activeMediaTab === "video") {
          dispatch(setActiveMediaTab('video'))
        } else if ((getFreeResponseExamTotal > 0 || getMCQExamTotal > 0) && activeMediaTab === "exams") {
          dispatch(setActiveMediaTab('exams'))
        }
        else {
          if (getVideo > 0) {
            dispatch(setActiveMediaTab('video'))
          }
          if (getRevison > 0) {
            dispatch(setActiveMediaTab('revision'))
          }
          if ((getFreeResponseExamTotal > 0 || getMCQExamTotal > 0)) {
            dispatch(setActiveMediaTab('exams'))
          }
        }
      }
    }
  }, [activeMediaTab, dispatch, getFlash, getFreeResponseExamTotal, getMCQExamTotal, getRevison, getVideo, hasMBFromSchool, hasOSCUser])

  return { setMediaTab, setActiveMediaType };
}
export default useMediaStatus;