import classNames from 'classnames';
import React, {FC, MouseEventHandler} from 'react';

import {
  TriangleLeft,
  TriangleRight
} from 'icons/FlashcardIcons';
import { Next5Icon, Prev5Icon } from 'icons/toolboxIcons';
import './navigationButton.scss';

type NavRoundButtonProps = {
  onClick: () => void;
  className?: string;
  icon: React.ReactElement;
}  
export const NavRoundButton: FC<NavRoundButtonProps> = ({icon, onClick, className}) => {
  return (
    <button onClick={onClick} className={classNames(className, 'navigation-round-btn')}>
      {icon}
    </button>
  )
}

type PrevButtonProps = {
  onClick: () => void;
  className?: string;
}  
export const PrevButton: FC<PrevButtonProps> = ({onClick, className}) => {
  return (
      <NavRoundButton icon={<TriangleLeft />} onClick={onClick} className={className} />
  )
}

type NextButtonProps = {
  onClick: () => void;
  className?: string;
}  
export const NextButton: FC<NextButtonProps> = ({onClick, className}) => {
  return (
      <NavRoundButton icon={<TriangleRight />} onClick={onClick} className={className} />
  )
}

type Prev5ButtonProps = {
  onClick: () => void;
  className?: string;
}  
export const Prev5Button: FC<Prev5ButtonProps> = ({onClick, className}) => {
  return (
      <NavRoundButton icon={<Prev5Icon />} onClick={onClick} className={classNames(className, 'nav-small-btn')} />
  )
}

type Next5ButtonProps = {
  onClick: () => void;
  className?: string;
}  
export const Next5Button: FC<Next5ButtonProps> = ({onClick, className}) => {
  return (
      <NavRoundButton icon={<Next5Icon />} onClick={onClick} className={classNames(className, 'nav-small-btn')} />
  )
}

type ExamNextButtonProps = {
  onClick: MouseEventHandler<HTMLDivElement>,
  exam: boolean,
  endPaper: boolean
}

type ExamPrevButtonProps = {
  onClick: MouseEventHandler<HTMLDivElement>,
  visibility:boolean,
  exam: boolean
}

export const ExamPrevButton: React.FC<ExamPrevButtonProps> = ({ onClick, visibility, exam }) => {
  return (
    <div className={classNames('prev-navigation-bottom', { 'invisible pointer-events-none': visibility })} onClick={onClick}>
      <div>
        <TriangleLeft />
      </div>
    </div>
  )
}

export const ExamNextButton: React.FC<ExamNextButtonProps> = ({ onClick, exam, endPaper }) => {
  const endPaperStyle = () => {
    if (endPaper) {
      return 'w-98px h-9'
    } else {
      return 'w-11 h-11'
    }
  }

  return (
    <div className={classNames('next-navigation-bottom ', { 'exam-bottom-navigation': exam }, endPaperStyle())}
      onClick={onClick}>
      {endPaper ? <div className='exam-end-navigation'>END</div>
        :
        <div>
          <TriangleRight />
        </div>
      }
    </div>
  )
}