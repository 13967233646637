import React from 'react';

export function AddVoucherIcon() {
  return <svg width="20px" height="20px" viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg" className="add-vocher-icon">
    <title>Icons/Header/Add Voucher/Active</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="A7---1---My-Vouchers/New-Voucher" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
       transform="translate(-20.000000, -183.000000)">
      <g id="Icons/Header/Add-Voucher/Active" transform="translate(20.000000, 182.000000)">
        <g id="Icon-Copy-2">
          <g id="Group-Copy-11"
             transform="translate(9.000000, 9.000000) rotate(90.000000) translate(-9.000000, -9.000000) translate(1.000000, 0.000000)"
             stroke="#378EF8" strokeDasharray="3,2" strokeLinecap="round">
            <path d="M8,10 L8,14" id="Line"></path>
          </g>
          <path
            d="M6.0999999,16.1000004 L2.39999998,16.1000004 C1.57157285,16.1000004 0.899999976,15.4284275 0.899999976,14.6000004 L0.899999976,11.8199997 L0.899999976,11.8199997 C2.45744293,11.035716 3.08421236,9.13737148 2.29992867,7.57992853 C1.99543907,6.97526825 1.50466043,6.48448957 0.900000168,6.17999992 L0.899999976,6.17999983 L0.899999976,3.39999998 C0.899999976,2.57157285 1.57157285,1.89999998 2.39999998,1.89999998 L15.6000004,1.89999998 C16.4284275,1.89999998 17.1000004,2.57157285 17.1000004,3.39999998 L17.1000004,5.4000001 L17.1000004,6.0999999"
            id="Path-2" stroke="#378EF8" strokeWidth="1.8" strokeLinecap="round"></path>
          <path
            d="M14.6499755,13.3500977 L14.6499755,10.65 C14.6499755,10.2910149 14.3589606,10 13.9999756,10 C13.6409905,10 13.3499756,10.2910149 13.3499756,10.65 L13.3499756,13.3500977 L10.65,13.3500977 C10.2910149,13.3500977 10,13.6411126 10,14.0000976 C10,14.3590827 10.2910149,14.6500976 10.65,14.6500976 L13.3499756,14.6500976 L13.3499756,17.35 C13.3499756,17.7089851 13.6409905,18 13.9999756,18 C14.3589606,18 14.6499755,17.7089851 14.6499755,17.35 L14.6499755,14.6500976 L17.35,14.6500976 C17.7089851,14.6500976 18,14.3590827 18,14.0000976 C18,13.6411126 17.7089851,13.3500977 17.35,13.3500977 L14.6499755,13.3500977 Z M14,20 C10.6862915,20 8,17.3137085 8,14 C8,10.6862915 10.6862915,8 14,8 C17.3137085,8 20,10.6862915 20,14 C20,17.3137085 17.3137085,20 14,20 Z"
            id="Combined-Shape" fill="#378EF8"></path>
        </g>
      </g>
    </g>
  </svg>;
}

export function PurchaseDateIcon() {
  return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Float</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="A-1-Profile-Copy-33" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
       transform="translate(-82.000000, -498.000000)">
      <g id="Chemistry-Core" transform="translate(20.000000, 437.000000)">
        <g id="Date-purchased" transform="translate(62.000000, 61.000000)">
          <g id="Float">
            <circle id="Combined-Shape" fill="#BEC4C3" cx="10" cy="10" r="9.5"></circle>
            <g id="Check" transform="translate(6.000000, 7.000000)" stroke="#FFFFFF" strokeLinecap="round"
               strokeWidth="1.3">
              <polyline points="0 3.375 2.73913043 6 9 0"></polyline>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
}

export function RemainingDaysIcon() {
  return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icons/Profile/Time/Black</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="A-1-Profile-Copy-33" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
       transform="translate(-82.000000, -526.000000)">
      <g id="Chemistry-Core" transform="translate(20.000000, 437.000000)" stroke="#BEC4C3">
        <g id="Day-remaining" transform="translate(63.000000, 89.000000)">
          <g id="Icons/Profile/Time/Black" transform="translate(0.000000, 1.000000)">
            <g id="Group-2-Copy" strokeWidth="1.8">
              <path
                d="M17.9343066,8.96715328 C17.9343066,4.01472263 13.9195839,0 8.96715328,0 C4.01472263,0 0,4.01472263 0,8.96715328 C0,13.9196496 4.01472263,17.9343066 8.96715328,17.9343066 C13.9195839,17.9343066 17.9343066,13.9196496 17.9343066,8.96715328 Z"
                id="Stroke-1"></path>
            </g>
            <polyline id="Path-7" strokeWidth="1.3" strokeLinecap="round" points="9 4 9 9 11.7322231 11.5"></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
}
