import React from 'react';

export function InfoIcon() {
  return <svg className="info-icon" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Icons/Content/Legal-Info/Active" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-5" transform="translate(1.000000, 1.000000)">
        <circle id="Detail-Disclosure" stroke="#378EF8" strokeWidth="1.8" cx="9" cy="9" r="9"></circle>
        <polygon id="Stalk" fill="#378EF8" points="7.20059465 6.72566899 7.20059465 7.17552032 8.10029733 7.17552032 8.10029733 13.9232904 7.20059465 13.9232904 7.20059465 14.3731417 10.7994053 14.3731417 10.7994053 13.9232904 9.89970267 13.9232904 9.89970267 6.72566899"></polygon>
        <circle id="Tittle" fill="#378EF8" cx="8.77507433" cy="4.25148663" r="1.12462834"></circle>
      </g>
    </g>
  </svg>;
}
export function ReadIcon() {
  return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" r="7" fill="url(#paint0_linear)" />
      <path d="M10.3177 4.6853L5.65104 9.3139L3.60938 7.36297" stroke="white" strokeWidth="1.14545" strokeLinecap="round" />
      <defs>
        <linearGradient id="paint0_linear" x1="-13.1307" y1="13.8053" x2="14.4798" y2="40.0668" gradientUnits="userSpaceOnUse">
          <stop stopColor="#51D378" />
          <stop offset="1" stopColor="#51D4B3" /> </linearGradient>
      </defs>
    </svg>;
}