import React, { useMemo } from 'react';
import ExternalAvatar from 'v2/components/avatar/ExternalAvatar';
import InternalAvatarContainer from 'v2/components/avatar/InternalAvatarContainer';
import { isMBUserSelector, userDataSelector } from 'selectors/user';
import { useSelector } from 'react-redux';
import "./avatar.scss"

type UserAvatarContainerProps = {
  size: number,
  alternate?: boolean,
  initialFlashcard?: boolean,
  accountPortal?: boolean
}
const UserAvatarContainer: React.FC<UserAvatarContainerProps> = ({ size = 40, initialFlashcard, alternate, accountPortal }) => {
  const isMBUser = useSelector(isMBUserSelector);
  const userData = useSelector(userDataSelector)?.toJS();

  const fullName = useMemo(() => {
    return userData.firstName + ' ' + userData.lastName;
  }, [userData.firstName, userData.lastName])
  if (accountPortal ? isMBUser : isMBUser && userData.fromSchool === "YES") {
    return <ExternalAvatar userName={fullName} userId={userData.mbId} size={size} initialFlashcard={initialFlashcard} accountPortal={accountPortal} />;
  }
  return <InternalAvatarContainer alternate={alternate} size={size} initialFlashcard={initialFlashcard} />;
}

export default UserAvatarContainer;