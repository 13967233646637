import React, {memo, useMemo} from 'react'
import {titles, subtitles, contents} from './messages'
import './emptyScreen.scss'
import EmptyScreenButton from './EmptyScreenButton'
import { BookmarkSvg} from 'icons/FlashcardIcons';

type EmptyScreenMessageProps = {
    type: string;
    mediaType: string;
    className: string;
    currentId?: string | number;
    itemId?: number | string;
    isSmart?: boolean | number;
    canvasType?: string;
    children: any;
}  
const typeVal = ['topic', 'subtopic', 'filterTopic', 'filterSubTopic', 'filterExercise', 'filterSearch']
type colorProps = {
    [key: string]: string
}
const color: colorProps = {
    'repeat': 'text-#FBA74A',
    'memorized': 'text-#50D496',
}

const EmptyScreenMessage: React.FC<EmptyScreenMessageProps> = ({type, mediaType, className, currentId, itemId, isSmart, canvasType, children}) => {
    const isScreenTypeExist = useMemo(() => typeVal.includes(type), [type])
    const isCurrentIdWithCanvasType = (currentId && canvasType !== mediaType)

    const getTitle = () => {
        if(isScreenTypeExist){
            return titles[type][mediaType]
        }
        return titles[type]
    }

    const getSubtitle = () => {
        if(isScreenTypeExist){
            return subtitles[type][mediaType]
        }
        return subtitles[type]
    }

    const renderEmptyContent = () => {
        return type === "bookmark" ? renderEmptyBoormark(): renderEmptyContentDescription()
    }

    const renderEmptyBoormark = () => {
        if(!currentId || isCurrentIdWithCanvasType){
            return renderEmptyContentDescription()
        }
        return bookMarkContent()
    }

    const renderEmptyFolderContent = () => {
        const isFolderWithoutFilter = type === "folders" || type !== 'filterFolders' 
        return <div>
            <div className="empty-screen-subtitle">
                {isFolderWithoutFilter  ? "Adding media to a folder is very easy." : "Although this folder contains media, none matches the filter you set."}
            </div>
            <div className="empty-screen-folder-content">
                {isFolderWithoutFilter ? "To add the media currently on view to this folder, tap ‘ADD’ in the header." : <strong>To view the media available in this folder, change the filter above.</strong>}
            </div>
            {type === "folders"  && 
                <div className="empty-screen-folder-content">To remove it from this folder, tap ’ADDED’.</div>
            }
        </div>
    }
    

    const renderEmptyContentDescription = () => {
        return <>
            <div className="empty-screen-subtitle">{getSubtitle()}</div>
            {contents[type] && 
                <div className={`${["topic", "filterTopic", "subtopic", "filterSubTopic", "filterExercise", "filterSearch", "filterNotes"].includes(type) ? 'empty-topic-content' : 'empty-screen-content'}`}>
                    {contents[type][mediaType]}
                </div>
            }
          { (!isSmart) && (canvasType !== mediaType) && (["notes", "folders", "bookmark"].includes(type)) && <EmptyScreenButton deskType={type} />}
          { (isSmart) && (canvasType !== mediaType) && !isScreenTypeExist && type !== 'search' && <EmptyScreenButton deskType={'bookmark'} />}
        </>
    }

    const bookMarkContent = () => {
        return <>
                <div className='empty-screen-subtitle'>
                        Bookmark any card, page, video or exam question that you wish to return to later.
                </div>
                <div className="empty-screen-bookmark-content">
                    <div className='flex'>
                        <div >To bookmark the media currently on view, tap</div>  
                        <div ><BookmarkSvg /></div>    
                        <div > in </div>     
                    </div>
                    <div>the header.</div>
                </div>
                <div className="empty-screen-folder-content">To remove the bookmark, tap on it again.</div>
        </>
    }

    return <div className={`flex items-center justify-center self-center h-full ${className}`}>
       <div className='w-full text-center'>
            <div className='m-auto [&_svg]:block [&_svg]:mx-auto'>{children}</div>
            <div className={`text-17px font-bold leading-23px pt-3 ${color[type] ?? 'text-blue-normal' }`}>
                {getTitle()}
            </div>
            {
                type !== 'filterFolders' && (!currentId || (currentId && isSmart) || isCurrentIdWithCanvasType) ? renderEmptyContent() : renderEmptyFolderContent()
            }
       </div>
    </div>
}

export default EmptyScreenMessage;