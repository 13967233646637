import React, { useRef, useCallback } from 'react';
import VideoRenderer from './VideoRenderer';
import './video.scss'

type VideoCarouselProps = {
    order: number[];
    rendererIds: any; 
}

const VideoCarousel: React.FC<VideoCarouselProps> = ({order, rendererIds}) => {
    const canvasRef =useRef<HTMLUListElement>(null);

    const renderRenderers = useCallback(() => {
        let renderers = new Array(3);
        for (let i = 0; i < 3; i++) {  
          const videoId = rendererIds.get(i);
          const isMiddle = order[i] === 2;
          const display = !isMiddle ? 'none' : 'flex';
          renderers[i] = (
                <li className="carousel-seat" style={{ order: order[i], display: display }} key={videoId}>
                    {/*TODO extract to study container*/}
                    <div className={'full-height flex justify-center '} >
                        <div className='video-render-wrapper'>
                            <VideoRenderer
                                isVisible={isMiddle}
                                videoId={videoId} 
                            />
                        </div>
                    </div>
                </li>
          );
        }
    
        return renderers;
    }, [rendererIds, order]);

    return (
        <>
          <div className='video-carousel-wrapper'>
            <ul ref={canvasRef}>
                {renderRenderers()}
            </ul>
          </div>
        </>
      )
}

export default VideoCarousel;