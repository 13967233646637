import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getActiveSubject, subjectsTeachingLevel } from "selectors";
import usePrevious from "./usePrevious";
import { useEffect } from "react";

const useRedirectHLSL = () => {
  const history = useHistory();
  const subject = useSelector(getActiveSubject);
  const subjectId = subject?.id;
  const hasSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
  const activeTvl = hasSubjectsTeachingLevel[`${subjectId}`];
  const prevTvl = usePrevious(activeTvl);

  useEffect(() => {
    if (subject?.tagging === "NEW_SUBJECT" && activeTvl !== prevTvl) {
      history.push("/flashcard/topics");
    }
  }, [activeTvl, history, prevTvl, subject?.tagging]);
};

export default useRedirectHLSL;
