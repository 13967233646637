import { changeMenuPathAction } from 'actions/menuActions';
import React from 'react'
import { useDispatch } from 'react-redux';
import BackButton from 'v2/components/navigation-header/BackButton';

const BackToMenuButtonContainer = () => {
    const disptach = useDispatch();
    return <div className='cursor-pointer z-1' onClick={()=>disptach(changeMenuPathAction())}>
       <BackButton text="Settings" onClick={() => {}}/>
    </div>
}

export default BackToMenuButtonContainer;