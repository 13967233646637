import { CircledNumber } from 'ui-components/search-results/PreviewListItem';
import AnswerBulletContainer from 'v2/components/AnswerBullet/AnswerBulletContainer';

type TextProps = {
  id: number | string,
  occurences: number,
  shouldMark: boolean,
  type: string,
  questionType?:any
}
const PreviewRightSection: React.FC<TextProps> = ({ id, occurences, type, shouldMark, questionType = '' }) => {
  if (shouldMark) {
    return <div className='flex items-center'>
      {occurences > 0 && <CircledNumber>{occurences}</CircledNumber>}
      <div className='pl-2' />
      <AnswerBulletContainer id={id} type={type} isMcq={questionType?.includes('mcq')}/>
    </div>
  } else {
    return <AnswerBulletContainer id={id} type={type} isMcq={questionType?.includes('mcq')}/>;
  }
}
export default PreviewRightSection;