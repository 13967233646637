import * as Actions from 'actions';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import PreviewListItem, {PreviewListHeader} from 'ui-components/search-results/PreviewListItem';
import {isLinksNavigationSectionVisible, currentFlashcardId} from 'selectors';
import AnswerBulletContainer from 'v2/components/AnswerBullet/AnswerBulletContainer';
import { isMobilePortrait } from 'selectors/browser/browserSelector';
import {
  addBackFlashcardToLocalStorage, decodeQuery
} from 'helpers';
import appHistory from 'appHistory';
import * as Selectors from 'selectors';
class FlashcardLinksResultsList extends Component {
  onListItemClick = (flashcardId) => {
    const query = decodeQuery(appHistory.location.search.substring(1));
    if(!this.props.isLinksNavigationSectionVisible && this.props.isMobilePortrait){
      this.props.toggleLinksNavigationSection(false) 
    }
    if(flashcardId !== this.props.currentFlashcardId){
      addBackFlashcardToLocalStorage("flashcard"+"-"+flashcardId, query.mediaType+"-"+this.props.currentFlashcardId);
    }
    this.props.selectFlashcard(flashcardId);
  };

  renderPreviewRightSection = (flashcardId) => {
    return <AnswerBulletContainer id={flashcardId} />;
  };

  render() {
    const {newListItems,activeSubjectId} = this.props;
    return newListItems.map((item, index) => {
      const firstItem = item.get(0);
        return (
          <div key={firstItem.get('teachingLevel') + index}>
            <PreviewListHeader topicNumber={firstItem.get('topicNumbering')} index={index}
              teachingLevel={firstItem.get('teachingLevel')}/>
            {this.renderFlashcardPreview(item)}
          </div>
        );
    });
  }
  
  renderFlashcardPreview(flashcards) {
    return flashcards.sortBy(item => item.get('numbering')).map(item => {
      const flashcardId = item.get('flashcardId');
      const numbering = item.get('numbering');
      let content = item.get('content') || item.getIn(['preview', 'textContent', 0]) || '';
      const {activeSubjectId} = this.props;

      return <PreviewListItem
        deleted={item.get('deleted')}
        flashcardId={flashcardId}
        key={numbering}
        onClick={() => this.onListItemClick(flashcardId)}
        flashcardNumber={numbering}
        preview={content}
        previewTitle={item.get('title')}
        rightSection={this.renderPreviewRightSection(flashcardId)}
        activeMath={activeSubjectId === 15075 || activeSubjectId  === 14982}
      />;
    });
  }
}
const mapStateToProps = (state) => ({
  isLinksNavigationSectionVisible: isLinksNavigationSectionVisible(state),
  isMobilePortrait: isMobilePortrait(state),
  currentFlashcardId: currentFlashcardId(state),
  activeSubjectId: Selectors.activeSubjectId(state),
});
const mapDispatchToProps = {
  selectFlashcard: Actions.Studying.selectFlashcard,
  toggleLinksNavigationSection: Actions.Navigation.toggleLinksNavigationSection
};
export default connect(mapStateToProps, mapDispatchToProps)(FlashcardLinksResultsList);