import { ReactComponent as CloseIcon } from 'assets/icons/page-overlay/close-icon-turquoise.svg';
import React from 'react'
import { useHistory } from 'react-router-dom'

const Errors = {
    INVALID_GMAIL: 'This email ID is not yet linked to a ManageBac account. To use Google SSO, please log in via ManageBac first.',
    INVALID_TOKEN: 'This email ID is not yet linked to a ManageBac account. To use Google SSO, please log in via ManageBac first.',
}

type GoogleLoginErrorProps = {
    error: string
}
const GoogleLoginError: React.FC<GoogleLoginErrorProps> = ({ error }) => {
    const history = useHistory();
    return <div className='google-login-wrapper flex items-center justify-center fixed top-0 right-0 bottom-0 left-0 bg-[rgba(26,109,172,0.60)] z-[6] m-auto'>
        <div className='w-[347px] flex flex-col rounded-10px select-none pt-15px pr-14px pb-24px pl-5 bg-white text-#2D3838'>
            <div className='flex justify-between items-center pb-14px'>
                <div className='font-[700] text-19px leading-26px'>Please log in with MB</div>
                <div className='close-icon-wrapper p-1.5 rounded-full cursor-pointer'
                    onClick={() => history.push('/')}
                ><span className='h-5 w-5 rounded-full bg-blue-normal flex items-center justify-center'><CloseIcon /></span></div>
            </div>

            <div className='relative text-15px leading-19px'>
                <div className='active-bar absolute left-[-20px] bg-#FA9000 rounded-bl-5px rounded-tl-5px top-0 bottom-0 w-1' />
                {Errors[error as keyof typeof Errors]}
            </div>

            <div className='btn-wrapper bg-white rounded-[22px] py-2 mt-9 cursor-pointer flex items-center justify-center text-[13px] text-blue-normal font-bold tracking-2px leading-[18px]'
                onClick={() => history.push('/')}>OK</div>
        </div>
    </div>
}

export default GoogleLoginError