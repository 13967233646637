import React, {Component} from 'react';
import HeaderEditNotesTooltip from './HeaderEditNotesTooltip';
import EditNotePage from 'pages/edit-note/EditNotePage';
/**
 * @deprecated TODO- delete not use any more
 */
export default class MobileNoteList extends Component {
  render() {
    return(
      <div className="notes-tooltip-container">
        <HeaderEditNotesTooltip 
          onBack={this.props.onBack} 
          flashcardNumber={this.props.currentFlashcard.get('numbering')} 
          deleteConfirmationMode={this.props.deleteConfirmationMode} 
          toggleDeleteConfirmationMode={this.props.toggleDeleteConfirmationMode}
          toggleFocusTextarea={this.props.toggleFocusTextarea}
          isTextareaFocused={this.props.isTextareaFocused}
          textareaNote={this.props.textareaNote}/>
        <EditNotePage onDelete={this.props.onBack} />
      </div>
    );
  }
}
