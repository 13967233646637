import React from 'react';

export const TopicsIcon = (props) => props.active ? <TopicsIconActive {...props}/> : <TopicsIconInactive {...props}/>;
export const DeskIcon = (props) => props.active ? <DeskIconActive {...props}/> : <DeskIconInactive {...props}/>;
export const NoteIcon = (props) => props.active ? <NoteIconActive {...props}/> : <NoteIconInactive {...props}/>;
export const FolderIcon = (props) => props.active ? <FolderIconActive {...props}/> : <FolderIconInactive {...props}/>;
export const ExerciseIcon = (props) => props.active ? <ExerciseIconActive {...props}/> : <ExerciseIconInactive {...props}/>;
export const ExamsIcon = (props) => props.active ? <ExamsIconActive {...props} />:<ExamsIconInactive {...props}/>;
export const TrophiesIcon = (props) => props.active ? <TrophiesIconActive {...props}/> :
  <TrophiesIconInactive {...props}/>;
export const SearchIcon = (props) => props.active ? <SearchIconActive {...props}/> : <SearchIconInactive {...props}/>;
export const MenuToggleIcon = (props) => props.expanded ? <CloseMenuIcon/> : <ExpandMenuIcon/>;
export const ClassRoomIcon = ({active}) => active ? <ClassRoomIconActive/>: <ClassRoomIconInactive/>;

function ClassRoomIconActive() {
  return (
    <svg width="23px" height="22px" viewBox="0 0 23 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons/Control-Bar/Classroom/Selected---2" transform="translate(-8.000000, -8.000000)">
          <g id="Classroom">
            <rect id="Space" x="0" y="0" width="40" height="40"></rect>
            <g id="Group-2" transform="translate(8.000000, 8.000000)" fill="#2EBCB4">
              <circle id="5" cx="3.5" cy="9.5" r="3.5"></circle>
              <circle id="4" cx="6.5" cy="18.5" r="3.5"></circle>
              <circle id="3" cx="16.5" cy="18.5" r="3.5"></circle>
              <circle id="2" cx="19.5" cy="9.5" r="3.5"></circle>
              <circle id="1" cx="11.5" cy="3.5" r="3.5"></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

function ClassRoomIconInactive() {
  return (
    <svg  width="25px" height="24px" viewBox="0 0 25 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons/Control-Bar/Classroom/Passive---2" transform="translate(-7.000000, -7.000000)">
          <g id="Classroom">
            <rect id="Space" x="0" y="0" width="40" height="40"></rect>
            <g id="Group-2" transform="translate(8.000000, 8.000000)" stroke="#919191" strokeWidth="1.3">
              <circle id="Oval" cx="11.5" cy="3.5" r="3.5"></circle>
              <circle id="Oval-Copy-2" cx="19.5" cy="9.5" r="3.5"></circle>
              <circle id="Oval-Copy-3" cx="16.5" cy="18.5" r="3.5"></circle>
              <circle id="Oval-Copy-4" cx="6.5" cy="18.5" r="3.5"></circle>
              <circle id="Oval-Copy-5" cx="3.5" cy="9.5" r="3.5"></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

function TopicsIconActive(props) {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="5" y="3.8999" width="16" height="8" rx="2" stroke="#378EF8" strokeWidth="1.8"/>
  <rect x="5" y="15.1001" width="16" height="8" rx="2" stroke="#378EF8" strokeWidth="1.3"/>
  </svg>;
}

function TopicsIconInactive(props) {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="5" y="3.8999" width="16" height="8" rx="2" stroke="#A2A3A3" strokeWidth="1.8"/>
  <rect x="5" y="15.1001" width="16" height="8" rx="2" stroke="#A2A3A3" strokeWidth="1.3"/>
  </svg>;
}


function NoteIconActive(props) {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.65 4.75H7.75C6.09315 4.75 4.75 6.09315 4.75 7.75V18.25C4.75 19.9069 6.09315 21.25 7.75 21.25H18.25C19.9069 21.25 21.25 19.9069 21.25 18.25V11.35" stroke="#378EF8" strokeWidth="1.8" strokeLinecap="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0381 15.0209C13.128 14.7664 14.125 14.2121 14.9164 13.4207L21.5725 6.76465C21.9955 6.34166 22.1087 5.28024 21.5628 4.64322L21.3069 4.38107C20.67 3.82154 19.5944 3.93352 19.1678 4.36001L12.5117 11.0161C11.7203 11.8075 11.1661 12.8045 10.9116 13.8944L10.5684 15.3641L12.0381 15.0209Z" stroke="#378EF8" strokeWidth="1.3"/>
  </svg>;
}

function NoteIconInactive(props) {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.65 4.75H7.75C6.09315 4.75 4.75 6.09315 4.75 7.75V18.25C4.75 19.9069 6.09315 21.25 7.75 21.25H18.25C19.9069 21.25 21.25 19.9069 21.25 18.25V11.35" stroke="#A2A3A3" strokeWidth="1.8" strokeLinecap="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0381 15.0209C13.128 14.7664 14.125 14.2121 14.9164 13.4207L21.5725 6.76465C21.9955 6.34166 22.1087 5.28024 21.5628 4.64322L21.3069 4.38107C20.67 3.82154 19.5944 3.93352 19.1678 4.36001L12.5117 11.0161C11.7203 11.8075 11.1661 12.8045 10.9116 13.8944L10.5684 15.3641L12.0381 15.0209Z" stroke="#A2A3A3" strokeWidth="1.3"/>
  </svg>;
}

function FolderIconActive(props) {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.39992 21.3999C5.29535 21.3999 4.39991 20.5045 4.39991 19.3999V7.3999C4.39991 6.29533 5.29534 5.3999 6.39991 5.3999L9.60022 5.3999C10.4294 5.3999 11.2217 5.74313 11.7888 6.34808L12.8999 7.53324H19.8999C21.0045 7.53324 21.8999 8.42867 21.8999 9.53324V19.3998C21.8999 20.5044 21.0045 21.3998 19.8999 21.3998L6.39992 21.3999Z" stroke="#378EF8" strokeWidth="1.8" strokeLinecap="round"/>
    <path d="M21.3999 10.3999H4.3999" stroke="#378EF8" strokeWidth="1.3" strokeLinecap="square"/>
  </svg>;
}

function FolderIconInactive(props) {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.39992 21.3999C5.29535 21.3999 4.39991 20.5045 4.39991 19.3999V7.3999C4.39991 6.29533 5.29534 5.3999 6.39991 5.3999L9.60022 5.3999C10.4294 5.3999 11.2217 5.74313 11.7888 6.34808L12.8999 7.53324H19.8999C21.0045 7.53324 21.8999 8.42867 21.8999 9.53324V19.3998C21.8999 20.5044 21.0045 21.3998 19.8999 21.3998L6.39992 21.3999Z" stroke="#A2A3A3" strokeWidth="1.8" strokeLinecap="round"/>
    <path d="M21.3999 10.3999H4.3999" stroke="#A2A3A3" strokeWidth="1.3" strokeLinecap="square"/>
  </svg>;
}

function ExerciseIconActive(props) {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="5" width="5" height="16" rx="1.5" stroke="#378EF8" strokeWidth="1.8"/>
    <path d="M1 13C1 11.8954 1.89543 11 3 11V11V15V15C1.89543 15 1 14.1046 1 13V13Z" stroke="#378EF8" strokeWidth="1.3"/>
    <path d="M25 13C25 11.8954 24.1046 11 23 11V11V15V15C24.1046 15 25 14.1046 25 13V13Z" stroke="#378EF8" strokeWidth="1.3"/>
    <rect x="8" y="11" width="10" height="4" stroke="#378EF8" strokeWidth="1.3"/>
    <rect x="18" y="5" width="5" height="16" rx="1.5" stroke="#378EF8" strokeWidth="1.8"/>
  </svg>;
}

function ExerciseIconInactive(props) {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="5" width="5" height="16" rx="1.5" stroke="#A2A3A3" strokeWidth="1.8"/>
    <path d="M1 13C1 11.8954 1.89543 11 3 11V11V15V15C1.89543 15 1 14.1046 1 13V13Z" stroke="#A2A3A3" strokeWidth="1.3"/>
    <path d="M25 13C25 11.8954 24.1046 11 23 11V11V15V15C24.1046 15 25 14.1046 25 13V13Z" stroke="#A2A3A3" strokeWidth="1.3"/>
    <rect x="8" y="11" width="10" height="4" stroke="#A2A3A3" strokeWidth="1.3"/>
    <rect x="18" y="5" width="5" height="16" rx="1.5" stroke="#A2A3A3" strokeWidth="1.8"/>
  </svg>;
}

function DeskIconActive(props) {
  return <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1"
    xmlns="http://www.w3.org/2000/svg" >
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons/Control-Bar/Desk/Selected---2" transform="translate(-9.000000, -9.000000)">
        <g id="Group" transform="translate(8.500000, 9.500000)">
          <path
            d="M2.50978153,9.58695652 L20.4902185,9.58695652 L20.4902185,9.58695652 C21.594788,9.58695652 22.4902185,8.69152602 22.4902185,7.58695652 C22.4902185,7.43671617 22.4732895,7.28695421 22.439752,7.14050491 L20.9980332,0.844908835 L20.9980332,0.844908835 C20.94599,0.617650251 20.7437914,0.456521739 20.5106499,0.456521739 L2.48935015,0.456521739 L2.48935015,0.456521739 C2.25620865,0.456521739 2.05400996,0.617650251 2.00196676,0.844908835 L0.560248011,7.14050491 L0.560248011,7.14050491 C0.313679592,8.21720254 0.986632283,9.28992162 2.06332992,9.53649004 C2.20977922,9.57002757 2.35954118,9.58695652 2.50978153,9.58695652 Z"
            id="Path-5" fill="#2EBCB4"></path>
          <path d="M3.2972028,11.8695652 L3.2972028,19.48627" id="Line" stroke="#2EBCB4" strokeWidth="1.5"
            strokeLinecap="round"></path>
          <path
            d="M11.5,10.9565217 L20.1853147,10.9565217 L20.1853147,18.269565 C20.1853147,19.3741345 19.2898842,20.269565 18.1853147,20.269565 L13.5,20.269565 C12.3954305,20.269565 11.5,19.3741345 11.5,18.269565 L11.5,10.9565217 Z M15.8426573,16.0695648 C16.508875,16.0695648 17.048951,15.5047982 17.048951,14.8081232 C17.048951,14.1114482 16.508875,13.5466815 15.8426573,13.5466815 C15.1764397,13.5466815 14.6363636,14.1114482 14.6363636,14.8081232 C14.6363636,15.5047982 15.1764397,16.0695648 15.8426573,16.0695648 Z"
            id="Combined-Shape" fill="#2EBCB4"></path>
        </g>
      </g>
    </g>
  </svg>
  ;
}

function DeskIconInactive(props) {
  return <svg width="23px" height="21px" viewBox="0 0 23 21" version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons/Control-Bar/Desk/Passive---2" transform="translate(-9.000000, -10.000000)">
        <g id="Group" transform="translate(9.000000, 10.000000)">
          <path
            d="M20.3911175,1.10652174 L2.60888246,1.10652174 L1.19384641,7.28560168 C1.02741272,8.01237258 1.48165579,8.73645797 2.20842669,8.90289165 C2.30727997,8.92552948 2.40836929,8.93695652 2.50978153,8.93695652 L20.4902185,8.93695652 C21.2358029,8.93695652 21.8402185,8.33254093 21.8402185,7.58695652 C21.8402185,7.48554428 21.8287914,7.38445496 21.8061536,7.28560168 L20.3911175,1.10652174 Z"
            id="Path-5" stroke="#919191" strokeWidth="1.3"></path>
          <path d="M3.2972028,9.39473684 L3.2972028,19.48627" id="Line" stroke="#919191" strokeWidth="1.2"
            strokeLinecap="round"></path>
          <path
            d="M12.15,8.93000069 L12.15,18.3898405 C12.15,19.1354249 12.7544156,19.7398405 13.5,19.7398405 L18.1853147,19.7398405 C18.9308991,19.7398405 19.5353147,19.1354249 19.5353147,18.3898405 L19.5353147,8.93000069 L12.15,8.93000069 Z"
            id="Rectangle-14" stroke="#919191" strokeWidth="1.3"></path>
          <ellipse id="Oval-8" fill="#919191" cx="15.8426573" cy="14.8081232" rx="1.20629371" ry="1.26144165"></ellipse>
        </g>
      </g>
    </g>
  </svg>;
}

function TrophiesIconActive(props) {
  return <svg width="24px" height="22px" viewBox="0 0 24 22" version="1.1"
    xmlns="http://www.w3.org/2000/svg" >
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons/Control-Bar/Medals/Selected---2" transform="translate(-8.000000, -9.000000)">
        <g id="Group" transform="translate(9.000000, 9.300000)">
          <path
            d="M5,0 L17,0 L17,0 C17.8284271,-1.52179594e-16 18.5,0.671572875 18.5,1.5 L18.5,7.5 L18.5,7.5 C18.5,11.6421356 15.1421356,15 11,15 L11,15 L11,15 C6.85786438,15 3.5,11.6421356 3.5,7.5 L3.5,1.5 L3.5,1.5 C3.5,0.671572875 4.17157288,1.52179594e-16 5,0 Z"
            id="Rectangle" fill="#2EBCB4"></path>
          <path
            d="M17,2.5 L21.4781443,2.5 L21.4781443,2.49999072 C21.7542918,2.49999072 21.9781535,2.7238525 21.9781535,3 C21.9781535,3.01793298 21.9771888,3.03585298 21.9752635,3.05368231 C21.487337,7.57207169 19.8289158,9.82079601 17,9.79985529"
            id="Path-6" stroke="#2EBCB4" strokeWidth="1.5"></path>
          <path
            d="M15.3694161,17.5385913 C15.3694161,17.5385913 13.7000927,17.1369644 12.8400371,16.0178491 C12.2085264,15.195305 12.1881372,14 12.1881372,14 L11.8126043,14 L10.3749768,14 L9.81241891,14 C9.81241891,14 9.79202966,15.195305 9.15996293,16.0178491 C8.29990732,17.1369644 6.63058387,17.5385913 6.63058387,17.5385913 C6.25208526,17.5845272 6,17.8197579 6,18.1159126 L6,19.9040822 C6,20.2329889 6.35644115,20.5 6.79573679,20.5 L15.2040778,20.5 C15.6435589,20.5 16,20.2329889 16,19.9040822 L16,18.1159126 C16,17.8197579 15.7482854,17.5845272 15.3694161,17.5385913 Z"
            id="Page-1" stroke="#2EBCB4" strokeWidth="1.5"></path>
          <path
            d="M1.42108547e-14,2.5 L4.47789874,2.5 L4.47789874,2.49997171 C4.75405674,2.49997171 4.97792704,2.723842 4.97792704,3 C4.97792704,3.01801903 4.97695304,3.03602488 4.9750093,3.05393877 C4.48676131,7.5537306 2.82842487,9.79319742 1.42108547e-14,9.77233925"
            id="Path-6" stroke="#2EBCB4" strokeWidth="1.5"
            transform="translate(2.488964, 6.136228) scale(-1, 1) translate(-2.488964, -6.136228) "></path>
          <path
            d="M6.66573931,17.7999992 L15.2633726,17.7999992 L15.2633726,17.7999992 C15.318601,17.7999992 15.3633726,17.7552277 15.3633726,17.6999992 C15.3633726,17.6593523 15.3387692,17.6227495 15.3011311,17.6074018 L12.6571489,16.5292626 L11.2760858,14.340647 L10.4715875,14.340647 L9.18316842,16.5292626 L6.62686432,17.6078649 L6.62686432,17.6078649 C6.57597994,17.629335 6.55213492,17.6879898 6.57360498,17.7388742 C6.58923545,17.7759187 6.62553232,17.7999992 6.66573931,17.7999992 Z"
            id="Path-2" stroke="#2EBCB4" fill="#2EBCB4"></path>
        </g>
      </g>
    </g>
  </svg>;
}
function TrophiesIconInactive(props) {
  return <svg width="24px" height="22px" viewBox="0 0 24 22" version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons/Control-Bar/Medals/Passive---2" transform="translate(-8.000000, -9.000000)" stroke="#919191"
        strokeWidth="1.3">
        <g id="Group" transform="translate(9.000000, 9.000000)">
          <path
            d="M5,0.65 C4.53055796,0.65 4.15,1.03055796 4.15,1.5 L4.15,7.5 C4.15,11.2831505 7.21684946,14.35 11,14.35 C14.7831505,14.35 17.85,11.2831505 17.85,7.5 L17.85,1.5 C17.85,1.03055796 17.469442,0.65 17,0.65 L5,0.65 Z"
            id="Rectangle"></path>
          <path
            d="M17.3999996,2.5 L21.4780633,2.5 L21.4780633,2.49991023 C21.7542553,2.49991023 21.9781531,2.72380804 21.9781531,3 C21.9781531,3.01793594 21.9771882,3.03585889 21.9752625,3.05369116 C21.4873348,7.57207462 19.828914,9.820796 17,9.79985529"
            id="Path-6"></path>
          <path
            d="M15.3694161,17.9385909 C15.3694161,17.9385909 13.7000927,17.536964 12.8400371,16.4178488 C12.2085264,15.5953046 12.1881372,14.3999996 12.1881372,14.3999996 L11.8126043,14.3999996 L10.3749768,14.3999996 L9.81241891,14.3999996 C9.81241891,14.3999996 9.79202966,15.5953046 9.15996293,16.4178488 C8.29990732,17.536964 6.63058387,17.9385909 6.63058387,17.9385909 C6.25208526,17.9845268 6,18.2197575 6,18.5159123 L6,20.3040819 C6,20.6329885 6.35644115,20.8999996 6.79573679,20.8999996 L15.2040778,20.8999996 C15.6435589,20.8999996 16,20.6329885 16,20.3040819 L16,18.5159123 C16,18.2197575 15.7482854,17.9845268 15.3694161,17.9385909 Z"
            id="Page-1"></path>
          <path
            d="M0.397926931,2.5 L4.47792979,2.5 L4.47792979,2.50000276 C4.75407065,2.50000276 4.97792704,2.72385915 4.97792704,3 C4.97792704,3.01801788 4.9769531,3.03602259 4.97500949,3.05393534 C4.48676197,7.55372946 2.82842541,9.79319743 -1.82234133e-07,9.77233925"
            id="Path-6" transform="translate(2.488963, 6.136242) scale(-1, 1) translate(-2.488963, -6.136242) "></path>
        </g>
      </g>
    </g>
  </svg>;
}

function SearchIconActive(props) {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10.4082" cy="10.4092" r="7.5" stroke="#378EF8" strokeWidth="1.8"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.673 14.5808C17.575 14.5524 17.4715 14.5371 17.3643 14.5371C16.7543 14.5371 16.2598 15.0316 16.2598 15.6417C16.2598 16.2517 16.7543 16.7462 17.3643 16.7462C17.5107 16.7462 17.6505 16.7177 17.7784 16.666C17.5111 16.3747 17.3479 15.9863 17.3479 15.5598C17.3479 15.1927 17.4688 14.8538 17.673 14.5808Z" fill="#378EF8"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.5632 17.6906C14.5348 17.5926 14.5195 17.489 14.5195 17.3819C14.5195 16.7719 15.0141 16.2773 15.6241 16.2773C16.2341 16.2773 16.7286 16.7719 16.7286 17.3819C16.7286 17.5283 16.7001 17.6681 16.6484 17.796C16.3571 17.5287 15.9687 17.3655 15.5423 17.3655C15.1751 17.3655 14.8363 17.4864 14.5632 17.6906Z" fill="#378EF8"/>
    <path d="M15.8633 15.8633L21.9242 21.9242" stroke="#378EF8" strokeWidth="1.8" strokeLinecap="round"/>
  </svg>;
}

function SearchIconInactive(props) {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10.4082" cy="10.4092" r="7.5" stroke="#A2A3A3" strokeWidth="1.8"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.673 14.5808C17.575 14.5524 17.4715 14.5371 17.3643 14.5371C16.7543 14.5371 16.2598 15.0316 16.2598 15.6417C16.2598 16.2517 16.7543 16.7462 17.3643 16.7462C17.5107 16.7462 17.6505 16.7177 17.7784 16.666C17.5111 16.3747 17.3479 15.9863 17.3479 15.5598C17.3479 15.1927 17.4688 14.8538 17.673 14.5808Z" fill="#A2A3A3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.5632 17.6906C14.5348 17.5926 14.5195 17.489 14.5195 17.3819C14.5195 16.7719 15.0141 16.2773 15.6241 16.2773C16.2341 16.2773 16.7286 16.7719 16.7286 17.3819C16.7286 17.5283 16.7001 17.6681 16.6484 17.796C16.3571 17.5287 15.9687 17.3655 15.5423 17.3655C15.1751 17.3655 14.8363 17.4864 14.5632 17.6906Z" fill="#A2A3A3"/>
    <path d="M15.8633 15.8633L21.9242 21.9242" stroke="#A2A3A3" strokeWidth="1.8" strokeLinecap="round"/>
  </svg>;
}

function ExpandMenuIcon(props) {
  return <svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Modules/Card/Overlays/Subject-Manager---Contracted" transform="translate(-7.000000, -16.000000)"
        fill="#2EBCB4">
        <g id="Subject-Manager---Contracted">
          <g id="Triangle/Active"
            transform="translate(11.000000, 22.000000) scale(-1, 1) translate(-11.000000, -22.000000) translate(7.000000, 16.000000)">
            <path
              d="M4.4,2.53333333 L9.4,9.2 L9.4,9.2 C9.56568542,9.4209139 9.5209139,9.73431458 9.3,9.9 C9.21345191,9.96491106 9.10818511,10 9,10 L-1,10 L-1,10 C-1.27614237,10 -1.5,9.77614237 -1.5,9.5 C-1.5,9.39181489 -1.46491106,9.28654809 -1.4,9.2 L3.6,2.53333333 L3.6,2.53333333 C3.76568542,2.31241943 4.0790861,2.26764791 4.3,2.43333333 C4.33790283,2.46176046 4.37157288,2.4954305 4.4,2.53333333 Z"
              id="Triangle-2-Copy-2"
              transform="translate(4.000000, 6.000000) rotate(-90.000000) translate(-4.000000, -6.000000) "></path>
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

function CloseMenuIcon(props) {
  return <svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Modules/Card/Overlays/Subject-Manager/Biology---Regular" transform="translate(-375.000000, -341.000000)"
        fill="#2EBCB4">
        <g id="Subject-Manager">
          <g id="Triangle/Active" transform="translate(375.000000, 341.000000)">
            <path
              d="M4.4,2.53333333 L9.4,9.2 L9.4,9.2 C9.56568542,9.4209139 9.5209139,9.73431458 9.3,9.9 C9.21345191,9.96491106 9.10818511,10 9,10 L-1,10 L-1,10 C-1.27614237,10 -1.5,9.77614237 -1.5,9.5 C-1.5,9.39181489 -1.46491106,9.28654809 -1.4,9.2 L3.6,2.53333333 L3.6,2.53333333 C3.76568542,2.31241943 4.0790861,2.26764791 4.3,2.43333333 C4.33790283,2.46176046 4.37157288,2.4954305 4.4,2.53333333 Z"
              id="Triangle-2-Copy-2"
              transform="translate(4.000000, 6.000000) rotate(-90.000000) translate(-4.000000, -6.000000) "></path>
          </g>
        </g>
      </g>
    </g>
  </svg>;
}
export function ClosePopUp(props) {
  return <svg width="16px" height="16px" viewBox="0 0 16 16">
    <defs></defs>
    <g id="Icons/Notification/Close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(-2.000000, -2.000000)" opacity="0.6">
      <g id="Group-2-Copy" transform="translate(2.000000, 2.000000)" fill={props.color || '#A4A4A4'}>
        <path d="M7.02236132,7.9723672 L4.75961958,10.2351089 C4.5057788,10.4889497 4.5057788,10.9005069 4.75961958,11.1543477 C5.01346036,11.4081885 5.42501758,11.4081885 5.67885836,11.1543477 L7.9416001,8.89160599 L10.2043418,11.1543477 C10.4581826,11.4081885 10.8697398,11.4081885 11.1235806,11.1543477 C11.3774214,10.9005069 11.3774214,10.4889497 11.1235806,10.2351089 L8.86083888,7.9723672 L11.1235806,5.70962547 C11.3774214,5.45578469 11.3774214,5.04422747 11.1235806,4.79038669 C10.8697398,4.53654591 10.4581826,4.53654591 10.2043418,4.79038669 L7.9416001,7.05312842 L5.67885836,4.79038669 C5.42501758,4.53654591 5.01346036,4.53654591 4.75961958,4.79038669 C4.5057788,5.04422747 4.5057788,5.45578469 4.75961958,5.70962547 L7.02236132,7.9723672 Z M15.9416058,7.97080292 C15.9416058,12.3730219 12.3729635,15.9416058 7.97080292,15.9416058 C3.56864234,15.9416058 0,12.3730219 0,7.97080292 C0,3.56864234 3.56864234,0 7.97080292,0 C12.3729635,0 15.9416058,3.56864234 15.9416058,7.97080292 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </svg>;
}

export function WhiteTriangleDown(props) {
  return <svg width="12px" height="9px" viewBox="0 0 12 9">
    <defs></defs>
    <g id="Icons/Control-Bar/CTA/Top/Online-(Regular)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(-25.000000, -27.000000)">
      <g id="CTA/Bottom/Downloading" transform="translate(4.000000, 4.000000)" fill="#FFFFFF">
        <g id="Icons/Content/Triangle/White" transform="translate(27.000000, 28.000000) rotate(270.000000) translate(-27.000000, -28.000000) translate(18.000000, 19.000000)">
          <path d="M9.91042172,5.21264825 L14.9352781,12.2037447 C15.0964449,12.4279764 15.0453206,12.7404037 14.8210888,12.9015704 C14.7360584,12.9626861 14.6339859,12.9955629 14.5292706,12.9955629 L4.4795578,12.9955629 C4.20341542,12.9955629 3.9795578,12.7717053 3.9795578,12.4955629 C3.9795578,12.3908476 4.01243461,12.2887751 4.07355027,12.2037447 L9.09840667,5.21264825 C9.25957344,4.98841649 9.57200068,4.93729221 9.79623244,5.09845898 C9.84023143,5.13008329 9.87879741,5.16864927 9.91042172,5.21264825 Z" id="Triangle-2-Copy-2" transform="translate(9.504433, 8.999996) rotate(-90.000000) translate(-9.504433, -8.999996) "></path>
        </g>
      </g>
    </g>
  </svg>;
}

export function DeleteTrashIcon(props) {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id="deletetrash">
    <path d="M17 2.95918H13.1681C13.1331 2.55103 13.0106 1.86272 12.5912 1.42093C12.3258 1.14171 11.9892 1 11.5908 1H8.40915C8.01078 1 7.67416 1.14171 7.40876 1.42093C6.98937 1.86272 6.86691 2.55096 6.83185 2.95918H3" stroke={props.color || "#FFFFFF"} strokeWidth="1.3" strokeLinecap="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.49982 17.075C4.51054 17.5679 4.82006 18.5 5.9173 18.5H14.0826C15.1799 18.5 15.4895 17.5679 15.4999 17.0812L16.0245 5.60457C16.0271 5.54761 15.9816 5.5 15.9246 5.5H4.07537C4.01835 5.5 3.97287 5.54761 3.97547 5.60457L4.49982 17.075Z" stroke={props.color || "#FFFFFF"} strokeWidth="1.8"/>
    <path d="M12.8 8L12.5 16" stroke={props.color || "#FFFFFF"} strokeWidth="1.3" strokeLinecap="round"/>
    <path d="M10 8V16" stroke={props.color || "#FFFFFF"} strokeWidth="1.3" strokeLinecap="round"/>
    <path d="M7.2002 8L7.5002 16" stroke={props.color || "#FFFFFF"} strokeWidth="1.3" strokeLinecap="round"/>
  </svg>
  ;
}

export function MoreIcon(props) {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7 12.9977C7 11.8944 6.10457 11 5 11C3.89551 11 3 11.8944 3 12.9977C3 14.101 3.89551 14.9954 5 14.9954C6.10457 14.9954 7 14.101 7 12.9977Z" stroke="#A2A3A3" strokeWidth="1.3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15 13C15 11.8954 14.1046 11 13 11C11.8955 11 11 11.8954 11 13C11 14.1046 11.8955 15 13 15C14.1046 15 15 14.1046 15 13Z" stroke="#A2A3A3" strokeWidth="1.3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M23 13C23 11.8954 22.1046 11 21 11C19.8955 11 19 11.8954 19 13C19 14.1046 19.8955 15 21 15C22.1046 15 23 14.1046 23 13Z" stroke="#A2A3A3" strokeWidth="1.3"/>
  </svg>;
}
export function QuestionInfoIcon(props){
  return <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="11" cy="11" r="10" stroke="#A2A3A3" strokeWidth="1.8"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M10.2632 13.7969C10.4386 13.6077 10.6882 13.5132 11.0117 13.5132C11.3353 13.5132 11.5837 13.6055 11.7568 13.79C11.93 13.9746 12.0166 14.2332 12.0166 14.5659C12.0166 15.2541 11.6816 15.5981 11.0117 15.5981C10.6836 15.5981 10.4329 15.5081 10.2598 15.3281C10.0866 15.1481 10 14.894 10 14.5659C10 14.2424 10.0877 13.986 10.2632 13.7969ZM10.3 6.7999C10.3 6.57899 10.4791 6.3999 10.7 6.3999H11.3C11.5209 6.3999 11.7 6.57899 11.7 6.7999L11.7 11.9999C11.7 12.2208 11.5209 12.3999 11.3 12.3999H10.7C10.4791 12.3999 10.3 12.2208 10.3 11.9999L10.3 6.7999Z" fill="#A2A3A3"/>
  </svg>
  
}

export function LinkIcon(props) {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8962 5.31127C18.1509 3.56291 15.3114 3.56291 13.5662 5.31127L6.9014 11.9878C6.69572 12.1938 6.69572 12.5276 6.9014 12.7337C7.10709 12.9397 7.44031 12.9397 7.646 12.7337L14.3107 6.05718C15.6045 4.76216 17.8578 4.76216 19.1515 6.05718C20.4859 7.39392 20.4859 9.56932 19.1515 10.9061L9.84513 20.3339C9.04913 21.1313 7.66228 21.1313 6.86631 20.3339C6.04511 19.5113 6.04511 18.173 6.86631 17.3503L15.8004 8.29487C16.0987 7.99611 16.619 7.99611 16.9173 8.29487C17.2253 8.6034 17.2253 9.10568 16.9173 9.41424L8.35599 18.0962C8.17841 18.2741 8.17841 18.6642 8.35599 18.8421C8.56167 19.0481 8.89489 19.0481 9.10058 18.8421L17.6619 10.1601C18.3808 9.43994 18.3808 8.2691 17.6619 7.54896C16.9656 6.8515 15.751 6.85252 15.0558 7.54896L6.12172 16.6044C4.89953 17.8287 4.89953 19.8554 6.12172 21.0798C6.71874 21.6779 7.51215 22 8.35595 22C9.19975 22 9.99323 21.6779 10.5897 21.0798L19.8961 11.6519C21.6414 9.90358 21.6414 7.05959 19.8962 5.31127Z" fill={props.color || "#A2A3A3"} stroke={props.color || "#A2A3A3"} strokeWidth="0.4"/>
  </svg>;
}
export const NotificationNoteIcon = (props) => {
  return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.88839 2.55762H4.173C3.28085 2.55762 2.55762 3.28085 2.55762 4.173V9.82685C2.55762 10.719 3.28085 11.4422 4.173 11.4422H9.82685C10.719 11.4422 11.4422 10.719 11.4422 9.82685V6.11146" stroke="white" strokeWidth="1.3" strokeLinecap="round"/>
    <path d="M7.57207 6.76687C7.29168 7.04727 6.95128 7.25846 6.57798 7.38539C6.7049 7.0121 6.91609 6.67171 7.19648 6.39133L10.7637 2.82407C10.7656 2.82329 10.7679 2.82242 10.7705 2.82147C10.7934 2.81321 10.8292 2.8052 10.8727 2.80397C10.9548 2.80165 11.0083 2.82303 11.0337 2.83981L11.1232 2.93153C11.14 2.95661 11.1618 3.01064 11.1591 3.0934C11.1577 3.13622 11.1497 3.17135 11.1416 3.19357C11.1408 3.19591 11.14 3.19794 11.1393 3.19968L7.57207 6.76687ZM10.7565 2.82736C10.7558 2.82778 10.7554 2.82797 10.7554 2.82796L10.7565 2.82736Z" stroke="white" strokeWidth="1.3"/>
  </svg>;
}
export const NotificationExerciseIcon = (props) => {
    return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.61548" y="2.69238" width="2.69231" height="8.61539" rx="0.807692" stroke="white" strokeWidth="1.3"/>
      <path d="M0.538574 7.00002C0.538574 6.40525 1.02073 5.9231 1.6155 5.9231V8.07694C1.02073 8.07694 0.538574 7.59479 0.538574 7.00002Z" fill="white" stroke="white" strokeWidth="0.7"/>
      <path d="M13.4617 7.00002C13.4617 6.40525 12.9795 5.9231 12.3847 5.9231V8.07694C12.9795 8.07694 13.4617 7.59479 13.4617 7.00002Z" fill="white" stroke="white" strokeWidth="0.7"/>
      <rect x="4.30786" y="6.19238" width="5.38462" height="1.61538" fill="white" stroke="white" strokeWidth="0.7"/>
      <rect x="9.69238" y="2.69238" width="2.69231" height="8.61539" rx="0.807692" stroke="white" strokeWidth="1.3"/>
    </svg>;
}

export const CloseToggleMenu = (props) => {
  return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.2426 4.75729C12.9823 4.49694 12.5602 4.49694 12.2998 4.75729L9 8.05712L5.70017 4.75729C5.43982 4.49694 5.01771 4.49694 4.75736 4.75729C4.49701 5.01764 4.49701 5.43975 4.75736 5.7001L8.05719 8.99993L4.75736 12.2998C4.49701 12.5601 4.49701 12.9822 4.75736 13.2426C5.01771 13.5029 5.43982 13.5029 5.70017 13.2426L9 9.94274L12.2998 13.2426C12.5602 13.5029 12.9823 13.5029 13.2426 13.2426C13.503 12.9822 13.503 12.5601 13.2426 12.2998L9.94281 8.99993L13.2426 5.7001C13.503 5.43975 13.503 5.01764 13.2426 4.75729Z" fill="white"/>
  </svg>;
}

export const CollapseIconMenu = (props) => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.03577 3.16317V3C6.03577 1.89543 6.9312 1 8.03577 1H17.0358C18.1403 1 19.0358 1.89543 19.0358 3V17C19.0358 18.1046 18.1403 19 17.0358 19H8.03577C6.9312 19 6.03577 18.1046 6.03577 17V16.8814" stroke={props.color ? props.color :"#378EF8"} strokeWidth="1.8"/>
  <path d="M13.4 7.5L16.5 7.5" stroke={props.color ? props.color :"#378EF8"} strokeLinecap="round"/>
  <path d="M11.5 4.5L16.5 4.5" stroke={props.color ? props.color :"#378EF8"} strokeLinecap="round"/>
  <path d="M13.8 10.5L16.5 10.5" stroke={props.color ? props.color :"#378EF8"} strokeLinecap="round"/>
  <path d="M13 13.5H16.5" stroke={props.color ? props.color :"#378EF8"} strokeLinecap="round"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M6 16C9.31371 16 12 13.3137 12 10C12 6.68629 9.31371 4 6 4C2.68629 4 0 6.68629 0 10C0 13.3137 2.68629 16 6 16ZM6.49468 13.1693L3.3 10L6.49468 6.83068C6.67919 6.64762 6.97681 6.64762 7.16132 6.83068C7.34793 7.0158 7.34793 7.31753 7.16132 7.50266L4.644 10L7.16132 12.4973C7.34793 12.6825 7.34793 12.9842 7.16132 13.1693C6.97681 13.3524 6.67919 13.3524 6.49468 13.1693Z" fill={props.color ? props.color :"#378EF8"}/>
  </svg>;
}
export const ExpandIconMenu = (props) => {
  return <svg width="26" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.9" y="0.9" width="18.2" height="11.2" rx="2.1" transform="matrix(0 1 1 0 3 3)" stroke="#378EF8" strokeWidth="1.8"/>
  <path d="M7 7.5H12" stroke="#378EF8" strokeLinecap="round"/>
  <path d="M7 10.5H12" stroke="#378EF8" strokeLinecap="round"/>
  <path d="M7 13.5H12" stroke="#378EF8" strokeLinecap="round"/>
  <path d="M7 16.5H12" stroke="#378EF8" strokeLinecap="round"/>
  <circle r="7.75" transform="matrix(-1 0 0 1 18 13)" fill="#378EF8" stroke="white" strokeWidth="1.5"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M20.7998 13L17.1487 16.6221C16.9379 16.8313 16.5977 16.8313 16.3869 16.6221V16.6221C16.1736 16.4105 16.1736 16.0657 16.3869 15.8541L19.2638 13L16.3869 10.1459C16.1736 9.93432 16.1736 9.58949 16.3869 9.37792V9.37792C16.5977 9.16871 16.9379 9.16871 17.1487 9.37792L20.7998 13Z" fill="white"/>
  </svg>
  
}
export const BackCardButton = (props) => {
  return <svg className={'back-button-svg'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.00001" cy="8.00001" r="6.15385" fill="#A2A3A3"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.53848 7.99997L8.74821 11.1842C8.93359 11.3681 9.2326 11.3681 9.41799 11.1842V11.1842C9.60548 10.9982 9.60548 10.6951 9.41799 10.5091L6.88881 7.99997L9.41799 5.49086C9.60548 5.30486 9.60548 5.00172 9.41799 4.81572V4.81572C9.2326 4.6318 8.93359 4.6318 8.74821 4.81572L5.53848 7.99997Z" fill="white"/>
    </svg>;
}
export const RightIcon = (props) => {
  return <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.5833 5L3.91667 11.6123L1 8.82524" stroke="#D9DFE4" strokeWidth="1.63636" strokeLinecap="round"/>
  </svg>;  
}
export const ExamsIconInactive =(props)=>{
  return<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="16" y="2.5" width="7" height="7" rx="1" stroke="#A2A3A3" strokeWidth="1.3"/>
  <rect x="16" y="11.5" width="7" height="7" rx="1" stroke="#A2A3A3" strokeWidth="1.3"/>
  <rect x="4" y="16.5" width="7" height="7" rx="1" stroke="#A2A3A3" strokeWidth="1.3"/>
  <rect x="4" y="7.5" width="7" height="7" rx="1" stroke="#A2A3A3" strokeWidth="1.3"/>
  <path d="M10 5H9.5C8.11929 5 7 6.11929 7 7.5V7.5" stroke="#A2A3A3" strokeWidth="1.8"/>
  <path d="M10 5H16" stroke="#A2A3A3" strokeWidth="1.8"/>
  <path d="M19 9.5V11.5" stroke="#A2A3A3" strokeWidth="1.8"/>
  <path d="M19 18.5V18.5C19 19.8807 17.8807 21 16.5 21H11" stroke="#A2A3A3" strokeWidth="1.8"/>
  <path d="M7 14.5V16.5" stroke="#A2A3A3" strokeWidth="1.8"/>
  </svg>
}
export const ExamsIconActive =(props)=>{
  return<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="16" y="2.5" width="7" height="7" rx="1" stroke="#378EF8" strokeWidth="1.3"/>
  <rect x="16" y="11.5" width="7" height="7" rx="1" stroke="#378EF8" strokeWidth="1.3"/>
  <rect x="4" y="16.5" width="7" height="7" rx="1" stroke="#378EF8" strokeWidth="1.3"/>
  <rect x="4" y="7.5" width="7" height="7" rx="1" stroke="#378EF8" strokeWidth="1.3"/>
  <path d="M10 5H9.5C8.11929 5 7 6.11929 7 7.5V7.5" stroke="#378EF8" strokeWidth="1.8"/>
  <path d="M10 5H16" stroke="#378EF8" strokeWidth="1.8"/>
  <path d="M19 9.5V11.5" stroke="#378EF8" strokeWidth="1.8"/>
  <path d="M19 18.5V18.5C19 19.8807 17.8807 21 16.5 21H11" stroke="#378EF8" strokeWidth="1.8"/>
  <path d="M7 14.5V16.5" stroke="#378EF8" strokeWidth="1.8"/>
  </svg>
}

