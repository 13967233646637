import React, {memo, useEffect, useState} from 'react'
import * as Api from 'api';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addPendingSVGAction, removePendingSVGAction } from 'actions/studying';

type SVGObjectProps = {
    mediaId?: number;
}  

const SVGObject: React.FC<SVGObjectProps> = ({mediaId}) => {
    const dispatch = useDispatch();
    const [svg, setSVG] = useState('');
    const [unmounted, setUnmounted] = useState(false)

    useEffect(() => {
        const svgUrl = Api.createMediaUrl(mediaId);
        dispatch(addPendingSVGAction(mediaId))
        axios.get(svgUrl)
        .then(response => {
            if(unmounted) return
            setSVG(response.data)
        })
        .finally(() => {
            dispatch(removePendingSVGAction(mediaId))
        });
        return () => {
            setUnmounted(true); 
        }
    },[])

    if (!svg) return null;
    return <>
        <object dangerouslySetInnerHTML={{__html: svg.replace(/(\r\n|\n|\r)/gm, '').replace(/(id\=\"SVGID)/g, `id="${mediaId}_SVGID`).replace(/(fill\=\"url\(\#SVGID)/g, `fill="url(#${mediaId}_SVGID`).replace(/(stroke\=\"url\(\#SVGID)/g, `stroke="url(#${mediaId}_SVGID`).replace(/(xlink:href\=\"#SVGID)/g, `xlink:href="#${mediaId}_SVGID`).replace(/(clip-path\=\"url\(\#SVGID)/g, `clip-path="url(#${mediaId}_SVGID`)}}></object>
    </>
}

export default memo(SVGObject);