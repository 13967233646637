import {iFrameDataInit} from 'actions';
import {toggleNavigationSection} from 'actions/navigation';
import cardIcon from 'assets/icons/card-icon.svg';
import menuToggleInactiveIcon from 'assets/icons/menu-toggle-inactive.svg';
import RoundButton from 'components/studying/RoundButton';
import classNames from 'classnames';
import ToggleMenuButtonContainer from 'components/studying/ToggleMenuButtonContainer';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {activeSubjectId, isLeftMenuOpen} from 'selectors';
import {mediaSize} from 'selectors/browser/browserSelector';
import styled, {css} from 'styled-components/macro';
import theme from 'theme';
import media from 'ui-components/helpers/media';
import FilterOverlay from 'v2/layout/FilterOverlay';
import './IFrameLayout.scss'
import TeachingLevelPopupOverlay from 'v2/components/studying/control-overlay/TeachingLevelPopupOverlay';

class IFrameLayout extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const {match} = this.props;

    const classId = match && match.params && match.params.classId;

    this.props.iFrameDataInit(classId);

    document.addEventListener('touchstart', {});
  }

  render() {
    const SideMenu = this.props.sideMenu;
    const Content = this.props.content;

    // Wait for subject to be set
    if (!this.props.activeSubjectId) return null;

    const isTablet = this.props.isTablet;
    const isMobile = this.props.isMobile;

    const isButtonSimple = isTablet ? !this.props.isLeftMenuOpen && this.state.isScrolled : !isTablet && this.state.isScrolled;

    return (
      <div>
        <MenuOverlay onClick={() => this.props.toggleNavigationSection(false)} isVisible={this.props.isLeftMenuOpen}/>

        <ButtonWrapper open={this.props.isLeftMenuOpen}>
          <ToggleMenuButtonContainer>
            {({isActive, onClick}) => (
              <div className={classNames('round-btn', {'simple-btn' : !isButtonSimple })} onClick={onClick}>
                <img src={isActive ? (isMobile ? cardIcon : menuToggleInactiveIcon) : menuToggleInactiveIcon} alt=""/>
              </div>
            )}
          </ToggleMenuButtonContainer>
        </ButtonWrapper>

        <SideMenuStyled open={this.props.isLeftMenuOpen}>
          <SideMenu match={this.props.match}/>
        </SideMenuStyled>

        <Content hideNavButton onScrolledChanged={this.onScrolledChanged}/>
        <TeachingLevelPopupOverlay subjectId={this.props.activeSubjectId} flashcardId={this.props.currentFlashcardId} /> 
        <FilterOverlay />
      </div>
    );
  }

  onScrolledChanged = (value) => {
    this.setState({isScrolled: value});
  }
}

IFrameLayout.propTypes = {
  sideMenu: PropTypes.func.isRequired,
  content: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,

  // Connect
  activeSubjectId: PropTypes.number,
  isLeftMenuOpen: PropTypes.bool,
  isTablet: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  toggleNavigationSection: PropTypes.func.isRequired,
  iFrameDataInit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  activeSubjectId: activeSubjectId(state),
  isTablet: mediaSize(state, theme.sizes.iFrameTablet),
  isMobile: mediaSize(state, theme.sizes.iFrameMobile),
  isLeftMenuOpen: isLeftMenuOpen(state),
});
const mapDispatchToProps = {
  toggleNavigationSection,
  iFrameDataInit
};
export default connect(mapStateToProps, mapDispatchToProps)(IFrameLayout);

const SideMenuStyled = styled.div`
  position: absolute;
  left: 0;
  width: 375px;
  height: 100%;
  z-index: 5;
  overflow: hidden;
  background-color: #FFFFFF;
  transform: translate3d(-103%, 0, 0);
  transition: transform 0.35s cubic-bezier(.25, .75, .5, 1);
  border: 0 solid #E3E6E6;
  box-shadow: 0 1px 14px 0 rgba(62,87,85,0.22);
  
  ${props => props.open && css`
    transform: unset;
  `}
  
  ${media.iFrameMobile(css`
    width: 100%;
  `)}
`;
const MenuOverlay = styled.div`
  top: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  background: #203D3C;
  z-index: 10;
  display: none;
  transition: opacity 0.35s cubic-bezier(.25, .75, .5, 1);
  pointer-events: none;
  
  ${props => props.isVisible && css`
    opacity: 0.4;
    pointer-events: all;
  `}
  
  ${media.iFrameTablet(css`
    display: block;
  `)}
  
  ${media.iFrameMobile(css`
    display: none;
  `)}
`;
const ButtonWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  padding: 2px 7px;
  transform: unset;
  
  ${media.iFrameMobile(css`
    left: 0;
  `)}
  
  ${props => props.open && css`
    transform: translate3d(375px, 0, 0);
    
    ${media.iFrameMobile(css`
      left: 100%;
      transform: translate3d(-100%, 0, 0);
    `)}
  `}
`;

// const CustomRoundButton = styled(RoundButton)`
//   ${props => !props.simple && css`
//     box-shadow: unset;
//     border: 1px solid rgb(225, 230, 230);
//   `}
// `;
