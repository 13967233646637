
import React, {memo} from 'react'
import Loader, {BackgroundEnum, SizeEnum} from './Loader.v2';

type RequestLoaderProps = {
    //TODO: will update the type of data object
    request?: {loading:boolean; data:any; succeeded:boolean; error: string }; 
    allowUpdate?: boolean;
    loader: React.ReactNode;
    error?: () => React.ReactElement;
    children: any;
    exercise?: boolean
} 
const RequestLoader: React.FC<RequestLoaderProps> = ({request, allowUpdate, loader, children, error, exercise}) => {
    
    if (!request) return null;

    if (request.loading && (!allowUpdate || !request.data)) {
        if (loader) return loader;
        return <div className='flex justify-center py-15px'>
            <Loader background={BackgroundEnum.Blue} size={SizeEnum.Small}/>
        </div>;
    }
    if (request.error) {
        return error ? error() : <div>Oops!</div>;
    }
    if (request.succeeded || allowUpdate) {
        if(exercise){
            return children(request.data);
        }
        return children;
    }
    return null;
}

export default memo(RequestLoader);