import './studying.scss';
import { updateFilterListOfItemsSelected } from 'actions/desk';
import { getMediaTypeFilterOverlay, toggleFilterOverlay, toggleMoreControlOverlay, toggleQuestionInfoOverlay } from 'actions/navigation';
import { getbackFlashcard, getFormExams, getLastMediaTypeVisited } from 'helpers';
import usePrevious from 'hooks/usePrevious';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getexamslistSize, getprogressBarData } from 'selectors/navigation/navigationSelector';
import { assessmentAnimation, getExamQuestionNumber, listOfFilterCheckedItems } from 'selectors/desk/deskSelector';
import { getUserId } from 'selectors/user';
import { isLandscape, isMobile } from 'selectors/browser/browserSelector';
import classNames from 'classnames';
import AddFlashcardToBookmarkContainer from 'pages/desk/folder/AddFlashcardToBookmarkContainer';
import { MoreIcon, LinkIcon } from 'icons/FlashcardNavIcons';
import { QuestionInfoIcon } from 'icons/FlashcardNavIcons';
import AnswerStatistics from 'v2/components/studying/answer-statistics/AnswerStatistics';
import StudyStatusInfo from 'v2/components/studying/study-location-info/StudyStatus';
import VideoStatusInfo from 'v2/components/studying/study-location-info/VideoStatus';
import ExamsStatusInfo from "v2/components/studying/study-location-info/ExamsStatusInfo";
import CardBackButtonContainer from 'v2/components/studying/CardBackButtonContainer';
import StudyLocationInfo from 'v2/components/studying/study-location-info/StudyLocationInfoContainer';
import ProgressGraphical from 'v2/components/studying/progress-graphical/ProgressGraphical';
import StudyCardInfoStatus from 'v2/components/studying/study-location-info/StudyCardInfoStatus';
import { FilterIcon, FilterClearIcon } from 'icons/HeaderIcons';
import InitialFlashcardSelector from './initial-flashcard-selector/InitialFlashcardSelector';
import VideoCarouselContainer from '../video-carousel/VideoCarouselContainer';
import FlashcardCarouselContainer from 'v2/components/flashcard-carousel/FlashcardCarouselContainer';
import ExamsCarouselContainer from '../examsCarousel/ExamsCarouselContainer';
import RevisionCarouselContainer from 'v2/components/revision-carousel/RevisionCarouselContainer';

type StudyingProps = {
  flashcardContent: any;
  flashcardId: string | number;
  isDeviceRestricted: any;
  isLinksNavigationSectionVisible: boolean;
  isLoading: any;
  isNavigationSectionVisible: any;
  links: any;
}

const Studying: React.FC<StudyingProps> = ({ flashcardId, flashcardContent, isDeviceRestricted, isLinksNavigationSectionVisible, isLoading, isNavigationSectionVisible, links }) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const getListOfFilterCheckedItems = useSelector(listOfFilterCheckedItems);
  const examslistSize = useSelector(getexamslistSize);
  const examQuestionNumber = useSelector(getExamQuestionNumber);
  const hasAssessmentAnimation = useSelector(assessmentAnimation);
  const progressBarData = useSelector(getprogressBarData);
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);
  const prevListOfFilterCheckedItems = usePrevious(getListOfFilterCheckedItems);
  let mediaType = getLastMediaTypeVisited(userId);
  const [filter, setFilter] = useState<boolean>(false);
  const hasMobileLandscape = (hasMobile && hasLandscape);
  let data = getListOfFilterCheckedItems.toJS()[mediaType];
  const cardLoading = flashcardContent.size === 0 && mediaType === 'exams' ? examslistSize.get('size') == 0 ? true : false : false;
  const questionType = mediaType === 'exams' ? flashcardContent.get('questionType') && (flashcardContent.get('questionType').includes('mcq') && true) : false;

  const checkFilter = () => {
    if (data && data.length > 0 && ((mediaType === "flashcard" && data.length < 4) || (mediaType === "revision" && data.length < 2) || (mediaType === "video" && data.length < 3))) {
      setFilter(true);
    }
    else {
      setFilter(false);
    }
  }

  useEffect(() => {
    checkFilter();
  }, [])

  useEffect(() => {
    if (getListOfFilterCheckedItems != prevListOfFilterCheckedItems) {
      checkFilter();
    }
  }, [getListOfFilterCheckedItems, prevListOfFilterCheckedItems])

  const clearFilter = (e: any) => {
    e.stopPropagation();
    dispatch(updateFilterListOfItemsSelected([], mediaType))
  }

  const openFilter = (e: any) => {
    e.stopPropagation();
    dispatch(toggleFilterOverlay(true, true));
    dispatch(getMediaTypeFilterOverlay(mediaType));
  }

  const flashcardNumber = () => flashcardContent.get('absoluteNumbering') || flashcardContent.get('numbering') || mediaType === 'exams' && (getFormExams() !== 'exams' ? null : examQuestionNumber && `Q${examQuestionNumber}`)

  const renderRightSide = (flashcardId: string | number) => <div className={classNames('right-side-wrapper tutorial-step-flashcard-answer', { 'carousel-video-wrapper': mediaType === 'video', 'mobile-landscape': hasMobileLandscape })}>
    {hasMobileLandscape && <div className='landscape-more-link-button'>
      <div className='mb-4 flex justify-center'><AddFlashcardToBookmarkContainer flashcardFolders={flashcardContent.get("bookMark")} /></div>
      <div className='more-btn box-border rounded-[22px] z-1 flex items-center justify-center cursor-pointer mb-2.5 select-none' onClick={() => dispatch(toggleMoreControlOverlay(true, true))}><MoreIcon /></div>
      {isLinksNavigationSectionVisible && links > 0 && mediaType === "flashcard" && <div className='link-btn box-border rounded-[22px] z-1 flex items-center justify-center cursor-pointer mb-2 select-none'><LinkIcon /></div>}
    </div>}
    {hasMobileLandscape && mediaType === 'exams' && <div onClick={() => dispatch(toggleQuestionInfoOverlay(true, true))} className={classNames('q-info box-border rounded-[22px] z-1 flex items-center justify-center relative h-min cursor-pointer p-2 select-none', { 'mr-[11px]': !hasMobileLandscape })}><QuestionInfoIcon /></div>}

    {!cardLoading && hasMobileLandscape && <>
      {mediaType === 'flashcard' && <AnswerStatistics />}
      {mediaType === 'revision' && <StudyStatusInfo />}
      {mediaType === 'video' && <VideoStatusInfo />}
      {mediaType === 'exams' && !questionType && <ExamsStatusInfo />}
    </>}

    {hasMobileLandscape && <div className={classNames('answer-statistics-content', { 'viewport-mobile': hasMobile })}>
      <CardBackButtonContainer flashcardId={flashcardId} returnCardId={getbackFlashcard()} isStudyOverlay={(isLinksNavigationSectionVisible && isNavigationSectionVisible)} />
      <div className='text-#2D3838 text-15px leading-19px font-bold'><span>{flashcardNumber()}</span></div>
    </div>}
  </div>

  return <div className={classNames('studying-wrapper tutorial-step-final tutorial-step-rotate', { 'exam-renderer exams': mediaType === "exams", 'carousel-revision-wrapper revision': mediaType === 'revision', 'video': mediaType === 'video', 'flashcard': mediaType === 'flashcard' })}>
    <div className={classNames('card-info-section ', { 'exams': mediaType === "exams", 'revision': mediaType === 'revision', 'video': mediaType === 'video', 'flashcard': mediaType === 'flashcard', 'assessment-height': hasAssessmentAnimation, 'assessment-dec-height': !hasAssessmentAnimation })}>
      {flashcardId && 
        <StudyLocationInfo flashcardId={flashcardId} className={classNames({ 'exams-wrapper': mediaType === 'exams' })} />
      }
      {mediaType === "exams" ? <>
          {!cardLoading && !hasMobileLandscape && !(flashcardContent.size === 0 || examslistSize.get('size') === 0) && <ProgressGraphical examList={examslistSize} />}
          {!hasMobileLandscape && !(flashcardContent.size === 0 || examslistSize.get('size') === 0) && <StudyCardInfoStatus flashcardNumber={flashcardNumber()} id={flashcardId} flashcardFolders={flashcardContent.get('bookMark')} mediaTypeCurrent={mediaType} questionType={questionType}/>}
          {cardLoading && hasMobileLandscape && <div onClick={(e) => openFilter(e)} className={classNames('flex flex-col items-center box-border rounded-[18px] w-9 filter-box', { 'filter': filter })}><FilterIcon /> {filter && <div onClick={(e) => clearFilter(e)} className='w-8 h-8 flex rounded-full items-center justify-center filter-clear'> <FilterClearIcon /></div>}</div>}
        </> :
        <>
          {!hasMobileLandscape && !(flashcardContent.size === 0 || progressBarData.get('size') === 0) && <div className='[&>div]:m-auto max-h-3.5'><ProgressGraphical examList={progressBarData} /></div>}
          {!(flashcardContent.size === 0 || progressBarData.get('size') === 0) && flashcardContent.get('parentsData') && flashcardId && <>
            <div className='answer-statistics-content'><CardBackButtonContainer flashcardId={flashcardId} returnCardId={getbackFlashcard()} isStudyOverlay={(isLinksNavigationSectionVisible && isNavigationSectionVisible)} />
              <div className='flashcard-nr'>
                <span>{flashcardNumber()}</span>
              </div>
            </div>
            <StudyCardInfoStatus flashcardNumber={flashcardNumber()} id={flashcardId} flashcardFolders={flashcardContent.get('bookMark')} mediaTypeCurrent={mediaType} />
          </>}
        </>
      }
    </div>
    {flashcardId && !isDeviceRestricted ?
      mediaType === 'video' ? <VideoCarouselContainer currentId={Number(flashcardId)} /> :
        mediaType === 'revision' ? <RevisionCarouselContainer /> :
          mediaType === "exams" ? <ExamsCarouselContainer flashcardId={flashcardId.toString()} /> :
            <FlashcardCarouselContainer flashcardId={Number(flashcardId)} /> :
      <InitialFlashcardSelector translate />}
    {!isLoading && flashcardId && renderRightSide(flashcardId)}
  </div>
}

export default Studying