import React from 'react'
import FilterHeader from './FilterHeader';
import {getFlashcardById} from 'selectors/studying'
import './filter.scss'
import { useSelector } from 'react-redux';

type QuestionInfoProps = {
    onClick?: (e: any) => void;
    onClose: () => void;
    currentId: string | number;
}  

const QuestionInfo: React.FC<QuestionInfoProps> = ({currentId, onClick, onClose}) => {
    const qContent = useSelector((state) => getFlashcardById(state, currentId))?.toJS()
    const qCalc = qContent?.experienceData?.calc_enabled;
    const calc = qCalc ? "Calc" : 'Non-calc'    
    const questionPoint = parseInt(qContent.points)

    return <div className='filter-wrapper' onClick={onClick}>
        <FilterHeader
            hideArrow={false} 
            title={'Question info'} 
            onCloseClick={onClose} 
        />
        <div className='flex items-center p-3.5 mt-3 mb-5 mx-5 rounded-10px text-[#979999] text-13px leading-16px bg-#EFF4F9'>
            {questionPoint} {questionPoint<=1 ? 'mark' :'marks'} • {calc}
        </div>
    </div>
}

export default QuestionInfo;