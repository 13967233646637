import React, { useRef, useEffect, useMemo, useState } from 'react';
import { getJWTToken, getFormExams, detectIncognito, getEnableCookies } from "helpers"
import Iframe from './Iframe';
import { examAnswerById } from 'selectors/answersSelectors';
import { useDispatch, useSelector } from "react-redux"
import { questionIdUpdateStats, updateExamFilterDataFromTopics } from "actions/desk"
import { setExamStatus } from "actions/api"
import {toggleEnableCookiesOverlay} from "actions/navigation"
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { userDataSelector } from 'selectors/user'
import { getActiveSubject, getFlashcardById, isIPad, isIPadPro, isMobile } from "selectors";
import classNames from 'classnames';
import {
  DecreaseFlashcardZoom,
  IncreaseFlashcardZoom,
} from "icons/FlashcardIcons";
import { DownloadIcon, LeftMenuToggleIcon } from "icons/HeaderIcons";

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

type ExamRendererContainerProps = {
  hasZoom: boolean,
  isVisible: number,
  flashcardId: string,
  currentFlashcardId: any
}

const maxWidth = 754;

const ExamRendererContainer: React.FC<ExamRendererContainerProps> = ({ flashcardId, hasZoom, isVisible, currentFlashcardId }) => {
  const dispatch = useDispatch();
  const hasMobile = useSelector(isMobile)
  const hasIsIPad = useSelector(isIPad)
  const hasIsIPadPro = useSelector(isIPadPro)
  const activeSubject = useSelector(getActiveSubject)
  const media = useSelector(state => getFlashcardById(state, flashcardId));
  const mediaToJS = media.toJS()
  const [pdfScale, setPdfScale] = useState(1);
  const [containerWidth, setContainerWidth] = useState<number>();
  const resizeElement = useRef<HTMLDivElement>(null);
  const setEaxmContainerRef = useRef<any>();
  const getAPJWTToken = getJWTToken();
  const userData = useSelector(userDataSelector)?.toJS();
  const APUrl = process.env.REACT_APP_AP_IFRAME_URL;
  const mathematicsIds = [14982 , 15075]
  const answers = useSelector(state => examAnswerById(state, flashcardId))?.toJS()
  const [isActive, setActive] = useState<boolean>(false);
  const [pdfTotalPages, setPdfTotalPages] = useState<number>();
  const isMathsIBExams = mathematicsIds.includes(activeSubject?.id) && mediaToJS?.experienceData?.qb_code === "ib_dp";
  const account_type =
    userData?.fromSchool === "YES" ||
    (!isMathsIBExams && userData?.userPlatformType === "smpp") ||
    (!isMathsIBExams && userData?.examStatus) ||
    (!isMathsIBExams && mathematicsIds.includes(activeSubject?.id))
      ? "b2b"
      : "b2c";
  // const isb2b = account_type.indexOf('b2b') != -1 ? '&account_type=b2b' : ''
  const pdfURL = useMemo(
    () => 
      mediaToJS?.experienceData?.resources?.find(
        (resource: any) => resource?.type === "application/pdf"
      )?.url,
    [mediaToJS?.experienceData?.resources]
  );
  
  let ifrm = `<iframe class="exam-iframe ${pdfURL ? 'half' : 'no-pdf'}" src="${APUrl}/${flashcardId}?mode=quick_check&partner=osc&layout=single&jwt=${getAPJWTToken}&account_type=${account_type}" frameborder="0" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" width="100%" height="100%" allow="autoplay"></iframe>`;

  useEffect(() => {
    if (!setEaxmContainerRef) return;
    const baseTabContent = setEaxmContainerRef && setEaxmContainerRef.current;
    // const baseTabContent = setContainerRef && setContainerRef.current;
    let resizeObserver = new ResizeObserver(() => {
      setContainerWidth(setEaxmContainerRef.current.clientWidth);
    });
    resizeObserver.observe(baseTabContent);
    return () => {
      resizeObserver.unobserve(baseTabContent);
    };
  }, []);


  useEffect(() => {
    const userResponseEventMethod = (e: any) => {
      if (e.data.correct == true || e.data.correct == false) {
        if (getFormExams() === "exams") {
          dispatch(questionIdUpdateStats(flashcardId, e.data.correct ? 0 : 1));
        } else {
          dispatch(updateExamFilterDataFromTopics(flashcardId, e.data.correct ? 0 : 1));
        }
        dispatch(setExamStatus({ flashcardId: flashcardId, answer: e.data.correct ? 0 : 1, prevAnswer: answers?.correct, type: "mcq" }));
      }
    }

    const win: Window = window;
    //@ts-ignore
    const eventMethod: any = win.addEventListener ? "addEventListener" : "attachEvent";
    const eventer: any = win[eventMethod];
    const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
    eventer(messageEvent, userResponseEventMethod);
    detectIncognito().then(result => {
      if (result.isPrivate && !getEnableCookies()) {
        dispatch(toggleEnableCookiesOverlay(true))
      }
    })
    return () => {
      //@ts-ignore
      const eventMethodRemove: any = win.addEventListener ? "removeEventListener" : "detachEvent";
      const eventerRemove: any = win[eventMethodRemove];
      const messageEventRemove: any = eventMethodRemove === "attachEvent" ? "onmessage" : "message";
      eventerRemove(messageEventRemove, userResponseEventMethod);
    }
  }, [answers, dispatch, flashcardId])

  const togglePdfHeight = () => {
    setActive(!isActive);
  };


  return (
    <div className={`exam-contain`}>
      <div className='main-exam' ref={setEaxmContainerRef}>
      {pdfURL && (
            <>
              {((hasMobile && !hasIsIPad && !hasIsIPadPro) ? isActive : true) && (
                <>
                  <div className="absolute right-20 top-[138px] flex items-center justify-between gap-1 z-1 rounded-2xl btn-wrapper">
                    <div
                      className={classNames(
                        "cursor-pointer select-none [&>svg]:w-[20px] [&>svg]:h-[22px]"
                      )}
                      onClick={() =>
                        setPdfScale((prev) => (prev <= 2 ? prev + 0.5 : prev))
                      }
                    >
                      <IncreaseFlashcardZoom />
                    </div>
                    <div
                      className={classNames(
                        "cursor-pointer select-none [&>svg]:w-[20px] [&>svg]:h-[22px]"
                      )}
                      onClick={() =>
                        setPdfScale((prev) => (prev > 1 ? prev - 0.5 : prev))
                      }
                    >
                      <DecreaseFlashcardZoom />
                    </div>
                  </div>
                  <a
                    download={true}
                    className={classNames(
                      "absolute right-12 top-[138px] z-1 cursor-pointer transition-all"
                    )}
                    href={pdfURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DownloadIcon width={"20"} height={"20"} />
                  </a>
                </>
              )}
              <div
                className={classNames(
                  "absolute right-4 top-[138px] cursor-pointer transition-all [&>svg]:w-[20px] [&>svg]:h-[22px] [&>svg]:block z-999",
                  { "rotate-[270deg]": isActive, "rotate-90": !isActive }
                )}
                onClick={togglePdfHeight}
              >
                <LeftMenuToggleIcon />
              </div>
              <Document
                file={pdfURL}
                onLoadSuccess={({ numPages }: { numPages: number }) =>
                  setPdfTotalPages(numPages)
                }
                onLoadError={(error: any) => console.error("Error: ", error)}
                className={`pdf-container w-full ${
                  isActive ? "pdf-full-height" : "pdf-half-height"
                }`}
              >
                {Array.from(Array(pdfTotalPages).keys())?.map(
                  (page: number) => (
                    <Page
                      key={`pdf-${page}`}
                      pageNumber={page + 1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      width={containerWidth ? containerWidth : maxWidth}
                      scale={pdfScale}
                    />
                  )
                )}
              </Document>
            </>
          )}
          <div
            className={classNames("exam-content", {
              "exam-iframe-hidden": isActive,
              "exam-iframe-show": !isActive,
            })}
          >
        <Iframe iframe={ifrm} ref={resizeElement} />
        </div>
      </div>
    </div>
  )
}

export default ExamRendererContainer