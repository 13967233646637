import React, { useState, useEffect } from 'react';
import {useDispatch} from 'react-redux';
import {authenticate} from 'actions/api';
import {useFormik} from 'formik';
import {RenderInputField, WarnIcon} from 'v2/utils/form-helper';
import { fontSize } from 'v2/components/common/font-size';
import { fontWeight } from 'v2/components/common/font-weights';
import Text from 'v2/components/common/Text';
import RouterLink from 'router/Link';
import * as Yup from 'yup';
import './login.scss'


type LoginFormProps = {
    requestError:{access_denied:boolean};
    setFormValided: (value:boolean) => void;
    isSubmitLogin: boolean,
    setSubmitLogin: any
}  
const initialFormValue = {
    username: '',
    password: ''
}

const LoginForm: React.FC<LoginFormProps> = ({requestError, setFormValided, isSubmitLogin, setSubmitLogin}) => {
    const dispatch = useDispatch();
    const [isFocused, setFocused] = useState({username:false, password:false});
    const {values, errors, touched, handleSubmit, handleChange, handleBlur}=useFormik({
        validationSchema: validation,
        enableReinitialize: true,
        initialValues:initialFormValue,
        onSubmit: (values) =>{
        }
    });
    useEffect(() => { 
        const checkValidate = initialFormValue.username !== values.username && initialFormValue.password !== values.password;
        setFormValided(checkValidate)
    },[values, setFormValided])

    useEffect(() => {
        if(isSubmitLogin){
            setSubmitLogin(false)
            //@ts-ignore
            dispatch(authenticate(values));
            
        }
    },[values, isSubmitLogin, setSubmitLogin, dispatch])

    const renderSubmissionError = () => {
        const errorMessage = requestError.access_denied ? 'Your account is not yet confirmed! Check your email address!' : 'Please check your credentials and try again!';
        return <div className="flex items-center justify-space-between" >
          <WarnIcon />
            <Text className={'text-#FA2326 leading-19px text-center ml-1.5 text-13px font-semibold'} >{errorMessage}</Text>
        </div>;
      };
    return <>
        <div className="pt-4" />
        {requestError && renderSubmissionError()}
        <form onSubmit={handleSubmit}>
            <div className='form-input-wrapper'>
                <RenderInputField 
                    label={'Email or username'}
                    type='text'
                    name='username'
                    placeholder='Enter your email address or username'
                    className='form-input'
                    showIcon={true}
                    touched={touched.username}
                    error={errors.username}
                    reset=''
                    active={isFocused.username}
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={false}
                    autoComplete={false}
                    onFocus={() => {
                        setFocused( {username:true, password:false }
                        )
                    }}
                />
            </div>
            <div className='form-input-wrapper'>
                <RenderInputField 
                    label='Password'
                    type='password'
                    name='password'
                    placeholder='Enter your password'
                    className='form-input'
                    showIcon={true}
                    touched={touched.password}
                    error={errors.password}
                    reset=''
                    active={isFocused.password}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={false}
                    autoComplete={false}
                    onFocus={() => {
                        setFocused( {username:false, password:true }
                        )
                    }}
                />
            </div>
            <div className="pt-18px" />
            <Text className={`flex items-center justify-center text-center leading-18px mb-2 text-#A2A3A3 text-13px`}>
                <>
                    Forgot your 
                    <RouterLink
                    to="/forgot-username">
                        <span className='login-forgot-password'>&nbsp;username&nbsp;</span>
                    </RouterLink> 
                    or 
                    <RouterLink
                    to="/forgot-password">
                        <span className='login-forgot-password'>&nbsp;password</span>
                    </RouterLink>
                    ?
                </>
            </Text>
            <button type={'submit'} hidden>submit</button>
      </form>
    </>;
}
let validation = Yup.object().shape({
    username: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
});

export default LoginForm;