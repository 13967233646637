import React, { memo } from 'react'
import { ReactComponent as ClassroomNoExercisesIcon } from 'assets/icons/exercise/classroom-empty-exercise.svg';
import { ForwardChevronIcon } from 'icons/HeaderIcons';
import './emptyScreen.scss'

type ClassroomEmptyMessageProps = {
    isAccountMerged?: boolean;
    type?: string;
    mediaType?: string;
}


const ClassroomEmptyMessage: React.FC<ClassroomEmptyMessageProps> = ({ isAccountMerged, type, mediaType }) => {

    const subtitle = isAccountMerged ?
        <>Ask your teachers to create OSC Exercises directly within <span>ManageBac</span>.</> :
        <>Your school must enable OSC Study in <span className='font-bold'>ManageBac</span> before teachers can push OSC Exercises to you for homework. Remind them!</>;
    const isExercises = mediaType === "flashcard" && type === "exercises"

    return <div className='flex items-center self-center content-center h-full py-0 px-5'>
        <div className='text-center'>
            <ClassroomNoExercisesIcon />
            <div className='text-blue-normal text-17px font-bold leading-23px px-3 pb-0 pt-2.5'>
                {isExercises ? 'New tools coming soon for all media' : 'Can’t see your homework here?'}
            </div>
            <div className='text-13px text-#919191 m-auto leading-16px pt-2.5'>
                {isExercises ? 'Exercises can be found only in flashcards at present.' : subtitle}
            </div>
            {isExercises ?
                <div className='text-13px text-#919191 m-auto leading-16px pt-2.5'>
                    Tools are being developed continuously. We will update you once exercises are available for revision guides, videos and exam questions.
                </div> :
                <a className='learn-more-btn' href='https://www.smart-prep.com/pages/app-for-schools' target='_blank' rel="noreferrer">
                    <div className='learn-more-txt text-13px font-bold leading-18px tracking-2px text-blue-normal mr-[11px]'>LEARN MORE</div>
                    <ForwardChevronIcon color={'#378EF8'} class={"more-icon"} />
                </a>
            }
        </div>
    </div>
}

export default memo(ClassroomEmptyMessage);