import PropTypes from 'prop-types';
import React, {Component} from 'react';
import * as HeaderIcons from 'icons/HeaderIcons';
import * as PassiveIcons from 'icons/PassiveIcons';

const CONST_VAT = 19;

export default class StoreBasket extends Component {
  constructor(props) {
    super(props);
    this.openBasketDetails = this.openBasketDetails.bind(this);
    this.onPayment = this.onPayment.bind(this);
  }

  setPurchaseDataForAPI(paymentType) {
    const totalPrice = calculateTotalPrice(this.props.itemsInBasket);
    const totalPriceVAT = calculateVAT(this.props.itemsInBasket, totalPrice);

    const basketItems = [];
    this.props.itemsInBasket.forEach(item => {
      item.get('subscriptions').forEach(subscription => {
        basketItems.push({
          id: subscription.get('id'),
          quantity: subscription.get('amount')
        });
      });
    });

    return {
      paymentType: paymentType,
      totalAmount: parseFloat(totalPrice).toFixed(2),
      vatPercentage: parseFloat(totalPriceVAT).toFixed(2),
      subscriptions: basketItems
    };
  }

  onPayment() {
    this.props.registerPurchase(this.setPurchaseDataForAPI('creditCard'));
  }

  changeAmount=(itemInCart, type)=> {
    this.props.changeAmount({itemInCart, type});
  }

  openBasketDetails() {
    this.props.toggleBasket();
  }

  renderDurationOfSelectedSubscription() {
    let itemsInBasket = this.props.itemsInBasket;

    if (!itemsInBasket) return null;
    return itemsInBasket.map(item => (
      item.get('subscriptions').map(subscription => (
        <div className="items-in-basket-container" key={subscription.get('id')}>
          <div className="select-duration-container">
            <div className="number-selection">
              <div
                className="decrement incremention-button"
                onClick={() => this.changeAmount(subscription, 'decrement')}>-
              </div>
              <div className="duration-selected">{subscription.get('amount')}</div>
              <div
                className="increment incremention-button"
                onClick={() => this.changeAmount(subscription, 'increment')}>+
              </div>
            </div>
            <div>
              <div>{item.get('name')}</div>
              <div>{subscription.get('amount') * subscription.get('duration')} {subscription.get('durationUnit')}s</div>
            </div>
          </div>
          <div>€{(subscription.get('price') * subscription.get('amount')).toFixed(2)}</div>
        </div>
      ))
    ));
  }

  renderTotalPrice() {
    return calculateTotalPrice(this.props.itemsInBasket).toFixed(2);
  }

  renderExpandedBasket() {
    const totalPrice = calculateTotalPrice(this.props.itemsInBasket).toFixed(2);
    return (
      <div className={`expanded-basket ${!this.props.isBasketDetailsVisible ? 'hide-top-border' : ''}`}>
        {this.renderDurationOfSelectedSubscription()}
        <div className="price-detail">
          <div>Incl. VAT</div>
        </div>
        <div className="price-detail">
          <div>Total</div>
          <div>€{totalPrice}</div>
        </div>
        <div className="basket-info">
          This page is operated by Hsquared Education GmbH and was developed for you to view all In-App Store Products.
          All In-App Store Products are sold to you and processed by our e-commerce partner and global reseller
          2Checkout within the meaning of § 3 Abs. 11a UStG.
          A purchase of In-App Store Products does not constitute a buy-sell relationship between you and the Hsquared
          Education GmbH (see
          <a
            className="legal-page-highlight"
            href='https://www.smart-prep.com/pages/terms-of-service' target="_blank"
            rel="noopener noreferrer"> Terms of Service</a>).
          By clicking on &#34;Buy now&#34;, you will be redirected to 2Checkout to complete your order.
        </div>
      </div>
    );
  }

  render() {
    let className = 'store-basket';
    if (this.props.isContained) className += ' contained';
    if (this.props.isBasketDetailsVisible) className += ' expanded';

    return (
      <div className={className}>
        <div className="arrow-container" onClick={this.openBasketDetails}>
          {this.props.isBasketDetailsVisible ? <PassiveIcons.CloseBasketArrow/> : <PassiveIcons.OpenBasketArrow/>}
        </div>
        <div className="basket-details" onClick={this.openBasketDetails}>
          <div className="number-of-items-container">
            <div><HeaderIcons.BasketIcon/></div>
            <div className="number-of-items">
              {this.props.itemsInBasket.size} item{this.props.itemsInBasket.size > 1 ? 's' : ''} added
            </div>
          </div>
          <div className="payment-container">
            <div>€{this.renderTotalPrice()}</div>
            {this.props.isBasketDetailsVisible ?
              <div className="payment-button" onClick={(e) => {
                e.stopPropagation();
                this.onPayment();
              }}>Buy now</div>
              : <div className="payment-button" onClick={(e) => {
                e.stopPropagation();
                this.openBasketDetails();
              }}> Checkout </div>}
          </div>
        </div>
        {this.renderExpandedBasket()}
      </div>
    );
  }
}

StoreBasket.propTypes = {
  isContained: PropTypes.bool,
  itemsInBasket: PropTypes.object,
  isBasketDetailsVisible: PropTypes.bool,
  changeAmount: PropTypes.func.isRequired,
  toggleBasket: PropTypes.func.isRequired,
  togglePaymentView: PropTypes.func.isRequired,
};

export function calculateTotalPrice(itemsInBasket) {
  if (!itemsInBasket) return null;

  let fullPrice = 0;
  itemsInBasket.map(item => {
    item.get('subscriptions').map(subscription => {
      fullPrice += subscription.get('price') * subscription.get('amount');
    });
  });

  return fullPrice;
}

export function calculateVAT(itemsInBasket, totalPrice = null) {
  const vatForPriceFormula = (totalPrice) => totalPrice * CONST_VAT / 100;

  return totalPrice !== null ? vatForPriceFormula(totalPrice) : vatForPriceFormula(calculateTotalPrice(itemsInBasket));
}
