import './videoStatus.scss'
import { useSelector } from 'react-redux';
import { currentFlashcardId } from 'selectors/studying';
import { watchedById } from 'selectors/answersSelectors';
import { RightIcon } from 'icons/FlashcardIcons';


const VideoStatus = () => {
    const currentId = useSelector(currentFlashcardId);
    const watched = useSelector(state => watchedById(state, currentId));
    if (!watched) return null;
    return <>
        {watched.get('watchedValue') ? <div className='watched flex items-center justify-center'>
            <RightIcon color={'#51D489'}/>
            <div className='text ml-1.5 text-13px font-[600] text-[#51D489] leading-19px '>Watched</div>
        </div> : <></>}
    </>
}

export default VideoStatus