import React, {useEffect, useState, useRef, useCallback, useMemo} from 'react'
import {ReactComponent as SMPPLogo} from 'assets/images/login-logo.svg';
import {ReactComponent as MBLogo} from 'assets/icons/mb-logo.svg';
import {ReactComponent as MBLogoChina} from 'assets/icons/mb-logo-china.svg';
import classNames from 'classnames';
import AuthHeader from 'components/header/AuthHeader';
import {isMBiframe} from 'helpers';
import {withRouter} from 'react-router-dom';
import './login.scss'
import { LandingPageLinks, LandingPageLogo, LoginFooter } from './LoginComponents';
import Button, {ThemeEnum} from 'v2/components/Button/Button';
import Text from 'v2/components/common/Text';
import Loader, {SizeEnum} from 'v2/components/loader.v2/Loader.v2';
import BackButton from 'v2/components/navigation-header/BackButton';
import FullPageLoader from 'v2/components/loader.v2/FullPageLoader';
import LoginForm from './LoginForm';
import { useSelector } from 'react-redux';
import {getErrorInApiRequest, isApiRequestInProgressV2} from 'selectors/api/apiSelector';
import GoogleLogin from './GoogleLogin';
export const manageBacUrl = `${process.env.REACT_APP_API_URL}/auth/managebac/authorize?region=canada`;
export const manageBacUrlChina = `${process.env.REACT_APP_API_URL}/auth/managebac/authorize?region=china`;

type LoginPageProps = {
    title: string,
    location:any 
} 
enum VISIBLE_SECTIONS {
    INITIAL = 'initial',
    LOGIN = 'login',
    MB = 'mb'
};

const LoginPage: React.FC<LoginPageProps> = ({title, location}) => {
    const requestError = useSelector((state) => getErrorInApiRequest(state, 'authenticate'))
    const isRequestInProgress = useSelector((state) => isApiRequestInProgressV2(state, 'authenticate'))
    const mbFormRef = useRef<HTMLFormElement>(null);
    const [visibleSection, setVisibleSection] = useState(VISIBLE_SECTIONS.INITIAL);
    const [isSubmitLogin, setSubmitLogin] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isFormValid, setFormValid] = useState(false);
    const [region, setRegion] = useState<string>('');
    const [mbUrl, setMbUrl] = useState(manageBacUrl);
    const getRedirectUrl = () => {  
        if(location.state && location.state.from && location.state.from.pathname){
          return location.state.from.pathname;
        }
        return localStorage.getItem('smpp-redirect-url');
      }
    const handleMbRedirecton = useCallback(() => {
        if (isMBiframe(getRedirectUrl())) {
            mbFormRef?.current?.submit();
        }
    },[]);
    useEffect(() => {
        const url = (window.location !== window.parent.location) ? document.referrer : document.location.href;
        if(url.indexOf(".cn") > -1){
            setMbUrl(manageBacUrlChina)
          } else {
            handleMbRedirecton();
          }
    },[])
    useEffect(() => {
        handleMbRedirecton()
    },[mbUrl, handleMbRedirecton])

    const handClickSubmit = (region:string) => {
        setLoading(true)
        setRegion(region)
    }

    const getCenterSectionStyle = useMemo(() => {
      if(visibleSection === VISIBLE_SECTIONS.MB){
          return 'login-transition-height-259';
      }else{
        if(visibleSection === VISIBLE_SECTIONS.LOGIN){
          return 'login-transition-height-283';
        }else{
          return 'login-transition-height-250';
        }
      }
      
    },[visibleSection]);
    const renderInitialButtons = () => {
        return <>
        <div className="login-first">
            First-time users enjoy a free 7 day trial
        </div>
        <Button icon={<MBLogo />} className='login-with-mb-button-wrapper' text={<div className="login-with-mb-btn-text">
                LOG IN WITH
                <span className="login-with-mb-btn-text-inner"> ManageBac
                </span>
            </div>} onClick={() => setVisibleSection(VISIBLE_SECTIONS.MB)}  btnTheme={ThemeEnum.White}/>
            <GoogleLogin />
        </>;
      };
      const onSubmitClick = () => {
        setSubmitLogin(true);
      };
      const renderMBForms = (manageBacUrl:string, regionVal:string, className?:string) => {
        return  <form ref={mbFormRef} method='POST' action={manageBacUrl}>
        <Button type={'submit'} className={classNames(className, 'login-with-mb-button-wrapper')} text={
          <>
            {(isLoading && region === regionVal )? <div className='absolute pl-10px'><Loader size={SizeEnum.Small} /></div> :null }
            <div className={classNames("flex items-center justify-center capitalize w-full",{'opacity-50': (isLoading && region === regionVal )})} >
            <MBLogo/>
              <Text className={`tracking-0px text-center leading-18px ml-3 text-13px font-bold`}  >
              {regionVal === 'global' ? 'ManageBac Global' : 'ManageBac China'}
              </Text>
              {regionVal === 'china' && <><div className="mr-2" /><MBLogoChina /> </>}
            </div>
           
          </>
        } onClick={() => handClickSubmit(regionVal)} btnTheme={ThemeEnum.White}/>
      </form>
      }
      const renderMBButtons = () => {

        return <>
            <div className={classNames('mb-btn-wrapper', {'mb-btn-animation': visibleSection})}>
                <Text className="tracking-normal text-center leading-5 text-#2D3838 py-5 text-15px" >
                  <>
                    Please chooseyour <strong>ManageBac</strong> region
                  </>
                </Text>
                {renderMBForms(manageBacUrl, 'global')}
                {renderMBForms(manageBacUrlChina, 'china', 'mt-6 mb-2')}
            </div>
        </>;
      };
    const renderContent = () => {
        switch (visibleSection) {
          case VISIBLE_SECTIONS.INITIAL:
            return renderInitialButtons();
          case VISIBLE_SECTIONS.LOGIN:
            return <LoginForm
                requestError={requestError}  
                setFormValided = {setFormValid}
                isSubmitLogin = {isSubmitLogin}
                setSubmitLogin = {setSubmitLogin}
              />;
          case VISIBLE_SECTIONS.MB:
            return renderMBButtons();
          default:
            return <div>unhandled</div>;
        }
    };
    // const canSubmit = props.isFormValid;
    if (isRequestInProgress)
        return <FullPageLoader text={'Logging you in'}/>;
    return( 
        <>
            <div className='center-section-wrapper'>
                <div className="login-bg" />
                <div className='center-col'>
                    <SMPPLogo />
                    <div className={'center-section-outer'}>
                        <div className={classNames(getCenterSectionStyle, 'center-section', 
                        {'pt-5 pr-19px pb-24px pl-5': visibleSection === VISIBLE_SECTIONS.LOGIN},
                       {'p-5': visibleSection !== VISIBLE_SECTIONS.LOGIN}
                        )} >
                            {/* @ts-ignore */}
                            <AuthHeader 
                                leftSection={visibleSection !== VISIBLE_SECTIONS.INITIAL ? <BackButton onClick={() => {
                                  setVisibleSection(VISIBLE_SECTIONS.INITIAL);
                                }}>Back</BackButton> : undefined}
                                rightSection={visibleSection === VISIBLE_SECTIONS.LOGIN ?
                                  <div className="z-10 cursor-pointer" onClick={onSubmitClick}>
                                    <div className={classNames("auth-header-login-text", {'active-login-btn': visibleSection}, {'active-login-btn': isFormValid}, {'inactive-login-btn': !isFormValid})}>Log in</div>
                                    </div> : undefined}
                                title={<div className="auth-login-text">Log in </div>}
                                /> 
                            {renderContent()} 
                        </div>
                        <LandingPageLinks hide={visibleSection === VISIBLE_SECTIONS.MB || visibleSection === VISIBLE_SECTIONS.LOGIN} onClick={() => {
                          setVisibleSection(VISIBLE_SECTIONS.LOGIN)
                        }}/>
                    </div>
                    <LandingPageLogo hide={false} loginHide={false} />
                </div>
                <div className='login-footer'>
                    <LoginFooter hide={false}/>
                </div>
            </div>
      
        </>
    )
}
//TODO- withRouter ignore
//@ts-ignore
export default withRouter(LoginPage);
