import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import MediaTypeTabSelector from 'v2/components/media-tab/MediaTypeTabSelector';
import RouterLink from 'router/Link';
import { activeSubjectId, getActiveSubject } from 'selectors/subject/subjectSelector';
import { isDeskEditModeActive, getActiveDeskTab } from 'selectors/navigation/navigationSelector';
import { activeSubjectExerciseNotificationsCount, activeSubjectExerciseNotificationsCountByTlvl } from 'selectors/exerciseSelectors';
import { setActiveDeskTab, setDocumentTitle, toggleDeskEditMode, toggleFlashcardNavigationVisibility } from 'actions/navigation/navigationActions'
import { useHistory } from 'react-router-dom';

const ExerciseDeskHeaderContainer = () => {
  const { location } = useHistory();
  const dispatch = useDispatch();
  const subjectId = useSelector(activeSubjectId);
  const hasDeskEditModeActive = useSelector(isDeskEditModeActive);
  const activeDeskTab = useSelector(getActiveDeskTab);
  const notificationsCount = useSelector(activeSubjectExerciseNotificationsCount);
  const activeSubject = useSelector(getActiveSubject);
  const notificationsCountByTlvl = useSelector(activeSubjectExerciseNotificationsCountByTlvl);
  const notificationCountByTlvl = useMemo(() => activeSubject?.tagging === "NEW_SUBJECT" ? notificationsCountByTlvl : notificationsCount,[activeSubject?.tagging, notificationsCount, notificationsCountByTlvl])

  useEffect(() => {
    dispatch(setDocumentTitle('Exercises'));
    if (location.pathname.indexOf('exercises') !== -1) {
      dispatch(setActiveDeskTab(0));
    }
    else {
      dispatch(setActiveDeskTab(1));
    }
    return () => {
      dispatch(toggleDeskEditMode(false));
      dispatch(toggleFlashcardNavigationVisibility(true));
    }
  }, [])

  return (
    <>
      <div className='pt-1 px-5 font-bold text-[33px] leading-[45px] text-#2D3838 pb-2'>Exercises</div>
      <MediaTypeTabSelector subjectId={subjectId} />
      <div className='pt-3.5 px-5'>
        <div className={classNames('flex bg-[#F1F3F2] rounded-[18px] justify-between', { 'pointer-events-none': hasDeskEditModeActive })}>
          <div className='flex-1'>
            <RouterLink tabTitle={'notes'} to='/flashcard/desk/exercises'>
              <div className={classNames('exercise-tab-wrapper uppercase text-[13px] font-bold cursor-pointer leading-[26px] w-full text-center tracking-[2px] py-[5px] px-5 rounded-[18px] text-[#A2A3A3]', { '!text-white shadow-#3E5755 active': activeDeskTab === 0, 'opacity-30 pointer-events-none': hasDeskEditModeActive })}>Personal</div>
            </RouterLink>
          </div>
          <div className='flex-[1.25]'>
            <RouterLink to='/flashcard/classroom'>
              <div className={classNames('exercise-tab-wrapper uppercase text-[13px] font-bold cursor-pointer leading-[26px] w-full text-center tracking-[2px] py-[5px] rounded-[18px] text-[#A2A3A3]', { '!text-white shadow-#3E5755 active': activeDeskTab === 1, 'opacity-30 pointer-events-none': hasDeskEditModeActive, 'py-[5px] pl-5': notificationCountByTlvl })}>
                <div className='flex items-center justify-center'>
                  From Teacher
                  {notificationCountByTlvl > 0 && <div className='flex rounded-[10px] h-5 min-w-[20px] text-white leading-[1.1] text-center font-[600] text-[13px] ml-[5px] items-center justify-center px-[5px] exercise-notifications'>{notificationCountByTlvl}</div>}
                </div>
              </div>
            </RouterLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExerciseDeskHeaderContainer