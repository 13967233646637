export const getActiveFlashcardTab = (state) => state.getIn(['navigation', 'active']);
export const isFlashcardNavExpanded = (state) => state.getIn(['navigation', 'isFlashcardNavExpanded']);
export const getActiveDeskTab = (state) => state.getIn(['navigation', 'activeDeskTab']);
export const getActiveTrophiesTab = (state) => state.getIn(['navigation', 'activeTrophiesTab']);
export const getActiveItemStoreDetailsTab = (state) => state.getIn(['navigation', 'activeItemStoreDetailsTab'], 0);
export const isMenuVisible = (state) => state.getIn(['navigation', 'isMenuVisible']);
export const isSubjectsManagerVisible = (state) => state.getIn(['navigation', 'isSubjectsManagerVisible']);
export const isDeskEditModeActive = (state) => state.getIn(['navigation', 'isDeskEditModeActive']);
export const isLastCardMessageHidden = (state) => state.getIn(['navigation', 'isLastCardMessageHidden']);
export const isStoreModalOpen = (state) => state.getIn(['navigation', 'isStoreModalOpen'], false);
export const isFlashcardNavVisible = (state) => state.getIn(['navigation', 'isFlashcardNavVisible']);

// TODO refactor the left menu selectors
export const isNavigationSectionVisible = (state) => !state.getIn(['navigation', 'isNavigationSectionVisible']);
export const isLeftMenuOpen = state => state.getIn(['navigation', 'isNavigationSectionVisible']);
export const isLinksNavigationSectionVisible = (state) => !state.getIn(['navigation', 'isLinksNavigationSectionVisible']);
export const isSubjectsManagerOverlayVisible = state => state.getIn(['navigation', 'isSubjectsManagerOverlayVisible']);
export const isSubjectsManagerOverlayAnimationVisible = state => state.getIn(['navigation', 'isSubjectsManagerOverlayAnimationVisible']);
export const isOldSubjectsManagerOverlayVisible = (state) => state.getIn(['navigation','isOldSubjectsManagerOverlayVisible']);
export const isOldSubjectsManagerOverlayAnimationVisible = state => state.getIn(['navigation', 'isOldSubjectsManagerOverlayAnimationVisible']);
export const isKeyboardNavigationOverlayVisible = state => state.getIn(['navigation', 'isKeyboardNavigationOverlayVisible']);
export const isSupportManagerOverlayVisible = state => state.getIn(['navigation', 'isSupportManagerOverlayVisible']);
export const isDeviceManagerOverlayVisible = state => state.getIn(['navigation', 'isDeviceManagerOverlayVisible']);
export const isMoreControlOverlayVisible = state => state.getIn(['navigation', 'isMoreControlOverlayVisible']);
export const isQuestionInfoOverlayVisible = state =>state.getIn(['navigation','isQuestionInfoOverlayVisible'])
export const isHiddenInfoOverlayVisible =state => state.getIn(['navigation','isHiddenInfoOverlayVisible'])
export const isShortcutMenu = (state) => state.getIn(['navigation', 'isShortcutMenu']);
export const lastMenuPath = (state) => {
    let lastMenuPath = state.getIn(['navigation', 'lastMenuPath']);
    if(lastMenuPath){
        lastMenuPath = lastMenuPath
    }
    return lastMenuPath
   
};
export const getActiveMediaTab = (state) => state.getIn(['navigation', 'activeMediaTab']);
export const getExerciseOnCanvas = (state) => state.getIn(['navigation', 'exerciseOnCanvas']);
export const scrollPosition = state => state.getIn(['navigation', 'scrollPosition']);
export const isFilterOverlayVisible = state => state.getIn(['navigation', 'isFilterOverlayVisible']);
export const getFilterItemSize = (state) => state.getIn(['navigation', 'filterItemSize']);
export const isTeachingLevelOverlayVisible = state => state.getIn(['navigation', 'isTeachingLevelOverlayVisible']);
export const macroFilter = state => state.getIn(['navigation', 'macroFilter']);
export const structureLoading = state => state.getIn(['navigation', 'structureLoading']);
export const setMediaTypeFilter = state => state.getIn(['navigation', 'setMediaTypeFilter']);
export const apiWaitingResponse = state => state.getIn(['navigation', 'apiWaitingResponse']);
export const execriseFilterItemSize = state => state.getIn(['navigation', 'execriseFilterItemSize']);
export const firstNode = state => state.getIn(['navigation', 'firstNode']);
export const firstTopicId = state => state.getIn(['navigation', 'firstTopicId']);
export const firstSubtopicId = state => state.getIn(['navigation', 'firstSubtopicId']);
export const isPaperOverlayVisible = state => state.getIn(['navigation','isPaperOverlayVisible']);
export const isDiffficultyOverlayVisible = state => state.getIn(['navigation','isDiffficultyOverlayVisible']);
export const getActiveExamsTab = state => state.getIn(['navigation','activeExamsTab']);
export const getActiveExamsId = state => state.getIn(['navigation','activeExamsId']);
export const getActiveExamsPaperId = state => state.getIn(['navigation','activeExamsPaperId']);
export const isVideoOverlayVisible = state => state.getIn(['navigation','isVideoOverlayVisible']);
export const isSourceOverlayVisible = state =>state.getIn(['navigation','isSourceOverlayVisible']);
export const getActivePaperTab =state => state.getIn(['navigation','activePaperTab']);
export const isTeachingLevelExamsOverlayVisible = state =>state.getIn(['navigation','isTeachingLevelExamsOverlayVisible']);
export const isExamsMode = state => state.getIn(['navigation','isExamsMode']);
export const isMCQOverlayVisible =state => state.getIn(['navigation','isMCQOverlayVisible']);
export const getActiveExamsDetails =state => state.getIn(['navigation','activeExamsDetails']);
export const isMainMicroFilterVisible = state=> state.getIn(['navigation','isMainMicroFilterVisible']);
export const getexamslistSize = state=> state.getIn(['navigation','examslistSize'])
export const isMainMicroFilterBack =state=> state.getIn(['navigation','isMainMicroFilterBack']);
export const isProgressBarFilterVisible =state=> state.getIn(['navigation','isProgressBarFilterVisible']);
export const getJWTToken = state=>state.getIn(['navigation','setJWTToken']);
export const getUpdateExamsPreviewList = state=>state.getIn(['navigation','updateExamsPreviewList'])
export const getFolderAddButtonActive = state => state.getIn(['navigation','folderAddButtonActive'])
export const isEnableCookiesOverlay = state => state.getIn(['navigation', 'isEnableCookiesOverlayVisible']);
export const getTopicsExamLoading = state => state.getIn(['navigation','topicsExamLoading'])
export const getActiveExamModule = state => state.getIn(['navigation','activeExamModule'])
export const isAccountsPortalOverlayVisible = state => state.getIn(['navigation', 'isAccountsPortalOverlayVisible'])
export const getprogressBarData = state=> state.getIn(['navigation','progressBarData'])
export const isTeachingLevelOverlayVisibleFromFeature = state => state.getIn(['navigation', 'isTeachingLevelOverlayVisibleFromFeature']);
