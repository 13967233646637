import React from 'react';
import { changeMenuPathAction } from 'actions/menuActions';
import { useDispatch } from 'react-redux'
import BackButton from 'v2/components/navigation-header/BackButton';

const BackToProfileButtonContainer = () => {
  const disptach = useDispatch()
  return <div className='cursor-pointer z-[1px]' onClick={() => disptach(changeMenuPathAction('profile'))}>
    <BackButton text="My Profile"  />
  </div>;
}

export default BackToProfileButtonContainer
