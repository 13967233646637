import { useDispatch, useSelector } from 'react-redux'
import {apiRequestData, examsofFilterItems, subjectsTeachingLevel, activeSubjectId, listOfFilterCheckedItems, isLeftMenuOpen, getUserId, progressPopupOverlayNextData, progressPopupOverlayPrevious} from 'selectors'
import {getExerciseOnCanvas} from 'selectors/navigation/navigationSelector'
import {progressBarFilter, setActivePaperTab, setExerciseOnCanvas, setActiveTeachingForExam, toggleNavigationSection} from 'actions/navigation/navigationActions'
import {changeFlashcard, setActiveExerciseId, selectExams} from 'actions/studying'
import { examTeachingFilter, returnExamsStatusFilter, addBackFlashcardToLocalStorage, setLastMediaTypeVisited, setFormExams, storeExamsParentId } from "v2/helpers"
import {setExamQuestionNumber, paperAllQuestionData, updateExamsQuestionIndex} from 'actions/desk/deskActions'
import { fromJS } from 'immutable'
import { useHistory } from 'react-router-dom'

const useExamNavigationData = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const nextPaperData = useSelector(progressPopupOverlayNextData)
    const prevPaperData = useSelector(progressPopupOverlayPrevious)
    const examFilterItems = useSelector(examsofFilterItems)?.toJS();
    const subjectId = useSelector(activeSubjectId);
    const userId = useSelector(getUserId)
    const filterCheckedData = useSelector(listOfFilterCheckedItems)?.toJS()
    const exerciseCanvas = useSelector(getExerciseOnCanvas)
    const teachingLvl = useSelector(subjectsTeachingLevel);
    const hasLeftMenuOpen = useSelector(isLeftMenuOpen)

    const getNavigationData = (data: any) => {
        const itemData = data?.data.data
        const tLvl = teachingLvl[`${subjectId}`]
        const newListData = itemData?.length > 0 && examTeachingFilter(tLvl, itemData, examFilterItems.teachingLevel, 'exams')
        const qType = itemData[0].questionType.includes('mcq') ? 'Paper 1' : 'Paper 2'
        const filter = qType === 'Paper 1' ? examFilterItems.mcq : filterCheckedData['exams']
        const userResFilterData = itemData?.length > 0 && returnExamsStatusFilter(newListData, filter, qType);
        const paperData = userResFilterData
        if (paperData.length > 0) {
            const id = paperData[0].uuid
            const numbering = paperData[0].numbering
            const examId = paperData[0].experienceData.exam_uuid
            const paperUuid = paperData[0].experienceData.paper_uuid
            addBackFlashcardToLocalStorage("exams" + "-" + id, "exams" + "-" + id);
            dispatch(setActivePaperTab(paperData[0].experienceData.tag_labels?.filter((item: any) => item.type === 'paper_type')[0]?.label))
            dispatch(changeFlashcard(id, "default"))
            dispatch(progressBarFilter(false, false))
            setLastMediaTypeVisited('exams', userId);
            if (exerciseCanvas || typeof getExerciseOnCanvas == "undefined") {
                dispatch(setExerciseOnCanvas(false))
                dispatch(setActiveExerciseId(null))
            }
            dispatch(selectExams(id, 'exams', paperData.length, 1))
            setFormExams('exams')
            storeExamsParentId(paperUuid)
            const examsIndex = data.data.data.map((val: any) => {
                let numbering = val.numbering
                return { index: numbering, uuid: val.uuid, user_response: val.user_response, paperType: val.paperType}
            })
            const filterData = paperData.map((val: any) => {
                let numbering = val.numbering
                return { index: numbering, uuid: val.uuid, user_response: val.user_response }
            })
            dispatch(setExamQuestionNumber(numbering))
            dispatch(paperAllQuestionData(examsIndex))
            dispatch(updateExamsQuestionIndex(filterData, 1))
            history.push(`/flashcard/desk/exams/${examId}/${paperUuid}`);
        }else{
            dispatch(progressBarFilter(false, false))
            const navExamId = data.data.header.experienceData.exam_uuid
            const navPaperUuid = data.data.header.experienceData.paper_uuid
            history.push(`/flashcard/desk/exams/${navExamId}/${navPaperUuid}`)
            dispatch(setActiveTeachingForExam())
            if(!hasLeftMenuOpen){
                dispatch(toggleNavigationSection(true, true))
            }
        }
    
    }
    return {nextNavData: () => getNavigationData(nextPaperData), prevNavData: () => getNavigationData(prevPaperData)}
}
export default useExamNavigationData