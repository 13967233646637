import React from 'react';
import classNames from 'classnames';
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';
import { currentFlashcardId, getFlashcardById } from 'selectors/studying/index'
import { setActiveExamPaperHeader, setActivePaperTab } from 'actions/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PaperTile from './PaperTile';
import LeftActiveTile from 'v2/components/topics-tree/LeftActiveTile';

type ExamsTileContainerProps = {
    examData: any;
    paperData: any;
    examId: string;
    activeExamsId: string;
    isActive: boolean;
}

const ExamsTileContainer: React.FC<ExamsTileContainerProps> = ({ examData, paperData, examId, activeExamsId, isActive }) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const getCurrentFlashcardId = useSelector(currentFlashcardId);
    const currentFlashcard = useSelector(state => getFlashcardById(state, getCurrentFlashcardId)).toJS()
    const handleClick = (e: React.MouseEvent<HTMLElement>, value: any, activeType: string) => {
        e.stopPropagation();
        setTimeout(() => {
            dispatch(setActiveExamPaperHeader({
                'teachingTag': examData.teachingTag,
                'title': examData.examTitle
            }));

            if (activeType === 'SELECT') {
                dispatch(setActivePaperTab(value?.papers?.[0]?.paperTitle))
                const firstPaperId = value.papers[0]?.uId;
                history.push(`/flashcard/desk/exams/${examId}/${firstPaperId}`);
            }
            else {
                dispatch(setActivePaperTab(value?.paperTitle))
                history.push(`/flashcard/desk/exams/${examId}/${value.uId}`);
            }
        }, 100);
    }

    return <div
        className={classNames('p-3.5 relative select-none mb-8 cursor-pointer left-menu-exams', { 'bg-#E5ECF2': isActive, 'bg-white': !isActive })}
        onClick={(e) => handleClick(e, examData, "SELECT")}>
        {isActive && <LeftActiveTile isExercise={false} />}
        <div className='flex items-center justify-between'>
            <TeachingLevelSeperator tlvl={examData.teachingTag} tlvlTag={examData.eachingTag} isTopic={true} slTag={examData.teachingTag === 'SL'} />
            {examData.is_new && <div className='exams-tiles-new-tag'>New</div>}
        </div>
        <div className='pt-6 text-ellipsis overflow-hidden select-none font-bold text-#2D3838 exams-topic-title'>
            {examData.examTitle}
        </div>
        <div className='pt-2.5'>
            {paperData && paperData.slice().sort((a: any, b: any) => (a.paperTitle > b.paperTitle) ? 1 : -1).map((data:any, index:number) => <PaperTile key={`${examId}-${data?.paperTitle}`} data={data} examId={examId} currentFlashcard={currentFlashcard} activeExamsId={activeExamsId} onClick={handleClick}/>)}
        </div>
    </div>
}

export default ExamsTileContainer
