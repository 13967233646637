import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MarkText from 'v2/ui-components/MarkText';
import PreviewListHeader from 'v2/ui-components/search-results/PreviewListHeader';
import PreviewRightSection from './PreviewRightSection';
import { setActiveFlashcardTab, setActivePaperTab, setExamsHeaderList, setExerciseOnCanvas } from 'actions/navigation';
import { updateListOfItemsSelected } from 'actions/desk';
import PreviewListItem from 'v2/ui-components/search-results/PreviewListItem';
import { setActiveExerciseId, selectFlashcard } from 'actions/studying'
import { listOfCheckedItems, isDeskEditModeActive, getExerciseOnCanvas, flashcardSearchResults, activeSearchTerm, activeSubjectId, getActiveMediaTab, currentFlashcardId, isExamsMode } from 'selectors';
import { getUserId } from 'selectors/user';
import { getFlashcardPreviewAndSide, sortArrayString, decodeQuery, addBackFlashcardToLocalStorage, setLastMediaTypeVisited, setFormExams, setExamSubTopicId, storeExamsParentId, setExamTopicId } from 'v2/helpers'
import { setSearchIndex } from 'actions/search';
import ExamsPreviewListItem from 'v2/ui-components/search-results/ExamsPreviewListItem'
import VideoPreviewListItem from 'v2/ui-components/search-results/VideoPreviewListItem'
import RevisionPreviewListItem from 'v2/ui-components/search-results/RevisionPreviewListItem'
import ExamPreviewListItem from 'v2/pages/desk/exam/ExamPreviewListItem'
import ExamPreviewListHeader from 'v2/ui-components/search-results/ExamPreviewListHeader'
import AnswerBulletContainer from 'v2/components/AnswerBullet/AnswerBulletContainer'

enum MediaTypes {
  FLASHCARD='flashcard',
  REVISION='revision',
  VIDEO='video',
  EXAMS='exams',
}

type FlashcardResultsListProps = {
  newListItems: any,
  shouldMark: boolean,
  fromPage?: string,
  filterFlashcardId: number | null | string | any,
  mediaType?: string,
  // listOfCheckedItems: any
}

const FlashcardResultsList: React.FC<FlashcardResultsListProps> = ({ newListItems, shouldMark, fromPage, filterFlashcardId, mediaType }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const hasDeskEditModeActive = useSelector(isDeskEditModeActive);
  const hasListOfCheckedItems = useSelector(listOfCheckedItems);
  const getActiveSearchTerm = useSelector(activeSearchTerm);
  const hasActiveSubjectId = useSelector(activeSubjectId);
  const hasExerciseOnCanvas = useSelector(getExerciseOnCanvas);
  const hasFlashcardSearchResults = useSelector(flashcardSearchResults);
  const userId = useSelector(getUserId);
  const activeMediaTab = useSelector(getActiveMediaTab)
  const currentId = useSelector(currentFlashcardId)
  const hasExamsMode = useSelector(isExamsMode)
  const hasExamsModeExamMediaTab:boolean = (activeMediaTab === 'exams' && hasExamsMode)

  const onSubTopicClick = (subTopicId: any) => {
    history.push(`/flashcard/subtopic/${subTopicId}`)
    dispatch(setActiveFlashcardTab(0))
  }
  const onListItemClick = (flashcardId: number, data:any={}) => {
    const query = decodeQuery(history.location.search.substring(1));
    if (hasDeskEditModeActive) {
      dispatch(updateListOfItemsSelected(flashcardId));
    } else {
      if (flashcardId !== currentId) {  
        addBackFlashcardToLocalStorage(activeMediaTab + "-" + flashcardId, query.mediaType + "-" + currentId);
      }
      setLastMediaTypeVisited(activeMediaTab, userId);
      if (hasExerciseOnCanvas || typeof hasExerciseOnCanvas == "undefined") {
        dispatch(setExerciseOnCanvas(false))
        dispatch(setActiveExerciseId(null))
      }
      dispatch(selectFlashcard(flashcardId, shouldMark ? 'search' : 'default'));
      const searchIndex = hasFlashcardSearchResults.findIndex((item: any) => item.flashcardId === flashcardId);
      if (searchIndex !== -1) {
        dispatch(setSearchIndex(searchIndex));
      }
      if(activeMediaTab === 'exams') {
        let type, parentId
        if (hasExamsMode) {
          type = 'exams'
          parentId = data?.experienceData?.paper_uuid
        } else {
          type = 'topics'
          parentId = data?.topicId
        }
        dispatch(setExamsHeaderList({ "type" : type, "parentId" : parentId }))
        setFormExams(type)
        setExamSubTopicId(data?.subTopicId)
        storeExamsParentId(parentId)
        setExamTopicId(data?.topicId)
      }
    }
  }

  const getSubtopicTitle = (data:any) : string => {
    if (activeMediaTab === 'exams') {
      return data?.[0]?.subTopicTitle
    }
    return data?.[0]?.subTopicName
  }

  const onPaperClick = (item:any) => {
    const paperId = item?.experienceData?.paper_uuid;
    const examsId = item?.experienceData?.exam_uuid;
    const paperLable = item?.experienceData?.tag_labels?.filter((item: any) => item?.type === 'paper_type')?.[0]?.label;
    dispatch(setActivePaperTab(paperLable))
    dispatch(setActiveFlashcardTab(1))
    history.push(`/flashcard/desk/exams/${examsId}/${paperId}`);
  }

  const renderItem = (data:any) : React.ReactElement | JSX.Element => {
    let preparedPreview: any;
    if (activeMediaTab === MediaTypes.FLASHCARD) {
      preparedPreview = getFlashcardPreviewAndSide(data.preview, getActiveSearchTerm, mediaType);
    }
    if (activeMediaTab === MediaTypes.EXAMS) {
      if(hasExamsMode) {
        return <ExamPreviewListItem
          key={data.questionId}
          number=''
          teachingLevel={data.teachingLevel}
          QuestionType={data.questionType}
          flashcardId={data?.questionId}
          isEditing={hasDeskEditModeActive}
          onClick={() => onListItemClick(data?.questionId, data)}
          listOfCheckedItems={hasListOfCheckedItems}
          teachingTag={data?.questionTeachingTag}
          active={data?.questionId === currentId}
          marks={data?.marks}
          experienceData={data?.experienceData}
          rightSection={<PreviewRightSection id={data?.questionId} occurences={data?.occurences} type={MediaTypes.EXAMS} shouldMark={shouldMark} />}
          sources={data?.sources}
        />
      }
      return <ExamsPreviewListItem 
        key={data?.questionId}
        flashcardId={data?.questionId}
        isEditing={hasDeskEditModeActive}
        onClick={() => onListItemClick(data?.questionId, data)}
        listOfCheckedItems={hasListOfCheckedItems}
        flashcardNumber={data?.numbering}
        title={data?.questionTitle}
        paper={data?.paper}
        teachingTag={data?.questionTeachingTag}
        active={data?.questionId === currentId}
        marks={data?.marks}
        experienceData={data?.experienceData}
        rightSection={<AnswerBulletContainer id={data?.questionId} type={MediaTypes.EXAMS} isMcq={data?.questionType?.includes('mcq')} />}
        sources={data?.sources}
        difficulty={data?.difficulty}
        occurences= {data?.occurences}
        nodeTeachingTag={data?.nodeTeachingTag}
      />
    }
    else if (activeMediaTab === MediaTypes.VIDEO) {
      return <VideoPreviewListItem 
        key={data?.nodeId}
        id={data?.nodeId}
        isEditing={hasDeskEditModeActive}
        videoNumber={data?.numbering}
        active={data?.nodeId==currentId}
        onClick={() => onListItemClick(data?.nodeId)}
        title={data?.title}
        totalDuration={data?.totalDuration}
        totalWatched={data?.totalWatched}
        totalDurationInSeconds={data?.totalDurationInSeconds}
        totalWatchedInSeconds={data?.totalWatchedInSeconds}
        deleted={filterFlashcardId == data?.flashcardId}
        shouldMark={true}
        listOfCheckedItems={hasListOfCheckedItems}
        rightSection={<PreviewRightSection id={data?.flashcardId} occurences={data?.occurences} type={MediaTypes.VIDEO} shouldMark={shouldMark} />}
        type={"folder"}
        thumbImage={data?.thumbImage}
        shortDescription={data?.shortDescription}
        nodeTeachingTag={data?.nodeTeachingTag}
        teachingLevel={data?.teachingLevel}
        teachingTag={data?.teachingTag}
      />
    }
    else if (activeMediaTab === MediaTypes.REVISION) {
      return <RevisionPreviewListItem 
        key={data?.nodeId}
        flashcardId={data?.nodeId}
        isEditing={hasDeskEditModeActive}
        active={data?.nodeId == currentId}
        onClick={() => onListItemClick(data?.nodeId)}
        title={data?.title}
        deleted={filterFlashcardId == data?.flashcardId}
        shouldMark={true}
        listOfCheckedItems={hasListOfCheckedItems}
        rightSection={<PreviewRightSection id={data?.flashcardId} occurences={data?.occurences} type={MediaTypes.REVISION} shouldMark={shouldMark} />}
        type={"folder"}
        shortDescription={data?.shortDescription}
        revisionNumber={data?.numbering}
        nodeTeachingTag={data.nodeTeachingTag}
        teachingLevel={data.teachingLevel}
        teachingTag={data.teachingTag}
      />
    }
    return <PreviewListItem
      key={data?.flashcardId}
      listOfCheckedItems={hasListOfCheckedItems}
      flashcardId={data?.flashcardId}
      onClick={() => onListItemClick(data?.flashcardId)}
      isEditing={hasDeskEditModeActive}
      flashcardNumber={data?.absoluteNumbering || data?.numbering}
      previewTitle={data.title}
      active={data?.flashcardId == currentId}
      preview={preparedPreview?.text}
      shouldMark={false}
      deleted={data.deleted || (filterFlashcardId === data?.flashcardId)}
      rightSection={<PreviewRightSection id={data?.flashcardId} occurences={data?.occurences} type={MediaTypes.FLASHCARD} shouldMark={shouldMark} />}
      activeMath={[14982, 15075].includes(hasActiveSubjectId)}
      data={data}
    />
  }

  if (newListItems.length === 0) return null;
  return <MarkText shouldMark={shouldMark}>
    {newListItems.map((item: any, index: number) => {
      return <div key={`flashcards${item[0]?.id}`}>
        {hasExamsModeExamMediaTab ? <ExamPreviewListHeader fromPage='' index={index} onClick={() => onPaperClick(item?.[0])}
          subTopicTitle={item?.[0]?.exam + ' • ' + item?.[0]?.paper} teachingLevel={item?.[0]?.examTeachingLevel}
          teachingTag={item?.[0]?.examTeachingLevel} /> :
          <PreviewListHeader topicNumber={item?.[0]?.topicNumbering} index={index} teachingLevel={item[0].teachingLevel}
            teachingTag={typeof item[0].teachingTag !== "undefined" ? item[0].teachingTag : null} subTopicId={item[0].subTopicId}
            onClick={() => onSubTopicClick(item[0].subTopicId)} fromPage={fromPage} subTopicTitle={getSubtopicTitle(item)} data={item?.[0]}/>}
        {hasExamsModeExamMediaTab ? sortArrayString(item, 'position')?.map((subItem) => renderItem(subItem)) :
          item.map((subItem: any) => renderItem(subItem))}
      </div>
    })}
  </MarkText>
}

export default FlashcardResultsList;
