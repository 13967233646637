import './FoldersList.scss';
import { setGoBackToSearch } from 'actions/search';
import React, { createRef, PureComponent } from 'react';
import FolderListItem from './FolderListItem';
import { LearnFolderIcon, MemorizedFolderIcon, RepeatFolderIcon, BookmarkedIcon } from 'icons/FolderIcons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Immutable from 'immutable';
import { connect } from 'react-redux';
import * as Actions from 'actions';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import * as Selectors from 'selectors';
import appHistory from 'appHistory';
import Padding from 'ui-components/helpers/Padding';
import { CreateFolderIcon } from 'icons/HeaderIcons';
import RouterLink from 'router/Link';
import { Reorder, getAnswerListStyle } from 'helpers';
import FlashcardEmptyScreen from 'v2/components/empty-screen-message/FlashcardEmptyScreen';
export class FoldersListContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      folders: [],
      foldersHeight: 0
    }
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  componentDidMount() {
    this.calculateFolderContainerHeight();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const propFolders = nextProps.folders.getIn(['data', 'items'], Immutable.List())
    if (prevState.folders.length != propFolders.size) {
      return {
        folders: [...propFolders]
      }
    }
    return null;	
  }

  onDragEnd(result) {
    const { destination, source, draggableId } = result
    if (!result.destination) {
      return;
    }
    if (result.type === "Folders") {

      const folders = Reorder(
        this.state.folders,
        result.source.index,
        result.destination.index
      );

      const sequenceFolderIds = folders.map(items => {
        return items.get('id');
      });
      const sequenceFolder = { sequence_folder: sequenceFolderIds.toString() };
      this.props.updateFolderSequence(sequenceFolder);
      this.setState({
        folders
      });
    }
  }
  onSmartFolderClick(type) {
    this.onFolderClick(Immutable.Map({ id: type, isSmart: true }));
  }

  redirectOnFolderClick(item) {
    let folderInfo = { id: item.get('id') };
  
    return this.props.changeRoute ? this.props.changeRoute('folderContent', folderInfo) : appHistory.push(`/flashcard/desk/folders/${item.get('id')}`);
  }

  onFolderClick(item) {
    // Remove previous clickable folder list on button click
    this.props.clearFlashcardsInFolder();
    this.props.clearFolder();
    this.props.setGoBackToSearch(false);
    return this.props.isDeskEditModeActive ?
      this.props.updateListOfItemsSelected(item.get('id')) : this.redirectOnFolderClick(item);
  }
  calculateFolderContainerHeight = () => {
    if (this.refs.folderContainerHeight && this.refs.folderContainerHeight.clientHeight) {
      this.setState({ foldersHeight: this.refs.folderContainerHeight.clientHeight });
    }
  }

  render() {
    const folders = this.state.folders;
    return (
      <div className="folders-list">
        <div className='folders-section'>
          <SytledSmartFolderList className='folders-list' editing={this.props.isDeskEditModeActive}>
            <FolderListItem
              hasFlashcard={true}
              flashcardFolders={this.props.flashcardContent && this.props.flashcardContent.get('bookMark')}
              onClick={() => this.onSmartFolderClick('bookmark')} 
              name='Bookmarked' />
             {/* <FolderListItem
              hasFlashcard={this.props.smartFolderType === 'repeat'}
              onClick={() => this.onSmartFolderClick('repeat')} name='Repeat'
              icon={<RepeatFolderIcon title={'list-repeat'} />} />
            <FolderListItem
              hasFlashcard={this.props.smartFolderType === 'memorized'}
              onClick={() => this.onSmartFolderClick('memorized')} name='Memorized'
              icon={<MemorizedFolderIcon title={'list-memorized'} />} /> */}
          </SytledSmartFolderList>
        </div>

        <div className='folders-section'>
          <h2 className='section-header'>PERSONAL</h2>
          <FlashcardEmptyScreen type='folders' size={folders.length} changeRoute={this.props.changeRoute} edit={this.props.isDeskEditModeActive}><CreateFolderIcon /></FlashcardEmptyScreen>
          <StyledDragSection ref="folderContainerHeight" folderHeight={this.state.foldersHeight}>
            <DragDropContext
              onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable" type="Folders" direction="vertical">
                {(provided, snapshot) => (
                  <div className="dragFolder" {...provided.droppableProps} ref={provided.innerRef}>
                    {folders.map((folder, index) => (
                      <FolderListItem
                        key={index}
                        index={index}
                        folder={folder}
                        foldercount={folders.length}
                        isfolders={true}
                        isMobile={this.props.isMobile}
                        isLandscape={this.props.isLandscape}
                        flashcardFolders={this.props.flashcardFolders}
                        onClick={() => this.onFolderClick(folder)}
                        isEditing={this.props.isDeskEditModeActive}
                        listOfCheckedItems={this.props.listOfCheckedItems} />
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </StyledDragSection>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  folders: Selectors.folders(state),
  isDeskEditModeActive: Selectors.isDeskEditModeActive(state),
  listOfCheckedItems: Selectors.listOfCheckedItems(state),
  currentFlashcardId: Selectors.Studying.currentFlashcardId(state),
  flashcardContent: Selectors.Studying.getFlashcardById(state, Selectors.Studying.currentFlashcardId(state)),
  flashcardFolders: Selectors.apiRequestData(state, 'flashcardFolders'),
  flashcardBookmarks: Selectors.apiRequestData(state, 'flashcardBookmarks'),
  smartFolderType: Selectors.smartFolderType(state),
  isMobile: Selectors.isMobile(state),
  isLandscape: Selectors.isLandscape(state),
  //activeSubjectId: Selectors.activeSubjectId(state),
});

const mapDispatchToProps = {
  updateListOfItemsSelected: Actions.Desk.updateListOfItemsSelected,
  setGoBackToSearch: setGoBackToSearch,
  updateFolderSequence: Actions.Desk.updateFolderSequence,
  clearFlashcardsInFolder: Actions.Desk.clearFlashcardsInFolder,
  clearFolder: Actions.Desk.clearFolder,
};

FoldersListContainer.propTypes = {
  smartFolderType: PropTypes.string,
  folders: PropTypes.object,
  currentFlashcardId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isDeskEditModeActive: PropTypes.bool,
  updateListOfItemsSelected: PropTypes.func,
  listOfCheckedItems: PropTypes.instanceOf(Immutable.List),
  flashcardFolders: PropTypes.instanceOf(Immutable.List),
};

export default connect(mapStateToProps, mapDispatchToProps)(FoldersListContainer);


const StyledDragSection = styled.div`
  min-height: ${props => props.folderHeight}px;
`;

const SytledSmartFolderList = styled.ul`
  ${props => props.editing && 'opacity: 0.5; pointer-events: none;'}
`;
