import { useDispatch, useSelector } from 'react-redux'
import {examsofFilterItems, subjectsTeachingLevel, activeSubjectId, listOfFilterCheckedItems, getPaperAllQuestionData} from 'selectors'
import { fromJS } from 'immutable'
import {examTeachingFilter, returnExamsStatusFilter} from 'v2/helpers';
import {changeFlashcard, selectExams} from 'actions/studying'
import {setExamQuestionNumber, updateExamsQuestionIndex} from 'actions/desk/deskActions'
import {currentFlashcardId, getFlashcardById} from 'selectors/studying'
import { useCallback } from 'react';
const useSetFirstQuestion = () => {
    const dispatch = useDispatch();
    const currentId = useSelector(currentFlashcardId)
    const examFilterItems = useSelector(examsofFilterItems)?.toJS();
    const currentContent = useSelector((state) => getFlashcardById(state, currentId))?.toJS()
    const paperAllQuestions = useSelector(getPaperAllQuestionData)?.toJS()
    const filterCheckedData = useSelector(listOfFilterCheckedItems)?.toJS()
    const subjectId = useSelector(activeSubjectId);
    const teachingLvl = useSelector(subjectsTeachingLevel);
    const tLvl = teachingLvl[`${subjectId}`]
    const questionType = currentContent?.questionType?.includes('mcq') ? 'Paper 1' : 'Paper 2'
    const newListItem = examTeachingFilter(tLvl, paperAllQuestions, examFilterItems.teachingLevel, 'exams');
    const filter = questionType === 'Paper 1' ? examFilterItems.mcq : filterCheckedData['exams']

    const userResponseFilterData = returnExamsStatusFilter(newListItem, filter, questionType);
    const paperData = fromJS(userResponseFilterData)

    const setFirstQuestion = useCallback(() => {
        if (paperData.length > 0) {
            const numbering = paperData[0].index;
            const QuestionList = paperData[0].uuid
            dispatch(changeFlashcard(QuestionList, "default"))
            dispatch(selectExams(QuestionList, 'exams', paperData.length, 1))
            dispatch(updateExamsQuestionIndex(paperData, 1))
            dispatch(setExamQuestionNumber(numbering))
        }
    }, [paperData, dispatch]);
   
    return {navFirstQuestion: () => setFirstQuestion()}
}

export default useSetFirstQuestion