import React ,{useEffect}  from 'react';
import {connect} from 'react-redux';
import { MircoFilterBox, MircoFilter, FilterClear, FilterText, MircoFilterContainer } from 'components/studying/microFilterContainer/MircoFilter.styles';
import Text from 'ui-components/helpers/Text';
import * as Selectors from 'selectors';
import * as Actions from 'actions';
import {getUserId} from 'selectors/user';
import {currentFlashcardId} from 'selectors/studying';
import {getActiveSubject, isFilterOverlayVisible} from 'selectors';
import { ArrowdownIcon, FilterClearIcon} from 'icons/HeaderIcons';
import {ConfidentIcon, UnconfidentIcon, NeitherIcon } from 'icons/FlashcardIcons';
import appHistory from 'appHistory';
import {getSelectedFlashcards} from 'actions/topics-tree/topicsTreeActions';
import {getActiveExerciseId} from 'selectors/studying'
import { titles} from 'components/studying/microFilter/MicroFilterOverlay'
import {updateExerciseNumber} from 'actions/topics-tree/topicsTreeActions';

const MircoFilterLayout = (props) => {

  let data=  props.page === 'exams' || props.formCC ? props.listOfFilterCheckedItems.toJS()['exams'] :  props.listOfFilterCheckedItems.toJS()[props.activeMediaTab]
  let title = props.page === 'exams' ? titles['exams'] : titles[props.activeMediaTab] 
  function setFormCCPopoup(){
    if(props.formCC){ 
        props.mainMicroFilterBack(true,true);
    }else{
        props.mainMicroFilterBack(false,false);
    }
}

    const selectFilter = (e) => {
      const mediaType = props.page === 'exams' ? 'exams': props.activeMediaTab;
      e.stopPropagation();
      props.toggleFilterOverlay(true, true);
      props.getMediaTypeFilterOverlay(mediaType);
      setFormCCPopoup()
      if(props.isMainMicroFilterVisible) return props.toggleMainMicroOverlay(false,false)
    };

    const clearFilter = (e) => {
      const mediaType = props.page === 'exams' ? 'exams': props.activeMediaTab;
      e.stopPropagation();
      props.updateFilterListOfItemsSelected([], mediaType);
      const pathname = appHistory.location.pathname;
      if (pathname.indexOf("/flashcard/desk/exercise/edit/") !== -1  || (pathname.indexOf("flashcard/desk/exercise/create")  !== -1 )) {
        props.updateExerciseNumber(props.getActiveExerciseId)
      }
      props.setExamFilterData();
    }

    const assesmentIcon = (value) =>{
      let val;
      if(value.includes("Not yet assessed")){
        if(data.length > 1)
          val = 'Not ye...'
        else
          val = 'Not yet assessed'
      }else if(value.includes("Unconfident")){
        val = 'Unconfident'
      }else if(value.includes("Neither")){
        val = 'Neither'
      }else if(value.includes("Confident")){
        val = 'Confident'
      }
      switch (val) {
        case 'Not yet assessed':
            return <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{val}</Text>
        case 'Unconfident':
          return <UnconfidentIcon/>
        case 'Neither':
          return <NeitherIcon/>
        case 'Confident':
          return <ConfidentIcon/>
        default:
          return <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{val}</Text>
      }
    }
    
  return(
      <React.Fragment>

        {data.length > 0 && ((props.activeMediaTab === "flashcard" && data.length <4 ) || (props.activeMediaTab === "revision" && data.length < 2) || (props.activeMediaTab === "video" && data.length < 3) || (props.activeMediaTab === "exams" && data.length <4 ) || props.page === 'exams' && data.length < 4 || props.formCC && data.length < 4) ?
          <MircoFilterBox page={props.page} marginTop={props.marginTop}  className={`${(props.isEditMode) ? "menu-animation" : ""}`}>
          <MircoFilterContainer>
              <MircoFilter className={"outerDiv"} filter={true} onClick={(e) =>selectFilter(e)} isFolderEdit={props.isEditMode}>
                {props.activeMediaTab === "flashcard" || props.activeMediaTab === "exams" || props.page === 'exams' || props.formCC    ? 
                  <React.Fragment>
                    {assesmentIcon(data)}{data.length > 1 && <FilterText data={data[0]}>+ {data.length-1}</FilterText>}
                    <div className="closeButton1"> </div>
                </React.Fragment>
                :
                <React.Fragment>
                  <Text size={13} weight={'bold'} color={'#378EF8'} lineHeight={'18px'}>{data[0]}{data.length > 1 && <span> + {data.length-1}</span>}</Text>
                  <div className="closeButton1"></div>
                </React.Fragment>
                }              
              </MircoFilter>
              <FilterClear onClick={(e) =>clearFilter(e)} isEditMode={props.isEditMode}> <FilterClearIcon /></FilterClear>
            </MircoFilterContainer>
          </MircoFilterBox>
         
          :
          <MircoFilterBox  page={props.page} marginTop={props.marginTop}>
            <MircoFilter onClick={(e) =>selectFilter(e)} className={"outerDiv"} filter={props.isFilterOverlayVisible} isMarging={true} isFolderEdit={props.isEditMode}>
              <Text size={13} weight={'bold'} color={'#2D3838'} lineHeight={'18px'}> {title}</Text>
              <ArrowdownIcon isMarging={true}/>
            </MircoFilter>
          </MircoFilterBox>
        }
      </React.Fragment>
  ) 
}

const mapStateToProps = (state,ownProps) => ({
  isFilterOverlayVisible: isFilterOverlayVisible(state),
  activeMediaTab: Selectors.getActiveMediaTab(state),
  activeSubject: getActiveSubject(state),
  currentFlashcardId: currentFlashcardId(state),
  userId: getUserId(state),
  getActiveExerciseId: getActiveExerciseId(state),
  listOfFilterCheckedItems: Selectors.listOfFilterCheckedItems(state),
  isMainMicroFilterVisible: Selectors.isMainMicroFilterVisible(state)
});

const mapDispatchToProps = {
  toggleFilterOverlay: Actions.Navigation.toggleFilterOverlay,
  getMediaTypeFilterOverlay: Actions.Navigation.getMediaTypeFilterOverlay,
  getSelectedFlashcards: getSelectedFlashcards,
  updateFilterListOfItemsSelected: Actions.Desk.updateFilterListOfItemsSelected,
  updateExerciseNumber: updateExerciseNumber,
  toggleMainMicroOverlay:Actions.Navigation.toggleMainMicroOverlay,
  mainMicroFilterBack:Actions.Navigation.mainMicroFilterBack,
  setExamFilterData: Actions.Api.setExamFilterData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MircoFilterLayout);