import { useEffect, useState } from 'react';
import { getLastMediaTypeVisited } from 'helpers';
import './studyStatus.scss';
import { useDispatch, useSelector } from 'react-redux';
import { currentFlashcardId } from 'selectors/studying';
import { getUserId } from 'selectors/user';
import { markById } from 'selectors/answersSelectors';
import { getActiveMediaTab } from 'selectors/navigation/navigationSelector';
import { isApiRequestInProgress } from 'selectors/api/apiSelector';
import { isMobile } from 'selectors/browser/browserSelector';
import { setMarks, updateMarkValueOfTitlesByFlashcardId } from 'actions/api';
import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2';
import { RightIcon } from 'icons/FlashcardNavIcons';
import { isMobile as isTouchDevice } from 'react-device-detect';

const StudyStatus = () => {
    const dispatch = useDispatch();
    const userId = useSelector(getUserId);
    const mediaType = getLastMediaTypeVisited(userId);
    const currentId = useSelector(currentFlashcardId);
    const mark = useSelector(state => markById(state, currentId));
    const activeMediaTab = useSelector(getActiveMediaTab);
    const inProgress = useSelector(state => isApiRequestInProgress(state, 'marks'))
    const hasMobile = useSelector(isMobile);
    const [isHover, setHover] = useState<boolean>(false);
    const keyEventListener = (e: any) => {
        if (['INPUT', 'TEXTAREA'].indexOf(e.target.nodeName) !== -1) return;
        if (e.key === '1') {
            markItem(0);
        }
        else if (e.key === '3') {
            markItem(1);
        }
    }
    const markItem = (markVal: number) => {
        if (mark.get('markValue') == 1 && markVal === 0) {
            dispatch(updateMarkValueOfTitlesByFlashcardId(currentId, 0));
            dispatch(setMarks({ id: currentId, activeTab: activeMediaTab, markValue: 0, lastPlayDuration: "" }));
        }
        if ((mark.get('markValue') == 0 || mark.get('markValue') == null) && markVal === 1) {
            dispatch(updateMarkValueOfTitlesByFlashcardId(currentId, 1))
            dispatch(setMarks({ id: currentId, activeTab: activeMediaTab, markValue: 1, lastPlayDuration: "" }));
        }
        setHover(false);
    }
    useEffect(() => {
        if (mediaType === 'revision') {
            window.addEventListener('keyup', keyEventListener)
        }
        return () => window.removeEventListener('keyup', keyEventListener)
    }, [])
    const handleMouseEnter = () => {
        if (!hasMobile || !isTouchDevice) {
            setHover(true);
        }
    }
    if (!mark) return null;
    return <>
        {inProgress ? <div className='loader-wrapper'><Loader size={SizeEnum.VSmall} background={BackgroundEnum.Blue} /></div> :
            <>
                {mark.get('markValue') ? <div className="flex items-center justify-center">
                    <div onClick={() => markItem(0)} onMouseEnter={handleMouseEnter} onMouseLeave={() => setHover(false)} className='marked-as-read bg-[#51D4A3] rounded-[22px] py-[3px] pr-2 pl-2.5 flex items-center text-white select-none'><RightIcon /><div className='mark-text font-bold text-13px leading-18px tracking-2px ml-1.5 cursor-pointer'>{isHover ? 'PAGE NOT READ' : 'MARKED AS READ'}</div></div>
                </div> : <div className='mark-as-read bg-white box-border rounded-[22px] py-[3px] pr-2 pl-2.5 flex items-center text-blue-normal select-none cursor-pointer' onClick={() => markItem(1)}><RightIcon /><div className='mark-text font-bold text-13px leading-18px tracking-2px ml-1.5 cursor-pointer'>MARK AS READ</div></div>}
            </>}
    </>
}

export default StudyStatus