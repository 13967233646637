import React, { FC, TouchEvent, Fragment, MouseEvent, FormEvent, useEffect, useState } from 'react'
import classnames from "classnames";
import { IncreaseFlashcardZoom, DecreaseFlashcardZoom, PinZoomIncrease, ZoomClose } from 'icons/FlashcardIcons';
import RendererArea from 'components/studying/renderer/RendererArea';
import ZoomCanvas from 'components/studying/ZoomCanvas';
import { isMobile } from "selectors"
import { useSelector } from "react-redux"

type ReviosionImageZoomProps = {
    imageZoom: boolean,
    zoomLevel: any,
    ishasZoom: any,
    hasTouchDevice: any,
    imageWidth: number,
    zoomCanvas: any,
    currentFlashcardId: number,
    canEditExercise?: undefined,
    listFlashcardId?: undefined,
    selectedSide?: undefined,
    revRef: React.Ref<HTMLDivElement>,
    setImageZoom: Function,
    setImageWidth: Function,
    setImageHeight: Function,
    setZoomLevel: Function,
    flashcardContent: any,
    setHasZoom: Function

}

let zoomListener;
const ReviosionImageZoom: FC<ReviosionImageZoomProps> = (props) => {
    const { imageZoom, zoomLevel, ishasZoom, hasTouchDevice, imageWidth, zoomCanvas, currentFlashcardId, canEditExercise, listFlashcardId, selectedSide, revRef, setImageZoom, setImageWidth, setImageHeight, setZoomLevel, flashcardContent, setHasZoom } = props
    const [imageUrl, setImageUrl] = useState<string>('')

    const hasMobile = useSelector(isMobile)
    const handleMapMove = (e: TouchEvent<HTMLDivElement>) => {
        if (e.touches && e.touches.length === 2) {
            setHasZoom(true)
        }
    }
    const closeZoom = () => {
        const modalImg: any = document.getElementById("img01");
        const modal: any = document.getElementById('myModal');
        modalImg.className += " out";
        setImageZoom(false)
        onReset()
        setTimeout(function () {
            modal.style.display = "none";
            modalImg.className = "modal-contents";
        }, 400);
    }
    const onReset = () => {
        setZoomLevel({ ...zoomLevel, current: 1 })
    }

    const onZoomOut = () => {
        let finalZoomLevel = zoomLevel.current > 1.5 ? 1.5 : 1;
        if (zoomLevel.current > 1) {
            setZoomLevel({ ...zoomLevel, current: finalZoomLevel })
        }
    }

    const onChangeZoomRange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setZoomLevel({ ...zoomLevel, current: parseFloat(e.target.value) })
    }

    const onZoomIn = () => {
        let finalZoomLevel = zoomLevel.current < 1.5 ? 1.5 : 2;
        if (zoomLevel.current < zoomLevel.max) {
            setZoomLevel({ ...zoomLevel, current: finalZoomLevel })
        }
    }
    const onImageClick = () => {
        if (zoomLevel.current == 1) {
            setZoomLevel({ ...zoomLevel, current: 1.5 })
        } else {
            setZoomLevel({ ...zoomLevel, current: 1 })
        }
    }
    const setZoomReset = (val: any) => {
        setHasZoom(val)
    }
    useEffect(() => {
        imageZoomStart()
    }, [flashcardContent])
    const imageZoomStart = () => {
        // @ts-ignore
        revRef.current?.querySelectorAll('img')?.forEach(items => {
            if (items.getAttribute('src')) {
                zoomListener = items.addEventListener('click', () => {
                    setImageZoom(true);
                    setImageWidth(items.width);
                    setImageHeight(items.height);
                    const modal: any = document.getElementById('myModal');
                    modal.style.display = "flex";
                    setImageUrl(items.getAttribute('src'));
                })
            }
        });
    }

    return (
        <div id="myModal" className={classnames('img-modal renderer-wrapper', { 'image-modal-zoom': imageZoom })}
            onTouchStart={handleMapMove}>
            <Fragment>
                <div className={'zoom-close-section'}>
                    <div className={'pin-close'} onClick={closeZoom}>
                        <ZoomClose />
                    </div>
                </div>
                <div className={'zoom-section'}>
                    {zoomLevel.current > 1 || ishasZoom ?
                        <div className={`pin-zoom ${hasTouchDevice ? 'mobile-pin-zoom' : " "}`} onClick={onReset}>
                            <PinZoomIncrease />
                        </div>
                        : null}
                    {!hasTouchDevice &&
                        <div className={'zoom-factor'}>
                            <div className={`decrease-zoom ${zoomLevel.current === 1 ? 'remove-decrease-click' : ''}`} onClick={onZoomOut}>
                                <DecreaseFlashcardZoom />
                            </div>
                            <div className={'main-progress-zoom'}>
                                <input className={'input-range'} type="range" min="1" max="2" step="0.01" value={zoomLevel.current} onChange={onChangeZoomRange} />
                            </div>
                            <div className={`increase-zoom ${zoomLevel.current === 2 ? 'remove-increase-click' : ''}`} onClick={onZoomIn}>
                                <IncreaseFlashcardZoom />
                            </div>
                        </div>
                    }
                </div>
            </Fragment>
            {imageZoom &&
                //   @ts-ignore 
                <ZoomCanvas imageZoom={true} minZoom={null} width={imageWidth} revisionGuide={true} ref={zoomCanvas} isMobile={hasMobile} externalZoomFactor={zoomLevel.current} currentFlashcardId={currentFlashcardId} isTouchDevice={hasTouchDevice} isZoomed={ishasZoom} onActionFlashcard={() => onImageClick()} canEditExercise={canEditExercise} resetZoom={setZoomReset} listFlashcardId={listFlashcardId} selectedSide={selectedSide}>
                    {/* @ts-ignore           */}
                    <RendererArea width={imageWidth} isZoomed={zoomLevel.current > 1}  >
                        <img className="modal-contents" id="img01" alt="" src={imageUrl} />
                    </RendererArea>
                </ZoomCanvas>
            }
        </div>
    )

}



export default ReviosionImageZoom;