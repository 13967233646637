import './index.scss';
import {isiOSSafari } from 'helpers';
import React from 'react';
import {unregister} from 'registerServiceWorker';
import store from './store';
import AppRoutes from './routes';
import ReactDOM from 'react-dom';
import history from './appHistory';
import * as Actions from 'actions';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import {ThemeProvider} from 'styled-components/macro';
import {theme} from 'theme';
import 'react-app-polyfill/ie11'; // For IE 11 support
import { GoogleOAuthProvider } from '@react-oauth/google';

String.prototype.replaceAll = function (search, replacement) {
  let target = this;
  return target.replace(new RegExp(search, 'gi'), replacement);
};

store.dispatch(Actions.initialize());

// Resize event listener throttled
let eventTimeout;
const actualEventHandler = function () {
  store.dispatch(Actions.windowResizeListener(window.innerWidth, window.innerHeight));
};
const eventThrottler = function () {
  if (!eventTimeout) {
    eventTimeout = setTimeout(function () {
      eventTimeout = null;
      actualEventHandler();
    }, 200);
  }
};
window.addEventListener('resize', eventThrottler, false);

const userAgent = window.navigator.userAgent;
const iOSMobile = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);

if (!iOSMobile) {
  let metaViewport = document.querySelector('meta[name=viewport]');
  metaViewport.setAttribute('content', 'width=device-width, initial-scale=1');
}

if (isiOSSafari()) {
  //fix needed for react-outside-click which does not work in safari mobile
  document.body.style.cursor = 'pointer';
}

const clientId  = process.env.REACT_APP_GOOGLE_CLIENT_ID

const render = routes => {
  ReactDOM.render(
    <GoogleOAuthProvider clientId={clientId}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            {routes}
          </Router>
        </ThemeProvider>
      </Provider>
    </GoogleOAuthProvider>,
    document.getElementById('root')
  );
};

render(AppRoutes);

if (module.hot) {
  module.hot.accept('./routes', () => {
    const nextRoutes = require('./routes').default;
    render(nextRoutes);
  });
}

unregister();
