import React, {Component} from 'react';
import SpeakingAvatarComponent from './SpeakingAvatarComponent';
import {PurchasedText} from './TextAvatar';
import swal from 'sweetalert';
import 'components/tutorial/Tutorial.scss';

export default class PurchasedNotification extends Component {
  render() {
    return (
      <div>
        <div className="swal-notification start-tutorial-container">
          <SpeakingAvatarComponent showAvatar='fantastic'>
            <PurchasedText/>
          </SpeakingAvatarComponent>
        </div>
        <div className="accept-button start-tutorial-button" onClick={() => {
          swal.close();
        }}>OK
        </div>
        <div className="later-button start-tutorial-button thin-text" onClick={() => {
          swal.setActionValue({cancel: {shouldOpenMenu: true}});
          swal.close();
        }}>Merge my account
        </div>
      </div>
    );
  }
}
