import classNames from 'classnames';
import React from 'react'
import moment from 'moment';
import ExternalAvatar from 'v2/components/avatar/ExternalAvatar';
import Text from '../common/Text';
import {NrOfCards} from './exerciseComponents'
import './exercise.scss'
import FlashcardIcon from 'assets/images/study/StudyMeta/Flashcard.svg';
import {returnFinalExerciseStatus} from 'v2/helpers';
import StudyMetaInfo from 'v2/components/studying/studay-meta-info/StudyMetaInfo';

type ExternalExerciseTileProps = {
    isActive: boolean;
    titleComponent: any;
    onClick: () => void;
    exercise: any;
    hasNotification: boolean;
}  

const ExternalExerciseTile: React.FC<ExternalExerciseTileProps> = ({titleComponent, isActive, exercise, hasNotification, onClick}) => {
    const {flashcardStatus, flashcardStatusGraph} = returnFinalExerciseStatus(exercise?.answerData?.flashcardBox);
    const momentDate = moment.unix(exercise.createdAt);
    const formattedDate = momentDate.format('MMM D, Y');
    return <div className='relative mb-8'>
        {hasNotification && <div className='absolute -left-1 bg-[#FC3C2F] rounded-[20px] top-0 bottom-0 w-2' />}
        <div className='px-3.5'>
            {isActive && <div className='left-active-title' /> }
            <div className={classNames('external-tile', {'external-tile-active': isActive})} onClick={onClick}>
                <div className='flex items-center'>
                    <ExternalAvatar size={28} userId={exercise.mbTeacherId} userName={exercise.addedBy}/>
                    <Text className='text-13px font-bold leading-4 text-#979999 ml-3'>{exercise.addedBy}</Text>
                    <div className='px-1.5'>
                        <Text className='text-13px font-bold leading-4 text-#979999'>•</Text>
                    </div>
                    <Text className='text-13px leading-4 text-#979999 mr-1.5'>{formattedDate}</Text>
                    <NrOfCards>{exercise.flashcardCount}</NrOfCards> 
                </div>
                {titleComponent ? titleComponent() : <div className='mt-2.5 mb-8 font-bold text-[25px] text-#2D3838 leading-32px'>{exercise.title}</div>}
                {flashcardStatus.total > 0 && <StudyMetaInfo hasSelection={isActive} icon={FlashcardIcon} name={'Flashcards'} singleCard={'Flashcard'} mediaType={"flashcard"} number={flashcardStatus.total || 0} isRead={flashcardStatus.total === flashcardStatus.correct} statusBarData={flashcardStatusGraph} isActive={isActive} isSubtopic={false} isCarousel={false} isExercise={true}/>}
            </div>
        </div>
    </div>
}

export default ExternalExerciseTile;