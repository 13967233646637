import {
  CLEAR_SEARCH_RESULTS,
  SET_ACTIVE_SEARCH_TAB,
  SET_ACTIVE_SEARCH_TERM,
  SET_GO_BACK_TO_SEARCH,
  SET_SEARCH_INDEX,
  SET_SEARCH_RESULTS,
  SET_PREVIOUS_SEARCH_RESULTS,
  SET_SEARCH_ACTIVE
} from 'actions/search/searchTypes';
import Immutable from 'immutable';

export default (state = Immutable.fromJS({currentTab: 'Cards', results: [], goBackToSearch: false, searchActive: false}), action) => {
  switch (action.type) {
    case SET_ACTIVE_SEARCH_TAB:
      return state.set('currentTab', action.data);
    case SET_ACTIVE_SEARCH_TERM:
      return state.set('currentTerm', action.data);
    case SET_SEARCH_RESULTS:
      // Removed for now as no pagination is required
      // return state.set('results', state.get('results').concat(Immutable.fromJS(action.data)));
      return state.set('results', action.data);
    case SET_PREVIOUS_SEARCH_RESULTS:
      // Removed for now as no pagination is required
      // return state.set('results', state.get('results').concat(Immutable.fromJS(action.data)));
      return state.set('previousSearchResults', Immutable.fromJS(action.data));
    case CLEAR_SEARCH_RESULTS:
      return state
      .set('results', Immutable.List())
      .set('previousSearchResults', Immutable.List());
    case SET_GO_BACK_TO_SEARCH:
      return state.set('goBackToSearch', action.data);
    case SET_SEARCH_ACTIVE:
      return state.set('searchActive', action.data)
    case SET_SEARCH_INDEX: {
      let index = action.index;
      if (index < 0) {
        index = 0;
      }
      return state
        .set('activeResult', state.getIn(['results', index]))
        .set('searchIndex', index);
    }
    default:
      return state;
  }
};
