import React from 'react';
import {ReactComponent as NotesIconNormal} from 'assets/icons/toolbox/notes.svg';
import {ReactComponent as NotesIconActive} from 'assets/icons/toolbox/notes-active.svg';
import {ReactComponent as FoldersIconNormal} from 'assets/icons/toolbox/folders.svg';
import {ReactComponent as FoldersIconActive} from 'assets/icons/toolbox/folders-active.svg';
import {ReactComponent as SubjectsChangerNormal} from 'assets/icons/toolbox/subjects-changer.svg';
import {ReactComponent as SubjectsChangerActive} from 'assets/icons/toolbox/subjects-changer-active.svg';
import {ReactComponent as ExercisesNormal} from 'assets/icons/toolbox/exercises.svg';
import {ReactComponent as ExercisesActive} from 'assets/icons/toolbox/exercises-active.svg';
import {ReactComponent as SupportActive} from 'assets/icons/toolbox/support-active.svg';
import {ReactComponent as SupportNormal} from 'assets/icons/toolbox/support.svg';
import {ReactComponent as Swop} from 'assets/icons/toolbox/swop.svg';
import {ReactComponent as ShuffleNormal} from 'assets/icons/toolbox/shuffle.svg';
import {ReactComponent as ShuffleActive} from 'assets/icons/toolbox/shuffle-active.svg';
import {ReactComponent as Keyboard} from 'assets/icons/toolbox/keyboard-shortcut-icon.svg';
import {ReactComponent as ReportContentError} from 'assets/icons/toolbox/report-content-icon.svg';
import {ReactComponent as Close} from 'assets/icons/toolbox/close.svg';
import {ReactComponent as ToolboxOpen} from 'assets/icons/toolbox/toolbox-open.svg';
import {ReactComponent as ToolboxClosed} from 'assets/icons/toolbox/toolbox-closed.svg';
import {ReactComponent as Prev5} from 'assets/icons/toolbox/prev5.svg';
import {ReactComponent as Next5} from 'assets/icons/toolbox/next5.svg';
import {ReactComponent as Portrait} from 'assets/icons/toolbox/portrait.svg';
import {ReactComponent as Landscape} from 'assets/icons/toolbox/landscape.svg';
import styled, {css} from 'styled-components/macro';
import media from 'ui-components/helpers/media';

const BaseControlIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
  margin: 12px 12px 0 12px;
  
  
  ${media.phone(css`
      margin: 0;
  `)}
`;

const MoreControlIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  @media (pointer: fine) {
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  } 
  &:active {
    background: #378EF8;
    .toolbox-close{
      path{
        fill: white;
      }
      
    }
  }
`;

const StyledMainCotent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const StyledText = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-left: 8px;
`;

const ControlIcon = styled(BaseControlIcon)`
  background: ${props => props.active ? 'white' : 'rgba(0,0,0,0.4)'};
`;

const ToggleMoreControlIcon = styled(MoreControlIcon)`
  background: ${props => props.active ? (props.gradient ? '#378EF8' : 'none') : 'none'};
`;

const ToggleControlIcon = styled(BaseControlIcon)`
  background: ${props => props.active ? (props.gradient ? 'linear-gradient(-135deg, #48CEA8 0%, #4ABAD1 100%)' : 'white') : 'rgba(255,255,255,0.4)'};
`;

const NavigationIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.4);
`;

export function Next5Icon() {
  return <svg className="nex-5-icon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5054 12.0962C21.2967 12.0962 21.9233 12.3057 22.3853 12.7246C22.8472 13.1436 23.0781 13.7147 23.0781 14.438C23.0781 15.2759 22.8149 15.9312 22.2886 16.4038C21.7658 16.8729 21.021 17.1074 20.0542 17.1074C19.1769 17.1074 18.4876 16.966 17.9863 16.6831V15.5391C18.2764 15.7038 18.6094 15.8309 18.9854 15.9204C19.3613 16.0099 19.7104 16.0547 20.0327 16.0547C20.6021 16.0547 21.0353 15.9276 21.3325 15.6733C21.6297 15.4191 21.7783 15.0467 21.7783 14.5562C21.7783 13.618 21.1803 13.1489 19.9844 13.1489C19.8161 13.1489 19.6084 13.1668 19.3613 13.2026C19.1143 13.2349 18.8976 13.2725 18.7114 13.3154L18.1475 12.9824L18.4482 9.14746H22.5303V10.27H19.5601L19.3828 12.2144C19.5081 12.1929 19.6603 12.1678 19.8394 12.1392C20.022 12.1105 20.244 12.0962 20.5054 12.0962Z" fill="#A2A3A3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9 19C12.3137 19 15 16.3137 15 13C15 9.68629 12.3137 7 9 7C5.68629 7 3 9.68629 3 13C3 16.3137 5.68629 19 9 19ZM8.34998 9.65C8.34998 9.29101 8.64099 9 8.99998 9C9.35896 9 9.64998 9.29101 9.64998 9.65V12.3501H12.35C12.709 12.3501 13 12.6411 13 13.0001C13 13.3591 12.709 13.6501 12.35 13.6501H9.64998V16.35C9.64998 16.709 9.35896 17 8.99998 17C8.64099 17 8.34998 16.709 8.34998 16.35V13.6501H5.65C5.29101 13.6501 5 13.3591 5 13.0001C5 12.6411 5.29101 12.3501 5.65 12.3501H8.34998V9.65Z" fill="#A2A3A3"/>
  </svg>;
}
export function MoreNext5Icon() {
  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5054 12.0962C21.2967 12.0962 21.9233 12.3057 22.3853 12.7246C22.8472 13.1436 23.0781 13.7147 23.0781 14.438C23.0781 15.2759 22.8149 15.9312 22.2886 16.4038C21.7658 16.8729 21.021 17.1074 20.0542 17.1074C19.1769 17.1074 18.4876 16.966 17.9863 16.6831V15.5391C18.2764 15.7038 18.6094 15.8309 18.9854 15.9204C19.3613 16.0099 19.7104 16.0547 20.0327 16.0547C20.6021 16.0547 21.0353 15.9276 21.3325 15.6733C21.6297 15.4191 21.7783 15.0467 21.7783 14.5562C21.7783 13.618 21.1803 13.1489 19.9844 13.1489C19.8161 13.1489 19.6084 13.1668 19.3613 13.2026C19.1143 13.2349 18.8976 13.2725 18.7114 13.3154L18.1475 12.9824L18.4482 9.14746H22.5303V10.27H19.5601L19.3828 12.2144C19.5081 12.1929 19.6603 12.1678 19.8394 12.1392C20.022 12.1105 20.244 12.0962 20.5054 12.0962Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15 13C15 16.3137 12.3137 19 9 19C5.68629 19 3 16.3137 3 13C3 9.68629 5.68629 7 9 7C12.3137 7 15 9.68629 15 13ZM8.34998 9.65C8.34998 9.29101 8.64099 9 8.99998 9C9.35896 9 9.64998 9.29101 9.64998 9.65V12.3501H12.35C12.709 12.3501 13 12.6411 13 13.0001C13 13.3591 12.709 13.6501 12.35 13.6501H9.64998V16.35C9.64998 16.709 9.35896 17 8.99998 17C8.64099 17 8.34998 16.709 8.34998 16.35V13.6501H5.65C5.29101 13.6501 5 13.3591 5 13.0001C5 12.6411 5.29101 12.3501 5.65 12.3501H8.34998V9.65Z" fill="white"/>
  </svg>;
}
export function MorePre5Icon() {
  return <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.5054 17.0962C26.2967 17.0962 26.9233 17.3057 27.3853 17.7246C27.8472 18.1436 28.0781 18.7147 28.0781 19.438C28.0781 20.2759 27.8149 20.9312 27.2886 21.4038C26.7658 21.8729 26.021 22.1074 25.0542 22.1074C24.1769 22.1074 23.4876 21.966 22.9863 21.6831V20.5391C23.2764 20.7038 23.6094 20.8309 23.9854 20.9204C24.3613 21.0099 24.7104 21.0547 25.0327 21.0547C25.6021 21.0547 26.0353 20.9276 26.3325 20.6733C26.6297 20.4191 26.7783 20.0467 26.7783 19.5562C26.7783 18.618 26.1803 18.1489 24.9844 18.1489C24.8161 18.1489 24.6084 18.1668 24.3613 18.2026C24.1143 18.2349 23.8976 18.2725 23.7114 18.3154L23.1475 17.9824L23.4482 14.1475H27.5303V15.27H24.5601L24.3828 17.2144C24.5081 17.1929 24.6603 17.1678 24.8394 17.1392C25.022 17.1105 25.244 17.0962 25.5054 17.0962Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14 24C17.3137 24 20 21.3137 20 18C20 14.6863 17.3137 12 14 12C10.6863 12 8 14.6863 8 18C8 21.3137 10.6863 24 14 24ZM10.65 17.3501C10.291 17.3501 10 17.6411 10 18.0001C10 18.3591 10.291 18.6501 10.65 18.6501H17.35C17.709 18.6501 18 18.3591 18 18.0001C18 17.6411 17.709 17.3501 17.35 17.3501H10.65Z" fill="white"/>
  </svg>;
}

export function Next10Icon() {
  return <svg width="21px" height="24px" viewBox="0 0 21 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>-10</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Modules/Card/Card-Tools/Overrides/Stepper-(+)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
      transform="translate(-74.000000, -10.000000)">
      <g id="Card-Control---Fast-Forward">
        <g id="-10" transform="translate(74.000000, 10.000000)">
          <g id="Icon" transform="translate(0.500000, 0.000000)">
            <path
              d="M10,22.5 C15.2467051,22.5 19.5,18.2467051 19.5,13 C19.5,7.75329488 15.2467051,3.5 10,3.5 C4.75329488,3.5 0.5,7.75329488 0.5,13"
              id="Oval" stroke="#919191" strokeWidth="1.3" strokeLinecap="round"
              transform="translate(10.000000, 13.000000) rotate(180.000000) translate(-10.000000, -13.000000) "></path>
            <path
              d="M15.1347154,3.7068416 L19.435238,6.54679071 C19.6195832,6.6685273 19.8677115,6.617773 19.9894481,6.4334278 C20.0326372,6.36802678 20.0556616,6.29137887 20.0556616,6.2130042 L20.0556616,0.699999237 C20.0556616,0.479085337 19.8765755,0.299999237 19.6556616,0.299999237 C19.5772869,0.299999237 19.500639,0.323023656 19.435238,0.366212727 L15.1347154,3.20616183 C14.9964565,3.29746428 14.9583908,3.48356052 15.0496932,3.62181942 C15.0720059,3.6556074 15.1009274,3.68452893 15.1347154,3.7068416 Z"
              id="Path" fill="#919191"
              transform="translate(17.527831, 3.456524) scale(-1, 1) translate(-17.527831, -3.456524) "></path>
            <path
              d="M10.1347154,3.7068416 L14.435238,6.54679071 C14.6195832,6.6685273 14.8677115,6.617773 14.9894481,6.4334278 C15.0326372,6.36802678 15.0556616,6.29137887 15.0556616,6.2130042 L15.0556616,0.699999237 C15.0556616,0.479085337 14.8765755,0.299999237 14.6556616,0.299999237 C14.5772869,0.299999237 14.500639,0.323023656 14.435238,0.366212727 L10.1347154,3.20616183 C9.99645649,3.29746428 9.95839076,3.48356052 10.0496932,3.62181942 C10.0720059,3.6556074 10.1009274,3.68452893 10.1347154,3.7068416 Z"
              id="Path" fill="#919191"
              transform="translate(12.527831, 3.456524) scale(-1, 1) translate(-12.527831, -3.456524) "></path>
          </g>
          <text id="10" fontFamily="OpenSans, Open Sans" fontSize="11" fontWeight="normal" letterSpacing="-0.22"
            fill="#919191">
            <tspan x="4.43044922" y="17">10</tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>;
}

export function Prev5Icon() {
  return <svg className="prev-5-icon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5054 12.0962C21.2967 12.0962 21.9233 12.3057 22.3853 12.7246C22.8472 13.1436 23.0781 13.7147 23.0781 14.438C23.0781 15.2759 22.8149 15.9312 22.2886 16.4038C21.7658 16.8729 21.021 17.1074 20.0542 17.1074C19.1769 17.1074 18.4876 16.966 17.9863 16.6831V15.5391C18.2764 15.7038 18.6094 15.8309 18.9854 15.9204C19.3613 16.0099 19.7104 16.0547 20.0327 16.0547C20.6021 16.0547 21.0353 15.9276 21.3325 15.6733C21.6297 15.4191 21.7783 15.0467 21.7783 14.5562C21.7783 13.618 21.1803 13.1489 19.9844 13.1489C19.8161 13.1489 19.6084 13.1668 19.3613 13.2026C19.1143 13.2349 18.8976 13.2725 18.7114 13.3154L18.1475 12.9824L18.4482 9.14746H22.5303V10.27H19.5601L19.3828 12.2144C19.5081 12.1929 19.6603 12.1678 19.8394 12.1392C20.022 12.1105 20.244 12.0962 20.5054 12.0962Z" fill="#A2A3A3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15 13C15 16.3137 12.3137 19 9 19C5.68629 19 3 16.3137 3 13C3 9.68629 5.68629 7 9 7C12.3137 7 15 9.68629 15 13ZM5.65 12.3501C5.29101 12.3501 5 12.6411 5 13.0001C5 13.3591 5.29101 13.6501 5.65 13.6501H12.35C12.709 13.6501 13 13.3591 13 13.0001C13 12.6411 12.709 12.3501 12.35 12.3501H5.65Z" fill="#A2A3A3"/>
  </svg>;
}

export function Prev10Icon() {
  return <svg width="22px" height="24px" viewBox="0 0 22 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>-10</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Modules/Card/Card-Tools/Overrides/Stepper-(-)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
      transform="translate(-16.000000, -10.000000)">
      <g id="Card-Control---Fast-Forward">
        <g id="-10" transform="translate(16.000000, 10.000000)">
          <g id="Icon" transform="translate(10.500000, 11.500000) scale(-1, 1) translate(-10.500000, -11.500000) ">
            <path
              d="M10,22.5 C15.2467051,22.5 19.5,18.2467051 19.5,13 C19.5,7.75329488 15.2467051,3.5 10,3.5 C4.75329488,3.5 0.5,7.75329488 0.5,13"
              id="Oval" stroke="#919191" strokeWidth="1.3" strokeLinecap="round"
              transform="translate(10.000000, 13.000000) rotate(180.000000) translate(-10.000000, -13.000000) "></path>
            <path
              d="M15.1347154,3.7068416 L19.435238,6.54679071 C19.6195832,6.6685273 19.8677115,6.617773 19.9894481,6.4334278 C20.0326372,6.36802678 20.0556616,6.29137887 20.0556616,6.2130042 L20.0556616,0.699999237 C20.0556616,0.479085337 19.8765755,0.299999237 19.6556616,0.299999237 C19.5772869,0.299999237 19.500639,0.323023656 19.435238,0.366212727 L15.1347154,3.20616183 C14.9964565,3.29746428 14.9583908,3.48356052 15.0496932,3.62181942 C15.0720059,3.6556074 15.1009274,3.68452893 15.1347154,3.7068416 Z"
              id="Path" fill="#919191"
              transform="translate(17.527831, 3.456524) scale(-1, 1) translate(-17.527831, -3.456524) "></path>
            <path
              d="M10.1347154,3.7068416 L14.435238,6.54679071 C14.6195832,6.6685273 14.8677115,6.617773 14.9894481,6.4334278 C15.0326372,6.36802678 15.0556616,6.29137887 15.0556616,6.2130042 L15.0556616,0.699999237 C15.0556616,0.479085337 14.8765755,0.299999237 14.6556616,0.299999237 C14.5772869,0.299999237 14.500639,0.323023656 14.435238,0.366212727 L10.1347154,3.20616183 C9.99645649,3.29746428 9.95839076,3.48356052 10.0496932,3.62181942 C10.0720059,3.6556074 10.1009274,3.68452893 10.1347154,3.7068416 Z"
              id="Path" fill="#919191"
              transform="translate(12.527831, 3.456524) scale(-1, 1) translate(-12.527831, -3.456524) "></path>
          </g>
          <text id="10" fontFamily="OpenSans, Open Sans" fontSize="11" fontWeight="normal" letterSpacing="-0.220000014"
            fill="#919191">
            <tspan x="4.43044923" y="17">10</tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>;
}

export const NotesIcon = ({active, onClick}) => {
  return <ControlIcon active={active} onClick={onClick}>
    {active ? <NotesIconActive/> : <NotesIconNormal/>}
  </ControlIcon>;
};

export const FoldersIcon = ({active, onClick}) => {
  return <ControlIcon active={active} onClick={onClick}>
    {active ? <FoldersIconActive/> : <FoldersIconNormal/>}
  </ControlIcon>;
};

export const SubjectsChangerIcon = ({active, onClick}) => {
  return <ControlIcon active={active} onClick={onClick}>
    {active ? <SubjectsChangerActive/> : <SubjectsChangerNormal/>}
  </ControlIcon>;
};

export const ExercisesIcon = ({active, onClick}) => {
  return <ControlIcon active={active} onClick={onClick}>
    {active ? <ExercisesActive/> : <ExercisesNormal/>}
  </ControlIcon>;
};

export const SupportsIcon = ({active, onClick}) => {
  return <ToggleControlIcon active={active} onClick={onClick}>
    {active ? <SupportActive/> : <SupportNormal/>}
  </ToggleControlIcon>;
};

export const SwopsIcon = ({active, onClick}) => {
  return <ToggleControlIcon active={active} onClick={onClick} gradient>
    <Swop/>
  </ToggleControlIcon>;
};

export const ShuffleIcon = ({active, onClick}) => {
  return <ToggleControlIcon active={active} onClick={onClick}>
    {active ? <ShuffleActive/> : <ShuffleNormal/>}
  </ToggleControlIcon>;
};

export const ToolboxIcon = ({isOpen}) => {
  return isOpen ? <ToolboxOpen/> : <ToolboxClosed/>;
};

export const Prev5FlashcardsIcon = ({onClick})=>{
  return <NavigationIcon onClick={onClick}><Prev5/></NavigationIcon>;
};

export const Next5FlashcardsIcon = ({onClick})=>{
  return <NavigationIcon onClick={onClick}><Next5/></NavigationIcon>;
};

export const KeyboardIcon = ({onClick}) => {
  return <StyledMainCotent>
      <MoreControlIcon onClick={onClick} >
        <Keyboard/>
      </MoreControlIcon>
      <StyledText>KEYBOARD SHORTCUTS</StyledText>
  </StyledMainCotent>;
};
export const CloseIcon = ({onClick}) => {
  return <MoreControlIcon onClick={onClick} isClose={true}>
    <Close/> 
  </MoreControlIcon>;
};
export const SupportIcon = ({onClick}) => {
  return <StyledMainCotent>
    <MoreControlIcon onClick={onClick}>
      <SupportNormal/>
    </MoreControlIcon>
    <StyledText>Support</StyledText>
  </StyledMainCotent>;
};

export const SwopIcon = ({active, onClick}) => {
  return <StyledMainCotent>
    <ToggleMoreControlIcon active={active} onClick={onClick} gradient>
      <Swop/>
    </ToggleMoreControlIcon>
    <StyledText>Swop</StyledText>
  </StyledMainCotent>;
};
export const PortraitIcon = ({onClick}) => {
  return <StyledMainCotent>
    <ToggleMoreControlIcon onClick={onClick} >
      <Landscape/>
    </ToggleMoreControlIcon>
    <StyledText>MOBILE LANDSCAPE VIEW</StyledText>
  </StyledMainCotent>;
};
export const LandscapeIcon = ({onClick}) => {
  return <StyledMainCotent>
    <ToggleMoreControlIcon onClick={onClick}>
      <Portrait/>
    </ToggleMoreControlIcon>
    <StyledText>MOBILE PORTRAIT VIEW</StyledText>
  </StyledMainCotent>;
};
export const ReportContentErrorIcon = ({onClick}) => {
  return <StyledMainCotent>
      <MoreControlIcon onClick={onClick} >
        <ReportContentError />
      </MoreControlIcon>
      <StyledText>REPORT CONTENT ERROR</StyledText>
  </StyledMainCotent>;
};