import * as Types from './searchTypes';
import makeAction from 'actions/makeAction';

export const setActiveSearchTab = makeAction(Types.SET_ACTIVE_SEARCH_TAB, 'data');
export const setActiveSearchTerm = makeAction(Types.SET_ACTIVE_SEARCH_TERM, 'data');
export const setSearchResults = makeAction(Types.SET_SEARCH_RESULTS, 'data');
export const clearSearchResults = makeAction(Types.CLEAR_SEARCH_RESULTS);
export const clearSearch = makeAction(Types.CLEAR_SEARCH);
export const setGoBackToSearch = makeAction(Types.SET_GO_BACK_TO_SEARCH, 'data');
export const setSearchIndex = makeAction(Types.SET_SEARCH_INDEX, 'index');
export const setPreviousSearchResults = makeAction(Types.SET_PREVIOUS_SEARCH_RESULTS, 'data');
export const setMicroFilterFlashcardIds = makeAction(Types.SET_MICRO_FILTER_FLASHCARD_IDS, 'data');
export const setsearchActive = makeAction(Types.SET_SEARCH_ACTIVE, 'data')
