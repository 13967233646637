import classNames from 'classnames';
import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { isLandscape, isPortrait, windowWidth } from 'selectors';
import './progress.scss'

type ProgressGraphicalProps = {
    examList: any;
}
const data = [{ id: 1, isRead: true, isActive: false }, { id: 1, isRead: true, isActive: false }]
const ProgressGraphical: React.FC<ProgressGraphicalProps> = ({ examList }) => {
    const widthRef = useRef<any>()
    const [width, setWidth] = useState(0)
    const [progress, setProgress] = useState<any>([])
    const hasLandscape = useSelector(isLandscape)
    const hasPortrait = useSelector(isPortrait)
    const wWith = useSelector(windowWidth)

    useEffect(() => {
        const size = examList.get('size')
        const index = examList.get('index')
        let data = []
        for (let i = 1; i <= size; i++) {
            if (i < index) {
                const obj = { id: i, isRead: true, isActive: false }
                data.push(obj)

            } else if (i === index) {
                const object = { id: i, isRead: true, isActive: true }
                data.push(object)
            }
            else {
                const object = { id: i, isRead: false, isActive: false }
                data.push(object)
            }
        }
        setProgress(data)
        resizeWindow()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examList, data, width])

    useEffect(() => {
        if (!widthRef?.current) return;
        const baseTabContent = widthRef && widthRef.current;
        let resizeObserver = new ResizeObserver(() => {
            resizeWindow();
        });
        resizeObserver.observe(baseTabContent);
        return () => {
            resizeObserver.unobserve(baseTabContent);
        };
    }, [hasLandscape, hasPortrait, width, wWith, examList]);

    const resizeWindow = () => {
        const numberCount = data.length - 1
        const multiple = numberCount * 5
        const width = widthRef && (widthRef.current && (widthRef.current.offsetWidth - multiple) / data.length)
        setWidth(width)
    }

    const getWidth = () => {
        if (progress.length === 1) {
            return `${widthRef.current.offsetWidth}px`
        }
        return `${width}px`;
    }
    return <div className='flex h-3.5 !mb-1.5 px-5' ref={widthRef}>
        {progress.length > 0 &&
            <>
                {progress.map((value: any) => <div key={value.id} className={classNames('progress-wrapper', { 'progress-active': value.isActive }, { 'bg-blue-normal': value.isRead }, { 'bg-#C8CCCC': !value.isRead })} style={{ width: getWidth() }}></div>)}
            </>
        }
    </div>
}

export default ProgressGraphical;