const PREFIX = 'NAVIGATION_';

export const OPEN_MENU = PREFIX + 'OPEN_MENU';

export const OPEN_STORE = PREFIX + 'OPEN_STORE';
export const SHOW_STORE_MODAL = PREFIX + 'SHOW_STORE_MODAL';
export const HIDE_STORE_MODAL = PREFIX + 'HIDE_STORE_MODAL';

export const CLOSE_MENU = PREFIX + 'CLOSE_MENU';
export const SET_DOCUMENT_TITLE = PREFIX + 'SET_DOCUMENT_TITLE';
export const SET_ACTIVE_DESK_TAB = PREFIX + 'SET_ACTIVE_DESK_TAB';
export const TOGGLE_DESK_EDIT_MODE = PREFIX + 'TOGGLE_DESK_EDIT_MODE';
export const TOGGLE_SUBJECTS_MANAGER = PREFIX + 'TOGGLE_SUBJECTS_MANAGER';
export const SET_ACTIVE_TROPHIES_TAB = PREFIX + 'SET_ACTIVE_TROPHIES_TAB';
export const SET_ACTIVE_FLASHCARD_TAB = PREFIX + 'SET_ACTIVE_FLASHCARD_TAB';
export const TOGGLE_NAVIGATION_SECTION = PREFIX + 'TOGGLE_NAVIGATION_SECTION';
export const TOGGLE_FLASHCARD_NAVIGATION = PREFIX + 'TOGGLE_FLASHCARD_NAVIGATION';
export const SET_ACTIVE_ITEM_STORE_DETAILS_TAB = PREFIX + 'SET_ACTIVE_ITEM_STORE_DETAILS_TAB';
export const HIDE_LAST_FLASHCARD_STUDIED_MESSAGE = PREFIX + 'HIDE_LAST_FLASHCARD_STUDIED_MESSAGE';

export const TOGGLE_BODY_SCROLL = PREFIX + 'TOGGLE_BODY_SCROLL';

export const TOGGLE_FLASHCARD_NAVIGATION_VISIBILITY = PREFIX + 'TOGGLE_FLASHCARD_NAVIGATION_VISIBILITY';
export const TOGGLE_SUBJECTS_MANAGER_OVERLAY = PREFIX + 'TOGGLE_SUBJECTS_MANAGER_OVERLAY';
export const TOGGLE_OLD_SUBJECTS_MANAGER_OVERLAY = PREFIX + 'TOGGLE_OLD_SUBJECTS_MANAGER_OVERLAY';
export const TOGGLE_KEYBOARD_NAVIGATION_OVERLAY = PREFIX + 'TOGGLE_KEYBOARD_NAVIGATION_OVERLAY';
export const TOGGLE_SUPPORT_MANAGER_OVERLAY = PREFIX + 'TOGGLE_SUPPORT_MANAGER_OVERLAY';
export const TOGGLE_DEVICE_MANAGER_OVERLAY = PREFIX + 'TOGGLE_DEVICE_MANAGER_OVERLAY';
export const TOGGLE_MORE_CONTROL_OVERLAY = PREFIX + 'TOGGLE_MORE_CONTROL_OVERLAY';
export const TOGGLE_LINkS_NAVIGATION_SECTION = PREFIX + 'TOGGLE_LINkS_NAVIGATION_SECTION';
export const OPEN_SHORTCUT_MENU = PREFIX + 'OPEN_SHORTCUT_MENU';
export const HIDE_SHORTCUT_MENU = PREFIX + 'HIDE_SHORTCUT_MENU';

export const SAVE_LAST_MENU_PATH = PREFIX + 'SAVE_LAST_MENU_PATH';
export const SET_ACTIVE_MEDIA_TAB = PREFIX + 'SET_ACTIVE_MEDIA_TAB';
export const SET_MEDIA_FLAHSCARD_IDS = PREFIX + 'SET_MEDIA_FLAHSCARD_IDS';
export const SET_EXERCISE_ON_CANVAS = PREFIX + 'SET_EXERCISE_ON_CANVAS';
export const SAVE_SCROLL_POSITION = PREFIX + 'SAVE_SCROLL_POSITION';
export const TOGGLE_TEACHING_LEVEL_OVERLAY = PREFIX + 'TOGGLE_TEACHING_LEVEL_OVERLAY';
export const MACRO_FILTER_SIZE = PREFIX + 'MACRO_FILTER_SIZE';
export const TOGGLE_FILTER_OVERLAY = PREFIX + 'TOGGLE_FILTER_OVERLAY';
export const SET_FILTER_ITEM_SIZE = PREFIX + 'SET_FILTER_ITEM_SIZE';
export const STRUCTURE_LOADING = PREFIX + 'STRUCTURE_LOADING';
export const  GET_MEDIA_TYPE_FILTER_OVERLAY = PREFIX + 'GET_MEDIA_TYPE_FILTER_OVERLAY';
export const API_RESPONSE_LOADING = PREFIX + 'API_RESPONSE_LOADING';
export const SET_EXERCISE_FILTER_ITEM_SIZE = PREFIX + 'SET_EXERCISE_FILTER_ITEM_SIZE';
export const SET_FIRST_NODE = PREFIX + 'SET_FIRST_NODE';
export const SET_FIRST_TOPIC_ID = PREFIX + 'SET_FIRST_TOPIC_ID';
export const SET_FIRST_SUBTOPIC_ID = PREFIX + 'SET_FIRST_SUBTOPIC_ID';
export const TOGGLE_PAPER_OVERLAY = PREFIX + 'TOGGLE_PAPER_OVERLAY';
export const TOGGLE_DIFFICULTY_OVERLAY = PREFIX + 'TOGGLE_DIFFICULTY_OVERLAY';
export const SET_ACTIVE_EXAMS_TAB = PREFIX + 'SET_ACTIVE_EXAMS_TAB';
export const SET_ACTIVE_EXAMS_IDS = PREFIX + 'SET_ACTIVE_EXAMS_IDS';
export const SET_ACTIVE_EXAMS_PAPER_IDS = PREFIX + 'SET_ACTIVE_EXAMS_PAPER_IDS';
export const TOGGLE_VIDEO_OVERLAY = PREFIX + 'TOGGLE_VIDEO_OVERLAY';
export const TOGGLE_SOURCE_OVERLAY = PREFIX + 'TOGGLE_SOURCE_OVERLAY'
export const TOGGLE_QUESTION_INFO_OVERLAY = PREFIX + 'TOGGLE_QUESTION_INFO_OVERLAY';
export const TOGGLE_HIDDEN_INFO_OVERLAY = PREFIX + 'TOGGLE_HIDDEN_INFO_OVERLAY';
export const TOGGLE_TEACHING_LEVEL_EXAMS_OVERLAY = PREFIX + 'TOGGLE_TEACHING_LEVEL_EXAMS_OVERLAY';
export const SET_ACTIVE_PAPER_TAB = PREFIX + 'SET_ACTIVE_PAPER_TAB';
export const TOGGLE_EXAMS_MODE = PREFIX + 'TOGGLE_EXAMS_MODE';
export const TOGGLE_MCQ_OVERLAY = PREFIX +'TOGGLE_MCQ_OVERLAY';
export const TOGGLE_MAIN_MICRO_OVERLAY = PREFIX + 'TOGGLE_MAIN_MICRO_OVERLAY';
export const MAIN_MICRO_FILTER_BACK = PREFIX + 'MAIN_MICRO_FILTER_BACK';
export const UPDATE_EXAMS_LIST = PREFIX + 'UPDATE_EXAMS_LIST';
export const PROGRESS_BAR_FILTER_VISIBLE = PREFIX + 'PROGRESS_BAR_FILTER_VISIBLE';
export const EXAM_PAPER_HEADER = PREFIX + 'EXAM_PAPER_HEADER';
export const SET_JWT_TOKEN = PREFIX + 'SET_JWT_TOKEN';
export const SET_ACTIVE_TEACHING_EXAM  = PREFIX + 'SET_ACTIVE_TEACHING_EXAM';
export const SET_EXAM_HEADER_LIST = PREFIX + 'SET_EXAM_HEADER_LIST';
export const SET_UPDATE_EXAMS_PREVIEW_LIST = PREFIX + 'SET_UPDATE_EXAMS_PREVIEW_LIST';
export const FOLDER_ADD_BUTTON_ACTIVE = PREFIX + 'FOLDER_ADD_BUTTON_ACTIVE';
export const ENABLE_COOKIES_OVERLAY_VISIBLE = PREFIX + 'ENABLE_COOKIES_OVERLAY_VISIBLE';
export const TOPICS_NEXT_PREV_BUTTON_ENABLE = PREFIX + 'TOPICS_NEXT_PREV_BUTTON_ENABLE';
export const SET_ACTIVE_EXAM_MODULE = PREFIX + 'SET_ACTIVE_EXAM_MODULE';
export const TOGGLE_ACCOUNTS_PORTAL_OVERLAY = PREFIX + 'TOGGLE_ACCOUNTS_PORTAL_OVERLAY';

export const UPDATE_PROGRESS_BAR = PREFIX + 'UPDATE_PROGRESS_BAR';
export const TOGGLE_TEACHING_LEVEL_OVERLAY_FROM_FEATURE = PREFIX + 'TOGGLE_TEACHING_LEVEL_OVERLAY_FROM_FEATURE';
