import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isMBUserSelector } from 'selectors/user'
import { startNotificationsPolling } from 'actions/exercises/exercisesActions';
import { externalExercisesResponse } from 'selectors';
import usePrevious from 'hooks/usePrevious';
import { getAllExternalExercisesRoutine, getViewedExercisesRoutine } from 'actions/api';

const ExternalExercisesNotificationsContainer = () => {
  const dispatch = useDispatch();
  const isMBUser = useSelector(isMBUserSelector);
  const externalExercises = useSelector(externalExercisesResponse)?.toJS()
  const prevExternalExercises = usePrevious(externalExercises)
  useEffect(() => {
    if(isMBUser) {
      dispatch(startNotificationsPolling())
    }
  },[dispatch, isMBUser])

  useEffect(() => {
    if(JSON.stringify(externalExercises) !== JSON.stringify(prevExternalExercises)) {
      dispatch(getViewedExercisesRoutine())
      dispatch(getAllExternalExercisesRoutine())
    }
  },[dispatch, externalExercises, prevExternalExercises])
  
  return null
}

export default ExternalExercisesNotificationsContainer