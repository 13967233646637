import classNames from 'classnames';
import { getExamSubTopicId, getFormExams, getLastMediaTypeVisited } from 'helpers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './studyLocationInfoContainer.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getprogressBarData, getexamslistSize, isNavigationSectionVisible } from 'selectors/navigation/navigationSelector';
import { getUserId } from 'selectors/user';
import { activeExercise, getFlashcardById } from 'selectors/studying';
import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2';
import { isLandscape, isMobile } from 'selectors/browser/browserSelector';
import { getMediaTypeFilterOverlay, setActiveFlashcardTab, setActivePaperTab, toggleFilterOverlay, toggleHiddenInfoOverlay, toggleMainMicroOverlay, toggleNavigationSection } from 'actions/navigation';
import { FilterIcon, FilterClearIcon } from 'icons/HeaderIcons';
import StudyingLocation from 'v2/components/studying/study-location-info/StudyingLocation';
import { clearSearch } from 'actions/search';
import { useHistory } from 'react-router-dom';
import ExternalAvatar from 'v2/components/avatar/ExternalAvatar';
import { listOfFilterCheckedItems, examsofFilterItems } from 'selectors/desk/deskSelector';
import usePrevious from 'hooks/usePrevious';
import { updateExamsPaperFilterItems, updateFilterListOfItemsSelected } from 'actions/desk';
import { getActiveSubject } from 'selectors/subject/subjectSelector';
import { currentFlashcardId } from 'selectors/studying';
import { apiRequestData } from 'selectors/api/apiSelector';
import { useSubtopicNumber } from 'hooks/useTopicNumber';

type StudyLocationInfoContainerProps = {
  className: string;
  flashcardId: string | number;
}

const StudyLocationInfoContainer: React.FC<StudyLocationInfoContainerProps> = ({ className, flashcardId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const progressBarData = useSelector(getprogressBarData)?.toJS();
  const examslistSize = useSelector(getexamslistSize)?.toJS();
  const flashcardContent = useSelector(state => getFlashcardById(state, flashcardId))?.toJS();
  const getListOfFilterCheckedItems = useSelector(listOfFilterCheckedItems);
  const getExamsofFilterItems = useSelector(examsofFilterItems)
  const getActiveExercise = useSelector(activeExercise)
  const examPreview = useSelector(state => apiRequestData(state, 'examPreview'))?.toJS();
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);
  const hasNavigationSectionVisible = useSelector(isNavigationSectionVisible);
  const subject = useSelector(getActiveSubject);
  const currentId = useSelector(currentFlashcardId);
  const prevExamsofFilterItems = usePrevious(getExamsofFilterItems);
  const prevFcId = usePrevious(currentId);
  const prevGetFormExams = usePrevious(getFormExams());
  const prevListOfFilterCheckedItems = usePrevious(getListOfFilterCheckedItems);
  const subtopicNumber = useSubtopicNumber(flashcardContent)
  const hasMobileLandscape = (hasMobile && hasLandscape);
  const [filter, setFilter] = useState<boolean>(false);
  const mediaType = getLastMediaTypeVisited(userId);
  const currentIndex = mediaType === "exams" ? examslistSize?.index : progressBarData?.index;
  const progressBarSize = mediaType === "exams" ? examslistSize?.size : progressBarData?.size;
  const parentsData = useMemo(() => mediaType === 'exams' && getFormExams() === 'exams' ? flashcardContent?.experienceData : flashcardContent?.parentsData, [flashcardContent, mediaType, getFormExams()]);
  const subTopicData = useMemo(() => getFormExams() !== 'exams' && parentsData?.filter((item: any) => item?.subTopicId?.toString() === getExamSubTopicId()), [parentsData, getFormExams(), getExamSubTopicId()]);
  const paperLabel = useMemo(() => mediaType === 'exams' && parentsData?.tag_labels?.filter((item: any) => item?.type === 'paper_type')?.[0]?.label, [mediaType, parentsData]);
  const mathematicsIds = [14982, 15075];
  const examTopicNumberData = useMemo(() => {
    return subTopicData?.[0]?.parentsData?.find((item:any) => item.type === 'SUB_TOPIC')
  },[subTopicData])
  const examSubtopicNumber = useSubtopicNumber(examTopicNumberData)


  const onPaperClick = () => {
    hasNavigationSectionVisible && dispatch(toggleNavigationSection(true, true));
    const { paper_uuid, exam_uuid } = parentsData
    dispatch(setActivePaperTab(paperLabel));
    dispatch(setActiveFlashcardTab(1));
    dispatch(clearSearch());
    history.push(`/flashcard/desk/exams/${exam_uuid}/${paper_uuid}`);
  }

  const onSubTopicClick = (subtopicId: string | number) => {
    hasNavigationSectionVisible && dispatch(toggleNavigationSection(true, true));
    dispatch(setActiveFlashcardTab(0));
    history.push(`/flashcard/subtopic/${subtopicId}`);
  }

  const topicSubTopicTitle = useCallback(() => {
    if (!parentsData) return;
    if (mediaType === 'exams') {
      if (getFormExams() === 'exams') {
        return paperLabel;
      }
      else {
        return subTopicData?.[0]?.parentsData?.filter((item: any) => item?.type === 'SUB_TOPIC')?.[0]?.title;
      }
    }
    else {
      const subTopicTitle = parentsData?.filter((item: any) => item?.type === 'SUB_TOPIC')?.[0]?.title;
      if (!subTopicTitle) {
        return parentsData?.filter((item: any) => item?.type === 'TOPIC')?.[0]?.title;
      }
      return subTopicTitle;
    }
  }, [parentsData, mediaType, getFormExams(), subTopicData])

  const topicSubtopicNumbering = useCallback(() => {
    if (!parentsData || (mediaType === 'exams' && getFormExams() === 'exams')) return null;
    let topicNumber, subTopicNumber;
    if (mediaType === 'exams') {
      topicNumber = subTopicData?.[0]?.parentsData?.filter((item: any) => item?.type === 'TOPIC')?.[0]?.numbering;
      subTopicNumber = subTopicData?.[0]?.parentsData?.filter((item: any) => item?.type === 'SUB_TOPIC')?.[0]?.numbering;
    }
    else {
      parentsData.forEach((item: any) => {
        if (item?.type === 'TOPIC') {
          topicNumber = item?.numbering;
        }
        if (item?.type === 'SUB_TOPIC') {
          subTopicNumber = item?.numbering;
        }
      })
    }
    return `${topicNumber}.${subTopicNumber}`;
  }, [parentsData, mediaType, getFormExams(), subTopicData])

  const flashcardTeachingLevel = useCallback(() => {
    if (!parentsData) return;
    let techingLvlTitle, teachingTag, subTopicId;
    if (mediaType === 'exams' ? parentsData?.length > 0 : parentsData?.length > 1) {
      if (mediaType === 'exams') {
        techingLvlTitle = subTopicData?.[0]?.parentsData?.filter((item: any) => item?.type === 'TEACHING_LEVEL')?.[0]?.title;
        subTopicId = subTopicData?.[0]?.parentsData?.filter((item: any) => item?.type === 'SUB_TOPIC')?.[0]?.id;
        teachingTag = subTopicData?.[0]?.parentsData?.filter((item: any) => item?.type === 'TEACHING_LEVEL')?.[0]?.teachingTag;
      }
      else {
        techingLvlTitle = parentsData?.filter((item: any) => item?.type === 'TEACHING_LEVEL')?.[0]?.title;
        subTopicId = parentsData?.filter((item: any) => item?.type === 'SUB_TOPIC')?.[0]?.id;
        teachingTag = flashcardContent?.teachingTag;
      }
    }
    return { 'teachingLvl': techingLvlTitle, 'teachingTag': teachingTag, 'subTopicId': subTopicId };
  }, [parentsData, mediaType, parentsData, subTopicData, flashcardContent, getFormExams()])

  const openFilter = () => {
    if (mediaType === 'exams') {
      dispatch(toggleMainMicroOverlay(true, true));
    } else {
      dispatch(toggleFilterOverlay(true, true));
    }
    dispatch(getMediaTypeFilterOverlay(mediaType));
  }

  useEffect(() => {
    checkFilter();
    checkExamsFilter()
  }, [])

  useEffect(() => {
    if (getListOfFilterCheckedItems != prevListOfFilterCheckedItems || getExamsofFilterItems !== prevExamsofFilterItems || currentId !== prevFcId || prevGetFormExams !== getFormExams()) {
      checkFilter();
    }
  }, [getListOfFilterCheckedItems, prevListOfFilterCheckedItems, getExamsofFilterItems, prevExamsofFilterItems, currentId, prevFcId, getFormExams(), prevGetFormExams])

  const isMaths = useCallback(() => ((getFormExams() !== 'exams' && !mathematicsIds.includes(subject.id)) || mathematicsIds.includes(subject.id)), [getFormExams(), subject])

  const checkExamsCCFilter = () => {
    let data = getExamsofFilterItems.toJS();
    let paperLable;
    if (getFormExams() === 'exams') {
      const questionData = examPreview?.data?.data?.filter((item: any) => item?.uuid === currentId)?.[0];
      paperLable = questionData?.experienceData?.tag_labels?.filter((item: any) => item?.type === 'paper_type')?.[0]?.label
    }
    else {
      paperLable = paperLabel;
    }
    if(typeof paperLable == 'undefined'){
      paperLable = flashcardContent?.experienceData?.tag_labels?.filter((item: any) => item?.type === 'paper_type')?.[0]?.label;
    }
    if (data['teachingLevel'].length > 0 || (paperLable === 'Paper 1' && data['mcq'].length > 0)) {
      return true;
    }
    if(paperLable === 'Paper 2' && getListOfFilterCheckedItems.toJS()[mediaType].length > 0){
      return true
    }
    return false;
  }

  const checkExamsFilter = () => {
    let data = getExamsofFilterItems?.toJS();
    const paperLable = flashcardContent?.experienceData?.tag_labels?.filter((item: any) => item?.type === 'paper_type')?.[0]?.label;

    if ((paperLable === 'Paper 1' && data['mcq'].length > 0) || data['source'].length > 0 || data['paper'].length > 0 || data['teachingLevel'].length > 0 || data['difficulty'].length > 0) {
      return true;
    }
    return false;
  }

  const checkFilter = () => {
    let data = getListOfFilterCheckedItems.toJS()[mediaType];
    if ((mediaType === "flashcard" && 0 < data.length && data.length < 4) || (mediaType === "revision" && 0 < data.length && data.length < 2) || (mediaType === "video" && 0 < data.length && data.length < 3)) {
      return setFilter(true);
    }
    else if (mediaType === "exams") {
      if ((0 < data.length && data.length < 4 && isMaths()) || (getFormExams() === 'exams' ? checkExamsCCFilter() : checkExamsFilter())) {
        return setFilter(true);
      }
      return setFilter(false);
    }
    setFilter(false);
  }

  const clearFilter = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(updateFilterListOfItemsSelected([], mediaType));
    if (mediaType === 'exams') {
      ['source', 'paper', 'teachingLevel', 'difficulty', 'mcq'].map((val: string) => dispatch(updateExamsPaperFilterItems([], val)));
    }
  }

  const renderLocation = () => {
    if (getActiveExercise) {
      return <div className='ex-location-wrapper flex items-center h-[37px]'>
        <ExternalAvatar
          size={34}
          widthSize={64}
          userName={getActiveExercise?.addedBy}
          userId={getActiveExercise?.mbTeacherId}
        />
        <StudyingLocation
          isExternalExercise={true}
          title={`Exercise: ${getActiveExercise?.title}`}
        />
      </div>
    }
    else {
      return <StudyingLocation
        isExternalExercise={false}
        title={topicSubTopicTitle()}
        // numbering={topicSubtopicNumbering()}
        numbering={mediaType === 'exams' ? examSubtopicNumber : subtopicNumber}
        isMobilePortrait={!(hasMobile && hasLandscape)}
        teachingLevel={(mediaType === 'exams' && getFormExams() === 'exams') ? null : flashcardTeachingLevel()?.teachingLvl}
        teachingTag={(mediaType === 'exams' && getFormExams() === 'exams') ? null : flashcardTeachingLevel()?.teachingTag}
        onClick={() => (getFormExams() === 'exams' && mediaType === 'exams') ? onPaperClick() : onSubTopicClick(flashcardTeachingLevel()?.subTopicId)}
      />
    }
  }

  return <div className={classNames(`study-location-info-wrapper ${className}`, { 'video-location-wrapper': mediaType === 'video' })}>
    <div className={classNames('flex items-wrapper flex-1 items-center !justify-between')}>
      {(!topicSubTopicTitle() || progressBarSize === 0) ? <div className="studying-Loader m-auto"><Loader size={SizeEnum.Small} background={BackgroundEnum.Blue} /></div> :
        <>
          <div className={classNames('title-section', { 'is-exercise': getActiveExercise, 'filter': filter })}>
            {renderLocation()}
          </div>
          <div className={classNames('location-left-section flex items-center right-0')}>
            {!hasMobileLandscape && (getFormExams() === 'exams' && mediaType === "exams") && <div className='hidden-info text-blue-normal text-13px leading-16px mr-4 select-none cursor-pointer' onClick={() => dispatch(toggleHiddenInfoOverlay(true, true))}>Show hidden info</div>}
            <div className={classNames('opacity-90 flex font-bold text-15px leading-19px', { 'mr-2.5': !hasMobileLandscape })}>
              <div className={classNames({ 'text-white': mediaType === "video", 'text-#2D3838': mediaType !== "video" })}>{currentIndex}</div>
              <div className='text-#A2A3A3'>/{progressBarSize}</div>
            </div>
            <div className={classNames('filter-box box-border cursor-pointer loc-filter-clear !h-9 flex items-center justify-center rounded-full', { 'filter bg-#E6EEF7 border-2 border-solid border-blue-normal [&>svg]:my-5px [&>svg]:mr-1.5 [&>svg]:ml-[17px] [&>svg>path]:fill-[#378EF8] [&>svg>circle]:stroke-[#378EF8]': filter, 'no-filter p-1 !w-9': !filter, 'mt-2':hasMobileLandscape })} onClick={openFilter}><FilterIcon />{filter && <div onClick={clearFilter} className='py-1.5 px-1.5 [&>svg]:block clear-icon-wrapper rounded-full w-8 h-8'><FilterClearIcon /></div>}</div>
          </div>
        </>}
    </div>
  </div>
}

export default StudyLocationInfoContainer