import React from 'react';
import styled from 'styled-components/macro';
import {ReactComponent as AddToFolderActive} from 'assets/icons/folders/add-to-folder-active.svg';
import {ReactComponent as DefaultFolderIconSVG} from 'assets/icons/folders/default-folder-icon.svg';
import {ReactComponent as MemorizedFolderIconSVG} from 'assets/icons/folders/memorized-folder-icon.svg';
import {ReactComponent as LearnFolderIconSVG} from 'assets/icons/folders/learn-folder-icon.svg';
import {ReactComponent as RepeatFolderIconSVG} from 'assets/icons/folders/repeat-folder-icon.svg';
import {ReactComponent as DragDropFolderIconSVG} from 'assets/icons/folders/drag-n-drop-folder-icon.svg';

export const LearnFolderIcon = () => <LearnFolderIconSVG/>;

export const RepeatFolderIcon = () => <RepeatFolderIconSVG/>;

export const MemorizedFolderIcon = (props) => <MemorizedFolderIconSVG/>;

export const DefaultFolderIcon = () => <DefaultFolderIconSVG/>;

export const DragDropFolderIcon = () => <DragDropFolderIconSVG/>;

export const AddToFolderIcon = (props) => {
  const {state, isHover,activButton} = props;
  switch (state) {
    case 'added':
      return <StyledAddToFolderAdded isHover={isHover} activButton={activButton}>{
        isHover
            ? 'Remove'
            : 'Added'
        }</StyledAddToFolderAdded>;
    case 'active':
      return <AddToFolderActive/>;
    default:
      return <StyledAddToFolderDefault activButton={activButton}>Add</StyledAddToFolderDefault>;
  }
};
export const AddToBookmarkIcon = (props) => {
  const {state } = props;
  switch (state) {
    case 'added':
      return <StyledActiveBook><ActiveBookmarkedIcon /></StyledActiveBook>;
    default:
      return <StyledInactiveBook><InActiveBookmarkedIcon /></StyledInactiveBook>;
  }
};
export function BookmarkedIcon(){
  return(
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="34" rx="17" fill="url(#paint0_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 12C12 10.8954 12.8954 10 14 10H20C21.1046 10 22 10.8954 22 12V23.7929C22 24.2383 21.4614 24.4614 21.1464 24.1464L17.3536 20.3536C17.1583 20.1583 16.8417 20.1583 16.6464 20.3536L12.8536 24.1464C12.5386 24.4614 12 24.2383 12 23.7929L12 12Z" stroke="white" strokeWidth="1.3" />
      <defs>
        <linearGradient id="paint0_linear" x1="17" y1="-17" x2="-17" y2="17" gradientUnits="userSpaceOnUse">
          <stop stopColor="#43DAF0" />
          <stop offset="1" stopColor="#395BF8" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export function InActiveBookmarkedIcon(){
  return(
    <svg className="inactive-bookmark" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.9 7C6.9 5.28792 8.28792 3.9 10 3.9H16C17.7121 3.9 19.1 5.28792 19.1 7V20.9L14.1133 16.1508C13.4899 15.557 12.5101 15.557 11.8867 16.1508L6.9 20.9V7ZM19.4138 21.1989L19.4135 21.1986L19.4138 21.1989ZM6.58621 21.1988C6.58629 21.1988 6.58637 21.1987 6.58645 21.1986L6.58621 21.1988Z" stroke="#A2A3A3" strokeWidth="1.8"/>
    </svg>    
  );
}
export function ActiveBookmarkedIcon(){
  return(
    <svg className="active-bookmark" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.9 7C6.9 5.28792 8.28792 3.9 10 3.9H16C17.7121 3.9 19.1 5.28792 19.1 7V20.9L14.1133 16.1508C13.4899 15.557 12.5101 15.557 11.8867 16.1508L6.9 20.9V7ZM19.4138 21.1989L19.4135 21.1986L19.4138 21.1989ZM6.58621 21.1988C6.58629 21.1988 6.58637 21.1987 6.58645 21.1986L6.58621 21.1988Z" fill="#378EF8" stroke="#378EF8" strokeWidth="1.8"/>
    </svg>  
  );
}
export const StyledAddToFolderDefault = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #378EF8;
  box-shadow: 0 0 0 1px #E3E6E6;
  border-radius: 26px;
  padding: 3px 8px 3px 10px;
  transition: all .2s;
  margin-left: 10px;
  cursor:${props => props.activButton ? 'none' : 'pointer'};
  @media (pointer: fine) {
      &:hover {
        background: #FFFFFF;
        box-shadow: 0 0 0 1px #C8CCCC;
      }
  }
  &:active {
    color: #FFFFFF;	
    box-shadow: none; 
    background: #2EBCB4;    
  }
`;

export const StyledInactiveBook = styled.div`
  user-select: none;
  cursor: pointer;
  @media (pointer: fine) {
    &:hover {
      .inactive-bookmark{
        path{
          stroke: #898989;
        }
      }
    }
  }
  &:active {
    .inactive-bookmark{
        path{
          stroke: #378EF8;
        }
      }
  }
`;

export const StyledActiveBook = styled.div`
  user-select: none;
  width: 26px;
  height: 26px;
  cursor: pointer;
  @media (pointer: fine) {
    &:hover {
      .active-bookmark{
        path{
          stroke: #64A8FA;
          fill: #64A8FA;
        }
      }
    }
  }
  &:active {
    .active-bookmark{
        path{
          stroke: #2170CF;
          fill: #2170CF;
        }
      }
  }
`;

export const StyledAddToFolderAdded = styled.div`
 
  background: #51D4A3;
  border-radius: 26px;
  padding: 3px 8px 3px 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #FFFFFF;
  transition: background 0.2s;
  cursor:${props => props.activButton ? 'none' : 'pointer'};
  margin-left: 10px;
  @media (pointer: fine) {
    &:hover {
     
      background: #80DCBA;
      color: #FFFFFF;
    }
  }
  &:active {
    color: #FFFFFF;
    background: #3EBF8F;    
  }
`;



export const StyledRemoveText = styled.div`
    
`;

export const StyledAddedText = styled.div`

`;
