import styled from 'styled-components/macro';

export const StyledForm = styled.form`
  width: 100%;
  
  .form-input-wrapper{
    width: 100%;
    
    .textarea{
      resize: vertical;
      box-sizing: border-box;
      padding-right: 28px;
      border-radius: 0;
    }
  }
`;

export const StyledFormInputInverted = styled.input`
  background: transparent;
  border: none;
  font-family: "Open Sans";
  font-size: 25px;
  font-weight: bold;
  outline: none;
  color: rgba(255,255,255,0.5);
  box-sizing: border-box;
  width: 100%;
  margin-right: 16px;
  -webkit-appearance: none;
  border-radius: 0;  
  padding: 0;
  height: 34px;
  
  &::-webkit-input-placeholder {
    color: rgba(255,255,255,0.5);
  }
  
  &:focus, &:valid, &:not(:placeholder-shown) {
    color: white;
  }
`;
