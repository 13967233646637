import {createSelector} from 'reselect';
import { activeSubjectId, subjectsTeachingLevel } from 'selectors/subject/subjectSelector';

export const externalExercises = state => state.getIn(['exercises', 'external'], null);
export const viewedExercises = state => state.getIn(['exercises', 'viewed'], null);
export const exerciseData = (state, exerciseId) => state.getIn(['exercises', exerciseId], null);

export const hasNotification = createSelector(
  [viewedExercises, (state, exerciseId) => exerciseId],
  (viewedExercises, exerciseId) => {
    if (!viewedExercises) return false;
    return !viewedExercises.find(exercise => exercise === exerciseId);
  }
);

export const exerciseNotificationsCount = createSelector(
  [externalExercises, viewedExercises],
  (externalExercises, viewedExercises) => {
    if (!externalExercises || !viewedExercises) return 0;

    return externalExercises.size - viewedExercises.size;
  }
);

export const activeSubjectExerciseNotificationsCount = createSelector(
  [activeSubjectId, externalExercises, viewedExercises],
  (activeSubjectId, externalExercises, viewedExercises) => {
    return getNotificationsCountBySubjectId(activeSubjectId, externalExercises, viewedExercises);
  }
);

export const subjectExerciseNotificationsCount = createSelector(
  [(state, subjectId) => subjectId, externalExercises, viewedExercises],
  (subjectId, externalExercises, viewedExercises) => {
    return getNotificationsCountBySubjectId(subjectId, externalExercises, viewedExercises);
  }
);

const getNotificationsCountBySubjectId = (subjectId, externalExercises, viewedExercises) => {
  if (!externalExercises || !viewedExercises) return 0;

  let count = 0;
  const filteredExercises = externalExercises.filter(exercise => exercise.get('subjectId') === subjectId).map(item => item.get('exerciseId'));
  filteredExercises.forEach(filteredExerciseId => {
    if (viewedExercises.findIndex(viewedExerciseId => viewedExerciseId === filteredExerciseId) === -1) {
      count++;
    }
  });

  return count;
};

export const activeSubjectExerciseNotificationsCountByTlvl = createSelector(
  [activeSubjectId, externalExercises, viewedExercises, subjectsTeachingLevel],
  (activeSubjectId, externalExercises, viewedExercises, subjectsTeachingLevel) => {
    return getNotificationsCountByTlvlBySubjectId(activeSubjectId, externalExercises, viewedExercises, subjectsTeachingLevel);
  }
);

const getNotificationsCountByTlvlBySubjectId = (subjectId, externalExercises, viewedExercises, subjectsTeachingLevel) => {
  if (!externalExercises || !viewedExercises) return 0;

  let count = 0;
  const activeSubjectTeachingTitle = subjectsTeachingLevel[`${subjectId}`];
  const filteredExercises = externalExercises.filter(exercise => exercise.get('subjectId') === subjectId &&
  exercise.get('teachingLevel') === activeSubjectTeachingTitle).map(item => item.get('exerciseId'));
  filteredExercises.forEach(filteredExerciseId => {
    if (viewedExercises.findIndex(viewedExerciseId => viewedExerciseId === filteredExerciseId) === -1) {
      count++;
    }
  });

  return count;
};


export const activeHeaderExerciseNotificationsCount = createSelector(
  [activeSubjectId, externalExercises, viewedExercises],
  (activeSubjectId, externalExercises, viewedExercises) => {
    return getNotificationsCountByAll(activeSubjectId, externalExercises, viewedExercises);
  }
);

const getNotificationsCountByAll = (subjectId, externalExercises, viewedExercises) => {
  if (!externalExercises || !viewedExercises) return 0;
  let count = 0;
  const filteredExercises = externalExercises.map(item => item.get('exerciseId'));
  filteredExercises.forEach(filteredExerciseId => {
    if (viewedExercises.findIndex(viewedExerciseId => viewedExerciseId === filteredExerciseId) === -1) {
      count++;
    }
  });
  return count;
};