import { getMediaTypeFilterOverlay, toggleFilterOverlay, toggleMainMicroOverlay } from 'actions/navigation';
import FilterHeader from 'v2/components/filter-overlay/FilterHeader';
import usePrevious from 'hooks/usePrevious';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveExerciseId, isMainMicroFilterBack, listOfFilterCheckedItems } from 'selectors';
import { titlesMicro, filters, heading } from './MicroFilterText';
import FilterToggleHeader from 'v2/components/filter-overlay/FilterToggleHeader';
import FilterContainer from 'v2/components/filter-overlay/FilterContainer';
import FilterButton from 'v2/components/filter-overlay/FilterButton';
import { updateFilterListOfItemsSelected } from 'actions/desk';
import { useHistory } from 'react-router-dom';
import { updateExerciseNumber } from 'actions/topics-tree/topicsTreeActions';
import { setExamFilterData } from 'actions/api';

type MicroFilterProps = {
  onClick?: (e: any) => void;
  onClose: () => void;
  mediaType: string;
}
const titles: any = titlesMicro;
const filterData: any = filters;
const FilterTitle: any = heading

type InitialState = { [key: string]: Array<string> }

const initialState: InitialState = {
  flashcard: ['Not yet assessed', 'Unconfident', 'Neither', 'Confident'],
  revision: ['Not yet read', 'Read'],
  video: ['Not yet watched', 'Partly-watched', 'Watched'],
  exams: ['Not yet assessed', 'Unconfident', 'Neither', 'Confident'],
}

const MicroFilter: React.FC<MicroFilterProps> = ({ onClick, onClose, mediaType }) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const [isActive, setActive] = useState(true)
  const [disabled, setDisable] = useState(true)
  const [filter, setFilter] = useState<InitialState>(initialState)
  const hasMainMicroFilterBack = useSelector(isMainMicroFilterBack);
  const listOfFilter = useSelector(listOfFilterCheckedItems).toJS();
  const activeExerciseId = useSelector(getActiveExerciseId)
  const previousFilterState = usePrevious(filter);

  useEffect(() => {
    let data = listOfFilter[mediaType]
    data.length > 0 && setFilter({ ...filter, [mediaType]: data })
  }, [])

  const checkChangedFilter = () => {
    let stateFilter = filter[mediaType]
    let reduxFilter = listOfFilter[mediaType]
    if (JSON.stringify(stateFilter.sort()) !== JSON.stringify(reduxFilter.sort())) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if (filter[mediaType].length < 1) {
      setDisable(true);
    }
    setActive(filter[mediaType].length === initialState[mediaType].length)
  }
  useEffect(() => {
    if (filter !== previousFilterState) {
      checkChangedFilter();
    }
  }, [filter, previousFilterState, isActive, disabled])

  const onSelectMain = () => {
    onClose();
    dispatch(toggleMainMicroOverlay(true, true));
  }
  const selectToggle = (val: any) => {
    setActive(!val);
    if (isActive) {
      return setFilter({ ...filter, [mediaType]: [] });
    }
    setFilter({ ...filter, [mediaType]: initialState[mediaType] });
  }
  const selectFilter = (val: string) => {
    const filterVal = [...filter[mediaType]];
    let indexOfItem = filterVal.indexOf(val);
    if (indexOfItem >= 0) {
      filterVal.splice(indexOfItem, 1);
    } else {
      filterVal.push(val);
    }
    setFilter({ ...filter, [mediaType]: filterVal });
  }
  const applyChanges = () => {
    let stateFilter = filter[mediaType]
    dispatch(updateFilterListOfItemsSelected(initialState[mediaType].length === stateFilter.length ? [] : stateFilter, mediaType))
    dispatch(toggleFilterOverlay(false, false));
    dispatch(getMediaTypeFilterOverlay(null));
    const pathname = history.location.pathname;
    if (pathname.indexOf("/flashcard/desk/exercise/edit/") !== -1 || (pathname.indexOf("flashcard/desk/exercise/create") !== -1)) {
      dispatch(updateExerciseNumber(activeExerciseId))
    }
    if (mediaType === "exams") {
      dispatch(setExamFilterData());
    }

  }
  return <div className='filter-wrapper' onClick={onClick}>
    <FilterHeader
      fromCC={hasMainMicroFilterBack}
      onBackClick={onSelectMain}
      text={'Filter'}
      hideArrow={false}
      title={titles[mediaType]}
      onCloseClick={onClose}
    />
    <FilterToggleHeader title={FilterTitle[mediaType]} onChange={() => selectToggle(isActive)} checked={isActive} />
    <div className='pt-0 px-5 pb-4'>
      {filterData[mediaType].map((value: any, index: number) => {
        return <FilterContainer key={'MicroFilter-'+index} index={index} isWidth={true} filterType={'micro'} filter={filter[mediaType]} onClick={() => selectFilter(value.text)} itemID={value.text} isRow={true} isMinHeight={false} active={filter[mediaType].includes(value.text)}>
          <>
            <div className='filter-category-section'>{value.text}</div>
            <div className='pl-2'>{value.icon}</div>
          </>
        </FilterContainer>;
      })}
    </div>
    <FilterButton onClose={onClose} onClick={applyChanges} disabled={disabled} />
  </div>
}
export default MicroFilter