
import React,{useCallback, useEffect,useState} from 'react';

import { connect } from 'react-redux';
import { statusById } from 'selectors/statusSelector';
import styled, { css } from 'styled-components/macro';
import FlashcardIcon from 'assets/images/study/StudyMeta/Flashcard.svg';
import RevisionIcon from 'assets/images/study/StudyMeta/RevisionGuide.svg';
import VideoIcon from 'assets/images/study/StudyMeta/Video.svg';
import { ReadIcon } from 'icons/CardIcons';
import StudyStatsBarsContainer from 'v2/components/topics-tree/StudyStatsBarsContainer';
import { quickStatusById } from 'selectors/quickSelectors';
import { returnFinalStatus } from 'helpers';
import Flickity from "react-flickity-component";
import {Map,fromJS} from 'immutable';
import Padding from 'ui-components/helpers/Padding';
import './StudyMetaInfo.scss';
import ExamIcon from 'assets/images/study/StudyMeta/Exams.svg';
import media from 'ui-components/helpers/media';
import { MultipleChoice, FreeResponse } from 'icons/HeaderIcons';
import * as Selectors from 'selectors';
import Text from 'ui-components/helpers/Text';
import Margin from 'ui-components/helpers/Margin';



const StudyMetaInformation = (props) => {
    const [flkty,setFlkty] = useState()
    const { mediaStatus, hasSelection, type, quickMediaStatus, topicData } = props;
    let statusBars = {};
    let isCarousel = 0;
    const flashcardNumbering = topicData?.getIn(['flashcardBox','numbering']);
    const revisionNumbering = topicData?.getIn(['revisionGuideBox','numbering']);
    const videoNumbering = topicData?.getIn(['videoBox','numbering']);
    
    if (type === "quickStatus") {
        statusBars = {
            flashcard: quickMediaStatus.get("flashcard") || Map({}),
            revision: quickMediaStatus.get("revision") || Map({}),
            video: quickMediaStatus.get("video") || Map({}),
            exams:  quickMediaStatus.get("exam").get('freeResponse') || Map({}),
            mcq:  quickMediaStatus.get("exam").get('msqTotal') || Map({})
        }
    } else {
        statusBars = {
            flashcard: mediaStatus.get("flashcard") || Map({}),
            revision: mediaStatus.get("revision") || Map({}),
            video: mediaStatus.get("video") || Map({}),
            exams:  mediaStatus.get("exam").get('freeResponse') || Map({}),
            mcq:  mediaStatus.get("exam").get('msqTotal') || Map({})

        }
    }

    const { flashcardStatus, revisionStatus, videoStatus, flashcardStatusGraph, revisionStatusGraph, videoStatusGraph, examsStatus, examsStatusGraph, mcqStatusGraph,mcqStatus } = returnFinalStatus(statusBars);
    let cardsCount = {
        "flashcard": flashcardStatus.total > 0 ? true : false,
        "revision": revisionStatus.total > 0 ? true : false,
        "video": videoStatus.total > 0 ? true : false,
        "exams": examsStatus.total > 0 || mcqStatus.total > 0 ? true : false
    }
  
    const activeCardArray = () => {
        let arr = []
        Object.entries(cardsCount).map(([key, values], i) => {
            if (values) {
                arr.push(key)
            }

        })
        return arr
    }

    const currentCard = () => {
        let activeKey = 0;
        activeCardArray().map((val, i) => {
            if (val === props.activeMediaTab) {
                activeKey = i
            }
        })
        return activeKey
    }
    // let flkty = React.useRef(null)
    useEffect(() => {
        flkty && flkty.select(currentCard())
    }, [props.activeMediaTab])
    let flickityOptions = {
        pageDots: true,
        groupCells: 1,
        prevNextButtons: true,
        initialIndex: currentCard(),
        on: {
            select: (e) => OnChangeIndex(e),
            staticClick: (event, pointer, cellElement, cellIndex ) => cardClick(cellIndex)
        }
    }
     function OnChangeIndex(e) {
            let activeKey;
            activeCardArray().map((value, index) => {
                if (e === index) {
                    activeKey = value;
                }
            })
            props.onClick(activeKey)
    
        }

    Object.keys(cardsCount).map(function (key, index) {
        if (cardsCount[key]) {
            isCarousel++;
        }
        else {
            isCarousel--;
        }
    });

   const cardClick = useCallback((val) => {
    let activeKey;
    activeCardArray().map((value, index) => {
        if (val === index) {
            activeKey = value;
        }
    })
        props.onCardClick(activeKey)
    }, [props.activeMediaType, mediaStatus])

    return (

        <React.Fragment>        
            {hasSelection ?
                <React.Fragment>
                    {flashcardStatus.total > 0 && <StudyMetaInfo hasSelection={hasSelection} icon={FlashcardIcon} name={'Flashcards'} mediaType={"flashcard"} number={flashcardStatus.total || 0} isRead={flashcardStatus.total === flashcardStatus.correct} statusBarData={flashcardStatusGraph} isActive={props.active} isSubtopic={props.isSubtopic} />}
                </React.Fragment>

                : isCarousel > -1 ?
                     <SliderContainer hasSelection={hasSelection} examsStatus={examsStatus.total} mcqStatus={mcqStatus.total}>
                    <Flickity options={flickityOptions} flickityRef={e=>setFlkty(e)}>
                        {flashcardStatus.total > 0 && <Padding left={7} right={7}> <StudyMetaInfo hasNumbering={flashcardNumbering}  hasSelection={hasSelection} icon={FlashcardIcon} name={'Flashcards'} singleCard={'Flashcard'} mediaType={"flashcard"} number={flashcardStatus.total || 0} isRead={flashcardStatus.total === flashcardStatus.correct} statusBarData={flashcardStatusGraph} isActive={props.active} isSubtopic={props.isSubtopic} isCarousel={true}/></Padding>}

                            {!hasSelection && revisionStatus.total > 0 && <Padding left={7} right={7}> <StudyMetaInfo hasNumbering={revisionNumbering} hasSelection={hasSelection} icon={RevisionIcon} name={'Pages'} singleCard={'Page'} mediaType={"revision"} number={revisionStatus.total || 0} isRead={(revisionStatus.total === revisionStatus.read)} statusBarData={revisionStatusGraph} isActive={props.active} isSubtopic={props.isSubtopic} isCarousel={true} /> </Padding>}


                            {!hasSelection && videoStatus.total > 0 && <Padding left={7} right={7}> <StudyMetaInfo hasNumbering={videoNumbering} hasSelection={hasSelection} icon={VideoIcon} name={'Videos'} singleCard={'Video'} mediaType={"video"} number={videoStatus.total || 0} isRead={videoStatus.total == videoStatus.read} statusBarData={videoStatusGraph} isActive={props.active} isSubtopic={props.isSubtopic} isCarousel={true}/> </Padding>}

                            {!hasSelection && (examsStatus.total> 0 || mcqStatus.total > 0)  && <Padding left={7} right={7}> <StudyMcqMetaInfo hasSelection={hasSelection} icon={ExamIcon} singleCard={'Flashcard'} firstName={'Multiple choice'} secondName={'Free response'} mediaType={"flashcard"} firstNumber={mcqStatus.total} secondnumber={examsStatus.total} isRead={false} statusBarData={examsStatusGraph} mcqStatusGraph={mcqStatusGraph} isActive={props.active} isSubtopic={props.isSubtopic} isCarousel={true} isMcq={mcqStatus.total > 0} hasResponse={examsStatus.total > 0 } examsStatus={examsStatus} mcqStatus={mcqStatus} />  </Padding>}
                        </Flickity></SliderContainer> :

                    <React.Fragment>
                        <div className='single-slider-container'>
                        {flashcardStatus.total > 0 && <StudyMetaInfo hasNumbering={flashcardNumbering} hasSelection={hasSelection} icon={FlashcardIcon} name={'Flashcards'} singleCard={'Flashcard'} mediaType={"flashcard"} number={flashcardStatus.total || 0} isRead={flashcardStatus.total === flashcardStatus.correct} statusBarData={flashcardStatusGraph} isActive={props.active} isSubtopic={props.isSubtopic} isCarousel={false} isMcq={mcqStatus.total > 0}/>}

                        {!hasSelection && revisionStatus.total > 0 && <StudyMetaInfo hasNumbering={revisionNumbering} hasSelection={hasSelection} icon={RevisionIcon} name={'Pages'} singleCard={'Page'} mediaType={"revision"} number={revisionStatus.total || 0} isRead={(revisionStatus.total === revisionStatus.read)} statusBarData={revisionStatusGraph} isActive={props.active} isSubtopic={props.isSubtopic} isCarousel={false} isMcq={mcqStatus.total > 0}/>}

                        {!hasSelection && videoStatus.total > 0 && <StudyMetaInfo hasNumbering={videoNumbering} hasSelection={hasSelection} icon={VideoIcon} name={'Videos'} singleCard={'Video'} mediaType={"video"} number={videoStatus.total || 0} isRead={videoStatus.total == videoStatus.read} statusBarData={videoStatusGraph} isActive={props.active} isSubtopic={props.isSubtopic} isCarousel={false} isMcq={mcqStatus.total > 0}/>}

                        {!hasSelection && (examsStatus.total> 0 || mcqStatus.total > 0)&& <StudyMcqMetaInfo hasSelection={hasSelection} icon={ExamIcon} firstName={'Multiple choice'} secondName={'Free response'} mediaType={"exams"} firstNumber={mcqStatus.total} secondnumber={examsStatus.total} isRead={false} statusBarData={examsStatusGraph} mcqStatusGraph={mcqStatusGraph} isActive={props.active} isSubtopic={props.isSubtopic} isCarousel={true} isMcq={mcqStatus.total > 0} hasResponse={examsStatus.total > 0 }  examsStatus={examsStatus} mcqStatus={mcqStatus}/>}
                        </div>
                    </React.Fragment>
            }
        </React.Fragment>
    )
};
const mapStateToProps = (state, ownProps) => ({
    mediaStatus: statusById(state, ownProps.id),
    quickMediaStatus: quickStatusById(state, ownProps.id),
    activeMediaTab: Selectors.getActiveMediaTab(state),
});
export default connect(
    mapStateToProps, null
)(StudyMetaInformation);

const StyledStudyMeta = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    user-select: none;
`;

export const StudyMetaInfo = ({ icon, name, number, isRead, statusBarData, isActive, isSubtopic, mediaType, hasSelection, isCarousel, isMcq,onClick ,singleCard, isExercise, hasNumbering}) =>
    <StyleMetaInfo hasSelection={hasSelection} isCarousel={isCarousel} isSubtopic={isSubtopic} isMcq={isMcq} isExercise={isExercise}>
        <img draggable="false" className="mediaTab-image" src={icon} alt={name} />
        <Heading>
            <Number>{number} {(isRead && number !== 0) && <ReadIcon />}</Number>
            <Title>{number > 1 ? name : singleCard}</Title>
            <Margin top={4}><Text weight={700} size={10} lineHeight={'14px'} color={'#2D3838'}>{hasNumbering}</Text></Margin>
        </Heading>
        <StudyStatsBarsContainer statusBarData={statusBarData} isActive={isActive} isSubtopic={isSubtopic} mediaType={mediaType} />
    </StyleMetaInfo>;
    

export const StudyMcqMetaInfo = ({ icon, name, firstNumber,secondnumber, isRead, statusBarData, isActive, isSubtopic, mediaType, hasSelection, isCarousel, isMcq, firstName, secondName, hasResponse, mcqStatusGraph , examsStatus, mcqStatus }) =>
    {
        return (<StyleMcqMetaInfo>
            {isMcq && <McqSection hasSelection={hasSelection} isCarousel={isCarousel} isSubtopic={isSubtopic} isMcq={isMcq}>
                <ImageContainer>
                    <img draggable="false" className="mediaTab-image" src={icon} alt={name} />
                </ImageContainer>
                <Heading>
                    <Number>{firstNumber} {(firstNumber === mcqStatus.correct && firstNumber !== 0) && <ReadIcon />}</Number>
                    <Title doubleCard={true}><SvgContainer><MultipleChoice /></SvgContainer>{firstName}</Title>
                </Heading>
                <StudyStatsBarsContainer statusBarData={mcqStatusGraph} isActive={isActive} isSubtopic={isSubtopic} mediaType={mediaType} />
            </McqSection>}
            {(isMcq && hasResponse) && <Separator color='#E1E6E6' />}
            {hasResponse && <StudySection hasSelection={hasSelection} isCarousel={isCarousel} isSubtopic={isSubtopic} isMcq={isMcq}>
                <ImageContainer>
                    {(!isMcq && hasResponse) && <img draggable="false" className="mediaTab-image" src={icon} alt={name} />}
                </ImageContainer>
                <Heading>
                    <Number>{secondnumber} {(secondnumber === examsStatus.correct && secondnumber !== 0) && <ReadIcon />}</Number>
                    <Title doubleCard={true}><SvgContainer><FreeResponse /></SvgContainer>{secondName}</Title>
                </Heading>
                <StudyStatsBarsContainer statusBarData={statusBarData} isActive={isActive} isSubtopic={isSubtopic} mediaType={mediaType} />
            </StudySection>}
        </StyleMcqMetaInfo>)
};

const StyleMcqMetaInfo = styled.div`
    box-shadow: 0 0 0 1px #e1e6e6;
    border-radius: 10px;
    max-height:142px;
    min-width: 298px;
    @media (max-width: 599px){
        min-width: calc(100vw - 77px) !important;   
    }
    padding: 9px 11px 8px;
    ${props => !props.hasSelection && css`
    background:#ffffff;
    `}
    `;
const McqSection = styled.div`
    display:flex;
`;
const StudySection = styled.div`
    display:flex;
`;
const ImageContainer = styled.div`
    width:38px;
    height:38px;
`;


const StyleMetaInfo = styled.div`
    display: flex;
    padding: 8px ${props => !props.hasSelection && !props.isCarousel ? 10.5 : props.hasSelection ? 0 :8 }px ${props => props.hasSelection ? 4 : 8}px ${props => props.isExercise ? 0 : 10.5}px !important;

        ${props => props.isCarousel && css`
            padding: 9px 11px 8px 11px !important;
        `}
        
        min-width: 292px;
        ${props => !props.hasSelection && css`
            background:#ffffff;
            @media (max-width: 599px){
                min-width: calc(100vw - 77px) !important;   
            }
            min-width: 298px !important;
            ${props => props.isCarousel && css`
                box-shadow: 0 0 0 1px #e1e6e6;
            `}
            ${props => !props.isCarousel && css`
            background: transparent !important;
            `}
        `}
        
        border-radius: 10px;
        
        img {
            width:40px;
            height:40px;
        }
        // ${props => (props.isMcq) && css`margin-bottom:-25px;`}
`;
const Heading = styled.div`
    margin-left: 12px;
    margin-right: 20px;
    width: 54px;
    ${props => props.doubleCard && css`
    width:66px;
    `}
`;
const Number = styled.div`
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    color: #2D3838;
`;
const Title = styled.div`
    font-weight: 700;
    font-size: 10px;
    line-height: 11px;
    color: #919191;
    ${props => props.doubleCard && css`
        display:flex;
        width:66px;
        font-weight: 700;
        line-height: 11px;
        align-items:center;
    `}
`;


const mcqData = fromJS({
    correct: 8,
    incorrect: 4,
    total: 16
  })

const SvgContainer = styled.div`margin-right:4px;`;

export const Separator = styled.div`
  background: #E1E6E6;
  height: 1px;
  border-radius: 1px;
  margin: 7.5px 0;
  margin-left: 55px;
`;

export const SliderContainer = styled.div`
    ${({examsStatus, mcqStatus}) => (examsStatus > 0 && mcqStatus > 0) && css `
        min-height: 170px;
    `};
    ${({examsStatus, mcqStatus}) => (examsStatus == 0 || mcqStatus == 0) && css `
        min-height: 121px;
    `};
    padding:24px 14px ${props => (props.examsStatus && props.mcqStatus) ?4 : 0}px; 
    margin-bottom: ${props => (props.examsStatus && props.mcqStatus) ? 21 : 0}px;
`;