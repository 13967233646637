import * as Types from './types';
import makeAction from 'actions/makeAction';

export const setActiveFlashcardTab = makeAction(Types.SET_ACTIVE_FLASHCARD_TAB, 'index');
export const toggleFlashcardNavigation = makeAction(Types.TOGGLE_FLASHCARD_NAVIGATION, 'value');
export const setActiveDeskTab = makeAction(Types.SET_ACTIVE_DESK_TAB, 'index');
export const setActiveTrophiesTab = makeAction(Types.SET_ACTIVE_TROPHIES_TAB, 'index');
export const setActiveItemStoreDetailsTab = makeAction(Types.SET_ACTIVE_ITEM_STORE_DETAILS_TAB, 'index');

export const closeMenu = makeAction(Types.CLOSE_MENU);
export const openMenu = makeAction(Types.OPEN_MENU);

export const toggleSubjectsManager = makeAction(Types.TOGGLE_SUBJECTS_MANAGER, 'value');
export const toggleNavigationSection = makeAction(Types.TOGGLE_NAVIGATION_SECTION);
export const setDocumentTitle = makeAction(Types.SET_DOCUMENT_TITLE, 'title');

export  const hideLastFlashcardStudiedMessage = makeAction(Types.HIDE_LAST_FLASHCARD_STUDIED_MESSAGE, 'value');

export const openStore = makeAction(Types.OPEN_STORE);
export const showStore = makeAction(Types.SHOW_STORE_MODAL);
export const hideStore = makeAction(Types.HIDE_STORE_MODAL);
export const toggleDeskEditMode = makeAction(Types.TOGGLE_DESK_EDIT_MODE,'value');

export const toggleBodyScroll = makeAction(Types.TOGGLE_BODY_SCROLL, 'value');

export const toggleFlashcardNavigationVisibility = makeAction(Types.TOGGLE_FLASHCARD_NAVIGATION_VISIBILITY, 'value');

export const toggleSubjectsManagerOverlay = makeAction(Types.TOGGLE_SUBJECTS_MANAGER_OVERLAY, 'value', 'shouldAnimate');
export const toggleOldSubjectsManagerOverlay = makeAction(Types.TOGGLE_OLD_SUBJECTS_MANAGER_OVERLAY, 'value', 'shouldAnimate');
export const toggleKeyboardNavigationOverlay = makeAction(Types.TOGGLE_KEYBOARD_NAVIGATION_OVERLAY, 'value');
export const toggleSupportManagerOverlay = makeAction(Types.TOGGLE_SUPPORT_MANAGER_OVERLAY, 'value');
export const toggleDeviceManagerOverlay = makeAction(Types.TOGGLE_DEVICE_MANAGER_OVERLAY, 'value');
export const toggleMoreControlOverlay = makeAction(Types.TOGGLE_MORE_CONTROL_OVERLAY, 'value');
export const toggleLinksNavigationSection = makeAction(Types.TOGGLE_LINkS_NAVIGATION_SECTION);
export const openShortcutMenu = makeAction(Types.OPEN_SHORTCUT_MENU);
export const hideShortcutMenu = makeAction(Types.HIDE_SHORTCUT_MENU);

// Save Last Menu Path
export const saveLastMenuPath = makeAction(Types.SAVE_LAST_MENU_PATH, 'data');
export const setActiveMediaTab = makeAction(Types.SET_ACTIVE_MEDIA_TAB, 'data', 'callback');
export const setMediaFlashcardIds = makeAction(Types.SET_MEDIA_FLAHSCARD_IDS);

export const setExerciseOnCanvas = makeAction(Types.SET_EXERCISE_ON_CANVAS, 'data');

export const saveScrollPosition = makeAction(Types.SAVE_SCROLL_POSITION, 'key', 'value');
export const toggleFilterOverlay = makeAction(Types.TOGGLE_FILTER_OVERLAY, 'value');
export const setFilterItemSize = makeAction(Types.SET_FILTER_ITEM_SIZE, 'data', 'callback');
export const toggleTeachingLevelOverlay = makeAction(Types.TOGGLE_TEACHING_LEVEL_OVERLAY, 'value');
export const macroFilterSize = makeAction(Types.MACRO_FILTER_SIZE, 'value');
export const structureLoading = makeAction(Types.STRUCTURE_LOADING, 'value');
export const getMediaTypeFilterOverlay = makeAction(Types.GET_MEDIA_TYPE_FILTER_OVERLAY, 'mediaType');
export const apiResponseLoading = makeAction(Types.API_RESPONSE_LOADING, 'value');
export const setExerciseFilterItemSize = makeAction(Types.SET_EXERCISE_FILTER_ITEM_SIZE, 'value');
export const togglePaperOverlay = makeAction(Types.TOGGLE_PAPER_OVERLAY,"value");
export const toggleDifficultyOverlay =makeAction(Types.TOGGLE_DIFFICULTY_OVERLAY,"value");
export const setFirstNode = makeAction(Types.SET_FIRST_NODE, 'value') ;
export const setFirstTopicId = makeAction(Types.SET_FIRST_TOPIC_ID, 'value') ;
export const setFirstSubtopicId = makeAction(Types.SET_FIRST_SUBTOPIC_ID, 'value') ;
export const setActiveExamsTab = makeAction(Types.SET_ACTIVE_EXAMS_TAB, 'value');
export const setActiveExamsId  = makeAction(Types.SET_ACTIVE_EXAMS_IDS,'id');
export const seActiveExamsPaperId = makeAction(Types.SET_ACTIVE_EXAMS_PAPER_IDS,'id');
export const toggleVideOverlay = makeAction(Types.TOGGLE_VIDEO_OVERLAY,'value');
export const toggleSourceOverlay = makeAction(Types.TOGGLE_SOURCE_OVERLAY,'value');
export const toggleQuestionInfoOverlay = makeAction(Types.TOGGLE_QUESTION_INFO_OVERLAY,'value');
export const toggleHiddenInfoOverlay = makeAction(Types.TOGGLE_HIDDEN_INFO_OVERLAY,'value');
export const setActivePaperTab = makeAction(Types.SET_ACTIVE_PAPER_TAB ,'value')
export const toggleTeachingLevelExamsOverlay = makeAction(Types.TOGGLE_TEACHING_LEVEL_EXAMS_OVERLAY,'value');
export const toggleExamsMode = makeAction(Types.TOGGLE_EXAMS_MODE ,'value')
export const toggleMCQOverlay = makeAction(Types.TOGGLE_MCQ_OVERLAY,'vlaue')
export const toggleMainMicroOverlay = makeAction(Types.TOGGLE_MAIN_MICRO_OVERLAY,'vlaue');
export const mainMicroFilterBack = makeAction(Types.MAIN_MICRO_FILTER_BACK,'value');
export const updateExamsList = makeAction(Types.UPDATE_EXAMS_LIST, 'size','index');
export const progressBarFilter = makeAction(Types.PROGRESS_BAR_FILTER_VISIBLE,'value');
export const setActiveExamPaperHeader = makeAction(Types.EXAM_PAPER_HEADER, 'value');
export const setJWTToken = makeAction(Types.SET_JWT_TOKEN,'value');
export const setActiveTeachingForExam = makeAction(Types.SET_ACTIVE_TEACHING_EXAM,'value')
export const setExamsHeaderList =  makeAction(Types.SET_EXAM_HEADER_LIST,'data')
export const setUpdateExamsPreviewList = makeAction(Types.SET_UPDATE_EXAMS_PREVIEW_LIST,'data');
export const folderAddButtonActive = makeAction(Types.FOLDER_ADD_BUTTON_ACTIVE,'vlaue')
export const toggleEnableCookiesOverlay = makeAction(Types.ENABLE_COOKIES_OVERLAY_VISIBLE,'value');
export const topicsNextPrevButtonEnable = makeAction(Types.TOPICS_NEXT_PREV_BUTTON_ENABLE, 'loading','hasType');
export const toggleAccountsPortalOverlay = makeAction(Types.TOGGLE_ACCOUNTS_PORTAL_OVERLAY, 'data');

export const setActiveExamModule = makeAction(Types.SET_ACTIVE_EXAM_MODULE,'data')
export const updateProgressBarData = makeAction(Types.UPDATE_PROGRESS_BAR, 'size','index');
export const toggleTeachingLevelOverlayFromFeature = makeAction(Types.TOGGLE_TEACHING_LEVEL_OVERLAY_FROM_FEATURE, 'value');

