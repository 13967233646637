import React from 'react'
import { useSelector } from 'react-redux'
import {getActiveSubject} from 'selectors/subject/subjectSelector'
import {getFlashcardById} from 'selectors/studying'
import FilterHeader from './FilterHeader'
import ExamFilterLayout from 'v2/components/studying/examFilterContainer/examFilterLayout'
import {getFormExams, disableMcqExamSubject} from "helpers"
import MCQFilterLayout from 'v2/components/filter-overlay/exams-filter-container/MCQFilterLayout'
import MircoFilterLayout from 'v2/components/studying/microFilterContainer/MicroFilterLayout'
import './filter.scss'
import classNames from 'classnames'

type MainMicroFilterProps = {
    currentId?: string | number;
    onClick?: (e:React.MouseEvent<HTMLDivElement>) => void;
    onClose: () => void;
}  

const MainMicroFilter: React.FC<MainMicroFilterProps> = ({currentId, onClick, onClose}) => {
    const activeSubject = useSelector(getActiveSubject)
    const content = useSelector((state) => getFlashcardById(state, currentId))?.toJS()
    const renderPaperType = (paperType: any) => {
        if (disableMcqExamSubject(activeSubject.id) && paperType === 'Paper 1') {
            return 'Paper 2'
        } else {
            return paperType
        }
    }
    const paperType = content?.experienceData?.tag_labels?.filter((item: any)=>{
        return item.type === 'paper_type'
    })[0].label;

    return <div className='filter-wrapper' onClick={onClick}>
        <FilterHeader
            fromCC={false} 
            text={'Filter'} 
            hideArrow={false} 
            title={'Filter'} 
            onCloseClick={onClose} 
        />
        <div className='group-exam-filter'>
            <ExamFilterLayout type={'popup'} examsMode={false} isEditMode={false} formCC={true} examsTypesMode = {getFormExams() === 'exams'} />
        </div>
        <div className={classNames('flex items-center pt-6 pb-0 ',{'pr-5' : getFormExams() === 'exams', 'px-5' : getFormExams() === 'topics'})}>
            {getFormExams() === 'exams' ?
                <>
                    
                    {renderPaperType(paperType) === 'Paper 1' ?
                        <><div className='pl-5'/><MCQFilterLayout formCC={true} /></> :
                        <MircoFilterLayout page={"exams"} formCC={true} />
                    }
                </> :
                <>
                    {
                        renderPaperType(paperType) === 'Paper 1' && <>
                        <MCQFilterLayout formCC={true} /> <div className='pl-2' /></>
                    }
                        <MircoFilterLayout page={"exercise"} formCC={true} />
                </>
            }
        </div>
        <div className='pt-5' />
    </div>
}

export default MainMicroFilter;