import appHistory from 'appHistory';
import { Map } from 'immutable';
import moment from 'moment';
import media from 'ui-components/helpers/media';
import { NoteIcon, FolderIcon, SearchIcon, TopicsIcon, ExerciseIcon, ExamsIcon } from 'icons/FlashcardNavIcons';
import FlashcardIcon from 'assets/images/study/StudyMeta/Flashcard.svg';
import RevisionIcon from 'assets/images/study/StudyMeta/RevisionGuide.svg';
import VideoIcon from 'assets/images/study/StudyMeta/Video.svg';
import ExamIcon from 'assets/images/study/StudyMeta/Exams.svg';
import { ReactComponent as NoBookmarkIcon } from 'assets/icons/folders/no-bookmark-icon.svg';

// @ts-ignore
const storageImpl = isLocalStorageNameSupported() ? window.localStorage : new LocalStorageAlternative();

function LocalStorageAlternative() {
  var structureLocalStorage: any = {};
  // @ts-ignore
  this.setItem = function (key: any, value: any) {
    structureLocalStorage[key] = value;
  };
  // @ts-ignore
  this.getItem = function (key: any) {
    if (typeof structureLocalStorage[key] != 'undefined') return structureLocalStorage[key];
    return null;
  };
  // @ts-ignore
  this.removeItem = function (key: any) {
    structureLocalStorage[key] = undefined;
  };
}
export function getFromStorage(key: any) {
  return storageImpl.getItem(key);
}
export function saveToStorage(key: any, data: any) {
  storageImpl.setItem(key, JSON.stringify(data));
}
export function addCredentialsToLocalStorage(credentials: any) {
  storageImpl.setItem('smpp-credentials', JSON.stringify(credentials));
}
export function addShownNotificationToLocalStorage() {
  storageImpl.setItem('smpp-shown-notif', 'true');
}
export function hasShownNotificationInLocalStorage() {
  return storageImpl.getItem('smpp-shown-notif');
}

export function toggleEnableCookies(data: any) {
  return storageImpl.setItem('Enable-Cookies', data);
}

export function getEnableCookies() {
  return storageImpl.getItem('Enable-Cookies');
}

export function getCredentials() {
  const defaultValue = {
    'access_token': null,
    'expires_in': null,
    'token_type': null,
    'scope': null,
    'refresh_token': null,
    'createdAt': null
  };
  try {
    return JSON.parse(storageImpl.getItem('smpp-credentials')) ||
      defaultValue;
  } catch (e) {
    return defaultValue;
  }
}

export function addLastFlashcardToLocalStorage(flashcardId: any, subject: any, userId: any) {
  let lastFlashcardsSaved;
  let subjectName = subject.slug;

  getLastFlashcard(userId) ? lastFlashcardsSaved = getLastFlashcard(userId) : lastFlashcardsSaved = {};
  if (typeof lastFlashcardsSaved === 'string') {
    lastFlashcardsSaved = {};
  }

  lastFlashcardsSaved[subjectName] = flashcardId;

  storageImpl.setItem('smpp-last-flashcard-' + userId, JSON.stringify(lastFlashcardsSaved));
}

export function getLastFlashcard(userId: any) {
  return JSON.parse(storageImpl.getItem('smpp-last-flashcard-' + userId));
}

export function addBackFlashcardToLocalStorage(flashcardId: any, returnFlashcardId: any) {
  let backFlashcardsSaved;
  getbackFlashcard() ? backFlashcardsSaved = getbackFlashcard() : backFlashcardsSaved = {};
  if (typeof backFlashcardsSaved === 'string') {
    backFlashcardsSaved = {};
  }
  backFlashcardsSaved['current-flashcard-id'] = flashcardId;
  backFlashcardsSaved['return-flashcard-id'] = returnFlashcardId;
  storageImpl.setItem('smpp-return-flashcard', JSON.stringify(backFlashcardsSaved));
}
export function getbackFlashcard() {
  return JSON.parse(storageImpl.getItem('smpp-return-flashcard'));
}
export function setLastSubjectVisited(subject: any, userId: number) {
  storageImpl.setItem('smpp-last-subject-visited-' + userId, JSON.stringify(subject));
}
export function setLastSubjectActive(subject: string | number, userId: number) {
  storageImpl.setItem('smpp-last-subject-active-' + userId, JSON.stringify(subject));
}
export function getFirstQuestionIdBySubjectId() {
  return storageImpl.getItem('smpp-first-question-id');
}

export function setFirstQuestionIdBySubject(questionId: any) {
  storageImpl.setItem('smpp-first-question-id', questionId);
}
export function setLastMediaTypeVisited(type: any, userId: number) {
  storageImpl.setItem('smpp-last-media-type-visited-' + userId, JSON.stringify(type));
}

export function getLastMediaTypeVisited(userId: number) {
  return JSON.parse(storageImpl.getItem('smpp-last-media-type-visited-' + userId));
}

export function getLastSubjectVisited(userId: number) {
  return JSON.parse(storageImpl.getItem('smpp-last-subject-visited-' + userId));
}

export function getLastSubjectActive(userId: number) {
  return JSON.parse(storageImpl.getItem('smpp-last-subject-active-' + userId));
}

export function setFormExams(type: any) {
  storageImpl.setItem('smpp-last-exams-type', JSON.stringify(type));
}
export function setExamSubTopicId(id: number) {
  storageImpl.setItem('smpp-last-exams-subTopic-id', JSON.stringify(id));
}
export function setExamTopicId(id: number) {
  storageImpl.setItem('smpp-last-exams-Topic-id', id);
}
export function getExamSubTopicId() {
  return storageImpl.getItem('smpp-last-exams-subTopic-id');
}
export function getExamTopicId() {
  return storageImpl.getItem('smpp-last-exams-Topic-id');
}

export function getFormExams() {
  return JSON.parse(storageImpl.getItem('smpp-last-exams-type'));
}

export function getAccessToken() {
  return getCredentials().access_token;
}

export function shouldRefreshToken() {
  const credentials = getCredentials();

  if (credentials.createdAt && credentials.expires_in) {
    const halfTime = credentials.createdAt + Math.floor(credentials.expires_in / 2);
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime > halfTime;
  }
  return false;
}

export function getRefreshToken() {
  return getCredentials().refresh_token;
}

export function removeCredentialsFromLocalStorage() {
  storageImpl.removeItem('smpp-credentials');
}

export function getCenteredScrollValue(container: any, target: any, elementIndex: any) {
  const fullWidth = container.clientWidth,
    targetWidth = target.clientWidth;

  const leftScreenOffset = (fullWidth - targetWidth) / 2,
    leftSiblingOffset = targetWidth * elementIndex;

  const scrollValue = leftSiblingOffset - leftScreenOffset;

  return Math.max(0, scrollValue);
}

export function percentageOf(x: number, y: number) {
  return x * 100 / y;
}

export function capitalizeFirstLetter(string: any) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isiOSSafari() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
}

export function isiPhoneSafari() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
}
export function isiPhone() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPhone/i);
  if (iOS) {
    return true;
  } else {
    return false;
  }
}
export function isTablet() {
  const reactDeviceDetect = require("react-device-detect");
  if (reactDeviceDetect.isTablet) {
    return true;
  }
}
export function isTouchDevice() {
  const reactDeviceDetect = require("react-device-detect");
  if (reactDeviceDetect.deviceType !== "browser") {
    return true;
  }
  if (reactDeviceDetect.isTablet) {
    return true;
  }
  return false;
}

export function isMobile() {
  const reactDeviceDetect = require("react-device-detect");
  if (reactDeviceDetect.deviceType == "mobile") {
    return true;
  }
  return false;
}
function isLocalStorageNameSupported() {
  try {
    window.localStorage.setItem('test', '1');
    window.localStorage.removeItem('test');
    return true;
  } catch (error) {
    return false;
  }
}
export function getDevice() {
  return storageImpl.getItem('smpp-device-id');
}

export function saveDevice(deviceId: string) {
  storageImpl.setItem('smpp-device-id', deviceId);
}

export function clearDevice() {
  storageImpl.removeItem('smpp-device-id');
}

export const ellipsis = (word: string, characterLimit: number) => {
  if (!word) return;
  return word.substring(0, characterLimit) + (word.length > characterLimit ? '...' : '');
};


export const getOrderOfTeachingLevels = () => ['Studies SL', 'SL', 'HL', 'Option A | SL', 'Option B | SL', 'Option C | SL', 'Option D | SL', 'Option A | HL', 'Option B | HL', 'Option C | HL', 'Option D | HL', 'Teaching Level'];

export function getAllIndexes(arr: Array<any>, val: string) {
  let indexes: any = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].indexOf(val) !== -1) {
      indexes.push(i);
    }
  }
  return indexes;
}

type pathType = {
  [key: string]: string
}
export const defaultMenuPathObj: pathType = {
  'topics': '/flashcard/topics',
  'notes': '/flashcard/desk/notes',
  'folders': '/flashcard/desk/folders',
  'exercises': '/flashcard/desk/exercises',
  'search': '/flashcard/search',
  'editNotes': '/flashcard/desk/notes/edit',
  'exams': '/flashcard/desk/exams'
}
export function getOrderofExamSection() {
  return ['Section 1', 'Section 2', 'Section 3', 'Section 4', "Section A", "Section B", "Section C"]
}
export function getOrderofExamOptions() {
  return ['', ' ', 'Core', 'Option A', 'Option B', 'Option C', 'Option D', 'Option E']
}

export const fcAssessments = (answer: number | null) => {
  switch (answer) {
    case 0:
      return "Unconfident"
    case 1:
      return "Confident"
    case 2:
      return "Neither"
    case null:
      return "Not yet assessed"
    default:
      break;
  }
}
type FCAssessmentFiltersTypes = {
  [key: number | string]: string
}
export const FCAssessmentFilters: FCAssessmentFiltersTypes = {
  0: 'Unconfident',
  1: 'Confident',
  2: 'Neither',
  null: 'Not yet assessed',
}

type RGAssessmentFiltersTypes = {
  [key: number | string]: string
}
export const RGAssessmentFilters: RGAssessmentFiltersTypes = {
  0: 'Not yet read',
  1: 'Read',
}
type VAssessmentFiltersTypes = (argA: number, argB: number) => string | null;

export const VAssessmentFilters: VAssessmentFiltersTypes = (partialValue: number, watchedValue: number) => {
  if (partialValue === 0 && watchedValue !== 1) {
    return "Not yet watched";
  } else if (partialValue === 1 && watchedValue !== 1) {
    return "Partly-watched";
  } else if (watchedValue === 1) {
    return "Watched";
  } else {
    return null;
  }
}

type ExamsAssessmentFiltersTypes = {
  [key: number | string]: string
}
export const ExamsAssessmentFilters: ExamsAssessmentFiltersTypes = {
  0: 'Unconfident',
  1: 'Confident',
  2: 'Neither',
  null: 'Not yet assessed',
}

export const mediaTypeTabs = [
  {
    name: 'Flashcards',
    mediaTab: 'flashcard',
    icon: FlashcardIcon
  },
  {
    name: 'Guides',
    mediaTab: 'revision',
    icon: RevisionIcon
  },
  {
    name: 'Videos',
    mediaTab: 'video',
    icon: VideoIcon
  },
  {
    name: 'Exams',
    mediaTab: 'exams',
    icon: ExamIcon
  }
]

export const getExamAnswer = (corrent: number, answered: boolean): number | null => {
  if (corrent === 0 && answered) {
    return 0;
  }
  else if (corrent === 1 && answered) {
    return 1;
  }
  else if (corrent === 2 && answered) {
    return 2;
  }
  else {
    return null;
  }
}

export const examsAssessmentType = (correct: number | null) => {
  switch (correct) {
    case 0:
      return { title: 'You feel unconfident about this exam question', color: "#FA9000" };
    case 1:
      return { title: 'You feel confident about this exam question', color: "#3EBF8F" };
    case 2:
      return { title: 'You feel neutral about this exam question', color: "#A2A3A3" };
    default:
      return { title: null, color: "" };
  }
}
export const FCAssessmentStatus = (answer: number | null, isAnswered: boolean): number | null => {
  if (answer == 0 && isAnswered) {
    return 0;
  }
  else if (answer == 1 && isAnswered) {
    return 1;
  }
  else if (answer == 2 && isAnswered) {
    return 2;
  }
  else {
    return null;
  }
}
export const FCAssessmentStatusTitle = (status: number | null) => {
  switch (status) {
    case 0:
      return 'You feel unconfident about this card';
    case 1:
      return 'You feel confident about this card';
    case 2:
      return 'You feel neutral about this card';
    default:
      return null;
  }
}


export const groupByParam = (array: Array<any>, param: string) => array.reduce(function (r, a) {
  r[a?.[param]] = r[a?.[param]] || [];
  r[a?.[param]].push(a);
  return r;
}, Object.create(null));


export function ExamsSectiongroupedList(list: any) {
  const orderOfTeachingLevels = getOrderofExamSection();
  const sortArray = list.slice().sort((a: any, b: any) => {
    const indexA = orderOfTeachingLevels.indexOf(a.sectionTitle.innerText);
    const indexB = orderOfTeachingLevels.indexOf(b.sectionTitle.innerText);
    if (indexA < indexB) return -1;
    return 1;
  }).sort((a: any, b: any) => { if (a.numbering < b.numbering) return -1; return 1; });
  const filterData = groupByParam(sortArray, 'category')

  return Object.values(filterData).map((arr: any) => arr.sort((a: any, b: any) => { if (a.numbering < b.numbering) return -1; return 1; }))

}
export function getOrderofExamPaper() {
  return ['Paper 1', 'Paper 2', 'Paper 3', 'Paper 4', 'Paper 5', 'Paper 6', 'Paper 7', 'Paper 8', 'Paper 9', 'Paper 10']
}
export function examsPapergroupedList(list: any) {
  const orderOfTeachingLevels = getOrderofExamPaper();
  const sortData = list.sort((a: any, b: any) => a.examCategory > b.examCategory ? 1 : -1)
  const examCategory = sortData.sort((a: any, b: any) => {
    const indexA = orderOfTeachingLevels.indexOf(a.examCategory);
    const indexB = orderOfTeachingLevels.indexOf(b.examCategory);
    if (indexA < indexB) return -1;
    return 1;
  }).sort((a: any, b: any) => { if (a.exam < b.exam) return -1; return 1; });
  const filterData = groupByParam(examCategory, 'examCategory')
  return Object.values(filterData).map((arr: any) => arr.sort((a: any, b: any) => { if (a.exam < b.exam) return -1; return 1; }))
}

export function ExamsOptionsgroupedList(list: any) {
  const orderOfTeachingLevels = getOrderofExamOptions();
  const sortArray = list.sort((a: any, b: any) => {
    const indexA = orderOfTeachingLevels.indexOf(a.question_options?.innerText);
    const indexB = orderOfTeachingLevels.indexOf(b.question_options?.innerText);
    if (indexA < indexB) return -1;
    return 1;
  }).sort((a: any, b: any) => { if (a.numbering < b.numbering) return -1; return 1; });
  const filterData = groupByParam(sortArray, 'question_options')
  return Object.values(filterData).map((arr: any) => arr.sort((a: any, b: any) => { if (a.numbering < b.numbering) return -1; return 1; }))
}

export function teachingFilterResultsListToArray(list: any, tag: string, activeSubject: any) {
  if (activeSubject?.slug === "mathematics") {
    return list.filter((item: any) => item.teachingTag === tag);
  }
  if (activeSubject?.slug === "pre-ib-mathematics") {
    return list;
  }
  if (activeSubject?.tagging === 'NEW_SUBJECT') {
    return list;
  }
  return list && list.filter((item: any) => item.teachingTag?.includes(tag));
}

export function transformResultsListToArray(list: any) {
  const orderOfTeachingLevels = getOrderOfTeachingLevels();

  const sortArray = list.slice().sort((a: any, b: any) => {
    const indexA = orderOfTeachingLevels.indexOf(a.teachingLevel);
    const indexB = orderOfTeachingLevels.indexOf(b.teachingLevel);
    if (indexA < indexB) return -1;
    return 1;
  }).slice().sort((a: any, b: any) => { return a.numbering - b.numbering })
  const filterData = groupByParam(sortArray, 'category')
  return Object.values(filterData).map((arr: any) => arr.sort((a: any, b: any) => { if (a.numbering < b.numbering) return -1; return 1; }))
}

export function teachingFilterResultsMapToArray(list: any, tag: string, activeSubject: any) {
  if (activeSubject?.slug === "mathematics") {
    return list.filter((item: any) => item.teachingTag === tag);
  }
  if (activeSubject?.slug === "pre-ib-mathematics" || activeSubject?.tagging === 'NEW_SUBJECT') {
    return list;
  }
  return list && list.filter((item: any) => item.teachingTag.includes(tag));
}

export const sortArray = (array: Array<any>, sortByProperty: string): Array<any> => {
  return array.sort((item: any, item2: any) => {
    if (item?.[sortByProperty] > item2?.[sortByProperty]) {
      return 1;
    }

    if (item?.[sortByProperty] < item2?.[sortByProperty]) {
      return -1;
    }

    return 0;
  })
}


export const sortArrayString = (array: Array<any>, sortByProperty: string): Array<any> => {
  return array.sort((item: any, item2: any) => {
    if (item?.[sortByProperty]?.toLowerCase() > item2?.[sortByProperty]?.toLowerCase()) {
      return 1;
    }

    if (item?.[sortByProperty]?.toLowerCase() < item2?.[sortByProperty]?.toLowerCase()) {
      return -1;
    }

    return 0;
  })
}

export const preparePreviewString = (initialString: any, searchValue: any) => {
  const replacement = '___REPLACEMENT___';
  let initialStringAsArray = initialString.replaceAll(searchValue, replacement).split(' ');
  const indexes = getAllIndexes(initialStringAsArray, replacement);

  const firstIndex = indexes[0] - 3 > 0 ? indexes[0] - 3 : 0;

  return {
    text: initialString.split(' ').splice(firstIndex).join(' '),
    occurences: indexes.length
  };
};



export function getFlashcardPreviewAndSide(preview: any, activeSearchTerm: string, activeTab: any) {
  if (!preview) {
    return {
      text: "",
      side: 0
    }
  }
  let regex = new RegExp(activeSearchTerm, 'gi');
  const textContent = preview.textContent;
  const svgContent = preview.svgContent;
  const sides = Math.max(textContent.size, svgContent.size);

  let returnObj = {
    text: textContent.join(' '),
    side: 0
  };
  for (let i = 0; i < sides; i++) {
    let textForCurrentIndex = textContent[i];
    let svgForCurrentIndex = svgContent[i];
    if (activeSearchTerm != null) {
      if (activeSearchTerm.indexOf("’") !== -1) {
        activeSearchTerm = activeSearchTerm.replace("’", "'");
        regex = new RegExp(activeSearchTerm, 'gi');
        textForCurrentIndex = textForCurrentIndex.replaceAll("’", "'");
        svgForCurrentIndex = svgForCurrentIndex.replaceAll("’", "'");
      }
      if (activeSearchTerm.indexOf("‘") !== -1) {
        activeSearchTerm = activeSearchTerm.replace("‘", "’");
        regex = new RegExp(activeSearchTerm, 'gi');
        textForCurrentIndex = textForCurrentIndex.replaceAll("‘", "’");
        svgForCurrentIndex = svgForCurrentIndex.replaceAll("‘", "’");
      }
      if (activeSearchTerm.indexOf("'") !== -1) {
        activeSearchTerm = activeSearchTerm.replace("'", "’");
        regex = new RegExp(activeSearchTerm, 'gi');
        textForCurrentIndex = textForCurrentIndex.replaceAll("'", "’");
        svgForCurrentIndex = svgForCurrentIndex.replaceAll("'", "’");
      }
    }
    if (activeSearchTerm != null && activeTab == 4) {
      if (textForCurrentIndex && textForCurrentIndex.search(regex) !== -1) {
        returnObj.text = preparePreviewString(textForCurrentIndex, activeSearchTerm).text;
        returnObj.side = i;
        return returnObj;
      } else if (svgForCurrentIndex && svgForCurrentIndex.search(activeSearchTerm) !== -1) {
        returnObj.text = preparePreviewString(svgForCurrentIndex, activeSearchTerm).text;
        returnObj.side = i;
        return returnObj;
      }
    } else {
      if (textForCurrentIndex) {
        returnObj.text = preparePreviewString(textForCurrentIndex, activeSearchTerm).text;
        returnObj.side = i;
        return returnObj;
      } else if (svgForCurrentIndex) {
        returnObj.text = preparePreviewString(svgForCurrentIndex, activeSearchTerm).text;
        returnObj.side = i;
        return returnObj;
      }
    }

  }

  return returnObj;
}
export const returnFinalExerciseStatus = (data: any) => {
  const flashcardStatus = {
    total: data?.total || 0,
    correct: data?.correct || 0,
    neither: data?.neither || 0,
    incorrect: data?.incorrect || 0,
  };
  const flashcardStatusGraph = [
    {
      title: "Unconfident",
      value: flashcardStatus.incorrect,
      percentage: flashcardStatus.incorrect * 100 / flashcardStatus.total,
      color: "text-[#FA9A00]"
    },
    {
      title: "Neither",
      value: flashcardStatus.neither,
      percentage: ((flashcardStatus.incorrect * 100 / flashcardStatus.total) <= 3.5 && (flashcardStatus.incorrect * 100 / flashcardStatus.total) > 0) ? ((parseInt(flashcardStatus.incorrect) + parseInt(flashcardStatus.neither)) * 100 / flashcardStatus.total) + 3.5 : (parseInt(flashcardStatus.incorrect) + parseInt(flashcardStatus.neither)) * 100 / flashcardStatus.total,
      color: "text-[#C2D0D9]"
    },
    {
      title: "Confident",
      value: flashcardStatus.correct,
      percentage: (((flashcardStatus.incorrect * 100 / flashcardStatus.total) <= 3.5 && (flashcardStatus.incorrect * 100 / flashcardStatus.total) > 0) || ((flashcardStatus.neither * 100 / flashcardStatus.total) <= 3.5 && (flashcardStatus.neither * 100 / flashcardStatus.total) > 0)) ? ((parseInt(flashcardStatus.correct) + parseInt(flashcardStatus.incorrect) + parseInt(flashcardStatus.neither)) * 100 / flashcardStatus.total) + 3.5 : (parseInt(flashcardStatus.correct) + parseInt(flashcardStatus.incorrect) + parseInt(flashcardStatus.neither)) * 100 / flashcardStatus.total,
      color: "text-[#50D4A8]"
    },
  ];
  return {
    flashcardStatus,
    flashcardStatusGraph
  }
}


export function questionTypeFind(list: any) {
  const orderofquestionType = ['mcq', "group"]
  const paperFilter = list.sort((a: any, b: any) => { if (a?.paper < b?.paper) return -1; return 1; })
  const sortArray = paperFilter.sort((a: any, b: any) => {
    const indexA = orderofquestionType.indexOf(a?.question_type) && orderofquestionType.indexOf(a?.question_type);
    const indexB = orderofquestionType.indexOf(a?.question_type) && orderofquestionType.indexOf(b?.question_type);
    if (indexA < indexB) return -1;
    return 1;
  })
  const filterData = groupByParam(sortArray, 'question_type') 
  return filterData
}

export function returnExamAnswerFilter(mcqList: any, frqList: any, mcqFilter: any, frqFilter: any, mediaType: string, list: any) {

  if (mediaType === 'exams') {
    // console.table(list)
    let multipleChooseData = []
    let freeResponseData = []
    if (mcqList && mcqList.length > 0) {
      multipleChooseData = mcqFilterData(mcqList, mcqFilter)
    }
    if (frqList && frqList.length > 0) {
      freeResponseData = frqFilterData(frqList, frqFilter)
    }
    const finalData = [...multipleChooseData, ...freeResponseData]
    return finalData
  } else {
    return list
  }
}

function mcqFilterData(list: any, filter: any) {
  if (filter.length > 2 || filter.length === 0) {
    return list
  }
  if (filter.length === 1) {
    if (filter.includes('Not yet answered')) {
      return list.filter((val: any) => {
        return val.answers.user_response.correct === null;
      })
    } else if (filter.includes('Incorrect')) {
      return list.filter((val: any) => {
        return val.answers.user_response.correct === 1;
      })
    } else if (filter.includes('Correct')) {
      return list.filter((val: any) => {
        return val.answers.user_response.correct === 0;
      })
    } else {
      return list
    }
  }
  if (filter.length === 2) {
    if (filter.includes('Not yet answered') && filter.includes('Incorrect')) {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === null || val.answers.user_response.correct === 1);
      })
    } else if (filter.includes('Not yet answered') && filter.includes('Correct')) {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === null || val.answers.user_response.correct === 0);
      })
    } else if (filter.includes('Incorrect') && filter.includes('Correct')) {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === 1 || val.answers.user_response.correct === 0);
      })
    }
  }

}

function frqFilterData(list: any, filter: any) {
  if (filter.length > 3 || filter.length === 0) {
    return list
  }
  if (filter.length === 1) {
    if (filter.includes('Not yet assessed')) {
      return list.filter((val: any) => {
        return val.answers.user_response.correct === null;
      })
    } else if (filter.includes('Unconfident')) {
      return list.filter((val: any) => {
        return val.answers.user_response.correct === 0;
      })
    } else if (filter.includes('Neither')) {
      return list.filter((val: any) => {
        return val.answers.user_response.correct === 2;
      })
    } else {
      return list.filter((val: any) => {
        return val.answers.user_response.correct === 1;
      })
    }
  }
  if (filter.length === 2) {
    if (filter.includes('Not yet assessed') && filter.includes('Unconfident')) {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === null || val.answers.user_response.correct === 0);
      })
    } else if (filter.includes('Not yet assessed') && filter.includes('Neither')) {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === null || val.answers.user_response.correct === 2);
      })
    } else if (filter.includes('Unconfident') && filter.includes('Neither')) {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === 0 || val.answers.user_response.correct === 2);
      })
    } else if (filter.includes('Unconfident') && filter.includes('Confident')) {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === 0 || val.answers.user_response.correct === 1);
      })
    } else if (filter.includes('Neither') && filter.includes('Confident')) {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === 2 || val.answers.user_response.correct === 1);
      })
    } else {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === null || val.answers.user_response.correct === 1);
      })
    }
  }
  if (filter.length === 3) {
    if (filter.includes('Not yet assessed') && filter.includes('Unconfident') && filter.includes('Neither')) {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === null || val.answers.user_response.correct === 0 || val.answers.user_response.correct === 2);
      })
    } else if (filter.includes('Not yet assessed') && filter.includes('Neither') && filter.includes('Confident')) {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === null || val.answers.user_response.correct === 2 || val.answers.user_response.correct === 1);
      })
    } else if (filter.includes('Unconfident') && filter.includes('Neither') && filter.includes('Confident')) {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === 0 || val.answers.user_response.correct === 2 || val.answers.user_response.correct === 1);
      })
    } else {
      return list.filter((val: any) => {
        return (val.answers.user_response.correct === null || val.answers.user_response.correct === 0 || val.answers.user_response.correct === 1);
      })
    }
  }
}


export function preparePreviewsList(list: any) {
  const orderOfTeachingLevels = getOrderOfTeachingLevels();

  const sortArray = list.sort((a: any, b: any) => {
    const indexA = orderOfTeachingLevels.indexOf(a.teachingLevel);
    const indexB = orderOfTeachingLevels.indexOf(b.teachingLevel);
    if (indexA < indexB) return -1;
    return 1;
  })
  const filterData = groupByParam(sortArray, 'category')
  return Object.values(filterData).map((arr: any) => arr.sort((a: any, b: any) => { if (a.numbering < b.numbering) return -1; return 1; }))
}

export function convertToIndexedArray(list: any = [], key: string) {
  return list.map((item: any) => [item[key], item]);
}
export function convertToIndexedMap(list: any = [], key: string) {
  return list.map((item: any) => [item[key], item]);
}

export function filterResultsListToArray(list: any, mediaType: string, filter: any) {
  if (mediaType === "flashcard") {
    if (filter.length > 3 || filter.length === 0) {
      return list
    }
    if (filter.length === 1) {
      if (filter.includes('Not yet assessed')) {
        return list.filter((val: any) => {
          return val.answers === null;
        })
      } else if (filter.includes('Unconfident')) {
        return list.filter((val: any) => {
          return val.answers === 0;
        })
      } else if (filter.includes('Neither')) {
        return list.filter((val: any) => {
          return val.answers === 2;
        })
      } else {
        return list.filter((val: any) => {
          return val.answers === 1;
        })
      }
    }
    if (filter.length === 2) {
      if (filter.includes('Not yet assessed') && filter.includes('Unconfident')) {
        return list.filter((val: any) => {
          return (val.answers === null || val.answers === 0);
        })
      } else if (filter.includes('Not yet assessed') && filter.includes('Neither')) {
        return list.filter((val: any) => {
          return (val.answers === null || val.answers === 2);
        })
      } else if (filter.includes('Unconfident') && filter.includes('Neither')) {
        return list.filter((val: any) => {
          return (val.answers === 0 || val.answers === 2);
        })
      } else if (filter.includes('Unconfident') && filter.includes('Confident')) {
        return list.filter((val: any) => {
          return (val.answers === 0 || val.answers === 1);
        })
      } else if (filter.includes('Neither') && filter.includes('Confident')) {
        return list.filter((val: any) => {
          return (val.answers === 2 || val.answers === 1);
        })
      } else {
        return list.filter((val: any) => {
          return (val.answers === null || val.answers === 1);
        })
      }
    }

    if (filter.length === 3) {
      if (filter.includes('Not yet assessed') && filter.includes('Unconfident') && filter.includes('Neither')) {
        return list.filter((val: any) => {
          return (val.answers === null || val.answers === 0 || val.answers === 2);
        })
      } else if (filter.includes('Not yet assessed') && filter.includes('Neither') && filter.includes('Confident')) {
        return list.filter((val: any) => {
          return (val.answers === null || val.answers === 2 || val.answers === 1);
        })
      } else if (filter.includes('Unconfident') && filter.includes('Neither') && filter.includes('Confident')) {
        return list.filter((val: any) => {
          return (val.answers === 0 || val.answers === 2 || val.answers === 1);
        })
      } else {
        return list.filter((val: any) => {
          return (val.answers === null || val.answers === 0 || val.answers === 1);
        })
      }
    }
  }
  else if (mediaType === "revision") {
    if (filter.length > 1 || filter.length === 0) {
      return list
    } else if (filter.includes('Read')) {
      return list.filter((val: any) => {
        return val.value === 1;
      })
    } else {
      return list.filter((val: any) => {
        return val.value === 0 || val.value === null;
      })
    }
  } else if (mediaType === "video") {
    if (filter.length > 2 || filter.length === 0) {
      return list
    }
    if (filter.length === 1) {
      if (filter.includes('Not yet watched')) {
        return list.filter((val: any) => {
          return val.totalWatchedInSeconds === 0;
        })
      } else if (filter.includes('Partly-watched')) {
        return list.filter((val: any) => {
          return (val.totalWatchedInSeconds > 0 && val.totalWatchedInSeconds !== val.totalDurationInSeconds && val.value === 0);
        })
      } else {
        return list.filter((val: any) => {
          return val.totalWatchedInSeconds === val.totalDurationInSeconds || val.value === 1;
        })
      }
    }
    if (filter.length === 2) {
      if (filter.includes('Not yet watched') && filter.includes('Partly-watched')) {
        return list.filter((val: any) => {
          return (val.totalWatchedInSeconds === 0 || ((val.totalWatchedInSeconds !== val.totalDurationInSeconds) && val.value === 0));
        })
      } else if (filter.includes('Not yet watched') && filter.includes('Watched')) {
        return list.filter((val: any) => {
          return (val.totalWatchedInSeconds === 0 || (val.totalWatchedInSeconds === val.totalDurationInSeconds || val.value === 1));
        })
      } else {
        return list.filter((val: any) => {
          return (val.totalWatchedInSeconds > 0 && (val.totalWatchedInSeconds !== val.totalDurationInSeconds || (val.totalWatchedInSeconds === val.totalDurationInSeconds)));
        })
      }
    }
  } else {
    return list
  }
}
export function filterResultsListToArrayByTOJS(list: any, mediaType: string, filter: any) {
  if (mediaType === "flashcard") {
    if (filter.length > 3 || filter.length === 0) {
      return list
    }
    if (filter.length === 1) {
      if (filter.includes('Not yet assessed')) {
        return list.filter((val: any) => {
          return val.answers === null;
        })
      } else if (filter.includes('Unconfident')) {
        return list.filter((val: any) => {
          return val.answers === 0;
        })
      } else if (filter.includes('Neither')) {
        return list.filter((val: any) => {
          return val.answers === 2;
        })
      } else {
        return list.filter((val: any) => {
          return val.answers === 1;
        })
      }
    }
    if (filter.length === 2) {
      if (filter.includes('Not yet assessed') && filter.includes('Unconfident')) {
        return list.filter((val: any) => {
          return (val.answers === null || val.answers === 0);
        })
      } else if (filter.includes('Not yet assessed') && filter.includes('Neither')) {
        return list.filter((val: any) => {
          return (val.answers === null || val.answers === 2);
        })
      } else if (filter.includes('Unconfident') && filter.includes('Neither')) {
        return list.filter((val: any) => {
          return (val.answers === 0 || val.answers === 2);
        })
      } else if (filter.includes('Unconfident') && filter.includes('Confident')) {
        return list.filter((val: any) => {
          return (val.answers === 0 || val.answers === 1);
        })
      } else if (filter.includes('Neither') && filter.includes('Confident')) {
        return list.filter((val: any) => {
          return (val.answers === 2 || val.answers === 1);
        })
      } else {
        return list.filter((val: any) => {
          return (val.answers === null || val.answers === 1);
        })
      }
    }
    if (filter.length === 3) {
      if (filter.includes('Not yet assessed') && filter.includes('Unconfident') && filter.includes('Neither')) {
        return list.filter((val: any) => {
          return (val.answers === null || val.answers === 0 || val.answers === 2);
        })
      } else if (filter.includes('Not yet assessed') && filter.includes('Neither') && filter.includes('Confident')) {
        return list.filter((val: any) => {
          return (val.answers === null || val.answers === 2 || val.answers === 1);
        })
      } else if (filter.includes('Unconfident') && filter.includes('Neither') && filter.includes('Confident')) {
        return list.filter((val: any) => {
          return (val.answers === 0 || val.answers === 2 || val.answers === 1);
        })
      } else {
        return list.filter((val: any) => {
          return (val.answers === null || val.answers === 0 || val.answers === 1);
        })
      }
    }
  }
  if (mediaType === "revision") {
    if (filter.length > 1 || filter.length === 0) {
      return list
    } else if (filter.includes('Read')) {
      return list.filter((val: any) => {
        return val.value === 1;
      })
    } else {
      return list.filter((val: any) => {
        return val.value === 0 || val.value === null;
      })
    }
  }
  if (mediaType === "video") {
    if (filter.length > 2 || filter.length === 0) {
      return list
    }
    if (filter.length === 1) {
      if (filter.includes('Not yet watched')) {
        return list.filter((val: any) => {
          return val.totalWatchedInSeconds === 0;
        })
      } else if (filter.includes('Partly-watched')) {
        return list.filter((val: any) => {
          return (val.totalWatchedInSeconds > 0 && val.totalWatchedInSeconds !== val.totalDurationInSeconds && val.value === 0);
        })
      } else {
        return list.filter((val: any) => {
          return val.totalWatchedInSeconds === val.totalDurationInSeconds || val.value === 1;
        })
      }
    }
    if (filter.length === 2) {
      if (filter.includes('Not yet watched') && filter.includes('Partly-watched')) {
        return list.filter((val: any) => {
          return (val.totalWatchedInSeconds === 0 || ((val.totalWatchedInSeconds !== val.totalDurationInSeconds) && val.value === 0));
        })
      } else if (filter.includes('Not yet watched') && filter.includes('Watched')) {
        return list.filter((val: any) => {
          return (val.totalWatchedInSeconds === 0 || (val.totalWatchedInSeconds === val.totalDurationInSeconds || val.value === 1));
        })
      } else {
        return list.filter((val: any) => {
          return (val.totalWatchedInSeconds > 0 && (val.totalWatchedInSeconds !== val.totalDurationInSeconds || (val.totalWatchedInSeconds === val.totalDurationInSeconds)));
        })
      }
    }
  }
}

export function buildSearchResultsArray(initialString: any, val: any, flashcardId: any, side: any) {
  const replacement = '___REPLACEMENT___';
  let indexes = [];
  if (val.indexOf("’") !== -1) {
    val = val.replace("’", "'");
    initialString = initialString.replaceAll("’", "'");
    initialString = initialString.replaceAll(val, replacement);
    let arr = initialString.split(' ');
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf(replacement) !== -1) {
        indexes.push({ flashcardId: flashcardId, searchIndex: indexes.length, side: side });
      }
    }
  } else if (val.indexOf("‘") !== -1) {
    val = val.replace("‘", "’");
    initialString = initialString.replaceAll("‘", "’");
    initialString = initialString.replaceAll(val, replacement);
    let arr = initialString.split(' ');
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf(replacement) !== -1) {
        indexes.push({ flashcardId: flashcardId, searchIndex: indexes.length, side: side });
      }
    }
  } else if (val.indexOf("'") !== -1) {
    val = val.replace("'", "’");
    initialString = initialString.replaceAll("'", "’");
    initialString = initialString.replaceAll(val, replacement);
    let arr = initialString.split(' ');

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf(replacement) !== -1) {
        indexes.push({ flashcardId: flashcardId, searchIndex: indexes.length, side: side });
      }
    }
  } else {
    initialString = initialString.replaceAll(val, replacement);
    let arr = initialString.split(' ');
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf(replacement) !== -1) {
        indexes.push({ flashcardId: flashcardId, searchIndex: indexes.length, side: side });
      }
    }
  }
  return indexes;
}

export function sortFoldersByDate(folders: any) {
  folders.items = folders.items.sort((a: any, b: any) => {
    let aDate = moment(a.createdAt.date);
    let bDate = moment(b.createdAt.date);

    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
  });

  return folders;
}
export function groupBy(input: any, fieldToGroupBy: any) {
  return input.reduce(function (accumulator: any, currentValue: any) {
    (accumulator[currentValue[fieldToGroupBy]] = accumulator[currentValue[fieldToGroupBy]] || []).push(currentValue);
    return accumulator;
  }, {});
}

export function transformArrayToKeyedObject(arr: any) {
  let returnObject: any = {};
  for (let i = 0; i < arr.length; i++) {
    returnObject[arr[i]] = {};
  }
  return returnObject;
}

export function isValidJSON(input: any) {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
}

export function updateFlashcardUrl(flashcardId: any, activeMediaTab: string, mediaType: string) {
  const currentUrl = window.location;
  appHistory.push({
    pathname: currentUrl.pathname,
    search: `?mediaType=${activeMediaTab}&id=${flashcardId}`
  });
}

export function storeExamsParentId(id: string | number) {
  storageImpl.setItem('exam-parent-id', JSON.stringify(id));
}
export function getStoreExamsParentId() {
  return JSON.parse(storageImpl.getItem('exam-parent-id'));
}

export function orderTeachingLevel(list: any) {
  const orderOfTeachingLevels = getOrderOfTeachingLevels();
  return list.sort((a: any, b: any) => {
    const indexA = orderOfTeachingLevels.indexOf(a.title);
    const indexB = orderOfTeachingLevels.indexOf(b.title);
    if (indexA < indexB) return -1;
    return 1;
  });
}

export function isMBiframe(url: string) {
  const exercisePageRegex = RegExp('\\/class\\/[0-9]+\\/exercise\\/');
  return exercisePageRegex.test(url);
}

export function getUniqueId() {
  return '_' + Math.random().toString(36).substr(2, 9);
};

export function decodeQuery(queryString: string) {
  if (!queryString) return {};

  try {
    return JSON.parse('{"' + decodeURI(queryString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
  } catch (e) {
    return {};
  }
}

export function getMBUrlBasedOnRegion(region: string) {
  switch (region) {
    case 'cn':
    case 'china':
      return process.env.REACT_APP_MB_URL_CN;
    case 'canada':
    default:
      return process.env.REACT_APP_MB_URL;
  }
}

export function getMBTldBasedOnRegion(region: string) {
  switch (region) {
    case 'china':
      return 'cn';
    case 'canada':
      return 'com';
    default:
      return 'com';
  }
}

export function getPartnersBasedOnRegion(region: string):string {
  switch (region) {
    case 'cn':
    case 'china':
      return process.env.REACT_APP_ACCOUNT_PROTAL_CN || '';
    case 'canada':
    default:
      return process.env.REACT_APP_ACCOUNT_PROTAL_CANADA || '';
  }
}

export function getPartnersServiceBasedOnRegion(region: string) {
  switch (region) {
    case 'cn':
    case 'china':
      return process.env.REACT_APP_PARTNER_BASE_URL_CN;
    case 'us':
    case 'usa':
      return process.env.REACT_APP_PARTNER_BASE_URL_US;
    case 'canada':
    default:
      return process.env.REACT_APP_PARTNER_BASE_URL;
  }
}


export function orderSubjects(subjects: any) {
  const orderOfSubjects = [
    'biology',
    'chemistry',
    'es-amp-s',
    'physics',
    'business-management',
    'geography',
    'history',
    'psychology',
    'maths-a-amp-a',
    'maths-a-amp-i',
    'english-b',
    'french',
    'spanish-b',
    'sample-package',
    'pre-ib-mathematics',
    'economics',
    'mathematics',
  ];

  return subjects.sort((a: any, b: any) => {
    const indexA = orderOfSubjects.indexOf(a['slug']);
    const indexB = orderOfSubjects.indexOf(b['slug']);
    if (indexA < indexB) return -1;
    return 1;
  });
}

export function orderLinks(links: any) {

  return links.sort((a: any, b: any) => {
    const indexA = a.get("linkKey");
    const indexB = b.get("linkKey");
    if (indexA < indexB) return -1;
    return 1;
  });
}

export function getUniqueExamPreviewDataByTopicNumber(list: any) {
  // @ts-ignore
  let uniquePreviewData: any = [...new Map(list.map((item: any) => [item['flashcarduuId'], item])).values()];
  return uniquePreviewData.sort((a: any, b: any) => {
    const indexA = a.topicNumbering;
    const indexB = b.topicNumbering;
    if (indexA < indexB) return -1;
    return 1;
  });
}

export function getUniqueExamPreviewData(list: any) {
  // @ts-ignore
  let uniquePreviewData = [...new Map(list.map(item => [item['flashcarduuId'], item])).values()];
  return uniquePreviewData.sort((a: any, b: any) => {
    const x = a.topicUniqueNumber.split('.').map((n: number) => +n + 100000).join('.')
    const y = b.topicUniqueNumber.split('.').map((n: number) => +n + 100000).join('.')
    const indexA = x;
    const indexB = y;
    if (indexA < indexB) return -1;
    return 1;
  });
}

export function getUniqueExamPreviewDataToJS(list: any) {
  // @ts-ignore
  let uniquePreviewData = [...new Map(list.map(item => [item['flashcarduuId'], item])).values()];
  // @ts-ignore
  return uniquePreviewData.toJS().sort((a: any, b: any) => {
    const x = a.topicUniqueNumber.split('.').map((n: number) => +n + 100000).join('.')
    const y = b.topicUniqueNumber.split('.').map((n: number) => +n + 100000).join('.')
    const indexA = x;
    const indexB = y;
    if (indexA < indexB) return -1;
    return 1;
  });
}

export function getUniqueTopicPreview(list: any) {
  // @ts-ignore
  let uniquePreviewData = [...new Map(list.map(item => [item['flashcarduuId'], item])).values()];
  return uniquePreviewData.sort((a, b) => {
    const indexA = a.numbering;
    const indexB = b.numbering;
    if (indexA < indexB) return -1;
    return 1;
  });
}

export function addSubjectSubtitles(subjects: any) {
  return subjects.map((subject: any) => {
    switch (subject.slug) {
      case 'psychology':
        return { ...subject, isNew: false };
      case 'geography':
        return { ...subject, isNew: false };
      case 'economics':
        return { ...subject, isNew: true };
      case 'mathematics':
        return { ...subject, title: 'Maths' };
      case 'maths-a-amp-a':
        return { ...subject, isNew: false };
      case 'maths-a-amp-i':
        return { ...subject, isNew: false };
      case 'spanish-b':
        return { ...subject, isNew: false };
      case 'biology':
        return { ...subject, isNew: true };
      case 'chemistry':
        return { ...subject, isNew: true };
      case 'physics':
        return { ...subject, isNew: true };
      case 'english-b':
        return { ...subject, isNew: true };
      case 'business-management':
        return { ...subject, isNew: true };
      case 'french':
        return { ...subject, isNew: true };
      default:
        return { ...subject, subtitle: null, subtitleColor: null };
    }
  });
}
export const Reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getItemStyle = (isDragging: any, draggableStyle: any) => {
  let finalStyle = {
    userSelect: "none",
    ...draggableStyle,
  };
  if (isDragging) {
    finalStyle.boxShadow = "0px 6px 17px rgba(0, 0, 0, 0.2)";
  }
  return finalStyle;
};

export const mediaTypes = { flashcard: 'flashcard', revision: 'revision', video: 'video', exams: 'exams' }

export function getFirstMediaNodeId() {
  return storageImpl.getItem('first-media-nodeId');
}

export function saveFirstMediaNodeId(nodeId: number) {
  storageImpl.setItem('first-media-nodeId', nodeId);
}

export const returnFinalStatus = (data: any) => {
  const { flashcard, revision, video, exams, mcq } = data;
  const flashcardStatus = {
    total: flashcard.get("total") || 0,
    correct: flashcard.get("correct") || 0,
    neither: flashcard.get("neither") || 0,
    incorrect: flashcard.get("incorrect") || 0,
  };

  const revisionStatus = {
    total: revision.get("total") || 0,
    read: revision.get("nodeWatchRead") || 0
  };
  const videoStatus = {
    total: video.get("total") || 0,
    read: video.get("nodeWatchRead") || 0
  };
  const examsStatus = {
    total: exams.get("total") || 0,
    correct: exams.get("correct") || 0,
    neither: exams.get("neither") || 0,
    incorrect: exams.get("incorrect") || 0,
  };
  const mcqStatus = {
    total: mcq.get("total") || 0,
    correct: mcq.get("correct") || 0,
    incorrect: mcq.get("incorrect") || 0,
  }
  const flashcardStatusGraph = [
    {
      title: "Unconfident",
      value: flashcardStatus.incorrect,
      percentage: flashcardStatus.incorrect * 100 / flashcardStatus.total,
      color: "text-[#FA9A00]"
    },
    {
      title: "Neither",
      value: flashcardStatus.neither,
      percentage: ((flashcardStatus.incorrect * 100 / flashcardStatus.total) <= 3.5 && (flashcardStatus.incorrect * 100 / flashcardStatus.total) > 0) ? ((parseInt(flashcardStatus.incorrect) + parseInt(flashcardStatus.neither)) * 100 / flashcardStatus.total) + 3.5 : (parseInt(flashcardStatus.incorrect) + parseInt(flashcardStatus.neither)) * 100 / flashcardStatus.total,
      color: "text-[#C2D0D9]"
    },
    {
      title: "Confident",
      value: flashcardStatus.correct,
      percentage: (((flashcardStatus.incorrect * 100 / flashcardStatus.total) <= 3.5 && (flashcardStatus.incorrect * 100 / flashcardStatus.total) > 0) || ((flashcardStatus.neither * 100 / flashcardStatus.total) <= 3.5 && (flashcardStatus.neither * 100 / flashcardStatus.total) > 0)) ? ((parseInt(flashcardStatus.correct) + parseInt(flashcardStatus.incorrect) + parseInt(flashcardStatus.neither)) * 100 / flashcardStatus.total) + 3.5 : (parseInt(flashcardStatus.correct) + parseInt(flashcardStatus.incorrect) + parseInt(flashcardStatus.neither)) * 100 / flashcardStatus.total,
      color: "text-[#50D4A8]"
    },
  ];
  const examsStatusGraph = [
    {
      title: "Unconfident",
      value: examsStatus.incorrect,
      percentage: examsStatus.incorrect * 100 / examsStatus.total,
      color: "text-[#FA9A00]"
    },
    {
      title: "Neither",
      value: examsStatus.neither,
      percentage: ((examsStatus.incorrect * 100 / examsStatus.total) <= 3.5 && (examsStatus.incorrect * 100 / examsStatus.total) > 0) ? ((parseInt(examsStatus.incorrect) + parseInt(examsStatus.neither)) * 100 / examsStatus.total) + 3.5 : (parseInt(examsStatus.incorrect) + parseInt(examsStatus.neither)) * 100 / examsStatus.total,
      color: "text-[#C2D0D9]"
    },
    {
      title: "Confident",
      value: examsStatus.correct,
      percentage: (((examsStatus.incorrect * 100 / examsStatus.total) <= 3.5 && (examsStatus.incorrect * 100 / examsStatus.total) > 0) || ((examsStatus.neither * 100 / examsStatus.total) <= 3.5 && (examsStatus.neither * 100 / examsStatus.total) > 0)) ? ((parseInt(examsStatus.correct) + parseInt(examsStatus.incorrect) + parseInt(examsStatus.neither)) * 100 / examsStatus.total) + 3.5 : (parseInt(examsStatus.correct) + parseInt(examsStatus.incorrect) + parseInt(examsStatus.neither)) * 100 / examsStatus.total,
      color: "text-[#50D4A8]"
    },
  ];
  const mcqStatusGraph = [
    {
      title: 'Incorrect',
      value: mcqStatus.incorrect,
      percentage: mcqStatus.incorrect * 100 / mcqStatus.total,
      color: mcqStatus.incorrect == 0 ? "text-[#2D3838]" : "text-[#FC2808]",
    },
    {
      title: "Correct",
      value: mcqStatus.correct,
      percentage: ((mcqStatus.incorrect * 100 / mcqStatus.total) <= 3.5 && (mcqStatus.incorrect * 100 / mcqStatus.total) > 0) ? ((parseInt(mcqStatus.incorrect) + parseInt(mcqStatus.correct)) * 100 / mcqStatus.total) + 3.5 : (parseInt(mcqStatus.incorrect) + parseInt(mcqStatus.correct)) * 100 / mcqStatus.total,
      color: mcqStatus.correct == 0 ? "text-[#2D3838]" : "text-[#50D4A8]",
    },
  ]
  const revisionStatusGraph = [
    {
      title: "Read",
      value: revisionStatus.read,
      percentage: revisionStatus.read * 100 / revisionStatus.total,
      color: "text-[#2D3838]"
    }
  ];
  const videoStatusGraph = [
    {
      title: "Watched",
      value: videoStatus.read,
      percentage: videoStatus.read * 100 / videoStatus.total,
      color: "text-[#2D3838]"
    }
  ];
  return {
    flashcardStatus,
    revisionStatus,
    videoStatus,
    examsStatus,
    flashcardStatusGraph,
    revisionStatusGraph,
    videoStatusGraph,
    examsStatusGraph,
    mcqStatusGraph,
    mcqStatus
  }
}
export const setResizeObserver = (elementRef: any, onResize: any) => {
  if (!elementRef && !elementRef.current) return;
  const baseTabContent = elementRef && elementRef.current;
  let resizeObserver = new ResizeObserver(() => {
    onResize();
  });
  resizeObserver.observe(baseTabContent);
  return () => {
    resizeObserver.unobserve(baseTabContent);
  };
};

export const splitString = (mediaWithId: any) => {
  if (!mediaWithId) return;
  const mediaValue = mediaWithId.split("-");
  return mediaValue;
}

export function checkFlashcardAssesstment(filterItems: any, assesstment: any) {
  if (filterItems.length === 0) {
    return false;
  }
  if (filterItems.indexOf(assesstment) !== -1) {
    return false;
  } else {
    return true;
  }
}

export const returnFinalExams = (data: any) => {
  const { exams, mcq } = data;
  const examsStatus = {
    total: exams.total || 0,
    correct: exams.correct || 0,
    neither: exams.neither || 0,
    incorrect: exams.incorrect || 0,
  };
  const mcqStatus = {
    total: mcq.total || 0,
    correct: mcq.correct || 0,
    incorrect: mcq.incorrect || 0,
  }

  const examsStatusGraph = [
    {
      title: "Unconfident",
      value: examsStatus.incorrect,
      percentage: examsStatus.incorrect * 100 / examsStatus.total,
      color: "text-[#FA9A00]"
    },
    {
      title: "Neither",
      value: examsStatus.neither,
      percentage: ((examsStatus.incorrect * 100 / examsStatus.total) <= 3.5 && (examsStatus.incorrect * 100 / examsStatus.total) > 0) ? ((parseInt(examsStatus.incorrect) + parseInt(examsStatus.neither)) * 100 / examsStatus.total) + 3.5 : (parseInt(examsStatus.incorrect) + parseInt(examsStatus.neither)) * 100 / examsStatus.total,
      color: "text-[#C2D0D9]"
    },
    {
      title: "Confident",
      value: examsStatus.correct,
      percentage: (((examsStatus.incorrect * 100 / examsStatus.total) <= 3.5 && (examsStatus.incorrect * 100 / examsStatus.total) > 0) || ((examsStatus.neither * 100 / examsStatus.total) <= 3.5 && (examsStatus.neither * 100 / examsStatus.total) > 0)) ? ((parseInt(examsStatus.correct) + parseInt(examsStatus.incorrect) + parseInt(examsStatus.neither)) * 100 / examsStatus.total) + 3.5 : (parseInt(examsStatus.correct) + parseInt(examsStatus.incorrect) + parseInt(examsStatus.neither)) * 100 / examsStatus.total,
      color: "text-[#50D4A8]"
    },
  ];
  const mcqStatusGraph = [
    {
      title: 'Incorrect',
      value: mcqStatus.incorrect,
      percentage: mcqStatus.incorrect * 100 / mcqStatus.total,
      color: mcqStatus.incorrect == 0 ? "text-[#2D3838]" : "text-[#FC2808]",
    },
    {
      title: "Correct",
      value: mcqStatus.correct,
      percentage: ((mcqStatus.incorrect * 100 / mcqStatus.total) <= 2.5 && (mcqStatus.incorrect * 100 / mcqStatus.total) > 0) ? ((parseInt(mcqStatus.incorrect) + parseInt(mcqStatus.correct)) * 100 / mcqStatus.total) + 2.5 : (parseInt(mcqStatus.incorrect) + parseInt(mcqStatus.correct)) * 100 / mcqStatus.total,
      // percentage: mcqStatus.correct * 100/mcqStatus.total,
      color: mcqStatus.correct == 0 ? "text-[#2D3838]" : "text-[#50D4A8]",
    },
  ]
  return {
    examsStatus,
    examsStatusGraph,
    mcqStatus,
    mcqStatusGraph
  }
}

const examsSubjectID = [14982, 15075, 47, 3, 50, 28374, 38010, 24932, 21913]
export const mathematicsIds = [14982, 15075]

/**
 * @deprecated instead use useExamModuleStatus hook 
 * @param activeModule 
 * @param subjectId 
 * @returns 
 */
export const activeExamsSubject = (activeModule: any, subjectId: number | string) => {
  let activeExamsSubject;
  if (activeModule) {
    activeExamsSubject = examsSubjectID.filter((id) => {
      return subjectId === id
    })
  } else {
    activeExamsSubject = mathematicsIds.filter((id) => {
      return subjectId === id
    })
  }
  return activeExamsSubject.length > 0;
}

export const disableMcqExamSubject = (val: any) => {
  const activeMcqExam = mathematicsIds.filter((id) => {
    return val === id
  })
  return activeMcqExam.length > 0;
}

export const renderPaperType = (activeSubjectId: number, paperType: string) => {
  if (disableMcqExamSubject(activeSubjectId) && paperType === 'Paper') {
    return 'Paper 2'
  } else {
    return paperType
  }
}

export function examAnswerStatistics(val: number) {
  switch (val) {
    case 0:
      return 'unconfident';
    case 1:
      return 'confident'
    case 2:
      return 'neither'
    case -1:
      return '_destroy'
    default:
      return val;
  }
}

export function examTeachingFilter(teachingTag: string, list: any, filter: any, form: any, mediaType?: string) {
  if (form === 'topics' && mediaType === 'exams') {
    if (teachingTag == "HL") {
      if (filter.length > 1 || filter.length === 0) {
        return list
      } else if (filter.includes('HL')) {
        return list.filter((val: any) => {
          return val.teachingTag === 'HL';
        })
      } else {
        return list.filter((val: any) => {
          return val.teachingTag == 'SL&HL' || val.teachingTag == 'SL';
        })
      }
    } else {
      return list
    }
  } else if (form === 'exams') {
    if (teachingTag == "HL") {
      if (filter.length > 1 || filter.length === 0) {
        return list
      } else if (filter.includes('HL')) {
        return list.filter((val: any) => {
          return val.teachingTag == 'HL';
        })
      } else {
        return list.filter((val: any) => {
          return val.teachingTag == 'SL&HL';
        })
      }
    } else {
      return list
    }
  } else {
    return list
  }
}

export function topicTeachingFilter(teachingTag: string, list: any, filter: any, form: any, mediaType: string) {
  if (form === 'topics' && mediaType === 'exams') {
    if (teachingTag == "HL") {
      if (filter.length > 1 || filter.length === 0) {
        return list
      } else if (filter.includes('HL')) {
        return list.filter((val: any) => {
          return val.questionTeachingTag === 'HL';
        })
      } else {
        return list.filter((val: any) => {
          return val.questionTeachingTag == 'SL&HL' || val.questionTeachingTag == 'SL';
        })
      }
    } else {
      return list
    }
  } else if (form === 'exams') {
    if (teachingTag == "HL") {
      if (filter.length > 1 || filter.length === 0) {
        return list
      } else if (filter.includes('HL')) {
        return list.filter((val: any) => {
          return val.questionTeachingTag == 'HL';
        })
      } else {
        return list.filter((val: any) => {
          return val.questionTeachingTag == 'SL&HL';
        })
      }
    } else {
      return list
    }
  } else {
    return list
  }
}

export function sourceFilter(list: any, filter: any, mediaType: string) {
  if (mediaType === "exams") {
    if (filter.length > 1 || filter.length === 0) {
      return list
    } else if (filter.includes('OSC')) {
      return list.filter((val: any) => {
        return val.qb_code === 'osc_dp';
      })
    } else if (filter.includes('IB')) {
      return list.filter((val: any) => {
        return val.qb_code === 'ib_dp';
      })
    } else {
      return list
    }
  }

}



export function paperFilter(list: any, filter: any, mediaType: string) {
  if (mediaType === "exams") {
    if (filter.length > 2 || filter.length === 0) {
      return list
    }
    if (filter.length === 1) {
      if (filter.includes('Paper 1')) {
        return list.filter((val: any) => {
          return val.paperType === 'Paper 1';
        })
      } else if (filter.includes('Paper 2')) {
        return list.filter((val: any) => {
          return val.paperType === "Paper 2";
        })
      } else if (filter.includes('Paper 3')) {
        return list.filter((val: any) => {
          return val.paperType === "Paper 3";
        })
      } else {
        return list
      }
    }
    if (filter.length === 2) {
      if (filter.includes('Paper 1') && filter.includes('Paper 2')) {
        return list.filter((val: any) => {
          return (val.paperType === "Paper 1" || val.paperType === "Paper 2");
        })
      } else if (filter.includes('Paper 1') && filter.includes('Paper 3')) {
        return list.filter((val: any) => {
          return (val.paperType === 'Paper 1' || val.paperType === 'Paper 3');
        })
      } else if (filter.includes('Paper 2') && filter.includes('Paper 3')) {
        return list.filter((val: any) => {
          return (val.paperType === 'Paper 2' || val.paperType === 'Paper 3');
        })
      }
    }

  } else {
    return list
  }

}

export function difficultyFilter(list: any, filter: any, mediaType: string) {
  if (mediaType === "exams") {
    if (filter.length > 2 || filter.length === 0) {
      return list
    }
    if (filter.length === 1) {
      if (filter.includes('Accessible')) {
        return list.filter((val: any) => {
          return val.difficultyLevel === 'Accessible';
        })
      } else if (filter.includes('Moderate')) {
        return list.filter((val: any) => {
          return val.difficultyLevel === "Moderate";
        })
      } else if (filter.includes('Discriminating')) {
        return list.filter((val: any) => {
          return val.difficultyLevel === "Discriminating";
        })
      } else {
        return list
      }
    }
    if (filter.length === 2) {
      if (filter.includes('Accessible') && filter.includes('Moderate')) {
        return list.filter((val: any) => {
          return (val.difficultyLevel === "Accessible" || val.difficultyLevel === "Moderate");
        })
      } else if (filter.includes('Accessible') && filter.includes('Discriminating')) {
        return list.filter((val: any) => {
          return (val.difficultyLevel === 'Accessible' || val.difficultyLevel === 'Discriminating');
        })
      } else if (filter.includes('Moderate') && filter.includes('Discriminating')) {
        return list.filter((val: any) => {
          return (val.difficultyLevel === 'Moderate' || val.difficultyLevel === 'Discriminating');
        })
      }
    }

  } else {
    return list
  }
}

export function examsFilterNumberingUpdate(list: any, mediaType: string) {
  if (mediaType === 'exams') {
    list.map((item: any, index: number) => {
      item.numbering = index + 1
    })
    return list
  } else {
    return list
  }
}
export function returnExamsStatusFilter(list: any, filter: any, paperType: string) {
  if (paperType !== "Paper 1") {
    if (filter.length > 3 || filter.length === 0) {
      return list
    }
    if (filter.length === 1) {
      if (filter.includes('Not yet assessed')) {
        return list.filter((val: any) => {
          return val.user_response.correct === null;
        })
      } else if (filter.includes('Unconfident')) {
        return list.filter((val: any) => {
          return val.user_response.correct === 0;
        })
      } else if (filter.includes('Neither')) {
        return list.filter((val: any) => {
          return val.user_response.correct === 2;
        })
      } else {
        return list.filter((val: any) => {
          return val.user_response.correct === 1;
        })
      }
    }
    if (filter.length === 2) {
      if (filter.includes('Not yet assessed') && filter.includes('Unconfident')) {
        return list.filter((val: any) => {
          return (val.user_response.correct === null || val.user_response.correct === 0);
        })
      } else if (filter.includes('Not yet assessed') && filter.includes('Neither')) {
        return list.filter((val: any) => {
          return (val.user_response.correct === null || val.user_response.correct === 2);
        })
      } else if (filter.includes('Unconfident') && filter.includes('Neither')) {
        return list.filter((val: any) => {
          return (val.user_response.correct === 0 || val.user_response.correct === 2);
        })
      } else if (filter.includes('Unconfident') && filter.includes('Confident')) {
        return list.filter((val: any) => {
          return (val.user_response.correct === 0 || val.user_response.correct === 1);
        })
      } else if (filter.includes('Neither') && filter.includes('Confident')) {
        return list.filter((val: any) => {
          return (val.user_response.correct === 2 || val.user_response.correct === 1);
        })
      } else {
        return list.filter((val: any) => {
          return (val.user_response.correct === null || val.user_response.correct === 1);
        })
      }
    }
    if (filter.length === 3) {
      if (filter.includes('Not yet assessed') && filter.includes('Unconfident') && filter.includes('Neither')) {
        return list.filter((val: any) => {
          return (val.user_response.correct === null || val.user_response.correct === 0 || val.user_response.correct === 2);
        })
      } else if (filter.includes('Not yet assessed') && filter.includes('Neither') && filter.includes('Confident')) {
        return list.filter((val: any) => {
          return (val.user_response.correct === null || val.user_response.correct === 2 || val.user_response.correct === 1);
        })
      } else if (filter.includes('Unconfident') && filter.includes('Neither') && filter.includes('Confident')) {
        return list.filter((val: any) => {
          return (val.user_response.correct === 0 || val.user_response.correct === 2 || val.user_response.correct === 1);
        })
      } else {
        return list.filter((val: any) => {
          return (val.user_response.correct === null || val.user_response.correct === 0 || val.user_response.correct === 1);
        })
      }
    }
  } else if (paperType === "Paper 1") {
    if (filter.length > 2 || filter.length === 0) {
      return list
    }
    if (filter.length === 1) {
      if (filter.includes('Not yet answered')) {
        return list.filter((val: any) => {
          return val.user_response.correct === null;
        })
      } else if (filter.includes('Incorrect')) {
        return list.filter((val: any) => {
          return val.user_response.correct === 1;
        })
      } else if (filter.includes('Correct')) {
        return list.filter((val: any) => {
          return val.user_response.correct === 0;
        })
      } else {
        return list
      }
    }
    if (filter.length === 2) {

      if (filter.includes('Not yet answered') && filter.includes('Incorrect')) {
        return list.filter((val: any) => {
          return (val.user_response.correct === null || val.user_response.correct === 1);
        })
      } else if (filter.includes('Not yet answered') && filter.includes('Correct')) {
        return list.filter((val: any) => {
          return (val.user_response.correct === null || val.user_response.correct === 0);
        })
      } else if (filter.includes('Incorrect') && filter.includes('Correct')) {
        return list.filter((val: any) => {
          return (val.user_response.correct === 1 || val.user_response.correct === 0);
        })
      }
    }
  } else {
    return list
  }
}
function numberStatsConvertName(val: number) {
  switch (val) {
    case 1:
      return 'incorrect';
    case 0:
      return 'correct'
    case null:
      return 'not yet answered'
    default:
      return val
  }
}

export function setJWTToken(val: any) {
  storageImpl.setItem('AP-JWT-Token', val);
}

export function getJWTToken() {
  return storageImpl.getItem('AP-JWT-Token');
}

export function getSubjectsTeachingLevel() {
  return JSON.parse(storageImpl.getItem('subjectsTeachingLevel'));
}
export function setSubjectsTeachingLevel(data: any) {
  storageImpl.setItem('subjectsTeachingLevel', JSON.stringify(data));
}
/**
 * 
 * @param userId 
 * @returns 
 */
export function getSubjectsSyllabus(userId:string) {
  return JSON.parse(storageImpl.getItem(`subjectsSyllabus-${userId}`));
}
/**
 * 
 * @param userId 
 * @param data 
 */
export function setSubjectsSyllabus(userId:number, data: any) {
  storageImpl.setItem('subjectsSyllabus-'+ userId, JSON.stringify(data));
}
export function changeTeachingTag(val: string) {
  switch (val) {
    case "SL":
      return "sl only"
    case "HL":
      return "hl only"
    default:
      return val
  }
}
export function teachingTagFilter(val: string) {
  switch (val) {
    case "HL":
      return "hl only"
    case "SL&HL":
      return "sl & hl"
    case "hl":
      return "hl only"
    case "sl&hl":
      return "sl & hl"
    default:
      return val
  }
}

export function prevNextTopicsData(previewListByTopics: any, topicIdsByTeachingLvl: any, currentId: string | number, index: number) {
  const flashcardTopicId = previewListByTopics[index].topicId;
  const flashcardCount = previewListByTopics.filter((item: any) => item.topicId === flashcardTopicId);

  const flashcardIndexOfTopic = flashcardCount.findIndex((item: any) => item.flashcardId === currentId);
  let topicIndex;
  if (flashcardIndexOfTopic - 3 > 0) {
    topicIndex = topicIdsByTeachingLvl.findIndex((item: any) => item.topicId === flashcardTopicId);

    let topicIds = [];
    if (topicIndex === 0) {
      topicIds.push(topicIdsByTeachingLvl.toJS()[topicIndex + 1].topicId);
    } else {
      topicIds.push(topicIdsByTeachingLvl.toJS()[topicIndex - 1].topicId);
      topicIds.push(topicIdsByTeachingLvl.toJS()[topicIndex + 1].topicId);
    }
    return topicIds;
  } else {
    return false;
  }
}

export function changeQuestionBank(val: string) {
  switch (val) {
    case "IB":
      return "ib_dp"
    case "OSC":
      return "osc_dp"
    default:
      return val
  }
}

export function countExamsStatus(list: any, type: string, fromTopic = false) {
  if (type == "Paper 1") {
    if (fromTopic) {
      return list.reduce(function (r: any, a: any) {
        r[numberStatsConvertName(a?.answers?.user_response?.correct)] = r[numberStatsConvertName(a?.answers?.user_response?.correct)] || [];
        r[numberStatsConvertName(a?.answers?.user_response?.correct)].push(a);
        return r;
      }, Object.create(null));
    } else {
      return list.reduce(function (r: any, a: any) {
        r[numberStatsConvertName(a.user_response.correct)] = r[numberStatsConvertName(a.user_response.correct)] || [];
        r[numberStatsConvertName(a.user_response.correct)].push(a);
        return r;
      }, Object.create(null));
    }
  } else {
    if (fromTopic) {
      return list.reduce(function (r: any, a: any) {
        r[PaperStatsConvertName(a.answers.user_response.correct)] = r[PaperStatsConvertName(a.answers.user_response.correct)] || [];
        r[PaperStatsConvertName(a.answers.user_response.correct)].push(a);
        return r;
      }, Object.create(null));
    } else {
      return list.reduce(function (r: any, a: any) {
        r[PaperStatsConvertName(a.user_response.correct)] = r[PaperStatsConvertName(a.user_response.correct)] || [];
        r[PaperStatsConvertName(a.user_response.correct)].push(a);
        return r;
      }, Object.create(null));
    }
  }
}
function PaperStatsConvertName(val: number) {
  switch (val) {
    case 0:
      return 'incorrect';
    case 1:
      return 'correct'
    case 2:
      return 'neither'
    case null:
      return 'not yet answered'
    default:
      return val
  }
}

export function sortHiddenInfoOverlayQuestions(list: any) {
  // @ts-ignore
  let uniquePreviewData = [...new Map(list.map(item => [item['topicNumber'], item])).values()];
  return uniquePreviewData.sort((a, b) => {
    const x = a.topicNumber.split('.').map((n: number) => +n + 100000).join('.')
    const y = b.topicNumber.split('.').map((n: number) => +n + 100000).join('.')
    const indexA = x;
    const indexB = y;
    if (indexA < indexB) return -1;
    return 1;
  });
}

export const detectIncognito = function () {
  return new Promise(function (resolve, reject) {
    var browserName = "Unknown";

    function __callback(isPrivate: any) {
      resolve({
        isPrivate: isPrivate,
        browserName: browserName
      });
    }

    function identifyChromium() {
      var ua = navigator.userAgent;
      if (ua.match(/Chrome/)) {
        // @ts-ignore
        if (navigator.brave !== undefined) {
          return "Brave"
        } else if (ua.match(/Edg/)) {
          return "Edge";
        } else if (ua.match(/OPR/)) {
          return "Opera";
        }
        return "Chrome";
      } else {
        return "Chromium";
      }
    }

    function assertEvalToString(value: any) {
      return value === eval.toString().length;
    }

    function isSafari() {
      var v = navigator.vendor;
      return v !== undefined && v.indexOf("Apple") === 0 && assertEvalToString(37);
    }

    function isChrome() {
      var v = navigator.vendor;
      return v !== undefined && v.indexOf("Google") === 0 && assertEvalToString(33);
    }

    function macOS_safari14() {
      try {
        // @ts-ignore
        window.safari.pushNotification.requestPermission("https://example.com", "private", {}, (function () { }));
      } catch (e: any) {
        return __callback(!new RegExp("gesture").test(e));
      }
      return __callback(false);
    }
    function iOS_safari14() {
      var tripped = false;
      var iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      // @ts-ignore
      iframe.contentWindow.applicationCache.addEventListener("error", function () {
        tripped = true;
        return __callback(true);
      });

      setTimeout(function () {
        if (!tripped) {
          __callback(false);
        }
      }, 100);
    }

    function oldSafariTest() {
      // @ts-ignore
      var openDB = window.openDatabase;
      var storage = window.localStorage;
      try {
        openDB(null, null, null, null);
      } catch (e) {
        return __callback(true);
      }
      try {
        storage.setItem("test", "1");
        storage.removeItem("test");
      } catch (e) {
        return __callback(true);
      }
      return __callback(false);
    }

    function safariPrivateTest() {
      var w = window;
      if (navigator.maxTouchPoints !== undefined) {
        // @ts-ignore
        if (w.safari !== undefined && w.DeviceMotionEvent === undefined) {
          browserName = "Safari for macOS";
          macOS_safari14();
        } else if (w.DeviceMotionEvent !== undefined) {
          browserName = "Safari for iOS";
          iOS_safari14();
        } else {
          reject(new Error("detectIncognito Could not identify this version of Safari"));
        }
      } else {
        browserName = "Safari";
        oldSafariTest();
      }
    }

    function getQuotaLimit() {
      var w = window;
      // @ts-ignore
      if (w.performance !== undefined && w.performance.memory !== undefined && w.performance.memory.jsHeapSizeLimit !== undefined) {
        // @ts-ignore
        return performance.memory.jsHeapSizeLimit;
      }
      return 1073741824;
    }

    // >= 76
    function storageQuotaChromePrivateTest() {
      // @ts-ignore
      navigator.webkitTemporaryStorage.queryUsageAndQuota(
        function (usage: any, quota: any) {
          __callback(quota < getQuotaLimit());
        },
        function (e: any) {
          reject(new Error("detectIncognito somehow failed to query storage quota: " + e.message));
        }
      );
    }

    // 50 to 75
    function oldChromePrivateTest() {
      // @ts-ignore
      var fs = window.webkitRequestFileSystem;
      var success = function () {
        __callback(false);
      };
      var error = function () {
        __callback(true);
      };
      fs(0, 1, success, error);
    }

    function chromePrivateTest() {
      if (Promise !== undefined && Promise.allSettled !== undefined) {
        storageQuotaChromePrivateTest();
      } else {
        oldChromePrivateTest();
      }
    }

    function main() {
      if (isSafari()) {
        safariPrivateTest();
      } else if (isChrome()) {
        browserName = identifyChromium();
        chromePrivateTest();
      } else {
        reject(new Error("detectIncognito cannot determine the browser"));
      }
    }
    main();
  });
};

type NavigationsTab = {
  name: string;
  tab: number;
}
export const navigationsTab:NavigationsTab[] = [
  {
    name: 'topic',
    tab: 0
  },
  {
    name: 'exams',
    tab: 1
  },
  {
    name: 'notes',
    tab: 2
  },
  {
    name: 'folders',
    tab: 3
  },
  {
    name: 'exercise',
    tab: 4
  },
  {
    name: 'search',
    tab: 5
  }
]

type NavigationsName = {
  name: string;
  tabName: string;
}
export const navigationsName:NavigationsName[] = [
  {
    name: 'folders',
    tabName: 'folders'
  },
  {
    name: 'exams',
    tabName: 'exams'
  },
  {
    name: 'notes',
    tabName: 'notes'
  },
  {
    name: 'search',
    tabName: 'search'
  },
  {
    name: 'exercises',
    tabName: 'exercises'
  },
  {
    name: 'exercise',
    tabName: 'exercises'
  },
  {
    name: 'classroom',
    tabName: 'exercises'
  },
  {
    name: 'topics',
    tabName: 'topics'
  },
  {
    name: 'topic',
    tabName: 'topics'
  },
  {
    name: 'subtopic',
    tabName: 'topics'
  }
]

type LeftMenuItems = {
  name: string;
  icon: any;
  hasNotification: boolean;
  index: number;
  url: string;
}
export const leftMenuItems:LeftMenuItems[] = [
  {
    name: 'Topics',
    icon: TopicsIcon,
    hasNotification: false,
    index: 0,
    url: 'topics'
  },
  {
    name: 'Exams',
    icon: ExamsIcon,
    hasNotification: false,
    index: 1,
    url: 'exams'
  },
  {
    name: 'Notes',
    icon: NoteIcon,
    hasNotification: false,
    index: 2,
    url: 'notes'
  },
  {
    name: 'Folders',
    icon: FolderIcon,
    hasNotification: false,
    index: 3,
    url: 'folders'
  },
  {
    name: 'Exercises',
    icon: ExerciseIcon,
    hasNotification: false,
    index: 4,
    url: 'exercises'
  },
  {
    name: 'Search',
    icon: SearchIcon,
    hasNotification: false,
    index: 5,
    url: 'search'
  }
]


export const mediaFilters = [
  'flashcard',
  'revision',
  'video',
  'exams',
]

export const examFilters = [
  'teachingLevel',
  'difficulty',
  'paper',
  'source',
  'mcq',
]

export function menuPathIndex() {
  return [
    { name: "topics", id: 0 },
    { name: "topic", id: 0 },
    { name: "exams", id: 1 },
    { name: "notes", id: 2 },
    { name: "folders", id: 3 },
    { name: "exercise", id: 4 },
    { name: "classroom", id: 4 },
    { name: "search", id: 5 },
  ];
}
export const returnFinalStatusV2 = (data: any) => {
  const { flashcard, revision, video, exams, mcq } = data;
  const flashcardStatus = {
    total: flashcard.total || 0,
    correct: flashcard.correct || 0,
    neither: flashcard.neither || 0,
    incorrect: flashcard.incorrect || 0,
  };

  const revisionStatus = {
    total: revision.total || 0,
    read: revision.nodeWatchRead || 0
  };
  const videoStatus = {
    total: video.total || 0,
    read: video.nodeWatchRead || 0
  };
  const examsStatus = {
    total: exams.total || 0,
    correct: exams.correct || 0,
    neither: exams.neither || 0,
    incorrect: exams.incorrect || 0,
  };
  const mcqStatus = {
    total: mcq.total || 0,
    correct: mcq.correct || 0,
    incorrect: mcq.incorrect || 0,
  }
  const flashcardStatusGraph = [
    {
      title: "Unconfident",
      value: flashcardStatus.incorrect,
      percentage: flashcardStatus.incorrect * 100 / flashcardStatus.total,
      color: "text-[#FA9A00]"
    },
    {
      title: "Neither",
      value: flashcardStatus.neither,
      percentage: ((flashcardStatus.incorrect * 100 / flashcardStatus.total) <= 3.5 && (flashcardStatus.incorrect * 100 / flashcardStatus.total) > 0) ? ((parseInt(flashcardStatus.incorrect) + parseInt(flashcardStatus.neither)) * 100 / flashcardStatus.total) + 3.5 : (parseInt(flashcardStatus.incorrect) + parseInt(flashcardStatus.neither)) * 100 / flashcardStatus.total,
      color: "text-[#C2D0D9]"
    },
    {
      title: "Confident",
      value: flashcardStatus.correct,
      percentage: (((flashcardStatus.incorrect * 100 / flashcardStatus.total) <= 3.5 && (flashcardStatus.incorrect * 100 / flashcardStatus.total) > 0) || ((flashcardStatus.neither * 100 / flashcardStatus.total) <= 3.5 && (flashcardStatus.neither * 100 / flashcardStatus.total) > 0)) ? ((parseInt(flashcardStatus.correct) + parseInt(flashcardStatus.incorrect) + parseInt(flashcardStatus.neither)) * 100 / flashcardStatus.total) + 3.5 : (parseInt(flashcardStatus.correct) + parseInt(flashcardStatus.incorrect) + parseInt(flashcardStatus.neither)) * 100 / flashcardStatus.total,
      color: "text-[#50D4A8]"
    },
  ];
  const examsStatusGraph = [
    {
      title: "Unconfident",
      value: examsStatus.incorrect,
      percentage: examsStatus.incorrect * 100 / examsStatus.total,
      color: "text-[#FA9A00]"
    },
    {
      title: "Neither",
      value: examsStatus.neither,
      percentage: ((examsStatus.incorrect * 100 / examsStatus.total) <= 3.5 && (examsStatus.incorrect * 100 / examsStatus.total) > 0) ? ((parseInt(examsStatus.incorrect) + parseInt(examsStatus.neither)) * 100 / examsStatus.total) + 3.5 : (parseInt(examsStatus.incorrect) + parseInt(examsStatus.neither)) * 100 / examsStatus.total,
      color: "text-[#C2D0D9]"
    },
    {
      title: "Confident",
      value: examsStatus.correct,
      percentage: (((examsStatus.incorrect * 100 / examsStatus.total) <= 3.5 && (examsStatus.incorrect * 100 / examsStatus.total) > 0) || ((examsStatus.neither * 100 / examsStatus.total) <= 3.5 && (examsStatus.neither * 100 / examsStatus.total) > 0)) ? ((parseInt(examsStatus.correct) + parseInt(examsStatus.incorrect) + parseInt(examsStatus.neither)) * 100 / examsStatus.total) + 3.5 : (parseInt(examsStatus.correct) + parseInt(examsStatus.incorrect) + parseInt(examsStatus.neither)) * 100 / examsStatus.total,
      color: "text-[#50D4A8]"
    },
  ];
  const mcqStatusGraph = [
    {
      title: 'Incorrect',
      value: mcqStatus.incorrect,
      percentage: mcqStatus.incorrect * 100 / mcqStatus.total,
      color: mcqStatus.incorrect == 0 ? "text-[#2D3838]" : "text-[#FC2808]",
    },
    {
      title: "Correct",
      value: mcqStatus.correct,
      percentage: ((mcqStatus.incorrect * 100 / mcqStatus.total) <= 3.5 && (mcqStatus.incorrect * 100 / mcqStatus.total) > 0) ? ((parseInt(mcqStatus.incorrect) + parseInt(mcqStatus.correct)) * 100 / mcqStatus.total) + 3.5 : (parseInt(mcqStatus.incorrect) + parseInt(mcqStatus.correct)) * 100 / mcqStatus.total,
      color: mcqStatus.correct == 0 ? "text-[#2D3838]" : "text-[#50D4A8]",
    },
  ]
  const revisionStatusGraph = [
    {
      title: "Read",
      value: revisionStatus.read,
      percentage: revisionStatus.read * 100 / revisionStatus.total,
      color: "text-[#2D3838]"
    }
  ];
  const videoStatusGraph = [
    {
      title: "Watched",
      value: videoStatus.read,
      percentage: videoStatus.read * 100 / videoStatus.total,
      color: "text-[#2D3838]"
    }
  ];
  return {
    flashcardStatus,
    revisionStatus,
    videoStatus,
    examsStatus,
    flashcardStatusGraph,
    revisionStatusGraph,
    videoStatusGraph,
    examsStatusGraph,
    mcqStatusGraph,
    mcqStatus
  }
}


export const pandemicExamsIds = [
  '01GF3DQ9Q2X0WJH9FF006RAC8G',
  '01GF3FNWMK113H0C050GGV30GS',
  '01GF3DPAJCAFPWF2P9794CTEYB',
  '01GF3FKPGJSJ582SJHW5J2FSHZ',
  '01GF3E0DKQ46B0HEEMR2BW9WBQ',
  '01GF3E1EAJFYM0MN1E23FCC1Z8',
  '01GKGXP5M96A35Y2XC276CBNW5',
  '01GEVWM0H19SCJD0T9VXW7Y8SV',
  '01GKGXT4K6ED2BJM5FGN00TMG6',
  '01GV5P1PPD5ZJEHHR381GNCHB9',
  '01GEVWJZ1MYXPRNM5QDNVS570A',
  '01GV8C2NCJBGWX7NMSNFYYJH7B',
  '01GEVWREKRJVZTFF0C1ZGVWDVQ',
  '01GKGY1A3SAV0HJ1S78ZZ748WA',
  '01GVADQRXZHY285HDS16EFZEWA',
  '01GEVWQXZAWNPH8P2F6T1K4V91',
  '01GF5PZKN0BPBEZ0JGPBTCPBP5',
  '01GF5Q3WDAFXDPC246NS4M1T5E'
]

type SmartFolderValue = {
  title: string;
  icon: any;
  description?: string;
}
type SmartFoldersProps = {
  [key: string]: SmartFolderValue;
}
export const smartFolders: SmartFoldersProps = {
  'bookmark': {
    title: 'bookmark',
    icon: () => NoBookmarkIcon
  },
};

export const examsForB2BUsers:Array<string> = [
  '01FV0RRPBBSZKTM6QAVT6TH1YT',
  '01FS549A925V4GZ4SBK9ZJMBH8',
  '01FV0RM4KK8V6AASF31ABW5K9E',
  '01FTTZPB1YDH52K8ZFZFG02YGB',
  '01GF3FKPGJSJ582SJHW5J2FSHZ',
  '01GF3DPAJCAFPWF2P9794CTEYB',
  '01GF3FNWMK113H0C050GGV30GS',
  '01GF3DQ9Q2X0WJH9FF006RAC8G',
  '01GF3E0DKQ46B0HEEMR2BW9WBQ',
  '01GF3E1EAJFYM0MN1E23FCC1Z8'
]


export const oldNewSubjectMapped:Array<Array<number>> = [
  [47, 24932],     // biology
  [3, 21913],      // Chemistry
  [50, 38010],     // physics
  [1703, 21345],   // economics
  [2269, 2269],   // es-amp-s
  [2837, 2837],   // mathematics
  [14631, 14631],  // Geography
  [8944, 8944],   // spanish-b
  [14982, 14982],  // maths-a-amp-a
  [15075, 15075],  // maths-a-amp-i
  [17408, 17408],  // psychology
  [19700, 19700],  // history
  [19711, 19711],  // english-b
  [19978, 28374],  // business-management
  [20483, 20483],
  [20970, 20970],  // pre-ib-mathematics
  [41046, 41046],   // french
  [8832, 8832],   // demo
  [41046, 41046],   // french
]

export const activeSyllabusBySubject:any = {
  3: 3,
  47: 47,
  50: 50,
  1703: 1703,
  2269:2269,
  2837: 2837,
  8944: 8944,
  14631: 14631,
  14982: 14982,
  15075: 15075,
  17408: 17408,
  19700: 19700,
  19978: 19978,
}

export const yearNewSyllabus = (slug: string): string => {
  switch (slug) {
    case "economics":
      return "2020";
    case "french":
    case "english-b":
      return "2018"
    default:
      return "2023";
  }
};

/**
 * 
 * @param folders 
 * @param sequence 
 * @returns 
 */
export const changeSequenceOfFolders = (folders:Array<any>, sequence:Array<any>) => {
  const newSequence = folders.sort((a:any, b:any) => {
    const indexA = sequence.indexOf(a.id.toString());
    const indexB = sequence.indexOf(b.id.toString());
    if (indexA < indexB) return -1;
    return 1;
  });

  return newSequence
}

export const optimizedSubjectTitle = (subjectTitle: string) => {
  switch (subjectTitle) {
    case 'Business Management':
      return 'Business M.'
    case 'Pre-IB Mathematics':
      return 'Pre-IB Maths'
    default:
      return subjectTitle;
  }
}

