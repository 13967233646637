import React, {memo} from 'react'
import NotificationIcons from './NotificationIcons'
import './notifications.scss'

type NotificationProps = {
    title: string; 
    icon?: any; 
    text?: string; 
}  

const Notification: React.FC<NotificationProps> = ({title, icon, text}) => {
    return <div className='swal-notification'>
        <div className="swal-notification-image">
            {icon && <img src={NotificationIcons[icon]} alt=''/> }
        </div>
        <div className="swal-notification-title" dangerouslySetInnerHTML={{__html: title}} />
           
        
        {text && <div className="swal-notification-text">
            {text}
        </div> }
    </div>
}

export default memo(Notification);