import React from "react"
import './Support.scss';
import classNames from "classnames";
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import { ReactComponent as CloseIcon } from 'assets/icons/page-overlay/close-icon-turquoise.svg';
import { PageTitle } from 'v2/ui-components/pageComponents';
import { ForwardArrowIcon } from "icons/HeaderIcons"

type SupportComponentsProps = {
  isControlOverlay?: boolean,
  openMenu: () => void,
  userData: any,
  startOnboarding: () => void,
  isMobilePortrait: boolean,
  isMBUser: any,
  toggleSupportManagerOverlay: () => void,
  activeSubject: any
}

const SupportComponents: React.FC<SupportComponentsProps> = ({ isControlOverlay = false, openMenu, startOnboarding, userData, isMBUser, isMobilePortrait, toggleSupportManagerOverlay }) => {
  const paddingStyle = () => {
    if (isMBUser) {
      return 'py-12 pr-12 pl-9';
    } else {
      return 'p-9'
    }
  }
  return <div className={classNames('bg-white rounded-t-2xl max-w-822px border-solid border-1px border-#E1E6E6 support-manager', paddingStyle(), { 'bg-none p-0 border-none': isControlOverlay })}>
    {!isMobilePortrait &&
     
      (<NavigationHeader 
        title={(<div className="support-title-container"><div className="support-title">Support</div></div>)}
        rightSection={<div className="StyledCloseButton" onClick={() => toggleSupportManagerOverlay()}><CloseIcon /> </div>}
        isSupportPage={true} leftSection={<div />} extendedHeight bottomSection={<div />}
      />)}
    <div className="flex flex-wrap">
      <div className="support-left-section">
      <PageTitle>Support</PageTitle>
      <div className="support-page-items-container">
        <a href={`http://help.oxfordstudycourses.com/`} target="_blank" className="support-page-item">
          <div className="support-page-item-container"> Support centre
            <div className="forward-arrow-icon"><ForwardArrowIcon /></div>
          </div>
        </a>
        <a
          onClick={() => startOnboarding()}
          className="support-page-item">
          <div className="support-page-item-container"> Restart onboarding
          </div>
        </a>
      </div>
    </div>
    </div>
  </div>
}

export default SupportComponents