import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ToolTipManager from './ToolTipManager.js';
import * as Selectors from 'selectors';

const TOOL_TIP_HEIGHT_OFFSET = 14;
const TOOL_TIP_MARGIN_OFFSET = 10;
/**
 * @deprecated TODO- delete not use any more
 */
class ToolTipManagerContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      toolTipHeight: 0,
      toolTipWidth: 0
    };
  }

  static getTarget(dataId) {
    return document.querySelector(`[data-tooltip-id='${dataId}']`);
  }

  static getParentTarget(className) {
    return document.querySelector(`.${className}`);
  }

  static getIconAttributes(obj) {
    return {
      left: obj.left,
      top: obj.top,
      height: obj.height,
      width: obj.width
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      toolTipHeight: this.ref.clientHeight,
      toolTipWidth: this.ref.clientWidth
    });
  }

  calculateToolTipProps(toolTip) {
    const obj = ToolTipManagerContainer.getTarget(toolTip.get('id'));
    //Todo Dynamic parent Fix!;
    const parentObj = this.props.parent || ToolTipManagerContainer.getParentTarget('control-overlay-wrapper');

    if (!obj) return {
      top: 0,
      left: 0,
      visible: false,
      triangleLeft: 10,
      componentName: '',
      propName: ''
    };

    const objRect = obj.getBoundingClientRect();
    // const parentRect = parentObj.getBoundingClientRect();
    const objActualRect = {
      top: objRect.top,
      width: objRect.width,
      height: objRect.height,
      left: objRect.left
    };

    const componentName = obj.getAttribute('data-component-name');
    const propName = obj.getAttribute('data-tooltip-prop-key-name');
    const iconAttrs = ToolTipManagerContainer.getIconAttributes(objActualRect);
    const title = obj.getAttribute('data-tooltip-title');
    const icon = obj.getAttribute('data-icon');
    const position = obj.getAttribute('data-position');

    let topToolTipPos;
    let leftToolTipPos;
    let triangleTop;
    let triangleLeft;
    if (!position || position === 'top') {
      topToolTipPos = iconAttrs.top - this.state.toolTipHeight - TOOL_TIP_HEIGHT_OFFSET;
      leftToolTipPos = iconAttrs.left + iconAttrs.width / 2 - this.state.toolTipWidth / 2;
      if (iconAttrs.left + iconAttrs.width / 2 - this.state.toolTipWidth / 2 - TOOL_TIP_MARGIN_OFFSET < 0) {
        leftToolTipPos = TOOL_TIP_MARGIN_OFFSET;
      }
      if (iconAttrs.left + iconAttrs.width / 2 + this.state.toolTipWidth / 2 + TOOL_TIP_MARGIN_OFFSET > this.props.windowWidth) {
        leftToolTipPos = this.props.windowWidth - this.state.toolTipWidth - TOOL_TIP_MARGIN_OFFSET;
      }
      triangleLeft = iconAttrs.left - leftToolTipPos - 4 || 0;
    } else if (position === 'left') {
      topToolTipPos = iconAttrs.top;
      leftToolTipPos = iconAttrs.left - this.state.toolTipWidth - TOOL_TIP_HEIGHT_OFFSET;
      triangleTop = iconAttrs.height / 2 - TOOL_TIP_MARGIN_OFFSET / 2;
      triangleLeft = this.state.toolTipWidth - TOOL_TIP_MARGIN_OFFSET;
    } else if (position === 'bottom') {
      topToolTipPos = iconAttrs.top + TOOL_TIP_HEIGHT_OFFSET + iconAttrs.height;
      leftToolTipPos = iconAttrs.left + iconAttrs.width / 2 - this.state.toolTipWidth / 2;
      triangleLeft = iconAttrs.left - leftToolTipPos - 4;
      triangleTop = -TOOL_TIP_HEIGHT_OFFSET;
    } else if (position === 'top-right') {
      topToolTipPos = iconAttrs.top - this.state.toolTipHeight - TOOL_TIP_HEIGHT_OFFSET;
      leftToolTipPos = iconAttrs.left;
      triangleLeft = iconAttrs.left - leftToolTipPos + iconAttrs.width / 2 - TOOL_TIP_HEIGHT_OFFSET;
    }

    return {
      top: topToolTipPos,
      left: leftToolTipPos,
      visible: this.props.toolTipProps.get('visible'),
      triangleTop,
      triangleLeft,
      componentName: componentName,
      propName: propName,
      title: title,
      icon: icon,
    };
  }

  UNSAFE_componentWillUpdate() {
    if (!this.ref) return;

    setTimeout(() => {
      if (!this.ref) return;
      this.setState({
        toolTipHeight: this.ref.clientHeight,
        toolTipWidth: this.ref.clientWidth
      });


      if (!isNaN(this.ref.clientHeight) && !isNaN(this.ref.clientWidth)
        && this.state.toolTipHeight !== this.ref.clientHeight && this.state.toolTipWidth !== this.ref.clientWidth
      ) {
        // TODO: try to fix this (slight height/width variation in certain cases with leades to repeated updates)
        if ((Math.abs(this.state.toolTipHeight - this.ref.clientHeight) > 3) || (Math.abs(this.state.toolTipWidth - this.ref.clientWidth) > 3)) {
          this.setState({
            toolTipHeight: this.ref.clientHeight,
            toolTipWidth: this.ref.clientWidth
          });
        }
      }
    }, 0);
  }

  render() {
    const obj = ToolTipManagerContainer.getTarget(this.props.toolTipProps.get('id'));
    const position = obj && obj.getAttribute('data-position');

    return <ToolTipManager
      parent={this.props.parent}
      position={position}
      key={this.props.toolTipProps.get('id')}
      renderThis={this.props.renderThis}
      toolTipProps={this.calculateToolTipProps(this.props.toolTipProps)}
      cRef={ref => this.ref = ref}/>;
  }
}

function mapStateToProps(state) {
  return {
    toolTipProps: Selectors.Studying.toolTipProps(state),
    windowWidth: Selectors.windowWidth(state),
  };
}


ToolTipManagerContainer.propTypes = {
  toolTipProps: PropTypes.instanceOf(Immutable.Map),
  renderThis: PropTypes.func.isRequired,
  windowWidth: PropTypes.number
};

export default connect(mapStateToProps, {})(ToolTipManagerContainer);
