import Immutable from 'immutable';
import * as Types from 'actions/desk/types';
import { applyReducers } from 'reducers';
import { apiRequest } from 'reducers/api/apiReducer';
import { getUniqueExamPreviewData, getUniqueTopicPreview } from 'helpers';
import { fromJS, Map} from 'immutable';

const flashcardNote = apiRequest(
  Types.GET_NOTE,
  Types.GET_NOTE_SUCCEEDED,
  Types.GET_NOTE_FAILED
);
const deleteFlashcardNote = apiRequest(
  Types.DELETE_NOTE,
  Types.DELETE_NOTE_SUCCEEDED,
  Types.DELETE_NOTE_FAILED
);
const flashcardsWithNotes = apiRequest(
  Types.GET_FLASHCARDS_PREVIEW_WITH_NOTES,
  Types.GET_FLASHCARDS_PREVIEW_WITH_NOTES_SUCCEEDED,
  Types.GET_FLASHCARDS_PREVIEW_WITH_NOTES_FAILED
);
const flashcardsInFolder = apiRequest(
  Types.GET_FLASHCARDS_IN_FOLDER,
  Types.GET_FLASHCARDS_IN_FOLDER_SUCCEEDED,
  Types.GET_FLASHCARDS_IN_FOLDER_FAILED,
  true
);
const getFolders = apiRequest(
  Types.GET_FOLDERS,
  Types.GET_FOLDERS_SUCCEEDED,
  Types.GET_FOLDERS_FAILED
);
const getFolder = apiRequest(
  Types.GET_FOLDER,
  Types.GET_FOLDER_SUCCEEDED,
  Types.GET_FOLDER_FAILED
);
const deleteFolders = apiRequest(
  Types.DELETE_FOLDERS,
  Types.DELETE_FOLDERS_SUCCEEDED,
  Types.DELETE_FOLDERS_FAILED
);

const deleteFlashcardsFromFolder = apiRequest(
  Types.MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER,
  Types.MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER_SUCCEEDED,
  Types.MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER_FAILED
);

const deskReducer = (state = Immutable.fromJS({
  listOfCheckedItems: [],
  // listOfFilterCheckedItems: {flashcard:['Not yet assessed', 'Unconfident', 'Neither', 'Confident' ], revision:['Not yet read', 'Read'], video: ['Not yet watched', 'Partly-watched', 'Watched']},
  listOfFilterCheckedItems: {flashcard:[], revision:[], video: [],exams:[]},
  examsofFilterItems:{paper:[],difficulty:[],source:[],teachingLevel:[],mcq:[]},
  folderFilterListSize:{flashcard:[], revision:[], video: []},
  listOfFilterItemsIds: {flashcard:[], revision:[], video: [], exams: []},
  listOfFilterTitles: {flashcard:[], revision:[], video: []},
  deleteConfirmationMode: false,
  isAssessmentAnimation: false,
  isTextareaFocused: false,
  initializeStatus: '',
  examsQuestionIndex:{data:'',currentIndex:''},
  paperAllQuestionData:[],
  setExamQuestionNumber:null,
  isActiveTeachingTagStatus:false,
  multipleSourceUpdate:null,
  lastMediaTypeNotes: null,
  isActiveMessageLoader:false,
  examTopicStatus:Map({exam:Map({})}),
}), action) => {
  switch (action.type) {
    case Types.INITIALIZE_NOTE:
      return state.set('initializeStatus', 'inProgress');
    case Types.INITIALIZE_NOTE_SUCCEEDED:
      return state.set('initializeStatus', 'succeeded');
    case Types.TOGGLE_FOCUS_TEXTAREA:
      return state.set('isTextareaFocused', action.value);
    case Types.TOGGLE_DELETE_CONFIRMATION_MODE:
      return state.set('deleteConfirmationMode', action.value);
    case Types.SET_ASSESSMENT_ANIMATION:
      return state.set('isAssessmentAnimation', action.value);
    case Types.TEXTAREA_NOTE_CHANGED:
      return state.set('textareaNote', action.value);
    case Types.UPDATE_LIST_OF_ITEMS_SELECTED: {
      var listOfFlashcards = state.get('listOfCheckedItems');
      let indexOfItem = listOfFlashcards.indexOf(action.item);
      let newList;
      if (indexOfItem >= 0) {
        newList = listOfFlashcards.delete(indexOfItem);
        return state.set('listOfCheckedItems', newList);
      } else {
        return state.updateIn(['listOfCheckedItems'], arr => arr.push(action.item));
      }
    }
    case Types.SELECT_ALL_EXERCISES: {
      return state.set('listOfCheckedItems', action.items.map(item => item.get('exerciseId')));
    }
    case Types.SELECT_ALL_FLASHCARDS_WITH_NOTES: {
      let flashcardsWithNotes = state.getIn(['flashcardsWithNotes', 'data', 'items']);
      let flashcardsIds = flashcardsWithNotes.map((item) => item.get('flashcardId'));
      return state.set('listOfCheckedItems', flashcardsIds);
    }
    case Types.SELECT_ALL_FOLDERS: {
      let folders = state.getIn(['getFolders', 'data', 'items']);
      let flashcardsIds = folders.map((item) => item.get('id'));
      return state.set('listOfCheckedItems', flashcardsIds);
    }
    case Types.SELECT_ALL_FLASHCARD_PREVIEWS_FROM_FOLDER: {
      let flashcardsInFolder = state.getIn(['flashcardsInFolder', 'data']);
      let flashcardsIds = flashcardsInFolder.map((item) => item.get('flashcardId'));
      return state.set('listOfCheckedItems', flashcardsIds);
    }
    case Types.DESELECT_ALL_ITEMS: {
      return state.set('listOfCheckedItems', Immutable.List());
    }
    case Types.MULTIPLE_DELETE_NOTE: {
      const checkedItems = state.get('listOfCheckedItems');
      return setDeletedFlashcards(checkedItems, state);
    }
    case Types.DELETE_NOTE: {
      state = state.setIn(['flashcardNote', 'data'], Immutable.Map());
      return setDeletedFlashcards([parseInt(action.flashcardId)], state);
    }
    case Types.DELETE_FOLDERS: {
      const checkedItems = state.get('listOfCheckedItems');
      return setDeletedFolders(checkedItems, state);
    }
    case Types.SET_SMART_FOLDER_TYPE:
      return state.set('smartFolderType', action.folderType);
    case Types.MULTIPLE_DELETE_FLASHCARDS_FROM_FOLDER: {
      const checkedItems = state.get('listOfCheckedItems');
      return setDeletedFlashcardsInFolder(checkedItems, state);
    }
    case Types.CLEAR_FLASHCARDS_IN_FOLDER:
      return state.set('flashcardsInFolder', Immutable.Map());
    case Types.CLEAR_FOLDER:
      return state.set('getFolder', Immutable.Map());
    case Types.UPDATE_FOLDER_STARTED:
      return state.setIn(['getFolder', 'inProgress'], true);
    case Types.UPDATE_FOLDER_SUCCEEDED: {
      return state.setIn(['getFolder', 'data', 'title'], action.data).setIn(['getFolder', 'inProgress'], false);
    }
    case Types.UPDATE_FILTER_LIST_OF_ITEMS_SELECTED: {
        return state.setIn(['listOfFilterCheckedItems', action.mediaType], action.item);
    }
    case Types.UPDATE_EXAMS_PAPER_FILTER_ITEMS:{
      return state.setIn(['examsofFilterItems', action.filterType], action.item);
    }
    case Types.UPDATE_IDS_OF_FILTER_ITEM: {
         return state.setIn(['listOfFilterItemsIds', action.mediaType], (action.item));
    }
    case Types.FOLDER_FILTER_LIST_SIZE: {
        return state.setIn(['folderFilterListSize', action.mediaType], action.item);
    }
    case Types.UPDATE_LIST_OF_FILTERED_TITLES: {
      return state.setIn(['listOfFilterTitles', action.mediaType], action.item);
    }
    case Types.UPDATE_ANSWER_BY_FLASHCARD_ID:
    {
      var itemObject = state.getIn(['listOfFilterItemsIds', 'flashcard',action.data.index]);
      let newObject = {...itemObject, answers: action.data.answer}
      return state.setIn(['listOfFilterItemsIds', 'flashcard',  action.data.index], newObject);
    }
    case Types.UPDATE_EXAMS_QUESTION_INDEX:{
      return state.set('examsQuestionIndex', fromJS({data:action.data, currentIndex:action.index}))
    }
    case Types.PAPER_ALL_QUESTION_DATA:{
      return state.set('paperAllQuestionData',fromJS(action.data))
    }
    case Types.QUESTION_IDS_UPDATE_STATS:{
      const prevData = state.get('paperAllQuestionData');
      const currentIndex = prevData.toJS().findIndex((item) => item.uuid === action.id);
      let itemObject = state.getIn(['paperAllQuestionData', currentIndex])
      if (itemObject.size > 0) {
        itemObject = itemObject.toJS()
      }
      let newObject
      if(action.answer === -1){
        newObject = {...itemObject, 'user_response':{'answered' : false , 'correct' : 0}} 
      }else{
        newObject = {...itemObject, 'user_response':{'answered' : true , 'correct' : action.answer}} 
      }
      return state.setIn(['paperAllQuestionData',  currentIndex], newObject);
    }
    case Types.UPDATE_EXAM_FILTER_DATA_FROM_TOPICS:{
      const prevData = state.getIn(['listOfFilterItemsIds', 'exams']);

      if(prevData.size != 0){
        //const currentIndex = prevData.findIndex((item) => item.uuId === action.id);
        const itemObj = prevData.find((item) => item.uuId === action.id);
        //let itemObject = state.getIn(['listOfFilterItemsIds', 'exams'], currentIndex);
        prevData.map((item) => {
          if(item.uuId === action.id){
            if(action.answer == -1){
              return {...itemObj, 'answers':{'user_response':{'answered' : false , 'correct' : 0}}}  
            }else{
              return {...itemObj, 'answers':{'user_response':{'answered' : true , 'correct' : action.answer}}}
            
            }
          }
          return item;
        })
        // let newObject
        // if(action.answer == -1){
        //   newObject = {...itemObject, 'answers':{'user_response':{'answered' : false , 'correct' : 0}}}
        // }else{
        //   newObject = {...itemObject, 'answers':{'user_response':{'answered' : true , 'correct' : action.answer}}}
        // }
        return state.setIn(['listOfFilterItemsIds', 'exams'], (prevData));
      }
      return state; 
    }
    case Types.UPDATE_EXAM_QUESTION_BY_FILTER:{
      let examData =  state.getIn(['listOfFilterItemsIds', 'exams']) || [];
        let finalExamData;
        if(examData?.size > 0 || examData?.length > 0){
            examData.map(data => {
              action.data.response = action.data.response?.filter(res => res.flashcarduuId !== data.flashcarduuId)
            })
          finalExamData = examData.concat( action.data.response);
          finalExamData = finalExamData.sort((a,b) =>{
            if (parseInt(a?.topicNumberingWithoutDecimal) > parseInt(b?.topicNumberingWithoutDecimal)) {
              return 1;
            }
            if (parseInt(a?.topicNumberingWithoutDecimal) < parseInt(b?.topicNumberingWithoutDecimal)) {
              return -1;
            }
            // a must be equal to b
            return 0;
          })
        }else{
          finalExamData = getUniqueTopicPreview(action.data.response);
        }
        // let orderSequence = [false, true]
        // finalExamData.sort((a, b) => {
        //   const indexA = orderSequence.indexOf(a['isTopic']);
        //   const indexB = orderSequence.indexOf(b['isTopic']);
        //   if (indexA < indexB) return -1;
        //   return 1;
        // });
        // finalExamData = getUniqueExamPreviewData(finalExamData)
        finalExamData.map((item,index) => {
            item.index = index
        });
        return state.setIn(['listOfFilterItemsIds', 'exams'], (finalExamData));
    }
    case Types.EXAM_QUESTION_NUMBER:{
      return state.set('setExamQuestionNumber',action.data)
    }
    case Types.SET_ACTIVE_TEACHING_TAG_STATUS:{
      return state.set("isActiveTeachingTagStatus", action.data)
    }
    case Types.SET_ACTIVE_MESSAGE_LOADER:
      return state.set('isActiveMessageLoader',action.data)
    case Types.MULTIPLE_SOURCE_UPDATE:
      return state.set('multipleSourceUpdate',action.data)
    case Types.SET_LAST_MEDIA_TYPE_NOTES:
      return state.set('lastMediaTypeNotes', action.data)
    case  Types.SET_EXAM_TOPIC_STATUS:
      const status =  state.get('examTopicStatus').withMutations(data => {
        action.data.forEach(node => {
            data.set(node.id, fromJS({exam:node.exam}));
        });
        // return state;
        return data;
      });
      return state.set('examTopicStatus',status)
    default:
      return applyReducers(state, action, {
        flashcardNote,
        deleteFlashcardNote,
        flashcardsWithNotes,
        getFolders,
        flashcardsInFolder,
        getFolder,
        deleteFolders,
        deleteFlashcardsFromFolder
      });
  }
};
export default deskReducer;
function setDeletedFlashcards(ids, state) {
  if (!state.getIn(['flashcardsWithNotes', 'data'])) return state;
  return state.updateIn(['flashcardsWithNotes', 'data', 'items'], (items => items.map((item) => {
    if (ids.indexOf(item.get('flashcardId')) === -1) return item;
    return item.set('deleted', true);
  })));
}

function setDeletedFolders(ids, state) {
  return state.updateIn(['getFolders', 'data', 'items'], (items => items.map((item) => {
    if (ids.indexOf(item.get('id')) === -1) return item;
    return item.set('deleted', true);
  })));
}

function setDeletedFlashcardsInFolder(ids, state) {
  return state.updateIn(['flashcardsInFolder', 'data'], (items => items.map((item) => {
    if (ids.indexOf(item.get('flashcardId')) === -1) return item;
    return item.set('deleted', true);
  })));
}
