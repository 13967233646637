import classNames from 'classnames';
import React from 'react';
import './button.scss';

export enum ThemeEnum{
  Blue = 'blue',
  White = 'white'
}
type ButtonProps = {
    text: string|React.ReactElement; 
    type?: 'button'|'submit'|'reset'; 
    onClick: () => void;
    btnTheme: ThemeEnum;
    className?: string;
    icon?: React.ReactNode
}  

const Button: React.FC<ButtonProps> = ({text, type="button", onClick, btnTheme, className, icon}) => {
  return (
    <button type={type} className={classNames(className,{'blue-osc-btn': btnTheme === ThemeEnum.Blue},{'white-osc-btn': btnTheme === ThemeEnum.White})} onClick={onClick}>
      {icon && icon}
      {text}
    </button>
  )
}

export default Button;