import React, {Component} from 'react';
import StoreBasket from './StoreBasket';
import PaymentView from './PaymentView';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Actions from 'actions';
import * as Selectors from 'selectors';

class StoreBasketContainer extends Component {
  render() {
    if (this.props.isPaymentView) return (<PaymentView {...this.props}/>);
    return (<StoreBasket {...this.props}/>);
  }
}

StoreBasketContainer.propTypes = {
  isPaymentView: PropTypes.bool
};

const mapDispatchToProps = {
  changeAmount: Actions.UserStore.changeAmount,
  togglePaymentView: Actions.UserStore.togglePayment,
  // registerPurchase: Actions.Api.registerPurchase
};

function mapStateToProps(state) {
  return {
    isPaymentView: Selectors.isPaymentView(state)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreBasketContainer);

