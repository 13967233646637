import React from 'react'
import MediaTypeStatusBar from 'v2/components/media-tab/MediaTypeStatusBar';
import {returnFinalExerciseStatus} from 'v2/helpers';
import moment from 'moment';
import { Separator } from 'v2/ui-components/pageComponents'
import ExternalAvatar from 'v2/components/avatar/ExternalAvatar'
import Text from 'v2/components/common/Text'
import {DueDate, NrOfCards} from 'ui-components/exerciseComponents';
import { useSelector } from 'react-redux';
import { exerciseStatusById } from 'selectors/statusSelector';
type ExerciseHeaderProps = {
    data: any;
    className?: string; 
    type?: string;
}  

const ExerciseHeader: React.FC<ExerciseHeaderProps> = ({data, className, type}) => {
    // const {flashcardStatus, flashcardStatusGraph} = returnFinalExerciseStatus(data?.answerData?.flashcardBox);
    const getExerciseStatusById = useSelector(state => exerciseStatusById(state, data?.exerciseId))
    const {flashcardStatus, flashcardStatusGraph} = returnFinalExerciseStatus(getExerciseStatusById.toJS().flashcard);
    let dueDateUnix, dueDate, isPastDueDate = false;
    const createdAt = moment.unix(data?.createdAt).format('MMM D, Y');
    const currentTime = moment().unix();
    const comment = data?.comment;
    if (data?.dueDate) {
        dueDateUnix = moment.unix(data.dueDate);
        dueDate = dueDateUnix.format('MMM D, Y hh:mm A');
        isPastDueDate = currentTime > dueDateUnix.unix();
    }
    return <>
        <div className='text-[25px] font-bold leading-[34px] text-#2D3838 pt-1.5 pb-3.5' dangerouslySetInnerHTML={{__html: data?.title}}/>
        <MediaTypeStatusBar page='exercise' number={flashcardStatus.total} title={'Flashcards'} mediaType={"flashcard"} mediaStatusData={flashcardStatusGraph} isRead={flashcardStatus.total === flashcardStatus.correct} isMargin/>
        {comment && 
            <>
                <div className='rounded-10px bg-#EFF4F9 mt-5 px-3.5 py-2.5'>
                    <div className='text-15px leading-19px text-#2D3838'>{data?.comment}</div>
                </div>
            </>
        }
        <Separator className='mt-4' />
        <div className='pt-2.5 pb-2.5'>
            <div className='flex items-center'>
                {data?.mbTeacherId &&
                    <div className='pr-2.5'>
                        <ExternalAvatar size={34} userId={data.mbTeacherId} userName={data.addedBy}/>
                    </div>
                }
                <div className='flex flex-col justify-between'>
                    <Text className='text-13px leading-4 text-#979999'><>Added
                        by <strong>{data?.addedBy}</strong> on {createdAt}</>
                    </Text>
                    <div className='flex mt-[2px]'>
                        {type === 'internal' && 
                            <>
                                <DueDate passed={isPastDueDate}>{dueDate ? `Due on ${dueDate}` : '—'}</DueDate>
                                <div className='pr-3.5' />
                            </>
                        }
                        <NrOfCards>{data?.flashcardCount}</NrOfCards>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ExerciseHeader;