import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import ProfilePicture from '../profile-picture/ProfilePicture';
import './avatar.scss';

export default class AvatarComponent extends PureComponent {
  renderProfilePictureWithInitials() {
    const {initials, className, newUserInitials} = this.props;
    return <ProfilePicture className={`avatar text ${className}`} source={newUserInitials ? newUserInitials : initials} isImage={false}/>;
  }

  render() {
    return this.renderProfilePictureWithInitials();
  }
}

AvatarComponent.propTypes = {
  newUserInitials: PropTypes.string,
  initials: PropTypes.string.isRequired,
  className: PropTypes.string
};
