import classNames from 'classnames';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMultipleSourceUpdate, examsofFilterItems, isMainMicroFilterVisible, isSourceOverlayVisible, isPaperOverlayVisible, subjectsTeachingLevel, getActiveSubject, isTeachingLevelExamsOverlayVisible, isDiffficultyOverlayVisible } from 'selectors';
import { IBIcon, PmjIcon } from "icons/HeaderIcons"
import Text from 'v2/components/common/Text';
import { ArrowdownIcon, FilterClearIcon } from 'icons/HeaderIcons';
import './examFilter.scss'
import { toggleSourceOverlay, mainMicroFilterBack, toggleMainMicroOverlay, togglePaperOverlay, toggleDifficultyOverlay, toggleTeachingLevelExamsOverlay } from 'actions/navigation'
import { updateExamsPaperFilterItems } from 'actions/desk'
import { setExamFilterData } from 'actions/api';
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';
import DifficultyText from './DifficultyText';

type ExamFilterLayoutProps = {
  type?: "popup" | 'filter';
  examsMode?: boolean;
  isEditMode?: boolean;
  formCC?: boolean;
  examsTypesMode?: boolean

}
const ExamFilterLayout: React.FC<ExamFilterLayoutProps> = ({ type, examsMode = false, isEditMode = false, formCC = false, examsTypesMode = false }) => {
  const dispatch = useDispatch();
  const hasMultipleSourceUpdate = useSelector(getMultipleSourceUpdate);
  const hasMainMicroFilterVisible = useSelector(isMainMicroFilterVisible)
  const data = useSelector(examsofFilterItems).toJS();
  const hasSourceOverlayVisible = useSelector(isSourceOverlayVisible);
  const hasPaperOverlayVisible = useSelector(isPaperOverlayVisible);
  const hasDiffficultyOverlayVisible = useSelector(isDiffficultyOverlayVisible)
  const activeSubject = useSelector(getActiveSubject);
  const teachingLevel = useSelector(subjectsTeachingLevel);
  const teachingExamsOverlay = useSelector(isTeachingLevelExamsOverlayVisible)

  const teachingTag = teachingLevel[`${activeSubject.id}`];

  const sourceIcon = useCallback((value: string) => {
    let val;
    if (value.includes("OSC")) {
      val = 'OSC'
    } else if (value.includes("IB")) {
      val = 'IB'
    }
    switch (val) {
      case 'OSC':
        return <div className='flex items-center py-9px pr-1 pl-4 [&_svg]:my-2 [&_svg]:mr-1.5 ' ><PmjIcon /><Text className='text-13px font-bold text-blue-normal leading-18px pr-1.5 active-filter-text'>Pamoja</Text></div>
      case 'IB':
        return <div className='flex items-center py-9px pr-1 pl-4 [&_svg]:my-2 [&_svg]:mr-1.5' ><IBIcon /><Text className='text-13px font-bold  text-blue-normal leading-18px pr-1.5 active-filter-text' >Official IB</Text></div>
      default:
        return <div className='flex items-center py-9px pr-1 pl-4 [&_svg]:my-2 [&_svg]:mr-1.5' ><PmjIcon /><Text className='text-13px font-bold text-blue-normal leading-18px pr-1.5 active-filter-text' >Pamoja</Text></div>
    }
  }, [])

  function setFormCCPopoup() {
    if (formCC) {
      dispatch(mainMicroFilterBack(true, true));
    } else {
      dispatch(mainMicroFilterBack(false, false));
    }
  }
  const clearFilter = (e: React.MouseEvent<HTMLElement>, val: string) => {
    e.stopPropagation();
    dispatch(updateExamsPaperFilterItems([], val));
    dispatch(setExamFilterData())
  }

  function openSourceFilter(e: React.MouseEvent<HTMLElement>) {
    dispatch(toggleSourceOverlay(true, true));
    setFormCCPopoup()
    e.stopPropagation();
    if (hasMainMicroFilterVisible) return dispatch(toggleMainMicroOverlay(false, false));
  }
  function openTeachingLeve(e: React.MouseEvent<HTMLElement>) {
    dispatch(toggleTeachingLevelExamsOverlay(true, true));
    setFormCCPopoup()
    e.stopPropagation();
    if (hasMainMicroFilterVisible) return dispatch(toggleMainMicroOverlay(false, false));
}
  function openPaperFilter(e: React.MouseEvent<HTMLElement>) {
    dispatch(togglePaperOverlay(true, true));
    setFormCCPopoup()
    e.stopPropagation();
    if (hasMainMicroFilterVisible) return dispatch(toggleMainMicroOverlay(false, false));
  }
  function openDifficultyFilter(e: React.MouseEvent<HTMLElement>) {
    dispatch(toggleDifficultyOverlay(true, true));
    setFormCCPopoup()
    e.stopPropagation();
    if (hasMainMicroFilterVisible) return dispatch(toggleMainMicroOverlay(false, false));
}

  return <div className={classNames('relative', { 'mb-4': !formCC })}>
    {!examsTypesMode && <div className='flex items-center'>
      {hasMultipleSourceUpdate && <>
        {data['source'].length > 0 && data['source'].length < 2 ?
          <div className='relative w-fit'>
            <div onClick={(e: React.MouseEvent<HTMLElement>) => openSourceFilter(e)} className={classNames('exam-filter !shadow-#378EF8 !bg-#E6EEF7', { '!opacity-50 !select-none !pointer-events-none': isEditMode })}>
              {sourceIcon(data['source'])}
              <div className="closeButton1" />
            </div>
            <div className={classNames('filter-clear', { '!opacity-50 !select-none !pointer-events-none': isEditMode })} onClick={(e: React.MouseEvent<HTMLElement>) => clearFilter(e, 'source')}> <FilterClearIcon /></div>
          </div>
          : <div className='relative w-fit'>
            <div className={classNames('exam-filter', { '!opacity-50 !select-none !pointer-events-none': isEditMode, '[&_svg]:rotate-180 !shadow-#378EF8': hasSourceOverlayVisible })} onClick={(e: React.MouseEvent<HTMLElement>) => openSourceFilter(e)}>
              <Text  className='text-#2D3838 leading-18px filter-text text-13px font-bold'>Source</Text>
              <ArrowdownIcon />
            </div>
          </div>}
      </>
      }
      {data['paper'].length > 0 && data['paper'].length < 3 ?
        <div className='relative w-fit'>
          <div onClick={(e: React.MouseEvent<HTMLElement>) => openPaperFilter(e)} className={classNames('exam-filter !shadow-#378EF8 !bg-#E6EEF7', { '!opacity-50 !select-none !pointer-events-none': isEditMode, 'ml-2': hasMultipleSourceUpdate })}>
            <Text  className='text-blue-normal leading-18px filter-text text-13px font-bold'>{data['paper'][0]}</Text>
            {data['paper'].length > 1 && <div className='font-bold leading-18px text-13px text-blue-normal pr-1' >&nbsp;+ {data['paper'].length - 1}</div>}
            <div className="closeButton1" />
          </div>
          <div className={classNames('filter-clear', { '!opacity-50 !select-none !pointer-events-none': isEditMode })} onClick={(e: React.MouseEvent<HTMLElement>) => clearFilter(e, 'paper')}> <FilterClearIcon /></div>
        </div> :
        <div onClick={(e: React.MouseEvent<HTMLElement>) => openPaperFilter(e)} className={classNames('exam-filter', { '!opacity-50 !select-none !pointer-events-none': isEditMode, 'ml-2': hasMultipleSourceUpdate, ' [&_svg]:rotate-180 !shadow-#378EF8': hasPaperOverlayVisible })}>
          <Text className='text-#2D3838 leading-18px filter-text text-13px font-bold' >Paper</Text>
          <ArrowdownIcon />
        </div>}

    </div>}
    <div className={classNames('flex items-center', { 'pt-2': !examsTypesMode })}>
      {data['teachingLevel'].length > 0 && data['teachingLevel'].length < 2 ?
        <div className='relative w-fit'>
          <div onClick={(e: React.MouseEvent<HTMLElement>) => openTeachingLeve(e)} className={classNames('exam-filter !shadow-#378EF8 !bg-#E6EEF7', { '!opacity-50 !select-none !pointer-events-none': isEditMode || teachingTag === "SL" ? true : false })}>
            <div className='flex items-center py-9px pr-1 pl-4'>
              <TeachingLevelSeperator tlvl={data['teachingLevel'][0]} tlvlTag={data['teachingLevel'][0]} isExams={false} />
            </div>

            <div className="closeButton1" />
          </div>
          <div className={classNames('filter-clear', { '!opacity-50 !select-none !pointer-events-none': isEditMode })} onClick={(e: React.MouseEvent<HTMLElement>) => clearFilter(e, 'teachingLevel')}> <FilterClearIcon /></div>
        </div> :
        <div onClick={(e: React.MouseEvent<HTMLElement>) => openTeachingLeve(e)} className={classNames('exam-filter', { '!opacity-50 !select-none !pointer-events-none': isEditMode || teachingTag === "SL" ? true : false, '[&_svg]:rotate-180 !shadow-#378EF8': teachingExamsOverlay })}>
          <Text className='text-#2D3838 leading-18px filter-text text-13px font-bold'>Teaching level</Text>
          <ArrowdownIcon />
        </div>}
      {!examsTypesMode && <>
        {data['difficulty'].length > 0 && data['difficulty'].length < 3 ?
          <div className='relative w-fit'>
            <div onClick={(e: React.MouseEvent<HTMLElement>) => openDifficultyFilter(e)} className={classNames('exam-filter !shadow-#378EF8 !bg-#E6EEF7 ml-2', { '!opacity-50 !select-none !pointer-events-none': isEditMode })}>
              {/* {assesmentIcon(data['difficulty'])} */}
              <DifficultyText formCC={formCC} data={data} />
              {data['difficulty'].length > 1 && <div className='font-bold leading-18px text-13px text-blue-normal pr-1' >&nbsp;+ {data['difficulty'].length - 1}</div>}
              <div className="closeButton1" />

            </div>
            <div className={classNames('filter-clear', { '!opacity-50 !select-none !pointer-events-none': isEditMode || examsMode})} onClick={(e: React.MouseEvent<HTMLElement>) => clearFilter(e, 'difficulty')}> <FilterClearIcon /></div>
          </div> :
          <div onClick={(e: React.MouseEvent<HTMLElement>) => openDifficultyFilter(e)} className={classNames('exam-filter ml-2', { '!opacity-50 !select-none !pointer-events-none': isEditMode || examsMode, ' [&_svg]:rotate-180 !shadow-#378EF8': hasDiffficultyOverlayVisible })}>
            <Text className='text-#2D3838 leading-18px filter-text text-13px font-bold'>Difficulty</Text>
            <ArrowdownIcon />
            </div>}
      </>}
    </div>
  </div>
}


export default ExamFilterLayout;