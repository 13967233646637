import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { subjectsTeachingLevel, getActiveSubject, examsofFilterItems, listOfFilterCheckedItems } from 'selectors';
import { sourceFilter, paperFilter, difficultyFilter, topicTeachingFilter, returnExamAnswerFilter, getUniqueTopicPreview, teachingFilterResultsListToArray, transformResultsListToArray, questionTypeFind, filterResultsListToArray } from 'v2/helpers'
import { setFilterItemSize, setFirstNode, setFirstTopicId, setFirstSubtopicId, macroFilterSize, setUpdateExamsPreviewList } from 'actions/navigation'

const useTopicData = <T>(resultData: any, activeMediaTab: string): { finalData: T, filterStatus: boolean } => {
  const dispatch = useDispatch()
  const activeSubject = useSelector(getActiveSubject);
  const hasSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
  const examsofFilterItemsToJS = useSelector(examsofFilterItems).toJS();
  const hasListOfFilterCheckedItems = useSelector(listOfFilterCheckedItems).toJS()

  const teachingTag = hasSubjectsTeachingLevel[`${activeSubject.id}`]
  const requestData = resultData && teachingFilterResultsListToArray(resultData, teachingTag, activeSubject);
  const data = requestData && sourceFilter(requestData, examsofFilterItemsToJS.source, activeMediaTab);
  const paperFilterData = requestData && paperFilter(data, examsofFilterItemsToJS.paper, activeMediaTab);
  const difficultyData = requestData && difficultyFilter(paperFilterData, examsofFilterItemsToJS.difficulty, activeMediaTab)
  const teachingTagData = requestData && topicTeachingFilter(teachingTag, difficultyData, examsofFilterItemsToJS.teachingLevel, "topics", activeMediaTab)

  const multipleChooseData = activeMediaTab === 'exams' && requestData && questionTypeFind(teachingTagData) && questionTypeFind(teachingTagData)?.mcq;
  const freeResponseData = activeMediaTab === 'exams' && requestData && questionTypeFind(teachingTagData) && questionTypeFind(teachingTagData)?.group;

  const examAnswerFilter = activeMediaTab === 'exams' && requestData && returnExamAnswerFilter(multipleChooseData, freeResponseData, examsofFilterItemsToJS.mcq, hasListOfFilterCheckedItems[activeMediaTab], activeMediaTab, teachingTagData);
  const uiqueExamData = requestData && (activeMediaTab === 'exams' && getUniqueTopicPreview(examAnswerFilter));
  const newFiltertems = requestData && filterResultsListToArray(activeMediaTab === 'exams' ? uiqueExamData : requestData, activeMediaTab, hasListOfFilterCheckedItems[activeMediaTab])

  const firstFlashcard = (newFiltertems && newFiltertems.length) && (activeMediaTab === 'flashcard' || activeMediaTab === 'exams' ? requestData && requestData.length > 0 && newFiltertems[0]?.flashcardId : requestData.length > 0 && newFiltertems[0]?.id)
  const firstTopicId = (newFiltertems && newFiltertems.length) && (activeMediaTab === 'flashcard' || activeMediaTab === 'exams' ? requestData && requestData.length > 0 && newFiltertems[0]?.topicId : requestData.length > 0 && newFiltertems[0]?.id)
  const firstSubtopicId = (newFiltertems && newFiltertems.length) && (activeMediaTab === 'flashcard' || activeMediaTab === 'exams' ? requestData && requestData.length > 0 && newFiltertems[0]?.subTopicId : requestData.length > 0 && newFiltertems[0]?.id)

  const resultSize = resultData && resultData.length
  const filterStatus = resultSize === 0 ? true : hasListOfFilterCheckedItems[activeMediaTab] === 0 ? true : false;
  const newListItems:any = newFiltertems ? transformResultsListToArray(newFiltertems) : [];

  useEffect(() => {
    newFiltertems && dispatch(setFilterItemSize(newFiltertems.length))
    requestData && dispatch(setFirstNode(firstFlashcard))
    requestData && dispatch(setFirstTopicId(firstTopicId))
    requestData && dispatch(setFirstSubtopicId(firstSubtopicId))
    requestData && dispatch(macroFilterSize(requestData.length))
    newFiltertems && dispatch(setUpdateExamsPreviewList(newFiltertems))
  }, [newFiltertems])
  return {finalData:newListItems, filterStatus}
}

export default useTopicData;