import React from 'react'
import './filter.scss'
import classNames from 'classnames';
import BackButton from 'v2/components/navigation-header/BackButton'
import { ReactComponent as CloseIcon } from 'assets/icons/page-overlay/close-icon-turquoise.svg';

type FilterHeaderProps = {
    text?: string;
    fromCC?: boolean;
    onBackClick?: () => void;
    hideArrow: boolean;
    title: string;
    onCloseClick: () => void;
}

const FilterHeader: React.FC<FilterHeaderProps> = ({ text, title, fromCC, hideArrow, onBackClick, onCloseClick }) => {
    return <div className={classNames('flex items-center justify-between pt-15px pr-3.5 pb-5px', { 'pl-3': fromCC }, { 'pl-5': !fromCC })}>
        <div className='flex'>
            {fromCC &&
                <BackButton onClick={()=> onBackClick?.()} text={text} hideArrow={hideArrow} />
            }
            <div className={classNames('text-19px font-bold leading-26px text-#2D3838', { 'ml-[23px]': fromCC })}>{title}</div>
        </div>
        <div className='p-1.5 filter-close-icon-wrapper rounded-full cursor-pointer' onClick={() => onCloseClick()}>
            <div className='flex items-center justify-center w-5 h-5 rounded-full bg-blue-normal'>
                <CloseIcon />
            </div>
        </div>
    </div>
}

export default FilterHeader;