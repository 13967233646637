import classNames from 'classnames';
import checkboxIcon from 'assets/icons/checkbox.svg';
import './checkRadioButton.scss'
type CheckRadioButtonProps = {
  itemId: string | number,
  listOfCheckedItems: any,
  isWidth?: boolean,
  isExams?: boolean,
  isDisabled?: boolean
}

const CheckRadioButton: React.FC<CheckRadioButtonProps> = ({ listOfCheckedItems, itemId, isWidth, isExams, isDisabled }) => {
  return <div className={classNames('inline-flex self-center cursor-pointer w-[34px] h-[34px] justify-center items-center ', {'w-18px':isWidth, 'h-5': isExams, 'cursor-default' : isDisabled})}>
    {isDisabled || listOfCheckedItems?.indexOf(itemId) > -1 ?
      <div className={classNames('default-checkbox !border-0', { 'bg-#E3E6E6': isDisabled , 'active-checkbox': !isDisabled})}>
        <div className='flex items-center justify-center h-full'>
          <img src={checkboxIcon} />
        </div>
      </div>
      : <div className='default-checkbox' />}
  </div>
}


export default CheckRadioButton