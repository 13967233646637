import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MediaTabContainer from './MediaTabContainer'
import { setLastMediaTypeVisited, setFormExams, setExamTopicId, storeExamsParentId, setExamSubTopicId, getFirstQuestionIdBySubjectId, getLastMediaTypeVisited } from 'helpers';
import {quickStatusById} from 'selectors/quickSelectors';
import {firstQuestionIdBySubject} from 'selectors/api/apiSelector'
import {structureLoading} from 'selectors/navigation/navigationSelector'
import MediaQuickStudyButton from 'v2/components/media-tab/MediaQuickStudyButton'
import { getActiveMediaTab, flashcardIdsResponse, getListOfFilterItemsIds } from 'selectors' 
import {setActiveTeachingLevel} from 'actions/subject';
import {subjectsTeachingLevel} from 'selectors/subject/subjectSelector'
import {currentFlashcardId, getLastStudyFlashcardSelector, inProgressLastStudyFlashcardSelector} from 'selectors/studying'
import {changeFlashcard, getLastStudyFlashcard, selectFlashcard, setActiveExerciseId} from 'actions/studying';
import {CardButtonIcon, PageButtonIcon, VedioButtonIcon,ExamButtonIcon } from 'icons/MediaIcons';
import {carouselFlashcardIds, carouselRevisionIds, carouselVideoIds, carouselFlashcardIdDetails, carouselRevisiondIdDetails, carouselVideoIdDetails} from 'selectors/carouselSelectors';
import {getUserId} from 'selectors/user';
import {setExerciseOnCanvas, setExamsHeaderList} from 'actions/navigation/navigationActions'
import useMediaStatus from 'hooks/useMediaStatus';

type QuickStartProps = {
    subject?: any;
} 

const QuickStart: React.FC<QuickStartProps> = ({subject}) => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true)
    const [isLastStudyLoading, setLastStudyLoading] = useState(false);
    const [isMediaLoading, setMediaLoading] = useState(false);
    const mediaQuickStatus = useSelector((state) => quickStatusById(state, subject.id))?.toJS();
    const activeMediaTab = useSelector(getActiveMediaTab);
    const mediaFilterItem = useSelector(getListOfFilterItemsIds)?.toJS();
    const flashcardData = useSelector(flashcardIdsResponse)?.toJS();
    const userId = useSelector(getUserId);
    const firstQuestion = useSelector(firstQuestionIdBySubject)?.toJS();
    const subjectTvl = useSelector(subjectsTeachingLevel);
    const lastStudyData = useSelector(getLastStudyFlashcardSelector)
    const currentMediaId = useSelector(currentFlashcardId)
    const hasStructureLoading = useSelector(structureLoading)
    const hasInprogressLastStudy = useSelector(inProgressLastStudyFlashcardSelector)
    const flashcardIdsData = useSelector(carouselFlashcardIdDetails);
    const revisionIdsData = useSelector(carouselRevisiondIdDetails)
    const videoIdsData = useSelector(carouselVideoIdDetails)
    const flashcardIds = useSelector(carouselFlashcardIds)
    const revisionIds = useSelector(carouselRevisionIds)
    const videoIds = useSelector(carouselVideoIds)
    const { setActiveMediaType } = useMediaStatus()
    const lastMediaType: string = getLastMediaTypeVisited(userId);
    
    const statusData = {
        flashcard: mediaQuickStatus.flashcard || {},
        revision: mediaQuickStatus.revision || {},
        video: mediaQuickStatus.video || {},
        exams:  mediaQuickStatus.exam?.freeResponse || {},
        mcq:  mediaQuickStatus.exam?.msqTotal || {},
        mcqTotal: 1,
        freeResponseTotal: 1
    }

    const mediaData: any = {
        flashcard: {
            title: 'Card 1',
            icon: <CardButtonIcon />,
        },
        revision: {
            title: 'Page 1',
            icon: <PageButtonIcon />,
        },
        video: {
            title: 'Video 1',
            icon: <VedioButtonIcon />,
        },
        exams: {
            title: 'Question 1',
            icon: <ExamButtonIcon />,
        },
    }

    useEffect(() => {
        setActiveMediaType();
    }, [setActiveMediaType])
    
    useEffect(() => {
        setLoading(hasStructureLoading)
    }, [hasStructureLoading])

    useEffect(() => {
        if ((activeMediaTab === "flashcard" && flashcardIds?.length === 0) ||
            (activeMediaTab === "revision" && revisionIds?.length === 0) ||
            (activeMediaTab === "video" && videoIds?.length === 0) ||
            hasInprogressLastStudy
            ) {
            setLastStudyLoading(true)
        }
        else {
            setLastStudyLoading(false)
        }
    }, [activeMediaTab, flashcardIds?.length, revisionIds?.length, videoIds?.length, hasInprogressLastStudy])

    useEffect(() => {
        dispatch(getLastStudyFlashcard());
    }, [currentMediaId, dispatch]);

    useEffect(() => {
        let topicId;
        switch(activeMediaTab){
            case 'flashcard':
                topicId = flashcardIdsData?.items?.[0]?.topicId
                break;
            case 'revision':
                topicId = revisionIdsData?.items?.[0]?.topicId
                break;
            case 'video':
                topicId = videoIdsData?.items?.[0]?.topicId
                break;
            default:
                break;
        }
        if (typeof topicId == 'undefined' || topicId == null) {
            setMediaLoading(true)
            setTimeout(() => {
                setMediaLoading(false)
            }, 3000);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subject, activeMediaTab, subjectTvl[subject.id]])

    const hasStudiedBefore = useCallback((activeMediaType : string) => {
        const slug = subject.slug;
        return lastStudyData && lastStudyData[slug]?.[activeMediaType]?.[subjectTvl[subject.id]];
    },[lastStudyData, subject.id, subject.slug, subjectTvl])

    const selectFirstCard = (mediaType: string) => {
        const items = flashcardData?.data?.items ?? [];
        const topicId = items?.[0]?.topicId;
        const firstId = mediaFilterItem?.[mediaType]?.[0]
        const subTopicId = firstQuestion?.[topicId]?.data?.subTopicId;
        dispatch(setExerciseOnCanvas(false));
        dispatch(setActiveExerciseId(null));
        setLastMediaTypeVisited(mediaType, userId);
        setTimeout(() => {
            dispatch(setActiveTeachingLevel(subject.id))
            if(mediaType==='exams'){
                setExamHelper(topicId, subTopicId)
                dispatch(selectFlashcard(getFirstQuestionIdBySubjectId()))
            }else{
                dispatch(selectFlashcard(firstId?.flashcardId))
            }
        }, 10)
    }

    const selectStudiedFlashcard = (mediaType: string) => {
        dispatch(setExerciseOnCanvas(false))
        dispatch(setActiveExerciseId(null))
        setLastMediaTypeVisited(mediaType, userId);
        const lastStudyMediaId = hasStudiedBefore(mediaType).id ;
        if (!lastStudyMediaId) return;
        dispatch(setActiveTeachingLevel(subject.id))
        if(mediaType === 'exams'){
            const topicId = hasStudiedBefore(mediaType).topic
            const subTopicId = hasStudiedBefore(mediaType).sub_topic
            setExamHelper(topicId, subTopicId)
        }
        dispatch(selectFlashcard(lastStudyMediaId))
        dispatch(changeFlashcard(lastStudyMediaId));
    }

    const setExamHelper = (topicId: number, subTopicId: number) => { 
        setFormExams('topics')
        setExamTopicId(topicId)
        storeExamsParentId(topicId)
        setExamSubTopicId(subTopicId)
        setExamsHeaderList({"type":'topics',"parentId":topicId})
    }

    const isLastStudyCard = useCallback((idsData : any) => {
        const hasStudyBefores = hasStudiedBefore(activeMediaTab);
        let lastStudyFilterId;
        lastStudyFilterId = idsData.filter((val: number) => val === hasStudyBefores.id)
        if(lastStudyFilterId?.length === 0) return true;
    },[activeMediaTab, hasStudiedBefore])
    const checkTvl = (mediaType: string) => {
        if(mediaType === "flashcard"){
            return isLastStudyCard(flashcardIds)
        }
        if(mediaType === "video"){
            return isLastStudyCard(videoIds)
        }
        if(mediaType === "revision"){
            return isLastStudyCard(revisionIds)
        }
    }
    const checkCardType = (mediaType: string) => {
        if(mediaType === lastMediaType){
            return true
        }
    }
    
    return <>
        <MediaTabContainer isMargin statusData={statusData} type='quickStart'/>
        <div className='pt-3.5 pb-2 px-5'>
            <MediaQuickStudyButton 
                icon={mediaData[activeMediaTab].icon} 
                title={mediaData[activeMediaTab].title} 
                onClick={() => selectFirstCard(activeMediaTab)} 
                lastStudyClick={() => selectStudiedFlashcard(activeMediaTab)} 
                disabled={!hasStudiedBefore(activeMediaTab) || checkTvl(activeMediaTab) || checkCardType(activeMediaTab)} 
                isActive={flashcardData?.loading} 
                isLoading={isLoading} 
                isLoadingLastStudy={isLastStudyLoading} 
                hasStructureLoading={hasStructureLoading} 
                isMediaLoading={isMediaLoading} 
            /> 
        </div>
    </>
}

export default QuickStart;