import React, {PureComponent} from 'react';
import '../subjects-changer/subjects-changer.scss';
import {SubjectIcon} from 'icons/SubjectIcons';
import PropTypes from 'prop-types';
/**
 * @deprecated TODO- delete not use any more
 */
export default class StoreSubjectsChangerComponent extends PureComponent {
  orderSubjects(subjects) {
    const orderOfSubjects = [
      'all',
      'biology',
      'chemistry',
      'physics',
      'es-amp-s',
      'mathematics',
      'economics',
    ];

    return subjects.sort((a, b) => {
      const indexA = orderOfSubjects.indexOf(a.get('slug'));
      const indexB = orderOfSubjects.indexOf(b.get('slug'));
      if (indexA < indexB) return -1;
      return 1;
    });
  }

  renderStoreSubjects() {
    return this.orderSubjects(this.props.storeSubjects)
      .map(subject => {
        const isActive = subject.get('id') === this.props.activeStoreSubject.get('id');
        return (
          <div
            key={subject.get('id')} className={`subject ${isActive ? 'active' : ''}`}
            onClick={() => this.props.setStoreActiveSubject(subject)}>
            <div className="subject-icon">
              <SubjectIcon subject={subject.get('slug')}/>
            </div>
            <div className="subject-title" dangerouslySetInnerHTML={{__html: subject.get('title')}}/>
          </div>
        );
      });
  }

  render() {
    return <div className="subjects-changer">
      <div className="subjects-wrapper">
        {this.renderStoreSubjects()}
      </div>
    </div>;
  }
}

StoreSubjectsChangerComponent.propTypes = {
  storeSubjects: PropTypes.object.isRequired,
  activeStoreSubject: PropTypes.object,
  setStoreActiveSubject: PropTypes.func.isRequired
};
