import classNames from 'classnames';
import React, { ReactSVGElement } from 'react';
import CounterNotification from 'v2/components/notifications/CounterNotification';

type ItemType = {
    icon: (arg: boolean) => ReactSVGElement;
    hasNotification: boolean;
}
type BottomMenuItemsProps = {
    onClick: () => void;
    onTouchStart: () => void;
    isActive: boolean;
    notificationCount?: number;
    item: ItemType;
}  
  
const BottomMenuItems: React.FC<BottomMenuItemsProps> = ({onClick, onTouchStart, isActive, item, notificationCount})  => <div className={classNames('bottom-menu-item flex items-center justify-center ml-3 first:ml-0 w-10 h-10 select-none rounded-[22px] cursor-pointer', { 'menu-active': isActive })} onClick={onClick} onTouchStart={onTouchStart}>
    <div className='flex items-center flex-col'>
        <div className='flex relative items-center justify-center'>
            {item.hasNotification && <CounterNotification amount={notificationCount} />}
            {item.icon(isActive)}
        </div>
    </div>
</div>

export default BottomMenuItems;