import React from 'react';
// import RouterLink from 'router/Link';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';
import Text from 'ui-components/helpers/Text';
import styled from 'styled-components/macro';
import {ReactComponent as OSCSTUDYLOGO} from 'assets/icons/os-study.svg';


export const LoginFooter = ({hide}) => {
  return <StyledLoginFooter hide={hide}>
    <Text size={13} color={'#FFFFFF'} lineHeight={'18px'} textAlign={'center'}>
      © {(new Date().getFullYear())} Faria Education Group Limited. All Rights Reserved. <a href="https://oxfordstudycourses.com/terms/terms-of-service" target="_blank"><b>Terms, Policies, Agreements & Security</b> </a>
    </Text>
  </StyledLoginFooter>;
};

export const LandingPageLinks = ({hide, onClick}) => {
  return <StyledLandingPageLinks hide={hide}>
    {/* <Text size={13} color={'white'} letterSpacing={0} lineHeight={'18px'}>No account? <RouterLink
      to={'/register'}><strong>Create
      one!</strong></RouterLink></Text> */}
    <Text size={15} color={'white'} letterSpacing={0} lineHeight={'20px'} textAlign={"center"}><b>OSC Study</b> is the new version of SMART<b>PREP</b></Text>
    <Padding top={12}/>
    <Text size={15} color={'white'} letterSpacing={0} lineHeight={'20px'} textAlign={"center"}>To log in with your SMART<b>PREP</b> account, <br /><LoginClick onClick={onClick}><b>click here</b></LoginClick></Text>
    {/* <Text size={13} color={'white'} letterSpacing={0} lineHeight={'18px'}>Didn't receive your confirmation
      email? <RouterLink
        to="/resend-confirmation"><strong>Resend!</strong></RouterLink></Text> */}
  </StyledLandingPageLinks>;
};
export const LandingPageLogo = ({hide, loginHide}) => {
  return <StyledLandingPageLogo hide={hide} loginHide={loginHide}>
      <OSCSTUDYLOGO />
  </StyledLandingPageLogo>;
};

export const LoginClick = styled.div`
  cursor: pointer;
`;
export const StyledLandingPageLogo = styled.div`
  margin: 0 auto;
  margin-top: ${props =>props.hide ? props.loginHide ? "48px" : "48px" : "48px"};
  display: flex;
`;
export const LoginPageButtonWrapper = styled.button`
  background-image: linear-gradient(90deg, #48cfa8, #4abad1);
  box-shadow: 0 1px 3px 0 rgba(93,116,114,0.35);
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  border-radius: 27px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  outline: none;
  border: none;
  //transition: all .3s;
  @media (pointer: fine) {
    &:hover {
      box-shadow: 0 3px 5px 0 rgba(93, 116, 114, 0.35);
    }
  }
  &:active {
    background-image: linear-gradient(99deg, #3dad8d, #3d9bad);
    box-shadow: none;
  }
`;

export const LoginWithMBButtonWrapper = styled.button`
  background: ${props => props.alternate ? '#FFFFFF' : '#FFFFFF'};
  //box-shadow: ${props => props.alternate ? '0 1px 3px 0 rgba(116,93,93,0.60)' : '0 1px 3px 0 rgba(93,116,114,0.35)'};
  
  font-size: 13px;
  color: #378EF8;
  letter-spacing: 0;
  text-align: center;
  border-radius: 22px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: ${props => props.isMBButton && "center"};
  cursor: pointer;
  width: 100%;
  outline: none;

  box-shadow: 0 0 0 1px #e3e6e6;
  //transition: all .3s;
  @media (pointer: fine) {
    &:hover {
      //box-shadow: ${props => props.alternate ? '0 2px 8px 0 rgba(116,93,93,0.60)' : '0 3px 5px 0 rgba(93, 116, 114, 0.35)'};
      //background: #64A8FA;
      box-shadow: 0 0 0 1px #c8cccc;
    }
  }
  &:active {
    background: #378EF8;
    box-shadow: none;
    ${Text}{
      color: #FFFFFF;
    }
  }
`;

export const StyledAnimation = styled.div`
  display: none;
  
  ${props => props.show ? 'display: block;  transition: all .4s ease .15s;' : ''};
`;
export const CenterSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  justify-content: space-between;
  min-height: 100vh;
  
`;

export const CenterSection = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.2);
  padding: ${props => props.loginCollapsed ? "20px 19px 24px 20px" : "20px" };
  min-width: 347px;
  margin-top: 16px;
  border-radius: 10px;
  max-height: 183px;
  transition: max-height 0.3s ease-out;
  ${props => props.collapsed ? props.loginCollapsed ? 'max-height: 283px; transition: max-height 0.3s ease-out; ' : 'max-height: 259px; transition: max-height 0.3s ease-out; ' : props.loginCollapsed ? 'max-height: 283px; transition: max-height 0.3s ease-out; ' : 'max-height: 239px; transition: max-height 0.3s ease-out;'};
`;



const StyledLoginFooter = styled.div`
  padding: 15px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  @media (orientation: landscape){
  }
  
  @media (max-width: 893px){
     ${props => props.hide ? 'display: none;' : ''}
  }
  
  @media (max-width: 360px){
    > ${Row}{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const StyledLandingPageLinks = styled.div`
  margin: 0 auto;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
  ${props => props.hide ? 'display: none;' : ''}
`;

const VerticalSeparator = styled.div`
  opacity: 0.3;
  width: 1px;
  margin: 0 15px;
  background: white;
`;

export const ComingSoonButton = styled.div`
  border: 1px solid #FF8000;
  border-radius: 6px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
`;

export const NewSection = styled.div`
    padding:16px 8px;
    display: flex;
    align-items: center;
`;
export const StyledNewBlock = styled.div`
    flex:0;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    color: #FF9600;
    padding: 4px 8px;
    border: 1px solid #FF9600;
    border-radius: 6px;
    margin: 0 8px;
`;

export const StyledLine = styled.div`
  border-bottom: 1px solid #FF9600;
  flex:1;
  width: 115px;
`;

export const CenterSectionOuter = styled.div`
  height: 287px;
  display: flex;
  flex-direction: column;
`;