import React from 'react';
import ListTypeHeader  from 'v2/ui-components/search-results/ListTypeHeader';

type SearchResultsHeaderProps = {
  show: boolean;
  title: string;
  icon: React.ReactNode;
  showMore: boolean,
  total: number;
  onClick: () => void
}

const SearchResultsHeader: React.FC<SearchResultsHeaderProps> = ({ show, title, icon, showMore, total, onClick }) => {
  if (!show) return null;

  return <ListTypeHeader
    title={title}
    icon={icon}
    showMore={showMore}
    total={total}
    onClick={onClick}
  />;
}


export default SearchResultsHeader;