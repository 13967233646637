import PropTypes from 'prop-types';
import React, {Component} from 'react';
import AvatarContainer from 'components/avatar/AvatarContainer';

export default class StoreHeader extends Component {
  render() {
    let className = 'header';
    if (this.props.isContained) className += ' full-width';
    if (this.props.isSimple) className += ' collapsed';

    if (this.props.isSimple) {
      return (
        <div className={className}>
          <div className="title-container">
            <h1 className="title">Store</h1>
          </div>
        </div>
      );
    }

    return (
      <div className={className}>
        <div className="title-container">
          <h1 className="title">Store</h1>
          <div className="picture-container ratio-1-1">
            <AvatarContainer className="content profile-picture dark"/>
          </div>
        </div>
      </div>
    );
  }
}

StoreHeader.propTypes = {
  isSimple: PropTypes.bool,
  isContained: PropTypes.bool,
};
