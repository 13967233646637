import {getExternalAvatar} from 'actions/api/apiActions';
import RequestLoader from 'v2/components/loader.v2/RequestLoader';
import {Map} from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getExternalAvatarResponse} from 'selectors/api/apiSelector';
import Avatar from 'ui-components/Avatar';

class ExternalAvatar extends Component {
  componentDidMount() {
    if (this.props.userId && !this.props.externalAvatarResponse.get(this.props.userId)) {
      this.props.getExternalAvatar({id: this.props.userId});
    }
   
  }

  getUserInitials = () => {
    const {userName} = this.props;
    const splitUserName = userName.split(' ');
    return splitUserName[0][0] + splitUserName[splitUserName.length - 1][0];
  };

  render() {
    const {size, widthSize, initialFlashcard, accountPortal} = this.props;

    return (

      <RequestLoader loader={<Avatar size={size}>{this.getUserInitials()}</Avatar>} request={this.props.externalAvatarResponse.get(this.props.userId)?.toJS()}
        error={() => <Avatar size={size} widthSize={widthSize} initialFlashcard={initialFlashcard} accountPortal={accountPortal}>
          {this.getUserInitials()}
        </Avatar>}
      >
        <Avatar size={size} widthSize={widthSize} url={this.props.externalAvatarResponse.get(this.props.userId)?.get('data')} initialFlashcard={initialFlashcard}/>
      </RequestLoader>
    );
  }
}

const mapStateToProps = state => ({
  externalAvatarResponse: getExternalAvatarResponse(state)
});
const mapDispatchToProps = {
  getExternalAvatar: getExternalAvatar
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(ExternalAvatar);

ExternalAvatar.propTypes = {
  size: PropTypes.number.isRequired,
  userId: PropTypes.string,
  userName: PropTypes.string.isRequired,

  // Connect
  getExternalAvatar: PropTypes.func.isRequired,
  externalAvatarResponse: PropTypes.instanceOf(Map),
};

ExternalAvatar.defaultProps = {
  size: 30,
};
