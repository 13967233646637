import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const PointerEvents = styled.div`
  pointer-events: ${props => props.value};
`;
PointerEvents.defaultProps = {
  value: 'auto'
};
PointerEvents.propTypes = {
  value: PropTypes.string,
};
export default PointerEvents;

