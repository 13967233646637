import Immutable from 'immutable';
import * as Types from 'actions/types';
import theme from 'theme';

export default (state = Immutable.Map({}), action) => {
  switch (action.type) {
    case Types.WINDOW_RESIZE_LISTENER: {
      state = state
        .set('isMobile', action.width <= theme.sizes.phone)
        .set('windowWidth', action.width)
        .set('windowHeight', action.height)
        .set('iPad', (((action.width > 490) && (action.width <= theme.sizes.iPad)) && !(action.width >= action.height)))
        .set('iPadPro', (((action.width > 490) && (action.width <= theme.sizes.iPadPro)) && !(action.width >= action.height)))
        .set('isMobilePortrait', ((action.width < 600) && (action.width <= action.height)))
        .set('orientation', action.width > action.height ? 'landscape' : 'portrait')
        .set('mediaSizes', calculateMediaSizes(action.width));
      return state;
    }
    default:
      return state;
  }
};

function calculateMediaSizes(width) {
  let mediaSizes = {};
  for (let size in theme.sizes) {
    mediaSizes[size] = width <= theme.sizes[size];
  }
  return mediaSizes;
}
