import React, { useRef, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import CheckRadioButton from 'v2/components/check-radio-button/CheckRadioButton';
import Text from "v2/components/common/Text";
import { IBIcon, PmjIcon } from 'icons/HeaderIcons';
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';
import { NoteListIcon } from 'icons/FlashcardIcons';

type ExamsPreviewListItemProps = {
  isEditing?: boolean,
  active: boolean,
  onClick: () => void,
  title: string,
  listOfCheckedItems?: any,
  flashcardNumber: number,
  paper: string,
  teachingTag: string // teachingLevel same,
  marks: number,
  rightSection: React.ReactNode,
  sources: string,
  experienceData: any,
  difficulty: string,
  occurences?: number,
  deleted?: boolean,
  type?: string,
  flashcardId: string,
  formSearch?: boolean,
  preview?: string,
  nodeTeachingTag?: "HL" | "hl" | undefined | null
}

const ExamsPreviewListItem: React.FC<ExamsPreviewListItemProps> = ({ occurences = 0, isEditing, active, onClick, deleted, type, flashcardId, listOfCheckedItems, title, sources, paper, teachingTag, marks, difficulty, experienceData, preview , rightSection, nodeTeachingTag }) => {
  const textRef = useRef<HTMLDivElement>(null)
  const textRef1 = useRef<HTMLDivElement>(null)
  const [textEllipse, setTextEllipse] = useState(false);
  const [textEllipse1, setTextEllipse1] = useState(false);
  useEffect(() => {
    if (textRef.current) {
      if (isEditing ? 158 - textRef.current.scrollWidth < 0 : occurences > 0 ? 158 - textRef.current.scrollWidth < 0 : 192 - textRef.current.scrollWidth < 0) {
        setTextEllipse(true);
      } else {
        setTextEllipse(false);
      }
    }
  }, [isEditing, occurences])

  useEffect(() => {
    if (textRef1.current && textRef.current) {
      if (isEditing ? 266 - textRef.current.scrollWidth < 0 : occurences > 0 ? 252 - textRef1.current.scrollWidth < 0 : false) {
        setTextEllipse1(true);
      } else {
        setTextEllipse1(false);
      }
    }

  }, [isEditing, occurences, active, textRef1])
  const renderSource = useMemo(() => {
    switch (sources) {
      case 'IB':
        return (<IBIcon />);
      case 'OSC':
        return (<PmjIcon />);
      case 'ib_dp':
        return (<IBIcon />);
      case 'osc_dp':
        return (<PmjIcon />);
      case 'osc_dp_mocks':
        return (<PmjIcon />);
      default:
        return;
    }
  }, [sources])
  const renderPaper = useMemo(() => {
    switch (paper) {
      case 'Paper 1':
        return 'P1'
      case 'Paper 2':
        return 'P2'
      case 'Paper 3':
        return 'P3'
      default:
        return 'P1';

    }
  }, [paper])

  const examsOtherInfo = textEllipse ? isEditing ? "exam-editMode-other-info-textellipse"  : "exam-other-info-textellipse" : 'exam-editMode-other-info-textellipse'

  return <div onClick={onClick} className={classNames('pt-2.5 px-5 pb-3.5 relative cursor-pointer revision-preview-list exam-textellipse flex items-center', { 'bg-#E5ECF2 exam-textellipse-active': active, 'delete-animation': deleted, 'flex items-center': type === "notes" })}>
    {isEditing && <CheckRadioButton itemId={flashcardId} listOfCheckedItems={listOfCheckedItems} />}
    <div className='relative break-words'>
      <Text className="text-15px font-normal leading-19px text-#2D3838 exams-title " >{title}</Text>
      <div className={classNames('flex items-center pt-2', { 'exam-search-textellipse': textEllipse1 })} ref={textRef1}>
        <div className='mr-2 max-h-[21px]'>{renderSource}</div>
        <Text className="text-13px font-bold leading-16px text-#979999 mr-2" >{renderPaper}</Text>
        {!!nodeTeachingTag && ['HL', 'hl'].includes(nodeTeachingTag) && <TeachingLevelSeperator tlvl={teachingTag} tlvlTag={nodeTeachingTag} isExams={true} />}
        <div ref={textRef} className={classNames('exam-other-info', examsOtherInfo, { 'ml-2' : !!nodeTeachingTag && ['HL', 'hl'].includes(nodeTeachingTag) })} >{marks} {marks <= 1 ? 'mark' : 'marks'} • {difficulty} • {experienceData?.calc_enabled ? 'Calc' : 'Non-calc'}</div>
      </div>
      {type === 'notes' &&
        <div className='flex mt-[9px]'>
          <div className='flex items-center mr-2'><NoteListIcon /></div>
          <div className='exam-mark-text'>
            <div className="note-short-desc text-15px leading-19px text-#727373  break-words max-w-[262px]" >{preview}</div>
          </div>
        </div>
      }

    </div>
    <div className={classNames('ml-auto pl-[13px] flex', { 'items-center pl-0': type === 'notes' })}>{rightSection}</div>
  </div >
}


export default ExamsPreviewListItem