import React, {memo} from 'react'
import ErrorIcon from 'assets/icons/error-icon.svg';
import './notifications.scss'

type ErrorNotificationProps = {
    title?: string;
    text: string | React.ReactElement;
    hasContact: boolean;
    contactSection?: React.ReactNode;
}  

const ErrorNotification: React.FC<ErrorNotificationProps> = ({title, text, hasContact, contactSection}) => {
    return <div className='swal-notification clear-margin'>
        <div className="swal-notification-image">
            <img src={ErrorIcon} alt=""/>
        </div>
        <div className="swal-notification-title">
            {title || 'An error has occurred!'}
        </div>
        <div className="swal-notification-text pl-2.5 pr-2.5">
          {text}
          <br/>
          {hasContact && (contactSection || <span>Please report the problem <a href='mailto:info-app@smart-prep.com'>here</a>.</span>)}
        </div>
    </div>
}

export default memo(ErrorNotification);