import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {FixedWidth} from 'ui-components/helpers/common';
import Margin from 'ui-components/helpers/Margin';
import Row from 'ui-components/helpers/Row';
import TextAlign from 'ui-components/helpers/TextAlign';

class PublicPageHeader extends PureComponent {
  render() {
    const {title, leftSection, rightSection} = this.props;

    return (
      <Row alignItems={'center'} justifyContent={'space-between'}>
        <FixedWidth as={TextAlign} size={'60px'} align={'left'}>
          <Margin left={-8}>{leftSection}</Margin>
        </FixedWidth>
        {title}
        <FixedWidth as={TextAlign} size={'60px'} align={'right'}>
          {rightSection}
        </FixedWidth>
      </Row>
    );
  }
}

PublicPageHeader.propTypes = {
  title: PropTypes.func.isRequired,
  leftSection: PropTypes.func.isRequired,
  rightSection: PropTypes.func.isRequired,
};

export default PublicPageHeader;