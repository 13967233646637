import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { apiRequestData, hasMBIdentitySelector, isMBUserSelector, userDataSelector } from 'selectors';
import Text from 'v2/components/common/Text';
import { OSCPortalIcon } from "icons/HeaderIcons";
import './accountsPortal.scss'
import AccountsListItems from './AccountsListItems'
import { getMBTldBasedOnRegion, getPartnersBasedOnRegion } from "v2/helpers";

type AccountsPortalOverlayProps = {
    onClose: () => void;
}

const AccountsPortalOverlay: React.FC<AccountsPortalOverlayProps> = ({ onClose }) => {
    const [url, setUrl] = useState("");
    const [partnerUrl, setPartnerUrl] = useState<string>()
    const mbPartnersData = useSelector((state) => apiRequestData(state, "partners"))
    const userData = useSelector(userDataSelector).toJS()
    const hasMBIdentity = useSelector(hasMBIdentitySelector)
    const isMBUser = useSelector(isMBUserSelector)
    const userName = userData?.firstName + ' ' + userData?.lastName

    useEffect(() => {
        const { region } = userData
        if (!hasMBIdentity || !userData?.mbRole) {
            setUrl(`https://www.managebac.${getMBTldBasedOnRegion(region)}/login/`);
            setPartnerUrl(getPartnersBasedOnRegion(region))
        }

        if (isMBUser) {
            const jumpUrl = mbPartnersData.getIn(['users', 0, 'jump_url'])
            setUrl(`${jumpUrl}?land_on=%2F${userData?.mbRole?.toLowerCase()}%2Foxford_study_courses`);
            setPartnerUrl(getPartnersBasedOnRegion(region))
        }
    }, [hasMBIdentity, isMBUser, mbPartnersData, userData?.mbRole, userData]);

    const handleClick = (partnerUrl?: string) => {
        window.open(partnerUrl, "_blank");
        onClose()
    }
    
    return <div className='account-portal-wrapper'>
        <AccountsListItems title={{ name: userName, fontWeight: 'font-semibold' }} description={{ text: 'Manage Accounts Portal', color: 'text-[#4B8FFA]' }} role={null} onClick={() => handleClick(partnerUrl)} />
        <div className='h-px bg-[#D9E7F7] overflow-hidden -mx-[5px] mt-0.5 mb-[3px]' />
        <div className='mb-[3px] pt-[3px] pr-15px pl-5px '>
            <Text className='text-[#5B6C86] text-12px leading-18px font-medium'>Switch Services</Text>
        </div>
        <AccountsListItems title={{ name: 'OSC Home', fontWeight: 'font-bold' }} description={{ text: 'Your subscription and free resources', color: 'text-[#5B6C86]' }} role={'OSC'} onClick={() => handleClick(url)} logo={<OSCPortalIcon />} />
        {mbPartnersData.size !== 0 &&
            mbPartnersData.get("applications").map((item: any) => {
                if (item.get("name") === "OSC Study") {
                    return null
                }
                return <AccountsListItems title={{ name: item.get("name") ? item.get("name") : item.get("school_name"), fontWeight: 'font-bold' }} description={{ text: item.get("description"), color: 'text-[#5B6C86]' }} role={item.get("role")} onClick={() => handleClick(item.get("jump_url"))} logo={item.get("logo")} />
            })
        }
    </div>
}

export default AccountsPortalOverlay;