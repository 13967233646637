import SearchResultsHeader from 'pages/search/SearchResultsHeader';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CardsIcon} from 'icons/FlashcardIcons';
import FlashcardLinksResultsList from 'ui-components/link-results/FlashcardLinksResultsList';
import {transformResultsListToArray} from 'helpers';
import {List, Map} from 'immutable';

export default class FlashcardLinksContainer extends Component {
  componentDidMount() {
  }
  render() {
    const newListItems = this.props.cardsList ? transformResultsListToArray(this.props.cardsList) : [];
    return <React.Fragment>
      <SearchResultsHeader show={true} title={'Flashcards'} icon={<CardsIcon />} showMore={false} total={0}/>
      <FlashcardLinksResultsList newListItems={newListItems} shouldMark={false}/>
    </React.Fragment>;
  }
}
