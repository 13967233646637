import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useFormik } from 'formik';
import { useRef, useState } from 'react';

type SearchInputProps = {
  onSubmit: (arg: any) => void;
  hasCancel: boolean;
  onCancel: () => void;
  onBlur: () => void;
  onFocusAction: () => void;
  showResults: any
}
const SearchInput: React.FC<SearchInputProps> = ({ onFocusAction , onBlur, hasCancel, onCancel, onSubmit, showResults }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setFocused] = useState({ searchField: false, })
  const { values, handleSubmit, handleChange, handleBlur, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: { searchField: showResults || '' },
    onSubmit: (values) => {
      inputRef.current?.blur();
      onSubmit(values)
    }
  });
  const handleEsc = (key: any, event: any) => {
    inputRef.current?.blur();
    event.stopPropagation();
    event.preventDefault();
  };

  return <form className="search-from" autoComplete='off' action='#' onSubmit={handleSubmit}>
    <KeyboardEventHandler handleKeys={['esc']} handleFocusableElements={true} onKeyEvent={handleEsc} />
    <div className='flex justify-between items-center '>
      <input ref={inputRef} placeholder='Search' type="search" id='search' name='searchField' className='search-input'
        onChange={handleChange}
        onBlur={(e: any) => [handleBlur(e),onBlur(), setFocused({ ...isFocused, searchField: false })]}
        disabled={false}
        onFocus={() => [setFocused({ ...isFocused, searchField: true }), onFocusAction()]}
        value={values.searchField}
      />
      {(values.searchField !== '' || hasCancel) && <div className='search-close' onClick={() => [onCancel(), resetForm()]}>Cancel</div>}
    </div>
  </form>
}


export default SearchInput;