import React, { useEffect } from 'react';
import classNames from 'classnames';
import { getActiveExamsTab, getActiveExamsDetails } from 'selectors/navigation/navigationSelector'
import { setActiveExamsTab } from 'actions/navigation';
import { getExams } from 'actions/api';
import { useDispatch, useSelector } from 'react-redux';
import { OfficialIBExams } from 'v2/components/empty-screen-message/PaperESM';

type ExamsHeaderProps = {
    hasExamListResponse: boolean;
    getActiveExamModule: boolean;
}
type ExamsTabs = {
    name: string;
    slug: string;
}

const examsTabs = [
    {
        name: 'IB Exams',
        slug: 'ib_dp'
    },
    {
        name: 'Pmj Exams',
        slug: 'osc_dp'
    },
    {
        name: 'Pmj Mocks',
        slug: 'osc_dp_mocks'
    }
]

const ExamsHeader: React.FC<ExamsHeaderProps> = ({ hasExamListResponse, getActiveExamModule }) => {

    const dispatch = useDispatch();

    const activeExamsTab = useSelector(getActiveExamsTab);
    const activeExamsDetails = useSelector(getActiveExamsDetails).toJS();

    // useEffect(() => {
    //     if (!getActiveExamModule && activeExamsTab === "ib_dp") {
    //         dispatch(setActiveExamsTab('osc_dp'));
    //         dispatch(getExams());
    //     }
    // }, [])

    const activeExams = (val: string) => {
        if (val !== activeExamsTab) {
            dispatch(setActiveExamsTab(val));
            dispatch(getExams());
        }
    }

    return (
        <div className='mt-2 mx-5 mb-6'>
            <div className='bg-#EFF4F9 rounded-2xl h-9 relative flex justify-between'>
                {examsTabs.map((item: ExamsTabs, index: number) =>
                    ((item.slug === 'ib_dp') || ['osc_dp','osc_dp_mocks'].includes(item.slug)) &&
                    <div key={index} className={classNames('w-full h-full text-center font-bold cursor-pointer flex items-center justify-center select-none toolbarbutton', { 'isActive text-white': activeExamsTab === item.slug, 'text-#A2A3A3': activeExamsTab !== item.slug })} onClick={() => activeExams(item.slug)}>{item.name}</div>
                )}
            </div>
            {hasExamListResponse &&
                <div className='esm-description bg-#EFF4F9 pt-3 px-3.5 pb-2.5 mt-4'>
                    <OfficialIBExams activeExamsTab={activeExamsTab} />
                    <div className={classNames('text-#2D3838 experience-realistic mt-2')}>
                        {activeExamsDetails[activeExamsTab]}
                    </div>
                </div>
            }
        </div>
    )
}

export default ExamsHeader