import makeAction from 'actions/makeAction';
import * as Types from './types';
import * as Api from './api';
import * as User from './user';
import * as Subject from './subject';
import * as Studying from './studying';
import * as Navigation from './navigation';
import * as Topic from './topic';
import * as Notification from './notification';
import * as UserStore from './user-store';
import * as Desk from './desk';
import * as Search from './search';

export {Api, User, Subject, Studying, Navigation, Topic, Notification, UserStore, Desk, Search};

export const initialize = makeAction(Types.INITIALIZE, 'data');
export const logout = makeAction(Types.LOGOUT);
export const windowResizeListener = makeAction(Types.WINDOW_RESIZE_LISTENER, 'width', 'height');
export const dataInit = makeAction(Types.DATA_INIT, 'flashcardId', 'exerciseId', 'activeMediaTab','examsId');
export const iFrameDataInit = makeAction(Types.IFRAME_DATA_INIT, 'classId');
