import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFlashcardById, isLandscape, isLinksNavigationSectionVisible, isMobile, isNavigationSectionVisible } from 'selectors';
import {MoreIcon, QuestionInfoIcon} from 'icons/FlashcardNavIcons';
import CardBackButtonContainer from 'v2/components/studying/CardBackButtonContainer';
import {getbackFlashcard} from 'helpers';
import AnswerStatistics from 'v2/components/studying/answer-statistics/AnswerStatistics';
import StudyStatus from './StudyStatus';
import VideoStatusInfo from 'v2/components/studying/study-location-info/VideoStatus';
import ExamsStatusInfo from 'v2/components/studying/study-location-info/ExamsStatusInfo';
import { toggleMoreControlOverlay, toggleQuestionInfoOverlay } from 'actions/navigation';
import AddFlashcardToBookmarkContainer from 'v2/pages/desk/folder/AddFlashcardToBookmarkContainer';
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';
import classNames from 'classnames';

type StudyCardInfoStatusProps = {
    id: string | number;
    flashcardNumber: string;
    flashcardFolders: any;
    mediaTypeCurrent: string;
    questionType?: boolean;
}  

const StudyCardInfoStatus: React.FC<StudyCardInfoStatusProps> = ({id, flashcardNumber, flashcardFolders, mediaTypeCurrent, questionType}) => {
    const dispatch = useDispatch();
    const currentFlashcard = useSelector((state) => getFlashcardById(state, id))
    const teachingLevel = currentFlashcard.get('parentsData')?.find((item:any) => item.type === 'TEACHING_LEVEL')
    const hasNavigationSectionVisible = useSelector(isNavigationSectionVisible)
    const hasLinksNavigationSectionVisible = useSelector(isLinksNavigationSectionVisible)
    const hasMobile = useSelector(isMobile)
    const hasLandscape = useSelector(isLandscape)
    const mcqType = questionType == undefined ? true : questionType
    const nodeTeachingTag = useMemo(() => {
        const teachingTag = currentFlashcard.get('nodeTeachingTag')
        if(!teachingTag || !['HL', 'hl'].includes(teachingTag)) return null
        return teachingTag
    },[currentFlashcard])

    return <div className={`card-info-status ${mediaTypeCurrent === 'exams' && "exam-status"}`}>
            <div className="card-info-assessment">
                <CardBackButtonContainer flashcardId={id} returnCardId={getbackFlashcard()} isStudyOverlay={(hasLinksNavigationSectionVisible && hasNavigationSectionVisible)} />
                {mediaTypeCurrent === 'exams' ?
                    <>
                        {(!(hasMobile && hasLandscape) && flashcardNumber && flashcardNumber !== 'Qundefined') && <div className='flashcard-nr'>
                            <span>{flashcardNumber}</span>
                        </div>}
                    </> :
                    <>
                    <div className={`flashcard-nr ${mediaTypeCurrent === 'video' ? 'text-white' : ''}`}>
                         <span>{flashcardNumber}</span>
                    </div>
                    </>
                }
                {nodeTeachingTag && <TeachingLevelSeperator tlvl={teachingLevel} tlvlTag={nodeTeachingTag} />}
                <div className={classNames('desktop-answer-assesstment', {'ml-3':nodeTeachingTag})}>
                    {mediaTypeCurrent === 'flashcard' &&  <AnswerStatistics />}
                    {mediaTypeCurrent === 'revision' &&  <StudyStatus />}
                    {mediaTypeCurrent === 'video' &&  <VideoStatusInfo />}
                    {mediaTypeCurrent === 'exams' && !mcqType && <ExamsStatusInfo /> }
                </div>
            </div>
            <div className="card-info-bookmark">
                {mediaTypeCurrent === 'exams'  &&
                    <div className='question-info-icon-wrapper box-border rounded-[22px] z-1 flex items-center justify-center relative h-min mr-[11px] cursor-pointer p-2 select-none ' onClick={() => dispatch(toggleQuestionInfoOverlay(true,true))}>
                        <QuestionInfoIcon/>
                    </div>
                }
                <div className='study-more-btn' onClick={() => dispatch(toggleMoreControlOverlay(true, true))}><MoreIcon /></div>
                <div className='study-bookmark-btn'>
                    <AddFlashcardToBookmarkContainer flashcardFolders={flashcardFolders} />
                </div>
            </div>
    </div>
}

export default StudyCardInfoStatus;