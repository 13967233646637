import * as Actions from 'actions';
import Loader, {BackgroundEnum, SizeEnum} from 'v2/components/loader.v2/Loader.v2';
import {ReactComponent as MBLogo} from 'assets/icons/mb-logo.svg';
import {ReactComponent as MBLogoChina} from 'assets/icons/mb-logo-china.svg';
import {ReactComponent as SMPPLogoSmall} from 'assets/icons/smpp-logo.svg';
import FullPageLoader from 'v2/components/loader.v2/FullPageLoader';
import BackButton from 'components/navigation-header/BackButton';
import PublicPageHeader from 'components/PublicPageHeader';
import {isMBiframe} from 'helpers';
import {
  LoginFooter,
  LandingPageLinks,
  LandingPageLogo,
  StyledNewBlock,
  CenterSection, CenterSectionWrapper, CenterSectionOuter, LoginPageButtonWrapper, LoginWithMBButtonWrapper, NewSection, StyledLine, StyledAnimation
} from 'pages/login/loginComponents';
import {LOGIN_FORM_NAME} from 'pages/login/LoginForm';
import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Col from 'ui-components/helpers/Col';
import {ClickableDiv} from 'ui-components/helpers/common';
import Margin from 'ui-components/helpers/Margin';
import Padding from 'ui-components/helpers/Padding';
import Text from 'ui-components/helpers/Text';
import {Background, PublicPageBackground} from 'ui-components/pageComponents';
import {isMobile} from '../../selectors';
import * as Selectors from '../../selectors';
import LoginForm from './LoginForm';
import PropTypes from 'prop-types';
import {ReactComponent as SMPPLogo} from 'assets/images/login-logo.svg';
import {submit, isValid} from 'redux-form/immutable';
import styled from 'styled-components/macro';

export const manageBacUrl = `${process.env.REACT_APP_API_URL}/auth/managebac/authorize?region=canada`;
export const manageBacUrlChina = `${process.env.REACT_APP_API_URL}/auth/managebac/authorize?region=china`;

const VISIBLE_SECTIONS = {
  INITIAL: 'initial',
  LOGIN: 'login',
  MB: 'mb'
};

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.mbFormRef = createRef();

    this.state = {
      visibleSection: VISIBLE_SECTIONS.INITIAL,
      isLoading: false,
      region: null,
      manageBacUrl: manageBacUrl
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: false,
      region: null,
    });
    const url = (window.location !== window.parent.location) ? document.referrer : document.location.href;
    if(url.indexOf(".cn") > -1){
      // isMbChina = true;
      this.setState({manageBacUrl: manageBacUrlChina}, () => {
        if (isMBiframe(this.getRedirectUrl())) {
          this.mbFormRef.current.submit();
        }
      })
    } else {
      if (isMBiframe(this.getRedirectUrl())) {
        this.mbFormRef.current.submit();
      }
    }
  }

  getRedirectUrl() {
    const {location} = this.props;
    if(location.state && location.state.from && location.state.from.pathname){
      return location.state.from.pathname;
    }

    return localStorage.getItem('smpp-redirect-url');
  }

  onSubmitClick = () => {
    this.props.submitForm(LOGIN_FORM_NAME);
  };

  handClickSubmit = (region) => {
    this.setState({
      isLoading: true,
      region: region,
    });
  }

  renderMBButtons = () => {

    return <React.Fragment>
     <StyledAnimation show={this.state.visibleSection}>
      <Padding top={20}/>
      <Text size={15} color={'#2D3838'} letterSpacing={0} textAlign={'center'} lineHeight={'20px'}>Please choose
        your <strong>ManageBac</strong> region</Text>
      <Padding top={20}/>
      <form ref={this.mbFormRef} method='POST' action={manageBacUrl}>
        <LoginWithMBButtonWrapper type={'submit'} onClick={() => this.handClickSubmit('global')}>
        {(this.state.isLoading && this.state.region === 'global' )? <StyledMBLoader><Loader size={SizeEnum.Small} /></StyledMBLoader> :null }
        <StyledGlobalTextWithLogo isLoader={(this.state.isLoading && this.state.region === 'global' )}>
          <MBLogo/>
          <Margin right={12}/>
            <Text as={'span'} color={'#378EF8'} weight={'bold'} size={13} letterSpacing={0}
              textAlign={'center'} lineHeight={'18px'}> ManageBac Global</Text>
          </StyledGlobalTextWithLogo>
        </LoginWithMBButtonWrapper>
      </form>
      <Padding top={24} />
  
      <form ref={this.mbFormRef} method='POST' action={manageBacUrlChina}>
       <LoginWithMBButtonWrapper type={'submit'} onClick={() => this.handClickSubmit('china')}>
       {(this.state.isLoading && this.state.region === 'china' )? <StyledMBLoader><Loader size={SizeEnum.Small} /></StyledMBLoader> :null }
        <StyledChinaTextWithLogo isLoader={(this.state.isLoading && this.state.region === 'china' )}>
          <MBLogo/>
          <Margin right={12}/>
            <Text as={'span'} color={'#378EF8'} weight={'bold'} size={13} letterSpacing={0.1}
              textAlign={'center'} lineHeight={'18px'}> ManageBac China&nbsp;</Text>  <Margin right={8}/>
            <MBLogoChina/>
        </StyledChinaTextWithLogo>
       </LoginWithMBButtonWrapper>  
      </form> 
      <Padding top={8} />
      </StyledAnimation>
    </React.Fragment>;
  };

  renderInitialButtons = () => {
    return <React.Fragment>
      <Padding top={20}/>
      <Text size={15} color={'#2D3838'} letterSpacing={0} textAlign={'center'} lineHeight={'20px'}>
        First-time users enjoy a free 7 day trial
      </Text>
      <Padding top={20}/>
      <LoginWithMBButtonWrapper type={'button'} onClick={() => this.setState({visibleSection: VISIBLE_SECTIONS.MB})} isMBButton={true}>
        <MBLogo/>
        <Margin right={12}/>
        <Text color={'#378EF8'} weight={'bold'} size={13} letterSpacing={2} textAlign={'center'}>
          LOG IN WITH
          <Text as={'span'} color={'#378EF8'} weight={'bold'} size={13} letterSpacing={0}
            textAlign={'center'}> ManageBac</Text>
        </Text>
      </LoginWithMBButtonWrapper>
      {/* <Padding top={16}/>

      <LoginPageButtonWrapper as={'div'} onClick={() => {
        this.setState({visibleSection: VISIBLE_SECTIONS.LOGIN});
      }}>
        <SMPPLogoSmall/>
        <Margin right={12}/>
        <Text color={'white'} weight={700} size={13} letterSpacing={2} textAlign={'center'}>
          LOG IN WITH <Text as={'span'} size={13} color={'white'} weight={300}
          letterSpacing={0}>SMART<strong>PREP</strong></Text>
        </Text>
      </LoginPageButtonWrapper> */}
    </React.Fragment>;
  };

  renderContent = () => {
    switch (this.state.visibleSection) {
      case VISIBLE_SECTIONS.INITIAL:
        return this.renderInitialButtons();
      case VISIBLE_SECTIONS.LOGIN:
        return <LoginForm
          requestError={this.props.requestError}
          onSubmit={this.onSubmit}/>;
      case VISIBLE_SECTIONS.MB:
        return this.renderMBButtons();
      default:
        return <div>unhandled</div>;
    }
  };

  render() {
    const {isRequestInProgress} = this.props;
    const {visibleSection} = this.state;
    const canSubmit = this.props.isFormValid;
    if (isRequestInProgress)
      return <FullPageLoader text={'Logging you in'}/>;

    //todo also handle for china
    if (isMBiframe(this.getRedirectUrl())) return <form ref={this.mbFormRef} method='POST' action={this.state.manageBacUrl}/>;
    return <React.Fragment>
      <PublicPageBackground>
        <CenterSectionWrapper >
          <Background/>
          <Col alignItems={'center'} flexGrow={1} justifyContent={'flex-start'}>
            <SMPPLogo/>
            <CenterSectionOuter>
            <CenterSection collapsed={visibleSection === VISIBLE_SECTIONS.MB } loginCollapsed={visibleSection === VISIBLE_SECTIONS.LOGIN}>
              <PublicPageHeader
                leftSection={visibleSection !== VISIBLE_SECTIONS.INITIAL && <BackButton onClick={() => {
                  this.setState({visibleSection: VISIBLE_SECTIONS.INITIAL});
                }}>Back</BackButton>}
                title={
                <Text size={33} color={'#2D3838'} weight={700} letterSpacing={0} lineHeight={'45px'} textAlign={'center'}>Log
                  in</Text>}
                rightSection={visibleSection === VISIBLE_SECTIONS.LOGIN &&
                  <ClickableDiv onClick={this.onSubmitClick}>
                    <StyledText weight={700} color={canSubmit ? '#378EF8' : '#C8CCCC'} disabled={!canSubmit}>Log
                      in</StyledText></ClickableDiv>
                }/>
              {this.renderContent()}
              
            </CenterSection>
            <LandingPageLinks hide={visibleSection === VISIBLE_SECTIONS.MB || visibleSection === VISIBLE_SECTIONS.LOGIN} onClick={() => {
                          this.setState({visibleSection: VISIBLE_SECTIONS.LOGIN});
              }}/>
            </CenterSectionOuter>
            
            <LandingPageLogo hide={visibleSection === VISIBLE_SECTIONS.MB || visibleSection === VISIBLE_SECTIONS.LOGIN} loginHide={visibleSection === VISIBLE_SECTIONS.LOGIN} />
          </Col>
          <Col alignItems={'center'}>
            <LoginFooter hide={visibleSection === VISIBLE_SECTIONS.LOGIN}/>
          </Col>
        </CenterSectionWrapper>
      </PublicPageBackground>
    </React.Fragment>;
  }
}

LoginPage.propTypes = {
  // Router
  location: PropTypes.object,

  // Connect
  requestError: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  isRequestInProgress: PropTypes.bool.isRequired,
};


const mapStateToProps = (state) => ({
  requestError: Selectors.getErrorInApiRequest(state, 'authenticate'),
  isRequestInProgress: Selectors.isApiRequestInProgress(state, 'authenticate'),
  isFormValid: isValid(LOGIN_FORM_NAME)(state),
  isMobile: isMobile(state)
});

const mapDispatchToProps = {
  submitForm: submit,
  setDocumentTitle: Actions.Navigation.setDocumentTitle
};
const StyledText = styled(Text)`
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  color: ${props => props.color? props.color : "#378EF8"};
  pointer-events: ${props => props.disabled ? 'none' : null};

  @media (pointer: fine) {
    &:hover {
      color: #64A8FA;
    }
  } 
  &:active {
    color: #2170CF;
  }
`;
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage));
const StyledMBLoader = styled.div`
  position: absolute;
  padding-left: 10px;
`;
const StyledChinaTextWithLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: ${props => props.isLoader ? '0.5' : null };
  pointer-events: ${props => props.isLoader ? 'none' : null };
`;
const StyledGlobalTextWithLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: ${props => props.isLoader ? '0.5' : null };
  pointer-events: ${props => props.isLoader ? 'none' : null };
`;