import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Modal from 'components/modal';
import React, {PureComponent} from 'react';
import {hideStore} from 'actions/navigation';
import StoreContainer from './StoreContainer';
import {isStoreModalOpen} from 'selectors/navigation/navigationSelector';

class StoreModal extends PureComponent {
  render() {
    if (!this.props.isOpen) return null;

    return (
      <Modal id='store-modal' onClose={this.props.hideStore}>
        <StoreContainer isContained={true}/>
      </Modal>
    );
  }
}

StoreModal.propTypes = {
  isOpen: PropTypes.bool,
  hideStore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isOpen: isStoreModalOpen(state)
});

const mapDispatchToProps = {
  hideStore
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreModal);
