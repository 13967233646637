import HorizontalSeparator from 'components/horizontal-separator';
import * as FlashcardIcons from 'icons/FlashcardIcons';
import React, {PureComponent} from 'react';
import styled from 'styled-components/macro';
import {orderLinks} from 'helpers';

export default class LinksComponent extends PureComponent {
  renderTeachingLevel(linkObjects, teachingLevel) {
    return <div key={teachingLevel}>
      <div className="subject-title">
        {teachingLevel}
      </div>
      
      {linkObjects.map(
        (link, index) => {
          
          return <Link key={link.get('nodeId')} onClick={() => this.props.linkClick(link)} className='link-container'>
            <div className="pop-up-list-item bold">
              <span className="list-item-chapter">{link.get('linkKey')}</span>
              <span className='pop-up-list-item-nodeId'>{link.get('linkValue')}</span>
            </div>
            {index < linkObjects.size - 1 ? <HorizontalSeparator className="items-separator"/> : null}
          </Link>;
        }
      )}
    </div>;
  }

  renderLinks(links) {
    let returnObjects = [];
    const orderedLinks = orderLinks(links);
    for (let key of orderedLinks.keys()) {
      returnObjects.push(this.renderTeachingLevel(orderedLinks.get(key), key));
    }
    return returnObjects;
  }

  render() {
    return (
      <div className="pop-up-container">
        <div className='flex'>
          <span className="pop-up-icon"><FlashcardIcons.LinksIcon/></span>
          <span className="pop-up-title">Links</span>
        </div>
        {this.props.links.size > 0 ? this.renderLinks(this.props.links) : <div className="subject-title">No links</div>}
      </div>
    );
  }
}


const Link = styled.div`
  padding: 0 20px;
  line-height: normal;
  margin: 0 -20px;

  &:last-child {
    .pop-up-list-item {
      border-bottom: unset;
    }
  }
  @media (pointer: fine) {
    &:hover {
      background-color: #F2F2F2;
    }
  }
  
  &:active {
    background-color: #E5E8E8;
  }
  
  .pop-up-list-item {
    margin: 0;
    padding: 9px 0;
    border-bottom: 1px solid #E3E6E6;
  }
`;
