import BackButton from 'components/navigation-header/BackButton';
import ChangeOrientationNotification from 'components/notifications/ChangeOrientationNotification';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {isMobile, isLandscape, shouldGoBackToSearch} from 'selectors';
import PropTypes from 'prop-types';
import appHistory from 'appHistory';
import * as Actions from 'actions';

class BackToSearchButton extends Component {
  onClickCallback = () => {
    const {isMobile, showAlert, isLandscape, setGoBackToSearch} = this.props;

    setGoBackToSearch(false);

    appHistory.push('/flashcard/search');
    if (isMobile && isLandscape) {
      showAlert({
        content: <ChangeOrientationNotification to={'portrait'}
          title={'Rotate your device to view search results'}
          subtitle={'Make sure auto rotate is activated'}
        />, buttons: false, timer: 2000
      });
    }
  };

  render() {
    const {shouldGoBackToSearch, alternate, isMobile} = this.props;
    return (
      (shouldGoBackToSearch && (!isMobile || alternate().props.text != "Notes" )) ? <BackButton onClick={this.onClickCallback} text={'Search'}/> : alternate()
    );
  }
}

const mapStateToProps = state => ({
  isMobile: isMobile(state),
  isLandscape: isLandscape(state),
  shouldGoBackToSearch: shouldGoBackToSearch(state)
});
const mapDispatchToProps = {
  showAlert: Actions.Notification.showAlert,
  toggleOverlay: Actions.Studying.toggleOverlay,
  setGoBackToSearch: Actions.Search.setGoBackToSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(BackToSearchButton);

