import './MarkingsMenu.scss';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ColorButton from './ColorButton';
import React, {PureComponent} from 'react';
import {currentTool} from 'selectors/markingsSelectors';
import {removeTool, selectTool} from 'actions/markings/markingsActions';
import {EraserIcon, HighlighterIcon, MarkingIcon, PenIcon} from 'icons/MarkingIcons';

export const tools = [
  {
    type: 'pen',
    icon: PenIcon
  }, {
    type: 'highlighter',
    icon: HighlighterIcon
  }, {
    type: 'eraser',
    icon: EraserIcon
  }
];

export const colors = [
  '#F23E38',
  '#F2A539',
  '#F2E43C',
  '#238EEF',
  '#82E52B',
  '#9013FE',
  '#D8D8D8',
];

class MarkingsMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isColorPickerOpen: false
    };

    this.onColorClick = this.onColorClick.bind(this);
    this.onCurrentToolClick = this.onCurrentToolClick.bind(this);
    this.onCurrentColorClick = this.onCurrentColorClick.bind(this);
  }

  currentColor() {
    const currentTool = this.props.currentTool;
    return currentTool && currentTool.get('color') || colors[0];
  }

  currentToolType() {
    const currentTool = this.props.currentTool;
    return currentTool && currentTool.get('type');
  }

  onToolSelect(type) {
    if (type === this.currentToolType()) {
      this.props.removeTool();
      return;
    }
    this.props.selectTool(type, this.currentColor());
  }

  onCurrentColorClick() {
    this.setState({
      isColorPickerOpen: true
    });
  }

  onColorClick(color) {
    const currentType = this.currentToolType();
    this.props.selectTool(currentType, color);
  }

  onCurrentToolClick() {
    this.setState({
      isColorPickerOpen: false
    });
  }

  shouldHaveColor(currentType) {
    return currentType && currentType !== 'eraser';
  }

  render() {
    const currentType = this.currentToolType();
    const currentColor = this.currentColor();

    return (
      <div className='markings-menu text-center bold'>
        {this.renderHeader()}
        {this.state.isColorPickerOpen ? (
          this.renderColorPicker(currentType, currentColor)
        ) : (
          <React.Fragment>
            {this.renderTools(currentType, currentColor)}
            {this.shouldHaveColor(currentType) && (
              <ColorButton
                onClick={this.onCurrentColorClick} active={true} color={currentColor}
                className='current-color'/>
            )}
          </React.Fragment>
        )}
        <hr/>
      </div>
    );
  }

  renderColorPicker(currentType, currentColor) {
    const currentToolIcon = tools.find((tool) => tool.type === currentType).icon;

    return (
      <div className='color-picker flex space-between vertical-align'>
        <div className='current-tool' onClick={this.onCurrentToolClick}>
          {currentToolIcon({color: currentColor})}
        </div>

        {colors.map((color, index) => (
          <ColorButton
            key={index} onClick={() => this.onColorClick(color)} active={currentColor === color}
            color={color}/>
        ))}
      </div>
    );
  }

  renderTools(currentType, currentColor) {
    return <div className='flex-center icons-container'>
      {tools.map((tool, index) => {
        const active = currentType === tool.type;
        return (
          <div
            key={index}
            onClick={() => this.onToolSelect(tool.type)}
            className={'icon' + (active ? ' active' : '')}>
            {tool.icon({active, color: active && currentColor || ''})}
          </div>
        );
      })}
    </div>;
  }

  renderHeader() {
    return <div className='flex-center text-with-icon'>
      <MarkingIcon/>
      <span className='text'>Markings</span>
    </div>;
  }
}

MarkingsMenu.propTypes = {
  selectTool: PropTypes.func.isRequired,
  removeTool: PropTypes.func.isRequired,
  currentTool: PropTypes.instanceOf(Immutable.Map),
};

const mapStateToProps = (state) => ({
  currentTool: currentTool(state)
});

const mapDispatchToProps = {
  selectTool, removeTool
};

export default connect(mapStateToProps, mapDispatchToProps)(MarkingsMenu);
