import classNames from 'classnames';
import { useCallback } from 'react';
import { isMobile, isSubjectsManagerOverlayVisible, currentFlashcardId, getActiveMessageLoader, getActiveSubject, isIPad, isTeachingLevelOverlayVisible, subjectsTeachingLevel, activeSubjectId, isMenuVisible, isLeftMenuOpen, isAccountsPortalOverlayVisible, isOldSubjectsManagerOverlayVisible, isNavigationSectionVisible, isDeviceManagerOverlayVisible, isLandscape, getSubjectv2 } from 'selectors';
import { activeHeaderExerciseNotificationsCount } from 'selectors/exerciseSelectors';
import ExerciseBullet from 'v2/components/exercise/ExerciseBullet';
import { useSelector, useDispatch } from "react-redux"
import { LeftMenuToggleIcon, TileGridIcon, ArrowTopicIcon } from 'icons/HeaderIcons';
import { SmallSubjectIcon } from 'icons/SubjectIcons';
import { isMBUserSelector, getUserData, getUserId } from 'selectors/user';
import { toggleTeachingLevelOverlay, openMenu, closeMenu, toggleNavigationSection, toggleAccountsPortalOverlay, toggleSubjectsManagerOverlay, toggleOldSubjectsManagerOverlay, setActiveFlashcardTab } from 'actions/navigation';
import { ReactComponent as GearIcon } from 'assets/icons/home/gear-icon.svg';
import { useHistory } from 'react-router-dom'
import './OSCHeader.scss'
import { menuPathIndex } from "helpers"
import { getExams } from 'actions/api/apiActions'
import { getLastSubjectActive, yearNewSyllabus } from 'v2/helpers';


const OSCHeader = () => {
    const dispatch = useDispatch()
    const hasMobile = useSelector(isMobile)
    const subjects = useSelector(getSubjectv2)
    const hasSubjectsManagerOverlayVisible = useSelector(isSubjectsManagerOverlayVisible);
    const hasOldSubjectsManagerOverlayVisible = useSelector(isOldSubjectsManagerOverlayVisible)
    const hasCurrentFlashcardId = useSelector(currentFlashcardId);
    const hasGetActiveMessageLoader = useSelector(getActiveMessageLoader);
    const activeSubject = useSelector(getActiveSubject);
    const hasPad = useSelector(isIPad);
    const hasTeachingLevelOverlayVisible = useSelector(isTeachingLevelOverlayVisible);
    const hasSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
    const hasActiveSubjectId = useSelector(activeSubjectId);
    const hasLeftMenuOpen = useSelector(isLeftMenuOpen);
    const hasMenuVisible = useSelector(isMenuVisible)
    const isMBUser = useSelector(isMBUserSelector);
    const hasAccountsPortalOverlayVisible = useSelector(isAccountsPortalOverlayVisible);
    const hasNavigationSectionVisible = useSelector(isNavigationSectionVisible);
    const isDeviceOverlayVisible = useSelector(isDeviceManagerOverlayVisible);
    const userData = useSelector(getUserData);
    const hasLandscape = useSelector(isLandscape)
    const activeSubjectExerciseNotificationsCount: number = useSelector(activeHeaderExerciseNotificationsCount)
    const isActive = hasSubjectsManagerOverlayVisible || hasGetActiveMessageLoader;
    const hasActive = isActive || !hasCurrentFlashcardId;
    const hasClassroomNotification = activeSubjectExerciseNotificationsCount > 0;
    const tlvl = hasSubjectsTeachingLevel[`${hasActiveSubjectId}`];
    const teachingTag = activeSubject?.slug !== "pre-ib-mathematics" ? tlvl : "Pre-IB"
    const studiesTag = tlvl && (teachingTag.indexOf('Studies SL') !== -1 || teachingTag.indexOf('Pre-IB') !== -1);
    const history = useHistory();
    const mobileLandscape = hasMobile && hasLandscape
    const isNewSubject = !["french", "english-b"].includes(activeSubject?.slug) && (activeSubject?.isNew || activeSubject?.tagging === 'NEW_SUBJECT')
    const userId = useSelector(getUserId);
    const lastSubjectActive = subjects?.find((subject:any) => getLastSubjectActive(userId) === subject?.id)
    
    const renderSubjectTitle = (val: string) => {
        switch (val) {
            case 'Business Management':
                return 'Business M.'
            case 'Pre-IB Mathematics':
                return 'Pre-IB Maths'
            default:
                return val;
        }
    }

    const menuToggle = () => {
        const currentPathname = history.location.pathname
        dispatch(toggleNavigationSection(!hasNavigationSectionVisible));
        if (hasCurrentFlashcardId && hasNavigationSectionVisible) {
            const activeTabId = menuPathIndex()?.find((item) => (currentPathname.indexOf(item.name) !== -1))?.id;
            dispatch(setActiveFlashcardTab(activeTabId));
        }
        else {
            dispatch(setActiveFlashcardTab(-1));
        }

    }
    const openSubjectManager = () => {
        if (hasOldSubjectsManagerOverlayVisible) {
            dispatch(toggleOldSubjectsManagerOverlay(!hasOldSubjectsManagerOverlayVisible));
            dispatch(toggleSubjectsManagerOverlay(!hasSubjectsManagerOverlayVisible));
        } else {
            dispatch(toggleSubjectsManagerOverlay(!hasSubjectsManagerOverlayVisible));
        }
    }
    const toggleTeachingLevel = () => {
        dispatch(toggleTeachingLevelOverlay(!hasTeachingLevelOverlayVisible))
    }

    const handleGearIconClick = () => {
        if (!hasLeftMenuOpen) {
            dispatch(toggleNavigationSection(true))
        }
        if (hasMenuVisible) {
            dispatch(closeMenu());
        }
        else {
            dispatch(openMenu());
        }
    }
    const handleTileClick = () => {
        dispatch(toggleAccountsPortalOverlay(!hasAccountsPortalOverlayVisible))
    }

    const teachingLevelWrapper = useCallback((onClick?: () => void) => {
      return (
        <div
          className={classNames("rounded-15px", {
            "ml-2 py-1.5 px-2.5 cursor-pointer select-none gray-background-hover":
              !isNewSubject,
          })}
          onClick={onClick}
        >
          {tlvl && (
            <div
              className={classNames(
                "flex items-center justify-center rounded-5px font-normal text-13px leading-18px text-white min-w-27px h-20px bg-#1FCD6C",
                {
                  "bg-#458CFC":
                    teachingTag.indexOf("SL") !== -1 ||
                    teachingTag.indexOf("Pre-IB") !== -1,
                  "w-full": studiesTag,
                  "w-27px": !studiesTag,
                  "py-0 px-2": studiesTag,
                }
              )}
            >
              {activeSubject?.slug === "pre-ib-mathematics" ? "Pre-IB" : tlvl}
            </div>
          )}
        </div>
      );
    },[activeSubject?.slug, isNewSubject, studiesTag, teachingTag, tlvl]);

    if (!activeSubject) return null;
    return (
        <div className={classNames('max-w-1440px h-56px bg-white fixed w-full flex  items-center justify-between  z-999  osc-home-padding', { 'left-0': hasMobile, 'max-width-container': !hasMobile, 'landscape:hidden' :mobileLandscape, 'bottom-line-border': !isDeviceOverlayVisible })}> {/* left pending */}
            { (!isDeviceOverlayVisible && hasSubjectsManagerOverlayVisible) ? <div className='subject-manager-close-btn' onClick={() => [dispatch(toggleSubjectsManagerOverlay(false)), dispatch(getExams())]}>Close <ArrowTopicIcon classnames=''/></div> :
                <div className={classNames('items-center flex', { 'invisible': hasSubjectsManagerOverlayVisible })}>
                    <div className={classNames('mr-1 rounded-15px cursor-pointer select-none gray-background-hover lm-toggle-icon', { 'pointer-events-none toggle-menu-button': hasActive })} onClick={menuToggle}>
                        <LeftMenuToggleIcon />
                    </div>

                    <div className={classNames('cursor-pointer flex items-center justify-center box-border rounded-15px px-2.5 py-7px select-none gray-background-hover ')} onClick={() => openSubjectManager()}>
                        <div className={classNames('w-3.5 h-3.5 svg-rotate-0')}>
                            <SmallSubjectIcon changeDefaultIcon={true} subject={lastSubjectActive?.slug} />
                        </div>
                        <div className={classNames('mx-1 font-semibold leading-18px text-13px text-blue-normal')} dangerouslySetInnerHTML={{ __html: (hasMobile && !hasPad) ? renderSubjectTitle(lastSubjectActive?.title) : lastSubjectActive?.title }} />
                        {hasClassroomNotification && <ExerciseBullet />}
                    </div>
                    {isNewSubject ? 
                    <div className='ml-2 gray-background-hover flex items-center justify-center gap-[5px] rounded-2xl cursor-pointer py-[5px] px-2.5 select-none' onClick={() => toggleTeachingLevel()}>
                        <div className='flex items-center justify-center rounded-[4px] year-wrapper w-[42px] h-[22px]'>
                            <div className='font-normal text-13px text-[#A2A3A3]'>
                                {activeSubject?.tagging === 'NEW_SUBJECT' ? yearNewSyllabus(activeSubject?.slug) : '2014'}
                            </div>
                        </div>
                        {teachingLevelWrapper()}
                    </div> : 
                    <>
                        {teachingLevelWrapper(toggleTeachingLevel)}
                    </>}

                </div>
            }
            <div className={classNames('items-center flex float-right')}>
                {!hasSubjectsManagerOverlayVisible &&
                    <div className={classNames('tutorial-step-header-icons inline-block p-1.5 gray-background-hover rounded-15px h-33px', { 'mr-18px': isMBUser })} onClick={handleGearIconClick}>
                        <GearIcon />
                    </div>}
                {userData.mbRole &&
                    <div className={classNames('rounded-15px cursor-pointer mb-tile-grid gray-background-hover p-9px h-33px', { 'pointer-events-none': isActive })} onClick={handleTileClick} >
                        <TileGridIcon />
                    </div>
                }
            </div>
        </div>
    )
}

export default OSCHeader;