import React, {memo, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {getNotification} from 'selectors/notification/notificationSelector'
import {isMobile, isPortrait} from 'selectors/browser/browserSelector'
import '../notifications.scss'
import classNames from 'classnames'
import {AnswerNotification, XXLCardNotification} from './NotificationTypes'
import {hideNotification} from 'actions/notification/notificationActions'

type NotificationWrapperProps = {
    hasNavigationSectionVisible: boolean;
}
const NotificationWrapper: React.FC<NotificationWrapperProps> = ({hasNavigationSectionVisible}) => {
    const dispatch = useDispatch();
    const notificationRef = useRef<any>();
    let hideTimeout: any = null;
    let hideTimeoutAnimation: any = null;
    const notification = useSelector(getNotification)
    const hasMobile = useSelector(isMobile)

    useEffect(() => {
        return () => {
          clearTimeout(hideTimeout);
          clearTimeout(hideTimeoutAnimation);
        };
      }, []);

    const hideNotif = () => {
        notificationRef?.current.classList.add('notification-hidden');  
        clearTimeout(hideTimeoutAnimation);
        hideTimeoutAnimation = setTimeout(() => {
          dispatch(hideNotification());
          clearTimeout(hideTimeout);
        }, 300);
      };
    const showNotif = () => {
        clearTimeout(hideTimeout);
        hideTimeout = setTimeout(() => {
          if (notification?.size) {
            hideNotif();
          }
        }, 2500);
      }
    showNotif();
    if (notification?.size === 0) return null;
    switch (notification.get('type')) {
        case 'answer':
            return <div className={classNames('notification-wrapper', {'!left-[375px]' : (!hasMobile && hasNavigationSectionVisible)})} ref={notificationRef} onClick={hideNotif}>
                <AnswerNotification type={notification?.get('value')} />
            </div>
        case 'xxlCard':
            return <div className={classNames('notification-wrapper', {'!left-[375px]' : (!hasMobile && hasNavigationSectionVisible)})} ref={notificationRef} onClick={hideNotif}>
                <XXLCardNotification mobile={hasMobile} />
            </div>
        default : return <></>
    }
}

export default NotificationWrapper;