import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MarkText from 'v2/ui-components/MarkText';
import PreviewListHeader from 'v2/ui-components/search-results/PreviewListHeader';
import PreviewRightSection from './PreviewRightSection';
import { setActiveFlashcardTab, setExerciseOnCanvas } from 'actions/navigation';
import { updateListOfItemsSelected } from 'actions/desk';
import { setSearchIndex } from 'actions/search';
import { setActiveExerciseId, selectFlashcard } from 'actions/studying'
import { listOfCheckedItems, isDeskEditModeActive, getExerciseOnCanvas, flashcardSearchResults, currentFlashcardId } from 'selectors';
import { getUserId } from 'selectors/user';
import { addBackFlashcardToLocalStorage, setLastMediaTypeVisited, decodeQuery } from 'helpers';
// import { VideoPreviewListItem } from 'ui-components/search-results/PreviewListItem';
import VideoPreviewListItem from './VideoPreviewListItem';

type VideoResultsListProps = {
  newListItems: any,
  shouldMark: boolean,
  fromPage?: string,
  filterFlashcardId: number | null,
  onListItemSelected?: any,
  mediaType: string
}

const VideoResultsList: React.FC<VideoResultsListProps> = ({ newListItems, shouldMark, fromPage, filterFlashcardId, mediaType }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const hasListOfCheckedItems = useSelector(listOfCheckedItems);
  const hasDeskEditModeActive = useSelector(isDeskEditModeActive);
  const userId = useSelector(getUserId);
  const hasExerciseOnCanvas = useSelector(getExerciseOnCanvas);
  const hasFlashcardSearchResults = useSelector(flashcardSearchResults)
  const currentId = useSelector(currentFlashcardId)

  const onSubTopicClick = (subTopicId: any) => {
    history.push(`/flashcard/subtopic/${subTopicId}`)
    dispatch(setActiveFlashcardTab(0))
  }
  const onListItemClick = (flashcardId: number) => {
    const query = decodeQuery(history.location.search.substring(1));
    if (hasDeskEditModeActive) {
      dispatch(updateListOfItemsSelected(flashcardId));
    } else {
      if (flashcardId !== currentId) {
        addBackFlashcardToLocalStorage("flashcard" + "-" + flashcardId, query.mediaType + "-" + currentId);
      }
      setLastMediaTypeVisited(mediaType, userId);
      if (hasExerciseOnCanvas || typeof hasExerciseOnCanvas == "undefined") {
        dispatch(setExerciseOnCanvas(false))
        dispatch(setActiveExerciseId(null))
      }
      dispatch(selectFlashcard(flashcardId, shouldMark ? 'search' : 'default'));
      const searchIndex = hasFlashcardSearchResults.findIndex((item: any) => item.flashcardId === flashcardId);
      if (searchIndex !== -1) {
        dispatch(setSearchIndex(searchIndex));
      }
    }

  }

  if (newListItems.length === 0) return null;
  return <MarkText shouldMark={shouldMark}>
    {newListItems.map((item: any, index: number) => {
      return <div key={`flashcards-${item[0].category}`}>
        <PreviewListHeader topicNumber={item[0].topicNumbering} index={index}
          teachingLevel={item[0].teachingLevel} teachingTag={typeof item[0].teachingTag !== "undefined" ? item[0].teachingTag : null} subTopicId={item[0].subTopicId}
          onClick={() => onSubTopicClick(item[0].subTopicId)} fromPage={fromPage} subTopicTitle={item[0].subTopicName} data={item?.[0]} />
        {item.map((subItem: any) => {
          const id = subItem.id;
          const occurences = subItem.occurences;
          return <VideoPreviewListItem key={`video-${subItem.flashcardId}`}
            id={id}
            isEditing={false}
            videoNumber={subItem.numbering}
            active={id == currentId}
            onClick={() => onListItemClick(subItem.nodeId)}
            title={subItem.title}
            totalDuration={subItem.totalDuration}
            totalWatched={subItem.totalWatched}
            totalDurationInSeconds={subItem.totalDurationInSeconds}
            totalWatchedInSeconds={subItem.totalWatchedInSeconds}
            deleted={filterFlashcardId === id}
            listOfCheckedItems={listOfCheckedItems}
            shouldMark={false}
            thumbImage={subItem.thumbImage}
            rightSection={<PreviewRightSection id={id} occurences={occurences} type='video' shouldMark={shouldMark} />}
            nodeTeachingTag={subItem?.nodeTeachingTag}
            teachingLevel={subItem?.teachingLevel}
            teachingTag={subItem?.teachingTag}
          />;
        })}
      </div>
    })}
  </MarkText>
}

export default VideoResultsList;