import React from "react"
import { ReactComponent as CloseIcon } from 'assets/icons/page-overlay/close-icon-turquoise.svg';
import { SupportIcon, MoreIcon, MyPlanIcon, ProfileIcon } from 'icons/ProfileIcons';
import MenuLink from "./MenuLink";
import { useSelector, useDispatch } from "react-redux";
import { getActiveFlashcardTab, isMobile, isPortrait } from "selectors"
import { closeMenu, toggleSupportManagerOverlay, toggleNavigationSection } from 'actions/navigation';
import { changeMenuPathAction } from 'actions/menuActions';
import Button, { ThemeEnum } from 'v2/components/Button/Button';
import { LogoutIcon } from 'icons/HeaderIcons';
import { logout } from 'actions';

const MenuPage = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveFlashcardTab);
  const hasMobile = useSelector(isMobile);
  const hasPortrait = useSelector(isPortrait);

  const handleSupportButtonClick = () => {
    if (hasMobile && hasPortrait) {
      dispatch(changeMenuPathAction('manual'))
    } else {
      dispatch(toggleSupportManagerOverlay())
    }
  }

  const closeMenuAction = () => {
    if (activeTab !== -1) {
      dispatch(closeMenu());
    } else {
      dispatch(toggleNavigationSection(false, false))
    }
  }
  const changeMenuPath = (val: string) => {
    dispatch(changeMenuPathAction(val))
  }

  return <div className="bg-white pt-2.5 h-full">
    <div className="px-3.5">
      <div className="menu-page-close p-1.5">
        <div onClick={closeMenuAction}>
          <CloseIcon />
        </div>
      </div>
    </div>

    <div className="text-#2D3838 text-33px font-bold leading-45px px-5 pt-18px pb-2">Settings</div>
    <MenuLink icon={ProfileIcon} onClick={() => changeMenuPath('profile')} >Account & profile</MenuLink>

    <MenuLink icon={MyPlanIcon} onClick={() => changeMenuPath('subscriptions')} >Your plan</MenuLink>

    <MenuLink icon={SupportIcon} onClick={handleSupportButtonClick} >Support</MenuLink>

    <MenuLink icon={MoreIcon} onClick={() => changeMenuPath('more')} >More</MenuLink>

    <div className="absolute w-full bottom-0 px-5 pb-10">
      <Button icon={<LogoutIcon />} onClick={() => dispatch(logout())} text={<div className="pl-2.5">Log out</div>} type={'button'}
        btnTheme={ThemeEnum.White} className={'w-full logout-active'} />
    </div>
  </div>
}

export default MenuPage