import * as Actions from 'actions';
import FormInput from 'components/FormInputComponent/FormInput';
import BackButton from 'components/navigation-header/BackButton';
import PublicPageHeader from 'components/PublicPageHeader';
import {
  CenterSection,
  CenterSectionWrapper,
  LoginFooter,
} from 'pages/login/loginComponents';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {isValid, reduxForm, submit} from 'redux-form/immutable';
import Col from 'ui-components/helpers/Col';
import {ClickableDiv} from 'ui-components/helpers/common';
import Padding from 'ui-components/helpers/Padding';
import Text from 'ui-components/helpers/Text';
import {Background, PublicPageBackground} from 'ui-components/pageComponents';
import {validator} from 'utils/form-helper';
import {ReactComponent as SMPPLogo} from 'assets/images/login-logo.svg';

class ResendConfirmationContainer extends Component {
  render() {
    const canSubmit = this.props.isFormValid;

    return <React.Fragment>
      <PublicPageBackground>
        <CenterSectionWrapper>
          <Background/>
          <Col alignItems={'center'} flexGrow={1} justifyContent={'flex-start'}>
            <SMPPLogo/>
            <CenterSection>
              <PublicPageHeader
                leftSection={() => <BackButton linkTo={'/login'}>Back</BackButton>}
                title={() => <Text size={25} color={'#2D3838'} weight={700} textAlign={'center'} lineHeight={1.2}
                  letterSpacing={0}>Resend <br/>confirmation</Text>}
                rightSection={() => <ClickableDiv onClick={() => this.props.submitForm(RESEND_CONFIRMATION_FORM_NAME)}>
                  <Text size={17} weight={700} color={canSubmit ? 'turquoise' : '#C8CCCC'}
                    letterSpacing={0}>Send</Text></ClickableDiv>}
              />

              <form onSubmit={this.props.handleSubmit}>
                <Padding top={10}/>
                <FormInput label="Email address" name="email" placeholder="Enter your email address" type="text"
                  validate={[validator.required, validator.email]} change={this.props.change} touch={this.props.touch}/>
                <button type="submit" hidden/>
              </form>

            </CenterSection>
          </Col>
          <Col alignItems={'center'}>
            <LoginFooter/>
          </Col>
        </CenterSectionWrapper>
      </PublicPageBackground>
    </React.Fragment>;
  }
}

const RESEND_CONFIRMATION_FORM_NAME = 'resend-confirmation';
const mapDispatchToProps = {
  // onSubmit: (data) => Actions.Api.resendConfirmationEmail(data),
  submitForm: submit,
};

const mapStateToProps = state => ({
  isFormValid: isValid(RESEND_CONFIRMATION_FORM_NAME)(state),
});

const ResendConfirmationForm = (reduxForm({form: RESEND_CONFIRMATION_FORM_NAME})(ResendConfirmationContainer));
export default connect(mapStateToProps, mapDispatchToProps)(ResendConfirmationForm);