import React, { useRef, useState } from "react"
import SaveButton from 'v2/components/navigation-header/SaveButton';
import useScrollDetection from "hooks/useScrollDetection";
import BackToProfileButtonContainer from 'v2/pages/profile/BackToProfileButtonContainer';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RenderInputField } from 'v2/utils/form-helper';

type ContactDetailsPageComponentProps = {
  userProfile: any
  onSubmit: (a:any) => void;
}
const ContactDetailsPageComponent: React.FC<ContactDetailsPageComponentProps> = ({ userProfile, onSubmit }) => {

  const ref = useRef(null);
  const { isScrolled, isScrolled2 } = useScrollDetection({ WrappedRef: ref, threshold1: 20 })
  const initialFormValue = {
    email: userProfile.email ?? '',
    company: userProfile?.company ?? '',
    address: userProfile?.address ?? '',
    suite: userProfile?.suite ?? '',
    city: userProfile?.city ?? '',
    country: userProfile?.country ?? '',
    postalCode: userProfile?.postalCode ?? '',
    phone: userProfile?.phone ?? '',
  }
  const [isFocused, setFocused] = useState({ email: false, company: false, address: false, suite: false, city: false, country: false, postalCode: false, phone: false });
  const { values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue,  } = useFormik({
    validationSchema: validation,
    enableReinitialize: true,
    initialValues: initialFormValue,
    onSubmit: (values) => {
      onSubmit(values);
    }
  });

  return <div className="profile-details-page" >
    <NavigationHeader isCollapsed={isScrolled} extendedWidth title="Contact details" leftSection={<BackToProfileButtonContainer />} rightSection={(<SaveButton disabled={false} onClick={handleSubmit} />)} />
    <div className='profile-details-form'>
      <form onSubmit={handleSubmit}>
        <div className="profile-details-subtitle">EMAIL</div>

        <div className='form-input-wrapper'>
          <RenderInputField
            label={'Email address'}
            type='email'
            name='email'
            placeholder='Enter your first name'
            className='form-input'
            showIcon={true}
            touched={touched.email}
            error={errors.email}
            reset={() => setFieldValue("email", "")}
            active={isFocused.email}
            value={values.email}
            onChange={handleChange}
            onBlur={(e: any) => [handleBlur(e),setFocused({...isFocused, email: false}) ]}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ ...isFocused, email: true })
            }}
          />
        </div>
        <div className="profile-details-subtitle subtitle-padding">ADDRESS</div>

        <div className='form-input-wrapper'>
          <RenderInputField
            label={'Company (optional)'}
            type='text'
            name='company'
            placeholder='Enter your company name'
            className='form-input'
            showIcon={true}
            touched={touched.company}
            error={errors.company}
            reset={() => setFieldValue("company", "")}
            active={isFocused.company}
            value={values.company}
            onChange={handleChange}
            onBlur={(e: any) => [handleBlur(e),setFocused({...isFocused, company: false}) ]}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ ...isFocused, company: true })
            }}
          />
        </div>

        <div className='form-input-wrapper'>
          <RenderInputField
            label={'Address (optional)'}
            type='text'
            name='address'
            placeholder='Enter your street name and house number'
            className='form-input'
            showIcon={true}
            touched={touched.address}
            error={errors.address}
            reset={() => setFieldValue("address", "")}
            active={isFocused.address}
            value={values.address}
            onChange={handleChange}
            onBlur={() => [handleBlur,setFocused({...isFocused, address: false}) ]}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ ...isFocused, address: true })
            }}
          />
        </div>

        <div className='form-input-wrapper'>
          <RenderInputField
            label={'Apt, suite, etc. (optional)'}
            type='text'
            name='suite'
            placeholder='Enter the data'
            className='form-input'
            showIcon={true}
            touched={touched.suite}
            error={errors.suite}
            reset={() => setFieldValue("suite", "")}
            active={isFocused.suite}
            value={values.suite}
            onChange={handleChange}
            onBlur={(e: any) => [handleBlur(e) ,setFocused({...isFocused, suite: false}) ]}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ ...isFocused, suite: true })
            }}
          />
        </div>

        <div className='form-input-wrapper'>
          <RenderInputField
            label={'City (optional)'}
            type='text'
            name='city'
            placeholder='Enter your city'
            className='form-input'
            showIcon={true}
            touched={touched.city}
            error={errors.city}
            reset={() => setFieldValue("city", "")}
            active={isFocused.city}
            value={values.city}
            onChange={handleChange}
            onBlur={(e: any) => [handleBlur(e), setFocused({...isFocused, city: false}) ]}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ ...isFocused, city: true })
            }}
          />
        </div>

        <div className='form-input-wrapper'>
          <RenderInputField
            label={'Country (optional)'}
            type='text'
            name='country'
            placeholder='Enter your country'
            className='form-input'
            showIcon={true}
            touched={touched.country}
            error={errors.country}
            reset={() => setFieldValue("country", "")}
            active={isFocused.country}
            value={values.country}
            onChange={handleChange}
            onBlur={(e: any) => [handleBlur(e), setFocused({...isFocused, country: false}) ]}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ ...isFocused, country: true })
            }}
          />
        </div>

        <div className='form-input-wrapper'>
          <RenderInputField
            label={'Postal code (optional)'}
            type='text'
            name='postalCode'
            placeholder='Enter your postal code'
            className='form-input'
            showIcon={true}
            touched={touched.postalCode}
            error={errors.postalCode}
            reset={() => setFieldValue("postalCode", "")}
            active={isFocused.postalCode}
            value={values.postalCode}
            onChange={handleChange}
            onBlur={(e: any) => [handleBlur(e), setFocused({...isFocused, postalCode: false}) ]}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ ...isFocused, postalCode: true })
            }}
          />
        </div>

        <div className='form-input-wrapper'>
          <RenderInputField
            label={'Phone (optional)'}
            type='text'
            name='phone'
            placeholder='Enter your phone number'
            className='form-input'
            showIcon={true}
            touched={touched.phone}
            error={errors.phone}
            reset={() => setFieldValue("phone", "")}
            active={isFocused.phone}
            value={values.phone}
            onChange={handleChange}
            onBlur={(e: any) => [handleBlur(e), setFocused({...isFocused, phone: false}) ]}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ ...isFocused, phone: true })
            }}
          />
        </div>
      </form>
    </div>
  </div>
}
let validation = Yup.object().shape({
  email: Yup.string().email('The email is invalid!').required("This field is required!"),
});


export default ContactDetailsPageComponent