import React, { useEffect } from "react"
import { setDocumentTitle } from 'actions/navigation';
import { useDispatch } from "react-redux"
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import BackToMenuButtonContainer from 'v2/pages/menu/BackToMenuButtonContainer';
import {ForwardArrowIcon} from 'icons/HeaderIcons';
import './MorePage.scss'

const MorePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDocumentTitle('More'))
  }, [])
  return <div className="more-page-fixed-spacing" id="more-page">
    <NavigationHeader extendedWidth title="" leftSection={(<BackToMenuButtonContainer />)} />
    <div className="px-5 font-bold leading-45px text-#2D3838 tracking-normal pt-52px text-33px ">More</div>
    <div className="more-page-items-container">
      <div className="more-page-item-container">
        <a href="https://oxfordstudycourses.com/terms/terms-of-service" className="more-page-item" target="_blank"
          rel="noopener noreferrer">Terms, Policies, Agreements & Security</a>
        <div className="forward-arrow-icon"><ForwardArrowIcon /></div>
      </div>
    </div>
  </div>
}

export default MorePage