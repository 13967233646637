import React from 'react';
import classNames from 'classnames';
import Text from 'v2/components/common/Text';


type MediaStudyButtonProps = {
    title: string;
    icon: React.ReactElement;
    onClick: () => void;
    isFCMaths?: boolean;
    isVCMaths?: boolean;
    isExercies?: boolean;
    techingLvl?: string | undefined;
    subjectSlug?: string;
} 
const MediaStudyButton: React.FC<MediaStudyButtonProps> = ({title, icon, onClick, isFCMaths = false, isVCMaths = false, techingLvl, subjectSlug, isExercies = false}) => {
    const isFCStatus = isFCMaths && (techingLvl === "SL" || techingLvl === "HL") && (subjectSlug === 'maths-a-amp-a' || subjectSlug === 'maths-a-amp-i');
    const isVCStatus = isVCMaths && (techingLvl === "HL") && (subjectSlug === 'maths-a-amp-a' || subjectSlug === 'maths-a-amp-i');
    const setMargin = isFCStatus || isVCStatus;
    return <>
       <div className='flex flex-col' onClick={onClick}>
            <div className={classNames('media-study-btn', {'mt-3.5 mx-0 mb-6' : setMargin}, {'mt-3.5 mx-0 mb-46px' : !setMargin})}>
                {icon}
                <Text className='leading-18px tracking-2px text-white font-bold text-13px' >
                    {title}
                </Text>
            </div>
       </div>
       {isFCStatus && <div className='media-haese'>All mathematics flashcards were developed by <strong>HAESE MATHEMATICS</strong>. <a href="https://www.haesemathematics.com/pages/about-us" target="_blank"> &nbsp;Learn more</a></div>}
        {isVCStatus && <div className='media-haese'>The mathematics videos currently only cover the SL core material. The additional higher level material will be uploaded soon. We’ll keep you updated!</div>}
    </>
}

export default MediaStudyButton;