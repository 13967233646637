import './Icons.scss';
import React from 'react';

export function CardButtonIcon(props) {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.0879 1.42456L17.7566 2.75563C18.6435 3.0774 19.1369 4.02407 18.8928 4.93537L17.1731 11.3531" stroke="#378EF8" strokeWidth="1.8" strokeLinecap="round"/>
  <path d="M6.03516 6.94482V2.54482C6.03516 1.66117 6.7515 0.944824 7.63516 0.944824H12.4207C13.31 0.944824 14.0286 1.66998 14.0206 2.55923L13.9811 6.94482" stroke="#378EF8" strokeWidth="1.8" strokeLinecap="round"/>
  <path d="M5.97461 1.42456L2.31903 2.75085C1.42667 3.07461 0.933565 4.03037 1.18689 4.94522L2.86344 10.9999" stroke="#378EF8" strokeWidth="1.8" strokeLinecap="round"/>
  <circle cx="10" cy="14" r="6" fill="#378EF8"/>
  <path className="path-number" fillRule="evenodd" clipRule="evenodd" d="M11 17V11H9.94897L8 12.539L8.61655 13.3023L9.31172 12.7483C9.39448 12.6826 9.54207 12.5445 9.75448 12.3338L9.73379 12.9576L9.72138 13.528V17H11Z" fill="white"/>
  </svg>
  ;
}
export function PageButtonIcon(props) {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 20L2 3.5C2 2.11929 3.11929 1 4.5 1H15.5C16.8807 1 18 2.11929 18 3.5V4.07135V10.6655V17.0577" stroke="#378EF8" strokeWidth="1.8" strokeLinecap="round"/>
  <path d="M6.5 1.5V7" stroke="#378EF8" strokeWidth="1.3" strokeLinecap="round"/>
  <circle cx="10" cy="14" r="6" fill="#378EF8"/>
  <path className="path-number" fillRule="evenodd" clipRule="evenodd" d="M11 17V11H9.94897L8 12.539L8.61655 13.3023L9.31172 12.7483C9.39448 12.6826 9.54207 12.5445 9.75448 12.3338L9.73379 12.9576L9.72138 13.528V17H11Z" fill="white"/>
  </svg>
  ;
}
export function VedioButtonIcon(props) {
  return <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.00017 7.75665V5.91427C8.00017 5.51901 8.43716 5.28003 8.76995 5.49329L12.6816 8" stroke="#378EF8" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
  <ellipse cx="9.98262" cy="7.75665" rx="6.71854" ry="6.69467" transform="rotate(-45 9.98262 7.75665)" stroke="#378EF8" strokeWidth="1.8" strokeLinecap="round"/>
  <circle cx="10" cy="15" r="6" fill="#378EF8"/>
  <path className="path-number" fillRule="evenodd" clipRule="evenodd" d="M11 18V12H9.94897L8 13.539L8.61655 14.3023L9.31172 13.7483C9.39448 13.6826 9.54207 13.5445 9.75448 13.3338L9.73379 13.9576L9.72138 14.528V18H11Z" fill="white"/>
  </svg>;
}
export function ExamButtonIcon(){
  return<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.80039 3H6.40039C5.29582 3 4.40039 3.89543 4.40039 5V5" stroke="#378EF8" strokeWidth="1.44"/>
  <path d="M6.80078 3.0001H11.6008" stroke="#378EF8" strokeWidth="1.44"/>
  <path d="M14.2012 6.6001V8.2001" stroke="#378EF8" strokeWidth="1.4"/>
  <path d="M13.9996 13.8V13.8C13.9996 14.9046 13.1042 15.8 11.9996 15.8H7.59961" stroke="#378EF8" strokeWidth="1.44"/>
  <rect x="11.5996" y="1" width="5.6" height="5.6" rx="0.8" stroke="#378EF8" strokeWidth="1.04"/>
  <path d="M14 8.19995H16.4C16.8418 8.19995 17.2 8.55812 17.2 8.99995V13.5422" stroke="#378EF8" strokeWidth="1.04" strokeLinecap="round"/>
  <path d="M7.59961 7.44247V5.8001C7.59961 5.35827 7.24144 5.0001 6.79961 5.0001H2.79961C2.35778 5.0001 1.99961 5.35827 1.99961 5.8001V9.8001C1.99961 10.2419 2.35778 10.6001 2.79961 10.6001H3.49283" stroke="#378EF8" strokeWidth="1.04" strokeLinecap="round"/>
  <path d="M3.6582 17.8H2.80063C2.3588 17.8 2.00063 17.4418 2.00063 17L2.00063 14.3736L2.00063 12.9901C2.00063 12.5537 2.35439 12.2 2.79078 12.2V12.2H3.00063" stroke="#378EF8" strokeWidth="1.04" strokeLinecap="round"/>
  <circle cx="10" cy="14" r="6" fill="#378EF8"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M11 17V11H9.94897L8 12.539L8.61655 13.3023L9.31172 12.7483C9.39448 12.6826 9.54207 12.5445 9.75448 12.3338L9.73379 12.9576L9.72138 13.528V17H11Z" fill="white"/>
  </svg>
  
}
export function ContinueButtonIcon(props) {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 2.5V17.5" stroke={props.disabled ? "#C8CCCC" : "#378EF8"} strokeWidth="1.8" strokeLinecap="round" strokeDasharray="3 3"/>
      <path d="M4.5 10H17.5" stroke={props.disabled ? "#C8CCCC" : "#378EF8"} strokeWidth="1.8" strokeLinecap="round"/>
      <path className="path-number" fillRule="evenodd" clipRule="evenodd" d="M19.4304 9.59313C19.7096 9.79254 19.7096 10.2075 19.4304 10.4069L13.7906 14.4353C13.4597 14.6717 13 14.4351 13 14.0284L13 5.9716C13 5.56491 13.4597 5.32835 13.7906 5.56473L19.4304 9.59313Z" fill={props.disabled ? "#C8CCCC" : "#378EF8"}/>
  </svg>;
}

export function StudyButtonIcon(props) {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.123 3.42456L17.6474 4.81046C18.5835 5.09722 19.1245 6.07377 18.8711 7.01949L16.5018 15.8618C16.2419 16.8317 15.2449 17.4073 14.275 17.1475L14.0887 17.0976" stroke="white" strokeWidth="1.3" strokeLinecap="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.36328 4.81818C6.36328 3.81403 7.17731 3 8.18146 3H11.8178C12.822 3 13.636 3.81403 13.636 4.81818V13.9091C13.636 14.9132 12.822 15.7273 11.8178 15.7273H8.18146C7.17731 15.7273 6.36328 14.9132 6.36328 13.9091V4.81818Z" stroke="white" strokeWidth="1.3"/>
    <path d="M6.93945 3.42456L2.41514 4.81046C1.479 5.09722 0.938036 6.07377 1.19144 7.01949L3.56073 15.8618C3.82063 16.8317 4.8176 17.4073 5.78754 17.1475L5.97377 17.0976" stroke="white" strokeWidth="1.3" strokeLinecap="round"/>
  </svg>;  
}

export function ReadButtonIcon(props) {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 3.5C2 2.11929 3.11929 1 4.5 1H15.5C16.8807 1 18 2.11929 18 3.5V16.5C18 17.8807 16.8807 19 15.5 19H4.5C3.11929 19 2 17.8807 2 16.5V3.5Z" stroke="white" strokeWidth="1.3"/>
    <path d="M6.5 1.5V18.5" stroke="white" strokeWidth="1.3" strokeLinecap="square"/>
  </svg>;  
}

export function WatchButtonIcon(props) {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM7.765 14.5219L14.3216 10.424C14.6349 10.2282 14.6349 9.77184 14.3216 9.576L7.765 5.47812C7.43198 5.26998 7 5.50941 7 5.90212V14.0979C7 14.4906 7.43197 14.73 7.765 14.5219Z"/>
      </mask>
      <path d="M14.3216 10.424L13.6326 9.3216L13.6326 9.3216L14.3216 10.424ZM7.765 14.5219L8.454 15.6243L8.454 15.6243L7.765 14.5219ZM14.3216 9.576L15.0106 8.4736L15.0106 8.4736L14.3216 9.576ZM7.765 5.47812L8.454 4.37573L7.765 5.47812ZM10 18.7C5.19512 18.7 1.3 14.8049 1.3 10H-1.3C-1.3 16.2408 3.75918 21.3 10 21.3V18.7ZM18.7 10C18.7 14.8049 14.8049 18.7 10 18.7V21.3C16.2408 21.3 21.3 16.2408 21.3 10H18.7ZM10 1.3C14.8049 1.3 18.7 5.19512 18.7 10H21.3C21.3 3.75918 16.2408 -1.3 10 -1.3V1.3ZM1.3 10C1.3 5.19512 5.19512 1.3 10 1.3V-1.3C3.75918 -1.3 -1.3 3.75918 -1.3 10H1.3ZM13.6326 9.3216L7.076 13.4195L8.454 15.6243L15.0106 11.5264L13.6326 9.3216ZM13.6326 10.6784C13.1313 10.3651 13.1313 9.63493 13.6326 9.3216L15.0106 11.5264C16.1386 10.8214 16.1386 9.17861 15.0106 8.4736L13.6326 10.6784ZM7.076 6.58052L13.6326 10.6784L15.0106 8.4736L8.454 4.37573L7.076 6.58052ZM8.3 5.90212C8.3 6.53047 7.60884 6.91355 7.076 6.58052L8.454 4.37573C7.25511 3.62642 5.7 4.48834 5.7 5.90212L8.3 5.90212ZM8.3 14.0979V5.90212L5.7 5.90212V14.0979H8.3ZM7.076 13.4195C7.60884 13.0865 8.3 13.4695 8.3 14.0979H5.7C5.7 15.5117 7.25511 16.3736 8.454 15.6243L7.076 13.4195Z" fill="white" mask="url(#path-1-inside-1)"/>
  </svg>;  
}

export function PracticeButtonIcon(){
  return<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12.1648" y="1" width="5.92941" height="5.92941" rx="0.847059" stroke="white" strokeWidth="1.10118"/>
    <rect x="12.1648" y="8.62354" width="5.92941" height="5.92941" rx="0.847059" stroke="white" strokeWidth="1.10118"/>
    <rect x="2" y="12.8588" width="5.92941" height="5.92941" rx="0.847059" stroke="white" strokeWidth="1.10118"/>
    <rect x="2" y="5.23529" width="5.92941" height="5.92941" rx="0.847059" stroke="white" strokeWidth="1.10118"/>
    <path d="M7.08244 3.11765H6.65891C5.48936 3.11765 4.54126 4.06575 4.54126 5.23529V5.23529" stroke="white" strokeWidth="1.52471"/>
    <path d="M7.08228 3.11765H12.1646" stroke="white" strokeWidth="1.52471"/>
    <path d="M14.706 6.92941V8.62353" stroke="white" strokeWidth="1.52471"/>
    <path d="M14.7059 14.5529V14.5529C14.7059 15.7225 13.7578 16.6706 12.5883 16.6706H7.92944" stroke="white" strokeWidth="1.52471"/>
    <path d="M4.54121 11.1647V12.8588" stroke="white" strokeWidth="1.52471"/>
    </svg>

}