import * as Actions from 'actions';
import FormInput from 'components/FormInputComponent';
import BackButton from 'components/navigation-header/BackButton';
import PublicPageHeader from 'components/PublicPageHeader';
import {CenterSection, CenterSectionWrapper} from 'pages/login/loginComponents';
import {connect} from 'react-redux';
import React, {PureComponent} from 'react';
import Col from 'ui-components/helpers/Col';
import {ClickableDiv} from 'ui-components/helpers/common';
import styled from 'styled-components/macro';
import Padding from 'ui-components/helpers/Padding';
import Text from 'ui-components/helpers/Text';
import {Background, PublicPageBackground} from 'ui-components/pageComponents';
import {validator} from 'utils/form-helper';
import {isValid, reduxForm, submit} from 'redux-form/immutable';
import {ReactComponent as SMPPLogo} from 'assets/images/login-logo.svg';

class ForgotUsernameContainer extends PureComponent {
  render() {
    const {change, touch, handleSubmit} = this.props;
    const canSubmit = this.props.isFormValid;

    return <React.Fragment>

      <PublicPageBackground>
        <CenterSectionWrapper>
          <Background/>
          <Col alignItems={'center'} flexGrow={1} justifyContent={'flex-start'}>
            <SMPPLogo/>
            <CenterSection>
              <PublicPageHeader
                leftSection={() => <BackButton linkTo={'/login'}>Back</BackButton>}
                title={() => <Padding left={10} right={10}>
                  <Text size={25} color={'#2D3838'} weight={700} textAlign={'center'} letterSpacing={0}>Forgot
                    username</Text>
                </Padding>}
                rightSection={() => <ClickableDiv onClick={() => this.props.submitForm(FORGOT_USERNAME_FORM_NAME)}>
                  <StyledText size={13} weight={700} color={canSubmit ? '#378EF8' : '#C8CCCC'}
                    lineHeight={"18px"} >Send</StyledText></ClickableDiv>}
              />

              <form onSubmit={handleSubmit}>
                <FormInput change={change} touch={touch} label='Email address' name='email'
                  placeholder='Enter your email address' type='email'
                  validate={[validator.required, validator.email]}/>
                <button type="submit" hidden/>
              </form>

            </CenterSection>
          </Col>
          <div/>
        </CenterSectionWrapper>
      </PublicPageBackground>
    </React.Fragment>;
  }
}

const FORGOT_USERNAME_FORM_NAME = 'forgot-username';

const mapDispatchToProps = {
  // onSubmit: (data) => Actions.Api.sendForgotUsernameEmail(data),
  submitForm: submit,
};

const mapStateToProps = state => ({
  isFormValid: isValid(FORGOT_USERNAME_FORM_NAME)(state),
});

const StyledText = styled(Text)`
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  color: #378EF8;
  letter-spacing: 2px;
  @media (pointer: fine) {
    &:hover {
      color: #64A8FA;
    }
  } 
  &:active {
    color: #2170CF;
  }
`;
const ForgotUsernameForm = (reduxForm({form: FORGOT_USERNAME_FORM_NAME})(ForgotUsernameContainer));
export default connect(mapStateToProps, mapDispatchToProps)(ForgotUsernameForm);
