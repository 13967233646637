import React, { Component } from 'react';
import {connect} from 'react-redux';
import {BackCardButton} from 'icons/FlashcardNavIcons';
import {getFlashcardById} from 'selectors/studying';
import * as Actions from 'actions';
import * as Selectors from 'selectors';
import { getLastMediaTypeVisited, splitString, setLastMediaTypeVisited} from 'helpers';
import {getUserId} from 'selectors/user';
import {withRouter} from 'react-router-dom';

class CardBackButtonContainer extends Component {
  render(){
    const {isLeftOverlay, isRightOverlay, isStudyOverlay, flashcardId, returnCardId, flashcard, selectFlashcard, isLandscape, setActiveMediaTab, userId} = this.props;
    if(!returnCardId['return-flashcard-id'] || !flashcard.get('numbering')) return null;
    const currentFlashcard = returnCardId['current-flashcard-id'];
    const returnFlashcard = returnCardId['return-flashcard-id'];
    const currentMediaValue = splitString(currentFlashcard);
    const returnMediaValue = splitString(returnFlashcard);
    const currentFlashcardId =  currentMediaValue[1];
    const returnFlashcardId =  returnMediaValue[1];
    const currentMedia =  currentMediaValue[0];
    const returnMedia =  returnMediaValue[0];
    const onClick = () => {
      setActiveMediaTab(returnMedia)
      setLastMediaTypeVisited(returnMedia,this.props.userId);
      selectFlashcard(parseInt(returnFlashcardId));
    };
    return (
      <React.Fragment>
        {(currentFlashcardId == flashcardId) && (<div className={`back-card-button ${isLeftOverlay ? 'button-left-overlay': ''} ${isStudyOverlay ? 'button-study-overlay': 'study-overlay'} ${isRightOverlay ? 'button-right-overlay': ''}`} onClick={onClick}>
            <div className={'card-info'}>
              <BackCardButton />
              {/* {!isLandscape && <span className={`back-card-number`}>{flashcard.get('numbering')}</span>} */}
            </div>
        </div>)}
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state, props) => {
  if(!props.returnCardId['return-flashcard-id']) return {};
  const currentMediaValue = splitString(props.returnCardId['return-flashcard-id']);
  return ({
    flashcard: getFlashcardById(state, parseInt(currentMediaValue[1])),
    userId: getUserId(state),
    activeMediaTab: Selectors.getActiveMediaTab(state),
  });
};
const mapDispatchToProps = {
  selectFlashcard: Actions.Studying.selectFlashcard,
  setActiveMediaTab: Actions.Navigation.setActiveMediaTab,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardBackButtonContainer));