import makeAction from 'actions/makeAction';
import * as Types from './types';

export const toggleOverlay = makeAction(Types.TOGGLE_OVERLAY, 'data');

export const toolTipProps = makeAction(Types.TOOL_TIP_PROPS, 'data', 'value');

export const selectSide = makeAction(Types.SELECT_SIDE, 'sideId', 'flashcardId');

export const changeFlashcard = makeAction(Types.CHANGE_FLASHCARD, 'data', 'navigationMode');

export const selectFlashcard = makeAction(Types.SELECT_FLASHCARD, 'flashcardId', 'navigationMode', 'deskType', 'isEmptyScreen');

export const setZoomFactor = makeAction(Types.SET_ZOOM_FACTOR, 'data');
export const setFixedZoomFactor = makeAction(Types.SET_FIXED_ZOOM_FACTOR, 'value');

export const setLinks = makeAction(Types.SET_LINKS, 'data');

export const toggleSwap = makeAction(Types.TOGGLE_SWAP);

export const setCurrentFlashcard = makeAction(Types.SET_CURRENT_FLASHCARD_ID, 'data');

export const prevFlashcard = makeAction(Types.CAROUSEL_PREV_FLASHCARD);

export const nextFlashcard = makeAction(Types.CAROUSEL_NEXT_FLASHCARD);

export const nextSide = makeAction(Types.NEXT_SIDE);
export const flipFlashcardAction = makeAction(Types.FLIP_FLASHCARD, 'flashcardId', 'index');

export const setFlashcardMeta = makeAction(Types.SET_FLASHCARD_META);

export const registerDevice = makeAction(Types.REGISTER_DEVICE);
export const unRegisterDevice = makeAction(Types.UNREGISTER_DEVICE);
export const startUpdateDevice = makeAction(Types.START_UPDATE_DEVICE);
export const enableRestriction = makeAction(Types.ENABLE_RESTRICTION);
export const setNavigationMode = makeAction(Types.SET_NAVIGATION_MODE, 'navigationMode');
export const setToolboxOpenSection = makeAction(Types.SET_TOOLBOX_OPEN_SECTION, 'data');

export const setActiveFlashcardAction = makeAction(Types.SET_ACTIVE_FLASHCARD, 'id');
export const scrollToFlashcardAction = makeAction(Types.SCROLL_TO_FLASHCARD, 'id');
export const scrollToPreviewAction = makeAction(Types.SCROLL_TO_PREVIEW, 'id');
export const setActiveExerciseId = makeAction(Types.SET_ACTIVE_EXERCISE_ID, 'id');
export const selectExerciseFlashcard = makeAction(Types.SELECT_EXERCISE_FLASHCARD, 'flashcardId');
export const endExercise = makeAction(Types.END_EXERCISE);
export const initializeCarousel = makeAction(Types.INITIALIZE_CAROUSEL, 'flashcardId');

export const currentFlashcardChangedAction = makeAction(Types.CURRENT_FLASHCARD_CHANGED, 'flashcardId');

export const setCarouselTeachingLevel = makeAction(Types.SET_CAROUSEL_TEACHING_LEVEL, 'id');

export const addPendingSVGAction = makeAction(Types.ADD_PENDING_SVG, 'id');
export const removePendingSVGAction = makeAction(Types.REMOVE_PENDING_SVG, 'id');

// Last Study Flashcard
export const getLastStudyFlashcard = makeAction(Types.GET_LAST_STUDY_FLASHCARD);
export const getLastStudyFlashcardSucceeded = makeAction(Types.GET_LAST_STUDY_FLASHCARD_SUCEEDED, 'data');
export const getLastStudyFlashcardFailed = makeAction(Types.GET_LAST_STUDY_FLASHCARD_FAILED, 'error');

export const setCanvasZoomFactor = makeAction(Types.SET_CANVAS_ZOOM_FACTOR, 'value');

export const setLinksSize = makeAction(Types.SET_LINKS_SIZE, 'data');

export const setCurrentExerciseId = makeAction(Types.SET_CURRENT_EXERCISE_ID, 'id');

export const setWatchedStatus = makeAction(Types.SET_WATCHED_STATUS, 'data');

export const setChangeFlashcardContentBookmarkStatus = makeAction(Types.SET_CHANGE_FLASCHCARD_CONTENT_BOOKMARK_STATUS, 'data');

export const setExerciseId = makeAction(Types.SET_EXERCISE_ID,"id");

export const selectExams = makeAction(Types.SELECT_EXAMS, 'flashcardId', 'from','size','index','activeIndex');

export const setInProgressLastStudyFlashcard = makeAction(Types.SET_INPROGRESS_LAST_STUDY_FLASHCARD, 'value');