import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as Selectors from 'selectors';
import theme from 'theme';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export function withScrollDetectionContained(WrappedComponent, threshold1 = 20, threshold2 = 60) {
  return withScrollDetection(WrappedComponent, threshold1 = 20, threshold2 = 60, true);
}

export default function withScrollDetection(WrappedComponent, threshold1 = 20, threshold2 = 60, containedOnly = true) {
  class WithScrollDetection extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        scrolledBeyondThreshold1: false,
        scrolledBeyondThreshold2: false
      };

      this.handleScroll = this.handleScroll.bind(this);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
      this.ref && this.ref.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
      clearAllBodyScrollLocks();
      if (!this.props.isMobile && event.target !== this.ref) return;
      if (window.innerWidth < theme.sizes.phone && window.innerWidth > window.innerHeight
        && this.props.isMobile && event.target !== this.ref) return;

      let target = event.target;

      if (target.scrollingElement) {
        target = target.scrollingElement;
      }

      if (target.scrollTop > threshold1) {
        this.setState({scrolledBeyondThreshold1: true});
      } else {
        this.setState({scrolledBeyondThreshold1: false});
      }

      if (target.scrollTop > threshold2) {
        this.setState({scrolledBeyondThreshold2: true});
      } else {
        this.setState({scrolledBeyondThreshold2: false});
      }
    }

    render() {
      return <WrappedComponent isScrolled={this.state.scrolledBeyondThreshold1}
        isScrolled2={this.state.scrolledBeyondThreshold2}
        bindScrollRef={this.bindScrollRef} {...this.props} />;
    }

    bindScrollRef = (ref) => {
      if (!ref) ref = document;
      this.ref = ref;

      if (!this.props.isMobile || containedOnly) {
        this.ref && this.ref.addEventListener('scroll', this.handleScroll);
      } else {
        window.addEventListener('scroll', this.handleScroll);
      }
    }
  }

  return connect((state) => ({isMobile: Selectors.isMobile(state)}))(WithScrollDetection);
}

