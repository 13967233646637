import ExerciseBullet from 'components/exercise/ExerciseBullet';
import {ClassRoomIcon, DeskIcon, SearchIcon, TopicsIcon, WhiteTriangleDown} from 'icons/FlashcardNavIcons';
import {SubjectIcon} from 'icons/SubjectIcons';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import RouterLink from 'router/Link';
import styled, {css} from 'styled-components/macro';
import media from 'ui-components/helpers/media';

export default class FlashcardNavigationMenu extends PureComponent {
  render() {
    const {hasClassroomNotification} = this.props;

    return <StyledFlashcardNavigationMenu className="tutorial-step-flashcard-navigation">
      <MenuItem isActive={this.isIconActive(0)} url='/flashcard/topics' icon={TopicsIcon}/>
      <MenuItem isActive={this.isIconActive(1)} url='/flashcard/desk/notes' icon={DeskIcon}/>

      <EmptyIcon/>

      <ControlIcon isExpanded={this.props.isExpanded} onClick={this.props.onToggle}>
        <StyledSubjectIcon
          as={SubjectIcon}
          changeDefaultIcon={true}
          subject={this.props.subject}
        />
      </ControlIcon>

      <MenuItem hasNotification={hasClassroomNotification} isActive={this.isIconActive(2)} url='/flashcard/classroom'
        icon={ClassRoomIcon}/>
      <MenuItem isActive={this.isIconActive(3)} url='/flashcard/search' icon={SearchIcon}/>

      {this.props.isExpanded ? <BorderBottom/> : null}
    </StyledFlashcardNavigationMenu>;
  }

  isIconActive(index) {
    return this.props.activeTab === index;
  }
}

FlashcardNavigationMenu.propTypes = {
  isExpanded: PropTypes.bool,
  hasClassroomNotification: PropTypes.bool,
  activeTab: PropTypes.number,
  onToggle: PropTypes.func,
  subject: PropTypes.string,
};


const MenuItem = ({url, icon: LinkIcon, isActive, hasNotification}) =>
  <RouterLink to={url}>
    <StyledLink isActive={isActive}>
      <LinkIcon active={isActive}/>
      {hasNotification && <ExerciseBullet/>}
    </StyledLink>
  </RouterLink>;
MenuItem.propTypes = {
  icon: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  hasNotification: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
};

const ControlIcon = ({onClick, isExpanded, children}) =>
  <StyledControlIcon onClick={onClick}>
    {isExpanded ? (
      <ExpandedIconCircle><WhiteTriangleDown/></ExpandedIconCircle>
    ) : (
      <SubjectIconCircle>
        {children}
      </SubjectIconCircle>
    )}
  </StyledControlIcon>;
ControlIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};


const StyledFlashcardNavigationMenu = styled.div`
  padding: 0 14px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  background-color: white;
  bottom: -1px;
  filter: drop-shadow(0px -3px 3px rgba(0, 0, 0, 0.1));
  
  ${media.phone(css` 
    display:none;
    @media(orientation: landscape){
      display:none;
    }
  `)}
`;

const StyledLink = styled.div`
  height: 40px;
  width: 40px;
  padding: 3px 0 1px;
  box-sizing: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 2px solid transparent;
  ${props => props.isActive && css`
    border-bottom: 2px solid ${props.theme.turquoise};
  `}
`;

const BorderBottom = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e4e6e6;
`;

const StyledSubjectIcon = styled.div`
  width: 31px;
  height: 31px;
`;

const StyledControlIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: white;
  border-radius: 50%;
  z-index: 1;
  border: 4px solid white;
  cursor: pointer;
`;

const EmptyIcon = styled.div`
  width: 60px;
  height: 1px;
  background-color: transparent;
`;

const ExpandedIconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-image: linear-gradient(134deg, #48cea8, #4abad1);
  box-shadow: 0 1px 3px 0 rgba(93, 116, 114, 0.35);
  transition: all .3s;

  @media (pointer: fine) {
    &:hover {
      box-shadow: 0 3px 5px 0 rgba(93, 116, 114, 0.35);
    }
  }
  
  &:active {
    background-image: linear-gradient(134deg, #3dad8d, #3d9bad);
    box-shadow: none;
  }
`;

const SubjectIconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.turquoise};
  box-shadow: 0 1px 3px 0 rgba(93, 116, 114, 0.35);
  transition: all .3s;
  @media (pointer: fine) {
    &:hover {
      box-shadow: 0 3px 5px 0 rgba(93, 116, 114, 0.35);
  } 
  
  &:active {
    background-color: ${props => props.theme.turquoise};
  }
`;
