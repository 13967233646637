import React from 'react';
import Text from 'v2/components/common/Text';


type DifficultyTextProps = {
  // type: string,
  formCC: boolean,
  data: any
}

const DifficultyText: React.FC<DifficultyTextProps> = ({ formCC, data }) => {
  const assesmentIcon = (value: any) => {
    let val;
    if (value.includes("Accessible")) {
      if (formCC && data['difficulty'].length > 1)
        val = 'Acce...'
      else
        val = 'Accessible'
    } else if (value.includes("Moderate")) {
      if (formCC && data['difficulty'].length > 1)
        val = 'Mode...'
      else
        val = 'Moderate'
    } else if (value.includes("Discriminating")) {
      if (formCC && data['difficulty'].length > 1)
        val = 'Disc...'
      else
        val = 'Discrimin...'
    }
    switch (val) {
      case 'Accessible':
        return <Text className='text-13px font-bold leading-18px text-blue-normal filter-text'  >{val}</Text>
      case 'Unconfident':
        return <Text className='text-13px font-bold leading-18px text-blue-normal filter-text' >{val}</Text>
      case 'Discriminating':
        return <Text className='text-13px font-bold leading-18px text-blue-normal filter-text' >{val}</Text>
      default:
        return <Text className='text-13px font-bold leading-18px text-blue-normal filter-text' >{val || 'Difficulty'}</Text>
    }
  }
  return assesmentIcon(data['difficulty'])
}


export default DifficultyText