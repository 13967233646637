import React from 'react';
import classNames from 'classnames';
import { AddToBookmarkIcon } from 'icons/FolderIcons';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveMediaTab } from 'actions/navigation';
import { addFlashcardToBookmarkRoutine, removeFlashcardFromBookmarkRoutine } from 'actions/api/apiActions'
import { getFolderAddButtonActive, getActiveMediaTab } from 'selectors/navigation/navigationSelector';
import { addFlashcardToBookmarkResponse, removeFlashcardFromBookmarkResponse } from 'selectors/api/apiSelector';
import { currentFlashcardId } from 'selectors/studying';
import { getUserId } from 'selectors/user';
import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2';
import { useHistory } from 'react-router-dom';
import { getLastMediaTypeVisited } from 'helpers';

type AddFlashcardToBookmarkContainerProps = {
  flashcardFolders: any;
  isFolderPage?: boolean;
}

const AddFlashcardToBookmarkContainer: React.FC<AddFlashcardToBookmarkContainerProps> = ({ flashcardFolders, isFolderPage }) => {
  const { location } = useHistory();
  const dispatch = useDispatch();
  const folderAddButtonActive = useSelector(getFolderAddButtonActive);
  const addFlashcardToBookmark = useSelector(addFlashcardToBookmarkResponse)?.toJS();
  const removeFlashcardFromBookmark = useSelector(removeFlashcardFromBookmarkResponse).toJS();
  const currentId = useSelector(currentFlashcardId);
  const userId = useSelector(getUserId);
  const activeMediaTab = useSelector(getActiveMediaTab);
  const mediaType = getLastMediaTypeVisited(userId);

  const isInFolder = () => {
    if (isFolderPage) {
      if (flashcardFolders.length > 0) {
        return flashcardFolders.filter((item: any) => item.flashcardId === currentId).length > 0;
      } else {
        return false
      }
    } else {
      if (flashcardFolders === 0) return false;
      if (flashcardFolders === 1) return true;
    }
  };
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (activeMediaTab !== mediaType) {
      dispatch(setActiveMediaTab(mediaType));
    }
    if (isInFolder()) {
      dispatch(removeFlashcardFromBookmarkRoutine({ mediaType: mediaType, fromPage: location.pathname.indexOf('/flashcard/desk/folders/bookmark') }));
    } else {
      dispatch(addFlashcardToBookmarkRoutine({ mediaType: mediaType, fromPage: location.pathname.indexOf('/flashcard/desk/folders/bookmark') }));
    }
  }
  return <div className={classNames('bookmark-icon-wrapper p-1.5 rounded-full select-none cursor-pointer active:border-none active:bg-[#E5ECF2] [&_svg]:block', { 'pointer-events-none': !folderAddButtonActive })} onClick={handleClick}>
    <>
      {
        addFlashcardToBookmark.loading || removeFlashcardFromBookmark.loading ?
          <Loader size={SizeEnum.VSmall} background={BackgroundEnum.Blue} /> :
          <>
            {currentId && !isInFolder() && (<AddToBookmarkIcon />)}
            {currentId && isInFolder() && (<AddToBookmarkIcon state='added' isHover={false} />)}
          </>
      }
    </>
  </div>
}

export default AddFlashcardToBookmarkContainer