export const titles: any = {
    'notes': 'Add your first note',
    'folders': 'Add any media',
    'filterFolders': 'No match found for your filter',
    'repeat': 'The cards flagged for extra practice',
    'memorized': 'The cards you’ve aced',
    'markings': 'Start highlighting!',
    'exercises': 'Add your first exercise!',
    'search': 'One search screen for all media and tools',
    'links': 'No links available!',
    'filterNotes':'No match found for your filter',
    'topic':{
      'flashcard': 'No match found for this topic',
      'revision':'No match found for this topic',
      'video':'No match found for this topic',
      'exams':'No match found for this topic',
    },
    'subtopic':{
      'flashcard': 'No match found for this subtopic',
      'revision':'No match found for this subtopic',
      'video':'No match found for this subtopic',
      'exams':'No match found for this subtopic'
    },
    'bookmark':'Add bookmarks',
    'filterTopic':{
      'flashcard': 'No match found for your filter',
      'revision':'No match found for your filter',
      'video':'No match found for your filter',
      'exams':'No match found for your filter'
    },
    'filterSubTopic':{
      'flashcard': 'No match found for your filter',
      'revision':'No match found for your filter',
      'video':'No match found for your filter',
      'exams':'No match found for your filter'
    },
    'filterExercise':{
      'flashcard': 'No match found for your filter'
    },
    'filterSearch':{
      'flashcard': 'No match found for your filter',
      'revision':'No match found for your filter',
      'video':'No match found for your filter',
      'exams':'No match found for your filter'
    }
  };
  export const subtitles: any = {
    'notes': 'With notes, you can add anything you feel is relevant – class notes, questions, to-do’s, etc.',
    'folders': 'Folders let you organise the apps media to suit your personal study needs – sort the cards, pages, videos and exam questions that link to concepts you need to revisit, those that include notes, etc.',
    'repeat': 'As your studying progresses, this smart folder will show all of the cards you tried to answer but haven\'t scored \'correct\' three times in a row.',
    'memorized': 'As your studying progresses, this smart folder will show all of the cards you answered and scored \'correct\' three times in a row.',
    'markings': 'This is where you’ll see the preview of all the markings you’ve added.',
    'exercises': 'This is where you\'ll see an overview of all your exercises.',
    'search': 'Access the apps media, including your notes, folders, and exercises from one convenient search screen. This is where you’ll see your recent searches displayed.',
    'filterNotes': 'Although you’ve added to exam questions, none of the questions matches the filter you set.',
    'links': 'Links always displays all of the cards, sub-topics and topics that our author feels is relevant for the card currently on view.',
    'topic':{
      'flashcard': <>This app features 5,000+ cards, but none for this topic.</>,
      'revision':<>The revision guide does not cover this topic at present.</>,
      'video':<>There’s no suitable video for this topic yet.</>,
      'exams':<>There are no exam questions that cover this topic at present.</>
    },
    'subtopic':{
      'flashcard': <>This app features 5,000+ cards, but none for this subtopic.</>,
      'revision':<>The revision guide does not cover this subtopic at present.</>,
      'video':<>There’s no suitable video for this subtopic yet.</>,
      'exams':<>There are no exam questions that cover this subtopic at present.</>
    },
    'bookmark':'Bookmark any card, page, video or exam question that you wish to return to later.',
    'filterTopic':{
      'flashcard': <>Although flashcards cover this topic, none match the filter you set.</>,
      'revision':<>Although the revision guide covers this topic, none of the pages match the filter you set.</>,
      'video':<>Although videos cover this topic, none match the filter you set.</>,
      'exams':<>Although exam questions cover this topic, none match the filter you set.</>
    },
    'filterSubTopic':{
      'flashcard': <>Although flashcards cover this subtopic, none match the filter you set.</>,
      'revision':<>Although the revision guide covers this subtopic, none of the pages match the filter you set.</>,
      'video':<>Although videos cover this subtopic, none match the filter you set.</>,
      'exams':<>Although exam questions cover this subtopic, none match the filter you set.</>
    },
    'filterExercise':{
      'flashcard':<>None of the flashcards match the filter you set.</>
    },
    'filterSearch':{
      'flashcard': <>Some flashcards include your search term, but none matches the filter you set.</>,
      'revision':<>Some revision guide pages include your search term, but none matches the filter you set.</>,
      'video':<>Some videos include your search term, but none matches the filter you set.</>
    },
    
  };
  
  export const contents: any = {
    notes:{
      'flashcard':<>Notes are added to the media currently on view. Do you want to add a note to the first card in this subject?</>,
      'revision':<>Notes are added to the media currently on view. Do you want to add a note to the first page in this subject?</>,
      'video':<>Notes are added to the media currently on view. Do you want to add a note to the first video in this subject?</>,
      'exams':<>Notes are added to the media currently on view. Do you want to add a note to the first exam question in this subject?</>
    },
    folders:{
      'flashcard':<>Media must be displayed in order to add it to a folder. Do you want to view the first card in this subject?</>,
      'revision':<>Media must be displayed in order to add it to a folder. Do you want to view the first page in this subject?</>,
      'video':<>Media must be displayed in order to add it to a folder. Do you want to view the first video in this subject?</>,
      'exams':<>Media must be displayed in order to add it to a folder. Do you want to view the first question in this subject?</>
    },
    topic:{
      'flashcard':<>Content development is ongoing; we’ll update you as topic coverage expands.</>,
      'revision':<>Content development is ongoing; we’ll update you as topic coverage expands.</>,
      'video':<>Content development is ongoing; we’ll update you as topic coverage expands.</>,
      'exams':<>Content development is ongoing; we’ll update you as topic coverage expands.</>
    },  
    subtopic:{
      'flashcard':<>Content development is ongoing; we’ll update you as subtopic coverage expands.</>,
      'revision':<>Content development is ongoing; we’ll update you as subtopic coverage expands.</>,
      'video':<>Content development is ongoing; we’ll update you as subtopic coverage expands.</>,
      'exams':null
    }, 
    bookmark:{
      'flashcard':<>Media must be displayed in order to bookmark it. Do you want to view the first card in this subject?</>,
      'revision':<>Media must be displayed in order to bookmark it. Do you want to view the first page in this subject?</>,
      'video':<>Media must be displayed in order to bookmark it. Do you want to view the first video in this subject?</>,
      'exams':<>Media must be displayed in order to bookmark it. Do you want to view the first exam question in this subject?</>
    },
    filterTopic:{
      'flashcard':<><strong>To view flashcards, change the filter above.</strong></>,
      'revision':<><strong>To view the revision guide, change the filter above.</strong></>,
      'video':<><strong>To view the videos, change the filter above.</strong></>,
      'exams':<><strong>To view exam questions, change the filter above.</strong></>
    },
    filterSubTopic:{
      'flashcard':<><strong>To view flashcards, change the filter above.</strong></>,
      'revision':<><strong>To view the revision guide, change the filter above.</strong></>,
      'video':<><strong>To view the videos, change the filter above.</strong></>,
      'exams':<><strong>To view exam questions, change the filter above.</strong></>
    },
    filterExercise:{
      'flashcard':<><strong>To view flashcards, change the filter above.</strong></>
    },
    filterSearch:{
      'flashcard':<><strong>To refresh search results, change the filter above.</strong></>,
      'revision':<><strong>To refresh search results, change the filter above.</strong></>,
      'video':<><strong>To refresh search results, change the filter above.</strong></>,
      'exams':<><strong>To refresh search results, change the filter above.</strong></>,
    },
    filterNotes:{
      'exams':<><strong>To view your notes, change the filter above.</strong></>,
    }
  };

  export const fcTitles: any = {
    'notes': 'Add note',
    'folders': 'Create Folder',
    'exercises': 'Add exercise'
  }
  export const fcSubtitles: any  = {
    'notes': 'With notes, you can add anything you feel is relevant – class notes, questions, to-do’s, etc.',
    'folders': 'Once you create a folder, you can add unlimited cards, pages, videos and exam questions to it, helping you keep track of your own learning.',
    'exercises': 'Exercises allow you to put together the cards you want to practice in one go. Add a comment and due date, then get right to the point, every time.'
  };
  export const fcContents: any = {
    'notes': 'Notes are always added to the media currently on view.',
  };
  export const fcLink: any = {
    'notes': '/flashcard/desk/notes/edit',
    'folders': '/flashcard/desk/folders/edit',
    'exercises': '/flashcard/desk/exercise/create'
  };