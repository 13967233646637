import { getLastMediaTypeVisited } from 'helpers';
import { useDispatch, useSelector } from 'react-redux'
import { apiWaitingResponse, getActiveFlashcardTab, getActiveMediaTab, isDeskEditModeActive, scrollPosition } from 'selectors/navigation/navigationSelector';
import { flashcardsWithNotes, getLastMediaTypeNotes, isDeskRequestInProgress } from 'selectors/desk/deskSelector';
import { getUserId, isMBFromSchool, isOSCUser } from 'selectors/user';
import { activeSubjectId, getActiveSubject, subjectsTeachingLevel } from 'selectors/subject/subjectSelector';
import { currentFlashcardId } from 'selectors/studying';
import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { saveScrollPosition, setActiveFlashcardTab, setDocumentTitle, toggleExamsMode } from 'actions/navigation';
import { getTopicIdByTeachingLevel } from 'selectors/api/apiSelector';
import { getAllTopicsInSubject } from 'actions/api';
import { multipleDeleteNote, selectAllFlashcardsWithNotes, setLastMediaTypeNotes, getFlashcardsPreviewWithNotes } from 'actions/desk/deskActions';
import DeskNavigationHeader from 'v2/components/navigation-header/DeskNavigationHeader';
import { isLandscape, isMobile, isPortrait } from 'selectors/browser/browserSelector';
import classNames from 'classnames';
import FreeTrialNotification from 'v2/components/free-trial-notification/FreeTrialNotification';
import FooterEditModeContainer from 'v2/components/footer-edit-mode/FooterEditModeContainer';
import MediaTypeTabSelector from 'v2/components/media-tab/MediaTypeTabSelector';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import FlashcardEmptyScreen from 'v2/components/empty-screen-message/FlashcardEmptyScreen';
import { NoNotesIcon } from 'icons/FlashcardIcons';
import ExamsFilterTile from 'v2/components/filter-overlay/exams-filter-container/ExamsFilterTile';
import EmptyScreenMessage from 'v2/components/empty-screen-message/EmptyScreenMessage';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import ListFlashcardPreviewContainer from 'v2/components/list-flashcard-preview/ListFlashcardPreviewContainer';
import { debounce } from 'lodash';
import usePrevious from 'hooks/usePrevious';
import { useNoteData } from 'hooks/useNoteData';
import useScrollDetection from 'hooks/useScrollDetection';
import { fromJS } from 'immutable';
import './Notes.scss'

const NotesPage = () => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  let notesRef:HTMLDivElement;
  const getFlashcardsWithNotes = useSelector(flashcardsWithNotes)?.toJS();
  const hasDeskEditModeActive = useSelector(isDeskEditModeActive);
  const subjectId = useSelector(activeSubjectId);
  const currentId = useSelector(currentFlashcardId);
  const activeMediaTab = useSelector(getActiveMediaTab);
  const subject = useSelector(getActiveSubject);
  const lastMediaTypeNotes = useSelector(getLastMediaTypeNotes);
  const topicIdByTeachingLevel = useSelector(getTopicIdByTeachingLevel);
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);
  const hasPortrait = useSelector(isPortrait);
  const getSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
  const hasDeskRequestInProgress = useSelector(state => isDeskRequestInProgress(state, 'flashcardsWithNotes'));
  const hasApiResponseLoading = useSelector(apiWaitingResponse)
  const getScrollPosition = useSelector(scrollPosition).toJS();
  const activeTab = useSelector(getActiveFlashcardTab);
  const hasMBFromSchool = useSelector(isMBFromSchool);
  const hasOSCUser = useSelector(isOSCUser);
  const subjectsTeachingLevels = useSelector(subjectsTeachingLevel)
  const teachingLvl = subjectsTeachingLevels[subjectId]
  const prevTeachingLvl = usePrevious(teachingLvl)
  const mediaType = getLastMediaTypeVisited(userId);
  const isMobileLandscape = (hasMobile && hasLandscape);
  const isMobilePortrait = (hasMobile && hasPortrait);
  const prevMediaTab = usePrevious(activeMediaTab);
  const prevSubject = usePrevious(subject);
  const prevTab = usePrevious(activeTab);
  const prevSubjectsTeachingLevel = usePrevious(getSubjectsTeachingLevel);
  const { flashcards, newFilterItems } = useNoteData();
  let wrapperRef = useRef<HTMLDivElement>(null);
  const { isScrolled, bindScrollRef } = useScrollDetection({ WrappedRef: wrapperRef, threshold1: 20 });
  const isLoading = hasDeskRequestInProgress || hasApiResponseLoading

  const handleScroll = debounce((event: any) => {
    const scrollTop = event.target.scrollTop;
    dispatch(saveScrollPosition('notes', scrollTop))
  }, 300);

  const renderViewNoteMode = ():ReactElement => {
    if(isMobilePortrait || hasDeskEditModeActive) return <></>;
    return <div className="note-icon">
      {getFlashcardsWithNotes.length === 0 ? <div className='font-bold text-13px leading-18px text-right tracking-2px uppercase pointer-events-none text-#C8CCCC'>EDIT</div> : null}
    </div>
  }
  useEffect(() => {
    dispatch(setActiveFlashcardTab(2));
    dispatch(setDocumentTitle('Notes'));
    if (getFlashcardsWithNotes.length === 0 || activeMediaTab !== mediaType || (lastMediaTypeNotes && lastMediaTypeNotes !== activeMediaTab)) {
      dispatch(getFlashcardsPreviewWithNotes(true));
    }
    if (typeof topicIdByTeachingLevel == 'undefined') {
      dispatch(getAllTopicsInSubject({
        key: subject.id,
        subjectId: subject.id,
        mapAnswers: true
      }))
    }
    dispatch(setLastMediaTypeNotes(activeMediaTab));
    if (notesRef) {
      notesRef.scrollTop = getScrollPosition.notes;
    }
    if (!(hasOSCUser || hasMBFromSchool)) {
      dispatch(toggleExamsMode(true));
    }
    return () => {           
      if (notesRef) {        
        notesRef.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])
  useEffect(() => {
    if (prevMediaTab !== activeMediaTab || prevSubject?.id !== subject.id || activeTab !== prevTab || teachingLvl !== prevTeachingLvl) {
      dispatch(getFlashcardsPreviewWithNotes());
      dispatch(setLastMediaTypeNotes(activeMediaTab))
    }
    if (subject.id !== prevSubject?.id) {
      if (wrapperRef.current) {
        wrapperRef.current.scrollTop = 0;
      }
    }
    if (getSubjectsTeachingLevel != prevSubjectsTeachingLevel && activeMediaTab === 'exams') {
      dispatch(getFlashcardsPreviewWithNotes())
    }
  }, [activeMediaTab, prevMediaTab, subject, prevSubject, activeTab, prevTab, getSubjectsTeachingLevel, prevSubjectsTeachingLevel, dispatch, teachingLvl, prevTeachingLvl])

  const renderBody = () => {
    if (flashcards && flashcards.length > 0) {
      return <>
        <div className={classNames('pt-3.5', { 'opacity-70 pointer-events-none': (mediaType !== activeMediaTab) || hasDeskEditModeActive })}><FlashcardEmptyScreen activeMedia={activeMediaTab} editing={hasDeskEditModeActive} size={getFlashcardsWithNotes.length} type='notes' className={currentId ? '' : 'no-flashcard'}><NoNotesIcon color={currentId ? '#378EF8' : '#C8CCCC'} /></FlashcardEmptyScreen></div>
        {activeMediaTab === 'exams' && <ExamsFilterTile isEditMode={false} type={'notes'} />}

        {newFilterItems.length > 0 ? <ListFlashcardPreviewContainer type="notes" list={fromJS(newFilterItems)} /> :
          <EmptyScreenMessage className={'without-margin empty-folder-screen'} type={'filterNotes'} canvasType={mediaType} mediaType={activeMediaTab} currentId={currentId} ><FilterIcon /></EmptyScreenMessage>
        }
      </>
    }
    if (getFlashcardsWithNotes.length == 0 && mediaType === activeMediaTab) {
      return <div className={classNames('pt-3.5', { 'opacity-70 pointer-events-none': hasDeskEditModeActive || mediaType !== activeMediaTab })}><FlashcardEmptyScreen activeMedia={activeMediaTab} type='notes' size={getFlashcardsWithNotes.length} editing={hasDeskEditModeActive} className={currentId ? '' : 'no-flashcard'}><NoNotesIcon /></FlashcardEmptyScreen></div>
    }
    return <EmptyScreenMessage type='notes' canvasType={mediaType} mediaType={activeMediaTab} className={'empty-note-screen'}><NoNotesIcon /></EmptyScreenMessage>;
  }
  const bindRef = useCallback((ref: HTMLDivElement) => {
    bindScrollRef(ref)
    notesRef = ref;
    if (notesRef) {
      notesRef.addEventListener('scroll', handleScroll);
    }
  }, [])
  return <div className='h-full'>
    <DeskNavigationHeader
      isCollapsed={isScrolled}
      tabTitle={'Notes'}
      canEdit={getFlashcardsWithNotes.length > 0}
      isScrolled={isScrolled}
      showButton={!isMobileLandscape}
      rightButton={renderViewNoteMode()}
      extendedHeight
    />
    <div ref={bindRef} className={classNames('pt-14 pb-[150px] h-full scroll-smooth overflow-auto', { 'flex flex-col pb-[1%]': getFlashcardsWithNotes.length < 1 })}>
      {!isMobileLandscape && <>
        <FreeTrialNotification />
        <div className='pt-1 px-5 font-bold text-33px leading-45px text-#2D3838 pb-2'>Notes </div>
      </>}
      <MediaTypeTabSelector subjectId={subjectId} />
      {isLoading ? <LeftMenuLoader /> : renderBody()}
    </div>
    <FooterEditModeContainer
      onDelete={() => multipleDeleteNote()}
      itemsCount={getFlashcardsWithNotes.length}
      onSelectAll={() => dispatch(selectAllFlashcardsWithNotes())}
    />
  </div>
}

export default NotesPage