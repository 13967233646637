import React from 'react';

export const CardsIcon = props => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.1226 3.42456L17.6469 4.81046C18.583 5.09722 19.124 6.07377 18.8706 7.01949L16.5013 15.8618C16.2414 16.8317 15.2444 17.4073 14.2745 17.1475L14.0882 17.0976" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.3" strokeLinecap="round"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M6.36328 4.81818C6.36328 3.81403 7.17731 3 8.18146 3H11.8178C12.822 3 13.636 3.81403 13.636 4.81818V13.9091C13.636 14.9132 12.822 15.7273 11.8178 15.7273H8.18146C7.17731 15.7273 6.36328 14.9132 6.36328 13.9091V4.81818Z" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.3"/>
          <path d="M6.93994 3.42456L2.41563 4.81046C1.47949 5.09722 0.938524 6.07377 1.19193 7.01949L3.56122 15.8618C3.82111 16.8317 4.81809 17.4073 5.78803 17.1475L5.97426 17.0976" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.3" strokeLinecap="round"/>
      </svg>;
};
export const RevisionsIcon = props => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 3.5C2 2.11929 3.11929 1 4.5 1H15.5C16.8807 1 18 2.11929 18 3.5V16.5C18 17.8807 16.8807 19 15.5 19H4.5C3.11929 19 2 17.8807 2 16.5V3.5Z" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.3"/>
        <path d="M6.5 1.5V18.5" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.3" strokeLinecap="square"/>
        </svg>;
};
export const VideosIcon = props => {
  return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <title>Icons/Content/Video/Black Copy</title>
    <g id="Icons/Content/Video/Black-Copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Video">
            <rect id="Placeholder" x="0" y="0" width="20" height="20"></rect>
            <g id="Icon" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.3">
                <path d="M10,0.65 C12.5819312,0.65 14.9194312,1.6965344 16.6114484,3.3885516 C18.3034656,5.08056879 19.35,7.41806879 19.35,10 C19.35,12.5819312 18.3034656,14.9194312 16.6114484,16.6114484 C14.9194312,18.3034656 12.5819312,19.35 10,19.35 C7.41806879,19.35 5.08056879,18.3034656 3.3885516,16.6114484 C1.6965344,14.9194312 0.65,12.5819312 0.65,10 C0.65,7.41806879 1.6965344,5.08056879 3.3885516,3.3885516 C5.08056879,1.6965344 7.41806879,0.65 10,0.65 Z M10.1128713,4.79869485 C9.69198448,4.7575196 9.26414121,4.95095652 9.02480195,5.33389933 L9.02480195,5.33389933 L4.77160813,12.2850753 L4.7549971,12.417635 C4.73062243,12.7676496 4.86298807,13.0872098 5.08895102,13.3131728 C5.29706009,13.5212819 5.58456009,13.65 5.90212382,13.65 L5.90212382,13.65 L14.5145977,13.5726629 L14.6502904,13.5086882 C14.9519145,13.3428648 15.1476806,13.0651521 15.2184261,12.7585884 C15.285714,12.4670075 15.2399029,12.1493269 15.0645047,11.8769951 L15.0645047,11.8769951 L10.2387332,4.8181309 Z" id="Combined-Shape" transform="translate(10.000000, 10.000000) rotate(-270.000000) translate(-10.000000, -10.000000) "></path>
            </g>
        </g>
    </g>
</svg>;
};
export const NotesIcon = props => {
  return <svg width="18px" height="18px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="MB/Content-Elements/Search-Bar/Fifth" transform="translate(-178.000000, -8.000000)"
        stroke={props.color ? props.color : '#FFFFFF'}>
        <g id="Icons/Content/Notes/White" transform="translate(177.000000, 8.000000)">
          <g id="Page-1" transform="translate(1.000000, 1.000000)">
            <path
              d="M10.65,0.75 L3.75,0.75 C2.09314575,0.75 0.75,2.09314575 0.75,3.75 L0.75,14.25 C0.75,15.9068542 2.09314575,17.25 3.75,17.25 L14.25,17.25 C15.9068542,17.25 17.25,15.9068542 17.25,14.25 L17.25,7.35"
              id="Stroke-9" strokeWidth="1.3" strokeLinecap="round"></path>
            <path
              d="M10.9162105,9.42073567 L17.5722917,2.76465448 C17.9952896,2.34165652 18.1085159,1.28024409 17.5625934,0.643215089 L17.3067594,0.381074837 C16.669826,-0.17846346 15.5941766,-0.0664793614 15.1676434,0.360006156 L8.51156216,7.01608735 C7.72015986,7.80748965 7.16588827,8.80447145 6.91138603,9.89436309 L6.56818182,11.364116 L8.03793474,11.0209118 C9.12782638,10.7664096 10.1248082,10.212138 10.9162105,9.42073567 Z"
              id="Stroke-1" strokeWidth="1.3"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>;
};

export const FoldersIcon = props => {
  return <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="MB/Content-Elements/Search-Bar/Fifth" transform="translate(-244.000000, -9.000000)"
        stroke={props.color ? props.color : '#FFFFFF'}>
        <g id="Icons/Content/Folders/White" transform="translate(244.000000, 8.000000)">
          <g id="Icon-Copy" transform="translate(0.300000, 0.500000)">
            <g id="Folder" transform="translate(0.400000, 0.900000)" strokeWidth="1.3">
              <path
                d="M0.499988842,14 L0.499988842,2.99999997 C0.499988842,1.89543047 1.39541934,0.99999997 2.49998884,0.99999997 L5.7573593,0.99999997 C6.55300878,0.99999997 7.31607052,1.3160705 7.87867966,1.87867964 L9,3 L16,3 C17.1045695,3 18,3.8954305 18,5 L18,14 C18,15.1045695 17.1045695,16 16,16 L2.49998884,16 C1.39541934,16 0.499988842,15.1045695 0.499988842,14 Z"
                id="Stroke-1" strokeLinecap="round"></path>
              <path d="M17.5,6 L0.5,6" id="Line-3" strokeLinecap="square"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>;
};

export const ExercisesIcon = props => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2.5" y="4" width="3.75" height="12" rx="1.125" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.3"/>
    <path d="M1 10C1 9.17157 1.67157 8.5 2.5 8.5V8.5V11.5V11.5C1.67157 11.5 1 10.8284 1 10V10Z" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.3"/>
    <path d="M19 10C19 9.17157 18.3284 8.5 17.5 8.5V8.5V11.5V11.5C18.3284 11.5 19 10.8284 19 10V10Z" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.3"/>
    <rect x="6.25" y="8.875" width="7.5" height="2.25" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.3"/>
    <rect x="13.75" y="4" width="3.75" height="12" rx="1.125" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.3"/>
  </svg>;
};

export const ExamsIcon =props =>{
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="12.1646" y="1" width="5.92941" height="5.92941" rx="0.847059" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.10118"/>
  <rect x="12.1646" y="8.62353" width="5.92941" height="5.92941" rx="0.847059" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.10118"/>
  <rect x="2" y="12.8588" width="5.92941" height="5.92941" rx="0.847059" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.10118"/>
  <rect x="2" y="5.23529" width="5.92941" height="5.92941" rx="0.847059" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.10118"/>
  <path d="M7.08219 3.11765H6.65866C5.48912 3.11765 4.54102 4.06575 4.54102 5.23529V5.23529" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.52471"/>
  <path d="M7.08252 3.11765H12.1649" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.52471"/>
  <path d="M14.7058 6.92941V8.62353" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.52471"/>
  <path d="M14.7057 14.5529V14.5529C14.7057 15.7225 13.7576 16.6706 12.588 16.6706H7.9292" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.52471"/>
  <path d="M4.54121 11.1647V12.8588" stroke={props.color ? props.color : '#FFFFFF'} strokeWidth="1.52471"/>
  </svg>
  
}