import React, {memo} from 'react'

type RendererAreaProps = {
    width: number;
    height: number; 
    isZoomed: boolean;
    children: React.ReactNode 
}  

const RendererArea: React.FC<RendererAreaProps> = ({width, height, isZoomed, children}) => {
    const renderAreaStyle = {
        width: width,
        minWidth: width,
        height: height,
    }
    return <div className={`renderer-area ${isZoomed && "draggable"} `} id='drag-area' style={renderAreaStyle}>
       {children}
    </div>
}

export default memo(RendererArea);