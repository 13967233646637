import { useDispatch, useSelector } from 'react-redux';
import { activeSearchTab, getActiveMediaTab, isMBFromSchool, isOSCUser } from 'selectors'
import { CardsIcon, ExercisesIcon, FoldersIcon, NotesIcon, RevisionsIcon, VideosIcon, ExamsIcon } from 'icons/SearchIcons';
import { useEffect, useMemo } from 'react';
import { setActiveSearchTab } from 'actions/search';
import classNames from 'classnames';

enum ThemeEnum{
  Blue = '#378EF8',
  White = '#FFFFFF'
}

const SearchTabs = () => {
  const dispatch = useDispatch();
  const hasActiveSearchTab = useSelector(activeSearchTab);
  const activeMediaTab = useSelector(getActiveMediaTab);
  const hasMBFromSchool = useSelector(isMBFromSchool);
  const hasOSCUser = useSelector(isOSCUser)
  const searchActiveIcon = useMemo(() => {
    switch (activeMediaTab) {
      case "flashcard":
        return ({ "key": "Cards", "icon": <CardsIcon color={ThemeEnum.White} />, "activeIcon": <CardsIcon color={ThemeEnum.Blue} /> })
      case "revision":
        return ({ "key": "Cards", "icon": <RevisionsIcon color={ThemeEnum.White} />, "activeIcon": <RevisionsIcon color={ThemeEnum.Blue} /> })
      case "video":
        return ({ "key": "Cards", "icon": <VideosIcon color={ThemeEnum.White} id="video-active" />, "activeIcon": <VideosIcon color={ThemeEnum.Blue} id="video-active" /> })
      case "exams":
        return ({ "key": "Cards", "icon": <ExamsIcon color={ThemeEnum.White} />, "activeIcon": <ExamsIcon color={ThemeEnum.Blue} /> })
      default:
        return ({ "key": "Cards", "icon": <CardsIcon color={ThemeEnum.White} />, "activeIcon": <CardsIcon color={ThemeEnum.Blue} /> })
    }
  }, [activeMediaTab])

  const isExercise = (key: string, index: number) => {
    if (activeMediaTab !== "flashcard" && key === "Exercises" && index === 4) {
      return true;
    }
  }
  const searchTabs = [
    // {key: 'All', icon: null},
    { key: searchActiveIcon.key, icon: searchActiveIcon.icon, activeIcon: searchActiveIcon.activeIcon },
    { key: 'Notes', icon: <NotesIcon color={'white'} />, activeIcon: <NotesIcon color={'#378EF8'} /> },
    { key: 'Folders', icon: <FoldersIcon color={'white'} />, activeIcon: <FoldersIcon color={'#378EF8'} /> },
    { key: 'Exercises', icon: <ExercisesIcon color={'white'} />, activeIcon: <ExercisesIcon color={'#378EF8'} /> }
  ];

  useEffect(() => {
    if(!(hasOSCUser || hasMBFromSchool) && activeMediaTab === 'exams' && ['Cards', 'Exercises'].includes(hasActiveSearchTab)) {
      dispatch(setActiveSearchTab('Notes'))
    }
  },[activeMediaTab])

  return <div className='bg-light-grey rounded-[18px] tracking-2px h-9 justify-around flex items-center'>
    {searchTabs.map((item, index) => {
      if(activeMediaTab === 'exams' && !(hasOSCUser || hasMBFromSchool) && [0, 3].includes(index)) return <></>;
      const isActive = hasActiveSearchTab === item.key;
      const content = isActive ? item.activeIcon || item.key : item.icon || item.key;
      return <div className={classNames('bg-transparent uppercase text-13px font-bold p-5px items-center justify-center flex cursor-pointer box-border h-9', { 'w-1/4':(hasOSCUser || hasMBFromSchool), 'w-1/2':!(hasOSCUser || hasMBFromSchool), '!bg-white rounded-[18px] shadow-#3E5755': isActive, '!pointer-events-none': isExercise(item.key, index) })} onClick={() => dispatch(setActiveSearchTab(item.key))} key={item.key} >
        {content}
      </div>
    })}
  </div>
}

export default SearchTabs;