import React, {Component} from 'react';
import NavigationHeader from 'components/navigation-header/NavigationHeader';
import BackButton from 'components/navigation-header/BackButton';
import * as FlashcardIcons from 'icons/FlashcardIcons';
/**
 * @deprecated TODO- delete not use any more
 */
export default class HeaderNotesTooltip extends Component {
  render() {
    return (
      <React.Fragment>
        <NavigationHeader
          isCollapsed={this.props.isCollapsed}
          extendedWidth
          contained
          title={(
            <div className="notes-title-container">
              <FlashcardIcons.NotesIcon color="#000000"/>
              <div className="notes-title">Notes</div>
            </div>
          )}
          leftSection={(
            this.props.deleteConfirmationMode ?
              <BackButton onClick={() => this.props.toggleDeleteConfirmationMode(false)} text="Cancel" hideArrow={true}/> : <div/>
          )}
          rightSection={null}/>
      </React.Fragment>
    );
  }
}
