import Immutable from 'immutable';

export const getUserId = (state) => state.getIn(['user', 'id'], null);

/**
 * @deprecated use userDataSelector
 */
export const getUserData = (state) => {
  return state.getIn(['user'], Immutable.Map()).toJS();
};
// export const getUserData = (state) => {
//   const users = state.get(['user'], []);
//   console.log("usefrss", users)
//   return users || []
// };
export const userDataSelector = (state) => state.get('user');

export const isAuthenticated = (state) => {
  return (state.getIn(['user', 'id'], null) != null);
};

export const userAvatar = (state) => {
  return state.getIn(['user', 'avatar'], null);
};

export const getAvatars = (state) => state.getIn(['user', 'avatars']).toJS();

export const getActiveAvatar = (state) => state.getIn(['user', 'avatars']).filter(item => item.get('active')).first();

export const getUserInitials = (state) => {
  const user = state.get('user');
  return user.get('firstName') ? (user.get('firstName').charAt(0) + user.get('lastName').charAt(0)).toUpperCase() : '';
};

export const isAvatarComponentExpanded = (state) => state.getIn(['user', 'isAvatarComponentExpanded']);
export const isMBUserSelector = (state) => !!(state.getIn(['user', 'mbId'], false) && state.getIn(['user', 'subdomain']));
export const hasMBIdentitySelector = (state) => !!(state.getIn(['user', 'mbId'], false));
export const getMBIdentity = (state) => state.getIn(['user', 'mbId']);
export const userMBRoleSelector = state => state.getIn(['user', 'mbRole'], null);
export const isMBFromSchool = (state) => state.getIn(['user', 'fromSchool']);
export const userPlatformType = (state) => state.getIn(['user', 'userPlatformType'], '')
export const isOSCUser = (state) => state.getIn(['user', 'userPlatformType'], '') === 'smpp'
