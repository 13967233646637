import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';

class RendererArea extends PureComponent {
  componentDidCatch(error, info) {
  }
  render() {
    let {width, height, children, isZoomed} = this.props;
    const renderAreaStyle = {
      width: width,
      minWidth: width,
      height: height,
    };

    return <div className={`renderer-area ${isZoomed ? "draggable" : ""} `}
      id='drag-area'
      style={renderAreaStyle}>
      {children}
    </div>;
  }
}

RendererArea.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
  children: PropTypes.node,
};

export default RendererArea;
