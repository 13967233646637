import {List} from 'immutable';
import React, {PureComponent} from 'react';
import {getSelectedTreeNodes} from 'selectors/topicsTreeSelector';
import styled, {css} from 'styled-components/macro';
import media from 'ui-components/helpers/media';
import Row from 'ui-components/helpers/Row';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

class SelectedCardsButtonWrapper extends PureComponent {
  render() {
    const {selectedTreeNodes, isIframe} = this.props;

    if (selectedTreeNodes.size === 0) return null;
    if (selectedTreeNodes.size === 1) return <SelectedItemsWrapper isIframe={isIframe}>
      <SelectedCardsButton>{selectedTreeNodes.size} card selected</SelectedCardsButton>
    </SelectedItemsWrapper>;
    return <SelectedItemsWrapper isIframe={isIframe}>
      <SelectedCardsButton>{selectedTreeNodes.size} cards selected</SelectedCardsButton>
    </SelectedItemsWrapper>;
  }
}

const SelectedCardsButton = styled(Row)`
  background-image: linear-gradient(225deg, #43DAF0 0%, #395BF8 100%);
  box-shadow: 0 2px 5px 1px rgba(62,87,85,0.22);
  font-weight: bold;
  font-size: 13px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  padding: 8px 11px;
  border-radius: 18px;
  height: 34px;
  align-items: center;
`;

const mediaQuery = (props) => props.isIframe ? media.iFrameMobile : media.phone;
const SelectedItemsWrapper = styled(Row)`
  max-width: 375px;
  position: fixed;
  // left:0; 
  left: calc(calc(100% - 1176px) / 2);
  right: 0;
  bottom: 12px;
  z-index: 3;
  align-items: center;
  justify-content: center;
  ${(props) => (props.isIframe === false ) && css`
    @media (min-width: 600px) and (max-width: 1111px) and (orientation: portrait) {
      max-width: 375px !important;
      bottom: 90px;
    }
  `}
   ${(props) => mediaQuery(props)(css`
    max-width: 100%;
  `)}
 
`;

const mapStateToProps = (state) => ({
  selectedTreeNodes: getSelectedTreeNodes(state)
});

const mapDispatchToProps = {};

SelectedCardsButtonWrapper.propTypes = {
  selectedTreeNodes: PropTypes.instanceOf(List).isRequired,
  isIframe: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedCardsButtonWrapper);
