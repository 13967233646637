import Text from "v2/components/common/Text";


type ListProps = {
  title: string;
  buttonText: string;
  buttonCallback: () => void;
  items: any;
  itemCallback: (arg: any) => void;
  deleteCallback: (arg: any) => void;
  isItemDeletable: boolean;
}

const List: React.FC<ListProps> = ({ title, buttonText, buttonCallback, items, itemCallback, isItemDeletable, deleteCallback }) => {
  return <div className='flex flex-col'>
    <div className='px-5 flex justify-between items-center h-10'>
      <Text className='text-19px font-bold'>{title}</Text>
      <Text className='text-13px font-normal search-clear' onClick={buttonCallback}>{buttonText}</Text>
    </div>
    <div>
      {items.map((item: any) => (
        <div className='search-list-item'>
          <div className='w-full self-stretch flex pl-5' onClick={() => itemCallback(item)}>
            <Text className='text-19px text-blue-normal cursor-pointer self-center' >{item}</Text>
          </div>
          {isItemDeletable && <div className='ml-auto px-5 flex self-stretch' >
            <div className="search-delete-button" onClick={() => deleteCallback(item)}><ClearIcon /></div></div>}
        </div>))}
    </div>

  </div>
}

export default List;


export const ClearIcon = (props: any) => {
  const {type = 'active'} = props;
  return <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
      fillOpacity={type === 'transparent' ? '0.7' : '1'}>
      <g id="Icons/Header/Delete/Active" transform="translate(-1.000000, -1.000000)"
        fill={type === 'transparent' ? '#FFFFFF' : '#378EF8'}>
        <g id="Group-2-Copy" transform="translate(1.000000, 1.000000)">
          <path
            d="M8.079468,8.99870678 L5.10961949,11.9685553 C4.85577871,12.2223961 4.85577871,12.6339533 5.10961949,12.8877941 C5.36346027,13.1416349 5.77501749,13.1416349 6.02885827,12.8877941 L8.99870678,9.91794556 L11.9685553,12.8877941 C12.2223961,13.1416349 12.6339533,13.1416349 12.8877941,12.8877941 C13.1416349,12.6339533 13.1416349,12.2223961 12.8877941,11.9685553 L9.91794556,8.99870678 L12.8877941,6.02885827 C13.1416349,5.77501749 13.1416349,5.36346027 12.8877941,5.10961949 C12.6339533,4.85577871 12.2223961,4.85577871 11.9685553,5.10961949 L8.99870678,8.079468 L6.02885827,5.10961949 C5.77501749,4.85577871 5.36346027,4.85577871 5.10961949,5.10961949 C4.85577871,5.36346027 4.85577871,5.77501749 5.10961949,6.02885827 L8.079468,8.99870678 Z M18,9 C18,13.9706374 13.9705714,18 9,18 C4.02942857,18 0,13.9706374 0,9 C0,4.02942857 4.02942857,0 9,0 C13.9705714,0 18,4.02942857 18,9 Z"
            id="Combined-Shape"/>
        </g>
      </g>
    </g>
  </svg>;
};