import { useRef, useEffect } from 'react';
import { scrollToFlashcardAction, setActiveFlashcardAction } from 'actions/studying';
import { toggleNavigationSection } from 'actions/navigation';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedFlashcards } from 'actions/topics-tree/topicsTreeActions';
import { getActiveTeachingLevel, activeSubjectId } from 'selectors';
import { mediaSize } from 'selectors/browser/browserSelector';
import theme from 'theme';
import Checkbox from 'v2/ui-components/Checkbox';
import TeachingLevelSelector from 'v2/components/teaching-level-selector/TeachingLevelSelectorContainer';
import SelectedCardsButtonWrapper from 'v2/components/topics-tree/SelectedCardsButtonWrapper';
import { getSelectedTreeNodes } from 'selectors/topicsTreeSelector';
import TopicsTreeContainer from 'v2/components/topics-tree/TopicsTreeContainer';
import classNames from 'classnames';
import { getAllTopicsInSubject } from 'actions/api';
import TeachingLevel from 'v2/layout/TeachingLevel';

const MBCreateExercisePage = () => {
  let wrapperRef = useRef<HTMLDivElement>(null);
  const params: any = useParams();
  const dispatch = useDispatch()
  const exerciseId = params?.exerciseId;
  const activeTeachingLevelId = useSelector(getActiveTeachingLevel);
  const hasActiveSubjectId = useSelector(activeSubjectId);
  const selectedTreeNodes = useSelector(getSelectedTreeNodes).toJS();
  const isIframeMobile = useSelector((state) => mediaSize(state, theme.sizes.iFrameMobile),)
  const teachingLevelSelectorParentIds = hasActiveSubjectId === activeTeachingLevelId ? [] : [hasActiveSubjectId];

  useEffect(() => {
    if (exerciseId) {
      dispatch(getSelectedFlashcards(exerciseId));
    }
  }, [])

  useEffect(() => {
    dispatch(getAllTopicsInSubject({
      key: hasActiveSubjectId,
      subjectId: hasActiveSubjectId,
      mapAnswers: true
    }));
  },[dispatch, hasActiveSubjectId, activeTeachingLevelId])

  const onPreviewClick = (flashcardId: number) => {
    dispatch(setActiveFlashcardAction(flashcardId));
    dispatch(scrollToFlashcardAction(flashcardId));
    if (isIframeMobile) {
      dispatch(toggleNavigationSection(false));
    }
  }
  const onTreeScrollTo = (element: any) => {
    const scrollRef = wrapperRef;
    const elementOffsetTop = scrollRef.current?.scrollTop + element.getBoundingClientRect().top;
    if (scrollRef.current) {
      scrollRef.current.scrollTop = elementOffsetTop - scrollRef.current?.offsetHeight / 2;
    }
  }
  return <>
    <div className='exercise-tvl-slider'>
      <div className='exercise-shadow pt-2.5 flex items-center'>
        <Checkbox className='ml-1.5 mr-2 mb-2' id={activeTeachingLevelId} parentIds={teachingLevelSelectorParentIds} />
        {/* <TeachingLevelSelector /> */}
        <TeachingLevel />
      </div>
    </div>
    <div className='fxied-spacing pt-14'>
      <div className={classNames('px-3.5 py-3.5', { '!pb-[46px]': selectedTreeNodes.length > 0 })}>
        <TopicsTreeContainer
          subjectId={hasActiveSubjectId}
          hasSelection={true}
          showAnswers={false}
          onPreviewClick={onPreviewClick}
          scrollTo={onTreeScrollTo}
          isExercise={true}
          isExernalExercise={true}
        />
      </div>
    </div>

    <SelectedCardsButtonWrapper isIframe={true} />
  </>
}


export default MBCreateExercisePage;