import classNames from 'classnames'
import React from 'react'
import { ReadIcon } from 'icons/CardIcons'
import StudyStatsBarsContainer from 'v2/components/topics-tree/StudyStatsBarsContainer'

type StudyMetaInfoProps = {
    icon: string;
    name: string;
    number: number;
    isRead: boolean;
    statusBarData: any;
    isActive?: boolean;
    isSubtopic?: boolean;
    mediaType: string;
    hasSelection?: boolean | null;
    isCarousel?: boolean;
    singleCard?: string;
    isExercise?: boolean;
    hasNumbering?: string;
    onClick?: () => void;
}

const StudyMetaInfo: React.FC<StudyMetaInfoProps> = ({ icon, name, number, isRead, statusBarData, isActive, isSubtopic, mediaType, hasSelection, isCarousel, singleCard, isExercise, hasNumbering, onClick }) => {
    return <div className={classNames('flex min-w-[292px] rounded-10px [&_img]:w-10 [&_img]:h-10 pt-2', { 'pr-[10.5px]': (!hasSelection && !isCarousel), 'pb-1': hasSelection, 'pb-2': !hasSelection, 'pl-0': isExercise, 'pl-[10.5px]': !isExercise, 'pt-[9px] px-[11px] pb-2': isCarousel })} onClick={() => onClick?.()}>
        <img draggable="false" className="mediaTab-image" src={icon} alt={name} />
        <div className='ml-3 mr-5 w-[54px]'>
            <div className='font-bold text-17px leading-[23px] text-#2D3838'>{number} {(isRead && number !== 0) && <ReadIcon />}</div>
            <div className='font-bold text-10px leading-[11px] text-#919191'>{number > 1 ? name : singleCard}</div>
            <div className='mt-1 font-bold text-10px leading-[14px] text-#2D3838'>{hasNumbering}</div>
        </div>
        <StudyStatsBarsContainer statusBarData={statusBarData} isActive={isActive} isSubtopic={isSubtopic} mediaType={mediaType} />
    </div>
}

export default StudyMetaInfo