import React from 'react';
import { fontSize } from 'v2/components/common/font-size';
import { fontWeight } from 'v2/components/common/font-weights';
import Text from 'v2/components/common/Text';
import { ForwardChevronIcon, OSCIcon, IBIcon } from 'icons/HeaderIcons';


type ListTypeHeaderProps = {
  title: string;
  icon: React.ReactNode;
  showMore: boolean,
  total: number;
  onClick: () => void
}

const ListTypeHeader: React.FC<ListTypeHeaderProps> = ({ icon, title, showMore, total, onClick }) => {
  const shouldShowMore = showMore && total - 3 > 0;
  return <div className='px-5 items-center justify-between h-10 flex'>
    <div className='flex items-center'>
      <div className='w-5 h-5 items-center'>{icon}</div>
      <div className='pl-2'>
        <Text className='text-19px font-bold text-#2D3838'>{title}</Text>
      </div>
    </div>
    {shouldShowMore &&
      <div className='more-item' onClick={onClick}>{total - 3} more <div className='pl-5px'>
        <ForwardChevronIcon color={'#378EF8'} class={"more-icon"} /></div>
      </div>
    }
  </div>
}


export default ListTypeHeader;