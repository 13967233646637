import React from 'react'
import SelectFlashcardIcon from 'assets/images/study/select-flashcard.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';

type FlashcardNotSelectedProps = {
    isFilter: boolean;
    title: string; 
    text: React.ReactElement
}  

const FlashcardNotSelected: React.FC<FlashcardNotSelectedProps> = ({isFilter, title, text}) => {
    return <>
        {!isFilter ? 
            <div className='w-full h-full flex flex-col justify-center text-center items-center'>
                <img className='h-5' src={SelectFlashcardIcon} alt='icon'/>
                <div className='pt-3 font-bold text-17px leading-23px text-center text-blue-normal'>{title || 'Select a flash card!'}</div>
                <div className="flex items-center justify-center text-#919191 text-center pt-2 font-normal text-13px leading-4 tracking-normal">{text ||
                    <>To start studying, go and select a card<br/>from any menu.</>}
                </div>
            </div>
            :
            <div className='w-full h-full flex flex-col justify-center text-center items-center'>
                <FilterIcon />
                <div className='pt-3 font-bold text-17px leading-23px text-center text-blue-normal'>{title}</div>
                <div className="flex items-center justify-center text-#919191 text-center pt-2 font-normal text-13px leading-4 tracking-normal">
                    None of the flashcards match the filter you set.
                </div>
                <div className="flex items-center justify-center text-#919191 text-center pt-4 font-normal text-13px leading-4 tracking-normal">
                    <><strong>To view flashcards, change the filter above.</strong></>
                </div>
            </div>
        }
    </>
}

export default FlashcardNotSelected;