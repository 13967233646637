import {setActiveNodesAction, toggleSubtopicVisibility, setActiveTopicId, setActiveSubTopicId} from 'actions/topics-tree/topicsTreeActions';
import StudyMetaInformation from 'components/studying/studay-meta-info/StudyMetaInformation';
import {CheckboxWrapper} from 'components/topics-tree/TopicsTreeContainer';
import {Subtopic} from 'components/topics-tree/TopicTile';
import {Map} from 'immutable';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getSubtopicById} from 'selectors';
import {getTreeSectionValue, isTreeSectionExpanded} from 'selectors/topicsTreeSelector';
import Checkbox from 'ui-components/Checkbox';
import appHistory from 'appHistory';
import * as Selectors from 'selectors';
import { filterResultsListToArrayByTOJS, getLastMediaTypeVisited, getFormExams } from 'helpers';
import * as Actions from 'actions';
class SubtopicTileContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeMediaType:this.props.activeMediaTab
    }
  }
  render() {
    const {isExpanded, subtopic, parentIds, hasSelection, showAnswers, children, isActive, parentTopic, activeTopicId, activeSubtopicId,currentFlashcardId, isExercise, isExernalExercise, userId} = this.props;
    const subtopicId = subtopic.get('id');
    const mediaType = getLastMediaTypeVisited(userId)
    const examsMode = mediaType === 'exams' && getFormExams() === 'exams';
    return (
      <Subtopic key={subtopicId}
        active={!examsMode && isActive}
        activeTopicId={activeTopicId}
        activeSubtopicId={activeSubtopicId}
        label={subtopic.get('label')}
        parentLabel={parentTopic.get('label')}
        parentTlvl={parentTopic.get('tlvl')}
        parentTlvlTag={parentTopic.get('teachingTag')}
        title={subtopic.get('title')}
        expanded={isExpanded}
        numbering={subtopic.get('numbering')}
        hasSelection={hasSelection}
        isExernalExercise={isExernalExercise}
        onExpand={() => this.onClick(subtopicId, parentIds, 'EXPAND', parentTopic.get('id'))}
        onSelect={(e) => {
          this.onClick(subtopicId, parentIds, 'SELECT', parentTopic.get('id'), isExercise);
          e.stopPropagation();
        }}
        isExercise={isExercise}
        answers={showAnswers && <StudyMetaInformation topicData={subtopic} id={subtopicId} active={isActive} isSubtopic={true} hasSelection={hasSelection} isMcq={true} hasResponse={true} onClick={this.setActiveMediaSlider}  className={`subtopic${subtopicId}`} onCardClick={()=>this.onClick(subtopicId, parentIds, 'SELECT', parentTopic.get('id'), isExercise)}/>}
        absolute={hasSelection && <CheckboxWrapper><Checkbox id={subtopicId} parentIds={parentIds}/></CheckboxWrapper>}
        currentFlashcardId={currentFlashcardId}
        checkMircoFilterFlashcard = {this.mircoFilter(isExercise )}
      >
        {children}
      </Subtopic>
    );
  }
  setActiveMediaSlider=(val)=>{
    if(val){
    this.setState({activeMediaType:val})
    }
  }
  mircoFilter(isExercise){
    if(isExercise){
      const filterList = this.props.listOfFilterCheckedItems.toJS()["flashcard"]
      const subtopicToOpen = this.props.subtopicToOpen;
      let filteFlashcardData = subtopicToOpen && subtopicToOpen.get('flashcardsAnswers').toJS()
      let filteFlashcardIds = filterResultsListToArrayByTOJS(filteFlashcardData, "flashcard", filterList).map((item) => {
        return item.flashcardId
      })
      return filteFlashcardIds.length == 0 ? true :false
      // 
    }else{
      return false
    }
  }

  onClick(subtopicId, parentIds, clickAction, topicId, isExercise) {
    setTimeout(() => {
    const subtopicToOpen = this.props.subtopicToOpen;
    const filterList = this.props.listOfFilterCheckedItems.toJS()["flashcard"]

    if(clickAction == "SELECT" && isExercise){
      if(subtopicToOpen){
        let filteFlashcardData = subtopicToOpen.get('flashcardsAnswers').toJS()
        let filteFlashcardIds = filterResultsListToArrayByTOJS(filteFlashcardData, "flashcard", filterList).map((item) => {
          return item.flashcardId
        })
        this.props.onPreviewClick(filteFlashcardIds[0]);
        // this.props.onPreviewClick(subtopicToOpen.get('flashcards').first());
      }
    }else{
      if (clickAction === 'SELECT') {
        let firstFlashcard
        if(subtopicToOpen){         
          firstFlashcard = subtopicToOpen.get('flashcards').first();
          //this.props.setActiveNodes(firstFlashcard, parentIds.concat(subtopicId, firstFlashcard));
        }          
          if (!this.props.isExpanded && this.props.isActive) {
            this.props.toggleSubtopicVisibility(subtopicId, parentIds);
          }
          //this.props.onPreviewClick(firstFlashcard);
          this.props.setActiveTopicId(topicId);
          this.props.setActiveSubTopicId(subtopicId);
          appHistory.push(`/flashcard/subtopic/${subtopicId}`);
          this.props.setActiveMediaTab(this.state.activeMediaType)
      } else {
        this.props.toggleSubtopicVisibility(subtopicId, parentIds);
      }

      this.props.onClick(subtopicId, parentIds);
    }
  }, 100);
}
}

SubtopicTileContainer.propTypes = {
  hasSelection: PropTypes.bool,
  showAnswers: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  onPreviewClick: PropTypes.func.isRequired,
  subtopic: PropTypes.instanceOf(Map).isRequired,
  parentIds: PropTypes.array.isRequired,

  // Connect
  isActive: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setActiveNodes: PropTypes.func.isRequired,
  toggleSubtopicVisibility: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const subtopicId = props.subtopic.get('id');
  return ({
    isActive: getTreeSectionValue(state, subtopicId, props.parentIds, 'active'),
    isExpanded: isTreeSectionExpanded(state, subtopicId, props.parentIds),
    subtopicToOpen: getSubtopicById(state, subtopicId),
    currentFlashcardId: Selectors.Studying.currentFlashcardId(state),
    listOfFilterCheckedItems: Selectors.listOfFilterCheckedItems(state),
    activeMediaTab: Selectors.getActiveMediaTab(state),
    userId : Selectors.getUserId(state)
  });
};
const mapDispatchToProps = {
  toggleSubtopicVisibility: toggleSubtopicVisibility,
  setActiveNodes: setActiveNodesAction,
  setActiveSubTopicId: setActiveSubTopicId,
  setActiveTopicId: setActiveTopicId,
  setActiveMediaTab: Actions.Navigation.setActiveMediaTab,
  
};
export default connect(mapStateToProps, mapDispatchToProps)(SubtopicTileContainer);
