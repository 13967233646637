import {SET_NODE_MARKS} from 'actions/answers/answersActionTypes';
import {fromJS, Map} from 'immutable';

export default (state = Map(), action) => {
  switch (action.type) {
    case SET_NODE_MARKS:
      return state.withMutations(state => {
        action.data.forEach(node => {
          if (node.total >= 0) {
            //all nodes but previews
            state.set(node.id, fromJS({markValue: node.markValue}));
          } else {
            //previews
            state.set(node.id, fromJS({
              markValue: node.markValue
            }));
          }
        });
        return state;
      });
    default:
      return state;
  }
};
