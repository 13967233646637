import { useState, useEffect } from "react";
import PageOverlay from "v2/ui-components/PageOverlay";
import { useSelector, useDispatch } from "react-redux";
// import FullPageLoader from 'v2/components/loader.v2/FullPageLoader';
import Loader, {
  BackgroundEnum,
  SizeEnum,
} from "v2/components/loader.v2/Loader.v2";
import DeviceListItem from "v2/components/device-manager/DeviceListItem";
import { getLoggedinDevices, removeLoggedinDevice } from "actions/api";
import {
  setActiveFlashcardTab,
  toggleNavigationSection,
  saveLastMenuPath,
} from "actions/navigation";
import { useHistory } from "react-router-dom";
import {
  isDeviceManagerOverlayVisible,
  getLoggedinDevicesList,
  isApiRequestInProgress,
  isNavigationSectionVisible,
} from "selectors";

const defaultMenuPathObj = {
  topics: "/flashcard/topics",
  notes: "/flashcard/desk/notes",
  folders: "/flashcard/desk/folders",
  exercises: "/flashcard/desk/exercises",
  search: "/flashcard/search",
  editNotes: "/flashcard/desk/notes/edit",
  exams: "/flashcard/desk/exams",
};
const DeviceManager = () => {
  const dispatch = useDispatch();
  const [pageStatus, setPageStatus] = useState("deviceList");
  const [hasDeviceList, setDeviceList] = useState<any>([]);
  const devicesInProgress = useSelector((state) =>
    isApiRequestInProgress(state, "devices")
  );
  const isDeviceOverlayVisible = useSelector(isDeviceManagerOverlayVisible);
  // const inProgressDeviceLogout = useSelector((state) => isApiRequestInProgress(state, 'logoutdevices'));
  const isNavigationVisible = useSelector(isNavigationSectionVisible);
  let deviceList = useSelector(getLoggedinDevicesList);
  const history = useHistory();

  useEffect(() => {
    // dispatch(getLoggedinDevices());
  }, [dispatch]);
  useEffect(() => setDeviceList(deviceList), [deviceList]);
  const handleLogout = (data: any) => {
    setDeviceList((prev: any) => {
      return prev.map((item: any) =>
        item.deviceId === data.deviceId
          ? { ...item, logOutLoading: true }
          : { ...item, logOutLoading: false }
      );
    });
    const logoutData = {
      deviceId: data.deviceId,
      device_token: data.accessToken,
    };
    dispatch(removeLoggedinDevice(logoutData));
    dispatch(saveLastMenuPath(defaultMenuPathObj));
    dispatch(setActiveFlashcardTab(0));
    if (isNavigationVisible) {
      dispatch(toggleNavigationSection(true, true));
    }
    history.push("/flashcard/topics");
  };
  if (!isDeviceOverlayVisible) return null;
  // if (inProgressDeviceLogout)
  //   return <FullPageLoader text={'Logging you in'}/>;

  return (
    <PageOverlay animate isOverlayVisible deactiveCloseBtn={false} topPosition>
      <div className="bg-white border rounded-2xl border-#E1E6E6 border-solid py-12 px-14 max-w-488px">
        {pageStatus === "deviceList" && (
          <>
            <div className="font-bold text-33px leading-45px -tracking-normal">
              Device limit reached
            </div>
            <div className="text-15px leading-19px text-#2D3838 mt-6 mb-4">
              OSC Study can be used on 2 devices simultaneously. To continue,
              tap on 'Log out' for one of the devices below. Your study progress
              and data will be unaffected when logging out.
            </div>
            {devicesInProgress ? (
              <div className="flex items-center justify-center">
                <Loader
                  size={SizeEnum.MeduiumSmall}
                  background={BackgroundEnum.Grey}
                />
              </div>
            ) : (
              <>
                {hasDeviceList.map((device: any) => {
                  const deviceId = device.id;
                  return (
                    <DeviceListItem
                      key={deviceId}
                      data={device}
                      handleLogout={handleLogout}
                      isLogoutInProgress={device?.logOutLoading}
                    />
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
    </PageOverlay>
  );
};

export default DeviceManager;