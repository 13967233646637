import React, {PropsWithChildren} from 'react'
import classNames from 'classnames';
import CheckRadioButton from 'v2/components/check-radio-button/CheckRadioButton';


type FilterContainerProps = {
    active?:boolean;
    index: number;
    onClick?: () => void; 
    filter: any;
    itemID: string;
    filterType: string;
    disabled?: boolean;
    children: React.ReactElement | string;
    isRow?: boolean;
    isWidth?: boolean;
    isMinHeight?:boolean;
    isExams?: boolean;
    isDisabled?: boolean;
}  

const FilterContainer: React.FC<PropsWithChildren<FilterContainerProps>> = ({active=true, index, filter, itemID, filterType, onClick, children, isRow=false, isMinHeight=false, isExams=false, isDisabled=false}) => {    
    return <div key={index} className={classNames('flex py-2.5 px-0 select-none', {'!min-h-[40px] !p-0 items-center' : ['difficulty', 'mcq'].includes(filterType)}, {'min-h-[82px]': isMinHeight, 'cursor-pointer':!isDisabled}, {'h-[40px]':filterType == 'micro'})} onClick={onClick}>
            <div className={classNames({'[&>div]:block':filterType==='source','pr-4 [&>div]:inline':!['source', 'teachinglevel'].includes(filterType), 'pr-4':filterType==='teachinglevel','mt-1px':filterType == 'micro' })}>
                {active ? <CheckRadioButton itemId={itemID} listOfCheckedItems={filter} isExams={isExams} isDisabled={isDisabled}/> : <div className='w-18px h-18px rounded-full border-1px border-solid border-#C8CCCC !block'></div>} 
            </div>
        <div className={classNames('h-full block relative [&_svg]:block', {'!flex': isRow})}>
            {children}
        </div>
    </div>
}

export default FilterContainer;