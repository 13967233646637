import * as Actions from 'actions';
import * as NavigationActions from 'actions/navigation';
import {setActiveFlashcardTab, setDocumentTitle} from 'actions/navigation';
import {DeskMenuItem} from 'components/desk-header/DeskHeader.styles';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import RouterLink from 'router/Link';
import * as Selectors from 'selectors';
import styled from 'styled-components/macro';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';
import {MainSeparator, PageHeader, PagePadding} from 'ui-components/pageComponents';
import './DeskHeader.scss';

const TitleRow = styled(Row)`
  background: #F1F3F2;
  border-radius: 18px;
  height: 36px;
  padding:4px;
  justify-content: space-between;
  //border: 1px solid;
`;
/**
 * @deprecated TODO- delete not use any more
 */
class DeskHeaderContainer extends Component {
  componentDidMount() {
    //this.props.setActiveFlashcardTab(1);
    this.props.setDocumentTitle('Desk');

    let url = window.location.href;
    if (url.indexOf('notes') !== -1) {
      this.props.setActiveDeskTab(0);
    } else if (url.indexOf('folders') !== -1) {
      this.props.setActiveDeskTab(1);
    } else if (url.indexOf('exercises') !== -1) {
      this.props.setActiveDeskTab(2);
    }
  }

  componentWillUnmount() {
    this.props.toggleDeskEditMode(false);
    this.props.toggleFlashcardNavigationVisibility(true);
  }

  isTabActive(index) {
    return this.props.activeDeskTab === index;
  }

  render() {
    return (
      <PagePadding>
        <PageHeader>Desk</PageHeader>

        <Padding top={12}/>

        <StyledTitleRow editing={this.props.isDeskEditModeActive}>
          <StyledRouterLink to="/flashcard/desk/notes" tabTitle={"notes"}>
            <DeskMenuItem active={this.isTabActive(0)}  editing={this.props.isDeskEditModeActive}>
              Notes
            </DeskMenuItem>
          </StyledRouterLink>
          <StyledRouterLink to="/flashcard/desk/folders">
            <DeskMenuItem active={this.isTabActive(1)} editing={this.props.isDeskEditModeActive}>
              Folders
            </DeskMenuItem>
          </StyledRouterLink>
          <StyledRouterLink to="/flashcard/desk/exercises">
            <DeskMenuItem active={this.isTabActive(2)} editing={this.props.isDeskEditModeActive}>
              Exercises
            </DeskMenuItem>
          </StyledRouterLink>
        </StyledTitleRow>
      </PagePadding>
    );
  }
}

const DeskHeaderSeparator = styled(MainSeparator)`
  padding-top: 16px;
`;
const StyledRouterLink = styled(RouterLink)`
  flex: ${props => props.tabTitle && props.tabTitle === "notes" ? 1 : 1.25 };

`;
const StyledTitleRow = styled(TitleRow)`
  ${props => props.editing && 'pointer-events: none;'}
`;

DeskHeaderContainer.propTypes = {
  rightButtons: PropTypes.element,
  setDocumentTitle: PropTypes.func.isRequired,
  toggleDeskEditMode: PropTypes.func.isRequired,
  setActiveFlashcardTab: PropTypes.func.isRequired,
  toggleFlashcardNavigationVisibility: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isDeskEditModeActive: PropTypes.bool,
  setActiveDeskTab: PropTypes.func.isRequired,
  activeDeskTab: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isDeskEditModeActive: Selectors.isDeskEditModeActive(state),
  currentFlashcardId: Selectors.Studying.currentFlashcardId(state),
  flashcardsWithNotes: Selectors.flashcardsWithNotes(state),
  deleteConfirmationMode: Selectors.deleteConfirmationMode(state),
  activeDeskTab: Selectors.getActiveDeskTab(state),
});

const mapDispatchToProps = {
  toggleDeskEditMode: NavigationActions.toggleDeskEditMode,
  deselectAllItems: Actions.Desk.deselectAllItems,
  toggleDeleteConfirmationMode: Actions.Desk.toggleDeleteConfirmationMode,
  toggleFlashcardNavigationVisibility: Actions.Navigation.toggleFlashcardNavigationVisibility,
  setActiveFlashcardTab,
  setDocumentTitle,
  setActiveDeskTab: NavigationActions.setActiveDeskTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskHeaderContainer);
