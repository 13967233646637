import React from 'react';
import { useSelector } from 'react-redux';
import { getActiveExamsId } from 'selectors/navigation/navigationSelector';
import { getUserId } from 'selectors/user/index'
import ExamsTileContainer from './ExamsTileContainer';
import { getLastMediaTypeVisited } from 'helpers';

type ExamsTreeContainerProps = {
    data: any;
}

const ExamsTreeContainer: React.FC<ExamsTreeContainerProps> = ({ data }) => {
    const activeExamsId = useSelector(getActiveExamsId);
    const userId = useSelector(getUserId);
    const mediaType = getLastMediaTypeVisited(userId);

    const renderExams = (examData: any) => {
        const examId = examData.examId
        const isActiveTile = mediaType === 'exams' && examId === activeExamsId
        return <ExamsTileContainer
            key={examId}
            examData={examData}
            paperData={examData.papers}
            examId={examId}
            activeExamsId={activeExamsId}
            isActive={isActiveTile}
        />
    }
    return <>
        {data && data.slice().sort((a: any, b: any) => a.examTitle > b.examTitle ? 1 : -1).map(renderExams)}
    </>
}

export default ExamsTreeContainer