import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader'
import RouterLink from 'router/Link';
import {isMobile} from 'selectors'
import BackButton from 'v2/components/navigation-header/BackButton'
import SimpleButton from 'v2/components/Button/SimpleButton'
import EditFolderNameForm from './EditFolderNameForm'
import { useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';
import './editFolder.scss'
import classNames from 'classnames';

type EditFolderPageProps = {
    className?: string; 
    toggleEditMode: () => void;
    folderId: number | string;
    folderTitle: string;
    currentFolderEdit: boolean;
}  

const EditFolderPage: React.FC<EditFolderPageProps> = ({className, toggleEditMode, folderId, folderTitle,  currentFolderEdit}) => {
    const history = useHistory()
    let formRef = useRef<HTMLFormElement>()
    const { submitForm } = useFormikContext()??{};
    const [isFolderNameChanged, setFolderNameChange] = useState(false)
    const [handleSubmit, setHandleSubmit] =  useState<any>()
    const invalid = false;
    const setFolderNameFlag = (flag: boolean) => {
        setFolderNameChange(flag)
    }
    if (folderId && !folderTitle) {
        history.push('/flashcard/desk/folders')
    }
    
    const formSubmit =() =>{
        formRef.current?.handleSubmit();
      }

    return <div className={classNames({className:className,'edit-folder-page':!className,'-mt-[56px]':folderId, 'h-full':!folderId})}>
       
        <NavigationHeader 
            className={'transparent'}
            contained={true}
            leftSection={!currentFolderEdit ? <>
                <RouterLink to={'/flashcard/desk/folders' + (folderId ? `/${folderId}` : '')}>
                    <BackButton inverted text='Cancel' hideArrow />
                </RouterLink>
            </> : <BackButton onClick={toggleEditMode} inverted text='Cancel' hideArrow />}
            rightSection={
                <SimpleButton onClick={formSubmit} disabled={invalid || !isFolderNameChanged} inverted text={currentFolderEdit ? 'Save' : 'Create Folder'} />
            } 
        />
        <EditFolderNameForm ref={formRef} setFolderNameFlag={setFolderNameFlag} title={folderTitle}  setHandleSubmit={setHandleSubmit} folderId={folderId} toggleEditMode={toggleEditMode}/>
       
    </div>
}

export default EditFolderPage;