import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import NavigationHeader from 'components/navigation-header';
import SaveButton from 'v2/components/navigation-header/SaveButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RenderInputField, WarnIcon } from 'v2/utils/form-helper';
import BackButton from 'v2/components/navigation-header/BackButton';
import {changeMenuPathAction} from 'actions/menuActions';
import {addVoucher} from "actions/api"
 
import './add-voucher.scss';


type AddVoucherProps = {
}

const AddVoucher: React.FC<AddVoucherProps> = ({ }) => {
  const dispatch = useDispatch()

  const initialFormValue = {
    voucherCode: '',
  }
  const [isFocused, setFocused] = useState({ voucherCode: false });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue } = useFormik({
    validationSchema: validation,
    enableReinitialize: true,
    initialValues: initialFormValue,
    onSubmit: (values) => {
      dispatch(addVoucher(values))
    }
  });



  return <div className='page' id='add-voucher-page'>
    {/* @ts-ignore */}
    <NavigationHeader title="Add voucher" leftSection={( <div className="cursor-pointer z-1" onClick={() => {dispatch(changeMenuPathAction('subscriptions'))}}>
          <BackButton hideArrow text="Cancel" />
        </div>
      )}
      // @ts-ignore
      rightSection={( <SaveButton disabled={false}  onClick= {handleSubmit}  text="Add" /> )}
    />
    <form onSubmit={handleSubmit}>
      <div className='profile-details-form'>
        <div className='form-input-wrapper'>
          <RenderInputField
            label={'Voucher code'}
            type='text'
            name='voucherCode'
            placeholder='Enter your new voucher code'
            className='form-input'
            showIcon={true}
            touched={touched.voucherCode}
            error={errors.voucherCode}
            reset={() => setFieldValue("voucherCode", "")}
            active={isFocused.voucherCode}
            value={values.voucherCode}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={false}
            autoComplete={"off"}
            onFocus={() => {
              setFocused({ voucherCode: true }
              )
            }}
          />
        </div>
      </div>
    </form>
  </div >
}

let validation = Yup.object().shape({
  voucherCode: Yup.string().required("This field is required!")
});
export default AddVoucher