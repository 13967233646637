import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MarkText from 'ui-components/MarkText';
import PreviewListHeader from 'v2/ui-components/search-results/PreviewListHeader';
import PreviewRightSection from './PreviewRightSection';
import { setActiveFlashcardTab, setExerciseOnCanvas } from 'actions/navigation';
import { updateExamsQuestionIndex } from 'actions/desk';
import { setActiveExerciseId, selectFlashcard, selectExams } from 'actions/studying';
import { getNextPrevTopicsData, getExamHeader } from 'actions/api'
import { listOfCheckedItems, getExerciseOnCanvas, getTopicIdByTeachingLevel, activeSubjectId, currentFlashcardId } from 'selectors';
import { getUserId } from 'selectors/user';
import { addBackFlashcardToLocalStorage, setLastMediaTypeVisited, decodeQuery, setExamSubTopicId, setFormExams, storeExamsParentId, setExamTopicId } from 'helpers';
import ExamsPreviewListItem from './ExamsPreviewListItem';

type ExamsResultsListProps = {
  newListItems: any,
  shouldMark: boolean,
  fromPage?: string,
  filterFlashcardId?: number | null,
  onListItemSelected: any,
  mediaType: string
}

const ExamsResultsList: React.FC<ExamsResultsListProps> = ({ newListItems, shouldMark, fromPage, mediaType, filterFlashcardId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(getUserId);
  const hasExerciseOnCanvas = useSelector(getExerciseOnCanvas);
  const topicIdByTeachingLevel = useSelector(getTopicIdByTeachingLevel);
  const getActiveSubjectId = useSelector(activeSubjectId);
  const currentId = useSelector(currentFlashcardId)
  const onSubTopicClick = (subTopicId: any) => {
    history.push(`/flashcard/subtopic/${subTopicId}`)
    dispatch(setActiveFlashcardTab(0))
  }
  const onListItemClick = (flashcardId: string, subTopicId: number, topicId: number, exam_uuid: string) => {
    const topicIndex = topicIdByTeachingLevel?.findIndex((item: any) => item.topicId === topicId);
    let topicIds: any = [];
    if (topicIndex === 0) {
      topicIdByTeachingLevel?.[topicIndex + 1] && topicIds.push(topicIdByTeachingLevel[topicIndex + 1].topicId);
    } else {
      topicIdByTeachingLevel?.[topicIndex - 1] && topicIds.push(topicIdByTeachingLevel[topicIndex - 1].topicId);
      topicIdByTeachingLevel?.[topicIndex + 1] && topicIds.push(topicIdByTeachingLevel[topicIndex + 1].topicId);
    }
    dispatch(getNextPrevTopicsData(topicIds, null));
    let selectedFlashcard = 0;
    const query = decodeQuery(history.location.search.substring(1));
    dispatch(getExamHeader({ activeSubjectId: getActiveSubjectId, examUid: exam_uuid }));
    if (flashcardId !== currentId) {
      addBackFlashcardToLocalStorage("flashcard" + "-" + flashcardId, query.mediaType + "-" + currentId);
    }    
    setLastMediaTypeVisited(mediaType, userId);
    if (hasExerciseOnCanvas || typeof hasExerciseOnCanvas == "undefined") {
      dispatch(setExerciseOnCanvas(false))
      dispatch(setActiveExerciseId(null))
    }
    const mergedArray = [].concat.apply([], newListItems);
    mergedArray.map((item: any, index) => {
      if (`${item.subTopicId}-${item.flashcardId}` === `${subTopicId}-${flashcardId}`) {
        selectedFlashcard = index
      }
    })
    dispatch(selectExams(flashcardId, 'topics', mergedArray.length, selectedFlashcard + 1));
    setExamSubTopicId(subTopicId)
    setExamTopicId(topicId);
    setFormExams('topics');

    storeExamsParentId(topicId);
    dispatch(selectFlashcard(flashcardId, shouldMark ? 'search' : 'default'));
    let examsIndex = []

    examsIndex = mergedArray.map((val: any) => {
      let numbering = val.numbering
      return { index: numbering, uuid: val.flashcardId, subTopicId: val.subTopicId }
    })

    dispatch(updateExamsQuestionIndex(examsIndex, selectedFlashcard + 1))
  }
  if (newListItems.length === 0) return null;
  return <MarkText shouldMark={shouldMark}>
    {newListItems.map((item: any, index: number) => {
      return <div key={`exams-${item[0].category}`}>
        <PreviewListHeader topicNumber={item[0].topicNumbering} index={index}
          teachingLevel={item[0].teachingLevel} teachingTag={typeof item[0].teachingTag !== "undefined" ? item[0].teachingTag : null} subTopicId={item[0].subTopicId}
          onClick={() => onSubTopicClick(item[0].subTopicId)}
          fromPage={fromPage}
          subTopicTitle={item[0].subTopicName}
          data={item?.[0]} />
        {item.map((subItem: any, index: number) => {
          const flashcardId = subItem.flashcardId;
          return <ExamsPreviewListItem key={`exams-${Math.random() * 100}`}
            flashcardId={flashcardId}
            isEditing={false}
            onClick={() => onListItemClick(subItem.flashcardId, subItem.subTopicId, subItem.topicId, subItem?.experienceData?.exam_uuid)}
            listOfCheckedItems={listOfCheckedItems}
            flashcardNumber={subItem.numbering}
            title={subItem.title}
            paper={subItem.paperType}
            teachingTag={subItem.questionTeachingTag}
            marks={parseInt(subItem.points)}
            rightSection={<PreviewRightSection id={subItem.flashcardId} occurences={0} type='exams' shouldMark={shouldMark} questionType={subItem.questionType} />}
            // rightSection={this.renderPreviewRightSection(subItem.get('flashcardId'), '', 'exams', subItem.get('questionType'))}
            sources={subItem?.experienceData?.qb_code}
            active={flashcardId === currentId}
            experienceData={subItem.experienceData}
            difficulty={subItem.difficultyLevel}
            type=''
            deleted={filterFlashcardId === flashcardId}          
            nodeTeachingTag={subItem.nodeTeachingTag}
            />
        })}
      </div>
    })}
  </MarkText>
}


export default ExamsResultsList