import React from 'react';
import './ColorButton.scss';
import PropTypes from 'prop-types';

const ColorButton = (props) => {
  const style = {backgroundColor: props.color};
  const className = `color-button${props.active ? ' active' : ''}${props.className ? ' ' + props.className : ''}`;

  return (
    <div onClick={props.onClick} className={className}>
      <div className='inner' style={style}/>
    </div>
  );
};

ColorButton.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ColorButton;
