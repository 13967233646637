import React, {Component} from 'react';
import MobileFolders from './mobile-folders/MobileFolders';
import * as Actions from 'actions';
import PropTypes from 'prop-types';
import * as Selectors from 'selectors';
import './FoldersTooltip.scss';
import {connect} from 'react-redux';
import EditFolderPage from 'pages/desk/edit-folder/EditFolderPage';
import MobileFolderContent from './mobile-folder-content/MobileFolderContent';
import appHistory from 'appHistory';
import { decodeQuery } from 'helpers';
import { getLastMediaTypeVisited } from 'helpers';
import {getUserId} from 'selectors/user';
/**
 * @deprecated TODO- delete not use any more
 */
class FoldersTooltipContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: 'folders',
      folderId: {}
    };
    this.changeRoute = this.changeRoute.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.isSmart = this.isSmart.bind(this);
    this.isCurrentFlashcardAdded = this.isCurrentFlashcardAdded.bind(this);
  }
  componentDidMount() {
    const folderDeskPageRegex = RegExp('/flashcard/desk/folders/([0-9]+)');
   // const flashcardId = this.getFlashcardIdFromUrl();
    let currentfolderId;
    if(folderDeskPageRegex.test(window.location.pathname)){
      const matches = folderDeskPageRegex.exec(window.location.pathname);
      currentfolderId = matches[1];
      this.state = {
        folderId: {id: currentfolderId}
      };
    }
    if(currentfolderId){
      let folderInfo = {id: this.state.folderId.id};
      this.changeRoute('folderContent', folderInfo);
    }
  }

  changeRoute(routeName, folderInfo = {}) {
    this.setState({route: routeName});
    this.setState({folderId: folderInfo});
  }

  isCurrentFlashcardAdded() {
    return this.props.flashcardPreviews.find((item) => item.get('flashcardId') === parseInt(this.props.currentFlashcardId));
  }

  onAddClick() {
    const  mediaType = getLastMediaTypeVisited(this.props.userId) 
    const folderId = this.state.folderId.id;
    let isCurrentFlashcardAdded = this.isCurrentFlashcardAdded();
    if (isCurrentFlashcardAdded) {
      this.props.removeFlashcardFromFolder(folderId,mediaType);
    } else {
      this.props.addFlashcardToFolder(folderId,mediaType);
    }
  }

  isSmart() {
    return isNaN(parseInt(this.state.folderId.id));
  }
  
  render() {
    switch (this.state.route) {
      case 'folders':
        return (
          <MobileFolders
            getFolders={this.props.getFolders}
            folders={this.props.folders}
            changeRoute={(routeName, folderInfo) => this.changeRoute(routeName, folderInfo)}
            getFoldersForFlashcard={this.props.getFoldersForFlashcard}
            currentFlashcardId={this.props.currentFlashcardId}
          />
        );
      case 'createFolder':
        return <EditFolderPage changeRoute={(routeName) => this.changeRoute(routeName)} id={'edit-folder-page'}/>;
      case 'folderContent': {
        let isSmart = this.isSmart();
        let isCurrentFlashcardAdded = this.isCurrentFlashcardAdded();
        return (
          <MobileFolderContent
            onListItemSelected={this.props.onListItemSelected}
            flashcardFolders={this.props.flashcardFolders }
            changeRoute={(routeName) => this.changeRoute(routeName)}
            isCurrentFlashcardAdded={isCurrentFlashcardAdded}
            onAddClick={this.onAddClick}
            isSmart={isSmart}
            folderTitle={this.props.folderTitle}
            folderId={this.state.folderId.id}
            getFolder={this.props.getFolder}
            currentFlashcardId={this.props.currentFlashcardId}/>
        );
      }
      default:
        return null;
    }
  }
}

const mapStateToProps = (state) => ({
  folders: Selectors.folders(state),
  activeSubjectId: Selectors.activeSubjectId(state),
  isDeskEditModeActive: Selectors.isDeskEditModeActive(state),
  listOfCheckedItems: Selectors.listOfCheckedItems(state),
  deleteConfirmationMode: Selectors.deleteConfirmationMode(state),
  currentFlashcardId: Selectors.Studying.currentFlashcardId(state),
  flashcardFolders: Selectors.apiRequestData(state, 'flashcardFolders'),
  flashcardPreviews: Selectors.flashcardsInFolder(state),
  folderTitle: Selectors.folderTitle(state),
  userId: getUserId(state)

});

const mapDispatchToProps = {
  getFolders: Actions.Desk.getFolders,
  updateListOfItemsSelected: Actions.Desk.updateListOfItemsSelected,
  toggleFlashcardNavigationVisibility: Actions.Navigation.toggleFlashcardNavigationVisibility,
  toggleDeleteConfirmationMode: Actions.Desk.toggleDeleteConfirmationMode,
  selectAllFolders: Actions.Desk.selectAllFolders,
  deselectAllItems: Actions.Desk.deselectAllItems,
  toggleDeskEditMode: Actions.Navigation.toggleDeskEditMode,
  deleteFolders: Actions.Desk.deleteFolders,
  getFoldersForFlashcard: Actions.Desk.getFoldersForFlashcard,
  getFlashcardsInFolder: Actions.Desk.getFlashcardsInFolder,
  addFlashcardToFolder: Actions.Api.addFlashcardToFolderRoutine,
  removeFlashcardFromFolder: Actions.Api.removeFlashcardFromFolderRoutine,
  getFolder: Actions.Desk.getFolder
};
export default connect(mapStateToProps, mapDispatchToProps)(FoldersTooltipContainer);
