import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Padding = styled.div`
  padding: ${({top}) => top || 0}px ${({right}) => right || 0}px ${({bottom}) => bottom || 0}px ${({left}) => left || 0}px;
`;

Padding.propTypes = {
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
};

export default Padding;
