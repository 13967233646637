import './Store.scss';
import theme from 'theme';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import StoreHeader from './StoreHeader';
import appHistory from '../../appHistory.js';
import * as StoreIcons from 'icons/StoreIcons';
import StoreBasketContainer from './StoreBasketContainer';
import StoreSubjectsChangerContainer from 'components/store-subjects-changer';
import NavigationHeader from 'components/navigation-header/NavigationHeader';
import BackButton from 'components/navigation-header/BackButton';
/**
 * @deprecated TODO- delete not use any more
 */
export default class StoreComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
    this.subjectPackagesDescriptionRefsMap = {};
    this.handleScroll = this.handleScroll.bind(this);
    this.addItemToBasket = this.addItemToBasket.bind(this);
  }

  componentDidMount() {
    const target = this.props.isContained ? this.storeContainerRef : window;
    target.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    const target = this.props.isContained ? this.storeContainerRef : window;
    target.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    if (this.props.packagesInProgress || !Object.keys(this.subjectPackagesDescriptionRefsMap).length)
      return;

    let packagesDescriptionPropsData = this.computePackagesDescriptionProps();

    if (packagesDescriptionPropsData.length) {
      this.props.setPackagesDescriptionProps(packagesDescriptionPropsData);
    }
  }

  addItemToBasket(packageItem, subscription) {
    this.props.addItemInPurchaseBasket({package: packageItem, subscription: subscription});
  }

  removeItemFromBasket(packageItem, subscription) {
    this.props.removeItemFromPurchaseBasket({package: packageItem, subscription: subscription});
  }

  handleScroll(event) {
    if (window.innerWidth < theme.sizes.phone && window.innerWidth > window.innerHeight) {
      this.setState({collapsed: false});
      return;
    }

    let target = event.target;

    if (target.scrollingElement) {
      target = target.scrollingElement;
    }

    const scrollThreshold = this.props.isContained ? 150 : 20;

    if (target.scrollTop > scrollThreshold) {
      if (!this.state.collapsed) this.setState({collapsed: true});
    } else {
      if (this.state.collapsed) this.setState({collapsed: false});
    }
  }

  computePackagesDescriptionProps() {
    let packagesDescriptionPropsData = [];

    this.props.storeSubjectPackages.forEach((item) => {
      let itemId = item.get('id');

      if (!this.subjectPackagesDescriptionRefsMap.hasOwnProperty(itemId)
        || !this.subjectPackagesDescriptionRefsMap[itemId].ref)
        return;

      let itemRef = this.subjectPackagesDescriptionRefsMap[itemId].ref;
      let height = parseFloat(itemRef.offsetHeight);
      let lineHeight = parseFloat(window.getComputedStyle(itemRef, null).getPropertyValue('line-height'));
      let lines = Math.ceil(height / lineHeight);

      let prevItemIndex = this.props.packagesDescriptionProps.findIndex((prevItem) => prevItem.get('packageId') == itemId);

      if (prevItemIndex !== -1) {
        return;
      }

      packagesDescriptionPropsData.push({
        packageId: itemId,
        height: height,
        lineHeight: lineHeight,
        lines: lines,
        isExpanded: false
      });
    });

    return packagesDescriptionPropsData;
  }

  toggleOverflow(e, packageId) {
    e.preventDefault();
    e.stopPropagation();
    this.props.togglePackageDescription({packageId: packageId});
  }

  render() {
    return (
      <div className="store-page page overlay">
        {this.renderOverlay()}
        {this.renderBasket()}
        {this.renderLinks()}
        {this.renderHeader(!this.state.collapsed || !this.props.isContained, true)}
        <div
          ref={(el) => this.storeContainerRef = el}
          className={`store-container ${this.props.isBasketDetailsVisible ? 'prevent-scroll' : ''}`}>
          {this.renderHeader()}
          {this.renderStoreItemsSection()}
        </div>
      </div>
    );
  }

  renderBasket() {
    if (this.props.itemsInBasket.isEmpty()) return;
    return <StoreBasketContainer  {...this.props}/>;
  }

  renderOverflowButton(itemId) {
    let itemIndex = this.props.packagesDescriptionProps.findIndex((prevItem) => prevItem.get('packageId') == itemId);
    if (itemIndex === -1)
      return;

    let itemState = this.props.packagesDescriptionProps.get(itemIndex);
    if (isNaN(itemState.get('lines')) || itemState.get('lines') < 3)
      return;

    let isExpanded = itemState.get('isExpanded');

    return (
      <span
        className={`store-overflow-button-wrapper ${isExpanded ? 'display-more' : 'display-less'} ${isExpanded ? ' no-fade' : ''}`}
        onClick={(e) => this.toggleOverflow(e, itemState.get('packageId'))}>
        {isExpanded ? 'less' : 'more'}
      </span>
    );
  }

  renderSubscriptions(packageItem, packageInBasket) {
    let selectedSubscriptionsIds = [];
    if (packageInBasket) {
      selectedSubscriptionsIds = Array.from(packageInBasket.get('subscriptions'), (subscription) => subscription.get('id'));
    }

    return packageItem.get('subscriptions').map((subscription) => {
      let subscriptionId = subscription.get('id');
      let subscriptionPrice = subscription.get('price');
      let subscriptionDuration = subscription.get('duration');
      let subscriptionDurationUnit = subscription.get('durationUnit');

      if (subscriptionDurationUnit === 'day') {
        if (!(subscriptionDuration % 365)) {
          subscriptionDuration = Math.floor(subscriptionDuration / 365);
          subscriptionDurationUnit = 'year';
        }
      }

      let isSubscriptionSelected = selectedSubscriptionsIds.filter((selectedSubscriptionId) => {
        return selectedSubscriptionId === subscriptionId;
      });

      if (isSubscriptionSelected.length) return (
        <div key={subscriptionId} className="price-item button-added-to-card" onClick={(e) => {
          e.stopPropagation();
          this.removeItemFromBasket(packageItem, subscription);
        }}>
          <div className="added-to-card">
            <div><StoreIcons.AddedToCardIcon/></div>
            <div className="added-to-card-label">Added to cart</div>
          </div>
        </div>
      );
      return (
        <div key={subscriptionId} className="price-item" onClick={(e) => {
          e.stopPropagation();
          this.addItemToBasket(packageItem, subscription);
        }}>
          <div>{subscriptionDuration} {subscriptionDurationUnit + (subscriptionDuration > 1 ? 's' : '')}</div>
          <div>€{subscriptionPrice} </div>
        </div>
      );
    });
  }

  renderStoreItems() {
    const storeItems = this.props.storeSubjectPackages;
    const itemsInBasket = this.props.itemsInBasket;

    if (!storeItems) return null;

    return storeItems.map((item) => {
      const itemId = item.get('id');

      const itemDescPropsIndex = this.props.packagesDescriptionProps.findIndex((itemDescProps) => itemDescProps.get('packageId') == itemId);
      const isExpanded = itemDescPropsIndex !== -1 ? this.props.packagesDescriptionProps.getIn([itemDescPropsIndex, 'isExpanded']) : true;

      const packagesInBasket = itemsInBasket.filter(itemInBasket => itemInBasket.get('id') === itemId);

      return this.renderStoreItem(item, isExpanded, packagesInBasket);
    });
  }

  renderStoreItem(item, isExpanded, packagesInBasket) {
    const itemId = item.get('id');
    let itemTitle = item.get('name');
    const itemSubtitle = item.get('title');
    const itemDescription = item.get('description');
    itemTitle = itemTitle.replace('Es-amp-s', 'ES&S');
    return (
      <div className="store-item" key={itemId}>
        <div className="store-item-title-container">
          <div className="store-item-title">{itemTitle}</div>
          {this.renderItemPurchesedStatus(item)}
        </div>
        <div className={this.props.isContained ? 'item-flex' : ''}>
          <div>
            <div className="store-item-subtitle">{itemSubtitle}</div>
            <div className="store-item-description-container">
              <div
                className={`store-title-description ${isExpanded ? 'display-more' : 'display-less'}`}
                ref={(elem) => this.subjectPackagesDescriptionRefsMap[itemId] = {ref: elem}}>
                {itemDescription}
                {this.renderOverflowButton(itemId)}
              </div>
            </div>
          </div>
          <div className="price-container">
            {this.renderSubscriptions(item, packagesInBasket.first())}
          </div>
        </div>
      </div>
    );
  }

  renderItemPurchesedStatus(item) {
    const isPurchased = item.get('isPurchased');
    if (!isPurchased) return null;

    let expired = item.get('isExpired');
    return (
      <div className='store-item-purchased'>
        {expired ?
          (<StoreIcons.ItemPurchasedAndExpired/>) :
          (<StoreIcons.ItemPurchasedAndValid/>)
        }
      </div>
    );
  }

  renderStoreItemsSection() {
    return (
      <div className="store-items">
        {/*todo make sure loader is not needed here*/}
        {this.props.packagesInProgress ? null : this.renderStoreItems()}
      </div>
    );
  }

  renderHeader(isHidden = false, isSimple = false) {
    let className = 'header-container';
    if (isSimple) className += ' collapsed';
    if (isHidden) className += ' hidden';
    return (
      <div className={className}>
        <StoreHeader isSimple={isSimple} isContained={this.props.isContained}/>
        <div className="subjects-container">
          {/*todo make sure loader is not needed here*/}
          {this.props.inProgress && !this.props.activeStoreSubject
            ? null
            : <StoreSubjectsChangerContainer {...this.props}/>
          }
        </div>
      </div>
    );
  }

  renderLinks() {
    if (this.props.isContained) return;
    return (
      <NavigationHeader extendedWidth isCollapsed={this.state.collapsed} text={'Store'} leftSection={(
        <BackButton text="Back" onClick={() => appHistory.goBack()}/>
      )}/>
    );
  }

  renderOverlay() {
    let className = 'overlay';
    if (this.props.isContained) className += ' contained';
    if (!this.props.isBasketDetailsVisible) className += ' hidden';

    return (
      <div onClick={() => (this.props.toggleBasket())} className={className}/>
    );
  }
}

StoreComponent.defaultProps = {
  isContained: false
};

StoreComponent.propTypes = {
  inProgress: PropTypes.bool,
  isContained: PropTypes.bool,
  packagesInProgress: PropTypes.bool,
  isBasketDetailsVisible: PropTypes.bool,

  itemsInBasket: PropTypes.object,
  previousLocation: PropTypes.object,
  activeStoreSubject: PropTypes.object,
  storeSubjectPackages: PropTypes.object,
  packagesDescriptionProps: PropTypes.object,

  toggleBasket: PropTypes.func.isRequired,
  addItemInPurchaseBasket: PropTypes.func.isRequired,
  togglePackageDescription: PropTypes.func.isRequired,
  setPackagesDescriptionProps: PropTypes.func.isRequired,
  removeItemFromPurchaseBasket: PropTypes.func.isRequired,
};
