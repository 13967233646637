import { useCallback } from "react";
import { useSelector } from "react-redux";
import { externalExercisesResponse, getActiveSubject } from "selectors";
import { subjectsTeachingLevel } from "selectors/subject/subjectSelector";
import { activeSubjectId } from "selectors";

/**
 *
 * @returns handleSetExercises : callback function to get externalExerciseData
 */
const useExternalExercisesData = () => {
  const externalExerciseData: any = useSelector(
    externalExercisesResponse
  )?.toJS();
  const subjectId = useSelector(activeSubjectId);
  const activeSubject = useSelector(getActiveSubject);
  const teachingLvl = useSelector(subjectsTeachingLevel);
  const teachingTag = teachingLvl[`${activeSubject.id}`];
  const { data } = externalExerciseData?.[subjectId] ?? {
    data: [],
  };

  const handleSetExercises = useCallback(() => {
    if (!data) return;

    if (activeSubject?.tagging === "NEW_SUBJECT") {
      return data?.filter(
        (exercises: any) =>
          exercises?.teachingLevel === teachingTag &&
          subjectId === exercises.subjectId
      );
    }
    return data?.filter((exercises: any) => subjectId === exercises.subjectId);
  }, [activeSubject?.tagging, data, subjectId, teachingTag]);

  return handleSetExercises;
};

export default useExternalExercisesData;
