import { useState, useEffect, useMemo } from 'react';
// import { SearchNextButton } from 'components/studying/buttons';
import { clearSearch, setActiveSearchTab, setSearchIndex, setPreviousSearchResults } from 'actions/search';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { SearchPrevButton, ClearSearchButton, SearchNextButton } from '../Button';
import {
  activeSearchResult,
  flashcardSearchResults,
  hasNextResult,
  hasPrevResult,
  searchIndex,
  listOfFilterCheckedItems,
  flashcardsSearchResponse,
  currentFlashcardId,
  isNavigationSectionVisible,
  getUserId,
  getActiveMediaTab,
  activeSearchTerm
} from 'selectors';
import useSearchIndex from 'hooks/useSearchIndex';
import usePrevious from 'hooks/usePrevious';
import { selectFlashcard } from 'actions/studying';
import { setActiveFlashcardTab, setActiveMediaTab } from 'actions/navigation'
import { getLastMediaTypeVisited } from 'helpers';
import { useHistory } from 'react-router-dom';

type SearchNavigationContainerProps = {
  wide?: boolean,
  isLandscapeMode?: boolean,
  isSmallDevice?: boolean,
  isMobile: boolean,
  isLandscape: boolean
}
const SearchNavigationContainer: React.FC<SearchNavigationContainerProps> = ({ wide, isLandscapeMode, isSmallDevice, isMobile, isLandscape }) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const hasSearchIndex = useSelector(searchIndex);
  const hasPrev = useSelector(hasPrevResult);
  const hasFlashcardSearchResults = useSelector(flashcardSearchResults);
  const hasNext = useSelector(hasNextResult);
  const activeResult = useSelector(activeSearchResult);
  const listOfFilter = useSelector(listOfFilterCheckedItems);
  const flashcards: any = useSelector(flashcardsSearchResponse);
  const currentId = useSelector(currentFlashcardId);
  const userId = useSelector(getUserId);
  const hasNavigationSectionVisible = useSelector(isNavigationSectionVisible)
  const activeMediaTab = useSelector(getActiveMediaTab)
  const { state, findSearchIndex, nextSearch, prevSearch } = useSearchIndex();
  const prevListOfFilter = usePrevious(listOfFilter);
  const prevFlashcards = usePrevious(flashcards);
  const prevSearchIndex = usePrevious(hasSearchIndex);
  const isActiveSearchTerm = useSelector(activeSearchTerm)
  const hasNoResults = hasFlashcardSearchResults.length === 0;
  const prevActiveSearchTerm = usePrevious(isActiveSearchTerm)
  const isNextButton = nextSearch.length > 0;
  let isPrevButton = prevSearch.length > 0
  const searchState = useMemo(() => {
    return !isNaN(hasSearchIndex);
  }, [hasSearchIndex])

  useEffect(() => {
    findSearchIndex();
  }, [])

  useEffect(() => {
    if(prevActiveSearchTerm != isActiveSearchTerm && prevActiveSearchTerm != null){
      findSearchIndex();
    }
  },[prevActiveSearchTerm, isActiveSearchTerm])
 
  useEffect(() => {
    if (!activeResult) return;
    
    if (prevListOfFilter !== listOfFilter) {
      
      findSearchIndex();
    }
    const searchResultFlashcardId = activeResult.flashcardId;
    
    if (flashcards.get('data') !== prevFlashcards.get('data')) {
      if (hasSearchIndex !== prevSearchIndex) {
       
        findSearchIndex();
        setTimeout(() => {
          dispatch(selectFlashcard(searchResultFlashcardId, 'search'));
        }, 300)
      }
    } else {
      if (hasSearchIndex !== prevSearchIndex) {
        
        findSearchIndex();
        setTimeout(() => {
          dispatch(selectFlashcard(searchResultFlashcardId, 'search'));
        }, 300)

        if (searchResultFlashcardId === currentId) return;
      }
    }
  })
  const updateSearchMediaData = (callback: () => void) => {
    const mediaType = getLastMediaTypeVisited(userId);
    if (mediaType !== activeMediaTab) {
      dispatch(setActiveMediaTab(mediaType));
      dispatch(setPreviousSearchResults(hasFlashcardSearchResults, callback))
    }
  }
  const onPrevClick = () => {
    if (!hasNavigationSectionVisible) {
      dispatch(setActiveFlashcardTab(5));
    }
    const mediaType = getLastMediaTypeVisited(userId);
    if (mediaType !== activeMediaTab) {
      updateSearchMediaData(() => {
        dispatch(setActiveSearchTab('Cards'));
        history.push('/flashcard/search');
        dispatch(setSearchIndex(prevSearch[prevSearch.length - 1]));
      })
    } else {
      dispatch(setActiveSearchTab('Cards'));
      history.push('/flashcard/search');
      dispatch(setSearchIndex(prevSearch[prevSearch.length - 1]));
    }
  }
  const onNextClick = () => {
    if (!hasNavigationSectionVisible) {
      dispatch(setActiveFlashcardTab(5));
    }
    const mediaType = getLastMediaTypeVisited(userId);
    if (mediaType !== activeMediaTab) {
      updateSearchMediaData(() => {
        dispatch(setActiveSearchTab('Cards'));
        history.push('/flashcard/search');
        dispatch(setSearchIndex(nextSearch[0]));
      })
    } else {
      dispatch(setActiveSearchTab('Cards'));
      history.push('/flashcard/search');
      dispatch(setSearchIndex(nextSearch[0]));

    }

  }
  if (!isActiveSearchTerm) return null;
  return <div>
    {wide && (
      <div className='flex justify-end'>
        <ClearSearchButton onClick={() => dispatch(clearSearch())} />
      </div>
    )}
    {!isLandscapeMode ?
      <div className={classNames('flex justify-center', { 'justify-between': wide })}>
        <div className={classNames('pr-3 pl-6', { '!pl-0': (wide || isSmallDevice) })}>
          <SearchPrevButton onClick={onPrevClick} isPrevButton={isPrevButton} isMobile={isMobile && !isLandscape} />
        </div>
        <div className={classNames({ 'pr-3': !wide })}>
          <SearchNextButton onClick={onNextClick} isNextButton={isNextButton} />
        </div>
        {!wide && <ClearSearchButton onClick={() => dispatch(clearSearch())} />}
      </div> :
      <div className={classNames('flex justify-center', { 'justify-between': wide })}>
        <div className='flex absolute flex-row right-2.5 bottom-4'>
          <div className={classNames('flex', { 'pr-3': !wide })}>
            <SearchPrevButton isPrevButton={(!hasPrev && searchState) || hasNoResults} onClick={onPrevClick} />
          </div>
          <div className={classNames('flex', { 'pr-3': !wide })}>
            <SearchNextButton isNextButton={(!hasNext && searchState) || hasNoResults} onClick={onNextClick} />
          </div>
          {!wide && <ClearSearchButton onClick={() => dispatch(clearSearch())} />}
        </div>
      </div>
    }
  </div>
}
export default SearchNavigationContainer