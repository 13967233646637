import React, {Component} from 'react';
import {Field} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {renderTextAreaField} from '../../utils/form-helper';

export default class FormTextareaInput extends Component {
  constructor(props) {
    super(props);

    this.resetField = this.resetField.bind(this);
  }

  resetField() {
    this.props.change(this.props.name, '');
    this.props.touch(this.props.name);
  }


  render() {
    return (
      <div className='form-input-wrapper'>
        <Field
          name={this.props.name}
          component={renderTextAreaField}
          type={this.props.type}
          placeholder={this.props.placeholder}
          className='form-input textarea'
          validate={this.props.validate}
          label={this.props.label}
          reset={this.resetField}
          autoComplete={this.props.autoComplete}
          showIcon={this.props.showIcon}
          normalize={this.props.normalize}
          onResize={this.props.onResize}
        />
      </div>
    );
  }
}

FormTextareaInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  component: PropTypes.string,
  validate: PropTypes.array,
  change: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  showIcon: PropTypes.bool.isRequired,
  onResize: PropTypes.func.isRequired,
};

FormTextareaInput.defaultProps = {
  showIcon: true,
  onResize: () => null,
};
