import React, { useEffect } from "react"
import { setDocumentTitle } from "actions/navigation";
import { useDispatch, useSelector } from 'react-redux';
import AboutYouPageComponent from "./AboutYouPageComponent";

const AboutYouPageContainer: React.FC = () => {
  const disptach = useDispatch();
  useEffect(() => {
    disptach(setDocumentTitle('About you'))
  }, [])
  return <AboutYouPageComponent />
}


export default AboutYouPageContainer