/* eslint-disable import/no-anonymous-default-export */
import {
  SET_FLASHCARD_IDS,
  SET_REVISION_IDS,
  SET_VIDEO_IDS,
  SET_PREVIOUS_FLASHCARD_IDS,
  CALCULATE_FLASHCARD_INDEX,
  SELECT_NEXT_FLASHCARD, SELECT_PREV_FLASHCARD,
  SET_FLASHCARD_IDS_DETAILS,
  SET_VIDEO_IDS_DETAILS,
  SET_REVISION_IDS_DETAILS
} from 'actions/carousel/carouselActionTypes';
import Immutable, {Map} from 'immutable';
import { addBackFlashcardToLocalStorage } from 'helpers';

export default (state = Map(), action) => {
  switch (action.type) {    
    case SET_FLASHCARD_IDS:
      return state.set('flashcardIds', action.data);
    case SET_REVISION_IDS:
      return state.set('revisionIds', action.data);
    case SET_VIDEO_IDS:
      return state.set('videoIds', action.data);
    case SET_FLASHCARD_IDS_DETAILS:
      return state.set('flashcardIdDetails', action.data);
    case SET_VIDEO_IDS_DETAILS:
      return state.set('videoIdDetails', action.data);
    case SET_REVISION_IDS_DETAILS:
      return state.set('revisionIdDetails', action.data);
    case SET_PREVIOUS_FLASHCARD_IDS:
      return state.set('previousFlashcardIds', action.data);
    case CALCULATE_FLASHCARD_INDEX: {
      let flashcardIds = state.get('flashcardIds', Immutable.List());
      let revisionIds = state.get('revisionIds', Immutable.List());
      let videoIds = state.get('videoIds', Immutable.List());
      let flashcardIndex = flashcardIds.indexOf(parseInt(action.flashcardId));

      if(flashcardIndex < 0){
        flashcardIndex = revisionIds.indexOf(parseInt(action.flashcardId));
        flashcardIds = revisionIds
      }

      if(flashcardIndex < 0){
        flashcardIndex = videoIds.indexOf(parseInt(action.flashcardId));
        flashcardIds = videoIds
      }

      
      if (flashcardIndex < 0) return state.set('flashcardIndex', 0);
      state = state.set('flashcardIndex', flashcardIndex);

      let rendererIds = new Array(3);
      rendererIds[1] = flashcardIds[flashcardIndex];
      if (flashcardIndex === 0) {
        rendererIds[0] = null;
      }
      else {
        rendererIds[0] = flashcardIds[flashcardIndex - 1];
      }

      if (flashcardIndex === flashcardIds.size) {
        rendererIds[2] = null;
      }
      else {
        rendererIds[2] = flashcardIds[flashcardIndex + 1];
      }

      return state.set('rendererIds', Immutable.fromJS(rendererIds)).set('carouselRefIndex', 0);
    }
    case SELECT_PREV_FLASHCARD: {
      const flashcardIndex = state.get('flashcardIndex');
      const flashcardIds = state.get('flashcardIds');
      const carouselRefIndex = state.get('carouselRefIndex');
      const newIndex = flashcardIndex - 2;
      if (newIndex < -1) return state;
      addBackFlashcardToLocalStorage(null, null);
      const prevId = newIndex >= 0 ? flashcardIds[newIndex] : null;

      return state
        .setIn(['rendererIds', parseInt(carouselRefIndex + 2) % 3], prevId)
        .set('carouselRefIndex', (carouselRefIndex + 2) % 3)
        .set('flashcardIndex', parseInt(flashcardIndex) - 1)
        .set('currentFlashcardId', flashcardIds[flashcardIndex - 1]);
    }
    case SELECT_NEXT_FLASHCARD: {
      const flashcardIndex = state.get('flashcardIndex');
      const flashcardIds = state.get('flashcardIds');
      const carouselRefIndex = state.get('carouselRefIndex');
      const newIndex = flashcardIndex + 2;
      addBackFlashcardToLocalStorage(null, null);
      if (newIndex > flashcardIds.size) return state;
      const nextId = newIndex <= flashcardIds.length - 1 ? flashcardIds[newIndex] : null;

      return state
        .setIn(['rendererIds', parseInt(carouselRefIndex)], nextId)
        .set('carouselRefIndex', (carouselRefIndex + 1) % 3)
        .set('flashcardIndex', parseInt(flashcardIndex) + 1)
        .set('currentFlashcardId', flashcardIds[flashcardIndex + 1]);
    }
    default:
      return state;
  }
};
