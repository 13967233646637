import React, {Component} from 'react';
import './tool-tip-manager.scss';
import PropTypes from 'prop-types';
/**
 * @deprecated TODO- delete not use any more
 */
export default class ToolTipManager extends Component {
  render() {
    let triangleRotation = '';
    switch (this.props.position) {
      case 'left':
        triangleRotation = '-90deg';
        break;
      case 'bottom':
        triangleRotation = '180deg';
        break;
      default:
        break;
    }

    return (
      <div
        onClick={(event) => event.stopPropagation()}
        ref={this.props.cRef}
        className={'tool-tip-manager-wrapper '+ this.props.position}
        style={{
          display: (this.props.toolTipProps && this.props.toolTipProps.visible ? '' : 'none'),
          left: this.props.toolTipProps.left,
          top: this.props.toolTipProps.top,
        }}>
        {this.props.renderThis(this.props.toolTipProps.componentName, this.props.toolTipProps.propName, this.props.toolTipProps.title, this.props.toolTipProps.icon)}
        <div
          className='tool-tip-triangle'
          style={{
            left: this.props.toolTipProps.triangleLeft,
            top: this.props.toolTipProps.triangleTop,
            transform: `rotate(${triangleRotation})`
          }}/>
      </div>
    );
  }
}

ToolTipManager.propTypes = {
  position: PropTypes.string,
  toolTipProps: PropTypes.object.isRequired,
  renderThis: PropTypes.func.isRequired,
  cRef: PropTypes.func.isRequired,
};
