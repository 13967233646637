import React, { useRef, useMemo, useCallback } from "react"
import { List } from 'immutable';
import classnames from "classnames"
import Renderer from './RevisionRendererContainer';


type RevisionCarouselProps = {
  order: [1, 2, 3],
  rendererIds: List<string>,
  flashcardId: number,
  flashcardIndex: number,
  carouselRefIndex: number,
  isMobile: boolean,
  isLandscape: boolean
}
const RevisionCarousel: React.FC<RevisionCarouselProps> = (props) => {
  const { order, rendererIds } = props
  const canvas = useRef<HTMLUListElement>(null);

  const renderRenderers = useCallback(() => {
    let renderers = new Array(3);
    for (let i = 0; i < 3; i++) {

      const flashcardId: number = Number(rendererIds?.get(i));
      const isMiddle: boolean = order[i] === 2;
      
      renderers[i] = (
        <li className={classnames('carousel-seaqt', { 'hidden': !isMiddle })} style={{ order: order[i] }} key={flashcardId}>
          {/*TODO extract to study container*/}
          <div className={'full-height flex flex-row justify-center'} >
            <div className={classnames('renderer-wrapper')}>
              <Renderer
                hasZoom={true}
                isVisible={isMiddle}
                flashcardId={flashcardId}
                currentFlashcardId={flashcardId}
              />
            </div>
          </div>
        </li>
      );
    }
    return renderers;
  }, [rendererIds]);

  return (
    <div className='carousel-wrapperw'>
      <ul className="carouselw" ref={canvas}>
        {renderRenderers()}
      </ul>
    </div>
  )
}

export default RevisionCarousel;