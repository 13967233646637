import { debounce } from 'lodash';
import React, { Component } from 'react'
import { windowWidth } from 'selectors';
import { isTouchDevice } from 'v2/helpers';
import {connect} from 'react-redux';
import classNames from 'classnames';
import FlashcardControlsContainer from 'v2/components/studying/FlashcardControlsContainer';

const MAX_ZOOM = 3;

type ZoomCanvasV3Props = {
    imageZoom: any,
    canEditExercise: any,
    onActionFlashcard: any,
    parentClick: any,
    listFlashcardId: any,
    width: any,
    height: any,
    children: any,
    isDisabled: any,
    externalZoomFactor: any,
    minZoom: any,
    isZoomed: any,
    resetZoom: any,
    windowWidth: any,
}


type State = {}

class ZoomCanvasV3 extends Component<ZoomCanvasV3Props, State> {
  state = {
    scale: 1,
      offset: {
        x: 0, y: 0
      },
      zoomFactor: 1,
      lastScale: 1,
      isTouching: false,
      isScroll: false,
      isZoomImageDrag:false,
      flipClick: false,
      lastTouch: null,
      startTouches: null,
  }
  
  private zoomArea = React.createRef<any>();
  private contentElement = React.createRef<any>();
  
  
  componentDidMount() {
    if(this.zoomArea.current) {
        this.zoomArea.current.addEventListener('touchstart', this.onTouchStart.bind(this), {passive: false});
        this.zoomArea.current.addEventListener('touchmove', this.onTouchMove.bind(this), {passive: false});
        this.zoomArea.current.addEventListener('touchend', this.onTouchEnd.bind(this), {passive: false});
    
        this.zoomArea.current.addEventListener('mousedown', this.onTouchStart.bind(this), {passive: false});
        this.zoomArea.current.addEventListener('mousemove', this.onTouchMove.bind(this), {passive: false});
        this.zoomArea.current.addEventListener('mouseup', this.onTouchEnd.bind(this), {passive: false});
    }
    
    if(this.props.imageZoom){
      setTimeout(() => {
          this.initializeZoomArea();
      },100)
    }else{
      this.initializeZoomArea();
    }


    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    if(this.zoomArea.current) {
        this.zoomArea.current.addEventListener('mousedown', this.onTouchStart.bind(this), {passive: false});
        this.zoomArea.current.addEventListener('mousemove', this.onTouchMove.bind(this), {passive: false});
        this.zoomArea.current.addEventListener('mouseup', this.onTouchEnd.bind(this), {passive: false});
    
        this.zoomArea.current.removeEventListener('touchstart', this.onTouchStart.bind(this), {passive: false});
        this.zoomArea.current.removeEventListener('touchmove', this.onTouchMove.bind(this), {passive: false});
        this.zoomArea.current.removeEventListener('touchend', this.onTouchEnd.bind(this), {passive: false});
    }
    
    window.removeEventListener('resize', this.onResize);
  }

  onResize = debounce(() => {
    this.initializeZoomArea();
  }, 300);

  componentDidUpdate(prevProps:any, prevState:any) {
    if (prevProps.externalZoomFactor !== this.props.externalZoomFactor || prevProps.windowWidth !== this.props.windowWidth || (prevProps.isZoomed !== this.props.isZoomed && this.props.isZoomed === false)) {
      this.initializeZoomArea();  
    }
    if(this.props.isZoomed && prevState.zoomFactor > this.state.zoomFactor && this.state.zoomFactor === this.getInitialZoomFactor()){
      this.props.resetZoom(false);
    }
    
  }

  handleOnActionButtonClick = (callback:any) => {
    if(!this.state.isZoomImageDrag){
        callback && callback();        
    }
}

    onWheel = (e:any) => {			
        this.setState({		
        isScroll: true		
        });		
        let scrollTimeOut = setTimeout(() => {		
            this.setState({		
                isScroll: false		
            });
        clearTimeout(scrollTimeOut);		
        		
        }, 250);	
    }

    createStyle() {
        const {offset, zoomFactor, isTouching} = this.state;
        const offsetX = -offset.x / zoomFactor;
        const offsetY = -offset.y / zoomFactor;
        const hasScaleRatio = !isNaN(zoomFactor);
        const transform3d = `scale3d(${zoomFactor}, ${zoomFactor}, ${zoomFactor}) translate3d(${offsetX}px, ${offsetY}px, 0)`;
        const transform2d = `scale(${zoomFactor}) translate(${offsetX}px, ${offsetY}px)`;
        const zoom = {zoomFactor}
        return {
        transform: isTouching ? transform2d : transform2d,
        visibility: hasScaleRatio || this.props.imageZoom ? 'visible' : 'hidden',
        width: this.props.width,
        height: this.props.height,
        'transformOrigin':'0 0'
        };
    
        // const transform3d = `scale3d(${zoomFactor}, ${zoomFactor}, ${zoomFactor}) translate3d(${offsetX}px, ${offsetY}px, 0)`;
        //const transform2d = `scale(${zoomFactor}) translate(${offsetX}px, ${offsetY}px)`;
        // const zoom = {zoomFactor}
        // return {
        //   zoom:zoomFactor,
        //   left: `${offsetX}px`,
        //   top: `${offsetY}px`,
        //   position: `relative`,
        //   //transform: isTouching ? transform2d : transform2d,
        //   visibility: hasScaleRatio || this.props.imageZoom ? 'visible' : 'hidden',
        // };
    }

  render() {
    const contentStyle = this.createStyle();

    return (
        <div className={classNames('h-[calc(100%_-_36px)] my-[18px] mx-[22px] zoom-canvas-container', {
            'h-[unset]': this.props.imageZoom,
            'flex items-center justify-center': this.props.imageZoom && (this.state.zoomFactor || isNaN(this.state.zoomFactor)),
            'w-full h-full': this.props.imageZoom && isTouchDevice()
        })}
        ref={this.zoomArea}
        >
            {!this.props.imageZoom && <FlashcardControlsContainer displayStyle={this.state.isScroll}
              onActionFlashcard={!this.props.canEditExercise ? this.props.onActionFlashcard : this.props.parentClick} 
              canEditExercise={this.props.canEditExercise}
              listFlashcardId={this.props.listFlashcardId}/>}
            <div className={classNames('contentt flex',{
                'w-full h-full': this.props.imageZoom
            })} onClick={(e) => this.props.imageZoom && this.handleOnActionButtonClick(() => this.props.onActionFlashcard(true)) }
            // @ts-ignore
            style={contentStyle}
            ref={this.contentElement}>
                {this.props.children}
            </div>
        </div>
    )
  }

  initializeZoomArea() {
    this.setState({
      zoomFactor: isNaN(this.getInitialZoomFactor()) ? 1 : this.getInitialZoomFactor()
    }, () => {
      this.resetOffset();
    });
    
  }

  onTouchEnd() {
    if (this.props.isDisabled) return;
    this.setState({
      lastTouch: null,
      lastDistance: null,
      lastScale: 1,
      lastZoomCenter: null,
      isTouching: false,
      isScroll: false	
    });
  }

  mapTouches(touches:any) {
    const rect = this.zoomArea.current.getBoundingClientRect();
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;
    const posTop = rect.top + scrollTop;
    const posLeft = rect.left + scrollLeft;
    if(touches){
      if(!isTouchDevice()){
        return [{x: touches.pageX - posLeft, y: touches.pageY - posTop}]
      } else {
        return Array.from(touches).map((touch:any) => ({x: touch.pageX - posLeft, y: touch.pageY - posTop})); 
      }
    }
  }

  getMaxOffsets() {
    const elWidth = this.contentElement.current.offsetWidth * this.state.zoomFactor;
    const elHeight = this.contentElement.current.offsetHeight * this.state.zoomFactor;
    const maxX = elWidth - this.zoomArea.current.offsetWidth,
      maxY = elHeight - this.zoomArea.current.offsetHeight;

    return {x: maxX, y: maxY};
  }

  onTouchMove(event:any) {
    if (this.props.isDisabled) return;
    if (!this.state.isTouching) return;
    const touches = this.mapTouches(isTouchDevice() ? event.touches : event);
    const maxOffsets = this.getMaxOffsets();
    if (touches && touches.length === 1 && maxOffsets.x < 10 && maxOffsets.y < 10) {
      return;
    }
    this.cancelEvent(event);
    const touch = touches && touches[0];
    if (!this.state.lastTouch) {
      this.setState({
        lastTouch: touch
      });
      return;
    }
    if (touches && touches.length === 2) {
      this.setState({		
        isScroll: true		
      });
      this.handleZoom(touches, calculateScale(this.state.startTouches, touches));
      return;
    }
    this.handleDrag(touch);

    this.setState({
      lastTouch: touch
    });
  }

  handleZoom(touches:any, newScale:any) {
    const {lastScale} = this.state;
    const scale = newScale / lastScale;
 
    const touchCenter = getVectorAvg(touches);

    this.setState({
      lastScale: newScale
    });

    this.scale(scale, touchCenter);
    // this.drag(touchCenter, this.state.lastZoomCenter);
    this.setState(({offset}:any) => ({
      offset: this.sanitizeOffset(offset)
    }));
    this.setState({
      lastZoomCenter: touchCenter
    });
  }

  scale(scale:any, center:any) {
    scale = this.scaleZoomFactor(scale);
    this.addOffset({
      x: (scale - 1) * (center.x + this.state.offset.x),
      y: (scale - 1) * (center.y + this.state.offset.y)
    });
  }

  scaleZoomFactor(scale:any) {
    const originalZoomFactor = this.state.zoomFactor;
    let newZoomFactor = this.state.zoomFactor * scale;
    const minZoomFactor = this.getInitialZoomFactor();
    newZoomFactor = Math.min(MAX_ZOOM, Math.max(newZoomFactor, minZoomFactor));
    this.setState({
      zoomFactor: newZoomFactor
    });
    return newZoomFactor / originalZoomFactor;
  }

  handleDrag(touch:any) {
    
    this.drag(touch, this.state.lastTouch);
    this.setState(({offset}:any) => ({
      offset: this.sanitizeOffset(offset)
    }));
    this.setState({
      lastTouch: touch,
      isZoomImageDrag: true
    });
  }

  getInitialZoomFactor() {
    if (!this.zoomArea.current) return 1;
    const xZoomFactor = this.zoomArea.current.offsetWidth / this.contentElement.current.offsetWidth;
    const yZoomFactor = this.zoomArea.current.offsetHeight / this.contentElement.current.offsetHeight;
    let factor;
    if (xZoomFactor < 1 || yZoomFactor < 1) {
      // If any axis is already too small, use the biggest factor
      factor = Math.max(xZoomFactor, yZoomFactor);
    } else {
      // Both axis are small enough, use the smallest factor
      factor = Math.min(xZoomFactor, yZoomFactor);
    }
    // Does not allow the zoom to go under 1 (minZoom)
    if(this.props.externalZoomFactor > 1){
      return Math.abs(factor * this.props.externalZoomFactor);
    }
    if (this.props.minZoom >= 0) {
      return Math.max(factor, this.props.minZoom);
    }
    return factor;
  }

  sanitizeOffset(offset:any) {
    const maxOffsets = this.getMaxOffsets();
    const maxX = maxOffsets.x,
      maxY = maxOffsets.y,
      maxOffsetX = Math.max(maxX, 0),
      maxOffsetY = Math.max(maxY, 0),
      minOffsetX = Math.min(maxX, 0),
      minOffsetY = Math.min(maxY, 0);
    return {
      x: Math.min(Math.max(offset.x, minOffsetX), maxOffsetX),
      y: Math.min(Math.max(offset.y, minOffsetY), maxOffsetY)
    };
  }

  drag(center:any, lastCenter:any) {
    if (!lastCenter) return;
    this.setState({		
      isScroll: true		
    });
    this.addOffset({
      x: -(center.x - lastCenter.x),
      y: -(center.y - lastCenter.y),
    });
  }

  addOffset({x, y}:any) {
    this.setState(({offset}:any) => ({
      offset: {
        x: offset.x + x,
        y: offset.y + y,
      }
    }));
  }

  onTouchStart(event:any) { 
    if (this.props.isDisabled) return;
    if (isTouchDevice() && !event.touches) return;
    const touches = this.mapTouches(isTouchDevice() ? event.touches : event);
    this.setState({
      startTouches: touches,
      isTouching: true,
      isZoomImageDrag: false
    });
    if (this.detectDoubleTab(touches)) {
      this.cancelEvent(event);
    }
  }

  detectDoubleTab(touches:any) {
    const time = (new Date()).getTime();
    if (touches.length > 1) {
      this.setState({
        lastTouchStart: null
      });
      return false;
    }
    // @ts-ignore
    const isDoubleTap = (time - this.state.lastTouchStart) < 300;
    this.setState({
      lastTouchStart: time
    });
    return isDoubleTap;
  }

  cancelEvent(event:any) {
    event.preventDefault();
    event.stopPropagation();
  }
  computeInitialOffset2() {
    const sanitizedOffset = this.sanitizeOffset({
      x: -(Math.abs(this.contentElement.current.offsetWidth * this.state.zoomFactor - this.zoomArea.current.offsetWidth) / 2),
      y: -(Math.abs(this.contentElement.current.offsetHeight * this.state.zoomFactor - this.zoomArea.current.offsetHeight) / 2),
    });
    return sanitizedOffset;
  }
  computeInitialOffset() {
    //let x = -(Math.abs(this.contentElement.current.offsetWidth * this.state.zoomFactor - this.zoomArea.current.offsetWidth) / 2);
    let x = (this.contentElement.current.offsetWidth * this.state.zoomFactor - this.zoomArea.current.offsetWidth) / 2;
    let contentOffsetHeight;
    let isScrollPresent = this.contentElement.current.offsetHeight > this.zoomArea.current.offsetHeight;
    let adjustFactor;
    if(isScrollPresent){
      adjustFactor = 0;
      contentOffsetHeight = (this.zoomArea.current.offsetHeight - adjustFactor);
    } else {
      contentOffsetHeight = this.contentElement.current.offsetHeight;
    }
    let y = ((contentOffsetHeight * this.state.zoomFactor - this.zoomArea.current.offsetHeight) / 2);
    if(isScrollPresent){
      y = ((contentOffsetHeight * this.props.externalZoomFactor - this.zoomArea.current.offsetHeight) / 2);

    }

    let finalOffset = this.sanitizeOffset({x, y})
    return finalOffset;
    //return (this.isTouchDevice || (this.props.externalZoomFactor === 1 && !this.isTouchDevice)) ? this.sanitizeOffset({x,y}) : {x: -x, y: -y};
  }

  resetOffset() {
    this.setState({
      offset: this.computeInitialOffset()
    });
  }

}

const mapStateToProps = (state:any) => ({
    windowWidth: windowWidth(state)
  });

  function calculateScale(startTouches:any, endTouches:any) {
    const startDistance = getDistance(startTouches[0], startTouches[1]);
    const endDistance = getDistance(endTouches[0], endTouches[1]);
    return endDistance / startDistance;
  }


  function getDistance(a:any, b:any) {
    let x, y;
    x = a.x - b.x;
    y = a.y - b.y;
    return Math.sqrt(x * x + y * y);
  }

  function sum(a:any, b:any) {
    return a + b;
  }


  function getVectorAvg(vectors:any) {
    return {
      x: vectors.map(function (v:any) {
        return v.x;
      }).reduce(sum) / vectors.length,
      y: vectors.map(function (v:any) {
        return v.y;
      }).reduce(sum) / vectors.length
    };
  }
  
export default connect(mapStateToProps, null)(ZoomCanvasV3);


