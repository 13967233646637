import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getFlashcardIdsRoutine } from 'actions/api';
import { setActiveMediaTab } from 'actions/navigation';
import { getActiveMediaTab } from 'selectors/navigation/navigationSelector';
import { quickStatusById } from 'selectors/quickSelectors';
import { getActiveSubject } from 'selectors/subject/subjectSelector';
import { mediaTypeTabs } from 'v2/helpers';
import useMediaStatus from 'hooks/useMediaStatus';
import MediaTab from './MediaTab'
import { isMBFromSchool } from 'selectors/user';
import useExamModuleStatus from 'hooks/useExamModuleStatus';

type MediaTypeTabSelectorProps = {
  page?: string;
  subjectId: string;
}

type ItemType = {
  name: string;
  mediaTab: string;
  icon: string;
}

const MediaTypeTabSelector: React.FC<MediaTypeTabSelectorProps> = ({ page, subjectId }) => {
  const dispatch = useDispatch();
  const activeMediaTab = useSelector(getActiveMediaTab);
  const activeSubject = useSelector(getActiveSubject);
  const hasMBFromSchool = useSelector(isMBFromSchool);
  const mediaQuickStatus = useSelector((state) => quickStatusById(state, subjectId))?.toJS();
  const examModuleStatus = useExamModuleStatus();
  const { setMediaTab } = useMediaStatus();
  const getFlash = mediaQuickStatus?.flashcard?.total;
  const getRevison = mediaQuickStatus?.revision?.total;
  const getVideo = mediaQuickStatus?.video?.total;
  const getExams = mediaQuickStatus?.exam?.freeResponse?.total;

  const mediaFilterStatus:boolean = useMemo(() => {
    return [getFlash, getRevison, getVideo, examModuleStatus.status && hasMBFromSchool].filter((item:any) => item).length > 1
  },[getFlash, getRevison, getVideo, subjectId, hasMBFromSchool, examModuleStatus.status])

  useEffect(() => {
    dispatch(getFlashcardIdsRoutine({ subjectId: activeSubject?.id }));
    setMediaTab();
  }, [activeSubject?.id, getFlash, getRevison, getVideo, getExams])

  return (
    <>
      <div className={classNames('media-type-tab-container h-[94px] w-full', { 'pt-4 bg-none': page === 'search-page', 'bg-gradient px-5 py-4': page !== 'search-page' })}>
        <div className='flex justify-between'>
          {mediaTypeTabs.map((item: ItemType) => ((item.mediaTab !== 'exams' && mediaQuickStatus?.[item.mediaTab]?.total > 0) || (item.mediaTab === 'exams' && mediaQuickStatus?.exam?.freeResponse?.total > 0)) && 
          <MediaTab isActionTab={activeMediaTab === item.mediaTab} onClick={() => dispatch(setActiveMediaTab(item.mediaTab))} icon={item.icon} name={item.name} showBar={mediaFilterStatus}/>)}
        </div>
      </div>
    </>
  )
}

export default MediaTypeTabSelector
