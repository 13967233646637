import React, {memo, useEffect, useRef, useState} from 'react'
import {isTouchDevice} from 'helpers';
import {IncreaseFlashcardZoom, DecreaseFlashcardZoom, PinZoomIncrease} from 'icons/FlashcardIcons'
import ZoomCanvas from 'v2/components/studying/ZoomCanvasV3';
import RendererArea from 'v2/components/studying/renderer/RendererArea';
import usePrevious from 'hooks/usePrevious';
import './Renderer.scss';
import { useSelector } from 'react-redux';
import { windowWidth } from 'selectors';

type RendererProps = {   
    canEditExercise: boolean;
    isActiveZoom?: boolean;
    hasZoom: boolean;
    width: number;
    height: number;
    isMobile: boolean;
    currentFlashcardId: string;
    onActionFlashcard?:(actionKey: string, value: any, callback:() => void, canFlip: boolean) => void;
    parentClick?:(val:any) => void;
    listFlashcardId: any;
    selectedSide: any;
    elements: any;
}  

const Renderer: React.FC<RendererProps> = ({canEditExercise, isActiveZoom, hasZoom, width, height, isMobile, currentFlashcardId, onActionFlashcard, parentClick, listFlashcardId, selectedSide, elements}) => {
    const zoomCanvas = useRef<any>();
    const [ishasZoom, setHasZoom] = useState(false)
    const [zoomLevel, setZoomLevel] = useState({current: 1, max: 2})
    const prevCurrentFlashcardId = usePrevious(currentFlashcardId)
    const prevSelectedSide = usePrevious(selectedSide)
    const getWindowWidth = useSelector(windowWidth)
    
    useEffect(() => {
        if(prevCurrentFlashcardId !== currentFlashcardId || prevSelectedSide !== selectedSide){
            zoomCanvas.current?.initializeZoomArea();
            onReset();
        }
    }, [currentFlashcardId, selectedSide])

    const handleMapMove = (e: any) => {
        if (e.touches && e.touches.length === 2) {
            setHasZoom(true)  
        }
    }

    const onReset = () => {
        setHasZoom(false)
        setZoomLevel((prevZoomLevel) => {
            return {...prevZoomLevel, current: 1}
        })
    }

    const onZoomOut = () => {
        let finalZoomLevel = zoomLevel.current > 1.5 ? 1.5 : 1;
        if(zoomLevel.current > 1){
            setZoomLevel((prevZoomLevel) => {
                return {...prevZoomLevel, current: finalZoomLevel}
            })
        }
    }

    const onChangeZoomRange = (e: any) => {
        setZoomLevel((prevZoomLevel) => {
            return {...prevZoomLevel, current: parseFloat(e.target.value)}
        })
    }

    const onZoomIn = () => {
        let finalZoomLevel = zoomLevel.current < 1.5 ? 1.5 : 2; 
        if(zoomLevel.current < zoomLevel.max){
            setZoomLevel((prevZoomLevel) => {
                return {...prevZoomLevel, current: finalZoomLevel}
            })
        }
    }
    const setZoomReset = (val: boolean) => {
        setHasZoom(val)
    }
   
    const editclass = canEditExercise ? 'no-shadow' : 'show-shadow';    
    return <div className={`renderer-wrapper ${editclass}`} onTouchStart={(e) => handleMapMove(e)}>
        {(!canEditExercise || isActiveZoom) &&
            <div className={'zoom-section'}>
                {((zoomLevel.current > 1 && hasZoom ) || (ishasZoom)) && 
                  <div className={'pin-zoom'} onClick={(e) => onReset()}>
                    <PinZoomIncrease /> 
                  </div> 
                }
                {(hasZoom && !isTouchDevice() ) &&  <div className={'zoom-factor'}>
                    <div className={`decrease-zoom ${zoomLevel.current === 1 && 'remove-decrease-click' }`} onClick={(e) => onZoomOut()}>
                        <DecreaseFlashcardZoom />
                    </div>
                    <div className={'main-progress-zoom'}>
                        <input className={'input-range'} type="range" min="1"  max="2" step="0.01" value={zoomLevel.current} onChange={(e) => onChangeZoomRange(e)}/>
                    </div>
                    <div className={`increase-zoom ${zoomLevel.current === 2 && 'remove-increase-click' }`} onClick={(e) => onZoomIn()}>
                        <IncreaseFlashcardZoom />
                    </div>
                </div>} 
            </div>
        }
        <ZoomCanvas minZoom={0} width={width} height={height} 
            // ref={zoomCanvas}
            windowWidth={getWindowWidth}
            isDisabled={false}
            externalZoomFactor={zoomLevel.current} 
            isZoomed={ishasZoom} 
            onActionFlashcard={onActionFlashcard} 
            parentClick={parentClick} 
            canEditExercise={canEditExercise} 
            resetZoom={setZoomReset} 
            imageZoom={false}
            listFlashcardId={listFlashcardId}>
                <RendererArea width={width} height={height} isZoomed={zoomLevel.current>1} >
                    {elements}
                </RendererArea>
        </ZoomCanvas>
    </div>
}

export default memo(Renderer);