import React, {memo} from 'react'
import NotificationIcons from './NotificationIcons'
import swal from 'sweetalert';
import './notifications.scss'

export enum errorReasonsEnum{
    entityNotFound = 'entityNotFound',
    voucherHasBeenUsedForAccount = 'voucherHasBeenUsedForAccount',
    voucherRedeemLimit = 'voucherRedeemLimit',
    voucherExpired = 'voucherExpired',
    NotExercise = 'NotExercise',
}

type WarningNotificationProps = {
    title: errorReasonsEnum; 
    icon?: any; 
}  

const errorReasons = {
    entityNotFound: 'Sorry, the voucher code you’ve entered doesn’t exist.',
    voucherHasBeenUsedForAccount: 'Sorry, this voucher has already been used on this account.',
    voucherRedeemLimit: 'Sorry, this voucher has reached its redeem limit.',
    voucherExpired: 'Sorry, this voucher is past its expiration date.',
    NotExercise: 'Sorry, you’re not authorized to view this Exercise.'
  };

const WarningNotification: React.FC<WarningNotificationProps> = ({title, icon}) => {
    return <div className='swal-notification clear-margin'>
        <div className="notification-content">
          <div className="notification-icon">
            <img src={NotificationIcons[icon]} alt="warning-icon"/>
          </div>
          <div className="swal-notification-title">
            {errorReasons[title]}
          </div>
        </div>
        <div className="notification-button" onClick={() => {swal.close()}}>OK</div>
    </div>
}

export default memo(WarningNotification);