import React from 'react'

type LoadMoreButtonProps = {
  hide: boolean;
  onClick: () => void;
  selector: any;
}

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({ hide, onClick, selector }) => {
  if (hide) return null;
  if (selector.succeeded && selector.data.length < Number.parseInt(selector.count)) {
    return <div className='load-more-btn uppercase px-5 rounded-[27px] font-bold text-12px tracking-2px mx-5 flex items-center justify-center text-white cursor-pointer leading-[18px] bg-blue-normal mt-6 mb-2.5 py-2 select-none' onClick={onClick}>Load More</div>
  }
  return null;
}

export default LoadMoreButton