const PREFIX = 'TOPICS_TREE/';

export const TOGGLE_TOPIC_VISIBILITY = PREFIX + 'TOGGLE_TOPIC_VISIBILITY';
export const TOGGLE_SUBTOPIC_VISIBILITY = PREFIX + 'TOGGLE_SUBTOPIC_VISIBILITY';
export const TOGGLE_NODE_SELECTION = PREFIX + 'TOGGLE_NODE_SELECTION';
export const SET_ACTIVE_NODES = PREFIX + 'SET_ACTIVE_NODES';
export const SET_TREE_NODES = PREFIX + 'SET_TREE_NODES';

export const OPEN_NODES = PREFIX + 'OPEN_NODES';
export const GET_SELECTED_FLASHCARDS = PREFIX + 'GET_SELECTED_FLASHCARDS';
export const SELECT_FLASHCARDS = PREFIX + 'SELECT_FLASHCARDS';
export const SELECT_ACTIVE_TOPIC_ID = PREFIX + 'SELECT_ACTIVE_TOPIC_ID';
export const SELECT_ACTIVE_SUB_TOPIC_ID = PREFIX + 'SELECT_ACTIVE_SUB_TOPIC_ID';

const EXERCISE_PREFIX = 'EXERCISE_TREE/';
export const SET_EXERCISE_TREE_NODES = EXERCISE_PREFIX + 'SET_EXERCISE_TREE_NODES';

export const SET_FILTERED_EXERCISE_FLASHCARD_IDS = PREFIX + 'SET_FILTERED_EXERCISE_FLASHCARD_IDS';
export const UPDATE_EXERCISE_NUMBER = PREFIX + 'UPDATE_EXERCISE_NUMBER'