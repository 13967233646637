import React from "react";
import { useSelector } from "react-redux";
import { getUserInitials } from 'selectors'
import Avatar from 'v2/ui-components/Avatar';

type InternalAvatarContainerProps = {
  size: number,
  alternate?: boolean,
  initialFlashcard?: boolean,
  className?: string,
  accountPortal?: boolean
}
const InternalAvatarContainer: React.FC<InternalAvatarContainerProps> = ({ size, initialFlashcard, alternate, className }) => {

  const userInitials = useSelector(getUserInitials)
  return <Avatar className={className} size={size} alternate={alternate} initialFlashcard={initialFlashcard}> {userInitials}
  </Avatar>
}

export default InternalAvatarContainer;