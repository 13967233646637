import React, { useEffect, useRef, useState } from 'react'
import {ReactComponent as CloseIcon} from 'assets/icons/page-overlay/close-icon-turquoise.svg';
import './feature.scss'

type FeaturesProps = {
   data: any;
   onClick: (val: number) => void;
}  
let lastScrollTop = 0
const Features: React.FC<FeaturesProps> = ({data, onClick}) => {
    const popUpScrollRef = useRef<any>()
    const headerRef = useRef<any>();
    const [isScrolled, setScrolled] = useState(false)

    useEffect(() => {
        popUpScrollRef?.current?.addEventListener('scroll', handlePopUpScroll);
    })

    const handlePopUpScroll = (e: any) => {
        e.stopPropagation() 
        let scrollTop = popUpScrollRef.current.scrollTop
        if (scrollTop > lastScrollTop && scrollTop > 0){
            if(popUpScrollRef.current.scrollHeight - popUpScrollRef.current.scrollTop >= popUpScrollRef.current.clientHeight){
              if(!isScrolled){
                setScrolled(true)
              }   
            }
        }
        lastScrollTop = scrollTop;
        if(popUpScrollRef.current.scrollHeight - popUpScrollRef.current.scrollTop === popUpScrollRef.current.clientHeight) {
          if(isScrolled){
            setScrolled(false)
          }
        }
        if(scrollTop === 0){
            setScrolled(false)
        }
    }

    useEffect(() => {
        if(isScrolled){
            headerRef.current?.classList.add('pop-up-header-big')
            headerRef.current.style.display = "unset"
        }else{
            headerRef?.current?.classList.remove('pop-up-header-big')
            // headerRef.current.style.display = "none"
        }
    }, [isScrolled])


    return <div className="pop-up">
            <div className="pop-up-main-container" ref={popUpScrollRef}>
                <div className="pop-up-close-icon-box" onClick={()=> onClick(data?.[0]?.id)}>
                    <div className="pop-up-close-icon">
                    <CloseIcon />
                    </div>
                </div>
                {data?.[0]?.image && <div className="pop-up-image">  
                    <img className="pop-up-image-url" src={data?.[0]?.image} alt=""/>   
                </div>}
                <div className="pop-up-container1">
                    <div className="pop-up-title2">{data?.[0]?.title}</div>
                    <div className="pop-up-desc">{data?.[0]?.introText} </div>
                    <div className="pop-up-feature-container">  
                    {data?.[0]?.feature.map((val: any, index: number) =>
                        <div key={index} className="pop-up-feature-box">  
                        {val?.icon &&<div className="pop-up-feature-icon">
                            <img className='feature-icon-image' src={val?.icon} alt="" />
                        </div>}
                        <div className="pop-up-feature-inner">
                            <div className="pop-up-feature-title">{val?.title}</div> 
                            <div className="pop-up-feature-desc">{val?.text}</div> 
                        </div>
                        </div>
                    )}
                    </div>          
                </div>
            </div>
            <div className="pop-up-footer">
              <div className="pop-up-cta">  
                {data?.[0]?.cta.map((val: any, index: number) => 
                    val?.text && <a key={index} href={val?.['link-web']} target="_blank" rel="noreferrer"><div className="pop-up-cta-button">{val?.text}</div></a>
                )}
              </div>
            </div>
    </div>
}

export default Features;