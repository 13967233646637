import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {isDeskEditModeActive} from 'selectors/navigation/navigationSelector'
import {toggleDeskEditMode, toggleFlashcardNavigationVisibility} from 'actions/navigation/navigationActions'
import {deselectAllItems} from 'actions/desk/deskActions'
import './button.scss'

type EditButtonProps = {
    className?: string; 
} 

const EditButton: React.FC<EditButtonProps> = ({className}) => {
    const dispatch = useDispatch();
    const hasDeskEditModeActive = useSelector(isDeskEditModeActive)
    const onclick = () => {
        dispatch(toggleDeskEditMode(true))
        dispatch(deselectAllItems())
        dispatch(toggleFlashcardNavigationVisibility(false))
    }
    if(hasDeskEditModeActive) return null;
    return <div onClick={onclick}>
        <div className={`edit-btn-text ${className} text-blue-normal cursor-pointer`}> EDIT </div>
    </div>
}

export default EditButton;