import React from 'react';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveExamsTab } from 'actions/navigation';
import { getExams } from 'actions/api/apiActions';
import { activeSubjectId, subjectsTeachingLevel } from 'selectors/subject/subjectSelector';
import {title, comingSoonText, subtitles, examsIconTitle, examsIcon, buttonText, pandemicComingSoonText, pandemicSubtitles, mathsTitle} from './paperESMText'
import { pandemicExamsIds } from 'v2/helpers';
import { useParams } from 'react-router-dom';

type PaperESMProps = {
    color?: string;
    from: string;
    inactivePaper?: boolean;
    type: string;
}
type StyleTypes = { 
    [key: string]: string | undefined 
}
const PaperESM: React.FC<PaperESMProps> = ({ color, inactivePaper, from, type }) => {
    const dispatch = useDispatch();
    const getActiveSubjectId = useSelector(activeSubjectId);
    const getSubjectsTeachingLevel = useSelector(subjectsTeachingLevel);
    const subjectTeachingLevel = getSubjectsTeachingLevel[getActiveSubjectId];
    const params: any = useParams()
    const examId = params.examId
    const sciences = [3, 47, 50];
    let heading: string
    const isMathematics = [14982 , 15075].includes(getActiveSubjectId);
    if(isMathematics) {
        heading = mathsTitle[getActiveSubjectId][subjectTeachingLevel][type];
    }else {
        heading = title[type];
    }
    const changeExam = (val: string) => {
        if (val === 'ib_dp') {
            dispatch(setActiveExamsTab('osc_dp'));
        }
        if (['osc_dp', 'osc_dp_mocks'].includes(val)) {
            dispatch(setActiveExamsTab('ib_dp'));
        }
        dispatch(getExams());
    }
    
    return <div className={classNames('mx-auto', { 'mt-0': from === 'exam', 'mt-10': (from !== 'exam' && type === 'filterPaper'), 'mt-[60px]': !['exam', 'paper'].includes(from) , 'pt-2 px-5': from === 'exam', 'p-5': from !== 'exam' })}>
        {getActiveSubjectId && pandemicExamsIds.includes(examId)  ? <Title color={'#378EF8'} title={pandemicComingSoonText[type]} /> : comingSoonText[type] && <Title color={color} title={comingSoonText[type]} />}
        <div className='mt-4 text-#A2A3A3 text-13px text-center leading-4'>
            {(inactivePaper === undefined && from === 'paper') && <div className='my-0 mx-auto'><FilterIcon /></div>}
            <div className={classNames({ 'text-blue-normal text-17px font-bold pt-3 leading-23px': type === "filterPaper", 'font-normal': type !== "filterPaper" })}>{heading}</div>

            {getActiveSubjectId && pandemicExamsIds.includes(examId) ?  <div className='pt-4 font-bold' dangerouslySetInnerHTML={{ __html: pandemicSubtitles[type] }} />: 
            <div className={classNames('pt-4', {'font-bold':['exam'].includes(from)})} dangerouslySetInnerHTML={{ __html: subtitles[type] }} />}
        </div>
        {buttonText[type] && <div className='esm-btn shadow-[0_0_0_1px_#E3E6E6] bg-white rounded-[22px] py-[9px] mt-4 flex justify-center items-center select-none uppercase cursor-pointer ' onClick={() => changeExam(type)}><div className='text-content text-blue-normal text-13px font-bold tracking-2px leading-18px mr-[11px]'>{buttonText[type]}</div></div>}
    </div>
}

export default PaperESM

type TitleTypes = {
    color?: string;
    title: string;
}
const Title: React.FC<TitleTypes> = ({ color, title }) => {
    const style: StyleTypes = {
        'color': color,
        'border': '1px solid ' + color
    }
    return <div className='m-auto rounded-[6px] max-w-[fit-content] pt-1 pr-1.5 pb-[5px] pl-[9px]' style={style}>
        <div className='h-4 text-[12px] leading-16px'>{title}</div>
    </div>
}

type OfficialIBExamsTypes = {
    activeExamsTab: string;
}
export const OfficialIBExams: React.FC<OfficialIBExamsTypes> = ({ activeExamsTab }) => {
    return <div className='max-w-[fit-content] flex items-center gap-1.5'>
        {examsIcon[activeExamsTab]}
        <div className={classNames('font-bold text-15px leading-19px', { 'text-[#3F63A0]': activeExamsTab === 'ib_dp', 'text-[#F06153]': activeExamsTab !== 'ib_dp' })}>{examsIconTitle[activeExamsTab]}</div>
    </div>;
}