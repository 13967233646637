import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveMediaTab, getUserId, listOfFilterCheckedItems, getSubtopicById, currentFlashcardId, getActiveSubject } from 'selectors';
import { getLastMediaTypeVisited, getFormExams, filterResultsListToArrayByTOJS } from 'helpers'
import { getTreeSectionValue, isTreeSectionExpanded } from 'selectors/topicsTreeSelector';
import { useHistory } from "react-router-dom";
import Subtopic from 'v2/components/topics-tree/Subtopic';
import Checkbox from 'v2/ui-components/Checkbox';
import StudyMetaInformation from 'v2/components/studying/studay-meta-info/StudyMetaInformation';
import { setActiveMediaTab } from 'actions/navigation'
import { toggleSubtopicVisibility, setActiveTopicId, setActiveSubTopicId } from 'actions/topics-tree/topicsTreeActions';

type SubtopicTileContainerProps = {
  parentIds: Array<[0, 0]>,
  parentTopic: any,
  subtopic: any,
  isExercise?: boolean | undefined,
  hasSelection: boolean | undefined,
  showAnswers: boolean,
  isExernalExercise: boolean | undefined,
  onPreviewClick?: (arg: any) => void | undefined,
  onClick?: (arg: any, arg1: number) => void
}

const SubtopicTileContainer: React.FC<SubtopicTileContainerProps> = ({ subtopic, parentIds, parentTopic, hasSelection, isExernalExercise, isExercise, showAnswers, onPreviewClick, onClick }) => {
  const activeMediaTab = useSelector(getActiveMediaTab);
  const subtopicId = subtopic?.id;
  const dispatch = useDispatch();
  let history = useHistory();
  const [isActiveMediaType, setActiveMediaType] = useState<string>(activeMediaTab);
  const isActive = useSelector((state) => getTreeSectionValue(state, subtopicId, parentIds, 'active'));
  const isExpanded = useSelector((state) => isTreeSectionExpanded(state, subtopicId, parentIds));
  const subtopicToOpen = useSelector((state) => getSubtopicById(state, subtopicId))?.toJS()
  const userId = useSelector(getUserId);
  const hasCurrentFlashcardId = useSelector(currentFlashcardId);
  const hasListOfFilterCheckedItems = useSelector(listOfFilterCheckedItems);
  const subject = useSelector(getActiveSubject)
  const mediaType = getLastMediaTypeVisited(userId);
  const examsMode = mediaType === 'exams' && getFormExams() === 'exams';

  const onClickAction = (subtopicId: number, parentIds: any, topicId: number, exercise?: boolean, activeMedia?: string) => {
    setTimeout(() => {
      const filterList = hasListOfFilterCheckedItems.toJS()["flashcard"];
      if (isExercise) {
        if (subtopicToOpen) {
          let filteFlashcardData = subtopicToOpen?.flashcardsAnswers
          let filteFlashcardIds = filterResultsListToArrayByTOJS(filteFlashcardData, "flashcard", filterList).map((item: any) => {
            return item.flashcardId
          })
          onPreviewClick?.(filteFlashcardIds[0]);
        }
      } else {
          if (!isExpanded && isActive) {
            dispatch(toggleSubtopicVisibility(subtopicId, parentIds));
          }
          dispatch(setActiveTopicId(topicId));
          dispatch(setActiveSubTopicId(subtopicId));
          history.push(`/flashcard/subtopic/${subtopicId}`);
          dispatch(setActiveMediaTab(activeMedia))
      }

    }, 100);
  }

  const mircoFilter = useMemo(() => {
    if (isExercise) {
      const filterList = hasListOfFilterCheckedItems.toJS()["flashcard"]
      const subtopicOpen: any = subtopicToOpen;
      let filteFlashcardData = subtopicOpen && subtopicOpen?.flashcardsAnswers
      let filteFlashcardIds = filterResultsListToArrayByTOJS(filteFlashcardData, "flashcard", filterList)?.map((item: any) => {
        return item.flashcardId
      })
      return filteFlashcardIds?.length == 0 ? true : false
    } else {
      return false
    }
  }, [isExercise, hasListOfFilterCheckedItems])

  return <Subtopic key={subtopicId}
    active={!examsMode && isActive}
    label={subtopic?.label}
    parentLabel={parentTopic?.label}
    parentTlvl={parentTopic?.tlvl}
    parentTlvlTag={parentTopic?.teachingTag}
    title={subtopic?.title}
    expanded={isExpanded}
    hasSelection={hasSelection}
    isExernalExercise={isExernalExercise}
    onSelect={(e: any) => [e.stopPropagation(), onClickAction(subtopicId, parentIds, parentTopic?.id, isExercise, isActiveMediaType)]}
    isExercise={isExercise}
    currentFlashcardId={hasCurrentFlashcardId}
    checkMircoFilterFlashcard={mircoFilter}
    answers={showAnswers && <StudyMetaInformation activeMediaType={isActiveMediaType} topicData={subtopic} id={subtopicId} active={isActive} isSubtopic={true} hasSelection={hasSelection} isMcq={true}  onClick={(val:string) => setActiveMediaType(val)} className={`subtopic${subtopicId}`} onCardClick={(val: string) => onClickAction(subtopicId, parentIds, parentTopic?.id, isExercise, val)} />}
    absolute={hasSelection && <div key={subtopicId} className='absolute top-0 bottom-0 z-[1px] flex items-center left-[-34px]'><Checkbox id={subtopicId} parentIds={parentIds} /></div>}
    subject={subject}
    subtopic={subtopic}
    parentTopic={parentTopic}
  />
}
export default SubtopicTileContainer