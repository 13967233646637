import ExerciseDeskHeaderContainer from 'v2/components/exercise/ExerciseDeskHeaderContainer'


const ClassRoomHeader = () => {
    return <>
        <ExerciseDeskHeaderContainer />
        <div className='pt-3.5' />
    </>
}

export default ClassRoomHeader;