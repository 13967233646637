import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { mediaTypes, returnFinalStatusV2 } from 'helpers';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveMediaTab, isMBFromSchool, isOSCUser } from 'selectors' 
import {flashcardIconImg, revisionIconImg, videoIconImg, examIconImg} from 'v2/components/common/icons'
import MediaTab from './MediaTab'
import {activeSubjectId} from 'selectors/subject/subjectSelector';
import {saveScrollPosition, setActiveMediaTab} from 'actions/navigation/navigationActions';
import {quickStatusById} from 'selectors/quickSelectors';
import MediaTypeStatusBar from './MediaTypeStatusBar';
import ExamMediaTypeStatusBar from './ExamMediaTypeStatusBar';
import { MultipleChoice, FreeResponse } from "icons/HeaderIcons"
import useExamModuleStatus from 'hooks/useExamModuleStatus';

type MediaTabContainerProps = {
    statusData?: any;
    page?: string;
    isMargin?: boolean;
    type?: string;
} 

const MediaTabContainer: React.FC<MediaTabContainerProps> = ({type, statusData, page, isMargin=false}) => {
    const dispatch = useDispatch();
    const [mediaHeight, setMediaHeight] = useState(false)
    const {flashcardStatus, revisionStatus, videoStatus, flashcardStatusGraph, revisionStatusGraph, videoStatusGraph,examsStatus,examsStatusGraph,mcqStatusGraph,mcqStatus} = returnFinalStatusV2(statusData);
    const {flashcard, revision, video} = statusData ?? {};
    const examModuleStatus = useExamModuleStatus();
    const activeMediaTab = useSelector(getActiveMediaTab);
    const subjectId = useSelector(activeSubjectId);
    const hasMBFromSchool = useSelector(isMBFromSchool);
    const hasOSCUser = useSelector(isOSCUser)
    const mediaQuickStatus = useSelector((state) => quickStatusById(state, subjectId))?.toJS();
    const isMediaActive = (type: string) => {
        return activeMediaTab === type;
    }
    const flashcardCount = mediaQuickStatus.flashcard?.total
    const reivisonCount = mediaQuickStatus.revision?.total
    const videoCount = mediaQuickStatus.video?.total
    const examCount = mediaQuickStatus.exam?.freeResponse.total
    const mcqCount = mediaQuickStatus.exam?.msqTotal.total 

    useEffect(() => {
        if(!(hasOSCUser || hasMBFromSchool) && activeMediaTab === 'exams') {
            dispatch(setActiveMediaTab('flashcard'))
        }
    }, [hasMBFromSchool, hasOSCUser, activeMediaTab, dispatch])

    const isMcq = () =>{
        if(mcqStatus.total > 0 && examsStatus.total > 0 && type !== 'quickStart'){
            return true;
        }else{
            return false;
        }
    }

    const hasMcqFrq = useMemo(() => mcqStatus.total > 0 && examsStatus.total > 0,[mcqStatus, examsStatus]);
    const mediaFilterStatus = useMemo(() => {
        return [flashcardCount, reivisonCount, videoCount, examModuleStatus.status && (hasOSCUser || hasMBFromSchool)].filter((item:any) => item).length > 1
    },[flashcardCount, reivisonCount, videoCount, hasMBFromSchool, hasOSCUser, examModuleStatus.status])

    const selectMediaTab = (index : string, count: number) => {
        if(type !== 'quickStart'){
            const values = {
                "previousValue": 0,
                "currentValue": 0
            }
            dispatch(saveScrollPosition("topicsPage", values))
            if (count < 1) {
                setMediaHeight(true)
            } else {
                setMediaHeight(false)
            }
        }  
        dispatch(setActiveMediaTab(index))
    }

    const styleMinHeight = (val: string) => {
        if(val === 'outer'){
            if(isMediaActive(mediaTypes.exams)){
                if(mediaHeight){
                    return 'min-h-94px';
                }
                return isMcq() ? 'min-h-348px': type !== 'quickStart' ? "min-h-278px" : hasMcqFrq ? "min-h-247px" : "min-h-178px";
            }
            return mediaHeight ? 'min-h-94px': "min-h-178px";
           
        }else{
            if(mediaHeight){
                return 'h-94px';
            } 
            return 'h-142px';
        }
    }

    const examStatusData = useMemo(() => {
        const examData = [];
        if(mcqStatus.total > 0){
            examData.push({number : mcqStatus.total, title: 'Multiple choice', mediaStatusData: mcqStatusGraph, isRead: mcqStatus.total === mcqStatus.correct, mediaType: 'exams', icons: <MultipleChoice />, isMCQ: true, showLine: (activeMediaTab === 'exams' && examsStatus.total > 0) })
        }
        if(examsStatus.total > 0){
            examData.push({number : examsStatus.total, title: 'Free response', mediaStatusData: examsStatusGraph, isRead: examsStatus.total === examsStatus.correct, mediaType: 'exams', icons: <FreeResponse />, isMCQ: true, showLine: false })
        }
        return examData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeMediaTab, mcqStatus, examsStatus])
    
    return <div className={classNames(styleMinHeight('outer'), {'mt-2 mb-2.5':isMargin})}>
                <div className={classNames('media-tab-inner', styleMinHeight('inner'))}>
                    <div className="flex justify-between">
                        {flashcardCount > 0 && <MediaTab showBar={(flashcardCount > 0 && activeMediaTab === "flashcard" && mediaFilterStatus)} isActionTab={isMediaActive(mediaTypes.flashcard)} onClick={() => selectMediaTab(mediaTypes.flashcard, flashcardStatus.total)} icon = {flashcardIconImg} name={'Flashcards'} />} 

                        {reivisonCount > 0 && <MediaTab showBar={(reivisonCount > 0 && activeMediaTab === "revision" && mediaFilterStatus)} isActionTab={isMediaActive(mediaTypes.revision)} onClick={() =>  selectMediaTab(mediaTypes.revision, revisionStatus.total)} icon={revisionIconImg} name={'Guide'} />}

                        {videoCount > 0 && <MediaTab showBar={(videoCount > 0 && activeMediaTab === "video" && mediaFilterStatus)} isActionTab={isMediaActive(mediaTypes.video)} onClick={() => selectMediaTab(mediaTypes.video, videoStatus.total)} icon={videoIconImg} name={'Videos'} />}
                        
                        {examModuleStatus && (hasOSCUser || hasMBFromSchool) &&
                            <>
                                {(examCount > 0 || mcqCount > 0) && <MediaTab showBar={((examCount > 0 || mcqCount > 0 ) && activeMediaTab === "exams" && mediaFilterStatus)} isActionTab={isMediaActive(mediaTypes.exams)} onClick={() => selectMediaTab(mediaTypes.exams, examsStatus.total || mcqStatus.total)} icon={examIconImg} name={'Exams'} />}
                            </>
                        }
                    </div>
                    <>
                        {flashcardStatus.total > 0 && isMediaActive(mediaTypes.flashcard) &&
                            <MediaTypeStatusBar resultRange={flashcard?.absoluteNumbering || flashcard?.numbering} number={flashcardStatus.total} title={flashcardStatus.total > 1 ? 'Flashcards' :'Flashcard'} mediaStatusData={flashcardStatusGraph} isRead={flashcardStatus.total === flashcardStatus.correct} mediaType={"flashcard"} page={page}/>  
                        } 
                        {revisionStatus.total > 0 && isMediaActive(mediaTypes.revision) &&
                            <MediaTypeStatusBar resultRange={revision?.numbering} number={revisionStatus.total} title={revisionStatus.total > 1 ? 'Pages' :'Page'} mediaStatusData={revisionStatusGraph} isRead={revisionStatus.total === revisionStatus.read} mediaType={"revision"} page={page}/>  
                        }   
                        {videoStatus.total > 0 && isMediaActive(mediaTypes.video) &&
                            <MediaTypeStatusBar resultRange={video?.numbering} number={videoStatus.total} title={videoStatus.total > 1 ? 'Videos' :'Video'} mediaStatusData={videoStatusGraph} isRead={videoStatus.total === videoStatus.read} mediaType={"revision"} page={page}/>  
                        } 
                        {isMediaActive(mediaTypes.exams) &&
                            <ExamMediaTypeStatusBar examStatusData = {examStatusData} type={type}/>
                        }
                    </>
                </div>
            </div>

}

export default MediaTabContainer;