import CheckRadioButton from 'v2/components/check-radio-button/CheckRadioButton';
import { ForwardChevronIcon, OSCIcon,IBIcon} from 'icons/HeaderIcons';
import {List} from 'immutable';
import React,  { useState, useRef, useEffect } from 'react';
import styled, {css} from 'styled-components/macro';
import PropTypes from 'prop-types';
import Padding from 'ui-components/helpers/Padding';
import Row from 'ui-components/helpers/Row';
import Text from 'ui-components/helpers/Text';
import MarkText from 'ui-components/MarkText';
import {NoteListIcon, PlayOnThumbnailIcon} from 'icons/FlashcardIcons';
import {connect} from 'react-redux';
import './PreviewListItem.scss';
import {videoProgressById, videoPlayingStatusById} from 'selectors/videoProgressSelectors';
import TeachingLevelSeperator from 'components/teaching-level-selector/TeachingLevelSeperator';
import { activeSubjectId } from 'selectors';
import {SmallExamsIcons} from "icons/HeaderIcons"


const DefaultPreview = () => {
  return <div>Lorem ipsum sodium sit amet, consectetur adipiscing elit, sed do</div>;
};

export const CircledNumber = styled(Row)`
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  background: ${props => props.theme.blue};
  color: white;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  line-height: 1.1;
`;

const PreviewListItem = (props) => {
  const {flashcardNumber, flashcardId, rightSection, onClick, active, isEditing, listOfCheckedItems, deleted, previewCharacterLimit, shouldMark, type, previewTitle,activeMath} = props;
  let preview = props.preview;
  if (preview === ' ' || preview === '') {
    if(previewTitle != "New flashcard" && type !== 'notes' && previewTitle != ""){
      preview = previewTitle;
    }else{
      preview = 'The preview functionality is coming soon';
    }
  } else {
    if(previewTitle != "New flashcard" && type !== 'notes'){
      preview = previewTitle;
    }else{
      preview = preview;
    }
  }
  const parser = new DOMParser();
  preview = parser.parseFromString(`<!doctype html><body>${preview}`, 'text/html').body.textContent;

  return <StyledPreviewListItem onClick={onClick} active={active} deleted={deleted} activeMath={activeMath}>
    {isEditing && <CheckRadioButton itemId={flashcardId} listOfCheckedItems={listOfCheckedItems}/>}
    <FlashcardNumber>
      <Text size={15} weight={'bold'} color={'#2D3838'} lineHeight={'19px'}>{flashcardNumber}</Text>
    </FlashcardNumber>
    {type === 'notes' && <StyledNoteIcon><NoteListIcon /></StyledNoteIcon>}
    <Preview type={type}>
      <MarkText shouldMark={shouldMark} className={activeMath ? 'math-shot-desc note-short-desc' :'note-short-desc'} >{preview}</MarkText>
    </Preview>
    <RightSection type={type}>{rightSection}</RightSection>
  </StyledPreviewListItem>;
  
};

PreviewListItem.propTypes = {
  flashcardNumber: PropTypes.number.isRequired,
  preview: PropTypes.string.isRequired,
  rightSection: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  previewCharacterLimit: PropTypes.number,
  flashcardId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isEditing: PropTypes.bool,
  listOfCheckedItems: PropTypes.instanceOf(List),
};

PreviewListItem.defaultProps = {
  flashcardNumber: 0,
  previewCharacterLimit: 50,
  preview: <DefaultPreview/>,
  rightSection: <ForwardChevronIcon/>,
  onClick: () => {
    alert('Item clicked');
  },
  active: false,
  shouldMark: false
};

export default PreviewListItem;

export const VideoPreviewListItemComponent = (props) => {
  const {videoNumber, flashcardId, rightSection,thumbImage, onClick, active, title, isEditing, listOfCheckedItems,shortDescription, deleted, totalDuration, shouldMark, type, videoProgress, activeSubjectId } = props;

  let preview = shortDescription;
  if (!preview || preview === ' ' || preview === '') {
    preview = '';
  } else {
    preview = preview;
  }
  
  function secondToTime(timeInseconds){
    let totalWatched
    let  d = Number(timeInseconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h : 0;
    var mDisplay = m > 0 ? m > 9 ? m : '0' + m : '00';
    var sDisplay = s > 0 ? s > 9 ? s : '0' + s : '00';
    if(hDisplay>0){
      totalWatched = hDisplay + ':'+mDisplay +':' +sDisplay; 
    }else{
      totalWatched = mDisplay +':' +sDisplay; 
    }
    return totalWatched
  }

  let progressPecentage=0
  if(Number(videoProgress.get('totalTime')) && Number(videoProgress.get('currentTime'))){
    let totalDurationSeconds = Number(videoProgress.get('totalTime'))
    let totalWatchedSeconds = Number(videoProgress.get('currentTime'))
    progressPecentage=(totalWatchedSeconds/totalDurationSeconds)*100;
  }
  console.log(activeSubjectId, "activeSubjectId")
  const parser = new DOMParser();
  preview = parser.parseFromString(`<!doctype html><body>${preview}`, 'text/html').body.textContent;
  return <StyledVideoPreviewListItem onClick={onClick} active={active} deleted={deleted} type={type}>
  {isEditing && <CheckRadioButton itemId={flashcardId} listOfCheckedItems={listOfCheckedItems}/>} 
  <VideoPreview>
    <VideoInnerLayout>
      <VideoLayout>
          {[14982, 15075].includes(activeSubjectId) && 
            <React.Fragment>
              <LeftOverlaySection></LeftOverlaySection>
              <RightOverlaySection></RightOverlaySection>
            </React.Fragment>
          }
          <VideoSection src={thumbImage}></VideoSection>
          <ProgressBar progressPecentage={progressPecentage} />
      </VideoLayout>
      <VideoOverlay>{!props.videoPlayingStatus.get("playing") && <PlayOnThumbnailIcon />}</VideoOverlay>
      <VideoHeadingSection>
      {type === 'notes' && <RightSection type={type} isVideo={true}>{rightSection}</RightSection>  }
        <VideoNumber size={videoNumber && videoNumber.toString().length}>{videoNumber}</VideoNumber>  
        <VideoTitleSection isEditing={isEditing}>
        <StyledVideoTitle>
          <VideoText >{title} </VideoText>
              {type !== 'notes' &&  <RightSection type={type} isVideo={true}>{rightSection}</RightSection>}
          </StyledVideoTitle>
             <VideoStatusSection>
          <Text size={13} lineHeight={'18px'} color={'#2D3838'} >{videoProgress.get('currentTime') ? secondToTime(videoProgress.get('currentTime')) : '0:00'}</Text>
          <Padding left={2} right={2}> / </Padding>
          <Text size={13} lineHeight={'18px'} color={'#919191'} >{totalDuration}</Text>
          
        </VideoStatusSection>
        </VideoTitleSection> 
        
      </VideoHeadingSection>
    </VideoInnerLayout>
    {type === 'notes' &&  <VideoNotesSection>
      <StyledNoteIcon><NoteListIcon /></StyledNoteIcon>
      <StyledMarkText><MarkText shouldMark={shouldMark} className="note-short-desc" >{preview}</MarkText></StyledMarkText>
    </VideoNotesSection> }
  
  </VideoPreview>
  {/* {type === 'notes' && <RightSection type={type} isVideo={true}>{rightSection}</RightSection>  } */}
</StyledVideoPreviewListItem>;
};
const mapStateToPropsOfVideo = (state, ownProps) => ({
  videoProgress: videoProgressById(state, ownProps.id),
  videoPlayingStatus: videoPlayingStatusById(state, ownProps.id),
  activeSubjectId: activeSubjectId(state),
});

export const VideoPreviewListItem = connect(mapStateToPropsOfVideo, null)(VideoPreviewListItemComponent);

export const RevisionPreviewListItem = (props) => {
  const {revisionNumber, flashcardId, rightSection, onClick, active, title, isEditing, listOfCheckedItems, deleted, shortDescription, shouldMark, type} = props;
  let preview = shortDescription;
  if (!preview || preview === ' ' || preview === '') {
    preview = '';
  } else {
    preview = preview;
  }

  function Capitalize(str){
    if(str){
      let value = str.trim();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }

  const parser = new DOMParser();
  preview = parser.parseFromString(`<!doctype html><body>${preview}`, 'text/html').body.textContent;
    return <StyledRevisionPreviewListItem onClick={onClick} active={active} deleted={deleted} type={type}>
      {isEditing && <CheckRadioButton itemId={flashcardId} listOfCheckedItems={listOfCheckedItems}/>} 
      <RevisonNumber>
            <Text size={15} lineHeight={'19px'} color={'#2D3838'} weight={'bold'}>{revisionNumber}</Text>
      </RevisonNumber>
      <RevisionPreview>
        <Text size={15} lineHeight={'19px'} color={'#2D3838'} className="list-title">{title}</Text>
        {type === 'notes' ?  
        <React.Fragment>
          <Padding top={8}></Padding>
          <StyledNotesSection>
            <StyledNoteIcon><NoteListIcon /></StyledNoteIcon>
            <MarkText className="note-short-desc" shouldMark={false}>{preview}</MarkText>
          </StyledNotesSection> </React.Fragment>: null
          }
      </RevisionPreview>
      <RightSection type={type} >{rightSection}</RightSection>
    </StyledRevisionPreviewListItem>;
};
export const ExamsPreviewListItem = (props) =>{

  const { formSearch, flashcardId, rightSection, onClick, active, isEditing, listOfCheckedItems, deleted, isExamsMode, difficulty, shouldMark, type, teachingTag, title, paper, teachingLevel, marks, sources, calc, experienceData, preview, occurences = 0} = props;
  const textRef = useRef(null)
  const textRef1 = useRef(null)
  const [textEllipse, setTextEllipse] = useState(null); 
  const [textEllipse1, setTextEllipse1] = useState(null); 
  const parser = new DOMParser();
  useEffect(() => {
      if(textRef.current){
          if(isEditing ? 158 -  textRef.current.scrollWidth < 0 : occurences > 0  ? 158 -  textRef.current.scrollWidth < 0 : 192 -  textRef.current.scrollWidth < 0){
            setTextEllipse(true);
          
          }else{
            setTextEllipse('');
          }
      } 
    
  },[isEditing, occurences])

  useEffect(() => {
    if(textRef1.current){
        if(isEditing ? 266 -  textRef.current.scrollWidth < 0 : occurences > 0  ? 252 -  textRef1.current.scrollWidth < 0 : false){
          setTextEllipse1(true);
        
        }else{
          setTextEllipse1('');
        }
    } 
  
},[isEditing, occurences, active, textRef1])

  function paperType(val) {
    switch (val) {
      case 'Paper 1':
        return 'P1'
      case 'Paper 2':
        return 'P2'
      case 'Paper 3':
        return 'P3'
      default:
        return 'P1' ;
      
    }
  }
  return<StyledExamsPreviewListItem onClick={onClick} active={active} deleted={deleted} type={type}>
  {isEditing && <CheckRadioButton itemId={flashcardId} listOfCheckedItems={listOfCheckedItems}/>}
  <ExamsContainer>
  {/* <ExamsTitle >{title}</ExamsTitle> */}
  <Text size={15} lineHeight={'19px'} color={'#2D3838'} className="exams-title" paddingBottom={'8px'} weight={formSearch ?'normal':'normal'}>{title}</Text>
  <Row alignItems={'center'} ref={textRef1} className={`${textEllipse1 && 'exam-search-textellipse'}`}>
      <SourceIcon>
        {(() => {
          switch (sources) {
            case 'IB':
              return (<IBIcon />) ;
            case 'OSC':
              return (<OSCIcon />) ;
            case 'ib_dp':
              return (<IBIcon />) ;
            case 'osc_dp':
              return (<OSCIcon />) ;
            case 'osc_dp_mocks':
              return (<OSCIcon />) ;
            default:
              return ;
          }
        })()}
      </SourceIcon>

      <PaperType>{paperType(paper)}</PaperType> 
    
      {teachingTag &&
      <TeachingLevelSeperator tlvl={teachingTag} tlvlTag={teachingTag} slTag={teachingTag=== 'SL' ? true : false} isExams={true}/> }
      {/* // <StyledTechingLevelHeader tvl={teachingLevel} >{teachingLevel}</StyledTechingLevelHeader>} */}
      <ExamsOtherInfo className={`${(textEllipse) ? isEditing ? "exam-editMode-other-info-textellipse"  : "exam-other-info-textellipse" : 'exam-editMode-other-info-textellipse'}`} ref={textRef}>{marks} {marks <=1 ? 'mark' : 'marks'} • {difficulty} • {experienceData && experienceData.get('calc_enabled') ? 'Calc' : 'Non-calc'}</ExamsOtherInfo>

    </Row>
    {type === 'notes' && 
    <ExamsSection>
      <StyledNoteIcon><NoteListIcon /></StyledNoteIcon>
      <StyledMarkText><ExamsMarkText shouldMark={true} className="note-short-desc" >{preview}</ExamsMarkText></StyledMarkText>
    </ExamsSection>
    }
  
  </ExamsContainer>
  <RightSection type={type} >{rightSection}</RightSection>
  </StyledExamsPreviewListItem>

}

const Preview = styled.div`
  font-size: 15px;
  font-family: "Open Sans";
  line-height: 19px;
  color: #727373;
  word-break: break-word;
  max-width: ${props => props.types === 'notes' ? '225px' : '262px'};
 
`;
export const ExamsMarkText =styled.div`
  font-size: 15px;
  font-family: "Open Sans";
  line-height: 19px;
  color: #727373;;
  word-break: break-word;
  max-width: 262px;
`;

const StyledVideoTitle = styled.div`
  display: flex;
`;
export const StyledNoteIcon = styled.div`
  display: flex;
  margin-right: 8px;
  align-items: center;
`;
const RightSection = styled.div`
  margin-left: auto;
  padding-left: 13px;
  display: flex;
  ${props => props.type === 'notes' && css`
    align-items: center;
    padding-left: 0px;
  `};
  ${props => props.isVideo  && css`
    position: absolute;
    right: 0;
    padding-right: 20px;
  `};
  ${props => props.isVideo && props.type === 'notes' && css`
    margin-top: 5px;
  `};
`;
export const StyledPreviewListItem = styled(Row)`
  height:${props => props.activeMath ? '44px' : '59px'};
  padding:${props => props.activeMath ? '9px 20px' : '0px 20px'};
  position: relative;
  cursor: pointer;
  align-items: center;
  background: ${props => props.active ? '#E5ECF2' : 'white'};
  
  ${props => props.deleted && css`    
    animation: slideItem .3s linear forwards, downsize .2s linear forwards;
    animation-delay: 0s, .3s;`
  }
  
  :not(:last-child):after{
    content: '';
    position: absolute;
    bottom:0;
    left:0; 
    right:0;
    height: 0;
    margin: 0 20px;
    background: #F0F5F5;
  }
  @media (pointer: fine) {
    &:hover {
      background: ${props => props.active ? '#E5ECF2' : '#EFF4F9'};
    }
  }
  
`;
/////////////////////////////////////////////////////
const VideoNotesSection = styled.div`
    display: flex;
    margin-top: 16px;
`;
export const ExamsSection = styled.div`
  display: flex;
  margin-top: 9px;
`;
export const StyledMarkText = styled.div`
  // width: 270px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  webkit-box-orient: vertical;
  overflow: hidden;
  align-items: center;
`;
const VideoStatusSection = styled.div`
  display: flex;
  margin-top: 4px;
`;
const VideoHeadingSection = styled.div`
  display: flex;
`;
const VideoTitleSection = styled.div`
    display: flex;
    flex-direction: column;
    ${props => props.isEditing !== true && css`
      width: 170px;
    `};
`;
const VideoText=styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    width: 140px;  
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #2D3838;`
    ;
const VideoNumber = styled.div`
  display: flex;
  justify-content: center;
  ${props => props.size == 1 && css`
    width: 28px;
  `};
  ${props => props.size == 2 && css`
    width: 35px;
  `};${props => props.size == 3 && css`
    width: 42px;
  `}; 
  padding-right: 8px;
  padding-left: 8px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #2D3838;
`;
const VideoSection = styled.img`
  background: rgba(55, 142, 248, 0.15);
  border-radius: 10px;
  width: 100%;
   height: 100%;
`;

const PlayIcon = styled.div`
`;

const ProgressBar = styled.div`
  background: linear-gradient(135deg, #50D466 0%, #50D4A8 100%);
  height: 8px;
  margin-top:-12.5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius:${props => props.progressPecentage >= 96 || props.progressPecentage <=! 6 ? (props.progressPecentage>98 ? '10px' : "8px" ): '0px'}; 
  position:relative;
  width:${props => `${props.progressPecentage}%`};
`;
const VideoInnerLayout = styled.div`
  display: flex;
  align-items: center;
`;
const VideoLayout = styled.div`
    height: 76px;
    border-radius: 10px;
    width: 133px;
    min-width: 133px;
    position: relative;
    //border: 1px solid rgba(55,142,248,0.15);
`;

const VideoOverlay = styled.div`
  height: 76px;
  width: 133px;
  min-width: 133px;
  position: absolute;
  background: rgba(55, 142, 248, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoPreview = styled.div`
  font-size: 15px;
  font-family: "Open Sans";
  line-height: 19px;
  color: #727373;
  word-break: break-word;
  //width: 278px;
`;
export const StyledVideoPreviewListItem = styled(Row)`
  height: ${props => props.type === "notes" ? "150px" : "92px"};
  padding: 8px 20px;
  position: relative;
  cursor: pointer;
  
  background: ${props => props.active ? '#E5ECF2' : 'white'};
  
  ${props => props.deleted && css`    
    animation: slideItem .3s linear forwards, downsize .2s linear forwards;
    animation-delay: 0s, .3s;`
  }
  
  :not(:last-child):after{
    content: '';
    position: absolute;
    bottom:0;
    left:0; 
    right:0;
    height: 0;
    margin: 0 20px;
    background: #F0F5F5;
  }
  @media (pointer: fine) {
    &:hover {
      background: ${props => props.active ? '#E5ECF2' : '#EFF4F9'};
    }
  }
  
`;
const RevisonNumber = styled.div`
  text-align: center;
  //margin-left: 6px;
`;
const StyledNotesSection = styled.div`
  display: flex;
  align-items: center;
  //justify-content: center;
 
`;
const RevisionPreview = styled.div`
  font-size: 15px;
  font-family: "Open Sans";
  line-height: 19px;
  color: #727373;
  word-break: break-word;
  padding-left: 12px;
  width: 278px;
  position: relative;
`;
export const StyledRevisionPreviewListItem = styled(Row)`
  height: ${props => props.type ==="notes" ? "85px" : "44px" };
  padding: 9px 20px;
  position: relative;
  cursor: pointer;
  background: ${props => props.active ? '#E5ECF2' : 'white'};
  ${props => props.deleted && css`    
    animation: slideItem .3s linear forwards, downsize .2s linear forwards;
    animation-delay: 0s, .3s;`
  }
  ${props => props.type !== "notes"&& css`    
    display: flex;
    align-items: center;`
  }
  
  :not(:last-child):after{
    content: '';
    position: absolute;
    bottom:0;
    left:0; 
    right:0;
    height: 0;
    margin: 0 20px;
    background: #F0F5F5;
  }
  @media (pointer: fine) {
    &:hover {
      background: ${props => props.active ? '#E5ECF2' : '#EFF4F9'};
    }
  }
  
`;
export const StyledExamsPreviewListItem = styled(Row)`
  // height: 67px;
  padding: 10px 20px 14px 20px;
  position: relative;
  cursor: pointer;
  background: ${props => props.active ? '#E5ECF2' : 'white'};
  ${props => props.deleted && css`    
    animation: slideItem .3s linear forwards, downsize .2s linear forwards;
    animation-delay: 0s, .3s;`
  }
  ${props => props.type !== "notes"&& css`    
    display: flex;
    align-items: center;`
    
  }
  :not(:last-child):after{
    content: '';
    position: absolute;
    bottom:0;
    left:0; 
    right:0;
    height: 0;
    margin: 0 20px;
    background: #F0F5F5;
  }
  ${props => props.active && css`    
    .exam-other-info-textellipse{   
      &:before{ 
          background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #E5ECF2 100%);
      } 
    }
    .exam-search-textellipse {
      &:before{ 
        background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #E5ECF2 100%);
      } 
    }
    .exam-editMode-other-info-textellipse{
      &:before{ 
        background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #E5ECF2 100%);
      } 
    }
  `}
  
  @media (pointer: fine) {
    &:hover {
      background: ${props => props.active ? '#E5ECF2' : '#EFF4F9'};
      .exam-other-info-textellipse{   
        &:before{ 
            background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #EFF4F9 100%);
        } 
      }
      .exam-search-textellipse {
        &:before{ 
          background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #EFF4F9 100%);
        } 
      }
      .exam-editMode-other-info-textellipse{
        &:before{ 
          background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #EFF4F9 100%);
        } 
      }
      ${props => props.active && css`    
        .exam-other-info-textellipse{   
          &:before{ 
              background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #E5ECF2 100%);
          } 
        }
        .exam-search-textellipse {
          &:before{ 
            background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #E5ECF2 100%);
          } 
        }
        .exam-editMode-other-info-textellipse{
          &:before{ 
            background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #E5ECF2 100%);
          } 
        }
      `}
    }
  }
`
 export const ExamsContainer = styled.div`
  position: relative;
  word-break: break-word;
  // padding-left: 12px;
`;
export const ExamsTitle = styled.div`
  font-size: 15px;
  color: #2D3838;
  line-height: 19px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom:8px
`;
export const SourceIcon =styled.div`
margin-right:8.06px;`;
export const PaperType = styled.div`
  font-size: 13px;
  color: #979999;
  line-height: 16px;
  margin-right:8px;
  font-weight: bold;
`;
export const ExamsOtherInfo = styled.div`
  font-size: 13px;
  color: #979999;
  line-height: 16px;
  margin-left:8px;
  .text-highlighting-mark{
    color: #979999;
    font-weight: normal;
  }
  
`;
export const PreviewListHeader = (props) => {
  const {topicNumber, teachingLevel, index, fromPage, onClick, subTopicTitle, teachingTag} = props;
  return <StyledPreviewListHeader index={index} onClick={fromPage !== "subtopic" && onClick} fromPage={fromPage}>
    {teachingTag !== null ? 
    <TeachingLevelSeperator tlvl={teachingLevel} tlvlTag={teachingTag} isExams={false} slTag={teachingTag=== 'SL' ? true : false}/> :
    <StyledTechingLevelHeader tvl={teachingLevel}>{teachingLevel}</StyledTechingLevelHeader>}
    {topicNumber && <TopicNumber>
      {topicNumber}
    </TopicNumber> }
    <SubTopicTile tvl={teachingLevel} tlvlTag={teachingTag} dangerouslySetInnerHTML={{__html: subTopicTitle}} />
    {fromPage !== "subtopic" && <RightSectionIcon><ForwardChevronIcon color={'#BEC4C3'} /></RightSectionIcon>}
  </StyledPreviewListHeader>;
};
export const ExamPreviewListHeader =(props) =>{
  const {topicNumber, teachingLevel, index, fromPage, onClick, subTopicTitle, teachingTag} = props;
  return <StyledPreviewListHeader index={index} onClick={onClick} fromPage={fromPage}>
    {teachingTag !== null ? 
    <TeachingLevelSeperator tlvl={teachingLevel} tlvlTag={teachingTag} isExams={false} slTag={teachingTag=== 'SL' ? true : false}/> :
    <StyledTechingLevelHeader tvl={teachingLevel}>{teachingLevel}</StyledTechingLevelHeader>}
    <ExamsIconCard><SmallExamsIcons/></ExamsIconCard>
    <SubTopicTile tvl={teachingLevel} tlvlTag={teachingTag} dangerouslySetInnerHTML={{__html: subTopicTitle}} />
    {fromPage !== "subtopic" && <RightSectionIcon><ForwardChevronIcon color={'#BEC4C3'} /></RightSectionIcon>}
    </StyledPreviewListHeader>

}

const computeColor = tvl => {
  if (tvl.indexOf('SL') > -1) return '#458CFC';
  if (tvl.indexOf('HL') > -1) return '#1FCD6C';
  return '#458CFC;';
};

const computeWith = (tvl, tlvlTag) => {
  if (tlvlTag === "SL&HL" && tvl.includes('Option')) return '100px';
  if (tvl.includes('Option') || tvl.includes('options')) return '144px';
  if (tvl.includes('Pre-IB')) return '197px';
  if (tvl.includes('Prescribed')) return '81px';
  return '100%';
};
const computePadding = type => {
  if (type.indexOf('SL') > -1) return '2px 8px';
  if (type.indexOf('HL') > -1) return '2px 6px 2px 7px';
  return '2px 8px;';
};
const StyledTechingLevelHeader=styled.div`
  background: ${props => computeColor(props.tvl)};
  padding: ${props => computePadding(props.tvl)};
  border-radius: 5px;
  text-transform: initial;
  font-size: 13px;
  line-height: 18px;
  color: #FFFFFF;  
  min-width: 30px;
  font-weight: normal;
`;

const SubTopicTile = styled.div`
    margin-left: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #2D3838;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: ${props => computeWith(props.tvl, props.tlvlTag)};
    ${'' /* width: 197px;
    ${props => props.isWidth && `max-width: 144px;`} */}
`;

const RightSectionIcon = styled.div`
    margin-left: auto;
    padding-left: 13px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

`;
export const StyledPreviewListHeader = styled(Row)`
  height: 32px;
  padding: 5px 20px;
  align-items: center;
  ${props => props.index > 0 && `margin-top: 14px;`}
  ${props => props.fromPage !== "subtopic" && `cursor: pointer;`}
 
  @media (pointer: fine) {
    &:hover {
      // background: #EFF4F9;
      ${props => props.fromPage !== "subtopic" && `background: #EFF4F9;`};
    }
  }
  
`;
export const TopicNumber = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.34);
    border-radius: 11px;
    padding: 2px 9px;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #378EF8;
    margin-left: 12px;
`;

const FlashcardNumber = styled.div`
  // width: 33px;
  text-align: center;
  margin-right: 14px;
`;

export const ListTypeHeader = ({icon, title, showMore, total, onClick}) => {
  const shouldShowMore = showMore && total - 3 > 0;

  return <StyledListTypeHeader left={20} right={20}>
    <TitleWrapper >
      <Row alignItems={'center'}>
        <IconWrapper>{icon}</IconWrapper>
        <Padding left={8}>
          <Text size={19} color={'#2D3838'} weight={'bold'}>{title}</Text>
        </Padding>
      </Row>
      {shouldShowMore &&
      <MoreItems onClick={onClick}>{total - 3} more <Padding left={5}><ForwardChevronIcon color={'#378EF8'} class={"more-icon"}/></Padding></MoreItems>}
    </TitleWrapper>
  </StyledListTypeHeader>;
};

export const StyledListTypeHeader = styled(Padding)`

`;

const MoreItems = styled(Row)`
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all .2s;
  color: #378EF8;
    @media (pointer: fine) {
      &:hover {
        color: #64A8FA;
        .more-icon{
            path#Back-Chevron {
              fill: #64A8FA;
            }
        }
      }
    } 
    &:active {
      color: #2170CF;
      .more-icon{
        path#Back-Chevron {
          fill: #2170CF;
        }
      }
    }
`;

const TitleWrapper = styled(Row)`
    height: 40px;
    align-items: center;
    justify-content: space-between;
`;

const IconWrapper = styled(Row)`
  width: 20px;
  height: 20px;
  align-items: center;
`;

export const PreviewSubTopicListItem = (props) => {
  const { subTopicNumber, preview, onClick, active} = props;
  let previews = preview;
  const parsers = new DOMParser();
  previews = parsers.parseFromString(`<!doctype html><body>${previews}`, 'text/html').body.textContent;
  return <StyledSubTopicPreviewListItem alignItems={'center'} onClick={onClick} active={active} >
    <SubTopicNumber>
      {subTopicNumber}
    </SubTopicNumber>
    <SubTopicPreview>
      {previews}
    </SubTopicPreview>
  </StyledSubTopicPreviewListItem>;
};

const SubTopicNumber = styled.div`
    height: 20px;
    border-radius: 10px;
    background-color: ${props => props.alternate ? 'white' : '#EFF4F9'};
    font-family: "Open Sans";
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 1;
    color: ${props => props.theme.turquoise};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: ${props => props.children && props.children.length === 1 ? '0 6px' : '0 8px'};
    min-width: ${props => props.children && props.children.length === 1 ? '20px' : 'initial'};
`;

const SubTopicPreview = styled.div`
  font-size: 15px;
  font-family: "Open Sans";
  line-height: 19px;
  color: ${props => props.theme.charcoal};
  word-break: break-word;
  max-width: 260px;
  //opacity: 0.9;
  font-weight: bold;
  mix-blend-mode: normal;
  padding: 10px 19px 11px 12px;
`;
export const StyledSubTopicPreviewListItem = styled(Row)`
  
  padding: 0 20px;
  position: relative;
  cursor: pointer;
  
  background: ${props => props.active ? '#E5ECF2' : 'white'};
  
  ${props => props.deleted && css`    
    animation: slideItem .3s linear forwards, downsize .2s linear forwards;
    animation-delay: 0s, .3s;`
  }
  
  :not(:last-child):after{
    content: '';
    position: absolute;
    bottom:0;
    left:0; 
    right:0;
    height: 0;
    margin: 0 20px;
    background: #F0F5F5;
  }
  @media (pointer: fine) {
    &:hover {  
      background: ${props => props.active ? '#E5ECF2' : '#EFF4F9'};
      ${SubTopicNumber}{
        background-color: white;
      }
    } 
  }
`;

export const LeftOverlaySection = styled.div`
    position: absolute;
    height: 76px;
    width: 7px;
    background: #FFFFFF;
    border-radius: 10px 0 0 10px;
`;
export const RightOverlaySection = styled.div`
    position: absolute;
    height: 76px;
    width: 8px;
    background: #FFFFFF;
    border-radius: 0 10px 10px 0;
    right: 0px;
`;

export const ExamsIconCard = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.34);
  border-radius: 11px;
  margin:0 2px 0 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  width:41px;
  height:22px;
`;