import { toggleBodyScroll } from 'actions/navigation';
import { clearSearch, setsearchActive } from 'actions/search';
import SearchInput from 'v2/pages/search/SearchInput';
import React from 'react';
import { search } from 'actions/api';
import { activeSearchTerm } from 'selectors';
import { useDispatch, useSelector } from 'react-redux';

type SearchInputContainerProps = {
  showResults: any
}
const SearchInputContainer: React.FC<SearchInputContainerProps> = ({ showResults}) => {
  const dispatch = useDispatch();
  const hasActiveSearchTerm = useSelector(activeSearchTerm);
  const onFocus = () => {
    dispatch(toggleBodyScroll(false));
    dispatch(setsearchActive(true));
  };
  const onBlur = () => {
    dispatch(toggleBodyScroll(true));
    dispatch(setsearchActive(false));
  };

  const onCancel = () => {
    dispatch(clearSearch());
  };
  const onSubmit = (val: any) => {
    dispatch(search(val))
  }
  return <SearchInput
    onSubmit={onSubmit}
    onCancel={onCancel}
    onBlur={onBlur}
    onFocusAction={onFocus}
    showResults={showResults}
    hasCancel={!!hasActiveSearchTerm}
  />;
}


export default SearchInputContainer;