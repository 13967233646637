import {setActiveSearchTab, setActiveSearchTerm, setGoBackToSearch, setSearchIndex} from 'actions/search';
import {CLEAR_SEARCH} from 'actions/search/searchTypes';
import {change} from 'redux-form';
import {all, put, select, takeLatest} from 'redux-saga/effects';
import { isMBFromSchool } from 'selectors/user';
import { getActiveMediaTab } from 'selectors/navigation/navigationSelector';
import { activeSearchTab } from 'selectors/searchSelectors';

export function* watchers() {
  yield all([
    takeLatest(CLEAR_SEARCH, clearSearch)
  ]);
}

function* clearSearch() {
  const mediaTab        = yield select(getActiveMediaTab);
  const searchTab       = yield select(activeSearchTab);
  const hasMBFromSchool = yield select(isMBFromSchool);
  const tab = !hasMBFromSchool && mediaTab === 'exams' && ['Cards', 'Exercises'].includes(searchTab) ? 'Notes' : searchTab;
  try {
    yield put(setActiveSearchTerm(''));
    yield put(setActiveSearchTab(tab));
    yield put(setSearchIndex());
    yield put(change('search', 'searchField', ''));
    yield put(setGoBackToSearch(false));
  } catch (error) {
    console.error("error for clearSearch", error)
  }
}
