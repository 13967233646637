import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import AnswerBullet , { MarkRightValue, MCQAnswerBullet } from 'ui-components/previews/AnswerBullet';
import PropTypes from 'prop-types';
import {markById, watchedById, answerById, examAnswerById} from 'selectors/answersSelectors';

class AnswerBulletContainer extends PureComponent {
  
  render() {
    const {answer, mark, type, watched, examAnswers, isMcq} = this.props;
    if (!answer && !mark && !watched && !examAnswers) return null;
      if(answer && type ==="flashcard" ){
        const answerBulletData = {
          answered: answer.get('answered'),
          correct: answer.get('correct')
        };
        return <AnswerBullet {...answerBulletData}/>;
      }
      if(mark && type ==="revision"){
        const MarkReadBulletData = {
          markValue: mark.get('markValue'),
        };
        return <MarkRightValue {...MarkReadBulletData}/>;
      }
      if(watched && type ==="video"){
        const MarkReadBulletData = {
          markValue: watched.get('watchedValue'),
        };
        return <MarkRightValue {...MarkReadBulletData}/>;
      }
      if(examAnswers || type ==="exams"){
       
        const answerBulletData = {
          answered: examAnswers.get('answered'),
          correct: examAnswers.get('correct')
        };
        if(isMcq){
          return <MCQAnswerBullet {...answerBulletData} />
        }else{
          return <AnswerBullet {...answerBulletData}/>;
        }
        
      }
  }
}

const mapStateToProps = (state, ownProps) => ({
  answer: answerById(state, ownProps.id),
  mark: markById(state, ownProps.id),
  watched: watchedById(state, ownProps.id),
  examAnswers: examAnswerById(state, ownProps.id),
});

AnswerBulletContainer.propTypes = {
  id: PropTypes.number,
};

export default connect(
  mapStateToProps,
)(AnswerBulletContainer);
