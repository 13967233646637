import React, { useCallback } from 'react';
import classNames from 'classnames';
import './subjects-changer.scss';
import { SubjectIcon } from 'icons/SubjectIcons';
import SubjectNotification from 'v2/components/notifications/SubjectNotification';
import useExerciseNotificationCountBySubject from 'hooks/useExerciseNotificationCountBySubject';
import { optimizedSubjectTitle } from 'v2/helpers';

type subjectProps = {
  data: any;
  isActive: boolean;
  onClick: (arg:any) => void;
  subjectId: number;
}

const Subject: React.FC<subjectProps> = ({ data, isActive, onClick, subjectId}) => {
  // const hasSubjectExerciseNotificationsCount = useSelector((state) => subjectExerciseNotificationsCount(state, subjectId));
  const { totalSubjectExerciseNotificationsCount } = useExerciseNotificationCountBySubject(subjectId);

  const subjectTitle = useCallback((val:string) => optimizedSubjectTitle(val),[])
  return (
    <div className='subject flex flex-col items-center justify-start mb-2.5 basis-1/8 cursor-pointer' onClick={() => onClick(data)}>
      <div className={classNames('icon-wrapper relative h-32 items-center justify-center mb-2 mt-6 py-2.5 rounded-20px w-100px [&_img]:!block', { 'isActive': isActive })}>
        <SubjectIcon subject={data.slug} />
        {totalSubjectExerciseNotificationsCount > 0 && <SubjectNotification amount={totalSubjectExerciseNotificationsCount} />}
        <div className={classNames('title-text mt-4 text-center leading-18px text-13px font-bold',{'text-blue-normal':isActive,'text-#A2A3A3':!isActive})} dangerouslySetInnerHTML={{ __html: subjectTitle(data.title) }}></div>
      </div>

      {data.year ? <div className='text-center pt-1'>
          {data?.isNew && <div className='new-tag rounded-md text-xs py-1 flex items-center justify-center px-7px text-#FF8000'>{data?.isNew ? 'New ' : ''}</div>}
          <React.Fragment>
            {data.year && <div className='year rounded tracking-normal inline-block text-center py-0.5 text-11px text-#A2A3A3 w-42px leading-16px'>{data.year}</div>}
          </React.Fragment>
          {data.buttonLink && <a className='btn-link block text-center p-1 text-13px leading-normal' href={data.buttonLink} target='_blank' onClick={e => e.stopPropagation()} rel="noreferrer">Content progress</a>}
        </div>
       : <div className='text-center'>
          {data?.isNew && <div className='new-tag rounded-md text-xs flex items-center justify-center px-7px text-#FF8000 font-semibold'>{data?.isNew ? 'New ' : ''}</div>}
        </div>
      }
    </div>
  )
}


export default Subject;
