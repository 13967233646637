import React from 'react';
import classNames from 'classnames';
import CheckRadioButton from 'v2/components/check-radio-button/CheckRadioButton';
import { DefaultFolderIcon, DragDropFolderIcon } from 'icons/FolderIcons';
import MarkText from 'v2/ui-components/MarkText';
import AddFlashcardToBookmarkContainer from 'v2/pages/desk/folder/AddFlashcardToBookmarkContainer';
import AddFlashcardToFolderContainer from 'v2/pages/desk/folder/AddFlashcardToFolderContainer';
import { ForwardChevronIcon } from 'icons/HeaderIcons';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

type FolderListItemProps = {
    index?: number;
    folder?: any;
    foldercount?: number;
    isfolders?: boolean;
    flashcardFolders: any;
    onClick: () => void;
    isEditing?: boolean;
    listOfCheckedItems?: [number];
    shouldMark?: boolean;
    name?: string;
    hasFlashcard?: boolean;
}

const FolderListItem: React.FC<FolderListItemProps> = ({ isfolders, foldercount, onClick, folder, isEditing, listOfCheckedItems, shouldMark, name, hasFlashcard, flashcardFolders, index }) => {
    return <>
        {(!isfolders || !foldercount || foldercount <= 1) && (
            <ListItem onClick={() => onClick()} folder={folder} isEditing={isEditing} listOfCheckedItems={listOfCheckedItems} shouldMark={shouldMark} name={name} hasFlashcard={hasFlashcard} flashcardFolders={flashcardFolders} />
        )}
        {(isfolders && foldercount && foldercount > 1) && (
            <Draggable key={folder.id.toString()} draggableId={folder.id.toString()} index={index!}>
                {(provided, snapshot) => (
                    <ListItem onClick={() => onClick()} folder={folder} isEditing={isEditing} listOfCheckedItems={listOfCheckedItems} shouldMark={shouldMark} hasFlashcard={hasFlashcard} flashcardFolders={flashcardFolders} provided={provided} foldercount={foldercount} isDragging={snapshot.isDragging}/>
                )}
            </Draggable>)}
    </>
}
export default FolderListItem

type ListItemProps = {
    onClick: () => void;
    folder?: any;
    isEditing?: boolean;
    listOfCheckedItems?: [number];
    shouldMark?: boolean;
    name?: string;
    hasFlashcard?: boolean;
    flashcardFolders: any;
    provided?: DraggableProvided;
    foldercount?: number;
    isDragging?:boolean;
}
const ListItem: React.FC<ListItemProps> = ({ onClick, folder, isEditing, listOfCheckedItems, shouldMark, name, hasFlashcard, flashcardFolders, provided, foldercount, isDragging }) =>
    <div ref={provided?.innerRef} {...provided?.draggableProps} onClick={() => onClick()} className={classNames('cursor-pointer px-5 py-2 relative items-center bg-white flex folder-list-item select-none z-0', { 'deleted': folder && folder.deleted, 'shadow-[0_6px_17px_rgba(0,0,0,0.2)]':isDragging })}>
        {name !== 'Bookmarked' && isEditing && <CheckRadioButton itemId={folder.id} listOfCheckedItems={listOfCheckedItems} />}
        {name !== 'Bookmarked' && foldercount && foldercount > 1 && <div className='mr-3 block' {...provided?.dragHandleProps}><DragDropFolderIcon /></div>}
        <DefaultFolderIcon />
        <div className='relative ml-[14px] text-#2D3838 text-17px font-bold flex-1 whitespace-nowrap overflow-hidden text-ellipsis leading-23px name'>
            <MarkText shouldMark={shouldMark}>{name || folder.title}</MarkText>
        </div>
        <div className='ml-auto flex items-center justify-center'>
            <div className={classNames('rounded-full mr-2 add-button',{'p-1.5 active:bg-#E5ECF2':name==='Bookmarked'})}>{name === 'Bookmarked' ? hasFlashcard && <AddFlashcardToBookmarkContainer flashcardFolders={flashcardFolders} /> : (flashcardFolders && !isEditing) && <AddFlashcardToFolderContainer flashcardFolders={flashcardFolders} folderId={folder && folder.id} />}</div>
            {(name === 'Bookmarked' || !isEditing) && <ForwardChevronIcon /> }
        </div>
    </div>
