import styled, { css } from 'styled-components/macro';
import media from 'ui-components/helpers/media';
import Text from 'ui-components/helpers/Text';
import Row from 'ui-components/helpers/Row'
import TeachingLevelSeperator,{StyledTechingLevelHeader} from 'components/teaching-level-selector/TeachingLevelSeperator';
// import { StyledTechingLevelHeader } from 'components/teaching-level-selector/TeachingLevelSeperator';
export const FilterWrapper = styled.div`
  // width: 100%;
  width: 347px;
  z-index: 2;
  overflow: hidden;
  // max-height: 332px; 
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
  border-radius: 10px;
  user-select: none;
  cursor: default;
  // min-height: 442px;
  // border-top-right-radius: 0;
  // border-top-left-radius: 0;
  overflow: scroll;
  max-height: 100%;
  ${props => props.isScrollable && css `
    // margin-bottom: -35px;
    position: relative;
  `}

  // ${media.phone(css`
  // //    max-width: calc(100% - 28px);
  // margin-bottom: -35px;
  // `)}
`;

export const FilterClear = styled.div`  
  height: 36px; 
  width: 36px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  z-index: 9;
  right: 0;
  top: 0;
  cursor: pointer;
  margin-left:4px;
  position: absolute;
  ${props => props.isEditMode && css`
    opacity: 0.5 !important;
    pointer-events: none !important;
    user-select: none !important;
  `};
  svg{
      margin: 8px !important;
      cursor: unset;
        circle{
          stroke: unset !important;
        }
      path{
        fill:#FFFFFF !important;
      }
    }
  @media (pointer: fine) {
    &:hover {
      background: #B2D4FC;
   }
  }
  &:active {
    box-shadow: none;
    background: #378EF8;
    circle{
      fill: #FFFFFF;
    }
    path { 
      fill: #378EF8 !important;
    } 
  }
`;

export const MainExamsFilter = styled.div`
    position: relative;
    margin-bottom:${props => !props.formCC  && '16px' } 
`;

export const FilterText = styled.div`
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #378EF8;
    // padding-left:${props => props.data === 'Not yet assessed' ? '8px;' : '0;'}
    padding-right: 4px;
`;
export const FilterTextRow = styled.div``;
export const ExamsFilter = styled.div`
  margin-left:${props => props.marginLeft ? props.marginLeft : 0}px;
  margin-top: ${props => props.marginTop ? props.marginTop : 0}px;
  display: flex;
  box-shadow: ${props => props.filter ? '0 0 0 2px #378EF8' : '0 0 0 1px #E3E6E6'};
  background: ${props => props.filter ? '#E6EEF7' : '#FFFFFF'};
  border-radius: 18px;
  align-items: center;
  cursor: pointer;
  cursor:${props => !props.disabled && 'pointer'};
  user-select: none;
  pointer-events:${props => props.disabled && 'none'};
  opacity: ${props => props.disabled && '0.5 !important'};
  width:fit-content;
  height: 36px;
  // padding-right: ${props => props.filter ? "4px" :"0"};
  ${props => props.isEditMode && css`
    opacity: 0.5 !important;
    pointer-events: none !important;
    user-select: none !important;
  `}
  @media (pointer: fine) {
    &:hover{
      box-shadow: ${props => props.filter ? '0 0 0 2px #64A8FA' : '0 0 0 1px #C8CCCC'};
    }
  }
  >${Text}{
    padding: 9px 0px 9px 16px; 
  }
  >${FilterTextRow}{
    padding: 9px 4px 9px 16px; 
  }
  >${Row}{
    padding: 9px 4px 9px 16px; 
    >${Text}{
      padding-right:6px; 
    }
  }
  svg{
    margin:  ${props => props.filter ? props.isMarging ? '8px 6px 8px 0px' : ' 8px 8px 8px 16px' : '8px 12px 8px 6px'};  
  }
  .micro-arrow{
    margin: 8px 12px 8px 6px !important;
    transform:  ${props => (props.filter && props.isMarging) ? "rotate(180deg)" : "rotate(360deg)"};
    transition-duration: 0.3s;
  }
      
  &:active{
    background: #378EF8;
    box-shadow: none;
    >${Row}{
      padding: 9px 4px 9px 16px; 
    
    >${Text}{
      color: #FFFFFF !important;
    }
    }
    >${Text}{
      color: #FFFFFF !important;
    }
    >${FilterText}{
      color: #FFFFFF;
    }
    
    color: #FFFFFF;
    svg{
      circle{
          fill: #FFFFFF;
      }
      
      path{
          &:first-child{
              fill: #FFFFFF !important;
          }
          fill: ${props => props.filter ? '#378EF8 !important' : '#FFFFFF'};
      }
    }
    .closeButton1{
      height: 36px; 
      width: 36px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      z-index: 999;
      box-shadow: none;
      background: #378EF8;
      svg{
        margin:  0;
      }
      circle{
        fill: #FFFFFF;
      }
      path { 
        fill: #378EF8 !important;
      } 
    }
  }
  .closeButton1{
    height: 36px; 
    width: 36px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 7;
    
    &:active {
      z-index: 999;
      box-shadow: none;
      background: #378EF8;
      circle{
        fill: #FFFFFF;
      }
      path { 
        fill: #378EF8 !important;
      } 
    }
  }
  .closeButton{
    height: 36px; 
    width: 36px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 7;
    @media (pointer: fine) {
      z-index: 999;
      &:hover {
          background: #B2D4FC;
      }
    }
 
  }
  @media (max-width: 350px) {
    >${Text}{
      font-size:10px;
    }
  }
`;
export const FilterTitle = styled.div`
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  color: #2d3838;
  margin-left: ${props => props.fromCC && "23px;"}
`;
export const PopupLeftSection = styled.div`
display:flex;
align-items: center;
svg{
  cursor: pointer;
  width:20px;
}
${Text}{
  cursor: pointer;
  user-select: none;
}

`;


export const FilterHeader = styled.div`
  padding:${props => props.formCC ? "18px 20px 8px 12px" : "18px 20px 8px 20px"};
  display:flex;
  justify-content: space-between;
  align-items: center;
`;
export const FilterHeaderWrapper = styled.div`
  padding:${props => props.fromCC ? "15px 14px 5px 12px" : "15px 14px 5px 20px"};
  display:flex;
  justify-content: space-between;
  align-items: center;
`;
export const FilterCloseIconWrapper = styled.div`
  cursor: pointer;
  padding:6px;
  border-radius: 16px;

  @media (pointer: fine) {
    &:hover {
      background-color: #EFF4F9;
    }
  }
  &:active {
    background: #E5ECF2;
  }
`;
export const FilterCloseIcon = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #378ef8;
  display: flex;
  align-items: center;
  justify-content: center;
  // &:hover {
  //   background: #64A8FA;
  // }
`;
export const FilterToggleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding: 10px 20px;
`;
export const FilterHeaderText = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: #2D3838;
`;
export const FilterOptionContainer = styled.div`
  padding: 0 20px 16px 20px;
`;
export const FilterInnerContainer = styled.div`
  display: flex;
  padding: 10px 0;
  // cursor: pointer;
  cursor:${props => !props.disabled && 'pointer'};
  min-height: ${props => props.isDifficulty ? "40px" : "90px"};
  ${props => props.isDifficulty && css`
    -webkit-box-align: center;
    padding:0;
    align-items: center;`
  }
  user-select: none;
`;
export const TeachingInnerContainer = styled.div`
  display: flex;
  padding: 10px 0;
  cursor: pointer;
`;
export const SoucreInnerContainer =styled.div`
  display: flex;
  padding: 10px 0;
  cursor:${props => props.disabled ?'default':'pointer'};
  user-select: none;
`;
export const FilterCheckIcon = styled.div`
  padding-right: 16px;
`;
export const FilterTextContainer = styled.div`
  display:block;
  height:100%;
  position: relative;
  ${props => props.isRow && css `
      display: flex;
  `}
`;
export const FilterCategory = styled.div`
  font-size: 15px;
  line-height: 19px;
  color:#2D3838;
  display: flex;
  align-items: center;
  font-weight: 400;
  svg{
      margin-right: 6px;
      width: 18px !important;
      height: 18px !important;
  }
`;
export const FilterSubCategory = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #919191; 
  margin-top: ${props=> props.margintop ? `${props.margintop}px` : "3px"};
  display: -webkit-box;
  // -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const MoreContainer = styled.div`
  font-size: 13px;
  position: absolute;
  cursor: pointer;
  line-height: 18px;
  color: #378EF8;
  bottom: 0;
  z-index: 2;
  right: 0;
  ${props => props.position && css`
  position: relative;
  margin-top: 8px;
  display:inline-block;
  `}
`;


export const ApplyButton = styled.div`
    height: 36px;
    width: 146px;
    background:  ${props => props.disabled ? '#EFF4F9' : '#378EF8'};
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({isLoading}) => !isLoading ? 'pointer' : 'default'};
    pointer-events:${({isLoading}) => !isLoading ? 'auto' : 'none'};
    text-transform: uppercase;
    user-select:none;
    ${props =>  props.disabled && css`
    cursor:default;
    pointer-events:none;
    `}
    @media (pointer: fine) {
        &:hover {
            ${props => !props.disabled && css`
                background: #64A8FA;
            `};  
        }
    } 
    &:active {
        ${props => !props.disabled && css`
            background: #2170CF;
        `}; 
        
    }
    ${props => props.isHidden && css`
      visibility: hidden
    `}

`;

export const ButtonsWrapper =styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between'};
  align-items: center;
  padding: 0 20px 20px;
`;
export  const CancelButton = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    text-transform: uppercase;
    cursor: pointer;
    background: #FFFFFF;
    width: 146px;
    height: 36px;
    box-shadow: 0 0 0 1px #E3E6E6;
    box-sizing: border-box;
    border-radius: 22px;
    user-select:none;
    svg{
      margin-right: 12px;
    }
    &:active {
      box-shadow: none !important;
      background: #378EF8;
      >${Text}{
        color: #FFFFFF;
        margin:0
      }
    }

    @media (pointer: fine) {
        &:hover {
            box-shadow: 0 0 0 1px #C8CCCC;
        }
    } 
    
`;
export const MircoFilterContainer = styled.div`
  position: relative;
  width: fit-content;
  ${props => props.examsMode && css`
    opacity: 0.5;
    user-select: none !important;
    opacity: 0.5 !important;
    pointer-events: none !important;
  `}
`;
 
export const PaperDetails = styled.div`
  color: #2D3838;
  font-size: 15px;
  line-height: 19px;
  padding:0 20px;
`;
export const VideoWrapper = styled.div`
  margin:24px 0 16px 0;
  height:198px;
  position: relative;
`;
export const QuestionInfo = styled.div`
  margin:12px 20px 20px 20px;
  display:flex;
  background: #EFF4F9;
  border-radius: 10px;
  padding:14px;
  color: #979999;
  font-size: 13px;
  line-height: 16px;
  align-items: center;
`;
export const QuestionWrapper = styled.div`
  padding:0 20px;
  // max-height: calc(100vh - 180px);
  // overflow: scroll;
`;
export const QuestionDetail = styled.div`
  color: #2D3838;
  font-size: 15px;
  line-height: 19px;
`;
export const QuestionHeader = styled.div`
  position: relative;
`;

export const ActiveBar = styled.div`
  position: absolute;
  left: -20px;
  background: #FA9000;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  top: 0px;
  bottom: 0px;
  width: 4px;
`;
export const FormWrapper = styled.div`
  background: #EFF4F9;
  border-radius: 10px;
  padding:14px;
  margin:16px 0;
`;
export const QuestionTopics =styled.div`
  color: #2D3838;
  opacity: 0.9;
  font-size: 15px;
  line-height: 19px;
`;
export const QuestionDifficulty = styled.div`
  color: #979999;
  font-size: 13px;
  line-height: 16px;
`;
export const QuestionPart = styled.div`
  padding:10px 0px;
  color: #2D3838;
  font-size: 15px;
  line-height: 20px;
`;
export const FormTopic = styled.div`
padding-bottom: 20px;
> * {
  &:first-child {
    margin-top: 0;
  }
}
`;

export const TopicsWrapper = styled.div`
border-radius: 10px;
background: #FFFFFF;
box-shadow: 0px 0px 0px 1px #e1e6e6;
height:50px;
margin-top:14px;
padding:14px;
display:flex;
cursor: pointer;
@media (pointer: fine) {
      &:hover {
        box-shadow: 0 0 0 1px #C8CCCC;
      }
    } 
    &:active {
      box-shadow: none;
      background: #378EF8;
      
      .topic-title{
        color: #FFFFFF !important;
      }
      svg{
        
        path{
          fill: #FFFFFF;
        }
       
      }
    }

`;
export const TopicNumber = styled.div`
background: #FFFFFF;
box-shadow: 0px 2px 5px rgb(128 128 128 / 34%);
border-radius: 11px;
padding: 2px 9px;
font-weight: bold;
font-size: 13px;
line-height: 18px;
text-align: center;
letter-spacing: 2px;
text-transform: uppercase;
color: #378EF8;
margin-left: 12px;
`;
export const TopicsTitle= styled.div`
  margin-left: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #2D3838;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
`;
export const RightSectionIcon = styled.div`
  margin-left: auto;
  // padding-left: 13px;
  display: -webkit-box;
  display: -webkit-flex;
  align-items: center;
`;


export const StyledExamsFilterLayout = styled.div`

  padding :16px 20px 8px;

`;
export const ExamsModeContainer = styled.div`
  // margin-top:8px;
  display:flex;
  justify-content: space-between;
  align-items: center;  
`;
export const ExamsModeTitle = styled.div`
  color: #2D3838;
  font-size: 15px;
  line-height: 19px;
  opacity: ${props => props.isEditMode ? '0.5' : '1'};
  pointer-events: ${props => props.isEditMode ? 'none' : null};
  user-select: ${props => props.isEditMode && 'none'};
`;
export const ExamsModeSubTitle = styled.div`
  color: #979999;
  font-size: 13px;
  line-height: 16px;
  margin:10px 0 16px;
`;
export const FloderExamsSection = styled.div`
  background: #FFFFFF;
  box-shadow:0px 6px 20px rgba(62, 87, 85, 0.22);
  border-radius: 10px;
  height: 108px;
  margin-top: 6px;
  padding: 14px;
`;
export const ComingSoonButton = styled.div`
// margin 10px 0 5px;
color: #FF8000;
font-size: 12px;
line-height: 16px;
height:25px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 6px;
box-shadow: 0px 0px 0px 1px #FF8000;
width: fit-content;
padding:4px 8px;
// border: 1px solid #FF8000;
`;

export const FilterIcons =styled.div`
padding-left:8px
`;
export const CCExamsGroupFilter = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
  border-radius: 10px;
  // height: 108px;
  padding:  16px 14px 12px;
  margin:8px 16px 0;
`;
export const FilterNotTested = styled.div`
  color: #378EF8;
  font-size: 12px;
  line-height: 16px;
  box-shadow: 0px 0px 0px 1px #378ef8;
  border-radius: 6px;
  width: 106px;
  padding: 4px 8px 5px 8px;
  margin: 10px 0 5px 0;
`;

export const HiddenInfoWrapper = styled.div`
height: 100%;
width: 100%;
position: relative;
display: flex;
justify-content: center;
align-items: center;
padding: 56px 0 20px;
overflow: hidden;
// cursor: default;
`;