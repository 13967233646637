import classNames from 'classnames';
import React from 'react';
import Loader, {BackgroundEnum, SizeEnum} from './Loader.v2'

type LeftMenuLoaderProps = {
  background?: BackgroundEnum;
} 

const LeftMenuLoader: React.FC<LeftMenuLoaderProps> = ({background = BackgroundEnum.Blue}) => {
  return (
    <div className='flex justify-center pt-103px'>
        <Loader size={SizeEnum.Large} background={background} />
    </div>
  )
}

export default LeftMenuLoader;