import ConfirmEmail from 'components/confirm-email';
import FlashcardNavigationContainer from 'components/flashcard-navigation';
import FlashcardsVerticalListContainer from 'v2/components/flashcards-vertical-list/FlashcardsVerticalListContainer';
import AppLayout from 'layouts/AppLayout';
import IFrameLayout from 'layouts/IFrameLayout';
import ClassRoomPage from 'v2/pages/class-room/ClassRoomPage';
import EditFolderPage from 'v2/pages/edit-folder/EditFolderPage';
import FolderPage from 'v2/pages/desk/folder/FolderPage';
import CreateExercisePage from 'v2/pages/exercises/CreateExercisePage';
import EditNotePage from 'v2/pages/edit-note/EditNotePage';
import ExercisesPage from 'v2/pages/exercises/ExercisesPage';
import ExercisePage from 'v2/pages/exercises/ExercisePage';
import MBExercisesPage from 'v2/pages/exercises/MBCreateExercisePage';
import MBExercisePage from 'v2/pages/exercises/MBExercisePage';
import ExternalAuthPage from 'pages/ExternalAuthPage';
import ForgotPassword from 'pages/forgot-password';
import ResetPassword from 'pages/forgot-password/reset-password';
import ForgotUsername from 'pages/forgot-username';
import LoginPageContainer from 'v2/pages/login';
import RegisterPageContainer from 'pages/RegisterPage';
import ResendConfirmation from 'pages/resend-confirmation';
import SearchPage from 'v2/pages/search/SearchPageContainer';
import Store from 'pages/store';
import StoreItemDetails from 'pages/store/StoreItemDetails';
import TopicsPage from 'v2/pages/topics/TopicsPage';
import TopicPage from 'v2/pages/topics/TopicPage';
import SubTopicPage from 'v2/pages/topics/SubTopicPage';
import PropTypes from 'prop-types';
import PublicLayout from 'PublicLayout';
import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {getAccessToken} from './helpers';
import FoldersPage from 'v2/pages/desk/folders/FoldersPage';
import MarkingsPage from './pages/desk/markings/MarkingsContainer';
import NotesPage from 'v2/pages/desk/notes/NotesPage';
// import ExamsPage from "pages/desk/exams/ExamsPage";
import ExamsPage from "v2/pages/desk/exams/ExamsPage";
import ExamPage from "v2/pages/desk/exam/ExamPage";
import GoogleAuthPage from 'v2/pages/GoogleAuthPage';

const PublicRoute = ({component: Component, ...rest}) => {
  return <Route {...rest}
    render={props => <PublicLayout {...props}><Component match={rest.computedMatch}/></PublicLayout>}/>;
};
PublicRoute.propTypes = {
  component: PropTypes.any.isRequired
};

function isLoggedIn() {
  const token = getAccessToken();
  const isRefreshInProgress = window.refreshTokenPromise;
  return token || isRefreshInProgress;
}

const PrivateRoute = ({children, ...rest}) => {
  // if (!isLoggedIn()) {
  //   localStorage.setItem('smpp-redirect-url', rest.location.pathname);
  //   return <Redirect to='/login' state={{from: rest.location}}/>;
  // }

  return (
    // <Route {...rest} render={() => children}/>
    <Route {...rest} render={({location}) => {
      if (!isLoggedIn()) {
        localStorage.setItem('smpp-redirect-url', location.pathname);
        return <Redirect to={{
          pathname: '/login',
          state: { from: location}
        }}/>;
      } else {
        return children;
      }
    }}/>
  );
};
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};

const AppRoute = ({component: Component, content, ...rest}) =>
  <PrivateRoute {...rest}>
    <AppLayout content={content} {...rest}>
      <Component match={rest.computedMatch}/>
    </AppLayout>
  </PrivateRoute>;

const IFrameRoute = ({content, sideMenu, ...rest}) =>
  <PrivateRoute {...rest}>
    <IFrameLayout content={content} sideMenu={sideMenu} match={rest.computedMatch}/>
  </PrivateRoute>;

const FlashcardRoutes = ({match}) => (
  <div className="relative flashcard-nav-route-wrapper">
    {/* <FlashcardNavigationContainer/> */}
    <Route path={`${match.url}/topics`} component={TopicsPage}/>
    <Route path={`${match.url}/desk/notes`} component={NotesPage}/>
    <Route path={`${match.url}/desk/folders`} component={FoldersPage}/>
    {/* <Route path={`${match.url}/desk/markings`} component={MarkingsPage}/> */}
    <Route exact path={`${match.url}/desk/exercises`} component={ExercisesPage}/>
    <Route path={`${match.url}/search`} component={SearchPage}/>
    <Route exact path={`${match.url}/classroom`} component={ClassRoomPage}/>
    <Route exact path={`${match.url}/desk/exams`} component={ExamsPage}/>
  </div>
);

FlashcardRoutes.propTypes = {
  match: PropTypes.any
};

export default (
  <Switch>
    <PublicRoute exact path={'/'} component={() => <Redirect to={'/login'}/>}/>
    <PublicRoute exact path={'/register'} component={RegisterPageContainer}/>
    <PublicRoute exact path={'/login'} component={LoginPageContainer}/>
    <PublicRoute exact path={'/auth/external/:token?'} component={ExternalAuthPage}/>
    <PublicRoute exact path={'/confirm/:token'} component={ConfirmEmail}/>
    <PublicRoute exact path={'/auth/google/:token?'} component={GoogleAuthPage}/>
    {/* <PublicRoute exact path={'/forgot-password'} component={ForgotPassword}/> */}
    {/* <PublicRoute exact path={'/resend-confirmation'} component={ResendConfirmation}/>
    <PublicRoute exact path={'/reset-password/:token'} component={ResetPassword}/>
    <PublicRoute exact path={'/forgot-username'} component={ForgotUsername}/> */}

    <AppRoute exact path="/flashcard/desk/notes/edit" component={EditNotePage}/>
    <AppRoute exact path="/flashcard/desk/folders/edit/:id?" component={EditFolderPage}/>
    <AppRoute exact path="/flashcard/desk/folders/:id" component={FolderPage}/>
    <AppRoute exact path="/flashcard/desk/exams/:examId/:id" component={ExamPage}/>
    <AppRoute exact path="/flashcard/topic/:id" component={TopicPage}/>
    <AppRoute exact path="/flashcard/subtopic/:id" component={SubTopicPage}/>
    <AppRoute exact path="/flashcard/desk/exercise/create" component={CreateExercisePage} slidingMenu
      content={FlashcardsVerticalListContainer}/>
    <AppRoute exact path="/flashcard/desk/exercise/edit/:id" component={CreateExercisePage} slidingMenu
      content={FlashcardsVerticalListContainer}/>
    <AppRoute exact path="/flashcard/desk/exercise/:id" component={ExercisePage}/>
    <AppRoute exact path="/flashcard/classroom/exercise/:id" component={MBExercisePage}/>
    <AppRoute path="/flashcard" component={FlashcardRoutes}/>
    {/* <AppRoute exact path={'/store'} component={Store}/>
    <AppRoute exact path={'/store/:id'} component={StoreItemDetails}/> */}

    <IFrameRoute exact path={'/class/:classId/exercise/create'} sideMenu={MBExercisesPage}
      content={FlashcardsVerticalListContainer}/>
    <IFrameRoute exact path={'/class/:classId/exercise/:exerciseId'} sideMenu={MBExercisesPage}
      content={FlashcardsVerticalListContainer}/>

    <Redirect push to={'/'}/>
  </Switch>
);
