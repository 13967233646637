import React, { useEffect } from "react"
import ExamCarousel from './ExamCarousel';
import { getCardsTitleByType } from 'actions/api/apiActions';
import { getOrderofExamPaper, getFormExams } from 'v2/helpers';
import { useDispatch, useSelector } from "react-redux"
import {progressPopupOverlayNext, progressPopupOverlayPrevious} from "actions/api"
import { activeSubjectId, getExamsQuestionIndex, getExamFilterLoadingBySubject, getFlashcardById, getExamHeader, getActivePaperTab, getActiveExamsId} from "selectors"
import usePrevious from "hooks/usePrevious";
import {setActiveExamsId} from 'actions/navigation';
import ExamMediaButtonController from 'v2/components/studying/ExamMediaButtonController';
import ExamMediaLoading from 'v2/components/studying/ExamMediaLoading';
import './exam.scss'


type ExamsCarouselContainerProps = {
  flashcardId: string
}

const ExamsCarouselContainer: React.FC<ExamsCarouselContainerProps> = ({ flashcardId }) => {
  const dispatch = useDispatch();
  const hasExamsQuestionIndex: any = useSelector(getExamsQuestionIndex)?.toJS();
  const hasSubjectId = useSelector(activeSubjectId);
  const hasExamFilterLoadingBySubject = useSelector(getExamFilterLoadingBySubject);
  const flashcardContent =  useSelector((state) => getFlashcardById(state, flashcardId))?.toJS();
  const examHeader =  useSelector(getExamHeader);
  const hasActivePaperTab = useSelector(getActivePaperTab)
  const activeExamsId = useSelector(getActiveExamsId);
  const prevActivePaperTab = usePrevious(hasActivePaperTab)
  const prevActiveExamsId = usePrevious(activeExamsId)
  const prevFlashcardContent = usePrevious(flashcardContent);

  const formexamsType = getFormExams();
  const questionLoading = hasExamsQuestionIndex.data.length > 0 && !hasExamFilterLoadingBySubject;

  const setActiveExam = ():void => {
    dispatch(setActiveExamsId(flashcardContent?.experienceData?.exam_uuid))
  }

  useEffect(() => {
    dispatch(getCardsTitleByType({ subjectId: hasSubjectId, mediaType: "exams" }))
    setActiveExam()
  }, [])

  const paperSort = (list:any) => {
    const orderOfPaper = getOrderofExamPaper();
    return list.slice().sort((a:any, b:any) => {
        const indexA = orderOfPaper.indexOf(a.paper_type);
        const indexB = orderOfPaper.indexOf(b.paper_type);
        if (indexA < indexB) return -1;
        return 1;
      })
  }
  function getNextPrevPaperData(nextPaperId:any, prevPaperId:any){
    if(nextPaperId?.length){
      dispatch(progressPopupOverlayNext(nextPaperId[0].uid))
    }
    if(prevPaperId?.length){
      dispatch(progressPopupOverlayPrevious(prevPaperId.at(-1).uid))
    }
  }
  
  useEffect(() =>{
    const activePaperTab = flashcardContent?.experienceData?.tag_labels?.filter((item : any) => item.type === 'paper_type')[0].label
    if (examHeader) {
      if (getFormExams() === 'exams') {
        const sortData =  examHeader.papers && paperSort(examHeader.papers)
        const nextPaperId = sortData?.filter((val:any) => {
          return val.paper_type > activePaperTab
        })

        const prevPaperId = sortData?.filter((val:any) => {
          return val.paper_type < activePaperTab
        })
        getNextPrevPaperData(nextPaperId, prevPaperId)
      }
    }

    if((prevActivePaperTab != hasActivePaperTab || activeExamsId != prevActiveExamsId || flashcardContent.id != prevFlashcardContent.id) && flashcardContent?.experienceData?.tag_labels){
      setActiveExam()
    }
  }, [prevActivePaperTab, hasActivePaperTab, flashcardContent.id])

  return (<div className='exam-wrapper'>
    <ExamCarousel flashcardId={flashcardId} />
    <div className="exams-bottom-controls">
      {(formexamsType === 'exams' ? hasExamsQuestionIndex.data.length > 0 :
        questionLoading) ? <ExamMediaButtonController getExamsQuestionIndex={hasExamsQuestionIndex} /> : <ExamMediaLoading />}
    </div>
  </div>)
}

export default ExamsCarouselContainer