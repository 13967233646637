import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Margin = styled.div`
  margin: ${({top}) => top || 0}px ${({right}) => right || 0}px ${({bottom}) => bottom || 0}px ${({left}) => left || 0}px;
`;

Margin.propTypes = {
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
};

export default Margin;
