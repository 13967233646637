import classNames from 'classnames';
import React from 'react'
import {setGoBackToSearch} from 'actions/search'
import './emptyScreen.scss'
import {fcTitles, fcSubtitles, fcContents, fcLink} from './messages'
import { useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';


type FlashcardEmptyScreenProps = {
    className?: string;
    activeMedia?: string;
    type: string;
    size: number;
    edit?: boolean;
    changeRoute?: (val: string) => void;
    children?: any;
    FlashcardtypeSize?: boolean,
    editing?: boolean
}  

const FlashcardEmptyScreen: React.FC<FlashcardEmptyScreenProps> = ({className, activeMedia, type, size, edit, changeRoute, children}) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const addFlashcardInfo = () => {
        setTimeout(() => {
            dispatch(setGoBackToSearch(false))
            if(changeRoute && type === 'folders'){
                changeRoute('createFolder')
            }
            history.push(fcLink[type])
        }, 200)
    }
    return <div className={classNames('flashcard-empty-screen-container', {'!mb-3' : (activeMedia === 'exams')} , {'opacity-70 pointer-events-none' : edit})}>
       <div className={`flashcard-empty-screen ${className} ${edit && 'opacity-70 pointer-events-none'}`}>
            <div className='fc-empty-screen-btn' onClick={() => addFlashcardInfo()}>
                <div className='inline-flex items-center text-13px font-bold leading-18px tracking-2px uppercase py-2 px-0'>{children}
                    <div className='ml-2'>{fcTitles[type]}</div>
                </div>
            </div>
            {size === 0 && <div className='info-container'>
                    <div className="flashcard-empty-screen-subtitle">{fcSubtitles[type]}</div>
                    {fcContents[type] && <div className="flashcard-empty-screen-content">{fcContents[type]}
                    </div>}
                </div>
            } 
       </div>   
    </div>
}

export default FlashcardEmptyScreen;