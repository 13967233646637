import classNames from 'classnames';
import React, {memo, useImperativeHandle, useEffect, useState} from 'react'


type TabsProps = {
    children: any;
    header: (activeTab: string, selectTab:(tabId: string) => void) => React.ReactNode; 
    onTabChange:(val: string) => void;
}  
interface TabsRef {
  selectTab: (arg: string) => void;
}

const Tabs = React.forwardRef<TabsRef, TabsProps> ((props, ref) => {
  const {children, header, onTabChange = () => null} = props
    const [activeTab, setActiveTab] = useState('')
    const result = React.Children.toArray(children)
    useEffect(() => {
        setActiveTab(children?.props?.id || 'tab-all')
    },[])

    const selectTab = (tabId: string) => {
        setActiveTab(tabId)
        onTabChange(tabId)
    }
    useImperativeHandle(ref, () => ({ selectTab }), [header, ref, children]);
    return <div>
      {header(activeTab, selectTab)}
      <>
        {result?.find((tab: any) => tab.props.id === activeTab)}
      </>
    </div>
})

export default memo(Tabs);

