import React from "react";
type IframeProps = {
  iframe: string
}

type Ref = HTMLDivElement;

const Iframe = React.forwardRef<Ref, IframeProps>((props, ref) => {
  return <div ref={ref} dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />;
})

export default Iframe