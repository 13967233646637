import React from 'react';
import classNames from 'classnames';
import Text from 'v2/components/common/Text';
import {ContinueButtonIcon} from 'icons/MediaIcons';
import './mediaType.scss'
import Loader, {BackgroundEnum, SizeEnum} from 'v2/components/loader.v2/Loader.v2';

type MediaQuickStudyButtonProps = {
    title: string;
    icon: React.ReactElement;
    onClick: () => void;
    lastStudyClick: () => void;
    disabled: boolean | undefined;
    isActive: boolean;
    isLoading: boolean;
    isLoadingLastStudy: boolean;
    hasStructureLoading: boolean;
    isMediaLoading: boolean;
    isExamLoading?: boolean;
} 
const MediaQuickStudyButton: React.FC<MediaQuickStudyButtonProps> = ({title, icon, onClick, lastStudyClick, disabled, isActive, isLoading, isLoadingLastStudy, hasStructureLoading, isMediaLoading, isExamLoading=false}) => {
    const hasExamLoading = title === 'Question 1' && isExamLoading;
    const isQues1Loading = (hasExamLoading || isMediaLoading);
    const isContBtnLoading = (isLoading || isLoadingLastStudy);
    return <>
            <div className={classNames('quick-study-btn', {'quick-study-btn-loading [&>svg>path]:stroke-[#A2A3A3] [&>svg>circle]:stroke-[#A2A3A3] [&>svg>circle]:fill-[#A2A3A3] [&>svg>ellipse]:stroke-[#A2A3A3] [&>svg>rect]:stroke-[#A2A3A3] [&>svg]:opacity-50' : isQues1Loading})} onClick={onClick}>
                {icon}
                <Text className={classNames('quick-text leading-18px tracking-2px font-bold text-13px', {'text-#C8CCCC': isQues1Loading, 'text-blue-normal':!isQues1Loading})}  >
                    {title}
                </Text>
                {isQues1Loading && 
                    <div className='absolute right-2.5'><Loader size={SizeEnum.VSmall} background={BackgroundEnum.BlueX}/></div>
                }
            </div>
            <div className={classNames('continue-btn', {'pointer-events-none [&>svg>path]:stroke-[#A2A3A3] [&>svg>circle]:stroke-[#A2A3A3] [&>svg>path]:fill-[#A2A3A3] [&>svg]:opacity-50' : isContBtnLoading || disabled})} onClick={lastStudyClick}>
                <ContinueButtonIcon disabled={disabled} />
                <Text className={classNames('quick-text leading-18px tracking-2px text-13px font-bold', {'text-#C8CCCC' : (disabled || isContBtnLoading)}, {'text-blue-normal' : !disabled && !isContBtnLoading})} >
                    CONTINUE
                </Text>
                {isContBtnLoading && 
                    <div className='absolute right-2.5'><Loader size={SizeEnum.VSmall} background={BackgroundEnum.BlueX}/></div>
                }
            </div>
        </>
}

export default MediaQuickStudyButton;