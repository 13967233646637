import React from 'react'
import {BackgroundEnum} from './Loader.v2'

type LoaderSvgProps = {
  color?: BackgroundEnum; 
} 

const LoaderSvg: React.FC<LoaderSvgProps> = ({color}) => {
  if(color === BackgroundEnum.White ){
    return <LoaderWhiteBg />
  }   
  if(color === BackgroundEnum.Blue || color === BackgroundEnum.BlueX){
    return <LoaderBlueBg />
  }  
  if(color === BackgroundEnum.Grey){
    return <LoaderGreyBg />
  }  
  return <LoaderGradientBg />
}

export default LoaderSvg;

export const LoaderGradientBg = () => {
  return <svg className={'gradient'} width="52px" height="52px" viewBox="0 0 52 52" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="(xx)-N----Loader-Spinner" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="8--Mobile---Log-in---SP-Opt-2-(Password---Typing)" transform="translate(-162.000000, -357.000000)"
        stroke="#FFFFFF">
        <g id="MB/Content-Elements/Loader-Spinner/Large---CI-Background" transform="translate(161.000000, 356.000000)">
          <g id="Loader-Spinner-52px" transform="translate(3.000000, 3.000000)">
            <circle id="Background" strokeOpacity="0.25" strokeWidth="3" cx="24" cy="24" r="24"></circle>
            <path d="M24,0 C10.745166,0 0,10.745166 0,24" id="Loader-Copy" strokeWidth="3" strokeLinecap="round"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>;
};


export const LoaderWhiteBg = () => {
  return <svg className={'white'} width="52px" height="52px" viewBox="0 0 52 52" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient x1="0%" y1="100%" x2="106.755154%" y2="0%" id="linearGradient-120">
        <stop stopColor="#48CEA8" offset="0%"></stop>
        <stop stopColor="#4ABAD1" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="MB/Content-Elements/Loader-Spinner/Large---White-Background" transform="translate(-1.000000, -1.000000)"
        strokeWidth="3">
        <g id="Loader-Spinner-52px" transform="translate(3.000000, 3.000000)">
          <circle id="Background" stroke="#EFF0F0" cx="24" cy="24" r="24"></circle>
          <path d="M24,0 C10.745166,0 0,10.745166 0,24" id="Loader" stroke="url(#linearGradient-120)"
            strokeLinecap="round"></path>
        </g>
      </g>
    </g>
  </svg>;
};

export  const LoaderGreyBg = () => {
  return <svg className={'grey'} width="52px" height="52px" viewBox="0 0 52 52" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient x1="0%" y1="100%" x2="106.755154%" y2="0%" id="linearGradient-119">
        <stop stopColor="#48CEA8" offset="0%"></stop>
        <stop stopColor="#4ABAD1" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="(xx)-N----Loader-Spinner" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Loader-Spinner---Large-(White-Background)" transform="translate(-162.000000, -308.000000)">
        <g id="MB/Content-Elements/Loader-Spinner/Large---Gray-Background"
          transform="translate(161.000000, 307.000000)">
          <g id="Loader-Spinner-52px" transform="translate(3.000000, 3.000000)">
            <circle id="Background" stroke="#FFFFFF" strokeWidth="3" cx="24" cy="24" r="24"></circle>
            <path d="M24,0 C10.745166,0 0,10.745166 0,24" id="Loader" stroke="url(#linearGradient-119)" strokeWidth="3"
              strokeLinecap="round"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>;
};

export const LoaderBlueBg = () => {
  return <svg className={'blue'} width="52px" height="52px" viewBox="0 0 52 52" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient x1="0%" y1="100%" x2="106.755154%" y2="0%" id="linearGradient-118">
        <stop stopColor="#43DAF0" offset="0%"></stop>
        <stop stopColor="#395BF8" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="(xx)-N----Loader-Spinner" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Loader-Spinner---Large-(White-Background)" transform="translate(-162.000000, -308.000000)">
        <g id="MB/Content-Elements/Loader-Spinner/Large---Gray-Background"
          transform="translate(161.000000, 307.000000)">
          <g id="Loader-Spinner-52px" transform="translate(3.000000, 3.000000)">
            <circle id="Background" stroke="#FFFFFF" strokeWidth="3" cx="24" cy="24" r="24"></circle>
            <path d="M24,0 C10.745166,0 0,10.745166 0,24" id="Loader" stroke="url(#linearGradient-118)" strokeWidth="3"
              strokeLinecap="round"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>;
};
