import React from 'react';
import styled, {css} from 'styled-components/macro';
import Col from 'ui-components/helpers/Col';
import PropTypes from 'prop-types';

export const textEllipsis = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

export const Centered = styled.div`
  width: 100%;
  text-align: center;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Light = styled.span`
  font-weight: lighter;
`;

export const Center = ({children}) => (<Col alignItems={'center'}>{children}</Col>);

export const Relative = styled.div`
  position: relative;
`;

export const Hidden = styled.div`
  visibility: ${({when}) => when ? 'hidden' : 'visible'};
  opacity: ${({when}) => when ? '0' : '1'};
`;
export const SearchHidden = styled.div`
  display: ${({when}) => when ? 'none' : 'flex'};
`;
export const ClickableDiv = styled.div`
  cursor: pointer;
  z-index: 1;
  
`;

export const FixedWidth = styled.div`
  width: ${({size}) => size ? `${size}` : 'auto'};
`;

FixedWidth.propTypes = {
  size: PropTypes.string.isRequired,
};
