import React, {PureComponent} from 'react';
import styled, {css} from 'styled-components/macro';
import {Relative} from 'ui-components/helpers/common';
import Padding from 'ui-components/helpers/Padding';
import {ReactComponent as CloseIcon} from 'assets/icons/page-overlay/close-icon-turquoise.svg';
import {ReactComponent as BigCloseIcon} from "assets/icons/page-overlay/big-close-icon.svg"
import PropTypes from 'prop-types';
import media from 'ui-components/helpers/media';
import './PageOverlay.scss';
export const PageOverlayWrapper = styled.div`
  top: ${props => props.topPosition ? '56px' : '56px'};
  max-width: 1440px;
  margin: auto;
`;

export class PageOverlay extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closing: false
    };
  }


  componentDidMount() {
    const {onClose} = this.props;
    if (!onClose) return;
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 27) {
      this.onClose(false);
    }
  };

  onClose = () => {
    this.setState({closing: true});
    setTimeout(() => {
      this.props.onClose(false);
    }, 200);
  };

  render() {
    const {onClose, children, animate, isInactiveClose, topPosition, isOverlayVisible, supportAnimation ,subjectsManager} = this.props;
    return <PageOverlayWrapper animate={animate} topPosition={topPosition} className={`page-overlay ${isOverlayVisible ? 'open' : ''} ${supportAnimation ? 'support-animation' : ''} ${this.props.deviceContainerClass}`} isWidth={this.props.isWidth} supportAnimation={supportAnimation}>
      {isInactiveClose ? null : <Relative>
        {onClose && !subjectsManager  && <div className='close-btn-support' onClick={this.onClose}><CloseButton active={this.state.closing} subjectsManager={subjectsManager}/></div>}
      </Relative>}
      <ChildrenWrapper top={24} right={24} bottom={24} left={24} subjectsManager={subjectsManager}>
        {children}
      </ChildrenWrapper>
    </PageOverlayWrapper>;
  }
}

const CloseButton = ({active,subjectsManager}) =>
  <StyledCloseButton active={active} subjectsManager={subjectsManager}>
    <CloseIcon/>
  </StyledCloseButton>;

const StyledCloseButton = styled.div`
  // position: absolute;
  // top: 14px;
  // right: 20px;
  height: 20px;
  width: 20px;
  background: #378EF8;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  //transition: all .2s;
  // ${props =>props.subjectsManager && css`
  // position: relative;
  // margin-right: 0;
  // margin-left: auto;
  // top: 20px;
  // height: 40px;
  // width: 40px;
  // `}
  // @media (pointer: fine) {
  //   &:hover {
  //     background: #64A8FA;
  //   }
  // } 
  // &:active {
  //   background: #2170CF;
  // }
  
  ${media.phone(css`
    box-shadow: none;
    top: 16px;
    right: 16px;
    border-radius: 100px;
    height: 20px;
    width: 20px;
    z-index: 3;
    
  `)}
`;

const ChildrenWrapper = styled(Padding)`
  height: 100%;
  ${props => !props.subjectsManager &&  css `
  display: flex;
  align-items: center;
  justify-content: center;
  `}
  ${props => props.subjectsManager &&  css `
  padding: 0;
  `}
  ${media.phone(css`
    padding: 0;
    overflow: auto;
  `)}
`;

PageOverlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

PageOverlay.defaultProps = {
  onClose: () => {
  },
  animate: false
};
