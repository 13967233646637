import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import { getLastMediaTypeVisited } from 'v2/helpers';
import {getUserId} from 'selectors/user';
import {initializeStatus} from 'selectors/desk/deskSelector'
import {shouldGoBackToSearch} from 'selectors/searchSelectors'
import {getActiveMediaTab} from 'selectors/navigation/navigationSelector'
import {initializeNote, textareaNoteChange, updateNote} from 'actions/desk/deskActions'
import {setActiveMediaTab} from 'actions/navigation/navigationActions'
import NoteEditor from 'v2/components/edit-note/NoteEditor';

type NoteEditorContainerProps = {
    innerRef: any,
    noteText: string | number;
    currentId: number | string;
    toggleFocusTextarea: (val: boolean) => void;
    disable?: boolean | undefined;
} 

const NoteEditorContainer: React.FC<NoteEditorContainerProps> = ({innerRef, noteText, currentId, disable, toggleFocusTextarea}) => {
    const dispatch = useDispatch();
    const initialStatus = useSelector(initializeStatus)
    const searchGoBack = useSelector(shouldGoBackToSearch)
    const userId = useSelector(getUserId)
    const activeMedia = useSelector(getActiveMediaTab)
  

    useEffect(() => {
        const  mediaType = getLastMediaTypeVisited(userId);
        dispatch(initializeNote(currentId, mediaType))
        return () => {
            dispatch(textareaNoteChange(''))
        }
    }, [currentId])

    const onTextChange = (event: any) => {
        const  mediaType = getLastMediaTypeVisited(userId) 
        const {value} = event.target;
        dispatch(textareaNoteChange(value))
        dispatch(updateNote(value, currentId, mediaType))
        if(mediaType != activeMedia){
            dispatch(setActiveMediaTab(mediaType));
        }
    }
    return <>
        {initialStatus === 'inProgress' ?
            <div className="note-editor"><LeftMenuLoader /></div> : 
            <NoteEditor onTextareaChange={onTextChange} innerRef={innerRef} noteText={noteText} currentId={currentId} toggleFocusTextarea={toggleFocusTextarea} searchGoBack={searchGoBack} disable={disable}/>
        }
    </>;
}

export default NoteEditorContainer;