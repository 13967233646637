import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isMobile, isLandscape, structureLoading, getFlashcardById, flashcardFlipStatus } from 'selectors';
import {
  carouselFlashcardIndex,
  carouselRefIndex,
  carouselRendererIds, hasNextFlashcard, hasPrevFlashcard
} from 'selectors/carouselSelectors';
import FlashcardCarousel from 'v2/components/flashcard-carousel/FlashcardCarousel';
import usePrevious from 'hooks/usePrevious';

import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2';

type FlashcardCarouselContainerProps = {
  flashcardId: number
}

const FlashcardCarouselContainer: React.FC<FlashcardCarouselContainerProps> = ({flashcardId}) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const hasLandscape = useSelector(isLandscape)
  const hasMobile = useSelector(isMobile)
  const rendererIds = useSelector(carouselRendererIds)?.toJS()
  const hasCarouselRefIndex = useSelector(carouselRefIndex);
  const hasNext = useSelector(hasNextFlashcard)
  const hasPrev = useSelector(hasPrevFlashcard)
  const flashcardIndex = useSelector(carouselFlashcardIndex);
  const hasStructureLoading = useSelector(structureLoading);
  const prevStructureLoading  = usePrevious(hasStructureLoading);
  const currentFlashcard = useSelector((state) => getFlashcardById(state, flashcardId));
  const flipStatus = useSelector(flashcardFlipStatus)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  useEffect(()=>{
    if(prevStructureLoading !== hasStructureLoading){
      setLoading(hasStructureLoading);
    }
  }, [hasStructureLoading, prevStructureLoading])
  
  const computeOrder = useMemo(() => {
    let orderArr = new Array(3);
    orderArr[hasCarouselRefIndex] = 1;
    orderArr[(hasCarouselRefIndex + 1) % 3] = 2;
    orderArr[(hasCarouselRefIndex + 2) % 3] = 3;
    return orderArr;
  },[hasCarouselRefIndex]);

  if (!rendererIds || !(flashcardIndex >=0)) return null;
  
  if (isLoading) {
    return <div className='flex items-center justify-center z-3 bottom-0 top-0 right-0 left-0 fixed bg-#EFF4F9'>
      <Loader background={BackgroundEnum.BlueX} size={SizeEnum.Large} />
    </div>
  }

  return <FlashcardCarousel
    order={computeOrder} 
    animate={hasLandscape} 
    rendererIds={rendererIds} 
    hasPrev={hasPrev} 
    hasNext={hasNext} 
    flashcardIndex={flashcardIndex} 
    carouselRefIndex={hasCarouselRefIndex} 
    currentFlashcard={currentFlashcard} 
    isMobile={hasMobile} 
    isLandscape={hasLandscape} 
    flipStatus={flipStatus}
  />;

}

export default FlashcardCarouselContainer;
