import Immutable from 'immutable';
import * as Types from 'actions/topic/topicTypes';

export default (state = Immutable.List(), action) => {
  switch (action.type) {
    case Types.PUSH_TOPIC: {
      const immutableData = Immutable.fromJS(action.data);
      if (state.size === 0) {
        return state.push(immutableData);
      }
      const foundIndex = state.findIndex(item => item.get('teachingLevelId') === immutableData.get('teachingLevelId'));
      if (foundIndex === -1) {
        return state.push(immutableData);
      }
      return state.set(foundIndex, immutableData);
    }

    case Types.CLEAR_TOPICS:
      return state.clear();
    default:
      return state;
  }
};
