import * as Actions from 'actions';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {activeSubjectId} from 'selectors';
import * as Selectors from 'selectors';
import {topicsTree} from 'selectors/topicsTreeSelector';
import LinksComponent from './LinksComponent';
import { getOrderOfTeachingLevels } from 'helpers';

class LinksContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.links = Immutable.Map();

    this.linkClick = this.linkClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.links) {
      this.links = Immutable.Map();
    } else {
      this.mapLinks(nextProps.links);
    }
  }

  getLinkParentNumbering(link, type) {
    return link.get('parents').find(parent => parent.get('type') === type).get('numbering');
  }
  orderGroupLinks(groupedLinks){
    const orderOfTeachingLevels = getOrderOfTeachingLevels();
    const getTeachingLevel = (item) => {
      return item.get(0).get('parents').find(item => item.get('type') === 'TEACHING_LEVEL').get('text');
    }
    return groupedLinks.sort((a,b) => {
      const indexA = orderOfTeachingLevels.indexOf(getTeachingLevel(a));
      const indexB = orderOfTeachingLevels.indexOf(getTeachingLevel(b));
      if (indexA < indexB) return -1;
      return 1;
    })
  }
  mapLinks(links) {
   
    const groupedLinks = links.groupBy(link => link.get('parents').find(item => item.get('type') === 'TEACHING_LEVEL').get('text'));
    const groupOrderLinks = this.orderGroupLinks(groupedLinks);
    this.links = groupOrderLinks.map((value) => {
      return value.map(link => {
        let linkKey, linkValue = null, nodeId = link.get('nodeId'), linkType = null;
        switch (link.get('type')) {
          case 'TOPIC':
            linkKey = link.get('numbering');
            linkType = 'TOPIC';
            break;
          case 'SUB_TOPIC':
            linkKey = this.getLinkParentNumbering(link, 'TOPIC') + '.' + link.get('numbering');
            linkType = 'SUB_TOPIC';
            break;
          case 'FLASHCARD':
            linkValue = link.get('numbering');
            linkType = 'FLASHCARD';
            linkKey = this.getLinkParentNumbering(link, 'TOPIC') + '.' + this.getLinkParentNumbering(link, 'SUB_TOPIC');
            break;
          default:
            break;
        }
        return Immutable.fromJS({linkKey, linkValue, linkType, nodeId, linkData: link});
      });
    });
  }

  linkClick(link) {
    this.props.onClick && this.props.onClick();

    switch (link.get('linkType')) {
      case 'FLASHCARD':
        this.props.changeFlashcard(link.get('nodeId'));
        break;
      case 'TOPIC': {
        const subjectId = this.getIdsFromLinks(link, 'SUBJECT');
        const teachingLevelId = this.getIdsFromLinks(link, 'TEACHING_LEVEL');
        const topicId = link.get('nodeId');
        const topicContents = this.props.topicsTree.getIn([subjectId, 'children', teachingLevelId, 'children', topicId, 'children']);
        if (topicContents) {
          const firstFlashcard = topicContents.keySeq().first();
          this.props.changeFlashcard(firstFlashcard);
        }
        break;
      }
      case 'SUB_TOPIC': {
        const subjectId = this.getIdsFromLinks(link, 'SUBJECT');
        const teachingLevelId = this.getIdsFromLinks(link, 'TEACHING_LEVEL');
        const topicId = this.getIdsFromLinks(link, 'TOPIC');
        const subTopicId = link.get('nodeId');
        const subTopicContents = this.props.topicsTree.getIn([subjectId, 'children', teachingLevelId, 'children', topicId, 'children', subTopicId, 'children']);
        if (subTopicContents) {
          const firstFlashcard = subTopicContents.keySeq().first();
          this.props.changeFlashcard(firstFlashcard);
        }
        break;
      }
      default:
        return;
    }
  }

  getIdsFromLinks(link, type) {
    const foundItemFiltered = link.getIn(['linkData', 'parents'], Immutable.List()).filter(item => item.get('type') === type);
    if (foundItemFiltered.isEmpty()) return null;
    return foundItemFiltered.first().get('nodeId');
  }

  UNSAFE_componentWillMount() {
    const {links} = this.props;
    if (!links) return;

    this.mapLinks(links);
  }

  render() {
    return (
      <LinksComponent links={this.links} linkClick={this.linkClick}/>
    );
  }
}

LinksContainer.propTypes = {
  links: PropTypes.object,
  onClick: PropTypes.func,
  topicsTree: PropTypes.object.isRequired,
  changeFlashcard: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  links: Selectors.Studying.getLinks(state),
  topicsTree: topicsTree(state),
});
const mapDispatchToProps = {
  changeFlashcard: Actions.Studying.changeFlashcard,
};
export default connect(mapStateToProps, mapDispatchToProps)(LinksContainer);
