import {selectNote, updateListOfItemsSelected, selectExamNote} from 'actions/desk';
import {setGoBackToSearch} from 'actions/search';
import {selectFlashcard, setToolboxOpenSection, toggleOverlay, setActiveExerciseId} from 'actions/studying';
import { setExerciseOnCanvas } from 'actions/navigation';
import appHistory from 'appHistory';
import Immutable, { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as Selectors from 'selectors';
import {isDeskEditModeActive, listOfCheckedItems} from 'selectors';
import {currentFlashcardId} from 'selectors/studying';
import ListFlashcardPreview from 'v2/components/list-flashcard-preview/ListFlashcardPreview';
import {
  addBackFlashcardToLocalStorage,
  setLastMediaTypeVisited, 
  decodeQuery,
  storeExamsParentId,
  setFormExams,
  setExamSubTopicId,
  setExamTopicId
} from 'helpers';
import * as Actions from 'actions';
import {getUserId} from 'selectors/user';


const orderOfTeachingLevels = ['Studies SL', 'SL', 'HL', 'Option A | SL', 'Option B | SL', 'Option C | SL', 'Option D | SL', 'Option A | HL', 'Option B | HL', 'Option C | HL', 'Option D | HL', 'Teaching Level'];

class ListFlashcardPreviewContainer extends Component {
  
  groupedList() {
    return this.props.list
      .sort((a, b) => {
        const indexA = orderOfTeachingLevels.indexOf(a.get('teachingLevel'));
        const indexB = orderOfTeachingLevels.indexOf(b.get('teachingLevel'));
        if (indexA < indexB) return -1;
        return 1;
      })
      .groupBy(flashcard => flashcard.get('teachingLevel'))
      .map(teachingLevel =>
        teachingLevel
          .sortBy((item) => parseFloat(item.get('topicNumbering')))
          .groupBy((item) => item.get('topicNumbering')));
  }
  examGroupedList() {
    const sortData = this.props.list.sort((a, b) => a.get('examCategory') > b.get('examCategory') ? 1 : -1)
    return sortData
    .sort((a, b) => {
      const indexA = orderOfTeachingLevels.indexOf(a.get('examCategory'));
      const indexB = orderOfTeachingLevels.indexOf(b.get('examCategory'));
      if (indexA < indexB) return -1;
      return 1;
    })
    .groupBy(flashcard => flashcard.get('examCategory'))
    .map(teachingLevel =>
      teachingLevel
        .sortBy((item) => parseFloat(item.get('exam')))
        .groupBy((item) => item.get('exam')));
  }

  selectNote(item) {
    const query = decodeQuery(appHistory.location.search.substring(1));
    if(item.get('flashcardId') !== this.props.currentFlashcardId){
      addBackFlashcardToLocalStorage(this.props.activeMediaTab+"-"+item.get('flashcardId'), query.mediaType+"-"+this.props.currentFlashcardId);
    }
    let flashcardId ="";
    if(this.props.activeMediaTab === "flashcard"){
      flashcardId = item.get('flashcardId');
    }else if(this.props.activeMediaTab === 'exams'){
      flashcardId = item.get('flashcardId'); 
    }else{
      flashcardId = item.get('nodeId');
    }
   
    this.props.selectNote(flashcardId);
    appHistory.push('/flashcard/desk/notes/edit');
  }

  selectMode(item) {
    setLastMediaTypeVisited(this.props.activeMediaTab,this.props.userId);
    switch (this.props.type) {
      case 'notes':
        this.selectNote(item);
        break;
      case 'folders':
        this.props.selectFlashcard(item.get('flashcardId'));
        this.props.isMobile && this.props.toggleOverlay(false);
        break;
      default:
        break;
    }
  }

  onFlashcardCLick(item,form) {
    if(this.props.getExerciseOnCanvas || typeof this.props.getExerciseOnCanvas == "undefined" && !this.props.isDeskEditModeActive){
      this.props.setExerciseOnCanvas(false)
      this.props.setActiveExerciseId(null)
    }
    if (this.props.activeMediaTab === 'exams' && !this.props.isDeskEditModeActive) {
      let type 
      let parentId
      if (form) {
        type = 'topics';
        parentId = item.get('topicId')
      } else {
        type = 'exams'
        parentId = item.getIn(['experienceData','paper_uuid'])
        !this.props.isExamsMode && this.props.toggleExamsMode(true)
      }
      this.props.setExamsHeaderList({"type":type,"parentId":parentId})
      setExamTopicId(item.get('topicId'))
      setFormExams(type)
      storeExamsParentId(parentId)
      setExamSubTopicId(item.get('subTopicId'))
      
    }

    return this.props.isDeskEditModeActive ?
      this.props.updateListOfItemsSelected(item.get('flashcardId')) :
      this.selectMode(item);
  }

  render() {
    const data = this.props.isExamsMode ? this.examGroupedList(this.props.list): this.groupedList()

    return (
      <ListFlashcardPreview
        isExamsMode={this.props.isExamsMode}
        list={this.props.activeMediaTab === 'exams' ? this.props.isExamsMode ? this.examGroupedList(this.props.list): this.groupedList() :this.groupedList()}
       // list={this.props.isExamsMode && this.props.activeMediaTab==="exams" ? this.examGroupedList(this.props.list): this.groupedList()}
        type={this.props.type}
        isEditing={this.props.isDeskEditModeActive}
        onClick={(item,type) => this.onFlashcardCLick(item,type)}
        currentFlashcardId={this.props.currentFlashcardId}
        listOfCheckedItems={this.props.listOfCheckedItems}/>
    );
  }
}

ListFlashcardPreviewContainer.propTypes = {
  selectNote: PropTypes.func.isRequired,
  list: PropTypes.instanceOf(Immutable.List),
  isDeskEditModeActive: PropTypes.bool.isRequired,
  listOfCheckedItems: PropTypes.instanceOf(Immutable.List),
  updateListOfItemsSelected: PropTypes.func.isRequired,
  currentFlashcardId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isMobile: PropTypes.bool,
  selectFlashcard: PropTypes.func,
  type: PropTypes.string,
  setGoBackToSearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isDeskEditModeActive: isDeskEditModeActive(state),
  listOfCheckedItems: listOfCheckedItems(state),
  currentFlashcardId: currentFlashcardId(state),
  isMobile: Selectors.isMobile(state),
  activeMediaTab: Selectors.getActiveMediaTab(state),
  userId: getUserId(state),
  getExerciseOnCanvas: Selectors.getExerciseOnCanvas(state),
  isExamsMode:Selectors.isExamsMode(state)
});

const mapDispatchToProps = {
  selectNote,
  updateListOfItemsSelected,
  selectFlashcard,
  toggleOverlay,
  setGoBackToSearch: setGoBackToSearch,
  setToolboxOpenSection: setToolboxOpenSection,
  setExerciseOnCanvas: setExerciseOnCanvas,
  setActiveExerciseId: setActiveExerciseId,
  setExamsHeaderList:Actions.Navigation.setExamsHeaderList,
  toggleExamsMode: Actions.Navigation.toggleExamsMode
};
export default connect(mapStateToProps, mapDispatchToProps)(ListFlashcardPreviewContainer);



const newListItems = fromJS([
  [{
    "preview": {
      "svgContent": ["", ""],

      "textContent": "Derivative using limits",
      "fullText": "List the key statements of the cell theory Living organisms are made up of one or more cells. There is no smaller unit of life than cells. Every new cell must come from a pre-existing cell"
    },
    "subTopicId": 12387,
    "topicId": 12386,
    "topicNumbering": "1.1",
    "teachingTag": "SL&HL",
    "answers": 2,
    "teachingLevel": "SL",
    "title": "New flashcard",
    "numbering": 1,
    "subTopicName": "Introduction to cells",
    "flashcardId": 12388,
    "category": "1.1 SL",
    "paperType": "P1",
    "marks": 4,
    "difficulty": "Straightforward",
    "calculator": "calc",
    "sources": "IB",
    "nodeId": 12388,
    "paper": "Paper 1",
    "exam": "Exam 1",
    "questionType":'MCQ'
  },
  {
    "preview": {
      "svgContent": ["", ""],
      "textContent": "Limit notation",
      "fullText": "List the key statements of the cell theory Living organisms are made up of one or more cells. There is no smaller unit of life than cells. Every new cell must come from a pre-existing cell"
    },
    "subTopicId": 12387,
    "topicId": 12386,
    "topicNumbering": "1.1",
    "teachingTag": "SL&HL",
    "answers": 2,
    "teachingLevel": "SL",
    "title": "New flashcard",
    "numbering": 2,
    "subTopicName": "Introduction to cells",
    "flashcardId": 12388,
    "category": "1.1 SL",
    "paperType": "P1",
    "marks": 4,
    "difficulty": "Straightforward",
    "calculator": "calc",
    "sources": "OSC",
    "nodeId": 12389,
    "paper": "Paper 1",
    "exam": "Exam 1",
    "questionType":'FR'
  }],
  [{
    "preview": {
      "svgContent": ["", ""],
      "textContent": "Derivative as a rate of change",
      "fullText": "Outline three exceptions to the cell theory Giant algae (example, Acetabularia species): Can be up to 10 cm in length. Single nucleus. Striated muscle cell: Very long (up to 30 cm in length). Multiple nuclei. Aseptate fungi: Filamentous hyphae without cross walls (septa). Multiple nuclei. TEST TEST These exceptions oppose the idea that cells have a single nucleus and that they are small (a typical eukaryotic cell’s size ranges from 10 μm to 100 μm). However, these few discrepancies are not enough to invalidate the cell theory"
    },
    "subTopicId": 12387,
    "topicId": 12386,
    "topicNumbering": "1.2",
    "teachingTag": "SL&HL",
    "answers": 1,
    "teachingLevel": "SL",
    "title": "New flashcard",
    "numbering": 3,
    "subTopicName": "Introduction to cells",
    "flashcardId": 12389,
    "category": "1.1 SL",
    "paperType": "P1",
    "marks": 5,
    "difficulty": "Challenging",
    "calculator": "non-calc",
    "sources": "IB",
    "nodeId": 12390,
    "paper": "Paper 2",
    "exam": "Exam 1",
    "questionType":'MCQ'
  },
  {
    "preview": {
      "svgContent": [
        "Nucleus\nFlagellum\nEye spot\nCytoplasm\nChloroplast\nCell wall Contractile vacuole Cilia\nNucleus\nCytoplasm Plasma membrane Contractile vacuole Food vacuole",
        ""
      ],
      "textContent": "Intervals of decreasing function",
      "fullText": "Outline the functions of life, using the unicellular organisms Chlamydomonas and Paramecium as examples Chlamydomonas Feature Paramecium Reactions in the cytoplasm catalyzed by enzymes. Metabolism Reactions in the cytoplasm catalyzed by enzymes. Asexual or sexual. Reproduction Asexual or sexual. Water content maintained, contractile vacuoles remove excess water. Homeostasis Water content maintained, contractile vacuoles remove excess water. Photosynthesis (autotrophic). Nutrition Endocytosis of smaller organisms into food vacuoles (heterotrophic). O 2 produced during photosynthesis and CO 2 produced by cellular respiration, leave the cell by diffusion. Excretion CO 2 produced by cellular respiration leaves the cell by diffusion. Eye spot senses bright light and uses flagella to move towards it. Response When contacting an obstruction, it moves back by reversing the beating of the cilia and changes direction. Due to the uptake of inorganic mineral ions and photosynthesis. Growth Due to the absorption of digested organic materials from food vacuoles"
    },
    "subTopicId": 12387,
    "topicId": 12386,
    "topicNumbering": "1.3",
    "teachingTag": "SL&HL",
    "answers": 1,
    "teachingLevel": "SL",
    "title": "New flashcard",
    "numbering": 4,
    "subTopicName": "Introduction to cells",
    "flashcardId": 12391,
    "category": "1.1 SL",
    "paperType": "P2",
    "marks": 5,
    "difficulty": "Medium",
    "calculator": "non-calc",
    "sources": "OSC",
    "nodeId": 12391,
    "paper": "Paper 2",
    "exam": "Exam 1",
    "questionType":'MCQ'
  }],

])