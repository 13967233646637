import React, { useEffect } from "react";
import { setDocumentTitle } from "actions/navigation";
import { useDispatch, useSelector } from "react-redux";
import { getStudentPackages, checkPurchase } from "actions/api"
import { useLocation } from "react-router-dom";
import { decodeQuery } from 'helpers'
import { changeMenuPathAction } from 'actions/menuActions';
import { showPurchasedNotification } from 'actions/notification';
import Subscriptions from "./Subscriptions";
import './Subscriptions.scss'

const SubscriptionsPage= () => {
  const dispatch = useDispatch();
  const location = useLocation()


  useEffect(() => {
    dispatch(setDocumentTitle('Subscriptions'));
    dispatch(getStudentPackages());
    const urlQuery = decodeQuery(location.search.substring(1));
    if (urlQuery?.purchaseId) {
      dispatch(showPurchasedNotification());
      dispatch(checkPurchase(urlQuery.purchaseId));
    }
  }, [])
  return <Subscriptions />
}

export default SubscriptionsPage