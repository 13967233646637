import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';

export default class MathjaxFormula extends PureComponent {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    window.MathJax.Hub.Queue(['Typeset',  window.MathJax.Hub]);
  }

  render() {
    return <span className='mathjax-formula' ref={this.ref}>{this.props.children}</span>;
  }
}
MathjaxFormula.propTypes = {
  children: PropTypes.node,
};
