import React, {memo} from 'react'
import swal from 'sweetalert';
import {ReactComponent as CloseIcon} from 'assets/icons/page-overlay/close-icon-turquoise.svg';
import './notifications.scss'

type NoLinkNotificationProps = {
  title: string;
  text: string;
}  

const NoLinkNotification: React.FC<NoLinkNotificationProps> = ({title, text}) => {
    return <div className='swal-notification-link clear-margin'>
        <div className='swal-notification-close' onClick={() => {swal.close()}}>
            <CloseIcon />
        </div>
        <div className="swal-notification-link-title">
            {title || ''}
        </div>
        <div className="swal-notification-text">
          {text || ''}
        </div>
        <div className="notification-button" onClick={() => {swal.close()}}> OK </div>
    </div>
}

export default memo(NoLinkNotification);