import Immutable from 'immutable';
import * as Types from 'actions/studying/types';
import * as ApiTypes from 'actions/api/apiTypes';

export default (state = Immutable.fromJS({
  deviceRestricted: false,
  activeTools: {
    swap: false
  },
  zoomFactor: 1,
  navigationMode: 'default',
  toolboxOpenSection: null,
  pendingSVGs: [],
  inProgressLastStudyFlashcard: false,
}), action) => {
  switch (action.type) {
    case Types.TOGGLE_OVERLAY: {
      const toolTip = state.get('toolTipProps');
      if (toolTip && toolTip.get('visible')) {
        state = state.setIn(['toolTipProps', 'visible'], false).setIn(['toolTipProps', 'id'], '');
      }

      return state.set('showOverlay', action.data);
    }
    case Types.TOOL_TIP_PROPS:
      if (!state.get('toolTipProps')) return state.set('toolTipProps', Immutable.Map({
        'id': action.data,
        'visible': action.value === undefined ? true : action.value
      }));

      return state = state.update('toolTipProps',
        val => {
          if (val.get('id') !== action.data) {
            return val.set('visible', true).set('id', action.data);
          }
          else {
            if (val.get('visible')) {
              return val.set('visible', false);
            }
            else {
              return val.set('visible', true);
            }
          }
        }
      );
    case Types.SELECT_SIDE: {
      if (!state.getIn(['flashcards', action.flashcardId], undefined)) return state;

      return state.setIn(['flashcards', action.flashcardId, 'selectedSide'], action.sideId);
    }
    case Types.SET_ZOOM_FACTOR: {
      const zoomFactor = state.get('zoomFactor', 1);
      const zoomFactorToSet = zoomFactor + parseFloat(action.data);

      if (zoomFactor <= 0.5 && action.data === -0.1) {
        return state;
      }

      if (zoomFactor >= 2 && action.data === 0.1) {
        return state;
      }

      return state.set('zoomFactor', zoomFactorToSet);
    }
    case Types.SET_FIXED_ZOOM_FACTOR: {
      return state.set('zoomFactor', action.value);
    }
    case Types.SET_LINKS:
      return state.set('links', action.data);
    case Types.TOGGLE_SWAP: {
      const swap = state.getIn(['activeTools', 'swap']);
      const flashcardSide = getCurrentFlashcard(state).getIn(['outsideTemplate', 'sides']);

      if (!swap) {
        state = state.setIn(['flashcards', getCurrentFlashcard(state).get('id'), 'selectedSide'], flashcardSide.getIn([1, 'id']));
      }
      else state = state.setIn(['flashcards', getCurrentFlashcard(state).get('id'), 'selectedSide'], flashcardSide.getIn([0, 'id']));

      return state.updateIn(['activeTools', 'swap'], val => !val);
    }
    case ApiTypes.GET_FLASHCARD_CONTENT_SUCCEEDED: {
      let flashcards = state.get('flashcards', Immutable.Map());
      const data = action.data.data;
      const flashcardId = data.id;
      // if (flashcards.get(flashcardId)) return state;
      let newTempState;
      if(flashcards.get(flashcardId)){
        newTempState = state.setIn(['flashcards', flashcardId], Immutable.fromJS({}));
      } 
      const swap = state.getIn(['activeTools', 'swap'], false);
      newTempState = state.setIn(['flashcards', flashcardId], Immutable.fromJS(data));
      if(["revision", "video","exams"].includes(data.cType)){
        return newTempState;
      } else {
        return selectSide(newTempState, flashcardId, swap ? 1 : 0);
      }
      //state = state.setIn(['flashcards', flashcardId], Immutable.fromJS(data));
    }
    case Types.SET_CHANGE_FLASCHCARD_CONTENT_BOOKMARK_STATUS: {
      const flashcardId = action.data.flashcardId;
      const bookmarkStatus = action.data.bookmarkStatus;
      //let currentFlashcardContent = state.getIn(['flashcards', flashcardId]);
      return state.setIn(['flashcards', flashcardId, 'bookMark'], bookmarkStatus);
     
    }
    case Types.CALCULATE_FLASHCARD_IDS: {
      let ids = Immutable.List();

      action.topics.forEach((item) => {
        item.get('content').forEach((topic) => {
          topic.get('children').forEach(subTopic => {
            ids = ids.concat(subTopic.get('childrenIds'));
          });
        });
      });

      return state.set('flashcardIds', ids);
    }
    case Types.SET_FLASHCARD_INDEX: {
      const flashcardIds = state.get('flashcardIds', Immutable.List());
      const flashcardIndex = flashcardIds.indexOf(parseInt(action.flashcardId));

      if (flashcardIndex < 0) return state.set('flashcardIndex', 0);
      return state.set('flashcardIndex', flashcardIndex);
    }
    case Types.CALCULATE_RENDERER_IDS: {
      const index = state.get('flashcardIndex');
      const flashcardIds = state.get('flashcardIds', Immutable.List());

      let rendererIds = new Array(3);
      rendererIds[1] = flashcardIds.get(index);
      if (index === 0) {
        rendererIds[0] = null;
      }
      else {
        rendererIds[0] = flashcardIds.get(index - 1);
      }

      if (index === flashcardIds.size) {
        rendererIds[2] = null;
      }
      else {
        rendererIds[2] = flashcardIds.get(index + 1);
      }

      return state.set('rendererIds', Immutable.fromJS(rendererIds)).set('carouselRefIndex', 0);
    }
    case Types.CAROUSEL_PREV_FLASHCARD: {
      const flashcardIndex = state.get('flashcardIndex');
      const flashcardIds = state.get('flashcardIds');
      const carouselRefIndex = state.get('carouselRefIndex');
      const newIndex = flashcardIndex - 2;

      if (newIndex < -1) return state;

      const prevId = newIndex >= 0 ? flashcardIds.get(newIndex) : null;

      return state
        .setIn(['rendererIds', parseInt(carouselRefIndex + 2) % 3], prevId)
        .set('carouselRefIndex', (carouselRefIndex + 2) % 3)
        .set('flashcardIndex', parseInt(flashcardIndex) - 1);
    }
    case Types.CAROUSEL_NEXT_FLASHCARD: {
      const flashcardIndex = state.get('flashcardIndex');
      const flashcardIds = state.get('flashcardIds');
      const carouselRefIndex = state.get('carouselRefIndex');
      const newIndex = flashcardIndex + 2;

      if (newIndex > flashcardIds.size) return state;

      const nextId = newIndex <= flashcardIds.size - 1 ? flashcardIds.get(newIndex) : null;

      return state
        .setIn(['rendererIds', parseInt(carouselRefIndex)], nextId)
        .set('carouselRefIndex', (carouselRefIndex + 1) % 3)
        .set('flashcardIndex', parseInt(flashcardIndex) + 1);
    }
    case Types.SET_CURRENT_FLASHCARD_ID: {
      return state.set('currentFlashcardId', action.data);
    }
    case Types.FLIP_FLASHCARD: {
      if (!getFlashcardById(state, action.flashcardId)) {
        // Flashcard is not yet loaded
        return state;
      }
      const currentSideIndex = getFlashcardSideIndex(state, action.flashcardId);
      const numberOfSides = getFlashcardSides(state, action.flashcardId).size;
      const nextIndex = action.index >= 0 ? action.index : (currentSideIndex + 1) % numberOfSides;
      return selectSide(state, action.flashcardId, nextIndex);
    }
    case Types.NEXT_SIDE: {
      const currentFlashcard = getCurrentFlashcard(state);
      const selectedSide = currentFlashcard.get('selectedSide');
      const templateSideIds = getTemplateSideIds(currentFlashcard);

      const currentSideIndex = templateSideIds.indexOf(selectedSide);
      let nextSideIndex = currentSideIndex + 1;

      if (nextSideIndex >= templateSideIds.size) {
        nextSideIndex = '0';
      }

      const nextSide = templateSideIds.get(nextSideIndex);

      return state.setIn(['flashcards', currentFlashcard.get('id'), 'selectedSide'], nextSide);
    }
    case Types.ENABLE_RESTRICTION:
      return state.set('deviceRestricted', true);
    case Types.SET_NAVIGATION_MODE:
      return state.set('navigationMode', action.navigationMode);
    case Types.SET_TOOLBOX_OPEN_SECTION:
      return state.set('toolboxOpenSection', action.data);
    case Types.SET_ACTIVE_FLASHCARD: {
      return state.set('activeFlashcardId', action.id);
    }
    case Types.SCROLL_TO_FLASHCARD:
      return state.set('scrollToFlashcardId', action.id);
    case Types.SET_ACTIVE_EXERCISE_ID:
      return state.set('activeExerciseId', action.id);
    case Types.SET_EXERCISE_ID:
      return state.set('setExerciseId', action.id);
    case Types.SET_CAROUSEL_TEACHING_LEVEL:
      return state.set('carouselTeachingLevel', action.id);
    // case Types.ADD_PENDING_SVG:
      //return state.update('pendingSVGs', (pendingSVGs) => pendingSVGs.push(action.id));
      // return state.update('pendingSVGs', (pendingSVGs) => {
      //   const foundSVG = pendingSVGs.find((item) => item.id === action.id);
      //   if(!foundSVG){
      //     return pendingSVGs.push(action.id);
      //   }
      // });
    case Types.REMOVE_PENDING_SVG:
      return state.update('pendingSVGs', (pendingSVGs) => pendingSVGs.filter((item) => item !== action.id));
    case Types.GET_LAST_STUDY_FLASHCARD_SUCEEDED:
        return state.set('getStudyLastFlashcard', action.data);
    case Types.SET_CANVAS_ZOOM_FACTOR: {
      return state.set('canvasZoomFactor', action.value);
    }
    case Types.SET_LINKS_SIZE:
      return state.set('linksSize', action.data);
    case Types.SET_CURRENT_EXERCISE_ID:
      return state.set('currentExerciseId', action.id);
    case Types.SET_INPROGRESS_LAST_STUDY_FLASHCARD:
      return state.set('inProgressLastStudyFlashcard', action.value);
    default:
      return state;
  }
};

function getCurrentFlashcard(state) {
  const flashcards = state.get('flashcards', Immutable.List());
  const flashcardId = state.get('currentFlashcardId');
  return flashcards.get(parseInt(flashcardId));
}

function getTemplateSideIds(flashcard) {
  const sides = flashcard.getIn(['outsideTemplate', 'sides'], null);

  if (sides === null) return Immutable.List();
  return sides.map(item => {
    return item.get('id');
  });
}

function getFlashcardById(state, id) {
  const flashcards = state.get('flashcards', Immutable.List());
  return flashcards.get(parseInt(id));
}

function getFlashcardSideIndex(state, flashcardId) {
  const flashcard = getFlashcardById(state, flashcardId);
  const selectedSide = flashcard.get('selectedSide');
  const templateSideIds = getTemplateSideIds(flashcard);
  return templateSideIds.indexOf(selectedSide);
}

function selectSide(state, flashcardId, newSideIndex) {
  const sides = getFlashcardSides(state, flashcardId);
  const sideId = sides.get(newSideIndex);
  return state.setIn(['flashcards', flashcardId, 'selectedSide'], sideId);
}

function getFlashcardSides(state, flashcardId) {
  const flashcard = getFlashcardById(state, flashcardId);
  return getTemplateSideIds(flashcard);
}
