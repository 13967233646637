import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveMediaTab } from 'selectors/navigation/navigationSelector';
import { activeSubjectId } from 'selectors/subject/subjectSelector';
import ExamPreviewListItem from 'v2/pages/desk/exam/ExamPreviewListItem';
import { ForwardChevronIcon } from 'icons/HeaderIcons';
import { useHistory } from 'react-router-dom';
import { setActiveFlashcardTab, setActivePaperTab } from 'actions/navigation';
import classNames from 'classnames';
import PreviewListItem from 'v2/ui-components/search-results/PreviewListItem';
import PreviewListHeader from 'v2/ui-components/search-results/PreviewListHeader';
import ExamsPreviewListItem from 'v2/ui-components/search-results/ExamsPreviewListItem';
import VideoPreviewListItem from 'v2/ui-components/search-results/VideoPreviewListItem';
import RevisionPreviewListItem from 'v2/ui-components/search-results/RevisionPreviewListItem';
import ExamPreviewListHeader from 'v2/ui-components/search-results/ExamPreviewListHeader';
import { sortArray, sortArrayString } from 'v2/helpers';

type ListFlashcardPreviewProps = {
  currentFlashcardId?: number | string;
  isEditing?: boolean;
  isExamsMode: boolean;
  list: any;
  listOfCheckedItems?: any;
  onClick?: (argA: any, argB?: any) => void;
  type: any;
}

const ListFlashcardPreview: React.FC<ListFlashcardPreviewProps> = ({ currentFlashcardId, isEditing, isExamsMode, onClick, list, listOfCheckedItems, type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeMediaTab = useSelector(getActiveMediaTab);
  const subjectId = useSelector(activeSubjectId);
  const onSubTopicClick = (subTopicId: any) => {
    history.push(`/flashcard/subtopic/${subTopicId}`);
    dispatch(setActiveFlashcardTab(0));
  }
  const renderFlashcardPreview = (data: any) => data.map((item: any, index: number) => {
    switch (activeMediaTab) {
      case 'revision':
        return <RevisionPreviewListItem
          key={index}
          flashcardId={item?.flashcardId}
          type={type}
          isEditing={isEditing}
          onClick={() => onClick?.(item)}
          revisionNumber={item?.numbering}
          active={item?.nodeId == currentFlashcardId}
          title={item?.title}
          shortDescription={item?.content}
          deleted={false}
          shouldMark={false}
          listOfCheckedItems={listOfCheckedItems}
          rightSection={<ForwardChevronIcon />}
          nodeTeachingTag={item.nodeTeachingTag}
          teachingLevel={item.teachingLevel}
          teachingTag={item.teachingTag}
        />
      case 'video':
        return <VideoPreviewListItem
          key={index}
          id={item?.flashcardId}
          type={type}
          isEditing={isEditing}
          onClick={() => onClick?.(item)}
          videoNumber={item?.numbering}
          active={item?.nodeId == currentFlashcardId}
          title={item?.title}
          shortDescription={item?.content}
          totalDuration={item?.totalDuration}
          totalWatched={item?.totalWatched}
          totalDurationInSeconds={item?.totalDurationInSeconds}
          totalWatchedInSeconds={item?.totalWatchedInSeconds}
          deleted={false}
          shouldMark={false}
          thumbImage={item?.thumbImage}
          listOfCheckedItems={listOfCheckedItems}
          rightSection={<ForwardChevronIcon />}
          nodeTeachingTag={item?.nodeTeachingTag}
          teachingLevel={item?.teachingLevel}
          teachingTag={item?.teachingTag}
        />
      case 'exams':
        return !isExamsMode ? <ExamsPreviewListItem key={`exam-${Math.random()}`}
          deleted={item?.deleted}
          flashcardId={item?.flashcardId}
          isEditing={isEditing}
          listOfCheckedItems={listOfCheckedItems}
          flashcardNumber={item?.numbering}
          title={item?.questionTitle}
          paper={item?.paper}
          teachingTag={item?.questionTeachingTag}
          active={item?.flashcardId == currentFlashcardId}
          marks={item?.marks}
          type={type}
          rightSection={<ForwardChevronIcon />}
          sources={item?.experienceData?.qb_code}
          experienceData={item?.experienceData}
          onClick={() => onClick?.(item, item?.sources !== 'osc_dp_mocks')}
          preview={item?.content}
          difficulty={item?.difficultyLevel}
          nodeTeachingTag={item?.nodeTeachingTag}
        /> :
          <ExamPreviewListItem
            key={index}
            QuestionType={item?.questionType}
            flashcardId={item?.flashcardId}
            isEditing={isEditing}
            listOfCheckedItems={listOfCheckedItems}
            teachingTag={item?.questionTeachingTag}
            teachingLevel={item?.questionTeachingTag}
            active={item?.flashcardId == currentFlashcardId}
            marks={item?.marks}
            type={type}
            preview={item?.content}
            rightSection={<ForwardChevronIcon />}
            sources={item?.experienceData && item?.experienceData?.qb_code}
            experienceData={item?.experienceData}
            onClick={() => onClick?.(item, false)}
            number={item?.id}
          />
      default:
        return <PreviewListItem
          deleted={item?.deleted}
          type={type}
          flashcardId={item?.flashcardId}
          key={item?.numbering}
          onClick={() => onClick?.(item)}
          isEditing={isEditing}
          active={item?.flashcardId === currentFlashcardId}
          flashcardNumber={item?.absoluteNumbering || item?.numbering}
          preview={item?.content || item?.preview?.textContent?.[0]}
          previewTitle={item?.title}
          rightSection={<ForwardChevronIcon />}
          listOfCheckedItems={listOfCheckedItems}
          shouldMark={false}
          activeMath={[15075, 14982].includes(subjectId)}
          data={item}
        />;
    }
  }
  )
  const title = (item: any) => activeMediaTab === 'exams' ? item?.subTopicTitle : item?.subTopicName;
  const onPaperClick = (item: any) => {
    const paperId = item?.experienceData?.paper_uuid;
    const examsId = item?.experienceData?.exam_uuid;
    const paperLable = item?.experienceData?.tag_labels?.filter((item: any) => item?.type === 'paper_type')?.[0]?.label;
    dispatch(setActivePaperTab(paperLable));
    dispatch(setActiveFlashcardTab(1));
    history.push(`/flashcard/desk/exams/${examsId}/${paperId}`);
  }
  
  if (!isExamsMode || activeMediaTab !== 'exams') {
    return list.map((teachingLevel: any, index: number) =>
      {
        return Object.values(teachingLevel)?.map((subtopic: any, key: number) => {
          return <div key={key} className={classNames({ 'pt-[25px]': index === 0 && key === 0 && activeMediaTab === 'exams' })}>
            <PreviewListHeader topicNumber={subtopic?.[0]?.topicNumbering}
              index={key + index}
              teachingLevel={subtopic?.[0]?.teachingLevel}
              teachingTag={subtopic?.[0]?.teachingTag}
              subTopicId={subtopic?.[0]?.subTopicId}
              subTopicTitle={title(subtopic?.[0])} key={key}
              onClick={() => onSubTopicClick(subtopic?.[0]?.subTopicId)} 
              data={subtopic?.[0]} />

            {renderFlashcardPreview(sortArray(subtopic, 'numbering'))}
          </div>;
        }
        );
      })
  }
  else if (isExamsMode) {
    return <div className='pt-6'>
      {list?.map((teachingLevel: any, index: number) =>
        {
          return Object.values(teachingLevel)?.map((item: any, key: number) => {
            return <>
              <ExamPreviewListHeader
                index={index} key={key}
                teachingLevel={item?.[0]?.examTeachingLevel}
                teachingTag={typeof item?.examTeachingLevel !== "undefined" ? item?.examTeachingLevel : null}
                fromPage={'notes'}
                subTopicTitle={item?.[0]?.exam + ' • ' + (item?.[0]?.paper ?? 'Paper 1')}
                onClick={() => onPaperClick(item?.[0])} />
              {renderFlashcardPreview(sortArrayString(item, 'position'))}
            </>;
          }
          );
        }
      )}
    </div>
  }
  else {
    return <></>
  }
}

export default ListFlashcardPreview