import { useSelector } from "react-redux";
import { subjectExerciseNotificationsCount } from "selectors/exerciseSelectors";
import { oldNewSubjectMapped } from "v2/helpers";

/**
 *
 * @param subjectId number
 * @returns {{ oldSubjectExerciseNotificationsCount: number, newSubjectExerciseNotificationsCount: number, totalSubjectExerciseNotificationsCount: number }}
 */
const useExerciseNotificationCountBySubject = (subjectId: number) => {
  const [oldSubjectID, newSubjectID] = oldNewSubjectMapped.find((ids: any) =>
    ids.includes(subjectId)
  ) ?? [0, 0];
  const oldSubjectExerciseNotificationsCount = useSelector((state) =>
    // @ts-ignore
    subjectExerciseNotificationsCount(state, oldSubjectID)
  );
  const newSubjectExerciseNotificationsCount = useSelector((state) =>
    // @ts-ignore
    subjectExerciseNotificationsCount(state, newSubjectID)
  );
  const totalSubjectExerciseNotificationsCount = oldSubjectID === newSubjectID ? oldSubjectExerciseNotificationsCount :
    oldSubjectExerciseNotificationsCount + newSubjectExerciseNotificationsCount;
  return {
    oldSubjectExerciseNotificationsCount,
    newSubjectExerciseNotificationsCount,
    totalSubjectExerciseNotificationsCount,
  };
};

export default useExerciseNotificationCountBySubject;