import React, { useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { getActiveMediaTab, getExamTopicsStatus } from 'selectors' 
import { mediaTypes, returnFinalStatusV2, disableMcqExamSubject, setLastMediaTypeVisited, setFormExams, setExamTopicId, setExamSubTopicId, storeExamsParentId } from 'helpers';
import './mediaType.scss';
import { statusById } from 'selectors/statusSelector';
import {getUserId} from 'selectors/user';
import { getFilterItemSize, firstNode, getUpdateExamsPreviewList, firstTopicId, firstSubtopicId} from 'selectors/navigation/navigationSelector';
import { getActiveSubject} from 'selectors/subject/subjectSelector';
import { setExamsHeaderList} from 'actions/navigation/navigationActions';
import MediaTabContainer from './MediaTabContainer'
import MCQFilterLayout from 'v2/components/filter-overlay/exams-filter-container/MCQFilterLayout';
import MircoFilterLayout from 'v2/components/studying/microFilterContainer/MicroFilterLayout'
import {getFlashcardIdsRoutine} from 'actions/api/apiActions';
import {updateExamsQuestionIndex} from 'actions/desk/deskActions'
import MediaStudyButton from './MediaStudyButton';
import { StudyButtonIcon, ReadButtonIcon, WatchButtonIcon, PracticeButtonIcon } from 'icons/MediaIcons';
import { selectFlashcard, selectExams } from 'actions/studying';

type MediaTypeTabProps = {
    subject?: any;
    topicId?: number;
    techingLevel: string | undefined;
    isLoading?: boolean;
    resultSize?: number;
    page?: string;
}  
const MediaTypeTab: React.FC<MediaTypeTabProps> = ({subject, topicId, techingLevel, isLoading, resultSize, page}) => {
    const dispatch = useDispatch();   
    const activeMediaTab = useSelector(getActiveMediaTab);
    const mediaQuickStatus = useSelector((state) => statusById(state, topicId)).toJS();
    const activeSubject = useSelector(getActiveSubject);
    const examTopicStatus = useSelector((state) => getExamTopicsStatus(state, topicId)).toJS();
    const filterItemCount = useSelector(getFilterItemSize);
    const userId = useSelector(getUserId);
    const firstStudy = useSelector(firstNode);
    const udpatedExamPrivewList = useSelector(getUpdateExamsPreviewList);
    const firstTopic = useSelector(firstTopicId);

    const firstSubTopic = useSelector(firstSubtopicId);
    const statusData = {
        flashcard: mediaQuickStatus.flashcard || {},
        revision: mediaQuickStatus.revision || {},
        video: mediaQuickStatus.video || {},
        exams:  mediaQuickStatus.exam?.freeResponse || {},
        mcq:  mediaQuickStatus.exam?.msqTotal || {},
        mcqTotal: examTopicStatus?.msqTotal?.total,
        freeResponseTotal: examTopicStatus?.freeResponse?.total,
    }
    const { flashcardStatus, revisionStatus, videoStatus, examsStatus, mcqStatus } = returnFinalStatusV2(statusData);

    useEffect(() => {
        dispatch(getFlashcardIdsRoutine({ subjectId: subject.id }));
    },[activeSubject, subject, dispatch]);

    const isMediaActive = (type: string) => {
        return activeMediaTab === type;
    }
    
 
    const selectCard = () => {
        setLastMediaTypeVisited(activeMediaTab, userId);
        dispatch(selectFlashcard(firstStudy))
        if (activeMediaTab === "exams") {
            dispatch(selectExams(firstStudy, "topics", udpatedExamPrivewList?.length))
            setFormExams("topics");
            setExamTopicId(udpatedExamPrivewList?.[0]?.topicId);
            let examsIndex = [];
            examsIndex = udpatedExamPrivewList.map((val: any) => {
                let numbering = val.numbering;
                return { index: numbering, uuid: val.flashcardId };
            });
            dispatch(updateExamsQuestionIndex(examsIndex, 1))
            dispatch(setExamsHeaderList({
                type: 'topic',
                parentId: firstTopic,
            }))
            setExamSubTopicId(firstSubTopic)
            storeExamsParentId(firstTopic)
        }
    }
    

    return <>
        <MediaTabContainer statusData={statusData} page={page} />
        {(!isLoading && resultSize !== 0) && <>
            {activeMediaTab === 'exams' ? !(mcqStatus.total == 0 && examsStatus.total == 0) &&
                    <div className='flex items-center pt-6 pb-0 px-5'>
                        {!disableMcqExamSubject(activeSubject?.id) && <>
                            <MCQFilterLayout formCC={false} isEditMode={false}/>

                        </> }   
                        <MircoFilterLayout page={activeMediaTab === 'exams' ? "exercise" : 'topics'} />
                    </div>
                    :
                    <MircoFilterLayout  className='mt-6' />
                }
        </> }
        <div className='px-5'>
            {flashcardStatus.total > 0 && filterItemCount > 0 && !isLoading && isMediaActive(mediaTypes.flashcard) &&  <MediaStudyButton title={'Study'} icon={<StudyButtonIcon />} onClick={selectCard} isFCMaths={true} techingLvl={techingLevel} subjectSlug={activeSubject.slug} />}

            {revisionStatus.total > 0 && filterItemCount > 0 && !isLoading && isMediaActive(mediaTypes.revision) && <MediaStudyButton title={'Read'} icon={<ReadButtonIcon />} onClick={selectCard} />}

            {videoStatus.total > 0 && filterItemCount > 0 && !isLoading && isMediaActive(mediaTypes.video) && <MediaStudyButton title={'Watch'} icon={<WatchButtonIcon />} onClick={selectCard} isVCMaths={true} techingLvl={techingLevel} subjectSlug={activeSubject.slug} />}

            {(examsStatus.total > 0 || mcqStatus.total) > 0 && !isLoading && isMediaActive(mediaTypes.exams) && <MediaStudyButton title={'Practice'} icon={<PracticeButtonIcon />} onClick={selectCard} isFCMaths={false} techingLvl={techingLevel} subjectSlug={activeSubject.slug} />}
        </div>
    </>
}

export default MediaTypeTab;