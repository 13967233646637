import React from 'react'
import { splitString, setLastMediaTypeVisited} from 'helpers';
import {BackCardButton} from 'icons/FlashcardNavIcons';
import { setActiveMediaTab } from 'actions/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveMediaTab, getFlashcardById, getUserId } from 'selectors';
import { selectFlashcard } from 'actions/studying';
import './cardBackButton.scss'

type CardBackButtonContainerProps = {
    flashcardId: string | number;
    returnCardId: any;
    isStudyOverlay?: boolean;
    isRightOverlay?:boolean;
    isLeftOverlay?: boolean;
}  

const CardBackButtonContainer: React.FC<CardBackButtonContainerProps> = ({flashcardId, returnCardId, isStudyOverlay, isRightOverlay, isLeftOverlay}) => {
    const disptach = useDispatch();
    const userId = useSelector(getUserId)
    const activeMediatype = useSelector(getActiveMediaTab)
    const currentFlashcard = returnCardId['current-flashcard-id'];
    const returnFlashcard = returnCardId['return-flashcard-id'];
    const currentMediaValue = splitString(currentFlashcard);
    const returnMediaValue = splitString(returnFlashcard);
    const currentFlashcardId =  currentMediaValue?.[1];
    const returnFlashcardId =  returnMediaValue?.[1];
    const returnMedia =  returnMediaValue?.[0];
    const flashcard = useSelector((state) => getFlashcardById(state, parseInt(returnMediaValue?.[1])))?.toJS();
    const onClick = () => {
        if(activeMediatype !== returnMedia) disptach(setActiveMediaTab(returnMedia))
        setLastMediaTypeVisited(returnMedia,userId)
        disptach(selectFlashcard(parseInt(returnFlashcardId)))
    };
    if(!returnCardId['return-flashcard-id'] || !flashcard?.numbering) return null;
    return <>
        {(currentFlashcardId == flashcardId) && 
            <div className={`back-card-button ${isLeftOverlay && 'button-left-overlay'} ${isStudyOverlay ? 'button-study-overlay': 'study-overlay'} ${isRightOverlay &&  'button-right-overlay'}`} onClick={onClick}>
                <div className='flex items-center justify-center'>
                    <BackCardButton />
                </div>
            </div>
        }
    </>
}

export default CardBackButtonContainer;
