import {SET_EXAM_NODE_STATUS } from 'actions/examStatus/examStatusActionTypes';
import {fromJS, Map} from 'immutable';

export default (state = Map({
    freeResponse: Map({}),
    mcqResponse: Map({}),
}), action) => {
  switch (action.type) {
    case SET_EXAM_NODE_STATUS:
      return state.withMutations(state => {
        action.data.forEach(node => {
            state.set(node.id, fromJS({freeResponse: node.freeResponse, mcqResponse: node.mcqResponse}));
        });
        return state;
      });
    default:
      return state;
  }
};
