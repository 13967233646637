import {List} from 'immutable';
import {getFlashcardById} from 'selectors/studying';

// export const carouselFlashcardIds = state => state.getIn(['carousel', 'flashcardIds'], List());
// export const carouselRevisionIds = state => state.getIn(['carousel', 'revisionIds'], List());
// export const carouselVideoIds = state => state.getIn(['carousel', 'videoIds'], List());
export const carouselPreviousFlashcardIds = state => state.getIn(['carousel', 'previousFlashcardIds'], List());

export const carouselRendererIds = (state) => state.getIn(['carousel', 'rendererIds'], null);
export const carouselRefIndex = state => state.getIn(['carousel', 'carouselRefIndex']);
export const carouselFlashcardIndex = state => state.getIn(['carousel', 'flashcardIndex']);
export const carouselCurrentFlashcardId = state => carouselFlashcardIds(state)[(carouselFlashcardIndex(state))];
export const carouselCurrentFlashcard = (state) => getFlashcardById(state, parseInt(carouselCurrentFlashcardId(state)));

export const hasPrevFlashcard = (state) => carouselFlashcardIndex(state) > 0;
export const hasNextFlashcard = (state) => {
  const ids = carouselPreviousFlashcardIds(state);
  return ids && carouselFlashcardIndex(state) < ids.size - 1;
};
// export const carouselFlashcardIdDetails = state => state.getIn(['carousel', 'flashcardIdDetails'], List());
// export const carouselRevisiondIdDetails = state => state.getIn(['carousel', 'revisionIdDetails'], List());

// export const carouselVideoIdDetails = state => state.getIn(['carousel', 'videoIdDetails'], List());
// export const hasNextRevision = (state) => {
//   let ids = carouselRevisionIds(state);
//   const flashcardIndex = ids.indexOf(parseInt(carouselCurrentFlashcardId(state)));
//   if(carouselRendererIds(state) && carouselRendererIds(state).get(2)){
//   //  ids = carouselPreviousFlashcardIds(state)
//     return true;
//   }else{
// // else if(carouselRendererIds(state) && carouselRendererIds(state).get(2)){
//   //     return true;
//   //   }else{
//   //     return false;
//   // }
//    return ids && carouselFlashcardIndex(state) < ids.size - 1;
//   }
  
// };

export const hasNextRevision = (state) => {
  const ids = carouselRevisionIds(state);
  return ids && carouselFlashcardIndex(state) < ids.length - 1;
};

export const hasNextVideo = (state) => {
  const ids = carouselVideoIds(state);
  return ids && carouselFlashcardIndex(state) < ids.length - 1;
};

export const hasPrev5Flashcards = (state) => carouselFlashcardIndex(state) >= 5;
export const hasNext5Flashcards = state => {
  const ids = carouselPreviousFlashcardIds(state);
  return ids && carouselFlashcardIndex(state) < ids.size - 5;
};

// REFECTORING

export const carouselFlashcardIds = state => state.getIn(['carousel', 'flashcardIds'], []);
export const carouselRevisionIds = state => state.getIn(['carousel', 'revisionIds'], []);
export const carouselVideoIds = state => state.getIn(['carousel', 'videoIds'], []);

export const carouselFlashcardIdDetails = state => state.getIn(['carousel', 'flashcardIdDetails'], []);
export const carouselRevisiondIdDetails = state => state.getIn(['carousel', 'revisionIdDetails'], []);
export const carouselVideoIdDetails = state => state.getIn(['carousel', 'videoIdDetails'], []);
