import React, { useEffect, useState } from 'react'
import FilterHeader from './FilterHeader';
import './filter.scss'
import { useDispatch, useSelector } from 'react-redux';
import {isMainMicroFilterBack} from 'selectors/navigation/navigationSelector'
import FilterToggleHeader from './FilterToggleHeader'
import {examsofFilterItems} from 'selectors/desk/deskSelector'
import {updateExamsPaperFilterItems} from 'actions/desk/deskActions'
import {toggleMainMicroOverlay} from 'actions/navigation/navigationActions'
import {setExamFilterData} from 'actions/api/apiActions'
import usePrevious from 'hooks/usePrevious';
import FilterButton from './FilterButton'
import FilterContainer from './FilterContainer';
import { CorrectIcon, IncorrectIcon } from "icons/FlashcardIcons"

type MCQFilterProps = {
    onClick?: (e: any) => void;
    onClose: () => void;
}  

const filterData = [
    { text: 'Not yet answered', icon: '' },
    { text: 'Incorrect', icon: <IncorrectIcon /> },
    { text: 'Correct', icon: <CorrectIcon /> }
]

const MCQFilter: React.FC<MCQFilterProps> = ({onClick, onClose}) => {
    const dispatch = useDispatch();
    const [isActive, setActive] = useState(true)
    const [disabled, setDisable] = useState(true)
    const [filter, setFilter] = useState(['Not yet answered', 'Incorrect', 'Correct'])
    const hasMainMicroFilterBack = useSelector(isMainMicroFilterBack)
    const examFilterItems = useSelector(examsofFilterItems)?.toJS();
    const previousFilterState = usePrevious(['Not yet answered', 'Incorrect', 'Correct'])
    
    useEffect(() => {
        let data = examFilterItems['mcq']
        data.length > 0 && setFilter(data);
    },[])

    useEffect(() => {
        if(previousFilterState !== filter){
            changeFilter();
        }  
    },[filter, previousFilterState])

    const selectToggle = (val: boolean) => {
        setActive(!val)
        if(isActive){    
            setFilter([]);
            return;
        }
        setFilter(previousFilterState) 
    }

    const selectFilter = (val: string) => {
        const filterVal = [...filter];
        let indexOfItem = filterVal.indexOf(val);
        if (indexOfItem >= 0) {
            filterVal.splice(indexOfItem, 1);
        } else {
            filterVal.push(val);
        }
        setFilter(filterVal);
    }

    const changeFilter = () => {
        let prevExamFilter = examFilterItems['mcq']
        if (JSON.stringify(filter.sort()) !== JSON.stringify(prevExamFilter.sort())) {
            setDisable(false)
        } else {
            setDisable(true)
        }
        if (filter.length < 1) {
            setDisable(true)
        }
        if (filter.length === 3) {
            setActive(true)
        } else {
            setActive(false)
        }
    }

    const applyChanges = () => {
        if(disabled) return;
        dispatch(updateExamsPaperFilterItems(filter, "mcq"))
        dispatch(setExamFilterData())
        onClose();
    }

    const onSelectMain= () => {
        onClose();
        dispatch(toggleMainMicroOverlay(true, true))
    }
    
    return <div className='filter-wrapper' onClick={onClick}>
        <FilterHeader
            fromCC={hasMainMicroFilterBack} 
            onBackClick={onSelectMain} 
            text={'Filter'} 
            hideArrow={false} 
            title={'MCQ answer'} 
            onCloseClick={onClose} 
        />
        <FilterToggleHeader title={'All answers'} onChange={() => selectToggle(isActive)} checked={isActive}/>
        <div className='pt-0 px-5 pb-4'>
            {filterData.map((value, index) => {
                return <FilterContainer index={index} isWidth={true} filterType={'mcq'} filter={filter} onClick={() => selectFilter(value.text)} itemID={value.text} isRow={true} isMinHeight={false}>
                    <>
                        <div className='filter-category-section'>{value.text}</div>
                        <div className='pl-2'>{value.icon}</div>
                    </>
                </FilterContainer>;
            })}
        </div>
        <FilterButton onClose={onClose} onClick={applyChanges} disabled={disabled}/>
    </div>
}

export default MCQFilter;