import { useState, useEffect } from "react"
import theme from 'theme';
import { changeMenuPathAction } from 'actions/menuActions';
import { useDispatch, useSelector } from "react-redux"
import { isMBUserSelector, userDataSelector, isApiRequestInProgress, getStudentPackages} from 'selectors';
import moment from 'moment';
import mbLogo from 'assets/images/mb_logo.svg';
import { AddVoucherIcon } from 'icons/MySubjectsIcon';
import { SubjectIcon } from 'icons/SubjectIcons';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import BackToMenuButtonContainer from 'v2/pages/menu/BackToMenuButtonContainer';
import Button, { ThemeEnum } from 'v2/components/Button/Button';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import Subscription from 'v2/pages/subscriptions/Subscription';

const Subscriptions = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isClick, setClick] = useState(false);
  const dispatch = useDispatch();
  const isMBUser = useSelector(isMBUserSelector);
  const userData = useSelector(userDataSelector).toJS()
  const studentPackagesInProgress = useSelector(state =>isApiRequestInProgress(state, 'studentPackages'));
  const studentPackages = useSelector(getStudentPackages);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  const handleScroll = (event: Event) => {
    if (window.innerWidth < theme.sizes.phone && window.innerWidth > window.innerHeight) {
      setCollapsed(false);
      return;
    }
    let target: any = event.target;

    if (target.scrollingElement) {
      target = target.scrollingElement;
    }

    if (target.scrollTop > 20) {
      setCollapsed(false);
    } else {
      setCollapsed(false);
    }

  }

  const addVocher = () => {
    setClick(true);
    setTimeout(() => {
      dispatch(changeMenuPathAction('add-voucher'))
      setClick(false);
    }, 200);
  }

  const renderStudentPackage = (item: any, index: number) => {
    if (isMBUser) {
    // If the expiration date is 15 Nov 2019 and the user is from ManageBac - display the ManageBac Voucher
    //TODO add a way of displaying user vouchers
    const expiration = moment(item.expirationDate);
      if (expiration.month() === 10 && expiration.year() === 2019 && expiration.date() === 15) {
        return <Subscription key={index}
          icon={<div><img width={'48px'} src={mbLogo} alt=""/></div>}
          name={'ManageBac Trial'}
          userData={userData}
          daysPurchased={parseInt(item.daysPurchased)}
          validity={'Valid through November 15, 2019'}
          daysRemaining={parseInt(item.daysRemaining)}
          purchaseDateFormatted={item.purchaseDateFormatted}
        />;
      }
    }

    return <Subscription key={index}
      icon={<div className={'subject-icon'}><SubjectIcon subject={item.subjectSlug}/></div>}
      name={item.name}
      userData={userData}
      daysPurchased={item.daysPurchased}
      daysRemaining={parseInt(item.daysRemaining)}
      purchaseDateFormatted={item.purchaseDateFormatted}
    />;
  };


  return <div className="more-page-fixed-spacing px-5" id='subscriptions-page' >
    {/* @ts-ignore */}
    <NavigationHeader extendedWidth isCollapsed={collapsed} text='Your plan' leftSection={(<BackToMenuButtonContainer />)} />
    <div className="font-bold leading-45px text-#2D3838 tracking-normal pt-52px text-33px ">Your plan</div>
    <div className="page-body pt-3">
    <Button icon={<AddVoucherIcon />} onClick={addVocher} text={<div className="pl-2.5">ADD MANUAL ACCESS CODE</div>} type={'button'}
        btnTheme={ThemeEnum.White} className={'w-full logout-active'} />
    </div>

    <div className="my-3.5 h-1px rounded-1px bg-#E1E6E6" />
    <div className='subject-list'>
      {studentPackagesInProgress ? 
      <LeftMenuLoader/> : studentPackages.map(renderStudentPackage)}
    </div>
  </div>
}

export default Subscriptions