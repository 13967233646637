import moment from 'moment';
import React, {Component} from 'react';
import {Field} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import {renderDatepicker} from 'utils/form-helper';
import 'react-datepicker/dist/react-datepicker.css';

export default class FormInputDatepicker extends Component {
  constructor(props) {
    super(props);

    this.resetField = this.resetField.bind(this);
  }

  resetField() {
    this.props.change(this.props.name, '');
    this.props.touch(this.props.name);
  }

  render() {
    return (
      <div className='form-input-wrapper'>
        <Field
          id={this.props.id}
          name={this.props.name}
          component={renderDatepicker}
          className='form-input'
          label={this.props.label}
          placeholder={this.props.placeholder}
          validate={this.props.validate}
          reset={this.resetField}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          normalize={this.props.normalize}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

FormInputDatepicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  validate: PropTypes.array,
};

FormInputDatepicker.defaultProps = {
  maxDate: moment(),
  minDate: moment().subtract(30, 'y')
};
