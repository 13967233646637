import React, { ReactElement } from 'react';
import classNames from 'classnames';
import CheckRadioButton from 'v2/components/check-radio-button/CheckRadioButton';
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';
import { NoteListIcon } from 'icons/FlashcardIcons';
import AnswerBulletContainer from 'v2/components/AnswerBullet/AnswerBulletContainer';
import { IBIcon, OSCIcon, PmjIcon } from 'icons/HeaderIcons';

type ExamPreviewListItemProps = {
    active: boolean;
    type?: string;
    isEditing?: boolean;
    flashcardId: string;
    listOfCheckedItems?: any;
    number: string;
    teachingLevel: string;
    marks: number;
    experienceData: any;
    preview?: string;
    rightSection?: ReactElement;
    QuestionType: string;
    sources?: string;
    previewTitle?: string;
    teachingTag: string;
    onClick: () => void;
}

const ExamPreviewListItem: React.FC<ExamPreviewListItemProps> = ({ active, type, isEditing, flashcardId, listOfCheckedItems, number, teachingLevel, marks, experienceData, preview, rightSection, QuestionType, sources='', previewTitle, teachingTag, onClick }) => {

    let previewDesc = preview;
    if (previewDesc === ' ' || previewDesc === '') {
        if (previewTitle != "New flashcard" && type !== 'notes' && previewTitle != "") {
            previewDesc = previewTitle;
        } else {
            previewDesc = 'The preview functionality is coming soon';
        }
    } else {
        if (previewTitle != "New flashcard" && type !== 'notes') {
            previewDesc = previewTitle;
        } else {
            previewDesc = previewDesc;
        }
    }

    const sourcesIcon = (sources:string) => {
        switch (sources) {
            case 'ib_dp':
                return <IBIcon />;
            case 'osc_dp':
                return <PmjIcon />;
            case 'osc_dp_mocks':
                return <PmjIcon />;
            default:
                return <OSCIcon />;
        }
    }
    
    return <div 
            className={classNames('py-3 px-5 flex items-center cursor-pointer exam-preview-list-item', { 
                'bg-#E5ECF2': active, 
                'bg-white': !active, 
                'pt-2.5 px-5 pb-3.5': type !== "notes" 
            })}
            onClick={onClick}>
            {isEditing && <CheckRadioButton itemId={flashcardId} listOfCheckedItems={listOfCheckedItems} isExams={true} />}
            <div className='relative exams-container'>
                <div className='flex items-center justify-center'>
                    {type !== 'notes' && <div className='text-#2D3838 font-bold mr-4 id-number'>{number}</div>}
                    <div className='source-icon'>{sourcesIcon(sources)}</div>
                    <div className='flex items-center justify-center'>
                        {teachingLevel && <TeachingLevelSeperator tlvl={teachingLevel} tlvlTag={teachingTag} isExams={true} slTag={teachingTag === "SL"}/> }
                    </div>
                    <div className='ml-2 exam-marks-info'>
                        {marks} {marks <= 1 ? 'mark' :'marks'} • {experienceData && experienceData.calc_enabled ? 'Calc' : 'Non-calc'}
                    </div>
                </div>
                {type === 'notes' && 
                <div className='flex exams-section'>
                    <div className='flex mr-2 items-center'><NoteListIcon /></div>
                    <div className='mark-text overflow-hidden items-center'><div className="note-short-desc exam-mark">{previewDesc}</div></div>
                </div>
                }
            </div>
            <div className={classNames('ml-auto flex right-section', {'pl-0 items-center':type==='notes'})}>
            {type === 'notes' ? <>
                    {rightSection}
                </> :
                <>
                    {QuestionType && <>
                        <AnswerBulletContainer id={flashcardId} type={'exams'} isMcq={QuestionType.includes('mcq')}/>
                    </>}
                </>}
            </div>
        </div>
}

export default ExamPreviewListItem