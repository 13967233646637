import React, {Component} from 'react';
import NavigationHeader from 'components/navigation-header/NavigationHeader';
import BackButton from 'components/navigation-header/BackButton';
import {DeleteTrashIcon} from 'icons/FlashcardNavIcons';
import SimpleButton from 'components/simple-button/SimpleButton';
import BackToSearchButton from 'ui-components/BackToSearchButton';
/**
 * @deprecated TODO- delete not use any more
 */
export default class HeaderEditNotesTooltip extends Component {
  renderLeftSection = () => {
    const {deleteConfirmationMode} = this.props;

    return <BackToSearchButton alternate={() => {
      return deleteConfirmationMode ?
        <BackButton onClick={() => this.props.toggleDeleteConfirmationMode(false)} text="Cancel"
          hideArrow={true}/> : <BackButton onClick={this.props.onBack} text="Notes"/>;
    }}/>;
  };

  render() {
    return (
      <React.Fragment>
        <NavigationHeader
          extendedWidth={!this.props.deleteConfirmationMode}
          contained
          title={this.props.flashcardNumber}
          leftSection={this.renderLeftSection()}
          rightSection={(
            this.props.deleteConfirmationMode ?
              <div></div> :
              <div className="edit-note-buttons-container">
                {this.props.isTextareaFocused ? <div className="done-button focused"><SimpleButton text="Done"
                  onClick={() => this.props.toggleFocusTextarea(false)}/></div> : null}
                {this.props.textareaNote !== '' ?
                  <div className="delete-icon-note" onClick={() => this.props.toggleDeleteConfirmationMode(true)}>
                    <DeleteTrashIcon color='#2ebcb4'/></div> : null}
              </div>
          )}/>
      </React.Fragment>
    );
  }
}
