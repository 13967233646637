
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {openMenu} from 'actions/navigation';
import {startOnboarding} from 'actions/onboarding/onboardingActions';
import {isMBUserSelector, userDataSelector} from 'selectors';
import SupportComponent from 'components/supports-manager/SupportComponents';
import * as Selectors from 'selectors';
import {toggleSupportManagerOverlay} from 'actions/navigation';
class SupportContainer extends Component {

    computeErrorSubject = () => {
        const {activeSubject, activeTeachingLevel, currentFlashcard} = this.props; 
        if (currentFlashcard.size === 0) return null;
    
        const currentFlashcardNo = currentFlashcard.get('numbering');
        const activeTopic = currentFlashcard.get('parentsData').find(item => item.get('type') === 'TOPIC').get('title');
        const subjectTitle = activeSubject.get('title');
        const teachingLevelTitle = activeSubject.get('children').find(item => item.get('id') === activeTeachingLevel).get('title');
    
        return `Subject=Error report on subject: ${subjectTitle}, package: ${teachingLevelTitle}, topic: ${activeTopic}, card #${currentFlashcardNo}`;
      }
    render(){
        const {isMBUser, userData, startOnboarding, openMenu, activeSubject, activeTeachingLevel, currentFlashcard, isControlOverlay, isMobile, isLandscape, toggleSupportManagerOverlay} = this.props;
        const isMobilePortrait = !(isMobile && isLandscape)
        return <SupportComponent isMBUser={isMBUser} userData={userData} startOnboarding={startOnboarding} openMenu={openMenu} activeSubject={activeSubject} activeTeachingLevel={activeTeachingLevel} currentFlashcard={currentFlashcard} isControlOverlay={isControlOverlay} computeErrorSubject={this.computeErrorSubject} isMobilePortrait={isMobilePortrait} toggleSupportManagerOverlay={toggleSupportManagerOverlay}/>
    }
}

const mapStateToProps = (state) => ({
    activeSubject: Selectors.getActiveSubject(state),
    activeTeachingLevel: Selectors.getActiveTeachingLevel(state),
    currentFlashcard: Selectors.Studying.currentFlashcard(state),
    isMBUser: isMBUserSelector(state),
    userData: userDataSelector(state),
    isMobile: Selectors.isMobile(state),
    isLandscape: Selectors.isLandscape(state),
});
const mapDispatchToProps = {
  startOnboarding,
  openMenu,
  toggleSupportManagerOverlay:toggleSupportManagerOverlay,
};

SupportContainer.defaultProps = {
    isControlOverlay: false
}
export default connect(mapStateToProps, mapDispatchToProps)(SupportContainer);