import React, {useEffect, useState} from 'react'
import FilterHeader from './FilterHeader';
import './filter.scss'
import { useDispatch, useSelector } from 'react-redux';
import {isMainMicroFilterBack} from 'selectors/navigation/navigationSelector'
import FilterToggleHeader from './FilterToggleHeader'
import {examsofFilterItems} from 'selectors/desk/deskSelector'
import {updateExamsPaperFilterItems} from 'actions/desk/deskActions'
import {toggleMainMicroOverlay} from 'actions/navigation/navigationActions'
import {setExamFilterData} from 'actions/api/apiActions'
import {activeSubjectId, subjectsTeachingLevel} from 'selectors/subject/subjectSelector'
import usePrevious from 'hooks/usePrevious';
import FilterButton from './FilterButton'
import FilterContainer from './FilterContainer';
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';

type TeachingLevelExamsFilterProps = {
    onClick?: (e: any) => void;
    onClose: () => void;
}  

const filterData: any ={
    "SL":[
        {
            tlvlTag: "SL", 
            subTitle: 'All questions that apply only to the SL student.' 
        },
        { 
            tlvlTag: "SL&HL", 
            subTitle: 'Shared content - all questions that apply to the SL and HL student.' 
        }
    ],
    "HL":[
        {
            tlvlTag: "SL&HL", 
            subTitle: 'Shared content - questions that apply to the SL and HL student.' 
        },
        { 
            tlvlTag: "HL", 
            subTitle: 'All questions that apply only to the HL student.' 
        }
    ]
}

const TeachingLevelExamsFilter: React.FC<TeachingLevelExamsFilterProps> = ({onClick, onClose}) => {
    const dispatch = useDispatch();
    const [isActive, setActive] = useState(true)
    const [disabled, setDisable] = useState(true)
    const [filter, setFilter] = useState<any>({SL:["SL","SL&HL"], HL:['SL&HL', 'HL']})
    const hasMainMicroFilterBack = useSelector(isMainMicroFilterBack)
    const examFilterItems = useSelector(examsofFilterItems)?.toJS();
    const subjectId = useSelector(activeSubjectId)
    const subjectTLvl = useSelector(subjectsTeachingLevel)
    const previousFilterState = usePrevious({SL:["SL","SL&HL"], HL:['SL&HL', 'HL']})
    const tlvl = subjectTLvl[`${subjectId}`];
    useEffect(() => {
        let data = examFilterItems['teachingLevel']
        data.length > 0 && setFilter((prevFilter: any) => {return { ...prevFilter, [tlvl]: data}})
    },[])

    useEffect(() => {
        if(previousFilterState !== filter){
            changeFilter();
        }  
    },[filter, previousFilterState])

    const selectToggle = (val: boolean) => {
        setActive(!val)
        if(isActive){
            if(tlvl === "HL"){
                setFilter({SL: ["SL","SL&HL"], HL:[]})
            }
            if(tlvl === "SL"){
                setFilter({SL: [], HL:['SL&HL', 'HL']})
            }
            return
        }
        setFilter(previousFilterState) 
    }

    const selectFilter = (val: string) => {
        const filterVal = [...filter[tlvl]];

        let indexOfItem = filterVal.indexOf(val);
        if (indexOfItem >= 0) {
            filterVal.splice(indexOfItem, 1);
        } else {
            filterVal.push(val);
        }
        setFilter((prevFilter: any) => {return { ...prevFilter, [tlvl]: filterVal}})
    }

    const changeFilter = () => {
        let stateFilter = filter[tlvl]
        let prevExamFilter = examFilterItems['teachingLevel']
        if (JSON.stringify(stateFilter?.sort()) !== JSON.stringify(prevExamFilter?.sort())) {
            setDisable(false)
        } else {
            setDisable(true)
        }
        if (filter.length < 1) {
            setDisable(true)
        }
        if (filter[subjectTLvl[`${subjectId}`]]?.length === 2) {
            setActive(true)
        } else {
            setActive(false)
        }
    }

    const applyChanges = () => {
        if(disabled) return ;
        let stateFilter = filter[tlvl]
        dispatch(updateExamsPaperFilterItems(stateFilter, "teachingLevel"))
        dispatch(setExamFilterData())
        onClose();
    }

    const onSelectMain= () => {
        onClose();
        dispatch(toggleMainMicroOverlay(true, true))
    }
    
    return <div className='filter-wrapper' onClick={onClick}>
        <FilterHeader
            fromCC={hasMainMicroFilterBack} 
            onBackClick={onSelectMain} 
            text={'Filter'} 
            hideArrow={false} 
            title={'Teaching level'} 
            onCloseClick={onClose} 
        />
        <FilterToggleHeader title={'All teaching levels'} onChange={() => selectToggle(isActive)} checked={isActive}/>
        <div className='pt-0 px-5 pb-4'>
            {filterData[tlvl].map((value:any, index:number) => {
                return <FilterContainer index={index} filterType={'teachinglevel'} filter={filter[tlvl]} onClick={() => selectFilter(value.tlvlTag)} itemID={value.tlvlTag} isMinHeight={true}>
                    <>
                        <div className='flex items-center'>
                            <TeachingLevelSeperator  tlvl={value.tlvlTag} tlvlTag={value.tlvlTag} slTag={value.tlvlTag === "SL"}/>
                        </div>
                        <div className='text-13px leading-16px text-#919191 text-ellipsis overflow-hidden mt-2'>{value.subTitle}</div>
                    </>
                </FilterContainer>;
            })}
        </div>
        <FilterButton onClose={onClose} onClick={applyChanges} disabled={disabled}/>
    </div>
}

export default TeachingLevelExamsFilter;