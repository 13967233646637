import FooterMenuWrapper from 'components/footer-edit-mode/FooterMenuWrapper';
import {DeleteTrashIcon} from 'icons/FlashcardNavIcons';
import PropTypes from 'prop-types';
import React from 'react';
import './FooterEditMode.scss';

export const FooterDeleteMode = ({onClick, contained}) =>
  <FooterMenuWrapper className={`footer-delete-mode ${contained ? 'contained' : ''}`}
    onClick={onClick}>
    <div className='delete-btn'><DeleteTrashIcon/></div>
  </FooterMenuWrapper>;

FooterDeleteMode.propTypes = {
  contained: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
export default FooterDeleteMode;
