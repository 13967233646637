import sagas from '../sagas';
import Immutable from 'immutable';
import reducer from '../reducers';
import createSagaMiddleware from 'redux-saga';
import {createStore, applyMiddleware, compose} from 'redux';
import {oauthMiddleware, loggerMiddleware} from '../middleware';

const handleSagaError = (error) => {
  console.error('Error logged by Saga error handler:', error);
};

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware({onError: handleSagaError});

  const middleWares = [oauthMiddleware, sagaMiddleware, loggerMiddleware];

  let getComposeEnhancers = () => {
    if (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) {
      return compose(
        applyMiddleware(...middleWares),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      );
    }

    return compose(applyMiddleware(...middleWares));
  };

  let store = createStore(reducer, Immutable.Map(), getComposeEnhancers());

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(reducer);
    });
  }

  sagaMiddleware.run(sagas);

  return store;
};


export default configureStore;
