import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import Immutable from 'immutable';
import sagas from '../sagas';
import reducer from '../reducers';
import {oauthMiddleware} from '../middleware';

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  let middleWares = [oauthMiddleware, sagaMiddleware];

  let store = createStore(
    reducer,
    Immutable.Map(),
    applyMiddleware(...middleWares)
  );

  sagaMiddleware.run(sagas);
  return store;
};

export default configureStore;
