import classNames from 'classnames';
import React, {PropsWithChildren} from 'react'


type TabProps = {
    children: any; 
    className?: string;
    id?: string;
}  

const Tab: React.FC<PropsWithChildren<TabProps>> = ({className, children, id}) => {
    return <div className={classNames(className, 'bg-#EFF4F9')} id={id}>
       {children}
    </div>
}

export default Tab;