import {CHANGE_NODE_ANSWERS, SET_NODE_ANSWERS } from 'actions/answers/answersActionTypes';
import {fromJS, Map} from 'immutable';

export default (state = Map(), action) => {
  switch (action.type) {
    case SET_NODE_ANSWERS:
      return state.withMutations(state => {
        action.data.forEach(node => {
          if (node.total >= 0) {
            //all nodes but previews
            state.set(node.id, fromJS({correct: node.correct, studied: node.studied, total: node.total}));
          } else {
            //previews
            state.set(node.id, fromJS({
              correct: node.correct,
              answered: node.answered
            }));
          }
        });
        return state;
      });
    case CHANGE_NODE_ANSWERS:
      return state.withMutations(state => {
        action.nodes.forEach(node => {
          const currentNode = state.get(node);
          if (currentNode) {
            state.setIn([node, 'correct'], currentNode.get('correct') + action.correctIncrease);
            state.setIn([node, 'studied'], currentNode.get('studied') + action.studiedIncrease);
          }
        });
        return state;
      });
    default:
      return state;
  }
};
