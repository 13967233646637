import React from 'react';
import MessageCode from './MessageQuotes';
import './MessageLoader.scss'



type MessageLoaderProps = {
}

const MessageLoader: React.FC<MessageLoaderProps> = () => {
  return <div className='bg-white fixed bottom-0 right-0 w-full h-full message-loader-main'>
    <div className='justify-center items-center full-height flex flex-col w-auto pr-45px pl-45px'>
      <MessageCode />
    </div>
  </div>
}

export default MessageLoader