import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { currentFlashcardId } from 'selectors/studying';
import { activeSearchTerm } from 'selectors/searchSelectors';
import { chnageTeachingTag, getJWTToken } from 'helpers';
import { activeSubjectId, getActiveSubject, subjectsTeachingLevel } from 'selectors/subject/subjectSelector';
import { searchFolders } from 'actions/api';
import usePrevious from 'hooks/usePrevious';
import { getFoldersForFlashcard } from 'actions/desk';
import { apiRequestData, foldersSearchResponse } from 'selectors/api/apiSelector';
import { MainSeparator } from 'ui-components/pageComponents';
import SearchResultsHeader from 'pages/search/SearchResultsHeader';
import FolderListItem from 'v2/pages/desk/folders/FolderListItem';
import { FoldersIcon } from 'icons/FlashcardIcons';
import NoResults from 'ui-components/search-results/NoResults';
import { setActiveSearchTab, setGoBackToSearch } from 'actions/search';
import LoadMoreButton from 'v2/ui-components/LoadMoreButton';

type FoldersResultsListContainerProps = {
  hidden?: boolean;
  isPartial?: boolean;
}

const FoldersResultsListContainer: React.FC<FoldersResultsListContainerProps> = ({ hidden = false, isPartial = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchTerm = useSelector(activeSearchTerm);
  const currentId = useSelector(currentFlashcardId);
  const subjectsTlvl = useSelector(subjectsTeachingLevel);
  const subjectId = useSelector(activeSubjectId);
  const folders = useSelector(foldersSearchResponse).toJS();
  const subject = useSelector(getActiveSubject)
  let flashcardFolders = useSelector(state => apiRequestData(state, 'flashcardFolders'))
  flashcardFolders = flashcardFolders && flashcardFolders.toJS()
  const prevSearchTerm = usePrevious(searchTerm);
  const prevFCId = usePrevious(currentId);
  const APJWTToken = getJWTToken();

  useEffect(() => {
    searchTerm && startSearch(0);
    currentId && dispatch(getFoldersForFlashcard(currentId))
  }, [])

  useEffect(() => {
    if (prevSearchTerm !== searchTerm) {
      startSearch(0);
    }
  }, [searchTerm, prevSearchTerm])

  useEffect(() => {
    if (prevFCId !== currentId) {
      dispatch(getFoldersForFlashcard(currentId));
    }
  }, [currentId, prevFCId])

  const startSearch = (page: number) => {
    const teachingTag = subjectsTlvl[`${subjectId}`];
    const APTeachingTag = chnageTeachingTag(teachingTag);
    dispatch(searchFolders({
      searchField: searchTerm,
      subjectId: subjectId,
      page: page,
      limit: isPartial ? 3 : 10,
      jwt: APJWTToken,
      tag: APTeachingTag,
      ...(subject?.tagging === 'NEW_SUBJECT' && {'folder-teaching-level' : teachingTag})
    }))
  }

  const onListItemClick = (folderId: string) => {
    dispatch(setGoBackToSearch(true));
    history.push(`/flashcard/desk/folders/${folderId}`);
  }
  if (hidden) return null;

  const requestSucceeded = folders.succeeded;
  const requestLoading = folders.loading;
  const requestData = folders.data;
  const newListItems = requestData ?? [];
  const hasData = newListItems.length > 0;
  if (requestSucceeded && !isPartial && !hasData) return <NoResults />;

  return <>
    <SearchResultsHeader show={hasData} title={'Folders'} icon={<FoldersIcon color={'#2D3838'} />} showMore={isPartial} total={folders.count} onClick={() => dispatch(setActiveSearchTab('Folders'))} />
    {newListItems.map((folder: any, index: number) => <FolderListItem
      key={index}
      flashcardFolders={flashcardFolders}
      folder={folder}
      onClick={() => onListItemClick(folder.id)}
      shouldMark={true}
    />
    )}
    <LoadMoreButton selector={folders} onClick={() => startSearch(folders.page + 1)} hide={isPartial} />
    {!requestLoading && isPartial && hasData && <MainSeparator />}
  </>
}

export default FoldersResultsListContainer