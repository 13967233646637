import React, {PureComponent} from 'react';
import Mark from 'mark.js';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {activeSearchTerm, flashcardSearchResults, searchIndex} from 'selectors';

class MarkText extends PureComponent {
  constructor(props) {
    super(props);

    this.markWrapperRef = React.createRef();
  }

  componentDidMount() {
    const {activeSearchTerm, shouldMark} = this.props;

    if (activeSearchTerm && shouldMark) {
      setTimeout(() => {
        this.updateMark(activeSearchTerm);
        this.highlightElements();
      }, 0);
    }
  }

  getMarkInstance() {
    return new Mark(this.markWrapperRef.current);
  }

  componentDidUpdate(prevProps) {
    if (this.props.shouldMark !== prevProps.shouldMark && !this.props.shouldMark) {
      this.getMarkInstance().unmark();

      const highlightedElements = this.getHighlightedElements();
      for (let i = 0; i < highlightedElements.length; i++) {
        const element = highlightedElements[i];
        if (element.nodeName !== 'rect') continue;
        try {
          element.parentNode.removeChild(element);
        } catch (e) {
          console.warn(e);
        }
      }
      return;
    }

    if (!this.props.shouldMark || !this.props.activeSearchTerm) return;

    if (prevProps.children !== this.props.children || prevProps.activeSearchTerm !== this.props.activeSearchTerm || prevProps.shouldMark !== this.props.shouldMark) {
      const {activeSearchTerm} = this.props;
      // setTimeout(() => {
        this.updateMark(activeSearchTerm);
        this.highlightElements();
      // }, 100);
    }
  }

  highlightElements() {
    const highlightedElements = this.getHighlightedElements();
   
    for (let i = 0; i < highlightedElements.length; i++) {
      const element = highlightedElements[i];
      if (i === this.props.selectedIndex) {
        element.classList.add('active');
      } else {
        element.classList.remove('active');
      }
    }
  }

  getHighlightedElements() {
    if (!this.markWrapperRef.current) return [];
    return this.markWrapperRef.current.querySelectorAll('.text-highlighting');
  }

  componentDidCatch(error) {
    console.warn('catch', error);
  }

  updateMark = (textToMark) => {
    const options = {
      element: 'mark',
      className: this.props.markType === 'span' ? `text-highlighting text-highlighting-span ${this.props.type}` : 'text-highlighting-mark',
      acrossElements: true,
      caseSensitive: false,
      separateWordSearch: false,
      exclude: [
        'tspan.skip',
        'span.text-highlighting',
        '.mathjax-formula',
        '.mathjax-formula *',
        '.mjx-char'
      ],
      each: (node) => {
        const parent = node.parentNode;
        switch (parent.nodeName) {
          case 'text': {
            const text = node.textContent;
            parent.replaceChild(document.createTextNode(text), node);

            if (this.hasRect(parent)) return;

            const rect = this.createRect(parent);
            if (parent.getAttribute('transform')) {
              rect.setAttribute('transform', parent.getAttribute('transform'));
            }

            parent.parentNode.insertBefore(rect, parent);
            break;
          }
          case 'tspan': {
            const text = node.textContent;
            parent.replaceChild(document.createTextNode(text), node);

            if (this.hasRect(parent.parentNode)) return;

            const rect = this.createRect(parent);
            if (parent.parentNode.getAttribute('transform')) {
              rect.setAttribute('transform', parent.parentNode.getAttribute('transform'));
            }

            parent.parentNode.parentNode.insertBefore(rect, parent.parentNode);

            break;
          }
        }
      }
    };

    try {
      const markInstance = this.getMarkInstance();

      markInstance.unmark({done: () => {
        markInstance.mark(textToMark, options);

        if(textToMark.indexOf("’") != -1){
          markInstance.mark(textToMark.replace("’", "'"), options);
        }
        if(textToMark.indexOf("‘") != -1){
          markInstance.mark(textToMark.replace("‘", "’"), options);
        }
        if(textToMark.indexOf("'") != -1){
          markInstance.mark(textToMark.replace("'", "’"), options);
        }  
      }});
    } catch (e) {
      console.warn(e);
    }
  };

  hasRect(node) {
    return node.previousElementSibling
      && node.previousElementSibling.nodeName === 'rect'
      && node.previousElementSibling.getAttribute('class')
      && node.previousElementSibling.getAttribute('class').indexOf('text-highlighting') >= 0;
  }

  createRect(node) {
    const svgRect = node.getBBox();
    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    rect.setAttribute("x", svgRect.x - 1);
    rect.setAttribute("y", svgRect.y);
    rect.setAttribute("rx", '4');
    rect.setAttribute("ry", '10');
    rect.setAttribute("width", svgRect.width + 2);
    rect.setAttribute("height", svgRect.height);
    rect.setAttribute("class", "text-highlighting text-highlighting-rect");
    return rect;
  }

  render() {
    return (<div className={this.props.className} ref={this.markWrapperRef}>
      {this.props.children}
    </div>);
  }
}

const mapStateToProps = (state) => ({
  activeSearchTerm: activeSearchTerm(state),
  flashcardSearchResults: flashcardSearchResults(state),
  searchIndex: searchIndex(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MarkText);


MarkText.propTypes = {
  children: PropTypes.node.isRequired,
  activeSearchTerm: PropTypes.string,
  markType: PropTypes.oneOf(['mark', 'span']),
  shouldMark: PropTypes.any,
  selectedIndex: PropTypes.number,
};

MarkText.defaultProps = {
  markType: 'mark',
  shouldMark: true
};
