import React from 'react'
import './accountsPortal.scss'
import UserAvatarContainer from 'v2/components/avatar/UserAvatarContainer';
import Text from 'v2/components/common/Text';


type AccountsListItemsProps = {
    logo?: React.ReactElement | string;
    title:  {name: string, fontWeight: string}; 
    description: {text: string, color: string};
    role?: string | null; 
    onClick: () => void;
}  

const AccountsListItems: React.FC<AccountsListItemsProps> = ({logo=null, description, title, role, onClick}) => {
    return <div className="mb-list">
        <div className='account-prev-list' onClick={onClick}>
            <div className='user-img'>
                {typeof logo === 'string' ? 
                    role === "OSC" ? logo : <img src={logo} alt="" width={"100%"} />
                    : 
                    <UserAvatarContainer size={32} alternate accountPortal={true}/>
                }
            </div>
            <div className='h-8 leading-4'>
                <Text className={`txt-color text-[#333333] text-12px ${title.fontWeight}`} >
                    {title.name} 
                </Text>
                <Text className={`txt-color ${description.color} text-11px font-normal`}>{description.text}</Text>
            </div>
        </div>
    </div>
}

export default AccountsListItems;