import makeAction from 'actions/makeAction';
import {
  GET_SELECTED_FLASHCARDS,
  OPEN_NODES,
  SELECT_FLASHCARDS,
  SET_ACTIVE_NODES,
  SET_TREE_NODES,
  SET_EXERCISE_TREE_NODES,
  TOGGLE_NODE_SELECTION,
  TOGGLE_SUBTOPIC_VISIBILITY,
  TOGGLE_TOPIC_VISIBILITY,
  SELECT_ACTIVE_TOPIC_ID,
  SELECT_ACTIVE_SUB_TOPIC_ID,
  SET_FILTERED_EXERCISE_FLASHCARD_IDS,
  UPDATE_EXERCISE_NUMBER
} from 'actions/topics-tree/topicsTreeTypes';

export const toggleTopicVisibility = makeAction(TOGGLE_TOPIC_VISIBILITY, 'id', 'parentIds');
export const toggleSubtopicVisibility = makeAction(TOGGLE_SUBTOPIC_VISIBILITY, 'id', 'parentIds');
export const toggleNodeSelection = makeAction(TOGGLE_NODE_SELECTION, 'id', 'parentIds');
export const setActiveNodesAction = makeAction(SET_ACTIVE_NODES, 'id', 'parentIds');
export const setTreeNodes = makeAction(SET_TREE_NODES, 'parentIds', 'data');
export const setExerciseTreeNodes = makeAction(SET_EXERCISE_TREE_NODES, 'parentIds', 'data');
export const openNodesAction = makeAction(OPEN_NODES, 'previewId', 'parentIds', 'mapAnswers');
export const getSelectedFlashcards = makeAction(GET_SELECTED_FLASHCARDS, 'exerciseId');
export const selectFlashcards = makeAction(SELECT_FLASHCARDS, 'flashcardIds');

export const setActiveTopicId = makeAction(SELECT_ACTIVE_TOPIC_ID, 'topicId');
export const setActiveSubTopicId = makeAction(SELECT_ACTIVE_SUB_TOPIC_ID, 'subtopicId');

export const setFilteredExerciseFlashcardIds = makeAction(SET_FILTERED_EXERCISE_FLASHCARD_IDS, 'data');
export const updateExerciseNumber = makeAction (UPDATE_EXERCISE_NUMBER,'exerciseId')