import {selectNextFlashcard, selectPrevFlashcard} from 'actions/carousel/carouselActions';
import {CardFlipFlashcard } from 'icons/FlashcardIcons';
import * as Actions from 'actions/index';
import {changeFlashcard} from 'actions/studying';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {
  carouselCurrentFlashcard,
  hasNextFlashcard,
  hasPrevFlashcard
} from 'selectors/carouselSelectors';
import styled from 'styled-components/macro';
import {Hidden} from 'ui-components/helpers/common';
import * as Selectors from 'selectors';

class FlashcardControlsContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
          prevClick: false,
          nextClick: false,
          flipClick: false,
            // showAllActionButtons: true,
        };
    }
    // componentDidMount(){
    //     setTimeout(() => {
    //         this.setState({showAllActionButtons: false})
    //     }, 5000)
    // }
    handleOnActionButtonClick = (actionName, callback) => {
        this.setState({[actionName]: true}, () => {
            setTimeout(() => {
                this.setState({[actionName]: false}, () => {
                     callback && callback();
                });
            }, 200)
        })
    }
    canFlip() {  
        if(this.props.activeMediaTab === "flashcard"){
            if(this.props.canEditExercise){
                return this.props.listFlashcardContent && this.props.listFlashcardContent.getIn(['outsideTemplate', 'sides']).size > 1;
            }else{
                return this.props.flashcardContent && this.props.flashcardContent.size && this.props.flashcardContent.getIn(['outsideTemplate', 'sides']) &&  this.props.flashcardContent.getIn(['outsideTemplate', 'sides']).size > 1;
            }
        }
    }
    
    waveAnimation(e) {
        const bountValue = e.target.getBoundingClientRect();
        let x = e.clientX - bountValue.left;
        let y = e.clientY - bountValue.top;
        let rippleDiv = document.createElement("div");
        rippleDiv.classList.add('ripple');
        rippleDiv.setAttribute("style","top:"+y+"px; left:"+x+"px;");
        e.target.appendChild(rippleDiv);
        setTimeout(function(){
            rippleDiv.parentElement.removeChild(rippleDiv);
          }, 200);
    }
    render() {
        const canFlip = this.canFlip();
        const {isMobile } = this.props;
        return (
            <StyledButtonContainer className='buttons-container' displayStyle={this.props.displayStyle}>
                {!this.props.canEditExercise ?  
                    <React.Fragment>
                        <div className={`flashcard-button-control left ${this.props.hasPrev ? 'active' : ''}`} 
                            onClick={(e) => this.handleOnActionButtonClick("prevClick", () => this.props.onActionFlashcard("prevClick", true, this.props.prevFlashcard, this.props.hasPrev)) } 
                            onMouseOver={(e) => isMobile  ? null : this.props.onActionFlashcard("prevHover", true)} 
                            onMouseMove={(e) => isMobile  ? null : this.props.onActionFlashcard("prevHover", true)} 
                            onMouseLeave={(e) => isMobile ? null : this.props.onActionFlashcard("prevHover", false)}
                            >
                            
                        {/* {(this.props.hasPrev && (isMobile && isLandscape)) ? (
                            <Hidden when={!(this.state.prevClick)}>
                                <div className='prev-flashcard-button flashcard-nav-button tutorial-step-flashcard'
                                onClick={this.props.prevFlashcard}>
                                <CardTriangleLeft/>
                                </div>
                            </Hidden>
                        ) : (null)} */}
                        </div>
                        <div className={`flashcard-button-control-center ${canFlip ? 'active' : ''}`}
                            //onClick={() => this.props.onActionFlashcard("flipClick", true, this.props.nextSide, canFlip)} 
                            onClick={(e) => this.handleOnActionButtonClick("flipClick", () => this.props.onActionFlashcard("flipClick", true, this.props.nextSide, canFlip)) } 
                            onMouseOver={(e) => isMobile   ? null : this.props.onActionFlashcard("flipHover", true)} 
                            onMouseMove={(e) => isMobile   ? null : this.props.onActionFlashcard("flipHover", true)} 
                            onMouseLeave={(e) => isMobile  ? null : this.props.onActionFlashcard("flipHover", false)}>
                        {/* {(canFlip && (isMobile && isLandscape)) 
                            ?  <Hidden when={!(this.state.flipClick)}> <CardFlipFlashcard/> </Hidden>: null} */}
                        </div>
                        <div className={`flashcard-button-control right ${this.props.hasNext ? 'active' : ''}`} 
                           // onClick={() => this.props.onActionFlashcard("nextClick", true, this.props.nextFlashcard, this.props.hasNext) } 
                            onClick={(e) => this.handleOnActionButtonClick("nextClick", () => this.props.onActionFlashcard("nextClick", true, this.props.nextFlashcard, this.props.hasNext)) } 
                            onMouseOver={(e) => isMobile   ? null : this.props.onActionFlashcard("nextHover", true)} 
                            onMouseMove={(e) => isMobile   ? null : this.props.onActionFlashcard("nextHover", true)} 
                            onMouseLeave={(e) => isMobile  ? null : this.props.onActionFlashcard("nextHover", false)}>
                        {/* {(this.props.hasNext  && (isMobile && isLandscape) ) ? (
                            <Hidden when={!(this.state.nextClick)}>
                                <div className='next-flashcard-button flashcard-nav-button' onClick={this.props.nextFlashcard}  >
                                <CardTriangleRight/>
                                </div>
                            </Hidden>
                        ) : null} */}
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                            <div className={`flashcard-button-control-center ${canFlip ? 'active' : ''}`} 
                            //onClick={() => this.props.onActionFlashcard(canFlip)} 
                            onClick={(e) => this.handleOnActionButtonClick("flipClick", () => this.props.onActionFlashcard(canFlip)) } 
                            >
                        {canFlip ? <Hidden when={!(this.state.flipClick)}> <CardFlipFlashcard/> </Hidden>: null}
                        </div>
                    </React.Fragment>
                   
                 }

            {/* <NavigationWrapper>
            <SearchNavigationContainer wide/>
            </NavigationWrapper> */}
        </StyledButtonContainer>
        );
       
    }
}

function mapStateToProps(state, ownProps) {
  return {
    flashcardContent: carouselCurrentFlashcard(state),
    hasNext: hasNextFlashcard(state),
    hasPrev: hasPrevFlashcard(state),
    listFlashcardContent: Selectors.Studying.getFlashcardById(state, ownProps.listFlashcardId),
    isMobile: Selectors.isMobile(state),
    isLandscape: Selectors.isLandscape(state),
    activeMediaTab:Selectors.getActiveMediaTab(state)
  };
}

const mapDispatchToProps = {
  changeFlashcard,
  nextSide: Actions.Studying.nextSide,
  nextFlashcard: selectNextFlashcard,
  prevFlashcard: selectPrevFlashcard,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlashcardControlsContainer);

const StyledButtonContainer = styled.div`
    display: ${props => props.displayStyle ? 'none !important ' : 'flex !important'};
`;
