import React, { useEffect, useRef, useState } from 'react';
import './folderPage.scss';
import classNames from 'classnames';
import { isMobile, isLandscape } from 'selectors/browser/browserSelector';
import { getUserId, isMBFromSchool, isOSCUser } from 'selectors/user';
import { isRequestInProgress, apiRequestData, getTopicIdByTeachingLevel, isApiRequestInProgress } from 'selectors/api/apiSelector';
import { folderTitle, flashcardsInFolder, listOfFilterCheckedItems, examsofFilterItems } from 'selectors/desk/deskSelector';
import { getFlashcardById, currentFlashcardId } from 'selectors/studying';
import { isDeskEditModeActive, getActiveMediaTab, isExamsMode, apiWaitingResponse } from 'selectors/navigation/navigationSelector';
import { activeSubjectId, subjectsTeachingLevel, getActiveSubject, activeTeachingLevelTitle, } from 'selectors/subject/subjectSelector';
import { shouldGoBackToSearch } from 'selectors/searchSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { getLastMediaTypeVisited, teachingFilterResultsListToArray, disableMcqExamSubject, topicTeachingFilter, paperFilter, sourceFilter, difficultyFilter, smartFolders} from 'v2/helpers';
import { useHistory, useParams } from 'react-router-dom';
import { getFoldersForFlashcard, getFlashcardsInFolder, multipleDeleteFlashcardsFromFolder, selectAllFlashcardPreviewsFromFolder, getFolder } from 'actions/desk/deskActions';
import { getAllTopicsInSubject } from 'actions/api/apiActions';
import Loader, { BackgroundEnum, SizeEnum } from 'v2/components/loader.v2/Loader.v2';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import { EditIcon } from 'icons/HeaderIcons';
import EditButton from 'v2/components/Button/EditButton';
import AddFlashcardToFolderContainer from 'v2/pages/desk/folder/AddFlashcardToFolderContainer';
import AddFlashcardToBookmarkContainer from 'v2/pages/desk/folder/AddFlashcardToBookmarkContainer';
import FooterEditModeContainer from 'v2/components/footer-edit-mode/FooterEditModeContainer';
import MediaTypeTabSelector from 'v2/components/media-tab/MediaTypeTabSelector';
import ExamsFilterTile from "v2/components/filter-overlay/exams-filter-container/ExamsFilterTile";
import MCQFilterLayout from 'v2/components/filter-overlay/exams-filter-container/MCQFilterLayout';
import MircoFilterLayout from 'v2/components/studying/microFilterContainer/MicroFilterLayout';
import FlashcardResultsList from 'v2/ui-components/search-results/FlashcardResultsList';
import EditFolderPage from 'v2/pages/edit-folder/EditFolderPage';
import BackToSearchButton from 'v2/ui-components/BackToSearchButton';
import BackButton from 'v2/components/navigation-header/BackButton';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import { ReactComponent as NoFoldersIcon } from 'assets/icons/folders/no-folder-icon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import CancelEditButton from 'v2/components/Button/CancelEditButton';
import usePrevious from 'hooks/usePrevious';
import useAnswerStatus from 'hooks/useAnswerStatus';
import { questionTypeFind, transformResultsListToArray, examsPapergroupedList, returnExamAnswerFilter, filterResultsListToArray } from 'v2/helpers';
import EmptyScreenMessage from 'v2/components/empty-screen-message/EmptyScreenMessage';
import useScrollDetection from 'hooks/useScrollDetection';
import { setActiveFlashcardTab, toggleExamsMode } from 'actions/navigation';
import { ReactComponent as NoBookmarkIcon } from 'assets/icons/folders/no-bookmark-icon.svg';

type ParamsType = {
  id: string;
}
type FolderPageProps = {
  inTooltip?: boolean;
  folderId?: string;
  onListItemSelected?: () => void;
}

const FolderPage: React.FC<FolderPageProps> = ({ inTooltip, folderId, onListItemSelected }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let wrapperRef = useRef<HTMLDivElement>(null);
  const { isScrolled, bindScrollRef } = useScrollDetection({ WrappedRef: wrapperRef, threshold1: 20 });
  const { id } = useParams<ParamsType>();
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);
  const userId = useSelector(getUserId);
  const isFolderLoading = useSelector(state => isRequestInProgress(state, 'desk', 'getFolder'));
  const isFlashcardsLoading = useSelector(state => isRequestInProgress(state, 'desk', 'flashcardsInFolder'));
  const hasApiResponseLoading = useSelector(apiWaitingResponse)
  const title = useSelector(folderTitle);
  const currentId = useSelector(currentFlashcardId);
  const flashcardContent = useSelector(state => getFlashcardById(state, currentId)).toJS();
  const isEditing = useSelector(isDeskEditModeActive);
  const flashcardFolders = useSelector(state => apiRequestData(state, 'flashcardFolders'))?.toJS();
  const subjectId = useSelector(activeSubjectId);
  const activeSubject = useSelector(getActiveSubject);
  const activeMediaTab = useSelector(getActiveMediaTab);
  const flashcardPreviews = useSelector(flashcardsInFolder)?.toJS();
  const teachingLevel = useSelector(subjectsTeachingLevel);
  const hasExamsMode = useSelector(isExamsMode);
  const filterCheckedItems = useSelector(listOfFilterCheckedItems).toJS();
  const getexamsofFilterItems = useSelector(examsofFilterItems).toJS();
  const hasMBFromSchool = useSelector(isMBFromSchool);
  const hasOSCUser = useSelector(isOSCUser)
  const hasGoBackToSearch = useSelector(shouldGoBackToSearch);
  const topicIdByTeachingLevel = useSelector(getTopicIdByTeachingLevel);
  const teachingLevelTitle = useSelector(activeTeachingLevelTitle);
  const inProgressflashcardFolders = useSelector(state => isApiRequestInProgress(state, 'flashcardFolders'));
  const prevTeachingLevelTitle = usePrevious(teachingLevelTitle);
  const prevActiveMediaTab = usePrevious(activeMediaTab);
  const prevSubjectId = usePrevious(subjectId);
  const prevCurrentId = usePrevious(currentId);
  const prevTeachingLevel = usePrevious(teachingLevel);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const isMobilePortrait = !(hasMobile && hasLandscape);
  const getFolderId = folderId || id;
  const mediaType = getLastMediaTypeVisited(userId)
  const teachingTag = subjectId && teachingLevel[subjectId];
  const flashcards = flashcardPreviews && teachingTag && activeSubject && teachingFilterResultsListToArray(flashcardPreviews, teachingTag, activeSubject);
  const data = flashcards && sourceFilter(flashcards, getexamsofFilterItems.source, activeMediaTab);
  const paperFilterData = flashcards && paperFilter(data, getexamsofFilterItems.paper, activeMediaTab);
  const teachingTagData = flashcards && topicTeachingFilter(teachingTag, paperFilterData, getexamsofFilterItems.teachingLevel, "exams", activeMediaTab);
  const multipleChooseData = activeMediaTab === 'exams' && flashcards.length > 0 && questionTypeFind(teachingTagData)?.mcq;
  const freeResponseData = activeMediaTab === 'exams' && flashcards.length > 0 && questionTypeFind(teachingTagData)?.group;
  const examAnswerFilter = activeMediaTab === 'exams' && flashcards && returnExamAnswerFilter(multipleChooseData, freeResponseData, getexamsofFilterItems.mcq, filterCheckedItems[activeMediaTab], activeMediaTab, teachingTagData);
  const difficultyData = flashcards && difficultyFilter(examAnswerFilter, getexamsofFilterItems.difficulty, activeMediaTab);
  const sortData = activeMediaTab === 'exams' && difficultyData.length > 0 && difficultyData.sort((a: any, b: any) => {
    const indexA = a.numbering;
    const indexB = b.numbering;
    if (indexA < indexB) return -1;
    return 1;
  });
  const examsModeFilter = activeMediaTab === 'exams' ? (hasExamsMode ? examAnswerFilter : sortData) : flashcards;
  const newFilterItems = flashcards && filterResultsListToArray(examsModeFilter, activeMediaTab, filterCheckedItems[activeMediaTab]);
  const isLoading = hasApiResponseLoading || isFlashcardsLoading

  const filterSize = flashcards?.length === 0 ? false : filterCheckedItems?.[activeMediaTab]?.length !== 0;
  useEffect(() => {
    dispatch(setActiveFlashcardTab(3));
    if (currentId) {
      dispatch(getFoldersForFlashcard(currentId, mediaType));
    }
    dispatch(getFlashcardsInFolder(getFolderId, isSmart(), 0, isSmart()));
    if (!isSmart()) {
      dispatch(getFolder(getFolderId));
    }
    if (hasMobile && !inTooltip) {
      window.scrollTo(0, 0);
    }
    if (typeof topicIdByTeachingLevel == 'undefined') {
      dispatch(getAllTopicsInSubject({
        key: activeSubject.id,
        subjectId: activeSubject.id,
        mapAnswers: true
      }));
    }
    if (!(hasOSCUser || hasMBFromSchool)) {
      dispatch(toggleExamsMode(true));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (prevTeachingLevelTitle !== teachingLevelTitle && isSmart()) {
      dispatch(getFlashcardsInFolder(getFolderId, true, 0, true));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teachingLevelTitle, prevTeachingLevelTitle])

  useEffect(() => {
    if (activeMediaTab !== prevActiveMediaTab) {
      dispatch(getFlashcardsInFolder(getFolderId, isSmart(), 0, isSmart()));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMediaTab, prevActiveMediaTab])

  useEffect(() => {
    if (prevSubjectId && prevSubjectId !== subjectId) {
      history.push('/flashcard/desk/folders');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectId, prevSubjectId])

  useEffect(() => {
    if (currentId !== prevCurrentId) {
      dispatch(getFoldersForFlashcard(currentId, mediaType));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId, prevCurrentId])

  useEffect(() => {
    if (activeMediaTab === "exams") {
      if (currentId) {
        dispatch(getFoldersForFlashcard(currentId, mediaType));
      }
      dispatch(getFlashcardsInFolder(getFolderId, isSmart(), 0, isSmart()));
      if (!isSmart()) {
        dispatch(getFolder(getFolderId));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teachingLevel, prevTeachingLevel])

  const isSmart = () => {
    if (getFolderId === "bookmark") {
      return 1;
    } else {
      return isNaN(parseInt(getFolderId));
    }
  }
  const setAnswerStatus = () => [dispatch(getFlashcardsInFolder(getFolderId, isSmart(), 0, isSmart())), history.push(`/flashcard/desk/folders/${getFolderId}`)]
  const { filterFlashcardId } = useAnswerStatus(setAnswerStatus);
  const renderFolderContents = (newFilterItems: any) => {
    if (isFolderLoading || isLoading) {
      return null;
    }
    return <FlashcardResultsList newListItems={(activeMediaTab === "exams" && hasExamsMode) ? examsPapergroupedList(newFilterItems) : transformResultsListToArray(newFilterItems)} shouldMark={hasGoBackToSearch} filterFlashcardId={filterFlashcardId} />;
  };

  return <div className='h-full overflow-y-scroll'>
    {!isEditMode && <NavigationHeader collapsedTitle={isSmart() ? <AddFlashcardToBookmarkContainer flashcardFolders={flashcardContent.bookMark} isFolderPage={false} /> : !isEditing ? <AddFlashcardToFolderContainer folderId={getFolderId} flashcardFolders={flashcardPreviews} /> : null}
      isCollapsed={isScrolled}
      extendedWidth={!isEditing}
      leftSection={isEditing ? <CancelEditButton /> : <BackToSearchButton alternate={() => <BackButton linkTo='/flashcard/desk/folders' text='Folders' />} />}
      rightSection={(!isSmart() || isSmart() === 1) && (flashcardPreviews.length > 0 ? <EditButton className={"text-blue-normal cursor-pointer"} /> : <div className='font-bold text-[13px] text-right tracking-[2px] uppercase text-#C8CCCC pointer-events-none'>Edit</div>)}
      extendedHeight
      />}
    <div className={classNames('flex flex-col pt-14 pb-[150px] h-full overflow-auto scroll-smooth', { 'pt-0': inTooltip })} ref={bindScrollRef}>
      {isEditMode && <EditFolderPage toggleEditMode={() => setEditMode(!isEditMode)} folderTitle={title} folderId={getFolderId} currentFolderEdit={true} />}
      {!(inTooltip || !isMobilePortrait) && <>
        {!isEditMode ? <div className='pt-0.5 px-5 pb-0 font-bold text-[25px] justify-left leading-[1.27] flex items-center'>
          {isSmart() ? <div className='capitalize text-[25px] leading-[1.27] text-#2D3838'>Bookmarked</div> :
            <div className='flex cursor-pointer text-[25px] leading-[1.27] font-bold text-#2D3838 group' onClick={() => setEditMode(true)}>
              {isFolderLoading ? <Loader size={SizeEnum.MeduiumSmall} background={BackgroundEnum.Blue} /> : title}
              {!isFolderLoading && <div className="edit-icon-wrapper cursor-pointer ml-2.5 hidden group-hover:block group-hover:visible"><EditIcon /></div>}
            </div>}
          {isSmart() ? <div className={classNames('ml-auto flex items-center justify-center', { 'min-h-[38px]': !currentId })}><AddFlashcardToBookmarkContainer flashcardFolders={flashcardContent.bookMark} isFolderPage={false} /></div> :
            (!isEditing && currentId && !isFolderLoading) ? <div className='ml-auto flex items-center justify-center cursor-pointer'> {inProgressflashcardFolders ? <Loader size={SizeEnum.VSmall} background={BackgroundEnum.Blue} /> : <AddFlashcardToFolderContainer addButtonVisible={1} folderId={getFolderId} flashcardFolders={flashcardFolders} />}</div> : null}
        </div> : <></>}
      </>}
      {!(hasMobile && hasLandscape) && <div className={classNames({ 'pb-3': isEditMode, 'pb-3.5': !isEditMode })} />}
      <div className={classNames({ 'opacity-50 pointer-events-none select-none':isEditMode })}>
        <MediaTypeTabSelector subjectId={subjectId} />
      </div>
      {activeMediaTab === 'exams' && flashcards.length > 0 && !(isFolderLoading || isLoading) && <ExamsFilterTile isEditMode={isEditMode} type={"folder"} />}
      {(flashcards.length > 0 && !(isFolderLoading || isLoading)) && <>
        {activeMediaTab === 'exams' ? <div className='pt-6 px-5 flex items-center'>
          {!disableMcqExamSubject(activeSubject.id) && <div className='pr-2'><MCQFilterLayout isEditMode={isEditMode} formCC={false}/></div>}
          <MircoFilterLayout page={"exercise"} isEditMode={isEditMode} />
        </div> : <MircoFilterLayout className='mt-3.5' isEditMode={isEditMode} />}
      </>}
      <div className={classNames('flex-1 pt-6', { 'opacity-50 pointer-events-none select-none':isEditMode})}>
        {newFilterItems.length > 0 ? renderFolderContents(newFilterItems) : (isFolderLoading || isLoading) || (isSmart() && newFilterItems.length > 0) ? null :
          <EmptyScreenMessage 
            className={inTooltip ? 'without-margin empty-folder-screen' : 'empty-folder-screen'}  
            type={isSmart() ? smartFolders[getFolderId]?.title : (filterSize) ? 'filterFolders' : 'folders'} 
            canvasType={mediaType} 
            mediaType={activeMediaTab} 
            currentId={currentId} 
            itemId={getFolderId} 
            isSmart={isSmart()}> 
            <>
            {isSmart() ? <NoBookmarkIcon /> : 
              (filterSize) ? <FilterIcon /> : 
              <NoFoldersIcon />}
            </>
            </EmptyScreenMessage>
        }
        {(isFolderLoading || isLoading) && (<LeftMenuLoader />)}
      </div>
    </div>
    <FooterEditModeContainer onDelete={() => dispatch(multipleDeleteFlashcardsFromFolder(getFolderId, mediaType))} folderEditMode={isEditMode} itemsCount={newFilterItems.length} onSelectAll={() => dispatch(selectAllFlashcardPreviewsFromFolder())} />
  </div>
}

export default FolderPage