import {openFreeTrialNotification, toggleOnboardingOverlay} from 'actions/onboarding/onboardingActions';
import {FREE_TRIAL_NOTIFICATION_CLOSE, START_ONBOARDING} from 'actions/onboarding/onboardingTypes';
import {getFromStorage, saveToStorage} from 'helpers';
import {takeLatest, all, select, put, call} from 'redux-saga/effects';
import {delay} from 'redux-saga/effects';
import {getUserId} from 'selectors/user';
import {watchRequest} from 'redux-saga-request/src';
import * as Api from 'api';
import {getFreeTrialStatusRoutine} from '../actions/onboarding/onboardingActions';

export function* watchers() {
  yield all([
    yield takeLatest(START_ONBOARDING, onStartOnboarding),
    yield takeLatest(FREE_TRIAL_NOTIFICATION_CLOSE, onCloseFreeTrialNotification),
    watchRequest(getFreeTrialStatusRoutine, Api.getFreeTrialStatus)
  ]);
}

function* onStartOnboarding(action) {
  try {
    const userId = yield select(getUserId);
    const ONBOARDING_STORAGE_KEY = 'smpp-hide-onboarding-' + userId;
    const shouldHideOnboarding = getFromStorage(ONBOARDING_STORAGE_KEY);
    const response = yield call(Api.addOnBoardingStatus);
    if (shouldHideOnboarding && !action.force) {
      return;
    }

    yield put(toggleOnboardingOverlay(true));
    saveToStorage(ONBOARDING_STORAGE_KEY, true);
  } catch (error) {
    console.error("error for onStartOnboarding", error)
  }
}

function* onCloseFreeTrialNotification() {
  try {
    yield delay(600000);
    yield put(openFreeTrialNotification());
  } catch (error) {
    console.error("error for onStartOnboarding", error)
  }  
}
