import React from 'react';
import './saveButton.scss'

type SaveButtonProps = {
  onClick?: () => void,
  disabled?: boolean,
  text?: string
}

const SaveButton: React.FC<SaveButtonProps> = ({ onClick, disabled, text }) => {

  return <button
    onClick={onClick} disabled={disabled} type="submit"
    className="save-button">
    {text || 'Save'}
  </button>

}

export default SaveButton