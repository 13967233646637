import React, { useEffect, useMemo } from "react"
import { menuPathSelector } from 'selectors/menuSelectors';
import { useSelector } from "react-redux"
import AddVoucherContainer from 'v2/pages/add-voucher/AddVoucherContainer';
import ManualPage from 'v2/pages/manual/ManualPage';
import MenuPage from 'v2/pages/menu/MenuPage';
import MorePage from 'v2/pages/more/MorePage';
import ProfilePageContainer from 'v2/pages/profile/ProfilePageContainer';
import AboutYouPageContainer from 'v2/pages/profile/about-you/AboutYouPageContainer';
import ChangePasswordContainer from 'v2/pages/profile/change-password/ChangePasswordContainer';
import ChangeUsernameContainer from 'v2/pages/profile/change-username/ChangeUsernameContainer';
import ContactDetailsPageContainer from 'v2/pages/profile/contact-details/ContactDetailsPageContainer';
import SubscriptionsPage from 'v2/pages/subscriptions';
import './MenuRouter.scss'

const MenuRouter = () => {
  const menuPath = useSelector(menuPathSelector)

  useEffect(() => {
    const rootClass: HTMLElement | any = document.getElementById('root')
    rootClass.className = 'no-scroll';
    return () => {
      rootClass.className = '';
    }
  }, [])

  const renderPage = useMemo(() => {
    switch (menuPath) {
      case 'more':
        return <MorePage />;
      case 'profile':
        return <ProfilePageContainer />;
      case 'about-you':
        return <AboutYouPageContainer />;
      case 'manual':
        return <ManualPage />;
      case 'contact-details':
        return <ContactDetailsPageContainer />;
      case 'change-password':
        return <ChangePasswordContainer />;
      case 'subscriptions':
        return <SubscriptionsPage />;
      case 'change-username':
        return <ChangeUsernameContainer />;
      case 'add-voucher':
        return <AddVoucherContainer />;
      default:
        return <MenuPage />;
    }
  }, [menuPath])
  
  return <div className="menu-page">
    {renderPage}
  </div>
}

export default MenuRouter