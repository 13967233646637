import classNames from 'classnames';
import { PrevSearchButton, ClearSearchIcon, NextSearchButton } from 'icons/FlashcardIcons';

type SearchPrevButtonProps = {
  onClick: () => void;
  isPrevButton: boolean;
  isMobile?: boolean
}
type ClearSearchButtonProps = {
  onClick: () => void;
}
type SearchNextButtonProps = {
  onClick: () => void;
  isNextButton: boolean
}

export const SearchPrevButton: React.FC<SearchPrevButtonProps> = ({ onClick, isPrevButton, isMobile= false }) => {
  return <div className={classNames('w-9 h-9', { 'fixed left-[79px]': isMobile })}>
    {isPrevButton && <button className='styled-search-prev-button small-round-button w-9 h-9 m-0 bg-white rounded-full flex items-center justify-center' onClick={onClick}>
      <PrevSearchButton />
    </button>}
  </div>
}

export const ClearSearchButton: React.FC<ClearSearchButtonProps> = ({ onClick }) => {
  return <button className='clear-search-button small-round-button w-9 h-9 m-0 bg-white rounded-full flex items-center justify-center' onClick={onClick}>
    <ClearSearchIcon />
  </button>
};

export const SearchNextButton: React.FC<SearchNextButtonProps> = ({ onClick, isNextButton }) => {
  return <div className={classNames('w-9 h-9')} >
    {isNextButton && <button className='styled-search-next-button small-round-button w-9 h-9 m-0 bg-white rounded-full flex items-center justify-center' onClick={onClick}>
      <NextSearchButton />
    </button>}
  </div>
};