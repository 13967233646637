import React from 'react'
import ListFlashcardPreview from 'v2/components/list-flashcard-preview/ListFlashcardPreview';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveMediaTab, getExerciseOnCanvas, isDeskEditModeActive, isExamsMode } from 'selectors/navigation/navigationSelector';
import { currentFlashcardId } from 'selectors/studying';
import { listOfCheckedItems } from 'selectors/desk/deskSelector';
import { setExamsHeaderList, setExerciseOnCanvas, toggleExamsMode } from 'actions/navigation';
import { setActiveExerciseId } from 'actions/studying';
import { addBackFlashcardToLocalStorage, decodeQuery, setExamSubTopicId, setExamTopicId, setFormExams, setLastMediaTypeVisited, storeExamsParentId } from 'helpers';
import { selectNote, updateListOfItemsSelected } from 'actions/desk';
import { selectFlashcard } from 'actions/studying'
import { getUserId } from 'selectors/user';
import { useHistory } from 'react-router-dom';
import { groupByParam, sortArray } from 'v2/helpers';

type ListFlashcardPreviewContainerProps = {
  list: any;
  type: string;
}
const orderOfTeachingLevels = ['Studies SL', 'SL', 'HL', 'Option A | SL', 'Option B | SL', 'Option C | SL', 'Option D | SL', 'Option A | HL', 'Option B | HL', 'Option C | HL', 'Option D | HL', 'Teaching Level'];

const ListFlashcardPreviewContainer: React.FC<ListFlashcardPreviewContainerProps> = ({ list, type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeMediaTab = useSelector(getActiveMediaTab);
  const hasExamsMode = useSelector(isExamsMode);
  const hasDeskEditModeActive = useSelector(isDeskEditModeActive);
  const currentId = useSelector(currentFlashcardId);
  const getListOfcheckedItems = useSelector(listOfCheckedItems);
  const exerciseOnCanvas = useSelector(getExerciseOnCanvas);
  const userId = useSelector(getUserId);

  const onFlashcardCLick = (item: any, from: string) => {
    if (exerciseOnCanvas || typeof exerciseOnCanvas === "undefined" && !hasDeskEditModeActive) {
      dispatch(setExerciseOnCanvas(false));
      dispatch(setActiveExerciseId(null));
    }
    

    if (hasDeskEditModeActive) {
      dispatch(updateListOfItemsSelected(item?.flashcardId))
    } else {
      setLastMediaTypeVisited(activeMediaTab, userId);
      if (type === 'notes') {
        const query = decodeQuery(history.location.search.substring(1));
        if (item?.flashcardId !== currentId) {
          addBackFlashcardToLocalStorage(activeMediaTab + "-" + item?.flashcardId, query.mediaType + "-" + currentId);
        }
        let flashcardId = "";
        if (['flashcard', 'exams'].includes(activeMediaTab)) {
          flashcardId = item?.flashcardId;
        } else {
          flashcardId = item?.nodeId;
        }
        // dispatch(selectNote(flashcardId));
        dispatch(selectFlashcard(flashcardId))
        if (activeMediaTab === 'exams' && !hasDeskEditModeActive) {
          let type
          let parentId
          if (from) {
            type = 'topics';
            parentId = item?.topicId
          } else {
            type = 'exams'
            parentId = item?.experienceData?.paper_uuid
            !hasExamsMode && dispatch(toggleExamsMode(true));
          }
          dispatch(setExamsHeaderList({ "type": type, "parentId": parentId }));
          setExamTopicId(item?.topicId)
          setFormExams(type)
          storeExamsParentId(parentId)
          setExamSubTopicId(item?.subTopicId)
        }
        history.push('/flashcard/desk/notes/edit');
      }
    }
  }

  const groupedList = () => {
    const listToJS = list?.toJS()
    ?.sort((a: any, b: any) => {
            const indexA = orderOfTeachingLevels.indexOf(a?.teachingLevel);
            const indexB = orderOfTeachingLevels.indexOf(b?.teachingLevel);
            if (indexA < indexB)
              return -1;
            return 1;
          })

    const groupedListByTeachingLevel = groupByParam(listToJS, 'teachingLevel');
    const finalResult = Object.keys(groupedListByTeachingLevel).map(function(index){
      return groupByParam(groupedListByTeachingLevel[index], 'topicNumbering')
    });
    return finalResult
  }
  const examGroupedList = () => {

    const listToJS = list?.toJS().sort((a:any, b:any) => a?.examCategory > b?.examCategory ? 1 : -1)
    const sortedList = listToJS
    .sort((a:any, b:any) => {
      const indexA = orderOfTeachingLevels.indexOf(a?.examCategory);
      const indexB = orderOfTeachingLevels.indexOf(b?.examCategory);
      if (indexA < indexB) return -1;
      return 1;
    })
   
    const groupedListByExamCategory = groupByParam(sortedList, 'examCategory');
    const finalResult = Object.keys(groupedListByExamCategory).map(function(index){
      return sortArray(groupedListByExamCategory[index], 'exam')
    });
    const finalResultData = finalResult.map((item:any) => {
      return groupByParam(Object.values(item), 'exam')
    })
    return finalResultData;
  }

  return (
    <ListFlashcardPreview
      isExamsMode={hasExamsMode}
      list={(activeMediaTab === 'exams' && hasExamsMode) ? examGroupedList() : groupedList()}
      type={type}
      isEditing={hasDeskEditModeActive}
      onClick={(item: any, type: string) => onFlashcardCLick(item, type)}
      currentFlashcardId={currentId}
      listOfCheckedItems={getListOfcheckedItems} />
  );
}

export default ListFlashcardPreviewContainer