import React, {Component} from 'react';
import StoreComponent from './StoreComponent';
import * as Selectors from 'selectors';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Actions from 'actions';

class StoreContainer extends Component {
  UNSAFE_componentWillMount() {
    if (!this.props.activeStoreSubject) {
      // this.props.getSubjects();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (!this.props.activeStoreSubject && nextProps.activeStoreSubject) ||
      (this.props.activeStoreSubject && nextProps.activeStoreSubject && this.props.activeStoreSubject.get('id') !== nextProps.activeStoreSubject.get('id'))
    ) {
      // this.props.getSubjectPackages(nextProps.activeStoreSubject.get('id'));
    }
  }

  render() {
    return <StoreComponent {...this.props}/>;
  }
}

const mapStateToProps = (state) => ({
  // storeSubjects: Selectors.getStoreSubjects(state),
  // storeSubjectPackages: Selectors.getStoreSubjectPackages(state),
  inProgress: Selectors.isApiRequestInProgress(state, 'storeSubjects'),
  packagesInProgress: Selectors.isApiRequestInProgress(state, 'storeSubjectPackages'),
  activeStoreSubject: Selectors.getStoreActiveSubject(state),
  itemsInBasket: Selectors.itemsInBasket(state),
  packagesDescriptionProps: Selectors.getPackagesDescriptionProps(state),
  isBasketDetailsVisible: Selectors.isBasketDetailsVisible(state),
});
const mapDispatchToProps = {
  // getSubjects: Actions.Api.getStoreSubjects,
  toggleBasket: Actions.UserStore.toggleBasket,
  // getSubjectPackages: Actions.Api.getStoreSubjectPackages,
  addItemInPurchaseBasket: Actions.UserStore.addItemInPurchaseBasket,
  removeItemFromPurchaseBasket: Actions.UserStore.removeItemFromPurchaseBasket,
  setPackagesDescriptionProps: Actions.UserStore.setPackagesDescriptionProps,
  togglePackageDescription: Actions.UserStore.togglePackageDescription,
};

StoreContainer.propTypes = {
  // getSubjectPackages: PropTypes.func.isRequired,
  // getSubjects: PropTypes.func.isRequired,
  storeSubjects: PropTypes.object.isRequired,
  storeSubjectPackages: PropTypes.object.isRequired,
  inProgress: PropTypes.bool.isRequired,
  packagesInProgress: PropTypes.bool.isRequired,
  activeStoreSubject: PropTypes.object,
  itemsInBasket: PropTypes.object,
  isBasketDetailsVisible: PropTypes.bool,
  packagesDescriptionProps: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreContainer);

