import {
  getAllExternalExercisesRoutine,
  getExerciseDetails,
  getExercisePreviewsRoutine,
  getViewedExercisesRoutine
} from 'actions/api';
import { GET_EXERCISE_DETAILS_SUCCEEDED } from 'actions/api/apiTypes';
import {setExerciseData} from 'actions/exercises/exercisesActions';
import {GET_EXERCISE_DATA, START_NOTIFICATIONS_POLLING} from 'actions/exercises/exercisesActionTypes';
import {selectFlashcards} from 'actions/topics-tree/topicsTreeActions';
import {SET_TREE_NODES} from 'actions/topics-tree/topicsTreeTypes';
import {Map} from 'immutable';
import moment from 'moment';
import {delay} from 'redux-saga/effects';
import {all, call, put, select, take, takeLatest} from 'redux-saga/effects';
import {exerciseDetailsResponse, exercisePreviewsResponse, flashcardIdsResponse} from 'selectors';
import {userMBRoleSelector, isMBFromSchool} from 'selectors/user';

export function* watchers() {
  yield all([
    takeLatest(GET_EXERCISE_DATA, onGetExerciseData),
    takeLatest(START_NOTIFICATIONS_POLLING, onStartNotificationsPolling)
  ]);
}

function* onStartNotificationsPolling() {
  const mbUserRole = yield select(userMBRoleSelector);
  const mbFromSchool = yield select(isMBFromSchool);
  while (mbUserRole === 'STUDENT' && mbFromSchool === "YES") {
    try {
      yield put(getViewedExercisesRoutine());
      yield put(getAllExternalExercisesRoutine());
      //yield call(delay, 30000);
      yield delay(120000);
    } catch (e) {
      console.error(e);
    }
  }
}

function* onGetExerciseData(action) {
  try {
    yield all([
      put(getExerciseDetails({key: action.exerciseId})), put(getExercisePreviewsRoutine({id: action.exerciseId}))
    ]);

    yield all([
      take(GET_EXERCISE_DETAILS_SUCCEEDED),
      take(getExercisePreviewsRoutine.success.toString())
    ]);
    const exerciseDataPath = [action.exerciseId, 'data'];
    const exerciseResponse = yield select(exerciseDetailsResponse);
    const exerciseDetails = exerciseResponse.getIn(exerciseDataPath);
    const dueDate = exerciseDetails.get('dueDate');
    const exercisePreviews = yield select(exercisePreviewsResponse);
    let exerciseData = Map({
      title: exerciseDetails.get('title'),
      comment: exerciseDetails.get('comment')
    });

    if (dueDate) {
      const momentDueDate = moment(dueDate * 1000);
      const dueDateHour = momentDueDate.hour();
      const dueDateMinute = momentDueDate.minute();

      exerciseData = exerciseData.set('hour', dueDateHour)
        .set('minute', dueDateMinute);

      if (window.Modernizr.inputtypes.date) {
        exerciseData = exerciseData.set('dueDate', momentDueDate.format('YYYY-MM-DD'));
      } else {
        exerciseData = exerciseData.set('dueDate', momentDueDate);
      }
    }
    yield put(setExerciseData(action.exerciseId, exerciseData));

    const request = yield select(flashcardIdsResponse);
    if (request.get('loading')) {
      yield take(SET_TREE_NODES);
    }

    yield put(selectFlashcards(exercisePreviews.getIn(exerciseDataPath).map(item => item.get('flashcardId'))));
  } catch (error) {
    console.error("error for onGetExerciseData", error)
  }
}
