import { useEffect } from 'react';
import { getTopicsDetails } from 'actions/api';
import { topicsDetailsSelector } from 'selectors/api/apiSelector';
import { getActiveSubject } from 'selectors';
import { useSelector, useDispatch } from 'react-redux';
import RequestLoader from 'v2/components/loader.v2/RequestLoader';
import TopicHeader from 'v2/pages/topics/TopicHeader';


type TopicPageHeaderContainerProps = {
  typeId: string;
  activeType: string
}

const TopicPageHeaderContainer: React.FC<TopicPageHeaderContainerProps> = ({ typeId, activeType }) => {
  const dispatch = useDispatch();
  const activeSubject = useSelector(getActiveSubject);
  const topicDetailsResponse = useSelector(topicsDetailsSelector).toJS()

  useEffect(() => {
    if (!typeId) return;
    if (!activeType) return;
    dispatch(getTopicsDetails({ activeType: activeType, id: typeId }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeId, activeSubject])
  return <RequestLoader loader={<div />} request={topicDetailsResponse[typeId]}>
    <TopicHeader data={topicDetailsResponse[typeId]?.data} type={activeType} id={Number(typeId)} />
  </RequestLoader>
}


export default TopicPageHeaderContainer