import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getSubtopicsInTopic} from 'actions/api';
import LinkTopicTile from 'pages/link/LinkTopicTile';
import RawStatsBarsContainer from 'components/topics-tree/RawStatsBarsContainer';
import Row from 'ui-components/helpers/Row';
import styled from 'styled-components/macro';
import {activeSubjectId, isLinksNavigationSectionVisible, currentFlashcardId} from 'selectors';
import * as Actions from 'actions';
import {topicsTree} from 'selectors/topicsTreeSelector';
import {sortBySubTopic} from 'helpers';
import { isMobilePortrait } from 'selectors/browser/browserSelector';
import {
  addBackFlashcardToLocalStorage, decodeQuery
} from 'helpers';
import appHistory from 'appHistory';

class TopicLinksResultsList extends Component {
  onItemClick = (topicId, teachingLvl) => {
    const query = decodeQuery(appHistory.location.search.substring(1));
    const subjectId = this.props.activeSubjectId;
    const topicContents = this.props.topicsTree.getIn([subjectId, 'children', teachingLvl, 'children', topicId, 'children']);
    if (topicContents) {
      const subTopicId = topicContents.keySeq().first();
      const subTopicContents = this.props.topicsTree.getIn([subjectId, 'children', teachingLvl, 'children', topicId, 'children', subTopicId, 'children']);
      if (subTopicContents) {
        const firstFlashcard = subTopicContents.keySeq().first();
        if(!this.props.isLinksNavigationSectionVisible && this.props.isMobilePortrait){
          this.props.toggleLinksNavigationSection(false) 
        }
        if(firstFlashcard !== this.props.currentFlashcardId){
          addBackFlashcardToLocalStorage("flashcard"+"-"+firstFlashcard, query.mediaType+"-"+this.props.currentFlashcardId);
        }
        this.props.selectFlashcard(firstFlashcard);
      }
    }
  }

  render() {
    const {newListItems} = this.props;
    return newListItems.map((topics, i) => {
        return (
          <div key={i}>
            {this.renderTopicPreview(topics)}
          </div>
        );
    });
  }

  renderTopicPreview(topics) {
    return topics.sortBy(item => item.get('label')).map((item, index)=> {
        const topicId = item.get('id');
        const teachingLvl = item.get('tlvlId');
        return <StyledRow key={index}>
          <LinkTopicTile
            topicData={item}
            topicId={topicId}
            onSelect={() => this.onItemClick(topicId, teachingLvl)}
            active={false}
            expanded={false}
            absolute={false}
            answers={<RawStatsBarsContainer id={topicId}/>} /> </StyledRow>;
    });
  }
}

const mapStateToProps = (state) => ({
  activeSubjectId: activeSubjectId(state),
  topicsTree: topicsTree(state),
  isLinksNavigationSectionVisible: isLinksNavigationSectionVisible(state),
  isMobilePortrait: isMobilePortrait(state),
  currentFlashcardId: currentFlashcardId(state),
});
const mapDispatchToProps = {
  selectFlashcard: Actions.Studying.selectFlashcard,
  toggleLinksNavigationSection: Actions.Navigation.toggleLinksNavigationSection,
};
export default connect(mapStateToProps, mapDispatchToProps)(TopicLinksResultsList);
const StyledRow = styled(Row)`
  padding: 8px 20px;
`;