import {
  clearFlashcardsInFolder,
  clearFolder,
  getFlashcardsInFolder,
  getFolder,
  multipleDeleteFlashcardsFromFolder,
  selectAllFlashcardPreviewsFromFolder
} from 'actions/desk';

import { ReactComponent as NoFoldersIcon } from 'assets/icons/folders/no-folder-icon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import CancelEditButton from 'v2/components/Button/CancelEditButton';
import EditButton from 'v2/components/Button/EditButton';
import ElementPositionDetector from 'v2/components/ElementPositionDetector';
import EmptyScreenMessage from 'v2/components/empty-screen-message/EmptyScreenMessage';
import FooterEditModeContainer from 'v2/components/footer-edit-mode/FooterEditModeContainer';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import Loader, {BackgroundEnum, SizeEnum} from 'v2/components/loader.v2/Loader.v2';
import NavigationHeader from 'components/navigation-header';
import BackButton from 'components/navigation-header/BackButton';
import TeachingLevelSelectorContainer from 'components/teaching-level-selector/TeachingLevelSelectorContainer';
import { transformResultsListToArray, filterResultsListToArray, getLastMediaTypeVisited, teachingFilterResultsListToArray, checkFlashcardAssesstment,examsPapergroupedList,sourceFilter,paperFilter ,difficultyFilter,topicTeachingFilter,getUniqueExamPreviewData,disableMcqExamSubject,returnExamAnswerFilter,questionTypeFind} from 'helpers';
import withScrollDetection from 'hocs/withScrollDetection';
import { AddToFolderIcon, LearnFolderIcon, MemorizedFolderIcon, RepeatFolderIcon } from 'icons/FolderIcons';
import { ReactComponent as NoBookmarkIcon } from 'assets/icons/folders/no-bookmark-icon.svg';
import { EditIcon } from 'icons/HeaderIcons';
import Immutable,{fromJS} from 'immutable';
import AddFlashcardToFolderContainer from 'pages/desk/folder/AddFlashcardToFolderContainer';
import AddFlashcardToBookmarkContainer from 'pages/desk/folder/AddFlashcardToBookmarkContainer';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { activeSubjectId, activeTeachingLevelTitle, getActiveTeachingLevel } from 'selectors';
import * as Selectors from 'selectors';
import { flashcardsInFolder, folderTitle, hasMore, isMobile, isRequestInProgress, shouldGoBackToSearch } from 'selectors';
import { currentFlashcardId } from 'selectors/studying';
import BackToSearchButton from 'ui-components/BackToSearchButton';
import Margin from 'ui-components/helpers/Margin';
import Row from 'ui-components/helpers/Row';
import { FixedPageSpacing, SmallPageTitle } from 'ui-components/pageComponents';
import FlashcardResultsList from 'ui-components/search-results/FlashcardResultsList';
import './FolderPage.scss';
import styled, { css } from 'styled-components/macro';
import appHistory from 'appHistory';
import media from 'ui-components/helpers/media';
import Padding from 'ui-components/helpers/Padding';
import * as Actions from 'actions';
import EditFolderPage from 'pages/desk/edit-folder/EditFolderPage';
import MediaTypeTabSelector from 'v2/components/media-tab/MediaTypeTabSelector';
import { getUserId } from 'selectors/user';
import MircoFilterLayout from 'components/studying/microFilterContainer/MicroFilterLayout';
import { marks, answers, watched, examAnswers } from 'selectors/answersSelectors';
import ExamsFilterTile from "components/studying/examFilterContainer/examsFilterTile"
import MCQFilterLayout from 'components/studying/examFilterContainer/MCQFilterLayout';
import { ExamsMCQFilterWrapper } from "components/media-tab/MediaType.styles"

//TODO extract to constants
export const smartFolders = {
  'learn': {
    title: 'learn',
    icon: <LearnFolderIcon />,
    description: 'These are the cards you haven\'t tried yet.'
  },
  'repeat': {
    title: 'repeat',
    icon: <RepeatFolderIcon />,
    description: 'These are the cards you tried to answer but haven\'t scored \'correct\' three times in a row.'
  },
  'memorized': {
    title: 'memorized',
    icon: <MemorizedFolderIcon />,
    description: 'These are the cards you answered and scored \'correct\' three times in a row.'
  },
  'bookmark': {
    title: 'bookmark',
    icon: <NoBookmarkIcon />
  },
};


//TODO Refactor logic and split into smaller containers
class FolderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
      isEditMode: false,
      filterDataSize: 1,
      filterFlashcardId: null,
      active: false
    };
    this.onDelete = this.onDelete.bind(this);
    this.wrapperRef = null;
    this.props.getFolders();
    this.state = { isFixed: false };
  }

  componentDidMount() {
    const mediaType = getLastMediaTypeVisited(this.props.userId)

    const folderId = this.props.folderId ? this.props.folderId : this.props.match.params.id;
    const isSmart = this.isSmart();
    if (this.props.currentFlashcardId) {
      this.props.getFoldersForFlashcard(this.props.currentFlashcardId, mediaType);
    }


    this.props.getFlashcardsInFolder(folderId, isSmart, 0, isSmart);
    if (!isSmart) {
      this.props.getFolder(folderId);
    }

    if (this.props.isMobile && !this.props.inTooltip) {
      window.scrollTo(0, 0);
    }
    this.setState({
      isEditMode: false
    });
    if (typeof this.props.getTopicIdByTeachingLevel == 'undefined') {
      this.props.getAllTopicsInSubject({
        key: this.props.activeSubject.get('id'),
        subjectId: this.props.activeSubject.get('id'),
        mapAnswers: true
      });
    }
  }

  componentWillUnmount() {
    // Change screen orientation then getFolder function is empty
    // this.props.clearFlashcardsInFolder();
    // this.props.clearFolder();
  }

  componentDidUpdate(prevProps) {
    let assesstment;
    const { currentFlashcardId, marks, listOfFilterCheckedItems, activeMediaTab, answers, flashcardPreviews, watched,subjectsTeachingLevel, examAnswers } = this.props;

    const mediaType = getLastMediaTypeVisited(this.props.userId)
    const isSmart = this.isSmart();
    if (prevProps.activeTeachingLevelTitle !== this.props.activeTeachingLevelTitle && this.isSmart()) {
      const folderId = this.props.folderId ? this.props.folderId : this.props.match.params.id;
      this.props.getFlashcardsInFolder(folderId, true, 0, true);
    }

    const folderId = this.props.folderId ? this.props.folderId : this.props.match.params.id;
    if (prevProps.activeMediaTab != this.props.activeMediaTab) {
      this.props.getFlashcardsInFolder(folderId, isSmart, 0, isSmart);
    }

    if (prevProps.activeSubjectId && prevProps.activeSubjectId !== this.props.activeSubjectId) {
      appHistory.push('/flashcard/desk/folders');
    }

    if (prevProps.currentFlashcardId != this.props.currentFlashcardId && this.props.currentFlashcardId) {
      this.props.getFoldersForFlashcard(this.props.currentFlashcardId, mediaType);
    }

    let filters = listOfFilterCheckedItems.toJS()[activeMediaTab]

    let isCurrentCardInclude
    flashcardPreviews.forEach((val) => {
      if (val.get('flashcardId') === currentFlashcardId) {
        isCurrentCardInclude = true
      }
    })
    if (activeMediaTab === "flashcard" && currentFlashcardId) {
      if (prevProps.answers.toJS()[currentFlashcardId] && prevProps.answers.toJS()[currentFlashcardId].correct !== answers.toJS()[currentFlashcardId].correct) {
        if ((filters.length > 0 && filters.length < 4) || isCurrentCardInclude) {
          if (answers.get(currentFlashcardId).get("correct") === 1) {
            assesstment = "Confident";
          } else if (answers.get(currentFlashcardId).get("correct") === 2) {
            assesstment = "Neither";
          } else if (answers.get(currentFlashcardId).get("correct") === 0) {
            assesstment = "Unconfident";
          } else if (answers.get(currentFlashcardId).get("correct") === null) {
            assesstment = "Not yet assessed";
          } else {
            assesstment = null;
          }
          if (checkFlashcardAssesstment(this.props.listOfFilterCheckedItems.toJS()["flashcard"], assesstment, "flashcard")) {
            this.setState({ filterFlashcardId: currentFlashcardId });
          }
          setTimeout(() => {
            this.props.getFlashcardsInFolder(folderId, isSmart, 0, isSmart);
            appHistory.push(`/flashcard/desk/folders/${folderId}`)
            this.setState({ filterFlashcardId: null });
          }, 500)
        }
      }
    }

    if (activeMediaTab === "revision" && currentFlashcardId) {
      if (prevProps.marks.toJS()[currentFlashcardId] && prevProps.marks.toJS()[currentFlashcardId].markValue !== marks.toJS()[currentFlashcardId].markValue) {
        if (filters.length === 1 && isCurrentCardInclude) {
          if (marks.toJS()[currentFlashcardId].markValue === 1) {
            assesstment = "Read";
          } else if (marks.toJS()[currentFlashcardId].markValue === 0) {
            assesstment = "Not yet read";
          } else {
            assesstment = null;
          }
          if (checkFlashcardAssesstment(this.props.listOfFilterCheckedItems.toJS()["revision"], assesstment, "revision")) {
            this.setState({ filterFlashcardId: currentFlashcardId });
          }
          setTimeout(() => {
            appHistory.push(`/flashcard/desk/folders/${folderId}`)
            this.props.getFlashcardsInFolder(folderId, isSmart, 0, isSmart);
            this.setState({ filterFlashcardId: null });
          }, 500)
        }
      }
    }

    if (activeMediaTab === "video" && currentFlashcardId) {
      if (prevProps.watched.toJS()[currentFlashcardId] && prevProps.watched.toJS()[currentFlashcardId].partialValue !== watched.toJS()[currentFlashcardId].partialValue) {
        if ((filters.length > 0 && filters.length < 3) && isCurrentCardInclude) {
          if (watched.toJS()[currentFlashcardId].partialValue === 0 && watched.toJS()[currentFlashcardId].watchedValue !== 1) {
            assesstment = "Not yet watched";
          } else if (watched.toJS()[currentFlashcardId].partialValue === 1 && watched.toJS()[currentFlashcardId].watchedValue !== 1) {
            assesstment = "Partly-watched";
          } else if (watched.toJS()[currentFlashcardId].watchedValue === 1) {
            assesstment = "Watched";
          } else {
            assesstment = null;
          }
          if (checkFlashcardAssesstment(this.props.listOfFilterCheckedItems.toJS()["video"], assesstment, "video")) {
            this.setState({ filterFlashcardId: currentFlashcardId });
          }
          setTimeout(() => {
            appHistory.push(`/flashcard/desk/folders/${folderId}`)
            this.props.getFlashcardsInFolder(folderId, isSmart, 0, isSmart);
            this.setState({ filterFlashcardId: null });
          }, 500)

        }
      }
    }

    if(activeMediaTab === "exams" && currentFlashcardId){
      if(prevProps.examAnswers.get(currentFlashcardId) && prevProps.examAnswers.get(currentFlashcardId).get("correct") !== examAnswers.get(currentFlashcardId).get("correct")){
        if(examAnswers.get(currentFlashcardId).get("correct") === 1){
          assesstment = "Confident";
        }else if(examAnswers.get(currentFlashcardId).get("correct") === 2){
          assesstment = "Neither";
        }else if(examAnswers.get(currentFlashcardId).get("correct") === 0){
          assesstment = "Unconfident";
        }else if(examAnswers.get(currentFlashcardId).get("correct") === null){
          assesstment = "Not yet assessed";
        }else{
          assesstment = null;
        }
        // if(checkFlashcardAssesstment(this.props.listOfFilterCheckedItems.toJS()["exams"], assesstment, "exams")){
        //   this.setState({filterFlashcardId : currentFlashcardId});
        // }
        setTimeout(() => {
          appHistory.push(`/flashcard/desk/folders/${folderId}`)
          this.props.getFlashcardsInFolder(folderId, isSmart, 0, isSmart);
          this.setState({filterFlashcardId : null});
        }, 500)
        
      }
    }
    if(prevProps.subjectsTeachingLevel !==  subjectsTeachingLevel){
      if(activeMediaTab === "exams"){
        if (this.props.currentFlashcardId) {
          this.props.getFoldersForFlashcard(this.props.currentFlashcardId, mediaType);
        }
    
    
        this.props.getFlashcardsInFolder(folderId, isSmart, 0, isSmart);
        if (!isSmart) {
          this.props.getFolder(folderId);
        }
      }
    }
    if (this.props.activeSubjectId !== prevProps.activeSubjectId) {
      this.props.getAllTopicsInSubject({
        key: this.props.activeSubject.get('id'),
        subjectId: this.props.activeSubject.get('id'),
        mapAnswers: true
      });
    }
  }


  onDelete() {
    const mediaType = getLastMediaTypeVisited(this.props.userId)
    const folderId = this.props.match.params.id;
    this.props.multipleDeleteFlashcardsFromFolder(folderId, mediaType);
  }
  setEmptyScreen() {
    // this.props.setCurrentFlashcard(null);
    // appHistory.push('', true);
  }

  isSmart() {
    const folderId = this.props.folderId ? this.props.folderId : this.props.match.params.id;
    if (folderId === "bookmark") {
      return 1;
    } else {
      return isNaN(parseInt(folderId));
    }

  }

  isCurrentFlashcardAdded() {
    return this.props.flashcardPreviews.find((item) => item.get('flashcardId') === this.props.currentFlashcardId);
  }

  renderFolderContents = (newFilterItems) => {
    const { shouldGoBackToSearch, onListItemSelected ,isExamsMode, activeMediaTab} = this.props;
    if (this.props.loading) {
      return null;
    }
    return (
      <FlashcardResultsList form={isExamsMode} newListItems={(activeMediaTab === "exams" && isExamsMode) ? examsPapergroupedList(newFilterItems):transformResultsListToArray(newFilterItems)} 
        shouldMark={shouldGoBackToSearch} onListItemSelected={onListItemSelected} type="folder" filterFlashcardId={this.state.filterFlashcardId} />
    );
  };

  bindRef = (ref) => {
    if (this.props.innerRef) {
      this.wrapperRef = this.props.innerRef(ref);
    } else {
      this.wrapperRef = ref;
      this.props.bindScrollRef(ref);
    }
  };
  onMouseEnterHandler = () => {
    if (!this.props.isMobile) {
      this.setState({
        isHover: true
      });
    }
  }

  onMouseLeaveHandler = () => {
    this.setState({
      isHover: false
    });
  }

  editCurrentFolder = () => {
    this.setState({
      isEditMode: true
    });
  }

  toggleEditMode = () => {
    this.setState({
      isEditMode: !this.state.isEditMode
    });
    this.setState({
      isHover: false
    });
  }
  
  selectToggle = (val) => {
    this.setState({ active: !val })
  }

  render() {
    const { activeMediaTab, listOfFilterCheckedItems, folderFilterListSize, activeSubject, subjectsTeachingLevel ,examsofFilterItems,isExamsMode} = this.props;
    const isSmart = this.isSmart();
    const examsofFilterItemsToJS = examsofFilterItems.toJS()
    const folderId = this.props.folderId ? this.props.folderId : this.props.match.params.id;
    const loading = this.props.loading;
    const isEditing = this.props.isDeskEditModeActive;
    const flashcardPreviews = this.props.flashcardPreviews;
    const inTooltip = this.props.inTooltip;
    const isMobilePortrait = !(this.props.isMobile && this.props.isLandscape);
    const mediaType = getLastMediaTypeVisited(this.props.userId)
    const teachingTag = activeSubject && subjectsTeachingLevel[activeSubject.get("id")];
    const flashcards = flashcardPreviews && teachingTag && activeSubject &&  teachingFilterResultsListToArray(flashcardPreviews, teachingTag, activeSubject)
    const data  = flashcards  && sourceFilter(flashcards.toJS(),examsofFilterItemsToJS.source,activeMediaTab);
    const paperFilterData =  flashcards && paperFilter(data,examsofFilterItemsToJS.paper,activeMediaTab);
    const teachingTagData  = flashcards && topicTeachingFilter(teachingTag ,paperFilterData,examsofFilterItemsToJS.teachingLevel, "exams",activeMediaTab)
    // const uiqueExamData  = flashcards && (activeMediaTab === 'exams' && getUniqueExamPreviewData(teachingTagData));
    const multipleChooseData = activeMediaTab === 'exams' && flashcards.size > 0  && questionTypeFind(fromJS(teachingTagData)).size > 0 && questionTypeFind(fromJS(teachingTagData)).getIn(['mcq','mcq'])

    const freeResponseData =  activeMediaTab === 'exams' && flashcards.size > 0  && questionTypeFind(fromJS(teachingTagData)).size > 0 && questionTypeFind(fromJS(teachingTagData)).getIn(['group','group'])

    const examAnswerFilter =  activeMediaTab === 'exams' && flashcards && returnExamAnswerFilter(multipleChooseData && multipleChooseData.toJS(),freeResponseData && freeResponseData.toJS(),examsofFilterItemsToJS.mcq,listOfFilterCheckedItems.toJS()[activeMediaTab],activeMediaTab,teachingTagData)

    const difficultyData  =  flashcards && difficultyFilter(examAnswerFilter,examsofFilterItemsToJS.difficulty,activeMediaTab)
    const sortData =  activeMediaTab === 'exams' && difficultyData.length > 0 &&  difficultyData.sort((a, b) => {
      const indexA = a.numbering;
      const indexB = b.numbering;
      if (indexA < indexB) return -1;
      return 1;
    });
    const examsModeFilter = activeMediaTab === 'exams' ? (isExamsMode  ? fromJS(examAnswerFilter) : fromJS(sortData)) : flashcards;
    const newFilterItems = flashcards  && filterResultsListToArray(examsModeFilter, activeMediaTab, listOfFilterCheckedItems.toJS()[activeMediaTab])
    const filterSize = flashcards.size === 0 ? false : listOfFilterCheckedItems.toJS()[activeMediaTab].length !== 0;
    const addButtonVisible = 1 
    folderFilterListSize(newFilterItems.size, activeMediaTab)
    
    return (
      <div id='folder-page'>
        {!this.state.isEditMode ? this.renderHeader() : null}
        <StyledFixedPageSpacing className={inTooltip ? 'without-padding in-tooltip' : ''} ref={this.bindRef} isFolderSize={newFilterItems.size > 0}>
          {this.state.isEditMode ?
            <EditFolderPage id={'edit-current-folder-page'} editMode={this.isEditMode} toggleEditMode={this.toggleEditMode} folderTitle={this.props.folderTitle} folderId={folderId} currentFolderEdit={true} />
            : null
          }
          {(inTooltip || !isMobilePortrait) ? null :
            <React.Fragment>
              {!this.state.isEditMode ?
                <div className='folder-name flex vertical-align'>
                  <div>{isSmart ? (
                    <div className='capitalize'>
                      <SmallPageTitle>Bookmarked</SmallPageTitle>
                      {/*TODO should be streamlined to include FixedPageSpacing*/}
                    </div>
                  ) :
                    <StyledRow onMouseEnter={this.onMouseEnterHandler} onMouseLeave={this.onMouseLeaveHandler}>
                      <StyledSmallPageTitle onClick={this.editCurrentFolder}>{this.props.isFolderLoading ? <div><Loader background={BackgroundEnum.Blue} size={SizeEnum.MeduiumSmall} /></div> : this.props.folderTitle}</StyledSmallPageTitle>
                      <StyledEditIconDiv className='edit-folder-name-title' onClick={this.editCurrentFolder}>
                        {this.props.isFolderLoading ? null : <div className="edit-folder-icon"><EditIcon /></div>}
                      </StyledEditIconDiv>
                    </StyledRow>
                  }</div>
                  {isSmart ? (
                    <div className='flex-pull-right vertical-align'>
                      <AddFlashcardToBookmarkContainer flashcardFolders={this.props.flashcardContent.get('bookMark')} isFolderPage={false} />
                    </div>
                  ) : (
                    !isEditing && this.props.currentFlashcardId ? (
                      <div className='flex-pull-right vertical-align pointer'>
                        <AddFlashcardToFolderContainer addButtonVisible={addButtonVisible} folderId={folderId} flashcardFolders={this.props.flashcardFolders} />
                      </div>
                    ) : null
                  )}
                </div> : null}
            </React.Fragment>}

          {/* {(isSmart && flashcardPreviews.size > 0 ) && (
            <div className='folder-description flex'>
              {smartFolders[folderId].icon}
              <div>
                {smartFolders[folderId].description}
              </div>
            </div>
          )} */}

          <ElementPositionDetector
            onTrigger={(value) => {
              this.setState({ isFixed: value });
            }}
            debounce={0}
            maxScrollTop={34}
            parentScrollRef={this.wrapperRef}
          />

          {/* <StyledInfiniteScroll
            initialLoad={true}
            useWindow={false}
            editMode={this.state.isEditMode}
           > */}
          {this.props.isMobile && this.props.isLandscape ? null : <Padding bottom={this.state.isEditMode ? 12 : 14} />}

          <StyledFolderMediaTypeContainer editMode={this.state.isEditMode} >
            <MediaTypeTabSelector type={"folder"} subjectId={this.props.activeSubjectId} />
          </StyledFolderMediaTypeContainer>
         
            {activeMediaTab === 'exams' && flashcards.size > 0 && 
            <ExamsFilterTile isEditMode={this.state.isEditMode ? true : false} type={"folder"} activeMediaTab ={activeMediaTab} />}
          {flashcards.size > 0 &&
            <React.Fragment>
              {activeMediaTab === 'exams' ?
                <React.Fragment>
                  <ExamsMCQFilterWrapper>
                    {!disableMcqExamSubject(activeSubject.get('id')) && <React.Fragment>
                      <StyledMCQFilterLayout isEditMode={this.state.isEditMode ? true : false} />
                      <Padding left={8} />
                    </React.Fragment>
                    }
                    <StyledMircoFilterLayout page={"exercise"}  isEditMode={this.state.isEditMode ? true : false}/>
                  </ExamsMCQFilterWrapper>
                </React.Fragment>
                : <StyledMircoFilterLayout marginTop={14} isEditMode={this.state.isEditMode ? true : false} />
              }
            </React.Fragment>
          }
          <StyledInfiniteScroll editMode={this.state.isEditMode}>
            <Padding bottom={24} />
            {newFilterItems.size > 0 ? this.renderFolderContents(newFilterItems) : loading || (isSmart && newFilterItems.size > 0) ? null :
              <React.Fragment>
                <EmptyScreenMessage className={'empty-screen-padding'} changeRoute={this.props.changeRoute}
                  type={isSmart ? smartFolders[folderId].title : (filterSize) ? 'filterFolders' : 'folders'} card_canvas_mediaType={mediaType} mediaType={this.props.activeMediaTab} currentFlashcardid={this.props.currentFlashcardId}  itemId={folderId} isSmart={isSmart}> {isSmart ? smartFolders[folderId].icon : (filterSize) ? <FilterIcon /> : <NoFoldersIcon />}</EmptyScreenMessage>
              </React.Fragment>
            }
            {(loading) && (<LeftMenuLoader />)}
          </StyledInfiniteScroll>
        </StyledFixedPageSpacing>

        <FooterEditModeContainer
          onDelete={this.onDelete}
          folderEditMode={this.state.isEditMode}
          itemsCount={newFilterItems.size}
          onSelectAll={this.props.selectAllFlashcardPreviewsFromFolder}
        />
      </div>
    );
  }

  renderLeftSection = () => {
    if (this.props.isDeskEditModeActive) {
      return <CancelEditButton />;
    }
    return <BackToSearchButton alternate={() => <BackButton linkTo='/flashcard/desk/folders' text='Folders' />} />;
  };

  renderHeader() {
    const isSmart = this.isSmart();
    const isCurrentFlashcardAdded = this.isCurrentFlashcardAdded();
    const folderId = this.props.folderId ? this.props.folderId : this.props.match.params.id;
    const isEditing = this.props.isDeskEditModeActive;
    const isMobilePortrait = !(this.props.isMobile && this.props.isLandscape);
    return (
      <React.Fragment>
        {isMobilePortrait ? <NavigationHeader
          collapsedTitle={isSmart ? <AddFlashcardToBookmarkContainer flashcardFolders={this.props.flashcardContent.get('bookMark')} isFolderPage={false} /> : !isEditing ? <AddFlashcardToFolderContainer folderId={folderId} flashcardFolders={this.props.flashcardPreviews} /> : null}
          isCollapsed={this.props.isScrolled}
          extendedWidth={!isEditing}
          leftSection={this.renderLeftSection()}
          rightSection={
            (!isSmart || isSmart === 1) && (this.props.flashcardPreviews.size > 0 ? <EditButton className={this.props.flashcardPreviews.size > 0 ? 'text-blue-normal' : "text-#C8CCCC"} /> : <StyledDiv>Edit</StyledDiv>)
          }
          extendedHeight
          shouldExtendHeight={this.state.isFixed}
          bottomSection={null}
        /> :
          <NavigationHeader
            extendedWidth
            isCollapsed={true}
            collapsedTitle={isSmart ?
              (
                <div className="capitalize">
                  {folderId}
                </div>
              ) : (
                this.props.folderTitle
              )
            }
            extendedHeight
            shouldExtendHeight={this.state.isFixed}
            leftSection={this.renderLeftSection()}
            rightSection={(
              isSmart ? (
                <div className='flex-pull-right vertical-align'>
                  {isCurrentFlashcardAdded && (
                    <AddToFolderIcon state='active' />
                  )}
                </div>
              ) : (
                this.props.currentFlashcardId ?
                  <div className='flex-pull-right vertical-align pointer' onClick={this.props.onAddClick}>
                    <AddFlashcardToFolderContainer folderId={folderId} flashcardFolders={this.props.flashcardPreviews} />
                  </div> : null
              )
            )} />}
      </React.Fragment>
    );
  }
}

FolderPage.propTypes = {
  loading: PropTypes.bool,
  isScrolled: PropTypes.bool,
  isMobile: PropTypes.bool,
  inTooltip: PropTypes.bool,
  hasMore: PropTypes.bool,
  folderTitle: PropTypes.string,
  match: PropTypes.object.isRequired,
  currentFlashcardId: PropTypes.number,
  getFolder: PropTypes.func.isRequired,
  bindScrollRef: PropTypes.func.isRequired,
  getFlashcardsInFolder: PropTypes.func.isRequired,
  flashcardPreviews: PropTypes.instanceOf(Immutable.List),
  isDeskEditModeActive: PropTypes.bool,
  selectAllFlashcardPreviewsFromFolder: PropTypes.func,
  multipleDeleteFlashcardsFromFolder: PropTypes.func,
  clearFlashcardsInFolder: PropTypes.func,
  clearFolder: PropTypes.func,
  onListItemSelected: PropTypes.func,
};

const mapStateToProps = (state) => ({
  folderTitle: folderTitle(state),
  flashcardFolders: Selectors.apiRequestData(state, 'flashcardFolders'),
  flashcardContent: Selectors.Studying.getFlashcardById(state, Selectors.Studying.currentFlashcardId(state)),
  folders: Selectors.folders(state),
  flashcardPreviews: flashcardsInFolder(state),
  flashcardBookmarks: Selectors.apiRequestData(state, 'flashcardBookmarks'),
  currentFlashcardId: currentFlashcardId(state),
  isDeskEditModeActive: Selectors.isDeskEditModeActive(state),
  loading: isRequestInProgress(state, 'desk', 'getFolder') || isRequestInProgress(state, 'desk', 'flashcardsInFolder'),
  isMobile,
  hasMore: hasMore(state, 'desk', 'flashcardsInFolder'),
  shouldGoBackToSearch: shouldGoBackToSearch(state),
  activeTeachingLevelTitle: activeTeachingLevelTitle(state),
  activeTeachingLevel: getActiveTeachingLevel(state),
  activeSubjectId: activeSubjectId(state),
  isLandscape: Selectors.isLandscape(state),
  // isFolderLoading: isRequestInProgress(state, 'desk', 'getFolder')
  isFolderLoading: isRequestInProgress(state, 'desk', 'getFolder'),
  activeMediaTab: Selectors.getActiveMediaTab(state),
  userId: getUserId(state),
  listOfFilterCheckedItems: Selectors.listOfFilterCheckedItems(state),
  marks: marks(state),
  answers: answers(state),
  watched: watched(state),
  examAnswers: examAnswers(state),
  activeSubject: Selectors.getActiveSubject(state),
  subjectsTeachingLevel: Selectors.subjectsTeachingLevel(state),
  isExamsMode:Selectors.isExamsMode(state),
  examsofFilterItems:Selectors.examsofFilterItems(state),
  getTopicIdByTeachingLevel: Selectors.getTopicIdByTeachingLevel(state),
});
const mapDispatchToProps = {
  toggleDeskEditMode: Actions.Navigation.toggleDeskEditMode,
  getFolders: Actions.Desk.getFolders,
  getFlashcardsInFolder,
  selectAllFlashcardPreviewsFromFolder,
  getFolder,
  multipleDeleteFlashcardsFromFolder,
  clearFlashcardsInFolder,
  clearFolder,
  getFoldersForFlashcard: Actions.Desk.getFoldersForFlashcard,
  setCurrentFlashcard: Actions.Studying.setCurrentFlashcard,
  folderFilterListSize: Actions.Desk.folderFilterListSize,
  updateExamsQuestionIndex:Actions.Desk.updateExamsQuestionIndex,
  getAllTopicsInSubject: Actions.Api.getAllTopicsInSubject,
};

export default connect(mapStateToProps, mapDispatchToProps)(withScrollDetection(FolderPage));

const StyledMircoFilterLayout = styled(MircoFilterLayout)`

    opacity: 0.5 !important;
    pointer-events: none !important;
    user-select: none !important;
 
`;
const StyledMCQFilterLayout = styled(MCQFilterLayout)`

    opacity: 0.5 !important;
    pointer-events: none !important;
    user-select: none !important;
 
`;
const StyledFolderMediaTypeContainer = styled.div`
  opacity: ${props => props.editMode ? '0.5' : null};
  pointer-events: ${props => props.editMode ? 'none' : null};
  user-select: ${props => props.editMode && 'none'};
`;

const StyledInfiniteScroll = styled.div`
  flex:1;
  opacity: ${props => props.editMode ? '0.5' : null};
  pointer-events: ${props => props.editMode ? 'none' : null};
  user-select: ${props => props.editMode && 'none'};
`;


const StyledFixedPageSpacing = styled(FixedPageSpacing)`
  // ${props => !props.isFolderSize && 'display:flex; flex-direction: column;'}
  display:flex; flex-direction: column;
  @media (orientation: landscape){
    ${media.phone(css`
      padding-bottom: 30px;
    `)}
  }
  @media (orientation: portrait){
    ${media.phone(css`
      ${props => !props.isFolderSize && 'display:unset;'}
    `)}
  }
`;
const StyledSmallPageTitle = styled(SmallPageTitle)`
  cursor: pointer;
  
`;

const StyledDiv = styled.div`
  font-weight: bold;
  font-size: 13px;
  //line-height: 18px;
  text-align: right;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #C8CCCC;
  pointer-events: none;
`;
const StyledEditIconDiv = styled.div`
  margin-left: 10px;
  display: none;
`;
const StyledRow = styled(Row)`
  &:hover {
    ${StyledEditIconDiv} {
      display: block;
    }
  }
  @media (hover:none) and (pointer:coarse) { 
    ${StyledEditIconDiv} {
      display: block;
    }
  }
`;
