import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getActiveSubject } from "selectors"


const useNewSubject = () => {
    const [newSubject, setNewSubject] = useState(false)
    const subject = useSelector(getActiveSubject)

    useEffect(() => {
        setNewSubject(subject?.tagging === 'NEW_SUBJECT')
    },[subject?.tagging])
    
    return newSubject
}

export default useNewSubject