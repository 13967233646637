import { ReactNode, useMemo } from 'react'
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';
import classNames from 'classnames';
import LeftActiveTile from './LeftActiveTile';
import { ForwardChevronIcon, ArrowTopicIcon } from 'icons/HeaderIcons';
import Text from 'v2/components/common/Text';
import { useTopicNumber, useSubtopicNumber } from 'hooks/useTopicNumber';

type SubtopicProps = {
  active: boolean,
  label: string,
  parentLabel: string,
  parentTlvl: string,
  parentTlvlTag: string,
  title: string,
  expanded: boolean,
  hasSelection: boolean | undefined,
  isExernalExercise: boolean | undefined,
  onSelect: (arg: React.MouseEvent<HTMLDivElement>) => void,
  isExercise: boolean | undefined,
  currentFlashcardId: number | string,
  checkMircoFilterFlashcard: boolean,
  answers: boolean | ReactNode,
  absolute: boolean | ReactNode,
  subtopic: any,
  subject: any,
  parentTopic: any
}

const Subtopic: React.FC<SubtopicProps> = ({ active, currentFlashcardId, absolute, hasSelection, isExercise, parentTlvl, parentTlvlTag, title, checkMircoFilterFlashcard, onSelect, isExernalExercise, expanded, answers, label, subtopic, subject, parentTopic }) => {
  const NEW_SUBJECT = subject?.tagging === 'NEW_SUBJECT'
  const topicNumber = useTopicNumber(parentTopic)
  const subTopicNumber = useSubtopicNumber(subtopic)
  const answerPadding = () => {
    let className = '';
    if (hasSelection) {
      className = 'pt-3  px-3.5'
    } else {
      className = 'pt-0 px-[3.5px]';
    }
    return className
  }

  const getTlvlTag = useMemo(() => {
    const tlvlTag = (NEW_SUBJECT && subtopic?.teachingTag) ? subtopic?.teachingTag : parentTlvlTag
    return {
      tlvlTag : tlvlTag,
      ...((NEW_SUBJECT && subtopic?.teachingTag) ? 
      { slTag : subtopic?.teachingTag === 'SL' } : 
      { slTag : parentTlvlTag === 'SL' })
    }
  },[NEW_SUBJECT, parentTlvlTag, subtopic?.teachingTag])


  return <div data-subtopic-active={active} className={classNames('styled-subtopic', { '!bg-#E5ECF2': active && currentFlashcardId })} onClick={onSelect}>
    {absolute}
    {(active && currentFlashcardId && !hasSelection) && <LeftActiveTile isExercise={false} />}
    {(active && isExercise && hasSelection) && <LeftActiveTile isExercise={isExercise} />}
    <div className='flex flex-col px-3.5 pt-3.5'>
      <div className='flex items-center'>
        <TeachingLevelSeperator tlvl={parentTlvl} {...getTlvlTag}  />
        <div className='topic-number ml-3  !rounded-[97px] mr-2 !shadow-none'>{topicNumber}</div>
        <ArrowTopicIcon classnames={'rotate-[270deg]'} />
        <div className='sub-topic-number'>
          <Text className='text-13px font-bold text-blue-normal leading-18px tracking-2px'>{subTopicNumber}</Text>
        </div>
      </div>
      <div className='topic-title'>
        <Text className='text-#2D3838 leading-[34px] text-[25px] font-bold pt-6' dangerouslySetInnerHTML={title} />
      </div>
    </div>


    {isExercise && <div className='px-3.5 pt-3.5' >
      <div className={classNames('topic-button', { 'disabled': checkMircoFilterFlashcard })} onClick={onSelect} >
        <Text className={classNames('text-13px font-bold text-white leading-18px tracking-2px', { 'mr-4': isExercise })} >{isExercise ? "VIEW FLASHCARDS " : "STUDY SUBTOPIC "}</Text> <ForwardChevronIcon color={'#FFFFFF'} class={"more-icon"} />
      </div>
      {(isExernalExercise && !expanded) && <div className='pt-2' />}
    </div>}
    {answers && <div className={classNames(answerPadding(), { 'pb-4': !hasSelection }, ' overflow-hidden')}>
      <div onClick={(e) => e.stopPropagation()}>{answers}</div>
    </div>}
  </div>
}


export default Subtopic