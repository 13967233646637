import React, {Component} from 'react';
import './PrimaryButton.scss';
import PropTypes from 'prop-types';
import {RightArrowIcon} from 'icons/PassiveIcons';

export default class PrimaryButton extends Component {
  render() {
    return (
      <button
        type={this.props.type} disabled={this.props.disabled || false}
        className={`primary-button ${this.props.className}`}
        onClick={this.props.onClick}>
        <div className="button-content">
          <div>{this.props.content || this.props.children} </div>
          {this.props.hasArrow ? <div className="arrow"><RightArrowIcon /></div> : null}
        </div>
      </button>
    );
  }
}

PrimaryButton.propTypes = {
  className: PropTypes.string,
  content: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
};
