import React, { memo, useRef, useState, useEffect, useCallback } from 'react'
import Tabs from 'v2/ui-components/Tabs';
import { activeFlashcardId, scrollToFlashcardId } from 'selectors/studying';
import './flashcard-list.scss'
import { useDispatch, useSelector } from 'react-redux';
import { List, Map } from 'immutable';
import {
  activeSubjectId,
  flashcardIdsResponse,
  getActiveTeachingLevel,
  listOfFilterCheckedItems,
  mediaSizes,
  windowWidth,
  subjectsTeachingLevel
} from 'selectors';
import { getSelectedTreeNodes } from 'selectors/topicsTreeSelector';
import { openNodesAction, setActiveNodesAction } from 'actions/topics-tree/topicsTreeActions';
import { setLastMediaTypeVisited, filterResultsListToArrayByTOJS } from 'helpers';
import SubtopicFloatingTitle from 'components/flashcards-vertical-list/SubtopicFloatingTitle';
import RenderHeader from './RenderHeader';
import Tab from 'v2/ui-components/Tab';
import classNames from 'classnames';
import { isLeftMenuOpen } from 'selectors/navigation/navigationSelector';
import FlashcardNotSelected from '../studying/FlashcardNotSelected';
import RequestLoader from '../loader.v2/RequestLoader';
import LeftMenuLoader from '../loader.v2/LeftMenuLoader';
import FlashcardsInfiniteList from './FlashcardsInfiniteList';
import ViewportPositionDetector from 'v2/components/flashcards-vertical-list/ViewportPositionDetector';
import ListFlashcardContainer from 'v2/components/flashcards-vertical-list/ListFlashcardContainer';
import { flipFlashcardAction, setNavigationMode, setActiveFlashcardAction } from 'actions/studying';
import { setActiveMediaTab } from 'actions/navigation';
import { getFlashcardIdsRoutine } from 'actions/api/apiActions';
import { getUserId } from 'selectors/user';
import usePrevious from 'hooks/usePrevious';

type FlashcardsVerticalListContainerType = {
  hideNavButton: boolean;
}

const FlashcardsVerticalListContainer:React.FC<FlashcardsVerticalListContainerType> = ({hideNavButton}) => {
  const dispatch = useDispatch()
  const [isScrolled, setScrolled] = useState(false)
  const [activeId, setActiveId] = useState<number>(0)
  const [parentIds, setParentIds] = useState<any>(null)
  const flashcardListRef = useRef<any>();
  const tabsRef = useRef<any>(null)
  const listRef = useRef<any>();
  const flascardId = useSelector(activeFlashcardId)
  const flashcardIds = useSelector(flashcardIdsResponse)
  const hasLeftMenuOpen = useSelector(isLeftMenuOpen)
  const listOfFilteritems = useSelector(listOfFilterCheckedItems)?.toJS();
  const selectedTreeNodes = useSelector(getSelectedTreeNodes)
  const activeTlvlId = useSelector(getActiveTeachingLevel)
  const subjectId = useSelector(activeSubjectId);
  const userId = useSelector(getUserId);
  const getWidth = useSelector(windowWidth);
  const mediaSize = useSelector(mediaSizes);
  const scrollToCard = useSelector(scrollToFlashcardId);
  const subjectsTlvl= useSelector(subjectsTeachingLevel)
  const prevSubjectsTlvl = usePrevious(subjectsTlvl)
  const prevActiveId = usePrevious(activeId)
  const prevScrollToCard = usePrevious(scrollToCard);
  const prevWidth = usePrevious(getWidth);
  const prevActiveTlvlId = usePrevious(activeTlvlId);
  const prevLeftMenuOpen = usePrevious(hasLeftMenuOpen);
  let activeTab = 'tab-all'
  const scrollToFlashcard = (id: number) => {
    const isSelectedTab = activeTab === 'tab-selected';
    const list = mapList(isSelectedTab);
    let index = list.findIndex((item: any) => item.get('id') === id);
    listRef.current?.updateScrollToIndex(index);
  }
  useEffect(() => {
    setLastMediaTypeVisited("flashcard", userId);
    dispatch(setActiveMediaTab("flashcard"))
    dispatch(getFlashcardIdsRoutine({ subjectId: subjectId }));
    dispatch(setNavigationMode('select'));
    dispatch(setActiveFlashcardAction(null));
  }, [])
  useEffect(() => {
    // let timeout: any;
    if (prevWidth !== getWidth) {
      listRef.current?.recalculateRowHeights();
      listRef.current?.updateScrollToIndex(activeId);
    }
    if (activeTlvlId !== prevActiveTlvlId && flascardId) {
      scrollToFlashcard(flascardId);
    }
    if ((hasLeftMenuOpen !== prevLeftMenuOpen && (!mediaSize.isIframeTablet || mediaSize.isIPad)) && !mediaSize.isMobile) {
      setTimeout(() => {
        listRef.current?.recalculateRowHeights();
        listRef.current?.updateScrollToIndex(activeId);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 1);
      }, 360);
    }

    if (prevScrollToCard !== scrollToCard) {
      if (activeTab === 'tab-selected') {
        tabsRef.current?.selectTab('tab-all');
      }
      scrollToFlashcard(flascardId);
    }

    if (prevActiveId !== activeId) {
      prevActiveId && dispatch(flipFlashcardAction(prevActiveId, 0))
      const openNodeDelay = 500;
      setTimeout(() => {
        dispatch(setActiveNodesAction(activeId, parentIds));
        dispatch(setActiveFlashcardAction(activeId));
        dispatch(openNodesAction(activeId, parentIds, true));
      }, openNodeDelay);
    }
    if (prevSubjectsTlvl !== subjectsTlvl) {
      dispatch(getFlashcardIdsRoutine({ subjectId: subjectId}));
    }
  }, [getWidth, prevScrollToCard, hasLeftMenuOpen, mediaSize, activeTlvlId, activeId, prevActiveId, flascardId, scrollToCard])

  const getIdsConcated = (selectedData: any, subTopic: any, ids: any) => {
    return ids.concat(subTopic.get('flashcards')
      .filter((flashcard: any) => selectedData.indexOf(flashcard) >= 0)
      .map((flashcard: any, key: number) => Map({
        id: flashcard,
        shouldShowSubtopic: key === 0 && ids.size > 0 ? subTopic : false,
        subTopic
      })));
  }
  const mapList = useCallback((filterSelected = false) => {
    let ids: any = List()
    const items = flashcardIds.getIn(['data', 'items'], List())
    const filterList = listOfFilteritems["flashcard"]
    let filteFlashcardData: any = List()
    let filteFlashcardIds: any
    items.forEach((subTopic: any) => {
      if (filterSelected) {
        ids = getIdsConcated(selectedTreeNodes, subTopic, ids)
      } else if (activeTlvlId === subTopic.get('teachingLevelId') || activeTlvlId === subjectId) {
        filteFlashcardData = filteFlashcardData.concat(subTopic.get('flashcardsAnswers').toJS())
        filteFlashcardIds = filterResultsListToArrayByTOJS(filteFlashcardData, "flashcard", filterList).map((item: any) => {
          return item.flashcardId
        })
        ids = getIdsConcated(filteFlashcardIds, subTopic, ids)
      }
    });
    return ids;
  }, [flashcardIds, listOfFilteritems, selectedTreeNodes, activeTlvlId, subjectId])
  const onCardClick = (id: number, isActive: boolean, canFlip: any) => {
    if (isActive) {
      dispatch(flipFlashcardAction(id));
      if (!canFlip) {
        let selectorRenderClass = flashcardListRef.current.getElementsByClassName(
          `flashcard-id-${id}`
        )[0]
        selectorRenderClass.classList.remove('flip-animation');
        selectorRenderClass.classList.add('flip-animation');
        setTimeout(() => {
          selectorRenderClass.classList.remove('flip-animation');
        }, 300)
      }
    } else {
      scrollToFlashcard(id);
    }
  };
  const onListScroll = ({ scrollTop }: { scrollTop: any }) => {
    let isScroll = false;
    if (activeTab === 'tab-selected') {
      isScroll = scrollTop > 10;
    }
    if (isScrolled === isScroll) return;
    setScrolled(isScroll)
  };
  const getParentIds = (subTopic: any) => {
    return [subjectId, subTopic.get('teachingLevelId'), subTopic.get('topicId'), subTopic.get('id')];
  }
  const onTrigger = (index: number, id: number, subTopic: any) => {
    const parentIds = getParentIds(subTopic);
    setParentIds(parentIds)
    setActiveId(id)
  };

  const renderFlashcardRow = ({ index, key, style, row }: { index: number, key: number, style: any, row: any }) => {
    if (!row) return <div key={key} style={style} />;
    const shouldShowSubtopic = row.get('shouldShowSubtopic');
    const subTopic = row.get('subTopic');
    const id = row.get('id');
    const isActive = activeId === id;
    return <div key={key} style={style}>
      {shouldShowSubtopic && (
        <div className='flex justify-center'>
          <div className='pt-2.5 pb-4'>
            <SubtopicFloatingTitle
              subTopicSeparator={false}
              isScrolled={true}
              id={subTopic.get('id')}
              parentIds={[subjectId, subTopic.get('teachingLevelId'), subTopic.get('topicId')]}
              title={subTopic.get('name')}
              numbering={subTopic.get('numbering')} />
          </div>
        </div>
      )}
      <ViewportPositionDetector height={style.height} onTrigger={() => onTrigger(index, id, subTopic)} />
      <div className='pt-0.5 px-1'>
        <ListFlashcardContainer
          id={id}
          isActive={isActive}
          parentClick={onCardClick}
          canEditExercise={true}
          isIframe={false}
        />
      </div>
    </div>
  }
  const renderList = (list: any) => {
    return <RequestLoader request={flashcardIds} allowUpdate
      loader={<div className='pt-[130px]'><LeftMenuLoader /></div>}>
      <FlashcardsInfiniteList
        onScroll={onListScroll}
        ref={listRef}
        list={list}
        hideNavButton={false}>
        {renderFlashcardRow}
      </FlashcardsInfiniteList>

    </RequestLoader>
  }
  const list = mapList();
  const selectedList = mapList(true);

  return <div ref={flashcardListRef}>
    <Tabs
      ref={tabsRef}
      onTabChange={(newTab) => {
        activeTab = newTab;
        setTimeout(() => scrollToFlashcard(flascardId), 100);
      }}
      header={(activeTab, selectTab) => <RenderHeader activeTab={activeTab} selectTab={selectTab} list={list} selectedList={selectedList} isScrolled={isScrolled} subjectId={subjectId} hideNavButton={hideNavButton}/>}
    >
      <Tab className={classNames('style-tabs', { 'style-tabs-spacing': hasLeftMenuOpen })} id={'tab-all'} >
        {list.size > 0 || (flashcardIds?.get("loading") === true) ? renderList(list) : (
          <FlashcardNotSelected isFilter={true} title={'No match found for your filter'} text={<>
            None of the flashcards match the filter you set. <br /><strong>To view flashcards, change the filter above.</strong>
          </>} />
        )}
      </Tab>
      <Tab className={classNames('style-tabs', { 'style-tabs-spacing': hasLeftMenuOpen })} id={'tab-selected'} >
        {selectedList.size > 0 ? renderList(selectedList) : (
          <FlashcardNotSelected isFilter={false} title={'Select some flashcards'} text={<React.Fragment>
            This is where you’ll see all the cards
            that you want to <br />be part of this exercise.
          </React.Fragment>} />
        )}
      </Tab>
    </Tabs>
  </div>
}

export default memo(FlashcardsVerticalListContainer);