import React from 'react';
import PropTypes from 'prop-types';
import {BackChevronTurquoiseIcon} from 'icons/HeaderIcons';
import {CloseBasketArrow, OpenBasketArrow} from 'icons/PassiveIcons';
import appHistory from 'appHistory';
import AvatarContainer from 'components/avatar/AvatarContainer';
import './store-item-details.scss';

const RATING_CONST = 2;

const StoreItemDetails = (props) => {
  return (
    <div className='store-item-details-wrapper page'>
      <div className="back-to-shop-wrapper">
        <div className="back-to-shop" onClick={() => appHistory.push('/store', '')}>
          <BackChevronTurquoiseIcon/>
          <span className="back-to-shop-title">Shop</span>
        </div>
      </div>

      {typeof props.storeItemById !== "undefined" ? renderItemDetails(props) : renderEmptyItem()}

    </div>
  );
};

const renderItemDetails = (props) => {
  return (
    <div className='page-body'>
      <div className="header">
        <div className="title-container">
          <h1 className="title">{props.storeItemById.title}</h1>
          <div className="picture-container ratio-1-1">
            <AvatarContainer className="content profile-picture dark"/>
          </div>
        </div>
      </div>


      <div className="review-container">
        <div className="star-ratings">
          <div className="star-ratings-top" style={{width: RATING_CONST * 20 + '%'}}>
            <span>★</span><span>★</span><span>★</span><span>★</span><span>★</span>
          </div>

          <div className="star-ratings-bottom">
            <span>★</span><span>★</span><span>★</span><span>★</span><span>★</span>
          </div>
        </div>

        <div className="review-number">
          <span>22</span>
        </div>
      </div>

      <div className="menu-container">
        <div className={'menu-title' + (props.isTabActive(0) ? ' active' : '')}
             onClick={() => props.setActiveItemStoreDetailsTab(0)}>
          <span>Details</span>
        </div>
        <div className={'menu-title' + (props.isTabActive(1) ? ' active' : '')}
             onClick={() => props.setActiveItemStoreDetailsTab(1)}>
          <span>Reviews</span>
        </div>
      </div>
      <div className="menu-separator"></div>
    </div>
  );
};

const renderEmptyItem = () => {
  return (
    <div className="header">
      <div className="title-container">
        <h1 className="title">No Item selected!</h1>
        <div className="picture-container ratio-1-1">
          <AvatarContainer className="content profile-picture dark"/>
        </div>
      </div>
    </div>
  );
};


StoreItemDetails.propTypes = {};

export default StoreItemDetails;
