import styled from 'styled-components/macro';

const ExerciseBullet = styled.div`
  //position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: linear-gradient(135deg, #FC4B07 0%, #FC2666 100%);
  right: 0;
`;

export default ExerciseBullet;
