import classNames from 'classnames';
import React, {ReactElement} from 'react';


enum ThemeEnum{
  Blue = 'blue',
  White = 'white'
}
type AuthHeaderProps = {
    title: ReactElement;
    leftSection: ReactElement|undefined;
    rightSection: ReactElement|undefined;
}  

const AuthHeader: React.FC<AuthHeaderProps> = ({title, leftSection, rightSection}) => {
  return (
   <div className='auth-login'>
        <div className='w-60px text-left'>
            {leftSection}
        </div>
        {title}
        <div className='w-60px text-right'>
            {rightSection}
        </div>
   </div>
  )
}

export default AuthHeader;