import React from "react";
import BackButton from 'v2/components/navigation-header/BackButton';
import UserAvatarContainer from 'v2/components/avatar/UserAvatarContainer';
import './ProfileDetailsPage.scss';
import './ProfilePage.scss';
import mbCheckIcon from 'assets/icons/mb-check-icon.svg';
import SectionWrapper from "./SectionWrapper";
import HorizontalSeparator from "v2/components/horizontal-separator/HorizontalSeparator"
import * as HeaderIcons from 'icons/HeaderIcons';
import { ReactComponent as MBLogoChina } from 'assets/icons/mb-logo-china.svg';
import { ReactComponent as MBLogo } from 'assets/icons/mb-logo.svg';
import mbSyncIcon from 'assets/icons/mb-sync-icon.svg';


type ProfilePageComponentProps = {
  changeMenuPath: (arg?: string) => void,
  userProfile: any,
  userData: any,
  isMBUser: boolean,
  hasMBIdentity: boolean,
  lastSync: any,
  onMergeClick: (arg: string) => void
}

const ProfilePageComponent: React.FC<ProfilePageComponentProps> = ({ changeMenuPath, userProfile, userData, isMBUser, hasMBIdentity, lastSync, onMergeClick }) => {

  return <div className="profile-page">
    <div className="profile-page-header">
      <BackButton onClick={() => changeMenuPath('')} text="Settings" inverted />
      <div className="profile-picture-container">
        <div className="shadow">
          <UserAvatarContainer alternate size={110} />
        </div>
      </div>
    </div>

    <div className="profile-page-content">
      <div className="profile-page-subtitle flex items-center justify-between">
        <span>YOUR ACCOUNT</span>
        {hasMBIdentity && <div className="flex items-center profile-notification">
          <img src={mbCheckIcon} alt="" />
          <div className="ml-5px">Merged</div>
        </div>}
      </div>

      <SectionWrapper hasMBIdentity={hasMBIdentity} userData={userData} isMBUser={isMBUser} onClick={() => !hasMBIdentity && changeMenuPath('change-username')}>
        <div className="profile-page-item-container">
          <div className="white-background" />
          <div className="profile-page-item">Email</div>
          <HorizontalSeparator className="items-separator" />
          <div className="detailed-item">
            <span className={'profile-data' + (!hasMBIdentity ? ' no-spacing' : '')}>{userData.email}</span>
            {!hasMBIdentity && <HeaderIcons.ForwardChevronIcon />}
          </div>
        </div>
      </SectionWrapper>

      <SectionWrapper hasMBIdentity={hasMBIdentity} userData={userData} isMBUser={isMBUser} onClick={() => !hasMBIdentity && changeMenuPath('change-password')}>
        <div className="profile-page-item-container">
          <div className="profile-page-item">Password</div>
          <HorizontalSeparator className="items-separator" />
          <div className="detailed-item">
            <span
              className={'profile-data' + (!hasMBIdentity ? ' no-spacing' : '')}>&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</span>
            {!hasMBIdentity && <HeaderIcons.ForwardChevronIcon />}
          </div>
        </div>
      </SectionWrapper>

      {hasMBIdentity ? (
        <div className="extra-info">Change your ManageBac log in data on <SectionWrapper hasMBIdentity={hasMBIdentity} userData={userData} isMBUser={isMBUser} className='text-#6398E6 font-semibold' >ManageBac</SectionWrapper></div>
      ) : (
        <div className="extra-info">Change your login data by adjusting your email and password</div>
      )}
      {!hasMBIdentity && (
        <>
          <button type='button' onClick={() => onMergeClick('canada')} className='login-with-mb '>
            <div className="flex items-center justify-center">
              <MBLogo />
              <div className="ml-3 login-with-mb-text tracking-2px">
                MERGE WITH
                <span className="tracking-0.1px ogin-with-mb-text" > ManageBac Global</span>
              </div>
            </div>
          </button>

          <button type='button' onClick={() => onMergeClick('china')} className='login-with-mb mt-4'>
            <div className="flex items-center justify-center">
              <MBLogo />
              <div className="ml-3 login-with-mb-text tracking-2px">
                MERGE WITH
                <span className="tracking-0.1px ogin-with-mb-text" > ManageBac China&nbsp;</span>
              </div>
              <div className="mb-china-logo">
                <MBLogoChina />
              </div>
            </div>
          </button>
          <div className="extra-info">
            Merge this account with your ManageBac account to sync user data, benefit from exercises, and to log in
            with your ManageBac credentials
          </div>
        </>
      )}

      <div className="profile-page-subtitle flex">
        <span>YOUR PROFILE</span>
        {isMBUser && <div className="flex items-center profile-notification">
          <img src={mbSyncIcon} alt="" />
          <div className="ml-5px">Last sync {lastSync}</div>
        </div>}
      </div>

      {!hasMBIdentity && (
        <SectionWrapper hasMBIdentity={hasMBIdentity} userData={userData} isMBUser={isMBUser} onClick={() => !hasMBIdentity && changeMenuPath('change-username')}>
          <div className="profile-page-item-container">
            <div className="white-background" />
            <div className="profile-page-item">Username</div>
            <HorizontalSeparator className="items-separator" />
            <div className="detailed-item">
              <span className={'profile-data' + (!hasMBIdentity ? ' no-spacing' : '')}>{userData.username}</span>
              {!hasMBIdentity && <HeaderIcons.ForwardChevronIcon />}
            </div>
          </div>
        </SectionWrapper>
      )}

      <SectionWrapper hasMBIdentity={hasMBIdentity} userData={userData} isMBUser={isMBUser} onClick={() => !hasMBIdentity && changeMenuPath('about-you')}>
        <div className="profile-page-item-container">
          <div className="white-background" />
          <div className="profile-page-item">About you</div>
          <HorizontalSeparator className="items-separator" />
          <div className="detailed-item">
            <span
              className={'profile-data' + (!hasMBIdentity ? ' no-spacing' : '')}>{userData.firstName} {userData.lastName}</span>
            {!hasMBIdentity && <HeaderIcons.ForwardChevronIcon />}
          </div>
        </div>
      </SectionWrapper>

      <SectionWrapper hasMBIdentity={hasMBIdentity} userData={userData} isMBUser={isMBUser} onClick={() => !hasMBIdentity && changeMenuPath('contact-details')}>
        <div className="profile-page-item-container">
          <div className="white-background" />
          <div className="profile-page-item">Contact details</div>
          <HorizontalSeparator className="items-separator" />
          <div className="detailed-item">
            <span className={'profile-data' + (!hasMBIdentity ? ' no-spacing' : '')}>{userData.email}</span>
            {!hasMBIdentity && <HeaderIcons.ForwardChevronIcon />}
          </div>
        </div>
      </SectionWrapper>

      {hasMBIdentity ? (
            <div className="extra-info">
              Update any information about you and your contact details
              on  <SectionWrapper hasMBIdentity={hasMBIdentity} userData={userData} isMBUser={isMBUser} className='text-#6398E6 font-semibold' >ManageBac</SectionWrapper>
            </div>
          ) : (
            <div className="extra-info">Update any information about you and your contact details</div>
          )}

    </div>
  </div>
}

export default ProfilePageComponent