import axios from 'axios';
import {getAccessToken,getJWTToken} from '../helpers';

export const get = (url, headers, queryParams) => {
  return call('GET', url, headers, queryParams);
};

export const put = (url, data, headers, queryParams) => {
  return call('PUT', url, headers, queryParams, data);
};

export const post = (url, data, headers, queryParams) => {
  return call('POST', url, headers, queryParams, data);
};

export const remove = (url, data, headers, queryParams) => {
  return call('DELETE', url, headers, queryParams, data);
};
export const apPost = (url, data, headers, queryParams) => {
  return callAP('POST', url, headers, queryParams, data);
};
export const getPartners = (url, data, headers, queryParams) => {
  return callPartners('GET', url, headers, queryParams, data);
};



export const createAuthUrl = url => {
  const token = getAccessToken();
  return `${process.env.REACT_APP_CDN_URL}${url}?access_token=${token}`;
};

const call = (method, url, headers, queryParams, data) => {
  const receivedHeaders = (headers === undefined) ? {} : headers;
  const authHeader = getAuthorizationHeader();
  const requestHeaders = {...receivedHeaders, ...authHeader};
  const options = {
    method: method,
    url: process.env.REACT_APP_API_URL + url,
    headers: requestHeaders,
    data: data,
    params: queryParams
  };


  return axios(options).then(resp => checkResponse(resp)).catch(error => {
    const response = error.response;
    const data = response.data;
    if (data && data.error) {
      return Promise.reject(data.error);
    }
    return Promise.reject(response.statusText);
  });
};
const callAP = (method, url, headers, queryParams, data) => {
  const receivedHeaders = (headers === undefined) ? {} : headers;
  // const authHeader = getAuthorizationHeaderAP();
  // const requestHeaders = {...receivedHeaders, ...authHeader};
  const queryJWTParams = {jwt:getJWTToken(),partner: 'osc'}
  const options = {
    method: method,
    url:process.env.REACT_APP_AP_BASE_URL + url,
    // headers: requestHeaders,
    data: data,
    params: queryJWTParams
  };


  return axios(options).then(resp => checkResponse(resp)).catch(error => {
    const response = error.response;
    const data = response.data;
    if (data && data.error) {
      return Promise.reject(data.error);
    }
    return Promise.reject(response.statusText);
  });
};


function checkResponse(resp) {

  if (resp === '') return {};

  return resp.data;
}

const getAuthorizationHeader = () => {
  let headers = {};

  const token = getAccessToken();

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  return headers;
};


// const getAuthorizationHeaderAP = () => {
//   let headers = {};

//   const token = process.env.REACT_APP_AP_TOKEN;

//   if (token) {
//     headers['Authorization'] = 'Bearer ' + token;
//   }

//   return headers;
// };



/*        Get Partner       */
const callPartners = (method, url, headers, queryParams, data) => {
  const receivedHeaders = (headers === undefined) ? {} : headers;

  const options = {
    method: method,
    url:url,
    headers: receivedHeaders,
    data: data,
    params: queryParams
  };


  return axios(options).then(resp => checkResponse(resp)).catch(error => {
    const response = error.response;
    const data = response.data;
    if (data && data.error) {
      return Promise.reject(data.error);
    }
    return Promise.reject(response.statusText);
  });
};