import {SubTopicsIcon} from 'icons/FlashcardIcons';
import SearchResultsHeader from 'pages/search/SearchResultsHeader';
import React, {Component} from 'react';
import SubtopicLinkResultList from 'ui-components/link-results/SubtopicLinkResultList';
import {connect} from 'react-redux';
import {transformResultsListToArray} from 'helpers';

export default class SubtopicLinksContainer extends Component {
  componentDidMount() {

  }

  render() {
    const newListItems = this.props.subTopicsList ? transformResultsListToArray(this.props.subTopicsList) : [];
    return <React.Fragment>
      <SearchResultsHeader show={true} title={'Sub-topics'} icon={<SubTopicsIcon /> } showMore={false}  total={0} />
      <SubtopicLinkResultList newListItems={newListItems} />
    </React.Fragment>;
  }
}
