import {List} from 'immutable';
import {createSelector} from 'reselect';
export const getSubject = (state, id) => state.getIn(['api', 'subjects', 'data']).filter(item => item.get('id') === id).get(0, null);
export const getSubjectv2 = (state, id) => state.getIn(['api', 'subjects']).data || [];
export const activeSubjectId = (state) => state.getIn(['subject', 'activeSubject'])?.id;
export const getActiveSubject = state => state.getIn(['subject', 'activeSubject'], null);
export const getActiveTeachingLevel = state => state.getIn(['subject', 'activeTeachingLevel']);
export const subjectSyllabusList = state => state.getIn(['subject', 'subjectSyllabusList'], null);
export const hasSubject = (state, subject) => state.getIn(['api', 'subjects']).filter(item => {
  return item === subject;
});
export const subjectsTeachingLevel = state => state.getIn(['subject', 'subjectsTeachingLevel']);
export const teachingLevelsForActiveSubject = state => state.getIn(['subject', 'activeSubject']).children || [];

export const activeTeachingLevelTitle = state => {
  const activeSubject = getActiveSubject(state);
  const activeTeachingLevelId = getActiveTeachingLevel(state);
  const teachingLevel = activeSubject.children.find(item => item.id === activeTeachingLevelId);
  return teachingLevel && teachingLevel.title;
};

export const activeSubjectTeachingLevel = state => {
  const activeSubject = getActiveSubject(state);
  const activeSubjectId = activeSubject.id;
  const activeTeachingLevel = subjectsTeachingLevel(state);
  const activeSubjectTeachingTitle = activeTeachingLevel[`${activeSubjectId}`];
  return activeSubjectTeachingTitle && activeSubjectTeachingTitle;
};

export const activeExternalExerciseSubjectTeachingLevel = state => {
  const activeSubject = getActiveSubject(state);
  const activeTeachingLevelId = getActiveTeachingLevel(state);
  const teachingLevel = activeSubject.children.find(item => item.id === activeTeachingLevelId);
  // return teachingLevel && teachingLevel.get('title');
  return teachingLevel?.title;
};
export const topicsForCurrentActiveTeachingLevel = (state, subjectId, isExernalExercise) => {
  let activeTLTitle;
  // const syllabusList = subjectSyllabusList(state)
  if(!isExernalExercise){
    activeTLTitle = activeSubjectTeachingLevel(state);
  }else{
    activeTLTitle = activeExternalExerciseSubjectTeachingLevel(state);
  }
  //const activeTLTitle = activeSubjectTeachingLevel(state);
  const activeSubject = getActiveSubject(state);

  // if (activeTLTitle === 'All') {
  //   return state.getIn(['api', 'allTopics', subjectId, 'data', 'topics'], List());
  // }
  if(isExernalExercise && activeTLTitle === 'All'){
    return state.getIn(['api', 'allTopics', subjectId, 'data', 'topics'], List());
  }
  if(isExernalExercise && activeTLTitle !== 'All'){
    return state.getIn(['api', 'allTopics', subjectId, 'data', 'topics'], List()).filter((value) => value.get('tlvl') === activeTLTitle);
  }
  if(activeSubject.slug === "mathematics"){
    return state.getIn(['api', 'allTopics', subjectId, 'data', 'topics'], List()).filter((value) => value.get('teachingTag') === activeTLTitle);
  }
  if(activeSubject.slug === "pre-ib-mathematics"){
    return state.getIn(['api', 'allTopics', subjectId, 'data', 'topics'], List());
  }

  if(activeSubject?.tagging === 'NEW_SUBJECT') {
    return state.getIn(['api', 'allTopics', subjectId, 'data', 'topics'], List())
  }
  
  return state.getIn(['api', 'allTopics', subjectId, 'data', 'topics'], List()).filter((value) => value.get('teachingTag').includes(activeTLTitle));
};

export const allTopicsLoading =  (state, subjectId) =>{
  return state.getIn(['api', 'allTopics', subjectId, 'succeeded'],false);
}
// export const getFitlerTopicsId = createSelector(
  // [(state, subjectId) => { return state.getIn(['api', 'allTopics', subjectId, 'data', 'topics'], List())},
  
  // getActiveSubject
  // ],
  // (allTopics, activeSubject) => {

  //   return allTopics.filter((value) => value.get('teachingTag').includes())
  //   return getNotificationsCountBySubjectId(subjectId, externalExercises, viewedExercises);
  // }
// );
