import './EditFolderPage.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RouterLink from 'router/Link';
import React, { PureComponent } from 'react';
import { createFolder, updateFolder } from 'actions/desk';
import { isInvalid, submit } from 'redux-form/immutable';
import BackButton from 'components/navigation-header/BackButton';
import SimpleButton from 'components/simple-button/SimpleButton';
import EditFolderNameForm, { FORM_NAME } from './EditFolderNameForm';
import NavigationHeader from 'components/navigation-header/NavigationHeader';
import * as Selectors from 'selectors';
import { Redirect } from 'react-router-dom';

class EditFolderPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFolderNameChanged: false
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  setFolderNameFlag = (flag) => {
    this.setState({
      isFolderNameChanged: flag
    })
  }

  onFormSubmit(data) {
    const folderId = this.getFolderId() ? this.getFolderId() : this.props.folderId;
    if (folderId) {
      this.props.updateFolder(folderId, data);
      this.props.toggleEditMode();
    } else {
      this.props.createFolder(data);
      this.props.changeRoute && this.props.changeRoute('folders');
    }
  }

  render() {
    let folderId;
    let folderTitle;
    let initialValues;
    if (this.props.currentFolderEdit) {
      folderId = this.props.folderId;
      folderTitle = this.props.folderTitle;
      initialValues = { title: this.props.folderTitle };
    } else {
      if (!this.props.changeRoute) {
        folderId = this.getFolderId();
        folderTitle = this.props.location.state && this.props.location.state.folderTitle;
        initialValues = { title: folderTitle };
      }
    }
    if (folderId && !folderTitle) {
      return <Redirect to='/flashcard/desk/folders' />;
    }

    return (
      <div id={this.props.id ? this.props.id : 'edit-folder-page'} className={this.props.changeRoute ? 'fixed-dimensions' : ''}>
        <NavigationHeader
          className={'transparent'}
          contained={this.props.isMobile}
          leftSection={!this.props.currentFolderEdit ? <React.Fragment>{
            this.props.isMobile && this.props.changeRoute ? <BackButton onClick={() => this.props.changeRoute('folders')} inverted text='Cancel' hideArrow /> : <RouterLink to={'/flashcard/desk/folders' + (folderId ? `/${folderId}` : '')}>
              <BackButton inverted text='Cancel' hideArrow />
            </RouterLink>}</React.Fragment>
            : <BackButton onClick={() => this.props.toggleEditMode()} inverted text='Cancel' hideArrow />}

          rightSection={
            <SimpleButton onClick={this.props.submitForm} disabled={this.props.invalid || !this.state.isFolderNameChanged} inverted text={this.props.currentFolderEdit ? 'Save' : 'Create Folder'} />
          } />

        <EditFolderNameForm setFolderNameFlag={this.setFolderNameFlag} initialValues={initialValues} onSubmit={this.onFormSubmit} />
      </div>
    );
  }

  getFolderId() {
    return this.props.match && this.props.match.params && this.props.match.params.id;
  }

}

EditFolderPage.propTypes = {
  invalid: PropTypes.bool,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
  createFolder: PropTypes.func.isRequired,
  updateFolder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  invalid: isInvalid(FORM_NAME)(state),
  isMobile: Selectors.isMobile,
  isLandscape: Selectors.isLandscape
});

const mapDispatchToProps = {
  createFolder,
  updateFolder,
  submitForm: () => submit(FORM_NAME)
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFolderPage);
