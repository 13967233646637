import classNames from 'classnames';
import React from 'react';
import {ReactComponent as OSCSTUDYLOGO} from 'assets/icons/os-study.svg';

type LandingPageLinksProps = {
    hide: boolean,
    onClick:() => void; 
} 
export const LandingPageLinks: React.FC<LandingPageLinksProps> = ({hide, onClick}) => {
    return <div className={classNames('landing-page-link-wrapper',{'!hidden': hide})} > 
        <div className="landing-page-link-text "><b>OSC Study</b> is the new version of SMART<b>PREP</b></div>
        <div className="landing-page-link-text padding-top-12">To log in with your SMART<b>PREP</b> account, <br />
        <div className="login-click cursor-pointer" onClick={onClick}><b>click here</b></div></div>
    </div>;
};

type LandingPageLogoProps = {
  hide: boolean,
  loginHide: boolean, 
} 
export const LandingPageLogo: React.FC<LandingPageLogoProps>  = ({hide, loginHide}) => {
  return <div className="landign-page-logo" >
      <OSCSTUDYLOGO />
  </div>;
};

type LoginFooterProps = {
  hide: boolean, 
}
export const LoginFooter: React.FC<LoginFooterProps> = ({hide}) => {
  return <div className='login-footer-text'>
    <div className='login-footer-inner-text'>
      © {(new Date().getFullYear())} Faria Education Group Limited. All Rights Reserved. <a href="https://oxfordstudycourses.com/terms/terms-of-service" target="_blank"><b>Terms, Policies, Agreements & Security</b> </a>
    </div>
  </div>;
};
