import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './payment-view.scss';
import {BackChevronTurquoiseIcon} from 'icons/HeaderIcons';
import {CloseBasketArrow, OpenBasketArrow} from 'icons/PassiveIcons';
import {calculateTotalPrice, calculateVAT} from './StoreBasket.js';

export default class PaymentView extends Component {
  constructor(props) {
    super(props);
    this.registerCreditCardPurchase = this.registerCreditCardPurchase.bind(this);
    this.registerPaypalPurchase = this.registerPaypalPurchase.bind(this);
  }

  setPurchaseDataForAPI(paymentType) {
    const totalPrice = calculateTotalPrice(this.props.itemsInBasket);
    const totalPriceVAT = calculateVAT(this.props.itemsInBasket, totalPrice);
    const basketItems = Array.from(this.props.itemsInBasket, (item) => {
      return Array.from(item.get('subscriptions'), (subscription) => {
        return {
          id: subscription.get('id'),
          quantity: subscription.get('amount')
        };
      });
    });

    return {
      paymentType: paymentType,
      totalAmount: parseFloat(totalPrice).toFixed(2),
      vatPercentage: parseFloat(totalPriceVAT).toFixed(2),
      subscriptions: Array.prototype.concat.apply(...basketItems)
    };
  }

  registerCreditCardPurchase() {
    this.props.registerPurchase(this.setPurchaseDataForAPI('creditCard'));
  }

  registerPaypalPurchase() {
    this.props.registerPurchase(this.setPurchaseDataForAPI('paypal'));
  }

  render() {
    let className = 'payment-view-wrapper';
    if (this.props.isContained) className += ' contained';

    return (
      <div className={className}>
        <div className="arrow-container" onClick={() => {
          this.props.toggleBasket();
          this.props.togglePaymentView();
        }}>
          {this.props.isBasketDetailsVisible ? <CloseBasketArrow/> : <OpenBasketArrow/>}
        </div>

        <div className="back-to-cart-container" onClick={() => this.props.togglePaymentView()}>
          <BackChevronTurquoiseIcon/>
          <span className="back-to-cart">Cart</span>
        </div>

        <div className="payment-options">
          <div className='pay-with-title'>
            <span>PAY WITH:</span>
          </div>

          <div className='payment-option-container'>
            <div className="payment-option">
              <span className='payment-credit-card-title'>Credit card</span>
              <div className='credit-card-image' onClick={(e) => {
                  e.preventDefault();
                  this.registerCreditCardPurchase();
                }
              }></div>
            </div>
            <div className="payment-option">
              <div className="paypal-image" onClick={(e) => {
                  e.preventDefault();
                  this.registerPaypalPurchase();
                }
              }></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
