import { nextSide } from 'actions/studying';
import { selectNextFlashcard, selectPrevFlashcard } from 'actions/carousel/carouselActions';
import classNames from 'classnames';
import { isMobile } from 'helpers';
import React, {memo, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getActiveMediaTab, getFlashcardById, hasNextFlashcard, hasPrevFlashcard } from 'selectors';
import { carouselCurrentFlashcard } from 'selectors/carouselSelectors';
import {CardFlipFlashcard } from 'icons/FlashcardIcons';

type FlashcardControlsContainerProps = {
    displayStyle: boolean;
    onActionFlashcard:(prevClick: string, arg1: boolean, dispatchfunction?: () => void, hasPrev?: boolean) => void;
    listFlashcardId: string;
    canEditExercise: boolean;
}  

const FlashcardControlsContainer: React.FC<FlashcardControlsContainerProps> = ({displayStyle, onActionFlashcard, listFlashcardId, canEditExercise}) => {
    const dispatch = useDispatch();
    const [actionName, setActionName] = useState({prevClick: false, nextClick: false, flipClick: false})
    const hasPrev = useSelector(hasPrevFlashcard)
    const hasNext = useSelector(hasNextFlashcard)
    const listFlashcardContent = useSelector((state) => getFlashcardById(state, listFlashcardId))
    const flashcardContent = useSelector(carouselCurrentFlashcard)
    const hasMobile = useSelector(isMobile)
    const activeMediaTab = useSelector(getActiveMediaTab)

    const handleOnActionButtonClick = (actionType:string, callback: any) => {
        setActionName({...actionName, [actionType]: true})
        callback?.()
    }

    const canFlip = () => {
        if(activeMediaTab === "flashcard"){
            if(canEditExercise){
                return listFlashcardContent && listFlashcardContent.getIn(['outsideTemplate', 'sides']).size > 1;
            }else{
                return flashcardContent && flashcardContent.size && flashcardContent.getIn(['outsideTemplate', 'sides']) &&  flashcardContent.getIn(['outsideTemplate', 'sides']).size > 1;
            }
        }
    }
    
    const handleClick = (e:any, callback?:any) => {
        const { clientX, clientY } = e;
        const elementsFromPoint = document.elementsFromPoint(clientX, clientY);
        const anchorElement = elementsFromPoint.find((elementFromPoint) => elementFromPoint.nodeName === 'A') as HTMLElement | undefined;
        if(anchorElement) {
            return anchorElement?.click();
        }
        callback?.();
    }
    const handleLeftClick = (e:React.MouseEvent) => {
        const cb = () => handleOnActionButtonClick("prevClick", () => onActionFlashcard("prevClick", true, () => dispatch(selectPrevFlashcard()), hasPrev))
        handleClick(e, cb)
    }
    const handleCenterClick = (e:React.MouseEvent) => {
        const cb = () => handleOnActionButtonClick("flipClick", () => onActionFlashcard("flipClick", true, () => dispatch(nextSide()), canFlip()))
        handleClick(e, cb)
    }
    const handleRightClick = (e:React.MouseEvent) => {
        const cb = () => handleOnActionButtonClick("nextClick", () => onActionFlashcard("nextClick", true, () => dispatch(selectNextFlashcard()), hasNext))
        handleClick(e, cb)
    }
    return <div className={classNames('buttons-container', {'!hidden' : displayStyle}, {'!flex' : !displayStyle})}>
        {!canEditExercise ?  
            <>
                <div className={`flashcard-button-control left ${hasPrev ? 'active' : ''}`} 
                    onClick={handleLeftClick} 
                    onMouseOver={(e) => !hasMobile  && onActionFlashcard("prevHover", true)} 
                    onMouseMove={(e) => !hasMobile  && onActionFlashcard("prevHover", true)} 
                    onMouseLeave={(e) => !hasMobile && onActionFlashcard("prevHover", false)}>
                </div>
                <div className={`flashcard-button-control-center ${canFlip() && 'active' }`}
                    onClick={handleCenterClick} 
                    onMouseOver={(e) => !hasMobile  &&  onActionFlashcard("flipHover", true)} 
                    onMouseMove={(e) => !hasMobile && onActionFlashcard("flipHover", true)} 
                    onMouseLeave={(e) => !hasMobile && onActionFlashcard("flipHover", false)}>
                </div>
                <div className={`flashcard-button-control right ${hasNext && 'active'}`}  
                    onClick={handleRightClick} 
                    onMouseOver={(e) => !hasMobile && onActionFlashcard("nextHover", true)} 
                    onMouseMove={(e) => !hasMobile && onActionFlashcard("nextHover", true)} 
                    onMouseLeave={(e) => !hasMobile && onActionFlashcard("nextHover", false)}>
                       
                </div>
            </>
            : 
            <>
                <div className={`flashcard-button-control-center ${canFlip() ? 'active' : ''}`}     
                    onClick={(e) => handleOnActionButtonClick("flipClick", () => onActionFlashcard(canFlip(), false)) }>
                    {canFlip() && <div className={classNames('visible opacity-100', {'!invisible !opacity-0' : !(actionName.flipClick)})}> <CardFlipFlashcard/> </div>}
                </div>
            </>
        }
    </div>
}

export default memo(FlashcardControlsContainer);