import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ComingSoonComponent from 'components/coming-soon';
import * as PassiveIcons from 'icons/PassiveIcons';


const Props = {
  'notes': 'Add study notes to any card and access all your notes from a preview screen.',
  'folders': 'Categorize each card you view into folders and keep track of your own learning.',
  'markings': 'Color-code key information and access all your highlights in one screen.',
  'shuffle': 'Randomize the cards several times in order to promote knowledge acquisition.',
  'smart-shuffle': 'Automatically rank the question you answered incorrectly as your top learning priorities.',
};

class ComingSoonFeatureContainer extends Component {
  renderIcon() {
    const Icon = PassiveIcons[this.props.icon];
    return Icon ? <Icon /> : null;
  }
  
  render() {
    return <ComingSoonComponent title={this.props.title} text={Props[this.props.propKeyName]} className="tooltip-coming-soon">
      <div className="coming-soon-icon">{this.renderIcon()}</div>
    </ComingSoonComponent>;
  }
}

ComingSoonFeatureContainer.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  propKeyName: PropTypes.string.isRequired,
};

export default connect()(ComingSoonFeatureContainer);
