import React, {Component} from 'react';

export class ExpiredText extends Component {
  render() {
    return (
      <div className="modal-tutorial-text swal-container">
        <div className="title">Oopsie!</div>
        <div>Your free trial has now ended! Not to worry, I have been archiving your sessions.</div>
        <br/>
        <div>To <span className="extra-paragraph">restore your full access </span> and carry on as before, merge this
          account with ManageBac or contact us at<br/><a className='swal-email-text'
            href="mailto:info-app@smart-prep.com?Subject=Restore access"
            target="_top">info-app@smart-prep.com</a>.
        </div>
      </div>
    );
  }
}

export class NoTrialText extends Component {
  render() {
    return (
      <div className="modal-tutorial-text swal-container">
        <div className="title">Hey, There!</div>
        <div>Try the Demo to get a feel for the flashcards I offer in 6 subjects. Each subject includes 10 sample flash
          cards.
        </div>
        <br/>
        <div>To study with full access, visit the In-App Store.</div>
      </div>
    );
  }
}

export class TrialText extends Component {
  render() {
    const text = {
      title: 'Hey, There!',
      firstParagraph: 'Your fee trial gives you complete access to all subjects, so get busy! ',
      secondParagraph: <span>To continue with full access when your trial ends, merge this account with ManageBac or contact us at <br/><a
        className='swal-email-text' href="mailto:info-app@smart-prep.com?Subject=Restore access"
        target="_top">info-app@smart-prep.com</a></span>,
      extraParagraph: `You have ${this.props.numdays} ${this.props.numdays === 1 ? ' day' : ' days'} and ${this.props.numhours} ${this.props.numhours === 1 ? ' hour' : ' hours'} left.`
    };
    return (
      <div className="modal-tutorial-text swal-container">
        <div className="title">{text.title}</div>
        <div>{text.firstParagraph} </div>
        <div className="extra-paragraph add-space">{text.extraParagraph}</div>
        <br/>
        <div>{text.secondParagraph}</div>
      </div>
    );
  }
}

export class StartTutorialText extends Component {
  render() {
    return (
      <div className="modal-tutorial-text swal-container">
        <div className="title">Welcome!</div>
        <div>I’m here to give you a quick tour around the app. Do you want to follow me now?</div>
        <br/>
        <div>If you want to skip and go straight in, you can always find me under 'Support' in the Home Menu</div>
      </div>
    );
  }
}

export class PurchasedText extends Component {
  render() {
    return (
      <div className="modal-tutorial-text swal-container">
        <div className="title">Yaay!</div>
        <div>You have successfully purchased full access to new flashcards - you can start studying right away!</div>
        <br/>
        <div>Should you experince any problems with your purchase, please contact us at:</div>
        <div className="swal-email-text">
          <a href={'mailto:info-app@smart-prep.com?Subject=Purchase Problem'} target="_top">info-app@smart-prep.com</a>
        </div>
      </div>
    );
  }
}
