import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {isMobile, isLandscape, shouldGoBackToSearch} from 'selectors';
import BackButton from 'v2/components/navigation-header/BackButton';
import { useHistory } from 'react-router-dom';
import {setGoBackToSearch} from 'actions/search/searchActions'
import {showAlert} from 'actions/notification/notificationActions'
import Notification from 'v2/components/notifications/Notification'

type BackToSearchButtonProps = {
    alternate: Function;
}  

const BackToSearchButton: React.FC<BackToSearchButtonProps> = ({alternate}) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const backToSearch = useSelector(shouldGoBackToSearch)
    const hasMobile = useSelector(isMobile)
    const hasLandScape = useSelector(isLandscape)

    const onClickCallback = () => {
        dispatch(setGoBackToSearch(false))
        history.push('/flashcard/search');
        if(hasMobile && hasLandScape){
            showAlert({
                content: <Notification icon={'portrait'}
                  title={'Rotate your device to view search results'}
                  text={'Make sure auto rotate is activated'}
                />, buttons: false, timer: 2000
            });
        }
    }
    
    return (
        <>
        { backToSearch && (!hasMobile || alternate().props.text !== "Notes" ) ? <BackButton text="Search" onClick={onClickCallback}/> : alternate() }
        </>
    )
}

export default BackToSearchButton;