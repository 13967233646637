import React, { useEffect, useState } from 'react'
import FilterHeader from './FilterHeader';
import './filter.scss'
import { useDispatch, useSelector } from 'react-redux';
import {isMainMicroFilterBack} from 'selectors/navigation/navigationSelector'
import FilterToggleHeader from './FilterToggleHeader'
import {examsofFilterItems} from 'selectors/desk/deskSelector'
import {updateExamsPaperFilterItems} from 'actions/desk/deskActions'
import {toggleMainMicroOverlay} from 'actions/navigation/navigationActions'
import {setExamFilterData} from 'actions/api/apiActions'
import usePrevious from 'hooks/usePrevious';
import FilterButton from './FilterButton'
import FilterContainer from './FilterContainer';
import { IBIcon, PmjIcon} from 'icons/HeaderIcons';

type SourceFilterProps = {
    onClick?: (e: any) => void;
    onClose: () => void;
}  

const filterData = [
    { 
        title: 'Official IB exams', 
        id:'IB',
        subTitle:'Questions with solutions that were part of former official exams.',
        icon: <IBIcon />
    },
    { 
        title: 'OSC practice exams',
        id:'OSC',
        subTitle:'Questions with solutions designed by Pamoja authors, simulating official IB exam content.',
        icon: <PmjIcon />}
]

const SourceFilter: React.FC<SourceFilterProps> = ({onClick, onClose}) => {
    const dispatch = useDispatch();
    const [isActive, setActive] = useState(true)
    const [disabled, setDisable] = useState(true)
    const [filter, setFilter] = useState(['OSC', 'IB'])
    const hasMainMicroFilterBack = useSelector(isMainMicroFilterBack)
    const examFilterItems = useSelector(examsofFilterItems)?.toJS();
    const previousFilterState = usePrevious(['OSC', 'IB'])
    
    useEffect(() => {
        let data = examFilterItems['source']        
        data.length > 0 && setFilter(data);
    },[])

    useEffect(() => {
        if(previousFilterState !== filter){
            changeFilter();
        }  
    },[filter, previousFilterState])

    const selectToggle = (val: boolean) => {
        setActive(!val)
        if(isActive){    
            setFilter([]);
            return;
        }
        setFilter(previousFilterState) 
    }

    const selectFilter = (val: string) => {
        const filterVal = [...filter];
        let indexOfItem = filterVal.indexOf(val);
        if (indexOfItem >= 0) {
            filterVal.splice(indexOfItem, 1);
        } else {
            filterVal.push(val);
        }
        setFilter(filterVal);
    }

    const changeFilter = () => {        
        let prevExamFilter = examFilterItems['source']
        if (JSON.stringify(filter.sort()) === JSON.stringify(prevExamFilter.sort()) || filter.length < 1) {
            setDisable(true)
        } else {
            setDisable(false)
        }
        if (filter.length === 2) {
            setActive(true)
        } else {
            setActive(false)
        }
    }

    const applyChanges = () => {
        if(disabled) return ;
        dispatch(updateExamsPaperFilterItems(filter, "source"))
        dispatch(setExamFilterData())
        onClose();
    }

    const onSelectMain= () => {
        onClose();
        dispatch(toggleMainMicroOverlay(true, true))
    }
    
    return <div className='filter-wrapper' onClick={onClick}>
        <FilterHeader
            fromCC={hasMainMicroFilterBack} 
            onBackClick={onSelectMain} 
            text={'Filter'} 
            hideArrow={false} 
            title={'Source'} 
            onCloseClick={onClose} 
        />
        <FilterToggleHeader title={'All sources'} onChange={() => selectToggle(isActive)} checked={isActive}/>
        <div className='pt-0 px-5 pb-4'>
            {filterData.map((value:any, index:number) => {
                return <FilterContainer index={index} isWidth={true} filterType={'source'} itemID={value.id} filter={filter} onClick={() => selectFilter(value.id)} isExams={true} >
                    <>
                        <div className='filter-category-section'>{value.icon} {value.title}</div>
                        <div className='text-13px leading-16px text-#919191 text-ellipsis overflow-hidden mt-[3px]'>{value.subTitle}</div>
                    </>
                </FilterContainer>;
            })}
        </div>
        <FilterButton onClose={onClose} onClick={applyChanges} disabled={disabled}/>
    </div>
}

export default SourceFilter;