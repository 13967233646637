import React from 'react';
import {FilterHeaderWrapper, FilterTitle, FilterCloseIcon, PopupLeftSection, FilterCloseIconWrapper} from "components/studying/examFilterContainer/examFilter.styles";
import BackButton from 'components/navigation-header/BackButton';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/icons/page-overlay/close-icon-turquoise.svg';




function FilterHeader({fromCC, onBackClick, text, hideArrow, title, onCloseClick}) {
    return (
        <FilterHeaderWrapper fromCC={fromCC}>
            <PopupLeftSection>
                {fromCC &&
                    <BackButton onClick={onBackClick} text={text} hideArrow={hideArrow} />
                }
                <FilterTitle fromCC={fromCC}>{title}</FilterTitle>
            </PopupLeftSection>
            <FilterCloseIconWrapper onClick={()=>onCloseClick()}>
                <FilterCloseIcon >
                    <CloseIcon />
                </FilterCloseIcon>
            </FilterCloseIconWrapper>
        </FilterHeaderWrapper>
    )
}


FilterHeader.propTypes = {
    fromCC: PropTypes.bool,
    onBackClick: PropTypes.func,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func.isRequired,
};

export default FilterHeader
