import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components/macro';
import Checkbox from 'ui-components/Checkbox';
import {textEllipsis} from 'ui-components/helpers/common';
import Row from 'ui-components/helpers/Row';
import {TopicNumber} from 'ui-components/search-results/PreviewListItem';

const SubtopicFloatingTitle = ({numbering, title, active, id, parentIds, isScrolled, subTopicSeparator}) => {
  return (
    <StyledSubtopicFloatingTitle className={isScrolled && !subTopicSeparator ? "":"list-animation"} active={active} isScrolled={isScrolled} subTopicSeparator={subTopicSeparator}>
      <Row verticalAlign>
        <TopicNumber>{numbering}</TopicNumber>
        <Title dangerouslySetInnerHTML={{__html: title}}/>
        <Checkbox isActive={false} isPartial={false} id={id} parentIds={parentIds}/>
      </Row>
    </StyledSubtopicFloatingTitle>
  );
};

SubtopicFloatingTitle.propTypes = {
  numbering: PropTypes.string,
  title: PropTypes.string,
  active: PropTypes.bool,
};

export default SubtopicFloatingTitle;

const StyledSubtopicFloatingTitle = styled.div`
  max-width: 320px;
  font-weight: bold;
  font-size: 15px;
  color: #2D3838;
  letter-spacing: 0;
  line-height: 19px;
  // padding-left: 10px;
  border-radius: 18px;
  border: 1px solid #D7D9D9;
  ${TopicNumber}{
    margin-right: 16px;
  }
  
  ${props => !props.active && css`
    ${TopicNumber} {
      background-color: #FFFFFF;
    }
  `}
  
  ${props => props.active && css`
    border: 0;
    background: rgba(255,255,255,0.90);
    box-shadow: 0 2px 5px 1px rgba(62,87,85,0.22);  
  `}
  ${'' /* ${props => (!props.isScrolled && !props.subTopicSeparator) && css`
      animation: topPoistionAnimation 0.5s forwards !important;
  `}
    
    @keyframes topPoistionAnimation {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-24px);
      }
    } */}
`;

const Title = styled.div`
  flex: 1;
  text-align: center;
  
  ${textEllipsis};
  overflow: hidden;
`;
