import React, { MouseEventHandler,  ReactElement, SVGProps  } from "react"
import {ForwardChevronIcon} from 'icons/HeaderIcons';
type ReactComponent = (props: SVGProps<SVGElement>) => ReactElement;

type MenuLinkProps = {
  onClick: MouseEventHandler<HTMLDivElement>,
  icon : ReactComponent,
  children: string
}



const MenuLink: React.FC<MenuLinkProps> = ({ onClick, icon: Icon, children}) => {
  return <div className="cursor-pointer z-1 py-2 px-5 menu-link-item" onClick={onClick}>
    <div className="flex items-center">
      <Icon />
      <div className="ml-3.5 font-bold leading-23px text-17px text-#2D3838 ">{children}</div>
      <div className="ml-auto ">
        <ForwardChevronIcon />
      </div>
    </div>
  </div>
}

export default MenuLink