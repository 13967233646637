import React, { useRef, useEffect } from "react"
import debounce from 'lodash.debounce';
import { useDispatch} from "react-redux"
import { getFlashcardContent } from "actions/api"
import classNames from 'classnames';
import Renderer from './ExamRendererContainer';

type ExamCarouselProps = {
  flashcardId: string
}

const ExamCarousel: React.FC<ExamCarouselProps> = ({ flashcardId }) => {
  const canvas = useRef<HTMLUListElement>(null);
  const dispatch = useDispatch();


  useEffect(() => {
    if (!flashcardId) return;
    getExamContent();
  }, [flashcardId])

  const getExamContent = debounce(() => {
    flashcardId && dispatch(getFlashcardContent(flashcardId, 'exams'));
  }, 400)

  const renderRenderers = () => {
    const isMiddle = 2;
    //display: !isMiddle && 'none'
    return (
      <li className={classNames("carousel-seaqt", { 'hidden': !isMiddle })} style={{ order: 2, }} key={flashcardId}>
        {/*TODO extract to study container*/}
        <div className='full-height justify-center flex' >
          <div className="exam-renderer-wrapper">
            <Renderer
              hasZoom={true}
              isVisible={isMiddle}
              flashcardId={flashcardId}
              currentFlashcardId={null}
            />
          </div>
        </div>
      </li>
    );

  };

  return (<div className='carousel-wrapperw'>
    <ul
      className="carouselw" ref={canvas}>
      {renderRenderers()}
    </ul>
  </div>)
}

export default ExamCarousel;