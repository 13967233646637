import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDocumentTitle, saveScrollPosition, setActiveFlashcardTab } from 'actions/navigation'
import { scrollPosition, isMobile, isLandscape, isApiRequestInProgress, getActiveSubject } from 'selectors';
import { debounce } from 'lodash';
import NavigationHeader from 'v2/components/navigation-header/NavigationHeader';
import { InfoIcon } from 'icons/CardIcons';
import LeftMenuLoader from 'v2/components/loader.v2/LeftMenuLoader';
import FreeTrialNotification from 'v2/components/free-trial-notification/FreeTrialNotification';
import Text from 'v2/components/common/Text';
import QuickStart from 'v2/components/media-tab/QuickStart';
import TopicsContainer from 'v2/pages/topics/TopicsContainer';
import useScrollDetection from 'hooks/useScrollDetection';
import { getLastStudyFlashcard } from 'actions/studying';
import './topicsPage.scss';

const TopicsPage = () => {
  const dispatch = useDispatch();
  let wrapperRef = useRef<HTMLDivElement>(null);
  const {isScrolled, bindScrollRef} = useScrollDetection({ WrappedRef: wrapperRef})
  const getScrollPosition = useSelector(scrollPosition)?.toJS();
  const hasMobile = useSelector(isMobile);
  const hasLandscape = useSelector(isLandscape);
  const hasApiRequestInProgress = useSelector((state) => isApiRequestInProgress(state, 'subjectStatusSaved'));
  const activeSubject = useSelector(getActiveSubject);

  useEffect(() => {
    dispatch(setActiveFlashcardTab(0));
  },[dispatch])

 
  useEffect(() => {
    dispatch(setDocumentTitle('Topics'))
    if (wrapperRef) {
      document.querySelector('.topics-container')?.classList.remove('scroll-smooth')
      //@ts-ignore
      wrapperRef.scroll({
        top: getScrollPosition?.topics,
        left: 0,
      });
      document.querySelector('.topics-container')?.classList.add('scroll-smooth')
       //@ts-ignore
      wrapperRef.addEventListener('scroll', onScroll);
    }
    return () => {
      //@ts-ignore
      wrapperRef?.removeEventListener('scroll', onScroll);
    }
  }, [wrapperRef])

  useEffect(() => {
    dispatch(getLastStudyFlashcard());
  }, [dispatch]);
  
  const onScroll = debounce((event: any) => {
    const scrollTop = event.target.scrollTop;
    dispatch(saveScrollPosition("topics", scrollTop))
  }, 300);

  const bindRef = useCallback((ref: any) => {
    wrapperRef = ref;
    bindScrollRef(ref);
  },[]);

  return <>
    <NavigationHeader 
      isCollapsed={!(hasMobile && hasLandscape) ? isScrolled : true} 
      text={'Topics'} 
      rightSection={!(hasMobile && hasLandscape) ? <a href="https://oxfordstudycourses.com/publication-data" target="_blank"
        rel="noopener noreferrer" className="info-icon right-section-info-icons"> <InfoIcon /> </a> : null
      }
      extendedHeight 
      leftSection={(hasMobile && hasLandscape) ? <div /> : null} 
      bottomSection={null}
    />
    <div className='topics-container pt-14 pb-[150px] h-full scrolling-touch' id={'topics-page'} ref={bindRef}>
      {hasApiRequestInProgress ? <LeftMenuLoader /> :
        <>
          {!(hasMobile && hasLandscape) && <>
            <FreeTrialNotification />
            <div className='pt-1 px-5 pb-0'>
              <Text className='text-33px font-bold leading-45px text-#2D3838' ><span dangerouslySetInnerHTML={{__html:activeSubject?.title}}></span></Text>
            </div>
            <QuickStart subject={activeSubject} />

          </>}
          <TopicsContainer parentScrollRef={() => wrapperRef} />

        </>}
    </div>
  </>
}

export default TopicsPage