import React, { useEffect, useRef } from 'react'
import Mark from 'mark.js';
import { activeSearchTerm } from 'selectors/searchSelectors';
import { useSelector } from 'react-redux';
import usePrevious from 'hooks/usePrevious';

type MarkTextProps = {
  className?: string;
  children: React.ReactNode;
  shouldMark?: any;
  markType?: string;
  type?: string;
  selectedIndex?: number;
}

const MarkText: React.FC<MarkTextProps> = ({ className, children, shouldMark, markType, type, selectedIndex }) => {
  const ref = useRef<HTMLDivElement>(null);
  const getActiveSearchTerm = useSelector(activeSearchTerm);
  const prevShouldMark = usePrevious(shouldMark);
  const prevActiveSearchTerm = usePrevious(getActiveSearchTerm);
  const prevChildren = usePrevious(children);

  useEffect(() => {
    if (getActiveSearchTerm && shouldMark) {
      updateMark(getActiveSearchTerm);
      highlightElements();
    }
  }, [])

  useEffect(() => {
    if (!shouldMark && prevShouldMark !== shouldMark) {
      if (ref.current) {
        new Mark(ref.current).unmark();
      }
      const highlightedElements = getHighlightedElements();
      for (let i = 0; i < highlightedElements.length; i++) {
        const element = highlightedElements[i];
        if (element.nodeName !== 'rect') continue;
        try {
          if (element.parentNode) {
            element.parentNode.removeChild(element);
          }
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }, [shouldMark, prevShouldMark])

  useEffect(() => {
    if (!shouldMark || !getActiveSearchTerm) return;

    if (prevChildren !== children || prevActiveSearchTerm !== getActiveSearchTerm || prevShouldMark !== shouldMark) {
      updateMark(getActiveSearchTerm);
      highlightElements();
    }
  }, [children, prevChildren, getActiveSearchTerm, prevActiveSearchTerm, shouldMark, prevShouldMark])

  const updateMark = (textToMark: string) => {
    const options = {
      element: 'mark',
      className: markType === 'span' ? `text-highlighting text-highlighting-span ${type}` : 'text-highlighting-mark',
      acrossElements: true,
      caseSensitive: false,
      separateWordSearch: false,
      exclude: [
        'tspan.skip',
        'span.text-highlighting',
        '.mathjax-formula',
        '.mathjax-formula *',
        '.mjx-char'
      ],
      each: (node: any) => {
        const parent = node.parentNode;
        const text = node.textContent;
        switch (parent.nodeName) {
          case 'text': {
            parent.replaceChild(document.createTextNode(text), node);

            if (hasRect(parent)) return;

            const rect = createRect(parent);
            if (parent.getAttribute('transform')) {
              rect.setAttribute('transform', parent.getAttribute('transform'));
            }

            parent.parentNode.insertBefore(rect, parent);
            break;
          }
          case 'tspan': {
            parent.replaceChild(document.createTextNode(text), node);

            if (hasRect(parent.parentNode)) return;

            const rect = createRect(parent);
            if (parent.parentNode.getAttribute('transform')) {
              rect.setAttribute('transform', parent.parentNode.getAttribute('transform'));
            }

            parent.parentNode.parentNode.insertBefore(rect, parent.parentNode);

            break;
          }
        }
      }
    };

    try {
      if (ref.current) {
        const markInstance = new Mark(ref.current);
        markInstance.unmark({
          done: () => {
            markInstance.mark(textToMark, options);

            if (textToMark.indexOf("’") != -1) {
              markInstance.mark(textToMark.replace("’", "'"), options);
            }
            if (textToMark.indexOf("‘") != -1) {
              markInstance.mark(textToMark.replace("‘", "’"), options);
            }
            if (textToMark.indexOf("'") != -1) {
              markInstance.mark(textToMark.replace("'", "’"), options);
            }
          }
        });
      }
    } catch (e) {
      console.warn(e);
    }
  }

  const hasRect = (node: any) => node.previousElementSibling
    && node.previousElementSibling.nodeName === 'rect'
    && node.previousElementSibling.getAttribute('class')
    && node.previousElementSibling.getAttribute('class').indexOf('text-highlighting') >= 0;


  const highlightElements = () => {
    const highlightedElements = getHighlightedElements();

    for (let i = 0; i < highlightedElements.length; i++) {
      const element = highlightedElements[i];
      if (i === selectedIndex) {
        element.classList.add('active');
      } else {
        element.classList.remove('active');
      }
    }
  }

  const getHighlightedElements = () => {
    if (!ref.current) return [];
    return ref.current.querySelectorAll('.text-highlighting');
  }

  const createRect = (node: any) => {
    const svgRect = node.getBBox();
    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    rect.setAttribute("x", (svgRect.x - 1).toString());
    rect.setAttribute("y", svgRect.y);
    rect.setAttribute("rx", '4');
    rect.setAttribute("ry", '10');
    rect.setAttribute("width", svgRect.width + 2);
    rect.setAttribute("height", svgRect.height);
    rect.setAttribute("class", "text-highlighting text-highlighting-rect");
    return rect;
  }

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  )
}

export default MarkText