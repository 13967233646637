import React, { useMemo, useEffect, useRef, useState } from 'react'
import './studyingLocation.scss'
import { useDispatch, useSelector } from 'react-redux';
import { isLeftMenuOpen } from 'selectors/navigation/navigationSelector';
import { isLandscape, windowWidth, isMobile } from 'selectors/browser/browserSelector';
import { getFormExams, getLastMediaTypeVisited } from 'helpers';
import { getUserId } from 'selectors/user';
import TeachingLevelSeperator from 'v2/components/teaching-level-selector/TeachingLevelSeperator';
import classNames from 'classnames';
import { toggleHiddenInfoOverlay } from 'actions/navigation';
import { getActiveSubject } from 'selectors/subject/subjectSelector';

type StudyingLocationProps = {
  isExternalExercise: boolean;
  isMobilePortrait?: boolean;
  isTitleEllipsed?: boolean;
  numbering?: string | null;
  onClick?: () => void;
  teachingLevel?: string;
  teachingTag?: string;
  title: string;
}

const StudyingLocation: React.FC<StudyingLocationProps> = ({ isExternalExercise, isMobilePortrait, isTitleEllipsed, numbering, onClick, teachingLevel, teachingTag, title }) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const getWindowWidth = useSelector(windowWidth);
  const subject = useSelector(getActiveSubject)
  const hasMobile = useSelector(isMobile)
  const hasLandscape = useSelector(isLandscape);
  const hasLeftMenuOpen = useSelector(isLeftMenuOpen);
  const userId = useSelector(getUserId);
  const [ellipseClass, setEllipseClass] = useState<boolean>(false);
  let mediaType = getLastMediaTypeVisited(userId);

  const slTag = useMemo(() => {
    // if(subject?.tagging === 'NEW_SUBJECT') {
      return {
        slTag: teachingTag === 'SL'
      }
    // }
    // return {}
  },[subject?.tagging, teachingTag])

  useEffect(() => {
    setTimeout(() => {
      if (getFormExams() === 'exams' && mediaType === 'exams') {
        setEllipseClass(false);
      } else {
        if (hasLandscape) {
          if (ref?.current) {
            if (ref?.current?.clientHeight - ref?.current?.scrollHeight <= 0) {
              setEllipseClass(true);
            }
          }
          else {
            setEllipseClass(false);
          }
        } else {
          if (ref?.current) {
            if (ref?.current?.clientWidth - ref?.current?.scrollWidth < 0) {
              setEllipseClass(true);
            }
          }
          else {
            setEllipseClass(false);
          }
        }
      }
    }, 100)
  }, [getWindowWidth, hasLandscape, isMobilePortrait, hasMobile, hasLeftMenuOpen])

  return <div className='studying-location-container flex items-center h-9 font-bold text-15px text-#2D3838 leading-19px pr-4 box-content cursor-pointer' onClick={() => isMobilePortrait && onClick?.()}>
    {teachingLevel && <div className='studing-teaching-tag-mobile pr-3'>
      <TeachingLevelSeperator tlvl={teachingLevel} tlvlTag={teachingTag} slTag={teachingTag === 'SL'} />
    </div>}
    {numbering && <div className='bg-white numbering rounded-[11px] py-0.5 px-[9px] font-bold text-13px leading-18px text-center tracking-2px uppercase text-blue-normal mr-2'>{numbering}</div>}
    <div className={classNames('title flex-1 text-center leading-5', { 'text-white': mediaType === 'video', 'active-epllise video-cc': ellipseClass && mediaType === 'video', 'active-epllise': ellipseClass, 'isTitleEllipsed': isTitleEllipsed, 'isExternalExercise': isExternalExercise })} ref={ref} dangerouslySetInnerHTML={{ __html: title }}></div>
    {(mediaType === 'exams' && hasMobile && hasLandscape) && getFormExams() === 'exams' && <div className='hidden-info text-blue-normal text-13px leading-4 select-none cursor-pointer mt-4 font-[500]' onClick={() => dispatch(toggleHiddenInfoOverlay(true, true))}>Show hidden info</div>}
    <div className='empty-screen-landscape'></div>
  </div>
}

export default StudyingLocation